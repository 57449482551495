import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { formatAddress } from 'helpers'
import { useAuth } from 'hooks'
import { IOrigin, Order } from 'types'

import {
    Marker,
    ContainerPopup,
    ContainerTopInfo,
    StoreName,
    ContainerClose,
    Icon,
    TextInfo,
    OrderLink,
    Label,
} from './marker-collect.styled'

const MarkerCollect: React.FC = memo(() => {
    return <Marker />
})

type PopUpContentProps = {
    origin: IOrigin
    onClose(): void
}
const PopUpContent: React.FC<PopUpContentProps> = memo(({ origin, onClose }) => {
    const { basePath } = useAuth()
    const history = useHistory()
    const _onClose = useCallback(() => {
        if (onClose) {
            onClose()
        }
    }, [onClose])

    const _goToDetails = useCallback(
        (order: Order) => {
            history.push(`${basePath}/detalhes-pedido/${order.id}`)
        },
        [basePath, history]
    )

    return (
        <ContainerPopup>
            <ContainerTopInfo>
                <StoreName>{origin.name}</StoreName>
                <ContainerClose onClick={_onClose}>
                    <Icon icon="times" />
                </ContainerClose>
            </ContainerTopInfo>
            <TextInfo> {formatAddress(origin.address).toUpperCase()}</TextInfo>
            <Label>Pedidos a coletar</Label>
            {origin.orders.map((item, i) => (
                <OrderLink key={i} onClick={() => _goToDetails(item)}>{`#${item.reference_id || item.id} - ${
                    item.customer.name
                }`}</OrderLink>
            ))}
        </ContainerPopup>
    )
})

export { MarkerCollect, PopUpContent }
