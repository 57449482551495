import React, { memo } from 'react'

import { Container, VerticalContent, HorizontalContent } from './scroll.styled'
type Props = {
    horizontal?: boolean
    hideScrollbar?: boolean
}
const Scroll: React.FC<Props> = memo(({ children, horizontal, hideScrollbar }) => {
    return (
        <Container className="scroll">
            {horizontal ? (
                <HorizontalContent hideScrollbar={hideScrollbar} className="scroll-content">
                    {children}
                </HorizontalContent>
            ) : (
                <VerticalContent hideScrollbar={hideScrollbar} className="scroll-content">
                    {children}
                </VerticalContent>
            )}
        </Container>
    )
})

export { Scroll }
