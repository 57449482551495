import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
const Container = styled.div`
    height: 80%;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;

    .empty-list-message {
        background-color: #f5f5f5;
    }
`
const ContainerCounter = styled.div`
    padding: 30px 20px 20px 30px;
    background-color: #f5f5f5;
`

const Row = styled.div`
    display: flex;
    align-items: center;
`

const IconCounter = styled(FontAwesomeIcon)`
    margin-right: 10px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
`

const TitleCounter = styled.div``

const SearchContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 350px;
    margin-top: 10px;
`

const SearchInput = styled.input`
    border: 0;
    outline: none;
    flex: 1;
    background-color: transparent;
`

const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    margin-left: 10px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }
`

export { Container, ContainerCounter, IconCounter, TitleCounter, SearchContainer, SearchInput, SearchIcon, Row }
