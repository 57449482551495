import {
    ChannelImage,
    CustomerAddress,
    CustomerInfo,
    CustomerName,
    CustomerNeighborhood,
    IconContainer,
    OrderItemContainer,
    OrderDetails,
    StoreImage,
    StoreImageContainer,
    OrderValue,
    PaymentMethod,
    TagsContainer,
    ValueNumber,
    PlaceIcon,
    TagText,
    TagItem,
    ClockIcon,
    GreenClockIcon,
    MobileOrderValue,
    Position,
} from './order-item.styles'

const ifood = 'https://static.pelando.com.br/live/merchants/avatar_web_square_94_2x/avatar/889_2.jpg'
const rappi =
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Rappi_backgr_logo.png/480px-Rappi_backgr_logo.png'
const uberEats = 'https://logodownload.org/wp-content/uploads/2019/05/uber-eats-logo-1.png'

function renderChannelImage(orderChannel?: number) {
    if (orderChannel === 1) {
        return ifood
    }
    if (orderChannel === 2) {
        return rappi
    }
    if (orderChannel === 3) {
        return uberEats
    }
}

interface Props {
    storeImage?: string
    channel?: number
    customerName?: string
    customerNeighborhood?: string
    customerStreet?: string
    customerNumber?: number
    orderValue?: number
    payment?: string
    distance?: number
    productionTime?: number | string
    elapsedTime?: number | string
    readyTime?: number
    scheduleTime?: string
    routePosition?: number
}

const OrderItem: React.FC<Props> = ({
    storeImage,
    channel,
    customerName,
    customerNeighborhood,
    customerStreet,
    customerNumber,
    elapsedTime,
    orderValue,
    payment,
    distance,
    productionTime,
    readyTime,
    scheduleTime,
    routePosition,
}) => {
    return (
        <OrderItemContainer>
            <StoreImageContainer
                late={elapsedTime && elapsedTime >= 30 && elapsedTime < 90 ? true : false}
                attention={elapsedTime && elapsedTime < 30 && elapsedTime > 20 ? true : false}
                onTime={elapsedTime && elapsedTime <= 20 ? true : false}
                scheduled={scheduleTime ? true : false}
            >
                <StoreImage src={storeImage} />
                <ChannelImage src={renderChannelImage(channel)} />
            </StoreImageContainer>
            <CustomerInfo>
                <MobileOrderValue>
                    R$ {orderValue} <br />
                    {payment}
                </MobileOrderValue>
                <CustomerName>{customerName}</CustomerName>
                <CustomerNeighborhood>{customerNeighborhood}</CustomerNeighborhood>
                <CustomerAddress>
                    {customerStreet}, {customerNumber}
                </CustomerAddress>
            </CustomerInfo>
            <OrderDetails>
                <OrderValue>
                    <ValueNumber>R$ {orderValue}</ValueNumber>
                    <PaymentMethod>{payment}</PaymentMethod>
                </OrderValue>
                <TagsContainer>
                    {distance && (
                        <TagItem>
                            <IconContainer>
                                <PlaceIcon />
                            </IconContainer>
                            <TagText>{distance}km</TagText>
                        </TagItem>
                    )}
                    {productionTime && (
                        <TagItem>
                            <IconContainer>
                                <ClockIcon />
                            </IconContainer>
                            <TagText>{productionTime}min</TagText>
                        </TagItem>
                    )}
                    {scheduleTime && (
                        <TagItem>
                            <IconContainer>
                                <ClockIcon />
                            </IconContainer>
                            <TagText>{scheduleTime}</TagText>
                        </TagItem>
                    )}
                    {readyTime && (
                        <TagItem>
                            <IconContainer>
                                <GreenClockIcon />
                            </IconContainer>
                            <TagText>{readyTime}min</TagText>
                        </TagItem>
                    )}
                </TagsContainer>
            </OrderDetails>
            {routePosition && <Position hasPosition>{routePosition}</Position>}
        </OrderItemContainer>
    )
}

export default OrderItem
