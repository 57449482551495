import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    IChangePasswordModalRef,
    ManagerIntegratiorLogarooPasswordModal,
} from 'modals/manager-integrator-logaroo-modal/manager-integratior-logaroo-password-modal/manager-integratior-logaroo-password-modal'
import {
    IIntegrationLogarooModalRef,
    ManagerIntegratorContentLogarooModal,
} from 'modals/manager-integrator-logaroo-modal/manager-integrator-logaroo-modal'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalLoading from 'components/modal-loading'
import SelectInput from 'components/select-input'

import IntegratorListingItem from 'containers/integrators-page/components/integrator-listing-item'

import { useUI } from 'contexts'
import { setSearchParams } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IIntegrationLogaroo } from 'types/integration'

import {
    Container,
    ContainerTitle,
    Title,
    ButtonText,
    ContainerFilter,
    FilterItem,
    LoaderContainer,
    FilterButton,
    DeleteMessageContent,
    Text,
} from './integration-logaroo.styled'

type MessageData = {
    title?: string
    message?: string
    onClose?: () => void
}
type Integrators = {
    items?: IIntegrationLogaroo[]
    current_page?: number
    totals?: number
}

const statusFilter = [
    { name: 'Ativo', value: '1' },
    { name: 'Inativo', value: '0' },
]

const IntegrationLogaroo = memo(() => {
    const history = useHistory()
    const location = useLocation()

    const { basePath, mall } = useAuth()
    const { setConfirmationModal, setErrorModal } = useUI()

    const integrationLogarooModalRef = useRef<IIntegrationLogarooModalRef>()
    const changePasswordModalRef = useRef<IChangePasswordModalRef>()

    const initialFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            status: query.get('status') || '',
        }
    }, [location.search])

    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(initialFilter)

    const [integrators, setIntegrators] = useState<Integrators>({
        items: [],
        current_page: 1,
    })
    const [editIntegrator, setEditIntegrator] = useState<IIntegrationLogaroo>()

    const _deleteContent = useCallback(
        (integration: IIntegrationLogaroo) => (
            <DeleteMessageContent>
                <Text>
                    O usuário{' '}
                    <strong>
                        {integration?.first_name} {integration?.last_name}
                    </strong>{' '}
                    da lista de integradores?
                </Text>

                <Text>A integração com as lojas abaixo deixarão de funcionar:</Text>
                <ul>
                    {integration?.stores?.map(store => (
                        <li key={store.id}>{store.name}</li>
                    ))}
                </ul>
            </DeleteMessageContent>
        ),
        []
    )

    const setFilterParam = useCallback(
        (key, value) => {
            setFilter({ ...filter, [key]: value })
        },
        [filter]
    )

    const _getStores = useCallback(async () => {
        setLoading(true)
        try {
            const {
                data: { items },
            } = await api.get('/painel/stores', {
                params: {
                    mall_id: mall.id,
                    order_by: 'name',
                },
            })

            setStores(items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar a lista de lojas.',
                singleButtonClick: () => history.push(`${basePath}/gestao-mall`),
            })
        }

        setLoading(false)
    }, [history, mall, basePath])

    const _handleDelete = useCallback(
        (integration: IIntegrationLogaroo) => {
            return () => {
                const deleteIntegrator = async () => {
                    setLoading(true)
                    try {
                        await api.delete(`/painel/administrator/${integration.id}/`)

                        setIntegrators({
                            ...integrators,
                            items: integrators.items.filter(item => item.id !== integration.id),
                        })

                        _onModalClose()
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro!',
                            subtitle: 'Não foi possível excluir os dados do integrador. Tente novamente mais tarde.',
                        })
                    }
                    setLoading(false)
                }

                if (Number(integration.status) === 1) {
                    setConfirmationModal({
                        title: 'Excluir integração',
                        subtitle: 'Deseja mesmo excluir está integração?',
                        type: 'alert',
                        modalIcon: 'trash-alt',
                        leftButtonText: 'Cancelar',
                        rightButtonText: 'Sim, excluir!',
                        rightButtonClick: deleteIntegrator,
                        children: _deleteContent(integration),
                    })
                } else {
                    setErrorModal({
                        title: 'Atenção',
                        subtitle: 'É necessário definir o status do integrador como Inativo antes exclusão.',
                    })
                }
            }
        },
        [integrators]
    )

    const _getIntegrators = useCallback(
        async ({ current_page = 1, integrators = { items: [] }, filter = initialFilter } = {}) => {
            setLoading(true)
            try {
                const filters = {
                    status: filter.status && !isNaN(filter.status) ? [filter.status] : null,
                }

                const { data } = await api.get('/painel/integrators', {
                    params: {
                        current_page,
                        mall_id: mall.id,
                        ...Object.keys(filters).reduce((acc, key) => {
                            if (filters[key]) {
                                acc[key] = filters[key]
                            }

                            return acc
                        }, {}),
                    },
                })

                setIntegrators({
                    ...data,
                    items: current_page > 1 ? [...integrators.items, ...data.items] : data.items,
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível carregar a lista de integradores.',
                    singleButtonClick: () => history.push(`${basePath}/gestao-mall`),
                })
            }

            setLoading(false)
        },
        [initialFilter, history, mall, basePath]
    )

    const _onModalClose = useCallback(
        (isRefresh?: boolean) => {
            if (isRefresh) {
                _getIntegrators()
            }
            setEditIntegrator(null)
        },
        [_getIntegrators]
    )

    const _openModal = useCallback(() => {
        integrationLogarooModalRef.current?.show()
    }, [])

    const _openChangePasswordModal = useCallback((integration: IIntegrationLogaroo) => {
        return () => {
            setEditIntegrator(integration)
            changePasswordModalRef.current?.show()
        }
    }, [])

    const _editIntegrator = useCallback((integrator: IIntegrationLogaroo) => {
        return () => {
            setEditIntegrator(integrator)
            _openModal()
        }
    }, [])

    useEffect(() => {
        _getIntegrators()
    }, [_getIntegrators])

    useEffect(() => {
        _getStores()
    }, [_getStores])

    return (
        <Container>
            <ContainerTitle>
                <Title>Integradores</Title>
                <ButtonText onClick={_openModal}>
                    Adicionar integrador <FontAwesomeIcon icon="plus-circle" />
                </ButtonText>
            </ContainerTitle>

            <ContainerFilter>
                <FilterItem>
                    <SelectInput
                        placeholder="Todos os status"
                        data={statusFilter}
                        onChange={({ target: { value } }) => setFilterParam('status', value)}
                        value={filter.status || ''}
                    />
                </FilterItem>

                <FilterButton onClick={() => setSearchParams(filter, location, history)}>Filtrar</FilterButton>
            </ContainerFilter>

            <>
                {loading && !integrators.items.length ? (
                    <LoaderContainer className="loader-container"></LoaderContainer>
                ) : integrators.items.length ? (
                    integrators.items.map((item, index) => (
                        <IntegratorListingItem
                            key={index}
                            id={item.id}
                            name={`${item.first_name} ${item.last_name || ''}`}
                            email={item.email}
                            status={item.status}
                            editClick={_editIntegrator(item)}
                            passwordClick={_openChangePasswordModal(item)}
                            deleteClick={_handleDelete(item)}
                        />
                    ))
                ) : (
                    <EmptyMessage icon="user-friends">Não há integradores cadastrados.</EmptyMessage>
                )}

                <ButtonLoadMore
                    loading={loading}
                    visible={integrators.totals > integrators.items.length}
                    onClick={() =>
                        _getIntegrators({
                            current_page: ++integrators.current_page,
                            integrators,
                            filter,
                        })
                    }
                />
            </>

            <ManagerIntegratorContentLogarooModal
                ref={integrationLogarooModalRef}
                integration={editIntegrator}
                stores={stores}
                closeClick={_onModalClose}
                onRemove={editIntegrator && _handleDelete(editIntegrator)}
            />

            <ManagerIntegratiorLogarooPasswordModal
                ref={changePasswordModalRef}
                integration={editIntegrator}
                onClose={_onModalClose}
            />

            <ModalLoading visible={loading && integrators.items.length} />
        </Container>
    )
})

export { IntegrationLogaroo }
