import Spinner from 'react-spinner-material'

import colors from 'themes/colors'

import './style.scss'

const ButtonForm = props => {
    return (
        <button
            onClick={props.onClick}
            className={props.secondary ? 'button-form secondary' : 'button-form'}
            style={props.style}
            type={props.type}
        >
            {props.loading ? (
                <div className="button-spinner">
                    <Spinner
                        size={15}
                        color={props.secondary ? colors.light_primary_color : 'white'}
                        stroke={2}
                        radius={20}
                        visible={true}
                    />
                </div>
            ) : (
                props.buttonText
            )}
        </button>
    )
}

export default ButtonForm
