import { useState, useEffect, useRef, useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonLoadMore from '../../components/button-load-more/button-load-more'
import DelivererFilter from '../../components/deliverer-filter/deliverer-filter'
import ModalChangePassword from '../../components/modal-change-password'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { getBikerType } from '../../helpers'
import api from '../../services/api'

import './style.scss'
import { format } from 'date-fns'

import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

import { ModalChangeAgentModality } from './modal-change-agent-modality/modal-change-agent-modality'

const DeliverersModeration = ({ history, match }) => {
    const modalChangePassword = useRef()
    const modalMessage = useRef()
    const modalChangeModalityRef = useRef()

    const [loading, setLoading] = useState(false)
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [headerTitle, setHeaderTitle] = useState('')
    const [headerAmount, setHeaderAmount] = useState('0')
    const [headerAmountLabel, setHeaderAmountLabel] = useState('')
    const [headerAmountIcon, setHeaderAmountIcon] = useState('')
    const [passwordValues, setPasswordValues] = useState({})
    const [passwordError, setPasswordError] = useState('')
    const [passwordConfirmationError, setPasswordConfirmationError] = useState('')
    const [filter, setFilter] = useState({})
    const [bikers, setBikers] = useState({ items: [] })
    const [userEdit, setUserEdit] = useState({})

    useEffect(() => {
        modalMessageData.title && modalMessage.current.openModal()
    }, [modalMessageData])

    const _openModalChangeModality = useCallback(agent => {
        return () => modalChangeModalityRef.current?.show(agent)
    }, [])

    const getBikers = useCallback(
        async ({ page = 1, bikers = { items: [] }, filter = {} } = {}) => {
            try {
                setLoading(true)

                const filters = {
                    ...filter,
                    cellphone: filter.cellphone && filter.cellphone.replace(/\D+/g, ''),
                    cpf: filter.cpf && filter.cpf.replace(/\D+/g, ''),
                    cnpj: filter.cnpj && filter.cnpj.replace(/\D+/g, ''),
                }

                const { data } = await api.get(`/painel/agents`, {
                    params: {
                        current_page: page,
                        order: 'desc',
                        ...filters,
                    },
                })

                setBikers({
                    ...data,
                    items: page === 1 ? data.items : [...bikers.items, ...data.items],
                })

                const params = new URLSearchParams()
                Object.keys(filters).forEach(key => {
                    filters[key] && params.set(key, filters[key])
                })

                history.push(`?${params.toString()}`)
            } catch (err) {
                console.log('getBikers:', err)

                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar as informações de entregadores.',
                    onClose: () => history.push('/gestao-mall'),
                })
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    const getScoreboard = useCallback(async statusName => {
        try {
            const response = await api.get('/painel/agents/scoreboard')

            let amount = 0

            switch (statusName) {
                case 'aprovados':
                    amount = response.approved
                    break
                case 'reprovados':
                    amount = response.disapproved
                    break
                case 'bloqueados':
                    amount = response.blocked
                    break
                default:
                    amount = response.in_moderation
            }

            setHeaderAmount(amount)
        } catch (err) {
            console.log('getScoreboard:', err)
        }
    }, [])

    const onChangeFilterValue = (key, value) => {
        setFilter({ ...filter, [key]: value })
    }

    const initScreen = useCallback(
        statusName => {
            let status = ''

            switch (statusName) {
                case 'aprovados':
                    status = 1
                    setHeaderTitle('Entregadores aprovados')
                    setHeaderAmountIcon('thumbs-up')
                    break
                case 'pendentes':
                    status = 2
                    setHeaderTitle('Cadastros em andamento')
                    setHeaderAmountIcon('list-alt')
                    break
                case 'reprovados':
                    status = 3
                    setHeaderTitle('Entregadores reprovados')
                    setHeaderAmountIcon('thumbs-down')
                    break
                case 'bloqueados':
                    status = 0
                    setHeaderTitle('Entregadores bloqueados')
                    setHeaderAmountIcon('ban')
                    break
                default:
                    status = 4
                    setHeaderTitle('Moderação de entregadores')
                    setHeaderAmountIcon('clock')
                    setHeaderAmountLabel('Aguardando Moderação')
            }

            setFilter({ status })

            getBikers({ filter: { status } })
            getScoreboard(statusName)
        },
        [getBikers, getScoreboard]
    )

    useEffect(() => {
        initScreen(match.params.status)
    }, [initScreen, match.params.status])

    const onChangePasswordInput = (key, value) => {
        let passObj = passwordValues
        passObj[key] = value
        setPasswordValues(passObj)
    }

    const onChangePassword = async () => {
        try {
            const { password, password_confirmation } = passwordValues

            setPasswordError('')

            setPasswordConfirmationError('')

            let err = false

            if (!password || password.length < 8) {
                err = true
                setPasswordError('A senha deve ter ao menos 8 caracteres.')
            }
            if (password_confirmation !== password) {
                err = true
                setPasswordConfirmationError('As senhas não correspondem.')
            }

            if (err) return

            setLoading(true)

            modalChangePassword.current.closeModal()

            await api.put(`/painel/agent/${userEdit.id}/update-password`, {
                password,
                password_confirmation,
            })

            setModalMessageData({
                title: 'Senha alterada com sucesso',
                message: `A senha de ${userEdit.first_name} ${userEdit.last_name} foi alterada com sucesso.`,
                textButton: 'Ok',
            })
        } catch (err) {
            console.log('onChangePassword:', err)

            setModalMessageData({
                title: 'Erro',
                message: 'Houve um problema ao alterar sua senha.',
                onClose: () => history.push('/gestao-mall'),
            })
        } finally {
            setLoading(false)
        }
    }

    const getStatus = value => {
        return {
            0: 'Bloqueado',
            1: 'Aprovado',
            2: 'Aguardando',
            3: 'Reprovado',
            4: 'Em Moderação',
        }[value || '']
    }

    const _refreshAgents = useCallback(() => {
        getBikers({ filter })
    }, [filter, getBikers])

    return (
        <div className="deliverers-moderation-page">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalChangePassword
                ref={modalChangePassword}
                user={userEdit}
                setUserData={onChangePasswordInput}
                title="Alterar senha do entregador"
                subtitle={
                    userEdit?.id ? `Você está alterando a senha de: ${userEdit.first_name} ${userEdit.last_name}` : ''
                }
                onYes={onChangePassword}
                onNo={() => modalChangePassword.current.closeModal()}
                errors={{
                    password: passwordError,
                    password_confirmation: passwordConfirmationError,
                }}
            />

            <ModalChangeAgentModality ref={modalChangeModalityRef} onRefresh={_refreshAgents} />

            <TopRowDefault
                title={headerTitle}
                bikersLabel={headerAmountLabel || headerTitle}
                bikersAmount={headerAmount}
                bikersIcon={headerAmountIcon}
            />

            <div className="deliverers-moderation-page-content">
                <DelivererFilter
                    onFilterClick={() => getBikers({ filter })}
                    onChange={onChangeFilterValue}
                    filter={filter}
                />

                <div className="deliverer-table">
                    <div className="deliverer-table-row">
                        <div className="table-item id">
                            ID <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item deliverer">
                            Entregador <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item state">
                            Estado <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item type">
                            Tipo <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item status">
                            Status <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item status">
                            Localização <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item createdAt">
                            Cadastrado Em <FontAwesomeIcon icon="sort" />
                        </div>
                        <div className="table-item buttons"></div>
                    </div>
                    {bikers?.items?.map((item, index) => (
                        <div className="deliverer-table-row" key={index}>
                            <div className="table-item id">{item?.id}</div>
                            <div className="table-item deliverer">
                                <div className="deliverer-name">{`${item?.first_name} ${item?.last_name || ''}`}</div>
                                <div className="deliverer-mail">{item?.email}</div>

                                <div className="deliverer-mail">{formatTypeName(item?.type_name)}</div>
                            </div>
                            <div className="table-item state">{item?.state}</div>
                            <div className="table-item type">
                                {getBikerType(item?.type_name)}
                                {filter.status === 1 && (
                                    <LogarooIcon icon="edit" onClick={_openModalChangeModality(item)} />
                                )}
                            </div>
                            <div className="table-item status">{getStatus(item.status?.toString())}</div>
                            <div className="table-item deliverer">
                                <div className="deliverer-name">{item?.city}</div>
                                <div className="deliverer-mail">{item?.state}</div>
                            </div>
                            <div className="table-item createdAt">
                                {item.created_at ? format(new Date(item.created_at), 'DD/MM/YYYY - HH:mm') : ''}
                            </div>
                            <div className="table-item buttons">
                                <div
                                    className="edit-deliverer-button-item"
                                    onClick={() => history.push(`/informacoes-entregador/${item?.id}`)}
                                >
                                    <div className="icon">
                                        <FontAwesomeIcon icon="exclamation-circle" />
                                    </div>
                                    <div className="text">Ver detalhes</div>
                                </div>
                                <div
                                    className="edit-deliverer-button-item"
                                    onClick={() => {
                                        setPasswordValues({})
                                        setPasswordError('')
                                        setPasswordConfirmationError('')
                                        setUserEdit(item)
                                        modalChangePassword.current.openModal()
                                    }}
                                >
                                    <div className="icon">
                                        <FontAwesomeIcon icon="lock" />
                                    </div>
                                    <div className="text">Alterar senha</div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <ButtonLoadMore
                        visible={bikers.has_more}
                        onClick={() => getBikers({ page: ++bikers.current_page, bikers, filter })}
                    />
                </div>
            </div>
        </div>
    )
}

function formatTypeName(type_name) {
    switch (type_name) {
        case 'bike':
            return 'BICICLETA'
        case 'car':
            return 'CARRO'
        case 'moto':
            return 'MOTO'
        case 'truck':
            return 'CAMINHÃO'
        case 'van':
            return 'VAN'

        default:
            return ''
    }
}

export default DeliverersModeration
