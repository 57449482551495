import { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import EmptyMessage from 'components/empty-message/empty-message'
import ModalConfirmation from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage from 'components/modal-message'
import SelectInput from 'components/select-input'
import StoresListingTableHeader from 'components/stores-listing-table-header/stores-listing-table-header'
import StoresListingTableRow from 'components/stores-listing-table-row/stores-listing-table-row'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { setSearchParams } from 'helpers'
import './stores-listing.scss'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'

import { ContainerFilters, ContainerTotals, FilterButon } from './stores-listing.styled'

const StoresListing = ({ history, location }) => {
    const { basePath, mall } = useAuth()

    const modalMessage = useRef(null)
    const modalDelete = useRef(null)

    const initialFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            is_satelite: query.get('is_satelite') || 'all',
            status: query.get('status') || '1',
        }
    }, [location.search])

    const [loading, setLoading] = useState(false)
    const [stores, setStores] = useState([])
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [deleteID, setDeleteID] = useState(null)
    const [filter, setFilter] = useState(initialFilter)
    const [params, setParams] = useState({
        // is_satelite: '0',
        status: ['1'],
    })
    const [totals, setTotals] = useState(0)

    const getStores = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/stores', {
                params: {
                    mall_id: mall.id,
                    per_page: -1,
                    working: [0, 1],
                    order_by: 'id',
                    ...params,
                },
            })
            setTotals(data.totals)
            setStores(data.items)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [params, mall.id])

    const _setFilterParam = useCallback(
        (key, value) => {
            setFilter({ ...filter, [key]: value })
        },
        [filter]
    )

    const _applyFilter = useCallback(() => {
        const params = {}

        if (filter.is_satelite && filter.is_satelite !== 'all') {
            params.is_satelite = filter.is_satelite
        }

        if (filter.status && filter.status !== 'all') {
            params.status = [filter.status]
        } else {
            params.status = [0, 1]
        }
        setSearchParams(filter, location, history)
        setParams(params)
    }, [filter, location, history])

    useEffect(() => {
        getStores()
    }, [getStores])

    const deleteRate = async () => {
        try {
            setLoading(true)

            await api.delete(`/painel/store/${deleteID}`)

            getStores()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="dollar-sign">Esta unidade não possui lojas cadastradas.</EmptyMessage>
    }

    const getStatusStore = status => {
        return {
            0: 'Inativo',
            1: 'Ativo',
            2: 'Bloqueado',
            3: 'Pendente',
        }[status?.toString() || '']
    }

    return (
        <div className="stores-listing-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Loja"
                message="Deseja realmente excluir esta loja?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Lojas"
                buttons={[
                    {
                        title: 'Criar loja',
                        onClick: () => history.push(`${basePath}/loja-criacao`),
                    },
                ]}
            ></TopRowDefault>
            <ContainerFilters>
                <SelectInput
                    noPlaceholder
                    data={[
                        { name: 'Todas', value: 'all' },
                        { name: 'Base', value: '0' },
                        { name: 'Satélite', value: '1' },
                    ]}
                    onChange={({ target: { value } }) => _setFilterParam('is_satelite', value)}
                    value={filter.is_satelite || ''}
                />
                <SelectInput
                    noPlaceholder
                    data={[
                        { name: 'Todas', value: 'all' },
                        { name: 'Ativo', value: '1' },
                        { name: 'Inativo', value: '0' },
                    ]}
                    onChange={({ target: { value } }) => _setFilterParam('status', value)}
                    value={filter.status || ''}
                />
                <FilterButon onClick={_applyFilter}>Filtrar</FilterButon>
            </ContainerFilters>

            <ContainerTotals>
                Sua busca retornou <span>{totals}</span> {totals > 1 ? 'resultados' : 'resultado'}
            </ContainerTotals>
            <StoresListingTableHeader />

            {stores.map((item, index) => (
                <StoresListingTableRow
                    key={index}
                    id={item.id}
                    name={item.name}
                    type={item.store_category.store_type.name}
                    category={item.store_category.name}
                    status={getStatusStore(item.status)}
                    editClick={() => history.push(`${basePath}/loja/${item.id}`)}
                    deleteClick={() => {
                        setDeleteID(item.id)

                        modalDelete.current.openModal()
                    }}
                />
            ))}

            {!stores.length && !loading && renderEmptyMessage()}
        </div>
    )
}

export default StoresListing
