import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

interface ModalProps {
    variant?: 'success' | 'errorMessage' | 'yellow' | 'lightGray'
}

const Container = styled.div<{ visible?: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.3);
    animation-name: show;
    animation-duration: 200ms;
`

const Box = styled.div<{ show?: boolean }>`
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 340px;
    max-height: 98%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: -1px 3px 14px 0px rgba(0, 0, 0, 0.3);

    transition: all 200ms ease-out;

    @media screen and (max-width: ${({ theme }) => theme.devices.mobile}) {
        width: 95%;
        height: 95%;
    }

    .title {
        font-weight: 600;
        user-select: none;
    }
    .icon {
        cursor: pointer;
        user-select: none;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 20px;
    }

    ${({ show }) =>
        show
            ? css`
                  animation-name: show;
                  animation-duration: 400ms;
              `
            : css`
                  animation-name: close;
                  animation-duration: 450ms;
              `}
`

const Icon = styled(FontAwesomeIcon)<ModalProps>`
    color: ${({ theme, variant }) => (variant ? theme.colors[variant] : theme.colors.primary)};
    font-size: 30px;
    margin-bottom: 10px;
    align-self: center;
`

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
`

const Message = styled.div`
    color: #2f2f2f;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
`

const Footer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
`

const ContainerButtons = styled.div`
    flex: 1;
`

const Separator = styled.div`
    width: 5px;
    pointer-events: none;
`

const Button = styled.div<{ isOutline?: boolean; disabled?: boolean }>`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme, isOutline }) => (isOutline ? theme.colors.white : theme.colors.primary)};
    color: ${({ theme, isOutline }) => (isOutline ? theme.colors.primary : theme.colors.white)};
    padding: 10px 0px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    text-align: center;
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`

const ContainerSelect = styled.div`
    margin-top: 20px;
`

export { Box, Container, ContainerButtons, Footer, Icon, Message, Separator, Title, Button, ContainerSelect }
