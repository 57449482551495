import styled from 'styled-components'

import { MapLayoutProps } from './global-map.types'

const Container = styled.div<MapLayoutProps>`
    position: absolute;
    width: ${({ width }) => (width ? width : 0)}px;
    height: ${({ height }) => (height ? height : 0)}px;
    top: ${({ top }) => (top ? top : 0)}px;
    left: ${({ left }) => (left ? left : 0)}px;
    display: ${({ display }) => (display ? display : 'none')};
    z-index: 1;
`

const ContainerMirror = styled.div`
    min-height: 75vh;
    height: 100%;
    pointer-events: none;
`

export { Container, ContainerMirror }
