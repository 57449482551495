import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { SalesDetailModal } from 'modals'
import { ModalOrderCancel, ModalOrderCancelRef } from 'modals/modal-order-cancel/modal-order-cancel'
import { ChangeOrderObservable } from 'observables'

import integration from 'assets/images/bg-integracoes.png'
import storeDelivered from 'assets/images/store-delivered.png'
import storeInProduction from 'assets/images/store-in-production.png'
import storeInRoute from 'assets/images/store-in-route.png'
import storeScheduled from 'assets/images/store-scheduled.png'
import storeToCollect from 'assets/images/store-to-collect.png'

import { ModalConfirmation, useUI } from 'contexts'
import { IntegrationsContext, IntegrationsContextProvider } from 'contexts/integration-context'
import { useAuth } from 'hooks'
import api, { plugApi } from 'services/api'
import { OrdersInProgressState } from 'store/reducers/types'
import { Order } from 'types'
import { IIntegration } from 'types/integration'
import { IOrderIntegrationList } from 'types/order-integration-list'

import GroupedProgressCard from '../grouped-progress-card/grouped-progress-card'
import { OrderIntegrationsDetailsModal } from '../order-integrations-details-modal/order-integrations-details-modal'
import OrderSummary from '../order-summary'
import ProgressCard from '../progress-card/progress-card'
import { ProgressIntegration } from '../progress-integration/progress-integration'
import RescheduleModal from '../reschedule-modal'
import { SettingsIntegratorModal } from '../settings-integrator-modal/settings-integrator-modal'

import {
    CardsContainer,
    InProgressOrders,
    Status,
    StatusBall,
    Logo,
    ContainerIntegrationInfo,
    StoreName,
    StoreMessage,
    ContainerItemIntegration,
} from './orders-in-progress.styles'

interface Props {
    ordersInProgress: OrdersInProgressState
    ordersIntegration?: IOrderIntegrationList[]
    ordersIntegrationLoading?: boolean
}

const OrdersInProgress: React.FC<Props> = ({ ordersInProgress, ordersIntegration, ordersIntegrationLoading }) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { store } = useAuth()
    const { setConfirmationModal, setErrorModal, setLoading, setSnackbar } = useUI()

    const modalOrderCancelRef = useRef<ModalOrderCancelRef>(null)
    const salesDetailModalRef = useRef<SalesDetailModal>(null)

    const [dropdownOrderIdActive, setDropdownOrderIdActive] = useState<number>()
    const [orderIntegrationDetailsId, setIntegrationOrderDetailsId] = useState<number>()
    const [rescheduleOrderId, setRescheduleOrderId] = useState<number>()
    const [hasIntegration, setHasIntegration] = useState<boolean>()

    const { scheduled, inProduction, toCollect, awaitingCollect, inRoute, delivered } = ordersInProgress.orders

    const { location } = history

    const _checkIntegration = useCallback(async () => {
        if (!store) {
            setHasIntegration(false)
            return
        }
        try {
            setLoading(true)

            const { data } = await plugApi.get<{ active: number; inactive: number }>(
                `/store/${store.id}/has-integrations`
            )

            setHasIntegration(data.active > 0)

            if (store.marketplace_enable) {
                setHasIntegration(true)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [store, setLoading])

    useEffect(() => {
        _checkIntegration()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store])

    useEffect(() => {
        if (!location?.search) {
            setIntegrationOrderDetailsId(undefined)
            return
        }

        const query = new URLSearchParams(location.search)

        const orderIntegrationDetailsId = query.get('order-integration')
        const orderMercadooId = query.get('order-mercadoo-integration')
        if (orderMercadooId) {
            salesDetailModalRef.current.showById(Number(orderMercadooId))
        }
        setIntegrationOrderDetailsId(Number(orderIntegrationDetailsId))
    }, [location])

    const changesOrderStatus = useCallback(
        async (order: Order, status: number, successMessage: string, reason?: string) => {
            try {
                setLoading(true)

                switch (status) {
                    case 0:
                        await api.put(`/painel/dm-order/${order.id}/cancel`, { reason })
                        break
                    case 3:
                        await api.put(`/painel/dm-order/${order.id}/in_production`)
                        break
                    case 4:
                        await api.put(`/painel/dm-order/${order.id}/ready_to_delivery`)
                        break
                    default:
                        await api.put(`/painel/dm-order/${order.id}`, { status })
                        break
                }

                setSnackbar({ message: successMessage })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Houve um problema ao alterar o status do pedido',
                })
            } finally {
                setLoading(false)
            }
        },
        [setErrorModal, setLoading, setSnackbar]
    )

    const onConfirmOrder = useCallback(
        (order: Order) => {
            const commonProps = {
                type: 'alert',
                modalIcon: 'check-circle',
                leftButtonText: 'Fechar',
                children: <OrderSummary order={order} />,
            } as ModalConfirmation

            if (order.status === '1') {
                setConfirmationModal({
                    ...commonProps,
                    title: 'Confirmar pedido?',
                    subtitle: 'O pedido irá para pedidos Em produção.',

                    rightButtonText: 'Confirmar',
                    rightButtonClick: () => changesOrderStatus(order, 3, 'Novo pedido em produção'),
                })
            }

            if (order.status === '3') {
                setConfirmationModal({
                    ...commonProps,
                    title: 'Disponibilizar pedido p/ coleta?',
                    subtitle: 'O pedido ficará disponível para entrega.',
                    rightButtonText: 'Disponibilizar',
                    rightButtonClick: () => changesOrderStatus(order, 4, 'Pedido pronto para coleta'),
                })
            }
        },
        [changesOrderStatus, setConfirmationModal]
    )

    function onMoveOrder(order: Order) {
        const commonProps = {
            type: 'alert',
            title: 'Mover o pedido?',
            modalIcon: 'arrow-circle-left',
            leftButtonText: 'Fechar',
            rightButtonText: 'Mover',
            children: <OrderSummary order={order} />,
        } as ModalConfirmation

        if (order.status === '4') {
            setConfirmationModal({
                ...commonProps,
                subtitle: 'O pedido não está mais disponível para coleta e deverá ser enviado novamente.',
                rightButtonClick: () => changesOrderStatus(order, 3, 'Pedido movido com sucesso'),
            })
        }
    }

    function onToTakeout(order: Order) {
        const callback = async () => {
            setLoading(true)
            try {
                await api.put(`/painel/dm-order/${order.id}/to_takeout`)
                setSnackbar({ message: 'Pedido retirado!' })
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        }
        setConfirmationModal({
            type: 'alert',
            title: 'Retirada em Balcão?',
            subtitle: 'Deseja alterar o tipo de pedido de delivery para retirada em balcão?',
            modalIcon: 'hand-holding-medical',
            leftButtonText: 'Cancelar',
            rightButtonText: 'Alterar',
            children: <OrderSummary order={order} />,
            rightButtonClick: callback,
        })
    }
    function onDelivered(order: Order) {
        const callback = async () => {
            setLoading(true)
            try {
                await api.put(`/painel/dm-order/${order.id}/hand_delivery`)
                setSnackbar({ message: 'Pedido entregue!' })
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        }
        setConfirmationModal({
            type: 'alert',
            title: 'Pedido entregue',
            subtitle: 'Deseja alterar para pedido entregue?',
            modalIcon: 'handshake',
            leftButtonText: 'Fechar',
            rightButtonText: 'Entregue',
            children: <OrderSummary order={order} />,
            rightButtonClick: callback,
        })
    }

    const onCancelOrder = useCallback(
        (order: Order) => {
            modalOrderCancelRef.current?.show({
                order,
                onSelectReason: reason => {
                    changesOrderStatus(order, 0, 'Pedido cancelado', reason)
                },
            })
        },
        [changesOrderStatus]
    )

    function onRescheduleOrder(order: Order) {
        setRescheduleOrderId(order.id)
    }

    const onClickOrder = useCallback(
        (order: Order) => {
            history.push(`?modal-order-id=${order.id}`)
        },
        [history]
    )

    const _onCloseModal = useCallback(() => {
        history.goBack()
    }, [history])

    useEffect(() => {
        const sub = ChangeOrderObservable.subscribe(({ order, type }) => {
            if (type === 'schedule' && order) {
                onRescheduleOrder(order)
            }
        })

        return () => {
            if (sub) {
                sub.unsubscribe()
            }
        }
    }, [])

    return (
        <IntegrationsContextProvider>
            <InProgressOrders>
                <ModalOrderCancel ref={modalOrderCancelRef} />
                <SalesDetailModal ref={salesDetailModalRef} />
                {!!orderIntegrationDetailsId && (
                    <OrderIntegrationsDetailsModal
                        isActive={!!orderIntegrationDetailsId}
                        orderDetailsId={orderIntegrationDetailsId}
                        closeClick={() => history.push('em-andamento')}
                    />
                )}

                {rescheduleOrderId && (
                    <RescheduleModal
                        isActive={!!rescheduleOrderId}
                        rescheduleOrderId={rescheduleOrderId}
                        closeClick={() => {
                            setRescheduleOrderId(undefined)
                            history.goBack()
                        }}
                    />
                )}

                <CardsContainer>
                    <SettingsIntegratorModal
                        isActive={pathname.includes('integracao')}
                        closeClick={_onCloseModal}
                        refresh={_checkIntegration}
                    />
                    {hasIntegration && (
                        <ProgressIntegration
                            cardTitle="Integrações"
                            loading={ordersIntegrationLoading}
                            amount={ordersIntegration.length}
                            ordersIntegrations={ordersIntegration}
                            dropdownOrderIdActive={dropdownOrderIdActive}
                            setDropdownOrderIdActive={setDropdownOrderIdActive}
                            onRescheduleOrder={onRescheduleOrder}
                            onCancelOrder={onCancelOrder}
                            onConfirmOrder={onConfirmOrder}
                            onClickOrder={(order: Order) => onClickOrder(order)}
                            emptyText="Aqui você visualizará os pedidos do integrador para aceitar ou negar."
                            emptyIcon={integration}
                            header={<IntegrationInfo />}
                        />
                    )}
                    {(scheduled?.items || []).length > 0 && (
                        <GroupedProgressCard
                            cardTitle="Agendados"
                            loading={scheduled.loading}
                            amount={(scheduled?.items || []).length}
                            orders={[
                                ...scheduled?.items.filter(order => !order.delivery_forecast),
                                ...scheduled?.items.filter(order => order.delivery_forecast),
                            ]}
                            dropdownOrderIdActive={dropdownOrderIdActive}
                            setDropdownOrderIdActive={setDropdownOrderIdActive}
                            onRescheduleOrder={onRescheduleOrder}
                            onCancelOrder={onCancelOrder}
                            onConfirmOrder={onConfirmOrder}
                            onClickOrder={(order: Order) => onClickOrder(order)}
                            emptyText="Aqui você poderá visualizar os pedidos agendados e confirmar seu início de produção."
                            emptyIcon={storeScheduled}
                        />
                    )}
                    <ProgressCard
                        cardTitle="Em produção"
                        type="in-production"
                        loading={inProduction.loading}
                        amount={(inProduction?.items || []).length}
                        orders={inProduction?.items}
                        dropdownOrderIdActive={dropdownOrderIdActive}
                        setDropdownOrderIdActive={setDropdownOrderIdActive}
                        onRescheduleOrder={onRescheduleOrder}
                        onConfirmOrder={onConfirmOrder}
                        onToTakeout={onToTakeout}
                        onCancelOrder={onCancelOrder}
                        onClickOrder={(order: Order) => onClickOrder(order)}
                        emptyText="Aqui você poderá ver os pedidos em produção e confirmar que estão prontos para coleta."
                        emptyIcon={storeInProduction}
                    />
                    <ProgressCard
                        cardTitle="Prontos para coleta"
                        type="ready-for-collect"
                        loading={toCollect?.loading}
                        amount={(toCollect?.items || []).length}
                        orders={toCollect?.items}
                        dropdownOrderIdActive={dropdownOrderIdActive}
                        setDropdownOrderIdActive={setDropdownOrderIdActive}
                        onMoveOrder={onMoveOrder}
                        onToTakeout={onToTakeout}
                        onDelivered={onDelivered}
                        onCancelOrder={onCancelOrder}
                        onClickOrder={(order: Order) => onClickOrder(order)}
                        emptyText="Aqui ficarão os pedidos prontos que aguardam coleta."
                        emptyIcon={storeToCollect}
                    />
                    <ProgressCard
                        cardTitle="Aguardando coleta"
                        type="awaiting-collect"
                        loading={awaitingCollect?.loading}
                        amount={(awaitingCollect?.items || []).length}
                        orders={awaitingCollect?.items}
                        dropdownOrderIdActive={dropdownOrderIdActive}
                        setDropdownOrderIdActive={setDropdownOrderIdActive}
                        onCancelOrder={onCancelOrder}
                        onClickOrder={(order: Order) => onClickOrder(order)}
                        emptyText="Aqui ficarão os pedidos roteirizados que ainda não foram coletados."
                        emptyIcon={storeToCollect}
                        hasBikerInfo={true}
                    />
                    <ProgressCard
                        cardTitle="Em Rota"
                        type="on-route"
                        loading={inRoute?.loading}
                        amount={(inRoute?.items || []).length}
                        orders={inRoute?.items}
                        onCancelOrder={onCancelOrder}
                        dropdownOrderIdActive={dropdownOrderIdActive}
                        setDropdownOrderIdActive={setDropdownOrderIdActive}
                        onClickOrder={(order: Order) => onClickOrder(order)}
                        emptyText="Aqui você poderá acompanhar os pedidos que estão em rota."
                        emptyIcon={storeInRoute}
                    />
                    <ProgressCard
                        cardTitle="Entregues hoje"
                        type="delivered-today"
                        loading={delivered?.loading}
                        amount={(delivered?.items || []).length}
                        orders={delivered?.items}
                        dropdownOrderIdActive={dropdownOrderIdActive}
                        setDropdownOrderIdActive={setDropdownOrderIdActive}
                        onClickOrder={(order: Order) => onClickOrder(order)}
                        emptyText="Aqui você poderá conferir os pedidos do dia que foram entregues"
                        emptyIcon={storeDelivered}
                    />
                </CardsContainer>
            </InProgressOrders>
        </IntegrationsContextProvider>
    )
}

const IntegrationInfo = memo(() => {
    const { integrators } = useContext(IntegrationsContext)

    const list = useMemo(() => {
        const myIntegrations: IIntegration[] = []

        integrators.forEach(e => {
            e.integration.forEach(item => myIntegrations.push({ ...item }))
        })
        return myIntegrations
    }, [integrators])

    return (
        <>
            {list.map((item, i) => (
                <ContainerItemIntegration key={i}>
                    <Logo src={item.extra?.logo} />
                    <ContainerIntegrationInfo>
                        <StoreName> {item.origin.name}</StoreName>
                        <Status active={!!item.extra?.store_status} pause={!!item.extra?.pause}>
                            {item.extra?.pause ? 'Pausada' : item.extra?.store_status ? 'Aberta' : 'Fechada'}
                        </Status>
                        {item.extra?.pause?.message?.subtitle && (
                            <StoreMessage>{item.extra.pause.message.subtitle}</StoreMessage>
                        )}
                        {item.extra?.pause?.message?.description && (
                            <StoreMessage>{item.extra.pause.message.description}</StoreMessage>
                        )}
                    </ContainerIntegrationInfo>
                    <StatusBall
                        active={!!item.extra?.store_status}
                        pause={!!item.extra?.pause}
                        data-for="store-status-tooltip"
                        data-tip={
                            item.extra?.pause
                                ? 'Loja Pausada'
                                : item.extra?.store_status
                                ? 'Loja Aberta'
                                : 'Loja Fechada'
                        }
                    />
                    <ReactTooltip id="store-status-tooltip" />
                </ContainerItemIntegration>
            ))}
        </>
    )
})

export default OrdersInProgress
