import React, { memo, useState, useCallback, forwardRef, useImperativeHandle } from 'react'
import { Popup } from 'react-mapbox-gl'

import { format } from 'date-fns'

import { formatAddress } from 'helpers'
import { PopupData } from 'types/map-line'

import {
    ButtonText,
    Circle,
    ContainerClose,
    ContainerPopup,
    ContainerStatus,
    ContainerTopInfo,
    FooterPopup,
    Icon,
    Line,
    PopupInfo,
    PopupTopInfo,
    Row,
    StoreName,
} from './map-line-popup.slyted'

type Props = {
    ref: React.MutableRefObject<MapLinePopupRef>
}

export type MapLinePopupRef = {
    show: boolean
    openPopup: (data: PopupData) => void
}

const MapLinePopup: React.FC<Props> = memo(
    forwardRef<MapLinePopupRef>((props, ref) => {
        const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
        const [item, setItem] = useState<PopupData>()

        const _togglePopup = useCallback(() => {
            setIsOpenPopup(!isOpenPopup)
        }, [isOpenPopup])

        useImperativeHandle(ref, () => ({
            show: isOpenPopup,
            openPopup: (data: PopupData) => {
                setItem(data)
                setIsOpenPopup(true)
            },
        }))

        if (!isOpenPopup || !item) {
            return null
        }

        return (
            <Popup coordinates={item.lngLat} offset={[0, -10]}>
                <ContainerPopup>
                    <ContainerTopInfo>
                        <Row>
                            <PopupTopInfo># {item.data?.current_order}</PopupTopInfo>
                            <ContainerStatus>
                                <Circle color={item.color} />
                                <PopupTopInfo uppercase>Pedido: {item.data?.label}</PopupTopInfo>
                            </ContainerStatus>
                        </Row>
                        <ContainerClose onClick={_togglePopup}>
                            <Icon icon="times" />
                        </ContainerClose>
                    </ContainerTopInfo>
                    <StoreName>{item.data?.order?.merchant.name}</StoreName>
                    <PopupInfo>{item.data?.order?.customer?.name}</PopupInfo>
                    <PopupInfo> {formatAddress(item.data?.order?.address)}</PopupInfo>

                    <FooterPopup>
                        <Line />
                        <ButtonText>Detalhes da rota</ButtonText>
                        <Line />
                    </FooterPopup>

                    <PopupInfo>
                        Início da entrega:{` `}
                        {format(new Date(item.data?.order?.start_delivery), 'DD/MM/YY H:mm:ss')}
                    </PopupInfo>
                    <PopupInfo>
                        Final da entrega:{` `}
                        {format(new Date(item.data?.order?.end_delivery), 'DD/MM/YY H:mm:ss')}
                    </PopupInfo>
                    <PopupInfo>
                        Registro de ponto:{` `}
                        {format(new Date(item.data?.created_at), 'DD/MM/YY H:mm:ss')}
                    </PopupInfo>
                </ContainerPopup>
            </Popup>
        )
    })
)

export { MapLinePopup }
