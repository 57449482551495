import { SocketIoChannel } from 'laravel-echo/dist/channel'
import { take, spawn, ForkEffect, TakeEffect } from 'redux-saga/effects'

import { echoConnection } from 'services/api'
import {
    PendingOrdersActions,
    PendingOrdersTypes,
    SetWebSocketConnectionAction,
    WebSocketConnection,
} from 'store/reducers/pendingOrders'
import { Mall, PendingOrder, Store } from 'types'

import { listenToEchoChannel } from './helpers'

export function* watchPendingOrders(): Generator<
    TakeEffect | ForkEffect<void>,
    void,
    {
        connection: WebSocketConnection
        mall: Mall
        store: Store
    }
> {
    const echo = echoConnection()

    while (true) {
        const action = yield take<SetWebSocketConnectionAction>(PendingOrdersTypes.SET_WEB_SOCKET_CONNECTION)
        const { connection, store } = action

        const channelName = store.id ? `moderation.store.${store.id}` : null

        if (connection === 'connected' && channelName) {
            const socketIoChannel = echo.private(channelName) as SocketIoChannel

            const { addOrder, updateOrder, removeOrder } = PendingOrdersActions

            yield spawn(listenToEchoChannel, {
                socketIoChannel,
                events: [
                    { name: '.new', action: order => addOrder(order as PendingOrder) },
                    {
                        name: '.updated',
                        action: order =>
                            typeof (order as PendingOrder).status === 'boolean'
                                ? removeOrder(order as PendingOrder)
                                : updateOrder(order as PendingOrder),
                    },
                ],
            })
        } else if (connection === 'disconnected' && channelName) {
            echo.leave(channelName)
        }
    }
}
