function groupItemsByKey(items, key, transform) {
    const groupedKeys = items
        ?.map(item => (transform ? transform(item) : item))
        ?.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [...(result[item[key]] || []), item],
            }),
            {}
        )

    return groupedKeys ? Object.keys(groupedKeys)?.map(key => ({ title: key, items: groupedKeys[key] })) : []
}

export { groupItemsByKey }
