const showErrors = error => {
    let messages = ''

    try {
        if (typeof error === 'string') {
            return error
        }

        const { data } = error.response
        const errors = data.errors || data
        const errorsKeys = Object.keys(errors)

        for (const key of errorsKeys) {
            //if (errors[key]?.length > 60) {
            //    throw error
            //}

            messages = messages + ' ' + errors[key] + '\n'
        }
    } catch (err) {
        messages = 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
    }

    return messages
}

export { showErrors }
