import { Link } from 'react-router-dom'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const MallSettingsModalContainer = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transition: 400ms;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
`

export const MallSettingsModalBackground = styled.div<{ isEffectActive: boolean }>`
    z-index: ${({ theme }) => theme.commonModalZIndex};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    transition: 400ms;
    opacity: ${({ isEffectActive }) => (isEffectActive ? '1' : '0')};
`

export const MallSettingsModalBox = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 500px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

export const PersonalDataHeader = styled.div`
    padding: 20px;
    padding-bottom: 0;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 20px 40px;
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    width: 30px;
    position: absolute;
    top: 15px;
    right: 20px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

export const MallSettingsModalContent = styled.div`
    flex: 1;
    overflow: auto;
    margin-bottom: 0;
    p15px &::-webkit-scrollbar {
        display: none;
    }
`

export const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 30px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 3;
`

export const MallsListing = styled.div`
    padding: 0px 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 0px 40px;
    }
`

export const StoreItem = styled(Link)`
    font-size: 12px;
    position: relative;
    padding-left: 23px;
    margin-left: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    user-select: none;
    color: #111;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    &::before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #ccc;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0;
    }
    &:hover {
        &::before {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export const MallInfoContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`

export const MallCity = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
`

export const MallTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
`

export const MallAddress = styled.div`
    font-size: 14px;
`
