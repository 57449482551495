import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import ExportReportsModal from 'components/_common/export-reports-modal'
import { PerformanceHeader } from 'components/_reports'
import { PeriodOrdersTable } from 'components/_store-general-vision'

import { setSearchParams } from 'helpers'
import { useAuth, useFetchPagination } from 'hooks'
import { Order, OrderReport } from 'types'

import {
    InvoicingCard,
    OrdersByAreaCard,
    OrdersByStoreCard,
    OverviewCard,
    PaymentMethodsCard,
    PeriodPerformanceCard,
    SalesChannelsCard,
} from './cards'
import { CardRow, PerformanceContent, PerformancePageContainer, RowItem } from './performance-page.styles'

export default function PerformancePage(): JSX.Element {
    const history = useHistory()
    const { mall, store, user } = useAuth()
    const [isReportsModalOpened, setIsReportsModalOpened] = useState(false)

    const filters = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            mall_id: mall?.id ? undefined : Number(query.get('mall_id')) || undefined,
            store_id: user?.isShopkeeper ? undefined : Number(query.get('store_id')) || undefined,
            start_date: query.get('start_date') || undefined,
            end_date: query.get('end_date') || undefined,
            start_time: query.get('start_time') || undefined,
            end_time: query.get('end_time') || undefined,
            shifts: [1],
        }
    }, [location.search, store, user, mall.id])

    const hasReportIncomingPermission =
        (!user?.isStaff && user.isShopkeeper) || (!mall?.id && (user.isMallManager || user.isFinancial))
    const hasReportStoresPermission = user?.isMallManager || user?.isManager || user?.isFinancial

    const params = useMemo(() => {
        if (!filters.start_date || !filters.end_date) {
            return null
        }

        return {
            ...filters,
            store_id: store?.id || filters.store_id,
            mall: mall?.id ? mall.id : filters.mall_id && filters.mall_id,
            malls: mall?.id ? [mall.id] : filters.mall_id && [filters.mall_id],
        }
    }, [filters, mall, store])

    const reportOrders = useFetchPagination<OrderReport>('/painel/report/order-list', {
        revalidateOnMount: false,
        revalidateOnFocus: false,
        params: { ...params, per_page: 100 },
    })

    useEffect(() => {
        if (params) {
            reportOrders.revalidate()
        }
    }, [params])

    return (
        <PerformancePageContainer>
            <PerformanceHeader
                title="Performance"
                filters={filters}
                onFilterData={filters => setSearchParams(filters, location, history)}
            />
            <PerformanceContent>
                <CardRow>
                    <RowItem>
                        <OverviewCard params={params} />
                    </RowItem>
                    <RowItem>
                        <PeriodPerformanceCard params={params} />
                    </RowItem>
                </CardRow>
                <CardRow>
                    <RowItem>
                        <SalesChannelsCard params={params} />
                    </RowItem>
                    <RowItem>
                        <PaymentMethodsCard params={params} />
                    </RowItem>
                </CardRow>

                <OrdersByAreaCard params={params} />

                {hasReportStoresPermission && <OrdersByStoreCard params={params} />}

                {hasReportIncomingPermission && (
                    <CardRow>
                        <InvoicingCard params={params} />
                    </CardRow>
                )}

                <PeriodOrdersTable
                    filters={filters}
                    loading={reportOrders?.loading}
                    onLoadMore={() => reportOrders.setSize(reportOrders.size + 1)}
                    orders={reportOrders.items || ([] as OrderReport[])}
                    total={reportOrders.totals}
                    exportClick={() => setIsReportsModalOpened(true)}
                />
                <ExportReportsModal
                    filters={filters}
                    isActive={isReportsModalOpened}
                    closeClick={() => setIsReportsModalOpened(false)}
                />
            </PerformanceContent>
        </PerformancePageContainer>
    )
}
