import styled from 'styled-components'

export const OrderItemContainer = styled.div`
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
`

export const ItemID = styled.div`
    width: fit-content;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 14px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const ItemTextContent = styled.div`
    flex: 1;
    margin-left: 5px;
`

export const ItemTitle = styled.div`
    text-transform: uppercase;
    margin-bottom: 5px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 14px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const ItemAmount = styled.div`
    width: 20%;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 14px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
        text-align: right;
    }
`
export const ItemValue = styled.div`
    width: 20%;
    text-align: right;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 14px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const ItemObservations = styled.div`
    color: #666;
    margin-bottom: 20px;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const ObservationsTitle = styled.div`
    margin-bottom: 5px;
`

export const ObservationContent = styled.div``
