import { useState } from 'react'

import {
    ChannelLogo,
    OrderItemContainer,
    ifood,
    rappi,
    uberEats,
    undefinedStore,
    CustomerInfo,
    CustomerID,
    CustomerName,
    TimesContainer,
    ClockIcon,
    GreenClockIcon,
    TimeItem,
    TimeText,
    ActionRow,
    AcceptButton,
    RejectButton,
    RejectIcon,
    OptionsDropdown,
    DropdownItem,
    CloseDropdown,
    DropdownText,
    OrderPrice,
    PriceValue,
    PaymentMethod,
    LogosContainer,
    StoreLogo,
    ReturnButton,
} from './picker-order-item.styles'

interface Props {
    orderID: number
    customerName: string
    channel: number
    productionTime: number
    totalTime: number
    confirmClick(): void
    cardClick(): void
    orderValue: number
    payment: string
    detailClick(): void
    problemClick(): void
    storeLogo: string
    showCollectButton?: boolean
    showDeliverButton?: boolean
    isGivingBack?: boolean
}

const PickerOrderItem: React.FC<Props> = ({
    orderID,
    customerName,
    channel,
    productionTime,
    totalTime,
    confirmClick,
    cardClick,
    orderValue,
    payment,
    detailClick,
    problemClick,
    storeLogo,
    showCollectButton,
    showDeliverButton,
    isGivingBack,
}) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const handleClick = (e: React.MouseEvent, callback: () => void) => {
        e.stopPropagation()
        callback()
    }

    const handleSelectClick = (e: React.MouseEvent, callback: () => void) => {
        e.stopPropagation()
        setIsSelected(!isSelected)
        callback()
    }

    return (
        <OrderItemContainer onClick={(e: React.MouseEvent) => handleClick(e, cardClick)}>
            <LogosContainer>
                <StoreLogo src={storeLogo} />
                <ChannelLogo
                    src={channel === 1 ? ifood : channel === 2 ? rappi : channel === 3 ? uberEats : undefinedStore}
                />
            </LogosContainer>
            <CustomerInfo>
                <CustomerID>#{orderID}</CustomerID>
                <CustomerName>{customerName}</CustomerName>
            </CustomerInfo>
            <OrderPrice>
                <PriceValue>R$ {orderValue}</PriceValue>
                <PaymentMethod>{payment}</PaymentMethod>
            </OrderPrice>
            <TimesContainer>
                {productionTime && (
                    <TimeItem>
                        <ClockIcon />
                        <TimeText>{productionTime} min</TimeText>
                    </TimeItem>
                )}
                {totalTime && (
                    <TimeItem>
                        <GreenClockIcon />
                        <TimeText>{totalTime} min</TimeText>
                    </TimeItem>
                )}
            </TimesContainer>
            <ActionRow>
                {isGivingBack ? (
                    <ReturnButton onClick={(e: React.MouseEvent) => handleSelectClick(e, confirmClick)}>
                        {isSelected ? 'Devolvido' : 'Devolver'}
                    </ReturnButton>
                ) : (
                    <AcceptButton
                        isActive={isSelected}
                        onClick={(e: React.MouseEvent) => handleSelectClick(e, confirmClick)}
                    >
                        {showCollectButton ? (
                            <>{isSelected ? 'Coletado' : 'Coletar'}</>
                        ) : showDeliverButton ? (
                            <>{isSelected ? 'Entregue' : 'Entregar'}</>
                        ) : (
                            <>{isSelected ? 'Selecionado' : 'Selecionar'}</>
                        )}
                    </AcceptButton>
                )}
                <RejectButton onClick={(e: React.MouseEvent) => handleClick(e, () => setIsDropdownActive(true))}>
                    <RejectIcon /> Opções
                </RejectButton>
                <OptionsDropdown isActive={isDropdownActive}>
                    <CloseDropdown
                        onClick={(e: React.MouseEvent) => handleClick(e, () => setIsDropdownActive(false))}
                    />
                    <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, detailClick)}>
                        <DropdownText>Ver detalhes</DropdownText>
                    </DropdownItem>
                    <DropdownItem>
                        <DropdownText onClick={(e: React.MouseEvent) => handleClick(e, problemClick)}>
                            Notificar Problema
                        </DropdownText>
                    </DropdownItem>
                </OptionsDropdown>
            </ActionRow>
        </OrderItemContainer>
    )
}

export default PickerOrderItem
