import { faChevronDown, faEdit, faTimesCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const RoutedOrderItemContainer = styled.div<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
    border-top: 3px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#fff')};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
`

export const StepsContainer = styled.div`
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex: 1;
    margin-right: 40px;
`

export const StepsBox = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 7px;
    padding-top: 7px;
`

export const OrderId = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const LeftSide = styled.div`
    width: 275px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 88%;
    }
`

export const RightSide = styled.div`
    display: flex;
    align-items: center;
    width: 70px;
    margin-top: -30px;
`

export const AccordionButton = styled.div`
    cursor: pointer;
    padding-top: 4px;
`

export const ChevronIcon = styled(FontAwesomeIcon).attrs({
    icon: faChevronDown,
})`
    font-size: 20px;
`

export const AccordionContent = styled.div<{ isActive?: boolean }>`
    transition: 400ms;
    transform: translateX(${({ isActive }) => (isActive ? 0 : '-100%')});
    height: ${({ isActive }) => (isActive ? 'auto' : 0)};
    border-bottom: 3px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#fff')};
`

export const ButtonsRow = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.gray};
`

export const EditButton = styled(FontAwesomeIcon).attrs({
    icon: faEdit,
})`
    margin-right: 20px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const DeleteButton = styled(FontAwesomeIcon).attrs({
    icon: faTimesCircle,
})`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const OrdersListing = styled.div``

export const RouteButton = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
`

export const RouteIcon = styled(FontAwesomeIcon).attrs({
    icon: faMapMarkerAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 12px;
`
