import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IScheduledPeriod, IWeekDay } from 'types/scheduledPeriods'

import { WeekDaysSelect } from '../../../../../../components/week-days-select/week-days-select'

import {
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    InputsRow,
    StatusContainer,
    ButtonRow,
    ConfirmButton,
} from './picker-hours-modal.styled'

export type PickerHoursModalRef = {
    show(): void
    close(): void
    setSchedule?(schedule: IScheduledPeriod<'picker'>): void
}

type Props = {
    onClose?(isRefresh: boolean): void
}

type IForm = IScheduledPeriod<'picker'>

const PickerHoursModal = memo(
    forwardRef<PickerHoursModalRef, Props>(({ onClose }, ref) => {
        const { mall } = useAuth()
        const { setSuccessModal, setErrorModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [scheduledPeriod, setScheduledPeriod] = useState<IScheduledPeriod<'picker'>>()

        const {
            initialValues,
            isSubmitting,
            values,
            touched,
            errors,
            resetForm,
            setValues,
            setFieldValue,
            getFieldProps,
            handleSubmit,
        } = useFormik<IForm>({
            initialValues: {
                label: '',
                type: 'recurrent',
                status: 1,
                start_time: '',
                end_time: '',
                start_date: '',
                end_date: '',
                week_days: {
                    sunday: false,
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                },
            } as IForm,
            validationSchema: Yup.object().shape({
                label: Yup.string().required(),
                type: Yup.string().required(),
            }),
            onSubmit: async values => {
                const url = values?.id ? `/painel/schedules/picker/${values.id}` : '/painel/schedules/picker'
                const body = {
                    ...values,
                    type: 'picker',
                    mall_id: mall.id,
                    start_time: values.start_time + ':00',
                    end_time: values.end_time + ':59',
                }

                try {
                    isEdit ? await api.put(url, body) : await api.post(url, body)

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Horário de atendimento registrado com sucesso',
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: 'Houve um erro ao registrar seu horário de atendimento',
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setScheduledPeriod(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    resetForm()
                    setScheduledPeriod(null)
                    lateralModalBaseRef.current?.close()
                },
                setSchedule: (schedule: IScheduledPeriod<'picker'>) => {
                    setScheduledPeriod(schedule)
                    const type = Object.values(schedule.week_days).includes(true) ? 'recurrent' : 'dynamic'
                    setValues({ ...schedule, type })
                },
            }),
            []
        )

        const isEdit = useMemo(() => {
            return !!scheduledPeriod
        }, [scheduledPeriod])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Gerenciar' : 'Criar'} Horário de Atendimento`
        }, [isEdit])

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        const _handleChange = useCallback(
            (fieldName: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(fieldName, value)
                },
            []
        )

        const _handleWeekDayChange = useCallback(
            (key: IWeekDay) => {
                setFieldValue(`week_days.${key}`, !values.week_days[key])
            },
            [values.week_days]
        )

        useEffect(() => {
            if (values.type === 'recurrent') {
                setFieldValue('start_date', null)
                setFieldValue('end_date', null)
                return
            }

            setFieldValue('week_days', initialValues.week_days)
        }, [values.type, initialValues])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Tipo"
                                    type="select"
                                    options={[
                                        { label: 'Recorrente', value: 'recurrent' },
                                        { label: 'Dinâmico', value: 'dynamic' },
                                    ]}
                                    inputProps={getFieldProps('type')}
                                    errorMessage={touched.type && errors.type}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={{
                                        ...getFieldProps('label'),
                                        onChange: _handleChange('label'),
                                    }}
                                    errorMessage={touched.label && errors.label}
                                />
                            </InputContainer>

                            {values.type === 'recurrent' ? (
                                <InputContainer hasMargin>
                                    <FormLabel>Selecione os dias da semana</FormLabel>
                                    <WeekDaysSelect weekdays={values.week_days} inForm onClick={_handleWeekDayChange} />
                                </InputContainer>
                            ) : (
                                <InputContainer>
                                    <InputsRow>
                                        <InputContainer>
                                            <InputItem
                                                type="date"
                                                labelText="Data Inicial"
                                                inputProps={{
                                                    ...getFieldProps('start_date'),
                                                }}
                                                errorMessage={touched.start_date && errors.start_date}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <InputItem
                                                type="date"
                                                labelText="Data Final"
                                                inputProps={{
                                                    ...getFieldProps('end_date'),
                                                }}
                                                errorMessage={touched.end_date && errors.end_date}
                                            />
                                        </InputContainer>
                                    </InputsRow>
                                </InputContainer>
                            )}
                            <InputContainer>
                                <InputsRow>
                                    <InputContainer>
                                        <InputItem
                                            type="time"
                                            labelText="Hora Inicial"
                                            inputProps={{
                                                ...getFieldProps('start_time'),
                                                placeholder: '--:--',
                                            }}
                                            errorMessage={touched.start_time && errors.start_time}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <InputItem
                                            type="time"
                                            labelText="Hora Final"
                                            inputProps={{
                                                ...getFieldProps('end_time'),
                                                placeholder: '--:--',
                                            }}
                                            errorMessage={touched.end_time && errors.end_time}
                                        />
                                    </InputContainer>
                                </InputsRow>
                            </InputContainer>
                            {/* <InputContainer>
                                <StatusContainer>
                                    Status
                                    <SwitchButton
                                        isFlex
                                        isActive={Boolean(values.status)}
                                        onClick={_handleStatusChange}
                                    />
                                </StatusContainer>
                            </InputContainer> */}
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <ConfirmButton title="Salvar" onClick={() => handleSubmit()} />
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { PickerHoursModal }
