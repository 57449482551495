import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationRef, ModalConfirmationProps } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { Establishment, Establishments } from 'containers/stone-management/types'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

const EstablishmentsListing: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [establishments, setEstablishments] = useState<Establishments>({ establishment_list: [] })
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get<Establishments>('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const deactivateEstablishment = async (establishment: Establishment) => {
        try {
            setLoadingSubmit(true)

            const { data } = await siclosPagApi.delete(`/establishment/deactivate-establishment/${establishment?.id}`)

            if (data?.error) {
                throw data.msg
            }

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Estabelecimento desativado com sucesso!',
            })

            getEstablishments()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })
        } finally {
            setLoadingSubmit(false)
            modalMessage.current?.openModal()
        }
    }

    const reactivateEstablishment = async (establishment: Establishment) => {
        try {
            setLoadingSubmit(true)

            const { data } = await siclosPagApi.put('/establishment/reactivate-establishment', {
                establishment_id: establishment?.id,
                stone_code: establishment?.stone_code || undefined,
            })

            if (data?.error) {
                throw data.msg
            }

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Estabelecimento reativado com sucesso!',
            })

            getEstablishments()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })
        } finally {
            setLoadingSubmit(false)
            modalMessage.current?.openModal()
        }
    }

    const renderEmptyMessage = () => {
        return <EmptyMessage>Não foram encontrados estabelecimentos cadastrados.</EmptyMessage>
    }

    return (
        <div>
            <ModalLoading visible={loadingSubmit || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <RowTitle
                title="Estabelecimentos"
                buttonRow={[
                    {
                        label: 'Criar estabelecimento',
                        onClick: () => history.push('/painel-malls/gestao-stone/criar-estabelecimento'),
                    },
                    {
                        label: 'Criar estabelecimento já credenciado',
                        onClick: () => history.push('/painel-malls/gestao-stone/criar-estabelecimento-credenciado'),
                        secondary: true,
                    },
                ]}
            />

            <FlexTable
                columns={[
                    { name: 'Nome', width: '25%' },
                    { name: 'Endereço', width: '30%' },
                    { name: 'Status', width: '10%' },
                    { name: '', width: '30%' },
                ]}
                list={establishments.establishment_list?.map(establishment => {
                    const { id, business_name, address, establishment_is_active } = establishment

                    return {
                        business_name: (
                            <>
                                {business_name} <br />
                                <small>{id}</small>
                            </>
                        ),
                        address: (
                            <>
                                {address?.state} - {address?.city} <br />
                                {address?.neighborhood}
                            </>
                        ),
                        establishment_is_active: establishment_is_active ? 'Ativo' : 'Inativo',
                        buttonRow: (
                            <div className="flex-table-row-content button-row">
                                <div
                                    className="button-item outline"
                                    onClick={() => {
                                        if (establishment_is_active) {
                                            setModalConfirmationData({
                                                title: 'Desativar estabelecimento',
                                                message: 'Tem certeza de que deseja desativar este estabelecimento?',
                                                onYes: () => deactivateEstablishment(establishment),
                                            })
                                        } else {
                                            setModalConfirmationData({
                                                title: 'Reativar estabelecimento',
                                                message: 'Tem certeza de que deseja reativar este estabelecimento?',
                                                onYes: () => reactivateEstablishment(establishment),
                                            })
                                        }

                                        modalConfirmation.current?.openModal()
                                    }}
                                >
                                    {establishment_is_active ? 'Desativar' : 'Reativar'}
                                </div>
                                <div
                                    className="button-item"
                                    onClick={() =>
                                        history.push(`/painel-malls/gestao-stone/detalhes-estabelecimento/${id}`)
                                    }
                                >
                                    Visualizar
                                </div>
                            </div>
                        ),
                    }
                })}
            />

            {!establishments.establishment_list.length && !loading && renderEmptyMessage()}

            {/* <ButtonLoadMore
                loading={loading}
                visible={establishments.establishment_list.length < 10}
                onClick={() => getEstablishments({ current_page: 0, establishments })}
            /> */}
        </div>
    )
}

export default EstablishmentsListing
