import styled from 'styled-components'

export const InfoItemContainer = styled.div<{ noMargin?: boolean }>`
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 20)}px;
    flex: 1;
    margin-left: 10px;

    &:first-child {
        margin-left: 0;
    }
`

export const InfoLabel = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
    color: #737373;
`

export const InfoContent = styled.div<{ big?: boolean }>`
    font-weight: bold;
    font-size: ${({ big }) => (big ? '18px' : '14px')};
`
