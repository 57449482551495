import { memo } from 'react'

import './style.scss'
import {
    Table,
    TableBody,
    TableBodyItem,
    TableBodyRow,
    TableHead,
    TableHeadItem,
    TableHeadRow,
} from './flex-table.styled'

interface Column {
    name: string
    width: string
    style?: React.CSSProperties
}

interface ListItem {
    [key: string]: string | JSX.Element | undefined
}

type Props = {
    hasShadow?: boolean
    columns: Column[]
    list: ListItem[] | undefined
    isBordered?: boolean
}

const FlexTable: React.FC<Props> = memo(({ columns, list, hasShadow, isBordered }) => {
    return (
        <Table hasShadow={hasShadow} isBordered={isBordered}>
            <TableHead>
                <TableHeadRow>
                    {columns.map((column, index) => (
                        <TableHeadItem key={index} styles={{ width: column?.width, ...column.style }}>
                            {column.name}
                        </TableHeadItem>
                    ))}
                </TableHeadRow>
            </TableHead>

            <TableBody>
                {list?.map((item, index) => (
                    <TableBodyRow key={index}>
                        {Object.keys(item)
                            .filter(key => typeof item[key] !== 'function')
                            .map((key, index) => (
                                <TableBodyItem
                                    key={index}
                                    styles={{ width: columns[index].width, ...columns[index].style }}
                                >
                                    {item[key] || '-'}
                                </TableBodyItem>
                            ))}
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
})

export default FlexTable
