import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import theme from 'styles/theme'

export const PasswordStrengthContainer = styled.div<{ isFull?: boolean }>`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    width: 98%;
    margin-top: -10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: ${({ isFull }) => (isFull ? '100%' : '47%')};
    }
`

export const StrengthTitle = styled.div`
    font-size: 14px;
    margin-right: 10px;
`

export const BarsContainer = styled.div`
    display: flex;
    flex: 1;
    padding-top: 2px;
`

export const BarItem = styled.div<{ isActive?: boolean }>`
    height: 5px;
    background-color: ${({ isActive }) => (isActive ? theme.colors.primary : theme.colors.gray)};
    flex: 1;
    margin-left: 5px;
`

export const SuccessMessageContainer = styled.div`
    text-align: center;
`

export const SuccessIcon = styled(FontAwesomeIcon).attrs({
    icon: faCheckCircle,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-bottom: 30px;
    font-size: 62px;
`

export const SuccessTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 50px;
`
