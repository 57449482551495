import { all, delay, put, select } from 'redux-saga/effects'

import { getOrderWithTimes } from 'helpers'
import { OrdersInProgressActions, OrdersListKey } from 'store/reducers/ordersInProgress'
import { Order } from 'types'

export function* updateOrdersTimesPeriodically() {
    while (true) {
        yield delay(30000)

        const { orders } = yield select(({ ordersInProgress }) => ordersInProgress)

        yield all(
            Object.keys(orders).map(key =>
                put(
                    OrdersInProgressActions.setOrders(key as OrdersListKey, {
                        ...orders[key],
                        items: orders[key].items.map((order: Order) => getOrderWithTimes(order)),
                    })
                )
            )
        )
    }
}
