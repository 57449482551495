import { useEffect, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'

import FlexTable from 'components/flex-table'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'

import { ExtractLot } from 'containers/stone-management/types'

const FinanceExtractTransactions: React.FC<RouteComponentProps> = ({ history }) => {
    const location = useLocation<{
        title: string | undefined
        date_amount: string | undefined
        extract_lots: ExtractLot[] | undefined
    }>()
    const [extractLots, setExtractLots] = useState<ExtractLot[]>()
    const [selectedLotID, setSelectedLotID] = useState<string>('')

    useEffect(() => {
        const extract_lots = location?.state?.extract_lots

        if (extract_lots) {
            setExtractLots(extract_lots)
            delete location.state.extract_lots
        }
    }, [location])

    function getExtractLotById(lotId: string) {
        const extractLot = extractLots?.flatMap(item => item.lot).find(item => item.lot_stone_payment_id === lotId)

        return extractLot
    }

    function getTotal() {
        if (selectedLotID.length) {
            return `Total do lote: ${getExtractLotById(selectedLotID)?.lot_amount}`
        } else {
            return `Total da data: ${location.state?.date_amount}`
        }
    }

    return (
        <div>
            <RowTitle
                title={`${location.state?.title || 'Transações'}`}
                backClick={() => history.push('/painel-malls/gestao-stone/extrato')}
            >
                <>
                    <div>{getTotal()}</div>
                    <SelectInputForm
                        style={{ margin: 0, marginLeft: '10px' }}
                        label="Lote"
                        placeholder="Filtrar por lote"
                        data={extractLots?.flatMap(
                            ({ lot: { lot_stone_payment_id, lot_brand, lot_payment_type } }) => ({
                                name: `${lot_brand} - ${lot_payment_type}`,
                                value: lot_stone_payment_id,
                            })
                        )}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedLotID(value)
                        }}
                        value={selectedLotID}
                    />
                </>
            </RowTitle>

            <FlexTable
                columns={[
                    { name: 'ID', width: '30%' },
                    { name: 'Valor bruto/Valor líquido', width: '25%' },
                    { name: 'Parcela/Total de parcelas', width: '25%' },
                    { name: 'Taxa de antecipação', width: '20%' },
                ]}
                list={extractLots
                    ?.filter(item => (selectedLotID.length ? item.lot.lot_stone_payment_id === selectedLotID : true))
                    .flatMap(item =>
                        item.transaction_details?.map(item => {
                            const {
                                siclos_transaction_id,
                                stone_transaction_id,
                                order_id,
                                gross_amount,
                                net_amount,
                                installments_number,
                                actual_installment,
                                anticipation_rate_amount,
                            } = item

                            return {
                                id: (
                                    <>
                                        ID Siclos <small>{siclos_transaction_id}</small> <br />
                                        ID Stone <small>{stone_transaction_id}</small> <br />
                                        ID Logaroo <small>{order_id}</small> <br />
                                    </>
                                ),
                                amount: `${gross_amount}/${net_amount}`,
                                installment: `${actual_installment}/${installments_number}`,
                                anticipation_rate_amount,
                            }
                        })
                    )}
            />
        </div>
    )
}

export default FinanceExtractTransactions
