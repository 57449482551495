import { useCallback, useRef, useState } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { formatCurrency } from 'helpers'
import api, { mercadooApi } from 'services/api'
import { PendingOrder } from 'types'

export type IReimbursementForm = {
    id?: number
    type?: 'total' | 'partial'
    amount: number
    totalValue: number
}

type Tabs = 'details' | 'reimbursement' | 'historic'

function useSalesDetailModalController() {
    const { setErrorModal, setSuccessModal, setConfirmationModal, setLoading } = useUI()

    const lateralModalBaseRef = useRef<LateralModalBase>()

    const [order, setOrder] = useState<PendingOrder>()
    const [tab, setTab] = useState<Tabs>('details')

    const { values, touched, errors, getFieldProps, handleSubmit, setValues, resetForm } =
        useFormik<IReimbursementForm>({
            initialValues: {
                type: 'total',
                totalValue: 0,
                amount: 0,
            },
            validationSchema: Yup.object().shape({}),
            onSubmit: async values => {
                if (values.amount > values.totalValue) {
                    setErrorModal({
                        title: 'Atenção',
                        subtitle: 'Valor de estorno é maior que o valor total do pedido',
                    })
                    return
                }

                setLoading(true)
                try {
                    if (values?.id) {
                        const body = { amount: values.type === 'total' ? values.totalValue : Number(values.amount) }
                        await mercadooApi.post(`/orders/${values.id}/reversal`, body)
                        setSuccessModal({
                            title: 'Sucesso',
                            subtitle: 'Estorno realizado com sucesso',
                            singleButtonClick() {
                                lateralModalBaseRef.current.close()
                                setTab('details')
                            },
                        })
                    }
                } catch (error) {
                    setErrorModal({ title: 'Erro', subtitle: 'Não foi possível realizar o estorno' })
                } finally {
                    setLoading(false)
                }
            },
        })

    const _getDetails = useCallback(async (id: number) => {
        setLoading(true)
        try {
            const { data } = await api.get<PendingOrder>(`/mercadoo/orders/${id}`)
            setValues({ amount: 0, totalValue: Number(data.price), id: id })
            setOrder(data)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível consultar as informações do pedido',
            })
        } finally {
            setLoading(false)
        }
    }, [])

    const _handleChangeTab = useCallback((tab: Tabs) => {
        return () => setTab(tab)
    }, [])

    const _handleSubmitReimbursement = useCallback((): void => {
        const { id, customer, created_at } = order
        const { type, totalValue, amount } = values

        const message = `Tem certeza de que desejar estornar ${
            type === 'total' ? formatCurrency(totalValue) : formatCurrency(amount)
        } ${customer?.name ? `para ${customer.name}` : ''} do pedido n° ${id} realizado em ${format(
            created_at,
            'DD/MM/YYYY'
        )} às ${format(order.created_at, 'HH:mm')}?`

        const children: React.ReactNode = <span>Esta é uma operação irreversível!</span>

        setConfirmationModal({
            title: 'Atenção!',
            subtitle: message,
            children,
            type: 'delete',
            modalIcon: 'exclamation-circle',
            leftButtonText: 'Cancelar',
            rightButtonText: 'Sim, reembolsar!',
            rightButtonClick: handleSubmit,
        })
    }, [order, values])

    return {
        tab,
        order,
        lateralModalBaseRef,
        values,
        touched,
        errors,
        getFieldProps,
        setValues,
        resetForm,
        _getDetails,
        _handleChangeTab,
        _handleSubmitReimbursement,
    }
}
export { useSalesDetailModalController }
