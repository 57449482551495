import { Circle, Line, StepTitle, TimelineContainer } from './status-timeline.styles'
const steps = ['Capturado', 'Pronto', 'Disp p/ Coleta', 'COletado', 'Saiu para Rota', 'Chegou no cliente', 'Entregue']

interface Props {
    currentStatus: number
}

const StatusTimeline: React.FC<Props> = ({ currentStatus }) => {
    return (
        <TimelineContainer>
            {steps.map((item, index) => (
                <>
                    <Circle isActive={currentStatus > index + 1}>
                        <StepTitle>{item}</StepTitle>
                    </Circle>
                    <Line />
                </>
            ))}
        </TimelineContainer>
    )
}

export default StatusTimeline
