import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: 410px;
`

const Content = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 3rem;

    display: flex;
    flex-direction: column;
`

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 40px;

    padding-top: 0px !important;

    select {
        @media (min-width: 1200px) {
            width: 350px;
        }
    }
`

export { Container, Content, InputContainer }
