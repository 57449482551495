import { faDollarSign, faExternalLinkAlt, faLongArrowAltLeft, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const PerformancePageContainer = styled.div`
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
`

export const PerformanceContent = styled.div`
    background-color: #e8e8e8;
    flex: 1;
    padding: 20px;
`

export const CardRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        justify-content: space-between;
    }
`

export const RowItem = styled.div`
    height: 100%;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 49%;
    }
`

export const GraphicMock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    background-color: red;
`

export const AmountRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

export const AmountItem = styled.div`
    margin-bottom: 20px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 49%;
    }
`

export const IncomeTotals = styled.div`
    margin-bottom: 20px;
`

export const IncomeNumber = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 32px;
    font-weight: 600;
`

export const IncomeLabel = styled.div`
    font-size: 14px;
    color: #717171;
`

export const ResumeCard = styled.div`
    border-radius: 12px;
    background-color: #f7f7f7;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
`
export const ResumeRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        align-items: center;
    }
`

export const BagIcon = styled(FontAwesomeIcon).attrs({
    icon: faShoppingBag,
})`
    color: #ababab;
    font-size: 40px;
    margin-right: 20px;
`

export const MoneySign = styled(FontAwesomeIcon).attrs({
    icon: faDollarSign,
})`
    color: #ababab;
    font-size: 40px;
    margin-right: 20px;
`

export const ResumeText = styled.div``

export const ResumeNumber = styled.div`
    font-size: 22px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`

export const ResumeTitle = styled.div`
    font-size: 16px;
    color: #717171;
`

export const InfoSectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    font-weight: bold;
`

export const InfoRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
`

export const InfoItemContainer = styled.div<{ triple?: boolean }>`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: ${({ triple }) => (triple ? '25%' : '45%')};
        padding-right: 5%;
    }
`

export const BikerCard = styled.div`
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 330px;
    }
`

export const BikerPhoto = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    border: 1px solid #eee;
    object-fit: cover;
`

export const BikerText = styled.div`
    flex: 1;
    margin-bottom: -10px;
`

export const BikerName = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`

export const InfoFlex = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    width: 100%;
`

export const ReportHeader = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 20px;
    }
`

export const HeaderTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
`

export const HeaderContent = styled.div`
    color: #aaa;
    margin-bottom: 20px;
`

export const External = styled(FontAwesomeIcon).attrs({
    icon: faExternalLinkAlt,
})`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
`

export const BackContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`

export const BackIcon = styled(FontAwesomeIcon).attrs({
    icon: faLongArrowAltLeft,
})`
    color: ${({ theme }) => theme.colors.primary};
`

export const BackText = styled.div`
    font-weight: bold;
    margin-left: 10px;
`
