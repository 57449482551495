import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const DetailsTabContainer = styled.div``

export const PickerContainer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 300px;
    }
`

export const PickerPhoto = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #eee;
    overflow: hidden;
`

export const PickerTextContainer = styled.div`
    margin-left: 20px;
    flex: 1;
`

export const PickerTitle = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 5px;
`

export const PickerName = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 3px;
`

export const PickerPhone = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const PhoneIcon = styled(FontAwesomeIcon).attrs({
    icon: faPhoneAlt,
})`
    margin-right: 5px;
`

export const InfoSection = styled.div`
    margin-bottom: 20px;
`

export const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
`

export const InfoRow = styled.div`
    display: flex;
    align-items: flex-start;
`
