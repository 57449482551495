import styled from 'styled-components'

export const IncomeListingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const IncomeItemTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    margin-right: 20px;
    color: #717171;
`

export const IncomeProgress = styled.div`
    width: 100%;
    background-color: #c6c6c6;
    border-radius: 20px;
    height: 6px;
    overflow: hidden;
    margin-bottom: 5px;
    position: relative;
`

export const IncomeFill = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
`

export const IncomeValue = styled.div`
    font-weight: 600;
    color: #717171;
`

export const IncomeItem = styled.div`
    width: 100%;
    margin-bottom: 15px;
    padding: 0px 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        ${IncomeProgress} {
            width: 40%;
            margin-bottom: 0;
        }
        ${IncomeItemTitle}, ${IncomeValue} {
            width: 25%;
        }
        ${IncomeValue} {
            text-align: right;
        }
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        padding: 15px 10px;
        display: block;
        background-color: #f1f1f1;
        border-radius: 12px;
        margin-bottom: 15px;
        ${IncomeProgress}, ${IncomeItemTitle}, ${IncomeValue} {
            width: 100%;
        }
        ${IncomeProgress} {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        ${IncomeValue} {
            text-align: left;
        }
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            width: calc(32% - 20px);
        }
    }
`
