import { useEffect, useState, memo } from 'react'

import { IIntegration, IntegrationType } from 'types/integration'

import { ManagerIntegratorContent } from './manager-integrator-content/manager-integrator-content'
import {
    ManagerIntegratorModalBackground,
    ManagerIntegratorModalBox,
    ManagerIntegratorModalContainer,
} from './manager-integrator-modal.styled'

interface Props {
    type: IntegrationType
    isActive: boolean
    integration?: IIntegration | null
    closeClick(isRefresh?: boolean): void
}

const ManagerIntegratorModal: React.FC<Props> = memo(({ isActive, closeClick, type, integration }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <ManagerIntegratorModalContainer isDisplayed={isDisplayed}>
            <ManagerIntegratorModalBox isEffectActive={isEffectActive}>
                <ManagerIntegratorContent
                    type={type}
                    isActive={isActive}
                    closeClick={closeClick}
                    integration={integration}
                />
            </ManagerIntegratorModalBox>
            <ManagerIntegratorModalBackground isEffectActive={isEffectActive} onClick={() => closeClick()} />
        </ManagerIntegratorModalContainer>
    )
})

export { ManagerIntegratorModal }
