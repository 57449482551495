import { useEffect, useState } from 'react'

import { RescheduleContent } from '..'

import { RescheduleModalBackground, RescheduleModalBox, RescheduleModalContainer } from './reschedule-modal.styles'

interface Props {
    isActive: boolean
    rescheduleOrderId: number | undefined
    closeClick(): void
}

const RescheduleModal: React.FC<Props> = ({ isActive, rescheduleOrderId, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <RescheduleModalContainer isDisplayed={isDisplayed}>
            <RescheduleModalBox isEffectActive={isEffectActive}>
                <RescheduleContent closeClick={closeClick} rescheduleOrderId={rescheduleOrderId} />
            </RescheduleModalBox>
            <RescheduleModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </RescheduleModalContainer>
    )
}

export default RescheduleModal
