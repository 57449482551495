import { memo, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import LogarooSource from 'assets/images/biker-marker.png'
import MercadooSource from 'assets/images/mercadoo.png'

import { Scroll } from 'components/scroll/scroll'

import { IntegratorStoreListingItem } from 'containers/integrators-page/components/integrator-store-listing-item/integrator-store-listing-item'
import { AuthIntegratorModal } from 'containers/integrators-page/modals'
import { PloogaModal } from 'containers/integrators-page/modals/plooga-modal/plooga-modal'

import api, { plugApi } from 'services/api'
import { PagedList } from 'types'
import { IOriginators } from 'types/originators'
import { IPlooga } from 'types/plooga'
import { ISettings } from 'types/settings'

import {
    Content,
    Button,
    ContainerList,
    ContainerCredentials,
    Container,
    ContainerTitle,
    Title,
    ButtonText,
    FormSectionTitle,
    RowInfo,
    Label,
    Value,
    ContainerButton,
    ContainerTitleForm,
} from './integration-plooga.styled'

const IntegrationPlooga = memo(() => {
    const history = useHistory()

    const [plooga, setPlooga] = useState<IPlooga>()
    const [originators, setOriginators] = useState<IOriginators[]>([])
    const [showCredentialsModal, setShowCredentialsModal] = useState(false)
    const [showPloogaCredentialsModal, setShowPloogaCredentialsModal] = useState(false)

    const _getPlooga = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<ISettings>>('/painel/settings')
            data.items.forEach(item => {
                if (item.name === 'plooga') {
                    setPlooga({
                        created_at: item.created_at,
                        status: item.status,
                        updated_at: item.updated_at,
                        token: item.values.token,
                    })
                }
            })
        } catch (error) {
            console.log('error')
        }
    }, [])

    const _goTo = useCallback(
        (type: string) => {
            return () => {
                history.push(`/painel-malls/integracoes/${type}`)
            }
        },
        [history]
    )

    const _getOriginators = useCallback(async () => {
        try {
            const { data } = await plugApi.get<PagedList<IOriginators>>('/originators')
            setOriginators(data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    const _openCredentialsModal = useCallback(() => {
        setShowCredentialsModal(true)
    }, [])

    const _onCredentialsModalClose = useCallback(
        (isRefresh: boolean) => {
            if (isRefresh) {
                _getOriginators()
            }

            setShowCredentialsModal(false)
        },
        [_getOriginators]
    )

    const _openPloogaCredentialsModal = useCallback(() => {
        setShowPloogaCredentialsModal(true)
    }, [])

    const _onPloogaCredentialsModalClose = useCallback(
        (isRefresh: boolean) => {
            if (isRefresh) {
                _getPlooga()
            }

            setShowPloogaCredentialsModal(false)
        },
        [_getPlooga]
    )

    useEffect(() => {
        _getOriginators()
        _getPlooga()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <ContainerTitle>
                <Title>Origens</Title>
                <ButtonText onClick={_openCredentialsModal}>
                    Adicionar Origem <FontAwesomeIcon icon="plus-circle" />
                </ButtonText>
            </ContainerTitle>
            <Content>
                <ContainerList>
                    <Scroll>
                        {originators.map((item, i) => (
                            <IntegratorStoreListingItem
                                key={i}
                                image={item.logo}
                                name={item.name}
                                onClickButton={_goTo(item.slug)}
                                buttonText="Gerenciar"
                            />
                        ))}
                        <IntegratorStoreListingItem
                            image={LogarooSource}
                            name="Logaroo"
                            buttonText="Gerenciar"
                            onClickButton={_goTo('logaroo')}
                        />
                        <IntegratorStoreListingItem
                            image={MercadooSource}
                            name="Mercadoo"
                            buttonText="Gerenciar"
                            onClickButton={_goTo('mercadoo')}
                        />
                    </Scroll>
                </ContainerList>
                <ContainerCredentials>
                    <ContainerTitleForm>
                        <FormSectionTitle>Plooga Credencial</FormSectionTitle>
                    </ContainerTitleForm>
                    {!!plooga && (
                        <>
                            <RowInfo>
                                <Label>Status:</Label>
                                <Value type={plooga.status ? 'success' : 'error'}>
                                    {plooga.status ? 'Ativo' : 'Inativo'}
                                </Value>
                            </RowInfo>
                            <RowInfo>
                                <Label>Ultima alteração:</Label>
                                <Value>{plooga.token ? format(plooga.updated_at, 'DD/MM/YYYY HH:mm') : 'N/S'}</Value>
                            </RowInfo>
                            {/* <RowInfo>
                                <Label>Expira em:</Label>
                                <Value>{plooga.token ? format(plooga.expires, 'DD/MM/YYYY HH:mm') : 'N/S'}</Value>
                            </RowInfo> */}
                            <ContainerButton>
                                <Button onClick={_openPloogaCredentialsModal}>Atualizar Credencial</Button>
                            </ContainerButton>
                        </>
                    )}
                </ContainerCredentials>
            </Content>

            <AuthIntegratorModal isActive={showCredentialsModal} closeClick={_onCredentialsModalClose} />
            <PloogaModal isActive={showPloogaCredentialsModal} closeClick={_onPloogaCredentialsModalClose} />
        </Container>
    )
})

export { IntegrationPlooga }
