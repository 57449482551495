import NewTopRow from 'components/_new-general-vision/new-top-row/new-top-row'
import { PickerNavigationRow, PickerVisionCards } from 'components/_picker-vision'

import { ContentContainer, GeneralVisionContainer } from './picker-general-vision.styles.ts'

export default function PickerGeneralVision(): JSX.Element {
    return (
        <>
            <GeneralVisionContainer>
                <NewTopRow title="Visão geral de pedidos" />
                <PickerNavigationRow
                    inRouteClick={() => null}
                    isInRouteActive={true}
                    inProductionValue={124}
                    deliveredValue={864}
                />
                <ContentContainer>
                    <PickerVisionCards orderItemClick={() => null} />
                </ContentContainer>
            </GeneralVisionContainer>
        </>
    )
}
