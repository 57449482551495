import React, { memo } from 'react'

import { IDeliveryOrigin } from 'types'

import { BorderContent, Container, Label, Title, RemoveIcon } from './collect-item.styled'
type Props = {
    name?: string
    collect?: IDeliveryOrigin
    index?: number
    onRemoveClick?(): void
}

const CollectItem: React.FC<Props> = memo(({ index, name, collect, onRemoveClick }) => {
    return (
        <Container>
            <Label>Coleta {index}</Label>
            <BorderContent>
                <Title>{name || collect.name}</Title>
                {!name && <RemoveIcon icon="trash-alt" onClick={onRemoveClick} />}
            </BorderContent>
        </Container>
    )
})

export { CollectItem }
