import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

const Container = styled.div`
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 99;
`

const Box = styled.div<{ show?: boolean }>`
    position: relative;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    min-width: 18.75rem;
    width: 21.25rem;
    max-height: 98%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.125rem;
    box-shadow: -0.063rem 0.188rem 0.875rem 0 rgba(0, 0, 0, 0.3);

    transition: all 200ms ease-out;

    @media screen and (max-width: ${({ theme }) => theme.devices.mobile}) {
        width: 95%;
        height: 95%;
    }

    .title {
        font-weight: 600;
        user-select: none;
    }
    .icon {
        cursor: pointer;
        user-select: none;
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        font-size: 1.25rem;
    }

    ${({ show }) =>
        show
            ? css`
                  animation-name: show;
                  animation-duration: 400ms;
              `
            : css`
                  animation-name: close;
                  animation-duration: 450ms;
              `}
`

const Title = styled.div`
    font-weight: bold;
    /* margin-bottom: 1.25rem; */
    text-align: center;
    font-size: 1.125rem;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .text {
        font-size: 1rem;
    }
`

const InputContent = styled.div`
    width: 100%;
`

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.alert};
    font-size: 1.875rem;
    /* margin-bottom: 0.625rem; */
    align-self: center;
`

const Message = styled.div`
    color: #2f2f2f;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    text-align: center;
`

const Footer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.625rem;
`

const ContainerButtons = styled.div`
    flex: 1;
`

const Separator = styled.div`
    width: 0.25rem;
    pointer-events: none;
`

const Button = styled.div<{ isOutline?: boolean; disabled?: boolean }>`
    width: 100%;
    border: 0.063rem solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme, isOutline }) => (isOutline ? theme.colors.white : theme.colors.primary)};
    color: ${({ theme, isOutline }) => (isOutline ? theme.colors.primary : theme.colors.white)};
    padding: 0.625rem 0;
    border-radius: 0.25rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 0.625rem;
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    text-align: center;
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`

export { Container, Box, Content, InputContent, Title, Icon, Message, Footer, ContainerButtons, Separator, Button }
