import { useEffect, useState, memo } from 'react'

import { SettingsIntegratorContent } from './settings-integrator-content/settings-integrator-content'
import {
    SettingsIntegratorModalBackground,
    SettingsIntegratorModalBox,
    SettingsIntegratorModalContainer,
} from './settings-integrator-modal.styled'

interface Props {
    isActive: boolean
    closeClick(): void
    refresh(): void
}

const SettingsIntegratorModal: React.FC<Props> = memo(({ isActive, closeClick, refresh }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <SettingsIntegratorModalContainer isDisplayed={isDisplayed}>
            <SettingsIntegratorModalBox isEffectActive={isEffectActive}>
                <SettingsIntegratorContent isActive={isActive} closeClick={closeClick} refresh={refresh} />
            </SettingsIntegratorModalBox>
            <SettingsIntegratorModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </SettingsIntegratorModalContainer>
    )
})

export { SettingsIntegratorModal }
