import styled from 'styled-components'

export const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 90%;
        margin: auto;
        flex-direction: row;
        margin-bottom: 40px;
        margin-top: 20px;
    }
`

export const Circle = styled.div<{ isActive?: boolean }>`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: relative;
    border: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.lightGray)};
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.success : 'transparent')};
`
export const StepTitle = styled.div`
    font-size: 10px;
    position: absolute;
    top: 3px;
    right: -150px;
    width: 140px;
    text-transform: uppercase;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        left: -150%;
        right: unset;
        top: 130%;
        width: 70px;
        font-size: 8px;
        text-align: center;
    }
`

export const Line = styled.div`
    height: 40px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    margin-left: 10px;
    &:last-child {
        display: none;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
        height: 2px;
        flex: 1;
        margin-left: 0px;
        margin-top: 10px;
    }
`
