import { useEffect, useState } from 'react'

import { format, isAfter } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { DateInput, InputItem, Snackbar } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError } from 'helpers'
import api from 'services/api'
import { RateMultiplier } from 'types'

import {
    ContentTitle,
    RatesReplicationModalBackground,
    RatesReplicationModalBox,
    RatesReplicationModalContainer,
    RatesReplicationModalContent,
    CloseButton,
    CloseContainer,
    RatesReplicationHeader,
    ButtonRow,
    TextButton,
    ConfirmButton,
    InputRow,
    ItemContainer,
} from './rates-replication-modal.styles'

type Rate = Pick<RateMultiplier, 'reason' | 'start_at'>

interface Props {
    isActive: boolean
    rateMultiplier: RateMultiplier
    closeClick(): void
}

const RatesReplicationModal: React.FC<Props> = ({ isActive, rateMultiplier, closeClick }) => {
    const { closeSnackbar, setConfirmationModal, setLoading, setSnackbar, snackbarProps } = useUI()

    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    const {
        errors,
        handleSubmit,
        getFieldProps,
        setFieldError,
        setFieldValue,
        setTouched,
        setValues,
        touched,
        values,
    } = useFormik({
        initialValues: {
            rates: [] as Rate[],
        },
        validationSchema: Yup.object().shape({
            rates: Yup.array()
                .min(1)
                .of(
                    Yup.object().shape({
                        reason: Yup.string().trim().required('Ocasião é requerida'),
                        start_at: Yup.string().trim().required('Data é requerida'),
                    })
                ),
        }),
        onSubmit: async values => {
            try {
                setLoading(true)
                const formattedRates = values.rates.map(rate => formatRate(rate))

                await Promise.all(formattedRates.map(async rate => await api.post('/painel/rate-multiplier', rate)))

                setSnackbar({ message: 'Tarifas replicadas com sucesso' })
                closeClick()
            } catch (error) {
                setSnackbar({ message: 'Houveram erros ao replicar uma ou mais tarifas', type: 'error' })
            } finally {
                setLoading(false)
            }
        },
    })

    function onSubmit() {
        setConfirmationModal({
            title: 'Replicar tarifas',
            subtitle: 'Deseja replicar as tarifas de acordo com as datas preenchidas?',
            type: 'alert',
            leftButtonText: 'Cancelar',
            rightButtonText: 'Sim, replicar',
            rightButtonClick: handleSubmit,
        })
    }

    function formatRate(rate: Rate) {
        const [, startTime] = rateMultiplier.start_at.split(' ')
        const [, finishTime] = rateMultiplier.finish_at.split(' ')

        return {
            ...rateMultiplier,
            payment_areas: rateMultiplier.payment_areas.map(item => item.id),
            reason: rate.reason,
            start_at: `${rate.start_at} ${startTime}`,
            finish_at: `${rate.start_at} ${finishTime}`,
            values: undefined,
        }
    }

    function populateForm() {
        setValues({ rates: [{ reason: rateMultiplier.reason, start_at: '' }] })
    }

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                populateForm()
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    function validateDuplication(items: string[], field: string, message: string): boolean {
        let duplicatedIndex = -1

        items.forEach((item, index, array) => {
            const hasSome = array.some(
                (arrayItem, arrayIndex) => item.trim() === arrayItem.trim() && index !== arrayIndex
            )

            if (index > 0 && hasSome) {
                duplicatedIndex = index
            }
        })

        if (duplicatedIndex !== -1) {
            setFieldError(`rates[${duplicatedIndex}].${field}`, message)
        }

        return duplicatedIndex !== -1
    }

    function validateDates(dates: string[]): boolean {
        let invalidDateIndex = -1
        dates.forEach((date, index, array) => {
            if (index > 0 && !isAfter(date, array[index - 1])) {
                invalidDateIndex = index
            }
        })

        if (invalidDateIndex !== -1) {
            setFieldError(`rates[${invalidDateIndex}].start_at`, 'Selecione uma data posterior a anterior')
        }

        return invalidDateIndex !== -1
    }

    function validateForm() {
        const hasReasonDuplicates = validateDuplication(
            values.rates.map(rate => rate.reason),
            'reason',
            'Ocasião duplicada'
        )
        const hasDateDuplicates = validateDuplication(
            values.rates.map(rate => rate.start_at),
            'start_at',
            'Data duplicada'
        )

        const hasInvalidDate = validateDates(values.rates.map(rate => rate.start_at))

        return !hasReasonDuplicates && !hasDateDuplicates && !hasInvalidDate
    }

    function addRateInputs() {
        const lastIndex = values.rates.length - 1
        const secondToLastIndex = values.rates.length - 2
        setTouched({
            rates: values.rates.map(() => ({
                reason: true,
                start_at: true,
            })),
        })

        const lastRate = values.rates[lastIndex]
        const secondToLastRate = values.rates[secondToLastIndex]

        if (!lastRate.start_at) return

        if (lastRate?.start_at?.length && secondToLastRate?.start_at.length) {
            const isDateAfter = isAfter(lastRate.start_at, secondToLastRate.start_at)

            if (!isDateAfter) {
                setFieldError(`rates[${lastIndex}].start_at`, 'Selecione uma data posterior a anterior')

                return
            }
        }

        const hasRateWithSameReason = values.rates.some(
            (rate, index) => rate.reason === lastRate.reason && index !== lastIndex
        )

        if (hasRateWithSameReason) {
            setFieldError(`rates[${lastIndex}].reason`, 'Ocasião duplicada')
            return
        }

        const isFormValidated = validateForm()

        if (Object.keys(errors)?.length > 0 && !isFormValidated) return

        const rates = values.rates.map(rate => ({
            reason: rate.reason,
            start_at: rate.start_at,
        }))

        setFieldValue('rates', [
            ...rates,
            {
                reason: rateMultiplier.reason,
                start_at: lastRate.start_at,
            },
        ])
    }

    function removeRateInput(inputIndex: number) {
        setFieldValue(
            'rates',
            values.rates.filter((_item, index) => index !== inputIndex)
        )
    }

    function handleDateSelect(index: number) {
        return (date: Date) => {
            setFieldValue(`rates[${index}].start_at`, format(date.toString(), 'YYYY-MM-DD'))
        }
    }

    return (
        <RatesReplicationModalContainer isDisplayed={isDisplayed}>
            <RatesReplicationModalBox isEffectActive={isEffectActive}>
                <Snackbar {...snackbarProps} closeClick={closeSnackbar} />

                <RatesReplicationHeader>
                    <CloseContainer onClick={() => closeClick()}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Replicar tarifa dinâmica</ContentTitle>
                </RatesReplicationHeader>
                <RatesReplicationModalContent>
                    {values.rates?.map((_value, index, array) => (
                        <InputRow key={index}>
                            <ItemContainer>
                                <InputItem
                                    labelText="Ocasião*"
                                    inputProps={getFieldProps(`rates[${index}].reason`)}
                                    errorMessage={getFormInputError(`rates[${index}].reason`, errors, touched)}
                                />
                            </ItemContainer>
                            <ItemContainer>
                                <DateInput
                                    labelText="Data*"
                                    inputProps={{
                                        onChange: handleDateSelect(index),
                                        onSelect: handleDateSelect(index),
                                        selected: values.rates[index]?.start_at
                                            ? new Date(values.rates[index]?.start_at)
                                            : new Date(),
                                        value: values.rates[index]?.start_at
                                            ? format(values.rates[index].start_at, 'DD/MM/YYYY')
                                            : '',
                                    }}
                                    errorMessage={getFormInputError(`rates[${index}].start_at`, errors, touched)}
                                />
                            </ItemContainer>
                            <ItemContainer>
                                {array.length - 1 === index ? (
                                    <TextButton onClick={addRateInputs}>Adicionar data</TextButton>
                                ) : (
                                    <TextButton onClick={() => removeRateInput(index)}>Remover data</TextButton>
                                )}
                            </ItemContainer>
                        </InputRow>
                    ))}
                </RatesReplicationModalContent>
                <ButtonRow>
                    <TextButton onClick={() => closeClick()}>Cancelar</TextButton>
                    <ConfirmButton
                        onClick={() => {
                            if (validateForm()) {
                                onSubmit()
                            }
                        }}
                    >
                        Salvar Alterações
                    </ConfirmButton>
                </ButtonRow>
            </RatesReplicationModalBox>
            <RatesReplicationModalBackground isEffectActive={isEffectActive} onClick={() => closeClick()} />
        </RatesReplicationModalContainer>
    )
}

export default RatesReplicationModal
