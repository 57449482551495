import { memo } from 'react'

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Text } from 'components/text/text'

import { IImportOrder } from 'types/import-order'

import { Container, Table, RowTable, ColumnTable, ColumnHeader, CellHeader } from './table-order-file.styled'

const columnHelper = createColumnHelper<IImportOrder>()

const columns = [
    columnHelper.accessor('no_pedido_opc', {
        id: 'id',
        header: () => <CellHeader>Nº Pedido</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('nome', {
        id: 'name',
        header: () => <CellHeader>Nome</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('email_opc', {
        id: 'email',
        header: () => <CellHeader>E-mail</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('telefone_opc', {
        id: 'phone',
        header: () => <CellHeader>Telefone</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('valor', {
        id: 'value',
        header: () => <CellHeader>Valor</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('itens', {
        id: 'items',
        header: () => <CellHeader>Itens</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('logradouro', {
        id: 'street',
        header: () => <CellHeader>Logradouro</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('numero', {
        id: 'number',
        header: () => <CellHeader>Número</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('complemento', {
        id: 'complement',
        header: () => <CellHeader>Complemento</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('ponto_de_referencia_opc', {
        id: 'landmark',
        header: () => <CellHeader>Ponto de Referência</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
]

type Props = {
    orders: IImportOrder[]
}

const TableOrderFile: React.FC<Props> = memo(({ orders }) => {
    const table = useReactTable({
        data: orders,
        columns,
        enableColumnResizing: true,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <>
            <Container>
                <Table cellPadding={0} cellSpacing={0}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <RowTable key={headerGroup.id} gray>
                                {headerGroup.headers.map(header => (
                                    <ColumnHeader key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                    </ColumnHeader>
                                ))}
                            </RowTable>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <RowTable key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <ColumnTable key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </ColumnTable>
                                ))}
                            </RowTable>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </>
    )
})

export { TableOrderFile }
