import {
    faStoreAlt,
    faFlag,
    faCheck,
    faTimes,
    faHourglass,
    faEdit,
    faBullhorn,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const RoutedStepItemContainer = styled.div<{ itemColor?: string; isActive?: boolean }>`
    width: 40px;
    height: 40px;
    border: 3px solid ${({ theme, itemColor, isActive }) => (isActive ? itemColor : theme.colors.lightGray)};
    border-radius: 50%;
    padding: 2px;
    margin-right: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
    margin-bottom: 30px;
    &:not(:first-child) {
        &::after {
            content: '';
            width: 30px;
            height: 3px;
            background-color: ${({ theme, itemColor, isActive }) => (isActive ? itemColor : theme.colors.lightGray)};
            position: absolute;
            top: 18px;
            left: -33px;
        }
    }
`

export const StepItemContent = styled.div<{ itemColor?: string; isActive?: boolean }>`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme, itemColor, isActive }) => (isActive ? itemColor : theme.colors.lightGray)};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
`

export const StoreLogo = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`

export const StoreIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})`
    font-size: 18px;
`

export const FlagIcon = styled(FontAwesomeIcon).attrs({
    icon: faFlag,
})`
    font-size: 18px;
`

export const TagContainer = styled.div<{ error?: boolean; waiting?: boolean }>`
    width: 18px;
    height: 18px;
    border-radius: ${({ waiting }) => (waiting ? '50%' : '4px')};
    background-color: ${({ error, waiting }) => (waiting ? '#666' : error ? 'red' : 'green')};
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CheckIcon = styled(FontAwesomeIcon).attrs({
    icon: faCheck,
})`
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
`

export const ErrorIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
`

export const WaitingIcon = styled(FontAwesomeIcon).attrs({
    icon: faHourglass,
})`
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
`

export const DraftIcon = styled(FontAwesomeIcon).attrs({
    icon: faEdit,
})``

export const WaitingBikerIcon = styled(FontAwesomeIcon).attrs({
    icon: faBullhorn,
})``

export const ClientName = styled.div`
    font-size: 10px;
    position: absolute;
    top: 120%;
    text-align: center;
`

export const BikerTag = styled.div`
    width: 34px;
    height: 18px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: -7px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
`
