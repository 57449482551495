import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'

import { Route } from 'types'

import { useModalRouteFinishCTL } from './modal-route-cancel.controller'
import {
    Box,
    Container,
    ContainerButtons,
    Footer,
    Icon,
    Message,
    Separator,
    Title,
    Button,
    ContainerSelect,
} from './modal-route-cancel.styled'

const OPTIONS = [
    {
        code: 1,
        title: 'TRANSFERÊNCIA DE BASE',
    },
    {
        code: 2,
        title: 'ENTREGADOR SEM INTERNET',
    },
    {
        code: 3,
        title: 'ENTREGADOR ESQUECEU DE FINALIZAR ENTREGA',
    },
    {
        code: 4,
        title: 'ERRO DE LOCALIZAÇÃO NÃO AJUSTADO',
    },
    {
        code: 5,
        title: 'ENCERRAMENTO DE OPERAÇÃO',
    },
    {
        code: 6,
        title: 'OUTROS',
    },
]

type Params = {
    route: Route
    onSelectReason(reason: string): void
}

export type ModalRouteFinishRef = {
    show?(params: Params): void
}

interface ModalRouteFinishProps {
    onClose?(success?: boolean): void
}
const ModalRouteFinish = memo(
    forwardRef<ModalRouteFinishRef, ModalRouteFinishProps>((props, ref) => {
        const { visible, show, reason, _setOptionCancel, _onButtonClick, _setShow } = useModalRouteFinishCTL(props)

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
            }),
            [_setShow]
        )

        if (!visible) {
            return null
        }

        return (
            <Container id="global-modal" visible={visible}>
                <Box show={show}>
                    <>
                        <Title id="title-modal" className="title">
                            Finalizar rota
                        </Title>
                        <Icon icon="exclamation-circle" />
                        <Message id="message-modal">Deseja realmente finalizar está rota?</Message>

                        <ContainerSelect>
                            <InputItem
                                labelText="Motivo"
                                type="select"
                                options={OPTIONS.map(({ title, code }) => ({ label: title, value: code }))}
                                inputProps={{
                                    placeholder: 'Selecione o motivo da finalização',
                                    value: reason?.code?.toString(),
                                    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                        _setOptionCancel(
                                            OPTIONS.find(e => {
                                                return e.code === Number(value)
                                            })
                                        )
                                    },
                                }}
                            />
                        </ContainerSelect>
                        <Footer>
                            <>
                                <ContainerButtons>
                                    <Button
                                        id="yes-button"
                                        onClick={reason && _onButtonClick('yes')}
                                        disabled={!reason}
                                    >
                                        Sim
                                    </Button>
                                </ContainerButtons>
                                <Separator />
                                <ContainerButtons>
                                    <Button id="no-button" isOutline onClick={_onButtonClick('no')}>
                                        Não
                                    </Button>
                                </ContainerButtons>
                            </>
                        </Footer>
                    </>
                </Box>
            </Container>
        )
    })
)

export { ModalRouteFinish }
