import { useEffect, useState, memo } from 'react'

import { OrderDetailsContent } from './content'
import { OrderModalBackground, OrderModalBox, OrderModalContainer } from './order-integrations-details-modal.styled'

interface Props {
    isActive: boolean
    orderDetailsId: number | undefined
    closeClick(): void
}

const OrderIntegrationsDetailsModal: React.FC<Props> = memo(({ isActive, orderDetailsId, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            document.body.style.overflow = 'hidden'
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            document.body.style.overflow = 'auto'
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <OrderModalContainer isDisplayed={isDisplayed}>
            <OrderModalBox isEffectActive={isEffectActive}>
                <OrderDetailsContent closeClick={closeClick} orderDetailsId={orderDetailsId} />
            </OrderModalBox>
            <OrderModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </OrderModalContainer>
    )
})

export { OrderIntegrationsDetailsModal }
