/**
 *
 * @param {number} minutes
 */

const getStatusTypeReadyDeliver = (minutes, configs) => {
    const seconds = minutes * 60

    if (configs?.collect) {
        const { on_time, attention, late } = configs.collect

        switch (true) {
            case seconds <= on_time:
                return 'in-time'
            case seconds > on_time && seconds <= attention:
                return 'delayed'
            case seconds >= late:
                return 'late'
            default:
                return 'out-of-rote'
        }
    } else {
        return 'out-of-rote'
    }
}

export { getStatusTypeReadyDeliver }
