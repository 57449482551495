import { memo } from 'react'

import { Canvas } from 'components/canvas/canvas'
import { DefaultButton } from 'components/default-button/default-button'
import { Grid } from 'components/grid/grid'
import { Text } from 'components/text/text'

import { useIndoorContent } from './indoor-content.controller'
import { Container, ContainerTabs, ContentCanvas, Tab } from './indoor-content.styled'

const IndoorContent = memo(() => {
    const { floors, selectedFloor, selectedArea, _onSelectArea, _close, _confirm, _onSelectFloor } = useIndoorContent()

    return (
        <Container>
            <Text
                text="Preencha os dados faltantes para a alteração de tipo de entrega para:"
                marginBottom={5}
                light
                dimensions="xSmall"
            />
            <Text text="Atendimento em Mesa" variant="primary" bold dimensions="large" marginBottom={20} />
            <ContainerTabs>
                {floors.map((floor, i) => (
                    <Tab key={i} selected={floor.id === selectedFloor?.id} onClick={_onSelectFloor(floor)}>
                        {floor.label}
                    </Tab>
                ))}
            </ContainerTabs>
            <ContentCanvas>
                {!!selectedFloor && (
                    <Canvas destiny={selectedFloor} selectedId={selectedArea?.id} onSelectMap={_onSelectArea} />
                )}
            </ContentCanvas>
            <Grid.Row className="container-buttons" justify="space-between" align="center">
                <DefaultButton title="Cancelar" outline variant="danger" onClick={_close} />
                <DefaultButton title="Selecionar" disabled={!selectedArea} onClick={_confirm} />
            </Grid.Row>
        </Container>
    )
})

export { IndoorContent }
