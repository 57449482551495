import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { format } from 'date-fns'

import FlexTable from 'components/flex-table'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'

import { Establishments, Recipients } from 'containers/stone-management/types'

import { formatCurrency, showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

import './style.scss'

type PeriodType = 'day' | 'month'
type CardBrand = 'Mastercard' | 'Visa' | 'Elo' | 'Amex' | 'Hipercard'
type TransactionStatus = 'canceled' | 'processed'
type PaymentType = 1 | 2
type SplitStatus = 'processing' | 'rejected' | 'created_with_manual_adjustment' | 'processed'

interface Filters {
    profile_id: string
    period_type: PeriodType | ''
    period: string
    card_brand?: CardBrand | ''
    transaction_status?: TransactionStatus | ''
}

interface TransactionList {
    resume: {
        transactions_approved: number
        transactions_canceled: number
        total_gross_amount_canceled: string
        total_gross_amount_to_receive: string
    }
    transactions_list: Array<{
        siclos_transaction_id: string
        stone_transaction_id: string
        order_id: string
        created_at: string
        transaction_status: TransactionStatus
        transaction_amount: string
        transaction_net_amount: string
        installments_number: number
        card_brand: string
        card_holder_name: string
        card_number: string
        transaction_authorization_code: string
        payment_type: PaymentType
        conciliated: true
        split_status: SplitStatus
        installments_detail: Array<{
            profile_id: string
            profile_name: string
            installments: Array<{
                actual_installment: number
                gross_amount: string
                net_amount: string
                prevision_liquidation_date: string
                anticipated_installment: true
                anticipation_rate_amount: string
            }>
            services: Array<{
                service_name: string
                service_gross_amount: string
                service_installment_gross_amount: string
                service_net_amount: string
                service_installment_net_amount: string
                service_split_detail: {
                    split_recipient_gross_amount: string
                    split_recipient_installment_gross_amount: string
                    split_recipient_net_amount: string
                    split_recipient_installment_net_amount: string
                    split_merchant_gross_amount: string
                    split_merchant_installment_gross_amount: string
                    split_merchant_net_amount: string
                    split_merchant_installment_net_amount: string
                }
            }>
        }>
        data_of_customer_sended_on_validations: {
            document_number_validation: string
            phone_validation: string
            custom_validation1: string
            custom_validation2: string
            custom_validation3: string
            validate_before_transaction: true
        }
        data_of_amount_adjustment_applied_on_transaction: {
            transaction_original_amount: string
            transaction_adjusted_final_amount: string
            adjustment_percent: number
            adjustment_type: number
            apply_adjustment_for_payment_type: number
        }
    }>
}

const TransactionListPage: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [transactionList, setTransactionList] = useState<TransactionList>()
    const [establishments, setEstablishments] = useState<Establishments>({ establishment_list: [] })
    const [recipients, setRecipients] = useState<Recipients>({ recipients: [] })
    const [selectedEstablishment, setSelectedEstablishment] = useState('')
    const [filter, setFilter] = useState<Filters>({
        profile_id: '',
        period_type: '',
        period: '',
        card_brand: '',
        transaction_status: '',
    })
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const getTransactionList = useCallback(async (filters: Filters) => {
        try {
            if (!filters.profile_id || !filters.period_type || !filters.period) {
                setModalMessageData({
                    title: 'Atenção',
                    message:
                        'Selecione pelo menos um perfil (estabelecimento ou recebedor), tipo de período e período para filtrar',
                })

                modalMessage.current?.openModal()
                return
            }

            setLoading(true)

            const { data } = await siclosPagApi.post('/transaction/list/period', {
                profile_id: filters.profile_id,
                period_type: filters.period_type,
                period: filters.period.replaceAll('-', ''),
                card_brand: filters.card_brand,
                transaction_status: filters.transaction_status,
            })

            if (data?.error) {
                throw data.msg
            }

            setTransactionList(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [])

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const getRecipients = useCallback(
        async (selectedEstablishment: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get<Recipients>(
                    `/recipient/get-all-establishment-recipients/${selectedEstablishment}`
                )

                setRecipients(data)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/painel-malls'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    useEffect(() => {
        selectedEstablishment && getRecipients(selectedEstablishment)
    }, [getRecipients, selectedEstablishment])

    function getPaymentTypeById(payment_type: PaymentType) {
        return {
            1: 'Débito',
            2: 'Crédito',
        }[payment_type || '']
    }

    function getTransactionStatus(transaction_status: TransactionStatus): string {
        return {
            processed: 'Aprovada',
            canceled: 'Rejeitada/Cancelada',
        }[transaction_status || '']
    }

    function getSplitStatus(split_status: SplitStatus) {
        return {
            processing: 'Processando',
            rejected: 'Rejeitado',
            created_with_manual_adjustment: 'Ajuste manual feito pela Siclos',
            processed: 'Aprovado',
        }[split_status || '']
    }

    function setFilterParam(key: keyof Filters, value: string | PeriodType | TransactionStatus) {
        setFilter({ ...filter, [key]: value })
    }

    return (
        <div className="transaction-list-page">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <RowTitle
                title="Transações"
                buttonRow={[
                    {
                        label: 'Filtrar',
                        onClick: () => getTransactionList({ ...filter }),
                    },
                ]}
            >
                <div className="filters-input-stack">
                    <SelectInputForm
                        label="Estabelecimento"
                        placeholder="Filtrar por estabelecimento"
                        data={establishments.establishment_list?.map(({ id, business_name }) => ({
                            name: business_name,
                            value: id,
                        }))}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedEstablishment(value)
                            setFilterParam('profile_id', value)
                        }}
                        value={selectedEstablishment}
                    />

                    <SelectInputForm
                        label="Recebedor"
                        placeholder="Filtrar por recebedor"
                        data={recipients.recipients?.map(({ recipient_id, business_name }) => ({
                            name: business_name,
                            value: recipient_id,
                        }))}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setFilterParam('profile_id', value)
                        }}
                        value={filter?.profile_id}
                    />
                </div>

                <div className="filters-input-stack">
                    <SelectInputForm
                        label="Tipo de período"
                        data={[
                            { name: 'Dia', value: 'day' },
                            { name: 'Mês', value: 'month' },
                        ]}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setFilterParam('period_type', value)
                        }}
                        value={filter?.period_type}
                    />
                    <TextInputForm
                        label="Período*"
                        disabled={!filter.period_type}
                        type={filter.period_type === 'month' ? 'month' : 'date'}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                            setFilterParam('period', value)
                        }}
                        value={filter.period}
                    />
                </div>

                <div className="filters-input-stack">
                    <SelectInputForm
                        label="Bandeira"
                        data={[
                            { name: 'Mastercard', value: 'Mastercard' },
                            { name: 'Visa', value: 'Visa' },
                            { name: 'Elo', value: 'Elo' },
                            { name: 'Amex', value: 'Amex' },
                            { name: 'Hipercard', value: 'Hipercard' },
                        ]}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setFilterParam('card_brand', value)
                        }}
                        value={filter?.card_brand || ''}
                    />
                    <SelectInputForm
                        label="Status"
                        data={[
                            { name: 'Processada', value: 'processed' },
                            { name: 'Rejeitada/Cancelada', value: 'canceled' },
                        ]}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setFilterParam('transaction_status', value)
                        }}
                        value={filter?.transaction_status || ''}
                    />
                </div>
            </RowTitle>

            <div className="resume-container">
                <div className="title">Resumo</div>

                <div className="resume-blocks">
                    <div className="resume-block">
                        <div className="resume-item">
                            <strong> Total bruto a receber: </strong>
                            {formatCurrency(transactionList?.resume.total_gross_amount_to_receive)}
                        </div>
                        <div className="resume-item">
                            <strong> Total bruto cancelado: </strong>
                            {formatCurrency(transactionList?.resume.total_gross_amount_canceled)}
                        </div>
                    </div>

                    <div className="resume-block">
                        <div className="resume-item">
                            <strong> Transações aprovadas: </strong>
                            {transactionList?.resume.transactions_approved || 0}
                        </div>
                        <div className="resume-item">
                            <strong> Transações canceladas: </strong>
                            {transactionList?.resume.transactions_canceled || 0}
                        </div>
                    </div>
                </div>
            </div>

            <FlexTable
                columns={[
                    { name: '', width: '25%' },
                    { name: 'Valor', width: '7%' },
                    { name: 'Valor líquido', width: '7%' },
                    { name: 'Pagamento', width: '16%' },
                    { name: 'Status', width: '10%' },
                    { name: 'Split', width: '10%' },
                    { name: 'Conciliado', width: '10%' },
                    { name: 'Criação', width: '15%' },
                ]}
                list={transactionList?.transactions_list.map(item => {
                    const {
                        siclos_transaction_id,
                        stone_transaction_id,
                        order_id,
                        transaction_amount,
                        transaction_net_amount,
                        card_brand,
                        payment_type,
                        transaction_status,
                        split_status,
                        conciliated,
                        created_at,
                    } = item

                    return {
                        id: (
                            <>
                                ID Siclos: <small>{siclos_transaction_id}</small> <br />
                                ID Stone: <small>{stone_transaction_id}</small> <br />
                                ID Logaroo: <small>{order_id}</small> <br />
                            </>
                        ),
                        transaction_amount: formatCurrency(transaction_amount),
                        transaction_net_amount: formatCurrency(transaction_net_amount),
                        payment: `${card_brand} - ${getPaymentTypeById(payment_type)}`,
                        transaction_status: getTransactionStatus(transaction_status),
                        split_status: getSplitStatus(split_status),
                        conciliated: conciliated ? 'Sim' : 'Não',
                        created_at: (
                            <div style={{ display: 'flex', breakInside: 'auto' }}>
                                {format(created_at, 'DD/MM/YYYY [às] HH:mm')}
                            </div>
                        ),
                    }
                })}
            />
        </div>
    )
}

export default TransactionListPage
