import { useRef, useCallback } from 'react'
import * as Feather from 'react-feather'
import { useHistory } from 'react-router'

import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImportOrdersModal, ImportOrdersModalRef } from 'modals/import-orders-modal/import-orders-modal'
import { PrinterConfigModal, PrinterConfigModalRef } from 'modals/printer-config-modal/printer-config-modal'

import { RefreshButton } from 'components/_common/'
import { DefaultButton } from 'components/default-button/default-button'

import { useAuth } from 'hooks'

import {
    NavigationRowContainer,
    NavigationLeftSide,
    NumbersContainer,
    NumberItem,
    NumberValue,
    NumberLabel,
    ButtonContainer,
} from './store-navigation-row.styles'

interface Props {
    inProductionValue?: number
    deliveredValue?: number
    openCollectModal?(): void
    isRefreshing?: boolean
    refresh?(): void
}

const StoreNavigationRow: React.FC<Props> = ({
    inProductionValue,
    deliveredValue,
    openCollectModal,
    isRefreshing,
    refresh,
}) => {
    const history = useHistory()
    const { pathname } = history.location
    const isInRouteActive = pathname.includes('/coleta')

    const { mall, store } = useAuth()

    const importOrdersModalRef = useRef<ImportOrdersModalRef>(null)
    const printerConfigModalRef = useRef<PrinterConfigModalRef>(null)

    const _requestDeliveryClick = useCallback(() => {
        const url = new URL(window.location.href)
        if (!url.searchParams.has('modal-request-order')) {
            url.searchParams.append('modal-request-order', '')
            history.push({ search: url.search })
        }
    }, [history])

    const _openKDS = useCallback(() => {
        history.push(`/${mall.slug}/${store.slug}/kds`)
    }, [history, store, mall])

    return (
        <>
            <NavigationRowContainer>
                <NavigationLeftSide>
                    {/* <MobileSelectTab
                    defaultValue={pathname.split('/').pop()}
                    onChange={({ target: { value } }) => history.push(value)}
                >
                    {hasMarketPlaceEnabled && <option value="novos">Novos</option>}
                    <option value="em-andamento">Em andamento</option>
                    {hasPickerOps && <option value="coleta">Coleta</option>}
                </MobileSelectTab> */}
                    {/* <ButtonsContainer>
                    {hasMarketPlaceEnabled && (
                        <NavigationItemContainer isActive={isNewsActive}>
                            <NavigationItem onClick={newsClick} isActive={isNewsActive}>
                                Novos
                            </NavigationItem>
                            {newOrdersValue ? <NewItemIcon>!</NewItemIcon> : null}
                        </NavigationItemContainer>
                    )}
                    <NavigationItemContainer isActive={isProductionActive}>
                        <NavigationItem onClick={productionClick} isActive={isProductionActive}>
                            Em andamento
                        </NavigationItem>
                        {inProductionValue ? <NewItemIcon>!</NewItemIcon> : null}
                    </NavigationItemContainer>
                    {hasPickerOps && (
                        <NavigationItemContainer isActive={isInRouteActive}>
                            <NavigationItem onClick={inRouteClick} isActive={isInRouteActive}>
                                Coleta
                            </NavigationItem>
                            {deliveredValue ? <NewItemIcon>!</NewItemIcon> : null}
                        </NavigationItemContainer>
                    )}
                </ButtonsContainer> */}
                </NavigationLeftSide>
                <ButtonContainer>
                    <DefaultButton onClick={printerConfigModalRef.current?.show} widthFitContainer simple>
                        <Feather.Printer /> Impressora
                    </DefaultButton>
                    <DefaultButton onClick={() => importOrdersModalRef.current?.show()} widthFitContainer simple>
                        <Feather.File /> Importar pedidos
                    </DefaultButton>
                    <DefaultButton onClick={_requestDeliveryClick} widthFitContainer simple>
                        <FontAwesomeIcon className="icon" icon="motorcycle" />
                        Solicitar Entrega
                    </DefaultButton>
                    <DefaultButton onClick={_openKDS} widthFitContainer simple>
                        <FontAwesomeIcon className="icon" icon="columns" />
                        Produção
                    </DefaultButton>
                    {/* <StoreStatus status={storeStatus} statusClick={storeStatusClick} /> */}
                    {isInRouteActive && (
                        <DefaultButton onClick={openCollectModal} widthFitContainer simple>
                            Solicitar coleta
                        </DefaultButton>
                    )}
                </ButtonContainer>
                <NumbersContainer>
                    <NumberItem>
                        <NumberValue>{inProductionValue}</NumberValue>
                        <NumberLabel>Pedidos em andamento</NumberLabel>
                    </NumberItem>
                    <NumberItem>
                        <NumberValue>{deliveredValue}</NumberValue>
                        <NumberLabel>Pedidos entregues</NumberLabel>
                    </NumberItem>
                    <RefreshButton
                        icon={faSync}
                        isRefreshing={isRefreshing}
                        label="Atualizar informações"
                        onClick={refresh}
                        type="link"
                    />
                </NumbersContainer>
            </NavigationRowContainer>
            <ImportOrdersModal ref={importOrdersModalRef} />
            <PrinterConfigModal ref={printerConfigModalRef} />
        </>
    )
}

export default StoreNavigationRow
