import styled from 'styled-components'

const PopupContainer = styled.div<{ areaColor?: string }>`
    border-radius: 4px;
    background-color: #f1f5fe;
    padding: 10px;
    border: 1px solid #ccc;
    border-top: 6px solid;
    border-top-color: ${({ theme, areaColor }) => (areaColor ? areaColor : theme.colors.primary)};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
    overflow: hidden;
`

const PopupTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`

export { PopupContainer, PopupTitle }
