import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #c6c6c6;
`

const Header = styled.div`
    width: 100%;
    background-color: #fff;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #fff;
    .button {
        align-self: center;
        margin-top: 20px;
    }
`

export { Container, Header, Content }
