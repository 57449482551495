// import colors from '../../themes/colors'
// import Button from '../button/Button'
import './style.scss'

const DelivererCard = ({
    orderNumber,
    delivererPhoto,
    delivererName,
    delivererVehicle,
    clientName,
    clientAddress,
    orderDate,
    orderTime,
    routeTime,
    // onConfirmCollect,
}) => {
    return (
        <div className="deliverer-card">
            <div className="card-box"></div>
            <div className="deliverer-title-row">
                <div className="photo">
                    <img src={delivererPhoto} alt="" />
                </div>
                <div className="number">#{orderNumber}</div>
            </div>

            <div className="deliverer-info">
                <div className="deliverer-name">{delivererName}</div>
                <div className="deliverer-vehicle">{delivererVehicle}</div>
            </div>

            <div className="info-item">
                <div className="label">Cliente</div>
                <div className="content">{clientName}</div>
            </div>

            <div className="info-item">
                <div className="label">Endereço</div>
                <div className="content">{clientAddress}</div>
            </div>

            <div className="info-row">
                <div className="info-item">
                    <div className="label">Pedido feito em</div>
                    <div className="content">
                        {orderDate} às {orderTime}
                    </div>
                </div>

                <div className="info-item">
                    <div className="label">Tempo de rota</div>
                    <div className="content">{routeTime}</div>
                </div>
            </div>

            {/* <Button
                bgColor={colors.primary_color}
                buttonText="CONFIRMAR COLETA"
                textColor={'white'}
                onClick={onConfirmCollect}
                width="230px"
            >
                Confirmar coleta
            </Button> */}
        </div>
    )
}

export default DelivererCard
