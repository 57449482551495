import { useEffect, useState, memo } from 'react'

import { IntegrationType } from 'types/integration'
import { IOriginators } from 'types/originators'

import { AuthIntegratorContent } from './auth-integrator-content/auth-integrator-content'
import {
    AuthIntegratorModalBackground,
    AuthIntegratorModalBox,
    AuthIntegratorModalContainer,
} from './auth-integrator-modal.styled'

interface Props {
    origin?: IOriginators
    isActive: boolean
    closeClick(isRefresh?: boolean): void
}

const AuthIntegratorModal: React.FC<Props> = memo(({ isActive, closeClick, origin }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <AuthIntegratorModalContainer isDisplayed={isDisplayed}>
            <AuthIntegratorModalBox isEffectActive={isEffectActive}>
                <AuthIntegratorContent origin={origin} isActive={isActive} closeClick={closeClick} />
            </AuthIntegratorModalBox>
            <AuthIntegratorModalBackground isEffectActive={isEffectActive} onClick={() => closeClick()} />
        </AuthIntegratorModalContainer>
    )
})

export { AuthIntegratorModal }
