import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const LoadingButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    padding: 10px;
    width: 170px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 400ms;
    user-select: none;
    text-transform: uppercase;
    align-self: center;
    font-weight: bold;
    text-align: center;
    &:hover {
        opacity: 0.6;
    }
`

export const LoadingIcon = styled(FontAwesomeIcon).attrs({
    icon: faSpinner,
})`
    font-size: 20px;
    animation-name: spin;
    animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`
