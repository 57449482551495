import { memo, useCallback } from 'react'

import { format } from 'date-fns'

import { formatCurrency } from 'helpers'
import { RateMultiplier } from 'types'

import {
    ContainerButton,
    Button,
    Container,
    Column,
    Reason,
    ContainerDayWeek,
    BoxDayWeek,
    ContainerHours,
    ContainerValue,
    LabelValue,
    TextValue,
    Status,
    IconValue,
    Row,
} from './dynamic-rates-table-row.styled'

type Props = {
    rate: RateMultiplier
    isSingle?: boolean
    editClick?: () => void
    deleteClick?: () => void
}

const typeVehicle = ['bike', 'moto', 'car', 'van', 'truck']

const Vehicles = {
    bike: 'Bicicleta',
    moto: 'Moto',
    car: 'Carro',
    van: 'Van',
    truck: 'Caminhão',
}
const VehiclesIcon = {
    bike: 'bicycle',
    moto: 'motorcycle',
    car: 'car',
    van: 'shuttle-van',
    truck: 'truck',
}

const DynamicRatesTableRow: React.FC<Props> = memo(({ rate, isSingle, editClick, deleteClick }) => {
    const { agent, store } = rate.value

    const _formatPeriod = useCallback(
        (date: string) => {
            return format(date, isSingle ? 'DD/MM/YYYY HH:mm' : 'HH:mm')
        },
        [isSingle]
    )

    return (
        <Container>
            <Column fitContent widthFix={50}>
                {rate.id}
            </Column>
            <Column fitContent widthFix={300}>
                <Reason>{rate.reason}</Reason>
                <Status active={rate.status === 1}>{rate.status === 1 ? 'Ativo' : 'Inativo'}</Status>
            </Column>
            {!isSingle && (
                <Column fitContent widthFix={140} center>
                    <ContainerDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('dom')}>D</BoxDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('seg')}>S</BoxDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('ter')}>T</BoxDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('qua')}>Q</BoxDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('qui')}>Q</BoxDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('sex')}>S</BoxDayWeek>
                        <BoxDayWeek active={rate.week_days?.includes('sab')}>S</BoxDayWeek>
                    </ContainerDayWeek>
                </Column>
            )}
            <Column fitContent widthFix={160} center>
                <ContainerHours>{_formatPeriod(rate.start_at)}</ContainerHours>
                <ContainerHours>{_formatPeriod(rate.finish_at)}</ContainerHours>
            </Column>
            <Column>
                <Row>
                    {typeVehicle.map((type, i) => (
                        <ContainerValue key={i}>
                            <LabelValue>{Vehicles[type]}</LabelValue>
                            <TextValue>
                                <IconValue icon="store" /> {formatCurrency(store[type])}
                            </TextValue>
                            <TextValue>
                                <IconValue icon={VehiclesIcon[type]} />
                                {formatCurrency(agent[type])}
                            </TextValue>
                        </ContainerValue>
                    ))}
                </Row>
            </Column>
            <Column fitContent>
                <ContainerButton>
                    <Button outline onClick={editClick}>
                        Editar
                    </Button>
                    <Button onClick={deleteClick}>Remover</Button>
                </ContainerButton>
            </Column>
        </Container>
    )
})

type HeaderProps = {
    isSingle?: boolean
}
const DynamicRatesTableHeader: React.FC<HeaderProps> = memo(({ isSingle }) => {
    return (
        <Container>
            <Column fitContent widthFix={50}>
                ID
            </Column>
            <Column fitContent widthFix={300}>
                Ocasião
            </Column>
            {!isSingle && (
                <Column fitContent widthFix={140} center>
                    Dias da semana
                </Column>
            )}
            <Column fitContent widthFix={160} center>
                Período
            </Column>
            <Column>Valores</Column>
            <Column fitContent></Column>
        </Container>
    )
})

export { DynamicRatesTableRow, DynamicRatesTableHeader }
