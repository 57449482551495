import { Map, Marker, Popup } from 'mapbox-gl'

import { CustomMarker } from '../types'

export function addMultipleMarkers(map: Map, customMarkers: CustomMarker[]): Marker[] {
    const markers = customMarkers.map(({ coordinates, image, popupContent }) => {
        const imageElement = document.createElement('img') as HTMLImageElement
        imageElement.src = image.src
        imageElement.width = image.size || 35

        const popup = new Popup({ offset: 25, closeButton: false })

        const popupElement = document.createElement('div')
        popupElement.innerHTML = popupContent
        popupElement.addEventListener('click', () => {
            popup.remove()
        })

        popup.setLngLat(coordinates).setDOMContent(popupElement)

        const marker = new Marker({
            element: imageElement,
        })
            .setLngLat([coordinates.lng, coordinates.lat])
            .setPopup(popup)
            .addTo(map)

        return marker
    })

    return markers
}
