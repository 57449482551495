import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import SourceMapPin from 'assets/images/map-pin.png'

const Container = styled.div`
    .client-info {
        width: fit-content !important;
    }
    .time-info {
        width: fit-content !important;
        .total {
            font-size: 12px !important;
        }
    }
    .order-info {
        .primary {
            color: ${({ theme }) => theme.colors.primary};
        }
        .black-text {
            align-items: center;
            .icon {
                color: ${({ theme }) => theme.colors.primary};
                font-size: 15px;
                margin-left: 5px;
                margin-bottom: 1px;
            }
        }
    }
`

const ContainerButtons = styled.div`
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    align-items: flex-start !important;
    width: fit-content;
    width: 120px;
    @media (min-width: 1200px) {
        align-items: flex-end !important;
    }
`

const RejectButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    text-align: center;
    color: ${({ theme, orange }) => (orange ? theme.colors.darkOrange : theme.colors.darkGray)};
    font-weight: normal;
    padding: 0;
    font-size: 12px;
    margin: 4px 0px;
    &:hover {
        text-decoration: underline;
    }
    span {
        margin-right: 4px;
    }
`

const AcceptButton = styled.div`
    text-align: center;
    background-color: ${({ theme, orange }) => (orange ? theme.colors.darkOrange : theme.colors.success)};
    color: ${({ theme }) => theme.colors.white};
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 200ms;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    &:hover {
        opacity: 0.8;
    }
    @media (min-width: 1200px) {
        margin-top: 0px;
    }
`

const DetailIcon = styled(FontAwesomeIcon).attrs({
    icon: faFileUpload,
})``

const Collect = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    .collect {
        span {
            font-weight: bold;
        }
    }
`

const MapImage = styled.img.attrs({ src: SourceMapPin })`
    width: 16px;
    height: 16px;
    margin-right: 2px;
    object-fit: contain;
`

export { Collect, RejectButton, AcceptButton, Container, ContainerButtons, DetailIcon, MapImage }
