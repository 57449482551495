import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'

import { format } from 'date-fns'

import { SwitchButton } from 'components/_marketplace-management'
import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'
import { WeekDaysSelect } from 'components/week-days-select/week-days-select'

import { ContentHeader, ContentHeaderTitle } from 'containers/marketplace-management/marketplace-management.styles'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { IScheduleTypes, IScheduledPeriod } from 'types/scheduledPeriods'

import { OpeningHoursModal, OpeningHoursModalRef } from './components/opening-hours-modal/opening-hours-modal'
import { ButtonRow, Container, Content, DeleteIcon, PeriodContainer, PeriodItem } from './opening-hours.styled'

const OpeningHours: FC = memo(_ => {
    const { store } = useAuth()
    const { setErrorModal, setLoading, setConfirmationModal } = useUI()
    const openingHoursModalRef = useRef<OpeningHoursModalRef>()

    const [schedules, setSchedules] = useState<IScheduledPeriod<'store'>[]>()

    const _openModal = useCallback(() => {
        openingHoursModalRef.current.show()
    }, [])

    const _openEdit = useCallback(
        (schedule: IScheduledPeriod<'store'>) => () => {
            _openModal()
            openingHoursModalRef.current.setSchedule(schedule)
        },
        []
    )

    const _getSchedules = useCallback(
        async (type: IScheduleTypes = 'starting') => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<IScheduledPeriod<'store'>>>('/painel/schedules/stores', {
                    params: {
                        store_id: store.id,
                        type,
                    },
                })

                setSchedules(data.items)
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível listar os horários de atendimento',
                })
            } finally {
                setLoading(false)
            }
        },
        [store]
    )

    const _handleCloseModal = useCallback(
        (refresh?: boolean) => {
            if (refresh) {
                _getSchedules()
            }

            openingHoursModalRef.current?.close()
        },
        [_getSchedules]
    )

    const _handleDelete = useCallback((id: number) => {
        const deleteSchedule = async () => {
            openingHoursModalRef.current?.close()
            setLoading(true)

            try {
                await api.delete(`/painel/schedules/${id}`)

                _getSchedules()
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir a horário de funcionamento!',
                })
            }
            setLoading(false)
        }

        return (): void => {
            setConfirmationModal({
                title: 'Excluir horário de funcionamento',
                subtitle: 'Tem certeza de que deseja excluir este horário de funcionamento?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: deleteSchedule,
            })
        }
    }, [])

    useEffect(() => {
        _getSchedules()
    }, [_getSchedules])

    return (
        <Container>
            <OpeningHoursModal ref={openingHoursModalRef} onClose={_handleCloseModal} />
            <ContentHeader>
                <ContentHeaderTitle>Horários de Funcionamento</ContentHeaderTitle>
                <DefaultButton widthFitContainer onClick={_openModal}>
                    Criar Horário
                </DefaultButton>
            </ContentHeader>
            <Content>
                <FlexTable
                    columns={[
                        { name: 'ID', width: '10%', style: { display: 'flex', justifyContent: 'start' } },
                        { name: 'Nome', width: '15%', style: { display: 'flex', justifyContent: 'center' } },
                        {
                            name: 'Período',
                            width: '20%',
                            style: { display: 'flex', justifyContent: 'center', textAlign: 'center' },
                        },
                        { name: 'Dias das Semana', width: '20%', style: { display: 'flex', justifyContent: 'center' } },
                        // { name: 'Status', width: '10%' },
                        { name: '', width: '15%' },
                    ]}
                    list={schedules?.map(schedule => ({
                        id: String(schedule.id),
                        name: schedule.label,
                        period: (
                            <PeriodContainer>
                                {schedule.start_date && schedule.end_date && (
                                    <PeriodItem>
                                        {format(schedule.start_date, 'DD/MM/YYYY')} até{' '}
                                        {format(schedule.end_date, 'DD/MM/YYYY')}
                                    </PeriodItem>
                                )}
                                <PeriodItem>
                                    {schedule.start_time?.substring(0, 5)} às {schedule.end_time?.substring(0, 5)}
                                </PeriodItem>
                            </PeriodContainer>
                        ),
                        days_on_the_week: <WeekDaysSelect weekdays={schedule?.week_days} />,
                        // status: <SwitchButton isActive />,
                        buttonRow: (
                            <ButtonRow>
                                <DefaultButton title="Editar" widthFitContainer outline onClick={_openEdit(schedule)} />
                                <DefaultButton widthFitContainer variant="danger" onClick={_handleDelete(schedule.id)}>
                                    <DeleteIcon />
                                </DefaultButton>
                            </ButtonRow>
                        ),
                    }))}
                />
            </Content>
        </Container>
    )
})

export { OpeningHours }
