import { StatusCircle, StatusContainer, StatusText } from './store-status.styles'

interface Props {
    status: number
    statusClick(): void
}

const StoreStatus: React.FC<Props> = ({ status, statusClick }) => {
    return (
        <StatusContainer onClick={statusClick}>
            <StatusCircle status={status} />
            <StatusText>{status === 1 ? 'Loja Aberta' : 'Loja Fechada'}</StatusText>
        </StatusContainer>
    )
}

export default StoreStatus
