import { useCallback, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'
import { IAgent } from 'types/agent'

type Form = {
    modality?: string
}

const SELECT_OPTIONS = [
    { label: 'Bicicleta', value: 2 },
    { label: 'Moto', value: 1 },
    { label: 'Carro', value: 3 },
    { label: 'Van', value: 4 },
    { label: 'Caminhão', value: 5 },
]

function useChangeAgentModalityController(callback: () => void) {
    const { setSuccessModal, setErrorModal } = useUI()
    const [visible, setVisible] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [selectedAgent, setSelectedAgent] = useState<IAgent>()

    const { values, setFieldValue, handleSubmit, resetForm } = useFormik<Form>({
        initialValues: {},
        validationSchema: Yup.object().shape({}),
        onSubmit: async (values: Form) => {
            try {
                await api.put('/painel/agent/change-category', {
                    agent_id: selectedAgent.id,
                    type: values.modality,
                })

                setSuccessModal({
                    title: 'Sucesso',
                    subtitle: 'Modalidade de entregador altuazada',
                    singleButtonClick() {
                        callback()
                        _close()
                    },
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: showErrors(error),
                })
            }
        },
    })

    const _setShow = useCallback((agent: IAgent) => {
        setShow(true)
        setSelectedAgent(agent)
    }, [])

    const _close = useCallback(() => {
        resetForm()
        setShow(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleChangeModality = useCallback(({ target: { value } }) => {
        setFieldValue('modality', value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
            setVisible(true)
            setTimeout(() => {
                setShow(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setShow(false)
            setTimeout(() => {
                setVisible(false)
                setSelectedAgent(undefined)
            }, 300)
        }

        return () => {
            document.body.style.overflow = 'auto'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return {
        selectOptions: SELECT_OPTIONS,
        selectedAgent,
        show,
        values,
        visible,
        _setShow,
        _close,
        _handleChangeModality,
        handleSubmit,
    }
}

export { useChangeAgentModalityController }
