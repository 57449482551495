import { useEffect, useMemo, useState } from 'react'

import { ProductDetailsContent, SwitchButton, ProductDishesContent } from '..'

import { useHistory } from 'react-router'

import { AxiosError } from 'axios'
import { useFormikContext } from 'formik'

import { Snackbar } from 'components/_common'
import ActionModal from 'components/_common/action-modal'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useDataFetching } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { Product, ProductFormFields, ProductPart, SideDish } from 'types/marketplace'

import DuplicateGroupModal from '../duplicate-group-modal'
import EditGroupModal from '../edit-group-modal'

import {
    ContentTitle,
    EditProductModalBox,
    EditProductModalContent,
    CloseButton,
    CloseContainer,
    EditProductHeader,
    OrderStatusContainer,
    TabsContainer,
    TabItem,
    ButtonRow,
    TextButton,
    ConfirmButton,
} from './edit-product-modal.styles'

interface Props {
    closeClick(): void
    isEffectActive: boolean
    products: Product[] | undefined
    updateProductStatus(productId: number, status: number): void
}

const ProductFormContent: React.FC<Props> = ({ closeClick, isEffectActive, products, updateProductStatus }) => {
    const {
        actionModalProps,
        closeActionModal,
        closeSnackbar,
        displayLoading,
        setConfirmationModal,
        setErrorModal,
        setLoading,
        setSnackbar,
        snackbarProps,
    } = useUI()

    const history = useHistory()
    const { pathname } = history.location

    const [isDetailsActive, setIsDetailsActive] = useState(true)
    const [isDuplicateModalActive, setIsDuplicateModalActive] = useState(false)

    const { resetForm, setFieldValue, submitForm, values, setValues, initialValues } =
        useFormikContext<ProductFormFields>()

    function handleRequestError(error: AxiosError) {
        setErrorModal({
            title: 'Erro',
            subtitle: showErrors(error),
        })
    }

    function addProductPartOnForm(productPart: ProductPart) {
        const updatedParts = [
            ...values.parts.slice(0, productPart.order - 1),
            productPart,
            ...values.parts.slice(productPart.order - 1),
        ]

        setFieldValue(
            'parts',
            updatedParts.map((part, index) => ({ ...part, order: index + 1 }))
        )
    }

    function editProductPartOnForm(productPart: ProductPart, productPartIndex: number | undefined) {
        if (productPartIndex === undefined) {
            return
        }

        const updatedParts = values.parts.map((item, index) => (productPartIndex === index ? productPart : item))

        updatedParts.splice(productPartIndex, 1)
        updatedParts.splice(productPart.order - 1, 0, productPart)

        setFieldValue(
            'parts',
            updatedParts.map((part, index) => ({ ...part, order: index + 1 }))
        )
    }

    function removeProductPartOnForm(productPartIndex: number | undefined) {
        const updatedParts = values.parts.filter((_part, index) => productPartIndex !== index)

        setFieldValue(
            'parts',
            updatedParts.map((part, index) => ({ ...part, order: index + 1 }))
        )
    }

    async function handleAddProductPart(productPart: ProductPart) {
        try {
            setLoading(true)

            if (productId) {
                const { data } = await api.post('/painel/product-part', {
                    ...productPart,
                    product_id: productId,
                })

                setSnackbar({ message: 'Grupo de acompanhamento adicionado' })

                addProductPartOnForm({ ...productPart, id: data.id })
                return
            }

            addProductPartOnForm(productPart)
        } catch (error) {
            handleRequestError(error)
        } finally {
            setLoading(false)
        }
    }

    async function handleEditProductPart(productPart: ProductPart, productPartIndex: number | undefined) {
        try {
            setLoading(true)

            if (productId && productPart?.id) {
                await api.put(`/painel/product-part/${productPart.id}`, {
                    ...productPart,
                    product_id: productId,
                })

                setSnackbar({ message: 'Grupo de acompanhamento atualizado' })
            }

            editProductPartOnForm(productPart, productPartIndex)
        } catch (error) {
            handleRequestError(error)
        } finally {
            setLoading(false)
        }
    }

    function onDeleteProductPart(productPartId: number, productPartIndex: number | undefined) {
        async function handle() {
            try {
                setLoading(true)

                if (productId && productPartId) {
                    await api.delete(`/painel/product-part/${productPartId}`, {
                        data: {
                            product_id: productId,
                        },
                    })

                    setSnackbar({ message: 'Grupo de acompanhamento atualizado' })
                }

                removeProductPartOnForm(productPartIndex)
            } catch (error) {
                handleRequestError(error)
            } finally {
                setLoading(false)
            }
        }

        setConfirmationModal({
            title: 'Excluir grupo de acompanhamento',
            subtitle: 'Deseja mesmo excluir este grupo de acompanhamento do produto',
            type: 'delete',
            leftButtonText: 'Fechar',
            rightButtonText: 'Sim, excluir',
            rightButtonClick: handle,
        })
    }

    const productId = useMemo(() => {
        const editingMatch = pathname.match(new RegExp(/editar-produto\/\d+/g))

        if (!editingMatch) return undefined

        const productId = editingMatch[0].split('/').pop()

        return Number(productId)
    }, [pathname])

    useEffect(() => {
        resetForm()
        setIsDetailsActive(true)
    }, [productId, resetForm])

    function importClick() {
        if (productId) {
            setIsDuplicateModalActive(true)
        } else {
            setErrorModal({
                title: 'Impossivel importar',
                subtitle: 'Salve o produto para importar acompanhamentos',
            })
        }
    }

    const { loading, revalidate } = useDataFetching<Product>(productId ? `/painel/product/${productId}` : null, {
        onSuccess: (product: Product) => {
            setValues({
                ...initialValues,
                ...product,
                photo: product.photo as string,
                id: product.id.toString(),
                description: product.description || '',
                category_id: product.category.id.toString(),
                price: product.price.toFixed(2),
                price_offer: product.price_offer.toFixed(2),
                starts_at: product.starts_at?.toFixed(2),
                stock: product.stock,
                is_offer: product.is_offer ? 1 : 0,
                ideal_for: product.ideal_for || 0,
                tags: product.tags.map(tag => tag.id),
                shifts: product.shifts.map(shift => shift.id),
                is_order_token: product.is_order_token || 0,
                parts: product.parts.map(part => ({
                    ...part,
                    side_dishes: part.side_dishes,
                })),
            })
        },
    })

    return (
        <>
            <EditProductModalBox isEffectActive={isEffectActive}>
                <EditProductHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>{productId ? 'Editar produto' : 'Novo produto'}</ContentTitle>
                    <OrderStatusContainer>
                        <SwitchButton
                            isActive={Boolean(values.status)}
                            onClick={() =>
                                productId
                                    ? updateProductStatus(productId, values.status ? 0 : 1)
                                    : setFieldValue('status', values.status ? 0 : 1)
                            }
                        />
                    </OrderStatusContainer>
                </EditProductHeader>
                <TabsContainer>
                    <TabItem onClick={() => setIsDetailsActive(true)} isActive={isDetailsActive}>
                        Detalhes do Produto
                    </TabItem>
                    <TabItem onClick={() => setIsDetailsActive(false)} isActive={!isDetailsActive}>
                        Acompanhamentos
                    </TabItem>
                </TabsContainer>
                <EditProductModalContent>
                    {isDetailsActive ? (
                        <ProductDetailsContent
                            productId={productId}
                            products={products}
                            closeClick={closeClick}
                            loading={loading}
                        />
                    ) : (
                        <ProductDishesContent productId={productId} onEditProductPart={handleEditProductPart} />
                    )}
                </EditProductModalContent>
                <ButtonRow>
                    {isDetailsActive ? (
                        <>
                            <TextButton>Cancelar</TextButton>
                            <ConfirmButton onClick={() => submitForm()}>Salvar Alterações</ConfirmButton>
                        </>
                    ) : (
                        <>
                            <TextButton onClick={() => importClick()}>Importar de outro produto</TextButton>
                            <ConfirmButton onClick={() => history.push(`${pathname}/criar-grupo`)}>
                                Criar novo Grupo
                            </ConfirmButton>
                        </>
                    )}
                </ButtonRow>
            </EditProductModalBox>

            <EditGroupModal
                isActive={pathname.includes('editar-grupo') || pathname.includes('criar-grupo')}
                onAddProductPart={handleAddProductPart}
                onEditProductPart={handleEditProductPart}
                onDeleteProductPart={onDeleteProductPart}
                handleRequestError={handleRequestError}
                closeClick={() => history.goBack()}
            />

            <DuplicateGroupModal
                isActive={isDuplicateModalActive}
                closeClick={() => setIsDuplicateModalActive(false)}
                productId={productId}
                revalidateProduct={revalidate}
            />

            <Snackbar {...snackbarProps} closeClick={closeSnackbar} />
            <ActionModal {...actionModalProps} closeClick={closeActionModal} />
            <ModalLoading visible={displayLoading} />
        </>
    )
}

export default ProductFormContent
