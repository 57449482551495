import styled from 'styled-components'

export const GeneralVisionContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: 100vh;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const Teste = styled.div`
    flex: 1;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    background-color: red;
`

export const ContentContainer = styled.div`
    flex: 1;
    min-height: 0;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        justify-content: space-between;
    }
`
