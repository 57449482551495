import styled from 'styled-components'

export const BurgerButtonContainer = styled.div`
    width: 20px;
    cursor: pointer;
    user-select: none;
    position: relative;
    z-index: 3;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding-top: 12px;
    }
`

export const BurgerTopLine = styled.div<{ isActive?: boolean }>`
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: #666;
    margin-bottom: 5px;
    transition: 400ms;
    margin-left: ${({ isActive }) => (isActive ? '10px' : 0)};
    transform: rotate(${({ isActive }) => (isActive ? '45deg' : 0)})
        translateY(${({ isActive }) => (isActive ? '10px' : 0)});
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        background-color: ${({ theme }) => theme.colors.white};
    }
`

export const BurgerMidLine = styled.div<{ isActive?: boolean }>`
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: #666;
    margin-bottom: 5px;
    transition: 400ms;
    opacity: ${({ isActive }) => (isActive ? 0 : 1)};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        background-color: ${({ theme }) => theme.colors.white};
    }
`

export const BurgerBottomLine = styled.div<{ isActive?: boolean }>`
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: #666;
    transition: 400ms;
    margin-left: ${({ isActive }) => (isActive ? '10px' : 0)};
    transform: rotate(${({ isActive }) => (isActive ? '-45deg' : 0)})
        translateY(${({ isActive }) => (isActive ? '-11px' : 0)});
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        background-color: ${({ theme }) => theme.colors.white};
    }
`
