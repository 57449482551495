import React, { memo } from 'react'

import { Actions, Color, HeaderContainer, ID, Label, Status, Type } from './payments-listing-table-header.styled'

const PaymentsListingTableHeader: React.FC = memo(() => {
    return (
        <HeaderContainer>
            <ID>ID</ID>
            <Label>Forma de pagamento</Label>
            <Type>Tipo</Type>
            <Color>Cor</Color>
            <Status>Status</Status>
            <Actions />
        </HeaderContainer>
    )
})

export { PaymentsListingTableHeader }
