import { forwardRef, useRef, useImperativeHandle, useState, memo, useCallback } from 'react'
import * as Feather from 'react-feather'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import { ModalRomaneioReject, ModalRomaneioRejectRef } from 'modals/modal-romaneio-reject/modal-romaneio-reject'

import { DefaultButton } from 'components/default-button/default-button'
import ModalLoading from 'components/modal-loading'
import { Text } from 'components/text/text'
import { ViewInfo } from 'components/view-info/view-info'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import { plugApi } from 'services/api'
import { IRomaneioSimple } from 'types/romaneio-simple'

import {
    Container,
    SectionTitle,
    ContainerDetails,
    ContainerInfo,
    CardInfo,
    DocIcon,
    Footer,
    ContainerCard,
    ContianerButton,
    ContainerAmounts,
} from './modal-romaneio.styled'
type Params = {
    romaneio: IRomaneioSimple
    onChange(romaneio: IRomaneioSimple, type: 'add' | 'update' | 'delete'): void
}
export type ModalRomaneioRef = {
    show?(params?: Params): void
    close?(): void
}

// eslint-disable-next-line react/display-name
const ModalRomaneio = memo(
    forwardRef<ModalRomaneioRef, any>((props, ref) => {
        const history = useHistory()

        const modalRomaneioRejectRef = useRef<ModalRomaneioRejectRef>()

        const { store, mall } = useAuth()

        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)
        const onUpdateRef = useRef<(importFile: IRomaneioSimple, type: 'add' | 'update' | 'delete') => void>()

        const { setConfirmationModal, setErrorModal } = useUI()

        const [romaneio, setRomaneio] = useState<IRomaneioSimple>()
        const [loading, setLoading] = useState(false)

        useImperativeHandle(
            ref,
            () => ({
                show: params => {
                    setRomaneio(params?.romaneio)
                    onUpdateRef.current = params.onChange
                    lateralModalBaseRef.current.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [lateralModalBaseRef]
        )

        const _onClose = useCallback(() => {
            onUpdateRef.current = undefined
            modalRomaneioRejectRef.current?.close()
            setRomaneio(undefined)
            setLoading(false)
        }, [])

        const _preview = useCallback(() => {
            history.push(`/${mall.slug}/${store.slug}/visualizar-romaneio/${romaneio.id}`)
        }, [history, romaneio, mall, store])

        const _accept = useCallback(() => {
            const callback = async () => {
                setLoading(true)
                try {
                    await plugApi.put<{ message: string; status: boolean }>(`/webhooks/${romaneio.id}`, {
                        status: 'accept',
                        type: 'azul-cargo',
                    })
                    if (onUpdateRef.current) {
                        onUpdateRef.current(romaneio, 'delete')
                    }
                    lateralModalBaseRef.current?.close()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao aceitar romaneio',
                        subtitle: showErrors(error),
                    })
                }

                setLoading(false)
            }

            setConfirmationModal({
                title: 'Aceitar romaneio',
                subtitle: 'Deseja realmente aceitar este romaneio?',
                type: 'alert',
                modalIcon: 'check-circle',
                rightButtonText: 'Sim, aceitar',
                rightButtonClick: callback,
                leftButtonText: 'Não',
            })
        }, [romaneio, setErrorModal, setConfirmationModal])

        return (
            <>
                <LateralModalBase
                    ref={lateralModalBaseRef}
                    title="Romaneio"
                    onClose={_onClose}
                    headerComponent={
                        <>
                            <Footer>
                                <ContianerButton>
                                    <DefaultButton widthFitContainer simple onClick={_preview}>
                                        <Feather.Eye />
                                        Preview
                                    </DefaultButton>
                                </ContianerButton>
                            </Footer>
                        </>
                    }
                >
                    <Container>
                        {!!romaneio && (
                            <>
                                <SectionTitle>
                                    <Feather.Archive /> Detalhes do Romaneio
                                </SectionTitle>
                                <ContainerDetails>
                                    <ContainerInfo>
                                        <ViewInfo label="Empresa" value={romaneio.empresa} />
                                        <ViewInfo
                                            label="Data do Envio"
                                            value={format(romaneio.created_at, 'DD/MM/YYYY HH:mm')}
                                        />
                                    </ContainerInfo>
                                    <ContainerCard>
                                        <CardInfo>
                                            <DocIcon size={30} />
                                            <ContainerAmounts>
                                                <Text text={romaneio.total_awb} className="counter" font="Exo" />
                                                <Text text="pedidos" className="label" />
                                            </ContainerAmounts>
                                        </CardInfo>
                                    </ContainerCard>
                                </ContainerDetails>
                                <DefaultButton
                                    className="confirm-button"
                                    title="Aceitar"
                                    variant="success"
                                    position="right"
                                    onClick={_accept}
                                />
                            </>
                        )}
                    </Container>
                    <ModalLoading visible={loading} />
                    <ModalRomaneioReject ref={modalRomaneioRejectRef} />
                </LateralModalBase>
            </>
        )
    })
)

export { ModalRomaneio }
