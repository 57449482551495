import styled from 'styled-components'

const RowContainer = styled.tbody``

const RowContent = styled.tr`
    padding: 1.25rem;
    padding-left: 1.25rem;
    background-color: #fff;

    display: grid;
    grid-template-areas:
        'code code code'
        'label label label'
        'type type type'
        'color status status'
        'actions actions actions';
    align-items: center;
    column-gap: 0.5rem;

    &:nth-child(odd) {
        background: #f0f0f0;
    }

    td:nth-child(n) {
        margin-bottom: 0.7rem;
    }

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
`

const ID = styled.td`
    width: 100%;
    grid-area: code;
    font-weight: bold;

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 2%;
    }
`

const Label = styled.td`
    width: 100%;
    font-size: 1.2rem;
    grid-area: label;

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 0.875rem;
        width: 25%;
    }
`

const Type = styled.td`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    grid-area: type;

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 30%;
    }
`

const Status = styled.td`
    width: 100%;
    grid-area: status;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    font-weight: bold;

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 10%;
        justify-content: start;
    }
`

const Actions = styled.td`
    width: 100%;
    gap: 0.875rem;
    margin: 0.875rem 0;
    flex-direction: column;
    grid-area: actions;

    button {
        width: 100%;
        margin: 0.5rem 0;
    }

    @media all and (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;

        button {
            width: fit-content;
        }
    }
`

const PointColor = styled.div<{ color?: string }>`
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 1rem;
    border-radius: 100%;
    background-color: ${({ color }) => color || '#fff'};
`

const ColorContent = styled.td`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    grid-area: color;

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 12.5%;
    }
`
const Color = styled.div<{ color?: string }>`
    background-color: ${({ color }) => color || '#fff'};
    width: 2rem;
    height: 2rem;
    margin-right: 1.5rem;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 5px 0px rgb(138, 138, 138);
`

const ColorText = styled.div`
    font-size: 1rem;
`

export { RowContainer, RowContent, ID, Label, Type, Status, Actions, PointColor, Color, ColorContent, ColorText }
