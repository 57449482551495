import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .content-order {
        margin-bottom: 20px;
    }
    width: 700px;
    @media (max-width: 870px) {
        width: 100%;
    }
`

const Content = styled.div`
    display: flex;
    overflow: hidden;
    margin-bottom: 20px;
    flex: 1;
    @media (max-width: 870px) {
        flex-direction: column-reverse;
    }
`

const ContentMap = styled.div`
    flex: 1;
    position: relative;
    min-height: 300px;
    width: 100%;
`

const ContentForm = styled.div`
    margin-left: 20px;
    flex: 1;
    @media (max-width: 870px) {
        margin-left: 0px;
    }
`

const InputsRow = styled.div`
    @media (min-width: 870px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const InputContainer = styled.div`
    @media (min-width: 870px) {
        width: 48%;
    }
`

export { Container, Content, ContentMap, ContentForm, InputsRow, InputContainer }
