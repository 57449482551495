import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ContentContainer = styled.div`
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

const FormSection = styled.div`
    margin-bottom: 30px;
`

const FormSectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
`

const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

const ContentAccordion = styled.div`
    font-size: 12px;
    position: relative;
    padding-left: 23px;
    margin-left: 10px;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        &::before {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
    .switch {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

const Message = styled.div`
    color: #cecece;
    font-size: 11px;
    margin-top: -10px;
`

const AwaitMessage = styled.div`
    color: ${({ theme }) => theme.colors.errorMessage};
    font-size: 11px;
    margin-top: -10px;
`

const StoreName = styled.div`
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: bold;
`

export {
    CloseButton,
    CloseContainer,
    FormSectionTitle,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
    ContentAccordion,
    AwaitMessage,
    StoreName,
    Message,
}
