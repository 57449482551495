import styled from 'styled-components'

import SourceMapPin from 'assets/images/map-pin.png'

const Collect = styled.div`
    margin-top: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    .collect {
        span {
            margin-left: 4px;
            font-weight: bold;
        }
    }
`

const MapImage = styled.img.attrs({ src: SourceMapPin })`
    width: 16px;
    height: 16px;
    margin-right: 2px;
    object-fit: contain;
`

export { Collect, MapImage }
