import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useEffect } from 'react'

import { useDataFetching, useAuth } from 'hooks'
import { IMallOperationTypes, Mall, OrderFilters, PagedList } from 'types'
import { getFormattedDateFilters, getFormInputError } from 'helpers'

import Checkbox from '../checkbox/checkbox'

import {
    ButtonsContainer,
    CheckboxContainer,
    HeaderContainer,
    MallsRow,
    SectionTitle,
    MiddleContainer,
    PrimaryButton,
    InputsRow,
    SectionContainer,
    MallErrorMessage,
    PageTitle,
    PeriodButton,
    ButtonsFilterContainer,
} from './financial-header.styles'

import { InputItem, LoadingMessage } from '..'

interface FinancialFilters {
    malls: number[]
    start_date?: string
    end_date?: string
}

interface Props {
    title?: string
    showFilters?: boolean
    total?: number
    filters: FinancialFilters
    onFilterData?(filters: OrderFilters): void
}

const FinancialHeader: React.FC<Props> = ({ filters, onFilterData }) => {
    const { mall } = useAuth()

    const {
        data: malls,
        loading,
        revalidate,
    } = useDataFetching<PagedList<Mall>>('/painel/malls', {
        params: { order_by: 'name', status: [1], per_page: 100 },
        revalidateOnMount: false,
        revalidateOnFocus: false,
    })

    const { errors, getFieldProps, handleSubmit, touched, values, setFieldValue, setValues } = useFormik({
        initialValues: {
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            ...filters,
            malls: filters?.malls || [],
        },
        validationSchema: Yup.object().shape({
            malls: Yup.array().min(1, 'Selecione pelo menos um Mall'),
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
        }),

        onSubmit: async values => {
            if (onFilterData) {
                onFilterData({
                    ...values,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                })
            }
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    function onCheckboxClick(mallId: number) {
        if (values.malls?.some(id => id === mallId)) {
            setFieldValue(
                'malls',
                values.malls.filter(id => id !== mallId)
            )
        } else {
            setFieldValue('malls', [...values.malls, mallId])
        }
    }

    useEffect(() => {
        if (mall?.type === 'private') {
            setFieldValue('malls', [mall.id])
        } else {
            console.log('mall', mall)
            revalidate()
        }
    }, [mall])

    return (
        <HeaderContainer>
            <MiddleContainer>
                <PageTitle>Relatório de Pagamento</PageTitle>
                {mall?.type !== 'private' && (
                    <SectionContainer>
                        <SectionTitle>Selecionar Malls</SectionTitle>
                        {errors.malls && <MallErrorMessage>{errors.malls}</MallErrorMessage>}
                        <MallsRow>
                            {loading ? (
                                <LoadingMessage
                                    message="Carregando listagem de malls..."
                                    containerStyle={{ minHeight: '75px', margin: 0, padding: 0 }}
                                />
                            ) : (
                                malls?.items.map((mall, index) => (
                                    <CheckboxContainer key={index}>
                                        <Checkbox
                                            isSquared
                                            checkboxTitle={`${mall.name} (${transformOperationType(
                                                mall.configs.operation_type
                                            )})`}
                                            inputProps={{
                                                checked: true,
                                                value: Number(values.malls?.some(id => id === mall.id)),
                                                onChange: () => onCheckboxClick(mall.id),
                                            }}
                                        />
                                    </CheckboxContainer>
                                ))
                            )}
                        </MallsRow>
                    </SectionContainer>
                )}
                <SectionContainer>
                    <SectionTitle>Selecionar Período</SectionTitle>
                    <InputsRow>
                        <InputItem
                            labelText="Data de início"
                            type="date"
                            errorMessage={getFormInputError('start_date', errors, touched)}
                            inputProps={getFieldProps('start_date')}
                        />
                        <InputItem
                            labelText="Data de término"
                            type="date"
                            errorMessage={getFormInputError('end_date', errors, touched)}
                            inputProps={getFieldProps('end_date')}
                        />
                        <InputItem
                            labelText="Hora de início"
                            type="time"
                            errorMessage={getFormInputError('start_time', errors, touched)}
                            inputProps={getFieldProps('start_time')}
                        />
                        <InputItem
                            labelText="Hora de término"
                            type="time"
                            errorMessage={getFormInputError('end_time', errors, touched)}
                            inputProps={getFieldProps('end_time')}
                        />
                    </InputsRow>
                    <ButtonsFilterContainer>
                        {periodFilters.map((filter, filterIndex) => (
                            <PeriodButton key={filterIndex} onClick={filter.onClick}>
                                {filter.label}
                            </PeriodButton>
                        ))}
                    </ButtonsFilterContainer>
                </SectionContainer>
            </MiddleContainer>
            <ButtonsContainer>
                <PrimaryButton onClick={() => handleSubmit()}>Consultar</PrimaryButton>
            </ButtonsContainer>
        </HeaderContainer>
    )
}

function transformOperationType(type: IMallOperationTypes) {
    return (
        {
            saas: 'SaaS',
            full_service: 'Full Service',
        }[type] || 'Indefinido'
    )
}

export default FinancialHeader
