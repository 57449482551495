import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './order-detail-top-row.styles.scss'
import { useAuth } from 'hooks'

const OrderDetailTopRow = ({ statusOrder, backClick, cancelOrderClick, followRouteClick, newRouteClick }) => {
    const { user } = useAuth()

    return (
        <div className="order-detail-top-row-container">
            {statusOrder === '2' ? (
                <div className="row-item">
                    <div className="left-column">
                        <div onClick={backClick} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon="arrow-left" />
                        </div>
                        <div className="top-row-title">Pedido aguardando moderação</div>
                    </div>
                    <div className="right-column">
                        <div className="buttons-container">
                            <button className="small-button outline" onClick={followRouteClick}>
                                Recusar
                            </button>
                            <button className="small-button" onClick={newRouteClick}>
                                Aceitar
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row-item">
                    <div className="left-column">
                        <div onClick={backClick} className="icon">
                            <FontAwesomeIcon icon="arrow-left" />
                        </div>
                        <div className="top-row-title">Detalhes do Pedido</div>
                    </div>
                    <div className="right-column">
                        <div className="buttons-container">
                            {statusOrder && !['0', '8', '9'].includes(statusOrder) && (
                                <button className="small-button outline" onClick={cancelOrderClick}>
                                    <div className="label">Cancelar este pedido</div>
                                    <FontAwesomeIcon icon="ban" color="#fff" />
                                </button>
                            )}

                            {statusOrder === '6' && (
                                <button className="small-button" onClick={followRouteClick}>
                                    <div className="label">Acompanhar rota</div>
                                    <FontAwesomeIcon icon="paper-plane" color="#fff" />
                                </button>
                            )}

                            {statusOrder === '4' && !user.isShopkeeper && (
                                <button className="small-button" onClick={newRouteClick}>
                                    <div className="label">Roteirizar este pedido</div>
                                    <FontAwesomeIcon icon="paper-plane" color="#fff" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrderDetailTopRow
