import { OrderItem } from 'types'

function hasOrderItemsDefaultFormat(item: OrderItem): boolean {
    if ((item?.code_pdv !== undefined || item?.cod !== undefined) && item?.name && item?.quantity) {
        return true
    }

    return false
}

export { hasOrderItemsDefaultFormat }
