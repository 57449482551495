/* eslint-disable no-useless-escape */
function testCNPJ(strCNPJ?: string | null) {
    if (!strCNPJ) {
        return false
    }
    const cnpj = strCNPJ.replace(/[^\d]+/g, '')

    if (cnpj === '') {
        return false
    }

    if (cnpj.length !== 14) {
        return false
    }

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return false
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    const digitos = cnpj.substring(tamanho)
    let soma = 0
    let resultado = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--
        if (pos < 2) {
            pos = 9
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== Number(digitos.charAt(0))) {
        return false
    }

    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--
        if (pos < 2) {
            pos = 9
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== Number(digitos.charAt(1))) {
        return false
    }

    return true
}

function testCPF(cpf?: string | null) {
    if (!cpf) {
        return false
    }
    const number = cpf.replace(/[^\d]+/g, '')
    let sum
    let rest
    sum = 0
    if (number === '00000000000') {
        return false
    }

    for (let i = 1; i <= 9; i++) {
        sum = sum + Number(number.substring(i - 1, i)) * (11 - i)
    }
    rest = (sum * 10) % 11

    if (rest === 10 || rest === 11) {
        rest = 0
    }
    if (rest !== Number(number.substring(9, 10))) {
        return false
    }

    sum = 0
    for (let i = 1; i <= 10; i++) {
        sum = sum + Number(number.substring(i - 1, i)) * (12 - i)
    }
    rest = (sum * 10) % 11

    if (rest === 10 || rest === 11) {
        rest = 0
    }
    if (rest !== Number(number.substring(10, 11))) {
        return false
    }
    return true
}

function testPixCode(code?: string | null, type?: any) {
    if (!code || !type) {
        return false
    }

    if (type === 'random_key') {
        return true
    }

    if (type === 'phone') {
        return /\(\d{2,}\) \d{4,}\-\d{4}/g.test(code)
    }

    if (type === 'email') {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(code)
    }

    if (type === 'cpf') {
        return testCPF(code)
    }
    if (type === 'cnpj') {
        return testCNPJ(code)
    }

    return false
}

export { testCNPJ, testCPF, testPixCode }
