import React, { memo } from 'react'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'

import { IZone, IZoneTypes } from 'types/floor-map'

import { useZoneFormController } from './zone-form.controller'
import {
    ButtonRow,
    CheckBoxContainer,
    ConfirmButton,
    Container,
    Content,
    CoordinatesContent,
    HeaderContent,
    InputContainer,
    OutlineButton,
    Row,
    Title,
} from './zone-form.styled'

type Props = {
    zoneTypes: { label: string; value: IZoneTypes }[]
    zone?: IZone
    floorId: number
    toggleShow?(): void
    handleCloseZonesForms?(): void
}
const ZoneForm: React.FC<Props> = memo(({ zoneTypes, toggleShow, zone, floorId, handleCloseZonesForms }) => {
    const {
        stores,
        values,
        canvasPolygon,
        getFieldProps,
        _handleClose,
        _setCanvasPolygon,
        handleSubmit,
        _handleSelectedStore,
    } = useZoneFormController(zone, floorId, toggleShow, handleCloseZonesForms)

    return (
        <Container>
            <HeaderContent>
                <Title>Zona de Atendimento</Title>
                <b>Selecionar zona</b>
                <span>Siga o passo a passo:</span>
                <ol>
                    <li>Preencha os campos abaixo;</li>
                    <li>
                        Em seguida no piso ao lado, clique e arraste o mouse criando um polígono onde deseja adicionar
                        uma nova zona;
                    </li>
                </ol>
            </HeaderContent>

            <Content>
                <InputContainer>
                    <InputItem labelText="Nome da zona" inputProps={getFieldProps('label')} />
                </InputContainer>

                <InputContainer>
                    <InputItem labelText="Cor da zona" inputProps={getFieldProps('color')} />
                </InputContainer>

                <InputContainer>
                    <InputItem type="select" labelText="Tipo" options={zoneTypes} inputProps={getFieldProps('type')} />
                </InputContainer>

                {values.type === 'takeout' && !!stores && (
                    <InputContainer>
                        <CheckBoxContainer>
                            {stores?.map((store, i) => (
                                <Checkbox
                                    key={i}
                                    isSquared
                                    checkboxTitle={store.name}
                                    inputProps={{
                                        checked: values?.stores?.some(item => item === store.id),
                                        value: Number(values?.stores?.some(item => item === store.id)),
                                        onChange: _handleSelectedStore(store.id),
                                    }}
                                />
                            ))}
                        </CheckBoxContainer>
                    </InputContainer>
                )}

                <InputContainer>
                    <InputItem
                        type="select"
                        labelText="Status"
                        options={[
                            {
                                label: 'Ativo',
                                value: 1,
                            },
                            {
                                label: 'Inativo',
                                value: 0,
                            },
                        ]}
                        inputProps={getFieldProps('status')}
                    />
                </InputContainer>

                <CoordinatesContent>
                    <Row>
                        <InputContainer>
                            <InputItem
                                labelText="Ponto X"
                                type="number"
                                inputProps={{
                                    onChange: _setCanvasPolygon('x'),
                                    value: canvasPolygon?.x,
                                    placeholder: '0',
                                    min: 1,
                                }}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Ponto Y"
                                type="number"
                                inputProps={{
                                    onChange: _setCanvasPolygon('y'),
                                    value: canvasPolygon?.y,
                                    placeholder: '0',
                                    min: 1,
                                }}
                            />
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer>
                            <InputItem
                                labelText="Largura"
                                type="number"
                                inputProps={{
                                    onChange: _setCanvasPolygon('width'),
                                    value: canvasPolygon?.width,
                                    placeholder: '0',
                                    min: 1,
                                }}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Altura"
                                type="number"
                                inputProps={{
                                    onChange: _setCanvasPolygon('height'),
                                    value: canvasPolygon?.height,
                                    placeholder: '0',
                                    min: 1,
                                }}
                            />
                        </InputContainer>
                    </Row>
                </CoordinatesContent>
            </Content>
            <ButtonRow>
                <OutlineButton onClick={_handleClose}>Cancelar</OutlineButton>
                <ConfirmButton onClick={handleSubmit}>Salvar</ConfirmButton>
            </ButtonRow>
        </Container>
    )
})

export { ZoneForm }
