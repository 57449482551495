import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const BagDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
`

export const BagDetailTitleContainer = styled.div`
    background-color: #f7f7f7;
    padding: 20px;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
    }
`

export const OrderTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const OrderSubtitle = styled.div`
    color: #888;
    text-transform: uppercase;
    b {
        color: ${({ theme }) => theme.colors.black};
    }
`

export const ScrollBox = styled.div`
    height: 500px;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.white};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        height: unset;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        /* for Firefox */
        min-height: 0;
    }
`

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 16px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const ConfirmButton = styled.div`
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const OrderHeader = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 30px 40px;
        padding-top: 0;
    }
`

export const ChannelIcon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #888;
    overflow: hidden;
    margin-right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 70px;
        height: 70px;
    }
`

export const CustomerInfo = styled.div`
    flex: 1;
    margin-right: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 40%;
    }
`

export const OrderPrice = styled.div`
    flex-basis: 45%;
`

export const CustomerId = styled.div`
    color: #666;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const CustomerName = styled.div`
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const PriceValue = styled.div`
    font-weight: bold;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const PaymentMethod = styled.div`
    color: #666;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const Address = styled.div`
    flex-basis: 100%;
    margin-top: 25px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const AddressTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`

export const AddressValue = styled.div`
    color: #666;
`

export const OrderItensContainer = styled.div`
    margin: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin: 30px 40px;
        margin-bottom: 0;
    }
`

export const ItensTitle = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
`

export const FinalValuesContainer = styled.div`
    margin: 20px;
    margin-top: 20px;
    text-align: right;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin: 30px 40px;
        margin-top: 20px;
    }
`

export const FinalValueItem = styled.div`
    margin-bottom: 10px;
    &:last-child {
        font-size: 18px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const BagItemContainer = styled.div`
    margin-bottom: 20px;
`

export const PartContainer = styled.div`
    margin-left: 10px;
`

export const BagItemStore = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`

export const BagItemTitle = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const BagItemPart = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const BagItemName = styled.div`
    flex: 1;
`

export const BagItemPrice = styled.div`
    width: 100px;
    margin-left: 20px;
    text-align: right;
`

export const BagProductObs = styled.div`
    font-style: italic;
    margin-left: 10px;
    font-size: 12px;
    color: #888;
    &::before {
        content: '> ';
    }
`

export const TabsContainer = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
`

export const TabItem = styled.div<{ isActive?: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#aaa')};
    border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : 'transparent')};
    margin-right: 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
`

export const RefundContainer = styled.div`
    padding: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
        padding-top: 0;
    }
`

export const RefundHistoryContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding-bottom: 10px;
    margin-bottom: 10px;
`

export const RefundCardRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`

export const RefundCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 4px;
    flex-basis: 48%;
`
export const CardTitle = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const CardValue = styled.div`
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    &:last-child {
        color: ${({ theme }) => theme.colors.errorMessage};
    }
`

export const RefundTitle = styled.div`
    font-weight: bold;
    margin-bottom: 15px;
`

export const RefundHeaderContainer = styled.div`
    display: flex;
    align-items: center;
`

export const RefundHeaderItem = styled.div<{ width: string }>`
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
    width: ${({ width }) => width};
`

export const RefundRowContainer = styled.div`
    display: flex;
    align-items: baseline;
`

export const RefundRowItem = styled.div<{ width: string }>`
    font-size: 13px;
    width: ${({ width }) => width};
`

export const RefundItem = styled.div`
    font-size: 14px;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

export const RefundDate = styled.div`
    margin-bottom: 5px;
`

export const RefundValue = styled.div``

export const RefundCreationContainer = styled.div`
    margin-top: 20px;
`

export const RefundButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`

export const RefundButton = styled.div<{ isActive: boolean }>`
    padding: 10px 20px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.darkGray)};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    margin-bottom: 20px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
    transition: 400ms;
    cursor: ${({ isActive }) => (isActive ? 'pointer' : 'no-drop')};
    &:hover {
        opacity: 0.8;
    }
`

export const PaymentContainer = styled.div`
    padding: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
        padding-top: 0;
    }
`

export const InfoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const InfoItemContainer = styled.div`
    width: 45%;
`
