import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    user-select: none;
    display: flex;
    border-radius: 8px;
    transition: box-shadow 500ms;
    max-width: 320px;
    padding: 8px 16px;
    background-color: #ececec;
    margin-bottom: 20px;
    position: relative;
    &:hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        .image {
            transition: border 500ms;
            border-color: ${({ theme }) => theme.colors.primary};
        }
        .name {
            transition: color 500ms;
            color: ${({ theme }) => theme.colors.primary};
        }
        .contianer-icon {
            transition: background 500ms;
            background-color: ${({ theme }) => theme.colors.primary};
            color: #fff;
        }
    }
`

const Content = styled.div`
    display: flex;
    flex: 1;
`

const Avatar = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: #dadde7 solid 4px;
    object-fit: cover;
`

const ContainerInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
`

const AgentName = styled.div`
    font-weight: bold;
    font-size: 16px;
    color: #333;
`
const Line = styled.div`
    height: 2px;
    width: 40px;
    background-color: ${({ theme }) => theme.colors.primary};
    margin: 10px 0px;
`

const RowInfo = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 4px;
    span {
        font-size: 10px;
        color: ${({ theme }) => theme.colors.success};
    }
`
const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
    font-size: 14px;
`

const TextInfo = styled.div`
    color: #848484;
    font-size: 12px;
`

const ContainerIcon = styled.div`
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 2px ${({ theme }) => theme.colors.primary};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    bottom: 5px;
    color: ${({ theme }) => theme.colors.primary};
    background-color: #ececec;
`

const ArrowIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
`

export { Container, Content, Avatar, ContainerInfo, AgentName, Line, RowInfo, Icon, TextInfo, ContainerIcon, ArrowIcon }
