import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'

import { Recipient, Recipients } from 'containers/stone-management/types'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

const RecipientsListing: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [recipients, setRecipients] = useState<Recipients>({ recipients: [] })
    const [establishments, setEstablishments] = useState({ establishment_list: [] })
    const [selectedEstablishment, setSelectedEstablishment] = useState('')
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const getRecipients = useCallback(
        async (selectedEstablishment: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get<Recipients>(
                    `/recipient/get-all-establishment-recipients/${selectedEstablishment}`
                )

                setRecipients(data)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/painel-malls'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    useEffect(() => {
        selectedEstablishment && getRecipients(selectedEstablishment)
    }, [getRecipients, selectedEstablishment])

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const deactivateRecipient = async (recipient: Recipient) => {
        try {
            setLoadingSubmit(true)

            const { data } = await siclosPagApi.delete(`/recipient/deactivate-recipient/${recipient?.recipient_id}`)

            if (data?.error) {
                throw data.msg
            }

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Recebedor desativado com sucesso!',
            })

            getRecipients(selectedEstablishment)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })
        } finally {
            setLoadingSubmit(false)
            modalMessage.current?.openModal()
        }
    }

    const reactivateRecipient = async (recipient: Recipient) => {
        try {
            setLoadingSubmit(true)

            const { data } = await siclosPagApi.put('/recipient/reactivate-recipient', {
                recipient_id: recipient?.recipient_id,
                stone_code: recipient?.stone_code || undefined,
            })

            if (data?.error) {
                throw data.msg
            }

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Recebedor reativado com sucesso!',
            })

            getRecipients(selectedEstablishment)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })
        } finally {
            setLoadingSubmit(false)
            modalMessage.current?.openModal()
        }
    }

    const renderEmptyMessage = () => {
        return (
            <EmptyMessage>
                {selectedEstablishment.length
                    ? 'Não foram encontrados recebedores cadastrados.'
                    : 'Selecione um estabelecimento para filtrar.'}
            </EmptyMessage>
        )
    }

    return (
        <div>
            <ModalLoading visible={loadingSubmit || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <RowTitle
                title="Recebedores"
                buttonRow={[
                    {
                        label: 'Criar recebedor',
                        onClick: () => history.push('/painel-malls/gestao-stone/recebedores/criar-recebedor'),
                    },
                    {
                        label: 'Criar recebedor já credenciado',
                        onClick: () =>
                            history.push('/painel-malls/gestao-stone/recebedores/criar-recebedor-credenciado'),
                        secondary: true,
                    },
                ]}
            >
                <SelectInputForm
                    placeholder="Filtrar por estabelecimento"
                    data={establishments.establishment_list?.map(({ id, business_name }) => ({
                        name: business_name,
                        value: id,
                    }))}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedEstablishment(value)
                    }}
                    value={selectedEstablishment}
                />
            </RowTitle>

            <FlexTable
                columns={[
                    { name: 'Nome', width: '25%' },
                    { name: 'Endereço', width: '30%' },
                    { name: 'Status', width: '10%' },
                    { name: '', width: '30%' },
                ]}
                list={recipients.recipients?.map(recipient => {
                    const { recipient_id, business_name, address, recipient_is_active } = recipient

                    return {
                        business_name: (
                            <>
                                {business_name} <br />
                                <small>{recipient_id}</small>
                            </>
                        ),
                        address: (
                            <>
                                {address?.state} - {address?.city} <br />
                                {address?.neighborhood}
                            </>
                        ),
                        recipient_is_active: recipient_is_active ? 'Ativo' : 'Inativo',
                        buttonRow: (
                            <div className="flex-table-row-content button-row">
                                <div
                                    className="button-item outline"
                                    onClick={() => {
                                        if (recipient_is_active) {
                                            setModalConfirmationData({
                                                title: 'Desativar recebedor',
                                                message: 'Tem certeza de que deseja desativar este recebedor?',
                                                onYes: () => deactivateRecipient(recipient),
                                            })
                                        } else {
                                            setModalConfirmationData({
                                                title: 'Reativar recebedor',
                                                message: 'Tem certeza de que deseja reativar este recebedor?',
                                                onYes: () => reactivateRecipient(recipient),
                                            })
                                        }

                                        modalConfirmation.current?.openModal()
                                    }}
                                >
                                    {recipient_is_active ? 'Desativar' : 'Reativar'}
                                </div>
                                <div
                                    className="button-item"
                                    onClick={() =>
                                        history.push(
                                            `/painel-malls/gestao-stone/recebedores/detalhes-recebedor/${recipient_id}`
                                        )
                                    }
                                >
                                    Visualizar
                                </div>
                            </div>
                        ),
                    }
                })}
            />

            {!recipients.recipients.length && !loading && renderEmptyMessage()}
        </div>
    )
}

export default RecipientsListing
