import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import './order-detail-history.styles.scss'

const OrderDetailHistory = ({ historic }) => {
    if (!historic) {
        return null
    }

    return (
        <div className="order-detail-history-container">
            <div className="section-title">
                <FontAwesomeIcon icon="chart-line" /> Histórico do Pedido
            </div>

            {historic.map((item, index) => {
                const date = format(item.created_at, 'DD/MM/YYYY')
                const time = format(item.created_at, 'HH:mm:ss')
                const causer = item.causer?.full_name || ''
                return (
                    <div className="history-item" key={index}>
                        <div className="title">{item.description}</div>
                        <div className="value">
                            {date} às {time} por {causer}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default OrderDetailHistory
