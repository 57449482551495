import { featureCollection, point } from '@turf/helpers'
import { EventData, Map, GeoJSONSource } from 'mapbox-gl'

import { Coordinates, Image } from '../types'

import { addSymbolPoint } from './addSymbolPoint'

export function addDraggableSymbolPoint(
    map: Map,
    coordinates: Coordinates,
    onDragEnd?: (coords: Coordinates) => void,
    image?: Image
): void {
    addSymbolPoint(map, coordinates, image)

    const canvas = map.getCanvasContainer()
    const geojson = featureCollection([point([coordinates.lng, coordinates.lat])])

    function updateCoordinates(coords: Coordinates) {
        geojson.features[0].geometry.coordinates = [coords.lng, coords.lat]

        const point = map.getSource('point') as GeoJSONSource
        point.setData(geojson as GeoJSON.FeatureCollection<GeoJSON.Geometry>)
    }

    function onMouseMove(e: EventData) {
        const coords = e.lngLat

        // Set a UI indicator for dragging.
        canvas.style.cursor = 'grabbing'

        // Update the Point feature in `geojson` coordinates
        // and call setData to the source layer `point` on it.

        updateCoordinates(coords)
    }

    function onMouseUp(e: EventData) {
        const coords = e.lngLat

        // Print the coordinates of where the point had
        // finished being dragged to on the map.
        canvas.style.cursor = ''

        if (onDragEnd) onDragEnd({ lat: coords.lat, lng: coords.lng })

        map.setCenter(coords)
        map.setZoom(15)

        // Unbind mouse/touch events
        map.off('mousemove', onMouseMove)
        map.off('touchmove', onMouseMove)
    }

    map.on('mousedown', 'point', function (e) {
        // Prevent the default map drag behavior.
        e.preventDefault()

        canvas.style.cursor = 'grab'

        map.on('mousemove', onMouseMove)
        map.once('mouseup', onMouseUp)
    })

    map.on('touchstart', 'point', function (e) {
        if (e.points.length !== 1) return

        // Prevent the default map drag behavior.
        e.preventDefault()

        map.on('touchmove', onMouseMove)
        map.once('touchend', onMouseUp)
    })
}
