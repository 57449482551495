import { useEffect, useState } from 'react'

import { LoadingMessage } from 'components/_common'
import {
    ButtonRow,
    TextButton,
    ConfirmButton,
} from 'components/_new-general-vision/change-password-modal/change-password-modal.styles'
import TextInputForm from 'components/text-input-form'

import { useUI } from 'contexts'
import { useAgentsAvailable } from 'hooks'
import api from 'services/api'
import { Biker, Route } from 'types'

import SelectableBikerCard from '../selectable-biker-card'
import { SelectableAgentCard } from '../selectable-biker-card/selectable-biker-card'

import {
    ContentTitle,
    ExchangeRouteBikerModalBackground,
    ExchangeRouteBikerModalBox,
    ExchangeRouteBikerModalContainer,
    ExchangeRouteBikerModalContent,
    CloseButton,
    CloseContainer,
    ExchangeRouteBikerHeader,
    EmptyMessageContainer,
    EmptyText,
    BikerIcon,
    ResetSearch,
} from './exchange-route-biker-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
    bikers?: Biker[]
    deleteItem?(item): void
    route: Route
    revalidate(): void
}

const ExchangeBikerModal: React.FC<Props> = ({ isActive, closeClick, route, revalidate }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [selectedBiker, setSelectedBiker] = useState<number>()
    const { agents, revalidateAgents, loading } = useAgentsAvailable()
    const [searchedBiker, setSearchedBiker] = useState('')
    const [filteredValues, setFilteredValues] = useState(agents?.items)

    const { setLoading, setSnackbar } = useUI()

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            revalidateAgents()
            setSelectedBiker(route?.biker?.id)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    async function exchangeBiker() {
        try {
            setLoading(true)
            await api.post('/painel/route/change-biker', {
                route_id: route.id,
                agent_id: selectedBiker,
            })
            closeClick()
            revalidate()
            setSnackbar({ message: 'Entregador alterado' })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setFilteredValues(agents.items)
    }, [agents])

    useEffect(() => {
        setFilteredValues(
            agents.items.filter(function (el) {
                return `${el.name.toLowerCase()}`.includes(searchedBiker.toLowerCase())
            })
        )

        if (searchedBiker.length === 0) {
            setFilteredValues(agents.items)
        }
    }, [searchedBiker])

    return (
        <ExchangeRouteBikerModalContainer isDisplayed={isDisplayed}>
            <ExchangeRouteBikerModalBox isEffectActive={isEffectActive}>
                <ExchangeRouteBikerHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Alterar entregador</ContentTitle>
                    <TextInputForm
                        placeholder="Buscar entregador responsável"
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                            setSearchedBiker(value)
                        }
                        value={searchedBiker}
                    />
                    {searchedBiker !== '' && (
                        <ResetSearch onClick={() => setSearchedBiker('')}>Limpar busca</ResetSearch>
                    )}
                </ExchangeRouteBikerHeader>
                <ExchangeRouteBikerModalContent>
                    {route?.biker?.id && (
                        <SelectableBikerCard
                            biker={route?.biker}
                            isActive={selectedBiker === route?.biker?.id}
                            cardClick={() => setSelectedBiker(route?.biker?.id)}
                            key={route?.biker?.id}
                            isRouteBiker={true}
                        />
                    )}
                    {loading ? (
                        <LoadingMessage />
                    ) : (
                        <>
                            {filteredValues.length > 0 ? (
                                <>
                                    {filteredValues.map(biker => (
                                        <>
                                            {biker?.id !== route?.biker?.id && (
                                                <SelectableAgentCard
                                                    key={biker?.id}
                                                    agent={biker}
                                                    isActive={selectedBiker === biker?.id}
                                                    cardClick={() => setSelectedBiker(biker?.id)}
                                                />
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : (
                                <EmptyMessageContainer>
                                    <BikerIcon />
                                    <EmptyText>Nao há entregadores disponíveis</EmptyText>
                                </EmptyMessageContainer>
                            )}
                        </>
                    )}
                </ExchangeRouteBikerModalContent>
                <ButtonRow>
                    <TextButton onClick={closeClick}>Cancelar</TextButton>
                    <ConfirmButton isActive onClick={() => exchangeBiker()}>
                        Alterar entregador
                    </ConfirmButton>
                </ButtonRow>
            </ExchangeRouteBikerModalBox>
            <ExchangeRouteBikerModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </ExchangeRouteBikerModalContainer>
    )
}

export default ExchangeBikerModal
