import { forwardRef, useImperativeHandle, useState, useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Loader from 'components/modal-loading'
import TextInputForm from 'components/text-input-form'

import { formatPhone } from 'helpers'
import { setFormFieldErrors } from 'helpers'
import { useAuth, useEventListener } from 'hooks'
import api from 'services/api'

import './style.scss'
import { Biker } from 'types'

import {
    ContainerRider,
    Avatar,
    ContainerInfo,
    TypeAgent,
    RiderName,
    RiderPhone,
} from './modal-add-allowed-deliveries.styled'

type ModalAddAllowedDeliveriesParams = {
    onAddSuccess?(): void
    onRemoveClick?(id: number, name: string): void
    onApproveClick?(id: number): void
    agent?: Biker
    id?: number
}
type ModalAddAllowedDeliveries = {
    show?(data: ModalAddAllowedDeliveriesParams): void
    close?(): void
}
interface ModalAddAllowedDeliveriesProps {
    name?: string
}

// eslint-disable-next-line react/display-name
const ModalAddAllowedDeliveries = forwardRef<ModalAddAllowedDeliveries, ModalAddAllowedDeliveriesProps>(
    (props, ref) => {
        const { mall } = useAuth()
        const [show, setShow] = useState(false)
        const [callbacks, setCallbacks] = useState<ModalAddAllowedDeliveriesParams>(null)
        const [loading, setLoading] = useState<boolean>(false)
        const [agent, setAgent] = useState<Biker>(null)
        const [id, setId] = useState(null)
        const { errors, handleSubmit, values, isValid, setTouched, setFieldValue } = useFormik({
            initialValues: {
                logaroo_id: '',
                prefix_cpf: '',
            },

            validationSchema: Yup.object().shape({
                logaroo_id: Yup.string().trim().required('LogarooID é obrigatório'),
                prefix_cpf: Yup.string()
                    .trim()
                    .required('Primeiro 3 dígitos do CPF é obrigatório')
                    .min(3, 'Deve conter 3 dígitos')
                    .min(3, 'Deve conter 3 dígitos'),
            }),

            onSubmit: async (values, { setFieldError, resetForm }) => {
                try {
                    setLoading(true)
                    const { data } = await api.post('/painel/fleets', {
                        ...values,
                        mall_id: mall.id,
                    })

                    resetForm()
                    setId(data.id)
                    setAgent(data.agent)
                    if (callbacks?.onAddSuccess) {
                        callbacks.onAddSuccess()
                    }
                } catch (error) {
                    setFormFieldErrors(error, setFieldError)
                } finally {
                    setLoading(false)
                }
            },
        })

        const handleEscPress = useCallback(
            ({ code }) => {
                if (code === 'Escape') {
                    setShow(false)
                }
            },
            [ref]
        )
        useEventListener('keydown', handleEscPress)

        useImperativeHandle(ref, () => ({
            show: data => {
                if (data.id) {
                    setId(data.id)
                }
                if (data.agent) {
                    setAgent(data.agent)
                }
                setCallbacks(data)
                setShow(true)
            },
            close: () => {
                _close()
            },
        }))

        const _close = useCallback(() => {
            setShow(false)
            setTimeout(() => {
                setAgent(null)
                setId(null)
            }, 300)
        }, [])

        const _approve = useCallback(() => {
            if (callbacks?.onApproveClick) {
                callbacks.onApproveClick(id)
            }
            _close()
        }, [_close, id, callbacks])

        const _remove = useCallback(() => {
            if (callbacks?.onRemoveClick) {
                callbacks.onRemoveClick(id, agent?.name)
            }
            _close()
        }, [_close, id, callbacks, agent])

        return (
            <div className={`create-admin-modal ${show ? 'is-active' : ''}`}>
                <div className="Modal-bg">
                    <div className="Modal-box">
                        <div className="Modal-header">
                            <div className="Modal-title">{agent ? 'Aprovar' : 'Adicionar'} Entregador</div>
                            <div className="Modal-close" onClick={_close}>
                                <FontAwesomeIcon size="lg" icon="times" />
                            </div>
                        </div>
                        <div className="Modal-body">
                            {!agent ? (
                                <div>
                                    <div className="Modal-content">
                                        <div className="inputs-container">
                                            <div className="inputs-row">
                                                <TextInputForm
                                                    label="LogarooID"
                                                    placeholder="Ex: ABC0123"
                                                    value={values.logaroo_id}
                                                    onChange={e => {
                                                        setTouched({ logaroo_id: true })
                                                        setFieldValue(
                                                            'logaroo_id',
                                                            (e.target.value || '').toUpperCase()
                                                        )
                                                    }}
                                                    msgErro={errors.logaroo_id}
                                                />
                                                <TextInputForm
                                                    label="3 primeiro digitos do CPF"
                                                    placeholder="Ex: 000"
                                                    maxLength={3}
                                                    value={values.prefix_cpf}
                                                    onChange={e => {
                                                        setTouched({ logaroo_id: true })
                                                        setFieldValue('prefix_cpf', e.target.value)
                                                    }}
                                                    msgErro={errors.prefix_cpf}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Modal-buttons">
                                        <button
                                            className="Modal-button primary"
                                            disabled={!isValid}
                                            onClick={() => handleSubmit()}
                                        >
                                            Adicionar
                                        </button>
                                        <button className="Modal-button outline" onClick={_close}>
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <ContainerRider>
                                    <Avatar src={agent.avatar} />
                                    <ContainerInfo>
                                        <TypeAgent>{agent.type_name === 'moto' ? 'Moto' : 'Bicicleta'}</TypeAgent>
                                        <RiderName>{agent.name}</RiderName>
                                        <RiderPhone>{formatPhone(agent.cellphone)}</RiderPhone>
                                    </ContainerInfo>
                                    <div className="Modal-buttons">
                                        {callbacks?.onApproveClick && (
                                            <button className="Modal-button primary" onClick={_approve}>
                                                Aprovar
                                            </button>
                                        )}
                                        {callbacks?.onRemoveClick && (
                                            <button className="Modal-button outline" onClick={_remove}>
                                                Remover
                                            </button>
                                        )}
                                    </div>
                                </ContainerRider>
                            )}
                        </div>

                        <Loader visible={loading} />
                    </div>
                </div>
            </div>
        )
    }
)

export default ModalAddAllowedDeliveries
