import { forwardRef, memo, useImperativeHandle } from 'react'

import { format } from 'date-fns'
import { LateralModalBase } from 'modals'

import { Accordion } from 'components/_common'
import InfoItem from 'components/_new-general-vision/info-item/info-item'
import { Grid } from 'components/grid/grid'
import { Text } from 'components/text/text'

import { formatCurrency, formatPhone } from 'helpers'

import { useAttendanceDetailModalController } from './attendance-detail-modal.controller'
import {
    BagContent,
    ContentContainer,
    OrderDetailItem,
    OutsideContainer,
    Row,
    SectionTitle,
} from './attendance-detail-modal.styled'
import { OrderDetails } from './widget/order-details'

interface AttendanceDetailModal {
    show(id: number): void
    close(): void
}

const AttendanceDetailModal = memo(
    forwardRef((_, ref) => {
        const { attendance, lateralModalBaseRef, _getDetails } = useAttendanceDetailModalController()

        useImperativeHandle(
            ref,
            () => ({
                show: async (id: number) => {
                    await _getDetails(id)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            []
        )

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={'Detalhes do Atendimento'}>
                <OutsideContainer>
                    <ContentContainer>
                        <OrderDetailItem>
                            <SectionTitle>Informações Sobre o Atendimento</SectionTitle>
                            <Row>
                                <InfoItem label="Lojista" content={attendance?.bags[0]?.orders[0].store?.name} />
                            </Row>
                            <Row>
                                <InfoItem label="Valor do atendimento" content={formatCurrency(attendance?.subtotal)} />
                            </Row>
                            <Row>
                                <InfoItem label="Comanda de atendimento" content={attendance?.command.code} />
                                <InfoItem
                                    label="Forma de Pagamento"
                                    content={
                                        <Grid.Column>
                                            {attendance?.payment?.type || 'Pendente'}
                                            {!!attendance?.payment?.gateway_id && (
                                                <Text
                                                    bold
                                                    dimensions="small"
                                                    marginTop={4}
                                                    text={attendance?.payment?.gateway_id}
                                                />
                                            )}
                                        </Grid.Column>
                                    }
                                />
                            </Row>
                        </OrderDetailItem>
                        <OrderDetailItem>
                            <SectionTitle>Cliente</SectionTitle>
                            <Row>
                                <InfoItem label="Nome do Cliente" content={attendance?.client?.name} />
                                <InfoItem label="Telefone" content={formatPhone(attendance?.client?.phone)} />
                            </Row>
                            {!!attendance?.client?.email && (
                                <Row>
                                    <InfoItem label="E-mail do cliente" content={attendance?.client?.email} />
                                </Row>
                            )}
                        </OrderDetailItem>
                        <OrderDetailItem>
                            <SectionTitle>Atendimento</SectionTitle>
                            <Row>
                                <InfoItem
                                    label="Abertura"
                                    content={
                                        <Grid.Column>
                                            {/* {attendance?.creator.name} */}
                                            <Text bold text={format(attendance?.created_at, 'DD/MM/YYYY [às] HH:mm')} />
                                        </Grid.Column>
                                    }
                                />
                                {!!attendance?.finished_at && (
                                    <InfoItem
                                        label="Encerramento"
                                        content={
                                            <Grid.Column>
                                                {/* {attendance?.finisher.name} */}
                                                <Text
                                                    bold
                                                    text={format(attendance?.finished_at, 'DD/MM/YYYY [às] HH:mm')}
                                                />
                                            </Grid.Column>
                                        }
                                    />
                                )}
                            </Row>
                        </OrderDetailItem>
                        <Grid.Column maxWidth marginTop={16}>
                            <SectionTitle>Informações Sobre os Pedidos</SectionTitle>
                            {attendance?.bags.map((bag, key) => {
                                const subtotal = bag.orders.reduce((acc, order) => {
                                    if (order.canceled) {
                                        return acc
                                    }
                                    return acc + order.items.reduce((acc, item) => acc + item.total_price, 0)
                                }, 0)
                                return (
                                    <BagContent key={key}>
                                        <Accordion
                                            hideIcon
                                            accordionTitle={format(
                                                bag.created_at,
                                                `[Solicitado às] HH:mm [de] DD/MM/YYYY [por ${
                                                    bag.details.seller_name
                                                }] [(Subtotal: ${formatCurrency(subtotal)})]`
                                            )}
                                        >
                                            <SectionTitle>Items e Observação</SectionTitle>
                                            {bag.orders.map((order, i) => (
                                                <OrderDetails key={i} order={order} />
                                            ))}
                                        </Accordion>
                                    </BagContent>
                                )
                            })}
                        </Grid.Column>
                    </ContentContainer>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { AttendanceDetailModal }
