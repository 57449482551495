import circle from '@turf/circle'
import { featureCollection } from '@turf/helpers'
import { GeoJSONSource, Map } from 'mapbox-gl'

import colors from 'themes/colors'

import { Coordinates } from '../types'

import { addGeojsonPolygons } from './addGeojsonPolygons'

export function addCircleLines(
    map: Map,
    center: Coordinates,
    radius: { min: number; max: number }
): { source: GeoJSONSource } {
    const minCirclePolygon = circle([center.lng, center.lat], radius.min, {
        steps: 50,
        units: 'meters',
        properties: {
            'fill-opacity': 0,
            'line-color': colors.light_primary_color,
            'line-width': 1,
        },
    })
    const maxCirclePolygon = circle([center.lng, center.lat], radius.max, {
        steps: 50,
        units: 'meters',
        properties: {
            'fill-opacity': 0,
            'line-color': colors.light_primary_color,
            'line-width': 1,
        },
    })
    const geojsonData = featureCollection([minCirclePolygon, maxCirclePolygon])

    const { source } = addGeojsonPolygons(map, geojsonData as GeoJSON.FeatureCollection<GeoJSON.Geometry>)

    return { source }
}
