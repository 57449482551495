import { useState, useRef, useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import amexLogo from 'assets/images/amex.png'
import eloLogo from 'assets/images/elo.png'
import hiperLogo from 'assets/images/hipercard.png'
import masterLogo from 'assets/images/mastercard.png'
import visaLogo from 'assets/images/visa.png'

import ButtonForm from 'components/button-form'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TextInputMask from 'components/text-input-mask'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'
import stoneBanks from 'utils/stoneBanks'

type CardRateTypeID = 1 | 2 | 3 | 4
type CardRateBrand = 'visa' | 'master' | 'elo' | 'hiper' | 'amex'
type CardRateObject = 'type_id' | 'visa' | 'master' | 'elo' | 'hiper' | 'amex'

const logoCards = {
    visa: visaLogo,
    master: masterLogo,
    elo: eloLogo,
    hiper: hiperLogo,
    amex: amexLogo,
}

const EstablishmentsCreate: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const { isSubmitting, errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } = useFormik({
        initialErrors: {
            cards_rate: [1, 2, 3, 4].map(() => ({
                visa: '',
                master: '',
                elo: '',
            })),
        },
        initialValues: {
            establishment_id: '',
            name: '',
            is_establishment_to_production: false,
            legal_name: '',
            business_name: '',
            document_number: '',
            tpv: 0,
            mcc: '',
            email_access_stone: '',
            partner_stone_id: 0,
            capture_method: {
                amount_of_s920: '',
                mobile_carrier_id: '',
                monthly_fee: '',
                initial_exemption_days: '',
            },
            responsible: {
                name: '',
                document_number: '',
            },
            contact: {
                name: '',
                cell_phone: '',
                phone: '',
            },
            anticipation: {
                is_automatic: false,
                automatic_rate: 0,
                spot_rate: 0,
            },
            bank: {
                bank_code: '',
                agency: '',
                agency_dv: '',
                account: '',
                account_dv: '',
                operation_number: '',
                account_type: '',
            },
            cards_active: {
                visa: false,
                master: false,
                elo: false,
                hiper: false,
                amex: false,
            },
            cards_rate: [
                {
                    type_id: 2,
                    visa: '',
                    master: '',
                    elo: '',
                    hiper: '',
                    amex: '',
                },
                {
                    type_id: 1,
                    visa: '',
                    master: '',
                    elo: '',
                    hiper: '',
                    amex: '',
                },
                {
                    type_id: 3,
                    visa: '',
                    master: '',
                    elo: '',
                    hiper: '',
                    amex: '',
                },
                {
                    type_id: 4,
                    visa: '',
                    master: '',
                    elo: '',
                    hiper: '',
                    amex: '',
                },
            ],
            address: {
                zip_code: '',
                number: '',
                complement: '',
            },
        },
        validationSchema: Yup.object().shape({
            legal_name: Yup.string().trim().required('Razão Social é obrigatório'),
            business_name: Yup.string().trim().required('Nome Fantasia é obrigatório'),
            document_number: Yup.string().trim().required('CNPJ é obrigatório'),
            tpv: Yup.string().trim().required('TPV é obrigatório'),
            mcc: Yup.string().trim().required('MCC é obrigatório'),
            email_access_stone: Yup.string().trim().email('Deve ser um email válido').required('Email é obrigatório'),
            capture_method: Yup.object().shape({
                amount_of_s920: Yup.string().trim().required('Quantidade de máquinas é obrigatório'),
                mobile_carrier_id: Yup.string().trim().required('Selecione a operadora'),
                initial_exemption_days: Yup.number().required('Dias de isenção é obrigatório'),
            }),
            responsible: Yup.object().shape({
                name: Yup.string().trim().required('Nome do responsável é obrigatório'),
                document_number: Yup.string().trim().required('CPF do responsável é obrigatório'),
            }),
            contact: Yup.object().shape({
                name: Yup.string().trim().required('Nome do contato é obrigatório'),
                cell_phone: Yup.string().trim().required('Telefone celular do contato é obrigatório'),
                phone: Yup.string().trim().required('Telefone fixo do contato é obrigatório'),
            }),
            bank: Yup.object().shape({
                bank_code: Yup.string().trim().required('Selecione o banco'),
                agency: Yup.string().trim().required('Agência é requerida'),
                agency_dv: Yup.string().trim().required('Dígito da agência é requerido'),
                account: Yup.string().trim().required('Conta é requerida'),
                account_dv: Yup.string().trim().required('Dígito da conta é requerido'),
                account_type: Yup.string().trim().required('Selecione o Tipo de conta'),
            }),
            address: Yup.object().shape({
                zip_code: Yup.string().trim().required('CEP é obrigatório'),
                number: Yup.string().trim().required('Número é obrigatório'),
            }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)
                await localStorage.setItem('establishment-create-values', JSON.stringify(values))

                const { data } = await siclosPagApi.post('/establishment/create', {
                    ...values,
                    document_number: values.document_number.replace(/\D+/g, ''),
                    tpv: values.tpv * 100,
                    capture_method: {
                        amount_of_s920: Number(values.capture_method.amount_of_s920),
                        mobile_carrier_id: Number(values.capture_method.mobile_carrier_id),
                        monthly_fee: Number(values.capture_method.monthly_fee),
                        initial_exemption_days: Number(values.capture_method.initial_exemption_days),
                    },
                    cards_rate: values.cards_rate.map(item => ({
                        ...item,
                        ...Object.keys(item)
                            .filter(key => Number(item[key as CardRateBrand]) !== 0)
                            .reduce((acc, key) => {
                                const card_rate = Number(item[key as CardRateObject])

                                return { ...acc, [key]: card_rate > 0 ? card_rate : undefined }
                            }, {}),
                    })),
                    contact: {
                        ...values.contact,
                        phone: values.contact.phone.replace(/\D+/g, ''),
                    },
                    address: {
                        ...values.address,
                        zip_code: values.address.zip_code.replace(/\D+/g, ''),
                    },
                })

                if (data?.error) {
                    throw data.msg
                }

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Estabelecimento criado com sucesso!',
                    onClose: () => history.push('/painel-malls/gestao-stone'),
                })

                modalMessage.current?.openModal()
                resetForm()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    isActive: true,
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessage.current?.openModal()
            } finally {
                setSubmitting(false)
            }
        },
    })

    function handleInputChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field, value)
        }
    }

    function handleSelectChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue(field, value)
        }
    }

    function getCardRateByType(type_id: CardRateTypeID) {
        return {
            1: 'Débito',
            2: 'Crédito',
            3: 'Parc. 1 a 6 vezes',
            4: 'Parc. 6 a 12 vezes',
        }[type_id || '']
    }

    const getFormValues = useCallback(async () => {
        const formValues = await localStorage.getItem('establishment-create-values')

        if (formValues) {
            setValues(JSON.parse(formValues as string))
        }
    }, [setValues])

    useEffect(() => {
        // getFormValues()
    }, [getFormValues])

    return (
        <div>
            <RowTitle title="Criar estabelecimento" backClick={() => history.push('/painel-malls/gestao-stone')} />

            <ModalLoading visible={isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="stone-form-container">
                <div className="inputs-row col-2">
                    <TextInputForm
                        label="Razão Social*"
                        onChange={handleInputChange('legal_name')}
                        value={values.legal_name}
                        msgErro={touched.legal_name && errors.legal_name}
                    />
                    <TextInputForm
                        label="Nome Fantasia*"
                        onChange={handleInputChange('business_name')}
                        value={values.business_name}
                        msgErro={touched.business_name && errors.business_name}
                    />
                </div>

                <div className="inputs-row col-3">
                    <TextInputMask
                        label="CNPJ do estabelecimento*"
                        mask="99.999.999/9999-99"
                        onChange={handleInputChange('document_number')}
                        value={values.document_number}
                        msgErro={touched.document_number && errors.document_number}
                    />
                    <TextInputForm
                        label="TPV*"
                        type="currency"
                        maxLength={18}
                        onChange={(floatValue: number) => setFieldValue('tpv', floatValue)}
                        value={values.tpv}
                        msgErro={touched.tpv && errors.tpv}
                    />
                    <TextInputForm
                        label="MCC*"
                        onChange={handleInputChange('mcc')}
                        value={values.mcc}
                        msgErro={touched.mcc && errors.mcc}
                    />
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Email de Acesso Stone*"
                        onChange={handleInputChange('email_access_stone')}
                        value={values.email_access_stone}
                        msgErro={touched.email_access_stone && errors.email_access_stone}
                    />
                    <TextInputForm
                        label="Código Stone Partner"
                        type="number"
                        onChange={handleInputChange('partner_stone_id')}
                        value={values.partner_stone_id}
                        msgErro={touched.partner_stone_id && errors.partner_stone_id}
                    />
                    <div className="radio-group">
                        <label>Ativo em produção?</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="is_establishment_to_production"
                                label="Sim"
                                checked={values.is_establishment_to_production}
                                onClick={() => setFieldValue('is_establishment_to_production', true)}
                            />

                            <InputRadio
                                id="is_establishment_not_to_production"
                                label="Não"
                                checked={!values.is_establishment_to_production}
                                onClick={() => setFieldValue('is_establishment_to_production', false)}
                            />
                        </div>
                    </div>
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Meio de captura
                </div>

                <div className="inputs-row col-4">
                    <TextInputForm
                        label="Quantidade de máquinas S920*"
                        type="number"
                        onChange={handleInputChange('capture_method.amount_of_s920')}
                        value={values.capture_method.amount_of_s920}
                        msgErro={touched.capture_method?.amount_of_s920 && errors.capture_method?.amount_of_s920}
                    />
                    <SelectInputForm
                        label="Operadora*"
                        data={[
                            { name: 'Oi', value: 1 },
                            { name: 'Claro', value: 2 },
                            { name: 'Vivo', value: 3 },
                            { name: 'Tim', value: 4 },
                        ]}
                        onChange={handleSelectChange('capture_method.mobile_carrier_id')}
                        value={values.capture_method.mobile_carrier_id}
                        msgErro={touched.capture_method?.mobile_carrier_id && errors.capture_method?.mobile_carrier_id}
                    />
                    <TextInputForm
                        label={`Taxa mensal${values.partner_stone_id ? '*' : ''}`}
                        type="currency"
                        onChange={(floatValue: number) => setFieldValue('capture_method.monthly_fee', floatValue)}
                        value={values.capture_method.monthly_fee}
                        msgErro={touched.capture_method?.monthly_fee && errors.capture_method?.monthly_fee}
                    />
                    <TextInputForm
                        label="Dias de isenção*"
                        type="number"
                        onChange={handleInputChange('capture_method.initial_exemption_days')}
                        value={values.capture_method.initial_exemption_days}
                        msgErro={
                            touched.capture_method?.initial_exemption_days &&
                            errors.capture_method?.initial_exemption_days
                        }
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Responsável
                </div>

                <div className="inputs-row col-2">
                    <TextInputForm
                        label="Nome completo*"
                        onChange={handleInputChange('responsible.name')}
                        value={values.responsible.name}
                        msgErro={touched.responsible?.name && errors.responsible?.name}
                    />
                    <TextInputMask
                        label="CPF*"
                        mask="999.999.999-99"
                        onChange={handleInputChange('responsible.document_number')}
                        value={values.responsible.document_number}
                        msgErro={touched.responsible?.document_number && errors.responsible?.document_number}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Contato
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome do contato*"
                        onChange={handleInputChange('contact.name')}
                        value={values.contact.name}
                        msgErro={touched.contact?.name && errors.contact?.name}
                    />
                    <TextInputMask
                        label="Celular*"
                        mask="(99) 99999-9999"
                        onChange={handleInputChange('contact.cell_phone')}
                        value={values.contact.cell_phone}
                        msgErro={touched.contact?.cell_phone && errors.contact?.cell_phone}
                    />
                    <TextInputMask
                        label="Telefone*"
                        mask="(99) 9999-9999"
                        onChange={handleInputChange('contact.phone')}
                        value={values.contact.phone}
                        msgErro={touched.contact?.phone && errors.contact?.phone}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Antecipação
                </div>

                <div className="inputs-row col-3">
                    <div className="radio-group">
                        <label>Automática?</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="automatic-yes"
                                label="Sim"
                                checked={values.anticipation.is_automatic}
                                onClick={() => setFieldValue('anticipation.is_automatic', true)}
                            />

                            <InputRadio
                                id="automatic-no"
                                label="Não"
                                checked={!values.anticipation.is_automatic}
                                onClick={() => setFieldValue('anticipation.is_automatic', false)}
                            />
                        </div>
                    </div>

                    <TextInputForm
                        label="Taxa da antecipação automática*"
                        onChange={handleInputChange('anticipation.automatic_rate')}
                        value={values.anticipation.automatic_rate}
                        msgErro={touched.anticipation?.automatic_rate && errors.anticipation?.automatic_rate}
                    />
                    <TextInputForm
                        label="Taxa de antecipação pontual*"
                        onChange={handleInputChange('anticipation.spot_rate')}
                        value={values.anticipation.spot_rate}
                        msgErro={touched.anticipation?.spot_rate && errors.anticipation?.spot_rate}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Banco
                </div>

                <div className="inputs-row col-3">
                    <SelectInputForm
                        label="Banco*"
                        data={stoneBanks}
                        onChange={handleSelectChange('bank.bank_code')}
                        value={values.bank.bank_code}
                        msgErro={touched.bank?.bank_code && errors.bank?.bank_code}
                    />
                    <div className="bank-info-input">
                        <TextInputForm
                            label="Agência*"
                            onChange={handleInputChange('bank.agency')}
                            value={values.bank.agency}
                            msgErro={touched.bank?.agency && errors.bank?.agency}
                        />
                        <TextInputForm
                            onChange={handleInputChange('bank.agency_dv')}
                            value={values.bank.agency_dv}
                            msgErro={touched.bank?.agency_dv && errors.bank?.agency_dv}
                        />
                    </div>
                    <div className="bank-info-input">
                        <TextInputForm
                            label="Conta*"
                            onChange={handleInputChange('bank.account')}
                            value={values.bank.account}
                            msgErro={touched.bank?.account && errors.bank?.account}
                        />
                        <TextInputForm
                            onChange={handleInputChange('bank.account_dv')}
                            value={values.bank.account_dv}
                            msgErro={touched.bank?.account_dv && errors.bank?.account_dv}
                        />
                    </div>
                </div>

                <div className="inputs-row col-2">
                    <SelectInputForm
                        label="Tipo de conta*"
                        data={[
                            { name: 'Corrente', value: 1 },
                            { name: 'Poupança', value: 2 },
                        ]}
                        onChange={handleSelectChange('bank.account_type')}
                        value={values.bank.account_type}
                        msgErro={touched.bank?.account_type && errors.bank?.account_type}
                    />
                    <TextInputForm
                        label="Operação"
                        onChange={handleInputChange('bank.operation_number')}
                        value={values.bank.operation_number}
                        msgErro={touched.bank?.operation_number && errors.bank?.operation_number}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Cartões
                </div>

                <div className="cards-rate-container">
                    <div className="cards-rate-label-types">
                        {values.cards_rate.map(({ type_id }, index) => (
                            <label key={index}>{getCardRateByType(type_id as CardRateTypeID)} (%)</label>
                        ))}
                    </div>

                    <div className="cards-rate-group">
                        {Object.keys(values.cards_active).map(key => (
                            <div key={key} className="cards-rate-group-item">
                                <img src={logoCards[key as CardRateBrand]} alt="key" />

                                <div className="cards-rate-input-items">
                                    {values.cards_rate.map((item, index) => (
                                        <TextInputForm
                                            key={index}
                                            type="number"
                                            onChange={handleInputChange(`cards_rate.${index}.${key}`)}
                                            value={item[key as CardRateBrand]}
                                            msgErro={
                                                touched?.cards_rate?.[index as number]?.[key as CardRateObject] &&
                                                !item[key as CardRateBrand]?.length &&
                                                `Tarifa ${key} requerida`
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Endereço
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="CEP*"
                        mask="99999-999"
                        onChange={handleInputChange('address.zip_code')}
                        value={values.address.zip_code}
                        msgErro={touched.address?.zip_code && errors.address?.zip_code}
                    />
                    <TextInputForm
                        label="Número*"
                        onChange={handleInputChange('address.number')}
                        value={values.address.number}
                        msgErro={touched.address?.number && errors.address?.number}
                    />
                    <TextInputForm
                        label="Complemento"
                        onChange={handleInputChange('address.complement')}
                        value={values.address.complement}
                        msgErro={touched.address?.complement && errors.address?.complement}
                    />
                </div>

                <ButtonForm
                    buttonText="Salvar"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
                />
            </div>
        </div>
    )
}

export default EstablishmentsCreate
