import React, { memo } from 'react'

import { IconContainer } from './tag-icon.styled'

export type ITagIcons =
    | 'almonds'
    | 'chestnuts'
    | 'crustaceans'
    | 'dyes'
    | 'eggs'
    | 'fish'
    | 'gluten'
    | 'lactose'
    | 'milk'
    | 'soy'

type Props = {
    icon: ITagIcons
}

const TagIcon: React.FC<Props> = memo(({ icon }) => {
    return <IconContainer icon={icon} />
})

export { TagIcon }
