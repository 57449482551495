import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import colors from '../../themes/colors'

const MensagemErro = props => {
    if (!props.msgErro) return <div />

    return (
        <div className={classNames('item-input-form', 'error-container')}>
            <div className="msg-erro" style={{ color: props.msgColor ? props.msgColor : '#000' }}>
                <FontAwesomeIcon className="msg-erro-icon" icon="times-circle" color={colors.red} />{' '}
                <span>{props.msgErro}</span>
            </div>
        </div>
    )
}

export default MensagemErro
