import {
    BottomRow,
    EditIcon,
    OrderDate,
    OrderProblem,
    OrderStatusContainer,
    OrderTitle,
    OrderTitleRow,
    ScheduleDetail,
    SeeRoute,
    StatusCircle,
    StatusTitle,
    TopRowContainer,
} from './order-detail-top-row.styles'

interface Props {
    status: number
    captureDate: string
    captureTime: string
    orderNumber: number
    scheduleDate?: string
    scheduleTime?: string
    problemDetail?: string
    seeRouteClick?(): void
}

const OrderDetailTopRow: React.FC<Props> = ({
    status,
    captureDate,
    captureTime,
    orderNumber,
    scheduleDate,
    scheduleTime,
    problemDetail,
    seeRouteClick,
}) => {
    function renderStatus(status: number) {
        if (status === 1) {
            return 'Em Andamento'
        }
        if (status === 2) {
            return 'Concluído'
        }
        if (status === 3) {
            return 'Problema notificado:'
        }
        if (status === 4) {
            return 'Agendado'
        }
    }

    function renderDisclaimer(status: number) {
        if (status === 3) {
            return <OrderProblem>{problemDetail}</OrderProblem>
        }
        if (status === 4) {
            return (
                <ScheduleDetail>
                    {scheduleDate} às {scheduleTime} <EditIcon />
                </ScheduleDetail>
            )
        }
    }

    return (
        <TopRowContainer>
            <OrderTitleRow>
                <OrderTitle>Pedido #{orderNumber}</OrderTitle>
                <OrderStatusContainer>
                    <StatusCircle status={status} />
                    <StatusTitle>{renderStatus(status)}</StatusTitle>
                    {renderDisclaimer(status)}
                </OrderStatusContainer>
            </OrderTitleRow>
            <BottomRow>
                <OrderDate>
                    Capturado em{' '}
                    <span>
                        {captureDate} às {captureTime}
                    </span>
                </OrderDate>
                {status === 1 && <SeeRoute onClick={seeRouteClick}>Acompanhar Rota</SeeRoute>}
            </BottomRow>
        </TopRowContainer>
    )
}

export default OrderDetailTopRow
