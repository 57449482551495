import styled from 'styled-components'

const Container = styled.div`
    /* width: 100vw; */
    height: 100%;
    min-height: 25.625rem;
`

const TableSalesChannel = styled.table`
    width: 100%;
`

const TableSalesChannelContent = styled.tbody``

const FilterContent = styled.div`
    width: fit-content;
    padding: 1.25rem;
`

export { Container, TableSalesChannel, TableSalesChannelContent, FilterContent }
