import { useReducer } from 'react'

type ActionType = 'ADD' | 'UPDATE' | 'REMOVE' | 'SET'

interface Action {
    type: ActionType
    payload: any
}

const initialState: any[] = []

function reducer(state: any[], action: Action) {
    let data = [...state]
    let indexRoute = -1
    switch (action.type) {
        case 'ADD':
            indexRoute = data.findIndex(item => item.id === action.payload?.id)
            if (indexRoute === -1) {
                data.unshift(action.payload)
            } else {
                data[indexRoute] = action.payload
            }
            break
        case 'UPDATE':
            indexRoute = data.findIndex(item => item.id === action.payload?.id)

            if (indexRoute >= 0) {
                const oldData = { ...data[indexRoute] }
                data[indexRoute] = { ...oldData, ...action.payload }
            }
            break
        case 'REMOVE':
            data = data.filter(item => item.id !== action.payload?.id)
            break
        case 'SET':
            data = action.payload
            break
    }
    return data
}

function useList<T>() {
    const [data, dispatchData] = useReducer(reducer, initialState)

    return { data: data as T[], dispatchData }
}

export { useList }
