import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/button/Button'
import EmptyMessage from 'components/empty-message/empty-message'

import { getBikerType } from 'helpers'
import colors from 'themes/colors'
import { IAgent } from 'types'

import DelivererItem from '../deliverer-item/deliverer-item'

import './style.scss'

interface Props {
    title?: string
    closeClick?(): void
    data: IAgent[]
    onConfirmClick?(biker: IAgent | null): void
    selectedBiker?: IAgent | null
    setSelectedBiker?(biker: IAgent | null): void
    visible?: boolean
}

export const DeliverersQueueListing: React.FC<Props> = ({
    title,
    closeClick,
    onConfirmClick,
    data,
    selectedBiker = null,
    setSelectedBiker,
}) => {
    return (
        <div className="deliverers-queue-container">
            <div className="title-row">
                <div className="text">{title || 'Selecione um entregador'}</div>
                {closeClick && (
                    <div className="close" onClick={closeClick}>
                        <FontAwesomeIcon icon="times" />
                    </div>
                )}
            </div>

            <div className="deliverer-listing">
                <DelivererItem
                    delivererName="Ofertar p/ base"
                    isSelected={selectedBiker == null}
                    onClick={() => setSelectedBiker && setSelectedBiker(null)}
                />

                {data?.length > 0 ? (
                    data.map((item, index) => (
                        <DelivererItem
                            key={index}
                            checkpointName={item.device?.checkpoint}
                            photoPath={`${item.avatar}?width=100`}
                            isSelected={item.id === selectedBiker?.id}
                            delivererName={`${item.name}`}
                            vehicleName={getBikerType(item.type)}
                            deviceInfo={{
                                battery_level: item.device?.battery,
                                network_type: item.device?.signal,
                                app_version: item.device?.app,
                                app_status: item.device?.app_status,
                                android_version: item.device?.android,
                                strike: item.strike,
                                type_name: item.type,
                            }}
                            onClick={() => setSelectedBiker && setSelectedBiker(item)}
                        />
                    ))
                ) : (
                    <EmptyMessage icon="exclamation-triangle">
                        Não existem entregadores disponíveis no momento.
                    </EmptyMessage>
                )}
            </div>

            {onConfirmClick && (
                <Button
                    buttonText="Confirmar"
                    bgColor={colors.primary_color}
                    textColor="#fff"
                    width="100%"
                    onClick={() => onConfirmClick(selectedBiker)}
                />
            )}
        </div>
    )
}

const SelectDelivererModal: React.FC<Props> = ({
    visible,
    title,
    closeClick,
    onConfirmClick,
    data,
    selectedBiker,
    setSelectedBiker,
}) => {
    return (
        <div className="select-deliverer-modal" style={{ display: visible ? 'flex' : 'none' }}>
            <DeliverersQueueListing
                closeClick={closeClick}
                onConfirmClick={onConfirmClick}
                data={data}
                title={title}
                selectedBiker={selectedBiker}
                setSelectedBiker={setSelectedBiker}
            />
        </div>
    )
}

export default SelectDelivererModal
