/**
 * Return status name from an route
 *
 * @param {string} status
 */

import { RouteStatus } from 'types'

function getStatusRoute(status: RouteStatus): string {
    return {
        0: 'Aguardando entregador',
        1: 'Aceita pelo entregador',
        2: 'Em andamento',
        3: 'Finalizada',
        4: 'Recusada pelo entregador',
        5: 'Entregador substituído',
        6: 'Processo interrompido',
        7: 'Entregador indisponível',
        8: 'Tempo de espera ultrapassado',
        9: 'Cancelada',
    }[status]
}

export { getStatusRoute }
