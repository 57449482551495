import styled from 'styled-components'

import fastFood from 'assets/images/fast-food.png'

export const GroupDishesListing = styled.div``

export const ListingTitle = styled.div`
    display: none;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 40px;
    padding-bottom: 0;
    padding-top: 0;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        margin-left: 45px;
    }
`

export const GroupTitle = styled.div`
    flex-basis: 40%;
`

export const GroupTitleInfo = styled.div`
    flex-basis: 20%;
`

export const EmptyMessageContainer = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.lightGray};
    border-radius: 15px;
    padding: 70px;
`

export const EmptyImage = styled.img.attrs({
    src: fastFood,
})`
    height: 160px;
    margin-bottom: 20px;
`

export const EmptyMessage = styled.div`
    font-size: 14px;
    color: #c6c6c6;
    font-weight: bold;
`
