import { useCallback, useEffect, FC, useMemo, useState } from 'react'

import { faStreetView } from '@fortawesome/free-solid-svg-icons'

import { AvailableBikerCard } from 'components/_bikers'
import { EmptyListMessage } from 'components/_common'
import Loader from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'
import { api2 } from 'services/api'
import { Biker, IAgent } from 'types'
import { IPagination } from 'types/paginate'

import { Container, ContainerCounter, IconCounter, TitleCounter } from './operation-agents-available.styled'

interface OperationAgentsAvailableProps {
    onAgentsListChange?(agents: Biker[])
    onHoverAgent?(agent?: Biker)
}

const OperationAgentsAvailable: FC<OperationAgentsAvailableProps> = ({ onAgentsListChange, onHoverAgent }) => {
    const { mall } = useAuth()

    const { agents, loading } = useAgentsFetch()

    const { setErrorModal, setConfirmationModal, setLoading } = useUI()

    const _removeBikerFromQueue = useCallback(
        async (bikerId: number) => {
            try {
                setLoading(true)

                await api2.delete(`/malls/${mall.slug}/kick`, {
                    data: { agent: bikerId },
                })
            } catch (error) {
                setErrorModal({ title: 'Erro ao remover da fila', subtitle: showErrors(error) })
            } finally {
                setLoading(false)
            }
        },
        [mall, setErrorModal, setLoading]
    )

    const _onRemoveBiker = useCallback(
        (biker: Biker) => {
            setConfirmationModal({
                title: 'Remover da fila',
                subtitle: 'Deseja mesmo remover este entregador da fila?',
                type: 'alert',
                leftButtonText: 'Fechar',
                rightButtonText: 'Sim, remover',
                rightButtonClick: () => _removeBikerFromQueue(biker.id),
            })
        },
        [_removeBikerFromQueue, setConfirmationModal]
    )

    useEffect(() => {
        if (onAgentsListChange) {
            onAgentsListChange(agents)
        }
    }, [agents, onAgentsListChange])

    return (
        <Container>
            <ContainerCounter>
                <IconCounter icon={faStreetView} />
                <TitleCounter>{agents.length} entregadores disponíveis</TitleCounter>
            </ContainerCounter>
            {!loading && agents.length === 0 && (
                <EmptyListMessage message="Não há entregadores disponíveis" icon={faStreetView} />
            )}
            {agents?.map(biker => (
                <AvailableBikerCard
                    key={biker.id}
                    biker={biker}
                    onRemove={() => _onRemoveBiker(biker)}
                    onMouseOver={() => onHoverAgent(biker)}
                    onMouseLeave={() => onHoverAgent(null)}
                />
            ))}

            <Loader visible={loading} />
        </Container>
    )
}

function useAgentsFetch() {
    const { mall } = useAuth()

    const [loading, setLoading] = useState(false)

    const events = useMemo(() => {
        return [
            {
                name: '.added',
                callback: (payload: IAgent) => {
                    dispatchData({ type: 'ADD', payload })
                },
            },
            {
                name: '.location',
                callback: (payload: any) => {
                    dispatchData({ type: 'UPDATE', payload })
                },
            },
            {
                name: '.device-update',
                callback: (payload: any) => {
                    dispatchData({ type: 'UPDATE', payload })
                },
            },
            {
                name: '.removed',
                callback: (payload: IAgent) => {
                    dispatchData({ type: 'REMOVE', payload })
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data, dispatchData } = useEchoConnection<any, any>({
        enable: !!mall?.id,
        channelName: mall?.id ? `v2.queue.mall.${mall?.id}` : null,
        events,
    })

    const _listAgents = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api2.get<IPagination<IAgent>>(`malls/${mall.slug}/queue`)
            dispatchData({ type: 'SET', payload: data.items })
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [mall, dispatchData])

    useEffect(() => {
        _listAgents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return { agents: data, loading }
}

export default OperationAgentsAvailable
