import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const DetailsTabContainer = styled.div``

const InfoSection = styled.div`
    margin-bottom: 20px;
`

const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
`
export const Takeout = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
`

const InfoRow = styled.div`
    display: flex;
    align-items: flex-start;
`

const MapContainer = styled.div`
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border: 1px solid #f1f1f1;
    margin-bottom: 20px;
`

const ScheduleButton = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    text-align: center;
    border-radius: 4px;
    padding: 10px 60px;
    display: inline-flex;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

const EditIcon = styled(FontAwesomeIcon).attrs({
    icon: faEdit,
})`
    margin-left: 20px;
    position: absolute;
    top: 10px;
    right: 5px;
`

export { DetailsTabContainer, InfoSection, SectionTitle, InfoRow, MapContainer, ScheduleButton, EditIcon }
