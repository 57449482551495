import { faBiking, faMapMarkedAlt, faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import mobileLogo from '../../../assets/images/logaroo-logo-full-orange.png'
import logoImage from '../../../assets/images/logaroo-logo-full-white.png'
import map from '../../../assets/images/mapa.png'
import mercadoo from '../../../assets/images/mercadoo.png'
import plug from '../../../assets/images/plug.png'

export const MapImage = styled.img.attrs({
    src: map,
})`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
`

export const LoginPageContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
`

export const LeftSide = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 60%;
        display: flex;
    }
`

export const LeftLogo = styled.img.attrs({
    src: logoImage,
})`
    width: 400px;
    margin-bottom: 70px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 500px;
    }
`

export const LoginImageContainer = styled.div`
    width: 600px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 700px;
    }
`

export const LoginImage = styled.img<{ isChanging?: boolean }>`
    width: ${({ isChanging }) => (isChanging ? '100%' : '100%')};
    opacity: ${({ isChanging }) => (isChanging ? 0 : 1)};
    transition: 800ms;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: ${({ isChanging }) => (isChanging ? '100%' : '100%')};
    }
`

export const RightSide = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    position: relative;
    height: 100%;
`

export const LoginTitle = styled.div`
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    position: relative;
    &::after {
        content: '';
        width: 90px;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 90%;
        left: 0;
    }
`

export const LoginSubtitle = styled.div`
    font-size: 14px;
    margin-bottom: 60px;
    width: 90%;
`

export const LoginBox = styled.div`
    width: 90%;
    overflow-x: hidden;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 80%;
    }
`

export const ButtonsRow = styled.div`
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: space-between;
    position: relative;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`

export const MainButton = styled.div<{ sentLink?: boolean }>`
    background-color: ${({ theme, sentLink }) => (sentLink ? theme.colors.success : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    padding: 10px 20px;
    flex: 1;
    text-align: center;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    &:hover {
        opacity: 0.6;
    }
`

export const MobileLogo = styled.img.attrs({
    src: mobileLogo,
})`
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const SignupBox = styled.div`
    margin-top: 80px;
    text-align: center;
    position: absolute;
    bottom: 50px;
    left: 10%;
    width: 80%;
`

export const SignupCall = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`

export const SignupButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    width: 98%;
    padding: 10px 0px;
    margin: auto;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`

export const AreaIcon = styled.div`
    width: 30px;
    margin-right: 20px;
    font-size: 24px;
    transition: 400ms;
    color: ${({ theme }) => theme.colors.primary};
    svg {
        width: 100%;
    }
`

export const AreaItem = styled.div`
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 20px;
    border: 1px solid #f6f6f6;
    transition: 200ms;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    &:hover {
        border-color: ${({ theme }) => theme.colors.primary};
        ${AreaIcon} {
            transform: translateX(5px);
        }
    }
`

export const AreaText = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #777;
`

export const RouteIcon = styled(FontAwesomeIcon).attrs({
    icon: faMapMarkedAlt,
})``

export const BikersIcon = styled(FontAwesomeIcon).attrs({
    icon: faBiking,
})``

export const MercadooIcon = styled.img.attrs({
    src: mercadoo,
})`
    width: 32px;
`

export const PlugIcon = styled.img.attrs({
    src: plug,
})`
    width: 32px;
`

export const MallsIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})``

export const MallItem = styled.div`
    position: relative;
    padding-left: 35px;
    font-size: 14px;
    font-weight: 600;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ddd;
        position: absolute;
        top: 3px;
        left: 7px;
        transition: 400ms;
    }
    &:hover {
        &::before {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export const TopSignupTag = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 20px;
    font-size: 14px;
`

export const DesktopText = styled.div`
    margin-right: 20px;
`

export const TagOutline = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 5px 20px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    transition: 400ms;
    user-select: none;
    text-transform: uppercase;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const ResendLink = styled.div`
    font-size: 12px;
`

export const SendContainer = styled.div`
    flex: 1;
    text-align: center;
    margin-left: 20px;
    position: relative;
    margin-top: 20px;
`

export const CounterSection = styled.div`
    text-align: center;
    margin-top: 5px;
    width: 100%;
    font-size: 12px;
`

export const StrengthContainer = styled.div`
    width: 100%;
`
