import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { SwitchButton } from 'components/_marketplace-management'
import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'

import { useUI } from 'contexts'
import { formatCurrency } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { IAttendanceArea } from 'types/attendance-area'

import {
    Container,
    Content,
    Header,
    HeaderActions,
    HeaderTitle,
    IconContent,
    RateContent,
    RateIcon,
    RateValue,
    Row,
    TableContainer,
} from './attendance-area.styled'
import { AttendanceAreaModal, AttendanceAreaModalRef } from './components'

type Props = {
    type: 'mall' | 'store'
}
const AttendanceArea: React.FC<Props> = memo(({ type }) => {
    const { setLoading, setErrorModal, setConfirmationModal } = useUI()
    const { store, mall } = useAuth()

    const attendanceAreaModalRef = useRef<AttendanceAreaModalRef>()

    const [attendanceAreas, setAttendanceAreas] = useState<IAttendanceArea[]>()

    const requestURL = useMemo(() => {
        if (type === 'mall') {
            return `painel/mall/${mall.id}/attendance-area`
        }
        return `painel/store/${store.id}/attendance-area`
    }, [type, mall, store])

    const _openModal = useCallback(() => {
        attendanceAreaModalRef.current?.show()
    }, [])

    const _openEdit = useCallback(
        (attendanceArea: IAttendanceArea) => () => {
            _openModal()
            attendanceAreaModalRef.current?.setAttendanceArea(attendanceArea)
        },
        [_openModal]
    )

    const _getRates = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IAttendanceArea>>(requestURL, {
                params: {
                    order_by: 'id',
                },
            })
            setAttendanceAreas(data.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar área de atendimento',
            })
        } finally {
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestURL])

    const _updateStatus = useCallback(
        (item: IAttendanceArea) => async () => {
            try {
                await api.put<IAttendanceArea>(`/painel/attendance-area/${item.id}`, {
                    status: !item.status ? 1 : 0,
                })
                _getRates()
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'não foi possível atualizar status da área de atendimento',
                })
            }
        },
        [_getRates]
    )

    const _formatRage = useCallback((range: { start: number; end: number }): string => {
        const formatDistance = (value: number) => {
            if (value >= 1000) {
                return Math.round(value / 1000.0) + ' KM'
            }
            if (value >= 100) {
                return Math.round(value) + ' m'
            }
            if (Number(value) === 0) {
                return '0m'
            }
            return value.toFixed(1) + ' m'
        }

        return `${formatDistance(range?.start)} ~ ${formatDistance(range?.end)}`
    }, [])

    const _handleRefresh = useCallback(
        (isRefresh: boolean) => {
            if (isRefresh) {
                _getRates()
            }
        },
        [_getRates]
    )

    const _handleDelete = useCallback((id: number) => {
        const deleteTag = async () => {
            attendanceAreaModalRef.current?.close()
            setLoading(true)

            try {
                await api.delete(`/painel/attendance-area/${id}`)
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir a área de atendimento!',
                })
            }
            setLoading(false)
        }

        return (): void => {
            setConfirmationModal({
                title: 'Excluir Área de Atendimento!',
                subtitle: 'Tem certeza de que deseja excluir esta área de atendimento?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: deleteTag,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        _getRates()
    }, [_getRates])

    return (
        <Container>
            <AttendanceAreaModal
                type={type}
                baseUrl={requestURL}
                ref={attendanceAreaModalRef}
                onRemove={_handleDelete}
                onRefresh={_handleRefresh}
            />

            <Content>
                <Header>
                    <HeaderTitle>Tarifas</HeaderTitle>
                    <HeaderActions>
                        <DefaultButton onClick={_openModal}>Nova Tarifa</DefaultButton>
                    </HeaderActions>
                </Header>
                <TableContainer>
                    <FlexTable
                        columns={[
                            { name: 'Status', width: '3%' },
                            { name: 'ID', width: '5%' },
                            { name: 'Tarifa', width: '20%' },
                            { name: 'A Cobrar do Cliente', width: '10%' },
                            { name: 'Custo Para Loja', width: '10%' },
                            { name: 'Grátis a partir de:', width: '10%' },
                            { name: 'Raio de Atendimento:', width: '10%' },
                            { name: '', width: '10%' },
                        ]}
                        list={attendanceAreas?.map((item, i) => {
                            const freeFee = item.configs?.free_fee.enabled
                                ? formatCurrency(item.configs?.free_fee.value)
                                : ' - '
                            const range = item.range ? _formatRage(item.range) : ' - '

                            return {
                                status: <SwitchButton isActive={Boolean(item.status)} onClick={_updateStatus(item)} />,
                                id: String(item?.id),
                                rate: item.label,
                                client_value: <RateItem icon={'user'} value={item.values.fee} />,
                                store_value: <RateItem icon={'store-alt'} value={item.values.cost} />,
                                freeFrom: freeFee,
                                range,
                                buttonRow: <DefaultButton onClick={_openEdit(item)}>Editar</DefaultButton>,
                            }
                        })}
                    />
                </TableContainer>
            </Content>
        </Container>
    )
})

type IRateItemProp = { icon: IconProp; value: number | string }

const RateItem: React.FC<IRateItemProp> = memo(({ icon, value }) => {
    return (
        <RateContent>
            {/*
                <Row>
                    <IconContent>
                        <RateIcon icon="store-alt" />
                    </IconContent>
                    <RateValue>{formatCurrency(values.store)}</RateValue>
                </Row>
            */}
            <Row>
                <IconContent>
                    <RateIcon icon={icon} />
                </IconContent>
                <RateValue>{formatCurrency(value)}</RateValue>
            </Row>
        </RateContent>
    )
})

export { AttendanceArea }
