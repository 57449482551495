/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useRef, useImperativeHandle, useCallback, useContext, useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import { DefaultButton } from 'components/default-button/default-button'

import { DistrictContext } from 'contexts/district-context'

import { ContainerDistrics, Badge, Footer, Message, Header } from './districts-modal.styled'

type DistrictsModal = {
    show?(): void
    close?(): void
}

interface DistrictsModalProps {
    name?: string
}

// eslint-disable-next-line react/display-name
const DistrictsModal = forwardRef<DistrictsModal, DistrictsModalProps>((_, ref) => {
    const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

    const [all, setAll] = useState(false)

    const { districts, selectedDistricts, toogleDistrict, setDistrict } = useContext(DistrictContext)

    useImperativeHandle(
        ref,
        () => ({
            show: () => {
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
        }),
        [lateralModalBaseRef]
    )

    const _toogleDistrict = useCallback(
        (item: string) => {
            return () => {
                toogleDistrict(item)
            }
        },
        [toogleDistrict]
    )

    const _selectAll = useCallback(() => {
        if (all) {
            setDistrict([])
            setAll(false)
        } else {
            setDistrict(districts)
            setAll(true)
        }
    }, [all, districts, setDistrict])

    useEffect(() => {
        if (selectedDistricts.length > 0) {
            setAll(true)
        }
    }, [selectedDistricts])

    return (
        <LateralModalBase
            ref={lateralModalBaseRef}
            title="Selecionar Bairros"
            footerComponent={
                <Footer>
                    <DefaultButton title="Fechar" onClick={lateralModalBaseRef.current?.close} />
                </Footer>
            }
        >
            <Header>
                <Badge selected={!all} className="badge" onClick={_selectAll}>
                    {all ? 'Remover Todos' : 'Adicionar Todos'}{' '}
                    <FontAwesomeIcon icon={all ? 'minus-circle' : 'plus-circle'} />
                </Badge>
            </Header>
            <Message>
                Selecione na listagem abaixo os bairros que você deseja filtrar dentre os pedidos prontos p/ coleta
            </Message>
            <ContainerDistrics>
                {districts.map((item, i) => (
                    <Badge key={i} selected={selectedDistricts.includes(item)} onClick={_toogleDistrict(item)}>
                        {item}
                    </Badge>
                ))}
            </ContainerDistrics>
        </LateralModalBase>
    )
})

export default DistrictsModal
