import { useState, useEffect, useRef, useCallback, useReducer } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalAddAllowedDeliveries } from 'modals'

import ViewControl from 'components/view-control/view-control'

import { useAuth } from 'hooks'

import EmptyMessage from '../../components/empty-message/empty-message'
import { ItemAllowedDeliveryman } from '../../components/items'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalMessage from '../../components/modal-message'
import Spinner from '../../components/spinner/spinner'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import api from '../../services/api'

import { LoadButton } from './allowed-deliveries-page.styled'

import './allowed-deliveries-page.scss'

const initialStateParams = {
    per_page: 20,
    current_page: 1,
}

function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

const AllowedDeliveriesPage = ({ history }) => {
    const { basePath, user, mall } = useAuth()

    const modalMessage = useRef()
    const modalRemoveRef = useRef()
    const modalBlockRef = useRef()
    const modalAddRef = useRef()

    const [params, setParams] = useReducer(reducerParams, initialStateParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)

    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [bikers, setBikers] = useState([])
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [removeData, setRemoveData] = useState({ id: null, name: '' })
    const [blockData, setBlockData] = useState({ id: null, name: '' })
    const [filter, setFilter] = useState('approved')

    const _getBikers = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/fleets', {
                params: {
                    ...params,
                    current_page: 1,
                    malls: [mall.id],
                    [filter]: 1,
                },
            })

            setBikers(data.items)
            setParams({
                current_page: data.current_page,
            })
            setTotal(data.totals)
            setCurrentPage(data.current_page)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar a lista de entregadores.',
                onClose: () => {},
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history, user, params, mall, filter])

    const _pagination = async () => {
        if (total <= bikers.length) {
            return
        }

        if (loadingMore) {
            return
        }

        if (params.page + 1 === currentPage) {
            return
        }

        try {
            setCurrentPage(currentPage + 1)
            setLoadingMore(true)

            const { data } = await api.get('/painel/fleets', {
                params: {
                    ...params,
                    current_page: params.current_page + 1,
                    malls: [mall.id],
                    [filter]: 1,
                },
            })

            setParams({
                current_page: data.current_page,
            })
            setTotal(data.totals)
            setCurrentPage(data.current_page)
            setBikers([...bikers, ...data.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoadingMore(false)
        }
    }

    useEffect(() => {
        if (filter) {
            _getBikers()
        }
    }, [filter])

    const _blockBiker = async id => {
        try {
            setLoading(true)

            await api.delete(`/painel/fleets/${id ? id : blockData.id}/block`)

            setModalMessageData({
                title: 'Sucesso',
                message: 'Entregador bloqueado com sucesso!',
            })

            modalMessage.current?.openModal()

            _getBikers()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível bloqueado os dados do entregador. Tente novamente mais tarde.',
                onClose: () => {},
            })

            modalMessage.current?.openModal()
        }
        setTimeout(() => {
            setBlockData(null)
        }, 500)
    }

    const _removeBiker = async id => {
        try {
            setLoading(true)

            await api.delete(`/painel/fleets/${id ? id : removeData.id}`)

            setModalMessageData({
                title: 'Sucesso',
                message: 'Entregador removido com sucesso!',
            })

            modalMessage.current?.openModal()

            _getBikers()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível remover os dados do entregador. Tente novamente mais tarde.',
                onClose: () => {},
            })

            modalMessage.current?.openModal()
        }

        setTimeout(() => {
            setRemoveData(null)
        }, 500)
    }
    const _approveBiker = async id => {
        try {
            setLoading(true)

            await api.put(`/painel/fleets/${id}/approve`)

            setModalMessageData({
                title: 'Sucesso',
                message: 'Entregador aprovado com sucesso!',
            })

            modalMessage.current?.openModal()

            _getBikers()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível aprovar o entregador. Tente novamente mais tarde.',
                onClose: () => {},
            })

            modalMessage.current?.openModal()
        }
    }

    return (
        <div className="page-container allowed-deliveries-page">
            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalRemoveRef}
                title="Remover Entregador"
                message={`Deseja mesmo remover o entregador ${removeData?.name} da lista de autorizados?`}
                onYes={() => _removeBiker()}
            />

            <ModalConfirmation
                ref={modalBlockRef}
                title="Bloquear Entregador"
                message={`Deseja mesmo bloquear o entregador ${blockData?.name} da lista de autorizados?`}
                onYes={() => _blockBiker()}
            />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Entregadores Autorizados"
            />
            <div className="content-container">
                <div className="left-column">
                    <div className="title-row">
                        <div>
                            <div className="section-title">Entregadores</div>
                            <ViewControl
                                leftItem="Aprovados"
                                rightItem="Pendentes"
                                leftItemActive={filter === 'approved'}
                                rightItemActive={filter === 'pending'}
                                setLeftItemActive={() => setFilter('approved')}
                                setRightItemActive={() => setFilter('pending')}
                            />
                        </div>
                        <div
                            className="add-button"
                            onClick={() =>
                                modalAddRef.current.show({
                                    onAddSuccess: _getBikers,
                                    onApproveClick: _approveBiker,
                                    onRemoveClick: (id, name) => {
                                        setRemoveData({ id: id, name: name })
                                        modalRemoveRef.current?.openModal()
                                    },
                                })
                            }
                        >
                            Adicionar entregador. <FontAwesomeIcon icon="plus-circle" />
                        </div>
                    </div>
                    <div className="listing">
                        {loading ? (
                            <div className="loader-container">
                                <Spinner />
                            </div>
                        ) : bikers.length ? (
                            <>
                                {bikers.map((item, index) => (
                                    <ItemAllowedDeliveryman
                                        key={index}
                                        id={item.id}
                                        biker={item.agent}
                                        blockClick={() => {
                                            setBlockData({
                                                id: item.id,
                                                name: item.agent.name,
                                            })

                                            modalBlockRef.current?.openModal()
                                        }}
                                        viewClick={() =>
                                            modalAddRef.current.show({
                                                id: item.id,
                                                agent: item.agent,
                                                onApproveClick: !item.approved_at && _approveBiker,
                                                onRemoveClick: () => {
                                                    setRemoveData({ id: item.id, name: item.agent.name })
                                                    modalRemoveRef.current?.openModal()
                                                },
                                            })
                                        }
                                    />
                                ))}
                                {total !== bikers.length && (
                                    <LoadButton onClick={_pagination}>
                                        {loadingMore ? 'Carregando...' : 'Carregar Mais'}
                                    </LoadButton>
                                )}
                            </>
                        ) : (
                            <EmptyMessage icon="user-friends">Não há entregadores cadastrados.</EmptyMessage>
                        )}
                    </div>
                </div>
                <div className="right-column"></div>
            </div>
            <ModalAddAllowedDeliveries ref={modalAddRef} />
        </div>
    )
}

export default AllowedDeliveriesPage
