import { memo } from 'react'

import { Text } from 'components/text/text'

import { Container } from './view-info.styled'

type Props = {
    label: string
    value?: string
    items?: string[]
    variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'alert' | 'foregroundPrimary' | null
}

const ViewInfo: React.FC<Props> = memo(({ label, value, items, variant }) => {
    return (
        <Container className="info">
            <Text text={label} dimensions="small" variant={variant} bold />
            <Text text={value} variant={variant} light marginTop={4} />
            {items?.map((text, i) => (
                <Text key={i} text={text} marginTop={5} variant={variant} light />
            ))}
        </Container>
    )
})

export { ViewInfo }
