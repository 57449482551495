import { forwardRef, useRef, useImperativeHandle, useCallback, useState } from 'react'

import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import EmptyMessage from 'components/empty-message/empty-message'
import { ItemProblemCard } from 'components/items'
import ModalLoading from 'components/modal-loading'

import { useAuth, useDataFetching } from 'hooks'
import api from 'services/api'
import { PagedList, Route } from 'types'

import { ContainerCardListing } from './routes-not-accepted-modal.styled'

type RoutesNotAcceptedModal = {
    show?(): void
    close?(): void
}
interface RoutesNotAcceptedModalProps {
    name?: string
}
// eslint-disable-next-line react/display-name
const RoutesNotAcceptedModal = forwardRef<RoutesNotAcceptedModal, RoutesNotAcceptedModalProps>((props, ref) => {
    const { mall } = useAuth()

    const [routes, setRoutes] = useState<Route[]>([])
    const lateralModalBaseRef = useRef<LateralModalBase | null>(null)
    const [loading, setLoading] = useState(true)

    const _getRoutes = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<Route>>(`/painel/routes/pending`, {
                params: { mall: mall.id, per_page: -1 },
            })
            setRoutes(data.items)
        } catch (err) {
            console.log('ON ROUTES CLICK ERROR:', err)
            setRoutes(undefined)
        }
        setLoading(false)
    }, [mall.id])

    useImperativeHandle(
        ref,
        () => ({
            show: () => {
                _getRoutes()
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
        }),
        [lateralModalBaseRef, _getRoutes]
    )

    return (
        <LateralModalBase ref={lateralModalBaseRef} title="Rotas não aceitas" refresh={_getRoutes}>
            {!!routes && (
                <ContainerCardListing>
                    {!routes.length ? (
                        <div className="empty-container">
                            <EmptyMessage icon="biking">Você não possui rotas não aceitas</EmptyMessage>
                        </div>
                    ) : (
                        routes.map((item, i) => <ItemProblemCard key={i} route={item} />)
                    )}
                </ContainerCardListing>
            )}
            <ModalLoading visible={loading} />
        </LateralModalBase>
    )
})

export default RoutesNotAcceptedModal
