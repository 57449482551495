import React, { memo, useCallback } from 'react'

import { Container, StyledSlider, Thumb, Track } from './slider.styled'

interface SliderProps {
    defaultValue?: number
    onValueChange?(value: number): void
}

const Slider: React.FC<SliderProps> = memo(({ defaultValue, onValueChange }) => {
    const _thumb = useCallback(
        (props, state) => {
            if (onValueChange) {
                onValueChange(state.valueNow)
            }
            return <Thumb {...props}>{state.valueNow}</Thumb>
        },
        [onValueChange]
    )
    const _track = useCallback((props, state) => <Track {...props} index={state.index} />, [])

    return (
        <Container>
            <StyledSlider
                min={5}
                max={70}
                step={5}
                defaultValue={defaultValue || 0}
                renderTrack={_track}
                renderThumb={_thumb}
            />
        </Container>
    )
})

export { Slider }
