import { serialize } from 'object-to-formdata'

const options = {
    indices: true,
    nullsAsUndefineds: true,
    booleansAsIntegers: true,
    allowEmptyArrays: true,
    noFilesWithArrayNotation: false,
    dotsForObjectNotation: false,
}

function transformFormData(object: any) {
    return serialize(object, options)
}

export { transformFormData }
