import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SelectInput from 'components/select-input'

import { useUI } from 'contexts'
import { setSearchParams } from 'helpers'
import { useAuth } from 'hooks'
import { AdministrationUser } from 'types/user'

import AdministrationListingItem from '../../components/administration-listing-item/administration-listing-item'
import EmptyMessage from '../../components/empty-message/empty-message'
import { ModalCreateAdmin, ModalCreateAdminRef } from '../../components/modal-create-admin/modal-create-admin'
import Spinner from '../../components/spinner/spinner'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import api from '../../services/api'

import { ModalChangePassword } from './components/modal-change-password/modal-change-password'

import './administration-page.scss'

interface Props extends RouteComponentProps {}

type AdminRoles = 'admin' | 'manager'
type Status = 0 | 1

const AdministrationPage: React.FC<Props> = memo(({ history }) => {
    const { basePath, user } = useAuth()

    const { setErrorModal, setConfirmationModal } = useUI()

    const modalCreateAdminRef = useRef<ModalCreateAdminRef>(null)
    const modalChangePasswordRef = useRef<ModalChangePassword>()

    const initialFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            roles: query.get('role') || [],
            malls: query.get('mall') || [],
            status: query.get('status') || 1,
        }
    }, [location.search])

    const [loading, setLoading] = useState(false)
    const [admins, setAdmins] = useState<AdministrationUser[]>([])
    const [filter, setFilter] = useState(initialFilter)
    const [malls, setMalls] = useState([])

    const _getAdmins = useCallback(
        async (filter?: any) => {
            try {
                setLoading(true)

                const roles = filter?.roles?.filter(item => !!item) || []

                const { data } = await api.get('/painel/administrators', {
                    params: {
                        ...filter,
                        status: [filter?.status || 1],
                        per_page: 50,
                        roles: roles.length > 0 ? filter.roles : ['admin', 'manager'],
                    },
                })

                setAdmins(data.items.filter(item => item.id !== user.id))
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível carregar a lista de administradores.',
                    //singleButtonClick: () => history.push(`${basePath}/gestao-mall`),
                })
            }

            setLoading(false)
        },
        [user, setErrorModal]
    )

    const _getMalls = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/malls', {
                params: { order_by: 'name', status: [1], per_page: 100 },
            })

            setMalls(data.items)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])

    const _deleteAdmin = useCallback(
        (id: number) => async () => {
            modalCreateAdminRef.current?.close()

            setLoading(true)

            try {
                await api.delete(`/painel/administrator/${id}`)

                _getAdmins()
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir os dados do administrador. Tente novamente mais tarde.',
                })
            }
            setLoading(false)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const _handleDelete = useCallback((id: number) => {
        return (): void => {
            setConfirmationModal({
                title: 'Excluir Administrador.',
                subtitle: 'Deseja mesmo excluir este administrador?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: _deleteAdmin(id),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleEdit = useCallback(
        (isRefresh: boolean) => {
            if (isRefresh) {
                _getAdmins(filter)
            }
        },
        [filter, _getAdmins]
    )

    const _openModal = useCallback((modal: 'user' | 'password') => {
        if (modal === 'password') {
            modalChangePasswordRef.current?.show()
            return
        }

        modalCreateAdminRef.current?.show()
    }, [])

    const _openEdit = useCallback((modal: 'user' | 'password', user?: any, isUserData?: boolean) => {
        const userData = {
            id: user?.id,
            first_name: user?.first_name,
            last_name: user?.last_name,
        }

        if (modal === 'password') {
            _openModal(modal)
            modalChangePasswordRef.current?.setUser(userData)
            return
        }

        _openModal(modal)
        modalCreateAdminRef.current?.setUser(
            {
                ...userData,
                email: user?.email,
                status: user?.status as Status,
                role: user?.roles[0].name as AdminRoles,
                malls: user?.malls.map(item => item.id),
            },
            isUserData
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setFilterParam = useCallback(
        (key, value) => {
            setFilter({ ...filter, [key]: value })
        },
        [filter]
    )

    useEffect(() => {
        _getMalls()
        _getAdmins()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="administration-page">
            <ModalCreateAdmin ref={modalCreateAdminRef} onClose={_handleEdit} onRemove={_handleDelete} />

            <ModalChangePassword ref={modalChangePasswordRef} />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Gerenciar Administração"
            />
            <div className="content-container">
                <div className="left-column">
                    <div className="title-row">
                        <div className="title-row-content">
                            <div className="filter-row">
                                <div className="filter-item">
                                    <SelectInput
                                        placeholder="Todas as funções"
                                        data={[
                                            { name: 'Admin', value: 'admin' },
                                            { name: 'Gestor', value: 'manager' },
                                        ]}
                                        onChange={({ target: { value } }) => setFilterParam('roles', [value])}
                                        value={(filter.roles && filter.roles[0]) || ''}
                                    />
                                </div>
                                <div className="filter-item">
                                    <SelectInput
                                        placeholder="Todos os malls"
                                        data={malls.map(item => ({ name: item.name, value: item.id }))}
                                        onChange={({ target: { value } }) => setFilterParam('malls', [value])}
                                        value={(filter.malls && filter.malls[0]) || ''}
                                    />
                                </div>
                                <div className="filter-item">
                                    <SelectInput
                                        placeholder="Status de usuário"
                                        data={[
                                            { name: 'Ativo', value: '1' },
                                            { name: 'Inativo', value: '0' },
                                        ]}
                                        onChange={({ target: { value } }) => setFilterParam('status', value)}
                                        value={filter.status || ''}
                                    />
                                </div>

                                <div
                                    className="filter-button"
                                    onClick={() => {
                                        setSearchParams(filter, location, history)
                                        _getAdmins(filter)
                                    }}
                                >
                                    Filtrar
                                </div>
                            </div>
                            <div
                                className="add-button"
                                onClick={() => {
                                    _openModal('user')
                                }}
                            >
                                Adicionar adm. <FontAwesomeIcon icon="plus-circle" />
                            </div>
                        </div>
                        <div className="totals">
                            Sua busca retornou <span>{admins?.length}</span>{' '}
                            {admins?.length > 1 ? 'resultados' : 'resultado'}
                        </div>
                    </div>
                    <div className="listing">
                        {loading ? (
                            <div className="loader-container">
                                <Spinner />
                            </div>
                        ) : admins.length ? (
                            admins.map((item, index) => (
                                <AdministrationListingItem
                                    key={index}
                                    roles={item.roles}
                                    status={item.status}
                                    adminName={`${item.first_name} ${item.last_name}`}
                                    adminEmail={item.email}
                                    editClick={() => {
                                        _openEdit('user', item)
                                    }}
                                    deleteClick={_handleDelete(item.id)}
                                    passwordClick={() => {
                                        _openEdit('password', item)
                                    }}
                                />
                            ))
                        ) : (
                            <EmptyMessage icon="user-friends">Não há administradores cadastrados.</EmptyMessage>
                        )}
                    </div>
                </div>
                <div className="right-column">
                    <div className="section-title">Minha Conta</div>
                    <div className="user-content">
                        <div className="info-item">
                            <div className="info-title">Nome:</div>
                            <div className="info-content">{user.first_name}</div>
                        </div>
                        <div className="info-item">
                            <div className="info-title">Sobrenome:</div>
                            <div className="info-content">{user.last_name}</div>
                        </div>
                        <div className="info-item">
                            <div className="info-title">E-mail:</div>
                            <div className="info-content">{user.email}</div>
                        </div>
                    </div>

                    <div className="button-row">
                        <div
                            className="button-item outline"
                            onClick={() => {
                                _openEdit('user', user, true)
                            }}
                        >
                            Editar meus dados
                        </div>
                        <div
                            className="button-item"
                            onClick={() => {
                                _openEdit('password', user)
                            }}
                        >
                            Alterar minha senha
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default AdministrationPage
