import InfoItem from 'components/_new-general-vision/info-item/info-item'

import { formatCurrency } from 'helpers'
import { Order } from 'types'

import { InfoSection, InputsRow, SectionTitle } from './details-tab.styles'

interface Props {
    order?: Order
}

const DetailsTab: React.FC<Props> = ({ order }) => {
    return (
        <InfoSection>
            <SectionTitle>Informações sobre o pedido</SectionTitle>
            <InfoItem label="Lojista" content={order?.merchant.name} />
            <InputsRow>
                <InfoItem label="Canal de venda" content={order?.sales_channel?.name || order?.reference_name} />
                <InfoItem label="Número do pedido" content={order?.reference_id} />
            </InputsRow>
            <InputsRow>
                <InfoItem label="Nome do cliente" content={order?.customer?.name} />
                <InfoItem label="Bairro do cliente" content={order?.address?.neighborhood as string} />
            </InputsRow>
            <InputsRow>
                <InfoItem label="Forma de Pagamento" content={order?.payment?.name} />
                <InfoItem label="Valor do Pedido" content={formatCurrency(order?.total_price)} />
            </InputsRow>
        </InfoSection>
    )
}

export default DetailsTab
