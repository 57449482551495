import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { getIn, useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

export const TransactionAdjustmentConfiguration: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [establishments, setEstablishments] = useState({ establishment_list: [] })
    const [selectedEstablishment, setSelectedEstablishment] = useState('')
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const { errors, handleSubmit, initialValues, isSubmitting, setFieldValue, setValues, touched, values } = useFormik({
        initialValues: {
            labels_of_amount_adjustment_resume: {
                original_amount_label: '',
                adjustment_amount_label: '',
                adjusted_final_amount_label: '',
            },
            configurations_of_amount_adjustment: [
                {
                    adjustment_percent: '',
                    adjustment_type: '',
                    apply_adjustment_for_payment_type: '',
                },
            ],
        },
        validationSchema: Yup.object().shape({
            labels_of_amount_adjustment_resume: Yup.object().shape({
                original_amount_label: Yup.string().required('Rótulo de valor original é obrigatório'),
                adjustment_amount_label: Yup.string().required('Rótulo de valor de ajuste é obrigatório'),
                adjusted_final_amount_label: Yup.string().required('Rótulo de valor final é obrigatório'),
            }),
            configurations_of_amount_adjustment: Yup.array().of(
                Yup.object().shape({
                    adjustment_percent: Yup.number()
                        .min(1, 'Ajuste percentual deve ser pelo menos de 1%')
                        .required('Ajuste percentual é requerido'),
                    adjustment_type: Yup.string().required('Selecione o Tipo de Ajuste'),
                    apply_adjustment_for_payment_type: Yup.string().required('Selecione o Tipo de pagamento'),
                })
            ),
        }),
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                setSubmitting(true)

                const { data } = await siclosPagApi.post(
                    '/adjustment/create-transaction-adjustment-amount-configuration',
                    {
                        ...values,
                        establishment_id: selectedEstablishment,
                        configurations_of_amount_adjustment: values.configurations_of_amount_adjustment.map(
                            ({ adjustment_percent, adjustment_type, apply_adjustment_for_payment_type }) => ({
                                adjustment_percent: Number(adjustment_percent),
                                adjustment_type: Number(adjustment_type),
                                apply_adjustment_for_payment_type: Number(apply_adjustment_for_payment_type),
                            })
                        ),
                    }
                )

                if (data?.error) {
                    throw data.msg
                }

                setSelectedEstablishment('')
                resetForm()

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Ajustes configurados com sucesso!',
                })

                modalMessage.current?.openModal()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    isActive: true,
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessage.current?.openModal()
            } finally {
                setSubmitting(false)
            }
        },
    })

    const getTransactionAdjustments = useCallback(
        async (selectedEstablishment: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get(
                    `/adjustment/get-transaction-adjustment-amount-configuration/${selectedEstablishment}`
                )

                setValues({ ...initialValues, ...data.transaction_adjustment_amount_configurations })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/painel-malls'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history, initialValues, setValues]
    )

    useEffect(() => {
        selectedEstablishment && getTransactionAdjustments(selectedEstablishment)
    }, [getTransactionAdjustments, selectedEstablishment])

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    function handleInputChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field, value)
        }
    }

    function handleSelectChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue(field, value)
        }
    }

    function getFormInputError(name: string) {
        const error = getIn(errors, name)
        const touch = getIn(touched, name)
        return touch && error ? error : null
    }

    function addConfiguration() {
        if (values.configurations_of_amount_adjustment.length === 2) {
            setModalMessageData({
                title: 'Atenção!',
                message: 'É possível criar no máximo duas configurações por estabelecimento.',
            })

            modalMessage.current?.openModal()
            return
        }

        setFieldValue('configurations_of_amount_adjustment', [
            ...values.configurations_of_amount_adjustment,
            {
                adjustment_percent: 0,
                adjustment_type: '',
                apply_adjustment_for_payment_type: '',
            },
        ])
    }

    function removeConfiguration() {
        if (values.configurations_of_amount_adjustment.length === 1) {
            setModalMessageData({
                title: 'Atenção!',
                message: 'Para salvar um ajuste de transação é necessário ter pelo menos uma configuração.',
            })

            modalMessage.current?.openModal()
            return
        }

        values.configurations_of_amount_adjustment.pop()

        setFieldValue('configurations_of_amount_adjustment', values.configurations_of_amount_adjustment)
    }

    return (
        <div>
            <RowTitle title="Ajustes de transação">
                <SelectInputForm
                    style={{ margin: 0, marginRight: '10px' }}
                    placeholder="Selecione um estabelecimento"
                    data={establishments.establishment_list?.map(({ id, business_name }) => ({
                        name: business_name,
                        value: id,
                    }))}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedEstablishment(value)
                    }}
                    value={selectedEstablishment}
                />
            </RowTitle>

            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            {selectedEstablishment?.length ? (
                <div className="stone-form-container">
                    <div className="title" style={{ marginTop: '20px' }}>
                        Rótulos de resumo do ajuste de valor
                    </div>

                    <div className="inputs-row col-3">
                        <TextInputForm
                            label="Rótulo de valor original*"
                            onChange={handleInputChange('labels_of_amount_adjustment_resume.original_amount_label')}
                            value={values.labels_of_amount_adjustment_resume.original_amount_label}
                            msgErro={getFormInputError('labels_of_amount_adjustment_resume.original_amount_label')}
                        />
                        <TextInputForm
                            label="Rótulo de valor de ajuste*"
                            onChange={handleInputChange('labels_of_amount_adjustment_resume.adjustment_amount_label')}
                            value={values.labels_of_amount_adjustment_resume.adjustment_amount_label}
                            msgErro={getFormInputError('labels_of_amount_adjustment_resume.adjustment_amount_label')}
                        />
                        <TextInputForm
                            label="Rótulo de valor final*"
                            onChange={handleInputChange(
                                'labels_of_amount_adjustment_resume.adjusted_final_amount_label'
                            )}
                            value={values.labels_of_amount_adjustment_resume.adjusted_final_amount_label}
                            msgErro={getFormInputError(
                                'labels_of_amount_adjustment_resume.adjusted_final_amount_label'
                            )}
                        />
                    </div>

                    <div className="title" style={{ marginTop: '20px' }}>
                        Configurações de ajuste de valor
                    </div>

                    {values.configurations_of_amount_adjustment.map((configuration, index) => (
                        <div className="inputs-row col-3" key={index}>
                            <TextInputForm
                                label="Ajuste percentual"
                                onChange={handleInputChange(
                                    `configurations_of_amount_adjustment[${index}].adjustment_percent`
                                )}
                                value={values.configurations_of_amount_adjustment[index].adjustment_percent}
                                msgErro={getFormInputError(
                                    `configurations_of_amount_adjustment[${index}].adjustment_percent`
                                )}
                            />

                            <SelectInputForm
                                label="Tipo de ajuste"
                                data={[
                                    { name: 'Acréscimo', value: 1 },
                                    { name: 'Decréscimo', value: 2 },
                                ]}
                                onChange={handleSelectChange(
                                    `configurations_of_amount_adjustment[${index}].adjustment_type`
                                )}
                                value={values.configurations_of_amount_adjustment[index].adjustment_type}
                                msgErro={getFormInputError(
                                    `configurations_of_amount_adjustment[${index}].adjustment_type`
                                )}
                            />
                            <SelectInputForm
                                label="Tipo de pagamento"
                                data={[
                                    { name: 'Débito', value: 1 },
                                    { name: 'Crédito', value: 2 },
                                    { name: 'Ambos', value: 0 },
                                ]}
                                onChange={handleSelectChange(
                                    `configurations_of_amount_adjustment[${index}].apply_adjustment_for_payment_type`
                                )}
                                value={
                                    values.configurations_of_amount_adjustment[index].apply_adjustment_for_payment_type
                                }
                                msgErro={getFormInputError(
                                    `configurations_of_amount_adjustment[${index}].apply_adjustment_for_payment_type`
                                )}
                            />
                        </div>
                    ))}

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonForm secondary buttonText="Remover configuração" onClick={removeConfiguration} />
                        <ButtonForm
                            style={{ marginLeft: 10 }}
                            buttonText="Adicionar configuração"
                            onClick={addConfiguration}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonForm
                            type="submit"
                            style={{ marginLeft: 10 }}
                            buttonText="Salvar"
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            ) : (
                <EmptyMessage>Selecione um estabelecimento para edição</EmptyMessage>
            )}
        </div>
    )
}

export default TransactionAdjustmentConfiguration
