import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;

    * {
        box-sizing: border-box;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 1200px) {
        flex-direction: row;
    }
`
const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 25px;

    @media (min-width: 1200px) {
        width: 30%;
    }
`

const ContainerMap = styled.div`
    flex: 1;
`

const FormTitle = styled.p`
    text-align: justify;
`
const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`
const InputGroup = styled.div<{ direction?: 'row' | 'column' }>`
    display: flex;
    flex-direction: ${({ direction }) => (direction ? direction : 'auto')};
    box-sizing: border-box;
    justify-content: space-between;
    .input-item {
        width: 48%;
    }
`

const Label = styled.label`
    font-weight: 600;
    margin-bottom: 8px;
`

export { Container, Content, ContainerForm, ContainerMap, FormTitle, FormGroup, InputGroup, Label }
