import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

export const InputLabel = styled.label`
    margin-bottom: 5px;
    flex: 1;
    transition: 400ms;
`

export const StyledDateInput = styled(DatePicker)<ReactDatePickerProps>`
    border: 1px solid #bbb;
    border-radius: 4px;
    flex: 1;
    outline: none;
    background-color: #fff;
    padding-left: 5px;
    height: 28px;
    user-select: none;
    font-family: 'Arial';
`

export const DateInputContainer = styled.div`
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    outline: none;
    position: relative;
    user-select: none;
    &:focus-within {
        ${InputLabel} {
            transform: translateX(5px);
        }
        ${StyledDateInput} {
            border-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export const ErrorMessage = styled.div`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.errorMessage};
    position: absolute;
    bottom: -15px;
    left: 0;
`
