import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { getFormInputError } from 'helpers'
import api from 'services/api'
import { Mall, PagedList, Store } from 'types'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './manager-mercadoo-integration-modal.styled'

export type ManagerMercadooIntegrationModalRef = {
    show?(): void
    close?(): void
    setStore?(store: Store): void
}

type Props = {
    onUpdate?(storeId: number, body: any): void
    onRemove?(storeId: number): () => void
    onClose?(): void
}

type IForm = {
    mall_id?: number | string
    store_id?: number | string
    auto_integration: boolean
    auto_accept: boolean
    is_marketplace_start?: boolean
    marketplace_start_at?: string
    seller_id?: string
}

const ManagerMercadooIntegrationModal = memo(
    forwardRef<ManagerMercadooIntegrationModalRef, Props>(({ onClose, onUpdate, onRemove }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()
        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [store, setStore] = useState<Store>()

        const [malls, setMalls] = useState<Mall[]>()
        const [stores, setStores] = useState<Store[]>()

        const [loading, setLoading] = useState<boolean>()

        const isEdit = useMemo(() => {
            return !!store
        }, [store])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Gerenciar' : 'Criar'} integração`
        }, [isEdit])

        const {
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            getFieldProps,
            handleSubmit,
            resetForm,
            setValues,
        } = useFormik<IForm>({
            initialValues: {
                auto_integration: false,
                is_marketplace_start: false,
                auto_accept: false,
                marketplace_start_at: format(new Date(), 'YYYY-MM-DD HH:mm'),
                seller_id: '',
            },
            validationSchema: Yup.object().shape({
                store_id: Yup.string().required(),
            }),
            onSubmit: async values => {
                const body = {
                    marketplace_enable: values.is_marketplace_start,
                    seller_id: values?.seller_id ? values.seller_id : null,
                    auto_integration: values.auto_integration,
                    auto_accept: values.auto_accept,
                    marketplace_start_at: values.is_marketplace_start
                        ? format(new Date(values.marketplace_start_at), 'YYYY-MM-DD HH:mm')
                        : null,
                }

                try {
                    await onUpdate(Number(values.store_id), body)

                    setSuccessModal({
                        title: 'Sucesso!',
                        subtitle: `Integração ${isEdit ? 'atualizada' : 'registrada'} com sucesso!`,
                        singleButtonClick: _close,
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: `Não foi possivel ${isEdit ? 'atualizar' : 'criar'} a integração`,
                    })
                }
            },
        })

        useImperativeHandle(ref, () => ({
            show: () => {
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                setStore(null)
                setStores(null)
                resetForm()
                lateralModalBaseRef.current?.close()
            },
            setStore: (store: Store) => {
                setStore(store)
            },
        }))

        const _close = useCallback(() => {
            if (onClose) {
                onClose()
            }

            lateralModalBaseRef.current?.close()
        }, [onClose])

        const _getMalls = useCallback(async () => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<Mall>>('/painel/malls', {
                    params: { order_by: 'name', status: [1], per_page: 100 },
                })
                setMalls(data.items)
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        }, [])

        const _getStores = useCallback(
            async (mall_id: number | string) => {
                if (mall_id) {
                    setLoading(true)
                    try {
                        const { data } = await api.get<PagedList<Store>>('/painel/stores', {
                            params: { mall_id, marketplace_enable: 1 },
                        })
                        setStores(data.items)
                    } catch (error) {
                        console.log('error', error)
                    }
                    setLoading(false)
                } else {
                    setStores(null)
                }
            },
            [isEdit]
        )

        const _handleCheck = useCallback(
            (fieldName: string) => () => {
                if (fieldName === 'is_marketplace_start' && values[fieldName]) {
                    setFieldValue('auto_accept', false)
                    setFieldValue('auto_integration', false)
                }

                if (fieldName === 'auto_accept') {
                    setFieldValue('auto_integration', !values[fieldName])
                }

                setFieldValue(fieldName, !values[fieldName])
            },
            [values, setFieldValue]
        )

        const _handleChangeMall = useCallback(
            ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setStores(null)
                _getStores(value)
                setFieldValue('mall_id', value)
            },
            [_getStores, setFieldValue]
        )

        useEffect(() => {
            _getMalls()
        }, [_getMalls])

        useEffect(() => {
            if (store) {
                if (store?.mall?.id) {
                    _getStores(store.mall.id)

                    setValues({
                        auto_integration: store?.configs.auto_integration,
                        auto_accept: store?.configs.auto_accept,
                        mall_id: store.mall.id,
                        store_id: store?.id,
                        is_marketplace_start: !!store?.marketplace_start_at,
                        marketplace_start_at: format(new Date(store.marketplace_start_at), 'YYYY-MM-DD HH:mm'),
                        seller_id: store?.seller_id ?? '',
                    })
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={loading || isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Mall"
                                    type="select"
                                    options={malls?.map(mall => ({
                                        label: mall.name,
                                        value: mall.id,
                                    }))}
                                    inputProps={{
                                        disabled: isEdit,
                                        value: values?.mall_id || '',
                                        onChange: _handleChangeMall,
                                    }}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Loja"
                                    type="select"
                                    name="id-store"
                                    options={stores?.map(store => ({
                                        label: store.name,
                                        value: store.id,
                                    }))}
                                    inputProps={{
                                        placeholder: 'Selecione uma loja',
                                        disabled: isEdit || !stores,
                                        ...getFieldProps('store_id'),
                                    }}
                                    errorMessage={getFormInputError('store_id', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Zoop ID"
                                    name="seller"
                                    inputProps={{
                                        ...getFieldProps('seller_id'),
                                        value: values.seller_id,
                                        placeholder: 'Informe o identificador zoop referente a loja',
                                    }}
                                    errorMessage={getFormInputError('seller_id', errors, touched)}
                                />
                            </InputContainer>

                            <InputContainer row hasMarginBottom>
                                <SwitchButton
                                    isFlex
                                    isActive={values?.is_marketplace_start}
                                    activeLabel={''}
                                    inactiveLabel={''}
                                    onClick={_handleCheck('is_marketplace_start')}
                                />
                                Exibir no Marketpalce
                            </InputContainer>
                            {!!values?.is_marketplace_start && (
                                <>
                                    <InputContainer row hasMarginBottom>
                                        <SwitchButton
                                            isFlex
                                            isActive={values?.auto_accept}
                                            activeLabel={''}
                                            inactiveLabel={''}
                                            onClick={_handleCheck('auto_accept')}
                                        />
                                        Aceite Automático
                                    </InputContainer>
                                    <InputContainer row hasMarginBottom>
                                        <SwitchButton
                                            isFlex
                                            isActive={Boolean(values?.auto_integration)}
                                            activeLabel={''}
                                            inactiveLabel={''}
                                            onClick={_handleCheck('auto_integration')}
                                        />
                                        Criação Automática de Pedido no Aceite
                                    </InputContainer>
                                </>
                            )}
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <div>
                            {isEdit && (
                                <TextButton isRemove onClick={onRemove(store.id)}>
                                    <FontAwesomeIcon icon="trash-alt" /> <span>Excluir</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { ManagerMercadooIntegrationModal }
