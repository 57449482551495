import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

interface Props {
    id?: string
    checked?: boolean
    onChange?(e: React.ChangeEvent<HTMLInputElement>): void
    label?: string
}

const CheckboxInput: React.FC<Props> = ({ id, checked, onChange, label }) => {
    return (
        <div className="checkbox-input">
            <label htmlFor={id}>
                <input id={id} type="checkbox" onChange={onChange} checked={checked} />
                <div onChange={onChange} className="check-container">
                    {checked ? <FontAwesomeIcon icon="check" /> : null}
                </div>
                <div className="label">{label}</div>
            </label>
        </div>
    )
}

export default CheckboxInput
