import { faBiking, faMotorcycle, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Contaienr = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 20px;
    display: flex;
    align-items: flex-start;
`

const ContainerAvatar = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    margin-right: 20px;
    position: relative;
`

const ContainerBadge = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Avatar = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`

const BadgeIcon = styled(FontAwesomeIcon).attrs({
    icon: faMotorcycle,
})`
    color: #fff;
    font-size: 16px;
`

const BikeIcon = styled(FontAwesomeIcon).attrs({
    icon: faBiking,
})`
    color: #fff;
    font-size: 14px;
`

const ContainerInfo = styled.div`
    flex: 1;
`

const RiderName = styled.div`
    font-size: 14px;
`

const RiderPhone = styled.div`
    font-weight: bold;
    font-size: 12px;
    color: #999;
`

const ContainerOrders = styled.div`
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    display: block;
`

const OrderItem = styled.div`
    border-radius: 20px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 5px;
    position: relative;
    color: ${({ theme }) => theme.colors.primary};
    transition: 400ms;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .merchant {
        text-transform: none;
        color: ${({ theme }) => theme.colors.mediumGray};
        font-weight: bold;
        font-size: 12px;
    }
    .status {
        text-transform: none;
        color: ${({ theme }) => theme.colors.darkGray};
        font-size: 12px;
        font-weight: normal;
    }
`

const ContainerButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
`

const DetailsButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    padding: 3px 10px;
    border-radius: 12px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        opacity: 0.7;
    }
`

const RejectButton = styled.div<{ orange?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    text-align: center;
    color: ${({ theme, orange }) => (orange ? theme.colors.darkOrange : theme.colors.darkGray)};
    font-weight: normal;
    padding: 0;
    font-size: 12px;
    margin: 10px 0px;
    &:hover {
        text-decoration: underline;
    }
    span {
        margin-right: 4px;
    }
`

const DetailIcon = styled(FontAwesomeIcon).attrs({
    icon: faFileUpload,
})``

export {
    BikeIcon,
    BadgeIcon,
    Contaienr,
    ContainerAvatar,
    ContainerBadge,
    Avatar,
    OrderItem,
    ContainerInfo,
    RejectButton,
    DetailIcon,
    RiderName,
    RiderPhone,
    ContainerButtons,
    ContainerOrders,
    DetailsButton,
}
