import { Component } from 'react'

import ResultItem from './result-item/result-item'
import './style.scss'

export default class SearchResultListing extends Component {
    render() {
        let data = this.props.data || []

        return (
            <div className="result-listing-container">
                {data.map((item, index) => (
                    <ResultItem
                        key={index}
                        orderNumber={item.reference_id}
                        orderValue={item.total_price}
                        restaurantName={item.merchant.name}
                        onClick={() => this.props.onClick(item.id)}
                    />
                ))}
            </div>
        )
    }
}
