import { forwardRef, useRef, useImperativeHandle, useMemo, useState, useEffect, useCallback } from 'react'

import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import OrderSummary from 'components/_store-general-vision/order-summary'
import EmptyMessage from 'components/empty-message/empty-message'
import { ItemOrderCard } from 'components/items'
import ModalLoading from 'components/modal-loading'
import SelectInput from 'components/select-input'

import { useUI } from 'contexts'
import { getSortedItems, getFiltersByTimeOptions, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import { useOrdersInProgress } from 'hooks/orders'
import api from 'services/api'
import { Store, Order } from 'types'

import { ContainerCardListing, ContainerFilter, RefreshIcon, ContainerRefresh, TextRefresh } from './oders-modal.styled'

type AddRoutesProps = {
    position: number
    routeId: number
}
type OrdersModal = {
    show?(addPosition: AddRoutesProps): void
    close?(): void
}
interface OrdersModalProps {
    name?: string
    ordersPositions: number[]
    onUpdateRoute?(route: any): void
}
// eslint-disable-next-line react/display-name
const OrdersModal = forwardRef<OrdersModal, OrdersModalProps>(({ ordersPositions, onUpdateRoute }, ref) => {
    const { mall } = useAuth()
    const { setConfirmationModal, setErrorModal } = useUI()
    const [addPosition, setAddPosition] = useState(null)
    const [routeId, setRouteId] = useState(null)
    const lateralModalBaseRef = useRef<LateralModalBase | null>(null)
    const [stores, setStores] = useState<Store[]>([])

    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({
        store_id: '',
        order_by: '1',
    })

    const { orders: data, revalidateOrders } = useOrdersInProgress()

    // const {
    //     revalidate,
    //     data: orders,
    //     loading,
    // } = useDataFetching<PagedList<Order>>('/painel/dm-orders', {
    //     revalidateOnFocus: false,
    //     revalidateOnMount: false,
    //     params: {
    //         mall_id: mall.id,
    //         per_page: -1,
    //         has_route: 0,
    //         status: [4],
    //     },
    // })

    const orders = useMemo(() => {
        const isOrderFromStore = (order: Order) =>
            filter.store_id && !isNaN(Number(filter?.store_id) as number)
                ? order.merchant.id.toString() === filter.store_id
                : true

        const items = getSortedItems(data.toCollect.items, filter.order_by)

        return items.filter(isOrderFromStore)
    }, [data, filter])

    useImperativeHandle(
        ref,
        () => ({
            show: ({ position, routeId }) => {
                setAddPosition(position)
                setRouteId(routeId)
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
        }),
        [lateralModalBaseRef]
    )

    const _setFilterParam = useCallback(
        (key: string, value: string) => {
            setFilter({ ...filter, [key]: value })
        },
        [filter]
    )

    const _getStores = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/stores-to-select', {
                params: {
                    mall_id: mall.id,
                    order_by: 'name',
                },
            })

            setStores(data.items)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    const _addOrder = useCallback(
        async orderId => {
            if (routeId && addPosition >= 0) {
                const positions = [...ordersPositions]

                positions.splice(addPosition, 0, orderId)

                const body = {
                    route_id: routeId,
                    order_id: orderId,
                    positions: positions,
                }

                try {
                    setLoading(true)
                    const { data } = await api.post('/painel/route/add-order', body)
                    if (onUpdateRoute) {
                        onUpdateRoute(data)
                    }
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao estornar',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setLoading(false)
                }
            }
        },
        [addPosition, ordersPositions, routeId, onUpdateRoute]
    )

    const _onAddOrderClick = useCallback(
        (order: Order) => {
            setConfirmationModal({
                title: 'Adicionar Pedido',
                subtitle: 'Deseja adicionar este pedido na rota?',
                type: 'alert',
                modalIcon: 'check-circle',
                leftButtonText: 'Fechar',
                rightButtonText: 'Sim',
                rightButtonClick: async () => {
                    await _addOrder(order.id)
                    lateralModalBaseRef.current?.close()
                },
                children: <OrderSummary order={order} />,
            })
        },
        [setConfirmationModal, lateralModalBaseRef, _addOrder]
    )

    const _refresh = useCallback(async () => {
        setLoading(true)
        await revalidateOrders()
        setLoading(false)
    }, [revalidateOrders])

    const _onClose = useCallback(async () => {
        setAddPosition(null)
        setRouteId(null)
    }, [revalidateOrders])

    useEffect(() => {
        _getStores()
    }, [_getStores])

    return (
        <LateralModalBase ref={lateralModalBaseRef} title="Adicionar pedido" onClose={_onClose}>
            <ContainerFilter>
                <SelectInput
                    placeholder="Todas as lojas"
                    onChange={({ target: { value } }) => _setFilterParam('store_id', value)}
                    value={filter.store_id}
                    bgColor="transparent"
                    borderColor="transparent"
                    data={stores}
                />
                <SelectInput
                    onChange={({ target: { value } }) => _setFilterParam('order_by', value)}
                    value={filter.order_by}
                    bgColor="transparent"
                    borderColor="transparent"
                    data={getFiltersByTimeOptions()}
                />
                <ContainerRefresh onClick={_refresh}>
                    <RefreshIcon />
                    <TextRefresh>Atualizar</TextRefresh>
                </ContainerRefresh>
            </ContainerFilter>

            {!!orders && (
                <ContainerCardListing>
                    {!orders.length ? (
                        <div className="empty-container">
                            <EmptyMessage icon="shopping-bag">Não há pedidos disponíveis no momento</EmptyMessage>
                        </div>
                    ) : (
                        orders.map((item, index) => (
                            <ItemOrderCard key={index} onAddOrderClick={_onAddOrderClick} order={item} />
                        ))
                    )}
                </ContainerCardListing>
            )}
            <ModalLoading visible={loading} />
        </LateralModalBase>
    )
})

export default OrdersModal
