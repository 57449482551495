/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useRef, useImperativeHandle, useState, useCallback, useEffect, useMemo, memo } from 'react'
import { useLocation } from 'react-router-dom'

import { useFormik } from 'formik'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import * as Yup from 'yup'

import { Accordion } from 'components/_common'
import CheckboxInput from 'components/checkbox-input'
import MensagemErro from 'components/mensagem-erro'
import ModalLoading from 'components/modal-loading'
import PasswordInputForm from 'components/password-input-form'
import { Scroll } from 'components/scroll/scroll'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'

import { useUI } from 'contexts'
import { getRoleId, getRoles, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Order } from 'types'
import { IStaff } from 'types/staff'

import {
    Container,
    InputRow,
    StoresCheckboxContainer,
    StoreLabel,
    StoresCheckboxGroup,
    Row,
    ContainerAccordion,
    TextButton,
    ConfirmButton,
} from './manager-staff-modal.styled'

type Params = {
    staff?: IStaff
    onChange(type: 'add' | 'update', staff: IStaff)
}

type ManagerStaffModalRef = {
    show?(params: Params): void
    close?(): void
}
interface ManagerStaffModalProps {
    onEditClick?(order: Order): void
}
// eslint-disable-next-line react/display-name
const ManagerStaffModal = memo(
    forwardRef<ManagerStaffModalRef, ManagerStaffModalProps>((props, ref) => {
        const { mall, user } = useAuth()
        const { pathname } = useLocation()

        const [stores, setStores] = useState([])

        const [loading, setLoading] = useState(false)
        const [visible, setVisible] = useState(false)

        const [staff, setStaff] = useState<IStaff>()
        const [callback, setCallback] = useState<{ exec(type: 'add' | 'update', staff: IStaff) }>({ exec: () => {} })

        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

        const { setErrorModal, setSuccessModal } = useUI()

        const isCreation = useMemo(() => {
            return !staff
        }, [staff])

        const { errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } = useFormik({
            initialValues: {
                mall_id: mall?.id,
                stores: [],
                collect_origins: [],
                role: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirmation: '',
                status: '1',
            },
            validationSchema: Yup.object().shape({
                role: Yup.string().trim().required('Selecione uma Função'),
                stores: Yup.array().when('role', {
                    is: '4',
                    then: Yup.array(),
                    otherwise: Yup.array().min(1, 'Selecione pelo menos uma loja'),
                }),
                first_name: Yup.string().trim().required('Insira o Nome'),
                last_name: Yup.string().trim(),
                email: Yup.string().trim().email('Deve ser um email válido').required('Insira o Email'),
                password:
                    isCreation &&
                    Yup.string().min(8, 'Deve ter no mínimo 8 caracteres').trim().required('Insira a Senha'),
                password_confirmation:
                    isCreation &&
                    Yup.string()
                        .trim()
                        .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
                        .required('Confirme a Senha'),
                status: Yup.string().required('Selecione o Status'),
            }),
            onSubmit: async values => {
                try {
                    setLoading(true)
                    const form = Object.keys(values).reduce((acc, key) => {
                        if (values[key]) {
                            acc[key] = values[key]
                        }

                        return acc
                    }, {})

                    if (isCreation) {
                        const { data } = await api.post<IStaff>(`painel/staff`, form)
                        callback.exec('add', data)
                    } else {
                        const { data } = await api.put<IStaff>(`painel/staff/${staff.id}`, form)
                        callback.exec('update', data)
                    }

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: isCreation ? 'Lojista criado com sucesso!' : 'Lojista atualizado com sucesso!',
                    })
                    lateralModalBaseRef.current?.close()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                }
                setLoading(false)
            },
        })

        const _show = useCallback(({ staff, onChange }: Params) => {
            setVisible(true)
            setStaff(staff)
            setCallback({ exec: onChange })
            lateralModalBaseRef.current?.show()
        }, [])

        const _close = useCallback(() => {
            setVisible(false)
            setStaff(null)
            setLoading(false)
            setStores([])
            resetForm()

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useImperativeHandle(
            ref,
            () => ({
                show: _show,
            }),
            [_show]
        )

        const onChangeStores = (store_id, checked) => {
            if (checked) {
                setFieldValue('stores', [...values.stores, store_id])
                setFieldValue('collect_origins', [...values.collect_origins, store_id])
            } else {
                setFieldValue(
                    'stores',
                    values.stores.filter(id => id !== store_id)
                )
                setFieldValue(
                    'collect_origins',
                    values.collect_origins.filter(id => id !== store_id)
                )
            }
        }
        const onChangeOriginStores = (store_id, checked) => {
            if (checked) {
                setFieldValue('collect_origins', [...values.collect_origins, store_id])
            } else {
                if (!values.stores.includes(store_id)) {
                    setFieldValue(
                        'collect_origins',
                        values.collect_origins.filter(id => id !== store_id)
                    )
                }
            }
        }

        const _getAllStores = useCallback(async () => {
            try {
                setLoading(true)

                const {
                    data: { items },
                } = await api.get('/painel/stores', {
                    params: {
                        order_by: 'name',
                        mall_id: mall.id,
                    },
                })
                setStores(items)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }, [mall])

        useEffect(() => {
            if (visible && !isCreation) {
                const storeIds = staff.stores?.map(item => item.id)
                const originsIds = staff.configs?.collect_origins
                const [role] = staff.role

                setValues({
                    ...values,
                    first_name: staff.first_name,
                    last_name: staff.last_name,
                    email: staff.email,
                    status: staff.status.toString(),
                    role: getRoleId(role),
                    stores: storeIds,
                    collect_origins: originsIds || [],
                })
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isCreation, setValues, staff])

        useEffect(() => {
            if (visible) {
                _getAllStores()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [visible])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                onClose={_close}
                title={
                    staff?.id && staff.id === user.id
                        ? 'Editar meus dados'
                        : staff?.id && staff.id !== user.id
                        ? 'Editar lojista'
                        : 'Criar novo lojista'
                }
                footerComponent={
                    <Row>
                        <TextButton onClick={() => lateralModalBaseRef.current?.close()}>Cancelar</TextButton>
                        <ConfirmButton onClick={() => handleSubmit()}>{isCreation ? 'Criar' : 'Salvar'}</ConfirmButton>
                    </Row>
                }
            >
                <Scroll>
                    <Container>
                        <SelectInputForm
                            label="Função"
                            data={getRoles(pathname.includes('painel-malls'))}
                            value={values.role}
                            onChange={e => {
                                if (e.target.value === '4') {
                                    setFieldValue('stores', [])
                                }
                                setFieldValue('role', e.target.value)
                            }}
                            msgErro={touched.role && errors.role}
                        />
                        {values.role !== '4' && (
                            <StoresCheckboxContainer>
                                <StoreLabel>Lojas</StoreLabel>
                                <StoresCheckboxGroup>
                                    {stores.map(store => (
                                        <CheckboxInput
                                            key={store.id}
                                            id={`store-${store.id}`}
                                            label={store.name}
                                            onChange={({ target: { checked } }) => onChangeStores(store.id, checked)}
                                            checked={values.stores.some(id => id === store.id)}
                                        />
                                    ))}
                                </StoresCheckboxGroup>
                                <MensagemErro msgErro={touched.stores && errors.stores} />
                            </StoresCheckboxContainer>
                        )}
                        {stores.length > 0 && (
                            <ContainerAccordion>
                                <Accordion hideIcon accordionTitle="Lojas de origem">
                                    <StoreLabel>Selecione as lojas de origem</StoreLabel>
                                    <StoresCheckboxGroup>
                                        {stores.map((store, i) => (
                                            <CheckboxInput
                                                key={i}
                                                id={`origin-${store.id}`}
                                                label={store.name}
                                                onChange={({ target: { checked } }) =>
                                                    onChangeOriginStores(store.id, checked)
                                                }
                                                checked={values.collect_origins.some(id => id === store.id)}
                                            />
                                        ))}
                                    </StoresCheckboxGroup>
                                </Accordion>
                            </ContainerAccordion>
                        )}
                        <InputRow>
                            <TextInputForm
                                label="Nome"
                                value={values.first_name}
                                onChange={e => setFieldValue('first_name', e.target.value)}
                                msgErro={touched.first_name && errors.first_name}
                            />
                            <TextInputForm
                                label="Sobrenome"
                                value={values.last_name}
                                onChange={e => setFieldValue('last_name', e.target.value)}
                                msgErro={touched.last_name && errors.last_name}
                            />
                        </InputRow>

                        <TextInputForm
                            label="E-mail"
                            value={values.email}
                            onChange={e => setFieldValue('email', e.target.value)}
                            msgErro={touched.email && errors.email}
                        />

                        {isCreation ? (
                            <InputRow>
                                <PasswordInputForm
                                    label="Senha"
                                    value={values.password}
                                    onChange={e => setFieldValue('password', e.target.value)}
                                    msgErro={touched.password && errors.password}
                                />
                                <PasswordInputForm
                                    label="Confirmar senha"
                                    value={values.password_confirmation}
                                    onChange={e => setFieldValue('password_confirmation', e.target.value)}
                                    msgErro={touched.password_confirmation && errors.password_confirmation}
                                />
                            </InputRow>
                        ) : null}

                        <SelectInputForm
                            label="Status"
                            data={[
                                { name: 'Ativo', value: '1' },
                                { name: 'Inativo', value: '0' },
                            ]}
                            value={values.status}
                            onChange={e => setFieldValue('status', e.target.value)}
                            msgErro={touched.status && errors.status}
                        />
                    </Container>
                </Scroll>
                <ModalLoading visible={loading} />
            </LateralModalBase>
        )
    })
)
export { ManagerStaffModal }
