import React, { memo, useMemo, useCallback } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DefaultButton } from 'components/default-button/default-button'

import { formatCurrency } from 'helpers'
import { RateChain, RateValues } from 'types'

import {
    TextGroup,
    Icon,
    RateDelivery,
    RateId,
    RateLabel,
    Row,
    TableActions,
    CurrencyText,
    Text,
    RateStatus,
} from './chain-rates-table-row-item.styled'

interface IRowItem {
    rate: RateChain
    handleDelete(id: number): void
    handleEdit(id: number): void
}

const ChainRateTableRowItem: React.FC<IRowItem> = memo(({ rate, handleDelete, handleEdit }) => {
    const { agent, store } = (rate?.value || {}) as RateValues

    const formattedPaymentAreas = useMemo(() => {
        if (rate?.payment_areas?.length === 0) {
            return 'Sem área de combrança'
        }
        return rate?.payment_areas?.map(area => area.label).join(', ')
    }, [rate])

    const _hasRateValues = useCallback(
        (key: string) => {
            return store?.[key] && agent?.[key]
        },
        [agent, store]
    )

    const _renderNotHasRateValues = useCallback((modality?: string, icon?: IconProp) => {
        return (
            <TextGroup>
                <Icon>
                    <FontAwesomeIcon icon={icon ?? 'store'} color="black" />
                </Icon>
                <Text>Não habilitado para {modality}</Text>
            </TextGroup>
        )
    }, [])

    return (
        <Row>
            <RateId>{rate.id}</RateId>
            <RateLabel>{formattedPaymentAreas}</RateLabel>
            <RateDelivery>
                {_hasRateValues('bike') ? (
                    <>
                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="store" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(store?.bike)}</CurrencyText>
                        </TextGroup>

                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="bicycle" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(agent?.bike)}</CurrencyText>
                        </TextGroup>
                    </>
                ) : (
                    _renderNotHasRateValues('bicicleta', 'bicycle')
                )}
            </RateDelivery>
            <RateDelivery>
                {_hasRateValues('moto') ? (
                    <>
                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="store" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(store?.moto)}</CurrencyText>
                        </TextGroup>

                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="motorcycle" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(agent?.moto)}</CurrencyText>
                        </TextGroup>
                    </>
                ) : (
                    _renderNotHasRateValues('motocicleta', 'motorcycle')
                )}
            </RateDelivery>
            <RateDelivery>
                {_hasRateValues('car') ? (
                    <>
                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="store" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(store?.car)}</CurrencyText>
                        </TextGroup>

                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="car" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(agent?.car)}</CurrencyText>
                        </TextGroup>
                    </>
                ) : (
                    _renderNotHasRateValues('carro', 'car')
                )}
            </RateDelivery>
            <RateDelivery>
                {_hasRateValues('van') ? (
                    <>
                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="store" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(store?.van)}</CurrencyText>
                        </TextGroup>

                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="shuttle-van" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(agent?.van)}</CurrencyText>
                        </TextGroup>
                    </>
                ) : (
                    _renderNotHasRateValues('van', 'shuttle-van')
                )}
            </RateDelivery>
            <RateDelivery>
                {_hasRateValues('truck') ? (
                    <>
                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="store" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(store?.truck)}</CurrencyText>
                        </TextGroup>

                        <TextGroup>
                            <Icon>
                                <FontAwesomeIcon icon="truck" color="black" />
                            </Icon>
                            <CurrencyText>{formatCurrency(agent?.truck)}</CurrencyText>
                        </TextGroup>
                    </>
                ) : (
                    _renderNotHasRateValues('caminhão', 'truck')
                )}
            </RateDelivery>
            <RateStatus>{rate?.status ? 'Ativo' : 'Inativo'}</RateStatus>
            <TableActions>
                <DefaultButton outline={true} onClick={() => handleDelete(rate.id)}>
                    Remover
                </DefaultButton>
                <DefaultButton onClick={() => handleEdit(rate.id)}>Editar</DefaultButton>
            </TableActions>
        </Row>
    )
})

export { ChainRateTableRowItem }
