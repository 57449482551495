import { forwardRef, useRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import AvailableBikerCard from 'components/_bikers/available-biker-card/available-biker-card'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalLoading from 'components/modal-loading'

import RootState, { AgentsState } from 'store/reducers/types'
import { Biker, IAgent } from 'types'

import { ContainerCardListing } from './available-deliverymen-modal.styled'

type AvailableDeliverymenModal = {
    show?(): void
    close?(): void
}
interface AvailableDeliverymenModalProps {
    onRemoveClick?(item: IAgent): void
    loading?: boolean
}
// eslint-disable-next-line react/display-name
const AvailableDeliverymenModal = forwardRef<LateralModalBase, AvailableDeliverymenModalProps>(
    ({ onRemoveClick, loading }, ref) => {
        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

        const agentsState = useSelector<RootState, AgentsState>(({ agents }) => agents)

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [lateralModalBaseRef]
        )

        return (
            <LateralModalBase ref={lateralModalBaseRef} title="Entregadores disponíveis">
                {!!agentsState.agents && (
                    <ContainerCardListing>
                        {!agentsState.agents.items.length ? (
                            <div className="empty-container">
                                <EmptyMessage icon="biking">Nao há entregadores disponíveis</EmptyMessage>
                            </div>
                        ) : (
                            agentsState.agents.items.map((item, i) => (
                                <AvailableBikerCard
                                    key={i}
                                    biker={item}
                                    onRemove={() => {
                                        if (onRemoveClick) {
                                            onRemoveClick(item)
                                        }
                                    }}
                                />
                            ))
                        )}
                    </ContainerCardListing>
                )}
                <ModalLoading visible={loading} />
            </LateralModalBase>
        )
    }
)

export default AvailableDeliverymenModal
