import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import chevron from 'assets/images/chevron-down.png'
export type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>
export type HTMLTextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement>
export type HTMLSelectProps = React.InputHTMLAttributes<HTMLSelectElement>

export const InputLabel = styled.label`
    margin-bottom: 5px;
    flex: 1;
    transition: 400ms;
`

export const TextInput = styled.input<HTMLInputProps>`
    border: 1px solid #ababab;
    font-size: 14px;
    border-radius: 8px;
    flex: 1;
    padding: 10px;
    outline: none;
    background-color: #fff;
    padding-left: 5px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
`

export const ColorInput = styled.input<HTMLInputProps>`
    width: 100%;
    border: 1px solid #ababab;
    border-radius: 0.5rem;
    flex: 1;
    outline: none;
    background-color: #fff;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};

    &::-webkit-color-swatch-wrapper {
        height: 2.125rem;
    }
    &::-webkit-color-swatch {
        border-radius: 0.5rem;
        margin: 0;
        border-color: transparent;
    }
`

export const TimeInput = styled.input<HTMLInputProps>`
    border: 1px solid #ababab;
    font-size: 14px;
    border-radius: 8px;
    flex: 1;
    padding: 10px;
    outline: none;
    background-color: #fff;
    padding-left: 5px;

    user-select: none;
    font-family: 'Arial';
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
`

export const DateInput = styled.input<HTMLInputProps>`
    border: 1px solid #ababab;
    font-size: 14px;
    border-radius: 8px;
    flex: 1;
    padding: 10px;
    outline: none;
    background-color: #fff;
    padding-left: 5px;

    user-select: none;
    font-family: 'Arial';
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
`

export const SelectInput = styled.select<HTMLSelectProps>`
    border: 1px solid #ababab;
    font-size: 14px;
    border-radius: 8px;
    flex: 1;
    padding: 10px 25px 10px 10px;
    outline: none;

    background-color: #fff;
    appearance: none;
    background-image: url(${chevron});
    background-position: calc(100% - 8px);
    background-size: 12px;
    background-repeat: no-repeat;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
`

export const TextareaInput = styled.textarea<HTMLTextAreaProps & { big?: boolean }>`
    border: 1px solid #ababab;
    font-size: 14px;
    border-radius: 8px;
    flex: 1;
    padding: 10px;
    outline: none;
    min-height: ${({ big }) => (big ? '80px' : '40px')};
    resize: none;
    font-family: 'Arial';
    background-color: #fff;
    padding: 10px;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
`

export const InputItemContainer = styled.div`
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    outline: none;
    position: relative;
    user-select: none;
    &:focus-within {
        ${InputLabel} {
            transform: translateX(5px);
        }
        ${TextInput}, ${SelectInput}, ${TextareaInput} , ${TimeInput}, ${DateInput} {
            border-color: ${({ theme }) => theme.colors.primary};
        }
    }

    .css-1t8y0t9-control {
        min-height: 35px !important;
    }
    .css-g1d714-ValueContainer {
        min-height: 35px !important;
        padding-top: 0;
        padding-bottom: 0;
    }
    .css-1vp9nu5-control {
        min-height: 35px !important;
    }
    .css-8ext48-Input {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: auto;
        min-height: auto;
    }
`

export const EyeIcon = styled(FontAwesomeIcon).attrs({
    icon: faEye,
})`
    color: ${({ theme }) => theme.colors.black};
    position: absolute;
    bottom: 9px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        transform: scale(1.2);
    }
`

export const ErrorMessage = styled.div`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.errorMessage};
    position: absolute;
    bottom: -15px;
    left: 0;
`
