import Spinner from 'react-spinner-material'

import colors from 'themes/colors'
import './style.scss'

interface Props {
    onClick(): void
    textColor?: string
    bgColor?: string
    borderColor?: string
    width?: string | number
    loading?: boolean
    buttonText: string
    style?: React.CSSProperties
}

const Button: React.FC<Props> = ({ onClick, textColor, bgColor, borderColor, width, loading, buttonText, style }) => {
    return (
        <button
            onClick={onClick}
            className="default-button"
            style={{
                color: textColor ? textColor : colors.dark_primary_color,
                backgroundColor: bgColor ? bgColor : colors.secondary_color,
                borderColor: borderColor ? borderColor : colors.primary_color,
                width: width || '250px',
                ...style,
            }}
        >
            {loading ? (
                <div className="button-spinner">
                    <Spinner size={16.5} color={colors.primary_color} stroke={2} radius={20} visible={true} />
                </div>
            ) : (
                buttonText
            )}
        </button>
    )
}

export default Button
