import {
    CardContainer,
    CardSubtitle,
    CardTitle,
    CurrentStatus,
    HistoryTabContainer,
    StatusContainer,
    StatusItem,
    TimeContent,
    TimeItem,
    TimesContainer,
    TimeTitle,
} from './history-tab.styles'

const HistoryTab: React.FC = () => {
    return (
        <HistoryTabContainer>
            <CardContainer>
                <CardTitle>PEDIDO ENTREGUE ÀS 11:36</CardTitle>
                <CardSubtitle>Pedido realizado às 11:30 do dia 12/03/2021</CardSubtitle>
            </CardContainer>
            <TimesContainer>
                <TimeItem>
                    <TimeTitle>Tempo para coleta</TimeTitle>
                    <TimeContent>00:00:00</TimeContent>
                </TimeItem>
                <TimeItem>
                    <TimeTitle>Tempo de entrega</TimeTitle>
                    <TimeContent>00:00:00</TimeContent>
                </TimeItem>
                <TimeItem>
                    <TimeTitle>Total</TimeTitle>
                    <TimeContent>00:00:00</TimeContent>
                </TimeItem>
            </TimesContainer>
            <StatusContainer>
                <CurrentStatus>00:00 Pedido entregue no dispatch</CurrentStatus>
                <StatusItem>00:00 Pedido entregue no dispatch</StatusItem>
                <StatusItem>
                    00:00 Coletado por <span>Pintinho Rodrigues Delvale</span>
                </StatusItem>
                <StatusItem>00:00 Disponível para coleta</StatusItem>
                <StatusItem>
                    00:00 Criado por <span>Noélia Admin</span>
                </StatusItem>
            </StatusContainer>
        </HistoryTabContainer>
    )
}

export default HistoryTab
