import styled from 'styled-components'

import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: 0.75rem;
    padding: 1rem 0.75rem;

    background-color: ${({ theme }) => theme.colors.gray};
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    overflow: hidden;
    border-radius: 0.5rem;
`

const PeriodContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;
    //justify-content: center;
`

const PeriodItem = styled.div`
    width: 100%;
    text-align: center;
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
`

const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    color: ${({ theme }) => theme.colors.white};
`

export { Container, Content, ButtonRow, DeleteIcon, PeriodContainer, PeriodItem }
