import {
    ChannelLogo,
    OrderItemContainer,
    ifood,
    rappi,
    uberEats,
    undefinedStore,
    CustomerInfo,
    CustomerID,
    CustomerName,
    TimesContainer,
    ClockIcon,
    GreenClockIcon,
    TimeItem,
    TimeText,
    LogosContainer,
    StoreLogo,
    PickerPhoto,
} from './order-item.styles'

interface Props {
    customerID: number
    customerName: string
    channel: number
    productionTime: number
    totalTime: number
    confirmClick(): void
    cardClick(): void
    storeLogo: string
    pickerPhoto?: string
}

const OrderItem: React.FC<Props> = ({
    customerID,
    customerName,
    channel,
    productionTime,
    totalTime,
    cardClick,
    storeLogo,
    pickerPhoto,
}) => {
    const handleClick = (e: React.MouseEvent, callback: () => void) => {
        e.stopPropagation()
        callback()
    }

    return (
        <OrderItemContainer onClick={(e: React.MouseEvent) => handleClick(e, cardClick)}>
            <LogosContainer>
                {pickerPhoto && <PickerPhoto src={pickerPhoto} />}
                <StoreLogo src={storeLogo} />
                <ChannelLogo
                    src={channel === 1 ? ifood : channel === 2 ? rappi : channel === 3 ? uberEats : undefinedStore}
                />
            </LogosContainer>
            <CustomerInfo>
                <CustomerID>#{customerID}</CustomerID>
                <CustomerName>{customerName}</CustomerName>
            </CustomerInfo>
            <TimesContainer>
                {productionTime && (
                    <TimeItem>
                        <ClockIcon />
                        <TimeText>{productionTime} min</TimeText>
                    </TimeItem>
                )}
                {totalTime && (
                    <TimeItem>
                        <GreenClockIcon />
                        <TimeText>{totalTime} min</TimeText>
                    </TimeItem>
                )}
            </TimesContainer>
        </OrderItemContainer>
    )
}

export default OrderItem
