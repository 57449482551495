import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import BurgerButton from 'components/_common/burger-button'

import { useAuth } from 'hooks'

import {
    adminContent,
    bikersContent,
    mallsContent,
    marketplaceContent,
    storeContent,
    financialContent,
} from '../menu-content'

import {
    DesktopMenuContainer,
    DesktopMenuIcon,
    DesktopMenuItem,
    HoverBalloon,
    IconContainer,
    ItemText,
    MenuLogo,
    TopRow,
} from './desktop-menu.styles'

const DesktopMenu: React.FC = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const history = useHistory()
    const { pathname } = history.location

    const { basePath, store, user, mall } = useAuth()

    const bikers = pathname.includes('entregador') && basePath?.length === 0
    const malls = pathname.includes('/painel-malls')
    const marketplace = pathname.includes('/marketplace')

    const isShopkeeper = user?.isShopkeeper
    const isFinancial = user?.isFinancial

    const params = useMemo(() => {
        const query = new URLSearchParams(history.location.search)
        const store = query.get('store')
        if (store) {
            return `?store=${store}`
        }
        return ''
    }, [history.location.search])

    function navigate(route: string) {
        if (!route?.length) return

        if (route.includes('solicitar-entrega')) {
            const url = new URL(window.location.href)
            if (!url.searchParams.has('modal-request-order')) {
                url.searchParams.append('modal-request-order', '')
                history.push({ search: url.search })
            }
        } else {
            history.push(route)
        }

        setIsMenuOpened(false)
    }

    function checkMarketplace(route: string) {
        if (route === 'gestao-marketplace' && store.marketplace_enable === 0) {
            return false
        } else {
            return true
        }
    }

    return (
        <DesktopMenuContainer isOpened={isMenuOpened}>
            <TopRow isActive={isMenuOpened}>
                <MenuLogo isActive={isMenuOpened} />
                <BurgerButton burgerClick={() => setIsMenuOpened(!isMenuOpened)} isActive={isMenuOpened} />
            </TopRow>
            {bikers ? (
                <>
                    {bikersContent.map((bikerMenuItem, bikerIndex) => (
                        <DesktopMenuItem
                            isSelected={pathname === bikerMenuItem.route}
                            key={bikerIndex}
                            onClick={() => navigate(bikerMenuItem.route)}
                        >
                            <IconContainer>
                                <DesktopMenuIcon icon={bikerMenuItem.icon as IconProp} />
                            </IconContainer>
                            <ItemText isActive={isMenuOpened}>{bikerMenuItem.label}</ItemText>
                            {!isMenuOpened && <HoverBalloon isHome>{bikerMenuItem.label}</HoverBalloon>}
                        </DesktopMenuItem>
                    ))}
                </>
            ) : malls ? (
                <>
                    {mallsContent.map((mallsMenuItem, mallsIndex) => (
                        <DesktopMenuItem
                            isSelected={pathname === mallsMenuItem.route}
                            key={mallsIndex}
                            onClick={() => navigate(mallsMenuItem.route)}
                        >
                            <IconContainer>
                                <DesktopMenuIcon icon={mallsMenuItem.icon as IconProp} />
                            </IconContainer>
                            <ItemText isActive={isMenuOpened}>{mallsMenuItem.label}</ItemText>
                            {!isMenuOpened && <HoverBalloon isHome>{mallsMenuItem.label}</HoverBalloon>}
                        </DesktopMenuItem>
                    ))}
                </>
            ) : marketplace ? (
                <>
                    {marketplaceContent.map((marketplaceMenuItem, marketplaceIndex) => (
                        <DesktopMenuItem
                            isSelected={pathname.includes(marketplaceMenuItem.route)}
                            key={marketplaceIndex}
                            onClick={() => navigate(marketplaceMenuItem.route)}
                        >
                            <IconContainer>
                                <DesktopMenuIcon icon={marketplaceMenuItem.icon as IconProp} />
                            </IconContainer>
                            <ItemText isActive={isMenuOpened}>{marketplaceMenuItem.label}</ItemText>
                            {!isMenuOpened && <HoverBalloon isHome>{marketplaceMenuItem.label}</HoverBalloon>}
                        </DesktopMenuItem>
                    ))}
                </>
            ) : isShopkeeper || store?.id ? (
                <>
                    {storeContent.map((storeMenuItem, storeIndex) => {
                        const route = basePath.length ? `${basePath}/${storeMenuItem.route}` : ''
                        return (
                            <DesktopMenuItem
                                isShown={checkMarketplace(storeMenuItem.route)}
                                isSelected={pathname.includes(route)}
                                key={storeIndex}
                                onClick={() => navigate(route)}
                            >
                                <IconContainer>
                                    <DesktopMenuIcon icon={storeMenuItem.icon as IconProp} />
                                </IconContainer>
                                <ItemText isActive={isMenuOpened}>{storeMenuItem.label}</ItemText>
                                {!isMenuOpened && <HoverBalloon isHome>{storeMenuItem.label}</HoverBalloon>}
                            </DesktopMenuItem>
                        )
                    })}
                </>
            ) : isFinancial ||
              pathname.includes('financeiro') ||
              pathname.includes('notas') ||
              pathname.includes('relatorio-individual') ? (
                <>
                    {financialContent.map((financialContentItem, financialIndex) => {
                        return (
                            <DesktopMenuItem
                                isSelected={pathname.includes(financialContentItem.route)}
                                key={financialIndex}
                                onClick={() => navigate(financialContentItem.route)}
                            >
                                <IconContainer>
                                    <DesktopMenuIcon icon={financialContentItem.icon as IconProp} />
                                </IconContainer>
                                <ItemText isActive={isMenuOpened}>{financialContentItem.label}</ItemText>
                                {!isMenuOpened && <HoverBalloon isHome>{financialContentItem.label}</HoverBalloon>}
                            </DesktopMenuItem>
                        )
                    })}
                </>
            ) : (
                <>
                    {adminContent.map((adminMenuItem, i) => {
                        if (
                            ['pagamentos', 'entregadores-autorizados'].includes(adminMenuItem.route) &&
                            mall.type === 'public'
                        ) {
                            return null
                        }

                        const route = basePath.length ? `${basePath}/${adminMenuItem.route}${params}` : ''
                        let selected = false

                        if (adminMenuItem.route !== 'rotas') {
                            selected = pathname.includes(adminMenuItem.route)
                        }

                        return (
                            <DesktopMenuItem isSelected={selected} key={i} onClick={() => navigate(route)}>
                                <IconContainer>
                                    <DesktopMenuIcon icon={adminMenuItem.icon as IconProp} />
                                </IconContainer>
                                <ItemText isActive={isMenuOpened}>{adminMenuItem.label}</ItemText>
                                {!isMenuOpened && <HoverBalloon isHome>{adminMenuItem.label}</HoverBalloon>}
                            </DesktopMenuItem>
                        )
                    })}
                </>
            )}
        </DesktopMenuContainer>
    )
}

export default DesktopMenu
