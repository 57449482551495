import { ReactDatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { StyledDateInput, ErrorMessage, DateInputContainer, InputLabel } from './date-input.styles'

interface Props {
    errorMessage?: string | null
    inputProps?: ReactDatePickerProps
    labelText?: string
}

const DateInput: React.FC<Props> = ({ errorMessage, inputProps, labelText }) => {
    return (
        <DateInputContainer>
            {labelText && <InputLabel>{labelText}</InputLabel>}

            <StyledDateInput {...(inputProps as ReactDatePickerProps)} />

            <ErrorMessage>{errorMessage}</ErrorMessage>
        </DateInputContainer>
    )
}

export default DateInput
