import './order-detail-deliveryman.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import photoPlaceholder from '../../assets/images/deliverer.png'

const OrderDeliveryman = props => (
    <div className="order-deliveryman-container">
        <div className="section-title">
            <FontAwesomeIcon icon="user-check" /> Entregador
        </div>

        <div className="deliveryman-info">
            <div className="photo-container">
                <img src={props.photoPath || photoPlaceholder} alt="" />
            </div>
            <div className="info-container">
                <div className="name">{props.delivererName}</div>
                <div className="vehicle">{props.vehicle}</div>
            </div>
            {props.changeClick && (
                <div className="change-button" onClick={props.changeClick}>
                    <FontAwesomeIcon icon="sync" />
                </div>
            )}
        </div>
        {props.exchangeClick && props.routeStatus < 3 && (
            <div className="order-deliveryman__button" onClick={props.exchangeClick}>
                Trocar Entregador
            </div>
        )}
    </div>
)

export default OrderDeliveryman
