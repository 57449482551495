/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react'

import ModalLoading from 'components/modal-loading'

import api, { plugApi } from 'services/api'
import { PagedList } from 'types'
import { IntegrationType } from 'types/integration'
import { ILinkedPayment } from 'types/linked-payment'
import { IPayment } from 'types/payment'

type SelectValue = {
    value: string | number
    name: string
}

type PaymentsContextType = {
    edit?: ILinkedPayment | null
    type?: IntegrationType
    payments: SelectValue[]
    paymentsPlooga: SelectValue[]
    linkedPayments: ILinkedPayment[]
    refresh(): void
}

const InitialValue: PaymentsContextType = {
    payments: [],
    paymentsPlooga: [],
    linkedPayments: [],
    refresh: () => {},
}

const PaymentsContext = createContext<PaymentsContextType>(InitialValue)

type PaymentsContextProps = {
    edit?: ILinkedPayment | null
    type: IntegrationType
    children: any
}

const paymentsAmericanas: SelectValue[] = [
    {
        value: 'PAGAMENTO_ONLINE',
        name: 'Pagamento Online',
    },
    {
        value: 'PIX',
        name: 'PIX Online',
    },
    {
        value: 'PICPAY',
        name: 'PicPay Online',
    },
    {
        value: 'DINHEIRO',
        name: 'Dinheiro Online',
    },
    {
        value: 'VR',
        name: 'Vale Refeição (VR) Online',
    },
    {
        value: 'ALELO',
        name: 'Vale Refeição (Alelo) Online',
    },
    {
        value: 'SODEXO',
        name: 'Vale Refeição (Sodexo) Online',
    },
    {
        value: 'MKT_VALE_VR',
        name: 'Vale Refeição (VR)',
    },
    {
        value: 'MKT_VALE_TICKET',
        name: 'Vale Refeição (Ticket)',
    },
    {
        value: 'MKT_VALE_SODEXO',
        name: 'Vale Refeição (Sodexo)',
    },
    {
        value: 'MKT_VALE_ALELO',
        name: 'Vale Refeição (Alelo)',
    },
    {
        value: 'MKT_PIX',
        name: 'PIX',
    },
    {
        value: 'MKT_PICPAY',
        name: 'PicPay',
    },
    {
        value: 'MKT_DINHEIRO',
        name: 'Dinheiro',
    },
    {
        value: 'MKT_CARTAO_DEBITO_VISA',
        name: 'Cartão de Débito (Visa)',
    },
    {
        value: 'MKT_CARTAO_CREDITO_VISA',
        name: 'Cartão de Crédito (Visa)',
    },
    {
        value: 'MKT_CARTAO_DEBITO_MASTER',
        name: 'Cartão de Débito (Master)',
    },
    {
        value: 'MKT_CARTAO_CREDITO_MASTER',
        name: 'Cartão de Crédito (Master)',
    },
    {
        value: 'MKT_CARTAO_DEBITO_HYPERCARD',
        name: 'Cartão de Débito (Hypercard)',
    },
    {
        value: 'MKT_CARTAO_CREDITO_HYPERCARD',
        name: 'Cartão de Crédito (Hypercard)',
    },
    {
        value: 'MKT_CARTAO_DEBITO_ELO',
        name: 'Cartão de Débito (Elo)',
    },
    {
        value: 'MKT_CARTAO_DEBITO_DINERS',
        name: 'Cartão de Débito (Diners)',
    },
    {
        value: 'MKT_CARTAO_CREDITO_DINERS',
        name: 'Cartão de Crédito (Diners)',
    },
    {
        value: 'MKT_CARTAO_DEBITO_AMEX',
        name: 'Cartão de Débito (Amex)',
    },
    {
        value: 'MKT_CARTAO_CREDITO_AMEX',
        name: 'Cartão de Crédito (Amex)',
    },
]
const paymentsIfood: SelectValue[] = [
    {
        value: 'CASH',
        name: 'Dinheiro',
    },
    {
        value: 'CREDIT',
        name: 'Cartão de Crédito',
    },
    {
        value: 'DEBIT',
        name: 'Cartão de Débito',
    },
    {
        value: 'MEAL_VOUCHER',
        name: 'Vale Refeição',
    },
    {
        value: 'FOOD_VOUCHER',
        name: 'Vale Alimentação',
    },
    {
        value: 'GIFT_CARD',
        name: 'Cartão Presente',
    },
    {
        value: 'DIGITAL_WALLET',
        name: 'Carteira Digital',
    },
    {
        value: 'PIX',
        name: 'PIX',
    },
    {
        value: 'OTHER',
        name: 'Outros',
    },
]

const PaymentsContextProvider: React.FC<PaymentsContextProps> = memo(({ children, type, edit }) => {
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState<SelectValue[]>([])
    const [linkedPayments, setLinkedPayments] = useState<ILinkedPayment[]>([])

    const paymentsPlooga = useMemo(() => {
        if (type === 'ifood') {
            return paymentsIfood
        }
        if (type === 'americanas-delivery') {
            return paymentsAmericanas
        }

        return []
    }, [type])

    const _getPayments = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<IPayment>>('/painel/payments')

            setPayments(data.items.map(item => ({ name: item.name, value: item.id })))
        } catch (error) {
            console.log('error', error)
        }
    }, [])
    const _getPaymentsPlooga = useCallback(async () => {
        try {
            const { data } = await plugApi.get<PagedList<ILinkedPayment>>('/payment-methods', { params: { type } })

            setLinkedPayments(data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [type])

    const _refresh = useCallback(() => {
        _getPaymentsPlooga()
    }, [_getPaymentsPlooga])

    useEffect(() => {
        setLoading(true)
        Promise.all([_getPayments(), _getPaymentsPlooga()]).finally(() => {
            setLoading(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PaymentsContext.Provider
            value={{
                edit,
                type,
                payments,
                paymentsPlooga,
                linkedPayments,
                refresh: _refresh,
            }}
        >
            {children}
            <ModalLoading visible={loading} />
        </PaymentsContext.Provider>
    )
})

export { PaymentsContext, PaymentsContextProvider }
