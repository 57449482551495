export const routed_orders = [
    {
        id: 1,
        route_color: '#6e4480',
        order_status: 2,
        waiting_biker: true,
        orders: [
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Léo',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 8,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Yan',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 7,
                readyTime: 20,
            },
        ],
    },

    {
        id: 1,
        order_status: 1,
        route_color: '#fa8000',
        drafted_order: true,
        orders: [
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Samuel',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Germmano',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
        ],
    },

    {
        id: 1,
        order_status: 1,
        route_color: '#00aa00',
        drafted_order: true,
        orders: [
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Moises',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
        ],
    },

    {
        id: 1,
        order_status: 1,
        route_color: '#0000aa',
        drafted_order: true,
        orders: [
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen ',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen ',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
            {
                storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                channel: 1,
                customerName: 'Carmen',
                customerNeighborhood: 'Varjota',
                customerStreet: 'Floriano Peixoto',
                customerNumber: 762,
                orderValue: 72.3,
                payment: 'Dinheiro',
                distance: 3,
                productionTime: 20,
                elapsedTime: 30,
                status: 6,
                readyTime: 20,
            },
        ],
    },
]
