import { FC, memo, useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'

import iconMapMarker from 'assets/images/icon-map-marker.png'
import mallMarker from 'assets/images/mall-marker.png'

import InfoItem from 'components/_new-general-vision/info-item/info-item'
import { TableOrderItem } from 'components/_store-general-vision'
import { Canvas } from 'components/canvas/canvas'

import { formatCEP, formatCurrency, formatPhone } from 'helpers'
import { useAuth, useMapboxView } from 'hooks'
import { addMultipleSymbolPoints } from 'hooks/useMapboxView/helpers'
import { IDestiny, PendingOrder } from 'types'

import { MapContainer, OrderDetailItem, Row, SectionTitle } from '../sales-detail-modal.styled'

type Props = {
    order: PendingOrder
}

const OrderDetails: FC<Props> = memo(({ order }) => {
    const { mall } = useAuth()
    const { mapRef, mapContainerRef } = useMapboxView({})

    const _removePolygonLayer = useCallback(async () => {
        if (mapRef.current?.getSource('multiple-points')) {
            await mapRef.current?.removeLayer('multiple-points')
            await mapRef.current?.removeSource('multiple-points')
        }
    }, [mapRef])

    useEffect(() => {
        _removePolygonLayer().then(() => {
            if (!mapRef.current) {
                return
            }
            setTimeout(() => {
                if (order?.address?.location) {
                    addMultipleSymbolPoints(mapRef.current, [
                        {
                            id: 'map-marker',
                            src: iconMapMarker,
                            coordinates: order?.address?.location,
                        },
                        {
                            id: 'mall-marker',
                            src: mallMarker,
                            size: 0.4,
                            coordinates: { lat: mall.address?.lat, lng: mall.address?.lng },
                        },
                    ])
                }
                mapRef.current.setCenter({ lat: mall.address?.lat, lng: mall.address?.lng })
                mapRef.current.setZoom(11.5)
                mapRef.current?.resize()
            }, 1000)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    return (
        <>
            <OrderDetailItem>
                <SectionTitle>Informações Sobre o Pedido</SectionTitle>
                <Row>
                    <InfoItem label="Lojista" content={order?.store?.name} />
                </Row>
                <Row>
                    <InfoItem label="Canal de venda" content={order?.sales_channel?.name} />
                    <InfoItem label="Número do Pedido" content={order?.id} />
                </Row>
            </OrderDetailItem>
            <OrderDetailItem>
                <SectionTitle>Cliente</SectionTitle>
                <Row>
                    <InfoItem label="Nome do Cliente" content={order?.customer?.name} />
                    <InfoItem
                        label="Telefone"
                        content={
                            order?.customer?.cellphone?.includes('+55')
                                ? formatPhone(order?.customer?.cellphone)
                                : order?.customer?.cellphone
                        }
                    />
                </Row>
                <Row>
                    <InfoItem label="CPF" content={order?.customer?.cpf || '-'} />
                </Row>
            </OrderDetailItem>
            <OrderDetailItem>
                <SectionTitle>Pedido</SectionTitle>
                {order?.items?.map((item, i) => (
                    <TableOrderItem key={i} orderItem={item} />
                ))}
            </OrderDetailItem>
            <OrderDetailItem>
                <SectionTitle>Detalhes de Pagamento</SectionTitle>

                <Row>
                    <InfoItem label="Gateway" content={order?.transaction?.gateway?.toLocaleUpperCase()} />
                    <InfoItem label="Forma de Pagamento" content={order?.transaction?.type} />
                </Row>
                <Row>
                    <InfoItem label="Valor do Pedido" content={formatCurrency(order?.price?.total)} />
                    <InfoItem label="Data da compra" content={format(order?.created_at, 'DD/MM/YYYY HH:mm')} />
                </Row>
                <Row>
                    <InfoItem label="ID da Transação" content={order?.transaction?.id} />
                </Row>
            </OrderDetailItem>
            <OrderDetailItem>
                <SectionTitle>Endereço</SectionTitle>
                <Row>
                    <InfoItem label="CEP" content={formatCEP(order?.address?.zipcode)} />
                    <InfoItem label="Bairro de entrega" content={order?.address?.neighborhood as string} />
                </Row>
                <Row>
                    <InfoItem label="Logradouro" content={order?.address?.street} />
                    <InfoItem label="Número" content={order?.address?.number} />
                </Row>
                <Row>
                    <InfoItem label="Complemento" content={order?.address?.complement} />
                    <InfoItem label="Referência" content={order?.address?.landmark} />
                </Row>
                {order?.destinies?.length > 0 ? (
                    <CanvasComponent destinies={order.destinies} />
                ) : (
                    <MapContainer ref={mapContainerRef}></MapContainer>
                )}
            </OrderDetailItem>
        </>
    )
})

type CanvasComponentProps = {
    destinies: IDestiny[]
}
const CanvasComponent: React.FC<CanvasComponentProps> = memo(({ destinies }) => {
    const [destiny, setDestiny] = useState<IDestiny>()

    useEffect(() => {
        if (destinies.length > 0) {
            setDestiny(destinies[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (!destiny) {
        return null
    }
    return (
        <>
            <Canvas destiny={destiny} />
        </>
    )
})

export { OrderDetails }
