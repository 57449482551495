/* eslint-disable react/style-prop-object */
import { useRef, useEffect, useState, useCallback } from 'react'

import mapboxgl, { GeoJSONSource, Map, Marker, Popup, SymbolLayer } from 'mapbox-gl'

import { useAuth } from 'hooks/useAuth'
import { mapboxAccessToken } from 'services/mapbox'

import {
    addCircleLines,
    addCirclePolygon,
    addDraggableMarker,
    addDraggableSymbolPoint,
    addGeojsonPolygon,
    addGeojsonPolygons,
    addMultipleMarkers,
    addMultipleSymbolPoints,
    addSymbolPoint,
} from './helpers'
import { Coordinates, CustomMarker, Image } from './types'

mapboxgl.accessToken = mapboxAccessToken || ''

interface HookParams {
    coordinates?: Coordinates
    zoom?: number
    onLoad?(map: Map): void
}

export function useMapboxView({ coordinates, zoom = 12, onLoad }: HookParams): {
    map: Map | undefined
    mapRef: React.RefObject<Map>
    mapContainerRef: React.RefObject<HTMLDivElement>
    addCircleLines(center: Coordinates, radius: { min: number; max: number }): { source: GeoJSONSource }
    addCirclePolygon(center: Coordinates, radius: number): { source: GeoJSONSource }
    addDraggableMarker(coordinates: Coordinates, onDragEnd: (coords: Coordinates) => void): Marker | undefined
    addDraggableSymbolPoint(coordinates: Coordinates, onDragEnd?: (coords: Coordinates) => void, image?: Image): void
    addGeojsonPolygons(geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry>): { source: GeoJSONSource }
    addGeojsonPolygon(geojson: GeoJSON.Feature<GeoJSON.Geometry>): { source: GeoJSONSource }
    addMultipleMarkers(customMarkers: CustomMarker[]): Marker[]
    addMultipleSymbolPoints(
        images: Image[],
        fitBounds?: boolean,
        fitBoundsOptions?: mapboxgl.FitBoundsOptions
    ): {
        source: GeoJSONSource
        layer: SymbolLayer
        popup: Popup
    }
    addSymbolPoint(coordinates: Coordinates, image?: Image): void
} {
    const { mall } = useAuth()

    const [lat, setLat] = useState(coordinates?.lat || mall?.address?.lat)
    const [lng, setLng] = useState(coordinates?.lng || mall?.address?.lng)
    const [mapZoom, setMapZoom] = useState(zoom)
    const [map, setMap] = useState<Map>()

    const mapRef = useRef<Map>()

    const mapContainerRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>

    useEffect(() => {
        if (!mapContainerRef.current) {
            return
        }
        const map = new mapboxgl.Map({
            container: mapContainerRef.current || '',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: mapZoom,
        })

        map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right')

        map.on('move', () => {
            setLng(map.getCenter().lng)
            setLat(map.getCenter().lat)
            setMapZoom(map.getZoom())
        })

        map.on('load', () => {
            map.resize()

            if (onLoad) {
                onLoad(map)
            }
        })
        mapRef.current = map
        setMap(map)

        return () => map.remove()
    }, []) // eslint-disable-line

    const _addDraggableMarker = useCallback((coordinates: Coordinates, onDragEnd: (coords: Coordinates) => void) => {
        if (mapRef.current) {
            return addDraggableMarker(mapRef.current, coordinates, onDragEnd)
        }
    }, [])

    return {
        map,
        mapRef,
        mapContainerRef,
        addCircleLines: (center, radius) => map && addCircleLines(map, center, radius),
        addCirclePolygon: (center, radius) => map && addCirclePolygon(map, center, radius),
        addDraggableMarker: _addDraggableMarker,
        addDraggableSymbolPoint: (coordinates, onDragEnd, image) =>
            map && addDraggableSymbolPoint(map, coordinates, onDragEnd, image),
        addGeojsonPolygon: geojson => map && addGeojsonPolygon(map, geojson),
        addGeojsonPolygons: geojson => map && addGeojsonPolygons(map, geojson),
        addMultipleMarkers: customMarkers => map && addMultipleMarkers(map, customMarkers),
        addMultipleSymbolPoints: (images, fitBounds, fitBoundsOptions) =>
            map && addMultipleSymbolPoints(map, images, fitBounds, fitBoundsOptions),
        addSymbolPoint: (coordinates, image) => map && addSymbolPoint(map, coordinates, image),
    }
}
