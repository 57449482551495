export const ready_orders = [
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 1,
        productionTime: 20,
        elapsedTime: 30,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
        channel: 2,
        customerName: 'Yan Gondim',
        customerNeighborhood: 'Edson Queiroz',
        customerStreet: 'Avenida Washington Soares',
        customerNumber: 800,
        orderValue: 72.3,
        payment: 'Dinheiro',
        routePosition: 2,
        distance: 7,
        productionTime: 10,
        elapsedTime: 25,
        readyTime: 50,
    },
    {
        storeImage:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1Q--mar77GcICx-Nw3PxY1m-xRdFW6GcODQ&usqp=CAU',
        channel: 3,
        customerName: 'Erlon Charles',
        customerNeighborhood: 'Joaquim Tavora',
        customerStreet: 'Osvaldo Cruz',
        customerNumber: 2133,
        orderValue: 14.3,
        payment: 'Cartão de Crédito - Visa',
        distance: 3,
        routePosition: 3,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Samuel',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 4,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 5,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 30,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
        channel: 2,
        customerName: 'Yan Gondim',
        customerNeighborhood: 'Edson Queiroz',
        customerStreet: 'Avenida Washington Soares',
        customerNumber: 800,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 7,
        productionTime: 10,
        elapsedTime: 25,
        readyTime: 50,
    },
    {
        storeImage:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1Q--mar77GcICx-Nw3PxY1m-xRdFW6GcODQ&usqp=CAU',
        channel: 3,
        customerName: 'Erlon Charles',
        customerNeighborhood: 'Joaquim Tavora',
        customerStreet: 'Osvaldo Cruz',
        customerNumber: 2133,
        orderValue: 14.3,
        payment: 'Cartão de Crédito - Visa',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Samuel',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 30,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
        channel: 2,
        customerName: 'Yan Gondim',
        customerNeighborhood: 'Edson Queiroz',
        customerStreet: 'Avenida Washington Soares',
        customerNumber: 800,
        orderValue: 72.3,
        payment: 'Dinheiro',
        routePosition: 12,
        distance: 7,
        productionTime: 10,
        elapsedTime: 25,
        readyTime: 50,
    },
    {
        storeImage:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1Q--mar77GcICx-Nw3PxY1m-xRdFW6GcODQ&usqp=CAU',
        channel: 3,
        customerName: 'Erlon Charles',
        customerNeighborhood: 'Joaquim Tavora',
        customerStreet: 'Osvaldo Cruz',
        customerNumber: 2133,
        orderValue: 14.3,
        payment: 'Cartão de Crédito - Visa',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Samuel',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
    {
        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
        channel: 1,
        customerName: 'Carmen Li',
        customerNeighborhood: 'Varjota',
        customerStreet: 'Floriano Peixoto',
        customerNumber: 762,
        orderValue: 72.3,
        payment: 'Dinheiro',
        distance: 3,
        routePosition: 12,
        productionTime: 20,
        elapsedTime: 10,
        readyTime: 50,
    },
]
