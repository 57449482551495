import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef } from 'react'

import { useFormik } from 'formik'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import api from 'services/api'
import { IIntegrationLogaroo } from 'types/integration'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from '../manager-integrator-logaroo-modal.styled'

interface IChangePasswordModal {
    integration: IIntegrationLogaroo
    onClose?(isRefresh?: boolean): void
    ref: IChangePasswordModalRef
}

export interface IChangePasswordModalRef {
    show(): void
    close(): void
}

interface IForm {
    password: string
    password_confirmation: string
}

const ManagerIntegratiorLogarooPasswordModal = memo(
    forwardRef<IChangePasswordModalRef, IChangePasswordModal>(({ onClose, integration }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const headerContent = useMemo(
            () => (
                <FormSection>
                    <FormLabel>
                        Editar senha de{' '}
                        <strong>
                            {integration?.first_name} {integration?.last_name}
                        </strong>
                    </FormLabel>
                </FormSection>
            ),
            [integration]
        )

        const { isSubmitting, errors, handleSubmit, resetForm, setFieldValue, touched, values } = useFormik<IForm>({
            initialValues: {
                password: '',
                password_confirmation: '',
            },
            validationSchema: Yup.object().shape({
                password: Yup.string().min(8, 'Deve ter no mínimo 8 caracteres').trim().required('Insira a Senha'),
                password_confirmation: Yup.string()
                    .trim()
                    .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
                    .required('Confirme a Senha'),
            }),
            onSubmit: async values => {
                try {
                    await api.put(`painel/integrator/${integration.id}`, {
                        password: values.password,
                        password_confirmation: values.password_confirmation,
                    })

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Senha atualizada com sucesso!',
                        singleButtonClick: _onClose(true),
                    })

                    resetForm()
                } catch (error) {
                    console.log({ error })

                    setErrorModal({
                        title: 'Erro',
                        subtitle: 'Não foi possível atualizar a senha do integrador. Tente novamente mais tarde.',
                    })
                }
            },
        })

        useImperativeHandle(ref, () => ({
            show: () => {
                resetForm()
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
        }))

        const _onClose = useCallback(
            (isRefresh?: boolean) => {
                return () => {
                    if (onClose) {
                        onClose(isRefresh)
                    }

                    resetForm()
                    lateralModalBaseRef.current?.close()
                }
            },
            [onClose, resetForm]
        )

        const _handleChange = useCallback(
            (key: string) => {
                return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue(key, value)
                }
            },
            [setFieldValue]
        )

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title="Logaroo - Alterar Senha"
                onClose={onClose}
                headerComponent={headerContent}
            >
                <OutsideContainer>
                    <ContentContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Senha"
                                type="password"
                                inputProps={{
                                    value: values.password,
                                    onChange: _handleChange('password'),
                                }}
                                errorMessage={touched.password && errors.password}
                            />
                            <InputItem
                                labelText="Confirmar Senha"
                                type="password"
                                inputProps={{
                                    value: values.password_confirmation,
                                    onChange: _handleChange('password_confirmation'),
                                }}
                                errorMessage={touched.password_confirmation && errors.password_confirmation}
                            />
                        </InputContainer>
                    </ContentContainer>
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={_onClose()}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{'Salvar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
                <ModalLoading visible={isSubmitting} />
            </LateralModalBase>
        )
    })
)

export { ManagerIntegratiorLogarooPasswordModal }
