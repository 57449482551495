import { useState, useRef, useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TextInputMask from 'components/text-input-mask'

import { showErrors } from 'helpers'
import api from 'services/api'
import siclosPagApi from 'services/siclospag'

interface Store {
    id: number
    name: string
    trade_name: string
    cnpj: string
}

const RecipientCreateCredentialed: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)
    const [loading, setLoading] = useState(false)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const [establishments, setEstablishments] = useState({ establishment_list: [] })
    const [stores, setStores] = useState<Store[]>([])

    const { initialValues, isSubmitting, errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                selected_store: '',
                is_recipient_to_production: false,
                establishment_id: '',
                recipient_name: '',
                business_name: '',
                is_cnpj: false,
                document_number: '',
                stone_code: '',
                address: {
                    zip_code: '',
                    number: '',
                    complement: '',
                },
            },
            validationSchema: Yup.object().shape({
                establishment_id: Yup.string().trim().required('Selecione o Estabelecimento'),
                recipient_name: Yup.string().trim().required('Nome do recebedor é obrigatório'),
                business_name: Yup.string().trim().required('Nome Fantasia é obrigatório'),
                is_cnpj: Yup.bool(),
                document_number: Yup.string()
                    .trim()
                    .when('is_cnpj', {
                        is: is_cnpj => is_cnpj,
                        then: (schema: Yup.StringSchema) =>
                            schema
                                .transform(document_number => document_number.replace(/\D+/g, ''))
                                .min(14, 'CNPJ deve ter 14 caracteres')
                                .required('CNPJ é obrigatório'),
                        otherwise: (schema: Yup.StringSchema) =>
                            schema
                                .transform(document_number => document_number.replace(/\D+/g, ''))
                                .min(11, 'CPF deve ter 11 caracteres')
                                .required('CPF é obrigatório'),
                    }),
                stone_code: Yup.string().trim().required('Stone code é obrigatório'),
                address: Yup.object().shape({
                    zip_code: Yup.string().trim().required('CEP é obrigatório'),
                    number: Yup.string().trim().required('Número é obrigatório'),
                }),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    const { data } = await siclosPagApi.post('/recipient/create-stone-credentialed', {
                        ...values,
                        document_number: values.document_number.replace(/\D+/g, ''),
                        address: {
                            ...values.address,
                            zip_code: values.address.zip_code.replace(/\D+/g, ''),
                        },
                    })

                    if (data?.error) {
                        throw data.msg
                    }

                    setModalMessageData({
                        title: 'Sucesso',
                        message: 'Recebedor criado com sucesso!',
                    })

                    modalMessage.current?.openModal()
                    resetForm()
                } catch (error) {
                    console.log('handleSubmit', { error })

                    setModalMessageData({
                        isActive: true,
                        title: 'Erro',
                        message: showErrors(error),
                        textButton: 'Revisar alterações',
                    })

                    modalMessage.current?.openModal()
                } finally {
                    setSubmitting(false)
                }
            },
        })

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const getStores = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/stores-to-select', {
                params: { per_page: -1 },
            })

            setStores(data.items)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/gestao-mall'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getStores()
    }, [getStores])

    async function populateWithStoreFields(store_id: number) {
        try {
            setLoading(true)

            const { data } = await api.get(`/painel/store/${store_id}`)

            setValues({
                ...initialValues,
                is_cnpj: true,
                recipient_name: data?.name || '',
                business_name: data?.trade_name || '',
                document_number: data?.cnpj || '',
                address: {
                    ...values.address,
                    zip_code: data?.zipcode || '',
                    number: data?.number || '',
                    complement: data?.complement || '',
                },
            })
        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }
    }

    function handleInputChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field, value)
        }
    }

    function handleSelectChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue(field, value)
        }
    }

    return (
        <div>
            <RowTitle
                title="Criar recebedor já credenciado"
                backClick={() => history.push('/painel-malls/gestao-stone/recebedores')}
                buttonRow={
                    values.selected_store.length
                        ? [
                              {
                                  label: 'Autopreencher campos',
                                  onClick: () => populateWithStoreFields(Number(values.selected_store)),
                              },
                          ]
                        : undefined
                }
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>Criar a partir de uma loja existente:</label>
                    <SelectInputForm
                        style={{ margin: 0, marginRight: '10px' }}
                        data={stores?.map(({ id, name }) => ({
                            name: name,
                            value: id,
                        }))}
                        onChange={handleSelectChange('selected_store')}
                        value={values.selected_store}
                    />
                </div>
            </RowTitle>

            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="stone-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome de identificação do recebedor*"
                        onChange={handleInputChange('recipient_name')}
                        value={values.recipient_name}
                        msgErro={touched.recipient_name && errors.recipient_name}
                    />
                    <div className="radio-group">
                        <div className="radio-group-row">
                            <InputRadio
                                id="is_cnpj-no"
                                label="Pessoa Física"
                                checked={!values.is_cnpj}
                                onClick={() => setFieldValue('is_cnpj', false)}
                            />

                            <InputRadio
                                id="is_cnpj-yes"
                                label="Pessoa Jurídica"
                                checked={values.is_cnpj}
                                onClick={() => setFieldValue('is_cnpj', true)}
                            />
                        </div>
                    </div>

                    <SelectInputForm
                        label="Estabelecimento*"
                        data={establishments.establishment_list?.map(({ id, business_name }) => ({
                            name: business_name,
                            value: id,
                        }))}
                        onChange={handleSelectChange('establishment_id')}
                        value={values.establishment_id}
                        msgErro={touched.establishment_id && errors.establishment_id}
                    />
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label={values.is_cnpj ? 'Nome Fantasia*' : 'Nome do recebedor*'}
                        onChange={handleInputChange('business_name')}
                        value={values.business_name}
                        msgErro={touched.business_name && errors.business_name}
                    />

                    <TextInputMask
                        label={values.is_cnpj ? 'CNPJ do recebedor*' : 'CPF do recebedor*'}
                        mask={values.is_cnpj ? '99.999.999/9999-99' : '999.999.999-99'}
                        onChange={handleInputChange('document_number')}
                        value={values.document_number}
                        msgErro={touched.document_number && errors.document_number}
                    />

                    <TextInputForm
                        label="Stone code*"
                        onChange={handleInputChange('stone_code')}
                        value={values.stone_code}
                        msgErro={touched.stone_code && errors.stone_code}
                    />
                </div>

                <div className="inputs-row col-3">
                    <div className="radio-group">
                        <label>Ativo em produção?</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="is_recipient_to_production"
                                label="Sim"
                                checked={values.is_recipient_to_production}
                                onClick={() => setFieldValue('is_recipient_to_production', true)}
                            />

                            <InputRadio
                                id="is_recipient_not_to_production"
                                label="Não"
                                checked={!values.is_recipient_to_production}
                                onClick={() => setFieldValue('is_recipient_to_production', false)}
                            />
                        </div>
                    </div>
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Endereço
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="CEP*"
                        mask="99999-999"
                        onChange={handleInputChange('address.zip_code')}
                        value={values.address.zip_code}
                        msgErro={touched.address?.zip_code && errors.address?.zip_code}
                    />
                    <TextInputForm
                        label="Número*"
                        onChange={handleInputChange('address.number')}
                        value={values.address.number}
                        msgErro={touched.address?.number && errors.address?.number}
                    />
                    <TextInputForm
                        label="Complemento"
                        onChange={handleInputChange('address.complement')}
                        value={values.address.complement}
                        msgErro={touched.address?.complement && errors.address?.complement}
                    />
                </div>

                <ButtonForm
                    buttonText="Salvar"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
                />
            </div>
        </div>
    )
}

export default RecipientCreateCredentialed
