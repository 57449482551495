import { memo, useCallback, useMemo } from 'react'
import * as Feather from 'react-feather'

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { format } from 'date-fns'

import { Info } from 'components/info/info'

import { bytesToSize } from 'helpers/bytes-to-size'
import { IImportFile } from 'types/import-file'

import { Container, Table, RowTable, ColumnTable } from './table-file.styled'

const columnHelper = createColumnHelper<IImportFile>()

type Props = {
    onMenuClick(data: IImportFile): void
    imports: IImportFile[]
}
const TableFile: React.FC<Props> = memo(({ onMenuClick, imports }) => {
    const _open = useCallback(
        (id: number) => {
            return () => {
                const data = imports.find(item => item.id === id)
                if (onMenuClick) {
                    onMenuClick(data)
                }
            }
        },
        [onMenuClick, imports]
    )

    const columns = useMemo(() => {
        return [
            columnHelper.accessor('file.original_name', {
                id: 'name',
                cell: cell => <Info label="Nome do Arquivo" value={cell.getValue()} />,
            }),
            columnHelper.accessor('creator.name', {
                id: 'user',
                cell: cell => <Info label="Quem enviou" value={cell.getValue()} />,
            }),
            columnHelper.accessor('created_at', {
                id: 'date',
                cell: cell => <Info label="Data" value={format(cell.getValue(), 'DD/MM/YYYY')} />,
            }),
            columnHelper.accessor('created_at', {
                id: 'time',
                cell: cell => <Info label="Hora" value={format(cell.getValue(), 'HH:mm')} />,
            }),
            columnHelper.accessor('orders_count', {
                id: 'qty',
                cell: cell => <Info label="Qnt. de Pedidos" value={`${cell.getValue()} Pedidos`} />,
            }),
            columnHelper.accessor('file_size', {
                id: 'size',
                cell: cell => <Info label="Tamanho do arquivo" value={bytesToSize(cell.getValue())} />,
            }),
            columnHelper.accessor('status', {
                id: 'status',
                cell: cell => <Info label="Status" {...getStatusProps(cell.getValue())} />,
            }),
            columnHelper.accessor('id', {
                id: 'menu',
                cell: cell => <Feather.Eye className="eye" onClick={_open(cell.getValue())} size={20} />,
            }),
        ]
    }, [_open])

    const table = useReactTable({
        data: imports,
        columns,
        enableColumnResizing: true,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <>
            <Container>
                <Table>
                    {table.getRowModel().rows.map(row => (
                        <RowTable key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <ColumnTable key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </ColumnTable>
                            ))}
                        </RowTable>
                    ))}
                </Table>
            </Container>
        </>
    )
})

function getStatusProps(status: 'draft' | 'run' | 'completed'): {
    variant: 'danger' | 'primary' | 'success' | 'alert'
    value: string
} {
    if (status === 'draft') {
        return {
            variant: 'danger',
            value: 'Rascunho',
        }
    }
    if (status === 'run') {
        return {
            variant: 'alert',
            value: 'Processando',
        }
    }
    if (status === 'completed') {
        return {
            variant: 'success',
            value: 'Sucesso',
        }
    }
}

export { TableFile }
