import styled from 'styled-components'

export const AmountCardContainer = styled.div`
    border-radius: 12px;
    background-color: #f7f7f7;
    padding: 15px 0px;
`

export const Number = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 15px;
`

export const AmountTitle = styled.div`
    font-weight: 600;
    color: #717171;
    font-size: 14px;
    margin-left: 15px;
`

export const AmountSubtitle = styled.div`
    color: #ababab;
    font-size: 12px;
    margin-left: 15px;
`
