import { useMemo } from 'react'

import { formatCurrency, getSalesChannelIcon, getTimeDiff } from 'helpers'
import { PendingOrder } from 'types'

import {
    IconContainer,
    ClockIcon,
    ChannelIcon,
    OrderID,
    CustomerInfo,
    CustomerName,
    OrderItemContainer,
    OrderPrice,
    PaymentMethod,
    PriceValue,
    TagItem,
    TagText,
    TimesContainer,
    MobileValue,
    ActionRow,
    RejectButton,
    RejectIcon,
    AcceptButton,
} from './store-order-item.styles'

interface Props {
    order: PendingOrder
    isHighlighted: boolean
    onConfirm(): void
    onReject(): void
    cardClick(): void
}

const StoreOrderItem: React.FC<Props> = ({ order, isHighlighted, onConfirm, onReject, cardClick }) => {
    const handleClick = (e: React.MouseEvent, callback: () => void) => {
        e.stopPropagation()
        callback()
    }

    const totalTime = useMemo(() => {
        const { text } = getTimeDiff(order.created_at)

        return text
    }, [order])

    return (
        <OrderItemContainer isHighlighted={isHighlighted} onClick={(e: React.MouseEvent) => handleClick(e, cardClick)}>
            <ChannelIcon src={getSalesChannelIcon(order)} />
            <CustomerInfo>
                <OrderID>#{order.id}</OrderID>
                <CustomerName>{order.customer.name}</CustomerName>
                <MobileValue>
                    {formatCurrency(order.price.total)} - {order?.payment?.name}
                </MobileValue>
            </CustomerInfo>
            <OrderPrice>
                <PriceValue>{formatCurrency(order.price.total)}</PriceValue>
                <PaymentMethod>{order?.payment?.name}</PaymentMethod>
            </OrderPrice>
            <TimesContainer>
                {totalTime && (
                    <TagItem>
                        <IconContainer>
                            <ClockIcon />
                        </IconContainer>
                        <TagText>{totalTime}</TagText>
                    </TagItem>
                )}
            </TimesContainer>
            <ActionRow>
                <AcceptButton onClick={(e: React.MouseEvent) => handleClick(e, onConfirm)}>Confirmar</AcceptButton>
                <RejectButton onClick={(e: React.MouseEvent) => handleClick(e, onReject)}>
                    <RejectIcon /> Não Aceitar
                </RejectButton>
            </ActionRow>
        </OrderItemContainer>
    )
}

export default StoreOrderItem
