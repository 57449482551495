import {
    SearchContainer,
    SearchIcon,
    SearchInput,
    Title,
    TitleContainer,
    TopRowContainer,
    UserIcon,
} from './new-top-row.styles'

interface Props {
    title: string
    changeUserClick?(): void
    showSearch?: boolean
}
const NewTopRow: React.FC<Props> = ({ title, changeUserClick, showSearch }) => {
    return (
        <TopRowContainer>
            <TitleContainer>
                <Title>{title}</Title>
                {changeUserClick && <UserIcon onClick={changeUserClick} />}
            </TitleContainer>
            {showSearch && (
                <SearchContainer>
                    <SearchInput placeholder="Buscar" />
                    <SearchIcon />
                </SearchContainer>
            )}
        </TopRowContainer>
    )
}

export default NewTopRow
