import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const SnackbarContainer = styled.div<{ isDisplayed?: boolean; isEffectActive?: boolean; rightSide?: boolean }>`
    width: 300px;
    border-radius: 10px;
    display: ${({ isDisplayed }) => (isDisplayed ? 'flex' : 'none')};
    align-items: center;
    background-color: #666;
    color: ${({ theme }) => theme.colors.white};
    padding: 10px;
    position: fixed;
    bottom: 50px;
    right: ${({ rightSide }) => (rightSide ? '20px' : 'unset')};
    left: ${({ rightSide }) => (rightSide ? 'unset' : '20px')};
    z-index: ${({ theme }) => theme.snackbarIndex};
    transition: 200ms;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? 0 : '400%')});
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        right: ${({ rightSide }) => (rightSide ? '120px' : 'unset')};
        left: ${({ rightSide }) => (rightSide ? 'unset' : '120px')};
    }
`

export const SnackbarIconContainer = styled.div`
    margin-right: 20px;
    font-size: 24px;
`

export const SnackbarIcon = styled(FontAwesomeIcon)<{ icon: IconProp; color: string }>``

export const TextContainer = styled.div`
    flex: 1;
    margin-right: 10px;
`

export const CloseContainer = styled.div`
    cursor: pointer;
    user-select: none;
`

export const CloseIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.white};
`
