import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const TopRowContainer = styled.div`
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.gray};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 20px 83px;
    }
`

export const OrderTitleRow = styled.div`
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        align-items: center;
    }
`

export const OrderTitle = styled.div`
    font-size: 32px;
    font-weight: 700;
    position: relative;
    margin-right: 40px;
    &::after {
        content: '';
        width: 80px;
        height: 3px;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        bottom: -10px;
        left: 0;
    }
`

export const OrderStatusContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 16px;
        align-items: center;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-top: 0;
    }
`

export const StatusCircle = styled.div<{ status?: number }>`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${({ theme, status }) =>
        status === 1
            ? theme.colors.primary
            : status === 2
            ? theme.colors.success
            : status === 3
            ? theme.colors.errorMessage
            : theme.colors.lightGray};
    margin-right: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding-left: 0;
    }
`

export const StatusTitle = styled.div`
    text-transform: uppercase;
    width: 80%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
    }
`

export const ScheduleDetail = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.primary};
    padding: 10px 20px;
    border-radius: 12px;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 0;
        margin-left: 20px;
    }
`

export const OrderDate = styled.div`
    font-size: 14px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: bold;
    span {
        color: ${({ theme }) => theme.colors.black};
    }
`

export const EditIcon = styled(FontAwesomeIcon).attrs({
    icon: faPencilAlt,
})`
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
`

export const OrderProblem = styled.div`
    margin-left: 5px;
    text-transform: uppercase;
    flex: 1;
    padding-left: 33px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
        padding-left: 0;
        flex: unset;
    }
`

export const BottomRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const SeeRoute = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    transition: 400ms;
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 0;
    }
`
