import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'

import fastFood from 'assets/images/fast-food.png'

import { EmptyListMessage, Snackbar } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'

import { EditProductModal, ProductCategory } from '..'

import { PagedList } from 'types'
import { Product } from 'types/marketplace'

import { Header, HeaderTitle, MainContent, ProductTabContainer } from './product-tab-content.styles'

const ProductTabContent: React.FC = memo(() => {
    const { store } = useAuth()
    const { closeSnackbar, snackbarProps, setErrorModal, setSnackbar } = useUI()

    const history = useHistory()
    const { pathname } = history.location

    const [products, setProducts] = useState<Product[]>()
    const [loading, setLoading] = useState<boolean>()

    const groupedProducts:
        | Array<{
              categoryName: string
              products: Product[]
          }>
        | undefined = useMemo(() => {
        const groupedProductsByCategory = products?.reduce(
            (acc, product) => ({
                ...acc,
                [product.category.name]: [...(acc[product.category.name] || []), product],
            }),
            {} as { [key: string]: Product[] }
        )

        if (!groupedProductsByCategory) return undefined

        return Object.keys(groupedProductsByCategory).map(categoryName => ({
            categoryName: categoryName,
            products: groupedProductsByCategory[categoryName],
        }))
    }, [products])

    const productFormVisible = useMemo(() => {
        return pathname.includes('editar-produto') || pathname.includes('criar-produto')
    }, [pathname])

    const _getProducts = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<Product>>(`/painel/products`, {
                params: { store_id: store.id, order_by: 'name', per_page: -1 },
            })
            setProducts(data.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro ao alterar status',
                subtitle: showErrors(error),
            })
        } finally {
            setLoading(false)
        }
    }, [store])

    const _updateProductStatus = useCallback(
        async (productId: number, status: number) => {
            try {
                const { data } = await api.put(`/painel/product/${productId}`, { store_id: store.id, status })

                setProducts(state => products?.map(product => (product.id === data.id ? data : product)))

                setSnackbar({ message: status ? 'Pedido ativo' : 'Pedido Inativo' })
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao alterar status',
                    subtitle: showErrors(error),
                })
            }
        },
        [products]
    )

    //const { data: products, loading } = useDataFetching<PagedList<Product>>(`/painel/products`, {
    //    params: { store_id: store.id, order_by: 'name', per_page: -1 },
    //})

    useEffect(() => {
        _getProducts()
    }, [_getProducts])

    return (
        <>
            <ProductTabContainer>
                {/*
                    <RowTitle
                        title="Produtos"
                        buttonRow={[
                            {
                                label: 'Criar novo produto',
                                onClick: () => history.push(`${pathname}/criar-produto`),
                            },
                        ]}
                    />
                */}
                <Header>
                    <HeaderTitle>Produtos</HeaderTitle>

                    <DefaultButton widthFitContainer onClick={() => history.push(`${pathname}/criar-produto`)}>
                        Criar novo produto
                    </DefaultButton>
                </Header>
                <MainContent>
                    {loading
                        ? Array.from({ length: 4 }).map((_item, index) => (
                              <div key={index} style={{ padding: 20, margin: '10px' }}>
                                  <Skeleton key={index} height={80} />
                              </div>
                          ))
                        : groupedProducts?.map((group, index) => (
                              <ProductCategory
                                  key={index}
                                  categoryTitle={group.categoryName}
                                  products={group.products}
                                  updateProductStatus={_updateProductStatus}
                                  editClick={(productId: number) =>
                                      history.push(`${pathname}/editar-produto/${productId}`)
                                  }
                              />
                          ))}

                    {!loading && groupedProducts?.length === 0 && (
                        <EmptyListMessage image={fastFood} message="Não há produtos cadastrados" />
                    )}
                </MainContent>
            </ProductTabContainer>

            <EditProductModal
                isActive={productFormVisible}
                products={products}
                closeClick={() => history.goBack()}
                updateProductStatus={_updateProductStatus}
            />

            <Snackbar {...snackbarProps} closeClick={() => closeSnackbar()} />
        </>
    )
})

export default ProductTabContent
