import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const EditGroupModalContainer = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transition: 400ms;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
`

export const EditGroupModalBackground = styled.div<{ isEffectActive: boolean }>`
    z-index: ${({ theme }) => theme.commonModalZIndex};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    transition: 400ms;
    opacity: ${({ isEffectActive }) => (isEffectActive ? '1' : '0')};
`

export const EditGroupModalBox = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 650px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

export const EditGroupHeader = styled.div`
    padding: 20px;
    padding-bottom: 0;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 0px 40px;
        padding-top: 20px;
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    width: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
        top: 0;
        right: 10px;
    }
`

export const EditGroupModalContent = styled.div`
    flex: 1;
    overflow: auto;
    margin-bottom: 0;
    padding: 20px;
    padding-top: 0;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 0px 40px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`

export const OrderStatusContainer = styled.div`
    margin-top: -6px;
`

export const StatusTitle = styled.div`
    font-size: 12px;
`

export const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 40px;
        margin-bottom: 10px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

export const TabsContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 0px 40px;
    }
`

export const TabItem = styled.div<{ isActive?: boolean }>`
    font-size: 12px;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 18px;
    }
`

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 16px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const ConfirmButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const InputRow = styled.div<{ halfSize?: boolean }>`
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: ${({ halfSize }) => (halfSize ? '60%' : '100%')};
    }
`

export const ItemContainer = styled.div`
    margin-right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 48%;
    }
`

export const GroupTitle = styled.div`
    margin-bottom: 10px;
`

export const CheckboxRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    div {
        margin-right: 10px;
    }
`

export const SingleInputItem = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 60%;
    }
`

export const DishesListing = styled.div`
    display: flex;
    flex-direction: column;
`

export const DishItem = styled.div`
    font-size: 14px;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;

    .check-box {
        margin-bottom: 0;
        .box {
            margin-right: 10px;
        }
        .checkbox-title {
            font-size: 12px;
        }
        .checkbox-subtitle {
            font-size: 11px;
            margin-top: 0;
        }
    }

    .column-product {
        flex: 1;
        &.text {
            font-size: 12px;
        }
    }
    .fake-icon {
        width: 14px;
    }
    .container-inputs {
        display: flex;
        gap: 10px;
        .icon {
            align-self: center;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 0px;
            cursor: pointer;
        }
        .column-input {
            margin: 0;
            &.text {
                font-size: 12px;
            }
            input {
                margin-bottom: 0px;
                box-sizing: border-box;
                font-size: 12px !important;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &[type='number'] {
                    -moz-appearance: textfield;
                }
            }

            width: 80px;
            &:first-child {
                input {
                    text-align: center;
                    padding: 0;
                }
                width: 40px;
            }
        }
    }
`
