import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 10px 40px;
    align-items: center;
    &:nth-child(odd) {
        background: #f0f0f0;
    }
`

const Column = styled.div<{ fitContent?: boolean; center?: boolean; widthFix?: number }>`
    font-size: 12px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    ${({ fitContent }) =>
        fitContent
            ? css`
                  width: fit-content;
              `
            : css`
                  flex: 1;
              `}

    ${({ widthFix }) =>
        widthFix &&
        css`
            width: ${widthFix}px;
        `}
`

const ContainerDayWeek = styled.div`
    user-select: none;
    display: flex;
    flex-wrap: nowrap;
`

const BoxDayWeek = styled.div<{ active?: boolean }>`
    width: 20px;
    height: 20px;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 2px;
    &:last-child {
        margin-right: 0;
    }
    border: 1px solid #666;
    ${({ active }) =>
        active &&
        css`
            background-color: #666;
            border: 1px solid #666;
            color: #fff;
        `}
`
const Row = styled.div`
    user-select: none;
    display: flex;
`

const ContainerHours = styled.div`
    margin-bottom: 8px;
    align-self: center;
    font-size: 12px;
`

const ContainerValue = styled.div`
    width: fit-content;
    margin-right: 30px;
    &:last-child {
        margin-right: 0;
    }
`
const LabelValue = styled.div`
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
`

const TextValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    margin-bottom: 4px;
`

const IconValue = styled(FontAwesomeIcon)`
    margin-top: 1px;
    font-size: 14px;
    margin-right: 8px;
`

const ContainerButton = styled.div``

const Button = styled.div<{ outline?: boolean }>`
    box-sizing: border-box;
    text-align: center;
    user-select: none;
    margin-bottom: 10px;
    margin-right: 65px;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 5px 20px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    cursor: pointer;

    ${({ outline }) =>
        outline &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.colors.primary};
        `}
    &:hover {
        opacity: 0.8;
    }
`

const Reason = styled.div`
    font-weight: 600;
`
const Status = styled.div<{ active?: boolean }>`
    font-weight: 600;
    text-transform: uppercase;
    font-size: 10px;
    color: ${({ active, theme }) => (active ? '#000' : theme.colors.errorMessage)};
`

export {
    ContainerButton,
    Button,
    Container,
    Column,
    Reason,
    ContainerDayWeek,
    BoxDayWeek,
    ContainerHours,
    ContainerValue,
    LabelValue,
    TextValue,
    Status,
    IconValue,
    Row,
}
