import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    overflow: auto;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: calc(100vh - 4.4rem);
    }
`

const Content = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: calc(100vh - 14rem);
    }
`

const EmptyMessage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 1rem;

    max-width: 43.75rem;
    height: 10rem;
    position: relative;
`

const EmptyMessageText = styled.div`
    font-weight: 400;
    text-align: justify;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 1.5rem;
    }

    span {
        color: ${({ theme }) => theme.colors.primary};
    }
`

const IconBackGround = styled(FontAwesomeIcon).attrs({
    icon: 'map',
})`
    height: 100%;
    width: 9rem !important;

    position: absolute;
    opacity: 0.3;
    color: #939393;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 14.063rem !important;
    }
`

const IconEdit = styled(FontAwesomeIcon).attrs({
    icon: 'edit',
})`
    color: ${({ theme }) => theme.colors.white};
`
const IconDelete = styled(FontAwesomeIcon).attrs({
    icon: 'trash-alt',
})`
    color: ${({ theme }) => theme.colors.white};
`

const InputContainer = styled.div`
    width: 100%;
`

const AttendanceRow = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1.5rem;
    background-color: #dddddd;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        flex-direction: row;
        //border: 2px solid;
        height: calc(100vh - 9.813rem);
    }
`

const AttendanceLeftContent = styled.div`
    padding: 1.25rem;
    //height: 100%;
    background-color: ${({ theme }) => theme.colors.white};

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 0.5rem;

    transition: 500ms;
    overflow: hidden;
    overflow-y: auto;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 25%;
    }
`

const ZoneListContent = styled.div`
    height: 8rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1;

    padding-top: 2rem;
`

const ZoneEmptyMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex: 1;
    padding: 4.625rem;

    font-size: 1rem;

    p {
        padding: 0;
        margin: 0;
    }

    span {
        color: ${({ theme }) => theme.colors.primary};
    }
`

const AttendanceRightContent = styled.div`
    /* width: 100%;
    height: 100%; */
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};

    /* button {
        margin: 1.25rem 0rem 0rem 1.25rem;
    } */
`

const ListItemContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
`

const ColorBox = styled.div<{ color?: string }>`
    background-color: ${({ color }) => color || '#fff'};
    width: 2rem;
    height: 2rem;
    border-radius: 0.3rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`

const TextItem = styled.div`
    font-size: 0.75rem;
    display: flex;
    flex: 1;
`

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`

const DeleteButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.danger)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.5rem;
    width: fit-content;
    &:hover {
        opacity: 0.8;
    }
`

const EditButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.5rem;
    width: fit-content;
    &:hover {
        opacity: 0.8;
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 0.5rem;
    padding: 1rem;
`

export {
    Container,
    Content,
    EmptyMessage,
    IconEdit,
    IconDelete,
    IconBackGround,
    EmptyMessageText,
    InputContainer,
    AttendanceRow,
    AttendanceLeftContent,
    AttendanceRightContent,
    ZoneListContent,
    ListItemContent,
    ColorBox,
    TextItem,
    Actions,
    DeleteButton,
    EditButton,
    ZoneEmptyMessage,
    ButtonRow,
}
