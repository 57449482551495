import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    background-color: #f1f1f1;
    height: 100%;
    padding: 1.875rem 1.25rem;
`

const Content = styled.div`
    background-color: #fff;
    border-radius: 0.5rem;
`
const Header = styled.div`
    padding: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const HeaderActions = styled.div``

const HeaderTitle = styled.h2`
    font-size: 1.5rem;
`

const TableContainer = styled.div`
    th,
    td {
        display: flex;
        justify-content: center;
    }
`

const RateContent = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    gap: 0.5rem;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
`
const IconContent = styled.div`
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`
const RateIcon = styled(FontAwesomeIcon)`
    font-size: 0.938rem;
`
const RateValue = styled.span`
    //width: 4rem;
`

export {
    Container,
    Content,
    IconContent,
    Header,
    HeaderActions,
    HeaderTitle,
    TableContainer,
    Row,
    RateContent,
    RateIcon,
    RateValue,
}
