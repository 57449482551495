import {
    faUser,
    faTimes,
    faChevronRight,
    faChartBar,
    faDollarSign,
    faMapMarkedAlt,
    faShoppingBag,
    faThumbsUp,
    faLock,
    faDoorOpen,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ContentContainer = styled.div<{ isActive?: boolean }>`
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0px;
    left: 0;
    transform: translateX(${({ isActive }) => (isActive ? 0 : '-600%')});
    transition: 400ms;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 9999;
`

export const UserRow = styled.div`
    border-bottom: 1px solid #c6c6c6;
    padding: 0px 20px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    width: 60%;
`

export const UserIcon = styled(FontAwesomeIcon).attrs({
    icon: faUser,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 20px;
`

export const UserName = styled.div`
    font-weight: 600;
    font-size: 18px;
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
`

export const MobileMenuSection = styled.div`
    border-bottom: 1px solid #c6c6c6;
    padding: 20px;
`

export const MenuSectionTitle = styled.div`
    font-size: 10px;
    text-transform: uppercase;
    color: #ababab;
    margin-bottom: 15px;
`

export const AreaItem = styled.div`
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #717171;
    font-size: 16px;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

export const ItemText = styled.div`
    width: 90%;
`

export const ExtranetItem = styled.div<{ isShown?: boolean }>`
    display: ${({ isShown }) => (isShown !== undefined ? (isShown ? 'flex' : 'none') : 'flex')};
    align-items: center;
    color: #717171;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

export const ExtranetIconContainer = styled.div`
    width: 40px;
    text-align: center;
`
export const ExtranetText = styled.div`
    font-size: 14px;
`

export const ChevronIcon = styled(FontAwesomeIcon).attrs({
    icon: faChevronRight,
})``

export const ExtranetIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-right: 15px;
`

export const ModerationIcon = styled(FontAwesomeIcon).attrs({
    icon: faThumbsUp,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const RouteIcon = styled(FontAwesomeIcon).attrs({
    icon: faMapMarkedAlt,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const ShoppingBag = styled(FontAwesomeIcon).attrs({
    icon: faShoppingBag,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const GraphIcon = styled(FontAwesomeIcon).attrs({
    icon: faChartBar,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const MoneyIcon = styled(FontAwesomeIcon).attrs({
    icon: faDollarSign,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const AccountIcon = styled(FontAwesomeIcon).attrs({
    icon: faUser,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const LockIcon = styled(FontAwesomeIcon).attrs({
    icon: faLock,
})`
    font-size: 20px;
    margin-right: 15px;
`

export const DoorIcon = styled(FontAwesomeIcon).attrs({
    icon: faDoorOpen,
})`
    font-size: 20px;
    margin-right: 15px;
`
