import { useState, useCallback, useMemo } from 'react'

import { faCalendarAlt, faConciergeBell, faClock, faRoute, faStreetView } from '@fortawesome/free-solid-svg-icons'

import TopTags from 'components/top-tags/top-tags'

import { useUI } from 'contexts'
import { useAuth, useDataFetching } from 'hooks'
import api from 'services/api'
import { OrdersScoreboard } from 'store/reducers/ordersInProgress'
import { RoutesScoreboard } from 'store/reducers/routes'
import { Mall, Store } from 'types'

import './page-top-row.styles.scss'
import {
    ButtonsContainer,
    NavigationItemContainer,
    NavigationItem,
    MobileSelectTab,
    NavigationIcon,
    Attention,
    ContainerControls,
} from './operation-top-row.styled'
type Control = 'scheduled' | 'in-production' | 'routes-in-progress' | 'routes-no-starting' | 'agents-in-base'

interface Props {
    agentsAvailable: number
    routesScoreboard: RoutesScoreboard
    ordersScoreboard: OrdersScoreboard
    agentsAvailableClick(): void
    onChangeTab?(tab: Control | string): void
}

const OperationTopRow: React.FC<Props> = ({
    agentsAvailable,
    ordersScoreboard,
    routesScoreboard,
    agentsAvailableClick,
    onChangeTab,
}) => {
    const { mall } = useAuth()
    const [controlActive, setControlActive] = useState<Control | string>('in-production')

    const tabOptions = useMemo(() => {
        return [
            {
                label: 'Agendados',
                value: 'scheduled',
                icon: faCalendarAlt,
                score: ordersScoreboard?.scheduled,
            },
            {
                label: 'Em Produção',
                value: 'in-production',
                icon: faConciergeBell,
                score: ordersScoreboard?.in_production,
            },
            {
                label: 'Rotas não iniciadas',
                value: 'routes-no-starting',
                icon: faClock,
                score: routesScoreboard?.accepted,
            },
            {
                label: 'Rotas em andamento',
                value: 'routes-in-progress',
                icon: faRoute,
                score: routesScoreboard?.in_progress,
            },
            {
                label: 'Localizar agentes da base',
                value: 'agents-in-base',
                icon: faStreetView,
                score: 0,
            },
        ]
    }, [ordersScoreboard, routesScoreboard])

    useDataFetching<Mall>(`/painel/mall/${mall.id}`, {
        onSuccess: data => {
            // setAutoRouting(data?.configs?.route_automatic?.status)
        },
    })

    const _setNavigaitonTab = useCallback(
        (tab: Control | string) => {
            setControlActive(tab)
            if (onChangeTab) {
                onChangeTab(tab)
            }
        },
        [onChangeTab]
    )

    return (
        <div className="top-row-container">
            {/* <div className="row-item controls">
                <a className="operation-row-link" rel="noreferrer" target="_blank" onClick={problemOrdersClick}>
                    <FontAwesomeIcon icon="exclamation-triangle" />
                    <div className="label">Pedidos com problemas</div>
                </a>
             
            </div> */}
            <div className="row-item tags">
                <ContainerControls>
                    <MobileSelectTab
                        value={controlActive}
                        onChange={({ target: { value } }) => _setNavigaitonTab(value)}
                    >
                        {tabOptions.map((item, i) => (
                            <option key={i} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </MobileSelectTab>
                    <ButtonsContainer className="container-buttons">
                        {tabOptions.map((item, i) => (
                            <NavigationItemContainer key={i} isActive={controlActive === item.value}>
                                <NavigationItem
                                    isActive={controlActive === item.value}
                                    onClick={() => _setNavigaitonTab(item.value)}
                                >
                                    <NavigationIcon isActive={controlActive === item.value} icon={item.icon} />
                                    {item.label}
                                </NavigationItem>
                                {!!item.score && <Attention>{item.score}</Attention>}
                            </NavigationItemContainer>
                        ))}
                    </ButtonsContainer>
                    <div className="right-column">
                        <TopTags
                            agentsAvailable={agentsAvailable}
                            agentsAvailableClick={agentsAvailableClick}
                            routesInProgress={routesScoreboard.in_progress}
                            routesFinished={routesScoreboard.finished}
                            ordersFinished={ordersScoreboard.finished}
                        />
                    </div>
                </ContainerControls>
            </div>
        </div>
    )
}

export default OperationTopRow
