import { ChipsContainer, ChipsLabel, ChipsRow, ChipsItem } from './chips-input.styles'

export interface Item {
    label: string
    value: string | number
}

interface Props {
    label?: string
    items: Item[] | undefined
    selectedItems: number[]
    onClickItem(value: string | number): void
}

const ChipsInput: React.FC<Props> = ({ label, items, selectedItems, onClickItem }) => {
    return (
        <ChipsContainer>
            {label && <ChipsLabel>{label}</ChipsLabel>}
            <ChipsRow>
                {items?.map(({ label, value }, index) => {
                    const isActive = selectedItems.some(chipsId => chipsId === value)

                    return (
                        <ChipsItem key={index} isActive={isActive} onClick={() => onClickItem(value)}>
                            {label}
                        </ChipsItem>
                    )
                })}
            </ChipsRow>
        </ChipsContainer>
    )
}

export default ChipsInput
