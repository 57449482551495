import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss'

const TextInput = props => {
    return (
        <div className="item-input">
            <label style={props.labelStyle}>{props.labelInput}</label>
            <input
                type="text"
                placeholder={props.placeholder}
                onChange={props.onChange}
                disabled={props.disabled}
                onKeyPress={props.onKeyPress}
            />
            {props.msgErro && (
                <div className="msg-erro" style={{ color: props.msgColor ? props.msgColor : '#000' }}>
                    <FontAwesomeIcon icon="times-circle" /> {props.msgErro}
                </div>
            )}
        </div>
    )
}

export default TextInput
