import { memo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import { formatDistance } from 'helpers'

import { Collect, MapImage } from './order-card.styled'
import './order-card.styles.scss'

const OrderCard = props => {
    return (
        <div className={`order-card-container ${props.isOnRouteOrders && 'route-added'}`}>
            <div
                className="order-card-content"
                style={{
                    backgroundColor: props.clicked ? 'rgba(255, 232, 162, 0.4)' : '',
                    borderLeftColor: props.status,
                }}
            >
                <div className="order-info">
                    <div className="title ">
                        <span className="id">#{props.orderNumber}</span> • {props.restaurantName}
                    </div>
                    <div className="title">
                        <span>CLIENTE:</span> {props.clientName}
                    </div>
                    <div className="title">
                        <span>VALOR:</span> R$ {props.orderValue}
                    </div>
                    <div className="title">
                        <span>PAGAMENTO:</span> {props.paymentName}
                    </div>
                    <div className="title">
                        <span>Distância:</span> {formatDistance(props.distance)}
                    </div>
                </div>

                <div className="client-info">
                    <div className="title">
                        <span>ENDEREÇO</span>
                    </div>
                    <div className="client-name">
                        {props.neighborhood} <br /> {props.street} <br /> {props.number}
                    </div>
                </div>

                {!!props.collectName && (
                    <Collect>
                        <MapImage />
                        <div className="collect">
                            Coleta: <span className="collect"> {props.collectName}</span>
                        </div>
                    </Collect>
                )}

                <div>
                    {props.deliveryForecast && (
                        <div className="order-info">
                            <div className="title">PREVISÃO DE ENTREGA: </div>
                            <div className="time-info">{`${format(props.deliveryForecast, 'DD/MM/YYYY')} às ${format(
                                props.deliveryForecast,
                                'HH:mm'
                            )}`}</div>
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {props.totalTime && (
                        <div className="order-info">
                            <div className="title">TEMPO TOTAL</div>
                            <div className="time-info">{props.totalTime}</div>
                        </div>
                    )}
                    {props.isReadyToDeliver && (
                        <div className="order-info" style={{ width: '50%' }}>
                            <div className="title">TEMPO DE PRONTO</div>
                            <div className="time-info">{props.timeReady}</div>
                        </div>
                    )}
                </div>

                <div className="buttons-container">
                    {props.leftButtonClick && (
                        <div className="button-item" onClick={props.leftButtonClick}>
                            <FontAwesomeIcon icon="arrow-left" /> {props.leftButtonLabel}
                        </div>
                    )}

                    <div className="button-item" onClick={props.detailClick}>
                        Detalhes
                    </div>

                    {props.rightButtonClick && (
                        <div className="button-item" onClick={props.rightButtonClick}>
                            {props.rightButtonLabel} <FontAwesomeIcon icon="arrow-right" />
                        </div>
                    )}

                    {props.onRouteCreation && !props.isOnRouteOrders ? (
                        <div className="button-item" onClick={props.onAddClick}>
                            Adicionar à rota <FontAwesomeIcon icon="plus" />
                        </div>
                    ) : props.isOnRouteOrders ? (
                        <div className="button-item" onClick={props.onAddClick}>
                            Adicionado à rota <FontAwesomeIcon icon="check" />
                        </div>
                    ) : props.isReadyToDeliver ? (
                        <div className="button-item" onClick={props.routeClick}>
                            Roteirizar a partir deste <FontAwesomeIcon icon="paper-plane" />
                        </div>
                    ) : props.changeStatusReadyToDeliver ? (
                        <div className="button-item" onClick={props.changeStatusReadyToDeliver}>
                            Pronto para coleta <FontAwesomeIcon icon="arrow-right" />
                        </div>
                    ) : props.changeStatusProduction ? (
                        <div className="button-item" onClick={props.changeStatusProduction}>
                            Iniciar Produção <FontAwesomeIcon icon="arrow-right" />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default memo(OrderCard)
