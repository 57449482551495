import { ChartData } from 'chart.js'

import { ChartObject } from 'types'

export function getStoresChart(store_performance: ChartData<any>): ChartObject {
    return {
        data: store_performance,
        // callbacks: {
        //     label: tooltipItem => {
        //         const item = store_performance[tooltipItem.dataIndex as number]
        //         return `${item.Y} pedidos - ${item.percentage?.toString().replace('.', ',')}%`
        //     },
        // },
    }
}
