import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const OrderDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
`

export const OrderDetailTitleContainer = styled.div`
    background-color: #f7f7f7;
    padding: 20px;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
    }
`

export const OrderTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const OrderSubtitle = styled.div`
    color: #888;
    text-transform: uppercase;
    b {
        color: ${({ theme }) => theme.colors.black};
    }
`

export const ScrollBox = styled.div`
    height: 500px;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.white};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        height: unset;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        /* for Firefox */
        min-height: 0;
    }
`

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 16px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const ConfirmButton = styled.div`
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const OrderHeader = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 30px 40px;
    }
`

export const ChannelIcon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #888;
    overflow: hidden;
    margin-right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 70px;
        height: 70px;
    }
`

export const CustomerInfo = styled.div`
    flex: 1;
    margin-right: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 40%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 18px;
    }
`

export const OrderPrice = styled.div`
    flex-basis: 25%;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 18px;
    }
`

export const CustomerId = styled.div`
    color: #666;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 22px;
    }
`

export const CustomerName = styled.div`
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 22px;
    }
`

export const PriceValue = styled.div`
    font-weight: bold;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 22px;
    }
`

export const PaymentMethod = styled.div`
    color: #666;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 22px;
    }
`

export const Address = styled.div`
    flex-basis: 100%;
    margin-top: 25px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 22px;
    }
`

export const AddressTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`

export const AddressValue = styled.div`
    color: #666;
`

export const OrderItensContainer = styled.div`
    margin: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin: 30px 40px;
        margin-bottom: 0;
    }
`

export const ItensTitle = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 16px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 22px;
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
`

export const FinalValuesContainer = styled.div`
    margin: 20px;
    margin-top: 20px;
    text-align: right;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin: 30px 40px;
        margin-top: 20px;
    }
`

export const FinalValueItem = styled.div`
    margin-bottom: 10px;
    &:last-child {
        font-size: 18px;
        font-weight: bold;
    }
`
