import colors from '../../themes/colors'
import Spinner from '../spinner/spinner'

import './style.scss'

const ModalLoading = props => {
    if (!props.visible) return <div />

    return (
        <div className="modal-loading" style={{ position: props.absolute ? 'absolute' : 'fixed' }}>
            <div className="modal-loading-bg" />
            <div className="modal-loading-box">
                <Spinner size={props.size} color={props.spinnerColor || colors.primary_color} visible={true} />
            </div>
        </div>
    )
}

export default ModalLoading
