import styled, { css } from 'styled-components'

type Props = {
    align?: 'center' | 'flex-end' | 'flex-start'
    justify?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly'
    maxWidth?: boolean
    maxHeight?: boolean
    flex?: number
    marginBottom?: number
    marginTop?: number
    marginLeft?: number
    marginRight?: number
}

const ContainerRow = styled.div<Props>`
    display: flex;
    align-items: ${({ align }) => align || 'flex-start'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    width: ${({ maxWidth }) => (maxWidth ? '100%' : 'auto')};
    height: ${({ maxHeight }) => (maxHeight ? '100%' : 'auto')};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
    margin-top: ${({ marginTop }) => marginTop || 0}px;
    margin-left: ${({ marginLeft }) => marginLeft || 0}px;
    margin-right: ${({ marginRight }) => marginRight || 0}px;
    ${({ flex }) =>
        flex &&
        css`
            flex: ${flex};
        `}
`

const ContainerColumn = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align || 'flex-start'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    height: ${({ maxHeight }) => (maxHeight ? '100%' : 'auto')};
    width: ${({ maxWidth }) => (maxWidth ? '100%' : 'auto')};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
    margin-top: ${({ marginTop }) => marginTop || 0}px;
    margin-left: ${({ marginLeft }) => marginLeft || 0}px;
    margin-right: ${({ marginRight }) => marginRight || 0}px;
    ${({ flex }) =>
        flex &&
        css`
            flex: ${flex};
        `}
`

export { ContainerColumn, ContainerRow }
