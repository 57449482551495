import { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

export default class PasswordInputForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordMask: true,
        }
    }

    togglePasswordVisibility = () => {
        this.setState({ passwordMask: !this.state.passwordMask })
    }

    render() {
        return (
            <div className="item-input-form">
                <label style={{ color: this.props.colorLabel }}>{this.props.label}</label>
                <input
                    type={this.state.passwordMask ? 'password' : 'text'}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onKeyPress={this.props.onKeyPress}
                    disabled={this.props.disabled}
                />
                {this.props.msgErro && (
                    <div className="msg-erro" style={{ color: this.props.msgColor ? this.props.msgColor : '#000' }}>
                        <FontAwesomeIcon icon="times-circle" color="#EFBE27" /> {this.props.msgErro}
                    </div>
                )}
                <div className="password-trigger" onClick={() => this.togglePasswordVisibility()}>
                    <FontAwesomeIcon icon={this.state.passwordMask ? 'eye' : 'eye-slash'} />
                </div>
            </div>
        )
    }
}
