import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatCurrency } from 'helpers'
import { Rate, RateValues } from 'types'

import './style.scss'

interface Props {
    rate: Rate
    deleteClick(): void
    editClick(): void
}

const RatesListingTableRow: React.FC<Props> = ({ rate, deleteClick, editClick }) => {
    const { store, agent } = rate.values as RateValues

    return (
        <div className="rates-table-row-item">
            <div className="rates-table-row-content id">{rate.id}</div>
            <div className="rates-table-row-content label">{rate.label}</div>

            <div className="rates-table-row-content delivery">
                {agent?.bike != null && !store?.bike != null ? (
                    <>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="store" color="black" />
                            </div>
                            <span>{store.bike ? formatCurrency(store.bike) : 'R$ 0,00'}</span>
                        </div>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="bicycle" color="black" />
                            </div>
                            <span>{agent.bike ? formatCurrency(agent.bike) : 'R$ 0,00'}</span>
                        </div>
                    </>
                ) : (
                    <span>Não opera neste turno</span>
                )}
            </div>
            <div className="rates-table-row-content delivery">
                {agent?.moto != null && !store?.moto != null ? (
                    <>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="store" color="black" />
                            </div>
                            <span>{store.moto ? formatCurrency(store?.moto) : 'R$ 0,00'}</span>
                        </div>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="motorcycle" color="black" />
                            </div>
                            <span>{agent.moto ? formatCurrency(agent?.moto) : 'R$ 0,00'}</span>
                        </div>
                    </>
                ) : (
                    <span>Não opera neste turno</span>
                )}
            </div>
            <div className="rates-table-row-content delivery">
                {agent?.car != null && !store?.car != null ? (
                    <>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="store" color="black" />
                            </div>
                            <span>{store.car ? formatCurrency(store?.car) : 'R$ 0,00'}</span>
                        </div>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="car" color="black" />
                            </div>
                            <span>{agent.car ? formatCurrency(agent?.car) : 'R$ 0,00'}</span>
                        </div>
                    </>
                ) : (
                    <span>Não opera neste turno</span>
                )}
            </div>

            <div className="rates-table-row-content delivery">
                {agent?.van != null && !store?.van != null ? (
                    <>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="store" color="black" />
                            </div>
                            <span>{store.van ? formatCurrency(store?.van) : 'R$ 0,00'}</span>
                        </div>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="shuttle-van" color="black" />
                            </div>
                            <span>{agent.van ? formatCurrency(agent?.van) : 'R$ 0,00'}</span>
                        </div>
                    </>
                ) : (
                    <span>Não opera neste turno</span>
                )}
            </div>
            <div className="rates-table-row-content delivery">
                {agent?.truck != null && !store?.truck != null ? (
                    <>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="store" color="black" />
                            </div>
                            <span>{store.truck ? formatCurrency(store?.truck) : 'R$ 0,00'}</span>
                        </div>
                        <div className="icon-value">
                            <div className="icon">
                                <FontAwesomeIcon icon="truck" color="black" />
                            </div>
                            <span>{agent.truck ? formatCurrency(agent?.truck) : 'R$ 0,00'}</span>
                        </div>
                    </>
                ) : (
                    <span>Não opera neste turno</span>
                )}
            </div>
            <div className="rates-table-row-content status">{rate.status ? 'Ativo' : 'Inativo'}</div>
            <div className="rates-table-row-content button-row">
                <div className="button-item outline" onClick={deleteClick}>
                    Remover
                </div>
                <div className="button-item" onClick={editClick}>
                    Editar
                </div>
            </div>
        </div>
    )
}

export default RatesListingTableRow
