import { createActions, createReducer } from 'reduxsauce'

import { IntegrationReducerTypes, SetAction, IntegrationReducerActions, IntegrationType } from './integrations.types'

const INITIAL_STATE: IntegrationType = { token: null }

export const {
    Creators: { setIntegration, updateIntegration, removeIntegration },
} = createActions<IntegrationReducerActions, IntegrationReducerActions>({
    setIntegration: ['payload'],
    updateIntegration: ['payload'],
    removeIntegration: [''],
})

function set(state: IntegrationType, action: SetAction): IntegrationType {
    return action.payload
}

function update(state: IntegrationType, action: SetAction): IntegrationType {
    return { ...state, ...action.payload }
}
function remove(): IntegrationType {
    return INITIAL_STATE
}

const reducers = createReducer(INITIAL_STATE, {
    [IntegrationReducerTypes.SET_INTEGRATION]: set,
    [IntegrationReducerTypes.UPDATE_INTEGRATION]: update,
    [IntegrationReducerTypes.REMOVE_INTEGRATION]: remove,
})

export default reducers
