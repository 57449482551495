import { useCallback, useState } from 'react'

import { DebitNotesHeader } from 'components/_reports'
import ModalLoading from 'components/modal-loading'

import api from 'services/api'
import { IFormDebitNotes } from 'types'

import { DebitNotesContent, DebitNotesPageContainer } from './debit-notes-page.styles'

export default function DebitNotesPage(): JSX.Element {
    const [loading, setLoading] = useState(false)

    const _getReports = useCallback(async (value: IFormDebitNotes) => {
        const body = {
            download: value.download,
            mall_id: value.mall_id,
            service: value.service,
            store_id: value.store_id,
            number: value.number,
            percent: value.percent,
            period: {
                start_date: value.start_date,
                end_date: value.end_date,
            },
            supplier: {
                cnpj: value.supplier_cnpj,
                social_reason: value.supplier_social_reason,
                address: value.supplier_address,
            },
            payer: {
                cnpj: value.payer_cnpj,
                social_reason: value.payer_social_reason,
                address: value.payer_address,
            },
        }
        setLoading(true)
        try {
            const response = await api.post<any, any>('/painel/report/debit-note', body, {
                responseType: 'blob',
            })

            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url

            const fileName = `${value.number}-${value.store_name}.${value.download}`

            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])
    return (
        <DebitNotesPageContainer>
            <DebitNotesHeader title="Notas de débito" onFilterData={_getReports} disabled={loading} />
            <DebitNotesContent></DebitNotesContent>
            <ModalLoading visible={loading} />
        </DebitNotesPageContainer>
    )
}
