import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import DropzoneInput from 'components/dropzone-input/dropzone-input'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextArea from 'components/text-area'
import TextInputForm from 'components/text-input-form'

import { getFormInputError, showErrors } from 'helpers'
import { useAxiosRequest } from 'hooks'
import api from 'services/api'
import { ApiPhoto, PagedList, StoreCategory, StoreType } from 'types'

const StoreCategoryForm: React.FC<RouteComponentProps<{ store_category_id: string }>> = ({
    history,
    match: { params },
}) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const { loading } = useAxiosRequest<StoreCategory>({
        api,
        url: `/painel/store-category/${params.store_category_id}`,
        executeOnStart: params.store_category_id ? true : false,
        onComplete: ({ data }) => {
            const { id } = data

            setValues({
                ...values,
                ...data,
                id: id.toString(),
                store_type_id: data.store_type.id.toString(),
            })
        },
        onError: onRequestError,
    })

    const storeTypes = useAxiosRequest<PagedList<StoreType>>({
        api,
        url: '/painel/store-types',
        onError: onRequestError,
    })

    const { isSubmitting, errors, getFieldProps, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                id: '',
                name: '',
                description: '',
                store_type_id: '',
                icon: { id: '' } as ApiPhoto | File,
                banner: { id: '' } as ApiPhoto | File,
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Título da categoria é obrigatório'),
                store_type_id: Yup.string().required('Vertical de loja é obrigatória'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    const formData = new FormData()

                    formData.set('name', values.name)
                    formData.set('description', values.description)
                    formData.set('store_type_id', values.store_type_id)

                    formData.set('status', values.status.toString())

                    if (values.id) {
                        formData.set('_method', 'PUT')
                        await api.post(`/painel/store-category/${values.id}`, formData)
                        if (values.icon instanceof File) {
                            const iconData = new FormData()
                            iconData.set('icon', values.icon as File)
                            await api.post(`/painel/store-category/${values.id}/icon`, iconData)
                        }
                        if (values.banner instanceof File) {
                            const bannerData = new FormData()
                            bannerData.set('banner', values.banner as File)
                            await api.post(`/painel/store-category/${values.id}/banner`, bannerData)
                        }
                    } else {
                        const { data } = await api.post('/painel/store-category', formData)
                        if (values.icon instanceof File) {
                            const iconData = new FormData()
                            iconData.set('icon', values.icon as File)
                            await api.post(`/painel/store-category/${data.id}/icon`, iconData)
                        }
                        if (values.banner instanceof File) {
                            const bannerData = new FormData()
                            bannerData.set('banner', values.banner as File)
                            await api.post(`/painel/store-category/${data.id}/banner`, bannerData)
                        }
                    }

                    setModalMessageData({
                        title: 'Sucesso!',
                        message: `Vertical de loja ${values.id ? 'editada' : 'criada'} com sucesso!`,
                        onClose: () => {
                            resetForm()
                            history.push('/marketplace/gestao-marketplace/categorias-loja')
                        },
                    })

                    modalMessage.current?.openModal()
                } catch (error) {
                    onRequestError(error)
                } finally {
                    setSubmitting(false)
                }
            },
        })

    return (
        <div>
            <RowTitle
                title={params.store_category_id ? 'Editar categoria de loja' : 'Criar categoria de loja'}
                backClick={() => history.push('/marketplace/gestao-marketplace/categorias-loja')}
            />

            <ModalLoading visible={isSubmitting || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="marketplace-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Título*"
                        {...getFieldProps('name')}
                        msgErro={getFormInputError('name', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <TextArea
                        label="Descrição (opcional)"
                        {...getFieldProps('description')}
                        msgErro={getFormInputError('description', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <SelectInputForm
                        label="Vertical de loja*"
                        data={storeTypes.data?.items.map(item => ({ name: item.name, value: item.id }))}
                        {...getFieldProps('store_type_id')}
                        msgErro={getFormInputError('store_type_id', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <div className="inputs-row">
                        <DropzoneInput
                            label="Ícone da categoria"
                            onChange={(file: File) => setFieldValue('icon', file)}
                            preview={values.icon}
                            width={200}
                        />
                        <div style={{ margin: '0 15px' }}></div>
                        <DropzoneInput
                            label="Banner da categoria"
                            onChange={(file: File) => setFieldValue('banner', file)}
                            preview={values.banner}
                            width={350}
                        />
                    </div>
                </div>

                <div className="inputs-row col-2">
                    <div className="radio-group">
                        <label>Status</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="true-status"
                                label="Ativa"
                                checked={Boolean(values.status)}
                                onClick={() => setFieldValue('status', 1)}
                            />

                            <InputRadio
                                id="false-status"
                                label="Inativa"
                                checked={!values.status}
                                onClick={() => setFieldValue('status', 0)}
                            />
                        </div>
                    </div>
                </div>

                <ButtonForm type="submit" buttonText="Salvar" style={{ marginTop: 30 }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default StoreCategoryForm
