import {
    CheckboxContainer,
    CheckboxSubtitle,
    CheckboxText,
    CheckboxTitle,
    CircleContainer,
    InnerCircle,
} from './checkbox.styles'

interface Props {
    checkboxTitle?: string
    checkboxSubtitle?: string
    isSquared?: boolean
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>
    className?: string
}

const Checkbox: React.FC<Props> = ({ checkboxTitle, checkboxSubtitle, isSquared, inputProps, className }) => {
    return (
        <label className={className} htmlFor={inputProps?.id}>
            <input id={inputProps?.id} type="checkbox" {...inputProps} style={{ display: 'none' }} />

            <CheckboxContainer className="check-box">
                <CircleContainer className="box" isSelected={!!inputProps?.value} isSquared={isSquared}>
                    <InnerCircle isSquared={isSquared} isSelected={!!inputProps?.value} />
                </CircleContainer>
                <CheckboxText isSelected={!!inputProps?.value}>
                    {checkboxTitle && <CheckboxTitle className="checkbox-title">{checkboxTitle}</CheckboxTitle>}
                    {checkboxSubtitle && (
                        <CheckboxSubtitle className="checkbox-subtitle">{checkboxSubtitle}</CheckboxSubtitle>
                    )}
                </CheckboxText>
            </CheckboxContainer>
        </label>
    )
}

export default Checkbox
