import { useRef, useReducer, useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import Echo from 'laravel-echo'
import { SocketIoConnector } from 'laravel-echo/dist/connector'
import { Socket } from 'socket.io'

import { echoConnection } from 'services/api'
import { Channel } from 'types/channel'

interface Event<T> {
    name: string
    callback(data: T): void
}
interface echoConnectionProps<T> {
    channelName?: string | null
    enable?: boolean
    events: Event<T>[]
    alternativeConnection?: Echo
    onRestart?(): void
}

type ActionType = 'ADD' | 'UPDATE' | 'REMOVE' | 'SET'

interface Action {
    type: ActionType
    payload: any
}

const initialState: any[] = []

function reducer(state: any[], action: Action) {
    let data = [...state]
    let indexRoute = -1
    switch (action.type) {
        case 'ADD':
            indexRoute = data.findIndex(item => item.id === action.payload?.id)
            if (indexRoute === -1) {
                data.unshift(action.payload)
            }
            break
        case 'UPDATE':
            indexRoute = data.findIndex(item => item.id === action.payload?.id)

            if (indexRoute >= 0) {
                const oldData = { ...data[indexRoute] }
                data[indexRoute] = { ...oldData, ...action.payload }
            }
            break
        case 'REMOVE':
            data = data.filter(item => item.id !== action.payload?.id)
            break
        case 'SET':
            data = action.payload
            break
    }
    return data
}

function useEchoConnection<S, T>({
    channelName,
    enable = true,
    events,
    alternativeConnection,
    onRestart,
}: echoConnectionProps<T>) {
    const token = useSelector(state => state.auth?.user?.token)

    const echoRef = useRef<Echo>()
    const enableRef = useRef<boolean>()
    const channelRef = useRef<Channel>()
    const channelNameRef = useRef<string>()
    const disconnectedRef = useRef<boolean>(false)

    const oldTokenRef = useRef<string>()
    const oldAlternativeConnectionRef = useRef<Echo>()

    const eventsRef = useRef<Event<T>[]>()
    const [data, dispatchData] = useReducer(reducer, initialState)

    const [connected, setConnected] = useState(true)

    const _listenEvents = useCallback((name: string | undefined, channel: Channel) => {
        if (channel) {
            eventsRef.current?.forEach(event => {
                const eventName = `${event.name}`
                console.log(` |-${eventName}`)

                channel.listen(eventName, ({ data }: { data: any }) => {
                    console.log(`dispatch-event: ${name}${eventName}`)
                    event.callback(data)
                })
            })
        }
    }, [])

    const _stopEvents = useCallback(() => {
        const channel = channelRef.current
        if (channel) {
            eventsRef.current?.forEach(event => {
                const eventName = `${event.name}`
                channel.stopListening(eventName)
            })
        }
    }, [])

    useEffect(() => {
        if (token) {
            const oldToken = oldTokenRef.current
            const oldAaternativeConnection = oldAlternativeConnectionRef.current

            if (oldToken !== token || oldAaternativeConnection !== alternativeConnection) {
                oldTokenRef.current = token
                oldAlternativeConnectionRef.current = alternativeConnection

                const connection = alternativeConnection ? alternativeConnection : echoConnection()

                const connector = connection.connector as SocketIoConnector
                const socket = connector.socket as Socket

                socket.on('connect', () => {
                    setConnected(true)
                    console.log(channelNameRef.current, ': connected')
                })
                socket.on('disconnect', () => {
                    setConnected(false)
                    disconnectedRef.current = true
                    console.log(channelNameRef.current, ': disconnected')
                })

                echoRef.current = connection
            }
        } else {
            echoRef.current?.disconnect()
        }
        return () => {
            echoRef.current?.disconnect()
        }
    }, [alternativeConnection, token])

    useEffect(() => {
        const _onFocus = () => {
            if (!document.hidden) {
                if (disconnectedRef.current === true) {
                    disconnectedRef.current = false
                    if (onRestart) {
                        onRestart()
                    }
                    console.log(channelNameRef.current, ': estou focado')
                    echoRef.current?.connect()
                }
            }
        }
        document.addEventListener('visibilitychange', _onFocus)

        return () => {
            document.removeEventListener('visibilitychange', _onFocus)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let isLeave = false
        const echo = echoRef.current
        channelNameRef.current = channelName || undefined
        enableRef.current = enable
        if (echo) {
            echo.disconnect()
            if (enable) {
                if (channelName) {
                    echo.connect()
                    console.log(`listening to ${channelName}`)
                    const channel = echo.private(channelName)
                    channelRef.current = channel
                    _listenEvents(channelName, channel)
                }
            } else {
                isLeave = true
                if (channelName) {
                    _stopEvents()
                    console.log(`leave to ${channelName}`)
                    echo.leave(channelName)
                }
                echo.disconnect()
            }
        }

        return () => {
            if (echo && enable && !isLeave) {
                if (channelName) {
                    _stopEvents()
                    console.log(`leave to ${channelName}`)
                    echo.leave(channelName)
                }
                echo.disconnect()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enable, channelName])

    useEffect(() => {
        eventsRef.current = events
        const channel = channelRef.current
        const isEnable = enableRef.current
        if (channel && isEnable) {
            const name = channelNameRef.current
            _listenEvents(name, channel)
        }

        return () => {
            if (events && channel) {
                events.forEach(e => {
                    channel.stopListening(e.name)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    return { data: data as S[], dispatchData, connected }
}

export { useEchoConnection }
