import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { formatPhone, getStatusOrder } from 'helpers'
import { useAuth } from 'hooks'
import { Order, Route } from 'types'

import {
    BikeIcon,
    BadgeIcon,
    Contaienr,
    ContainerAvatar,
    ContainerBadge,
    Avatar,
    OrderItem,
    ContainerInfo,
    RejectButton,
    DetailIcon,
    RiderName,
    RiderPhone,
    ContainerButtons,
    ContainerOrders,
    DetailsButton,
} from './item-problem-card.styled'

interface ItemProblemCardProps {
    route?: Route
    order?: Order
    onEditClick?(order: Order): void
}

interface IData {
    id: number
    agentName: string
    agentImage: string
    agentType: 'moto' | 'bike' | 'car' | 'van' | 'truck' | null
    agentPhone: string
    orders?: Order[]
}

const ItemProblemCard: React.FC<ItemProblemCardProps> = ({ order, route, onEditClick }) => {
    const { basePath } = useAuth()
    const history = useHistory()

    const data = useMemo<IData | null>(() => {
        if (order) {
            return {
                id: order.id,
                agentName: order.biker?.first_name + (order.biker?.last_name || ''),
                agentImage: order.biker?.avatar,
                agentType: order.biker?.type_name,
                agentPhone: order.biker?.cellphone,
                orders: [order],
            }
        }

        if (route) {
            return {
                id: route.id,
                agentName: route?.biker
                    ? route.biker.first_name + (route.biker.last_name || '')
                    : 'Aguardando entregador',
                agentImage: route.biker
                    ? route.biker.avatar
                    : 'https://firebasestorage.googleapis.com/v0/b/logaroo-agente.appspot.com/o/placeholder.jpeg?alt=media&token=f3adb19e-d888-4e95-9f2a-cba09633c6d5',
                agentType: route.biker?.type_name,
                agentPhone: route.biker?.cellphone,
                orders: route.orders,
            }
        }

        return null
    }, [order, route])

    if (!data) {
        return null
    }
    return (
        <Contaienr>
            <ContainerAvatar>
                {data.agentType !== null && (
                    <ContainerBadge>{data.agentType === 'moto' ? <BadgeIcon /> : <BikeIcon />}</ContainerBadge>
                )}
                <Avatar src={data.agentImage} />
            </ContainerAvatar>
            <ContainerInfo>
                <RiderName>{data.agentName}</RiderName>
                <RiderPhone>{data.agentPhone && formatPhone(data.agentPhone)}</RiderPhone>
                <ContainerOrders>
                    {data.orders.map((item, i) => (
                        <OrderItem key={i} onClick={() => history.push(`${basePath}/detalhes-pedido/${item.id}`)}>
                            #{item.id}
                            <div className="merchant">{item.merchant.name}</div>
                            <div className="status">{getStatusOrder(item.status)}</div>
                        </OrderItem>
                    ))}
                </ContainerOrders>
            </ContainerInfo>
            <ContainerButtons>
                <DetailsButton
                    onClick={() => {
                        if (route) {
                            history.push(`${basePath}/detalhes-rota/${data.id}`)
                        } else {
                            if (onEditClick) {
                                onEditClick(order)
                            }
                        }
                    }}
                >
                    {route ? 'Ver Rota' : 'corrigir'}
                </DetailsButton>
                {!!order && (
                    <RejectButton onClick={() => history.push(`${basePath}/detalhes-pedido/${data.id}`)}>
                        <span>
                            <DetailIcon />
                        </span>
                        Ver pedido
                    </RejectButton>
                )}
            </ContainerButtons>
        </Contaienr>
    )
}

export { ItemProblemCard }
