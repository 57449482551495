import { useState, useEffect, useRef, useCallback, useContext, useLayoutEffect, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { getBounds } from 'geolib'
import * as Yup from 'yup'

import { MirrorMap, GlobalMapContext } from 'components/global-map'

import { useAuth } from 'hooks'

import ButtonForm from '../../components/button-form'
import { CircleFill } from '../../components/mapbox-view'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import TextInputMask from '../../components/text-input-mask'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { showErrors, getCircleCoordinates } from '../../helpers'
import api from '../../services/api'
import colors from '../../themes/colors'

import './style.scss'

const ConfigPerformanceTimes = ({ history }) => {
    const { basePath, mall } = useAuth()
    const { setMapBoxContentComponent, setFitBounds, setFitBoundsOptions } = useContext(GlobalMapContext)
    const modalMessageRef = useRef()

    const [loading, setLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState({
        title: '',
        message: '',
        textButton: null,
        onClose: null,
    })

    const validateObject = Yup.object().shape({
        on_time: Yup.string()
            .trim()
            .test('validate-time', 'Mínimo de 1 segundo', time => time && convertTimeToSeconds(time) >= 1),
        attention: Yup.string()
            .trim()
            .when('on_time', (on_time, schema) => {
                return schema.test({
                    test: attention => convertTimeToSeconds(attention) >= convertTimeToSeconds(on_time),
                    message: 'Tempo máx. deve ser maior que tempo máx. ideal',
                })
            }),
    })

    const { initialValues, errors, handleSubmit, isSubmitting, setFieldValue, setValues, touched, values } = useFormik({
        initialValues: {
            global: {
                on_time: '00:00',
                attention: '00:00',
                late: '00:00',
            },
            production: {
                on_time: '00:00',
                attention: '00:00',
                late: '00:00',
            },
            collect: {
                on_time: '00:00',
                attention: '00:00',
                late: '00:00',
            },
        },
        validationSchema: Yup.object().shape({
            global: validateObject,
            production: validateObject,
            collect: validateObject,
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                await api.put(`/painel/mall/${mall.id}`, {
                    configs: transformNestedObject(initialValues, values, convertTimeToSeconds),
                })

                setModalMessage({
                    title: 'Sucesso!',
                    message: 'Unidade editada com sucesso!',
                    textButton: 'Continuar',
                    onClose: () => history.push(`${basePath}/gestao-mall`),
                })

                modalMessageRef.current.openModal()
            } catch (error) {
                console.log({ error })

                setModalMessage({
                    title: 'Erro',
                    message: showErrors(error),
                })

                modalMessageRef.current.openModal()
            } finally {
                setSubmitting(false)
            }
        },
    })

    const getMall = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/mall/${mall.id}`)

            setValues(transformNestedObject(initialValues, data.configs, covertSecondsToTime))
        } catch (error) {
            setModalMessage({
                title: 'Erro',
                message: showErrors(error),
            })
            modalMessageRef.current.openModal()
        } finally {
            setLoading(false)
        }
    }, [mall, initialValues, setValues])

    useEffect(() => {
        getMall()
    }, [getMall])

    const covertSecondsToTime = totalSeconds => {
        const minutes = Math.floor(totalSeconds / 60) % 60
        const seconds = totalSeconds % 60

        return [minutes, seconds].map(value => (value < 10 ? `0${value}` : value)).join(':')
    }

    const convertTimeToSeconds = time => {
        return time.split(':').reduce((acc, time) => 60 * acc + Number(time), 0)
    }

    const calculateLateTime = time => {
        const seconds = convertTimeToSeconds(time)

        return covertSecondsToTime(seconds + 1)
    }

    const transformNestedObject = (initialObject, object, transformFunction) => {
        return Object.keys(initialObject).reduce(
            (acc, formKey) =>
                object[formKey]
                    ? {
                          ...acc,
                          [formKey]: {
                              ...initialObject[formKey],
                              ...Object.keys(object[formKey]).reduce(
                                  (acc, key) => ({
                                      ...acc,
                                      [key]: transformFunction(object[formKey][key]),
                                  }),
                                  {}
                              ),
                          },
                      }
                    : {
                          ...acc,
                          [formKey]: initialObject[formKey],
                      },
            {}
        )
    }

    const bounds = useMemo(() => {
        if (!mall?.address) {
            return null
        }
        const [coords] = getCircleCoordinates([mall.address?.lng, mall.address?.lat], mall.radius)
        if (coords?.length > 0) {
            const { minLat, minLng, maxLat, maxLng } = getBounds(
                coords.map(([lng, lat]) => ({ latitude: lat, longitude: lng }))
            )

            return [
                [minLng, minLat],
                [maxLng, maxLat],
            ]
        }

        return null
    }, [mall])

    useEffect(() => {
        if (bounds) {
            setFitBounds(bounds)
            setFitBoundsOptions({ maxZoom: 18, padding: 100 })
        }
    }, [bounds])

    const _renderMapContent = useCallback(() => {
        return (
            <>
                <CircleFill radius={mall.radius} center={[mall.address?.lng, mall.address?.lat]} />
            </>
        )
    }, [mall])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
    }, [_renderMapContent])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="config-performance-times-page-container">
            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessageRef}
                isActive={modalMessage.isActive}
                title={modalMessage.title}
                message={modalMessage.message}
                textButton={modalMessage.textButton}
                onClose={modalMessage.onClose}
            />

            <TopRowDefault
                title="Tempos de Performance"
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title">{mall.name}</div>

                        <small>
                            Os tempos de performance são fundamentais para que haja um reforço visual nas telas de Visão
                            Geral dos Pedidos e Roteirização da situação de determinado pedido, visando identificar de
                            forma prática se aquele pedido está dentro do prazo estimado para uma alta performance{' '}
                            <span style={{ color: colors.green, fontWeight: 'bold' }}>(Verde)</span>, se o mesmo está
                            prestes a atrasar{' '}
                            <span style={{ color: colors.yellow, fontWeight: 'bold' }}>(Amarelo)</span> ou se ele já se
                            encontra em atraso <span style={{ color: colors.red, fontWeight: 'bold' }}>(Vermelho)</span>
                            .
                        </small>

                        <div className="subtitle" style={{ marginTop: 30 }}>
                            Tempo global
                        </div>

                        <div className="inputs-container">
                            <TextInputMask
                                label="Tempo máx. p/ entrega ideal"
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.green} />}
                                onChange={({ target: { value } }) => setFieldValue('global.on_time', value)}
                                value={values.global.on_time}
                                msgErro={touched.global?.on_time && errors.global?.on_time}
                            />
                            <TextInputMask
                                label="Tempo máx. p/ entrega"
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.yellow} />}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('global.attention', value)
                                    setFieldValue('global.late', calculateLateTime(value))
                                }}
                                value={values.global.attention}
                                msgErro={touched.global?.attention && errors.global?.attention}
                            />
                            <TextInputMask
                                label="Entrega em atraso"
                                disabled
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.red} />}
                                onChange={({ target: { value } }) => setFieldValue('global.late', value)}
                                value={values.global.late}
                            />
                        </div>

                        <div className="subtitle">Tempo de Produção</div>

                        <div className="inputs-container">
                            <TextInputMask
                                label="Tempo máx. de preparo ideal"
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.green} />}
                                onChange={({ target: { value } }) => setFieldValue('production.on_time', value)}
                                value={values.production.on_time}
                                msgErro={touched.production?.on_time && errors.production?.on_time}
                            />
                            <TextInputMask
                                label="Tempo máx. de preparo"
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.yellow} />}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('production.attention', value)
                                    setFieldValue('production.late', calculateLateTime(value))
                                }}
                                value={values.production.attention}
                                msgErro={touched.production?.attention && errors.production?.attention}
                            />
                            <TextInputMask
                                label="Preparo em atraso"
                                disabled
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.red} />}
                                onChange={({ target: { value } }) => setFieldValue('production.late', value)}
                                value={values.production.late}
                            />
                        </div>

                        <div className="subtitle">Tempo de Coleta</div>

                        <div className="inputs-container">
                            <TextInputMask
                                label="Tempo máx. de coleta ideal"
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.green} />}
                                onChange={({ target: { value } }) => setFieldValue('collect.on_time', value)}
                                value={values.collect.on_time}
                                msgErro={touched.collect?.on_time && errors.collect?.on_time}
                            />
                            <TextInputMask
                                label="Tempo máx. de coleta"
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.yellow} />}
                                onChange={({ target: { value } }) => {
                                    setFieldValue('collect.attention', value)
                                    setFieldValue('collect.late', calculateLateTime(value))
                                }}
                                value={values.collect.attention}
                                msgErro={touched.collect?.attention && errors.collect?.attention}
                            />
                            <TextInputMask
                                label="Coleta em atraso"
                                disabled
                                mask="99:99"
                                placeholder="mm:ss"
                                rightInsideIcon={<FontAwesomeIcon icon="stopwatch" color={colors.red} />}
                                onChange={({ target: { value } }) => setFieldValue('collect.late', value)}
                                value={values.collect.late}
                            />
                        </div>

                        <ButtonForm
                            type="submit"
                            buttonText="Salvar"
                            onClick={handleSubmit}
                            style={{ width: '100%', marginBottom: 100 }}
                        />
                    </div>

                    <div className="right-column">
                        <MirrorMap />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigPerformanceTimes
