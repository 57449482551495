import styled, { css } from 'styled-components'

import { DefaultButton } from 'components/default-button/default-button'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.gray};
    padding-top: 1rem;
    padding-right: 1rem;

    gap: 0.75rem;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    padding: 1.25rem;

    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.white};
`

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1.25rem;
    > * {
        margin-bottom: 0;
    }

    > button {
        align-self: flex-end;
    }
`

const StatusText = styled.div<{ isActive?: boolean }>`
    font-size: 0.75rem;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.success)};
`

const SearchIcon = styled(LogarooIcon).attrs({
    icon: 'search',
})`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
`

const HeaderTitle = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`

const SalesPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    padding: 1.25rem;

    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.white};
`

const SalesPanelTitle = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textLight};
`

const SalesPanelContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
`

const SalesPanelItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const SalesPanelItemTitle = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textLight};
`

const SalesPanelItemValue = styled.div<{ variant?: string }>`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${({ theme, variant }) => (variant ? theme.colors[variant] : theme.colors.primary)};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`

const DateFilterActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1rem;
`

const ActionsButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`

const ActionButton = styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
`

const PeriodButton = styled(DefaultButton)<{ isSelected?: boolean }>`
    ${({ theme, isSelected }) =>
        isSelected &&
        css`
            background-color: ${theme.colors.primary};
            div {
                color: ${theme.colors.white};
            }
        `}
`

export {
    Container,
    Content,
    Header,
    HeaderTitle,
    FilterContainer,
    SearchIcon,
    Row,
    SalesPanelContainer,
    SalesPanelContent,
    SalesPanelTitle,
    SalesPanelItem,
    SalesPanelItemTitle,
    SalesPanelItemValue,
    ActionsButtonRow,
    ActionButton,
    StatusText,
    PeriodButton,
    DateFilterActionsContainer,
}
