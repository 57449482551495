import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { getVehicleIcon, getVehicleName } from 'utils/get-vehicle-icon'

import avatarBase from '../../assets/images/avatar-base.png'
import { getBatteryIcon } from '../../helpers'

import './style.scss'
import { BikerPhoneContainer, MallIcon, Value, EyeIcon, VehicleIcon } from './deliverer-item.styled'

const DelivererItem = ({
    isSelected,
    onClick,
    photoPath = undefined,
    delivererName = undefined,
    checkpointName = undefined,
    vehicleName = undefined,
    deviceInfo = undefined,
    hasRoute = undefined,
}) => {
    const isItemActive = classNames('circle-inside', {
        active: isSelected,
    })

    return (
        <div
            className="deliverer-item"
            onClick={hasRoute ? null : onClick}
            style={{ cursor: hasRoute && 'not-allowed' }}
        >
            <div className="circle-outside">
                <div className={isItemActive} />
            </div>
            <div className="deliverer-photo">
                <img key={photoPath} src={photoPath || avatarBase} alt="" />
            </div>

            <div className="deliverer-info">
                <div className="deliverer-name">{delivererName}</div>
                <div className="vehicle">{vehicleName}</div>
                {!!checkpointName && (
                    <BikerPhoneContainer>
                        <MallIcon />
                        <Value>{checkpointName}</Value>
                    </BikerPhoneContainer>
                )}
                {!!deviceInfo?.type_name && (
                    <BikerPhoneContainer>
                        <VehicleIcon icon={getVehicleIcon(deviceInfo.type_name)} />
                        <Value>{getVehicleName(deviceInfo.type_name)}</Value>
                    </BikerPhoneContainer>
                )}
                {deviceInfo && (
                    <div className="device">
                        <div className="device-info-item">
                            <FontAwesomeIcon icon={getBatteryIcon(deviceInfo.battery_level)} />{' '}
                            {deviceInfo.battery_level}%
                        </div>
                        <div className="device-info-item">
                            <FontAwesomeIcon icon="signal" /> {deviceInfo.network_type}
                        </div>
                        <div className="device-info-item">
                            <FontAwesomeIcon icon="mobile-alt" /> {deviceInfo.app_version}
                        </div>
                        <div className="device-info-item">
                            <FontAwesomeIcon icon="robot" /> {deviceInfo.android_version}
                        </div>

                        {deviceInfo.strike > 0 && (
                            <div className="device-info-item strike">
                                <FontAwesomeIcon icon="bomb" /> {deviceInfo.strike}
                            </div>
                        )}
                        {['active', 'suspended'].includes(deviceInfo.app_status) && (
                            <div className="device-info-item">
                                <EyeIcon status={deviceInfo.app_status} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {hasRoute && <div className="has-route-container">Em rota</div>}
        </div>
    )
}

export default DelivererItem
