import {
    AlertButton,
    AlertContainer,
    AlertContentContainer,
    AlertDescription,
    AlertTitle,
    NetworkIcon,
} from './connection-status-alert.styles'

interface Props {
    title: string
    description: string
    buttonLabel: string
    onClickButton?(): void
}

function ConnectionStatusAlert({ title, description, buttonLabel, onClickButton }: Props) {
    return (
        <AlertContainer>
            <NetworkIcon />
            <AlertContentContainer>
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription>{description}</AlertDescription>

                <AlertButton onClick={onClickButton}>{buttonLabel}</AlertButton>
            </AlertContentContainer>
        </AlertContainer>
    )
}

export default ConnectionStatusAlert
