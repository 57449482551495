import React, { forwardRef, useRef, useImperativeHandle, useState, useCallback, memo, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { format, subDays, startOfMonth, startOfWeek, endOfMonth, subMonths } from 'date-fns'
import _ from 'lodash'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import { EmptyListMessage, InputItem } from 'components/_common'
import ButtonLoadMore from 'components/button-load-more/button-load-more'
import ModalLoading from 'components/modal-loading'

import { formatCurrency, getSalesChannelIcon } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Order, PagedList } from 'types'

import {
    Header,
    ContainerStatus,
    SeeOrder,
    Circle,
    ContainerActions,
    StatusName,
    Row,
    SearchContainer,
    StoreName,
    SearchInput,
    CustomerID,
    SearchIcon,
    ContainerItem,
    ChannelLogo,
    CustomerInfo,
    CustomerName,
    TotalPrice,
    Button,
} from './search-modal.styled'
type SearchModal = {
    show?(): void
    close?(): void
}

const SearchModal = memo(
    forwardRef<LateralModalBase, any>((props, ref) => {
        const history = useHistory()
        const searchRef = useRef<any>()

        const { mall, store } = useAuth()
        const options = useMemo(() => getOptions() || [], [])

        const [search, setSearch] = useState('')
        const [period, setPeriod] = useState(options[0]?.value || '')
        const [loading, setLoading] = useState<boolean>(false)
        const [hasSearch, setHasSearch] = useState<boolean>(false)
        const [orders, setOrders] = useState<PagedList<Order>>({
            totals: 0,
            current_page: 1,
            items: [],
        })
        const [orderGroups, setOrderGroups] = useState<{ date?: string; orders: Order[] }[]>([])

        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    lateralModalBaseRef.current?.show()

                    setTimeout(() => {
                        if (searchRef.current?.focus) {
                            searchRef.current.focus()
                        }
                    }, 500)
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [lateralModalBaseRef]
        )

        const _searchOrders = useCallback(
            async ({ current_page = 1, orders = { items: [] } } = {}) => {
                if (search) {
                    setLoading(true)
                    try {
                        const { data } = await api.get<PagedList<Order>>(
                            `/painel/dm-orders/${mall.id}/search${period}`,
                            {
                                params: { current_page, merchant_id: store?.id, searching: search },
                            }
                        )

                        setOrders({
                            ...data,
                            items: current_page > 1 ? [...orders.items, ...data.items] : data.items,
                        })
                        setHasSearch(true)
                    } catch (error) {
                        console.log({ error })
                    }

                    setLoading(false)
                } else {
                    setOrderGroups([])
                }
            },
            [period, search, store, mall]
        )

        useEffect(() => {
            if (orders.items) {
                const groups = _.chain(orders.items.map(e => ({ ...e, birth: formatDate(e.birth) })))
                    // Group the elements of Array based on `color` property
                    .groupBy('birth')
                    // `key` is group's name (color), `value` is the array of objects
                    .map((items, date) => ({ date, orders: items as Order[] }))
                    .value()

                setOrderGroups(groups)
            }
        }, [orders.items])

        const _goToDetails = useCallback(
            (order: Order) => {
                history.push(`?modal-order-id=${order.id}`)
            },
            [history]
        )

        const _onClose = useCallback(() => {
            setOrderGroups([])
            setSearch('')
            setPeriod(options[0]?.value || '')
        }, [options])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title="Buscar Pedido"
                onClose={_onClose}
                headerComponent={
                    <Row>
                        <InputItem
                            type="select"
                            options={options}
                            inputProps={{
                                placeholder: '',
                                value: period,
                                onChange: ({ target: { value } }) => {
                                    setPeriod(value)
                                },
                            }}
                        />
                        <SearchContainer>
                            <SearchInput
                                ref={searchRef}
                                id="search"
                                name="search"
                                type="text"
                                autoComplete="new-password"
                                placeholder="Buscar por Nº do Pedido, Cliente"
                                value={search}
                                onChange={({ target: { value } }) => {
                                    setSearch(value)
                                }}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        _searchOrders()
                                    }
                                }}
                            />
                            <SearchIcon />
                        </SearchContainer>
                        <Button onClick={_searchOrders}>Buscar</Button>
                    </Row>
                }
            >
                {orderGroups.map((item, i) => (
                    <React.Fragment key={i}>
                        <Header>{item.date}</Header>
                        {item.orders.map((o, i) => (
                            <ItemSearch key={i} order={o} onSeeDetailsClick={() => _goToDetails(o)} />
                        ))}
                    </React.Fragment>
                ))}

                {hasSearch && !orderGroups?.length && (
                    <EmptyListMessage icon="exclamation-triangle" message="Nenhum resultado encontrado" />
                )}
                <ButtonLoadMore
                    loading={loading}
                    visible={orders.totals > 0 && orders.items.length < orders.totals}
                    onClick={() => _searchOrders({ current_page: orders.current_page + 1, orders })}
                />

                <ModalLoading visible={loading} />
            </LateralModalBase>
        )
    })
)

type ItemSearchProp = {
    order: Order
    onSeeDetailsClick?(): void
}

const ItemSearch: React.FC<ItemSearchProp> = memo(({ order, onSeeDetailsClick }) => {
    return (
        <ContainerItem>
            <ChannelLogo src={getSalesChannelIcon(order)} />
            <CustomerInfo>
                <ContainerStatus>
                    <Circle color={getStatusColor(order.status)} />
                    <StatusName uppercase>{getStatusName(order.status)}</StatusName>
                </ContainerStatus>
                <CustomerID>
                    #{order.id} {order.reference_id && <> {` | #${order.reference_id}`}</>}
                </CustomerID>
                <StoreName>{order.merchant?.name}</StoreName>
                <CustomerName>{order.customer.name}</CustomerName>
                <TotalPrice>{formatCurrency(order.total_price)}</TotalPrice>
            </CustomerInfo>
            <ContainerActions>
                <SeeOrder onClick={onSeeDetailsClick}>Ver Pedido</SeeOrder>
            </ContainerActions>
        </ContainerItem>
    )
})

function getOptions() {
    const today = new Date()

    const f = date => format(date, 'YYYY-MM-DD')

    return [
        {
            label: 'Hoje',
            value: `?start_date=${f(today)}&end_date=${f(today)}`,
        },
        {
            label: 'Ontem',
            value: `?start_date=${f(subDays(today, 1))}&end_date=${f(subDays(today, 1))}`,
        },
        {
            label: 'Esta semana',
            value: `?start_date=${f(startOfWeek(today))}&end_date=${f(today)}`,
        },
        {
            label: 'Este mês',
            value: `?start_date=${f(startOfMonth(today))}&end_date=${f(today)}`,
        },
        {
            label: 'Mês anterior',
            value: `?start_date=${f(startOfMonth(subMonths(today, 1)))}&end_date=${f(endOfMonth(subMonths(today, 1)))}`,
        },
    ]
}

function getStatusName(status) {
    if (status === '0') {
        return 'Pedido foi cancelado'
    }

    if (status === '1') {
        return 'Pedido foi capturado'
    }

    if (status === '2') {
        return 'Pedido aguardando moderação'
    }

    if (status === '3') {
        return 'Pedido em produção'
    }

    if (status === '4') {
        return 'Pedido pronto p/ coleta'
    }

    if (status === '5') {
        return 'Pedido coletado'
    }

    if (status === '6') {
        return 'Pedido saiu para entrega'
    }

    if (status === '7') {
        return 'Pedido chegou ao cliente'
    }

    if (status === '8') {
        return 'Pedido entregue ao cliente'
    }

    if (status === '9') {
        return 'Comprovante de pagamento entregue ao lojista'
    }

    if (['10', '10B', '10C', '10D', '10E', '10F', '10G', '10H'].includes(status)) {
        return 'Problema'
    }

    return ''
}

function getStatusColor(status) {
    if (['1', '2', '3', '4'].includes(status)) {
        return '#F1C40F'
    }
    if (['5', '6', '7', '8'].includes(status)) {
        return '#FF8E21'
    }
    if (['9', '11'].includes(status)) {
        return '#04B42D'
    }
    if (['0', '10', '10B', '10C', '10D', '10E', '10F', '10G', '10H'].includes(status)) {
        return '#B3050D'
    }
    return '#999999'
}

function formatDate(d: string) {
    const [date] = d.split(' ')

    const t = new Date()
    const tr = new Date()
    const y = new Date()
    tr.setDate(t.getDate() + 1)
    y.setDate(t.getDate() - 1)

    const today = format(t, 'YYYY-MM-DD')
    const yesterday = format(y, 'YYYY-MM-DD')
    const tomorrow = format(tr, 'YYYY-MM-DD')

    if (today === date) {
        return 'Hoje'
    }

    if (yesterday === date) {
        return 'Ontem'
    }

    if (tomorrow === date) {
        return 'Amanhã'
    }

    return format(d, 'DD/MM/YYYY')
}

export { SearchModal }
