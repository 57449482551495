import { memo, useEffect, useRef, useState } from 'react'

const Stopwatch = memo(() => {
    const intervalRef = useRef<NodeJS.Timer>()
    const counterRef = useRef<number>(0)
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            counterRef.current = counterRef.current + 1
            setTimer(counterRef.current)
        }, 1000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <span className="stopwatch">{secondsToHms(timer)}</span>
})

function secondsToHms(secs) {
    const hours = Math.floor(secs / (60 * 60))

    const divisor_for_minutes = secs % (60 * 60)
    const minutes = Math.floor(divisor_for_minutes / 60)

    const divisor_for_seconds = divisor_for_minutes % 60
    const seconds = Math.ceil(divisor_for_seconds)
    if (hours > 0) {
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
            seconds < 10 ? `0${seconds}` : seconds
        }`
    } else {
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
    }
}

export { Stopwatch }
