import styled from 'styled-components'

export const HeaderContainer = styled.div`
    padding: 30px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

export const HeaderTitle = styled.div`
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: bold;
`

export const InputsRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
    }
`

export const InputContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 200px;
        margin-right: 20px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 300px;
    }
`

export const MiddleContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`

export const TextButton = styled.div`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 30px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`

export const PrimaryButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`

export const FiltersContainer = styled.div`
    margin-top: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const SearchResultsTitle = styled.div`
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 0;
    }
    span {
        font-weight: bold;
    }
`
