import { createReducer, createActions } from 'reduxsauce'

import { ActionCreators, ActionTypes, Actions, State, SetWebSocketConnectionAction, SetScoreboardAction } from './types'

/* Types & Action Creators */

export const { Types: RoutesTypes, Creators: RoutesActions } = createActions<ActionTypes, ActionCreators>(
    {
        setWebSocketConnection: ['connection', 'mall', 'store'],
        setScoreboard: ['scoreboard'],
    },
    { prefix: 'routes/' }
)

/* Initial State */

const initialState: State = {
    webSocketConnection: 'disconnected',
    scoreboard: {
        accepted: 0,
        canceled: 0,
        failed: 0,
        finished: 0,
        offered: 0,
        refused: 0,
        in_progress: 0,
        waiting: 0,
    },
}

/* Reducers */

function setWebSocketConnection(state = initialState, action: SetWebSocketConnectionAction): State {
    return {
        ...state,
        webSocketConnection: action.connection,
    }
}

function setScoreboard(state = initialState, action: SetScoreboardAction): State {
    return {
        ...state,
        scoreboard: action.scoreboard,
    }
}

/* Reducers to types */

export const routesReducer = createReducer<State, Actions>(initialState, {
    [RoutesTypes.SET_WEB_SOCKET_CONNECTION]: setWebSocketConnection,
    [RoutesTypes.SET_SCOREBOARD]: setScoreboard,
})
