import {
    NavigationRowContainer,
    NavigationLeftSide,
    NavigationItem,
    NavigationItemContainer,
    NewItemIcon,
    NumbersContainer,
    NumberItem,
    NumberValue,
    FilterButton,
    FilterButtonContainer,
    AppliedFiltersNumber,
    NumberLabel,
    ButtonsContainer,
    MobileSelectTab,
} from './navigation-row.styles'

interface Props {
    isNewActive?: boolean
    isScheduledActive?: boolean
    isInRouteActive?: boolean
    isRoutedActive?: boolean
    appliedFilters?: number
    teamAvailable?: number
    routesValue?: number
    filterClick?(): void
    scheduledClick?(): void
    newsClick?(): void
    routedClick?(): void
    inRouteClick?(): void
}

const NavigationRow: React.FC<Props> = ({
    appliedFilters,
    filterClick,
    scheduledClick,
    newsClick,
    routedClick,
    teamAvailable,
    routesValue,
    inRouteClick,
    isNewActive,
    isScheduledActive,
    isInRouteActive,
    isRoutedActive,
}) => {
    return (
        <NavigationRowContainer>
            <NavigationLeftSide>
                <MobileSelectTab>
                    <option value="scheduled">Agendados</option>
                    <option value="news">Novos</option>
                    <option value="routed">Roteirizados</option>
                    <option value="in_route">Em Rota</option>
                </MobileSelectTab>
                <ButtonsContainer>
                    <NavigationItemContainer isActive={isScheduledActive}>
                        <NavigationItem onClick={scheduledClick} isActive={isScheduledActive}>
                            Agendados
                        </NavigationItem>
                        <NewItemIcon>!</NewItemIcon>
                    </NavigationItemContainer>
                    <NavigationItemContainer isActive={isNewActive}>
                        <NavigationItem onClick={newsClick} isActive={isNewActive}>
                            Novos
                        </NavigationItem>
                        <NewItemIcon>!</NewItemIcon>
                    </NavigationItemContainer>
                    <NavigationItemContainer isActive={isRoutedActive}>
                        <NavigationItem onClick={routedClick} isActive={isRoutedActive}>
                            Roteirizados
                        </NavigationItem>
                        <NewItemIcon>!</NewItemIcon>
                    </NavigationItemContainer>
                    <NavigationItemContainer isActive={isInRouteActive}>
                        <NavigationItem onClick={inRouteClick} isActive={isInRouteActive}>
                            Em rota
                        </NavigationItem>
                        <NewItemIcon>!</NewItemIcon>
                    </NavigationItemContainer>
                </ButtonsContainer>
                <FilterButtonContainer onClick={filterClick}>
                    <FilterButton />
                    {appliedFilters && <AppliedFiltersNumber>{appliedFilters}</AppliedFiltersNumber>}
                </FilterButtonContainer>
            </NavigationLeftSide>
            <NumbersContainer>
                <NumberItem>
                    <NumberValue>{teamAvailable}</NumberValue>
                    <NumberLabel>Equipe disponível</NumberLabel>
                </NumberItem>
                <NumberItem>
                    <NumberValue>{routesValue}</NumberValue>
                    <NumberLabel>Rotas em andamento</NumberLabel>
                </NumberItem>
            </NumbersContainer>
        </NavigationRowContainer>
    )
}

export default NavigationRow
