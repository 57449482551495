import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { PosConfiguration, Recipient } from 'containers/stone-management/types'

import { formatPhone, formatCnpj, formatCpf, showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'
import stoneBanks from 'utils/stoneBanks'

const InfoItem: React.FC<{ label: string; value?: string }> = ({ label, value }) => {
    return (
        <div className="info-item">
            <div className="label">{label}</div>
            <div className="value">{value || '-'}</div>
        </div>
    )
}

const RecipientView: React.FC<RouteComponentProps<{ recipient_id: string }>> = ({ history, match: { params } }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [recipient, setRecipient] = useState<Recipient>()
    const [posConfiguration, setPosConfiguration] = useState<PosConfiguration>()
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const getRecipient = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get(`/recipient/get-single/${params.recipient_id}`)

            if (data.recipient.recipient_is_active) {
                const posConfigResponse = await siclosPagApi.get(`/pos-configuration/get-config/${params.recipient_id}`)

                setPosConfiguration(posConfigResponse.data.pos_configuration)
            }

            if (data?.error) {
                throw data.msg
            }

            setRecipient(data.recipient)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history, params.recipient_id])

    useEffect(() => {
        getRecipient()
    }, [getRecipient])

    async function updatePosConfig(pos_configuration: PosConfiguration) {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.put(`/pos-configuration/update-config`, {
                profile_id: params.recipient_id,
                ...pos_configuration,
            })

            if (data?.error) {
                throw data.msg
            }

            setPosConfiguration(data.pos_configuration)

            setModalMessageData({
                title: 'Configuração alterada',
                message: 'Configuração alterada com sucesso!',
            })

            modalMessage.current?.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }

    function getStoneBank(bankCode: string | undefined): string {
        const bank = stoneBanks.find(bank => bank.value === Number(bankCode))

        return (bank && `${bank.value} - ${bank.name}`) || ''
    }

    return (
        <div>
            <RowTitle
                title="Detalhes do recebedor"
                backClick={() => history.push('/painel-malls/gestao-stone/recebedores')}
            />

            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="info-view-container">
                <div className="info-row">
                    <InfoItem label="Nome de identificação" value={recipient?.recipient_name} />
                    <InfoItem label="Nome fantasia" value={recipient?.business_name} />
                    <InfoItem
                        label={recipient?.is_cnpj ? 'CNPJ' : 'CPF'}
                        value={
                            recipient?.is_cnpj
                                ? formatCnpj(recipient?.document_number)
                                : formatCpf(recipient?.document_number)
                        }
                    />

                    {posConfiguration && (
                        <div className="radio-group">
                            <label>Exibir perfil na maquinha?</label>
                            <div className="radio-group-row">
                                <InputRadio
                                    id="true-profile_exhibition"
                                    label="Sim"
                                    checked={posConfiguration.profile_exhibition}
                                    onClick={() =>
                                        updatePosConfig({
                                            ...posConfiguration,
                                            profile_exhibition: true,
                                        })
                                    }
                                />

                                <InputRadio
                                    id="false-profile_exhibition"
                                    label="Não"
                                    checked={!posConfiguration.profile_exhibition}
                                    onClick={() =>
                                        updatePosConfig({
                                            ...posConfiguration,
                                            profile_exhibition: false,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="info-row">
                    <InfoItem label="Stone code" value={recipient?.stone_code} />
                    <InfoItem
                        label="Status"
                        value={
                            recipient?.recipient_is_active
                                ? recipient?.recipient_is_active
                                    ? 'Ativo'
                                    : 'Inativo'
                                : undefined
                        }
                    />
                    <InfoItem
                        label="Ativo para produção?"
                        value={
                            recipient?.is_recipient_to_production
                                ? recipient?.is_recipient_to_production
                                    ? 'Sim'
                                    : 'Não'
                                : undefined
                        }
                    />
                    <InfoItem
                        label="Split habilitado?"
                        value={recipient?.enabled_split ? (recipient?.enabled_split ? 'Sim' : 'Não') : undefined}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Endereço
                </div>

                <div className="info-row">
                    <InfoItem label="Logradouro" value={recipient?.address.street_name} />
                    <InfoItem label="Número" value={recipient?.address.number} />
                    <InfoItem label="Complemento" value={recipient?.address.complement} />
                    <InfoItem label="Bairro" value={recipient?.address.neighborhood} />
                    <InfoItem label="CEP" value={recipient?.address.zip_code} />
                    <InfoItem label="Cidade" value={recipient?.address.city} />
                    <InfoItem label="Estado" value={recipient?.address.state} />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Banco
                </div>

                <div className="info-row">
                    <InfoItem label="Banco" value={getStoneBank(recipient?.bank.bank_code)} />
                    <InfoItem
                        label="Agência"
                        value={recipient?.bank.agency && `${recipient?.bank.agency}-${recipient?.bank.agency_dv}`}
                    />
                    <InfoItem
                        label="Conta"
                        value={recipient?.bank.account && `${recipient?.bank.account}-${recipient?.bank.account_dv}`}
                    />
                    <InfoItem
                        label="Tipo de conta"
                        value={
                            recipient?.bank.account_type &&
                            (recipient?.bank.account_type === '1' ? 'Corrente' : 'Poupança')
                        }
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Contato
                </div>

                <div className="info-row">
                    <InfoItem label="Telefone" value={formatPhone(recipient?.contact.phone_number, false)} />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Responsável
                </div>

                <div className="info-row">
                    <InfoItem label="Nome" value={recipient?.responsible.name} />
                    <InfoItem label="Documento" value={recipient?.responsible.document_number} />
                    <InfoItem label="E-mail" value={recipient?.responsible.email} />
                    <InfoItem label="Celular" value={formatPhone(recipient?.responsible.cell_phone)} />
                </div>
            </div>
        </div>
    )
}

export default RecipientView
