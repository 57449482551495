import { useState, useEffect, useCallback, useMemo } from 'react'

import { format } from 'date-fns'

import { useAuth } from 'hooks'

import ButtonLoadMore from '../../components/button-load-more/button-load-more'
import DelivererInfoItem from '../../components/deliverer-info-item/deliverer-info-item'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import PaymentReportTopRow from '../../components/payment-report-top-row/payment-report-top-row'
import {
    formatCurrency,
    getBikerType,
    showErrors,
    formatCpf,
    formatCnpj,
    formatAddress,
    setSearchParams,
    formatDistance,
} from '../../helpers'
import api, { baseURL } from '../../services/api'
import './style.scss'

const IndividualPaymentReport = ({ location, history, match }) => {
    const { store, mall, user } = useAuth()

    const initialFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            merchant_id: user.isShopkeeper ? store.id : query.get('merchant_id') || '',
            start_date: query.get('start_date') || '',
            end_date: query.get('end_date') || '',
        }
    }, [location.search, store, user])

    const [loading, toggleLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [stores, setStores] = useState([])
    const [filter, setFilter] = useState(initialFilter)
    const [paymentReport, setPaymentReport] = useState({
        header: {},
        items: [],
    })

    const getPaymentReport = useCallback(
        async (
            { current_page, paymentReport, filter } = {
                current_page: 1,
                paymentReport: { items: [] },
                filter: initialFilter,
            }
        ) => {
            try {
                toggleLoading(true)
                if (!filter.start_date || !filter.end_date) return

                const filters = {
                    mall_id: mall.id,
                    merchant_id:
                        typeof Number(filter.merchant_id) === 'number' && !isNaN(filter.merchant_id)
                            ? filter.merchant_id
                            : null,
                    start_date: format(filter.start_date, 'YYYY-MM-DD'),
                    end_date: format(filter.end_date, 'YYYY-MM-DD'),
                }

                const { data } = await api.get(`/painel/dm-orders/payments/agent/${match.params.id}`, {
                    params: {
                        current_page: current_page,
                        ...filters,
                    },
                })

                setPaymentReport({
                    ...data,
                    items: current_page > 1 ? [...paymentReport.items, ...data.items] : data.items,
                })
            } catch (error) {
                setModalMessage(showErrors(error))
            } finally {
                toggleLoading(false)
            }
        },
        [initialFilter, mall.id, match.params.id]
    )

    useEffect(() => {
        getPaymentReport()
    }, [getPaymentReport])

    const getStores = useCallback(async () => {
        try {
            const {
                data: { items },
            } = await api.get('/painel/stores', {
                params: { mall_id: mall.id },
            })

            setStores(items)
        } catch (error) {
            setModalMessage(showErrors(error))
        }
    }, [mall.id])

    useEffect(() => {
        if (!user.isShopkeeper && !stores.length) getStores()
    }, [getStores, user, stores])

    const exportSpreadsheet = async () => {
        try {
            toggleLoading(true)

            const response = await api.get(`/painel/dm-orders/payments/agent/${match.params.id}`, {
                responseType: 'blob',
                params: {
                    download: 'xlsx',
                    mall_id: mall.id,
                    merchant_id:
                        typeof Number(filter.merchant_id) === 'number' && !isNaN(filter.merchant_id)
                            ? filter.merchant_id
                            : null,
                    start_date: format(filter.start_date, 'YYYY-MM-DD'),
                    end_date: format(filter.end_date, 'YYYY-MM-DD'),
                },
            })

            const file = new Blob([response], { type: response.type })

            const data = window.URL.createObjectURL(file)
            let link = document.createElement('a')
            link.href = data
            link.download = 'pagamento-individual.xlsx'
            link.click()
            setTimeout(() => window.URL.revokeObjectURL(data), 100)
        } catch (error) {
            setModalMessage(showErrors(error))
        } finally {
            toggleLoading(false)
        }
    }

    const setFilterParam = (key, value) => {
        setFilter({ ...filter, [key]: value })
    }

    return (
        <div className="individual-payment-report-page">
            <ModalLoading visible={loading} />

            <ModalMessage
                isActive={modalMessage}
                message={modalMessage}
                title={'Erro'}
                textButton={'Fechar'}
                onClose={() => setModalMessage('')}
            />

            <PaymentReportTopRow
                titleText="Relatório de pagamento individual"
                backClick={() => history.goBack()}
                stores={user.isShopkeeper ? null : stores} // hide select store if user is a shopkeeper
                selectStore={({ target: { value } }) => setFilterParam('merchant_id', Number(value))}
                storeFilter={filter.merchant_id}
                selectStartDate={date => setFilterParam('start_date', date)}
                startDate={filter.start_date}
                selectEndDate={date => setFilterParam('end_date', date)}
                endDate={filter.end_date}
                onFilterClick={() =>
                    setSearchParams(
                        {
                            ...filter,
                            start_date: filter.start_date ? format(filter.start_date, 'YYYY-MM-DD') : '',
                            end_date: filter.end_date ? format(filter.end_date, 'YYYY-MM-DD') : '',
                        },
                        location,
                        history
                    )
                }
            />
            <div className="individual-payment-report-page-content">
                <div className="box left-side">
                    <div className="top-row">
                        <div className="user-image">
                            {paymentReport.header.photo && (
                                <img src={`${baseURL}/${paymentReport.header.photo.path}?width=100`} alt="" />
                            )}
                        </div>
                        <div className="user-name">{paymentReport.header.biker_name}</div>
                    </div>

                    <div className="info-block">
                        <DelivererInfoItem label="name" value={paymentReport.header.biker_name} />
                        <DelivererInfoItem label="cpf" value={formatCpf(paymentReport.header.biker_cpf_cnpj)} />
                        <DelivererInfoItem label="cnpj" value={formatCnpj(paymentReport.header.biker_cnpj)} />
                        <DelivererInfoItem label="veículo" value={getBikerType(paymentReport.header.biker_type)} />
                        <DelivererInfoItem
                            label="Total a receber"
                            value={
                                paymentReport.header.payment_total && formatCurrency(paymentReport.header.payment_total)
                            }
                        />

                        {paymentReport.items.length ? (
                            <div className="export-button" onClick={() => exportSpreadsheet()}>
                                Exportar relatório
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="box right-side">
                    <div className="orders-row title">
                        <div className="row-item order-id">ID</div>
                        <div className="row-item store">Loja</div>
                        <div className="row-item biker">Entregador</div>
                        <div className="row-item address">Endereço</div>
                        <div className="row-item order-date">Data do Pedido</div>
                        <div className="row-item value">Taxa</div>
                        <div className="row-item value">Adicional</div>
                        <div className="row-item value">Total</div>
                    </div>
                    <div className="table-content-container">
                        {paymentReport.items.map((item, index) => (
                            <div className="orders-row" key={index}>
                                <div className="row-item order-id">{item.reference_id}</div>
                                <div className="row-item store">{item.store}</div>
                                <div className="row-item biker">
                                    {item.agent
                                        ? `#${item.agent.id} ${item.agent.first_name} ${item.agent.last_name}`
                                        : ''}
                                </div>
                                <div className="row-item address">
                                    <div className="neighborhood">{item.address.neighborhood}</div>
                                    {formatAddress({
                                        street: item.address.street,
                                        number: item.address.number,
                                    })}
                                    <br />
                                    {formatDistance(item.distance)}
                                </div>
                                <div className="row-item order-date">{format(item.birth, 'DD/MM/YYYY')}</div>
                                <div className="row-item value">{formatCurrency(item.rate)}</div>
                                <div className="row-item value">{formatCurrency(item.additional)}</div>
                                <div className="row-item value">{formatCurrency(item.agent_total)}</div>
                            </div>
                        ))}
                    </div>

                    <ButtonLoadMore
                        onClick={() =>
                            getPaymentReport({
                                current_page: ++paymentReport.current_page,
                                paymentReport,
                                filter,
                            })
                        }
                        visible={paymentReport.items.length < paymentReport.totals}
                    />
                </div>
            </div>
        </div>
    )
}

export default IndividualPaymentReport
