import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { getColorFromRoute } from 'helpers/getColorFromRoute'
import { useAuth } from 'hooks'
import { Biker } from 'types'

import {
    Container,
    Avatar,
    BikerName,
    BikerVehicle,
    ButtonText,
    Line,
    FooterPopup,
    Content,
    ContainerClose,
    Icon,
} from './agent-popup.styled'
interface Props {
    agent: Biker
    routeId?: number
    onClosePopup?(): void
}

const AgentPopup: React.FC<Props> = ({ agent, routeId, onClosePopup }) => {
    const history = useHistory()
    const { basePath } = useAuth()

    const _goToDetails = useCallback(() => {
        history.push(`${basePath}/detalhes-rota/${routeId}`)
    }, [history, basePath, routeId])

    return (
        <Container>
            <Content>
                <ContainerClose onClick={onClosePopup}>
                    <Icon icon="times" />
                </ContainerClose>
                <Avatar src={agent.avatar} color={routeId && getColorFromRoute(routeId)} />
                <BikerName>
                    {agent.first_name} {agent.last_name}
                </BikerName>
                <BikerVehicle>{Vehicles[agent.type_name]}</BikerVehicle>
                {routeId && (
                    <FooterPopup onClick={_goToDetails}>
                        <Line />
                        <ButtonText>Ver detalhes da rota</ButtonText>
                        <Line />
                    </FooterPopup>
                )}
            </Content>
        </Container>
    )
}

const Vehicles = {
    bike: 'Bicicleta',
    moto: 'Moto',
    car: 'Carro',
    van: 'Van',
    truck: 'Caminhão',
}

export default AgentPopup
