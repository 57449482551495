import React, { memo, useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'

import { AmountCard, LoadingMessage, PerformanceCard } from 'components/_common'

import { formatCurrency } from 'helpers'
import api from 'services/api'
import { ReportScoreboard } from 'types'

import { AmountRow, AmountItem } from './overview-card.styled'

interface Props {
    params?: any
}
const OverviewCard: React.FC<Props> = memo(({ params }) => {
    const [data, setData] = useState<ReportScoreboard>()
    const [loading, setLoading] = useState(false)

    const _loadData = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api.get<ReportScoreboard>('/painel/report/scoreboard', { params })

            setData(response.data)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (params) {
            _loadData(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <PerformanceCard title="Visão geral" subtitle="Placar geral do pedido">
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!data && (
                        <AmountRow>
                            <AmountItem>
                                <AmountCard
                                    number={data.orders_total}
                                    title="Quantidade de Pedidos"
                                    subtitle="Soma de todos os canais de venda"
                                />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard
                                    number={formatCurrency(data.ticket_average)}
                                    title="Ticket Médio"
                                    subtitle="Valor médio dos pedidos"
                                />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard
                                    number={data.time_average}
                                    title="Tempo Médio de Preparo"
                                    subtitle="Média entre captura e disponível para coleta"
                                />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard
                                    number={data.collect_time_average}
                                    title="Tempo Médio de Coleta"
                                    subtitle="Média entre finalização do preparo e realização da coleta"
                                />
                            </AmountItem>
                        </AmountRow>
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { OverviewCard }
