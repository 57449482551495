import { useCallback, useContext, useRef } from 'react'
import Spinner from 'react-spinner-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DistrictContext } from 'contexts/district-context'
import DistrictsModal from 'modals/districts-modal/districts-modal'

import { getFiltersByTimeOptions } from '../../helpers'
import colors from '../../themes/colors'
import SelectInput from '../select-input'

import {
    SearchContainer,
    SearchInput,
    SearchIcon,
    Button,
    ContainerDistrics,
    Badge,
} from './orders-card-listing.styled'
const OrdersCardListing = ({
    title = undefined,
    loading = undefined,
    stores = undefined,
    iconName = undefined,
    filter = undefined,
    setFilterParam = undefined,
    children = undefined,
    onSearch = undefined,
}) => {
    const districtsModalRef = useRef()
    const { selectedDistricts, removeDistrict } = useContext(DistrictContext)

    const _openModalDistricts = useCallback(() => {
        districtsModalRef.current?.show()
    }, [])

    return (
        <div className="orders-card-listing">
            <div className="column-title">
                <div className="title-flex">
                    <div className="icon">
                        {loading ? (
                            <Spinner
                                size={16}
                                color={colors.light_primary_color}
                                stroke={2}
                                radius={20}
                                visible={true}
                            />
                        ) : (
                            <FontAwesomeIcon icon={iconName} />
                        )}
                    </div>
                    <div className="text">{title}</div>
                </div>
            </div>
            <div className="filters">
                <SelectInput
                    placeholder="Todas as lojas"
                    onChange={({ target: { value } }) => setFilterParam('store_id', value)}
                    value={filter.store_id}
                    bgColor="transparent"
                    borderColor="transparent"
                    data={stores}
                />
                <SelectInput
                    onChange={({ target: { value } }) => setFilterParam('order_by', value)}
                    value={filter.order_by}
                    placeholder="Ordenar por"
                    bgColor="transparent"
                    borderColor="transparent"
                    data={getFiltersByTimeOptions()}
                />
            </div>

            <div className="container-search">
                <div className="container-input">
                    <SearchContainer>
                        <SearchInput
                            placeholder="Buscar por Nº do Pedido ou Nome do Cliente"
                            onChange={({ target: { value } }) => {
                                if (onSearch) {
                                    onSearch(value)
                                }
                            }}
                        />
                        <SearchIcon />
                    </SearchContainer>
                </div>
                <div className="container-districts">
                    <Button onClick={_openModalDistricts}>
                        <FontAwesomeIcon className="icon" icon="map-marked-alt" />{' '}
                        {selectedDistricts.length === 0 ? 'Todos' : selectedDistricts.length + '+'}
                    </Button>
                </div>
            </div>
            <ContainerDistrics>
                {selectedDistricts.map((item, i) => (
                    <Badge key={i}>
                        {item}
                        <FontAwesomeIcon className="icon" icon="times-circle" onClick={() => removeDistrict(item)} />
                    </Badge>
                ))}
            </ContainerDistrics>
            {children}
            <DistrictsModal ref={districtsModalRef} />
        </div>
    )
}

export default OrdersCardListing
