import styled, { css } from 'styled-components'

const Container = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    &:nth-child(odd) {
        background-color: #f1f1f1;
    }
`

const Avatar = styled.img`
    width: 64px;
    height: 64px;
    object-fit: contain;
    //border-radius: 50%;
    padding: 0.5rem;
`

const ContainerInfo = styled.div`
    flex: 1;
    margin-left: 20px;
`

const TextHighlights = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
`
const TextName = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin: 2px 0px;
`

const IntegrationStatusContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
`
const TextContent = styled.div<{ isActive?: boolean }>`
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin-bottom: 0.126rem;

    ${({ theme, isActive }) =>
        isActive &&
        css`
            span {
                color: ${theme.colors.danger};
            }
        `}
`

const TextStatus = styled.div<{ active?: boolean }>`
    color: ${({ theme, active }) => (active ? theme.colors.success : theme.colors.errorMessage)};
    font-size: 12px;
`

const Button = styled.div`
    width: fit-content;

    padding: 5px 14px;
    background-color: transparent;
    border: 2px solid;
    border-color: var(--primary-color);
    border-radius: 5px;
    color: white;
    background-color: var(--primary-color);
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
`

export {
    Container,
    Avatar,
    ContainerInfo,
    TextHighlights,
    IntegrationStatusContent,
    TextContent,
    TextName,
    TextStatus,
    Button,
}
