import { useCallback, useEffect, useRef, useState } from 'react'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { mercadooApi, plugApi } from 'services/api'
import { IOrderIntegration } from 'types/order-integration'
import { IOrderIntegrationList } from 'types/order-integration-list'

type Params = {
    order: IOrderIntegrationList | IOrderIntegration
}

type Reason = {
    title: string
    code: number
}

type TypeButton = 'yes' | 'no'

interface Props {
    onClose?(success?: boolean): void
    onNoClick?(): void
    onYesClick?(): void
}

function useModalOrderIntegrationCancelCTL({ onClose }: Props) {
    const { setErrorModal, setLoading } = useUI()
    const modalShowRef = useRef<boolean>()

    const [reason, setReason] = useState<Reason>()
    const [order, setOrder] = useState<IOrderIntegrationList | IOrderIntegration>()
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)

    const _setShow = useCallback(({ order }: Params) => {
        setOrder(order)
        setShow(true)
    }, [])

    const _onClose = useCallback(
        (success?: boolean) => {
            setShow(false)
            if (onClose) {
                onClose(success)
            }
        },
        [onClose]
    )

    const _setOptionCancel = useCallback((reason: Reason) => {
        setReason(reason)
    }, [])

    const _onButtonClick = useCallback(
        (type: TypeButton) => {
            return async () => {
                if (type === 'no') {
                    _onClose(false)
                }
                if (type === 'yes') {
                    setLoading(true)
                    try {
                        if (order.type === 'mercadoo') {
                            await mercadooApi.put(`/orders/${order.id}/moderate`, {
                                status: false,
                                reason: {
                                    id: String(reason?.code) || 55,
                                    description: reason?.title || 'Outros',
                                },
                            })
                        } else {
                            await plugApi.put<{ message: string; status: boolean }>(`/orders/${order.id}/reprove`, {
                                type: order.type,
                                store_id: order?.header?.store?.id,
                                reason_id: reason?.code || 55,
                                reason_description: reason?.title || 'Outros',
                            })
                        }
                        _onClose(true)
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro ao rejeitar pedido',
                            subtitle: showErrors(error),
                        })
                        _onClose(false)
                    }

                    setLoading(false)
                }
            }
        },
        [order, reason, setErrorModal, setLoading, _onClose]
    )

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setVisible(false)
            setOrder(null)
            setReason(null)
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    return {
        visible,
        show,
        order,
        reason,
        _setOptionCancel,
        _onButtonClick,
        _setShow,
    }
}

export { useModalOrderIntegrationCancelCTL }
