import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { InputItem } from 'components/_common'
import { ChainRatesTable } from 'components/chain-rates-table/chain-rates-table'
import ModalLoading from 'components/modal-loading'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { RateChain } from 'types'

import { Container, Content, InputContainer } from './rate-chain-list.styled'

interface IRedirectProp {
    state: { store: number }
}

const RateChainList: React.FC<RouteComponentProps> = ({ history }) => {
    const { state: locationState } = (history.location || {}) as IRedirectProp

    const { basePath, mall } = useAuth()
    const { setConfirmationModal, setErrorModal, setLoading } = useUI()

    const [loading, toggleLoading] = useState<boolean>(false)
    const [rates, setRates] = useState<RateChain[]>([])
    const [selectedStore, setSelectedStore] = useState<string>(null)
    const [storeOptions, setStoreOptions] = useState<{ label: string; value: number }[]>([])

    const _getChainRates = useCallback(async () => {
        toggleLoading(true)

        const getMallStoreId = () => {
            const locationValue = locationState?.store || null
            if (!locationValue) {
                if (selectedStore === null || Number(selectedStore) === mall.id) {
                    return { mall_id: mall.id }
                }
                return { store_id: selectedStore }
            }

            delete locationState.store
            if (locationValue === null || Number(locationValue) === mall.id) {
                return { mall_id: mall.id }
            }
            return { store_id: locationValue }
        }

        try {
            const { data } = await api.get(`/painel/rate-chain`, {
                params: {
                    ...getMallStoreId(),
                    per_page: -1,
                },
            })
            setRates(data?.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar os dados.',
            })
        }
        toggleLoading(false)
    }, [history, mall.id, selectedStore])

    const _redirect = useCallback(
        (endPoint: string) => {
            return (): void =>
                history.replace(`${basePath}/${endPoint}`, {
                    store: selectedStore,
                })
        },
        [history, basePath, selectedStore]
    )

    const _handleSelect = useCallback(({ target }): void => {
        const { value } = target
        setSelectedStore(String(value))
    }, [])

    const _handleDelete = useCallback(
        (id: number) => {
            const rateDelete = async () => {
                setLoading(true)
                try {
                    await api.delete(`/painel/rate-chain/${id}`)

                    _getChainRates()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro!',
                        subtitle: 'Não foi possível excluir tarifa!',
                    })
                }
                setLoading(false)
            }

            setConfirmationModal({
                title: 'Excluir Tarifa Corrente',
                subtitle: 'Deseja mesmo excluir está tarifa?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: rateDelete,
            })
        },
        [_getChainRates]
    )

    const _handleEdit = useCallback(
        (id: number) => {
            history.replace(`tarifa-corrente/edit/${id}`)
        },
        [history]
    )

    const _getAvailableStoresOptions = useCallback(async () => {
        toggleLoading(true)
        try {
            const { data } = await api.get(`/painel/stores`, {
                params: {
                    mall_id: mall?.id,
                    per_page: -1,
                    status: [1],
                },
            })

            if (data) {
                const avaibleStores = data?.items?.map(item => {
                    return { label: item.name, value: item.id }
                })

                setStoreOptions([{ label: mall?.name, value: mall?.id }, ...avaibleStores])
            }
        } catch (error) {
            setErrorModal({
                title: 'Erro!',
                subtitle: 'Não foi possível carregar lojas.',
            })
        }

        toggleLoading(false)
    }, [mall.id])

    useEffect(() => {
        if (locationState?.store) {
            setSelectedStore(String(locationState.store))
        }
    }, [])

    useEffect(() => {
        _getChainRates()
    }, [_getChainRates])

    useEffect(() => {
        _getAvailableStoresOptions()
    }, [_getAvailableStoresOptions])

    return (
        <Container>
            <ModalLoading visible={loading} />
            <TopRowDefault
                onBackButtonClick={_redirect('gestao-mall')}
                title="Tarifas Corrente"
                buttons={[
                    {
                        title: 'Criar tarifa corrente',
                        onClick: _redirect('tarifa-corrente/create'),
                        // icon: 'plus',
                    },
                ]}
            />

            <Content>
                <InputContainer>
                    <InputItem
                        type="select"
                        labelText="Estabelecimento"
                        options={storeOptions}
                        inputProps={{
                            onChange: _handleSelect,
                            value: selectedStore,
                        }}
                    />
                </InputContainer>

                <ChainRatesTable
                    rates={rates}
                    actions={{
                        handleDelete: _handleDelete,
                        handleEdit: _handleEdit,
                    }}
                />
            </Content>
        </Container>
    )
}

export { RateChainList }
