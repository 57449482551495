import { useState } from 'react'

import OrderItem from '../order-item/order-item'
import RoutedStepItem from '../routed-step-item/routed-step-item'

import {
    AccordionButton,
    AccordionContent,
    ButtonsRow,
    ChevronIcon,
    DeleteButton,
    EditButton,
    LeftSide,
    OrdersListing,
    RightSide,
    RouteButton,
    RoutedOrderItemContainer,
    RouteIcon,
    StepsBox,
    StepsContainer,
} from './routed-order-item.styles'

interface Props {
    orders: Array<{
        storeImage: string
        channel: number
        customerName: string
        customerNeighborhood: string
        customerStreet: string
        customerNumber: number
        orderValue: number
        payment: string
        distance: number
        productionTime: number
        elapsedTime: number
        status: number
        readyTime: number
    }>
    isDraft?: boolean
    isWaitingBiker?: boolean
    color: string
    orderStatus: number
    bikerPhoto?: string
    bikerName?: string
    bikerVehicle?: number
    showBikerTag?: boolean
}

const RoutedOrderItem: React.FC<Props> = ({
    orders,
    color,
    isDraft,
    isWaitingBiker,
    orderStatus,
    bikerPhoto,
    bikerName,
    bikerVehicle,
    showBikerTag,
}) => {
    const [isAccordionOpened, setIsAccordionOpened] = useState(false)
    return (
        <>
            <RoutedOrderItemContainer isActive={isAccordionOpened}>
                <LeftSide>
                    <StepsContainer>
                        <StepsBox>
                            <RoutedStepItem
                                showDraftIcon={isDraft}
                                showWaitingBikerIcon={isWaitingBiker}
                                isActive={orderStatus >= 1}
                                color={color}
                                showStatusTag={isWaitingBiker}
                                showWaitingIcon={isWaitingBiker}
                                bikerPhoto={bikerPhoto}
                                clientName={bikerName}
                                showBikerTag={showBikerTag}
                                bikerVehicle={bikerVehicle}
                            />
                            <RoutedStepItem showMallIcon={true} isActive={orderStatus >= 2} color={color} />
                            {orders.map((item, index) => (
                                <RoutedStepItem
                                    key={index}
                                    color={color}
                                    storeLogo={item.storeImage}
                                    isActive={item.status >= 7}
                                    showStatusTag={item.status >= 8}
                                    showSuccessTag={item.status === 8}
                                    showErrorTag={item.status === 10}
                                    clientName={item.customerName}
                                />
                            ))}
                            <RoutedStepItem color={color} isActive={orderStatus >= 3} showFlagIcon />
                        </StepsBox>
                    </StepsContainer>
                </LeftSide>
                <RightSide>
                    <RouteButton>
                        <RouteIcon />
                    </RouteButton>
                    <AccordionButton onClick={() => setIsAccordionOpened(!isAccordionOpened)}>
                        <ChevronIcon />
                    </AccordionButton>
                </RightSide>
            </RoutedOrderItemContainer>
            <AccordionContent isActive={isAccordionOpened}>
                <ButtonsRow>
                    <EditButton />
                    <DeleteButton />
                </ButtonsRow>
                <OrdersListing>
                    {orders.map((item, index) => (
                        <OrderItem
                            key={index}
                            storeImage={item.storeImage}
                            channel={item.channel}
                            customerName={item.customerName}
                            customerNeighborhood={item.customerNeighborhood}
                            customerStreet={item.customerStreet}
                            customerNumber={item.customerNumber}
                            orderValue={item.orderValue}
                            payment={item.payment}
                            distance={item.distance}
                            elapsedTime={item.elapsedTime}
                            productionTime={item.productionTime}
                            readyTime={item.readyTime}
                        />
                    ))}
                </OrdersListing>
            </AccordionContent>
        </>
    )
}

export default RoutedOrderItem
