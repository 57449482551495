import { useCallback, useEffect, useRef, useState } from 'react'

import {
    ModalOrderIntegrationCancel,
    ModalOrderIntegrationCancelRef,
} from 'modals/modal-order-integration-cancel/modal-order-integration-cancel'

import { OrderIntegrationSummary } from 'components/_store-general-vision/order-integration-summary/order-summary'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { plugApi } from 'services/api'
import { IOrderIntegration } from 'types/order-integration'

import DetailsTab from './details-tab/details-tab'
import {
    ContentContainer,
    ContentTitle,
    OutsideContainer,
    CloseButton,
    CloseContainer,
    TitleContainer,
    OrderStatus,
    ApproveIcon,
    StatusCircle,
    StatusTitle,
    HeaderContainer,
    ButtonsRow,
    TextAccept,
    TextReject,
    CancelIcon,
} from './order-details-content.styled'

interface Props {
    orderDetailsId: number | undefined
    closeClick(): void
}

const OrderDetailsContent: React.FC<Props> = ({ orderDetailsId, closeClick }) => {
    const ModalOrderIntegrationCancelRef = useRef<ModalOrderIntegrationCancelRef>()

    const { setConfirmationModal, setErrorModal, setLoading } = useUI()

    const [order, setOrder] = useState<IOrderIntegration>()

    const _onConfirmOrder = useCallback(() => {
        const callback = async () => {
            setLoading(true)
            try {
                await plugApi.put<{ message: string; status: boolean }>(`/orders/${order.id}/approve`, {
                    time: 20,
                    type: order.type,
                })
                if (closeClick) {
                    closeClick()
                }
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao aprovar pedido',
                    subtitle: showErrors(error),
                })
            }
            setLoading(false)
        }
        setConfirmationModal({
            title: 'Aceitar pedido',
            subtitle: 'Deseja realmente aceitar este pedido?',
            type: 'alert',
            modalIcon: 'check-circle',
            leftButtonText: 'Fechar',
            rightButtonText: 'Sim, aceitar',
            rightButtonClick: callback,
            children: <OrderIntegrationSummary order={order as any} />,
        })
    }, [order, setConfirmationModal, setErrorModal, closeClick, setLoading])

    const _onRejectOrder = useCallback(() => {
        ModalOrderIntegrationCancelRef.current?.show({ order })
    }, [order])

    const _getOrder = useCallback(async () => {
        const { data } = await plugApi.get<IOrderIntegration>(`/orders/${orderDetailsId}`)
        setOrder(data)
    }, [orderDetailsId])

    useEffect(() => {
        _getOrder()
    }, [])

    return (
        <>
            <OutsideContainer>
                <HeaderContainer>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <TitleContainer>
                        <OrderStatus>
                            <StatusCircle isFinished={false} />
                            <StatusTitle>Aguardando moderação</StatusTitle>
                        </OrderStatus>
                        <ContentTitle>Pedido #{order?.header?.reference_id || order?.id}</ContentTitle>
                    </TitleContainer>
                </HeaderContainer>
                <ContentContainer>
                    {order && (
                        <DetailsTab
                            order={order}
                            onRescheduleOrder={() => {
                                console.log('refresh')
                            }}
                        />
                    )}
                </ContentContainer>
                {order?.header?.status_plooga === 'R' && (
                    <ButtonsRow>
                        <TextReject onClick={_onRejectOrder}>
                            <CancelIcon /> Reprovar pedido
                        </TextReject>

                        <TextAccept onClick={_onConfirmOrder}>
                            <ApproveIcon /> Aprovar pedido
                        </TextAccept>
                    </ButtonsRow>
                )}
            </OutsideContainer>
            <ModalOrderIntegrationCancel
                ref={ModalOrderIntegrationCancelRef}
                onClose={success => {
                    if (success) {
                        closeClick()
                    }
                }}
            />
        </>
    )
}

export { OrderDetailsContent }
