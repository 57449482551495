export const ordersStatusList = [
    { id: '0', name: 'Cancelado' },
    { id: '1', name: 'Capturado' },
    { id: '2', name: 'Aguardando moderação' },
    { id: '3', name: 'Em produção' },
    { id: '4', name: 'Pronto p/ coleta' },
    { id: '5', name: 'Coletado' },
    { id: '6', name: 'Saiu  p/ entrega' },
    { id: '7', name: 'Chegou ao cliente' },
    { id: '8', name: 'Entregue ao cliente' },
    { id: '9', name: 'Comprovante de pagamento entregue ao lojista' },
    { id: '10', name: 'Problema identificado' },
    { id: '10A', name: 'Fora da área de cobertura' },
    { id: '10B', name: 'Endereço não encontrado' },
    { id: '10C', name: 'Destinatário ausente' },
    { id: '10D', name: 'Destinatário recusou receber' },
    { id: '10E', name: 'Problema no pagamento' },
    { id: '10F', name: 'Pedido errado' },
    { id: '10G', name: 'Impossibilitado de devolver comprovantes' },
    { id: '10H', name: 'Geolocalização errada' },
    { id: '11', name: 'Entregue no Balcão' },
]
