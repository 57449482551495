import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
const Container = styled.div``

const Label = styled.div`
    color: #333;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
`

const BorderContent = styled.div`
    border: 1px solid #ababab;
    font-size: 14px;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    padding-left: 5px;
    user-select: none;
    font-family: 'Arial';
    margin-bottom: 25px;
    display: flex;
    align-items: center;
`

const Title = styled.div`
    flex: 1;
`

const RemoveIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.errorMessage};
    font-size: 16px;
    cursor: pointer;
`

export { BorderContent, Container, Label, Title, RemoveIcon }
