import { forwardRef, useRef, useImperativeHandle, useState, memo, useCallback, useMemo } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import * as Feather from 'react-feather'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { format } from 'date-fns'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import { DefaultButton } from 'components/default-button/default-button'
import ModalLoading from 'components/modal-loading'
import { Text } from 'components/text/text'
import { ViewInfo } from 'components/view-info/view-info'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { bytesToSize } from 'helpers/bytes-to-size'
import { useAuth } from 'hooks'
import api, { baseURL } from 'services/api'
import { IErrorImport } from 'types/import-error'
import { IImportFile } from 'types/import-file'
import { transformFormData } from 'utils/transform-formdata'

import {
    Container,
    Loader,
    Message,
    LoaderBar,
    SectionTitle,
    CheckBoxContainer,
    CardFile,
    TitleFile,
    ContainerInfoCard,
    ContainerDetails,
    ContainerInfo,
    ContainerFileCard,
    ContianerButtonUpdate,
    CardInfo,
    DocIcon,
    Footer,
    ContainerCard,
    ContianerButton,
    ContainerAmounts,
} from './import-orders-modal-modal.styled'
type Params = {
    importFile?: IImportFile
    onChange?(importFile: IImportFile, type: 'add' | 'update' | 'delete'): void
}
export type ImportOrdersModalRef = {
    show?(params?: Params): void
    close?(): void
}

const fileLogarooTypes = ['CSV', 'XLS', 'XLSX']
const fileGFLTypes = ['PDF']

type TypeInfo = {
    orders_count: number
    creator: string
    status: 'draft' | 'run' | 'completed'
    file?: string
    filters?: {
        status: {
            total: number
            name: string
        }[]
    }
    file_size: number
    created_at: string
}

const options = [
    {
        label: 'Planilha Padrão Logaroo',
        value: 'logaroo',
    },
    {
        label: 'Planilha GFL Sync Log',
        value: 'romaneio-gfl',
    },
]

// eslint-disable-next-line react/display-name
const ImportOrdersModal = memo(
    forwardRef<ImportOrdersModalRef, any>((props, ref) => {
        const history = useHistory()

        const { store, user } = useAuth()
        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)
        const onUpdateRef = useRef<(importFile: IImportFile, type: 'add' | 'update' | 'delete') => void>()

        const { setSuccessModal, setErrorModal, setConfirmationModal } = useUI()

        const [file, setFile] = useState<File>()
        const [importFile, setImportFile] = useState<IImportFile>()
        const [problem, setProblem] = useState<IErrorImport>()
        const [loading, setLoading] = useState(false)
        const [importType, setImportType] = useState<'excel' | 'romaneio-gfl'>()
        const [selectedStatus, setSelectedStatus] = useState<string[]>([])

        const infoDisplay = useMemo<TypeInfo>(() => {
            if (importFile) {
                return {
                    orders_count: importFile.orders_count,
                    creator: importFile.creator.name,
                    status: importFile.status,
                    file: importFile.file.original_name,
                    filters: importFile.filters,
                    file_size: importFile.file_size,
                    created_at: importFile.created_at,
                }
            }

            if (problem?.data) {
                const fileInfo = problem.data
                return {
                    orders_count: fileInfo.orders_count,
                    creator: `${user.first_name || ''}  ${user.first_name || ''}`,
                    status: fileInfo.status,
                    file: file?.name,
                    file_size: fileInfo.file_size,
                    created_at: format(new Date(), 'YYYY-MM-DD HH:mm'),
                }
            }
        }, [importFile, problem, file, user])

        const fileError = useMemo(() => {
            return problem?.errors?.file
        }, [problem])

        const rowsError = useMemo(() => {
            if (importFile?.errors) {
                const errors: { line: number; field: string; message: string }[] = []

                importFile.errors.forEach(row => {
                    row.fails.forEach(err => {
                        err.errors.forEach(msg => {
                            errors.push({
                                line: row.row,
                                field: err.attribute,
                                message: msg,
                            })
                        })
                    })
                })

                return errors
            }

            return undefined
        }, [importFile])

        useImperativeHandle(
            ref,
            () => ({
                show: params => {
                    setImportFile(params?.importFile)
                    onUpdateRef.current = params?.onChange
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [lateralModalBaseRef]
        )

        const _sendFile = useCallback(async () => {
            try {
                const { data } = await api.post(
                    '/painel/imports',
                    transformFormData({
                        type: importType,
                        store_id: store?.id,
                        file,
                        status: 'draft',
                    }),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                if (onUpdateRef.current) {
                    onUpdateRef.current(data, 'add')
                }
                setImportFile(data)
            } catch (error) {
                setProblem(error?.response?.data)
            }
        }, [file, store])

        const _handleChangeFile = useCallback((file: File) => {
            setProblem(undefined)
            setFile(file)
        }, [])

        const _onClose = useCallback(() => {
            onUpdateRef.current = undefined
            setFile(undefined)
            setImportFile(undefined)
            setProblem(undefined)
            setLoading(false)
            setImportType(undefined)
        }, [])

        const _delete = useCallback(async () => {
            try {
                await api.delete(`/painel/imports/${importFile.id}`)
                if (onUpdateRef.current) {
                    onUpdateRef.current(importFile, 'delete')
                }
                lateralModalBaseRef.current?.close()
            } catch (error) {
                console.log('error', error)
            }
        }, [importFile])

        const _draft = useCallback(() => {
            setSuccessModal({
                title: 'Rascunho Salvo',
                subtitle: 'Rascunho salvo com sucesso',
                singleButtonClick() {
                    lateralModalBaseRef.current?.close()
                },
            })
        }, [setSuccessModal])

        const _next = useCallback(async () => {
            const callback = async () => {
                try {
                    await api.get(`/painel/imports/${importFile.id}/run`, {
                        params: {
                            status: selectedStatus,
                        },
                    })
                    if (onUpdateRef.current) {
                        onUpdateRef.current({ ...importFile, status: 'run' }, 'update')
                    }
                    lateralModalBaseRef.current?.close()
                } catch (error) {
                    setErrorModal({
                        title: 'error',
                        subtitle: showErrors(error),
                    })
                }
            }

            setConfirmationModal({
                title: 'Importar Pedidos',
                subtitle: 'Deseja realizar a importações de todos os pedidos do arquivo?',
                leftButtonText: 'Não',
                rightButtonText: 'Sim, importar',
                rightButtonClick: callback,
            })
        }, [importFile, selectedStatus, setErrorModal, setConfirmationModal])

        const _download = useCallback(async () => {
            try {
                const token = localStorage.getItem('@extranet.logaroo:token')
                const result = await axios({
                    url: `${baseURL}/painel/imports/${importFile.id}/download`,
                    method: 'GET',
                    responseType: 'blob', // important
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })

                const href = URL.createObjectURL(result.data)

                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', importFile.file.original_name)
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            } catch (error) {
                console.log('error', error)
            }
        }, [importFile])

        const _openModel = useCallback(() => {
            window.open(`${process.env.REACT_APP_API_URL}/modelo-importação.xlsx`, '_blank')
        }, [])

        const _preview = useCallback(() => {
            history.push(`visualizar-importacao/${importFile.id}`)
        }, [history, importFile])

        const _toogleStatus = useCallback((value: string) => {
            return () => {
                setSelectedStatus(state => {
                    if (state.includes(value)) {
                        return state.filter(x => x !== value)
                    } else {
                        return [...state, value]
                    }
                })
            }
        }, [])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title={importFile ? 'Detalhes da planilha importada' : 'Enviar Planilha'}
                onClose={_onClose}
                headerComponent={
                    <>
                        <Footer>
                            {!importFile ? (
                                <>
                                    <Message>Adicione um arquivo .XLSX, .XLS nativo do excel ou .CSV</Message>
                                    <Message>
                                        Baixe o modelo{' '}
                                        <span className="link" onClick={_openModel}>
                                            aqui
                                        </span>
                                        .
                                    </Message>
                                </>
                            ) : (
                                <ContianerButton>
                                    <DefaultButton widthFitContainer simple onClick={_preview}>
                                        <Feather.Eye />
                                        Preview
                                    </DefaultButton>
                                    <DefaultButton widthFitContainer simple onClick={_download}>
                                        <Feather.Download /> Baixar
                                    </DefaultButton>
                                    <DefaultButton
                                        className="trash"
                                        widthFitContainer
                                        simple
                                        variant="danger"
                                        onClick={_delete}
                                    >
                                        <Feather.Trash2 className="trash-icon" />
                                    </DefaultButton>
                                </ContianerButton>
                            )}
                        </Footer>
                    </>
                }
            >
                <Container>
                    <>
                        {!infoDisplay && (
                            <>
                                <InputItem
                                    labelText="Tipo de importação"
                                    type="select"
                                    options={options}
                                    inputProps={{
                                        value: importType,
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            setProblem(undefined)
                                            setFile(undefined)
                                            setImportType(value as any)
                                        },
                                    }}
                                />
                                {!!importType && (
                                    <>
                                        <SectionTitle>
                                            <Feather.Paperclip /> Planilha
                                        </SectionTitle>

                                        <ContainerFileCard>
                                            {!loading ? (
                                                <>
                                                    <FileUploader
                                                        hoverTitle="Solte o arquivo"
                                                        handleChange={_handleChangeFile}
                                                        name="file"
                                                        types={
                                                            importType === 'romaneio-gfl'
                                                                ? fileGFLTypes
                                                                : fileLogarooTypes
                                                        }
                                                    >
                                                        {!fileError ? (
                                                            <CardFile showBorder pointer>
                                                                <ContainerInfoCard>
                                                                    <TitleFile hasFile={!!file}>
                                                                        {file?.name || 'Selecionar arquivo'}
                                                                    </TitleFile>
                                                                    <Message>
                                                                        Arraste o <span>arquivo</span> para fazer{' '}
                                                                        <span>Upload</span> ou{' '}
                                                                        <span className="link">Clique aqui</span>
                                                                    </Message>
                                                                </ContainerInfoCard>
                                                                <Feather.Share className="upload" />
                                                            </CardFile>
                                                        ) : (
                                                            <CardFile alert>
                                                                <ContainerInfoCard>
                                                                    <Text className="error" text="Erro" bold />
                                                                    {fileError.map((error, i) => (
                                                                        <ViewInfo
                                                                            key={i}
                                                                            label={error}
                                                                            variant="foregroundPrimary"
                                                                        />
                                                                    ))}
                                                                    <Text
                                                                        text={file?.name}
                                                                        bold
                                                                        dimensions="small"
                                                                        variant="foregroundPrimary"
                                                                    />
                                                                </ContainerInfoCard>
                                                                <Feather.AlertCircle className="alert" />
                                                            </CardFile>
                                                        )}
                                                    </FileUploader>
                                                </>
                                            ) : (
                                                <CardFile>
                                                    <ContainerInfoCard>
                                                        <Text
                                                            className="sending"
                                                            text="Enviando arquivo..."
                                                            bold
                                                            light
                                                        />
                                                        <Loader>
                                                            <LoaderBar />
                                                        </Loader>
                                                        <Text
                                                            text="Aguarde enquanto seu arquivo esta sendo carregado"
                                                            dimensions="xSmall"
                                                            variant="primary"
                                                        />
                                                    </ContainerInfoCard>
                                                </CardFile>
                                            )}
                                        </ContainerFileCard>

                                        <DefaultButton
                                            disabled={!file || !!fileError}
                                            title="Enviar Arquivo"
                                            widthFitContainer
                                            position="right"
                                            onClick={_sendFile}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {!!infoDisplay && (
                            <>
                                <SectionTitle>
                                    <Feather.Archive /> Detalhes do arquivo
                                </SectionTitle>
                                <ContainerDetails>
                                    <ContainerInfo>
                                        <ViewInfo label="Enviado Por" value={infoDisplay.creator} />
                                        <ViewInfo
                                            label="Data do Envio"
                                            value={format(infoDisplay.created_at, 'DD/MM/YYYY HH:mm')}
                                        />

                                        {!!rowsError && (
                                            <>
                                                <Text
                                                    text="Linhas com Erro"
                                                    variant="danger"
                                                    dimensions="medium"
                                                    marginBottom={20}
                                                    marginTop={20}
                                                    bold
                                                />
                                                {rowsError.map((item, i) => (
                                                    <ViewInfo
                                                        key={i}
                                                        label={`${item.field}/L${item.line}`}
                                                        value={item.message}
                                                        variant="danger"
                                                    />
                                                ))}
                                            </>
                                        )}

                                        {infoDisplay.filters?.status?.length > 0 && (
                                            <>
                                                <CheckBoxContainer>
                                                    <Text
                                                        text="Status"
                                                        marginBottom={10}
                                                        bold
                                                        dimensions="xLarge"
                                                        variant="primary"
                                                    />
                                                    {infoDisplay.filters?.status.map((field, i) => (
                                                        <Checkbox
                                                            key={i}
                                                            isSquared
                                                            checkboxTitle={`${field.name} (${field.total})`}
                                                            inputProps={{
                                                                value: Number(selectedStatus.includes(field.name)),
                                                                onChange: _toogleStatus(field.name),
                                                            }}
                                                        />
                                                    ))}
                                                </CheckBoxContainer>
                                            </>
                                        )}
                                    </ContainerInfo>
                                    <ContainerCard>
                                        <CardInfo>
                                            <DocIcon size={30} />
                                            <ContainerAmounts>
                                                <Text text={infoDisplay.orders_count} className="counter" font="Exo" />
                                                <Text text="pedidos" className="label" />
                                            </ContainerAmounts>
                                        </CardInfo>
                                        <Text
                                            text={`${infoDisplay.file} - ${bytesToSize(infoDisplay.file_size)}`}
                                            variant="success"
                                            dimensions="small"
                                            className="message"
                                            marginTop={4}
                                        />
                                    </ContainerCard>
                                </ContainerDetails>
                                {infoDisplay.status !== 'completed' && (
                                    <ContianerButtonUpdate>
                                        <DefaultButton outline widthFitContainer onClick={_draft}>
                                            <Feather.Edit /> Rascunho
                                        </DefaultButton>
                                        <DefaultButton
                                            disabled={!!rowsError}
                                            widthFitContainer
                                            className="next"
                                            onClick={_next}
                                        >
                                            Continuar <Feather.ArrowRight />
                                        </DefaultButton>
                                    </ContianerButtonUpdate>
                                )}
                            </>
                        )}
                    </>
                </Container>
                <ModalLoading visible={loading} />
            </LateralModalBase>
        )
    })
)

export { ImportOrdersModal }
