import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import reducers from './reducers'
import sagas from './sagas'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    reducers(history),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk, sagaMiddleware))
)

sagaMiddleware.run(sagas)
const persistor = persistStore(store)

export { store, persistor, history }
