import { createContext } from 'react'

import { MapLayoutProps } from './global-map.types'

interface BoundsOptions {
    maxZoom?: number
    padding?: number
}

interface GlobalMap {
    setMapLayoutProps(props: MapLayoutProps): void
    setMapBoxContentComponent?(component: React.ReactNode): void
    setContentComponent?(component: React.ReactNode): void
    setDisplay?(display: 'none' | 'block'): void
    setFitBoundsOptions?(options: BoundsOptions): void
    setFitBounds?(bounds: any[][]): void
    resizeMap?(): void
    setCenter?(): void
    setMarkerCenter?(marker: number[]): void
    setZoom?(zoom: number): void
    onClickMap?(callback): void
}

const GlobalMapContext = createContext<GlobalMap>({} as GlobalMap)

export default GlobalMapContext
