import './style.scss'

const StoresListingTableHeader = () => {
    return (
        <div className="stores-table-head-container">
            <div className="table-head-item id">ID</div>
            <div className="table-head-item label">Nome</div>
            <div className="table-head-item type">Tipo</div>
            <div className="table-head-item category">Categoria</div>
            <div className="table-head-item status">Status</div>
            <div className="table-head-item button-row" />
        </div>
    )
}

export default StoresListingTableHeader
