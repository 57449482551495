import styled, { css } from 'styled-components'

const MapActionsContainer = styled.div<{ active?: boolean }>`
    padding: 0.938rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    position: absolute;
    top: 5rem;
    cursor: pointer;
    right: 0.625rem;
    .checkbox-input {
        margin: 0;
    }

    ${({ active }) =>
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.primary};
            .label {
                color: #fff !important;
                font-weight: bold;
            }
            .check-container {
                border: 0.126rem solid #fff !important;
                svg {
                    color: #fff !important;
                }
            }
        `}
`

export { MapActionsContainer }
