import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
const Container = styled.div`
    background-color: #f1f5fe;
    width: 200px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
`

const ContainerClose = styled.div`
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
`

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
    font-size: 14px;
`

const Content = styled.div`
    padding-top: 16px;
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
`

const Avatar = styled.img<{ color?: string }>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 4px;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    object-fit: cover;
    top: -30px;
    position: absolute;
    z-index: 10;
`

const BikerName = styled.div`
    margin: 5px 0px;
    font-weight: bold;
    font-size: 14px;
    text-transform: capitalize;
`

const BikerVehicle = styled.div`
    font-size: 14px;
    text-transform: uppercase;
`

const Line = styled.div`
    height: 1px;
    background-color: #909090;
    flex: 1;
`

const FooterPopup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #909090;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
        ${Line} {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

const ButtonText = styled.div`
    font-size: 10px;
    margin: 5px;
`

export { Container, Avatar, BikerName, BikerVehicle, ButtonText, Line, FooterPopup, Content, ContainerClose, Icon }
