import styled, { css } from 'styled-components'

import { DefaultButton } from 'components/default-button/default-button'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const ContentContainer = styled.div`
    flex: 1;
    padding: 0.938rem;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 1.563rem;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 1.5rem;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 1.75rem;
    }
    &::after {
        content: '';
        width: 3.125rem;
        height: 0.188rem;
        position: absolute;
        bottom: -0.625rem;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`
const FormLabel = styled.div`
    margin-bottom: 0.313rem;
`

const FormSection = styled.div`
    margin-bottom: 1.875rem;
`

const FormSectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
    font-weight: 600;
`

const InputsRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }
`

const InputContainer = styled.div<{ hasMargin?: boolean }>`
    width: 100%;

    ${({ hasMargin }) =>
        hasMargin &&
        css`
            margin-bottom: 1.563rem;
        `}
`

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 1rem;
    padding-right: 0.5rem;

    font-size: 1rem;
    font-weight: 500;

    background-color: #eaeaea;
    border-radius: 0.5rem;
`

const ButtonRow = styled.div<{ justify?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => justify || 'space-between'};
    padding: 2rem 1.25rem !important;
    //background-color: #f7f7f7;

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        padding: 0.625rem;
    }
`

const ConfirmButton = styled(DefaultButton)`
    border-radius: 0.375rem;
`
const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
`

const ProductPhoto = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    object-fit: cover;
    align-self: start;
`

const ProductText = styled.div`
    flex: 1;
    flex-direction: column;
`

const ProductName = styled.div`
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3a3a3a;
`

const ProductDescription = styled.div`
    //background-color: #f1f1f1;
    //padding: 10px;
    //border-radius: 12px;
    height: 55px;
    overflow: auto;
    font-size: 0.675rem;
`

const ProductInfo = styled.div`
    display: flex;
    flex: 1;
    gap: 0.5rem;
    //margin-right: 5%;
    //margin-bottom: 20px;
    align-items: center;
    justify-content: center;
`

export {
    ContentContainer,
    ContentTitle,
    FormLabel,
    FormSection,
    FormSectionTitle,
    InputContainer,
    InputsRow,
    OutsideContainer,
    StatusContainer,
    ButtonRow,
    ConfirmButton,
    DeleteIcon,
    ProductDescription,
    ProductName,
    ProductPhoto,
    ProductText,
    ProductInfo,
}
