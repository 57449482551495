import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import { mutate } from 'swr'
import * as Yup from 'yup'

import InfoItem from 'components/_new-general-vision/info-item/info-item'

import { useUI } from 'contexts'
import { formatCEP, formatPhone, formatCurrency, formatDistance, getFormInputError, showErrors } from 'helpers'
import { useDataFetching } from 'hooks'
import api from 'services/api'
import { Bag, SideDish, Tracker } from 'types'

import { InputItem, PaymentTimeline } from '../../_common'

import {
    BagDetailsContainer,
    BagDetailTitleContainer,
    OrderHeader,
    OrderSubtitle,
    OrderTitle,
    ScrollBox,
    CustomerInfo,
    OrderPrice,
    CustomerId,
    CustomerName,
    PriceValue,
    PaymentMethod,
    Address,
    AddressTitle,
    AddressValue,
    OrderItensContainer,
    ItensTitle,
    CloseButton,
    FinalValuesContainer,
    FinalValueItem,
    BagItemContainer,
    BagItemTitle,
    BagItemPart,
    BagItemName,
    BagItemPrice,
    PartContainer,
    BagItemStore,
    BagProductObs,
    TabsContainer,
    TabItem,
    RefundContainer,
    RefundHistoryContainer,
    RefundTitle,
    RefundItem,
    RefundHeaderContainer,
    RefundHeaderItem,
    RefundDate,
    RefundValue,
    RefundCard,
    RefundCardRow,
    CardTitle,
    CardValue,
    RefundCreationContainer,
    PaymentContainer,
    InfoContainer,
    InfoItemContainer,
    RefundRowContainer,
    RefundRowItem,
    RefundButtonContainer,
    RefundButton,
} from './bag-details-content.styles'

interface Props {
    orderId?: number
    closeClick?(): void
}

const BagDetailsContent: React.FC<Props> = ({ orderId, closeClick }) => {
    const { setConfirmationModal, setErrorModal, setLoading, setSnackbar } = useUI()

    const [isDetailsActive, setIsDetailsActive] = useState(false)
    const [isPaymentActive, setIsPaymentActive] = useState(true)
    const [isRefundActive, setIsRefundActive] = useState(false)

    const { data: bag, loading } = useDataFetching<Bag>(orderId ? `/bag/${orderId}` : null)
    const { data: bagTracker } = useDataFetching<Tracker>(orderId ? `/bag/${orderId}/tracker` : null)

    const { errors, getFieldProps, handleSubmit, isValid, resetForm, setFieldValue, touched, validateForm, values } =
        useFormik({
            initialValues: {
                delivery_fee: 0,
                orders: [] as Array<{
                    order_id: number
                    amount: number
                    price: number
                }>,
            },
            validateOnBlur: true,
            validateOnMount: true,
            validationSchema: Yup.object().shape({
                delivery_fee: bag?.delivery_fee
                    ? Yup.number().max(
                          bag?.delivery_fee,
                          `Valor máximo do estorno é ${formatCurrency(bag?.delivery_fee)}`
                      )
                    : Yup.number(),
                orders: Yup.array()
                    .min(1, '')
                    .of(
                        Yup.object().shape({
                            order_id: Yup.number(),
                            price: Yup.number(),
                            amount: Yup.number().when('price', (price: number, schema: Yup.NumberSchema) =>
                                schema.max(price, `Valor máximo do estorno é ${formatCurrency(price)}`)
                            ),
                        })
                    ),
            }),
            onSubmit: async (values, { resetForm }) => {
                try {
                    setLoading(true)

                    const { data } = await api.put(`/painel/bag/${orderId}/cancel`, {
                        delivery_fee: Number(values.delivery_fee) > 0 ? values.delivery_fee : undefined,
                        orders: values.orders
                            .filter(order => order.amount > 0)
                            .map(order => ({ order_id: order.order_id, amount: order.amount })),
                    })

                    mutate(`/bag/${orderId}`, data)

                    setSnackbar({ message: 'Estorno realizado com sucesso' })
                    resetForm()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao estornar',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setLoading(false)
                }
            },
        })

    useEffect(() => {
        resetForm()
    }, [orderId])

    function toggleTab(tab: string) {
        if (tab === 'details') {
            setIsDetailsActive(true)
            setIsPaymentActive(false)
            setIsRefundActive(false)
        }
        if (tab === 'payment') {
            setIsDetailsActive(false)
            setIsPaymentActive(true)
            setIsRefundActive(false)
        }
        if (tab === 'refund') {
            setIsDetailsActive(false)
            setIsPaymentActive(false)
            setIsRefundActive(true)
        }
    }

    function onSubmit() {
        const orders = values.orders.filter(order => order.amount > 0)

        if (!isValid || orders.length === 0) {
            validateForm()
            return
        }

        setConfirmationModal({
            title: 'Estornar valores',
            subtitle: 'Deseja mesmo estornar os valores abaixo?',
            type: 'alert',
            modalIcon: 'check-circle',
            leftButtonText: 'Fechar',
            rightButtonText: 'Confirmar',
            rightButtonClick: () => handleSubmit(),
            children: (
                <>
                    {orders.map(order => {
                        const bagOrder = bagTracker?.orders.find(item => item.id === order.order_id)

                        return (
                            <>
                                <small key={order.order_id}>
                                    {bagOrder?.store?.name} - {formatCurrency(order.amount)}
                                </small>
                                <br />
                            </>
                        )
                    })}
                    {Number(values.delivery_fee) > 0 ? (
                        <small>Frete - {formatCurrency(values.delivery_fee)}</small>
                    ) : null}
                </>
            ),
        })
    }

    return (
        <BagDetailsContainer>
            {loading || !bag ? (
                <>
                    <BagDetailTitleContainer>
                        <Skeleton height={40} width={300} />
                        <Skeleton height={2} width={300} />
                        <Skeleton height={30} width={500} />
                    </BagDetailTitleContainer>

                    <ScrollBox>
                        <OrderItensContainer>
                            <Skeleton height={30} width={200} style={{ marginBottom: 20 }} />

                            <Skeleton count={3} height={100} style={{ marginBottom: 10 }} />
                        </OrderItensContainer>
                    </ScrollBox>
                </>
            ) : (
                <>
                    <BagDetailTitleContainer>
                        <OrderTitle>Venda #{bag.id}</OrderTitle>
                        <OrderSubtitle>Criação: {format(new Date(bag.created_at), 'DD/MM/YYYY - HH:mm')}</OrderSubtitle>
                        <CloseButton onClick={closeClick} />
                    </BagDetailTitleContainer>
                    <TabsContainer>
                        <TabItem isActive={isDetailsActive} onClick={() => toggleTab('details')}>
                            Detalhes
                        </TabItem>
                        <TabItem isActive={isPaymentActive} onClick={() => toggleTab('payment')}>
                            Pagamento
                        </TabItem>
                        <TabItem isActive={isRefundActive} onClick={() => toggleTab('refund')}>
                            Estorno
                        </TabItem>
                    </TabsContainer>
                    <ScrollBox>
                        {isDetailsActive ? (
                            <>
                                <OrderHeader>
                                    <CustomerInfo>
                                        <CustomerName>{bag.user.name}</CustomerName>
                                        <CustomerId>{formatPhone(bag.user.cellphone)}</CustomerId>
                                    </CustomerInfo>
                                    <OrderPrice>
                                        <PriceValue>{formatCurrency(bag.total)}</PriceValue>
                                        <PaymentMethod>{bag.payment.name}</PaymentMethod>
                                    </OrderPrice>
                                    <Address>
                                        <AddressTitle>Endereço de entrega</AddressTitle>
                                        <AddressValue>
                                            {formatCEP(bag.address.zipcode)} - {bag.address.neighborhood}
                                        </AddressValue>
                                        <AddressValue>
                                            {bag.address.street}, {bag.address.number} ({formatDistance(bag.distance)})
                                        </AddressValue>
                                        {bag.address.complement && (
                                            <AddressValue>{bag.address.complement}</AddressValue>
                                        )}
                                        {bag.observation && (
                                            <AddressValue>
                                                <br />
                                                <b>Observação:</b>
                                                <br /> {bag.observation}
                                            </AddressValue>
                                        )}
                                    </Address>
                                </OrderHeader>
                                <OrderItensContainer>
                                    <ItensTitle>Itens da Bag</ItensTitle>
                                    {bag.products.map((bagProduct, index) => (
                                        <BagItemContainer key={index}>
                                            <BagItemStore>{bagProduct.store.name}</BagItemStore>
                                            <BagItemTitle>
                                                <BagItemName>{bagProduct.name}</BagItemName>
                                                <BagItemPrice>{formatCurrency(bagProduct.price)}</BagItemPrice>
                                            </BagItemTitle>
                                            {bagProduct.parts.map((part, partIndex) => (
                                                <PartContainer key={partIndex}>
                                                    {(part.side_dishes as SideDish[]).map((dish, dishIndex) => (
                                                        <BagItemPart key={dishIndex}>
                                                            <BagItemName>
                                                                {dish.quantity}x - {dish.name}
                                                            </BagItemName>
                                                            <BagItemPrice>{formatCurrency(dish.price)}</BagItemPrice>
                                                        </BagItemPart>
                                                    ))}
                                                </PartContainer>
                                            ))}
                                            {bagProduct.obs && <BagProductObs>{bagProduct.obs}</BagProductObs>}
                                        </BagItemContainer>
                                    ))}
                                </OrderItensContainer>
                                <FinalValuesContainer>
                                    <FinalValueItem>Subtotal: {formatCurrency(bag.subtotal)}</FinalValueItem>
                                    <FinalValueItem>Taxa de entrega: {formatCurrency(bag.delivery_fee)}</FinalValueItem>
                                    <FinalValueItem>Total do pedido: {formatCurrency(bag.total)}</FinalValueItem>
                                </FinalValuesContainer>
                            </>
                        ) : isPaymentActive ? (
                            <PaymentContainer>
                                <ItensTitle>Informações do Pagamento</ItensTitle>
                                <InfoContainer>
                                    <InfoItemContainer>
                                        <InfoItem
                                            label="ID da Transação"
                                            content={bag.financial_transaction?.transaction_id}
                                        />
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItem label="Produto" content={bag.payment.name} />
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItem label="NSU" content={bag.financial_transaction?.nsu} />
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItem
                                            label="Data de autorização"
                                            content={format(
                                                new Date(bag.financial_transaction?.authorized_date),
                                                'DD/MM/YYYY - HH:mm'
                                            )}
                                        />
                                    </InfoItemContainer>

                                    <InfoItemContainer>
                                        <InfoItem
                                            label="Data de Captura"
                                            content={format(
                                                new Date(bag.financial_transaction?.capture_data),
                                                'DD/MM/YYYY - HH:mm'
                                            )}
                                        />
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItem label="Valor Capturado" content={formatCurrency(bag.total)} />
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItem
                                            label="Número de Parcelas"
                                            content={`${bag.financial_transaction?.installments} parcela${
                                                bag.financial_transaction?.installments > 1 ? 's' : ''
                                            }`}
                                        />
                                    </InfoItemContainer>
                                    {bag.financial_transaction?.comissions && (
                                        <InfoItemContainer>
                                            <InfoItem
                                                label="Comissão"
                                                contentList={bag.financial_transaction?.comissions?.map(
                                                    comission => `${comission.store} - ${comission.percentage}`
                                                )}
                                            />
                                        </InfoItemContainer>
                                    )}
                                    <InfoItemContainer>
                                        <InfoItem label="Status" content={bag.financial_transaction?.status.name} />
                                    </InfoItemContainer>
                                    <InfoItemContainer>
                                        <InfoItem
                                            label="Número do Cartão"
                                            content={`•••• •••• •••• ${bag.card?.last_digits}`}
                                        />
                                    </InfoItemContainer>
                                </InfoContainer>
                                <PaymentTimeline statusList={bag.financial_transaction?.logs} />
                            </PaymentContainer>
                        ) : isRefundActive ? (
                            <RefundContainer>
                                <RefundCardRow>
                                    <RefundCard>
                                        <CardTitle>Valor Total</CardTitle>
                                        <CardValue>{formatCurrency(bag.total)}</CardValue>
                                    </RefundCard>
                                    <RefundCard>
                                        <CardTitle>Valor Estornado</CardTitle>
                                        <CardValue>
                                            {formatCurrency(
                                                bag?.financial_transaction?.transactions_refunded.reduce(
                                                    (acc, refund) => acc + refund.amount,
                                                    0
                                                )
                                            )}
                                        </CardValue>
                                    </RefundCard>
                                </RefundCardRow>
                                {bag?.financial_transaction?.transactions_refunded?.length > 0 && (
                                    <RefundHistoryContainer>
                                        <RefundTitle>Histórico de estornos</RefundTitle>
                                        {bag?.financial_transaction?.transactions_refunded?.map((refund, index) => (
                                            <RefundItem key={index}>
                                                <RefundDate>
                                                    {format(new Date(refund.created_at), 'DD/MM/YYYY - HH:mm')}
                                                </RefundDate>
                                                <RefundValue>
                                                    {refund.order_id ? `Loja: ${refund.store.name}` : 'Frete'} -{' '}
                                                    {formatCurrency(refund.amount)}
                                                </RefundValue>
                                            </RefundItem>
                                        ))}
                                    </RefundHistoryContainer>
                                )}
                                {bagTracker?.orders && Number(bag?.financial_transaction?.status?.id) === 2 ? (
                                    <RefundCreationContainer>
                                        <RefundTitle>Realizar Estorno</RefundTitle>

                                        <RefundHeaderContainer>
                                            <RefundHeaderItem width="30%">Loja</RefundHeaderItem>
                                            <RefundHeaderItem width="30%">Valor</RefundHeaderItem>
                                            <RefundHeaderItem width="40%">Estornar</RefundHeaderItem>
                                        </RefundHeaderContainer>

                                        {bagTracker?.orders.map((order, index) => (
                                            <RefundRowContainer key={index}>
                                                <RefundRowItem width="30%">{order.store?.name}</RefundRowItem>
                                                <RefundRowItem width="30%">
                                                    {formatCurrency(order.price?.total)}
                                                </RefundRowItem>

                                                <RefundRowItem width="40%">
                                                    <InputItem
                                                        type="currency"
                                                        inputProps={{
                                                            ...getFieldProps(`orders[${index}].amount`),
                                                            onChange: ({
                                                                target: { value },
                                                            }: React.ChangeEvent<HTMLInputElement>) => {
                                                                setFieldValue(`orders[${index}].order_id`, order.id)
                                                                setFieldValue(`orders[${index}].amount`, value)
                                                                setFieldValue(
                                                                    `orders[${index}].price`,
                                                                    order.price?.total
                                                                )
                                                            },
                                                        }}
                                                        errorMessage={getFormInputError(
                                                            `orders[${index}].amount`,
                                                            errors,
                                                            touched
                                                        )}
                                                    />
                                                </RefundRowItem>
                                            </RefundRowContainer>
                                        ))}

                                        <RefundRowContainer>
                                            <RefundRowItem width="30%">Frete</RefundRowItem>
                                            <RefundRowItem width="30%">
                                                {formatCurrency(bag.delivery_fee)}
                                            </RefundRowItem>

                                            <RefundRowItem width="40%">
                                                <InputItem
                                                    type="currency"
                                                    inputProps={{
                                                        ...getFieldProps('delivery_fee'),
                                                    }}
                                                    errorMessage={getFormInputError('delivery_fee', errors, touched)}
                                                />
                                            </RefundRowItem>
                                        </RefundRowContainer>
                                        <RefundButtonContainer>
                                            <RefundButton isActive={isValid} onClick={onSubmit}>
                                                Estornar
                                            </RefundButton>
                                        </RefundButtonContainer>
                                    </RefundCreationContainer>
                                ) : null}
                            </RefundContainer>
                        ) : null}
                    </ScrollBox>
                </>
            )}
        </BagDetailsContainer>
    )
}

export default BagDetailsContent
