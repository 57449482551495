export const orders = [
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        scheduleInterval: '12:00 às 12:30',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        scheduleInterval: '12:00 às 12:30',
    },
]
