import { ScaleLoader } from 'react-spinners'

import colors from '../../themes/colors'
import './style.scss'

interface Props {
    color?: string
    size?: number
}

const Spinner: React.FC<Props> = ({ color = colors.light_primary_color, size = 50 }) => {
    return (
        <div className="spinner">
            <ScaleLoader color={color} loading={true} height={size} />
        </div>
    )
}

export default Spinner
