import { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { faRedo, faCalendarCheck } from '@fortawesome/free-solid-svg-icons'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import {
    DynamicRatesTableRow,
    DynamicRatesTableHeader,
} from 'components/dynamic-rates-table-row/dynamic-rates-table-row'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { setSearchParams, showErrors } from 'helpers'
import { useAuth, useFetchPagination } from 'hooks'
import api from 'services/api'
import { RateMultiplier } from 'types'

import './style.scss'
import {
    NavigationItem,
    NavigationItemContainer,
    ContainerNavigation,
    NavigationIcon,
} from './dynamic-rate-listing.styled'

const DynamicRatesListing: React.FC = () => {
    const history = useHistory()
    const location = useLocation()

    const { basePath, mall } = useAuth()

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)

    const [loading, toggleLoading] = useState(false)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [deleteID, setDeleteID] = useState<number | null>(null)
    const [type, setType] = useState<'single' | 'periodic'>('single')

    const {
        count,
        items: dynamicRates,
        loading: loadingDynamicRates,
        revalidate,
        setSize,
        size,
        totals,
    } = useFetchPagination<RateMultiplier>('/painel/rate-multipliers', {
        params: { mall_id: mall.id, order: 'desc', order_by: 'start_at', type },
        onError: () => {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados. Tente novamente mais tarde.',
                onClose: () => history.push(`${basePath}/tarifas-dinamicas`),
            })

            modalMessage.current?.openModal()
        },
    })

    const deleteRate = async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate-multiplier/${deleteID}`)

            revalidate()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }

    useEffect(() => {
        setSearchParams({ type }, location, history)
    }, [type])

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const type = (query.get('type') || 'single') as 'single' | 'periodic'
        setType(type)
    }, [])

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="tag">Esta unidade não possui tarifas dinâmicas cadastradas.</EmptyMessage>
    }

    return (
        <div className="dynamic-rates-page-container">
            <ModalLoading visible={loading || (!count && loadingDynamicRates)} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Deseja realmente excluir esta tarifa?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Tarifas Dinâmicas"
                buttons={[
                    {
                        title: 'Criar tarifa dinâmica',
                        onClick: () => history.push(`${basePath}/criar-tarifa-dinamica`),
                    },
                ]}
            />
            <ContainerNavigation>
                <NavigationItemContainer isActive={type === 'single'} onClick={() => setType('single')}>
                    <NavigationItem isActive={type === 'single'}>
                        <NavigationIcon icon={faCalendarCheck} isActive={type === 'single'} /> Pontuais
                    </NavigationItem>
                </NavigationItemContainer>
                <NavigationItemContainer isActive={type === 'periodic'} onClick={() => setType('periodic')}>
                    <NavigationItem isActive={type === 'periodic'}>
                        <NavigationIcon icon={faRedo} isActive={type === 'periodic'} /> Recorrentes
                    </NavigationItem>
                </NavigationItemContainer>
            </ContainerNavigation>

            <DynamicRatesTableHeader isSingle={type === 'single'} />
            {dynamicRates?.map((item, index) => (
                <DynamicRatesTableRow
                    key={index}
                    isSingle={type === 'single'}
                    rate={item}
                    editClick={() => history.push(`${basePath}/tarifa-dinamica/${item.id}`)}
                    deleteClick={() => {
                        setDeleteID(item.id)

                        modalDelete.current?.openModal()
                    }}
                />
            ))}

            {!count && !loadingDynamicRates && renderEmptyMessage()}

            <ButtonLoadMore
                loading={loadingDynamicRates}
                visible={totals > 0 && count < totals}
                onClick={() => setSize(size + 1)}
            />
        </div>
    )
}

export default DynamicRatesListing
