import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { booleanPointInPolygon, point } from '@turf/turf'
import { format, isAfter } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { RatesReplicationModal } from 'components/_rates'
import ButtonForm from 'components/button-form'
import CheckboxInput from 'components/checkbox-input'
import DatepickerInput from 'components/datepicker-input'
import MapboxView, { PolygonFill, InfoMapMessage } from 'components/mapbox-view'
import MensagemErro from 'components/mensagem-erro'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PaymentArea, RateMultiplier } from 'types'

import './style.scss'
import { BoxDayWeek, ContainerDayWeek, Label } from './dynamic-rate-creation.styled'

const statusArray = [
    {
        name: 'Ativo',
        value: 'true',
    },
    {
        name: 'Inativo',
        value: 'false',
    },
]

const DynamicRateCreation: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match: { params } }) => {
    const { basePath, mall } = useAuth()

    const [loading, toggleLoading] = useState(false)
    const [isReplicationModalActive, setIsReplicationModalActive] = useState(false)
    const [paymentAreas, setPaymentAreas] = useState<PaymentArea[]>([])
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        textButton: '',
        onClose: () => null,
    })

    const validateMinRateValue = Yup.number()
        .when('not_work', {
            is: not_work => not_work === false,
            then: (schema: Yup.NumberSchema) => schema.min(0.0, 'Tarifa é obrigatória'),
        })
        .nullable()

    const {
        errors,
        handleSubmit,
        setFieldTouched,
        initialValues,
        isValid,
        isSubmitting,
        setFieldValue,
        setValues,
        touched,
        validateForm,
        values,
    } = useFormik({
        initialValues: {
            id: 0,
            reason: '',
            type: 'single',
            payment_areas: [],
            week_days: null,
            start_at: { date: '', time: '' },
            finish_at: { date: '', time: '' },
            values: {
                bike: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                moto: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                car: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                van: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                truck: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
            },
            status: 'true',
        },
        validationSchema: Yup.object().shape({
            type: Yup.string().trim().required('Por favor, selecione o tipo de tarifa'),
            week_days: Yup.array().when('type', {
                is: 'periodic',
                then: Yup.array().required('Dias da semana são obrigatório').min(1, 'Selecione os dias da semana'),
                otherwise: Yup.array().nullable().notRequired(),
            }),
            reason: Yup.string().trim().required('Por favor, preencha com a Ocasião'),
            start_at: Yup.object().shape({
                date: Yup.date().required('Data de início é obrigatória'),
                time: Yup.string().required('Hora de início é obrigatória'),
            }),
            finish_at: Yup.object().when(
                'start_at',
                (start_at: { date: Date; time: string }, schema: Yup.ObjectSchema) =>
                    schema.shape({
                        date: start_at?.date
                            ? Yup.date()
                                  .min(start_at.date, 'Data de fim deve ser após a inicial')
                                  .required('Data de fim é obrigatória')
                            : Yup.date().required('Data de fim é obrigatória'),
                        time: Yup.string()
                            .required('Hora de fim é obrigatória')
                            .when('date', (date: Date, schema: Yup.StringSchema) =>
                                schema.test({
                                    test: time => {
                                        if (time) {
                                            const startDate = getDateWithTime(start_at.date, start_at.time)

                                            const finishDate = getDateWithTime(date, time)

                                            return isAfter(finishDate, startDate)
                                        } else {
                                            return false
                                        }
                                    },
                                    message: 'Hora de fim deve ser após a inicial',
                                })
                            ),
                    })
            ),
            values: Yup.object().shape({
                bike: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                moto: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                car: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                van: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                truck: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
            }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                if (!values.payment_areas?.length) {
                    setModalMessageData({
                        title: 'Alerta',
                        message: 'É necessário escolher uma ou mais áreas de cobrança para a criação da tarifa.',
                    })

                    modalMessage.current?.openModal()
                    return
                }

                const formattedRate = getFormattedRate()

                const body = {
                    ...formattedRate,
                    payment_areas: values.payment_areas.map((item: PaymentArea) => item.id),
                    values: undefined,
                }

                if (values?.id) {
                    await api.put(`/painel/rate-multiplier/${values.id}`, body)
                } else {
                    await api.post('/painel/rate-multiplier', body)
                }

                setModalMessageData({
                    title: 'Sucesso!',
                    message: `Tarifa ${values?.id ? 'atualizada' : 'criada'} com sucesso.`,
                    onClose: () => history.push(`${basePath}/tarifas-dinamicas?type=${values.type}`),
                })
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                })
            } finally {
                setSubmitting(false)
                modalMessage.current?.openModal()
            }
        },
    })

    const getSelectData = useCallback(async () => {
        try {
            toggleLoading(true)
            const { data } = await api.get(`/painel/payment-areas`, {
                params: {
                    mall_id: mall.id,
                    per_page: -1,
                    status: [1],
                },
            })

            setPaymentAreas(data.items)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/tarifas-dinamicas`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [history, mall])

    const _setDayWeek = useCallback(
        (day: 'dom' | 'seg' | 'ter' | 'qua' | 'qui' | 'sex' | 'sab') => {
            return () => {
                if (values.week_days) {
                    setFieldTouched('week_days', true)
                    const weeks = values.week_days
                    if (weeks.includes(day)) {
                        setFieldValue(
                            'week_days',
                            weeks.filter(item => item !== day)
                        )
                    } else {
                        setFieldValue('week_days', [...weeks, day])
                    }
                }
            }
        },
        [values.week_days, setFieldValue, setFieldTouched]
    )

    useEffect(() => {
        if (values.type === 'single') {
            setFieldTouched('week_days', false)
            setFieldValue('week_days', null)
        } else {
            setFieldValue('start_at.date', format(new Date(), 'YYYY-MM-DD'))
            setFieldValue('finish_at.date', format(new Date(), 'YYYY-MM-DD'))
            setFieldValue('week_days', [])
        }
    }, [values.type])

    useEffect(() => {
        getSelectData()
    }, [getSelectData])

    const getRate = useCallback(
        async id => {
            try {
                toggleLoading(true)
                const { data } = await api.get(`/painel/rate-multiplier/${id}`)

                const { agent, store } = data.value
                console.log('data', data)

                setValues({
                    ...initialValues,
                    ...data,
                    status: data.status === 1 ? 'true' : 'false',
                    values: {
                        bike: {
                            not_work: agent.bike == null && store.bike == null,
                            agent: agent.bike,
                            store: store.bike,
                        },
                        moto: {
                            not_work: agent.moto == null && store.moto == null,
                            agent: agent.moto,
                            store: store.moto,
                        },
                        car: {
                            not_work: agent.car == null && store.car == null,
                            agent: agent.car,
                            store: store.car,
                        },
                        van: {
                            not_work: agent.van == null && store.van == null,
                            agent: agent.van,
                            store: store.van,
                        },
                        truck: {
                            not_work: agent.truck == null && store.truck == null,
                            agent: agent.truck,
                            store: store.truck,
                        },
                    },
                    start_at: {
                        date: format(data.start_at, 'YYYY-MM-DD'),
                        time: format(data.start_at, 'HH:mm'),
                    },
                    finish_at: {
                        date: format(data.finish_at, 'YYYY-MM-DD'),
                        time: format(data.finish_at, 'HH:mm'),
                    },
                })

                setTimeout(() => {
                    setFieldValue('week_days', data.week_days)
                }, 500)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                    onClose: () => history.push(`${basePath}/tarifas-dinamicas`),
                })
                modalMessage.current?.openModal()
            } finally {
                toggleLoading(false)
            }
        },
        [history, initialValues, setValues]
    )

    useEffect(() => {
        console.log('errors', errors)
    }, [errors])
    useEffect(() => {
        params.id && getRate(params.id)
    }, [getRate, params.id])

    function getFormattedRate(): RateMultiplier {
        const { bike, moto, car, van, truck } = values.values

        return {
            ...values,
            payment_areas: values.payment_areas,
            start_at: `${values.start_at.date} ${values.start_at.time + ':00'}`,
            finish_at: `${values.finish_at.date} ${values.finish_at.time + ':00'}`,
            status: values.status === 'true' ? 1 : 0,
            value: {
                agent: {
                    bike: !bike.not_work ? Number(bike.agent) : null,
                    moto: !moto.not_work ? Number(moto.agent) : null,
                    car: !car.not_work ? Number(car.agent) : null,
                    van: !van.not_work ? Number(van.agent) : null,
                    truck: !truck.not_work ? Number(truck.agent) : null,
                },
                store: {
                    bike: !bike.not_work ? Number(bike.store) : null,
                    moto: !moto.not_work ? Number(moto.store) : null,
                    car: !car.not_work ? Number(car.store) : null,
                    van: !van.not_work ? Number(van.store) : null,
                    truck: !truck.not_work ? Number(truck.store) : null,
                },
            },
        }
    }

    async function deleteRate(rateId: number) {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate-multiplier/${rateId}`)

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Tarifa removida com sucesso.',
                onClose: () => history.push(`${basePath}/tarifas-dinamicas`),
            })

            modalMessage.current?.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/tarifas-dinamicas`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }

    function getDateWithTime(date: Date, time: string) {
        const [hour, minutes] = time?.split(':')
        date?.setHours(Number(hour))
        date?.setMinutes(Number(minutes))

        return date
    }

    function checkPaymentArea({ lngLat }: { lngLat: { lat: number; lng: number } }) {
        const searchPoint = point([lngLat.lng, lngLat.lat])
        const areaClicked = paymentAreas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

        if (values.payment_areas.some((item: PaymentArea) => item.id === areaClicked?.id)) {
            setFieldValue(
                'payment_areas',
                values.payment_areas.filter((item: PaymentArea) => item.id !== areaClicked?.id)
            )
        } else {
            setFieldValue('payment_areas', [...values.payment_areas, areaClicked])
        }
    }

    const { bike, moto, car, van, truck } = values.values

    return (
        <div className="page-container">
            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                textButton={modalMessageData.textButton}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Deseja realmente excluir esta tarifa?"
                onYes={() => values?.id !== 0 && deleteRate(values.id)}
            />

            <RatesReplicationModal
                isActive={isReplicationModalActive}
                rateMultiplier={getFormattedRate()}
                closeClick={() => setIsReplicationModalActive(false)}
            />

            <TopRowDefault
                title="Tarifa Dinâmica"
                onBackButtonClick={() => history.push(`${basePath}/tarifas-dinamicas`)}
                buttons={
                    params.id && [
                        {
                            title: 'Excluir Tarifa Dinâmica',
                            icon: 'trash-alt',
                            onClick: () => modalDelete.current?.openModal(),
                        },
                    ]
                }
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title">
                            {params.id
                                ? `Editar Tarifa Dinâmica ${values.type === 'single' ? 'Pontual' : 'Recorrente'}`
                                : 'Criar Tarifa Dinâmica'}
                        </div>

                        <div className="rate-label">
                            <TextInputForm
                                label={'Ocasião*'}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('reason', value)
                                }
                                value={values.reason}
                                msgErro={touched.reason && errors.reason}
                            />
                        </div>

                        <div className="datetime-inputs-container">
                            <div className="datetime-inputs">
                                <div className="input-item">
                                    <SelectInputForm
                                        label="Tipo de tarifa*"
                                        data={[
                                            { name: 'Pontual', value: 'single' },
                                            { name: 'Recorrente', value: 'periodic' },
                                        ]}
                                        value={values.type}
                                        onChange={e => setFieldValue('type', e.target.value)}
                                        msgErro={errors.type}
                                    />
                                </div>
                                <div className="input-item"></div>
                            </div>
                            {values.type === 'periodic' && (
                                <>
                                    <Label>Dias da semana*</Label>
                                    <ContainerDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('dom')}
                                            active={values.week_days?.includes('dom')}
                                        >
                                            D
                                        </BoxDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('seg')}
                                            active={values.week_days?.includes('seg')}
                                        >
                                            S
                                        </BoxDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('ter')}
                                            active={values.week_days?.includes('ter')}
                                        >
                                            T
                                        </BoxDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('qua')}
                                            active={values.week_days?.includes('qua')}
                                        >
                                            Q
                                        </BoxDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('qui')}
                                            active={values.week_days?.includes('qui')}
                                        >
                                            Q
                                        </BoxDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('sex')}
                                            active={values.week_days?.includes('sex')}
                                        >
                                            S
                                        </BoxDayWeek>
                                        <BoxDayWeek
                                            onClick={_setDayWeek('sab')}
                                            active={values.week_days?.includes('sab')}
                                        >
                                            S
                                        </BoxDayWeek>
                                    </ContainerDayWeek>
                                    <MensagemErro msgErro={touched.week_days && errors.week_days} />
                                </>
                            )}
                            {values.type === 'single' && (
                                <>
                                    <div className="datetime-inputs">
                                        <div className="input-item">
                                            <DatepickerInput
                                                fieldHeight
                                                label="Data Inicial*"
                                                placeholder="DD/MM/YYYY"
                                                onChange={(date: Date) =>
                                                    setFieldValue('start_at.date', format(date, 'YYYY-MM-DD'))
                                                }
                                                value={values.start_at.date}
                                                msgErro={touched.start_at?.date && errors.start_at?.date}
                                            />
                                        </div>
                                        <div className="input-item">
                                            <TextInputForm
                                                label={'Hora Inicial*'}
                                                type="time"
                                                placeholder="hh:mm"
                                                onChange={({
                                                    target: { value },
                                                }: React.ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('start_at.time', value)
                                                }
                                                value={values.start_at.time}
                                                msgErro={touched.start_at?.time && errors.start_at?.time}
                                            />
                                        </div>
                                    </div>
                                    <div className="datetime-inputs">
                                        <div className="input-item">
                                            <DatepickerInput
                                                fieldHeight
                                                label="Data Final*"
                                                placeholder="DD/MM/YYYY"
                                                onChange={(date: Date) =>
                                                    setFieldValue('finish_at.date', format(date, 'YYYY-MM-DD'))
                                                }
                                                value={values.finish_at.date}
                                                msgErro={touched.finish_at?.date && errors.finish_at?.date}
                                            />
                                        </div>
                                        <div className="input-item">
                                            <TextInputForm
                                                label={'Hora Final*'}
                                                onChange={({
                                                    target: { value },
                                                }: React.ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('finish_at.time', value)
                                                }
                                                value={values.finish_at.time}
                                                msgErro={touched.finish_at?.time && errors.finish_at?.time}
                                                placeholder="hh:mm"
                                                type="time"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {values.type === 'periodic' && (
                                <>
                                    <div className="datetime-inputs">
                                        <div className="input-item">
                                            <TextInputForm
                                                label={'Hora Inicial*'}
                                                type="time"
                                                placeholder="hh:mm"
                                                onChange={({
                                                    target: { value },
                                                }: React.ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('start_at.time', value)
                                                }
                                                value={values.start_at.time}
                                                msgErro={touched.start_at?.time && errors.start_at?.time}
                                            />
                                        </div>
                                        <div className="input-item">
                                            <TextInputForm
                                                label={'Hora Final*'}
                                                onChange={({
                                                    target: { value },
                                                }: React.ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('finish_at.time', value)
                                                }
                                                value={values.finish_at.time}
                                                msgErro={touched.finish_at?.time && errors.finish_at?.time}
                                                placeholder="hh:mm"
                                                type="time"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="section-title-container">
                                <span className="section-title">Tarifa para Bicicleta</span>
                            </div>

                            <CheckboxInput
                                id="bike_not_work"
                                label={'Não opera neste turno*'}
                                onChange={({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('values.bike.not_work', checked)

                                    if (checked) {
                                        setFieldValue('values.bike.agent', null)
                                        setFieldValue('values.bike.store', null)
                                    } else {
                                        setFieldValue('values.bike.agent', '0.0')
                                        setFieldValue('values.bike.store', '0.0')
                                    }
                                }}
                                checked={bike.not_work}
                            />

                            <div className="price-inputs-container">
                                <div className="price-input">
                                    <TextInputForm
                                        label={'Restaurante*'}
                                        value={bike.store}
                                        onChange={(floatValue: number) =>
                                            setFieldValue('values.bike.store', floatValue)
                                        }
                                        msgErro={touched.values?.bike?.store && errors.values?.bike?.store}
                                        disabled={bike.not_work}
                                        type="currency"
                                    />
                                </div>

                                <div className="price-input">
                                    <TextInputForm
                                        label={'Entregador*'}
                                        value={bike.agent}
                                        onChange={(floatValue: number) =>
                                            setFieldValue('values.bike.agent', floatValue)
                                        }
                                        msgErro={touched.values?.bike?.agent && errors.values?.bike?.agent}
                                        disabled={bike.not_work}
                                        type="currency"
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Tarifa para Motocicleta</span>
                            </div>

                            <CheckboxInput
                                id="motorcycle_not_work"
                                label={'Não opera neste turno*'}
                                onChange={({ target: { checked } }) => {
                                    setFieldValue('values.moto.not_work', checked)

                                    if (checked) {
                                        setFieldValue('values.moto.agent', null)
                                        setFieldValue('values.moto.store', null)
                                    } else {
                                        setFieldValue('values.moto.agent', '0.0')
                                        setFieldValue('values.moto.store', '0.0')
                                    }
                                }}
                                checked={moto.not_work}
                            />

                            <div className="price-inputs-container">
                                <div className="price-input">
                                    <TextInputForm
                                        label={'Restaurante*'}
                                        value={moto.store}
                                        onChange={(floatValue: number) =>
                                            setFieldValue('values.moto.store', floatValue)
                                        }
                                        msgErro={touched.values?.moto?.store && errors.values?.moto?.store}
                                        disabled={moto.not_work}
                                        type="currency"
                                    />
                                </div>

                                <div className="price-input">
                                    <TextInputForm
                                        label={'Entregador*'}
                                        value={moto.agent}
                                        onChange={(floatValue: number) =>
                                            setFieldValue('values.moto.agent', floatValue)
                                        }
                                        msgErro={touched.values?.moto?.agent && errors.values?.moto?.agent}
                                        disabled={moto.not_work}
                                        type="currency"
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Tarifa para Carro</span>
                            </div>

                            <CheckboxInput
                                id="car_not_work"
                                label={'Não opera neste turno*'}
                                onChange={({ target: { checked } }) => {
                                    setFieldValue('values.car.not_work', checked)

                                    if (checked) {
                                        setFieldValue('values.car.agent', null)
                                        setFieldValue('values.car.store', null)
                                    } else {
                                        setFieldValue('values.car.agent', '0.0')
                                        setFieldValue('values.car.store', '0.0')
                                    }
                                }}
                                checked={car.not_work}
                            />

                            <div className="price-inputs-container">
                                <div className="price-input">
                                    <TextInputForm
                                        label={'Restaurante*'}
                                        value={car.store}
                                        onChange={(floatValue: number) => setFieldValue('values.car.store', floatValue)}
                                        msgErro={touched.values?.car?.store && errors.values?.car?.store}
                                        disabled={car.not_work}
                                        type="currency"
                                    />
                                </div>

                                <div className="price-input">
                                    <TextInputForm
                                        label={'Entregador*'}
                                        value={car.agent}
                                        onChange={(floatValue: number) => setFieldValue('values.car.agent', floatValue)}
                                        msgErro={touched.values?.car?.agent && errors.values?.car?.agent}
                                        disabled={car.not_work}
                                        type="currency"
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Tarifa para Van</span>
                            </div>

                            <CheckboxInput
                                id="van_not_work"
                                label={'Não opera neste turno*'}
                                onChange={({ target: { checked } }) => {
                                    setFieldValue('values.van.not_work', checked)

                                    if (checked) {
                                        setFieldValue('values.van.agent', null)
                                        setFieldValue('values.van.store', null)
                                    } else {
                                        setFieldValue('values.van.agent', '0.0')
                                        setFieldValue('values.van.store', '0.0')
                                    }
                                }}
                                checked={van.not_work}
                            />

                            <div className="price-inputs-container">
                                <div className="price-input">
                                    <TextInputForm
                                        label={'Restaurante*'}
                                        value={van.store}
                                        onChange={(floatValue: number) => setFieldValue('values.van.store', floatValue)}
                                        msgErro={touched.values?.van?.store && errors.values?.van?.store}
                                        disabled={van.not_work}
                                        type="currency"
                                    />
                                </div>

                                <div className="price-input">
                                    <TextInputForm
                                        label={'Entregador*'}
                                        value={van.agent}
                                        onChange={(floatValue: number) => setFieldValue('values.van.agent', floatValue)}
                                        msgErro={touched.values?.van?.agent && errors.values?.van?.agent}
                                        disabled={van.not_work}
                                        type="currency"
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Tarifa para Caminhão</span>
                            </div>

                            <CheckboxInput
                                id="truck_not_work"
                                label={'Não opera neste turno*'}
                                onChange={({ target: { checked } }) => {
                                    setFieldValue('values.truck.not_work', checked)

                                    if (checked) {
                                        setFieldValue('values.truck.agent', null)
                                        setFieldValue('values.truck.store', null)
                                    } else {
                                        setFieldValue('values.truck.agent', '0.0')
                                        setFieldValue('values.truck.store', '0.0')
                                    }
                                }}
                                checked={truck.not_work}
                            />

                            <div className="price-inputs-container">
                                <div className="price-input">
                                    <TextInputForm
                                        label={'Restaurante*'}
                                        value={truck.store}
                                        onChange={(floatValue: number) =>
                                            setFieldValue('values.truck.store', floatValue)
                                        }
                                        msgErro={touched.values?.truck?.store && errors.values?.truck?.store}
                                        disabled={truck.not_work}
                                        type="currency"
                                    />
                                </div>

                                <div className="price-input">
                                    <TextInputForm
                                        label={'Entregador*'}
                                        value={truck.agent}
                                        onChange={(floatValue: number) =>
                                            setFieldValue('values.truck.agent', floatValue)
                                        }
                                        msgErro={touched.values?.truck?.agent && errors.values?.truck?.agent}
                                        disabled={truck.not_work}
                                        type="currency"
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ width: 200 }}>
                            <SelectInputForm
                                label="Status"
                                data={statusArray}
                                value={values.status}
                                onChange={({ target: { value } }) => setFieldValue('status', value)}
                                msgErro={touched.status && errors.status}
                            />
                        </div>

                        {isValid && values?.id && values.type === 'single' ? (
                            <ButtonForm
                                type="submit"
                                secondary
                                buttonText="Replicar tarifa"
                                onClick={async () => {
                                    const errors = await validateForm()

                                    if (Object.keys(errors).length > 0) return

                                    setIsReplicationModalActive(true)
                                }}
                                style={{ width: '100%', margin: 0, marginTop: 10 }}
                            />
                        ) : null}
                        <ButtonForm
                            type="submit"
                            buttonText="Salvar"
                            onClick={() => handleSubmit()}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="right-column">
                        <MapboxView>
                            <PolygonFill
                                id="dynamic-rate-areas"
                                showPopUpArea
                                areaType="rate-area"
                                areas={paymentAreas}
                                onClick={checkPaymentArea}
                                defaultColor="#333"
                                defaultOpacity={0.5}
                                rateCoverageAreas={values.payment_areas}
                            />

                            <InfoMapMessage message="Clique nos polígonos destacados no mapa para marcar ou desmarcar uma área de cobrança." />
                        </MapboxView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DynamicRateCreation
