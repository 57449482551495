import { CheckboxContainer, CircleContainer, InnerCircle } from './check.styled'

interface Props {
    isSquared?: boolean
    isSelected?: boolean
    onClick?: () => void
}

const Check: React.FC<Props> = ({ isSquared, isSelected, onClick }) => {
    return (
        <CheckboxContainer onClick={onClick}>
            <CircleContainer isSelected={isSelected} isSquared={isSquared}>
                <InnerCircle isSelected={isSelected} isSquared={isSquared} />
            </CircleContainer>
        </CheckboxContainer>
    )
}

export default Check
