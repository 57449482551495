import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { DefaultButton } from 'components/default-button/default-button'

const Container = styled.div`
    display: flex;
    flex: 1;
    gap: 1rem;
    /* align-items: center; */
    flex-direction: column;
    background-color: #d9dde8;
    padding: 1.25rem 1rem;

    overflow-y: auto;
`

const SubTitle = styled.span`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.textLight};
    padding-bottom: 0.5rem;
`

const Row = styled.div<{ justify?: string }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: ${({ justify }) => justify ?? 'space-between'};

    > .input-item {
        width: 100%;
        margin: 0;
    }
`

const DivideContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    color: #999999;

    font-size: 0.875rem;

    svg {
        color: #999999 !important;
    }
`
const Divide = styled.div`
    height: 0.125rem;
    flex: 1;
    background-color: #999999;
`

const InfoContainer = styled.div<{ show?: boolean }>`
    height: 0;
    min-height: 0;

    display: flex;
    flex-direction: column;

    transition: min-height 300ms ease-in-out;
    overflow: hidden;
    gap: 0.75rem;

    > p {
        margin: 0;

        font-size: 0.75rem;
        font-style: normal;
        line-height: normal;
        text-align: justify;

        color: ${({ theme }) => theme.colors.text};
    }

    ${({ show }) =>
        show &&
        css`
            min-height: 11.25rem;
            @media (min-width: ${({ theme }) => theme.devices.tablet}) {
                min-height: 8.2rem;
            }
        `}
`

const CheckContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.75rem;
    max-height: 8rem;
    overflow: hidden;
    overflow-y: auto;
    background-color: #edf0f5;
    padding: 0.5rem;

    label div {
        margin-bottom: 0 !important;
    }

    label div:first-child {
        padding: 0.125rem;

        div {
            width: 0.375rem;
            height: 0.375rem;
        }
    }
`

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`

const Footer = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: center; */
    background-color: #d9dde8;
`
const ButtonRow = styled.div`
    width: 100%;
    padding: 1.25rem 1rem;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: space-between; */
    gap: 0.5rem;
`

const CardTitle = styled.h2`
    padding: 0;
    margin: 0;

    font-size: 1.25rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`
const PrinterButton = styled(DefaultButton)`
    color: ${({ theme, disabled }) => (disabled ? theme.colors.textLight : theme.colors.white)};

    .title-button {
        margin: 0;
        font-size: 0.75rem;
        font-weight: bold;
    }

    svg {
        color: ${({ theme, disabled }) => (disabled ? theme.colors.textLight : theme.colors.white)};
    }
`

const CancelButton = styled(DefaultButton)`
    padding: 0.25rem 0.5rem;
    border-color: #999999;
    transition: 300ms;

    &:hover {
        background-color: transparent !important;
        border-color: ${({ theme }) => theme.colors.primary} !important;
        .title-button {
            svg {
                color: ${({ theme }) => theme.colors.primary} !important;
            }
            color: ${({ theme }) => theme.colors.primary} !important;
        }
    }
    .title-button {
        transition: 300ms;
        gap: 0.5rem;
        margin: 0;
        font-size: 0.875rem;
        font-weight: bold;
        color: #999999 !important;

        svg {
            transition: 300ms;
            width: 1rem;
            margin: 0;
            color: #999999;
        }
    }
`

const PrinterAppInfoContainer = styled.div<{ isActive?: boolean }>`
    /* display: grid;

    grid-template-areas:
        'icon icon title'
        'icon icon status'
        'download download download'; */

    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    position: relative;

    ${({ isActive }) =>
        isActive &&
        css`
            filter: grayscale(1);
        `}
`

const BlockContainer = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
`

const PrinterAppInfoContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.75rem;

    > svg:first-child {
        width: 2.25rem;
        height: 2.25rem;
    }
`

const AppIntoTitleContent = styled.div<{ hasConnection?: boolean; gap?: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap ?? '0.25rem'};

    color: ${({ theme, hasConnection }) => (hasConnection ? theme.colors.success : theme.colors.danger)};

    svg {
        color: ${({ theme, hasConnection }) => (hasConnection ? theme.colors.success : theme.colors.danger)};
    }
`

const SelectedPrinterBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    font-size: 0.875rem;

    margin-bottom: 0.75rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 0.063rem solid ${({ theme }) => theme.colors.lightGray};

    > svg {
        width: 1.25rem !important;
        height: 1.25rem !important;
        color: ${({ theme }) => theme.colors.danger};
    }
`

const PrinterIcon = styled(FontAwesomeIcon).attrs({
    icon: 'print',
})`
    grid-area: icon;
    align-self: center;
    justify-self: center;
    color: ${({ theme }) => theme.colors.primary};
`
const CloseIcon = styled(FontAwesomeIcon).attrs({
    icon: 'times-circle',
})`
    grid-area: icon;
    align-self: center;
    justify-self: center;
`

const RemoveIcon = styled(FontAwesomeIcon).attrs({
    icon: 'trash-alt',
})`
    cursor: pointer;
    grid-area: icon-remove;
    align-self: center;
    justify-self: center;
`

const ReloadIcon = styled(FontAwesomeIcon).attrs({
    icon: 'sync',
})`
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.white};
`

const ChevronDown = styled(FontAwesomeIcon).attrs({
    icon: 'chevron-down',
})<{ active?: boolean }>`
    transition: 300ms;
    transform: rotateX(0deg);

    ${({ active }) =>
        active &&
        css`
            transform: rotateX(180deg);
        `}
`

const DownloadFileIcon = styled(FontAwesomeIcon).attrs({
    icon: 'file-download',
})`
    grid-area: icon;
    align-self: center;
    justify-self: center;
`

const CheckIcon = styled(FontAwesomeIcon).attrs({
    icon: 'check-circle',
})`
    grid-area: check-icon;
    width: 0.5 !important;
    height: 0.5 !important;
    align-self: center;
    justify-self: center;
`

const AppInfoLabel = styled.h2<{ color?: string }>`
    grid-area: title;
    color: ${({ theme, color }) => (color ? theme.colors[color] : '#939393')};
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.625rem;
    font-family: 'Exo';
    padding: 0;
    margin: 0;
`

const PrinterStatusContainer = styled.div`
    font-size: 0.75rem;
    span {
        cursor: pointer;
        font-weight: bold;
    }
`

const DownloadTextContainer = styled.div`
    flex: 1;
    font-size: 0.625rem;
    line-height: 0.625rem;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.textLight};

    a {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.primary};
    }
`

const DownloadInstallerButton = styled.a`
    /* width: 100%; */
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    font-size: 0.75rem;

    text-decoration: none !important;
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 0.5rem;
    border-radius: 0.25rem;

    svg {
        color: ${({ theme }) => theme.colors.white} !important;
    }
`

const ReloadButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    border: none;

    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.colors.primary};
`

const InputContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
`
const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 0.875rem;
`

export {
    Container,
    PrinterAppInfoContainer,
    PrinterAppInfoContent,
    PrinterIcon,
    AppInfoLabel,
    PrinterStatusContainer,
    AppIntoTitleContent,
    CheckIcon,
    DownloadTextContainer,
    Row,
    CardTitle,
    PrinterButton,
    Content,
    CloseIcon,
    InputContainer,
    Label,
    CheckContainer,
    SubTitle,
    Footer,
    ButtonRow,
    CancelButton,
    SelectedPrinterBox,
    BlockContainer,
    DownloadFileIcon,
    DownloadInstallerButton,
    DivideContainer,
    Divide,
    InfoContainer,
    ChevronDown,
    ReloadIcon,
    ReloadButton,
    RemoveIcon,
}
