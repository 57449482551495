import { subDays } from 'date-fns'

const getDateFilters = (filter, setFilter) => {
    const today = new Date()

    return [
        {
            label: 'Hoje',
            onClick: () => {
                setFilter({ ...filter, start_date: today, end_date: today })
            },
        },
        {
            label: 'Ontem',
            onClick: () => {
                setFilter({
                    ...filter,
                    start_date: subDays(today, 1),
                    end_date: subDays(today, 1),
                })
            },
        },
        {
            label: 'Esta semana',
            onClick: () => {
                const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()))

                setFilter({
                    ...filter,
                    start_date: lastSunday,
                    end_date: new Date(),
                })
            },
        },
        {
            label: 'Este mês',
            onClick: () => {
                today.setDate(1)
                setFilter({
                    ...filter,
                    start_date: today,
                    end_date: new Date(),
                })
            },
        },
        {
            label: 'Mês anterior',
            onClick: () => {
                today.setDate(1)
                setFilter({
                    ...filter,
                    start_date: new Date(today.getFullYear(), today.getMonth() - 1),
                    end_date: new Date(today.getFullYear(), today.getMonth(), 0),
                })
            },
        },
    ]
}

export { getDateFilters }
