import { faAndroid } from '@fortawesome/free-brands-svg-icons'
import { faStar, faUser, faBatteryHalf, faWifi, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const BikerInfoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`

export const PhotoContainer = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;
`

export const InfoContainer = styled.div`
    flex: 1;
`

export const BikerPhoto = styled.img`
    width: 100%;
    height: 100%;
`

export const EmptyIcon = styled(FontAwesomeIcon).attrs({
    icon: faUser,
})`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary};
    margin-left: -1px;
`

export const BikerName = styled.div`
    font-weight: bold;
    font-size: 14px;
`

export const BikerVehicle = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const BikerRatingContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-left: 10px;
`

export const StarIcon = styled(FontAwesomeIcon).attrs({
    icon: faStar,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
`

export const RatingValue = styled.div`
    font-weight: bold;
    font-size: 14px;
`

export const BikerPhoneDetails = styled.div`
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.lightGray};
    margin-left: -2px;
    display: flex;
`

export const PhoneDetailItem = styled.div`
    display: flex;
    margin-right: 20px;
`

export const PhoneDetailValue = styled.div`
    font-size: 12px;
    font-weight: 700;
`

export const NameRow = styled.div`
    display: flex;
`

export const BatteryIcon = styled(FontAwesomeIcon).attrs({
    icon: faBatteryHalf,
})`
    transform: rotate(-90deg);
    font-size: 12px;
    margin-right: 5px;
`

export const WifiIcon = styled(FontAwesomeIcon).attrs({
    icon: faWifi,
})`
    font-size: 13px;
    margin-right: 5px;
`

export const PhoneIcon = styled(FontAwesomeIcon).attrs({
    icon: faMobileAlt,
})`
    font-size: 13px;
    margin-right: 5px;
`

export const AndroidIcon = styled(FontAwesomeIcon).attrs({
    icon: faAndroid,
})`
    font-size: 16px;
    margin-right: 5px;
`
