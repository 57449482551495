import styled from 'styled-components'

const ContainerRider = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Avatar = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
`
const ContainerInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
`
const TypeAgent = styled.div`
    color: ${({ theme }) => theme.colors.darkOrange};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
`

const RiderName = styled.div`
    font-size: 14px;
`

const RiderPhone = styled.div`
    font-weight: bold;
    font-size: 12px;
    color: #999;
`

export { ContainerRider, Avatar, ContainerInfo, TypeAgent, RiderName, RiderPhone }
