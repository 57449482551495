import AppliedFilters from '../applied-filters/applied-filters'
import RoutedOrderItem from '../routed-order-item/routed-order-item'

import { in_route } from './mock-routes'
import {
    ListBox,
    ListsContainer,
    ListTitle,
    ListTitleRow,
    MapContainer,
    NumberContainer,
} from './orders-in-route.styles'

const selected_filters = ['HOY Pizza', 'Big Daddy']

const InRouteOrders: React.FC = () => {
    return (
        <>
            <ListsContainer>
                <AppliedFilters selectedFilters={selected_filters} />
                <ListTitleRow>
                    <ListTitle>Em rota</ListTitle>
                    <NumberContainer>{in_route.length}</NumberContainer>
                </ListTitleRow>
                <ListBox>
                    {in_route.map((item, index) => (
                        <RoutedOrderItem
                            orderStatus={item.order_status}
                            orders={item.orders}
                            bikerPhoto={item.biker.photo}
                            bikerName={item.biker.name}
                            showBikerTag={item.biker.vehicle !== null}
                            bikerVehicle={item.biker.vehicle}
                            key={index}
                            color={item.route_color}
                        />
                    ))}
                </ListBox>
            </ListsContainer>
            <MapContainer>mapa</MapContainer>
        </>
    )
}

export default InRouteOrders
