import {
    AndroidIcon,
    BikerInfoContainer,
    BikerName,
    BikerVehicle,
    EmptyIcon,
    BikerPhoto,
    PhotoContainer,
    BikerRatingContainer,
    StarIcon,
    RatingValue,
    InfoContainer,
    BikerPhoneDetails,
    PhoneDetailItem,
    NameRow,
    BatteryIcon,
    PhoneDetailValue,
    WifiIcon,
    PhoneIcon,
} from './biker-info.styles'

interface Props {
    name?: string
    vehicle?: string
    photo?: string
    bikerRating?: number
    batteryLevel?: number
    hasWifi?: boolean
    buildVersion?: string
    androidVersion?: number
}

const BikerInfo: React.FC<Props> = ({
    name,
    vehicle,
    photo,
    bikerRating,
    batteryLevel,
    hasWifi,
    buildVersion,
    androidVersion,
}) => {
    return (
        <BikerInfoContainer>
            <PhotoContainer>{photo ? <BikerPhoto src={photo} /> : <EmptyIcon />}</PhotoContainer>
            <InfoContainer>
                <NameRow>
                    <BikerName>{name || 'Não definido'}</BikerName>
                    {bikerRating && (
                        <BikerRatingContainer>
                            <StarIcon />
                            <RatingValue>{bikerRating}</RatingValue>
                        </BikerRatingContainer>
                    )}
                </NameRow>
                {vehicle && <BikerVehicle>{vehicle}</BikerVehicle>}

                <BikerPhoneDetails>
                    {batteryLevel && (
                        <PhoneDetailItem>
                            <BatteryIcon />
                            <PhoneDetailValue>75%</PhoneDetailValue>
                        </PhoneDetailItem>
                    )}
                    {hasWifi && (
                        <PhoneDetailItem>
                            <WifiIcon />
                            <PhoneDetailValue>WIFI</PhoneDetailValue>
                        </PhoneDetailItem>
                    )}
                    {buildVersion && (
                        <PhoneDetailItem>
                            <PhoneIcon />
                            <PhoneDetailValue>1.22.10</PhoneDetailValue>
                        </PhoneDetailItem>
                    )}
                    {androidVersion && (
                        <PhoneDetailItem>
                            <AndroidIcon />
                            <PhoneDetailValue>9</PhoneDetailValue>
                        </PhoneDetailItem>
                    )}
                </BikerPhoneDetails>
            </InfoContainer>
        </BikerInfoContainer>
    )
}

export default BikerInfo
