import { useCallback, useEffect, useState, memo, useRef, useMemo } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OrderProblemModal } from 'modals/order-problem-modal/order-problem-modal'

import { InputItem } from 'components/_common'
import CheckboxInput from 'components/checkbox-input'
import { ColumnOrderProduction } from 'components/column-order-production/column-order-production'
import ModalLoading from 'components/modal-loading'

import { useAuth } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'
import { api2 } from 'services/api'
import { IOrderKDS, Order, PagedList } from 'types'

import {
    ContainerButtons,
    ButtonOutline,
    Container,
    ContainerBoard,
    ContainerColumns,
    Header,
    ContainerHeaderFill,
    TitleFill,
    ContainerCheckbox,
    RowCheckbox,
    RowButtons,
} from './orders-production-page.styled'

type OrderTypes = 'indoor' | 'takeout' | 'delivery'
export default function OrdersProductionPage() {
    const orderProblemModalRef = useRef<OrderProblemModal>()
    const { store } = useAuth()

    const [orders, setOrders] = useState<IOrderKDS[]>([])
    const [search, setSearch] = useState<string>()
    const [types, setTypes] = useState<OrderTypes[]>(['indoor', 'takeout', 'delivery'])

    const [expand, setExpand] = useState(false)
    const [loading, setLoading] = useState(false)

    const socketEvents = useMemo(() => {
        const callback = (order: Order) => {
            setOrders(state => {
                if (order.status === '3') {
                    const index = state.findIndex(x => x.id === order.id)
                    if (index === -1) {
                        return [...state, orderToOrderKDS(order)]
                    }
                    state[index] = orderToOrderKDS(order)

                    return [...state]
                } else {
                    return state.filter(x => x.id !== order.id)
                }
            })
        }

        return [
            {
                name: '.new',
                callback,
            },
            {
                name: '.updated',
                callback,
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEchoConnection<Order, Order>({
        channelName: `orders.store.${store.id}`,
        events: socketEvents,
    })

    const _getOrders = useCallback(async () => {
        if (!store?.id) {
            return
        }
        setLoading(true)
        try {
            const { data } = await api2.get<PagedList<IOrderKDS>>('/kds/producing', {
                params: { store_id: store.id, types, search },
            })
            setOrders(data.items)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [types, search, store.id])

    const _handleChecked = useCallback(
        (type: OrderTypes) => {
            return () => {
                setTypes(state => {
                    if (state.includes(type)) {
                        return state.filter(x => x !== type)
                    }

                    return [...state, type]
                })
            }
        },
        [setTypes]
    )

    useEffect(() => {
        const timeout = setTimeout(() => {
            _getOrders()
        }, 1000)
        return () => {
            clearTimeout(timeout)
        }
    }, [_getOrders])

    return (
        <>
            <Container>
                <Header>
                    <InputItem
                        labelText="Buscar"
                        inputProps={{
                            placeholder: 'Nº do pedido ou nome do cliente',
                            value: search,
                            onChange: (ev: any) => {
                                setSearch(ev?.target?.value || '')
                            },
                        }}
                    />
                    <RowButtons>
                        <ContainerCheckbox>
                            <label>Tipo de pedido</label>
                            <RowCheckbox>
                                <CheckboxInput
                                    label="Balcão"
                                    checked={types.includes('takeout')}
                                    onChange={_handleChecked('takeout')}
                                />
                                <CheckboxInput
                                    label="Mesa"
                                    checked={types.includes('indoor')}
                                    onChange={_handleChecked('indoor')}
                                />
                                <CheckboxInput
                                    label="Delivery"
                                    checked={types.includes('delivery')}
                                    onChange={_handleChecked('delivery')}
                                />
                            </RowCheckbox>
                        </ContainerCheckbox>
                        <ContainerButtons>
                            <ButtonOutline onClick={_getOrders}>
                                <FontAwesomeIcon icon="redo" />
                            </ButtonOutline>
                            <ButtonOutline onClick={() => setExpand(!expand)}>
                                <FontAwesomeIcon icon="expand-arrows-alt" />
                            </ButtonOutline>
                        </ContainerButtons>
                    </RowButtons>
                </Header>

                <ContainerBoard expand={expand}>
                    {expand && (
                        <ContainerHeaderFill>
                            <ButtonOutline onClick={_getOrders}>
                                <FontAwesomeIcon icon="redo" />
                            </ButtonOutline>
                            <TitleFill>{store.name}</TitleFill>
                            <ButtonOutline onClick={() => setExpand(!expand)}>
                                <FontAwesomeIcon icon="compress-arrows-alt" />
                            </ButtonOutline>
                        </ContainerHeaderFill>
                    )}
                    <ScrollContainer vertical={false} horizontal hideScrollbars style={{ flex: 1 }}>
                        <Board orders={orders} />
                    </ScrollContainer>
                    <ModalLoading visible={loading} />
                </ContainerBoard>
            </Container>
            <OrderProblemModal ref={orderProblemModalRef} />
        </>
    )
}

type BoardProps = {
    orders?: IOrderKDS[]
}

const Board: React.FC<BoardProps> = memo(({ orders = [] }) => {
    return (
        <ContainerColumns>
            {orders.map((item, i) => (
                <ColumnOrderProduction order={item} key={i} />
            ))}
        </ContainerColumns>
    )
})

function orderToOrderKDS(order: Order): IOrderKDS {
    return {
        created_at: order.created_at,
        customer: order.customer,
        id: order.id,
        items: order.items,
        reference_id: order.reference_id,
        status: order.status,
        type: order.type,
        observation: order.observation,
    }
}
