import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { ButtonContainer, Icon, ButtonLabel } from './refresh-button.styles'

interface Props {
    icon: IconProp
    label: string
    onClick(): void
    isRefreshing: boolean
    type?: 'link'
}

function RefreshButton({ icon, label, onClick, isRefreshing, type }: Props) {
    return (
        <ButtonContainer onClick={isRefreshing ? undefined : onClick} type={type}>
            <Icon icon={icon} isRefreshing={isRefreshing} type={type} />
            <ButtonLabel type={type}>{label}</ButtonLabel>
        </ButtonContainer>
    )
}

export default RefreshButton
