import axios from 'axios'
//import { push } from 'connected-react-router'
import Echo from 'laravel-echo'
import socketIOClient from 'socket.io-client'

import { store } from '../store'
//import { Creators as UtilsActions } from '../store/reducers/utils'
import { AuthThunk } from '../store/thunks'

export const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
console.log({
    NODE_ENV: process.env.NODE_ENV,
    REACT_APP_WS_URL: process.env.REACT_APP_WS_URL,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    REACT_APP_SICLOSPAG_URL: process.env.REACT_APP_SICLOSPAG_URL,
})

export const echoConnection = () => {
    const token = localStorage.getItem('@extranet.logaroo:token')

    const echo = new Echo({
        host: process.env.REACT_APP_WS_URL,
        broadcaster: 'socket.io',
        client: socketIOClient,
        namespace: '.',
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    })

    return echo
}

export const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
export const baseURL2 = `${process.env.REACT_APP_API_URL}/v2`
const api = axios.create({ baseURL })
const api2 = axios.create({ baseURL: baseURL2 })
const marketplaceV1 = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/marketplace/v1` })
const mercadooApi = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/mercadoo/v1` })
const plugApi = axios.create({ baseURL: process.env.REACT_APP_PLOOGA_API_URL })
const printerServer = axios.create({ baseURL: process.env.REACT_APP_PRINTER_MIDDLEWARE_URL })

api.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('@extranet.logaroo:token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => Promise.reject(error)
)
api2.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('@extranet.logaroo:token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => Promise.reject(error)
)
marketplaceV1.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('@extranet.logaroo:token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => Promise.reject(error)
)

plugApi.interceptors.request.use(
    async config => {
        const token = store.getState().integrations.token

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => Promise.reject(error)
)

printerServer.interceptors.request.use(
    async config => config,
    error => {
        if (!error.response) {
            throw new Error('Verifique se a aplicação está devidamente iniciada.')
        }
        return Promise.reject(error)
    }
)

mercadooApi.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('@extranet.logaroo:token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => Promise.reject(error)
)

mercadooApi.interceptors.response.use(async response => response.data)

api.interceptors.response.use(
    async response => response.data,
    error => {
        const { response } = error

        if (response.status === 401) {
            store.dispatch(AuthThunk.logout())
        } else if (response.status === 403) {
            error.response.data.error = 'Você não possui permissão pra acessar esta página ou executar esta ação'
        }
        return Promise.reject(error)
    }
)
api2.interceptors.response.use(
    response => response,
    error => {
        const { response } = error

        if (response.status === 401) {
            store.dispatch(AuthThunk.logout())
        } else if (response.status === 403) {
            error.response.data.error = 'Você não possui permissão pra acessar esta página ou executar esta ação'
        }
        return Promise.reject(error)
    }
)
marketplaceV1.interceptors.response.use(
    response => response,
    error => {
        const { response } = error

        if (response.status === 401) {
            store.dispatch(AuthThunk.logout())
        } else if (response.status === 403) {
            error.response.data.error = 'Você não possui permissão pra acessar esta página ou executar esta ação'
        }
        return Promise.reject(error)
    }
)
export { plugApi, mercadooApi, printerServer, api2, marketplaceV1 }
export default api
