import * as Feather from 'react-feather'

import styled from 'styled-components'

const Container = styled.div`
    background-color: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    user-select: none;
`

const Message = styled.div`
    color: #5f5f5f;
    font-size: 12px;
    margin-bottom: 2px;
    span {
        color: ${({ theme }) => theme.colors.primary};

        &.link {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
`
const SectionTitle = styled.div`
    color: #2d2d2d;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
        color: ${({ theme }) => theme.colors.primary};
        margin-right: 5px;
        width: 18px;
        height: 18px;
    }
`
const ContainerFileCard = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
`

const CardFile = styled.div<{ showBorder?: boolean; alert?: boolean; pointer?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: ${({ showBorder }) => (showBorder ? '1px dashed #939393' : 'none')};
    background-color: ${({ alert, theme }) => (alert ? theme.colors.danger : '#f3f6ff')};
    padding: 10px;
    min-height: 150px;
    display: flex;
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};

    .upload {
        color: ${({ theme }) => theme.colors.primary};
        margin-left: 10px;
        width: 30px;
        height: 30px;
        opacity: 0.7;
    }
    .alert {
        color: #fff;
        margin-left: 10px;
        width: 30px;
        height: 30px;
        opacity: 0.7;
        align-self: flex-end;
    }

    .sending {
        font-size: 30px;
        color: #939393;
    }
    .error {
        font-size: 50px;
        color: #fff;
    }
`

const ContainerInfoCard = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const TitleFile = styled.div<{ hasFile }>`
    font-size: ${({ hasFile }) => (hasFile ? 20 : 36)}px;
    font-weight: bold;
    color: #939393;
    margin-bottom: 10px;
`

const ContainerDetails = styled.div`
    display: flex;
`

const ContainerInfo = styled.div`
    flex: 1;
`

const ContainerCard = styled.div`
    margin-right: 20px;
    .message {
        max-width: 120px;
    }
`

const CardInfo = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    min-width: 120px;
    height: 140px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    .text {
        color: #fff;
    }
    .counter {
        font-size: 55px;
        font-weight: bold;
    }
    .label {
        font-size: 12px;
        margin-top: -15px;
    }
`

const DocIcon = styled(Feather.FileText)`
    align-self: flex-end;
    opacity: 0.8;
`

const ContainerAmounts = styled.div``

const Loader = styled.div`
    margin: 10px 0px;
    height: 25px;
    border-radius: 4px;
    background-color: #d9d9d9;
    position: relative;
    box-sizing: border-box;
    &:before {
        content: '';
        border-radius: 10px;
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
    }
`

const LoaderBar = styled.div`
    position: absolute;
    height: 85%;
    margin: auto 0px;
    border-radius: 4px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.primary};
    animation: borealisBar 1.5s linear infinite;

    @keyframes borealisBar {
        0% {
            left: 0%;
            right: 100%;
            width: 15%;
        }
        10% {
            left: 0%;
            right: 75%;
            width: 30%;
        }
        90% {
            right: 0%;
            left: 75%;
            width: 25%;
        }
        100% {
            left: 100%;
            right: 0%;
            width: 0%;
        }
    }
`

const Footer = styled.div``

const ContianerButton = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    .button {
        margin-right: 10px;
    }
    .trash-icon {
        margin-right: 0;
    }
    .trash {
        padding: 0px;
    }
`
const ContianerButtonUpdate = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .button {
        margin-right: 10px;
        &:last-child {
            svg {
                margin-right: 0px;
                margin-left: 10px;
            }
        }
    }
`
const CheckBoxContainer = styled.div``

export {
    Container,
    CheckBoxContainer,
    Loader,
    Message,
    LoaderBar,
    SectionTitle,
    CardFile,
    TitleFile,
    ContainerInfoCard,
    ContainerDetails,
    ContainerInfo,
    ContainerFileCard,
    CardInfo,
    DocIcon,
    ContianerButtonUpdate,
    Footer,
    ContainerCard,
    ContianerButton,
    ContainerAmounts,
}
