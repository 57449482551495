import {
    faStoreAlt,
    faChevronDown,
    faUsers,
    faMapMarkerAlt,
    faCogs,
    faTags,
    faSlidersH,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const MallAccordion = styled.div`
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    margin-bottom: 20px;
`

export const AccordionHeader = styled.div`
    background-color: #f1f1f1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`

export const HeaderText = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
`

export const IconContainer = styled.div<{ isActive?: boolean }>`
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0deg')});
    transition: 400ms;
`

export const StoreIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const AreasIcon = styled(FontAwesomeIcon).attrs({
    icon: faMapMarkerAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`
export const SlidersIcon = styled(FontAwesomeIcon).attrs({
    icon: faSlidersH,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const TariffsIcon = styled(FontAwesomeIcon).attrs({
    icon: faTags,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const AdminIcon = styled(FontAwesomeIcon).attrs({
    icon: faUsers,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const SettingIcon = styled(FontAwesomeIcon).attrs({
    icon: faCogs,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const ChevronIcon = styled(FontAwesomeIcon).attrs({
    icon: faChevronDown,
})``

export const AccordionContent = styled.div<{ isActive?: boolean }>`
    padding: ${({ isActive }) => (isActive ? '20px 10px' : '0px')};
    width: ${({ isActive }) => (isActive ? 'auto' : '0px')};
    height: ${({ isActive }) => (isActive ? 'auto' : '0px')};
    overflow: ${({ isActive }) => (isActive ? 'auto' : 'hidden')};
    font-size: 14px;
`

export const HeaderIcon = styled.div`
    width: 25px;
    margin-right: 10px;
`
