import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { getFormInputError } from 'helpers'

import { useModalChangeCustomerName } from './modal-change-customer-name.controller'
import {
    Box,
    Button,
    Container,
    ContainerButtons,
    Content,
    Footer,
    InputContent,
    Separator,
    Title,
} from './modal-change-customer-name.styled'

export type ModalChangeCustomerNameRef = {
    show(data: { orderId: number; customerName: string }): void
    close(): void
}

type Props = {
    refresh?(): void
}

const ModalChangeCustomerName = memo(
    forwardRef<ModalChangeCustomerNameRef, Props>(({ refresh }, ref) => {
        const { isValid, visible, errors, touched, loading, getFieldProps, _show, _close, _handleSubmit } =
            useModalChangeCustomerName(refresh)

        useImperativeHandle(
            ref,
            () => ({
                show: _show,
                close: _close,
            }),
            [_show, _close]
        )

        if (!visible) return null

        return (
            <Container>
                <Box show={visible}>
                    <Content>
                        <Title id="title-modal" className="title">
                            Alterar Cliente
                        </Title>
                        {/* <Icon icon="exclamation-circle" /> */}
                        {/* <Message>Digite o nome do cliente</Message> */}

                        <InputContent>
                            <InputItem
                                labelText="Cliente"
                                inputProps={{
                                    ...getFieldProps('customer.name'),
                                }}
                                errorMessage={getFormInputError('customer.name', errors, touched)}
                            />
                        </InputContent>
                    </Content>
                    <Footer>
                        <ContainerButtons>
                            <Button id="yes-button" onClick={_handleSubmit} disabled={!isValid}>
                                Sim
                            </Button>
                        </ContainerButtons>
                        <Separator />
                        <ContainerButtons>
                            <Button id="no-button" isOutline onClick={_close}>
                                Não
                            </Button>
                        </ContainerButtons>
                    </Footer>
                </Box>
                <ModalLoading visible={loading} />
            </Container>
        )
    })
)

export { ModalChangeCustomerName }
