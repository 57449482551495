import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'
import SelectInput from 'components/select-input'

import { IntegratorStoreListingItem } from 'containers/integrators-page/components/integrator-store-listing-item/integrator-store-listing-item'
import { ManagerIntegratorModal, AuthIntegratorModal, ManagerPaymentsModal } from 'containers/integrators-page/modals'

import { useUI } from 'contexts'
import { PaymentsContext, PaymentsContextProvider } from 'contexts/payments-context'
import { setSearchParams, showErrors } from 'helpers'
import api, { plugApi } from 'services/api'
import { Mall, PagedList, Store } from 'types'
import { IIntegration, IntegrationType } from 'types/integration'
import { ILinkedPayment } from 'types/linked-payment'
import { IOriginators } from 'types/originators'
import { getIntegrationName } from 'utils/get-integration-name'

import {
    ItemPayment,
    ContainerInfo,
    LogarooPayment,
    OrtherPayment,
    FormSectionTitle,
    Content,
    ContainerList,
    ContainerCredentials,
    Container,
    ContainerTitle,
    Title,
    ButtonText,
    ContainerFilter,
    FilterItem,
    LoaderContainer,
    Button,
    OutlineButton,
    ContainerInput,
    RowInfo,
    Label,
    Value,
    ContainerTitleForm,
    ContainerButton,
    Line,
} from './integration-orthers.styled'

type Props = {
    type: IntegrationType
}
type FilterType = {
    status: string
    logaroo_id: string
    mall: string
}

const IntegrationOrthers = memo<Props>(({ type }) => {
    const pageRef = useRef<number>(1)
    const totalRef = useRef<number>(0)

    const history = useHistory()
    const location = useLocation()

    const { setErrorModal } = useUI()

    const [showModal, setShowModal] = useState(false)
    const [showCredentialsModal, setShowCredentialsModal] = useState(false)
    const [showPaymentsModal, setShowPaymentsModal] = useState(false)

    const [loading, setLoading] = useState(false)

    const [integrators, setIntegrators] = useState<IIntegration[]>([])
    const [editIntegrator, setEditIntegrator] = useState<IIntegration | null>(null)
    const [editPayment, setEditPayment] = useState<ILinkedPayment | null>(null)

    const [originator, setOriginator] = useState<IOriginators>()

    const [malls, setMalls] = useState<Mall[]>([])
    const [stores, setStores] = useState<Store[]>([])

    const query = useMemo<FilterType>(() => {
        const query = new URLSearchParams(location.search)

        return {
            status: query.get('status'),
            logaroo_id: query.get('logaroo_id'),
            mall: query.get('mall'),
        }
    }, [location.search])

    const [filter, setFilter] = useState(query)

    const _getIntegrators = useCallback(
        async (filter: FilterType) => {
            try {
                setLoading(true)
                let status

                if (filter.status === 'true') {
                    status = 1
                }
                if (filter.status === 'false') {
                    status = 0
                }
                const { data } = await plugApi.get<PagedList<IIntegration>>('/integrations', {
                    params: { ...filter, status, type },
                })

                setIntegrators(data.items)

                totalRef.current = data.totals
                pageRef.current = data.current_page
            } catch (error) {
                console.log('error', error)
            } finally {
                setLoading(false)
            }
        },
        [type]
    )

    const _onModalClose = useCallback(
        (isRefresh: boolean) => {
            if (isRefresh) {
                _getIntegrators(query)
            }
            setEditIntegrator(null)
            setShowModal(false)
        },
        [_getIntegrators, query]
    )

    const _onModalPaymentClose = useCallback(() => {
        setEditPayment(null)
        setShowPaymentsModal(false)
    }, [])

    const _openCredentialsModal = useCallback(() => {
        setShowCredentialsModal(true)
    }, [])

    const _refreshToken = useCallback(async () => {
        setLoading(true)
        try {
            await plugApi.put('/token/refresh', {
                type: type,
            })
        } catch (error) {
            setErrorModal({
                title: 'Erro ao atualizar token',
                subtitle: showErrors(error),
            })
        }
        setLoading(false)
    }, [type, setErrorModal])

    const _openModal = useCallback(() => {
        setShowModal(true)
    }, [])
    const _openPaymentModal = useCallback(() => {
        setShowPaymentsModal(true)
    }, [])

    const _editIntegrator = useCallback((integrator: IIntegration) => {
        setEditIntegrator(integrator)
    }, [])
    const _editPayment = useCallback((payment: ILinkedPayment) => {
        setEditPayment(payment)
    }, [])

    const _getMalls = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/malls', {
                params: { order_by: 'name', status: [1], per_page: 100 },
            })

            setMalls(data.items)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])

    const _setFilterParam = useCallback(
        (key, value) => {
            const data = { ...filter, [key]: value }
            setFilter(data)
            setSearchParams(data, location, history)
        },
        [filter, location, history]
    )

    const _loadMore = useCallback(async () => {
        try {
            setLoading(true)
            let status

            if (filter.status === 'true') {
                status = 1
            }
            if (filter.status === 'false') {
                status = 0
            }
            const { data } = await plugApi.get<PagedList<IIntegration>>('/integrations', {
                params: { ...filter, status, type, current_page: pageRef.current + 1 },
            })

            setIntegrators([...integrators, ...data.items])

            totalRef.current = data.totals
            pageRef.current = data.current_page
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [integrators, filter, type])

    const _getStores = useCallback(
        async (mallId: string) => {
            _setFilterParam('logaroo_id', null)
            try {
                setLoading(true)

                const {
                    data: { items },
                } = await api.get('/painel/stores-to-select', {
                    params: {
                        order_by: 'name',
                        mall_id: mallId,
                    },
                })

                setStores(items)
            } catch (error) {
                console.log('error', error)
            } finally {
                setLoading(false)
            }
        },
        [_setFilterParam]
    )

    const _getOrigens = useCallback(async () => {
        try {
            const { data } = await plugApi.get<IOriginators>(`/originators/slug/${type}`)
            setOriginator(data)
        } catch (error) {
            console.log('error', error)
        }
    }, [type])

    const _onCredentialsModalClose = useCallback(
        (isRefresh: boolean) => {
            if (isRefresh) {
                _getOrigens()
            }

            setShowCredentialsModal(false)
        },
        [_getOrigens]
    )

    useEffect(() => {
        if (query.mall) {
            _getStores(query.mall)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.mall])

    useEffect(() => {
        _getMalls()
    }, [_getMalls])

    useEffect(() => {
        _getOrigens()
        _getIntegrators(query)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <Container>
            <Content>
                <ContainerList>
                    <ContainerTitle>
                        <Title>{getIntegrationName(type)}</Title>
                        <ButtonText onClick={_openModal}>
                            Adicionar integrador <FontAwesomeIcon icon="plus-circle" />
                        </ButtonText>
                    </ContainerTitle>
                    <ContainerFilter>
                        <ContainerInput>
                            <FilterItem>
                                <SelectInput
                                    placeholder="Selecione um mall"
                                    data={malls.map(item => ({ name: item.name, value: item.id.toString() }))}
                                    onChange={({ target: { value } }) => {
                                        _setFilterParam('mall', value)
                                        setStores([])
                                    }}
                                    value={filter.mall || ''}
                                />
                            </FilterItem>
                            {stores.length > 0 && (
                                <FilterItem>
                                    <SelectInput
                                        placeholder="Todas as lojas"
                                        data={stores.map(item => ({ name: item.name, value: item.id.toString() }))}
                                        onChange={({ target: { value } }) => _setFilterParam('logaroo_id', value)}
                                        value={filter.logaroo_id || ''}
                                    />
                                </FilterItem>
                            )}
                            <FilterItem>
                                <SelectInput
                                    placeholder="Todos os status"
                                    data={[
                                        { name: 'Ativo', value: 'true' },
                                        { name: 'Inativo', value: 'false' },
                                    ]}
                                    onChange={({ target: { value } }) => _setFilterParam('status', value)}
                                    value={filter.status || ''}
                                />
                            </FilterItem>
                        </ContainerInput>
                    </ContainerFilter>
                    <Scroll>
                        {loading && !integrators.length ? (
                            <LoaderContainer className="loader-container"></LoaderContainer>
                        ) : integrators.length ? (
                            integrators.map((item, index) => (
                                <IntegratorStoreListingItem
                                    image={item.logaroo.logo}
                                    mallName={`${item.logaroo.mall.name} | ${item.logaroo.name}`}
                                    status={item.status}
                                    name={item.origin.name}
                                    buttonText="Editar"
                                    onClickButton={() => _editIntegrator(item)}
                                    key={index}
                                />
                            ))
                        ) : (
                            <EmptyMessage icon="user-friends">Não há integradores cadastrados.</EmptyMessage>
                        )}

                        <ButtonLoadMore
                            loading={loading}
                            visible={totalRef.current > integrators.length}
                            onClick={_loadMore}
                        />
                    </Scroll>
                </ContainerList>
                <ContainerCredentials>
                    <PaymentsContextProvider edit={editPayment} type={type}>
                        <ContainerTitleForm>
                            <FormSectionTitle>Credencial de Integração</FormSectionTitle>
                        </ContainerTitleForm>
                        {!!originator && (
                            <>
                                <RowInfo>
                                    <Label>Status:</Label>
                                    <Value type={originator.status ? 'success' : 'error'}>
                                        {originator.status ? 'Ativo' : 'Inativo'}
                                    </Value>
                                </RowInfo>
                                <RowInfo>
                                    <Label>Gerado em:</Label>
                                    <Value>
                                        {originator.token
                                            ? format(originator.token.created_at, 'DD/MM/YYYY HH:mm')
                                            : 'N/S'}
                                    </Value>
                                </RowInfo>
                                <RowInfo>
                                    <Label>Expira em:</Label>
                                    <Value>
                                        {originator.token
                                            ? format(originator.token.expires, 'DD/MM/YYYY HH:mm')
                                            : 'N/S'}
                                    </Value>
                                </RowInfo>
                                <ContainerButton>
                                    <Button onClick={_openCredentialsModal}>Atualizar Credencial</Button>
                                    <OutlineButton onClick={_refreshToken}>
                                        <FontAwesomeIcon className="icon" icon="sync" /> Token
                                    </OutlineButton>
                                </ContainerButton>
                            </>
                        )}
                        <Line />
                        <ContainerTitleForm>
                            <FormSectionTitle>Formas de Pagamento</FormSectionTitle>
                            <ButtonText onClick={_openPaymentModal}>Add +</ButtonText>
                        </ContainerTitleForm>
                        <Scroll>
                            <ContainerPayments onClickItem={_editPayment} />
                        </Scroll>
                        <ManagerPaymentsModal
                            isActive={showPaymentsModal || !!editPayment}
                            closeClick={_onModalPaymentClose}
                        />
                    </PaymentsContextProvider>
                </ContainerCredentials>
            </Content>

            <ManagerIntegratorModal
                integration={editIntegrator}
                type={type}
                isActive={showModal || !!editIntegrator}
                closeClick={_onModalClose}
            />
            <AuthIntegratorModal
                origin={originator}
                isActive={showCredentialsModal}
                closeClick={_onCredentialsModalClose}
            />

            <ModalLoading visible={loading && integrators.length} />
        </Container>
    )
})

type ContainerPaymentsProps = {
    onClickItem(item: ILinkedPayment): void
}
const ContainerPayments: React.FC<ContainerPaymentsProps> = memo(({ onClickItem }) => {
    const { linkedPayments } = useContext(PaymentsContext)

    return (
        <>
            {linkedPayments.map((item, i) => (
                <ItemPayment key={i}>
                    <ContainerInfo>
                        <LogarooPayment>{item.logaroo.name}</LogarooPayment>
                        <OrtherPayment>{item.origin.name}</OrtherPayment>
                    </ContainerInfo>
                    <Button onClick={() => onClickItem(item)}>Editar</Button>
                </ItemPayment>
            ))}
        </>
    )
})

export { IntegrationOrthers }
