import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'
import { WeekDaysSelect } from 'components/week-days-select/week-days-select'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IScheduledPeriod, IWeekDay } from 'types/scheduledPeriods'

import {
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    InputsRow,
    StatusContainer,
    ButtonRow,
    ConfirmButton,
} from './opening-hours-modal.styled'

export type OpeningHoursModalRef = {
    show(): void
    close(): void
    setSchedule?(schedule: IScheduledPeriod<'store'>): void
}

type Props = {
    onClose?(isRefresh: boolean): void
}

type IForm = IScheduledPeriod<'store'>

const OpeningHoursModal = memo(
    forwardRef<OpeningHoursModalRef, Props>(({ onClose }, ref) => {
        const { store } = useAuth()
        const { setSuccessModal, setErrorModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [scheduledPeriod, setScheduledPeriod] = useState<IScheduledPeriod<'store'>>()

        const {
            initialValues,
            isSubmitting,
            values,
            touched,
            errors,
            resetForm,
            setValues,
            setFieldValue,
            getFieldProps,
            handleSubmit,
        } = useFormik<IForm>({
            initialValues: {
                label: '',
                type: 'starting',
                status: 1,
                start_time: '',
                end_time: '',
                start_date: '',
                end_date: '',
                week_days: {
                    sunday: false,
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                },
            } as IScheduledPeriod<'store'>,
            validationSchema: Yup.object().shape({
                label: Yup.string().required(),
                type: Yup.string().required(),
                start_time: Yup.string().required(),
                end_time: Yup.string().required(),
            }),
            onSubmit: async values => {
                const { type } = values
                const isStarting = type === 'starting'
                const baseUrl = `/painel/schedules/store/${isStarting ? 'start' : 'pause'}`
                const url = isEdit ? `${baseUrl}/${values.id}` : baseUrl
                const body = {
                    ...values,
                    entity: {
                        type: 'store',
                        id: store.id,
                    },
                }

                try {
                    isEdit ? await api.put(url, body) : await api.post(url, body)

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Horário de Funcionamento registrado com sucesso',
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setScheduledPeriod(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    resetForm()
                    setScheduledPeriod(null)
                    lateralModalBaseRef.current?.close()
                },
                setSchedule: (schedule: IScheduledPeriod<'store'>) => {
                    setScheduledPeriod(schedule)
                    setValues(schedule.week_days ? schedule : { ...schedule, week_days: initialValues.week_days })
                },
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        )

        const isEdit = useMemo(() => {
            return !!scheduledPeriod
        }, [scheduledPeriod])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Gerenciar' : 'Criar'} Horário de Funcionamento`
        }, [isEdit])

        const hasWeekDaySelected = useMemo(() => {
            if (!values?.week_days) return false
            return Object.values(values?.week_days).includes(true)
        }, [values?.week_days])

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        const _handleChange = useCallback(
            (fieldName: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(fieldName, value)
                },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        )

        //const _handleStatusChange = useCallback(() => {
        //    setFieldValue('status', !values.status ? 1 : 0)
        //}, [values])

        const _handleWeekDayChange = useCallback(
            (key: IWeekDay) => {
                setFieldValue(`week_days.${key}`, !values?.week_days[key])
            },
            [values?.week_days]
        )

        useEffect(() => {
            if (hasWeekDaySelected) {
                setFieldValue('start_date', null)
                setFieldValue('end_date', null)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hasWeekDaySelected])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            {/*
                                <InputContainer>
                                    <InputItem
                                        labelText="Tipo"
                                        type="select"
                                        options={[
                                            { label: 'Funcionamento', value: 'starting' },
                                            { label: 'Pausa', value: 'pause' },
                                        ]}
                                        inputProps={{
                                            ...getFieldProps('type'),
                                        }}
                                        errorMessage={touched.type && errors.type}
                                    />
                                </InputContainer>
                            */}

                            <InputContainer>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={{
                                        ...getFieldProps('label'),
                                        onChange: _handleChange('label'),
                                    }}
                                    errorMessage={touched.label && errors.label}
                                />
                            </InputContainer>
                            <InputContainer hasMargin>
                                <FormLabel>Selecione os dias da semana</FormLabel>
                                <WeekDaysSelect weekdays={values.week_days} inForm onClick={_handleWeekDayChange} />
                            </InputContainer>

                            {!hasWeekDaySelected && (
                                <InputContainer>
                                    <InputsRow>
                                        <InputContainer>
                                            <InputItem
                                                type="date"
                                                labelText="Data Inicial"
                                                inputProps={{
                                                    ...getFieldProps('start_date'),
                                                }}
                                                errorMessage={touched.start_date && errors.start_date}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <InputItem
                                                type="date"
                                                labelText="Data Final"
                                                inputProps={{
                                                    ...getFieldProps('end_date'),
                                                }}
                                                errorMessage={touched.end_date && errors.end_date}
                                            />
                                        </InputContainer>
                                    </InputsRow>
                                </InputContainer>
                            )}
                            <InputContainer>
                                <InputsRow>
                                    <InputContainer>
                                        <InputItem
                                            type="time"
                                            labelText="Hora Inicial"
                                            inputProps={{
                                                ...getFieldProps('start_time'),
                                                placeholder: '--:--',
                                            }}
                                            errorMessage={touched.start_time && errors.start_time}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <InputItem
                                            type="time"
                                            labelText="Hora Final"
                                            inputProps={{
                                                ...getFieldProps('end_time'),
                                                placeholder: '--:--',
                                            }}
                                            errorMessage={touched.end_time && errors.end_time}
                                        />
                                    </InputContainer>
                                </InputsRow>
                            </InputContainer>
                            {/* <InputContainer>
                                <StatusContainer>
                                    Status
                                    <SwitchButton
                                        isFlex
                                        isActive={Boolean(values.status)}
                                        onClick={_handleStatusChange}
                                    />
                                </StatusContainer>
                            </InputContainer> */}
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <ConfirmButton title="Salvar" onClick={() => handleSubmit()} />
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { OpeningHoursModal }
