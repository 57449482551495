import React, { memo, useCallback, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'
import { Store } from 'types'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
    CheckBoxContainer,
    ErrorMessage,
} from './manager-integrator-logaroo-modal.styled'

interface IModalProps {
    stores: Store[]
    integration?: any
    closeClick?(isRefresh?: boolean): void
    onRemove?(): void
}

export interface IIntegrationLogarooModalRef {
    show(): void
    close(): void
}

type IForm = {
    id?: number
    role?: number
    first_name?: string
    last_name?: string
    email?: string
    status?: number
    stores?: number[]
    password: string
    password_confirmation: string
}

const statusOption = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 },
]

const ManagerIntegratorContentLogarooModal = memo(
    forwardRef<IIntegrationLogarooModalRef, IModalProps>(({ closeClick, onRemove, integration, stores }, ref) => {
        const lateralModalBaseRef = useRef<LateralModalBase>()

        const { setErrorModal, setSuccessModal } = useUI()

        const isEdit = useMemo(() => {
            return !!integration
        }, [integration])

        const headerContent = useMemo(
            () => (
                <FormSection>
                    <FormLabel>{isEdit ? 'Gerenciar' : 'Adicionar'} usuário Integrador</FormLabel>
                </FormSection>
            ),
            [isEdit]
        )

        const { isSubmitting, errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
            useFormik<IForm>({
                initialValues: {
                    role: 7,
                    first_name: '',
                    last_name: '',
                    stores: [],
                    email: '',
                    password: '',
                    password_confirmation: '',
                    status: 1,
                },
                validationSchema: Yup.object().shape({
                    first_name: Yup.string().trim().required('Insira o Empresa'),
                    last_name: Yup.string().trim().required('Insira o Software'),
                    stores: Yup.array().min(1, 'Selecione pelo menos uma loja'),
                    email: Yup.string().trim().email('Deve ser um email válido').required('Insira o Email'),
                    password:
                        !isEdit &&
                        Yup.string().min(8, 'Deve ter no mínimo 8 caracteres').trim().required('Insira a Senha'),
                    password_confirmation:
                        !isEdit &&
                        Yup.string()
                            .trim()
                            .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
                            .required('Confirme a Senha'),
                    status: Yup.string().required('Selecione o Status'),
                }),
                onSubmit: async values => {
                    try {
                        const form = Object.keys(values).reduce((acc, key) => {
                            if (values[key]) {
                                acc[key] = values[key]
                            }
                            return acc
                        }, {})

                        isEdit
                            ? await api.put(`painel/integrator/${values.id}`, {
                                  ...form,
                              })
                            : await api.post('painel/integrator', {
                                  ...form,
                              })

                        setSuccessModal({
                            title: 'Sucesso',
                            subtitle: !isEdit ? 'Integrador criado com sucesso!' : 'Integrador atualizado com sucesso!',
                            singleButtonClick: _onClose(true),
                        })

                        resetForm()
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro',
                            subtitle: showErrors(error),
                            singleButtonText: 'Revisar alterações',
                        })
                    }
                },
            })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [lateralModalBaseRef, resetForm]
        )

        const _onClose = useCallback(
            (isRefresh?: boolean) => {
                return () => {
                    if (closeClick) {
                        closeClick(isRefresh)
                    }

                    resetForm()
                    lateralModalBaseRef.current?.close()
                }
            },
            [closeClick, resetForm]
        )

        const _handleChange = useCallback(
            (key: string) => {
                return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue(key, value)
                }
            },
            [setFieldValue]
        )

        const _handleChecked = useCallback(
            (value: number) => {
                return ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
                    if (checked) {
                        setFieldValue('stores', [...values.stores, value])
                        return
                    }
                    setFieldValue(
                        'stores',
                        values.stores.filter(id => id !== value)
                    )
                }
            },
            [values.stores, setFieldValue]
        )

        useEffect(() => {
            if (integration) {
                const data = { ...integration, stores: integration.stores.map(item => item.id) }
                setValues(data)
            }
        }, [integration, setValues])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title="Logaroo"
                onClose={closeClick}
                headerComponent={headerContent}
            >
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Empresa"
                                    inputProps={{
                                        value: values.first_name,
                                        onChange: _handleChange('first_name'),
                                    }}
                                    errorMessage={touched.first_name && errors.first_name}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Sistema"
                                    inputProps={{
                                        value: values.last_name,
                                        onChange: _handleChange('last_name'),
                                    }}
                                    errorMessage={touched.last_name && errors.last_name}
                                />
                            </InputContainer>
                            <InputContainer>
                                <FormLabel>Lojas</FormLabel>
                                <CheckBoxContainer>
                                    {stores.map(store => (
                                        <Checkbox
                                            key={store.id}
                                            isSquared
                                            checkboxTitle={`${store.name} (${store.id})`}
                                            inputProps={{
                                                id: `store-${store.id}`,
                                                value: Number(values.stores.some(item => item === store.id)),
                                                onChange: _handleChecked(store.id),
                                            }}
                                        />
                                    ))}
                                </CheckBoxContainer>
                                {!!touched.stores && !!errors.stores && (
                                    <FormLabel>
                                        <ErrorMessage>{errors.stores}</ErrorMessage>
                                    </FormLabel>
                                )}
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="E-mail"
                                    inputProps={{
                                        type: 'email',
                                        value: values.email,
                                        onChange: _handleChange('email'),
                                    }}
                                    errorMessage={touched.email && errors.email}
                                />
                            </InputContainer>
                            {!isEdit && (
                                <InputContainer>
                                    <InputItem
                                        labelText="Senha"
                                        type="password"
                                        inputProps={{
                                            value: values.password,
                                            onChange: _handleChange('password'),
                                        }}
                                        errorMessage={touched.password && errors.password}
                                    />
                                    <InputItem
                                        labelText="Confirmar Senha"
                                        type="password"
                                        inputProps={{
                                            value: values.password_confirmation,
                                            onChange: _handleChange('password_confirmation'),
                                        }}
                                        errorMessage={touched.password_confirmation && errors.password_confirmation}
                                    />
                                </InputContainer>
                            )}
                            <InputContainer>
                                <InputItem
                                    labelText="Status"
                                    type="select"
                                    options={statusOption}
                                    inputProps={{
                                        value: values.status,
                                        onChange: _handleChange('status'),
                                    }}
                                    errorMessage={touched.status && errors.status}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <div>
                            {isEdit && (
                                <TextButton isRemove onClick={onRemove}>
                                    <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={_onClose()}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting} />
            </LateralModalBase>
        )
    })
)

export { ManagerIntegratorContentLogarooModal }
