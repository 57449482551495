import styled from 'styled-components'

export const CheckboxContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    cursor: pointer;
    align-items: center;
    user-select: none;
`

export const CircleContainer = styled.div<{ isSelected?: boolean; isSquared?: boolean }>`
    width: 10px;
    height: 10px;
    border-radius: ${({ isSquared }) => (isSquared ? '4px' : '50%')};
    padding: 5px;
    border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : '#aaa')};
    cursor: pointer;
    margin-right: 20px;
`

export const InnerCircle = styled.div<{ isSelected?: boolean; isSquared?: boolean }>`
    width: 100%;
    height: 100%;
    border-radius: ${({ isSquared }) => (isSquared ? '1px' : '50%')};
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.white)};
`

export const CheckboxText = styled.div<{ isSelected?: boolean }>`
    flex: 1;
    color: ${({ theme, isSelected }) => (isSelected ? theme.colors.darkGray : '#aaa')};
`

export const CheckboxTitle = styled.div`
    font-weight: bold;
`

export const CheckboxSubtitle = styled.div`
    font-size: 14px;
    margin-top: 5px;
`
