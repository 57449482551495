/**
 * Capitalize string
 *
 * @param {string} str
 */
const capitalize = str => {
    if (!str) return ''

    return `${str[0].toUpperCase()}${str.slice(1)}`
}

const formatCpf = str => {
    if (!str) return '-'
    str = str.replace(/[^\d]/g, '')
    return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

const formatCnpj = str => {
    if (!str) return '-'
    str = str.replace(/[^\d]/g, '')
    return str.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
}

const formatCEP = str => {
    if (str?.length !== 8) return '-'

    return [str.substring(0, 5), str.substring(5)].join('-')
}

const formatDistance = distance => {
    if (!distance) return '-'

    return `${distance.toFixed(2).replace('.', ',')}km`
}

const formatPlate = str => {
    if (!str) return '-'
    return `${str.substr(0, 3)}-${str.substr(3)}`
}

const formatPhone = (str, hasCountryCode = true) => {
    if (!str) return '-'

    return hasCountryCode
        ? `(${str.substr(3, 2)}) ${str.substr(5, 5)}-${str.substr(10)}`
        : str.length === 10
        ? `(${str.substr(0, 2)}) ${str.substr(2, 4)}-${str.substr(6)}`
        : `(${str.substr(0, 2)}) ${str.substr(2, 1)} ${str.substr(3, 4)}-${str.substr(7)}`
}

const formatAddress = ({ street, number, complement = null, neighborhood }) => {
    let str = street

    if (!str) {
        return ''
    }

    if (number) {
        str += `, ${number}`
    }
    if (complement) {
        str += `, ${complement}`
    }
    if (neighborhood?.name) {
        str += `, ${neighborhood.name}`
    } else if (neighborhood) {
        str += `, ${neighborhood}`
    }

    return str
}

export { capitalize, formatCEP, formatCpf, formatCnpj, formatDistance, formatPlate, formatPhone, formatAddress }
