import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import ReturnRatesListingTableHeader from 'components/return-rates-listing-table-header/return-rates-listing-table-header'
import ReturnRatesListingTableRow from 'components/return-rates-listing-table-row/return-rates-listing-table-row'

import { useAuth } from 'hooks'
import { Rate } from 'types'

import EmptyMessage from '../../components/empty-message/empty-message'
import ModalConfirmation, { ModalConfirmationRef } from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from '../../components/modal-message'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { showErrors } from '../../helpers'
import api from '../../services/api'

import './style.scss'

const ReturnRatesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { basePath, mall } = useAuth()

    const [loading, toggleLoading] = useState(false)
    const [rates, setRates] = useState<{
        current_page: number
        items: Rate[]
        totals: number
    }>({ current_page: 1, items: [], totals: 1 })
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [deleteID, setDeleteID] = useState<number | null>(null)

    const getRates = useCallback(
        async ({ current_page = 1, rates = { items: [] } } = {}) => {
            try {
                toggleLoading(true)

                const { data } = await api.get(`/painel/rate-return`, {
                    params: { mall_id: mall.id, current_page },
                })

                setRates({
                    ...data,
                    items: current_page > 1 ? [...rates.items, ...data.items] : data.items,
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push(`${basePath}/tarifas`),
                })

                modalMessage.current?.openModal()
            } finally {
                toggleLoading(false)
            }
        },
        [history, mall.id]
    )

    useEffect(() => {
        getRates()
    }, [getRates])

    const deleteRate = async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate-return/${deleteID}`)

            getRates()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="dollar-sign">Esta unidade não possui tarifas cadastradas.</EmptyMessage>
    }

    return (
        <div className="rates-listing-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Deseja realmente excluir esta tarifa?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Tarifas de Retorno"
                buttons={[
                    {
                        title: 'Criar tarifa de retorno',
                        onClick: () => history.push(`${basePath}/criar-tarifa-de-retorno`),
                    },
                ]}
            />

            <ReturnRatesListingTableHeader />

            {rates?.items?.map((rate, index) => (
                <ReturnRatesListingTableRow
                    key={index}
                    rate={rate}
                    editClick={() => history.push(`${basePath}/tarifas-de-retorno/${rate.id}`)}
                    deleteClick={() => {
                        setDeleteID(rate.id)
                        modalDelete.current?.openModal()
                    }}
                />
            ))}

            {!rates?.items?.length && !loading && renderEmptyMessage()}

            <ButtonLoadMore
                loading={loading}
                visible={Number(rates?.totals) > 0 && Number(rates?.items?.length) < Number(rates?.totals)}
                onClick={() => getRates({ current_page: Number(++rates.current_page), rates })}
            />
        </div>
    )
}

export default ReturnRatesListing
