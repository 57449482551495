import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

const ContainerTitle = styled.div<{ alert?: boolean }>`
    border-bottom: 1.5px solid #f2f2f2;
    position: relative;
    display: flex;
    padding: 20px 8px;
    align-items: center;
`

const ContentInfo = styled.div<{ alert?: boolean }>`
    border-bottom: 1.5px solid #f2f2f2;
    margin: 0 0 25px;
    padding: 0 0 25px;
`

const ContainerColumn = styled.div<{ alert?: boolean; attention?: boolean; shake?: boolean }>`
    display: flex;
    flex-direction: column;
    user-select: none;
    border-radius: 8px;
    margin: 10px;
    max-width: 300px;
    min-width: 300px;
    height: calc(100% - 20px);
    background-color: #fff;
    color: #333;
    &:first-child {
        margin-left: 20px;
    }
    &:last-child {
        margin-right: 20px;
    }
    ${({ alert }) =>
        alert &&
        css`
            background-color: #ffb200;
            ${ContainerTitle} {
                border-bottom: 1.5px solid #f1a661;
            }
            ${ContentInfo} {
                border-bottom: 1.5px solid #f1a661;
            }
        `}
    ${({ shake }) =>
        shake &&
        css`
            animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
            color: #333;
            ${ContainerTitle} {
                border-bottom: 1.5px solid #f2f2f2;
            }
            ${ContentInfo} {
                border-bottom: 1.5px solid #f2f2f2;
            }
        `}
${({ attention }) =>
        attention &&
        css`
            animation: bgColorAlert 1s infinite linear;
            color: #fff;
            ${ContainerTitle} {
                border-bottom: 1.5px solid #c0392b;
            }
            ${ContentInfo} {
                border-bottom: 1.5px solid #c0392b;
            }
        `}
`

const MallName = styled.div`
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const ContainerInfo = styled.div`
    padding: 10px 20px;
    flex: 1;
`

const SegmentTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0px 0px;
    text-transform: uppercase;
`

const TextInfo = styled.div`
    font-size: 16px;
    margin: 10px 0 0;
    span {
        font-size: 1.75em;
        font-weight: bold;
    }
    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                color: ${({ theme }) => theme.colors.primary};
            }
        `}
`

const SkeletonContent = styled.div`
    margin: 10px 0 0;
    display: flex;
    align-items: flex-end;
`

const ChevronIcon = styled(FontAwesomeIcon)<{ hide?: boolean }>`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
    min-width: 20px;
    display: ${({ hide }) => (hide ? 'none' : 'block')};
    cursor: pointer;
`
const RefreshIcon = styled(FontAwesomeIcon)<{ hide?: boolean }>`
    font-size: 16px;
    min-width: 20px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    cursor: pointer;
`

const Footer = styled.div`
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const TextButton = styled.div`
    cursor: pointer;
    font-size: 11px;
    &:hover {
        text-decoration: underline;
    }
`

const Row = styled.div<{ attention?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .stopwatch {
        text-align: center;
        min-width: 80px;
        font-size: 1.4em;
        font-weight: bold;
        background-color: #fff;
        color: ${({ attention }) => (attention ? '#c0392b' : '#333')};
        padding: 2px 4px;
        border-radius: 8px;
    }
`

const Message = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    padding: 20px;
    margin-top: 40px;
`

export {
    Message,
    ContentInfo,
    TextButton,
    Footer,
    ChevronIcon,
    SegmentTitle,
    TextInfo,
    ContainerInfo,
    SkeletonContent,
    MallName,
    ContainerColumn,
    ContainerTitle,
    Row,
    RefreshIcon,
}
