import { useHistory } from 'react-router'

import { faMap } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'

import { EmptyListMessage, LoadingMessage, LoadingMoreButton } from 'components/_common'

import { getRouteType, getStatusRoute, getTimeDiff } from 'helpers'
import { useAuth } from 'hooks'

import {
    RouteBiker,
    RouteDate,
    RouteId,
    RouteOrders,
    RouteSmallValue,
    RouteType,
    RouteTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    ItemButton,
    DetailsItem,
    ButtonText,
    OrderItem,
    OrderId,
} from './routes-listing-table.styles'

export interface RouteList {
    id: number
    status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
    biker: string
    orders: Order[]
    type: 'direct' | 'offer' | 'fix'
    start_at: string
    finished_at: string
    created_at: string
    updated_at: string
}

export interface Order {
    id: number
    sale_chennel: string
    store: string
}

interface Props {
    loading: boolean
    hasMore: boolean
    onLoadMore(): void
    routes: RouteList[]
    total: number
}

const RoutesListingTable: React.FC<Props> = ({ loading, onLoadMore, routes, total, hasMore }) => {
    const { basePath } = useAuth()
    const history = useHistory()

    function getTotalTime(route: RouteList) {
        if (route.status === 3) {
            const totalTime = getTimeDiff(route.created_at, route.updated_at)

            return totalTime.text
        } else {
            const totalTime = getTimeDiff(route.created_at)

            return totalTime.text
        }
    }

    return (
        <TableContainer>
            <RouteTable>
                <TableHeader>
                    <RouteId>ID</RouteId>
                    <RouteDate>Recebido</RouteDate>
                    <RouteBiker>Entregador</RouteBiker>
                    <RouteType>Tipo de Rota</RouteType>
                    <RouteOrders>Pedidos</RouteOrders>
                    <RouteDate>Entrega</RouteDate>
                    <RouteSmallValue>Tempo de rota</RouteSmallValue>
                    <RouteBiker>Status</RouteBiker>
                    <RouteSmallValue></RouteSmallValue>
                </TableHeader>
                <TableContent>
                    {loading && routes.length === 0 ? (
                        <LoadingMessage />
                    ) : routes.length > 0 ? (
                        <>
                            {routes.map((route, index) => (
                                <TableItem key={index}>
                                    <RouteId>
                                        <span>ID:</span>
                                        {route.id}
                                    </RouteId>
                                    <RouteDate>
                                        <span>Recebido:</span>
                                        {format(route.created_at, 'DD/MM/YYYY [às] HH:mm')}
                                    </RouteDate>
                                    <RouteBiker>
                                        <span>Entregador:</span>
                                        {route?.biker ? `${route?.biker}` : '-'}
                                    </RouteBiker>
                                    <RouteType>
                                        <span>Tipo de Rota:</span>
                                        {getRouteType(route.type)}
                                    </RouteType>
                                    <RouteOrders>
                                        <span>Pedidos:</span>
                                        {route.orders.map(order => (
                                            <OrderItem key={order.id}>
                                                <OrderId>{`#${order.id}`}</OrderId>
                                                {order.store}
                                            </OrderItem>
                                        ))}
                                    </RouteOrders>
                                    <RouteDate>
                                        <span>Entrega:</span>
                                        {route.status === 3 ? format(route.updated_at, 'DD/MM/YYYY [às] HH:mm') : '-'}
                                    </RouteDate>
                                    <RouteSmallValue>
                                        <span>Tempo de rota:</span>
                                        {getTotalTime(route)}
                                    </RouteSmallValue>
                                    <RouteBiker>
                                        <span>Status:</span>
                                        {getStatusRoute(route.status)}
                                    </RouteBiker>
                                    <RouteSmallValue>
                                        <ItemButton
                                            onClick={() => history.push(`${basePath}/detalhes-rota/${route.id}`)}
                                        >
                                            <DetailsItem />
                                            <ButtonText>Ver detalhes</ButtonText>
                                        </ItemButton>
                                    </RouteSmallValue>
                                </TableItem>
                            ))}
                            {hasMore && <LoadingMoreButton loading={loading} onClick={onLoadMore} />}
                        </>
                    ) : (
                        <EmptyListMessage icon={faMap} message="Nenhum resultado encontrado" />
                    )}
                </TableContent>
            </RouteTable>
        </TableContainer>
    )
}

export default RoutesListingTable
