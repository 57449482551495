import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    user-select: none;

    .confirm-button {
        width: 200px;
        margin-right: 20px;
    }
`

const ContentOrder = styled.div`
    padding: 16px;
`
const Footer = styled.div`
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background: #f6f6f6;
`

const TabsContainer = styled.div`
    padding: 0px 16px;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: 26px;
`

const TabItem = styled.div<{ isActive?: boolean }>`
    text-transform: uppercase;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const TextButton = styled.div`
    user-select: none;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        text-decoration: underline;
    }
`

const IconButton = styled(FontAwesomeIcon)`
    margin-right: 5px;
`

const ContainerSubModal = styled.div<{ animated?: boolean; isEffectActive: boolean }>`
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: ${({ animated }) => (animated ? '400ms' : 'none')};
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transform: translateY(${({ isEffectActive, animated }) => (animated ? (isEffectActive ? '0%' : '200%') : '0')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive, animated }) => (animated ? (isEffectActive ? '0%' : '200%') : '0')});
    }
`

export { TabsContainer, TabItem, IconButton, Container, Footer, TextButton, ContentOrder, ContainerSubModal }
