import { Link } from 'react-router-dom'

import { faExternalLinkAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0.75rem 1.25rem;

    background-color: ${({ theme }) => theme.colors.white};

    border-radius: 0.5rem;
`

export const ContentHeaderTitle = styled.h3`
    padding: 0;
    margin: 0;

    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primary};
`

export const MarketplaceManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        flex: 1;
        overflow-y: hidden;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const ContentContainer = styled.div`
    flex: 1;
    min-height: 0;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        justify-content: space-between;
    }
`

export const DetailsContainer = styled.div`
    flex: 1;
    min-height: 0;
`

export const TabsContainer = styled.div`
    /* flex: 1; */
    background-color: #f1f1f1;
    padding: 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding-top: 30px;
        /* flex: unset;
        flex-basis: 30%; */
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: fit-content;
    }
`

export const TabItem = styled(Link)<{ isActive?: boolean }>`
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.white)};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.black)};
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    display: none;
    text-decoration: none;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 20vw;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: block;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const StoreInfoContainer = styled.div`
    margin-bottom: 50px;
    margin-top: 15px;
    display: flex;
    align-items: center;
`

export const StoreLogo = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`

export const LogoImage = styled.img`
    width: 100%;
    height: 100%;
`

export const StoreTextContainer = styled.div``

export const StoreName = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 8px;
`

export const StarIcon = styled(FontAwesomeIcon).attrs({
    icon: faStar,
})`
    margin-right: 5px;
`

export const ExternalIcon = styled(FontAwesomeIcon).attrs({
    icon: faExternalLinkAlt,
})`
    margin-right: 5px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
`

export const StoreRatingContainer = styled.div`
    color: #ff8000;
    border: 1px solid #ff8000;
    padding: 5px 20px;
    border-radius: 20px;
    font-size: 12px;
    margin-right: 10px;
    text-align: center;
    padding-top: 3px;
`

export const StoreDetailButton = styled.a`
    font-size: 10px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid #ff8000;
    padding: 5px 20px;
    border-radius: 20px;
    text-align: center;
    text-decoration: none;
    transition: 400ms;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
    }
`
