import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { stringify } from 'query-string'

import { OrdersListingTable, OrdersListingHeader } from 'components/_orders/'

import { useUI } from 'contexts'
import { setSearchParams, showErrors } from 'helpers'
import { useAuth, useFetchPagination } from 'hooks'
import { Order, OrderFilters } from 'types'

import { PageContainer } from './order-listing-page.styles'

export default function OrdersListingPage(): JSX.Element {
    const { mall, store } = useAuth()
    const history = useHistory()
    const { setSnackbar } = useUI()

    const filters: OrderFilters = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            merchant_id: query.get('merchant_id') ? Number(query.get('merchant_id')) : store.id,
            start_date: query.get('start_date') || undefined,
            end_date: query.get('end_date') || undefined,
            status: query.get('status') || undefined,
        }
    }, [location.search, store])

    const { items, loading, size, setSize, has_more } = useFetchPagination<Order>('/painel/dm-orders', {
        revalidateOnMount: Boolean(stringify(filters).length),
        params: {
            ...filters,
            status: filters.status ? [filters.status] : undefined,
            merchant_id: filters.merchant_id,
            mall_id: mall.id,
            with_routed: 1,
            with_takeout: 1,
            per_page: 100,
        },
        onError: error => {
            setSnackbar({ message: showErrors(error), type: 'error' })
        },
    })

    return (
        <PageContainer>
            <OrdersListingHeader
                title="Listagem de pedidos"
                filters={filters}
                total={(items || []).length}
                onFilterData={filters => {
                    setSearchParams(filters, location, history)
                }}
            />

            <OrdersListingTable
                hasMore={has_more}
                loading={loading}
                onLoadMore={() => setSize(size + 1)}
                orders={items || ([] as Order[])}
            />
        </PageContainer>
    )
}
