import React, { memo, useMemo } from 'react'

import { Marker } from './marker-order.styled'

type Props = {
    isCollect?: boolean
    type?: 'delayed' | 'in-time' | 'late' | 'out-of-rote' | 'selected'
}

const MarkerOrder: React.FC<Props> = memo(({ type, isCollect }) => {
    const color = useMemo(() => {
        if (type === 'delayed') {
            return '#F19738'
        }
        if (type === 'in-time') {
            return '#76CE36'
        }
        if (type === 'late') {
            return '#C70025'
        }
        if (type === 'selected') {
            return '#F03E24'
        }

        return '#8E8E8E'
    }, [type])
    return <Marker color={color} isCollect={isCollect} />
})

export { MarkerOrder }
