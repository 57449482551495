import { faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import clickCallIcon from '../../../assets/images/deliver-dispatch.png'
import emptyIcon from '../../../assets/images/empty-icon.png'
import noOrdersIcon from '../../../assets/images/no-orders-icon.png'
export const ListBox = styled.div`
    height: 500px;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.white};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: unset;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        /* for Firefox */
        min-height: 0;
    }
`

export const ListScroll = styled.div`
    flex: 1;
    overflow: auto;
`

export const ListTitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.gray};
`

export const ListTitle = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`

export const NumberContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.white};
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`

export const ListsContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    min-height: 0;
    position: relative;
    border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
`

export const OrderDetails = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: block;
        width: 40%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 55%;
    }
`

export const EmptyMessage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const EmptyIcon = styled.img.attrs({
    src: emptyIcon,
})`
    width: 80px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 200px;
        height: 150px;
    }
`

export const EmptyText = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.lightGray};
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 200px;
    }
`

export const OrderCallClick = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const OrderCallIcon = styled.img.attrs({
    src: clickCallIcon,
})`
    width: 80px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 350px;
        height: 250px;
    }
`

export const EmptyTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
`

export const NoOrdersContainer = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
    }
`

export const NoOrdersIcon = styled.img.attrs({
    src: noOrdersIcon,
})`
    width: 200px;
    margin-bottom: 40px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 300px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 400px;
    }
`

export const NoOrdersTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    width: 70%;
`

export const NoOrdersText = styled.div`
    margin-top: 20px;
    width: 70%;
    font-weight: 500;
    span {
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const StoreTitleRow = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 20px;
    position: sticky;
    top: -2px;
    left: 0;
    z-index: 2;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
`

export const StoreIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})`
    margin-right: 10px;
`

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 12px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const ConfirmButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`
