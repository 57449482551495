import './style.scss'

const ButtonLink = ({ label, onClick, style = undefined }) => {
    return (
        <div className="button-link-container" style={style}>
            <button onClick={onClick} className="button-link">
                {label}
            </button>
        </div>
    )
}

export default ButtonLink
