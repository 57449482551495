import styled from 'styled-components'

export const InProgressOrders = styled.div`
    width: 90vw;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    min-height: 80vh;
    overflow: hidden;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 94.5vw;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 90vw;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 92vw;
    }
`

export const CardsContainer = styled.div`
    padding: 5px;
    margin: 0;
    margin-left: -5px;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
`
