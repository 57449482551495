import styled from 'styled-components'

const ContainerItem = styled.div`
    display: flex;
    padding: 15px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.lightGray};
`

const ChannelLogo = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-right: 5px;
`

const CustomerInfo = styled.div`
    flex: 1;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 37%;
    }
`

const CustomerID = styled.div`
    color: #666;
    font-size: 12px;
`

const StoreName = styled.div`
    font-size: 14px;
    font-weight: bold;
`

const CustomerName = styled.div`
    font-size: 10px;
    width: 80%;
`

const TextProblem = styled.div`
    color: ${({ theme }) => theme.colors.errorMessage};
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
`

const ContainerActions = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
`
const ContainerStatus = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
`

const Circle = styled.div<{ color?: string }>`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ color }) => (color ? color : '#999999')};
    margin-right: 5px;
`

const StatusName = styled.div<{ uppercase?: boolean }>`
    font-size: 10px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`

const SeeOrder = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 15px;
    cursor: pointer;
    width: fit-content;
    font-size: 11px;
    margin-top: 20px;
    align-self: flex-end;
`

const Header = styled.div`
    background-color: #f5f5f5;
    padding: 15px;
    font-weight: bold;
`

export {
    Header,
    ContainerStatus,
    SeeOrder,
    Circle,
    ContainerActions,
    StatusName,
    StoreName,
    CustomerID,
    ContainerItem,
    ChannelLogo,
    CustomerInfo,
    CustomerName,
    TextProblem,
}
