import { useState, useRef, useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TextInputMask from 'components/text-input-mask'

import { showErrors } from 'helpers'
import api from 'services/api'
import siclosPagApi from 'services/siclospag'
import stoneBanks from 'utils/stoneBanks'

interface Store {
    id: number
    name: string
    trade_name: string
    cnpj: string
}

const RecipientCreate: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)
    const [loading, setLoading] = useState(false)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const [establishments, setEstablishments] = useState({ establishment_list: [] })
    const [stores, setStores] = useState<Store[]>([])

    const { initialValues, isSubmitting, errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                selected_store: '',
                is_recipient_to_production: false,
                establishment_id: '',
                recipient_name: '',
                is_cnpj: false,
                document_number: '',
                business_name: '',
                mcc: '',
                partner_stone_id: '',
                responsible: {
                    email: '',
                    cell_phone: '',
                    document_number: '',
                    name: '',
                },
                contact: {
                    phone_number: '',
                },
                anticipation: {
                    is_automatic: false,
                    automatic_rate: 0,
                    spot_rate: 0,
                },
                bank: {
                    bank_code: '',
                    agency: '',
                    agency_dv: '',
                    account: '',
                    account_dv: '',
                    operation_number: '',
                    account_type: '',
                },
                address: {
                    zip_code: '',
                    number: '',
                    complement: '',
                },
            },
            validationSchema: Yup.object().shape({
                establishment_id: Yup.string().trim().required('Selecione o Estabelecimento'),
                recipient_name: Yup.string().trim().required('Nome do recebedor é obrigatório'),
                business_name: Yup.string().trim().required('Nome Fantasia é obrigatório'),
                // document_number: Yup.string().trim().required('CNPJ é obrigatório'),
                is_cnpj: Yup.bool(),
                document_number: Yup.string()
                    .trim()
                    .when('is_cnpj', {
                        is: is_cnpj => is_cnpj,
                        then: (schema: Yup.StringSchema) =>
                            schema
                                .transform(document_number => document_number.replace(/\D+/g, ''))
                                .min(14, 'CNPJ deve ter 14 caracteres')
                                .required('CNPJ é obrigatório'),
                        otherwise: (schema: Yup.StringSchema) =>
                            schema
                                .transform(document_number => document_number.replace(/\D+/g, ''))
                                .min(11, 'CPF deve ter 11 caracteres')
                                .required('CPF é obrigatório'),
                    }),
                mcc: Yup.string().trim().required('MCC é obrigatório'),
                responsible: Yup.object().when('is_cnpj', {
                    is: is_cnpj => is_cnpj,
                    then: (schema: Yup.ObjectSchema) =>
                        schema.shape({
                            email: Yup.string()
                                .trim()
                                .email('Deve ser um email válido')
                                .required('Email do responsável é obrigatório'),
                            cell_phone: Yup.string().trim().required('Celular do responsável é obrigatório'),
                            document_number: Yup.string()
                                .trim()
                                .required('CPF do responsável é obrigatório para Pessoa Jurídica'),
                            name: Yup.string()
                                .trim()
                                .required('Nome do responsável é obrigatório para Pessoa Jurídica'),
                        }),
                    otherwise: (schema: Yup.ObjectSchema) =>
                        schema.shape({
                            email: Yup.string()
                                .trim()
                                .email('Deve ser um email válido')
                                .required('Email do responsável é obrigatório'),
                            cell_phone: Yup.string().trim().required('Celular do responsável é obrigatório'),
                        }),
                }),
                contact: Yup.object().when('is_cnpj', {
                    is: is_cnpj => is_cnpj,
                    then: (schema: Yup.ObjectSchema) =>
                        schema.shape({
                            phone_number: Yup.string()
                                .trim()
                                .required('Telefone fixo para contato é obrigatório para Pessoa Jurídica'),
                        }),
                }),
                bank: Yup.object().shape({
                    bank_code: Yup.string().trim().required('Selecione o Banco'),
                    agency: Yup.string().trim().required('Agência é requerida'),
                    agency_dv: Yup.string().trim().required('Dígito da agência é requerido'),
                    account: Yup.string().trim().required('Conta é requerida'),
                    account_dv: Yup.string().trim().required('Dígito da conta é requerido'),
                    account_type: Yup.string().trim().required('Selecione o Tipo de conta'),
                }),
                address: Yup.object().shape({
                    zip_code: Yup.string().trim().required('CEP é obrigatório'),
                    number: Yup.string().trim().required('Número é obrigatório'),
                }),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    const { data } = await siclosPagApi.post('/recipient/create', {
                        ...values,
                        document_number: values.document_number.replace(/\D+/g, ''),
                        contact: values.is_cnpj
                            ? {
                                  phone_number: values.contact.phone_number.replace(/\D+/g, ''),
                              }
                            : undefined,
                        address: {
                            ...values.address,
                            zip_code: values.address.zip_code.replace(/\D+/g, ''),
                        },
                    })

                    if (data?.error) {
                        throw data.msg
                    }

                    setModalMessageData({
                        title: 'Sucesso',
                        message: 'Recebedor criado com sucesso!',
                    })

                    modalMessage.current?.openModal()
                    resetForm()
                } catch (error) {
                    console.log('handleSubmit', { error })

                    setModalMessageData({
                        isActive: true,
                        title: 'Erro',
                        message: showErrors(error),
                        textButton: 'Revisar alterações',
                    })

                    modalMessage.current?.openModal()
                } finally {
                    setSubmitting(false)
                }
            },
        })

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const getStores = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/stores-to-select', {
                params: { per_page: -1 },
            })

            setStores(data.items)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/gestao-mall'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getStores()
    }, [getStores])

    async function populateWithStoreFields(store_id: number) {
        try {
            setLoading(true)

            const { data } = await api.get(`/painel/store/${store_id}`)

            setValues({
                ...initialValues,
                is_cnpj: true,
                recipient_name: data?.name || '',
                business_name: data?.trade_name || '',
                document_number: data?.cnpj || '',
                responsible: {
                    ...values.responsible,
                    email: data?.owner_email || '',
                    cell_phone: data?.owner_cellphone || '',
                    document_number: data?.owner_cpf || '',
                    name: data?.owner_name || '',
                },
                contact: {
                    phone_number: data?.phone || '',
                },
                address: {
                    ...values.address,
                    zip_code: data?.zipcode || '',
                    number: data?.number || '',
                    complement: data?.complement || '',
                },
            })
        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }
    }

    function handleInputChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field, value)
        }
    }

    function handleSelectChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue(field, value)
        }
    }

    return (
        <div>
            <RowTitle
                title="Criar recebedor"
                backClick={() => history.push('/painel-malls/gestao-stone/recebedores')}
                buttonRow={
                    values.selected_store.length
                        ? [
                              {
                                  label: 'Autopreencher campos',
                                  onClick: () => populateWithStoreFields(Number(values.selected_store)),
                              },
                          ]
                        : undefined
                }
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>Criar a partir de uma loja existente:</label>
                    <SelectInputForm
                        style={{ margin: 0, marginRight: '10px' }}
                        data={stores?.map(({ id, name }) => ({
                            name: name,
                            value: id,
                        }))}
                        onChange={handleSelectChange('selected_store')}
                        value={values.selected_store}
                    />
                </div>
            </RowTitle>

            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="stone-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome de identificação do recebedor*"
                        onChange={handleInputChange('recipient_name')}
                        value={values.recipient_name}
                        msgErro={touched.recipient_name && errors.recipient_name}
                    />
                    <div className="radio-group">
                        <div className="radio-group-row">
                            <InputRadio
                                id="is_cnpj-no"
                                label="Pessoa Física"
                                checked={!values.is_cnpj}
                                onClick={() => setFieldValue('is_cnpj', false)}
                            />

                            <InputRadio
                                id="is_cnpj-yes"
                                label="Pessoa Jurídica"
                                checked={values.is_cnpj}
                                onClick={() => setFieldValue('is_cnpj', true)}
                            />
                        </div>
                    </div>

                    <SelectInputForm
                        label="Estabelecimento*"
                        data={establishments.establishment_list?.map(({ id, business_name }) => ({
                            name: business_name,
                            value: id,
                        }))}
                        onChange={handleSelectChange('establishment_id')}
                        value={values.establishment_id}
                        msgErro={touched.establishment_id && errors.establishment_id}
                    />
                </div>

                <div className="inputs-row col-2">
                    <TextInputForm
                        label={values.is_cnpj ? 'Nome Fantasia*' : 'Nome do recebedor*'}
                        onChange={handleInputChange('business_name')}
                        value={values.business_name}
                        msgErro={touched.business_name && errors.business_name}
                    />

                    <TextInputMask
                        label={values.is_cnpj ? 'CNPJ do recebedor*' : 'CPF do recebedor*'}
                        mask={values.is_cnpj ? '99.999.999/9999-99' : '999.999.999-99'}
                        onChange={handleInputChange('document_number')}
                        value={values.document_number}
                        msgErro={touched.document_number && errors.document_number}
                    />
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="MCC*"
                        onChange={handleInputChange('mcc')}
                        value={values.mcc}
                        msgErro={touched.mcc && errors.mcc}
                    />
                    <TextInputForm
                        label="Código Stone Partner"
                        type="number"
                        onChange={handleInputChange('partner_stone_id')}
                        value={values.partner_stone_id}
                        msgErro={touched.partner_stone_id && errors.partner_stone_id}
                    />
                    <div className="radio-group">
                        <label>Ativo em produção?</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="is_recipient_to_production"
                                label="Sim"
                                checked={values.is_recipient_to_production}
                                onClick={() => setFieldValue('is_recipient_to_production', true)}
                            />

                            <InputRadio
                                id="is_recipient_not_to_production"
                                label="Não"
                                checked={!values.is_recipient_to_production}
                                onClick={() => setFieldValue('is_recipient_to_production', false)}
                            />
                        </div>
                    </div>
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Responsável
                </div>

                <div className="inputs-row col-2">
                    <TextInputMask
                        label="Celular*"
                        mask="(99) 99999-9999"
                        onChange={handleInputChange('responsible.cell_phone')}
                        value={values.responsible.cell_phone}
                        msgErro={touched.responsible?.cell_phone && errors.responsible?.cell_phone}
                    />
                    <TextInputForm
                        label="Email*"
                        onChange={handleInputChange('responsible.email')}
                        value={values.responsible.email}
                        msgErro={touched.responsible?.email && errors.responsible?.email}
                    />

                    {values.is_cnpj && (
                        <>
                            <TextInputForm
                                label="Nome completo*"
                                onChange={handleInputChange('responsible.name')}
                                value={values.responsible.name}
                                msgErro={touched.responsible?.name && errors.responsible?.name}
                            />

                            <TextInputMask
                                label="CPF*"
                                mask="999.999.999-99"
                                onChange={handleInputChange('responsible.document_number')}
                                value={values.responsible.document_number}
                                msgErro={touched.responsible?.document_number && errors.responsible?.document_number}
                            />
                        </>
                    )}
                </div>

                {values.is_cnpj && (
                    <>
                        <div className="title" style={{ marginTop: '20px' }}>
                            Contato
                        </div>

                        <div className="inputs-row col-3">
                            <TextInputMask
                                label="Telefone*"
                                mask="(99) 9999-9999"
                                onChange={handleInputChange('contact.phone_number')}
                                value={values.contact.phone_number}
                                msgErro={touched.contact?.phone_number && errors.contact?.phone_number}
                            />
                        </div>
                    </>
                )}

                <div className="title" style={{ marginTop: '20px' }}>
                    Antecipação
                </div>

                <div className="inputs-row col-3">
                    <div className="radio-group">
                        <label>Automática?</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="automatic-yes"
                                label="Sim"
                                checked={values.anticipation.is_automatic}
                                onClick={() => setFieldValue('anticipation.is_automatic', true)}
                            />

                            <InputRadio
                                id="automatic-no"
                                label="Não"
                                checked={!values.anticipation.is_automatic}
                                onClick={() => setFieldValue('anticipation.is_automatic', false)}
                            />
                        </div>
                    </div>

                    <TextInputForm
                        label="Taxa da antecipação automática*"
                        onChange={handleInputChange('anticipation.automatic_rate')}
                        value={values.anticipation.automatic_rate}
                        msgErro={touched.anticipation?.automatic_rate && errors.anticipation?.automatic_rate}
                    />
                    <TextInputForm
                        label="Taxa de antecipação pontual*"
                        onChange={handleInputChange('anticipation.spot_rate')}
                        value={values.anticipation.spot_rate}
                        msgErro={touched.anticipation?.spot_rate && errors.anticipation?.spot_rate}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Banco
                </div>

                <div className="inputs-row col-3">
                    <SelectInputForm
                        label="Banco*"
                        data={stoneBanks}
                        onChange={handleSelectChange('bank.bank_code')}
                        value={values.bank.bank_code}
                        msgErro={touched.bank?.bank_code && errors.bank?.bank_code}
                    />
                    <div className="bank-info-input">
                        <TextInputForm
                            label="Agência*"
                            onChange={handleInputChange('bank.agency')}
                            value={values.bank.agency}
                            msgErro={touched.bank?.agency && errors.bank?.agency}
                        />
                        <TextInputForm
                            onChange={handleInputChange('bank.agency_dv')}
                            value={values.bank.agency_dv}
                            msgErro={touched.bank?.agency_dv && errors.bank?.agency_dv}
                        />
                    </div>
                    <div className="bank-info-input">
                        <TextInputForm
                            label="Conta*"
                            onChange={handleInputChange('bank.account')}
                            value={values.bank.account}
                            msgErro={touched.bank?.account && errors.bank?.account}
                        />
                        <TextInputForm
                            onChange={handleInputChange('bank.account_dv')}
                            value={values.bank.account_dv}
                            msgErro={touched.bank?.account_dv && errors.bank?.account_dv}
                        />
                    </div>
                </div>

                <div className="inputs-row col-2">
                    <SelectInputForm
                        label="Tipo de conta*"
                        data={[
                            { name: 'Corrente', value: 1 },
                            { name: 'Poupança', value: 2 },
                        ]}
                        onChange={handleSelectChange('bank.account_type')}
                        value={values.bank.account_type}
                        msgErro={touched.bank?.account_type && errors.bank?.account_type}
                    />
                    <TextInputForm
                        label="Operação"
                        onChange={handleInputChange('bank.operation_number')}
                        value={values.bank.operation_number}
                        msgErro={touched.bank?.operation_number && errors.bank?.operation_number}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Endereço
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="CEP*"
                        mask="99999-999"
                        onChange={handleInputChange('address.zip_code')}
                        value={values.address.zip_code}
                        msgErro={touched.address?.zip_code && errors.address?.zip_code}
                    />
                    <TextInputForm
                        label="Número*"
                        onChange={handleInputChange('address.number')}
                        value={values.address.number}
                        msgErro={touched.address?.number && errors.address?.number}
                    />
                    <TextInputForm
                        label="Complemento"
                        onChange={handleInputChange('address.complement')}
                        value={values.address.complement}
                        msgErro={touched.address?.complement && errors.address?.complement}
                    />
                </div>

                <ButtonForm
                    buttonText="Salvar"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
                />
            </div>
        </div>
    )
}

export default RecipientCreate
