import { memo, useCallback, useEffect, useRef, useState } from 'react'

import ModalLoading from 'components/modal-loading'

import { useResize } from 'hooks/use-resize'
import { IDestiny, IDestinyMap } from 'types'

import { CanvasStyled, Container } from './canvas.styled'

type Props = {
    selectedId?: number
    onSelectMap?(map?: IDestinyMap): void
    destiny: IDestiny
}
const Canvas: React.FC<Props> = memo(({ destiny, selectedId, onSelectMap }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const startRender = useRef(false)

    const zoomRef = useRef<number>(1)
    // const lastTouchDistanceRef = useRef<number | null>(null)
    const backgroundImage = useRef<HTMLImageElement>(new Image())
    const maxSizeRef = useRef<number>(0)
    const size = useResize(containerRef)

    const [imageLoaded, setImageLoaded] = useState(false)

    const _renderCanvas = useCallback(() => {
        const canvas = canvasRef.current

        if (!canvas) {
            return
        }
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        const maxSize = maxSizeRef.current
        canvas.width = maxSize
        canvas.height = maxSize
        ctx.drawImage(backgroundImage.current, 0, 0, maxSize, maxSize)

        function scale(value: number) {
            const scaled = (maxSize * value) / 1920
            return scaled
        }

        function pointInPolygon(point, polygon) {
            polygon

            let i,
                j,
                inside = false
            const nvert = polygon.length

            for (i = 0, j = nvert - 1; i < nvert; j = i++) {
                if (
                    polygon[i].y > point.y !== polygon[j].y > point.y &&
                    point.x <
                        ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y)) / (polygon[j].y - polygon[i].y) +
                            polygon[i].x
                ) {
                    inside = !inside
                }
            }
            return inside
        }

        canvas.addEventListener('click', function (event) {
            const rect = canvas.getBoundingClientRect()

            const x = event.clientX - rect.left
            const y = event.clientY - rect.top

            destiny.maps.forEach(map => {
                map.area.coordinates.forEach(points => {
                    const polygon = points.map(poit => ({ x: scale(poit[0]), y: scale(poit[1]) }))
                    const result = pointInPolygon({ x, y }, polygon)
                    if (result && onSelectMap) {
                        if (selectedId === map.id) {
                            onSelectMap(undefined)
                        } else {
                            onSelectMap(map)
                        }
                    }
                })
            })
        })

        function getCenterCoords(points: number[][]) {
            let x = 0
            let y = 0
            for (let i = 0; i < points.length; i++) {
                x += scale(points[i][0])
                y += scale(points[i][1])
            }
            x /= points.length
            y /= points.length
            return { x, y }
        }

        function createObject(id: number, positions: number[][], color: string, text: string) {
            if (!ctx) {
                return
            }
            if (!Array.isArray(positions) || positions.length === 0) {
                return
            }

            const zoom = zoomRef.current

            positions.pop()

            const center = getCenterCoords(positions)

            const first = positions.shift()

            if (!first) {
                return
            }

            ctx.beginPath()
            ctx.moveTo(scale(first[0]), scale(first[1]))

            positions.forEach((p, i) => {
                ctx.lineTo(scale(p[0]), scale(p[1]))
            })

            ctx.fillStyle = color

            ctx.fill()
            ctx.closePath()
            ctx.lineWidth = selectedId === id ? 2 : 1
            ctx.strokeStyle = selectedId === id ? '#1CAD35' : '#000'
            ctx.stroke()

            ctx.font = `bold ${scale(45)}px Arial`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#000'
            ctx.fillText(text, center.x, center.y)
            ctx.scale(zoom, zoom)
            return ctx
        }
        destiny.maps.forEach(map => {
            map.area.coordinates.forEach(points => {
                createObject(map.id, [...points], map.color, map.name)
            })
        })
    }, [destiny, selectedId, onSelectMap])

    useEffect(() => {
        setImageLoaded(false)
        if (!destiny) {
            return
        }
        backgroundImage.current.style.objectFit = 'contain'

        backgroundImage.current.src = destiny.image
        backgroundImage.current.onload = () => {
            setImageLoaded(true)
        }
        backgroundImage.current.onerror = () => {
            backgroundImage.current.src = `${destiny.image}?t=${new Date().getTime()}`
        }
    }, [destiny])

    useEffect(() => {
        if (imageLoaded && size) {
            maxSizeRef.current = size.height > size.width ? size.width : size.height
            if (!startRender.current) {
                startRender.current = true

                const newCanvas = document.createElement('canvas')
                containerRef.current.replaceChild(newCanvas, canvasRef.current)
                canvasRef.current = newCanvas

                _renderCanvas()
                startRender.current = false
            }

            // if (canvasRef.current) {
            //     const canvas = canvasRef.current

            //     let zoom = zoomRef.current
            //     let lastTouchDistance: number | null = lastTouchDistanceRef.current

            //     canvas.addEventListener('touchstart', e => {
            //         if (e.touches.length === 2) {
            //             lastTouchDistance = Math.hypot(
            //                 e.touches[0].clientX - e.touches[1].clientX,
            //                 e.touches[0].clientY - e.touches[1].clientY,
            //             )
            //         }
            //     })

            //     canvas.addEventListener('touchmove', e => {
            //         if (e.touches.length === 2) {
            //             const touchDistance = Math.hypot(
            //                 e.touches[0].clientX - e.touches[1].clientX,
            //                 e.touches[0].clientY - e.touches[1].clientY,
            //             )

            //             if (lastTouchDistance != null) {
            //                 zoom *= touchDistance / lastTouchDistance
            //             }

            //             lastTouchDistance = touchDistance

            //             zoomRef.current = zoom
            //             lastTouchDistanceRef.current = lastTouchDistance

            //             _renderCanvas()
            //         }
            //     })
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageLoaded, selectedId, size])

    return (
        <Container className="container-canvas" ref={containerRef}>
            <CanvasStyled ref={canvasRef} />
            <ModalLoading visible={!imageLoaded} absolute />
        </Container>
    )
})

export { Canvas }
