import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import './route-detail-history.styles.scss'

const RouteDetailHistory = ({ historic, orders }) => {
    console.log('orders', orders)
    return (
        <div className="route-detail-history-container">
            <div className="section-title">
                <FontAwesomeIcon icon="chart-line" /> Status da Rota
            </div>

            {historic?.map((item, index) => {
                const date = format(item.created_at, 'DD/MM/YYYY')
                const time = format(item.created_at, 'HH:mm:ss')
                const causer = item.causer?.full_name || item.biker?.full_name || ''

                return (
                    <div key={index} className="route-item">
                        <div className="tag route" />

                        <div className="text">
                            <div className="title">{item.message}</div>
                            <div className="value">
                                {date} às {time} por {causer}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default RouteDetailHistory
