import React, { memo, useCallback, useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { LoadingMessage, PerformanceCard } from 'components/_common'

import { getPaymentOrdersChart } from 'helpers/performance-page'
import api from 'services/api'
import { ChartObject, ReportPaymentForms } from 'types'

interface Props {
    params: any
}

const PaymentMethodsCard: React.FC<Props> = memo(({ params }) => {
    const [chart, setChart] = useState<ChartObject>()
    const [loading, setLoading] = useState(false)

    const _loadData = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api.get<ReportPaymentForms>('/painel/report/payment-orders', { params })
            const chart = getPaymentOrdersChart(response.data)
            setChart(chart)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (params) {
            _loadData(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <PerformanceCard title="Formas de Pagamento" subtitle="Baseado nos pedidos recebidos de todas as lojas">
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!chart && (
                        <Doughnut
                            data={chart.data}
                            height={400}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        position: 'right',
                                    },
                                    tooltip: {
                                        callbacks: chart.callbacks,
                                    },
                                },
                            }}
                        />
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { PaymentMethodsCard }
