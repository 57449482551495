/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useRef } from 'react'

import { ModalChangeTypeOrder, ModalChangeTypeOrderRef } from 'modals/modal-change-type-order/modal-change-type-order'

import { Order } from 'types'

type ShopkeeperContextType = {
    changeTypeOrder(order: Order): void
}

const InitialValue: ShopkeeperContextType = {
    changeTypeOrder: () => {},
}

const ShopkeeperContext = createContext<ShopkeeperContextType>(InitialValue)

type ShopkeeperContextProps = {
    children: any
}

const ShopkeeperContextProvider: React.FC<ShopkeeperContextProps> = memo(({ children }) => {
    const modalChangeTypeOrderRef = useRef<ModalChangeTypeOrderRef>(null)

    const _changeTypeOrder = useCallback((order: Order) => {
        modalChangeTypeOrderRef.current?.show(order)
    }, [])

    return (
        <ShopkeeperContext.Provider
            value={{
                changeTypeOrder: _changeTypeOrder,
            }}
        >
            {children}
            <ModalChangeTypeOrder ref={modalChangeTypeOrderRef} />
        </ShopkeeperContext.Provider>
    )
})

export { ShopkeeperContext, ShopkeeperContextProvider }
