import { useHistory } from 'react-router-dom'

import { LoadingMessage, LoadingMoreButton } from 'components/_common'

import { useUI } from 'contexts'
import { formatCurrency } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { BikerReport, PerformanceFilters } from 'types'

import {
    OrderTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    EmptyMessageContainer,
    EmptyImage,
    EmptyMessage,
    ActionHeader,
    ActionTitle,
    ButtonsContainer,
    ActionButton,
    TableId,
    TableBiker,
    TableSocial,
    TableVehicle,
    TableTotals,
    TableValue,
    TableButtons,
    DownloadButton,
    ReportButton,
    DownloadButtonContainer,
    DownloadText,
} from './general-reports-table.styles'

interface Props {
    bikers: BikerReport[]
    loadClick(): void
    loading: boolean
    totals: number | undefined
    filters: PerformanceFilters
}

const GeneralReportsTable: React.FC<Props> = ({ filters, bikers, loadClick, loading, totals }) => {
    const { setSnackbar, setLoading } = useUI()
    const history = useHistory()
    const { pathname } = history.location

    const exportSpreadsheet = async () => {
        try {
            setLoading(true)
            const response = await api.get('/painel/report/payments', {
                responseType: 'blob',
                params: {
                    ...filters,
                    download: 'xlsx',
                },
            })

            const downloadUrl = window.URL.createObjectURL(response)

            const timestamp = `${filters.start_date} a ${filters.end_date}`
            const filename = `Relatório Geral - ${timestamp}.xlsx`
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
            setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100)
        } catch (error) {
            setSnackbar({ message: 'Erro ao exportar relatório', type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const exportIndividualSpreadsheet = async (id: number) => {
        try {
            setLoading(true)
            const response = await api.get(`/painel/report/payments/agent/${id}`, {
                responseType: 'blob',
                params: {
                    ...filters,
                    download: 'xlsx',
                },
            })

            const downloadUrl = window.URL.createObjectURL(response)

            const timestamp = `${filters.start_date} a ${filters.end_date}`
            const filename = `Relatório Individual - ${timestamp}.xlsx`
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
            setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100)
        } catch (error) {
            setSnackbar({ message: 'Erro ao exportar relatório', type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <TableContainer>
            <OrderTable>
                <ActionHeader>
                    <ActionTitle>Relação de entregadores e valor a ser pago</ActionTitle>
                    <ButtonsContainer>
                        <ActionButton onClick={() => exportSpreadsheet()}>Exportar Relatório</ActionButton>
                    </ButtonsContainer>
                </ActionHeader>
                <TableHeader>
                    <TableId>ID</TableId>
                    <TableBiker>Entregador</TableBiker>
                    <TableSocial>Razão Social</TableSocial>
                    <TableVehicle>Veículo</TableVehicle>
                    <TableTotals>Total de entregas</TableTotals>
                    <TableValue>Valor de repasse</TableValue>
                    <TableButtons></TableButtons>
                </TableHeader>
                <TableContent>
                    {loading && bikers.length === 0 ? (
                        <LoadingMessage />
                    ) : bikers.length > 0 ? (
                        <>
                            {bikers?.map((item, index) => (
                                <TableItem key={index}>
                                    <TableId>
                                        <span>ID:</span> {item.id}
                                    </TableId>
                                    <TableBiker>
                                        <span>Entregador:</span> {item.name}
                                    </TableBiker>
                                    <TableSocial>
                                        <span>Razão Social:</span> {item.company_name}
                                    </TableSocial>
                                    <TableVehicle>
                                        <span>Veículo:</span> {item.type === 1 ? 'Motocicleta' : 'Bicicleta'}
                                    </TableVehicle>
                                    <TableTotals>
                                        <span>Total de Entregas:</span> {item.total_deliveries}
                                    </TableTotals>
                                    <TableValue>
                                        <span>Valor de repasse:</span> {formatCurrency(item.total_paid)}
                                    </TableValue>
                                    <TableButtons>
                                        <DownloadButtonContainer onClick={() => exportIndividualSpreadsheet(item.id)}>
                                            <DownloadButton />
                                            <DownloadText>Baixar relatório</DownloadText>
                                        </DownloadButtonContainer>
                                        <ReportButton
                                            onClick={() =>
                                                history.push(
                                                    `${
                                                        pathname.includes('financeiro')
                                                            ? 'relatorio-individual'
                                                            : 'relatorio-entregador'
                                                    }/${item.id}${location.search}`
                                                )
                                            }
                                        >
                                            Ver Relatório
                                        </ReportButton>
                                    </TableButtons>
                                </TableItem>
                            ))}
                            {bikers.length !== totals && <LoadingMoreButton loading={loading} onClick={loadClick} />}
                        </>
                    ) : (
                        <EmptyMessageContainer>
                            <EmptyImage />
                            <EmptyMessage>Nenhum relatório localizado</EmptyMessage>
                        </EmptyMessageContainer>
                    )}
                </TableContent>
            </OrderTable>
        </TableContainer>
    )
}

export default GeneralReportsTable
