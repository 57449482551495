import React, { forwardRef, memo, useMemo, useState } from 'react'

import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import { SwitchButton } from 'components/_marketplace-management'
import { DefaultButton } from 'components/default-button/default-button'

import { usePrinterConfigModalController } from './printer-config-modal.controller'
import {
    AppInfoLabel,
    AppIntoTitleContent,
    BlockContainer,
    ButtonRow,
    CancelButton,
    CardTitle,
    CheckContainer,
    CheckIcon,
    ChevronDown,
    CloseIcon,
    Container,
    Content,
    Divide,
    DivideContainer,
    DownloadFileIcon,
    DownloadInstallerButton,
    DownloadTextContainer,
    Footer,
    InfoContainer,
    InputContainer,
    Label,
    PrinterAppInfoContainer,
    PrinterAppInfoContent,
    PrinterButton,
    PrinterIcon,
    PrinterStatusContainer,
    ReloadButton,
    ReloadIcon,
    RemoveIcon,
    Row,
    SelectedPrinterBox,
    SubTitle,
} from './printer-config-modal.styled'

export type PrinterConfigModalRef = {
    show?(): void
    close?(): void
}

const PrinterConfigModal = memo(
    forwardRef<PrinterConfigModalRef>((_, ref) => {
        const [isShowInstallerInfo, setIsShowInstallerInfo] = useState<boolean>(false)
        const {
            isDirty,
            salesChannels,
            hasPrintConfig,
            selectedPrinters,
            lateralModalBaseRef,
            printerFormattedList,
            hasConnectionPrinterApp,
            _getPrinters,
            handleSubmit,
            checkPrinterAppConnection,
            _printTest,
            _printerName,
            _changeAutoPrint,
            _changeIsChecklist,
            _handleSalesChannels,
            _handleSelectPrinter,
            _handleRemoveSelectedPrinter,
        } = usePrinterConfigModalController(ref)

        const printerStatus = useMemo(() => {
            if (hasConnectionPrinterApp) {
                return (
                    <PrinterStatusContainer>
                        Ativo <CheckIcon />
                    </PrinterStatusContainer>
                )
            }
            return (
                <PrinterStatusContainer>
                    Logaroo Printer não iniciado, <span onClick={checkPrinterAppConnection}>Click para conectar</span>{' '}
                    <CloseIcon />
                </PrinterStatusContainer>
            )
        }, [hasConnectionPrinterApp, checkPrinterAppConnection])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title="Impressão de Comanda">
                <Container>
                    <PrinterAppInfoContainer>
                        <PrinterAppInfoContent>
                            <AppIntoTitleContent gap="0.75rem">
                                <AppInfoLabel color="primary">Instalador Logaroo Printer</AppInfoLabel>
                                <DownloadTextContainer>
                                    <DownloadInstallerButton
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/1fD1tGqPlRBnX0U6tl0c31_pYskSpln-o?usp=sharing"
                                    >
                                        <DownloadFileIcon /> Baixar instalador Logaroo Printer
                                    </DownloadInstallerButton>
                                </DownloadTextContainer>
                                <DivideContainer onClick={() => setIsShowInstallerInfo(state => !state)}>
                                    <Divide />
                                    <span>Como funciona</span>
                                    <ChevronDown active={isShowInstallerInfo} />
                                    <Divide />
                                </DivideContainer>
                                <InfoContainer show={isShowInstallerInfo}>
                                    <p>
                                        A Logaroo Printer é uma aplicação desenvolvida para conectar a Extranet Logaroo
                                        a sua impressora térmica, para que as comandas de pedidos possam ser impressas
                                        sem o auxílio de ERP ou outras aplicações externas, eliminando assim os erros de
                                        comunicação provenientes desse fluxo de informações passando por diferentes
                                        sistemas assim como a necessidade de gestão manual dessas informações.
                                    </p>
                                    <p>
                                        Para essa conexão a Logaroo Printer usa a rede local da sua Loja para a
                                        comunicação como noosso sistema, usando, por exemplo, seu roteador como ponto de
                                        conexão entre impressora e PC, sendo indispensável que ambos os dispositivos
                                        estejam conectados na mesma rede para que tudo funcione de forma correta.
                                    </p>
                                </InfoContainer>
                            </AppIntoTitleContent>
                        </PrinterAppInfoContent>
                    </PrinterAppInfoContainer>
                    <PrinterAppInfoContainer>
                        <PrinterAppInfoContent>
                            <PrinterIcon />
                            <AppIntoTitleContent hasConnection={hasConnectionPrinterApp}>
                                <AppInfoLabel>Logaroo Printer - V1.0</AppInfoLabel>
                                {printerStatus}
                            </AppIntoTitleContent>
                        </PrinterAppInfoContent>
                    </PrinterAppInfoContainer>
                    {hasConnectionPrinterApp && (
                        <PrinterAppInfoContainer isActive={!hasConnectionPrinterApp}>
                            {/* {!hasConnectionPrinterApp && <BlockContainer />} */}
                            <Row>
                                <CardTitle>Comanda de Produção</CardTitle>
                            </Row>
                            <Content>
                                <InputContainer>
                                    {!selectedPrinters?.production?.device ? (
                                        <React.Fragment>
                                            <Label>Selecione uma impressora</Label>

                                            <Row>
                                                <InputItem
                                                    type="select"
                                                    options={printerFormattedList}
                                                    inputProps={{
                                                        onChange: _handleSelectPrinter('production'),
                                                    }}
                                                />
                                                <ReloadButton onClick={_getPrinters}>
                                                    <ReloadIcon />
                                                </ReloadButton>
                                            </Row>
                                        </React.Fragment>
                                    ) : (
                                        <>
                                            <Label>Impressora</Label>
                                            <SelectedPrinterBox>
                                                <Row justify="start">
                                                    <PrinterIcon />
                                                    {_printerName(selectedPrinters?.production?.device)}
                                                </Row>
                                                <RemoveIcon onClick={_handleRemoveSelectedPrinter('production')} />
                                            </SelectedPrinterBox>
                                        </>
                                    )}
                                </InputContainer>
                                <InputContainer>
                                    <Row>
                                        <Label>Impressão Automática? </Label>
                                        <SwitchButton
                                            key={'production-switch'}
                                            isActive={selectedPrinters?.production?.auto_print}
                                            onClick={_changeAutoPrint('production')}
                                        />
                                    </Row>
                                </InputContainer>
                                <InputContainer>
                                    <SubTitle>
                                        Sempre que um novo pedido surgir na coluna ”Em Produção”, sua comanda será
                                        impressa automaticamente
                                    </SubTitle>
                                </InputContainer>
                                {!!selectedPrinters?.production?.auto_print && (
                                    <InputContainer>
                                        <CheckContainer>
                                            {salesChannels.map((channel, index) => (
                                                <Checkbox
                                                    key={index}
                                                    isSquared
                                                    checkboxTitle={channel.name}
                                                    inputProps={{
                                                        checked: true,
                                                        value: Number(
                                                            selectedPrinters?.production?.sales_channels?.includes(
                                                                channel.id
                                                            )
                                                        ),
                                                        onChange: _handleSalesChannels('production', channel.id),
                                                    }}
                                                />
                                            ))}
                                        </CheckContainer>
                                    </InputContainer>
                                )}
                                <InputContainer>
                                    <PrinterButton
                                        disabled={!selectedPrinters?.production?.device}
                                        onClick={_printTest('production')}
                                    >
                                        <PrinterIcon /> Teste Comanda de Produção
                                    </PrinterButton>
                                </InputContainer>
                            </Content>
                        </PrinterAppInfoContainer>
                    )}

                    {hasConnectionPrinterApp && (
                        <PrinterAppInfoContainer isActive={!hasConnectionPrinterApp}>
                            {/* {!hasConnectionPrinterApp && <BlockContainer />} */}
                            <Row>
                                <CardTitle>Comanda de Envio</CardTitle>
                            </Row>
                            <Content>
                                <InputContainer>
                                    {!selectedPrinters?.dispatch?.device ? (
                                        <>
                                            <Label>Selecione uma impressora</Label>
                                            <Row>
                                                <InputItem
                                                    type="select"
                                                    options={printerFormattedList}
                                                    inputProps={{
                                                        onChange: _handleSelectPrinter('dispatch'),
                                                    }}
                                                />
                                                <ReloadButton onClick={_getPrinters}>
                                                    <ReloadIcon />
                                                </ReloadButton>
                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <Label>Impressora</Label>
                                            <SelectedPrinterBox>
                                                <Row justify="start">
                                                    <PrinterIcon /> {_printerName(selectedPrinters?.dispatch?.device)}
                                                </Row>
                                                <RemoveIcon onClick={_handleRemoveSelectedPrinter('dispatch')} />
                                            </SelectedPrinterBox>
                                        </>
                                    )}
                                </InputContainer>

                                <InputContainer>
                                    <Row>
                                        <Label>Adicionar Checklist? </Label>
                                        <SwitchButton
                                            key={'dispatch-switch-checklist'}
                                            isActive={selectedPrinters?.dispatch?.with_checklist}
                                            onClick={_changeIsChecklist}
                                        />
                                    </Row>
                                </InputContainer>
                                <InputContainer>
                                    <Row>
                                        <Label>Impressão Automática? </Label>
                                        <SwitchButton
                                            key={'dispatch-switch'}
                                            isActive={selectedPrinters?.dispatch?.auto_print}
                                            onClick={_changeAutoPrint('dispatch')}
                                        />
                                    </Row>
                                </InputContainer>
                                <InputContainer>
                                    <SubTitle>
                                        Sempre que um novo pedido surgir na coluna ”Prontos para coleta”, sua comanda
                                        será impressa automaticamente
                                    </SubTitle>
                                </InputContainer>
                                {!!selectedPrinters?.dispatch?.auto_print && (
                                    <InputContainer>
                                        <CheckContainer>
                                            {salesChannels.map((channel, index) => (
                                                <Checkbox
                                                    key={index}
                                                    isSquared
                                                    checkboxTitle={channel.name}
                                                    inputProps={{
                                                        checked: true,
                                                        value: Number(
                                                            selectedPrinters?.dispatch?.sales_channels?.includes(
                                                                channel.id
                                                            )
                                                        ),
                                                        onChange: _handleSalesChannels('dispatch', channel.id),
                                                    }}
                                                />
                                            ))}
                                        </CheckContainer>
                                    </InputContainer>
                                )}
                                <InputContainer>
                                    <PrinterButton
                                        disabled={!selectedPrinters?.dispatch?.device}
                                        onClick={_printTest('dispatch', 'indoor', { only_checklist: true })}
                                    >
                                        <PrinterIcon /> Teste de Checklist
                                    </PrinterButton>
                                    <PrinterButton
                                        disabled={!selectedPrinters?.dispatch?.device}
                                        onClick={_printTest('dispatch', 'takeout')}
                                    >
                                        <PrinterIcon /> Teste Comanda Balcão
                                    </PrinterButton>
                                    <PrinterButton
                                        disabled={!selectedPrinters?.dispatch?.device}
                                        onClick={_printTest('dispatch', 'delivery')}
                                    >
                                        <PrinterIcon /> Teste Comanda Delivery
                                    </PrinterButton>
                                    <PrinterButton
                                        disabled={!selectedPrinters?.dispatch?.device}
                                        onClick={_printTest('dispatch', 'indoor')}
                                    >
                                        <PrinterIcon /> Teste Comanda Atendimento Mesa
                                    </PrinterButton>
                                </InputContainer>
                            </Content>
                        </PrinterAppInfoContainer>
                    )}
                </Container>
                {hasConnectionPrinterApp && (
                    <Footer>
                        <ButtonRow>
                            <CancelButton outline widthFitContainer onClick={lateralModalBaseRef.current?.close}>
                                Cancelar <CloseIcon />
                            </CancelButton>
                            <DefaultButton disabled={!isDirty} variant="success" onClick={() => handleSubmit()}>
                                {!hasPrintConfig ? 'Salvar' : 'Atualizar'}
                            </DefaultButton>
                        </ButtonRow>
                    </Footer>
                )}
            </LateralModalBase>
        )
    })
)

export { PrinterConfigModal }
