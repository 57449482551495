import React, { memo } from 'react'

import { getColorFromRoute } from 'helpers/getColorFromRoute'

import { Container, Avatar, Triangle } from './agent-marker.styled'
type Props = {
    src?: any
    alt?: string
    routeId?: number
}
const AgentMarker: React.FC<Props> = memo(({ src, alt, routeId }) => {
    return (
        <Container>
            <Avatar alt={alt} src={src} color={routeId && getColorFromRoute(routeId)} />
            <Triangle color={routeId && getColorFromRoute(routeId)} />
        </Container>
    )
})

export { AgentMarker }
