import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { baseURL } from 'services/api'
import { IAttachment } from 'types'

import { InputFile, ContainerFile, IconFile, FileName, ClickableArea } from './file-item.styled'

type FileItemProps = {
    index?: number
    file?: File
    isView?: boolean
    attachment?: IAttachment
    setFieldValue?(field: string, ...data: any)
    onRemove?(index: number): void
    onClick?(attachment?: IAttachment): void
}

const FileItem: React.FC<FileItemProps> = memo(
    ({ index, isView, file, attachment, onRemove, setFieldValue, onClick }) => {
        const [isEdit, setIsEdit] = useState(false)
        const [name, setName] = useState('')
        const [type, setType] = useState('')

        const isFile = useMemo(() => {
            return !!file
        }, [file])

        const _onClick = useCallback(() => {
            if (onClick) {
                onClick(attachment)
            }
        }, [attachment, onClick])

        const _saveName = useCallback(() => {
            setFieldValue(`collect_attachments.${index}`, { ...file, name: name + type })
            setIsEdit(false)
        }, [index, name, type, file, setFieldValue])

        const _onRemove = useCallback(() => {
            if (onRemove) {
                onRemove(index)
            }
        }, [index, onRemove])

        useEffect(() => {
            if (attachment?.original_name) {
                setName(attachment.original_name)
                if (attachment.original_name.lastIndexOf('.')) {
                    setType(attachment.original_name.substring(attachment.original_name.lastIndexOf('.')))
                }
            }
            if (file?.name) {
                setName((file.name || '').substring(0, file.name.lastIndexOf('.')))
                if (file.name.lastIndexOf('.')) {
                    setType(file.name.substring(file.name.lastIndexOf('.')))
                }
            }
        }, [file, attachment])

        return (
            <ContainerFile>
                <ClickableArea onClick={!isFile && _onClick}>
                    <IconFile icon={type?.toLocaleLowerCase()?.includes('pdf') ? 'file-pdf' : 'image'} />
                    {isEdit ? (
                        <InputFile value={name} onChange={({ target: { value } }) => setName(value)} />
                    ) : (
                        <FileName>{name}</FileName>
                    )}
                </ClickableArea>
                {isFile && (
                    <IconFile
                        onClick={() => (isEdit ? _saveName() : setIsEdit(true))}
                        icon={isEdit ? 'check' : 'edit'}
                    />
                )}
                {!isView && <IconFile denial icon={isFile ? 'times' : 'trash-alt'} onClick={_onRemove} />}
            </ContainerFile>
        )
    }
)

export { FileItem }
