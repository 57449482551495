import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatCurrency } from 'helpers'
import { RateExpress } from 'types'
import './style.scss'

interface Props {
    expressRate: RateExpress
    deleteClick(): void
    editClick(): void
}

const ExpressRatesTableRow: React.FC<Props> = ({ expressRate, deleteClick, editClick }) => {
    const { store, agent } = expressRate.value

    return (
        <div className="express-rates-table-row-item">
            <div className="rates-table-row-content id">{expressRate.id}</div>
            <div className="rates-table-row-content label">{expressRate.label}</div>
            <div className="rates-table-row-content charge-areas">
                {expressRate.payment_areas?.reduce(
                    (acc, item, index, array) =>
                        index < array.length - 1 ? `${acc}${item.label}, ` : `${acc}${item.label}`,
                    ''
                )}
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{store.bike != null ? formatCurrency(store.bike) : ''}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="bicycle" color="black" />
                    </div>
                    <span>{agent.bike != null ? formatCurrency(agent.bike) : ''}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{store.moto != null ? formatCurrency(store.moto) : ''}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="motorcycle" color="black" />
                    </div>
                    <span>{agent.moto != null ? formatCurrency(agent.moto) : ''}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{store.car != null ? formatCurrency(store.car) : ''}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="car" color="black" />
                    </div>
                    <span>{agent.car != null ? formatCurrency(agent.car) : ''}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{store.van != null ? formatCurrency(store.van) : ''}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="shuttle-van" color="black" />
                    </div>
                    <span>{agent.van != null ? formatCurrency(agent.van) : ''}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{store.truck != null ? formatCurrency(store.truck) : ''}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="truck" color="black" />
                    </div>
                    <span>{agent.truck != null ? formatCurrency(agent.truck) : ''}</span>
                </div>
            </div>
            <div className="rates-table-row-content status">{expressRate.status ? 'Ativo' : 'Inativo'}</div>
            <div className="rates-table-row-content button-row">
                <div className="button-item outline" onClick={deleteClick}>
                    Remover
                </div>
                <div className="button-item" onClick={editClick}>
                    Editar
                </div>
            </div>
        </div>
    )
}

export default ExpressRatesTableRow
