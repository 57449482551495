import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import { showErrors } from '../../helpers'
import { useEventListener } from '../../hooks'
import api from '../../services/api'
import ButtonForm from '../button-form'
import InputRadio from '../input-radio'
import ModalLoading from '../modal-loading'
import ModalMessage from '../modal-message'
import SelectInputForm from '../select-input-form'

const ModalCallUpBikers = forwardRef(({ onClose }, ref) => {
    const modalMessageRef = useRef()
    const modalCallUpRef = useRef()

    const [loading, setLoading] = useState(false)
    const [segment, setSegment] = useState('0')
    const [messageData, setMessageData] = useState({ message: '', error: '' })
    const [modalMessage, setModalMessage] = useState({ title: '', message: '', onClose: () => {} })

    const handleEscPress = useCallback(({ code }) => {
        if (code === 'Escape') {
            modalCallUpRef.current.closeModal()
        }
    }, [])

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            modalCallUpRef.current.openModal()
        },
        closeModal: () => {
            modalCallUpRef.current.closeModal()
            onClose && onClose()
        },
    }))

    const sendNotification = async () => {
        if (!messageData.message.length) {
            setMessageData({ ...messageData, error: 'Selecione uma mensagem' })

            return
        } else if (messageData.message.length && messageData.error.length) {
            setMessageData({ ...messageData, error: '' })
        }

        try {
            setLoading(true)

            await api.post('/painel/notification', {
                message: messageData.message,
                segment,
            })

            setModalMessage({
                title: 'Sucesso',
                message: 'Notificação enviada com sucesso!',
            })

            modalMessageRef.current.openModal()
        } catch (error) {
            setModalMessage({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessageRef.current.openModal()
        } finally {
            setLoading(false)
            modalCallUpRef.current.closeModal()
        }
    }

    return (
        <>
            <ModalMessage
                ref={modalMessageRef}
                title={modalMessage.title}
                message={modalMessage.message}
                onClose={modalMessage.onClose}
            />

            <ModalLoading visible={loading} />

            <ModalMessage ref={modalCallUpRef} title="Convocar entregadores" width="500px">
                <SelectInputForm
                    label="Mensagem"
                    placeholder="Escolha uma mensagem"
                    data={[
                        { value: 'Alta demanda de entregas. Venha agora para o Mall!' },
                        { value: 'Hoje é dia de oferta e muitas entregas! Venha para o Mall!' },
                        { value: 'Aproveite o adicional de R$1 nas entregas deste domingo!' },
                        { value: 'Almoço bombando! Venha agora para o Mall!' },
                        { value: 'A noite é longa! Entregas disponíveis para a madrugada!' },
                        { value: 'Atenção! Não esquecer de higienizar suas mãos e sua bag!' },
                        { value: 'Atenção! Utilize sempre sua máscara de proteção facial!' },
                    ]}
                    onChange={({ target: { value } }) => setMessageData({ ...messageData, message: value })}
                    value={messageData.message}
                    msgErro={messageData.error}
                />

                <div style={{ marginTop: '30px' }}>
                    <InputRadio
                        id="0"
                        checked={segment === '0'}
                        onClick={() => setSegment('0')}
                        label="Todos os entregadores"
                    />

                    <InputRadio id="1" checked={segment === '1'} onClick={() => setSegment('1')} label="Ciclistas" />

                    <InputRadio
                        id="2"
                        checked={segment === '2'}
                        onClick={() => setSegment('2')}
                        label="Motociclistas"
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonForm buttonText="Convocar" onClick={sendNotification} />
                </div>
            </ModalMessage>
        </>
    )
})

export default ModalCallUpBikers
