import { faClock, faFile, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ifood = 'https://static.pelando.com.br/live/merchants/avatar_web_square_94_2x/avatar/889_2.jpg'
export const rappi =
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Rappi_backgr_logo.png/480px-Rappi_backgr_logo.png'
export const uberEats = 'https://logodownload.org/wp-content/uploads/2019/05/uber-eats-logo-1.png'
export const undefinedStore =
    'https://images.vexels.com/media/users/3/152579/isolated/lists/a52ce2d4014c39b7b7c5974a1a1cbb85-icone-de-ponto-de-interrogacao-do-circulo-laranja.png'

export const OrderItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    white-space: normal;
    user-select: none;
    cursor: pointer;
    justify-content: space-between;
    &:hover {
        background-color: rgba(255, 134, 10, 0.1);
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
    }
`

export const ChannelLogo = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: -2px;
    right: -5px;
`

export const StoreLogo = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 55px;
        height: 55px;
    }
`

export const LogosContainer = styled.div`
    position: relative;
`

export const CustomerInfo = styled.div`
    width: 42%;
    margin-left: 10px;
    margin-right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-left: 0;
        width: 30%;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 16px;
    }
`

export const CustomerID = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: 12px;
`

export const CustomerName = styled.div`
    font-size: 14px;
    font-weight: 600;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const TimesContainer = styled.div`
    font-size: 12px;
    display: block;
    width: 30%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex: unset;
        width: 10%;
    }
`

export const TimeItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    margin-bottom: 5px;
`

export const TimeText = styled.div``

export const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

export const GreenClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

export const ActionRow = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: column;
        width: unset;
        flex-basis: 20%;
        margin-top: 0;
    }
`

export const RejectButton = styled.div`
    cursor: pointer;
    flex-basis: 48%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border: 0;
        text-transform: unset;
        font-weight: normal;
        padding: 0;
        font-size: 12px;
    }
    &:hover {
        text-decoration: underline;
    }
`

export const RejectIcon = styled(FontAwesomeIcon).attrs({
    icon: faFile,
})``

export const AcceptButton = styled.div<{ isActive?: boolean }>`
    flex-basis: 48%;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.success};
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.white)};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.success)};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 400ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`

export const ReturnButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.errorMessage};
    background-color: ${({ theme }) => theme.colors.errorMessage};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 400ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`

export const OptionsDropdown = styled.div<{ isActive: boolean }>`
    width: 150px;
    transition: 400ms;
    padding: 15px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -5px;
    right: -5px;
    overflow: hidden;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    z-index: 10;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        top: 75%;
    }
`

export const CloseDropdown = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
`

export const DropdownItem = styled.div`
    display: flex;
    width: 90%;
    white-space: normal;
    align-items: center;
    color: ${({ theme }) => theme.colors.lightGray};
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

export const DropdownText = styled.div`
    font-size: 12px;
    margin-left: 5px;
`

export const OrderPrice = styled.div`
    display: none;
    flex-basis: 30%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: block;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 12px;
        flex-basis: 16%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        flex-basis: 10%;
    }
`

export const PriceValue = styled.div`
    font-weight: bold;
`

export const PaymentMethod = styled.div``
