import { useEffect, useState, useCallback, useReducer, useRef } from 'react'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import { useAuth } from 'hooks'

import DelivererCard from '../../components/deliverer-card/deliverer-card'
import DelivererQueueTable from '../../components/delivery-queue-table/delivery-queue-table'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import SmallOrderCard from '../../components/small-order-card/small-order-card'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { formatAddress, getBikerType, getTimeDiff, getStatusColorReadyDeliver } from '../../helpers'
import api, { echoConnection } from '../../services/api'

import './style.scss'

const listReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return action.data.map(item => action.transform(item))
        case 'add':
            return [...state.filter(item => item.id !== action.item.id), action.transform(action.item)]
        case 'update':
            return state.map(item => (item.id === action.id ? { ...item, ...action.transform(action.item) } : item))
        case 'remove':
            return state.filter(item => item.id !== action.id)

        default:
            throw new Error('Unexpected action')
    }
}

const echo = echoConnection()

const DeliveryQueue = () => {
    const { mall, store } = useAuth()
    const {
        orders: { ordersReadyDeliver },
    } = useSelector(state => state)

    const modalConfirmationRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [ordersSocketConnected, setOrdersSocketConnected] = useState(false)
    const [nextOrders, dispatchNextOrders] = useReducer(listReducer, [])
    const [routeOrders, dispatchRouteOrders] = useReducer(listReducer, [])
    const [modalMessage, setModalMessage] = useState({
        title: '',
        message: null,
    })
    const [modalConfirmation, setModalConfirmation] = useState({
        title: '',
        message: '',
        textButton: null,
        onClose: () => {},
        onYes: () => {},
    })

    const getRouteOrders = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get('/painel/dm-orders', {
                params: {
                    mall_id: mall.id,
                    status: [5, 6, 7, 8],
                    merchant_id: store.id,
                    has_route: 1,
                },
            })

            dispatchRouteOrders({
                type: 'set',
                data: data.items.filter(order => order.routes?.status === 2),
                transform: item => {
                    return {
                        ...item,
                        route: item.routes,
                    }
                },
            })
        } catch (err) {
            console.log('GET ORDERS ERROR:', err)
            setModalMessage({
                title: 'Erro',
                message: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
            })
        } finally {
            setLoading(false)
        }
    }, [store.id, mall.id])

    const getNextOrders = useCallback(async () => {
        try {
            const { data } = await api.get('/painel/dm-orders', {
                params: {
                    mall_id: mall.id,
                    status: [4],
                    merchant_id: store.id,
                    has_route: 1,
                },
            })

            dispatchNextOrders({
                type: 'set',
                data: data.items.filter(order => order.routes?.status === 1 && order.agent.name),
                transform: item => {
                    const acceptedRoute = item?.routes

                    return {
                        ...item,
                        route: acceptedRoute,
                    }
                },
            })
        } catch (err) {
            console.log('GET ORDERS ERROR:', err)
            setModalMessage({
                title: 'Erro',
                message: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
            })
        } finally {
            setLoading(false)
        }
    }, [store.id, mall.id])

    const getData = useCallback(() => {
        getRouteOrders()
        getNextOrders()
    }, [getNextOrders, getRouteOrders])

    useEffect(() => {
        getData()
    }, [getData])

    const onUpdateOrder = useCallback(order => {
        if (Number(order.status) === 5) {
            dispatchNextOrders({ type: 'remove', id: order.id })
            dispatchRouteOrders({
                type: 'add',
                item: {
                    ...order,
                    route: order.routes,
                },
                transform: item => item,
            })
        }

        if (Number(order.status) <= 8) {
            dispatchRouteOrders({
                type: 'update',
                id: order.id,
                item: order,
                transform: item => ({ ...item, route: item.routes, biker: item?.agent }),
            })
        } else {
            dispatchRouteOrders({ type: 'remove', id: order.id })
        }
    }, [])

    const listenToOrdersChanges = useCallback(() => {
        try {
            echo.private(`orders.store.${store.id}`)
                .listen('.updated', ({ data }) => onUpdateOrder(data))
                .listen('.in_route', ({ data }) => {
                    if (data.agent?.name) {
                        dispatchNextOrders({
                            type: 'add',
                            item: data,
                            transform: item => {
                                const acceptedRoute = item.routes

                                return {
                                    ...item,
                                    route: acceptedRoute,
                                }
                            },
                        })
                    }
                })
                .listen('.out_route', ({ data }) => {
                    dispatchNextOrders({ type: 'remove', id: data.id })
                })
                .listen('.started', ({ data }) => {
                    dispatchRouteOrders({
                        type: 'add',
                        item: {
                            ...data,
                            route: data.routes,
                        },
                        transform: item => item,
                    })
                })

            setOrdersSocketConnected(true)
        } catch (error) {
            console.log({ error })
        }
    }, [store.id, onUpdateOrder])

    useEffect(() => {
        if (!ordersSocketConnected) {
            listenToOrdersChanges()
        }
    }, [listenToOrdersChanges, ordersSocketConnected])

    const confirmCollect = async order_id => {
        try {
            setLoading(true)

            await api.put(`/painel/dm-order/${order_id}`, { status: 5 })

            setModalMessage({ title: 'Status alterado', message: 'Status do pedido alterado com sucesso' })
        } catch (error) {
            console.log({ error })

            setModalMessage({ title: 'Erro', message: 'Houve um problema ao alterar o status do pedido' })
        } finally {
            setLoading(false)
        }
    }

    const onConfirmCollect = order => {
        const { id, reference_id, biker } = order

        setModalConfirmation({
            title: 'Mudança de status',
            onYes: () => confirmCollect(id),
            message: `Você confirma que o pedido #${reference_id} está
            sendo coletado neste momento por ${biker?.first_name} ${biker?.last_name}?`,
        })

        modalConfirmationRef.current.openModal()
    }

    return (
        <div>
            <ModalLoading visible={loading} />

            <ModalMessage
                isActive={modalMessage.message?.length}
                title={modalMessage.title}
                message={modalMessage.message}
                textButton="Ok"
                onClose={() => setModalMessage({ title: '', message: null })}
            />

            <ModalConfirmation
                ref={modalConfirmationRef}
                title="Confirmação de coleta"
                message={modalConfirmation.message}
                onYes={modalConfirmation.onYes}
            />

            <TopRowDefault title="Fila de coletas" />

            <div className="delivery-queue-page-content">
                <div className="left-side">
                    {nextOrders.length ? (
                        <div className="title-row">
                            <FontAwesomeIcon icon="shopping-bag" />
                            <div className="text">Próximas Coletas</div>
                        </div>
                    ) : null}

                    {nextOrders.map(order => {
                        return (
                            <DelivererCard
                                key={order.id}
                                orderNumber={order.reference_id}
                                orderDate={format(order.birth, 'DD/MM/YYYY')}
                                orderTime={format(order.birth, 'HH:mm')}
                                routeTime={`${getTimeDiff(order.route.created_at).text}`}
                                delivererName={order?.agent?.name}
                                delivererPhoto={order?.agent?.avatar}
                                delivererVehicle={getBikerType(order?.agent?.type)}
                                clientName={order.customer.name}
                                clientAddress={formatAddress(order.address)}
                                onConfirmCollect={() => onConfirmCollect(order)}
                            />
                        )
                    })}

                    <div className="title-row">
                        <FontAwesomeIcon icon="clock" />
                        <div className="text">Pedidos aguardando coleta</div>
                    </div>
                    {ordersReadyDeliver.items.map(item => {
                        const { minutes, text } = getTimeDiff(item.created_at)

                        return (
                            <SmallOrderCard
                                key={item.id}
                                statusColor={getStatusColorReadyDeliver(minutes)}
                                orderNumber={item.reference_id}
                                clientName={item.customer.name}
                                clientAddress={formatAddress(item.address)}
                                totalTime={text}
                            />
                        )
                    })}
                </div>

                <div className="right-side">
                    <div className="title-row">
                        <FontAwesomeIcon icon="biking" />
                        <div className="text">Pedidos em rota</div>
                    </div>

                    <DelivererQueueTable orders={routeOrders} />
                </div>
            </div>
        </div>
    )
}

export default DeliveryQueue
