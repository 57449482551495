import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { getIn, useFormik } from 'formik'

import ButtonForm from 'components/button-form'
import EmptyMessage from 'components/empty-message/empty-message'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

interface Validations {
    document_number_validation: boolean
    phone_validation: boolean
    custom_validation1: boolean
    custom_validation2: boolean
    custom_validation3: boolean
    validate_before_transaction: boolean
}

export const PartnerValidations: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [establishments, setEstablishments] = useState({ establishment_list: [] })
    const [selectedEstablishment, setSelectedEstablishment] = useState('')
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const {
        errors,
        getFieldProps,
        handleSubmit,
        initialValues,
        isSubmitting,
        setFieldValue,
        setValues,
        touched,
        values,
    } = useFormik({
        initialValues: {
            establishment_id: '',
            validation_endpoint: 'https://api.dev.logaroo.com.br/v1/stone/validations',
            labels_of_validations: {
                document_number_validation_label: '',
                phone_validation_label: '',
                custom_validation1_label: '',
                custom_validation2_label: '',
                custom_validation3_label: '',
            },
            validations: {
                document_number_validation: false,
                phone_validation: false,
                custom_validation1: false,
                custom_validation2: false,
                custom_validation3: false,
                validate_before_transaction: true,
            },
        },
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                setSubmitting(true)

                const { data } = await siclosPagApi.post('/partner-validations/select-validations', {
                    ...values,
                    establishment_id: selectedEstablishment,
                })

                if (data?.error) {
                    throw data.msg
                }

                setSelectedEstablishment('')
                resetForm()

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Validações configuradas com sucesso!',
                })

                modalMessage.current?.openModal()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    isActive: false,
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessage.current?.openModal()
            } finally {
                setSubmitting(false)
            }
        },
    })

    const getPartnerValidations = useCallback(
        async (selectedEstablishment: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get(`/partner-validations/get-validations/${selectedEstablishment}`)

                if (!data?.selected_validations) {
                    setValues(initialValues)
                    return
                }

                const { selected_validations } = data

                setValues({
                    ...initialValues,
                    labels_of_validations: {
                        ...initialValues.labels_of_validations,
                        ...selected_validations.labels_of_validations,
                    },
                    validations: {
                        ...initialValues.validations,
                        ...selected_validations.validations,
                    },
                    validation_endpoint: selected_validations.endpoint_to_send_validations.validation_endpoint,
                })
            } catch (error) {
                setSelectedEstablishment('')
                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [initialValues, setValues]
    )

    useEffect(() => {
        selectedEstablishment && getPartnerValidations(selectedEstablishment)
    }, [getPartnerValidations, selectedEstablishment])

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    function getFormInputError(name: string) {
        const error = getIn(errors, name)
        const touch = getIn(touched, name)
        return touch && error ? error : null
    }

    return (
        <div>
            <RowTitle title="Validações">
                <SelectInputForm
                    style={{ margin: 0, marginRight: '10px' }}
                    placeholder="Selecione um estabelecimento"
                    data={establishments.establishment_list?.map(({ id, business_name }) => ({
                        name: business_name,
                        value: id,
                    }))}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedEstablishment(value)
                    }}
                    value={selectedEstablishment}
                />
            </RowTitle>

            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            {selectedEstablishment?.length ? (
                <div className="stone-form-container">
                    <TextInputForm
                        label="Endpoint para verificação*"
                        {...getFieldProps('validation_endpoint')}
                        msgErro={getFormInputError(`validation_endpoint`)}
                    />

                    {Object.keys(values.validations).map((key, index) => (
                        <div className="inputs-row col-2" key={key}>
                            {key === 'document_number_validation' ? (
                                <TextInputForm
                                    label="Rótulo para validação de documento*"
                                    placeholder="Por exemplo: Digite seu CPF"
                                    disabled={!values.validations[key]}
                                    {...getFieldProps(`labels_of_validations.${key}_label`)}
                                    msgErro={getFormInputError(`labels_of_validations.${key}_label`)}
                                />
                            ) : null}

                            {key === 'phone_validation' ? (
                                <TextInputForm
                                    label="Rótulo para validação de número de telefone*"
                                    placeholder="Por exemplo: Digite seu número de telefone"
                                    disabled={!values.validations[key]}
                                    {...getFieldProps(`labels_of_validations.${key}_label`)}
                                    msgErro={getFormInputError(`labels_of_validations.${key}_label`)}
                                />
                            ) : null}

                            {key.includes('custom_validation') ? (
                                <TextInputForm
                                    label={`Validação customizada ${index - 1}*`}
                                    placeholder="Por exemplo: Digite seu email (ou qualquer outro campo suportado pela api)"
                                    disabled={!values.validations[key as keyof Validations]}
                                    {...getFieldProps(`labels_of_validations.${key}_label`)}
                                    msgErro={getFormInputError(`labels_of_validations.${key}_label`)}
                                />
                            ) : null}

                            {key !== 'validate_before_transaction' && key !== 'validations_id' ? (
                                <div className="radio-group" style={{ marginRight: '30%' }}>
                                    <label>Validação ativa?</label>
                                    <div className="radio-group-row">
                                        <InputRadio
                                            id={`${key}-true`}
                                            label="Sim"
                                            checked={values.validations[key as keyof Validations]}
                                            onClick={() => setFieldValue(`validations.${key}`, true)}
                                        />

                                        <InputRadio
                                            id={`${key}-false`}
                                            label="Não"
                                            checked={!values.validations[key as keyof Validations]}
                                            onClick={() => setFieldValue(`validations.${key}`, false)}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ))}

                    <ButtonForm
                        buttonText="Salvar"
                        type="submit"
                        onClick={handleSubmit}
                        style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
                    />
                </div>
            ) : (
                <EmptyMessage>Selecione um estabelecimento para edição</EmptyMessage>
            )}
        </div>
    )
}

export default PartnerValidations
