import { faHome, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const BikerPhoneContainer = styled.div`
    display: flex;
    margin-bottom: 2px;
`

export const MallIcon = styled(FontAwesomeIcon).attrs({
    icon: faHome,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    padding-top: 4px;
    font-size: 12px;
`
export const VehicleIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    padding-top: 4px;
    font-size: 12px;
`

export const Value = styled.div`
    font-size: 14px;
`

export const EyeIcon = styled(FontAwesomeIcon).attrs({
    icon: faEye,
})<{ status?: 'active' | 'suspended' | 'closed' }>`
    color: ${({ status, theme }) => {
        if (status === 'active') {
            return theme.colors.success
        }
        if (status === 'suspended') {
            return theme.colors.yellow
        }
        if (status === 'closed') {
            return theme.colors.errorMessage
        }
        return '#aaa'
    }};
`
