import styled from 'styled-components'

const DraggableShowcaseContainer = styled.div`
    display: flex !important;
    align-items: center;
    gap: 0.5rem;

    // width:  auto;
    left: 1.25rem !important;
    background: #f0f0f0;
    &:nth-child(odd) {
        background-color: #fff;
    }

    .accordion-content {
        padding-left: 50px;
        overflow: auto;
        transition: max-height 0.6s ease;
    }
`

const TableHeaderRow = styled.div`
    font-size: 0.875rem;
    font-weight: bold;
    background-color: #eaeaea;

    padding: 1.25rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const TableRow = styled.div`
    width: 100%;
    padding: 1.25rem;
`

const TableRowItem = styled.div`
    padding: 0;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const TableRowContent = styled.div`
    font-size: 0.875rem;
    margin-bottom: 0.625rem;
    width: 100%;

    &.button-row {
        width: 30%;
        display: flex;

        .button-item {
            user-select: none;
            margin-right: 1.25rem;
            background-color: var(--primary-color);
            padding: 0.625rem 1.25rem;
            border-radius: 0.25rem;
            color: white;
            font-weight: bold;
            border: 0.063rem solid var(--primary-color);
            cursor: pointer;

            &.outline {
                background-color: transparent;
                color: var(--primary-color);
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
`

export { DraggableShowcaseContainer, TableRow, TableRowItem, TableRowContent, TableHeaderRow }
