import { format } from 'date-fns'

import { ItemTitle, ItemValue, TimelineContainer, TimelineItem, TimelineTitle } from './payment-timeline.styles'

interface Props {
    statusList: Array<{
        created_at: string
        status: string
    }>
}

const PaymentTimeline: React.FC<Props> = ({ statusList }) => {
    return (
        <>
            <TimelineTitle>Status do pagamento</TimelineTitle>
            <TimelineContainer>
                {statusList
                    ?.slice(0)
                    .reverse()
                    .map((status, statusIndex) => (
                        <TimelineItem key={statusIndex}>
                            <ItemValue>{format(new Date(status.created_at), 'DD/MM/YYYY - HH:mm')}</ItemValue>
                            <ItemTitle>{status.status}</ItemTitle>
                        </TimelineItem>
                    ))}
            </TimelineContainer>
        </>
    )
}

export default PaymentTimeline
