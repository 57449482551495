import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'

import SelectInputForm from 'components/select-input-form'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import { AvailableStores, Rate } from 'types'

import EmptyMessage from '../../components/empty-message/empty-message'
import ModalConfirmation, { ModalConfirmationRef } from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from '../../components/modal-message'
import RatesListingTableHeader from '../../components/rates-listing-table-header/rates-listing-table-header'
import RatesListingTableRow from '../../components/rates-listing-table-row/rates-listing-table-row'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { showErrors } from '../../helpers'
import api from '../../services/api'

import './style.scss'

const RatesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { basePath, mall } = useAuth()
    const { setLoading } = useUI()

    const { state: locationState } = useLocation<{ store_id: number }>()

    const [loading, toggleLoading] = useState(false)
    const [rates, setRates] = useState<{
        current_page: number
        items: Rate[]
        totals: number
    }>({ current_page: 1, items: [], totals: 1 })
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [deleteID, setDeleteID] = useState<number | null>(null)
    const [availableStores, setAvailableStores] = useState<AvailableStores>()
    const [selectedStore, setSelectedStore] = useState<number | string | null>(null)
    const [selectOptions, setSelectOptions] = useState([])

    const _getRates = useCallback(async () => {
        try {
            toggleLoading(true)

            const filter =
                !!selectedStore && selectedStore !== 'mall' ? { store_id: selectedStore } : { mall_id: mall.id }

            const { data } = await api.get(`/painel/rates`, {
                params: {
                    ...filter,
                    per_page: -1,
                },
            })
            setRates(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push(`${basePath}/tarifas`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [basePath, history, mall.id, selectedStore])

    useEffect(() => {
        _getRates()
    }, [_getRates])

    const getAvailableStores = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/stores`, {
                params: {
                    mall_id: mall?.id,
                    per_page: -1,
                },
            })

            setAvailableStores(data)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    useEffect(() => {
        getAvailableStores()
    }, [getAvailableStores])

    const deleteRate = useCallback(async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate/${deleteID}`)

            _getRates()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteID])

    useEffect(() => {
        setSelectedStore(locationState?.store_id ?? 'mall')
        if (availableStores) {
            const available = []
            availableStores?.items?.map(item => available.push({ name: item.name, value: item.id }))

            setSelectOptions([{ name: mall?.name, value: 'mall' }, ...available])

            const select = document.getElementsByTagName('select')
            select[0].selectedIndex = 1
        }
    }, [availableStores, mall, locationState?.store_id])

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="dollar-sign">Esta unidade não possui tarifas cadastradas.</EmptyMessage>
    }

    const handleSelect = useCallback(value => {
        if (value === 'mall') {
            setSelectedStore('mall')
        } else {
            setSelectedStore(value)
        }
    }, [])

    return (
        <div className="rates-listing-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Deseja realmente excluir esta tarifa?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                onBackButtonClick={() => history.replace(`${basePath}/gestao-mall`)}
                title="Tarifas"
                buttons={[
                    {
                        title: 'Criar tarifa',
                        onClick: () => history.replace(`${basePath}/criar-tarifa`, { store_id: selectedStore }),
                        // icon: 'plus'
                    },
                ]}
            />
            <div className="store-select-input-container">
                <SelectInputForm
                    label="Estabelecimento"
                    data={selectOptions}
                    onChange={({ target: { value } }) => {
                        handleSelect(value)
                    }}
                    value={selectedStore}
                />
            </div>

            <RatesListingTableHeader />

            {rates?.items?.map((rate, index) => (
                <RatesListingTableRow
                    key={index}
                    rate={rate}
                    editClick={() => history.push(`${basePath}/tarifa/${selectedStore}/${rate.id}`)}
                    deleteClick={() => {
                        setDeleteID(rate.id)

                        modalDelete.current?.openModal()
                    }}
                />
            ))}

            {!rates?.items?.length && !loading && renderEmptyMessage()}
        </div>
    )
}

export default RatesListing
