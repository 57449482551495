import { useCallback, useContext, useEffect, useState } from 'react'

import { useUI } from 'contexts'
import { ChangeOrderTypeContext } from 'contexts/change-order-type-context'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IDestiny, IDestinyMap, PagedList } from 'types'
import { IFloor, IZone, IZoneTypes } from 'types/floor-map'

function useIndoorContent() {
    const { order, setType, setShow, setLoading } = useContext(ChangeOrderTypeContext)
    const { mall } = useAuth()
    const { setErrorModal } = useUI()

    const [floors, setFloors] = useState<IFloor[]>([])
    const [selectedFloor, setSelectedFloor] = useState<IDestiny>()
    const [selectedArea, setSelectedArea] = useState<IDestinyMap>()

    const _close = useCallback(() => {
        setType(undefined)
    }, [setType])

    const _confirm = useCallback(async () => {
        if (!selectedArea && !selectedFloor) {
            return
        }
        setLoading(true)
        try {
            const { address } = mall
            await api.put(`painel/dm-order/${order.id}`, {
                ...order,
                address: {
                    city: address.city.name,
                    state: address.city.state.uf,
                    street: address.street,
                    number: address.number,
                    zipcode: address.zipcode,
                    neighborhood:
                        typeof address.neighborhood === 'string' ? address.neighborhood : address.neighborhood.name,
                    complement: address.complement,
                    landmark: address.landmark,
                    lat: address.lat,
                    lng: address.lng,
                },
                destiny: {
                    id: selectedArea.id,
                    name: selectedArea.name,
                    floor: {
                        id: selectedFloor.id,
                        name: selectedFloor.name,
                    },
                },
                type: 'indoor',
            })
            setShow(false)
        } catch (error) {
            setErrorModal({
                title: 'Erro ao alterar o tipo de entrega',
                subtitle: showErrors(error),
            })
        }
        setLoading(false)
    }, [order, mall, selectedArea, selectedFloor, setLoading, setShow, setErrorModal])

    const _getZones = useCallback(async (id: number, type?: IZoneTypes) => {
        try {
            const { data } = await api.get<PagedList<IZone>>(`painel/floor-maps/`, {
                params: { floor: id, order: 'asc', per_page: 999, type },
            })

            return data.items
        } catch (error) {
            console.log('error', error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _setFloor = useCallback(
        async (floor: IFloor) => {
            const maps = await _getZones(floor.id, 'indoor')

            setSelectedFloor({
                id: floor.id,
                image: floor.image,
                name: floor.label,
                maps: maps.map(e => ({
                    id: e.id,
                    color: e.color,
                    name: e.label,
                    area: e.area,
                })),
            })
        },
        [_getZones]
    )

    const _onSelectFloor = useCallback(
        (floor: IFloor) => {
            return () => _setFloor(floor)
        },
        [_setFloor]
    )

    const _getFloors = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IFloor>>('painel/floors', { params: { mall: mall.id } })

            if (data.items.length > 0) {
                setFloors(data.items)
                const firstFloor = data.items[0]
                await _setFloor(firstFloor)
            }
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [mall.id, _setFloor, setLoading])

    const _onSelectArea = useCallback((area?: IDestinyMap) => {
        setSelectedArea(area)
    }, [])

    useEffect(() => {
        _getFloors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { floors, selectedFloor, selectedArea, _onSelectArea, _close, _confirm, _onSelectFloor }
}

export { useIndoorContent }
