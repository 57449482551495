import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const SearchContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex: 1;
`

const SearchInput = styled.input`
    border: 0;
    outline: none;
    flex: 1;
    background-color: transparent;
`

const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    margin-left: 10px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }
`

const ContainerItem = styled.div`
    display: flex;
    padding: 15px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.lightGray};
`

const ChannelLogo = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-right: 5px;
`

const CustomerInfo = styled.div`
    flex: 1;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 37%;
    }
`

const CustomerID = styled.div`
    color: #666;
    font-size: 12px;
`

const StoreName = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    font-weight: bold;
`

const CustomerName = styled.div`
    font-size: 14px;
    font-weight: 600;
    width: 80%;
`

const TotalPrice = styled.div`
    color: #27ae70;
    font-size: 10px;
`

const Button = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
    &:hover {
        opacity: 0.8;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .input-item {
        width: 124px;
        margin-bottom: 0;
        margin-right: 5px;
        height: 39px;
        select {
            border: 1px solid ${({ theme }) => theme.colors.lightGray};
            border-radius: 4px;
            height: 40px;
        }
    }
    * {
        box-sizing: border-box;
    }
`
const ContainerActions = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
`
const ContainerStatus = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
`

const Circle = styled.div<{ color?: string }>`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ color }) => (color ? color : '#999999')};
    margin-right: 5px;
`

const StatusName = styled.div<{ uppercase?: boolean }>`
    font-size: 10px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`

const SeeOrder = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 15px;
    cursor: pointer;
    width: fit-content;
    font-size: 11px;
    margin-top: 20px;
    align-self: flex-end;
`

const Header = styled.div`
    background-color: #f5f5f5;
    padding: 15px;
    font-weight: bold;
`

export {
    Header,
    ContainerStatus,
    SeeOrder,
    Circle,
    ContainerActions,
    StatusName,
    Row,
    SearchContainer,
    StoreName,
    SearchInput,
    CustomerID,
    SearchIcon,
    ContainerItem,
    ChannelLogo,
    CustomerInfo,
    CustomerName,
    TotalPrice,
    Button,
}
