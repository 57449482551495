import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ChannelItemContainer = styled.div<{ isActive?: boolean }>`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.white)};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
    user-select: none;
`

export const ChannelImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`

export const CheckContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
`

export const CheckIcon = styled(FontAwesomeIcon).attrs({
    icon: faCheck,
})`
    line-height: 1;
    font-size: 12px;
`
