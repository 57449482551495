import classNames from 'classnames'

import './style.scss'

interface Props {
    leftItem: string | JSX.Element
    rightItem: string | JSX.Element
    leftItemActive: boolean
    rightItemActive: boolean
    setLeftItemActive(): void
    setRightItemActive(): void
}

const ViewControl: React.FC<Props> = ({
    leftItem,
    rightItem,
    leftItemActive,
    rightItemActive,
    setLeftItemActive,
    setRightItemActive,
}) => {
    const leftActive = classNames('button-item', {
        active: leftItemActive,
    })

    const rightActive = classNames('button-item', {
        active: rightItemActive,
    })

    return (
        <div className="view-control-container">
            <div className={leftActive} onClick={setLeftItemActive}>
                {leftItem}
            </div>
            <div className={rightActive} onClick={setRightItemActive}>
                {rightItem}
            </div>
        </div>
    )
}

export default ViewControl
