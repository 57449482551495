import styled from 'styled-components'

export const MainMenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: unset;
    }
`
