import { useEffect, useState } from 'react'

import { Accordion } from '..'

import {
    ContentTitle,
    MallSettingsModalBackground,
    MallSettingsModalBox,
    MallSettingsModalContainer,
    MallSettingsModalContent,
    CloseButton,
    CloseContainer,
    PersonalDataHeader,
    MallsListing,
    StoreItem,
    MallInfoContainer,
    MallCity,
    MallTitle,
    MallAddress,
} from './mall-settings-modal.styles'
import { malls } from './mock-sections'

interface Props {
    isActive: boolean
    closeClick(): void
}

const MallSettingsModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <MallSettingsModalContainer isDisplayed={isDisplayed}>
            <MallSettingsModalBox isEffectActive={isEffectActive}>
                <PersonalDataHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Configurar Mall</ContentTitle>
                    <MallInfoContainer>
                        <MallCity>Fortaleza - CE</MallCity>
                        <MallTitle>Delivery Mall - Aldeota</MallTitle>
                        <MallAddress>Rua Osvaldo Cruz, 2095</MallAddress>
                    </MallInfoContainer>
                </PersonalDataHeader>
                <MallSettingsModalContent>
                    <MallsListing>
                        {malls.map((item, index) => (
                            <Accordion accordionTitle={item.section_title} key={index} iconType={item.type}>
                                {item.stores.map((item, index) => (
                                    <StoreItem key={index} to="/">
                                        {item}
                                    </StoreItem>
                                ))}
                            </Accordion>
                        ))}
                    </MallsListing>
                </MallSettingsModalContent>
            </MallSettingsModalBox>
            <MallSettingsModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </MallSettingsModalContainer>
    )
}

export default MallSettingsModal
