import { useEffect, useState } from 'react'

import { useFormik } from 'formik'

import TextInputForm from 'components/text-input-form'

import { useUI } from 'contexts'
import api from 'services/api'
import { Order } from 'types'

import {
    ContentTitle,
    OrderRatesSideModalBackground,
    OrderRatesSideModalBox,
    OrderRatesSideModalContainer,
    OrderRatesSideModalContent,
    CloseButton,
    CloseContainer,
    PersonalDataHeader,
    ButtonRow,
    TextButton,
    ConfirmButton,
    InputSection,
    InputSectionTitle,
    InputSectionRow,
    InputSectionItem,
    VerifyButton,
} from './order-rates-side-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
    order: Order
    revalidate(): void
}

const OrderRatesSideModal: React.FC<Props> = ({ isActive, closeClick, order, revalidate }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)

    async function setDefaultValue(rateType) {
        const { data: ratesResponse } = await api.get(order ? `/painel/dm-order/${order?.id}/rates` : null)
        console.log(ratesResponse)

        if (rateType === 'base') {
            setValues({
                ...values,
                store_delivery_rate: ratesResponse?.base?.store !== -1 ? ratesResponse.base?.store : 0,
                agent_rate: ratesResponse?.base?.agent !== -1 ? ratesResponse.base?.agent : 0,
            })
        }

        if (rateType === 'collect') {
            setValues({
                ...values,
                store_delivery_pick: ratesResponse?.collect?.store !== -1 ? ratesResponse.collect?.store : 0,
                agent_pick: ratesResponse?.collect?.agent !== -1 ? ratesResponse.collect?.agent : 0,
            })
        }

        if (rateType === 'dynamic') {
            setValues({
                ...values,
                store_delivery_dynamic_rate: ratesResponse?.dynamic?.store !== -1 ? ratesResponse.dynamic?.store : 0,
                agent_dynamic_rate: ratesResponse?.dynamic?.agent !== -1 ? ratesResponse.dynamic?.agent : 0,
            })
        }

        if (rateType === 'express') {
            setValues({
                ...values,
                store_delivery_express: ratesResponse?.express?.store !== -1 ? ratesResponse.express?.store : 0,
                agent_express: ratesResponse?.express?.agent !== -1 ? ratesResponse.express?.agent : 0,
            })
        }

        if (rateType === 'return') {
            setValues({
                ...values,
                store_rate_return: ratesResponse?.return?.store !== -1 ? ratesResponse.return?.store : 0,
                agent_rate_return: ratesResponse?.return?.agent !== -1 ? ratesResponse.return?.agent : 0,
            })
        }
    }

    const { setLoading } = useUI()
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive && order) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            setValues({
                id: order?.id || 0,
                agent_pick: order?.agent_pick || 0,
                store_delivery_pick: order?.store_delivery_pick || 0,

                agent_express: order?.agent_express || 0,
                store_delivery_express: order?.store_delivery_express || 0,

                agent_rate: order?.agent_rate || 0,
                store_delivery_rate: order?.store_delivery_rate || 0,

                agent_rate_return: order?.agent_rate_return || 0,
                store_rate_return: order?.store_rate_return || 0,

                agent_dynamic_rate: order?.agent_dynamic_rate || 0,
                store_delivery_dynamic_rate: order?.store_delivery_dynamic_rate || 0,
            })
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const { handleSubmit, setValues, values, setFieldValue } = useFormik({
        initialValues: {
            id: 0,
            agent_pick: 0,
            store_delivery_pick: 0,

            agent_express: 0,
            store_delivery_express: 0,

            agent_rate: 0,
            store_delivery_rate: 0,

            agent_rate_return: 0,
            store_rate_return: 0,

            agent_dynamic_rate: 0,
            store_delivery_dynamic_rate: 0,
        },
        onSubmit: async values => {
            try {
                setLoading(true)

                await api.put(`/painel/dm-order/${values.id}`, values)
                revalidate()
                closeClick()
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <OrderRatesSideModalContainer isDisplayed={isDisplayed}>
            <OrderRatesSideModalBox isEffectActive={isEffectActive}>
                <PersonalDataHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>
                        {order?.merchant?.name} - #{order?.id}
                    </ContentTitle>
                </PersonalDataHeader>
                <OrderRatesSideModalContent>
                    <InputSection>
                        <InputSectionTitle>Tarifa de Coleta</InputSectionTitle>
                        <InputSectionRow>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('store_delivery_pick', value)}
                                    value={values.store_delivery_pick}
                                    type="currency"
                                    label="Restaurante"
                                />
                            </InputSectionItem>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('agent_pick', value)}
                                    value={values.agent_pick}
                                    type="currency"
                                    label="Entregador"
                                />
                            </InputSectionItem>
                            <VerifyButton onClick={() => setDefaultValue('collect')}>Verificar Tarifa</VerifyButton>
                        </InputSectionRow>
                    </InputSection>

                    <InputSection>
                        <InputSectionTitle>Tarifa Expressa</InputSectionTitle>
                        <InputSectionRow>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('store_delivery_express', value)}
                                    value={values.store_delivery_express}
                                    type="currency"
                                    label="Restaurante"
                                />
                            </InputSectionItem>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('agent_express', value)}
                                    value={values.agent_express}
                                    type="currency"
                                    label="Entregador"
                                />
                            </InputSectionItem>
                            <VerifyButton onClick={() => setDefaultValue('express')}>Verificar Tarifa</VerifyButton>
                        </InputSectionRow>
                    </InputSection>

                    <InputSection>
                        <InputSectionTitle>Tarifa Padrão</InputSectionTitle>
                        <InputSectionRow>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('store_delivery_rate', value)}
                                    value={values.store_delivery_rate}
                                    type="currency"
                                    label="Restaurante"
                                />
                            </InputSectionItem>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('agent_rate', value)}
                                    value={values.agent_rate}
                                    type="currency"
                                    label="Entregador"
                                />
                            </InputSectionItem>
                            <VerifyButton onClick={() => setDefaultValue('base')}>Verificar Tarifa</VerifyButton>
                        </InputSectionRow>
                    </InputSection>

                    <InputSection>
                        <InputSectionTitle>Tarifa de Retorno</InputSectionTitle>
                        <InputSectionRow>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('store_rate_return', value)}
                                    value={values.store_rate_return}
                                    type="currency"
                                    label="Restaurante"
                                />
                            </InputSectionItem>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('agent_rate_return', value)}
                                    value={values.agent_rate_return}
                                    type="currency"
                                    label="Entregador"
                                />
                            </InputSectionItem>
                            <VerifyButton onClick={() => setDefaultValue('return')}>Verificar Tarifa</VerifyButton>
                        </InputSectionRow>
                    </InputSection>

                    <InputSection>
                        <InputSectionTitle>Tarifa Dinâmica</InputSectionTitle>
                        <InputSectionRow>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('store_delivery_dynamic_rate', value)}
                                    value={values.store_delivery_dynamic_rate}
                                    type="currency"
                                    label="Restaurante"
                                />
                            </InputSectionItem>
                            <InputSectionItem>
                                <TextInputForm
                                    onChange={(value: number) => setFieldValue('agent_dynamic_rate', value)}
                                    value={values.agent_dynamic_rate}
                                    type="currency"
                                    label="Entregador"
                                />
                            </InputSectionItem>
                            <VerifyButton onClick={() => setDefaultValue('dynamic')}>Verificar Tarifa</VerifyButton>
                        </InputSectionRow>
                    </InputSection>
                </OrderRatesSideModalContent>
                <ButtonRow>
                    <TextButton onClick={closeClick}>Cancelar</TextButton>
                    <ConfirmButton isActive onClick={() => handleSubmit()}>
                        Salvar Alterações
                    </ConfirmButton>
                </ButtonRow>
            </OrderRatesSideModalBox>
            <OrderRatesSideModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </OrderRatesSideModalContainer>
    )
}

export default OrderRatesSideModal
