const colors = [
    '#1abc9c',
    '#2ecc71',
    '#f1c40f',
    '#16a085',
    '#e67e22',
    '#27ae60',
    '#e74c3c',
    '#f39c12',
    '#3498db',
    '#d35400',
    '#9b59b6',
    '#c0392b',
    '#34495e',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
]

function getColorFromRoute(routeId: number) {
    if (routeId) {
        return colors[routeId % 16]
    }
    return '#FF860A'
}

export { getColorFromRoute }
