import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .content-order {
        margin-bottom: 20px;
    }
    width: 400px;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        width: 100%;
    }
`

const Primary = styled.span`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
`

const ExchangeIcon = styled(FontAwesomeIcon).attrs({ icon: 'exchange-alt' })`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    font-size: 30px;
    align-self: center;
`

export { Container, Primary, ExchangeIcon }
