import { ChartData } from 'chart.js'

import { formatCurrency } from 'helpers/currency'
import { ChartObject } from 'types'

export function getSalesChannelsChart(salesChannels: ChartData<any>): ChartObject {
    return {
        data: salesChannels,
        // callbacks: {
        //     label: tooltipItem => {
        //         const { percentage, Y } = salesChannels[tooltipItem.dataIndex as number]

        //         return `${formatCurrency(Y)} - ${percentage?.toFixed(2)}%`
        //     },
        // },
    }
}
