import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

type ButtonType = 'link' | undefined

export const ButtonContainer = styled.div<{ type: ButtonType }>`
    background-color: ${({ type, theme }) => (type === 'link' ? 'transparent' : theme.colors.primary)};
    font-size: 14px;
    user-select: none;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-radius: 5px;
    width: fit-content;
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
`

export const Icon = styled(FontAwesomeIcon)<{ type: ButtonType; icon: IconProp; isRefreshing: boolean }>`
    color: ${({ type, theme }) => (type === 'link' ? theme.colors.primary : theme.colors.white)};
    font-size: 20px;
    animation-name: ${({ isRefreshing }) => (isRefreshing ? 'spin' : 'none')};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export const ButtonLabel = styled.span<{ type: ButtonType }>`
    margin-left: 10px;
    color: ${({ type, theme }) => (type === 'link' ? theme.colors.primary : theme.colors.white)};
`
