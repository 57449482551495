import { memo, useCallback, useMemo } from 'react'
import { Marker } from 'react-mapbox-gl'

import { MarkerCollect } from 'components/marker-collect/marker-collect'
import { MarkerOrder } from 'components/marker-order/marker-order'

import { getStatusTypeReadyDeliver, getStatusMarker } from '../../../helpers'
import { MarkerSatellite } from '../marker-satellite/marker-satellite'

const OrderMarker = ({ order, configs }) => {
    const origin = useMemo(() => {
        return order?.delivery_origin
    }, [order?.delivery_origin])

    const satellite = useMemo(() => {
        return !order?.delivery_origin && !!order?.merchant?.is_satellite
    }, [order])

    const _getOrderMarker = useCallback(
        (minutes, status) => {
            if (Number(status) === 4) return getStatusMarker(minutes, configs, getStatusTypeReadyDeliver)

            return 'out-of-rote'
        },
        [configs]
    )

    return order?.address?.lat && order.address.lng ? (
        <>
            <Marker style={{ cursor: 'pointer' }} anchor="center" coordinates={[order.address.lng, order.address.lat]}>
                <MarkerOrder
                    key={`order-${getRandomInt()}-${order.id}`}
                    isCollect={!!origin || !!satellite}
                    type={_getOrderMarker(order.total_time.minutes, order.status)}
                />
            </Marker>
            {!!origin?.address?.lat && !!origin?.address?.lng && (
                <Marker
                    key={`collect-${getRandomInt()}-${origin.id}`}
                    style={{ cursor: 'pointer' }}
                    anchor="bottom"
                    coordinates={[origin.address.lng, origin.address.lat]}
                >
                    <MarkerCollect />
                </Marker>
            )}
            {!!satellite?.merchant && (
                <Marker
                    key={`satellite-${getRandomInt()}-${satellite.id}`}
                    style={{ cursor: 'pointer' }}
                    anchor="bottom"
                    coordinates={[satellite.merchant.address.lng, satellite.merchant.address.lat]}
                >
                    <MarkerSatellite />
                </Marker>
            )}
        </>
    ) : null
}

function getRandomInt() {
    let min = Math.ceil(0)
    let max = Math.floor(9999)
    return Math.floor(Math.random() * (max - min)) + min
}

export default memo(OrderMarker)
