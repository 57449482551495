import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { getFormInputError, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { SideDishCategory } from 'types'

import {
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    ButtonRow,
    ConfirmButton,
} from './side-dish-categories-modal.styled'

export type SideDishCategoriesModalRef = {
    show(): void
    close(): void
    setSideDish?(sideDish: SideDishCategory): void
}

type Props = {
    onClose?(refresh?: boolean): void
}

type IForm = {
    id?: number
    name: string
    description?: string
    status?: number
}

const SideDishCategoriesModal = memo(
    forwardRef<SideDishCategoriesModalRef, Props>(({ onClose }, ref) => {
        const { store } = useAuth()

        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [sideDishCategory, setSideDishCategory] = useState<SideDishCategory>()

        const {
            isSubmitting,
            errors,
            getFieldProps,
            handleSubmit,
            resetForm,
            setFieldValue,
            setValues,
            touched,
            values,
        } = useFormik<IForm>({
            initialValues: {
                name: '',
                description: '',
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome da categoria é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    if (values.id) {
                        await api.put(`/painel/side-dish-category/${values.id}`, {
                            ...values,
                            store_id: store.id,
                        })
                    } else {
                        await api.post('/painel/side-dish-category', {
                            ...values,
                            store_id: store.id,
                        })
                    }

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: `Categoria ${isEdit ? 'atualizada' : 'registrada'} com sucesso`,
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Error',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setSubmitting(false)
                }
            },
        })
        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setSideDishCategory(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    resetForm()
                    setSideDishCategory(null)
                    lateralModalBaseRef.current?.close()
                },
                setSideDish: (sideDish: SideDishCategory) => {
                    setSideDishCategory(sideDish)
                    setValues(sideDish)
                },
            }),
            []
        )

        const isEdit = useMemo(() => {
            return !!sideDishCategory
        }, [sideDishCategory])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Editar' : 'Criar'} Categoria de Acompanhamento`
        }, [isEdit])

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }
                resetForm()
                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={{
                                        ...getFieldProps('name'),
                                        placeholder: 'Dê um nome para essa nova categoria de acompanhamento',
                                    }}
                                    errorMessage={getFormInputError('name', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="textarea"
                                    big
                                    labelText="Descrição"
                                    inputProps={{
                                        ...getFieldProps('description'),
                                        placeholder:
                                            '(opcional) Descreve do que se trata essa categoria de acompanhamento',
                                    }}
                                    errorMessage={getFormInputError('description', errors, touched)}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={[
                                        { label: 'Ativo', value: 1 },
                                        { label: 'Inativo', value: 0 },
                                    ]}
                                    inputProps={getFieldProps('status')}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <ConfirmButton title="salvar" onClick={() => handleSubmit()} />
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { SideDishCategoriesModal }
