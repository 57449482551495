import { memo, useContext } from 'react'

import { IntegrationsContext } from 'contexts/integration-context'
import { Order } from 'types'
import { IOrderIntegrationList } from 'types/order-integration-list'

import GroupedProgressCard from '../grouped-progress-card/grouped-progress-card'
import { RomaneioCard } from '../romaneio-card/romaneio-card'

interface Props {
    cardTitle: string
    amount: number | undefined
    emptyText: string
    emptyIcon: string
    onClickOrder(order: Order): void
    dropdownOrderIdActive?: number | undefined
    setDropdownOrderIdActive?(orderId: number | undefined): void
    onRescheduleOrder?(order: Order): void
    onConfirmOrder?(order: Order): void
    onMoveOrder?(order: Order): void
    onCancelOrder?(order: Order): void
    loading?: boolean
    orders?: Order[]
    ordersIntegrations?: IOrderIntegrationList[]
    hasBikerInfo?: boolean
    header?: any
}

const ProgressIntegration: React.FC<Props> = memo(props => {
    const { isAzul } = useContext(IntegrationsContext)

    return <>{isAzul ? <RomaneioCard header={props.header} /> : <GroupedProgressCard {...props} />}</>
})

export { ProgressIntegration }
