import { AnyAction } from 'redux'
import { all, AllEffect, fork, ForkEffect, select, SelectEffect, spawn, take, TakeEffect } from 'redux-saga/effects'

import { User } from 'store/reducers/types'
import { Mall, Store } from 'types'

import { echoConnection } from '../../services/api'

import { updateOrdersTimesPeriodically } from './ordersInProgress'
import { watchPendingOrders } from './pendingOrders'
import { watchRoutesChanges } from './routes'

function* watchApplicationChanges(): Generator<
    | TakeEffect
    | SelectEffect
    | ForkEffect<void>
    | ForkEffect<{
          mall: Mall
          store: Store
      }>,
    void,
    {
        user: User
    }
> {
    while (true) {
        yield take([
            (action: AnyAction) => action.type === 'persist/REHYDRATE' && action.key === 'auth',
            'SET_USER_DATA',
            'REMOVE_USER_DATA',
        ])

        const echo = echoConnection()
        const { user } = yield select(({ auth }) => auth)

        yield fork(updateOrdersTimesPeriodically)
        yield fork(watchPendingOrders)
        yield fork(watchRoutesChanges)

        if (!user?.id) {
            echo.disconnect()
        }
    }
}

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        // takeEvery(RoutesTypes.REMOVE_ROUTE_ORDERS, removeRouteOrders),

        spawn(watchApplicationChanges),
        // takeEvery('*', function logger(action) {
        //     console.log('action', action)
        // })
    ])
}
