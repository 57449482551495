import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
`

const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 500;
`

const SubTitle = styled.span`
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.textLight};
`

const ColorsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1.4rem);
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.625rem;
`

const SelectedIcon = styled(FontAwesomeIcon).attrs({
    icon: 'check',
})`
    width: 0.75rem;
    height: 0.75rem;
    color: ${({ theme }) => theme.colors.white};
`

const ColorItem = styled.div<{ color?: string; isSelectedColor?: boolean }>`
    width: 1.5rem !important;
    height: 1.5rem !important;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: ${({ color, theme }) => color ?? theme.colors.black};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    ${SelectedIcon} {
        transition: all 300ms ease-out;
        opacity: ${({ isSelectedColor }) => (isSelectedColor ? 1 : 0)};

        ${({ color, theme }) =>
            color.includes('#fff') &&
            css`
                filter: invert(100%);
            `}
    }
`

const SelectedColorContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    padding: 0.5rem 0;

    label {
        font-size: 0.625rem;
        color: ${({ theme }) => theme.colors.textLight};
    }

    label:last-child {
        display: flex;
        padding: 0.25rem 0;
        border-bottom: 0.063rem solid;
        border-color: #d9dde8;

        input {
            flex: 1;
            border: none;
            background-color: transparent;
            color: ${({ theme }) => theme.colors.textLight};
            text-transform: uppercase;

            &:focus {
                outline: none;
            }
        }
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
`

export { Container, Label, ColorsContainer, SelectedIcon, ColorItem, SubTitle, SelectedColorContainer, Row }
