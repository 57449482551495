import { featureCollection, point } from '@turf/helpers'
import { GeoJSONSource, Map } from 'mapbox-gl'

import markerImage from 'assets/images/icon-map-marker.png'

import { Coordinates, Image } from '../types'

export function addSymbolPoint(map: Map, coordinates: Coordinates, image?: Image): void {
    const geojson = featureCollection([point([coordinates.lng, coordinates.lat])])

    function updateCoordinates(coords: Coordinates) {
        geojson.features[0].geometry.coordinates = [coords.lng, coords.lat]

        const point = map.getSource('point') as GeoJSONSource
        point.setData(geojson as GeoJSON.FeatureCollection<GeoJSON.Geometry>)
    }

    if (map.hasImage(image?.id || 'icon-map-marker')) {
        updateCoordinates(coordinates)

        map.setCenter(coordinates)
        map.setZoom(15)

        return
    }

    map.loadImage(image?.src || markerImage, function (error, loadedImage: HTMLImageElement | ImageBitmap | undefined) {
        if (error) throw error

        map.addImage(image?.id || 'icon-map-marker', loadedImage as HTMLImageElement)

        map.addSource('point', {
            type: 'geojson',
            data: geojson as GeoJSON.FeatureCollection<GeoJSON.Geometry>,
        })

        map.addLayer({
            id: 'point',
            type: 'symbol',
            source: 'point',
            layout: {
                'icon-image': image?.id || 'icon-map-marker',
                'icon-size': image?.size || 0.75,
            },
        })

        map.setCenter(coordinates)
        map.setZoom(15)
    })
}
