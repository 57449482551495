import MensagemErro from '../mensagem-erro'

const TextArea = props => {
    return (
        <div className="item-input-form">
            <label className="label" style={props.labelStyle}>
                {props.label}
            </label>
            <textarea
                {...props}
                placeholder={props.placeholder}
                onChange={props.onChange}
                disabled={props.disabled}
                rows={props.rows || 2}
                value={props.value}
            />
            <MensagemErro msgColor={props.msgColor} msgErro={props.msgErro} />
        </div>
    )
}

export default TextArea
