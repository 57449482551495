import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import FlexTable from 'components/flex-table'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { useUI } from 'contexts'
import { formatPhone } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { IPicker } from 'types/picker'

import {
    PickerChangePasswordModal,
    PickerChangePasswordModalRef,
} from './components/picker-change-password-modal/picker-change-password-modal'
import {
    PickerManagementModal,
    PickerManagementModalRef,
} from './components/picker-management-modal/picker-management-modal'
import {
    ActionButton,
    ButtonRow,
    ChangePassIcon,
    Container,
    Content,
    DeleteIcon,
    EditIcon,
} from './picker-management.styled'

const PickerManagement: FC<RouteComponentProps> = memo(({ history }) => {
    const { basePath, mall } = useAuth()
    const { setErrorModal, setLoading, setConfirmationModal } = useUI()

    const pickerManagementModalRef = useRef<PickerManagementModalRef>()
    const pickerChangePasswordModalRef = useRef<PickerChangePasswordModalRef>()

    const [pickers, setPickers] = useState<IPicker[]>()

    const _openModal = useCallback(() => {
        pickerManagementModalRef.current?.show()
    }, [])

    const _openEdit = useCallback(
        (picker: IPicker) => () => {
            _openModal()
            pickerManagementModalRef.current?.setPicker(picker)
        },
        []
    )

    const _openEditPassword = useCallback(
        (picker: IPicker) => () => {
            pickerChangePasswordModalRef.current?.show()
            pickerChangePasswordModalRef.current?.setPicker(picker)
        },
        []
    )

    const _getPickers = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get<PagedList<IPicker>>('/painel/pickers', {
                params: {
                    mall_id: mall.id,
                },
            })

            setPickers(data.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível listar os pickers',
            })
        } finally {
            setLoading(false)
        }
    }, [mall])

    const _handleClose = useCallback(
        (refresh: boolean) => {
            if (refresh) {
                _getPickers()
            }
        },
        [_getPickers]
    )

    const _handleDelete = useCallback(
        (id: number) => {
            const deletePicker = async () => {
                pickerManagementModalRef.current?.close()
                setLoading(true)

                try {
                    await api.delete(`/painel/pickers/${id}`)

                    _getPickers()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro!',
                        subtitle: 'Não foi possível excluir a horário de funcionamento!',
                    })
                }
                setLoading(false)
            }

            return (): void => {
                setConfirmationModal({
                    title: 'Excluir Picker!',
                    subtitle: 'Tem certeza de que deseja excluir este picker?',
                    type: 'alert',
                    modalIcon: 'trash-alt',
                    leftButtonText: 'Cancelar',
                    rightButtonText: 'Sim, excluir!',
                    rightButtonClick: deletePicker,
                })
            }
        },
        [_getPickers]
    )

    useEffect(() => {
        _getPickers()
    }, [_getPickers])

    return (
        <Container>
            <PickerManagementModal ref={pickerManagementModalRef} onClose={_handleClose} />
            <PickerChangePasswordModal ref={pickerChangePasswordModalRef} onClose={_handleClose} />
            <TopRowDefault
                title="Gerenciamento de Pickers"
                onBackButtonClick={() => history.replace(`${basePath}/gestao-mall`)}
                buttons={[
                    {
                        title: 'Criar Picker',
                        onClick: _openModal,
                    },
                ]}
            />

            <Content>
                <FlexTable
                    isBordered
                    columns={[
                        { name: 'ID', width: 'fit-content' },
                        { name: 'Nome', width: '10%' },
                        { name: 'Email', width: '10%' },
                        { name: 'Telefone', width: '10%' },
                        { name: '', width: '10%' },
                    ]}
                    list={pickers?.map(picker => {
                        const { id, full_name, email, cellphone } = picker

                        return {
                            id: id.toString(),
                            full_name,
                            email,
                            cellphone: formatPhone(cellphone),
                            buttonRow: (
                                <ButtonRow>
                                    <ActionButton widthFitContainer onClick={_openEdit(picker)}>
                                        <EditIcon />
                                    </ActionButton>

                                    <ActionButton widthFitContainer onClick={_openEditPassword(picker)}>
                                        <ChangePassIcon />
                                    </ActionButton>

                                    <ActionButton widthFitContainer variant="danger" onClick={_handleDelete(id)}>
                                        <DeleteIcon />
                                    </ActionButton>
                                </ButtonRow>
                            ),
                        }
                    })}
                />
            </Content>
        </Container>
    )
})

export { PickerManagement }
