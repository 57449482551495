import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ImportOrdersModal, ImportOrdersModalRef } from 'modals/import-orders-modal/import-orders-modal'

import { DefaultButton } from 'components/default-button/default-button'
import { ImportHeader } from 'components/import-header/import-header'
import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'
import { TableFile } from 'components/tables'

import { setSearchParams } from 'helpers'
import api from 'services/api'
import { PagedList } from 'types'
import { IImportFile } from 'types/import-file'

import { Container, Header, Content } from './import-page.styled'
export interface ImportFilters {
    store_id?: number
    start_date?: string
    end_date?: string
    current_page?: number
    status?: 'draft' | 'run' | 'completed'
}

const ImportPage = () => {
    const history = useHistory()

    const importOrdersModalRef = useRef<ImportOrdersModalRef>(null)
    const importsRef = useRef<IImportFile[]>([])

    const [page, setPage] = useState<PagedList<any>>()
    const [imports, setImports] = useState<IImportFile[]>([])
    const [filter, setFilter] = useState<ImportFilters>()
    const [loading, setLoading] = useState(false)

    const _getImports = useCallback(
        async (filter: ImportFilters) => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<IImportFile>>('painel/imports', {
                    params: {
                        ...filter,
                        status: filter?.status ? [filter.status] : undefined,
                        per_page: 100,
                        order: 'desc',
                    },
                })
                const { items, ...rest } = data
                setImports(items)
                setPage({ ...rest, items: [] })
                setSearchParams({ ...filter, current_page: rest.current_page }, location, history)
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        },
        [history]
    )

    const _onChange = useCallback((importFile: IImportFile, type: 'add' | 'update' | 'delete') => {
        const imports = importsRef.current || []

        if (type === 'add') {
            setImports([importFile, ...imports])
        }
        if (type === 'update') {
            setImports(imports.map(item => (item.id === importFile.id ? importFile : item)))
        }
        if (type === 'delete') {
            setImports(imports.filter(item => item.id !== importFile.id))
        }
    }, [])

    const _import = useCallback(() => {
        importOrdersModalRef.current?.show({ onChange: _onChange })
    }, [_onChange])

    const _openImport = useCallback(
        (data: IImportFile) => {
            importOrdersModalRef.current?.show({ importFile: data, onChange: _onChange })
        },
        [_onChange]
    )

    const _setFilter = useCallback((filter: ImportFilters) => {
        setFilter(filter)
    }, [])

    const _loadMore = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IImportFile>>('painel/imports', {
                params: {
                    ...filter,
                    status: filter?.status ? [filter.status] : undefined,
                    per_page: 1,
                    current_page: (page?.current_page || 0) + 1,
                },
            })
            const { items, ...rest } = data
            setImports([...imports, ...items])
            setPage({ ...rest, items: [] })
            setSearchParams({ ...filter, current_page: rest.current_page }, location, history)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [filter, history, imports, page])

    useEffect(() => {
        if (filter) {
            _getImports(filter)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        importsRef.current = imports
    }, [imports])

    return (
        <>
            <Container>
                <Header>
                    <ImportHeader title="Importação de Pedidos" onImportClick={_import} onFilterData={_setFilter} />
                </Header>
                <Content>
                    <Scroll>
                        <TableFile onMenuClick={_openImport} imports={imports} />
                        {page?.totals > imports.length && (
                            <DefaultButton outline title="Carregar mais" widthFitContainer onClick={_loadMore} />
                        )}
                    </Scroll>
                </Content>
            </Container>
            <ImportOrdersModal ref={importOrdersModalRef} />
            <ModalLoading visible={loading} />
        </>
    )
}

export { ImportPage }
