import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback, useMemo } from 'react'
import { Marker, Popup } from 'react-mapbox-gl'

import { getBounds } from 'geolib'

import OrderSummary from 'components/_store-general-vision/order-summary'
import MapboxView, { AgentPopup, DraggableMarker } from 'components/mapbox-view'
import Loader from 'components/modal-loading'
import SelectInput from 'components/select-input'

import { AgentMarker } from 'containers/agent-marker/agent-marker'

import { useUI } from 'contexts'
import { formatCEP, showErrors } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'
import api from 'services/api'
import { Order, Biker } from 'types'
import { ordersStatusList } from 'utils'

import {
    OrderId,
    StoreName,
    ClientName,
    Address,
    Header,
    ContainerPopupInfo,
    Container,
    BoxContainer,
    MapBox,
    Footer,
    FooterItem,
    Title,
    CloseIcon,
} from './modal-edit-order.styled'

type ModalEditRoute = {
    show?(order_id: number): void
    close?(): void
}

// eslint-disable-next-line react/display-name
const ModalEditOrder = forwardRef<ModalEditRoute, React.FC>((props, ref) => {
    const { mall } = useAuth()

    const { setConfirmationModal, setErrorModal, setSuccessModal } = useUI()

    const [submiting, setSubmiting] = useState(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [orderId, setOrderId] = useState(null)
    const [order, setOrder] = useState<Order>(null)
    const [status, setStatus] = useState<string>(null)
    const [locationType, setLocationType] = useState<string>('original')
    const [currentOrderLocation, setCurrentOrderLocation] = useState(null)
    const [orderLocation, setOrderLocation] = useState<{ lat: number; lng: number } | null>(null)
    const [agentPopupVisible, setAgentPopupVisible] = useState(null)
    const [agentInRouteInfo, setAgentInRouteInfo] = useState({} as Biker)

    useImperativeHandle(
        ref,
        () => ({
            show: order_id => {
                setOrderId(order_id)
                setVisible(true)
            },
            close: () => {
                setVisible(false)
            },
        }),
        []
    )

    const { loading } = useDataFetching(orderId ? `/painel/dm-order/${orderId}` : null, {
        onSuccess: (order: Order) => {
            const { biker } = order
            setOrder(order)
            if (!order.end_delivery) setAgentInRouteInfo(biker)

            setOrderLocation({ lat: order.address.lat, lng: order.address.lng })
            setCurrentOrderLocation({ lat: order.address.lat, lng: order.address.lng })

            setStatus(order.status)
        },
    })

    const fitBounds = useMemo(() => {
        if (
            agentInRouteInfo?.location?.lat &&
            agentInRouteInfo?.location?.lng &&
            orderLocation?.lat &&
            orderLocation?.lng
        ) {
            const { minLat, minLng, maxLat, maxLng } = getBounds([
                { latitude: mall.address.lat, longitude: mall.address.lng },
                { latitude: agentInRouteInfo?.location?.lat, longitude: agentInRouteInfo?.location?.lng },
                { latitude: orderLocation.lat, longitude: orderLocation.lng },
            ])

            return [
                [minLng, minLat],
                [maxLng, maxLat],
            ]
        }

        return orderLocation?.lat && orderLocation?.lng
            ? [
                  [mall.address.lng, mall.address.lat],
                  [orderLocation.lng, orderLocation.lat],
              ]
            : null
    }, [mall, agentInRouteInfo, orderLocation])

    const isValid = useMemo(() => {
        if (status || orderId || orderLocation?.lat || orderLocation?.lng) {
            return true
        }
        return false
    }, [orderId, orderLocation, status])

    const formattedAddress = useMemo(() => {
        const address = order?.address

        if (!address) return

        const { street, number } = address

        return `${street}, ${number}`
    }, [order])

    const _close = useCallback(() => {
        setVisible(false)
        setOrderId(null)
        setStatus(null)
        setOrder(null)
        setLocationType('0')
        setOrderLocation(null)
        setCurrentOrderLocation(null)
        setAgentPopupVisible(null)
        setAgentInRouteInfo({} as Biker)
    }, [orderId])

    const _update = useCallback(async () => {
        const _submit = async () => {
            setSubmiting(true)
            try {
                await api.put(`painel/dm-order/${orderId}`, {
                    ...orderLocation,
                    status,
                })
                setSuccessModal({
                    title: 'Pedido atualizado com sucesso!',
                    subtitle: 'As informações de localização e status foram atualizadas.',
                    singleButtonClick: _close,
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao atualizar',
                    subtitle: showErrors(error),
                })
            }
            setSubmiting(false)
        }

        setConfirmationModal({
            title: 'Corrigir Pedido',
            subtitle: 'Tem certeza de que deseja atualizar as informações deste pedido?',
            type: 'alert',
            modalIcon: 'check-circle',
            leftButtonText: 'Fechar',
            rightButtonText: 'Confirmar',
            rightButtonClick: _submit,
            children: <OrderSummary order={order} />,
        })
    }, [orderId, orderLocation, status, order, setErrorModal, setConfirmationModal, setSuccessModal, _close])

    useEffect(() => {
        if (visible) {
            freeze()
        } else {
            unfreeze()

            setTimeout(() => {
                _close()
            }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    useEffect(() => {
        if (locationType === 'original' && currentOrderLocation) {
            setOrderLocation(currentOrderLocation)
            return
        }
        if (locationType === 'agent' && agentInRouteInfo) {
            setOrderLocation({ lng: agentInRouteInfo?.location?.lng || 0, lat: agentInRouteInfo?.location?.lat || 0 })
            return
        }
    }, [locationType, currentOrderLocation, agentInRouteInfo])

    const { agentLocation } = useAgentsFetch({ orderId: order?.id })

    useEffect(() => {
        if (order?.biker && agentLocation) {
            setAgentInRouteInfo({ ...order.biker, ...agentLocation })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentLocation])

    if (!visible) {
        return null
    }

    return (
        <Container>
            <BoxContainer>
                <Header>
                    <Title>Corrigir pedido</Title>
                    <CloseIcon onClick={_close} />
                </Header>
                <MapBox>
                    <MapboxView fitBounds={fitBounds}>
                        <DraggableMarker
                            coordinates={
                                orderLocation?.lat && orderLocation.lng && [orderLocation.lng, orderLocation.lat]
                            }
                            onDragEnd={({ lngLat: { lat, lng } }: { lngLat: { lat: number; lng: number } }) => {
                                setOrderLocation({ lat, lng })
                                setLocationType('map')
                            }}
                        />

                        {agentInRouteInfo?.id && (
                            <Marker
                                style={{ cursor: 'pointer' }}
                                anchor="bottom"
                                coordinates={[
                                    agentInRouteInfo?.location?.lng || 0,
                                    agentInRouteInfo?.location?.lat || 0,
                                ]}
                                onClick={() => {
                                    setAgentPopupVisible(!agentPopupVisible)
                                }}
                            >
                                <AgentMarker alt={`agent-${agentInRouteInfo?.id}`} src={agentInRouteInfo.avatar} />
                            </Marker>
                        )}
                        {agentPopupVisible && (
                            <Popup
                                coordinates={[
                                    agentInRouteInfo?.location?.lng || 0,
                                    agentInRouteInfo?.location?.lat || 0,
                                ]}
                                offset={[0, -38]}
                            >
                                <AgentPopup agent={agentInRouteInfo} onClosePopup={() => setAgentPopupVisible(null)} />
                            </Popup>
                        )}
                    </MapboxView>
                    {order && (
                        <ContainerPopupInfo>
                            <OrderId className="info">#{order.id}</OrderId>
                            <StoreName className="info">{order.merchant.name}</StoreName>
                            <ClientName className="info">{order.customer.name}</ClientName>
                            <Address className="info">{formattedAddress}</Address>
                            <Address className="info">{order.address.complement}</Address>
                            <Address className="info">
                                {order.address.neighborhood} - {formatCEP(order.address.zipcode)}
                            </Address>
                        </ContainerPopupInfo>
                    )}
                    <Footer>
                        <FooterItem>
                            <SelectInput
                                bgColor="transparent"
                                noPlaceholder
                                data={[
                                    { name: 'Usar localização origial', value: 'original' },
                                    { name: 'Usar localização do mapa', value: 'map' },
                                    { name: 'Usar localização do entregador', value: 'agent' },
                                ]}
                                value={locationType}
                                onChange={({ target: { value } }) => {
                                    setLocationType(value)
                                }}
                            />
                        </FooterItem>
                        <FooterItem>
                            <SelectInput
                                bgColor="transparent"
                                noPlaceholder
                                data={ordersStatusList.map(item => ({ name: item.name, value: item.id }))}
                                value={status}
                                onChange={({ target: { value } }) => setStatus(value)}
                            />
                        </FooterItem>
                        <FooterItem>
                            <button
                                className="Modal-button primary"
                                type="submit"
                                disabled={!isValid}
                                onClick={_update}
                            >
                                Salvar
                            </button>
                        </FooterItem>
                    </Footer>
                </MapBox>

                <Loader visible={loading || submiting} />
            </BoxContainer>
        </Container>
    )
})
type AgentLocation = {
    id: number
    location: {
        lat: number
        lng: number
    }
}
interface useAgentsFetch {
    orderId: number
}
function useAgentsFetch({ orderId }: useAgentsFetch) {
    const [agentLocation, setAgentLocation] = useState<AgentLocation>()
    const socketEvents = useMemo(() => {
        return [
            {
                name: '.location',
                callback: (agent: any) => {
                    setAgentLocation(agent)
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEchoConnection<any, any>({
        enable: !!orderId,
        channelName: orderId ? `order.${orderId}` : null,
        events: socketEvents,
    })

    return { agentLocation }
}

function freeze() {
    const top = window.scrollY

    document.body.style.overflow = 'hidden'

    window.onscroll = function () {
        window.scroll(0, top)
    }
}

function unfreeze() {
    document.body.style.overflow = ''
    window.onscroll = null
}

export default ModalEditOrder
