import styled from 'styled-components'

export const HistoryTabContainer = styled.div``

export const CardContainer = styled.div`
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 100%;
    }
`

export const CardTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
`

export const CardSubtitle = styled.div`
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
`

export const TimesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
`

export const TimeItem = styled.div`
    background-color: #f1f1f1;
    padding: 10px 0px;
    border-radius: 4px;
    flex-basis: 32%;
`

export const TimeTitle = styled.div`
    font-size: 10px;
    margin-bottom: 5px;
    text-align: center;
`

export const TimeContent = styled.div`
    font-size: 12px;
    text-align: center;
    font-weight: bold;
`

export const StatusContainer = styled.div``

export const CurrentStatus = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
`

export const StatusItem = styled.div`
    font-size: 12px;
    margin-bottom: 10px;
    span {
        font-weight: 600;
    }
`
