import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'

import ButtonLoadMore from '../../components/button-load-more/button-load-more'
import EmptyMessage from '../../components/empty-message/empty-message'
import ModalLoading from '../../components/modal-loading'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import api from '../../services/api'
import { IPayment } from '../../types/payment'

import { PaymentsListingTable, PaymentsModal } from './components'
import { IPaymentMethodsModalRef } from './components/modal-create-payment/modal-payments'
import { Container, FilterContent } from './payments-listing.styled'

interface Props extends RouteComponentProps {}

type IPayments = {
    items: IPayment[]
    totals: number
    current_page: number
}

const statusOptions = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 },
]

const PaymentsListing: React.FC<Props> = memo(({ history }) => {
    const { setErrorModal, setConfirmationModal } = useUI()

    const paymentModalRef = useRef<IPaymentMethodsModalRef>()
    const modalMessage = useRef(null)

    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState<IPayments>()
    const [status, setStatus] = useState<number>(1)

    const renderEmptyMessage = useMemo(() => {
        return <EmptyMessage icon="dollar-sign">Não foram encontradas formas de pagamento cadastradas.</EmptyMessage>
    }, [])

    const _getPayments = useCallback(
        async ({ current_page = 1, payments = { items: [] } } = {}) => {
            try {
                setLoading(true)

                const { data } = await api.get('/painel/payments', {
                    params: { current_page, per_page: 50, status: [status] },
                })

                setPayments({
                    ...data,
                    items: current_page > 1 ? [...payments.items, ...data.items] : data.items,
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível carregar os dados.',
                    singleButtonClick: () => history.push('/painel-malls'),
                })

                modalMessage.current.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history, status]
    )

    const _openModal = useCallback(() => {
        paymentModalRef.current?.show()
    }, [])

    const _onClose = useCallback((isRefresh?: boolean) => {
        if (isRefresh) {
            _getPayments()
        }
    }, [])

    const _handleChangeStatus = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setStatus(Number(value))
    }, [])

    const _handleEdit = useCallback(
        (payment: IPayment) => () => {
            _openModal()
            paymentModalRef.current?.setPaymentMethod(payment)
        },
        []
    )

    const _handleDelete = useCallback((id: number) => {
        return (): void => {
            const paymentsDelete = async () => {
                paymentModalRef.current?.close()

                setLoading(true)

                try {
                    await api.delete(`/painel/payment/${id}`)

                    _getPayments()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro!',
                        subtitle: 'Não foi possível excluir forma de pagamento!',
                    })
                }
                setLoading(false)
            }

            setConfirmationModal({
                title: 'Excluir Formas de Pagamento.',
                subtitle: 'Deseja mesmo excluir esta forma de pagamento?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: paymentsDelete,
            })
        }
    }, [])

    useEffect(() => {
        _getPayments()
    }, [_getPayments])

    return (
        <Container>
            <TopRowDefault
                title="Formas de Pagamento"
                buttons={[
                    {
                        title: 'Criar Forma de Pagamento',
                        onClick: _openModal,
                    },
                ]}
            />

            <ModalLoading visible={loading} />
            <PaymentsModal ref={paymentModalRef} onClose={_onClose} onRemove={_handleDelete} />

            <FilterContent>
                <InputItem
                    labelText="Status"
                    type="select"
                    options={statusOptions}
                    inputProps={{
                        value: status,
                        onChange: _handleChangeStatus,
                    }}
                />
            </FilterContent>

            <PaymentsListingTable
                paymentMethods={payments?.items}
                handleDelete={_handleDelete}
                handleEdit={_handleEdit}
            />

            {!payments?.items?.length && !loading && renderEmptyMessage}

            <ButtonLoadMore
                loading={loading}
                visible={payments?.totals > 0 && payments?.items.length < payments?.totals}
                onClick={() => _getPayments({ current_page: ++payments.current_page, payments })}
            />
        </Container>
    )
})

export { PaymentsListing }
