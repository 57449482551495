import { useCallback, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'

import { ErrorDialog } from 'containers/login-page/login-page.styles'

import { useUI } from 'contexts'
import { getFormInputError, setFormFieldErrors, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Mall } from 'types'

import {
    ContentTitle,
    ConfigAutoRouteModalBackground,
    ConfigAutoRouteModalBox,
    ConfigAutoRouteModalContainer,
    ConfigAutoRouteModalContent,
    CloseButton,
    CloseContainer,
    ConfigAutoRouteHeader,
    ButtonRow,
    TextButton,
    ConfirmButton,
    SpacingDiv,
} from './config-auto-route-modal.styles'

const selectOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
]

interface Props {
    isActive: boolean
    closeClick(): void
}

const ConfigAutoRouteModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const { mall, setMall } = useAuth()
    const { setLoading, setSnackbar } = useUI()
    const [errorData, setErrorData] = useState('')

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setErrorData('')
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const { values, touched, errors, handleSubmit, setValues, setFieldValue } = useFormik({
        initialValues: {
            status: false,
            with_express: false,
            return_to_base: false,
            min_time_route: 6,
            max_orders_per_route: 1,
            max_distance_between_orders: 2.5,
        },
        validationSchema: Yup.object().shape({
            status: Yup.boolean().required('Obrigatório.'),
            with_express: Yup.boolean().required('Obrigatório.'),
            return_to_base: Yup.boolean().required('Obrigatório.'),
            min_time_route: Yup.number().required('Preencha o máximo tempo de coleta'),
            max_orders_per_route: Yup.number()
                .nullable()
                .min(1, 'Mínimo é 1')
                .max(8, 'Máximo é 8')
                .required('Obrigatório.'),
            max_distance_between_orders: Yup.number().nullable().min(1, 'Mínimo é 1').required('Obrigatório.'),
        }),

        onSubmit: async (values, { setFieldError }) => {
            try {
                setLoading(true)
                const { data } = await api.put(`/painel/mall/${mall.id}`, {
                    configs: {
                        route_automatic: values,
                    },
                })
                setMall(data)
                setSnackbar({ message: 'Alterações salvas com sucesso' })
                closeClick()
            } catch (error) {
                setFormFieldErrors(error, setFieldError)
                setErrorData(showErrors(error))
            } finally {
                setLoading(false)
            }
        },
    })

    const _setValue = useCallback(
        (field: 'min_time_route' | 'max_orders_per_route' | 'max_distance_between_orders') => {
            return (e: any) => {
                setFieldValue(field, Number(e.target.value))
            }
        },
        [setFieldValue]
    )

    const _getMall = useCallback(
        async (id: number) => {
            try {
                const { data } = await api.get<Mall>(`/painel/mall/${id}`)
                setValues({
                    status: data?.configs?.route_automatic?.status || false,
                    with_express: data?.configs?.route_automatic?.with_express || false,
                    return_to_base: data?.configs?.route_automatic?.return_to_base || false,
                    min_time_route: data?.configs?.route_automatic?.min_time_route || 6,
                    max_orders_per_route: data?.configs?.route_automatic?.max_orders_per_route || 1,
                    max_distance_between_orders: data?.configs?.route_automatic?.max_distance_between_orders || 2.5,
                })
            } catch (error) {
                console.log('error', error)
            }
        },
        [setValues]
    )

    useEffect(() => {
        if (mall?.id) {
            _getMall(mall.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mall?.id])

    return (
        <ConfigAutoRouteModalContainer isDisplayed={isDisplayed}>
            <ConfigAutoRouteModalBox isEffectActive={isEffectActive}>
                <ConfigAutoRouteHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Roteirização Automática</ContentTitle>
                </ConfigAutoRouteHeader>
                <ConfigAutoRouteModalContent>
                    {errorData.length > 0 && <ErrorDialog>{errorData}</ErrorDialog>}
                    <SwitchButton
                        isFlex
                        isActive={values.status}
                        onClick={() => setFieldValue('status', !values.status)}
                        activeLabel="Roteirização automática"
                        inactiveLabel="Roteirização automática"
                    />
                    <SpacingDiv>
                        <SwitchButton
                            isFlex
                            isActive={values.with_express}
                            onClick={() => setFieldValue('with_express', !values.with_express)}
                            activeLabel="Aplicar tarifa expressa"
                            inactiveLabel="Aplicar tarifa expressa"
                        />
                    </SpacingDiv>
                    <SpacingDiv>
                        <SwitchButton
                            isFlex
                            isActive={values.return_to_base}
                            onClick={() => setFieldValue('return_to_base', !values.return_to_base)}
                            activeLabel="Calcular rotas com retorno pro mall"
                            inactiveLabel="Calcular rotas com retorno pro mall"
                        />
                    </SpacingDiv>
                    <SpacingDiv>
                        <InputItem
                            labelText="Número máximo de pedidos por rota"
                            type="select"
                            options={selectOptions}
                            errorMessage={getFormInputError('max_orders_per_route', errors, touched)}
                            inputProps={{
                                value: values.max_orders_per_route?.toString(),
                                onChange: _setValue('max_orders_per_route'),
                            }}
                        />
                    </SpacingDiv>
                    <SpacingDiv>
                        <InputItem
                            labelText="Distância máxima entre pedidos (KM)"
                            type="number"
                            errorMessage={getFormInputError('max_distance_between_orders', errors, touched)}
                            inputProps={{
                                value: values.max_distance_between_orders?.toString(),
                                onChange: _setValue('max_distance_between_orders'),
                            }}
                        />
                    </SpacingDiv>
                    <SpacingDiv>
                        <InputItem
                            labelText="Tempo de Coleta (minutos)"
                            type="number"
                            errorMessage={getFormInputError('min_time_route', errors, touched)}
                            inputProps={{
                                value: values.min_time_route?.toString(),
                                onChange: _setValue('min_time_route'),
                            }}
                        />
                    </SpacingDiv>
                </ConfigAutoRouteModalContent>
                <ButtonRow>
                    <TextButton onClick={closeClick}>Cancelar</TextButton>
                    <ConfirmButton isActive onClick={() => handleSubmit()}>
                        Salvar Alterações
                    </ConfirmButton>
                </ButtonRow>
            </ConfigAutoRouteModalBox>
            <ConfigAutoRouteModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </ConfigAutoRouteModalContainer>
    )
}

export default ConfigAutoRouteModal
