import { useEffect, useState } from 'react'

import SelectInput from '../select-input'
import TextInput from '../text-input'
import TextInputMask from '../text-input-mask'
import './style.scss'

const STATES = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AM', value: 'AM' },
    { label: 'AP', value: 'AP' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MG', value: 'MG' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'PR', value: 'PR' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'RS', value: 'RS' },
    { label: 'SC', value: 'SC' },
    { label: 'SE', value: 'SE' },
    { label: 'SP', value: 'SP' },
    { label: 'TO', value: 'TO' },
    { label: 'Nenhum', value: 'nenhum' },
]

const DelivererFilter = ({ onChange, filter, onFilterClick }) => {
    const [states, setStates] = useState([])

    useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
            .then(res => res.json())
            .then(data => {
                setStates(
                    data.map(item => ({
                        label: `${item.nome} - ${item.sigla}`,
                        value: item.sigla,
                    }))
                )
            })
    }, [])

    return (
        <div className="deliverer-filter-container">
            <div className="filter-item">
                <div className="filter-label type">Tipo:</div>
                <SelectInput
                    onChange={e => {
                        let value = e.target.value
                        onChange('type', value === 'Selecionar' ? '' : value)
                    }}
                    placeholder={'Todos os veículos'}
                    value={filter.type}
                    bgColor="transparent"
                    data={[
                        { label: 'Bicicleta', value: '2' },
                        { label: 'Caminhão', value: '5' },
                        { label: 'Carro', value: '3' },
                        { label: 'Motocicleta', value: '1' },
                        { label: 'Van', value: '4' },
                    ]}
                />
            </div>

            <div className="filter-row">
                <div className="filter-item name">
                    <div className="filter-label">Nome:</div>
                    <TextInput
                        onChange={e => onChange('search', e.target.value)}
                        value={filter.search}
                        bgColor="transparent"
                    />
                </div>
                <div className="filter-item phone">
                    <div className="filter-label">Estado:</div>
                    <SelectInput
                        onChange={e => {
                            let value = e.target.value
                            onChange('state', ['Selecionar', 'nenhum'].includes(value) ? '' : value)
                        }}
                        value={filter.state}
                        bgColor="transparent"
                        data={STATES}
                    />
                </div>
                <div className="filter-item cpf">
                    <div className="filter-label">CPF:</div>
                    <TextInputMask
                        mask="999.999.999-99"
                        onChange={e => onChange('cpf', e.target.value)}
                        value={filter.cpf}
                    />
                </div>
                <div className="filter-item cnpj">
                    <div className="filter-label">CPNJ:</div>
                    <TextInputMask
                        mask="99.999.999/9999-99"
                        onChange={e => onChange('cnpj', e.target.value)}
                        value={filter.cnpj}
                    />
                </div>
                <div className="filter-item phone">
                    <div className="filter-label">Telefone:</div>
                    <TextInputMask
                        mask="(99) 9 9999-9999"
                        onChange={e => onChange('cellphone', e.target.value)}
                        value={filter.cellphone}
                    />
                </div>

                <div className="filter-item uf">
                    <div className="filter-label">UF:</div>
                    <SelectInput
                        placeholder="Todos os Estados"
                        onChange={({ target: { value } }) => {
                            onChange('state', value === 'Todos os Estados' ? '' : value)
                        }}
                        value={filter.state}
                        bgColor="transparent"
                        data={states}
                    />
                </div>

                <div className="submit-filter" onClick={onFilterClick}>
                    Filtrar
                </div>
            </div>
        </div>
    )
}

export default DelivererFilter
