import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

const ManageDeliverersCard = props => {
    return (
        <div className="manage-deliverers-card-item">
            <div className="top-row">
                <div className="icon">
                    <FontAwesomeIcon icon={props.icon} />
                </div>
                <div className="number">{props.number}</div>
            </div>
            <div className="title">{props.title}</div>
            <Link to={props.linkRoute} className="button">
                {props.linkText}
            </Link>
        </div>
    )
}

export default ManageDeliverersCard
