import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { InputItem } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { IFloor, IZone, IZoneTypes } from 'types/floor-map'

import { CreateFloorModal, CreateFloorModalRef } from './components'
import { FloorMapProvider } from './context/floor-map-context'
import {
    Actions,
    AttendanceLeftContent,
    AttendanceRightContent,
    AttendanceRow,
    ButtonRow,
    ColorBox,
    Container,
    Content,
    DeleteButton,
    EditButton,
    EmptyMessage,
    EmptyMessageText,
    IconBackGround,
    IconDelete,
    IconEdit,
    InputContainer,
    ListItemContent,
    TextItem,
    ZoneEmptyMessage,
    ZoneListContent,
} from './inperson-attendance-area.styled'
import { FloorMap } from './widget/floor-map/floor-map'
import { ZoneForm } from './widget/zone-form/zone-form'

interface Props extends RouteComponentProps {}

const InPersonAttendanceArea: React.FC<Props> = memo(({ history }) => {
    const { basePath, mall } = useAuth()
    const { setErrorModal, setLoading, setConfirmationModal, setSuccessModal } = useUI()

    const [selectedZone, setSelectedZone] = useState<IZone>()

    const createFloorModalRef = useRef<CreateFloorModalRef>()

    const [floors, setFloors] = useState<IFloor[]>()

    // valor temporário
    const [zones, setZones] = useState<IZone[]>()

    // valor temporário
    const [selectedFloor, setSelectedFloor] = useState<IFloor>()
    const [selectedZoneType, setSelectedZoneType] = useState<IZoneTypes>('indoor')

    const [showForm, setShowForm] = useState<boolean>()

    const selectedImage = useMemo((): string => {
        return selectedFloor?.image
    }, [selectedFloor])

    const floorsOptions = useMemo(() => {
        return floors?.map(item => ({ label: item.label, value: item.id }))
    }, [floors])

    const hasZonesInFloor = useMemo(() => zones?.length !== 0, [zones])

    const zoneTypes = useMemo(
        (): { label: string; value: IZoneTypes }[] => [
            {
                label: 'Atendimento em mesa',
                value: 'indoor',
            },
            {
                label: 'Retirada em balcão',
                value: 'takeout',
            },
        ],
        []
    )

    const _getFloorDetail = useCallback(async (id: number) => {
        setLoading(true)
        try {
            const { data } = await api.get<IFloor>(`painel/floors/${id}`)
            setSelectedFloor(data)
            setLoading(false)
            return data
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar os dados.',
            })
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getZones = useCallback(async (id: number, type?: IZoneTypes, isSelectedFloor = false) => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IZone>>(`painel/floor-maps/`, {
                params: { floor: id, order: 'asc', per_page: 999, type },
            })
            if (!isSelectedFloor) {
                setZones(data.items)
            }
            return data.items
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar as zonas do piso selecionado.',
            })
        } finally {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getDetails = useCallback(
        async (id: number) => {
            const [floor, zones] = await Promise.allSettled([
                _getFloorDetail(id),
                _getZones(id, selectedZoneType, true),
            ])
            setSelectedFloor((floor as PromiseFulfilledResult<IFloor>).value)
            setZones((zones as PromiseFulfilledResult<IZone[]>).value)
        },
        [selectedZoneType, _getFloorDetail, _getZones]
    )

    const _getFloors = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IFloor>>('painel/floors', { params: { mall: mall.id } })

            if (data.items.length > 0) {
                setFloors(data.items)
                const firstFloor = data.items[0]
                setSelectedFloor(firstFloor)
                _getDetails(firstFloor.id)
            }
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar os dados.',
            })
        }
        setLoading(false)
    }, [mall.id])

    const _openModal = useCallback(() => {
        createFloorModalRef.current?.show()
    }, [])

    const _openEdit = useCallback(() => {
        _openModal()
        createFloorModalRef.current.setFloor(selectedFloor)
    }, [selectedFloor, _openModal])

    const _handleClose = useCallback((refresh: boolean) => {
        if (refresh) {
            _getFloors()
            setSelectedFloor(null)
            setZones(null)
        }
    }, [])

    const _showForm = useCallback(
        (zone?: IZone) => () => {
            setShowForm(state => !state)

            if (zone) {
                setSelectedZone(zone)
            } else {
                setSelectedZone(null)
            }
        },
        []
    )

    const _handleDelete = useCallback(
        (id: number) => {
            return (): void => {
                const deleteClick = async () => {
                    setLoading(true)

                    const data = await _getZones(selectedFloor.id)
                    setZones(data)

                    setLoading(false)
                }

                const deleteZone = async () => {
                    setLoading(true)

                    try {
                        await api.delete(`/painel/floor-maps/${id}`)

                        setSuccessModal({
                            title: 'Sucesso!',
                            subtitle: 'Zona deletada com sucesso',
                            singleButtonClick: deleteClick,
                        })
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro!',
                            subtitle: 'Não foi possível excluir a zona de atendimento!',
                        })
                    }
                    setLoading(false)
                }

                setConfirmationModal({
                    title: 'Excluir Zona de Atendimento!',
                    subtitle: 'Tem certeza de que deseja excluir esta zona de atendimento?',
                    type: 'alert',
                    modalIcon: 'trash-alt',
                    leftButtonText: 'Cancelar',
                    rightButtonText: 'Sim, excluir!',
                    rightButtonClick: deleteZone,
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedFloor]
    )

    const _handleDeleteFloor = useCallback((id: number) => {
        const deleteFloor = async () => {
            createFloorModalRef.current?.close()
            setLoading(true)

            try {
                await api.delete(`/painel/floors/${id}`)
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir o piso!',
                })
            }
            setLoading(false)
        }

        return (): void => {
            setConfirmationModal({
                title: 'Excluir Piso!',
                subtitle: 'Tem certeza que deseja excluir este piso? toda a informação sera perdida',
                type: 'delete',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: deleteFloor,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleCloseZonesForms = useCallback(async () => {
        _showForm()

        setLoading(true)
        const zones = await _getZones(selectedFloor.id)
        setZones(zones)
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFloor])

    const _handleSelect = useCallback(
        (type: 'floor' | 'zone') =>
            ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                if (type === 'floor') {
                    if (value !== 'undefined') {
                        _getDetails(Number(value))
                        return
                    }

                    setSelectedFloor(null)
                    setZones(null)
                    return
                }

                const zoneType = value as IZoneTypes
                setSelectedZoneType(zoneType)
                _getZones(selectedFloor.id, zoneType)
            },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedFloor, selectedZoneType]
    )

    useEffect(() => {
        _getFloors()
    }, [_getFloors])

    return (
        <FloorMapProvider>
            <Container>
                <CreateFloorModal
                    ref={createFloorModalRef}
                    onClose={_handleClose}
                    onRemove={!hasZonesInFloor && _handleDeleteFloor}
                />
                <TopRowDefault
                    title="Área de Atendimento Presencial"
                    onBackButtonClick={() => history.replace(`${basePath}/gestao-mall`)}
                    buttons={[
                        {
                            title: 'Criar Novo Piso',
                            //icon: 'trash-alt',
                            onClick: _openModal,
                        },
                    ]}
                />

                <Content>
                    {!floors ? (
                        <EmptyMessage>
                            <IconBackGround />
                            <EmptyMessageText>
                                Clique no botão <span>“criar novo piso“</span> para criar um novo piso de atendimento.
                                {/*
                            um mapa ja existente para editar ou adiconar
                            areas de atendimento presencial, ou Adicione um novo mapa clicando aqui.
                        */}
                            </EmptyMessageText>
                        </EmptyMessage>
                    ) : (
                        <AttendanceRow>
                            <AttendanceLeftContent>
                                {showForm && !!selectedFloor ? (
                                    <ZoneForm
                                        zoneTypes={zoneTypes}
                                        toggleShow={_showForm()}
                                        zone={selectedZone}
                                        floorId={selectedFloor?.id}
                                        handleCloseZonesForms={_handleCloseZonesForms}
                                    />
                                ) : (
                                    <>
                                        <InputContainer>
                                            <InputContainer>
                                                <InputItem
                                                    labelText="Piso"
                                                    type="select"
                                                    options={floorsOptions}
                                                    inputProps={{
                                                        onChange: _handleSelect('floor'),
                                                        value: selectedFloor?.id ?? 'undefined',
                                                    }}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <InputItem
                                                    type="select"
                                                    labelText="Tipo"
                                                    options={zoneTypes}
                                                    inputProps={{
                                                        onChange: _handleSelect('zone'),
                                                        value: selectedZoneType,
                                                    }}
                                                />
                                            </InputContainer>
                                        </InputContainer>
                                        {!!selectedFloor && (
                                            <DefaultButton id="edit-floor-button" onClick={_showForm()}>
                                                Adicionar zona de atendimento
                                            </DefaultButton>
                                        )}
                                        {!selectedFloor && (
                                            <ZoneEmptyMessage>
                                                <p>
                                                    Clique em <span>“adicionar zona de atendimento“</span> para criar
                                                    suas zonas
                                                </p>
                                                <p>ou</p>
                                                <p>
                                                    Clique em <span>“criar novo piso“</span> para adicionar novos pisos
                                                </p>
                                            </ZoneEmptyMessage>
                                        )}
                                        {!!zones && (
                                            <ZoneListContent>
                                                {zones?.map((item, i) => (
                                                    <ListItemContent key={i}>
                                                        <ColorBox color={item.color} />
                                                        <TextItem>{item.label}</TextItem>
                                                        <Actions>
                                                            <DeleteButton onClick={_handleDelete(item.id)}>
                                                                <IconDelete />
                                                            </DeleteButton>
                                                            <EditButton onClick={_showForm(item)}>
                                                                <IconEdit />
                                                            </EditButton>
                                                        </Actions>
                                                    </ListItemContent>
                                                ))}
                                            </ZoneListContent>
                                        )}
                                    </>
                                )}
                            </AttendanceLeftContent>
                            <AttendanceRightContent>
                                {!!selectedFloor && !showForm && (
                                    <ButtonRow>
                                        {!hasZonesInFloor && (
                                            <DefaultButton
                                                widthFitContainer
                                                variant="danger"
                                                onClick={_handleDeleteFloor(selectedFloor.id)}
                                            >
                                                <IconDelete />
                                                Remover Piso
                                            </DefaultButton>
                                        )}
                                        <DefaultButton widthFitContainer onClick={_openEdit}>
                                            <IconEdit />
                                            Editar Piso
                                        </DefaultButton>
                                    </ButtonRow>
                                )}
                                <FloorMap selectedImage={selectedImage} zones={zones} />
                            </AttendanceRightContent>
                        </AttendanceRow>
                    )}
                </Content>
            </Container>
        </FloorMapProvider>
    )
})

export { InPersonAttendanceArea }
