import { formatCurrency } from 'helpers'
import { ProductPart, SideDish } from 'types/marketplace'

import { SwitchButton } from '..'

import {
    ContentContainer,
    DishesGroupContainer,
    DragContainer,
    GroupOptions,
    GroupTitle,
    GroupTitleRow,
    GroupTitleInfo,
    MobileTag,
    OptionItem,
    OptionText,
    OptionValue,
    ButtonsRow,
    EditButton,
    DragIcon,
} from './dishes-group.styles'

interface Props {
    editGroupClick(): void
    onUpdateProductPartStatus(productPartIndex: number | undefined, status: 0 | 1): void
    productPart: ProductPart
    productPartIndex: number
    sideDishes: SideDish[] | undefined
}

const DishesGroup: React.FC<Props> = ({
    editGroupClick,
    onUpdateProductPartStatus,
    productPart,
    productPartIndex,
    sideDishes,
}) => {
    return (
        <DishesGroupContainer>
            <DragContainer>
                <DragIcon />
            </DragContainer>
            <ContentContainer>
                <GroupTitleRow>
                    <GroupTitle>{productPart.name}</GroupTitle>
                    <GroupTitleInfo>
                        <MobileTag>Mínimo: </MobileTag> {productPart.qty_min}
                    </GroupTitleInfo>
                    <GroupTitleInfo>
                        <MobileTag>Máximo: </MobileTag> {productPart.qty_max}
                    </GroupTitleInfo>
                    <GroupTitleInfo>
                        <MobileTag>Tipo:</MobileTag> {productPart.is_required ? 'Obrigatório' : 'Opcional'}
                    </GroupTitleInfo>
                </GroupTitleRow>
                <GroupOptions>
                    {sideDishes
                        ?.filter(sideDish => productPart.side_dishes.some(x => x.id === sideDish.id))
                        ?.map(sideDish => (
                            <OptionItem key={sideDish.id}>
                                <OptionText>{sideDish.name}</OptionText>
                                <OptionValue>{formatCurrency(sideDish.price)}</OptionValue>
                            </OptionItem>
                        ))}
                </GroupOptions>
                <ButtonsRow>
                    <EditButton onClick={editGroupClick}>Editar</EditButton>
                    <SwitchButton
                        isActive={Boolean(productPart.status)}
                        onClick={() => onUpdateProductPartStatus(productPartIndex, productPart.status ? 0 : 1)}
                    />
                </ButtonsRow>
            </ContentContainer>
        </DishesGroupContainer>
    )
}

export default DishesGroup
