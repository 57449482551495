import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: calc(100% - 82px);
    display: flex;
    flex-direction: column;
    * {
        box-sizing: border-box;
    }
    overflow: auto;
`

const ContainerTitle = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
    @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
    }
`

const Title = styled.div`
    font-size: 22px;
    font-weight: bold;
`

const ButtonText = styled.div`
    color: var(--primary-color);
    cursor: pointer;
    margin-top: 20px;
    @media (min-width: 1200px) {
        margin-top: 0;
    }
`

const ContainerFilter = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
`

const FilterItem = styled.div`
    margin-right: 15px;
`

const Listing = styled.div`
    height: calc(100vh - 230px);
    overflow: auto;
`
const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`
const FilterButton = styled.div`
    background-color: var(--primary-color);
    color: white;
    padding: 8px 20px;
    border-radius: 20px;
    border: 1px solid var(--primary-color);
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-flex;
    cursor: pointer;
    @media (min-width: 1200px) {
        margin: 0;
    }
`

const DeleteMessageContent = styled.div`
    text-align: justify;
    padding: 1rem;
`

const Text = styled.div`
    font-size: 0.9rem;
    margin-bottom: 1rem;
`

export {
    Container,
    ContainerTitle,
    Title,
    ButtonText,
    ContainerFilter,
    FilterItem,
    Listing,
    LoaderContainer,
    FilterButton,
    DeleteMessageContent,
    Text,
}
