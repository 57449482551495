import styled from 'styled-components'

const Footer = styled.div`
    border-top: 2px solid #ccc;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.9);
    justify-content: space-between;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .row-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        @media (max-width: ${({ theme }) => theme.devices.tablet}) {
            margin: 10px 0px;
        }
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    span {
        color: ${({ theme }) => theme.colors.primary};
        margin: 0px 5px;
    }
`

export { Footer, Row }
