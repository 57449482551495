import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const AppliedFiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 60%;
    }
`

export const FilterItem = styled.div`
    border-radius: 20px;
    padding: 5px 15px;
    background-color: #eee;
    color: #838383;
    display: flex;
    align-items: center;
    user-select: none;
    margin-right: 15px;
    margin-bottom: 15px;
`

export const FilterText = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
`

export const FilterIcon = styled.div`
    font-size: 20px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const RemoveIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimesCircle,
})``
