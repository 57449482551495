import { Order } from 'types'

import { OrdersListKey } from './types'

export function findOrderListKey(order: Order): OrdersListKey | undefined {
    if (order.status === '1') return 'scheduled'
    if (order.status === '2') return 'inModeration'
    if (order.status === '3') return 'inProduction'
    if (order.status === '4' && !order.routes) return 'toCollect'
    if (order.status === '4' && order.routes) return 'awaitingCollect'
    if (['5', '6', '7', '10'].includes(order.status)) return 'inRoute'
    if (['8', '9', '11'].includes(order.status)) return 'delivered'

    return undefined
}
