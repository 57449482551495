export const stores = [
    'Hoy Pizza',
    'Big Daddy',
    'Chinatown Aldeota',
    'Meatpacking Smash Burgers',
    'Meat Factory',
    'Clube do Leitão',
    'Noélia Doces & Salgados',
    'Granja Regina Express',
    'POT',
]

export const payments = [
    'Cartão de Crédito (Entrega)',
    'Cartão de Crédito (Online)',
    'Cartão de Débito (Entrega)',
    'Cartão de Débito (Online)',
    'Mercado Pago',
    'Pagamento Online',
    'PayPal',
]

export const channels = [
    {
        name: 'Ifood',
        id: 1,
        logo: 'https://i.pinimg.com/originals/0d/6d/f3/0d6df30ba4594d1fcadbda4ba608519e.png',
    },
    {
        name: 'Rappi',
        id: 2,
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Rappi_backgr_logo.png/480px-Rappi_backgr_logo.png',
    },
    {
        name: 'James Delivery',
        id: 3,
        logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQFFW81BFQRlQg/company-logo_200_200/0/1588887458247?e=2159024400&v=beta&t=l5Y5C8YlRwIerOcanHqaH9zG66GXfqPt5ysPWs-btC8',
    },
    {
        name: 'Zé Delivery',
        id: 4,
        logo: 'https://courier-images-web.imgix.net/static/img/meta_image.png?auto=compress,format&fit=max&w=undefined&h=undefined&dpr=2&fm=png',
    },
    {
        name: 'Ifood',
        id: 1,
        logo: 'https://i.pinimg.com/originals/0d/6d/f3/0d6df30ba4594d1fcadbda4ba608519e.png',
    },
    {
        name: 'Rappi',
        id: 2,
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Rappi_backgr_logo.png/480px-Rappi_backgr_logo.png',
    },
    {
        name: 'James Delivery',
        id: 3,
        logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQFFW81BFQRlQg/company-logo_200_200/0/1588887458247?e=2159024400&v=beta&t=l5Y5C8YlRwIerOcanHqaH9zG66GXfqPt5ysPWs-btC8',
    },
    {
        name: 'Zé Delivery',
        id: 4,
        logo: 'https://courier-images-web.imgix.net/static/img/meta_image.png?auto=compress,format&fit=max&w=undefined&h=undefined&dpr=2&fm=png',
    },
]
