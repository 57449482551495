import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { ColorSelect } from 'components/color-select/color-select'
import MensagemErro from 'components/mensagem-erro'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { getPaymentType, showErrors } from 'helpers'
import api from 'services/api'
import { IPayment } from 'types/payment'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './modal-payments.styled'

export interface IPaymentMethodsModalRef {
    show(): void
    close(): void
    setPaymentMethod(payment: IPayment): void
}

interface Props {
    onRemove(id: number): () => void
    onClose(isRefresh?: boolean): void
}

type IForm = {
    id?: number
    name: string
    type: number
    status: number
    color: string
}

const formOptions = {
    status: [
        { label: 'Ativo', value: 1 },
        { label: 'Inativo', value: 0 },
    ],
    payment_methods: [...Array(3)].map((_, key) => ({
        value: key + 1,
        label: getPaymentType(key + 1),
    })),
}

const PaymentsModal = memo(
    forwardRef<IPaymentMethodsModalRef, Props>(({ onRemove, onClose }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [paymentMethod, setPaymentMethod] = useState<IPayment>()

        const modalTitle = useMemo(() => {
            return ` Formas de Pagamento.`
        }, [])

        const isEdit = useMemo(() => {
            return !!paymentMethod
        }, [paymentMethod])

        const { isSubmitting, errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
            useFormik<IForm>({
                initialValues: {
                    name: '',
                    type: 0,
                    status: 1,
                    color: '',
                },
                validationSchema: Yup.object().shape({
                    name: Yup.string().trim().required('Insira o nome da Forma de Pagamento'),
                    type: Yup.string().trim().required('Selecione o Tipo'),
                    status: Yup.string().trim().required('Selecione o Status'),
                    color: Yup.string().trim().required('Por favor, preencha com a cor da forma de pagamento'),
                }),
                onSubmit: async values => {
                    try {
                        const body = {
                            name: values.name,
                            type: values.type,
                            status: values.status,
                            color: values.color,
                        }

                        if (!isEdit) {
                            await api.post(`painel/payment`, body)
                        } else {
                            await api.put(`painel/payment/${values.id}`, body)
                        }

                        setSuccessModal({
                            title: 'Sucesso',
                            subtitle: !isEdit
                                ? 'Forma de Pagamento criado com sucesso!'
                                : 'Forma de Pagamento atualizado com sucesso!',
                            singleButtonClick: _onClose(true),
                        })
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro',
                            subtitle: showErrors(error),
                            singleButtonText: 'Revisar alterações',
                        })
                    }
                },
            })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    setPaymentMethod(null)
                    resetForm()
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setPaymentMethod: (payment: IPayment) => {
                    setPaymentMethod(payment)
                    setValues(payment)
                },
            }),
            []
        )

        const _onClose = useCallback(
            (isRefresh?: boolean) => {
                return () => {
                    if (onClose) {
                        onClose(isRefresh)
                    }

                    setPaymentMethod(null)
                    lateralModalBaseRef.current?.close()
                }
            },
            [onClose]
        )

        const _handleChange = useCallback(
            (field: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field, value)
                },
            []
        )

        const _handleChangeColor = useCallback((color: string) => {
            setFieldValue('color', color)
        }, [])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle} onClose={onClose}>
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Forma de Pagamento"
                                    inputProps={{
                                        value: values.name,
                                        onChange: _handleChange('name'),
                                    }}
                                    errorMessage={touched.name && errors.name}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="Tipos"
                                    type="select"
                                    options={formOptions.payment_methods}
                                    inputProps={{
                                        required: true,
                                        value: values.type,
                                        onChange: _handleChange('type'),
                                    }}
                                    errorMessage={touched.type && errors.type}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="Status"
                                    type="select"
                                    options={formOptions.status}
                                    inputProps={{
                                        required: true,
                                        value: values.status,
                                        onChange: _handleChange('status'),
                                    }}
                                    errorMessage={touched.type && errors.type}
                                />
                            </InputContainer>
                            <InputContainer>
                                <ColorSelect
                                    label="Cor da Forma de Pagamento"
                                    defaultColor={values?.color}
                                    handleColorSelected={_handleChangeColor}
                                />
                                {touched?.color && errors?.color && (
                                    <MensagemErro msgErro={touched.color && errors.color} />
                                )}
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <div>
                            {isEdit && (
                                <TextButton isRemove onClick={onRemove(paymentMethod.id)}>
                                    <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={_onClose()}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting} />
            </LateralModalBase>
        )
    })
)

export { PaymentsModal }
