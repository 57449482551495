import React, { memo, useCallback, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { LoadingMessage, PerformanceCard } from 'components/_common'

import { getStoresChart } from 'helpers/performance-page'
import api from 'services/api'
import { ChartObject, ChartResponse } from 'types'

interface Props {
    params: any
}
interface StoreChart {
    store_performance: ChartResponse
}
const OrdersByStoreCard: React.FC<Props> = memo(({ params }) => {
    const [chart, setChart] = useState<ChartObject>()
    const [loading, setLoading] = useState(false)

    const _loadData = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api.get<StoreChart>('/painel/report/store', {
                params: { ...params, with_canceled: 1 },
            })
            const chart = getStoresChart(response.data.store_performance)
            setChart(chart)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (params) {
            _loadData(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <PerformanceCard
            title="Quantitativo de pedidos por lojas"
            subtitle="Baseado nos pedidos recebidos de cada estabelecimento"
        >
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!chart && (
                        <Bar
                            data={chart.data}
                            width={100}
                            height={30}
                            options={{
                                plugins: {
                                    tooltip: {
                                        callbacks: chart.callbacks,
                                    },
                                },
                                responsive: true,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    },
                                },
                            }}
                        />
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { OrdersByStoreCard }
