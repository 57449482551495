import { OrderItem } from 'types'

import { formatCurrency } from './currency'
import { hasOrderItemsDefaultFormat } from './hasOrderItemsDefaultFormat'
import { replaceLastSubstring } from './replaceLastSubstring'

function getOrderItem(item: string, index: number): OrderItem {
    const codPattern = /#\d*|##\d*/g
    const codMatch = item.match(codPattern)

    const qtdPattern = /x\d+|X\d+/g
    const qtdMatch = item.match(qtdPattern)

    const pricePattern = /(R\$\d+\.\d+)|(R\$\d+,\d+)|(R\$\d+)/g
    const priceMatch = item.match(pricePattern)

    const codExtracted = codMatch?.length && Number(codMatch[codMatch.length - 1]?.replaceAll('#', '').trim())
    const cod = codExtracted ? codExtracted.toString() : (index + 1).toString()

    const quantity = qtdMatch?.length ? Number(qtdMatch[qtdMatch.length - 1].replaceAll(/x|X/g, '').trim()) : 1

    const price = priceMatch?.length
        ? Number(priceMatch[priceMatch.length - 1].replaceAll('R$', '').replaceAll(',', '.').trim())
        : null

    const replaceAllPatterns = (item: string) =>
        item.replaceAll(codPattern, '')?.replaceAll(qtdPattern, '')?.replaceAll(pricePattern, '')?.trim()

    const name = qtdMatch?.length
        ? replaceLastSubstring(qtdMatch[qtdMatch.length - 1], '', replaceAllPatterns(item))?.trim()
        : replaceAllPatterns(item)

    return {
        cod,
        quantity,
        name,
        price,
    }
}

function formatToOrderItems(items: string): OrderItem[] {
    const orderItems = [] as OrderItem[]

    items
        .split('\n')
        .filter(item => item.length > 0)
        .forEach((item, index) => {
            const subitemPattern = /##.*/g
            const subitemMatch = item.match(subitemPattern)

            const observationPattern = />.*/g
            const observationMatch = item.match(observationPattern)

            if (subitemMatch?.length || observationMatch?.length) {
                const lastOrderItem = orderItems[orderItems.length - 1]

                if (subitemMatch?.length) {
                    orderItems[orderItems.length - 1] = {
                        ...lastOrderItem,
                        subitems: lastOrderItem?.subitems
                            ? [...lastOrderItem?.subitems, getOrderItem(item, index)]
                            : [getOrderItem(item, index)],
                    }
                }

                if (observationMatch?.length) {
                    orderItems[orderItems.length - 1] = {
                        ...lastOrderItem,
                        obs: observationMatch[observationMatch.length - 1].replace('>', '').trim(),
                    }
                }
            } else {
                orderItems.push(getOrderItem(item, index))
            }
        })

    return orderItems
}

function formatOrderItemsToString(orderItems: OrderItem[] | string[]): string {
    return (orderItems as OrderItem[]).every(
        (item: OrderItem) => typeof item === 'object' && hasOrderItemsDefaultFormat(item)
    )
        ? (orderItems as OrderItem[]).reduce((acc, orderItem) => {
              const subitems = orderItem?.subitems?.reduce(
                  (acc, subitem) =>
                      (acc += `##${subitem?.cod || subitem?.code_pdv || ''} ${subitem.name} x${subitem.quantity} ${
                          subitem.price ? `${formatCurrency(subitem.price)} ` : ''
                      }\n`),
                  ''
              )

              return (acc += `#${orderItem?.cod || orderItem?.code_pdv || ''} ${orderItem.name} x${
                  orderItem.quantity
              } ${orderItem.price ? `${formatCurrency(orderItem.price)} ` : ''}\n${subitems || ''}`)
          }, '')
        : (orderItems as string[]).reduce(
              (acc, orderItem) =>
                  (acc += typeof orderItem === 'string' ? `${orderItem}\n` : `${JSON.stringify(orderItem)}\n`),
              ''
          )
}

export { formatToOrderItems, formatOrderItemsToString }
