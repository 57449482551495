import { useEffect, useState, memo } from 'react'

import { ManagerPaymentsContent } from './manager-payments-content/manager-payments-content'
import {
    ManagerPaymentsModalContainer,
    ManagerPaymentsModalBackground,
    ManagerPaymentsModalBox,
} from './manager-payments-modal.styled'

interface Props {
    isActive: boolean
    closeClick(isRefresh?: boolean): void
}

const ManagerPaymentsModal: React.FC<Props> = memo(({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <ManagerPaymentsModalContainer isDisplayed={isDisplayed}>
            <ManagerPaymentsModalBox isEffectActive={isEffectActive}>
                <ManagerPaymentsContent isActive={isActive} closeClick={closeClick} />
            </ManagerPaymentsModalBox>
            <ManagerPaymentsModalBackground isEffectActive={isEffectActive} onClick={() => closeClick()} />
        </ManagerPaymentsModalContainer>
    )
})

export { ManagerPaymentsModal }
