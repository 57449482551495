import { useEffect, useState, memo } from 'react'

import { IPlooga } from 'types/plooga'

import { PloogaContent } from './plooga-modal-content/plooga-modal-content'
import { PloogaModalBackground, PloogaModalBox, PloogaModalContainer } from './plooga-modal.styled'

interface Props {
    isActive: boolean
    closeClick(isRefresh?: boolean): void
}

const PloogaModal: React.FC<Props> = memo(({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <PloogaModalContainer isDisplayed={isDisplayed}>
            <PloogaModalBox isEffectActive={isEffectActive}>
                <PloogaContent isActive={isActive} closeClick={closeClick} />
            </PloogaModalBox>
            <PloogaModalBackground isEffectActive={isEffectActive} onClick={() => closeClick()} />
        </PloogaModalContainer>
    )
})

export { PloogaModal }
