import styled, { css } from 'styled-components'

const Container = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    overflow: hidden;
`

const VerticalContent = styled.div<{ hideScrollbar?: boolean }>`
    width: 100%;
    height: 1px;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    box-sizing: border-box;
    ${({ hideScrollbar }) =>
        hideScrollbar &&
        css`
            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        `}
`

const HorizontalContent = styled.div<{ hideScrollbar?: boolean }>`
    cursor: grab;
    display: flex;
    width: 1px;
    min-width: 100%;
    height: 100%;
    overflow-x: auto;
    box-sizing: border-box;
    ${({ hideScrollbar }) =>
        hideScrollbar &&
        css`
            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        `}
`

export { Container, VerticalContent, HorizontalContent }
