import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
        box-sizing: border-box;
    }
`

const BoxContainer = styled.div`
    background-color: #fff;
    min-width: 600px;
    min-height: 500px;
    max-height: 90%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-width: auto;
        min-height: auto;
    }
`

const Header = styled.div`
    height: 50px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    user-select: none;
`
const Footer = styled.div`
    height: 50px;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 20px;
    user-select: none;
`

const Content = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 16px;
`

const Title = styled.div`
    font-weight: bold;
    position: relative;
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

const CloseIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    cursor: pointer;
`

const GroupTitle = styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: bold;
`

const ContainerOption = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 10px;
    width: 100%;
    min-height: 30px;
    user-select: none;
`

const TitleOption = styled.div`
    color: ${({ theme }) => theme.colors.black};
    flex: 1;
`

const ContainerAgent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding-bottom: 10px;
`

const ContainerAvatar = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.colors.lightGray};
    position: relative;
    margin-right: 20px;
`

const Avatar = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
`

const ContainerAgentInfo = styled.div`
    flex: 1;
`

const Vehicle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
`

const AgentName = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 70%;
    }
`

const ContainerInfo = styled.div`
    display: flex;
    margin-bottom: 2px;
`

const PhoneIcon = styled(FontAwesomeIcon).attrs({
    icon: faPhoneAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    padding-top: 4px;
    font-size: 16px;
`

const TextInfo = styled.div`
    font-size: 14px;
`

export {
    Header,
    Footer,
    Container,
    BoxContainer,
    Title,
    CloseIcon,
    GroupTitle,
    Content,
    ContainerOption,
    TitleOption,
    ContainerAgent,
    ContainerAvatar,
    Avatar,
    ContainerAgentInfo,
    Vehicle,
    AgentName,
    ContainerInfo,
    PhoneIcon,
    TextInfo,
}
