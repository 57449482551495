import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Axios from 'axios'
import Echo from 'laravel-echo'
import socketIOClient from 'socket.io-client'
import useSound from 'use-sound'

import { useEchoConnection } from 'hooks/use-echo-connection'
import { useAuth } from 'hooks/useAuth'
import { mercadooApi, plugApi } from 'services/api'
import { PagedList } from 'types'
import { IOrderIntegrationList } from 'types/order-integration-list'

const bicycleBell = '/bicycle-bell.mp3'
const swissBell = '/swiss-bell.mp3'

function useOrdersInIntegrations() {
    const { store, mall } = useAuth()
    const integrationSettings = useSelector(state => state.integrations)
    const [loading, setLoading] = useState<boolean>(true)

    const connection = useMemo(() => {
        return new Echo({
            host: process.env.REACT_APP_PLOOGA_WS_URL,
            broadcaster: 'socket.io',
            client: socketIOClient,
            namespace: '.',
            auth: {
                headers: {
                    Authorization: `Bearer ${integrationSettings.token}`,
                },
            },
        })
    }, [integrationSettings])

    const [playBicycleBell] = useSound(bicycleBell)
    const [playSwissBell] = useSound(swissBell)

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.new',
                callback: (r: IOrderIntegrationList) => {
                    dispatchData({ type: 'ADD', payload: r })
                    playSwissBell()
                },
            },
            {
                name: '.approved',
                callback: (r: IOrderIntegrationList) => {
                    dispatchData({ type: 'REMOVE', payload: r })
                    playSwissBell()
                },
            },
            {
                name: '.reproved',
                callback: (r: IOrderIntegrationList) => {
                    dispatchData({ type: 'REMOVE', payload: r })
                    playSwissBell()
                },
            },
            {
                name: '.processing',
                callback: (r: IOrderIntegrationList) => {
                    console.log('r', r)

                    dispatchData({ type: 'UPDATE', payload: r })
                    playSwissBell()
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const marketPlaceEvents = useMemo(() => {
        return [
            {
                name: '.new',
                callback: (r: IOrderIntegrationList) => {
                    dispatchData({ type: 'ADD', payload: r })
                    playSwissBell()
                },
            },
            {
                name: '.updated',
                callback: (r: IOrderIntegrationList) => {
                    dispatchData({ type: 'REMOVE', payload: r })
                    playSwissBell()
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data, dispatchData } = useEchoConnection<IOrderIntegrationList, IOrderIntegrationList>({
        enable: !!store?.id,
        channelName: `plooga.integrations.${store?.id}`,
        events: socketEvents,
        alternativeConnection: connection,
    })

    useEchoConnection<IOrderIntegrationList, IOrderIntegrationList>({
        enable: !!store?.id && !!store.marketplace_enable,
        channelName: `moderation.store.${store?.id}`,
        events: marketPlaceEvents,
        // alternativeConnection: connection,
    })

    const _loadOrdersIntegrations = useCallback(async () => {
        setLoading(true)
        const integrationData = new Array<IOrderIntegrationList>()
        try {
            const { data } = await plugApi.get<{ active: number; inactive: number }>(
                `/store/${store.id}/has-integrations`
            )

            if (data.active > 0) {
                const { data } = await plugApi.get<IOrderIntegrationList[]>(`/orders`, {
                    params: {
                        store_ids: [store.id],
                    },
                })
                integrationData.push(...data)
            }

            if (store.marketplace_enable) {
                const { data } = await mercadooApi.get<PagedList<IOrderIntegrationList>>('/orders', {
                    params: {
                        mall_id: mall.id,
                        store_id: store.id,
                        pending: 1,
                    },
                })
                integrationData.push(...data.items)
            }

            dispatchData({ type: 'SET', payload: integrationData })
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [dispatchData, store.id, store.marketplace_enable, mall])

    useEffect(() => {
        _loadOrdersIntegrations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_loadOrdersIntegrations])

    return {
        ordersIntegration: data,
        ordersIntegrationLoading: loading,
        refreshIntegration: _loadOrdersIntegrations,
        dispatchData,
    }
}

export { useOrdersInIntegrations }
