import InputMask from 'react-input-mask'

import MensagemErro from '../mensagem-erro'
import './style.scss'

const TextInputMask = props => {
    return (
        <div className="item-input-form">
            {props.label && (
                <div className="top-row">
                    <label className={`label ${props.disabled ? 'faded' : ''}`} style={props.labelStyle}>
                        {props.label}
                    </label>
                </div>
            )}

            {props.rightInsideIcon && (
                <div className="icon" style={{ top: props.label ? null : '10px' }}>
                    {props.rightInsideIcon}
                </div>
            )}

            <InputMask
                className={`${props.disabled ? 'faded' : ''}`}
                mask={props.mask}
                type="text"
                placeholder={props.placeholder}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
            />
            <MensagemErro msgColor={props.msgColor} msgErro={props.msgErro} />
        </div>
    )
}

export default TextInputMask
