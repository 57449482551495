const setSearchParams = (filters, location, history, makeRequest) => {
    const params = new URLSearchParams()

    Object.keys(filters).forEach(key => {
        filters[key] && params.set(key, filters[key])
    })

    if (`?${params.toString()}` !== location.search) {
        history.push(`?${params.toString()}`)
    } else if (makeRequest) {
        makeRequest()
    }
}

export { setSearchParams }
