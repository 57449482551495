import {
    faClock,
    faCalendarCheck,
    faFile,
    faTimes,
    faTimesCircle,
    faArrowCircleRight,
    faFileUpload,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ifood = 'https://static.pelando.com.br/live/merchants/avatar_web_square_94_2x/avatar/889_2.jpg'
export const rappi =
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Rappi_backgr_logo.png/480px-Rappi_backgr_logo.png'
export const uberEats = 'https://logodownload.org/wp-content/uploads/2019/05/uber-eats-logo-1.png'
export const undefinedStore =
    'https://images.vexels.com/media/users/3/152579/isolated/lists/a52ce2d4014c39b7b7c5974a1a1cbb85-icone-de-ponto-de-interrogacao-do-circulo-laranja.png'

export const OrderItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    white-space: normal;
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 134, 10, 0.1);
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
`

export const LogosContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
`

export const PickerPhoto = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
`

export const StoreLogo = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-left: -10px;
`

export const ChannelLogo = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
`

export const TopRow = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

export const CustomerInfo = styled.div`
    flex: 1;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex: unset;
        width: 45%;
    }
`

export const CustomerID = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: 12px;
`

export const CustomerName = styled.div`
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 90%;
    }
`

export const TimesContainer = styled.div`
    width: 100%;
    font-size: 12px;
    display: flex;
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 0;
        width: 23%;
        display: block;
    }
`

export const TimeItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    margin-right: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-right: 0;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
`

export const TimeText = styled.div``

export const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

export const GreenClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

export const CalendarIcon = styled(FontAwesomeIcon).attrs({
    icon: faCalendarCheck,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 7px;
    width: 20px;
    font-size: 12px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
`

export const ActionRow = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: column;
        width: unset;
        flex-basis: 20%;
        margin-top: 0;
    }
`

export const RejectButton = styled.div`
    cursor: pointer;
    flex-basis: 48%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border: 0;
        text-transform: unset;
        font-weight: normal;
        padding: 0;
        font-size: 12px;
    }
    &:hover {
        text-decoration: underline;
    }
`

export const RejectIcon = styled(FontAwesomeIcon).attrs({
    icon: faFile,
})``

export const AcceptButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 400ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`

export const OptionsDropdown = styled.div<{ isActive: boolean }>`
    width: 150px;
    transition: 400ms;
    padding: 15px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -5px;
    right: -5px;
    overflow: hidden;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    z-index: 10;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        top: 75%;
    }
`

export const CloseDropdown = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
`

export const DropdownItem = styled.div`
    display: flex;
    width: 85%;
    white-space: normal;
    align-items: center;
    color: ${({ theme }) => theme.colors.lightGray};
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

export const CancelIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimesCircle,
})`
    font-size: 14px;
`
export const IconContainer = styled.div`
    width: 20px;
    margin-right: 20px;
`

export const DetailIcon = styled(FontAwesomeIcon).attrs({
    icon: faFileUpload,
})`
    font-size: 14px;
`

export const MoveIcon = styled(FontAwesomeIcon).attrs({
    icon: faArrowCircleRight,
})`
    font-size: 14px;
`

export const DropdownText = styled.div`
    font-size: 12px;
    margin-left: 5px;
`
