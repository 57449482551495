import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const NavigationRowContainer = styled.div`
    padding-bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    padding: 15px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
    }
`

export const NavigationLeftSide = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    flex: 1;
`

export const ButtonsContainer = styled.div`
    display: none;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
    }
`

export const NavigationItemContainer = styled.div<{ isActive?: boolean }>`
    margin-bottom: 20px;
    font-size: 14px;
    width: 40%;
    border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    position: relative;
    margin-right: 15px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
        border-bottom: 0;
        display: inline-flex;
        align-items: center;
        margin-right: 25px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        &:last-child {
            margin-right: 0;
        }
    }
`

export const NavigationItem = styled.div<{ isActive?: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    display: inline-flex;
    padding-bottom: 5px;
    margin-right: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: 400ms;
    font-size: 18px;
    &:hover {
        border-color: ${({ theme }) => theme.colors.primary};
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border-bottom: 3px solid;
        border-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : 'transparent')};
    }
`

export const NewItemIcon = styled.div`
    background-color: rgba(255, 134, 10, 0.3);
    padding: 3px 7px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    user-select: none;
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 10px;
        top: -15px;
        right: -10px;
        padding: 3px 7px;
    }
`

export const FilterButtonContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.gray};
    width: 25px;
    height: 25px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
`

export const FilterButton = styled(FontAwesomeIcon).attrs({
    icon: faFilter,
})`
    color: ${({ theme }) => theme.colors.darkGray};
`

export const AppliedFiltersNumber = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
`

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
    .icon {
        font-size: 16px;
    }
    @media (max-width: ${({ theme }) => theme.devices.tabletPro}) {
        .button {
            width: 48%;
        }
    }
`
export const NumbersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
    min-width: 454px;
    width: fit-content;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        justify-content: flex-start;
        width: 100%;
        min-width: inherit;
    }
`

export const RequestCollect = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    padding: 10px 15px;
    font-weight: bold;
    text-transform: uppercase;
    transition: 400ms;
    text-align: center;
    width: 100%;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
        display: inline-flex;
        margin-bottom: 0;
    }
    &:hover {
        opacity: 0.7;
    }
`

export const NumberItem = styled.div`
    display: flex;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
    }
`

export const NumberValue = styled.div`
    font-size: 34px;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
`

export const NumberLabel = styled.div`
    font-size: 14px;
    width: 80px;
`

export const MobileSelectTab = styled.select`
    border: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    flex: 1;
    outline: none;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    line-height: 1;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right;
    -webkit-appearance: none;
    background-size: 13px;
    background-position-x: 96%;
    font-weight: bold;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: none;
    }
`
