import { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class TopTagItem extends Component {
    render() {
        return (
            <>
                <div className="top-tag-item" onClick={this.props.onClick}>
                    <div className="icone">
                        <FontAwesomeIcon icon={this.props.iconName} color="#DA3176" />
                    </div>
                    <div className="value">{this.props.value || 0}</div>
                    <div className="label">{this.props.label}</div>
                </div>
            </>
        )
    }
}
