import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import colors from 'themes/colors'

import './style.scss'

const Notification = ({ visible, index, onCloseNotification, title, message, type, onMessageClick }) => {
    const visibleNotification = classNames('notification-container', {
        visible: visible,
    })

    const closeNotification = () => {
        if (onCloseNotification) onCloseNotification()
    }

    const getNotificationIcon = () => {
        return {
            'orders-failed': 'exclamation-circle',
            'order-moderation': 'thumbs-up',
            'route-waiting': 'map',
        }[type]
    }

    const textColor = type === 'orders-failed' ? '#fff' : undefined

    return (
        <div
            className={visibleNotification}
            style={{
                background: type === 'orders-failed' ? colors.red : undefined,
                bottom: index > 0 && index !== 1 ? `${index * 100 + 100}px` : index === 1 && '200px',
            }}
        >
            <div className="content-container">
                <div className="close-button" onClick={closeNotification} style={{ color: textColor }}>
                    <FontAwesomeIcon icon="times" />
                </div>
                <div className="main-content">
                    <div className="icon-container">
                        <FontAwesomeIcon icon={getNotificationIcon()} />
                    </div>
                    <div className="text">
                        <div className="title" style={{ color: textColor }}>
                            {title}
                        </div>
                        <div className="value" style={{ color: textColor }} onClick={onMessageClick}>
                            {`${message} `}
                            <FontAwesomeIcon icon="arrow-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification
