import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { circle, difference, Units } from '@turf/turf'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import mallMarker from 'assets/images/mall-marker.png'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'

import { useUI } from 'contexts'
import { formatCurrency, getFormInputError } from 'helpers'
import { useAuth, useMapboxView } from 'hooks'
import { addSymbolPoint } from 'hooks/useMapboxView/helpers'
import api from 'services/api'
import { PagedList } from 'types'
import { IAttendanceArea } from 'types/attendance-area'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    DeleteButton,
    DeleteButtonContent,
    FormLabel,
    FormRow,
    FormSection,
    InputContainer,
    MapContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './attendance-area-modal.styled'

type IForm = {
    id?: number
    label: string
    free_fee?: { enabled: boolean; value: number | string }
    values: {
        fee: number | string
        cost: number | string
    }
    range: {
        start: number
        end: number
    }
    status: number
}

export type AttendanceAreaModalRef = {
    show(): void
    close(): void
    setAttendanceArea(attendanceArea: IAttendanceArea): void
}

type Props = {
    type: 'mall' | 'store'
    baseUrl: string
    onRefresh: (isRefresh: boolean) => void
    onRemove?(id: number): () => void
}

const AttendanceAreaModal = memo(
    forwardRef<AttendanceAreaModalRef, Props>(({ type, baseUrl, onRefresh, onRemove }, ref) => {
        const { store, mall } = useAuth()
        const { setErrorModal, setSuccessModal } = useUI()
        const { mapRef, mapContainerRef, addGeojsonPolygon, addGeojsonPolygons } = useMapboxView({
            zoom: 12,
        })

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [attendanceArea, setAttendanceArea] = useState<IAttendanceArea>()
        const [rates, setRates] = useState<IAttendanceArea[]>()

        const { values, errors, touched, setValues, resetForm, getFieldProps, setFieldValue, handleSubmit } =
            useFormik<IForm>({
                initialValues: {
                    label: '',
                    free_fee: {
                        enabled: false,
                        value: 0,
                    },
                    values: { fee: 0, cost: 0 },
                    range: { start: 0, end: 0 },
                    status: 1,
                },
                validationSchema: Yup.object().shape({
                    label: Yup.string().required('Título da tarifa é obrigatório.'),
                    free_fee: Yup.object().shape({
                        enabled: Yup.boolean().nullable(),
                        value: Yup.number()
                            .when('enabled', {
                                is: enabled => enabled,
                                then: (schema: Yup.NumberSchema) => schema.min(0.1, 'Valor para frete é obrigatório.'),
                            })
                            .nullable(),
                    }),
                    range: Yup.object().shape({
                        start: Yup.number(),
                        end: Yup.number()
                            .moreThan(Yup.ref('start'), 'Raio máximo deve ser maior que o inicial.')
                            .required(),
                    }),
                }),
                onSubmit: async values => {
                    const polygonCircleData = getPolygonCircleData(Number(values.range.start), Number(values.range.end))
                    const body = {
                        ...values,
                        area: polygonCircleData.geometry,
                        configs: {
                            free_fee: values?.free_fee,
                        },
                    }
                    delete body.free_fee

                    try {
                        if (isEdit) {
                            await api.put<IAttendanceArea>(`painel/attendance-area/${attendanceArea.id}`, body)
                        } else {
                            await api.post<IAttendanceArea>(baseUrl, body)
                        }

                        setSuccessModal({
                            title: 'Sucesso!',
                            subtitle: `Tarifa ${isEdit ? 'atualizada' : 'registrada'} com sucesso!`,
                            singleButtonClick: _close(true),
                        })
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro',
                            subtitle: `Não foi possível ${isEdit ? 'atualizar' : 'registrar'} tarifa`,
                        })
                    }
                },
            })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setAttendanceArea(null)
                    setRates([])
                    lateralModalBaseRef.current?.show()

                    _getRates()
                    setTimeout(() => {
                        mapRef.current?.resize()
                        mapRef.current?.setZoom(12)
                    }, 200)
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setAttendanceArea: (attendanceArea: IAttendanceArea) => {
                    setValues({
                        id: attendanceArea.id,
                        label: attendanceArea.label,
                        range: attendanceArea.range,
                        values: {
                            fee: attendanceArea.values?.fee,
                            cost: attendanceArea.values?.cost,
                        },
                        free_fee: {
                            enabled: attendanceArea.configs?.free_fee.enabled,
                            value: attendanceArea.configs?.free_fee.value,
                        },
                        status: Number(attendanceArea.status),
                    })
                    setAttendanceArea(attendanceArea)
                },
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        )

        const isEdit = useMemo(() => {
            return !!attendanceArea
        }, [attendanceArea])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Gerenciar' : 'Criar nova'} Tarifa`
        }, [isEdit])

        const requestURL = useMemo(() => {
            if (type === 'mall') {
                return `painel/mall/${mall.id}/attendance-area`
            }
            return `painel/store/${store.id}/attendance-area`
        }, [type, mall, store])

        const _close = useCallback(
            (isRefresh?: boolean) => () => {
                if (isRefresh) {
                    onRefresh(isRefresh)
                }

                lateralModalBaseRef.current?.close()
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        )

        const _handleRadiusChange = useCallback(
            (fieldName: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    const { start, end } = values.range

                    if (fieldName === 'range.start') {
                        const valueDifference = Math.abs(Number(value) - Number(start))

                        if (Number(value) >= Number(end) || (!end && valueDifference === 500)) {
                            setFieldValue('range.end', Number(value) + 500)
                        }
                    } else {
                        const valueDifference = Math.abs(Number(value) - Number(end))
                        if (Number(value) <= Number(start) && valueDifference === 500) {
                            setFieldValue('range.start', Number(value) - 500)
                        }
                    }
                    setFieldValue(fieldName, value)
                },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [values.range]
        )

        const _removePolygonLayer = useCallback(() => {
            if (mapRef.current?.getSource('polygons')) {
                mapRef.current?.removeLayer('polygon-lines')
                mapRef.current?.removeLayer('polygons')
                mapRef.current?.removeSource('polygons')
            }
        }, [mapRef])

        const validatePolygonCircle = useCallback((rangeStart, rangeEnd) => {
            return isNaN(rangeStart) || isNaN(rangeEnd) || rangeEnd <= rangeStart ? false : true
        }, [])

        const getPolygonCircleData = useCallback(
            (rangeStart = 0, rangeEnd = 1000) => {
                if (!validatePolygonCircle(rangeStart, rangeEnd)) return null
                const { lat, lng } = type === 'store' ? store?.location : mall?.address

                const center = [lng, lat]

                const properties = {
                    'fill-color': '#FF860A',
                    'fill-opacity': 0.5,
                }

                const circleOptions = { steps: 100, units: 'meters' as Units }

                const outerCirclePolygon = circle(center, rangeEnd, {
                    ...circleOptions,
                    properties,
                })

                if (rangeStart > 0) {
                    const innerCirclePolygon = circle(center, rangeStart, {
                        ...circleOptions,
                        properties,
                    })

                    return difference(outerCirclePolygon, innerCirclePolygon)
                }

                return outerCirclePolygon
            },
            [store, mall, type, validatePolygonCircle]
        )

        const _getRates = useCallback(async () => {
            //setLoading(true)
            try {
                const { data } = await api.get<PagedList<IAttendanceArea>>(requestURL, {
                    params: {
                        order_by: 'id',
                    },
                })
                setRates(data.items)
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível carregar área de atendimento',
                })
            } finally {
                //setLoading(false)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [requestURL])

        const _handlePolygonRadius = useCallback(() => {
            const areas = rates ? [...rates] : []

            if (values?.range?.start && values?.range?.end) {
                const geometry = getPolygonCircleData(values?.range?.start, values?.range?.end)?.geometry

                const index = areas?.findIndex(item => item?.id === attendanceArea?.id)

                areas?.splice(index, 1, {
                    ...values,
                    area: {
                        type: 'Polygon',
                        coordinates: geometry?.coordinates,
                    },
                } as IAttendanceArea)
            }

            //addGeojsonPolygon(getPolygonCircleData(values?.range?.start, values?.range?.end))

            _removePolygonLayer()
            addGeojsonPolygons({
                type: 'FeatureCollection',
                features: areas?.map(item => {
                    return {
                        type: 'Feature',
                        properties: {
                            'fill-color': attendanceArea?.id === item.id ? '#FF860A' : '#000000',
                            'fill-opacity': attendanceArea?.id === item.id ? 0.7 : 0.5,
                        },
                        geometry: { type: 'Polygon', coordinates: item.area.coordinates },
                    }
                }),
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isEdit, store, values.range, rates, attendanceArea])

        const _handleCheck = useCallback(() => {
            setFieldValue('free_fee.enabled', !values?.free_fee?.enabled)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values])

        useEffect(() => {
            setTimeout(() => {
                const { lat, lng } = type === 'store' ? store?.location : mall?.address

                if (lat && lng) {
                    addSymbolPoint(
                        mapRef.current,
                        { lat, lng },
                        {
                            id: 'mall-marker-preview',
                            src: mallMarker,
                            size: 0.4,
                            coordinates: { lat, lng },
                        }
                    )
                }
                mapRef.current?.resize()
            }, 1000)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store])

        useEffect(() => {
            setTimeout(_handlePolygonRadius, 500)
        }, [_handlePolygonRadius])

        //useEffect(() => {
        //    _getRates()
        //}, [_getRates])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title={modalTitle}
                headerComponent={
                    <DeleteButtonContent>
                        {isEdit && onRemove && (
                            <DeleteButton onClick={onRemove(attendanceArea.id)}>
                                <FontAwesomeIcon icon="trash-alt" /> <span>Remover</span>
                            </DeleteButton>
                        )}
                    </DeleteButtonContent>
                }
            >
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Título da Tarifa"
                                    inputProps={getFieldProps('label')}
                                    errorMessage={getFormInputError('label', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <FormLabel>Tarifas Referente</FormLabel>
                                <FormRow>
                                    <InputItem
                                        type="currency"
                                        labelText="A Cobrar do Cliente"
                                        inputProps={{
                                            ...getFieldProps('values.fee'),
                                            value: formatCurrency(values?.values?.fee),
                                            placeholder: 'R$ 0,00',
                                        }}
                                        errorMessage={getFormInputError('values.fee', errors, touched)}
                                    />
                                    <InputItem
                                        type="currency"
                                        labelText="Custo Para Loja"
                                        inputProps={{
                                            ...getFieldProps('values.cost'),
                                            value: formatCurrency(values?.values?.cost),
                                            placeholder: 'R$ 0,00',
                                        }}
                                        errorMessage={getFormInputError('values.cost', errors, touched)}
                                    />
                                </FormRow>
                            </InputContainer>
                            <InputContainer>
                                <Checkbox
                                    isSquared
                                    checkboxTitle={'Grátis a partir de:'}
                                    inputProps={{
                                        checked: values?.free_fee?.enabled ?? false,
                                        value: Number(values?.free_fee?.enabled),
                                        onChange: _handleCheck,
                                    }}
                                />
                                <InputItem
                                    type="currency"
                                    inputProps={{
                                        ...getFieldProps('free_fee.value'),
                                        value: formatCurrency(values?.free_fee?.value),
                                        disabled: !values?.free_fee?.enabled,
                                        placeholder: 'R$ 0,00',
                                    }}
                                    errorMessage={getFormInputError('free_fee.value', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <FormLabel>Raio de Atendimento</FormLabel>
                                <FormRow>
                                    <InputItem
                                        labelText="MIN"
                                        type="number"
                                        inputProps={{
                                            ...getFieldProps('range.start'),
                                            onChange: _handleRadiusChange('range.start'),
                                            value: Number(values.range.start),
                                            placeholder: '0 KM',
                                            min: 0,
                                            step: 500,
                                        }}
                                        errorMessage={getFormInputError('range.start', errors, touched)}
                                    />
                                    <InputItem
                                        labelText="MAX"
                                        type="number"
                                        inputProps={{
                                            ...getFieldProps('range.end'),
                                            onChange: _handleRadiusChange('range.end'),
                                            value: Number(values.range.end),
                                            placeholder: '0 KM',
                                            min: 500,
                                            step: 500,
                                        }}
                                        errorMessage={getFormInputError('range.end', errors, touched)}
                                    />
                                </FormRow>
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={[
                                        { label: 'Ativo', value: 1 },
                                        { label: 'Inativo', value: 0 },
                                    ]}
                                    inputProps={getFieldProps('status')}
                                    errorMessage={getFormInputError('status', errors, touched)}
                                />
                            </InputContainer>

                            <MapContainer ref={mapContainerRef} />
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="flex-end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>Salvar</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { AttendanceAreaModal }
