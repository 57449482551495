import { useState, useRef, useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import { DefaultButton } from 'components/default-button/default-button'
import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { showErrors } from 'helpers'
import { useAuth, useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, SideDishCategory } from 'types'

import {
    SideDishCategoriesModal,
    SideDishCategoriesModalRef,
} from './components/side-dish-categories-modal/side-dish-categories-modal'
import {
    ButtonRow,
    Container,
    Content,
    DeleteIcon,
    Header,
    HeaderTitle,
    StatusItem,
} from './side-dish-categories-listing.styled'

const SideDishCategoriesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { basePath, store } = useAuth()

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const sideDishCategoriesModalRef = useRef<SideDishCategoriesModalRef>()

    const [sideDishCategories, setSideDishCategories] = useState<SideDishCategory[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState<{
        current_page: number
        totals: number
    }>()

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const _getSideDishCategories = useCallback(
        async (page?: number) => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<SideDishCategory>>('/painel/side-dish-categories', {
                    params: { store_id: store.id, ...(page && { current_page: page }) },
                })

                setPagination({ current_page: data.current_page, totals: data.totals })
                setSideDishCategories(state =>
                    Number(data?.current_page) > 1 && state ? [...state, ...data.items] : data.items
                )
            } catch (error) {
                onRequestError(error)
            } finally {
                setLoading(false)
            }
        },
        [store]
    )

    const removeSideDishCategory = useAxiosRequest({
        api,
        method: 'delete',
        executeOnStart: false,
        onComplete: () => {
            setModalMessageData({
                title: 'Sucesso!',
                message: 'Categoria de acompanhamento excluída com sucesso',
            })

            modalMessage.current?.openModal()
            //sideDishCategories.request()
        },
        onError: onRequestError,
    })

    const _openModal = useCallback(() => {
        sideDishCategoriesModalRef.current?.show()
    }, [])

    const _openEdit = useCallback(
        (sideDish: SideDishCategory) => () => {
            _openModal()
            sideDishCategoriesModalRef.current?.setSideDish(sideDish)
        },
        []
    )

    const _handleCloseModal = useCallback(
        (refresh?: boolean) => {
            if (refresh) {
                _getSideDishCategories(pagination.current_page)
            }
        },
        [pagination, _getSideDishCategories]
    )

    useEffect(() => {
        _getSideDishCategories()
    }, [_getSideDishCategories])

    return (
        <Container>
            <ModalLoading visible={loading || removeSideDishCategory.loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <SideDishCategoriesModal ref={sideDishCategoriesModalRef} onClose={_handleCloseModal} />

            <Header>
                <HeaderTitle>Categorias de acompanhamento</HeaderTitle>
                <DefaultButton widthFitContainer onClick={_openModal}>
                    Criar Categoria
                </DefaultButton>
            </Header>

            <Content>
                <FlexTable
                    columns={[
                        { name: 'ID', width: '5%' },
                        { name: 'Nome', width: '25%' },
                        { name: 'Descrição', width: '30%' },
                        { name: 'Status', width: '15%' },
                        { name: '', width: '25%' },
                    ]}
                    list={sideDishCategories?.map(sideDishCategory => {
                        const { id, name, description, status } = sideDishCategory

                        return {
                            id: id.toString(),
                            name,
                            description,
                            status: <StatusItem isActive={Boolean(status)}>{status ? 'Ativo' : 'Inativo'}</StatusItem>,
                            buttonRow: (
                                <ButtonRow>
                                    <DefaultButton outline widthFitContainer onClick={_openEdit(sideDishCategory)}>
                                        Editar
                                    </DefaultButton>
                                    <DefaultButton
                                        widthFitContainer
                                        variant="danger"
                                        onClick={() => {
                                            setModalConfirmationData({
                                                title: 'Excluir Categoria de acompanhamento',
                                                message:
                                                    'Tem certeza de que deseja excluir esta Categoria de acompanhamento?',
                                                onYes: () =>
                                                    removeSideDishCategory.request({
                                                        url: `/painel/side-dish-category/${id}`,
                                                    }),
                                            })

                                            modalConfirmation.current?.openModal()
                                        }}
                                    >
                                        <DeleteIcon />
                                    </DefaultButton>
                                </ButtonRow>
                            ),
                        }
                    })}
                />
            </Content>
            {!sideDishCategories?.length && !loading && (
                <EmptyMessage>Nenhuma Categoria de acompanhamento criada para esta loja</EmptyMessage>
            )}

            <ButtonLoadMore
                loading={loading}
                visible={
                    Number(pagination?.totals) > 0 && Number(sideDishCategories?.length) < Number(pagination?.totals)
                }
                onClick={() => _getSideDishCategories(Number(pagination?.current_page) + 1)}
            />
        </Container>
    )
}

export default SideDishCategoriesListing
