import { useState, forwardRef, useImperativeHandle, useCallback } from 'react'

import { useEventListener } from '../../hooks'
import PasswordInputForm from '../password-input-form'
import './style.scss'

const ModalChangePassword = forwardRef<any, any>(function ModalChangePassword(
    { isActive, onClose, title, user, subtitle, setUserData, errors, touched, onYes, onNo },
    ref
) {
    const [active, setActive] = useState(isActive || false)

    const _close = useCallback(() => {
        setActive(false)
        onClose && onClose()
    }, [onClose])

    const handleEscPress = useCallback(
        ({ code }) => {
            if (code === 'Escape') {
                _close()
            }
        },
        [_close]
    )

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setActive(true)
        },
        closeModal: () => {
            _close()
        },
    }))

    return (
        <div className={`modal-change-password ${active ? 'is-active' : ''}`}>
            <div className="Modal-bg">
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">{title}</div>
                        <div className="Modal-subtitle">{subtitle}</div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">
                            <div className="inputs-container">
                                <PasswordInputForm
                                    label="Nova senha"
                                    value={user.password}
                                    onChange={e => setUserData('password', e.target.value)}
                                    msgErro={touched ? touched.password && errors.password : errors.password}
                                />
                                <PasswordInputForm
                                    label="Confirmar nova senha"
                                    value={user.password_confirmation}
                                    onChange={e => setUserData('password_confirmation', e.target.value)}
                                    msgErro={
                                        touched
                                            ? touched.password_confirmation && errors.password_confirmation
                                            : errors.password_confirmation
                                    }
                                />
                            </div>
                        </div>
                        <div className="Modal-buttons">
                            <button className="Modal-button primary" onClick={onYes}>
                                Salvar
                            </button>
                            <button className="Modal-button outline" onClick={() => (onNo ? onNo() : _close())}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ModalChangePassword
