function getVehicleIcon(type: 'bike' | 'moto' | 'car' | 'van' | 'truck') {
    switch (type) {
        case 'bike':
            return 'bicycle'

        case 'moto':
            return 'motorcycle'
        case 'car':
            return 'car'
        case 'van':
            return 'shuttle-van'
        case 'truck':
            return 'truck'
    }
}
function getVehicleName(type: 'bike' | 'moto' | 'car' | 'van' | 'truck') {
    switch (type) {
        case 'bike':
            return 'Bicicleta'

        case 'moto':
            return 'Moto'
        case 'car':
            return 'Carro'
        case 'van':
            return 'Van'
        case 'truck':
            return 'truck'
    }
}

export { getVehicleIcon, getVehicleName }
