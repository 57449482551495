import './style.scss'

const RouteConfirmationModal = ({ visible, closeClick, createClick }) => {
    return (
        <div className="route-confirmation-modal" style={{ display: visible ? 'flex' : 'none' }}>
            <div className="modal-content">
                <div className="title-row">Confirmar Rota</div>
                <div className="ask-phrase">Tem certeza que deseja criar esta rota?</div>

                <div className="button-row">
                    <div className="button-item outline" onClick={closeClick}>
                        Fechar
                    </div>
                    <div className="button-item" onClick={createClick}>
                        Criar Rota
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RouteConfirmationModal
