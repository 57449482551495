import { forwardRef, useCallback, useState, useImperativeHandle } from 'react'

import { useEventListener } from 'hooks'

import {
    RefreshIcon,
    ContentTitle,
    ContainerRefresh,
    TextRefresh,
    BackgroundModal,
    BoxContent,
    Container,
    FooterModal,
    Content,
    CloseButton,
    CloseContainer,
    HeaderModal,
} from './lateral-modal-base.styled'

type LateralModalBase = {
    show?(): void
    close?(): void
}

interface LateralModalBaseProps {
    headerComponent?: React.ReactNode
    footerComponent?: React.ReactNode
    title?: string
    onClose?(): boolean | void | Promise<boolean | void>
    zIndex?: number
    children?: React.ReactNode
    refresh?(): void
}
// eslint-disable-next-line react/display-name
const LateralModalBase = forwardRef<LateralModalBase, LateralModalBaseProps>(
    ({ children, headerComponent, title, footerComponent, zIndex, refresh, onClose }, ref) => {
        const [isEffectActive, setIsEffectActive] = useState(false)
        const [isDisplayed, setIsDisplayed] = useState(false)

        const _show = useCallback(() => {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        }, [])

        const _close = useCallback(() => {
            if (onClose) {
                const result = onClose()

                if (result === false) {
                    return
                }
            }

            document.body.style.overflow = 'auto'
            setIsEffectActive(false)

            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }, [onClose])

        useImperativeHandle(
            ref,
            () => ({
                show: _show,
                close: _close,
            }),
            [_show, _close]
        )

        const handleEscPress = useCallback(
            ({ code }) => {
                if (code === 'Escape') {
                    _close()
                }
            },
            [_close]
        )

        useEventListener('keydown', handleEscPress)

        return (
            <Container isDisplayed={isDisplayed} zIndex={zIndex}>
                <BoxContent isEffectActive={isEffectActive}>
                    <HeaderModal>
                        <CloseContainer onClick={_close}>
                            <CloseButton />
                        </CloseContainer>
                        {!!title && <ContentTitle>{title}</ContentTitle>}
                        {headerComponent}
                    </HeaderModal>
                    {refresh && (
                        <ContainerRefresh onClick={refresh}>
                            <RefreshIcon />
                            <TextRefresh>Atualizar</TextRefresh>
                        </ContainerRefresh>
                    )}
                    <Content className="content-modal">{children}</Content>
                    {!!footerComponent && <FooterModal>{footerComponent}</FooterModal>}
                </BoxContent>
                <BackgroundModal isEffectActive={isEffectActive} onClick={_close} />
            </Container>
        )
    }
)

export default LateralModalBase
