import { ChartData } from 'chart.js'

import { formatCurrency } from 'helpers/currency'
import { ChartObject } from 'types'

export function getPaymentOrdersChart(paymentForms: ChartData<any>): ChartObject {
    return {
        data: paymentForms,
        // callbacks: {
        //     label: (tooltipItem: any) => {
        //         const { percentage, total } = paymentForms[tooltipItem.index as number]

        //         return `${formatCurrency(total)} - ${percentage?.toFixed(2)}%`
        //     },
        // },
    }
}
