import { forwardRef, memo, useImperativeHandle } from 'react'

import { LateralModalBase } from 'modals'

import { DefaultButton } from 'components/default-button/default-button'

import { Sales } from 'types/sales'

import { useSalesDetailModalController } from './sales-detail-modal.controller'
import { ButtonRow, ContentContainer, OutsideContainer, TextButton } from './sales-detail-modal.styled'
import { OrderDetails } from './widget/order-details'
import { ReimbursementContent } from './widget/reimbursement-content'

interface SalesDetailModal {
    show(sale: Sales): void
    showById(id: number): void
    close(): void
}

const SalesDetailModal = memo(
    forwardRef((_, ref) => {
        const {
            tab,
            order,
            lateralModalBaseRef,
            values,
            getFieldProps,
            setValues,
            resetForm,
            _handleChangeTab,
            _getDetails,
            _handleSubmitReimbursement,
        } = useSalesDetailModalController()

        useImperativeHandle(
            ref,
            () => ({
                show: async (sale: Sales) => {
                    _handleChangeTab('details')()
                    await _getDetails(sale.id)
                    resetForm()
                    lateralModalBaseRef.current?.show()
                },
                showById: async (id: number) => {
                    _handleChangeTab('details')()
                    await _getDetails(id)
                    resetForm()

                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            []
        )

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={'Detalhes do Pedido'}>
                <OutsideContainer>
                    <ContentContainer>
                        {tab === 'reimbursement' ? (
                            <ReimbursementContent
                                values={values}
                                orderItems={order.items}
                                getFieldProps={getFieldProps}
                            />
                        ) : (
                            <OrderDetails order={order} />
                        )}
                    </ContentContainer>
                    {/* <ButtonRow justify="end">
                        {tab === 'reimbursement' ? (
                            <>
                                <TextButton onClick={_handleChangeTab('details')}>Cancelar</TextButton>
                                <DefaultButton
                                    title="Estornar"
                                    widthFitContainer
                                    onClick={_handleSubmitReimbursement}
                                />
                            </>
                        ) : (
                            <DefaultButton
                                title="Estornar pedido"
                                variant="danger"
                                widthFitContainer
                                onClick={_handleChangeTab('reimbursement')}
                            />
                        )}
                    </ButtonRow> */}
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { SalesDetailModal }
