import styled, { css } from 'styled-components'

const Label = styled.div`
    font-size: 14px;
    font-weight: 500;
    width: auto;
    color: black;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
`

const ContainerDayWeek = styled.div`
    user-select: none;
    display: flex;
    flex-wrap: nowrap;
`

const BoxDayWeek = styled.div<{ active?: boolean }>`
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 2px;
    &:last-child {
        margin-right: 0;
    }
    border: 1px solid #666;
    ${({ active }) =>
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.primary};
            border: 1px solid ${({ theme }) => theme.colors.primary};
            color: #fff;
        `}

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary + '60'};
    }
`

export { BoxDayWeek, ContainerDayWeek, Label }
