import { faMotorcycle, faBicycle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const PopupContainer = styled.div<{ areaColor?: string }>`
    border-radius: 4px;
    background-color: #f1f5fe;
    padding: 15px;
    min-width: 200px;
    border: 1px solid #ccc;
    border-top: 6px solid;
    border-top-color: ${({ theme, areaColor }) => (areaColor ? areaColor : theme.colors.primary)};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
`

export const PopupTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`

export const ChargesContainer = styled.div`
    margin-top: 10px;
`

export const ChargeItem = styled.div<{ areacolor?: string }>`
    margin-bottom: 15px;
`

export const ChargeTitle = styled.div`
    font-size: 16px;
`

export const ChargeValuesRow = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
`

export const BikeIcon = styled(FontAwesomeIcon).attrs({
    icon: faBicycle,
})`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
`

export const MotoIcon = styled(FontAwesomeIcon).attrs({
    icon: faMotorcycle,
})`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
`

export const ValueItem = styled.div`
    margin-right: 30px;
    display: flex;
    align-items: center;
`

export const ValueText = styled.div`
    margin-left: 10px;
`

export const ShiftContainer = styled.div`
    margin-bottom: 20px;
`

export const ShiftTitle = styled.div`
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    span {
        &:not(:last-child) {
            &::after {
                content: ', ';
            }
        }
    }
`
