import styled from 'styled-components'

import SourceMapPin from 'assets/images/map-pin.png'

const FinishRoutes = styled.div`
    color: ${({ theme }) => theme.colors.errorMessage};
    text-decoration: underline;
    font-size: 8px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
`
const Container = styled.div<{ color?: string }>`
    border-left: 5px solid ${({ color }) => (color ? color : '#c1c1c1')};
`

const SeeMore = styled.div`
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    padding: 4px;
    align-self: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`

const Collect = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    .collect {
        span {
            font-weight: bold;
        }
    }
`

const MapImage = styled.img.attrs({ src: SourceMapPin })`
    width: 16px;
    height: 16px;
    margin-right: 2px;
    object-fit: contain;
`

export { FinishRoutes, Container, SeeMore, Collect, MapImage }
