/**
 *
 * @param {number} minutes
 * @param {function} statusColorFunction
 */

const getStatusMarker = (minutes, configs, statusColorFunction) => {
    // return 'in-time' | 'delayed' | 'late' | 'out-of-rote'
    return statusColorFunction(minutes, configs) || 'out-of-rote'
}

export { getStatusMarker }
