import { useState } from 'react'

import cep, { CEP } from 'cep-promise'

interface HookParams {
    beforeSearch?(): void
    afterSearch?(): void
    onSuccess?(response: CEP): void
    onError?(error: {
        name: string
        message: string
        type: string
        errors: Array<{
            message: string
            service: string
        }>
    }): void
}

export function useCEPSearch({ beforeSearch, afterSearch, onSuccess, onError }: HookParams): {
    loading: boolean
    getAddressByCEP: (zipcode: string) => void
} {
    const [loading, setLoading] = useState(false)

    const getAddressByCEP = async (zipcode: string) => {
        try {
            if (zipcode.length !== 8) return
            beforeSearch && beforeSearch()
            setLoading(true)

            const response = await cep(zipcode)

            onSuccess && onSuccess(response)
        } catch (error) {
            onError && onError(error)
        } finally {
            setLoading(false)
            afterSearch && afterSearch()
        }
    }

    return { loading, getAddressByCEP }
}
