import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IPicker } from 'types/picker'

import {
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    InputsRow,
    StatusContainer,
    ButtonRow,
    ConfirmButton,
    HeaderLabel,
    Row,
    TextButton,
} from './picker-change-password-modal.styled'

export type PickerChangePasswordModalRef = {
    show(): void
    close(): void
    setPicker?(picker: IPicker): void
}

type Props = {
    onClose?(isRefresh: boolean): void
}

type IForm = {
    password: string
    password_confirmation: string
}

const atLeastOneDigit = /.*\d.*/
const atLeastOneLowercaseCharacter = /.*[a-z].*/
const atLeastOneUppercaseCharacter = /.*[A-Z].*/

const PickerChangePasswordModal = memo(
    forwardRef<PickerChangePasswordModalRef, Props>(({ onClose }, ref) => {
        const { setSuccessModal, setErrorModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [picker, setPicker] = useState<IPicker>()

        const { isSubmitting, touched, errors, resetForm, getFieldProps, handleSubmit } = useFormik<IForm>({
            initialValues: {
                password: '',
                password_confirmation: '',
            },
            validationSchema: Yup.object().shape({
                password: Yup.string()
                    .trim()
                    .min(8, 'Senha deve ter no mínimo 8 caracteres')
                    .required('Preencha com sua Nova senha')
                    .matches(atLeastOneDigit, 'Deve conter um número')
                    .matches(atLeastOneLowercaseCharacter, 'Deve conter uma letra mínuscula')
                    .matches(atLeastOneUppercaseCharacter, 'Deve conter uma letra maiúscula'),
                password_confirmation: Yup.string()
                    .trim()
                    .oneOf([Yup.ref('password'), ''], 'Senhas não correspondem')
                    .required('Confirme a Nova senha'),
            }),
            onSubmit: async values => {
                try {
                    await api.put(`/painel/pickers/${picker.id}`, values)

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: `Senha atualizada com sucesso`,
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: `Houve um erro ao atualizar senha`,
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setPicker(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setPicker: (picker: IPicker) => {
                    setPicker(picker)
                },
            }),
            []
        )

        const modalTitle = useMemo(() => {
            return `Atualização de Senha para Picker`
        }, [])

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title={modalTitle}
                headerComponent={
                    <HeaderLabel>
                        Picker: {picker?.first_name} {picker?.last_name}
                    </HeaderLabel>
                }
            >
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    type="password"
                                    labelText="Senha"
                                    inputProps={getFieldProps('password')}
                                    errorMessage={touched.password && errors.password}
                                />
                            </InputContainer>
                            <InputContainer hasMargin>
                                <InputItem
                                    type="password"
                                    labelText="Confirmação de Senha"
                                    inputProps={getFieldProps('password_confirmation')}
                                    errorMessage={touched.password_confirmation && errors.password_confirmation}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{'Salvar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { PickerChangePasswordModal }
