import { useEffect, useState } from 'react'
import { Layer, Source } from 'react-mapbox-gl'

import { featureCollection, lineString } from '@turf/helpers'
import { computeDestinationPoint } from 'geolib'

import CheckboxInput from 'components/checkbox-input'

import colors from '../../../themes/colors'

// import { ContainerControl } from './wind-rose-lines.styled'
const WindRoseLines = ({ id, center, mapControls = false }) => {
    // const { mall, setMall } = useAuth()

    const [linesVisible, setLinesVisible] = useState(true)
    // const [routeAutomatic, setRouteAutomatic] = useState(!!mall?.configs?.route_automatic?.status)

    useEffect(() => {
        const linesVisibleStorage = localStorage.getItem('@extranet.logaroo:lines-visible')
        if (linesVisibleStorage) {
            setLinesVisible(linesVisibleStorage === 'true' ? true : false)
        }
    }, [])

    // const _toggleRouteAutomatic = useCallback(
    //     async active => {
    //         if (!mall?.configs?.route_automatic) {
    //             return
    //         }
    //         setRouteAutomatic(active)
    //         try {
    //             const { data } = await api.put(`/painel/mall/${mall.id}`, {
    //                 configs: {
    //                     route_automatic: {
    //                         ...mall.configs.route_automatic,
    //                         status: active,
    //                     },
    //                 },
    //             })
    //             setMall(data)
    //         } catch (error) {
    //             setRouteAutomatic(!active)
    //         }
    //     },
    //     [mall?.id, mall?.configs?.route_automatic, setMall]
    // )

    function handleLinesVisibleChange(checked) {
        setLinesVisible(checked)
        localStorage.setItem('@extranet.logaroo:lines-visible', checked)
    }

    const geoJsonSource = {
        type: 'geojson',
        data: featureCollection(
            [...Array(16)].map((_item, index) => {
                const bearing = index * 22.5

                const { latitude, longitude } = computeDestinationPoint(center, 30000, bearing)

                return lineString([center, [longitude, latitude]])
            })
        ),
    }

    return center?.length ? (
        <>
            {mapControls && (
                <>
                    <div className="map-controls-container" style={{ top: '140px' }}>
                        <CheckboxInput
                            label="Linhas de roteirização"
                            checked={linesVisible}
                            onChange={({ target: { checked } }) => handleLinesVisibleChange(checked)}
                        />
                    </div>
                    {/* <ContainerControl active={routeAutomatic} style={{ top: '200px' }}>
                        <CheckboxInput
                            label={`Roteirização automática`}
                            checked={routeAutomatic}
                            onChange={({ target: { checked } }) => _toggleRouteAutomatic(!!checked)}
                        />
                    </ContainerControl> */}
                </>
            )}

            <Source id={id} geoJsonSource={geoJsonSource} />
            <Layer
                type="line"
                id="lines-wind-rose"
                layout={{
                    visibility: linesVisible ? 'visible' : 'none',
                }}
                paint={{
                    'line-color': colors.gray_color,
                    'line-width': 1,
                }}
                sourceId={id}
            />
        </>
    ) : null
}

export default WindRoseLines
