import styled from 'styled-components'

const Container = styled.div`
    box-sizing: border-box;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const CanvasStyled = styled.canvas``

export { CanvasStyled, Container }
