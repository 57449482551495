import { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router'

import { ModalRouteFinish, ModalRouteFinishRef } from 'modals/modal-route-cancel/modal-route-cancel'

import EmptyMessage from 'components/empty-message/empty-message'
import Loader from 'components/modal-loading'

import { useUI } from 'contexts'
import { formatPhone, getStatusOrder, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Route } from 'types'

import {
    ContentTitle,
    RoutesInProgressModalBackground,
    RoutesInProgressModalBox,
    RoutesInProgressModalContainer,
    RoutesInProgressModalContent,
    CloseButton,
    CloseContainer,
    PersonalDataHeader,
    RouteInProgressItem,
    BikerAvatar,
    BikerAvatarContainer,
    BikerBadge,
    BadgeIcon,
    ContainerButton,
    FinishRoutes,
    BikeIcon,
    RouteTextContainer,
    RouteId,
    RouteBikerName,
    RouteOrders,
    OrderItem,
    DetailsButton,
} from './routes-in-progress-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
    refresh?(): void
    data: Route[]
}

const RoutesInProgressModal: React.FC<Props> = ({ isActive, closeClick, data, refresh }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const { setSuccessModal, setErrorModal } = useUI()
    const { basePath } = useAuth()
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const modalRouteFinishRef = useRef<ModalRouteFinishRef>(null)

    useEffect(() => {
        const mobileMenuBox = document.getElementById('menuContainer')
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            const buttonZoomIn = document.getElementById('zoomIn')
            mobileMenuBox.style.zIndex = 'unset'
            if (buttonZoomIn) {
                buttonZoomIn.parentElement.style.zIndex = '9'
            }
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            mobileMenuBox.style.zIndex = '40'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const _finishRoute = useCallback(
        async (route_id: number, reason: string) => {
            setLoading(true)

            try {
                await api.post(`/painel/route/finish`, { route_id, reason })

                setSuccessModal({
                    title: 'Sucesso',
                    subtitle: 'Rota finalizada com sucesso!',
                })
                if (refresh) {
                    refresh()
                }
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: showErrors(error),
                })
            }
            setLoading(false)
        },
        [setErrorModal, setSuccessModal, refresh]
    )

    const _confirmFinish = useCallback(
        (route: Route) => {
            modalRouteFinishRef.current?.show({
                route,
                onSelectReason: reason => {
                    _finishRoute(route.id, reason)
                },
            })
        },
        [_finishRoute]
    )

    return (
        <>
            <RoutesInProgressModalContainer isDisplayed={isDisplayed}>
                <RoutesInProgressModalBox isEffectActive={isEffectActive}>
                    <PersonalDataHeader>
                        <CloseContainer onClick={closeClick}>
                            <CloseButton />
                        </CloseContainer>
                        <ContentTitle>Rotas em andamento</ContentTitle>
                    </PersonalDataHeader>
                    <RoutesInProgressModalContent>
                        {data?.length > 0 ? (
                            <>
                                {data?.map((item, index) => (
                                    <RouteInProgressItem key={index}>
                                        <BikerAvatarContainer>
                                            <BikerBadge>
                                                {item?.biker.type === 1 ? <BadgeIcon /> : <BikeIcon />}
                                            </BikerBadge>
                                            <BikerAvatar src={item?.biker.avatar} />
                                        </BikerAvatarContainer>
                                        <RouteTextContainer>
                                            <RouteId>#{item?.id}</RouteId>
                                            <RouteBikerName>
                                                {item?.biker.first_name} {item?.biker.last_name}
                                            </RouteBikerName>
                                            <RouteId>{formatPhone(item?.biker?.cellphone)}</RouteId>
                                            <RouteOrders>
                                                {item?.orders.map((order, index) => (
                                                    <OrderItem
                                                        key={index}
                                                        onClick={() =>
                                                            history.push(`${basePath}/detalhes-pedido/${order?.id}`)
                                                        }
                                                    >
                                                        #{order?.id} - {order?.merchant.name}
                                                        <div>{getStatusOrder(order?.status)}</div>
                                                    </OrderItem>
                                                ))}
                                            </RouteOrders>
                                        </RouteTextContainer>
                                        <ContainerButton>
                                            <DetailsButton
                                                onClick={() => {
                                                    closeClick()
                                                    history.push(`${basePath}/detalhes-rota/${item.id}`)
                                                }}
                                            >
                                                Ver detalhes
                                            </DetailsButton>
                                            <FinishRoutes onClick={() => _confirmFinish(item)}>
                                                Finalizar Rota
                                            </FinishRoutes>
                                        </ContainerButton>
                                    </RouteInProgressItem>
                                ))}
                            </>
                        ) : (
                            <EmptyMessage icon="biking">Carregando rotas em andamento...</EmptyMessage>
                        )}
                    </RoutesInProgressModalContent>
                </RoutesInProgressModalBox>
                <RoutesInProgressModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
                <Loader visible={loading} />
            </RoutesInProgressModalContainer>
            <ModalRouteFinish ref={modalRouteFinishRef} />
        </>
    )
}

export default RoutesInProgressModal
