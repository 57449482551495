import { Component } from 'react'

import TopTagItem from '../top-tags/top-tag-item/top-tag-item'
import './route-creation-top-row.scss'

export default class RouteCreationTopRow extends Component {
    render() {
        return (
            <div className="route-creation-top-row-container">
                <div className="row-item">
                    <div className="left-column">
                        <div className="top-row-title">Roteirização</div>
                    </div>
                    <div className="right-column">
                        <TopTagItem iconName="cube" value={this.props.ordersToCollect} label="Pedidos prontos" />

                        <div onClick={this.props.inQueueClick}>
                            <TopTagItem
                                iconName="users"
                                value={this.props.deliverersQueue}
                                label={this.props.deliverersQueue === 1 ? 'Entregador' : 'Entregadores'}
                                hasLink
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
