import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { DefaultButton } from 'components/default-button/default-button'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const Container = styled.div``

const Content = styled.div`
    padding: 1.25rem;
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`

const ActionButton = styled(DefaultButton)`
    //width: 2rem;
    //height: 2rem;

    padding: 0.25rem;

    transition: all 500ms;
    svg {
        margin: 0;
    }
`

const EditIcon = styled(FontAwesomeIcon).attrs({
    icon: 'edit',
})`
    svg {
        margin: 0rem;
    }

    color: ${({ theme }) => theme.colors.white};
`
const ChangePassIcon = styled(FontAwesomeIcon).attrs({
    icon: 'lock',
})`
    color: ${({ theme }) => theme.colors.white};
`

const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
`

export { Container, Content, ButtonRow, ActionButton, DeleteIcon, EditIcon, ChangePassIcon }
