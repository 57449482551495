import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import api from 'services/api'
import { Mall } from 'types'

import { useEventListener } from '../../hooks'
import { Creators as AuthActions } from '../../store/reducers/auth'

import {
    ButtonRow,
    CheckBoxContainer,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    InputRow,
    OutsideContainer,
    Row,
    TextButton,
} from './modal-create-admin.styled'

export type ModalCreateAdminRef = {
    show?(): void
    close?(): void
    setUser(user: IForm, isUserData?: boolean): void
}

type Props = {
    onRemove?(id: number): () => void
    onClose?(isRefresh?: boolean): void
}

type Status = 0 | 1

type IForm = {
    id?: number
    role: 'admin' | 'manager'
    malls?: number[]
    first_name: string
    last_name: string
    email: string
    password?: string
    password_confirmation?: string
    status: Status
}

const roleOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'Gestor', value: 'manager' },
]

const statusOptions = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 },
]

const atLeastOneDigit = /.*\d.*/
const atLeastOneLowercaseCharacter = /.*[a-z].*/
const atLeastOneUppercaseCharacter = /.*[A-Z].*/
// eslint-disable-next-line react/display-name

// isActive, onClose, title, subtitle, user, malls, setUserData, errors, isCreation, onYes, onNo

const ModalCreateAdmin = memo(
    forwardRef<ModalCreateAdminRef, Props>(({ onClose, onRemove }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()

        const dispatch = useDispatch()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [user, setUser] = useState<IForm>(null)
        const [isUserData, setIsUserData] = useState<boolean>(false)
        const [malls, setMalls] = useState<Mall[]>(null)
        const [isLoading, setIsLoading] = useState<boolean>()

        const {
            isSubmitting,
            values,
            touched,
            errors,
            getFieldProps,
            setFieldValue,
            setValues,
            resetForm,
            handleSubmit,
        } = useFormik<IForm>({
            initialValues: {
                role: 'admin',
                first_name: '',
                last_name: '',
                email: '',
                malls: [],
                password: '',
                password_confirmation: '',
                status: 1,
            },
            validationSchema: Yup.object().shape({
                first_name: Yup.string().trim().required('Nome é um campo obrigatório'),
                last_name: Yup.string().trim().required('Sobrenome é um campo obrigatório'),
                role: Yup.string().trim().required('A função do usuário é obrigatória'),
                email: Yup.string().trim().email('Insira um e-mail válido'),
                password: Yup.string()
                    .trim()
                    .min(8, 'Senha deve ter no mínimo 8 caracteres')
                    .matches(atLeastOneDigit, 'Deve conter um número')
                    .matches(atLeastOneLowercaseCharacter, 'Deve conter uma letra mínuscula')
                    .matches(atLeastOneUppercaseCharacter, 'Deve conter uma letra maiúscula'),
                password_confirmation: Yup.string()
                    .trim()
                    .oneOf([Yup.ref('password'), ''], 'Senhas não correspondem'),
            }),
            onSubmit: async values => {
                setIsLoading(true)
                try {
                    if (values.id) {
                        const { data } = await api.put(`/painel/administrator/${values.id}`, values)
                        if (isUserData) {
                            dispatch(AuthActions.setUserData(data))
                        }
                    } else {
                        await api.post('/painel/administrator', values)
                    }

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: values.id
                            ? 'Administrador atualizado com sucesso!'
                            : 'Administrador criado com sucesso!',
                        singleButtonClick: () => _onClose(true),
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: 'Não foi possível salvar os dados do administrador. Tente novamente mais tarde.',
                    })
                }

                setIsLoading(false)
            },
        })

        const isEdit = useMemo(() => {
            return !!user
        }, [user])

        const modalTitle = useMemo(() => {
            return `${!isEdit ? 'Criar novo' : 'Editar'} Administrador`
        }, [isEdit])

        const isManager = useMemo(() => {
            return (values?.role || user?.role) === 'manager'
        }, [user, values])

        const handleEscPress = useCallback(({ code }) => {
            if (code === 'Escape') {
                lateralModalBaseRef.current?.close()
            }
        }, [])

        useEventListener('keydown', handleEscPress)

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    setIsUserData(false)
                    setUser(null)
                    resetForm()

                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setUser: (user: IForm, isUserData: boolean) => {
                    setIsUserData(isUserData)
                    setUser(user)
                    setValues(user)
                },
            }),
            []
        )

        const _onClose = useCallback(
            (isRefresh?: boolean) => {
                if (onClose) {
                    onClose(isRefresh)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        const _handleChangeMalls = useCallback(
            (mallId: number) => {
                return ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
                    if (checked) {
                        setFieldValue('malls', !values?.malls ? [mallId] : [...values?.malls, mallId])
                        return
                    }
                    setFieldValue(
                        'malls',
                        values?.malls?.filter(id => id !== mallId)
                    )
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [values]
        )

        const _handleRole = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (value === 'admin') {
                setFieldValue('malls', [])
            }

            setFieldValue('role', value)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const _handleChange = useCallback((field: string) => {
            return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(field, value)
            }
        }, [])

        const _getMalls = useCallback(async () => {
            try {
                setIsLoading(true)

                const { data } = await api.get('/painel/malls', {
                    params: { order_by: 'name', status: [1], per_page: 100 },
                })

                setMalls(data.items)
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }, [])

        useEffect(() => {
            if (isManager && !malls) {
                _getMalls()
            }
        }, [isManager, _getMalls, malls])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Função"
                                    options={roleOptions}
                                    inputProps={{
                                        ...getFieldProps('role'),
                                        onChange: _handleRole,
                                    }}
                                    errorMessage={touched.role && errors.role}
                                />
                            </InputContainer>
                            {isManager && malls && (
                                <InputContainer>
                                    <CheckBoxContainer>
                                        {malls?.map(mall => (
                                            <Checkbox
                                                key={mall.id}
                                                isSquared
                                                checkboxTitle={mall.name}
                                                inputProps={{
                                                    id: `store-${mall.id}`,
                                                    value: Number(values?.malls?.some(id => id === mall.id)),
                                                    onChange: _handleChangeMalls(mall.id),
                                                }}
                                            />
                                        ))}
                                    </CheckBoxContainer>
                                </InputContainer>
                            )}

                            <InputRow>
                                <InputContainer>
                                    <InputItem
                                        labelText="Nome"
                                        inputProps={{
                                            value: values.first_name,
                                            onChange: _handleChange('first_name'),
                                        }}
                                        errorMessage={touched.first_name && errors.first_name}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <InputItem
                                        labelText="Sobrenome"
                                        inputProps={{
                                            value: values.last_name,
                                            onChange: _handleChange('last_name'),
                                        }}
                                        errorMessage={touched.last_name && errors.last_name}
                                    />
                                </InputContainer>
                            </InputRow>

                            <InputContainer>
                                <InputItem
                                    labelText="E-mail"
                                    inputProps={{
                                        value: values.email,
                                        onChange: _handleChange('email'),
                                    }}
                                    errorMessage={touched.email && errors.email}
                                />
                            </InputContainer>

                            {!isEdit && (
                                <InputRow>
                                    <InputContainer>
                                        <InputItem
                                            type="password"
                                            labelText="Senha"
                                            inputProps={{
                                                value: values.password,
                                                onChange: _handleChange('password'),
                                            }}
                                            errorMessage={touched.password && errors.password}
                                        />
                                    </InputContainer>

                                    <InputContainer>
                                        <InputItem
                                            type="password"
                                            labelText="Confirmar de Senha"
                                            inputProps={{
                                                value: values.password_confirmation,
                                                onChange: _handleChange('password_confirmation'),
                                            }}
                                            errorMessage={touched.password_confirmation && errors.password_confirmation}
                                        />
                                    </InputContainer>
                                </InputRow>
                            )}

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={statusOptions}
                                    inputProps={{
                                        value: values.status,
                                        onChange: _handleChange('status'),
                                    }}
                                    errorMessage={touched.status && errors.status}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>

                    <ButtonRow>
                        <div>
                            {isEdit && (
                                <TextButton isRemove onClick={onRemove(user.id)}>
                                    <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting || isLoading} />
            </LateralModalBase>
        )
    })
)

export { ModalCreateAdmin }
