import { useCallback } from 'react'

import TopRowDefault from 'components/top-row-default/top-row-default'

import { IntegrationLogaroo } from './contents/integration-logaroo/integration-logaroo'
import { IntegrationMercadoo } from './contents/integration-mercadoo/integration-mercadoo'
import { IntegrationOrthers } from './contents/integration-orthers/integration-orthers'
import { IntegrationPlooga } from './contents/integration-plooga/integration-plooga'

import './style.scss'

const IntegratorsPage = ({ history, match: { params } }) => {
    const _getContent = useCallback(() => {
        if (params?.integration_type) {
            const type = params.integration_type

            if (type === 'logaroo') {
                return <IntegrationLogaroo />
            }

            if (type === 'mercadoo') {
                return <IntegrationMercadoo />
            }
            return <IntegrationOrthers type={type} />
        }
        return <IntegrationPlooga />
    }, [params?.integration_type])

    return (
        <div className="page-container integrators-page">
            <TopRowDefault
                onBackButtonClick={() =>
                    params?.integration_type ? history.push('/painel-malls/integracoes') : history.push('/painel-malls')
                }
                title="Gerenciar Integrações"
            />
            {_getContent()}
        </div>
    )
}

export default IntegratorsPage
