import { Layer, Source } from 'react-mapbox-gl'

const GeoJsonPolygon = ({ id, geoJsonData }) => {
    return geoJsonData ? (
        <>
            <Source
                id={id}
                geoJsonSource={{
                    type: 'geojson',
                    data: geoJsonData,
                }}
            />
            <Layer
                type="fill"
                id={`fill-${id}`}
                paint={{
                    'fill-color': ['get', 'color'],
                    'fill-opacity': ['get', 'opacity'],
                }}
                sourceId={id}
            />
            <Layer
                type="line"
                id={`line-${id}`}
                paint={{
                    'line-color': ['get', 'color'],
                    'line-width': 1,
                }}
                sourceId={id}
            />
        </>
    ) : null
}

export default GeoJsonPolygon
