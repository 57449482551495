import { useCallback, useContext, useEffect, useRef, useState, useLayoutEffect, useMemo } from 'react'
import { DragDropContext, DraggableLocation, DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { RouteComponentProps, useLocation } from 'react-router-dom'

import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvailableDeliverymenModal } from 'modals'

import { RefreshButton } from 'components/_common'
import ButtonForm from 'components/button-form'
import CheckboxInput from 'components/checkbox-input'
import EmptyMessage from 'components/empty-message/empty-message'
import { MirrorMap, GlobalMapContext } from 'components/global-map'
import { CircleLines, PolygonFill, RouteOrdersMarkers, WindRoseLines } from 'components/mapbox-view'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import OrderCard from 'components/order-card/order-card'
import OrdersCardListing from 'components/orders-card-listing/orders-card-listing'
import RouteConfirmationModal from 'components/route-confirmation-modal/route-confirmation-modal'
import RouteCreatedModal from 'components/route-created-modal/route-created-modal'
import RouteCreationTopRow from 'components/route-creation-top-row/route-creation-top-row'
import RouteDetailSteps from 'components/route-detail-steps/route-detail-steps'
import { SearchDeliverersListing } from 'components/search-deliverer-modal/search-deliverer-modal'
import { DeliverersQueueListing } from 'components/select-deliverer-modal/select-deliverer-modal'
import SelectInput from 'components/select-input'
import { Text } from 'components/text/text'

import { useUI } from 'contexts'
import { DistrictContextProvider } from 'contexts/district-context'
import { getSortedItems, getStatusColorReadyDeliver, setSearchParams, showErrors } from 'helpers'
import { useAgentsAvailable, useAuth } from 'hooks'
import { useOrdersInProgress } from 'hooks/orders'
import api, { api2 } from 'services/api'
import { OrdersInProgressActions } from 'store/reducers/ordersInProgress'
import { IAgent, Order, Store } from 'types'
import { findRoute } from 'utils/find-route'

import { Footer, Row } from './route-creation.styled'

import './style.scss'

const RouteCreation: React.FC<RouteComponentProps> = ({ history }) => {
    const { setMapBoxContentComponent, setContentComponent } = useContext(GlobalMapContext)
    const location = useLocation<{ initial: Order | undefined }>()
    const dispatch = useDispatch()
    const { basePath, mall } = useAuth()
    const { setConfirmationModal, setSnackbar, setErrorModal } = useUI()
    const { orders, routeOrders, scoreboard, revalidateOrders } = useOrdersInProgress()
    const { agents, ...agentsAvailable } = useAgentsAvailable()

    const modalMessageRef = useRef<ModalMessageRef>(null)
    const modalDeleteRef = useRef<ModalConfirmationRef>(null)
    const availableDeliverymenModalRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [loadingQueue, setLoadingQueue] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [stores, setStores] = useState<Store[]>([])
    const [deliveryman, setDeliveryman] = useState<IAgent | null>(null)
    const [routeType, setRouteType] = useState<'express' | 'chain'>('express')
    const [hasTaxExpress, setHasTaxExpress] = useState(false)
    const [fixedDeliveryOrder, setFixedDeliveryOrder] = useState(true)
    const [geofenceEnabled, setGeofenceEnabled] = useState(true)
    const [applyRateOnFail, setApplyRateOnFail] = useState(true)
    const [enableIfoodCode, setEnableIfoodCode] = useState(true)
    const [requiredIfoodCode, setRequiredIfoodCode] = useState(true)
    const [filter, setFilter] = useState({
        store_id: '',
        order_by: '',
    })
    const [clickedOrder, setClickedOrder] = useState<number | null>(null)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [removeQueueData, setRemoveQueueData] = useState<IAgent>()
    const [isFix, setFix] = useState<boolean>(false)
    const [ordersHasReturn, setOrdersHasReturn] = useState<number[]>([])
    const [neighborhoodSelected, setNeighborhoodSelected] = useState<string[]>([])
    const [coverageAreas, setCoverageAreas] = useState({ items: [] })
    const [confirmOfferVisible, setConfirmOfferVisible] = useState(false)
    const [routeCreatedVisible, setRouteCreatedVisible] = useState(false)
    const [searchOrders, setSearchOrders] = useState(null)
    const [modalMessage, setModalMessage] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const neighborhoods = useMemo(() => {
        const collects = orders?.toCollect.items || []

        const items: string[] = []

        for (let i = 0; i < collects.length; i++) {
            const order = collects[i]
            const exits = items.find(
                x => x.toLocaleLowerCase() === (order.address.neighborhood || '').toLocaleLowerCase()
            )
            if (!exits) {
                items.push(order.address.neighborhood)
            }
        }

        return items
    }, [orders?.toCollect])

    const _onNeighborhoodSelecte = useCallback((neighborhoods: string[]) => {
        setNeighborhoodSelected(neighborhoods)
    }, [])

    const insertOrder = useCallback(
        (order: Order) => {
            if (routeOrders.some(item => item.id === order.id)) return

            dispatch(OrdersInProgressActions.setRouteOrders([...routeOrders, order]))
        },
        [routeOrders, dispatch]
    )

    useEffect(() => {
        const initial = location?.state?.initial

        if (location?.state?.initial && !routeOrders.some(item => item.id === initial?.id)) {
            insertOrder(location.state.initial)
            delete location.state.initial
        }
    }, [location, insertOrder, routeOrders])

    const getCoverageAreas = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get(`painel/payment-areas`, {
                params: { per_page: -1, mall_id: mall.id },
            })

            setCoverageAreas(data)
        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    const getStores = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/stores', {
                params: {
                    mall_id: mall.id,
                    order_by: 'name',
                },
            })

            setStores(data.items)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    function removeOrder(order: Order) {
        const newRoute = routeOrders.filter(item => item.id !== order.id)

        if (ordersHasReturn.some(id => id === order.id)) {
            setOrdersHasReturn(items => items.filter(id => id !== order.id))
        }

        if (!newRoute.length) {
            setDeliveryman(null)
        }

        dispatch(OrdersInProgressActions.setRouteOrders(newRoute))
    }

    function reorderRouteOrders(items: Order[], source: DraggableLocation, destination: DraggableLocation) {
        const reordered = Array.from(items)
        const sourceIndex = source.index

        const sourceObject = reordered[sourceIndex]

        reordered.splice(source.index, 1)
        reordered.splice(destination.index, 0, sourceObject)

        return reordered
    }

    function onDragEnd(result: DropResult) {
        const { source, destination } = result

        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return

        const reordered = reorderRouteOrders(routeOrders, source, destination)

        dispatch(OrdersInProgressActions.setRouteOrders(reordered))
    }

    async function createRoute(deliveryManId: number | null) {
        try {
            setLoadingSubmit(true)
            setConfirmOfferVisible(false)

            if (routeOrders.some(item => Number(item.status) === 0)) {
                setModalMessage({
                    title: 'Atenção',
                    message: `Não é possível criar uma rota com pedidos cancelados. Por favor, revise sua rota!`,
                })

                modalMessageRef.current?.openModal()
                return
            }

            await api.post('/painel/route', {
                mall_id: mall.id,
                biker_id: deliveryManId || null,
                orders: routeOrders.map(item => item.id),
                orders_has_return: ordersHasReturn.length > 0 ? ordersHasReturn : undefined,
                type: routeType,
                fix: isFix,
                has_express: hasTaxExpress,
                configs: {
                    fixed_order_enabled: fixedDeliveryOrder,
                    pay_fail_enabled: applyRateOnFail,
                    express_rate_enabled: hasTaxExpress,
                    geofence_enabled: geofenceEnabled,
                    ifood: {
                        collect: {
                            code: false,
                            required: false,
                        },
                        delivery: {
                            code: enableIfoodCode,
                            required: requiredIfoodCode,
                        },
                    },
                },
            })

            setRouteCreatedVisible(true)
            setDeliveryman(null)
            setFilter({ store_id: '', order_by: '' })
            setClickedOrder(null)

            dispatch(OrdersInProgressActions.setRouteOrders([]))
        } catch (error) {
            console.log('OFFER ROUTE ERROR:', error)
            const errorMessage = showErrors(error)

            setModalMessage({
                title: 'Alerta',
                message: errorMessage,
            })

            modalMessageRef.current?.openModal()

            if (/^\b(O pedido) #\d* (foi cancelado)/g.test(errorMessage.trim())) {
                const matchOrderIds = errorMessage.match(/#\d+/)

                if (!matchOrderIds) {
                    return
                }

                const orderIds = matchOrderIds.map(item => Number(item.replace('#', '')))

                dispatch(
                    OrdersInProgressActions.setRouteOrders(
                        routeOrders.map(item => (orderIds.includes(Number(item.id)) ? { ...item, status: '0' } : item))
                    )
                )
            }
        } finally {
            setLoadingSubmit(false)
        }
    }

    function onChangeOrdersHasReturn(orderId: number) {
        setOrdersHasReturn(items =>
            items.some(id => id === orderId) ? items.filter(id => id !== orderId) : [...items, orderId]
        )
    }

    const filteredOrders = useMemo(() => {
        let ordersList = []

        if (searchOrders) {
            const search = searchOrders.toLocaleLowerCase()
            ordersList = orders.toCollect.items.filter(
                item =>
                    (item.customer?.name || '').toLocaleLowerCase().includes(search) ||
                    (item.id || '').toString().includes(search) ||
                    (item.reference_id || '').toString().toLocaleLowerCase().includes(search)
            )
        } else {
            ordersList = orders.toCollect.items
        }

        const isOrderFromStore = (order: Order) =>
            filter.store_id && !isNaN(Number(filter?.store_id) as number)
                ? order.merchant.id.toString() === filter.store_id
                : true

        const items = getSortedItems(ordersList, filter.order_by)

        let filtered = items.filter(isOrderFromStore)

        if (neighborhoodSelected.length > 0) {
            filtered = filtered.filter(x => neighborhoodSelected.includes(x.address.neighborhood))
        }

        return filtered
    }, [filter, orders.toCollect, searchOrders, neighborhoodSelected])

    const insertAllOrders = useCallback(() => {
        const ids = routeOrders.map(x => x.id)
        const orders = filteredOrders.filter(x => !ids.includes(x.id))

        if (orders.length === 0) {
            setSnackbar({
                type: 'alert',
                message: 'Sem pedidos para adicionar',
            })
            return
        }

        setConfirmationModal({
            title: 'Adicionar Todos os Pedidos',
            subtitle: `Tem certeza de que deseja adicionar ${orders.length} pedidos nesta rota?`,
            leftButtonText: 'Não',
            rightButtonClick() {
                dispatch(OrdersInProgressActions.setRouteOrders([...routeOrders, ...orders]))
            },
            rightButtonText: 'Sim',
        })
    }, [routeOrders, filteredOrders, dispatch, setConfirmationModal, setSnackbar])

    const generateRoute = useCallback(() => {
        let orders = filteredOrders
        let orderOriginator

        if (routeOrders.length > 1) {
            setErrorModal({
                title: 'Atenção!',
                subtitle: 'Limpe a rota atual para poder sugerir uma nova',
            })
            return
        }
        if (routeOrders.length === 1) {
            orderOriginator = routeOrders[0]

            orders = filteredOrders.filter(x => x.id !== orderOriginator.id)
        }

        if (orders.length === 0) {
            setErrorModal({
                title: 'Atenção!',
                subtitle: 'Sem pedidos para calcular uma rota',
            })
            return
        }

        setConfirmationModal({
            title: 'Sugerir Rota',
            subtitle: `A sugestão de rota tem o limite de 2 pedidos por rota com a distância máxima de até 2km entre os pedidos. Esta operação pode levar um tempo maior dependendo da quantidade de pedidos, deseja continuar?`,
            leftButtonText: 'Não',
            rightButtonClick() {
                const locations = orders.map(x => ({ id: x.id, location: [x.address.lng, x.address.lat] })) as any[]
                let originatorFirst = false
                let originator

                if (orderOriginator) {
                    originatorFirst = true
                    originator = {
                        id: orderOriginator.id,
                        location: [orderOriginator.address.lng, orderOriginator.address.lat],
                    }
                } else {
                    originator = locations[0]
                }

                const mallLocation = [mall.address.lng, mall.address.lat]
                const result = findRoute(locations, mallLocation, {
                    originatorFirst,
                    MaxDistanceBetweenOrders: 2,
                    MaxOrdersPerRoute: 2,
                    originator: originator,
                    returnToBase: false,
                })
                const avaliableOrders = [...filteredOrders]
                if (orderOriginator) {
                    avaliableOrders.push(orderOriginator)
                }

                dispatch(
                    OrdersInProgressActions.setRouteOrders(
                        result.map(id => avaliableOrders.find(e => e.id === id)).filter(x => !!x)
                    )
                )
            },
            rightButtonText: 'Continuar',
        })
    }, [routeOrders, filteredOrders, dispatch, setErrorModal, setConfirmationModal, mall])

    function setModalBikersData() {
        availableDeliverymenModalRef.current?.show()
    }

    function setRemoveFromQueue(item: IAgent) {
        availableDeliverymenModalRef.current?.close()
        setRemoveQueueData(item)
        modalDeleteRef.current?.openModal()
    }

    async function removeQueuePosition() {
        try {
            setLoadingQueue(true)
            availableDeliverymenModalRef.current?.show()

            await api2.delete(`/malls/${mall.slug}/kick`, {
                data: { agent: removeQueueData.id },
            })
        } catch (error) {
            console.log({ error })
        } finally {
            setLoadingQueue(false)
        }
    }

    function setFilterParam(key: string, value: string) {
        if (key === 'store_id') {
            setSearchParams({ store: value }, location, history)
        }
        setFilter({ ...filter, [key]: value })
    }

    const _refreshInfo = useCallback(async () => {
        setIsRefreshing(true)

        await revalidateOrders()
        if (agentsAvailable?.revalidateAgents) {
            await agentsAvailable.revalidateAgents()
        }
        await getStores()
        await getCoverageAreas()

        setIsRefreshing(false)
    }, [revalidateOrders, getStores, getCoverageAreas, agentsAvailable?.revalidateAgents])

    const _renderMapContent = useCallback(() => {
        return (
            <>
                <RouteOrdersMarkers
                    configs={mall.configs}
                    ordersToRoute={filteredOrders}
                    routeOrders={routeOrders}
                    insertOnRoute={insertOrder}
                    seeDetails={(id: number) => history.push(`${basePath}/detalhes-pedido/${id}`)}
                    onOrderClick={(id: number) => (clickedOrder === id ? setClickedOrder(null) : setClickedOrder(id))}
                />
                <PolygonFill id="coverage-areas" mapControls areas={coverageAreas.items} />
                <CircleLines id="circle-lines" center={[mall.address?.lng, mall.address?.lat]} />
                <WindRoseLines id="wind-rose-lines" mapControls center={[mall.address?.lng, mall.address?.lat]} />
            </>
        )
    }, [mall, routeOrders, history, insertOrder, clickedOrder, coverageAreas, basePath, filteredOrders])

    const _renderFooter = useCallback(() => {
        return (
            <Footer>
                {stores.length > 1 && (
                    <div className="filter-container row-item">
                        <div className="filter-select">
                            <SelectInput
                                bgColor="transparent"
                                data={stores}
                                onChange={({ target: { value } }) => setFilterParam('store_id', value)}
                                value={filter.store_id}
                            />
                        </div>
                    </div>
                )}

                <div className="row-item">
                    <RefreshButton
                        icon={faSync}
                        isRefreshing={isRefreshing}
                        label="Atualizar informações"
                        onClick={_refreshInfo}
                        type="link"
                    />
                </div>
            </Footer>
        )
    }, [isRefreshing, stores, _refreshInfo])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
        setContentComponent(_renderFooter)
    }, [_renderMapContent, _renderFooter, setMapBoxContentComponent, setContentComponent])

    useEffect(() => {
        getStores()
    }, [getStores])

    useEffect(() => {
        getCoverageAreas()
    }, [getCoverageAreas])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
            setContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const query = new URLSearchParams(location.search)

        const store = query.get('store')
        if (store !== filter.store_id) {
            setFilterParam('store_id', store)
        }
    }, [location.search])

    useEffect(() => {
        setFixedDeliveryOrder(!!mall?.configs?.route?.fixed_order_enabled)
        setApplyRateOnFail(!!mall?.configs?.route?.pay_fail_enabled || !!mall?.configs?.route?.apply_rate_on_fail)
        setHasTaxExpress(!!mall?.configs?.route?.express_rate_enabled)
        setGeofenceEnabled(!!mall?.configs?.route?.geofence_enabled)
        setEnableIfoodCode(!!mall?.configs?.route?.ifood?.delivery?.code)
        setRequiredIfoodCode(!!mall?.configs?.route?.ifood?.delivery?.required)
    }, [mall])

    return (
        <div className="page-container route-creation-page">
            <ModalMessage
                ref={modalMessageRef}
                title={modalMessage.title}
                message={modalMessage.message}
                onClose={modalMessage.onClose}
            />

            <AvailableDeliverymenModal
                ref={availableDeliverymenModalRef}
                loading={loadingQueue || agentsAvailable.loading}
                onRemoveClick={item => setRemoveFromQueue(item)}
            />

            <ModalConfirmation
                ref={modalDeleteRef}
                title="Remover da fila"
                message={`Deseja realmente remover ${removeQueueData?.name} da fila de entregadores?`}
                onYes={removeQueuePosition}
                onNo={() => {
                    modalDeleteRef.current?.closeModal()
                    availableDeliverymenModalRef.current?.show()
                }}
            />

            <ModalLoading visible={loadingSubmit} />

            <RouteCreationTopRow
                backClick={() => {
                    dispatch(OrdersInProgressActions.setRouteOrders([]))
                    history.goBack()
                }}
                ordersToCollect={scoreboard.to_collect}
                deliverersQueue={agents.totals}
                inQueueClick={() => setModalBikersData()}
            />

            <div className="content-container">
                <RouteConfirmationModal
                    visible={confirmOfferVisible}
                    closeClick={() => setConfirmOfferVisible(false)}
                    createClick={() => createRoute(deliveryman ? deliveryman?.id : null)}
                />

                <RouteCreatedModal
                    visible={routeCreatedVisible}
                    closeClick={() => {
                        setRouteCreatedVisible(false)
                        history.push(basePath)
                    }}
                    createClick={() => {
                        setConfirmOfferVisible(false)
                        setRouteCreatedVisible(false)
                        setDeliveryman(null)
                        dispatch(OrdersInProgressActions.setRouteOrders([]))
                    }}
                />

                <div className="left-column">
                    <DistrictContextProvider districts={neighborhoods} onChangeSelected={_onNeighborhoodSelecte}>
                        <OrdersCardListing
                            title="Pedidos"
                            filter={filter}
                            setFilterParam={setFilterParam}
                            loading={orders.toCollect.loading && !loading}
                            iconName="cube"
                            stores={stores}
                            onSearch={e => setSearchOrders(e)}
                        >
                            <Row>
                                <Text
                                    bold
                                    variant="primary"
                                    link
                                    uppercase
                                    dimensions="small"
                                    align="right"
                                    onClick={generateRoute}
                                >
                                    <FontAwesomeIcon icon="network-wired" /> Sugerir Rota
                                </Text>
                                <span> | </span>
                                <Text
                                    bold
                                    variant="primary"
                                    link
                                    uppercase
                                    dimensions="small"
                                    align="right"
                                    onClick={insertAllOrders}
                                >
                                    <FontAwesomeIcon icon="plus" /> Adicionar Todos
                                </Text>
                            </Row>
                            {filteredOrders?.length > 0 ? (
                                filteredOrders.map((item, index) => {
                                    return (
                                        <OrderCard
                                            key={index}
                                            orderNumber={item.reference_id || item.id}
                                            orderValue={item.total_price}
                                            collectName={item.delivery_origin?.name}
                                            paymentName={item.payment?.name}
                                            restaurantName={item.merchant.name}
                                            clientName={item.customer.name}
                                            neighborhood={item?.address?.neighborhood}
                                            street={item.address?.street}
                                            number={item.address?.number}
                                            distance={item.distance}
                                            totalTime={item.total_time.text}
                                            timeReady={item.time_ready.text}
                                            status={getStatusColorReadyDeliver(item.total_time.minutes, mall.configs)}
                                            routeClick={() => null}
                                            detailClick={() => history.push(`${basePath}/detalhes-pedido/${item.id}`)}
                                            onRouteCreation
                                            isReadyToDeliver
                                            isOnRouteOrders={routeOrders.some(element => element.id === item.id)}
                                            onAddClick={() => insertOrder(item)}
                                            clicked={item.id === clickedOrder}
                                        />
                                    )
                                })
                            ) : filteredOrders?.length === 0 && orders.toCollect.items?.length > 0 ? (
                                <EmptyMessage icon="cube">Nenhum pedido corresponde a filtragem</EmptyMessage>
                            ) : (
                                <EmptyMessage icon="cube">Não há pedidos prontos p/ coleta no momento</EmptyMessage>
                            )}
                            <div className="bottom" />
                        </OrdersCardListing>
                    </DistrictContextProvider>
                    <div className="orders-card-listing padding-top">
                        <div className="section-title">
                            <div className="icon">
                                <FontAwesomeIcon icon="boxes" />
                            </div>
                            <div className="text">Configure a rota</div>
                        </div>

                        <section className="route-creation-section">
                            <div className="text">1 - Escolha o tipo de rota</div>

                            <SelectInput
                                onChange={e => {
                                    const value = e.target.value
                                    setRouteType(value as any)
                                }}
                                value={routeType}
                                placeholder=""
                                noPlaceholder
                                bgColor="transparent"
                                data={[
                                    { label: 'Criar rota expressa', value: 'express' },
                                    { label: 'Criar rota corrente', value: 'chain' },
                                ]}
                            />
                            <div className="container-fix">
                                <CheckboxInput
                                    id="order_fix"
                                    label="Rota de correção"
                                    onChange={() => {
                                        setFix(state => !state)
                                    }}
                                    checked={isFix}
                                />
                            </div>
                        </section>

                        <section className="route-creation-section">
                            <div className="text">2 - Defina os pedidos da rota</div>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <RouteDetailSteps
                                    routeCreation
                                    droppable={'droppable'}
                                    mallName={`${mall.address.city.state.uf} - ${mall.name}`}
                                    mallAddress={`${mall.address?.street}, ${mall.address?.number}`}
                                    routeOrders={routeOrders || []}
                                    ordersHasReturn={ordersHasReturn}
                                    onChangeOrdersHasReturn={(id: number) => onChangeOrdersHasReturn(id)}
                                    textButton="Remover pedido"
                                    onRemoveClick={(order: Order) => removeOrder(order)}
                                />
                            </DragDropContext>
                        </section>

                        <section className="route-creation-section">
                            {isFix ? (
                                <SearchDeliverersListing
                                    title="3 - Defina o entregador"
                                    selectedBiker={deliveryman}
                                    setSelectedBiker={setDeliveryman}
                                />
                            ) : (
                                <DeliverersQueueListing
                                    title="3 - Defina o entregador"
                                    data={agents.items}
                                    selectedBiker={deliveryman}
                                    setSelectedBiker={setDeliveryman}
                                />
                            )}
                        </section>

                        {routeOrders.length > 0 && (
                            <>
                                <CheckboxInput
                                    id="fixed_order_enabled"
                                    label="Ordem fixa de entrega"
                                    onChange={() => setFixedDeliveryOrder(state => !state)}
                                    checked={fixedDeliveryOrder}
                                />
                                <CheckboxInput
                                    id="geofence_enabled"
                                    label="Habilitar cercas geográficas"
                                    onChange={() => setGeofenceEnabled(state => !state)}
                                    checked={geofenceEnabled}
                                />
                                <CheckboxInput
                                    id="pay_fail_enabled"
                                    label="Remunerar insucesso"
                                    onChange={() => setApplyRateOnFail(state => !state)}
                                    checked={applyRateOnFail}
                                />
                                <CheckboxInput
                                    id="has_express"
                                    label="Adicionar tarifa expressa para primeira entrega?"
                                    onChange={() => setHasTaxExpress(!hasTaxExpress)}
                                    checked={hasTaxExpress}
                                />
                                <CheckboxInput
                                    id="ifood-delivery-code"
                                    label="Ifood: Solicitar código"
                                    onChange={() => setEnableIfoodCode(state => !state)}
                                    checked={enableIfoodCode}
                                />
                                <CheckboxInput
                                    id="ifood-delivery-required"
                                    label="Ifood: Obrigatoriedade de informar código"
                                    onChange={() => setRequiredIfoodCode(state => !state)}
                                    checked={requiredIfoodCode}
                                />
                                <ButtonForm
                                    buttonText={deliveryman?.id ? 'Criar rota com entregador' : 'Criar rota'}
                                    style={{ width: '100%' }}
                                    onClick={() => setConfirmOfferVisible(true)}
                                />
                            </>
                        )}
                        <div className="bottom" />
                    </div>
                </div>

                <div className="right-column">
                    <MirrorMap />
                </div>
            </div>
        </div>
    )
}

export default RouteCreation
