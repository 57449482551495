import calcBearing from '@turf/bearing'
import calcDistance from '@turf/distance'
import { point } from '@turf/helpers'

type Point = number[]

interface ILocation {
    id: any
    location: Point
}

interface IScore {
    angle: number
    distance: number
    score: number
}

interface IPosition extends ILocation, IScore {}

type Config = {
    MaxOrdersPerRoute: number
    MaxDistanceBetweenOrders: number
    returnToBase: boolean
    originator: IPosition
    originatorFirst: boolean
}

function toRadius(angle: number) {
    if (angle < 0 && angle >= -180) {
        return angle + 360
    }

    return angle
}

function difference(a: number, b: number) {
    const d = Math.abs(a - b)
    return d > 180 ? 360 - d : d
}

function calcScore(order: IPosition, destination: Point): IScore {
    const point1 = point(order.location)
    const point2 = point(destination)

    const angle = toRadius(calcBearing(point1, point2))
    const distance = calcDistance(point1, point2)
    const diff = difference(order.angle, angle) / 180 + 1
    const score = Math.abs(distance + order.distance + 1) * diff + order.score

    return {
        angle,
        distance,
        score,
    }
}

function calcRoute(
    orders: IPosition[],
    baseLocation: Point,
    ancestors: number[],
    currentOrder: IPosition,
    configs: Config,
    onRouteFound: (path: number[], score: number) => void
) {
    const maxOrdersPerRoute = ancestors.includes(-1) ? configs.MaxOrdersPerRoute + 1 : configs.MaxOrdersPerRoute

    let restOrders: IPosition[] = []
    if (maxOrdersPerRoute === ancestors.length - 1 && !ancestors.includes(configs.originator.id)) {
        if (![-1, 0].includes(currentOrder.id)) {
            const point1 = point(currentOrder.location)
            const point2 = point(configs.originator.location)
            const distance = calcDistance(point1, point2)

            if (distance <= configs.MaxDistanceBetweenOrders) {
                restOrders = [configs.originator]
            }
        }
    } else {
        restOrders = orders.filter(x => {
            if (![-1, 0].includes(currentOrder.id)) {
                const point1 = point(currentOrder.location)
                const point2 = point(x.location)
                const distance = calcDistance(point1, point2)

                return distance <= configs.MaxDistanceBetweenOrders && !ancestors.includes(x.id)
            }
            return !ancestors.includes(x.id)
        })
    }

    if (restOrders.length > 0 && ancestors.length < maxOrdersPerRoute) {
        restOrders.forEach(o => {
            const score = calcScore(currentOrder, o.location)

            const nextPosition = {
                ...o,
                ...score,
            }

            calcRoute(orders, baseLocation, [...ancestors, o.id], nextPosition, configs, onRouteFound)
        })
    } else if (configs.returnToBase) {
        if (ancestors.includes(0)) {
            if (ancestors.includes(-1)) {
                if (ancestors.includes(configs.originator.id)) {
                    onRouteFound(ancestors, currentOrder.score)
                }
            } else {
                onRouteFound(ancestors, currentOrder.score)
            }
        } else {
            const score = calcScore(currentOrder, baseLocation)
            const baseReturn = {
                id: 0,
                location: baseLocation,
                ...score,
            }

            calcRoute(orders, baseLocation, [...ancestors, 0], baseReturn, configs, onRouteFound)
        }
    } else if (ancestors.includes(-1)) {
        if (ancestors.includes(configs.originator.id)) {
            onRouteFound(ancestors, currentOrder.score)
        }
    } else {
        onRouteFound(ancestors, currentOrder.score)
    }
}

function calcRouteFast(
    orders: IPosition[],
    baseLocation: Point,
    ancestors: number[],
    currentOrder: IPosition,
    configs: Config,
    onRouteFound: (path: number[], score: number) => void
) {
    const maxOrdersPerRoute = ancestors.includes(-1) ? configs.MaxOrdersPerRoute + 1 : configs.MaxOrdersPerRoute

    let restOrders: IPosition[] = []
    if (maxOrdersPerRoute === ancestors.length - 1 && !ancestors.includes(configs.originator.id)) {
        if (![-1, 0].includes(currentOrder.id)) {
            const point1 = point(currentOrder.location)
            const point2 = point(configs.originator.location)
            const distance = calcDistance(point1, point2)

            if (distance <= configs.MaxDistanceBetweenOrders) {
                restOrders = [configs.originator]
            }
        }
    } else {
        restOrders = orders.filter(x => {
            if (![-1, 0].includes(currentOrder.id)) {
                const point1 = point(currentOrder.location)
                const point2 = point(x.location)
                const distance = calcDistance(point1, point2)

                return distance <= configs.MaxDistanceBetweenOrders && !ancestors.includes(x.id)
            }
            return !ancestors.includes(x.id)
        })
    }

    if (restOrders.length > 0 && ancestors.length < maxOrdersPerRoute) {
        let minScore = 9999999999999
        let nextPosition: IPosition

        restOrders.forEach(o => {
            const score = calcScore(currentOrder, o.location)
            if (minScore > score.score) {
                minScore = score.score
                nextPosition = {
                    ...o,
                    ...score,
                }
            }
        })
        calcRouteFast(orders, baseLocation, [...ancestors, nextPosition.id], nextPosition, configs, onRouteFound)
    } else if (ancestors.includes(-1)) {
        if (ancestors.includes(configs.originator.id)) {
            onRouteFound(ancestors, currentOrder.score)
        }
    } else {
        onRouteFound(ancestors, currentOrder.score)
    }
}

function findRoute(orders: IPosition[], baseLocation: Point, configs: Config): any[] {
    if (orders.length > 0) {
        const basePosition = { id: -1, angle: 0, distance: 0, score: 0, location: baseLocation }
        let order = basePosition

        if (configs.originatorFirst) {
            order = configs.originator
            const result = calcScore(basePosition, order.location)
            order.angle = result.angle
            order.distance = result.distance
            order.score = result.score
        }

        let minPath: number[] = [order.id]
        let minScore = 9999999999999

        if (orders.length > 10) {
            calcRouteFast(orders, baseLocation, minPath, order, configs, (path, score) => {
                if (minScore > score) {
                    minPath = path
                    minScore = score
                }
            })
        } else {
            calcRoute(orders, baseLocation, minPath, order, configs, (path, score) => {
                if (minScore > score) {
                    minPath = path
                    minScore = score
                }
            })
        }
        return minPath.filter(o => ![0, -1].includes(o))
    }
    return []
}

export { findRoute }
