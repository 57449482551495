import { Fragment, useCallback, useState } from 'react'

import { getColorFromRoute } from 'helpers/getColorFromRoute'

import { formatAddress, getBikerType, getStatusOrder } from '../../helpers'

import './style.scss'
import { FinishRoutes, Container, SeeMore, Collect, MapImage } from './routes-running-list.styled'

const RouteStatus = {
    Started: 2,
    Finished: 3,
}

const RoutesRunningList = ({
    list,
    setHoverRoute,
    clickedRoute,
    hoverRoute,
    routeDetailsClick,
    status,
    finishRoute,
    cancelRoute = null,
}) => {
    const [isExpand, setExpand] = useState(false)

    const _canCancelRoute = useCallback(route => !route?.orders?.length, [])

    const _canFinishRoute = useCallback(route => {
        return route && ![RouteStatus.Finished, RouteStatus.Cancelled].includes(route.status) && !!route.biker
    }, [])

    const _expand = useCallback(() => {
        setExpand(state => !state)
    }, [])

    return (
        <div className="routes-running-list">
            <div className="routes-running-table-row">
                <div className="routes-running-row-column id">ID</div>
                <div className="routes-running-row-column orders">Pedidos</div>
                <div className="routes-running-row-column deliveryman">Entregador</div>
                <div className="routes-running-row-column button" />
            </div>

            {list?.map(route => (
                <Container key={route.id} color={getColorFromRoute(route.id)}>
                    <div
                        className="routes-running-table-row"
                        onMouseEnter={() => setHoverRoute(route)}
                        onMouseLeave={() => setHoverRoute(null)}
                        style={{
                            backgroundColor:
                                clickedRoute?.id === route.id || hoverRoute?.id === route.id
                                    ? 'rgba(255, 232, 162, 0.4)'
                                    : '',
                            borderLeftColor: status,
                        }}
                    >
                        <div className="routes-running-row-column id">{route.id}</div>
                        <div className="routes-running-row-column orders">
                            {!route.orders.length && <div>-</div>}
                            {route.orders.slice(0, isExpand ? route.orders.length - 1 : 3).map((order, index) => (
                                <Fragment key={index}>
                                    <span className="order-store-item">
                                        {order.merchant.name}{' '}
                                        <span className="order-reference-id">{`(#${
                                            order.reference_id || order.id
                                        })`}</span>
                                    </span>
                                    {!!order?.delivery_origin?.name && (
                                        <Collect>
                                            <MapImage />
                                            <div className="collect">
                                                Coleta: <span className="collect"> {order.delivery_origin.name}</span>
                                            </div>
                                        </Collect>
                                    )}
                                    <span className="order-info">{order?.customer?.name}</span>
                                    <span className="order-info">{formatAddress(order.address)}</span>
                                    <span className="order-status">{getStatusOrder(order.status)}</span>
                                </Fragment>
                            ))}
                            {route.orders.length > 3 && (
                                <SeeMore onClick={_expand}>{isExpand ? 'Ver Menos' : 'Ver Mais'}</SeeMore>
                            )}
                        </div>
                        <div className="routes-running-row-column deliveryman">
                            {route.biker ? (
                                <div className="deliveryman-data">
                                    <div className="info">
                                        <span className="name">{`${route.biker.first_name} ${route.biker.last_name}`}</span>
                                        <span className="modal">{getBikerType(route.biker.type)}</span>
                                    </div>
                                </div>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div className="routes-running-row-column button">
                            <div className="button-item outline" onClick={() => routeDetailsClick(route.id)}>
                                Detalhes
                            </div>
                            {!_canCancelRoute(route) && _canFinishRoute(route) && finishRoute && (
                                <FinishRoutes onClick={() => finishRoute(route)}>Finalizar Rota</FinishRoutes>
                            )}
                            {_canCancelRoute(route) && cancelRoute && (
                                <FinishRoutes onClick={cancelRoute(route.id)}>Cancelar Rota</FinishRoutes>
                            )}
                        </div>
                    </div>
                </Container>
            ))}
        </div>
    )
}

export default RoutesRunningList
