import { faBicycle, faFileAlt, faMotorcycle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const StoreChargeAreasContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f1f1f1;
    height: calc(100vh - 70px);
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: calc(100vh - 160px);
        flex-direction: row;
    }
`

export const ListingColumn = styled.div`
    background-color: #f1f1f1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 250px;
    overflow-y: auto;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: unset;
        flex: unset;
        flex-basis: 30%;
    }
`

export const MapColumn = styled.div`
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
    overflow-y: auto;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: unset;
    }
`

export const ChargeItem = styled.div<{ areacolor?: string }>`
    margin-bottom: 15px;
    border-radius: 6px;
    background-color: #e8e8e8;
    padding: 10px;
    border-left: 7px solid;
    border-left-color: ${({ areacolor, theme }) => (areacolor ? areacolor : theme.colors.primary)};
`

export const ChargeTitle = styled.div`
    font-size: 16px;
    margin-bottom: 15px;
`

export const ChargeValuesRow = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
`

export const BikeIcon = styled(FontAwesomeIcon).attrs({
    icon: faBicycle,
})`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
`

export const MotoIcon = styled(FontAwesomeIcon).attrs({
    icon: faMotorcycle,
})`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
`

export const ValueItem = styled.div`
    margin-right: 30px;
    display: flex;
    align-items: center;
`

export const ValueText = styled.div`
    margin-left: 10px;
`

export const ShiftContainer = styled.div`
    margin-bottom: 20px;
`

export const ShiftTitle = styled.div`
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    span {
        &:not(:last-child) {
            &::after {
                content: ', ';
            }
        }
    }
`

export const SpinnerContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`

export const TitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0px 20px;
`

export const TitleText = styled.div`
    font-weight: bold;
    font-size: 16px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        font-size: 20px;
    }
`

export const ExportButton = styled.a`
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 10px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    &:hover {
        opacity: 0.7;
    }
`

export const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export const MessageItem = styled.div`
    font-weight: bold;
    font-size: 14px;
`

export const EmptyIcon = styled(FontAwesomeIcon).attrs({
    icon: faFileAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 42px;
    margin-bottom: 20px;
`
