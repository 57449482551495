import { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem, Snackbar } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, setFormFieldErrors, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { User } from 'store/reducers/types'

import profile from '../../../assets/images/profile.gif'

import {
    ContentTitle,
    PersonalDataModalBackground,
    PersonalDataModalBox,
    PersonalDataModalContainer,
    PersonalDataModalContent,
    CloseButton,
    CloseContainer,
    PersonalDataHeader,
    FormContainer,
    FormSide,
    ImageSide,
    AnimatedImage,
    ButtonRow,
    TextButton,
    ConfirmButton,
} from './personal-data-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
}

const PersonalDataModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const { setLoading, setSnackbar, snackbarProps, closeSnackbar } = useUI()
    const { user, setUser } = useAuth()
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const { errors, getFieldProps, handleSubmit, isValid, touched } = useFormik({
        initialValues: {
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
        },
        validationSchema: Yup.object().shape({
            first_name: Yup.string().trim().required('Preencha com o nome'),
            last_name: Yup.string().trim().required('Preencha com o nome'),
        }),

        onSubmit: async (values, { resetForm, setFieldError }) => {
            try {
                setLoading(true)
                const { data } = await api.put(`/painel/administrator/${user.id}`, {
                    first_name: values.first_name,
                    last_name: values.last_name,
                })
                setUser(data as User)
                setSnackbar({ message: 'Informações alteradas com sucesso', type: 'success' })
                resetForm()
                closeClick()
            } catch (error) {
                if (error?.response?.status !== 422) {
                    setSnackbar({ message: showErrors(error), type: 'error' })
                    return
                }
                setFormFieldErrors(error, setFieldError)
            } finally {
                setLoading(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        },
    })

    return (
        <>
            <Snackbar {...snackbarProps} closeClick={closeSnackbar} />
            <PersonalDataModalContainer isDisplayed={isDisplayed}>
                <PersonalDataModalBox isEffectActive={isEffectActive}>
                    <PersonalDataHeader>
                        <CloseContainer onClick={closeClick}>
                            <CloseButton />
                        </CloseContainer>
                        <ContentTitle>Editar dados pessoais</ContentTitle>
                    </PersonalDataHeader>
                    <PersonalDataModalContent>
                        <FormContainer>
                            <ImageSide>
                                <AnimatedImage src={profile} />
                            </ImageSide>
                            <FormSide>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={getFieldProps('first_name')}
                                    errorMessage={getFormInputError('first_name', errors, touched)}
                                />
                                <InputItem
                                    labelText="Sobrenome"
                                    inputProps={getFieldProps('last_name')}
                                    errorMessage={getFormInputError('last_name', errors, touched)}
                                />
                            </FormSide>
                        </FormContainer>
                    </PersonalDataModalContent>
                    <ButtonRow>
                        <TextButton onClick={closeClick}>Cancelar</TextButton>
                        <ConfirmButton isActive={isValid} onClick={() => handleSubmit()}>
                            Salvar Alterações
                        </ConfirmButton>
                    </ButtonRow>
                </PersonalDataModalBox>
                <PersonalDataModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
            </PersonalDataModalContainer>
        </>
    )
}

export default PersonalDataModal
