import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ContainerPopup = styled.div`
    background-color: #f1f5fe;
    user-select: none;
    padding: 10px;
    border-radius: 8px;
    overflow: hidden;
    width: 220px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
`

const ContainerTopInfo = styled.div`
    width: 100%;
    display: flex;
    font-size: 9px;
    color: #909090;
`
const ContainerStatus = styled.div`
    display: flex;
    align-items: center;
`

const ContainerClose = styled.div`
    cursor: pointer;
`

const Circle = styled.div<{ color?: string }>`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ color }) => (color ? color : '#999999')};
    margin: 0px 8px;
`

const PopupTopInfo = styled.div<{ uppercase?: boolean }>`
    font-size: 10px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`

const StoreName = styled.div`
    font-weight: bold;
    margin: 5px 0px;
`
const PopupInfo = styled.div`
    font-size: 11px;
`

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
    font-size: 14px;
`

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`

const Line = styled.div`
    height: 1px;
    background-color: #909090;
    flex: 1;
`

const ButtonText = styled.div`
    font-size: 10px;
    margin: 5px;
`

const FooterPopup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #909090;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
        ${Line} {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export {
    ContainerPopup,
    ContainerTopInfo,
    ContainerStatus,
    ContainerClose,
    Circle,
    PopupTopInfo,
    StoreName,
    PopupInfo,
    Icon,
    Row,
    Line,
    ButtonText,
    FooterPopup,
}
