import styled from 'styled-components'

export const ChipsContainer = styled.div``

export const ChipsLabel = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const ChipsRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const ChipsItem = styled.div<{ isActive?: boolean }>`
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme, isActive }) => (isActive ? 'rgba(255,134,10, 0.7)' : theme.colors.white)};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.primary)};
    border-radius: 20px;
    text-align: center;
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    margin-bottom: 10px;
    font-weight: 600;
    &:hover {
        opacity: 0.6;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-right: 20px;
    }
`
