import styled, { css } from 'styled-components'

const ContainerDayWeek = styled.div<{ inForm?: boolean }>`
    user-select: none;
    display: flex;
    flex-wrap: nowrap;

    ${({ inForm }) =>
        inForm &&
        css`
            ${BoxDayWeek} {
                width: 2rem;
                height: 2.375rem;
                font-size: 0.875rem;
                cursor: pointer;
            }
        `}
`

const BoxDayWeek = styled.div<{ active?: boolean }>`
    width: 1rem;
    height: 1.125rem;
    display: flex;
    font-weight: bold;
    font-size: 0.75rem;
    align-items: center;
    justify-content: center;
    padding: 0.126rem 0.25rem;
    color: #fff;

    ${({ active }) =>
        active
            ? css`
                  background-color: ${({ theme }) => theme.colors.primary};
              `
            : css`
                  background-color: #999999;
              `}
`

export { BoxDayWeek, ContainerDayWeek }
