import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const AlertContainer = styled.div`
    display: flex;
    position: fixed;
    right: 600px;
    bottom: 50px;
    width: 300px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 16px;
    align-items: center;
    z-index: 100;
`

export const NetworkIcon = styled(FontAwesomeIcon).attrs({
    icon: faNetworkWired,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
`

export const AlertContentContainer = styled.div`
    padding: 0 20px;
`

export const AlertTitle = styled.div`
    color: ${({ theme }) => theme.colors.black};
`

export const AlertDescription = styled.div`
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 14px;
`

export const AlertButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    margin-top: 10px;
    user-select: none;
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
`
