import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    MallAccordion,
    AccordionHeader,
    HeaderText,
    StoreIcon,
    ChevronIcon,
    AccordionContent,
    IconContainer,
    AreasIcon,
    SlidersIcon,
    TariffsIcon,
    AdminIcon,
    SettingIcon,
    HeaderIcon,
} from './accordion.styles'

interface Props {
    accordionTitle: string
    iconType?: string
    isPrivate?: boolean
    open?: boolean
    hideIcon?: boolean
}

const Accordion: React.FC<Props> = ({ children, accordionTitle, iconType, isPrivate, open, hideIcon }) => {
    const [isOpened, setIsOpened] = useState(open || false)
    return (
        <MallAccordion>
            <AccordionHeader onClick={() => setIsOpened(!isOpened)}>
                <HeaderText>
                    {!hideIcon && (
                        <HeaderIcon>
                            {iconType === 'areas' ? (
                                <AreasIcon />
                            ) : iconType === 'tariffs' ? (
                                <TariffsIcon />
                            ) : iconType === 'sliders' ? (
                                <SlidersIcon />
                            ) : iconType === 'admin' ? (
                                <AdminIcon />
                            ) : iconType === 'settings' ? (
                                <SettingIcon />
                            ) : iconType === 'store' ? (
                                <StoreIcon />
                            ) : (
                                <StoreIcon />
                            )}
                        </HeaderIcon>
                    )}
                    {accordionTitle}
                    {isPrivate && <FontAwesomeIcon icon="lock" style={{ marginLeft: 5 }} />}
                </HeaderText>
                <IconContainer isActive={isOpened}>
                    <ChevronIcon />
                </IconContainer>
            </AccordionHeader>
            <AccordionContent isActive={isOpened}>{children}</AccordionContent>
        </MallAccordion>
    )
}

export default Accordion
