/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useMemo, useState } from 'react'

import { Order } from 'types'

type ChangeOrderTypeContextType = {
    options: React.OptionHTMLAttributes<HTMLOptionElement>[]
    type?: string
    order?: Order
    show: boolean
    loading: boolean
    setLoading(value: boolean): void
    setShow(value: boolean): void
    setType(type: string): void
    setOrder(order: Order): void
}

const InitialValue: ChangeOrderTypeContextType = {
    options: [],
    show: false,
    loading: false,
    setShow: () => {},
    setLoading: () => {},
    setType: () => {},
    setOrder: () => {},
}

const ChangeOrderTypeContext = createContext<ChangeOrderTypeContextType>(InitialValue)

type ChangeOrderTypeContextProps = {
    children: any
}

const ChangeOrderTypeContextProvider: React.FC<ChangeOrderTypeContextProps> = memo(({ children }) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [order, setOrder] = useState<Order>()
    const [type, setType] = useState<string>()

    const options = useMemo<React.OptionHTMLAttributes<HTMLOptionElement>[]>(() => {
        return [
            {
                label: 'Delivery',
                value: 'delivery',
            },
            {
                label: 'Retirada em balcão',
                value: 'takeout',
            },
            {
                label: 'Atendimento em Mesa',
                value: 'indoor',
            },
        ]
    }, [])

    const _setType = useCallback((value?: string) => {
        setType(value)
    }, [])

    const _setOrder = useCallback((value?: Order) => {
        setOrder(value)
    }, [])

    const _setShow = useCallback((value: boolean) => {
        setShow(value)
    }, [])

    const _setLoading = useCallback((value: boolean) => {
        setLoading(value)
    }, [])

    return (
        <ChangeOrderTypeContext.Provider
            value={{
                order,
                loading,
                show,
                options,
                type,
                setShow: _setShow,
                setLoading: _setLoading,
                setType: _setType,
                setOrder: _setOrder,
            }}
        >
            {children}
        </ChangeOrderTypeContext.Provider>
    )
})

export { ChangeOrderTypeContext, ChangeOrderTypeContextProvider }
