import { BarItem, BarsContainer, PasswordStrengthContainer, StrengthTitle } from './password-strength.styles'

interface Props {
    activeBars: number
    isFull?: boolean
}
const PasswordStrength: React.FC<Props> = ({ activeBars, isFull }) => {
    return (
        <PasswordStrengthContainer isFull={isFull}>
            <StrengthTitle>Força da senha:</StrengthTitle>
            <BarsContainer>
                <BarItem isActive={activeBars > 0} />
                <BarItem isActive={activeBars > 1} />
                <BarItem isActive={activeBars > 2} />
            </BarsContainer>
        </PasswordStrengthContainer>
    )
}
export default PasswordStrength
