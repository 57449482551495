import { useState, useRef, useCallback } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import { FormikValues } from 'formik'

import CheckboxInput from 'components/checkbox-input'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import { Text } from 'components/text/text'

import { useUI } from 'contexts'
import { formatCurrency, formatDistance, getStatusOrder, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { OrdersInProgressActions } from 'store/reducers/ordersInProgress'
import colors from 'themes/colors'
import { Order } from 'types'
import { IRequestORS, IRouteORS } from 'types/open-route'
import { routeColors } from 'utils'
// import { findRoute } from 'utils/find-route'

import { Collect, MapImage } from './route-detail-steps.styled'

import './style.scss'

interface Props {
    routeOrders: Order[]
    mallName: string
    mallAddress: string
    routeCreation?: boolean
    droppable?: string
    onRemoveClick(order: Order): void
    textButton: string
    routeDetails?: boolean
    onTextClick?(order: Order): void
    ordersHasReturn?: number[]
    onChangeOrdersHasReturn?(orderId: number): void
    updateOrderRates?(values: FormikValues): void
    routeID?: number
    revalidate?(): void
}

const RouteDetailSteps: React.FC<Props> = ({
    routeOrders,
    mallName,
    mallAddress,
    routeCreation,
    droppable,
    onRemoveClick,
    textButton,
    routeDetails,
    onTextClick,
    ordersHasReturn,
    onChangeOrdersHasReturn,
    updateOrderRates,
    routeID,
    revalidate,
}) => {
    const dispatch = useDispatch()
    const { mall } = useAuth()
    const { setLoading, setSnackbar, setErrorModal, setConfirmationModal } = useUI()

    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    function getBGColor(status: string) {
        if (
            status === '1' ||
            status === '2' ||
            status === '3' ||
            status === '4' ||
            status === '5' ||
            status === '6' ||
            status === '7'
        ) {
            return '#FF860A'
        } else if (status === '8' || status === '9' || status === '11') {
            return '#00bb00'
        } else {
            return '#cc0000'
        }
    }

    async function onRemoveFromRoute(route_id: number, order_id: number) {
        try {
            setLoading(true)
            await api.post('/painel/route/remove-order', {
                route_id: route_id,
                order_id: order_id,
            })
            revalidate()
            setSnackbar({ message: 'Removido da rota' })
        } catch (error) {
            console.log(error)
            setSnackbar({ message: 'Erro ao remover da rotaa' })
        } finally {
            setLoading(false)
        }
    }

    const _calcRoute = useCallback(() => {
        if (routeOrders.length === 0) {
            setErrorModal({
                title: 'Atenção!',
                subtitle: 'Sem pedidos otimizar',
            })
            return
        }

        setConfirmationModal({
            title: 'Otimizar Rota',
            subtitle: `Desejar otimizar a ordem de entregas dos pedidos desta rota? Esta operação pode levar um tempo maior dependendo da quantidade de pedidos.`,
            leftButtonText: 'Não',
            rightButtonClick: async () => {
                setLoading(true)
                try {
                    const baseURL = process.env.REACT_APP_OPEN_ROUTE_API_URL
                    const token = process.env.REACT_APP_OPEN_ROUTE_TOKEN

                    const body: IRequestORS = {
                        jobs: routeOrders.map(e => ({
                            id: e.id,
                            location: [e.address.lng, e.address.lat],
                        })),
                        vehicles: [
                            {
                                id: 1,
                                profile: 'driving-car',
                                start: [mall.address.lng, mall.address.lat],
                            },
                        ],
                    }

                    const { data } = await Axios.post<IRouteORS>(`${baseURL}/optimization`, body, {
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            Accept: 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
                            Authorization: token,
                        },
                    })

                    const ids: number[] = []

                    for (let i = 0; i < data.routes.length; i++) {
                        const route = data.routes[i]

                        for (let index = 0; index < route.steps.length; index++) {
                            const step = route.steps[index]
                            if (step.type === 'job' && step.id) {
                                ids.push(step.id)
                            }
                        }
                    }

                    dispatch(OrdersInProgressActions.setRouteOrders(ids.map(id => routeOrders.find(e => e.id === id))))
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao gerar Rota',
                        subtitle: showErrors(error),
                    })
                }
                setLoading(false)

                // const orders = routeOrders.map(x => ({ id: x.id, location: [x.address.lng, x.address.lat] })) as any[]

                // const originator = orders[0]
                // const mallLocation = [mall.address.lng, mall.address.lat]
                // const ids = findRoute(orders, mallLocation, {
                //     originatorFirst: false,
                //     MaxDistanceBetweenOrders: 99999999,
                //     MaxOrdersPerRoute: 999999999,
                //     originator: originator,
                //     returnToBase: false,
                // })

                // dispatch(OrdersInProgressActions.setRouteOrders(ids.map(id => routeOrders.find(e => e.id === id))))
            },
            rightButtonText: 'Sim',
        })
    }, [routeOrders, mall, dispatch, setConfirmationModal, setErrorModal, setLoading])

    const _clearRoute = useCallback(() => {
        dispatch(OrdersInProgressActions.setRouteOrders([]))
    }, [dispatch])

    return (
        <div className="route-detail-steps-container">
            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />
            <div className="title-row">
                <FontAwesomeIcon icon="store" />
                <span className="title-text">{mallName}</span>
                <div className="mall-address">{mallAddress}</div>
            </div>
            <div className="row">
                <Text bold variant="primary" link uppercase dimensions="small" align="left" onClick={_calcRoute}>
                    <FontAwesomeIcon icon="magic" /> Otimizar ordem
                </Text>
                <Text bold variant="primary" link uppercase dimensions="small" align="left" onClick={_clearRoute}>
                    <FontAwesomeIcon icon="trash-alt" /> Remover Todos
                </Text>
            </div>
            <div className="route-list-container">
                {routeCreation && droppable && (
                    <div className="tags-container">
                        {routeOrders.length ? (
                            <Droppable droppableId={droppable}>
                                {provided => {
                                    return (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {routeOrders.map((order, index) => {
                                                return (
                                                    <div key={index} className="address-item">
                                                        <div
                                                            className="index"
                                                            style={{
                                                                backgroundColor:
                                                                    routeColors[index % routeColors.length],
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </div>
                                                        <span
                                                            className="line-span"
                                                            style={{
                                                                backgroundColor:
                                                                    routeColors[index % routeColors.length],
                                                            }}
                                                        ></span>
                                                        <span
                                                            className="circle-span"
                                                            style={{
                                                                backgroundColor:
                                                                    routeColors[index % routeColors.length],
                                                            }}
                                                        ></span>
                                                        <Draggable
                                                            key={order.id}
                                                            draggableId={order.id.toString()}
                                                            index={index}
                                                        >
                                                            {provided => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                    className="draggable-container"
                                                                >
                                                                    <div className="store-name">
                                                                        {order.merchant.name}
                                                                    </div>
                                                                    <div className="address-value-container">
                                                                        <div className="address-value">
                                                                            {order.address?.street},{' '}
                                                                            {order.address?.number}
                                                                        </div>

                                                                        {routeOrders.length > 1 && (
                                                                            <div className="swap-icon-container">
                                                                                <FontAwesomeIcon
                                                                                    icon="grip-lines"
                                                                                    color="#999"
                                                                                    size="lg"
                                                                                    className="swap-icon"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {order.id && (
                                                                        <div className="reference">
                                                                            <FontAwesomeIcon icon="hashtag" />{' '}
                                                                            {order.reference_id
                                                                                ? `${order.reference_id} | `
                                                                                : ''}
                                                                            #{order.id}
                                                                        </div>
                                                                    )}

                                                                    <div className="payment-name">
                                                                        <FontAwesomeIcon icon="user" />{' '}
                                                                        {order.customer.name}
                                                                    </div>
                                                                    <div className="payment-name">
                                                                        <FontAwesomeIcon icon="money-bill" />{' '}
                                                                        {order.payment.name}
                                                                    </div>

                                                                    <div className="totals">
                                                                        <FontAwesomeIcon icon="check-circle" />{' '}
                                                                        {order.total_time.text} Tempo Total
                                                                    </div>
                                                                    <div className="totals">
                                                                        <FontAwesomeIcon icon="check-circle" />{' '}
                                                                        {order.time_ready.text} Tempo de Pronto
                                                                    </div>

                                                                    {!!order.delivery_origin?.name && (
                                                                        <Collect>
                                                                            <MapImage />
                                                                            <div className="collect">
                                                                                Coleta:
                                                                                <span className="collect">
                                                                                    {order.delivery_origin.name}
                                                                                </span>
                                                                            </div>
                                                                        </Collect>
                                                                    )}
                                                                    <div className="rate-return-checkbox-container">
                                                                        <CheckboxInput
                                                                            id={`${order.id}-has-rate-return`}
                                                                            label="Adicionar tarifa de retorno"
                                                                            onChange={() =>
                                                                                onChangeOrdersHasReturn &&
                                                                                onChangeOrdersHasReturn(order.id)
                                                                            }
                                                                            checked={ordersHasReturn.some(
                                                                                id => id === order.id
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    {Number(order.status) === 0 && (
                                                                        <div className="order-canceled-container">
                                                                            <div className="text">Pedido cancelado</div>
                                                                            <FontAwesomeIcon
                                                                                icon="exclamation-circle"
                                                                                color={colors.red}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {order.id && (
                                                                        <div
                                                                            className="order-action"
                                                                            onClick={() => onRemoveClick(order)}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon="times-circle"
                                                                                size={'lg'}
                                                                            />{' '}
                                                                            {textButton}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    </div>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )
                                }}
                            </Droppable>
                        ) : (
                            <div className="address-item">
                                <div className="address-value message">Adicione um pedido para iniciar a rota</div>
                            </div>
                        )}
                    </div>
                )}

                {routeDetails && (
                    <div className="tags-container">
                        {routeOrders?.map((order, index) => (
                            <div key={index} className="address-item route-details">
                                <span
                                    className="line-span"
                                    style={{ backgroundColor: routeColors[index % routeColors.length] }}
                                ></span>
                                <span
                                    className="circle-span"
                                    style={{ backgroundColor: routeColors[index % routeColors.length] }}
                                ></span>
                                <div
                                    className="order-item__order-status"
                                    style={{ backgroundColor: getBGColor(order.status) }}
                                >
                                    {getStatusOrder(order.status)}
                                </div>
                                <br />
                                <small>
                                    {order.address?.neighborhood} ({formatDistance(order.distance)})
                                </small>
                                <div className="order-item__merchant-name">
                                    {order.merchant.name} (#{order.id})
                                </div>
                                <div className="address-value-container">
                                    <div className="address-value">
                                        {order.address?.street}, {order.address?.number}
                                    </div>
                                </div>
                                <small>{order.payment.name}</small>

                                {Number(order.status) === 0 && (
                                    <div className="order-canceled-container">
                                        <div className="text">Pedido cancelado</div>
                                        <FontAwesomeIcon icon="exclamation-circle" color={colors.red} />
                                    </div>
                                )}

                                <div className="route-orders-rates-container">
                                    {(order.agent_pick > 0 || order.store_delivery_pick > 0) && (
                                        <section className="order-rates-section">
                                            <div className="rate-container">
                                                <strong className="rate-section-title">Tarifa de coleta</strong>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="store" />
                                                    {formatCurrency(order.store_delivery_pick)}
                                                </div>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="motorcycle" />
                                                    {formatCurrency(order.agent_pick)}
                                                </div>
                                            </div>
                                        </section>
                                    )}

                                    {(order.agent_express > 0 || order.store_delivery_express > 0) && (
                                        <section className="order-rates-section">
                                            <div className="rate-container">
                                                <strong className="rate-section-title">Tarifa expressa</strong>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="store" />
                                                    {formatCurrency(order.store_delivery_express)}
                                                </div>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="motorcycle" />
                                                    {formatCurrency(order.agent_express)}
                                                </div>
                                            </div>
                                        </section>
                                    )}

                                    {(order.agent_rate > 0 || order.store_delivery_rate > 0) && (
                                        <section className="order-rates-section">
                                            <div className="rate-container">
                                                <strong className="rate-section-title">Tarifa padrão</strong>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="store" />
                                                    {formatCurrency(order.store_delivery_rate)}
                                                </div>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="motorcycle" />
                                                    {formatCurrency(order.agent_rate)}
                                                </div>
                                            </div>
                                        </section>
                                    )}

                                    {(order.agent_rate_return > 0 || order.store_rate_return > 0) && (
                                        <section className="order-rates-section">
                                            <div className="rate-container">
                                                <strong className="rate-section-title">Tarifa de retorno</strong>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="store" />
                                                    {formatCurrency(order.store_rate_return)}
                                                </div>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="motorcycle" />
                                                    {formatCurrency(order.agent_rate_return)}
                                                </div>
                                            </div>
                                        </section>
                                    )}

                                    {(order.agent_dynamic_rate > 0 || order.store_delivery_dynamic_rate > 0) && (
                                        <section className="order-rates-section">
                                            <div className="rate-container">
                                                <strong className="rate-section-title">Tarifa dinâmica</strong>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="store" />
                                                    {formatCurrency(order.store_delivery_dynamic_rate)}
                                                </div>
                                                <div className="rate-value">
                                                    <FontAwesomeIcon icon="motorcycle" />
                                                    {formatCurrency(order.agent_dynamic_rate)}
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </div>

                                <div className="order-actions-container">
                                    {onTextClick && (
                                        <div className="order-action" onClick={() => onTextClick(order)}>
                                            <FontAwesomeIcon icon="file-invoice" /> {textButton}
                                        </div>
                                    )}
                                    {updateOrderRates && (
                                        <div className="order-action" onClick={() => updateOrderRates(order)}>
                                            <FontAwesomeIcon icon="dollar-sign" /> Alterar tarifas
                                        </div>
                                    )}

                                    {['1', '2', '3', '4', '5', '6'].includes(order.status) && (
                                        <div
                                            className="order-action remove"
                                            onClick={() => {
                                                setModalConfirmationData({
                                                    title: 'Excluir da rota',
                                                    message: 'Tem certeza de que deseja excluir este pedido da rota?',
                                                    onYes: () => onRemoveFromRoute(routeID, order.id),
                                                })
                                                modalConfirmation.current?.openModal()
                                            }}
                                        >
                                            <FontAwesomeIcon icon="times-circle" /> Remover da rota
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default RouteDetailSteps
