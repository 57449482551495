import React, { forwardRef, useImperativeHandle, useState, useCallback, useMemo } from 'react'

import { useFormik } from 'formik'

import Button from 'components/button-form'
import Check from 'components/check/check'
import Loader from 'components/modal-loading'
import SelectInput from 'components/select-input'

import { useUI } from 'contexts'
import { formatPhone, getBikerType } from 'helpers'
import api from 'services/api'
import { Biker } from 'types'
import { IReprovals } from 'types/reprovals'

import {
    Header,
    Footer,
    Container,
    BoxContainer,
    Title,
    CloseIcon,
    GroupTitle,
    Content,
    ContainerOption,
    TitleOption,
    ContainerAgent,
    ContainerAvatar,
    Avatar,
    ContainerAgentInfo,
    Vehicle,
    AgentName,
    ContainerInfo,
    PhoneIcon,
    TextInfo,
} from './modal-moderation.styled'

type ModalEditRoute = {
    show?(agent: Biker): void
    close?(): void
}
interface ModalModerationProps {
    onRefresh?: () => void
}
// eslint-disable-next-line react/display-name
const ModalModeration = forwardRef<ModalEditRoute, ModalModerationProps>(({ onRefresh }, ref) => {
    const { setConfirmationModal, setErrorModal, setSuccessModal } = useUI()

    const [visible, setVisible] = useState(false)
    const [agent, setAgent] = useState<Biker>(null)
    const [loading, setLoading] = useState(false)

    const { values, handleSubmit, setFieldValue, resetForm } = useFormik<IReprovals>({
        validateOnMount: true,
        initialValues: {
            avatar: null,
            address: {
                neighborhood: null,
                city: null,
                state: null,
                street: null,
                number: null,
                complement: null,
                landmark: null,
                zipcode: null,
            },
            personal: {
                cellphone: null,
                cpf: null,
                first_name: null,
                last_name: null,
                nickname: null,
                dob: null,
                gender: null,
            },
            bank: {
                pix_code: null,
                pix_type: null,
                account_number: null,
                account_type: null,
                bank_branch: null,
                bank: null,
                cpf_cnpj: null,
                operation_number: null,
                owner_account: null,
            },
            documents: {
                front: null,
                modality: null,
                type: null,
                verse: null,
            },
            company: {
                cnpj: null,
                company_name: null,
            },
            emergency: {
                name: null,
                cellphone: null,
            },
        },

        onSubmit: async values => {
            const callback = async () => {
                setLoading(true)

                try {
                    await api.put(`/painel/agent/${agent.id}/reprove`, values)
                    setSuccessModal({
                        title: 'Entregador reprovado',
                        subtitle: 'Em breve o entregador receberá uma notificação informando o motivo da reprovação.',
                    })
                    onRefresh()
                    _close()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: 'Não foi possível carregar as informações do entregador.',
                    })
                }
                setLoading(false)
            }
            setConfirmationModal({
                title: 'Repovar Entregador',
                subtitle:
                    'Tem certeza que deseja reprovar esse entregador? revise as informações bem antes de reprovar',
                leftButtonText: 'não',
                rightButtonText: 'sim',
                rightButtonClick: callback,
            })
        },
    })

    useImperativeHandle(
        ref,
        () => ({
            show: agent => {
                resetForm()
                setAgent(agent)
                setVisible(true)
                freeze()
            },
            close: () => {
                resetForm()
                setVisible(false)
            },
        }),
        [resetForm]
    )

    const _close = useCallback(() => {
        resetForm()
        setVisible(false)
        setAgent(null)
        unfreeze()
    }, [resetForm])

    const _setValue = useCallback(
        (field: string, value: any) => {
            setFieldValue(field, value)
        },
        [setFieldValue]
    )

    if (!visible) {
        return null
    }

    return (
        <Container>
            <BoxContainer>
                <Header>
                    <Title>Reprovar usuário</Title>
                    <CloseIcon onClick={_close} />
                </Header>
                <Content>
                    {!!agent && (
                        <ContainerAgent>
                            <ContainerAvatar>
                                <Avatar src={agent.avatar} />
                            </ContainerAvatar>
                            <ContainerAgentInfo>
                                <Vehicle>{getBikerType(agent.type)}</Vehicle>
                                <AgentName>
                                    {agent.first_name} {agent.last_name}
                                </AgentName>
                                <ContainerInfo>
                                    <PhoneIcon />
                                    <TextInfo>{formatPhone(agent.cellphone)}</TextInfo>
                                </ContainerInfo>
                            </ContainerAgentInfo>
                        </ContainerAgent>
                    )}

                    <GroupTitle>Informações do entregador</GroupTitle>
                    <ItemReproval
                        title="Foto"
                        field="avatar"
                        value={values.avatar}
                        optionValidate="Foto de perfil deve ser do seu rosto sem máscara e/ou capacete"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Nome"
                        field="personal.first_name"
                        value={values.personal.first_name}
                        optionValidate="Nome não preenchido corretamente"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Sobrenome"
                        field="personal.last_name"
                        value={values.personal.last_name}
                        optionValidate="Sobrenome não preenchido corretamente"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Nome de tratamento"
                        field="personal.nickname"
                        value={values.personal.nickname}
                        optionValidate="Nome de tratamento recusado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="CPF"
                        field="personal.cpf"
                        value={values.personal.cpf}
                        optionValidate="CPF inválido ou não correspondente ao titular do cadastro"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Telefone"
                        field="personal.cellphone"
                        value={values.personal.cellphone}
                        optionValidate="Telefone de contato inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Data de nascimento"
                        field="personal.dob"
                        value={values.personal.dob}
                        optionValidate="Data de nascimento inválida"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Gênero"
                        field="personal.gender"
                        value={values.personal.gender}
                        optionValidate="Gênero divergente do documento"
                        onChange={_setValue}
                    />
                    <GroupTitle>Endereço</GroupTitle>
                    <ItemReproval
                        title="CEP"
                        field="address.zipcode"
                        value={values.address.zipcode}
                        optionValidate="CEP não corresponde ao endereço informado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Estado"
                        field="address.state"
                        value={values.address.state}
                        optionValidates={[
                            'Estado não corresponde ao endereço informado',
                            'Não estamos operando neste Estado',
                        ]}
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Cidade"
                        field="address.city"
                        value={values.address.city}
                        optionValidates={[
                            'Cidade não corresponde ao endereço informado',
                            'Não estamos operando nesta cidade',
                        ]}
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Bairro"
                        field="address.neighborhood"
                        value={values.address.neighborhood}
                        optionValidate="Bairro não corresponde ao endereço informado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Logradouro"
                        field="address.street"
                        value={values.address.street}
                        optionValidate="Logradouro não corresponde ao endereço informado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Número"
                        field="address.number"
                        value={values.address.number}
                        optionValidate="Número não corresponde ao endereço informado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Complemento"
                        field="address.complement"
                        value={values.address.complement}
                        optionValidate="Complemento não corresponde ao endereço informado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Ponto de Referência"
                        field="address.landmark"
                        value={values.address.landmark}
                        optionValidate="Referência não corresponde ao endereço informado"
                        onChange={_setValue}
                    />
                    <GroupTitle>Documentos</GroupTitle>
                    <ItemReproval
                        title="Documento Frente"
                        field="documents.front"
                        value={values.documents.front}
                        optionValidate="O documento está ilegível ou é inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Documento Verso"
                        field="documents.verse"
                        value={values.documents.verse}
                        optionValidate="O documento está ilegível ou é inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Tipo de Documento"
                        field="documents.type"
                        value={values.documents.type}
                        optionValidate="É preciso selecionar o tipo do documento"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Modalidade de entrega"
                        field="documents.modality"
                        value={values.documents.modality}
                        optionValidate="É preciso selecionar a modalidade de entregas"
                        onChange={_setValue}
                    />
                    <GroupTitle>Informações bancárias</GroupTitle>
                    <ItemReproval
                        title="Tipo de Chave PIX"
                        field="bank.pix_type"
                        value={values.bank.pix_type}
                        optionValidate="Tipo de Chave PIX inválida"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Chave PIX"
                        field="bank.pix_code"
                        value={values.bank.pix_code}
                        optionValidate="Chave PIX não corresponde ao tipo informado"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Entidade bancária"
                        field="bank.bank"
                        value={values.bank.bank}
                        optionValidate="Entidade bancária inválida"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Operação bancária"
                        field="bank.account_type"
                        value={values.bank.account_type}
                        optionValidate="Tipo de operação bancária inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Agência"
                        field="bank.bank_branch"
                        value={values.bank.bank_branch}
                        optionValidate="Agência Bancária inválida"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Número da conta"
                        field="bank.account_number"
                        value={values.bank.account_number}
                        optionValidate="Número da conta bancária inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="CPF/CNPJ"
                        field="bank.cpf_cnpj"
                        value={values.bank.cpf_cnpj}
                        optionValidate="CPF ou CNPJ inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Número da Operação"
                        field="bank.operation_number"
                        value={values.bank.operation_number}
                        optionValidate="Número da Operação inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Titular"
                        field="bank.owner_account"
                        value={values.bank.owner_account}
                        optionValidate="Titular inválido"
                        onChange={_setValue}
                    />
                    <GroupTitle>Contato de emergência</GroupTitle>
                    <ItemReproval
                        title="Contato de emergência"
                        field="emergency.name"
                        value={values.emergency.name}
                        optionValidate="Contato de emergência inválido"
                        onChange={_setValue}
                    />
                    <ItemReproval
                        title="Número de contato"
                        field="emergency.cellphone"
                        value={values.emergency.cellphone}
                        optionValidate="Número de contato de emergência inválido"
                        onChange={_setValue}
                    />
                </Content>
                <Footer>
                    <Button buttonText="Enviar" style={{ width: 100 }} onClick={handleSubmit} />
                </Footer>
                <Loader visible={loading} />
            </BoxContainer>
        </Container>
    )
})

interface ItemReprovalProps {
    title: string
    field?: string
    optionValidate?: string
    optionValidates?: string[]
    value?: any
    onChange?: (field: string, value: any) => void
}
const ItemReproval: React.FC<ItemReprovalProps> = ({
    title,
    field,
    value,
    optionValidate,
    optionValidates,
    onChange,
}) => {
    const [selected, setSelected] = useState(false)

    const options = useMemo(() => {
        const items = ['Informação inválida', 'Informação divergente']
        if (optionValidate) {
            items.unshift(optionValidate)
        }
        if (optionValidates) {
            items.unshift(...optionValidates)
        }
        return items.map((text, i) => ({
            id: i,
            label: `item-${i}`,
            name: text,
            value: text,
        }))
    }, [optionValidate, optionValidates])

    const _onChange = useCallback(
        (e: any) => {
            const value = e.target.value

            if (onChange) {
                onChange(field, value)
            }
        },
        [onChange, field]
    )

    const _toggleSelect = useCallback(() => {
        if (selected) {
            setSelected(false)
            if (onChange) {
                onChange(field, null)
            }
        } else {
            setSelected(true)
            if (optionValidate) {
                onChange(field, optionValidate)
            } else if (optionValidates) {
                onChange(field, optionValidates[0])
            }
        }
    }, [selected, field, optionValidate, optionValidates, onChange])

    return (
        <ContainerOption>
            <Check isSquared onClick={_toggleSelect} isSelected={selected} />
            <TitleOption>{title}</TitleOption>
            {selected && (
                <SelectInput data={options} value={value} onChange={_onChange} placeholder="Selecione o motivo" />
            )}
        </ContainerOption>
    )
}

function freeze() {
    const top = window.scrollY

    document.body.style.overflow = 'hidden'

    window.onscroll = function () {
        window.scroll(0, top)
    }
}

function unfreeze() {
    document.body.style.overflow = ''
    window.onscroll = null
}

export default ModalModeration
