import { useEffect, useState, useCallback } from 'react'
import Dropzone from 'react-dropzone'

import { baseURL } from '../../services/api'
import './style.scss'

const DropzoneInput = ({ label, onChange, preview, height = undefined, width = undefined }) => {
    const [previewError, setPreviewError] = useState('')
    const [image, setImage] = useState('')

    const getPreview = useCallback(() => {
        try {
            if (preview instanceof File) {
                setImage(window.URL.createObjectURL(preview))
                return
            }
            if (preview?.path) {
                setImage(`${baseURL}/${preview.path}?width=${width || 250}&height=${height || ''}`)
                return
            }
            if (preview?.length > 0) {
                setImage(preview)
                return
            }
            setImage('')
        } catch (error) {
            setPreviewError('Não foi possível carregar a imagem')
        }
    }, [preview, height, width])

    useEffect(() => {
        getPreview()

        return () => {
            if (preview instanceof File) window.URL.revokeObjectURL(preview)
        }
    }, [preview, getPreview])

    return (
        <div className="dropzone-input">
            <label>{label}</label>
            <Dropzone onDrop={acceptedFiles => onChange(acceptedFiles[0])} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {image ? (
                                <div style={{ minWidth: 50, minHeight: 50 }}>
                                    <img src={image} style={{ width, height }} alt="" />
                                </div>
                            ) : (
                                <p>Arraste a imagem ou clique aqui.</p>
                            )}
                        </div>
                    </section>
                )}
            </Dropzone>

            <p>{previewError}</p>
        </div>
    )
}

export default DropzoneInput
