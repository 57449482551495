import { useState } from 'react'

import { ChannelImage, ChannelItemContainer, CheckIcon, CheckContainer } from './channel-item.styles'

interface Props {
    image?: string
}

const ChannelItem: React.FC<Props> = ({ image }) => {
    const [isActive, setIsActive] = useState(false)
    return (
        <ChannelItemContainer isActive={isActive} onClick={() => setIsActive(!isActive)}>
            <ChannelImage src={image} />
            {isActive && (
                <CheckContainer>
                    <CheckIcon />
                </CheckContainer>
            )}
        </ChannelItemContainer>
    )
}

export default ChannelItem
