import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const OrderDetailsModalContainer = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transition: 400ms;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const ConfirmModal = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100%;
    display: ${({ isDisplayed }) => (isDisplayed ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.6);
    z-index: ${({ theme }) => theme.commonModalZIndex};
`

export const ConfirmBox = styled.div<{ isEffectActive: boolean }>`
    padding: 20px;
    width: 80%;
    border-radius: 4px;
    transition: 400ms;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0' : '-200%')});
`

export const OrderDetailsModalBackground = styled.div<{ isEffectActive: boolean }>`
    z-index: ${({ theme }) => theme.commonModalZIndex};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    transition: 400ms;
    opacity: ${({ isEffectActive }) => (isEffectActive ? '1' : '0')};
`

export const OrderDetailsModalBox = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 650px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

export const OrderDetailsHeader = styled.div`
    position: relative;
    font-size: 24px;
    padding: 20px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.colors.black};
    letter-spacing: 2px;
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
        margin-top: 20px;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 70px;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.primary};
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            left: 40px;
        }
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    width: 30px;
    position: absolute;
    top: 15px;
    right: 20px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`
