import { useEffect, useState } from 'react'

import BikerInfo from '../biker-info/biker-info'

import {
    BikerListItem,
    BikerSelectHeader,
    BikerSelectModalBackground,
    BikerSelectModalBox,
    BikerSelectModalContainer,
    BikerSelectModalContent,
    CloseButton,
    ModalTabsContainer,
    SearchBox,
    SearchContainer,
    SearchIcon,
    SearchInput,
    SelectButton,
    TabItem,
} from './biker-select-modal.styles'
import { bikers } from './mock-bikers'

interface Props {
    isActive: boolean
    closeClick(): void
}

const BikerSelectModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [isAvailableTabActive, setIsAvailableTabActive] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <BikerSelectModalContainer isDisplayed={isDisplayed}>
            <BikerSelectModalBox isEffectActive={isEffectActive}>
                <BikerSelectHeader>
                    <CloseButton onClick={closeClick} />
                    Selecionar entregador
                </BikerSelectHeader>
                <ModalTabsContainer>
                    <TabItem isActive={isAvailableTabActive} onClick={() => setIsAvailableTabActive(true)}>
                        Disponíveis
                    </TabItem>
                    <TabItem isActive={!isAvailableTabActive} onClick={() => setIsAvailableTabActive(false)}>
                        cadastrados
                    </TabItem>
                </ModalTabsContainer>
                <SearchContainer>
                    <SearchBox>
                        <SearchIcon />
                        <SearchInput />
                    </SearchBox>
                </SearchContainer>
                <BikerSelectModalContent>
                    {bikers.map((item, index) => (
                        <BikerListItem key={index}>
                            <BikerInfo
                                name={item.name}
                                vehicle={item.vehicle}
                                bikerRating={item.bikerRating}
                                photo={item.photo}
                                batteryLevel={item.batteryLevel}
                                hasWifi={item.hasWifi}
                                buildVersion={item.buildVersion}
                                androidVersion={item.androidVersion}
                            />
                            <SelectButton>Selecionar</SelectButton>
                        </BikerListItem>
                    ))}
                </BikerSelectModalContent>
            </BikerSelectModalBox>
            <BikerSelectModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </BikerSelectModalContainer>
    )
}

export default BikerSelectModal
