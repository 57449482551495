import { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem, Snackbar } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, setFormFieldErrors, showErrors } from 'helpers'
import api from 'services/api'

import password from '../../../assets/images/password.gif'

import {
    ContentTitle,
    ChangePasswordModalBackground,
    ChangePasswordModalBox,
    ChangePasswordModalContainer,
    ChangePasswordModalContent,
    CloseButton,
    CloseContainer,
    ChangePasswordHeader,
    FormContainer,
    FormSide,
    ImageSide,
    AnimatedImage,
    ButtonRow,
    TextButton,
    ConfirmButton,
} from './change-password-modal.styles'

const atLeastOneDigit = /.*\d.*/
const atLeastOneLowercaseCharacter = /.*[a-z].*/
const atLeastOneUppercaseCharacter = /.*[A-Z].*/

interface Props {
    isActive: boolean
    closeClick(): void
}

const ChangePasswordModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const { setLoading, setSnackbar, snackbarProps, closeSnackbar } = useUI()
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            document.body.style.overflow = 'hidden'
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            document.body.style.overflow = 'auto'
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const { errors, getFieldProps, handleSubmit, isValid, touched } = useFormik({
        initialValues: {
            current_password: '',
            password: '',
            password_confirmation: '',
        },
        validationSchema: Yup.object().shape({
            current_password: Yup.string().required('Preencha com a Senha atual'),
            password: Yup.string()
                .trim()
                .min(8, 'Senha deve ter no mínimo 8 caracteres')
                .required('Preencha com sua Nova senha')
                .matches(atLeastOneDigit, 'Deve conter um número')
                .matches(atLeastOneLowercaseCharacter, 'Deve conter uma letra mínuscula')
                .matches(atLeastOneUppercaseCharacter, 'Deve conter uma letra maiúscula'),
            password_confirmation: Yup.string()
                .trim()
                .oneOf([Yup.ref('password'), ''], 'Senhas não correspondem')
                .required('Confirme a Nova senha'),
        }),

        onSubmit: async (values, { resetForm, setFieldError }) => {
            try {
                setLoading(true)
                await api.post('/painel/change-password', {
                    current_password: values.current_password,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                })
                setSnackbar({ message: 'Senha alterada com sucesso', type: 'success' })
                resetForm()
                closeClick()
            } catch (error) {
                if (error.response.status !== 422) {
                    setSnackbar({ message: showErrors(error), type: 'error' })
                    return
                }
                setFormFieldErrors(error, setFieldError)
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <>
            <Snackbar {...snackbarProps} closeClick={closeSnackbar} />
            <ChangePasswordModalContainer isDisplayed={isDisplayed}>
                <ChangePasswordModalBox isEffectActive={isEffectActive}>
                    <ChangePasswordHeader>
                        <CloseContainer onClick={closeClick}>
                            <CloseButton />
                        </CloseContainer>
                        <ContentTitle>Alterar senha da conta</ContentTitle>
                    </ChangePasswordHeader>
                    <ChangePasswordModalContent>
                        <FormContainer>
                            <ImageSide>
                                <AnimatedImage src={password} />
                            </ImageSide>
                            <FormSide>
                                <InputItem
                                    labelText="Senha atual"
                                    type="password"
                                    inputProps={getFieldProps('current_password')}
                                    errorMessage={getFormInputError('current_password', errors, touched)}
                                />
                                <InputItem
                                    labelText="Nova senha"
                                    type="password"
                                    inputProps={getFieldProps('password')}
                                    errorMessage={getFormInputError('password', errors, touched)}
                                />
                                <InputItem
                                    labelText="Confirmar nova senha"
                                    type="password"
                                    errorMessage={getFormInputError('password_confirmation', errors, touched)}
                                    inputProps={getFieldProps('password_confirmation')}
                                />
                            </FormSide>
                        </FormContainer>
                    </ChangePasswordModalContent>
                    <ButtonRow>
                        <TextButton onClick={closeClick}>Cancelar</TextButton>
                        <ConfirmButton isActive={isValid} onClick={() => handleSubmit()}>
                            Salvar Alterações
                        </ConfirmButton>
                    </ButtonRow>
                </ChangePasswordModalBox>
                <ChangePasswordModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
            </ChangePasswordModalContainer>
        </>
    )
}

export default ChangePasswordModal
