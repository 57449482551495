import styled from 'styled-components'

export const AppliedFiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px;
`

export const AppliedFilterItem = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 5px 10px;
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
`

export const EmptyFiltersTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`
