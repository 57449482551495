import { useState } from 'react'

import ModalChangePassword from 'components/_new-general-vision/change-password-modal/'
import FilterModal from 'components/_new-general-vision/filter-modal'
import NavigationRow from 'components/_new-general-vision/navigation-row/navigation-row'
import NewOrders from 'components/_new-general-vision/new-orders/new-orders'
import NewTopRow from 'components/_new-general-vision/new-top-row/new-top-row'
import InRouteOrders from 'components/_new-general-vision/orders-in-route/orders-in-route'
import PersonalDataModal from 'components/_new-general-vision/personal-data-modal'
import RoutedOrders from 'components/_new-general-vision/routed-orders/routed-orders'
import ScheduledOrders from 'components/_new-general-vision/scheduled-orders/scheduled-orders'

import { ContentContainer, GeneralVisionContainer } from './new-general-vision.styles'

export default function NewGeneralVision(): JSX.Element {
    const [isNewsActive, setIsNewsActive] = useState(false)
    const [isScheduledActive, setIsScheduledActive] = useState(false)
    const [isRoutedActive, setIsRoutedActive] = useState(false)
    const [isInRouteActive, setIsInRouteActive] = useState(true)
    const [isFilterModalActive, setIsFilterModalActive] = useState(false)

    function toggleNew() {
        setIsNewsActive(true)
        setIsScheduledActive(false)
        setIsRoutedActive(false)
        setIsInRouteActive(false)
    }

    function toggleScheduled() {
        setIsNewsActive(false)
        setIsScheduledActive(true)
        setIsRoutedActive(false)
        setIsInRouteActive(false)
    }

    function toggleRouted() {
        setIsNewsActive(false)
        setIsScheduledActive(false)
        setIsRoutedActive(true)
        setIsInRouteActive(false)
    }

    function toggleInRoute() {
        setIsNewsActive(false)
        setIsScheduledActive(false)
        setIsRoutedActive(false)
        setIsInRouteActive(true)
    }
    return (
        <GeneralVisionContainer>
            <NewTopRow title="Delivery Mall Aldeota" changeUserClick={() => null} />
            <NavigationRow
                isScheduledActive={isScheduledActive}
                isNewActive={isNewsActive}
                isRoutedActive={isRoutedActive}
                isInRouteActive={isInRouteActive}
                scheduledClick={() => toggleScheduled()}
                newsClick={() => toggleNew()}
                inRouteClick={() => toggleInRoute()}
                routedClick={() => toggleRouted()}
                filterClick={() => setIsFilterModalActive(true)}
                teamAvailable={256}
                routesValue={134}
                appliedFilters={6}
            />
            <ContentContainer>
                {isNewsActive && <NewOrders />}
                {isScheduledActive && <ScheduledOrders />}
                {isRoutedActive && <RoutedOrders />}
                {isInRouteActive && <InRouteOrders />}
            </ContentContainer>
            <FilterModal isActive={isFilterModalActive} closeClick={() => setIsFilterModalActive(false)} />
            <PersonalDataModal isActive={false} closeClick={() => null} />
            <ModalChangePassword isActive={false} closeClick={() => null} />
        </GeneralVisionContainer>
    )
}
