import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ProgressCardContainer = styled.div`
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    display: inline-flex;
    flex-direction: column;
    height: 500px;
    width: 350px;
    margin-bottom: 10px;
    border-radius: 12px;
    &:not(:last-child) {
        margin-right: 20px;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 550px;
        height: 63vh;
    }

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: 68vh;
    }

    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        height: 75vh;
    }
`

const CardHeader = styled.div`
    display: flex;
    background-color: #f7f7f7;
    padding: 10px 20px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
`

const CardTitle = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 18px;
    }
`

const CardNumber = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c6c6c6;
    font-weight: 600;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
`

const OrdersList = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
`

const EmptyMessageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const EmptyIcon = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 20px;
`

const EmptyText = styled.div`
    font-size: 16px;
    color: #666;
    width: 200px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    white-space: normal;
    text-align: center;
`

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 20px 10px;
    span {
        &:last-child {
            width: 95%;
        }
    }
`

const DateGroup = styled.div`
    position: relative;
`

const Groupheader = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    padding: 10px 20px;
    border-bottom: 1px solid #c1c1c1;
    background-color: #f1f1f1;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const GroupheaderTitle = styled.div`
    font-weight: bold;
`
const GroupheaderSize = styled.div`
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #c1c1c1;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
`

const Header = styled.div`
    width: 100%;
    padding: 10px;
`

const ContainerItem = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    .id {
        width: 70px;
    }
    .times {
        display: flex;
        align-items: center;
        width: 200px;
        .icon {
            color: ${({ theme }) => theme.colors.primary};
            margin-right: 4px;
        }
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`
const ContentInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const ActionRow = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;

    .accept {
        margin-bottom: 5px;
    }
`
const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

const Row = styled.div`
    display: flex;
    flex: 1;
`

export {
    ActionRow,
    Row,
    CardHeader,
    CardNumber,
    ClockIcon,
    CardTitle,
    EmptyIcon,
    EmptyText,
    EmptyMessageContainer,
    OrdersList,
    ProgressCardContainer,
    LoadingContainer,
    DateGroup,
    Groupheader,
    GroupheaderTitle,
    GroupheaderSize,
    ContentInfo,
    Header,
    ContainerItem,
}
