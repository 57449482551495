import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatCurrency } from 'helpers'
import { Rate, RateValues } from 'types'

import './style.scss'

interface Props {
    rate: Rate
    deleteClick(): void
    editClick(): void
}

const ReturnRatesListingTableRow: React.FC<Props> = ({ rate, deleteClick, editClick }) => {
    const { store, agent } = rate.values as RateValues

    return (
        <div className="rates-table-row-item">
            <div className="rates-table-row-content id">{rate.id}</div>
            <div className="rates-table-row-content label">{rate.label}</div>

            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{formatCurrency(store.bike)}</span>
                </div>

                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="bicycle" color="black" />
                    </div>
                    <span>{formatCurrency(agent.bike)}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{formatCurrency(store?.moto)}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="motorcycle" color="black" />
                    </div>
                    <span>{formatCurrency(agent?.moto)}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{formatCurrency(store?.car)}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="car" color="black" />
                    </div>
                    <span>{formatCurrency(agent?.car)}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{formatCurrency(store?.van)}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="shuttle-van" color="black" />
                    </div>
                    <span>{formatCurrency(agent?.van)}</span>
                </div>
            </div>
            <div className="rates-table-row-content delivery">
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="store" color="black" />
                    </div>
                    <span>{formatCurrency(store?.truck)}</span>
                </div>
                <div className="icon-value">
                    <div className="icon">
                        <FontAwesomeIcon icon="truck" color="black" />
                    </div>
                    <span>{formatCurrency(agent?.truck)}</span>
                </div>
            </div>
            <div className="rates-table-row-content status">{rate.status ? 'Ativo' : 'Inativo'}</div>
            <div className="rates-table-row-content button-row">
                <div className="button-item outline" onClick={deleteClick}>
                    Remover
                </div>
                <div className="button-item" onClick={editClick}>
                    Editar
                </div>
            </div>
        </div>
    )
}

export default ReturnRatesListingTableRow
