import { useEffect, useState } from 'react'

import { CollectDetailsContent } from 'components/_store-general-vision'

import {
    CollectDetailsModalBackground,
    CollectDetailsModalBox,
    CollectDetailsModalContainer,
} from './collect-details-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
}

const CollectDetailsModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <CollectDetailsModalContainer isDisplayed={isDisplayed}>
            <CollectDetailsModalBox isEffectActive={isEffectActive}>
                <CollectDetailsContent closeClick={closeClick} />
            </CollectDetailsModalBox>
            <CollectDetailsModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </CollectDetailsModalContainer>
    )
}

export default CollectDetailsModal
