import React, { createContext, memo, useCallback, useRef, useState } from 'react'

import { FieldInputProps, FormikErrors, FormikState, useFormik } from 'formik'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import api from 'services/api'
import { IZone, IZoneTypes } from 'types/floor-map'

type IContext = {
    values: IForm
    selectedZone: IZone
    isDrawZone: boolean
    isNewFormZone: boolean
    canvasPolygon: ICanvasPolygon
    setCanvasPolygon(value: { [key: string]: number | string }): void
    setZoneCoordinates(coordinates: number[][][]): void
    setFloor(id: number): void
    setIsNewFormZone(value: boolean): void
    setIsDrawZone(value: boolean): void
    handleSelectZone(zone: IZone)
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
    setValues: (values: IForm, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<IForm>>
    resetForm: (nextState?: Partial<FormikState<IForm>>) => void
    handleSubmit(): void
    setHandleClose?(callback: () => void): void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<IForm>>
}
type Props = {
    children: React.ReactNode
}

type ICanvasPolygon = { color?: string; x: number; y: number; width: number; height: number }

type IForm = {
    id?: number
    label: string
    floor_id?: number
    color: string
    type: IZoneTypes
    stores?: number[]
    area?: {
        type: 'Polygon'
        coordinates: number[][][]
    }
    status: 0 | 1
}

const FloorMapContext = createContext({} as IContext)

const FloorMapProvider: React.FC<Props> = memo(({ children }) => {
    const { setLoading, setSuccessModal, setErrorModal } = useUI()

    const handleCallbackRef = useRef<() => void>()

    const [selectedZone, setSelectedZone] = useState<IZone>()
    const [isDrawZone, setIsDrawZone] = useState<boolean>(false)
    const [isNewFormZone, setIsNewFormZone] = useState<boolean>(false)
    const [canvasPolygon, setCanvasPolygon] = useState<ICanvasPolygon>()

    const { values, getFieldProps, setValues, setFieldValue, resetForm, handleSubmit } = useFormik<IForm>({
        initialValues: {
            label: '',
            color: '#000000',
            type: 'indoor',
            stores: [],
            area: {
                type: 'Polygon',
                coordinates: [],
            },
            status: 1,
        },
        validationSchema: Yup.object().shape({}),
        onSubmit: async values => {
            const url = `painel/floor-maps/${values.id ?? ''}`

            setLoading(true)
            try {
                values.id ? await api.put(url, values) : await api.post(url, values)

                setSuccessModal({
                    title: 'Sucesso!',
                    subtitle: `Zona ${values.id ? 'atualizado' : 'registrado'} com sucesso!`,
                    singleButtonClick: _handleClose,
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível registrar zona.',
                })
            } finally {
                setLoading(false)
            }
        },
    })

    const _convertToCanvasPolygon = useCallback((coordinates: number[][]) => {
        const initialPoint = coordinates[0]
        const lastPoint = coordinates[2]

        const [fX, fY] = initialPoint
        const [lX, lY] = lastPoint

        return {
            x: Math.round(fX),
            y: Math.round(fY),
            width: Math.round(lX - fX),
            height: Math.round(lY - fY),
        }
    }, [])

    const _handleClose = useCallback(() => {
        return handleCallbackRef?.current()
    }, [])
    const _setHandleClose = useCallback((callback: () => void) => {
        handleCallbackRef.current = callback
    }, [])
    const _handleSelectZone = useCallback((zone: IZone) => {
        resetForm()
        if (zone) {
            setValues({
                id: zone.id,
                label: zone.label,
                area: zone.area,
                color: zone.color,
                status: Number(zone.status),
                type: zone.type,
                stores: zone?.stores.map(item => item?.id),
            } as IForm)
            setCanvasPolygon(_convertToCanvasPolygon(zone.area.coordinates[0]))
        }
        setSelectedZone(zone)
    }, [])

    const _setZoneCoordinates = useCallback((coordinates: number[][][]) => {
        setFieldValue('area.coordinates', coordinates)
    }, [])

    const _setFloor = useCallback((id: number) => {
        setFieldValue('floor_id', id)
    }, [])

    const _setIsNewFormZone = useCallback((value: boolean) => {
        setIsNewFormZone(value)
    }, [])

    const _setCanvasPolygon = useCallback((value: { [key: string]: number | string }) => {
        setCanvasPolygon(state => ({ ...state, ...value } as ICanvasPolygon))
    }, [])

    const _handleSubmit = useCallback(() => handleSubmit(), [])

    return (
        <FloorMapContext.Provider
            value={{
                values,
                isDrawZone,
                isNewFormZone,
                selectedZone,
                canvasPolygon,
                setIsDrawZone,
                getFieldProps,
                setValues,
                resetForm,
                setIsNewFormZone: _setIsNewFormZone,
                handleSelectZone: _handleSelectZone,
                handleSubmit: _handleSubmit,
                setZoneCoordinates: _setZoneCoordinates,
                setFloor: _setFloor,
                setCanvasPolygon: _setCanvasPolygon,
                setHandleClose: _setHandleClose,
                setFieldValue,
            }}
        >
            {children}
        </FloorMapContext.Provider>
    )
})

export { FloorMapProvider, FloorMapContext }
