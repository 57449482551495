import { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router'

import { RefreshRomaneioObservable } from 'observables'

import { Snackbar } from 'components/_common'
import ActionModal from 'components/_common/action-modal'
import {
    NewOrders,
    OrdersCollectTab,
    OrdersInProgress,
    RequestCollectModal,
    StoreNavigationRow,
    CollectDetailsModal,
} from 'components/_store-general-vision'

import { useUI } from 'contexts'
import { ShopkeeperContextProvider } from 'contexts/shopkeeper.context'
import { useAuth } from 'hooks'
import { useOrdersInIntegrations, useOrdersInProgress } from 'hooks/orders'
import { usePendingOrders } from 'hooks/orders'
import { IOrderIntegrationList } from 'types/order-integration-list'

import { ContentContainer, GeneralVisionContainer } from './new-store-general-vision.styles'

export default function NewStoreGeneralVision(): JSX.Element {
    const { basePath } = useAuth()

    const parentPath = `${basePath}/visao-geral`
    const paths = {
        news: 'novos',
        inProgress: 'em-andamento',
        delivery: 'coleta',
    }

    const { actionModalProps, closeActionModal, closeSnackbar, snackbarProps } = useUI()

    const history = useHistory()
    const { pathname } = history.location

    const [requestCollectModalActive, setRequestCollectModalActive] = useState(false)
    const [collectDetailsModalActive, setCollectDetailsModalActive] = useState(false)
    const [isRefreshing, setRefreshing] = useState(false)
    useEffect(() => {
        if (pathname !== parentPath) return

        history.push(`${parentPath}/${paths.inProgress}`)
    }, [history, pathname])

    const { pendingOrders, revalidateOrders } = usePendingOrders()
    const { ordersIntegration, ordersIntegrationLoading, refreshIntegration } = useOrdersInIntegrations()
    const ordersInProgress = useOrdersInProgress()

    const _refresh = useCallback(async () => {
        setRefreshing(true)
        try {
            RefreshRomaneioObservable.next(null)
            await revalidateOrders()
            await ordersInProgress.revalidateOrders()
            await refreshIntegration()
        } catch (error) {
            console.log(error)
        }
        setRefreshing(false)
    }, [revalidateOrders, refreshIntegration, ordersInProgress])

    return (
        <ShopkeeperContextProvider>
            <GeneralVisionContainer>
                <Snackbar {...snackbarProps} closeClick={() => closeSnackbar()} />
                <StoreNavigationRow
                    inProductionValue={(ordersInProgress?.orders?.inProduction?.items || []).length}
                    deliveredValue={(ordersInProgress?.orders?.delivered?.items || []).length}
                    openCollectModal={() => setRequestCollectModalActive(true)}
                    refresh={_refresh}
                    isRefreshing={isRefreshing}
                />

                <ContentContainer>
                    {pathname.includes(`${paths.news}`) && <NewOrders pendingOrders={pendingOrders} />}
                    {pathname.includes(`${paths.inProgress}`) && (
                        <OrdersInProgress
                            ordersInProgress={ordersInProgress}
                            ordersIntegration={ordersIntegration}
                            ordersIntegrationLoading={ordersIntegrationLoading}
                        />
                    )}
                    {pathname.includes(`${paths.delivery}`) && (
                        <OrdersCollectTab onClickOrder={() => setCollectDetailsModalActive(true)} />
                    )}
                </ContentContainer>

                <RequestCollectModal
                    isActive={requestCollectModalActive}
                    closeClick={() => setRequestCollectModalActive(false)}
                />
                <CollectDetailsModal
                    isActive={collectDetailsModalActive}
                    closeClick={() => setCollectDetailsModalActive(false)}
                />

                <ActionModal {...actionModalProps} closeClick={() => closeActionModal()} />
            </GeneralVisionContainer>
        </ShopkeeperContextProvider>
    )
}
