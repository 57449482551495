import { forwardRef, useImperativeHandle, useState, useCallback, RefObject } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import TextInputForm from 'components/text-input-form'

import { showErrors } from 'helpers'
import { useEventListener } from 'hooks'
import siclosPagApi from 'services/siclospag'

import './style.scss'

export interface ModalCreateServiceProps {
    getServices(): void
    modalMessageRef: RefObject<ModalMessageRef>
    onClose?(): void
    setLoadingSubmit(loading: boolean): void
    setModalMessageData(object: ModalMessageProps): void
    title: string
}

export interface ModalCreateServiceRef {
    openModal(): void
    closeModal(): void
}

const ModalCreateService: React.ForwardRefRenderFunction<ModalCreateServiceRef, ModalCreateServiceProps> = (
    { getServices, modalMessageRef, onClose, setLoadingSubmit, setModalMessageData, title },
    ref
) => {
    const [active, setActive] = useState(false)

    const { errors, handleSubmit, resetForm, setFieldValue, touched, values } = useFormik({
        initialValues: {
            service_name: '',
        },
        validationSchema: Yup.object().shape({
            service_name: Yup.string().trim().required('Nome é obrigatório'),
        }),
        onSubmit: async values => {
            try {
                setLoadingSubmit(true)

                const { data } = await siclosPagApi.post('services/integrator/create', values)

                if (data?.error) {
                    throw data.msg
                }

                getServices()

                setActive(false)

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Serviço criado com sucesso criado com sucesso!',
                })

                modalMessageRef.current?.openModal()
                resetForm()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessageRef.current?.openModal()
            } finally {
                setLoadingSubmit(false)
            }
        },
    })

    const handleEscPress = useCallback(({ code }) => {
        if (code === 'Escape') {
            setActive(false)
        }
    }, [])

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setActive(true)
        },
        closeModal: () => {
            setActive(false)
            onClose && onClose()
            resetForm()
        },
    }))

    return (
        <div className={`modal-create-service ${active ? 'is-active' : ''}`}>
            <div className="Modal-bg">
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">{title}</div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">
                            <div className="inputs-container">
                                <TextInputForm
                                    label="Nome*"
                                    value={values.service_name}
                                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                        setFieldValue('service_name', value)
                                    }
                                    msgErro={touched.service_name && errors.service_name}
                                />
                            </div>
                        </div>
                        <div className="Modal-buttons">
                            <button className="Modal-button primary" type="submit" onClick={() => handleSubmit()}>
                                Salvar
                            </button>
                            <button className="Modal-button outline" onClick={() => setActive(false)}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ModalCreateService)
