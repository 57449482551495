import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'

import { DefaultButton } from 'components/default-button/default-button'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'

import { useUI } from 'contexts'
import { groupItemsByKey, showErrors } from 'helpers'
import { useAuth, useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, SideDish } from 'types'

import { SideDishModal, SideDishModalRef } from './components/side-dish-modal/side-dish-modal'
import SideDishComponent from './side-dish-component'
import { Container, Header, HeaderTitle, SideDishesListingContainer } from './side-dishes-listing.styled'

const SideDishesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { basePath, store } = useAuth()

    const { setErrorModal } = useUI()

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const sideDishModalRef = useRef<SideDishModalRef>()

    const [sideDishes, setSideDishes] = useState<SideDish[]>()
    const [loading, setLoading] = useState<boolean>()

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    // const sideDishes = useAxiosRequest<PagedList<SideDish>>({
    //     api,
    //     url: '/painel/side-dishes',
    //     initialConfig: { params: { store_id: store.id, per_page: -1, order_by: 'name' } },

    //     onError: onRequestError,
    // })

    const _getSideDishes = useCallback(
        async (page?: number) => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<SideDish>>('/painel/side-dishes', {
                    params: { store_id: store.id, per_page: -1, order_by: 'name' },
                })

                setSideDishes(data.items)
            } catch (error) {
                onRequestError(error)
            } finally {
                setLoading(false)
            }
        },
        [store]
    )

    const groupedSideDishes = useMemo(
        () =>
            groupItemsByKey(sideDishes, 'category_name', (item: SideDish) => ({
                ...item,
                category_name: item?.category?.name,
            })).sort((a, b) => a.title.localeCompare(b.title)),
        [sideDishes]
    )

    const removeSideDish = useAxiosRequest({
        api,
        method: 'delete',
        executeOnStart: false,
        onComplete: () => {
            setModalMessageData({
                title: 'Sucesso!',
                message: 'Acompanhamento excluído com sucesso',
            })

            modalMessage.current?.openModal()
            _getSideDishes()
        },
        onError: onRequestError,
    })

    const _openModal = useCallback(() => {
        sideDishModalRef.current?.show()
    }, [])

    const _openEdit = useCallback((sideDish: SideDish) => {
        _openModal()
        sideDishModalRef.current?.setSideDish(sideDish)
    }, [])

    const _handleCloseModal = useCallback(
        (refresh?: boolean) => {
            if (refresh) {
                _getSideDishes()
            }

            sideDishModalRef.current?.close()
        },
        [_getSideDishes]
    )

    useEffect(() => {
        _getSideDishes()
    }, [_getSideDishes])

    return (
        <Container>
            <ModalLoading visible={loading || removeSideDish.loading} />
            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />
            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <SideDishModal ref={sideDishModalRef} onClose={_handleCloseModal} />

            <Header>
                <HeaderTitle>Acompanhamentos</HeaderTitle>
                <DefaultButton title={'Criar Acompanhamento'} widthFitContainer onClick={_openModal} />
            </Header>

            <SideDishesListingContainer>
                {groupedSideDishes.map((group, index) => (
                    <SideDishComponent
                        group={group}
                        key={index}
                        editClick={_openEdit}
                        deleteClick={id => {
                            setModalConfirmationData({
                                title: 'Excluir Acompanhamento',
                                message: 'Tem certeza de que deseja excluir este Acompanhamento?',
                                onYes: () =>
                                    removeSideDish.request({
                                        url: `/painel/side-dish/${id}`,
                                    }),
                            })

                            modalConfirmation.current?.openModal()
                        }}
                    />
                ))}
            </SideDishesListingContainer>

            {!sideDishes?.length && !loading && (
                <EmptyMessage>Nenhum Acompanhamento criado para esta loja</EmptyMessage>
            )}
        </Container>
    )
}

export default SideDishesListing
