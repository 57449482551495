/* eslint-disable no-case-declarations */
import React, { useRef, useState, memo, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'

import { format } from 'date-fns'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import { ModalOrderCancel, ModalOrderCancelRef } from 'modals/modal-order-cancel/modal-order-cancel'

import iconMapMarker from 'assets/images/icon-map-marker.png'
import mallMarker from 'assets/images/mall-marker.png'

import InfoItem from 'components/_new-general-vision/info-item/info-item'
import { TableOrderItem } from 'components/_store-general-vision'
import { Canvas } from 'components/canvas/canvas'
import { Grid } from 'components/grid/grid'
import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'

import { formatCEP, formatCurrency, formatPhone } from 'helpers'
import { useMapboxView } from 'hooks'
import { addMultipleSymbolPoints } from 'hooks/useMapboxView/helpers'
import { mercadooApi } from 'services/api'
import { IDestiny } from 'types'
import { IBagDetails } from 'types/bag-details'

import {
    Container,
    ContentOrder,
    Row,
    MapContainer,
    OrderDetailItem,
    SectionTitle,
    Avatar,
    StoreName,
    RowStore,
    Total,
} from './modal-lateral-bag.styled'

type Params = {
    bag_id: number
}

export type ModalLateralBagRef = {
    show?(params?: Params): void
}

const ModalLateralBag = memo(
    forwardRef<ModalLateralBagRef, any>((_, ref) => {
        const modalOrderCancelRef = useRef<ModalOrderCancelRef>(null)
        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

        const [bag, setBag] = useState<IBagDetails>()
        const [loading, setLoading] = useState(false)

        const { mapRef, mapContainerRef } = useMapboxView({})

        const _getBag = useCallback(async (bag_id: number) => {
            try {
                const result = await mercadooApi.get<IBagDetails>(`/bags/report/${bag_id}`)
                setBag(result.data)
            } catch (error) {
                console.log('error', error)
            }
        }, [])

        const _clear = useCallback(() => {
            setBag(undefined)
            setLoading(false)
        }, [])

        const _onClose = useCallback(() => {
            _clear()
        }, [_clear])

        useImperativeHandle(
            ref,
            () => ({
                show: ({ bag_id }) => {
                    _getBag(bag_id)
                    lateralModalBaseRef.current?.show()
                },
            }),
            [_getBag]
        )

        useEffect(() => {
            setTimeout(() => {
                if (!bag?.address) {
                    return
                }
                const location = bag?.address?.location

                if (location.lat && location.lng) {
                    addMultipleSymbolPoints(mapRef.current, [
                        {
                            id: 'map-marker',
                            src: iconMapMarker,
                            coordinates: location,
                        },
                        {
                            id: 'mall-marker',
                            src: mallMarker,
                            size: 0.4,
                            coordinates: location,
                        },
                    ])
                }
                mapRef.current?.resize()
            }, 1000)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <>
                <LateralModalBase
                    zIndex={9900000000000}
                    ref={lateralModalBaseRef}
                    title="Detalhes da Bag"
                    onClose={_onClose}
                >
                    <Container>
                        {!!bag && (
                            <>
                                <Scroll>
                                    <ContentOrder>
                                        <OrderDetailItem>
                                            <SectionTitle>Informações Sobre o Pedido</SectionTitle>

                                            <Row>
                                                <InfoItem label="Bag ID" content={bag.id} />
                                            </Row>
                                            <Row>
                                                <InfoItem
                                                    label="Tipo de entrega"
                                                    content={getTypeName(bag.type.name)}
                                                />
                                                <InfoItem label="Situação" content={getStatusName(bag.status)} />
                                            </Row>
                                            <Row>
                                                <InfoItem label="Transaction" content={bag.transaction_id} />
                                            </Row>

                                            <Row>
                                                <InfoItem label="Forma de Pagamento" content={bag.payment_method} />
                                                <InfoItem label="Valor do Pedido" content={formatCurrency(bag.total)} />
                                            </Row>
                                            <Row>
                                                <InfoItem
                                                    label="Data da compra"
                                                    content={format(bag.created_at, 'DD/MM/YYYY')}
                                                />
                                                <InfoItem
                                                    label="Horário da compra"
                                                    content={format(bag.created_at, 'HH:mm')}
                                                />
                                            </Row>
                                        </OrderDetailItem>
                                        <OrderDetailItem>
                                            <SectionTitle>Cliente</SectionTitle>
                                            <Row>
                                                <InfoItem label="Nome do Cliente" content={bag.customer.full_name} />
                                                <InfoItem
                                                    label="Telefone"
                                                    content={
                                                        bag?.customer?.phone?.includes('+55')
                                                            ? formatPhone(bag?.customer?.phone)
                                                            : bag?.customer?.phone
                                                    }
                                                />
                                            </Row>
                                        </OrderDetailItem>
                                        <OrderDetailItem>
                                            <SectionTitle>Entrega</SectionTitle>

                                            {bag?.type?.name === 'delivery' && (
                                                <>
                                                    <Row>
                                                        <InfoItem
                                                            label="CEP"
                                                            content={formatCEP(bag?.address?.zipcode)}
                                                        />
                                                        <InfoItem
                                                            label="Bairro de entrega"
                                                            content={bag?.address?.neighborhood as string}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <InfoItem label="Logradouro" content={bag?.address?.street} />
                                                        <InfoItem label="Número" content={bag?.address?.number} />
                                                    </Row>
                                                    <Row>
                                                        <InfoItem
                                                            label="Complemento"
                                                            content={bag?.address?.complement}
                                                        />
                                                        <InfoItem label="Referência" content={bag?.address?.landmark} />
                                                    </Row>
                                                    <MapContainer ref={mapContainerRef}></MapContainer>
                                                </>
                                            )}
                                            {['takeout', 'indoor'].includes(bag?.type?.name || '') && (
                                                <>
                                                    {bag.type.destinies.length > 0 && (
                                                        <Row>
                                                            <InfoItem
                                                                label="Piso"
                                                                content={bag.type.destinies[0].name}
                                                            />

                                                            {bag.type.destinies[0].maps.length > 0 && (
                                                                <InfoItem
                                                                    label="Local"
                                                                    content={bag.type.destinies[0].maps[0].name}
                                                                />
                                                            )}
                                                        </Row>
                                                    )}
                                                    <CanvasComponent destinies={bag.type.destinies} />
                                                </>
                                            )}
                                        </OrderDetailItem>

                                        <OrderDetailItem>
                                            <SectionTitle>Itens do pedido</SectionTitle>
                                            {bag?.stores?.map((store, i) => (
                                                <React.Fragment key={i}>
                                                    <RowStore first={i === 0}>
                                                        <Avatar src={store.logo} />
                                                        <Grid.Row justify="space-between" align="center" maxWidth>
                                                            <StoreName>{store.name}</StoreName>
                                                            <Total>
                                                                Total:{' '}
                                                                {formatCurrency(
                                                                    store.items.reduce((a, b) => a + b.price, 0)
                                                                )}
                                                            </Total>
                                                        </Grid.Row>
                                                    </RowStore>

                                                    {store.items.map((item, i) => (
                                                        <TableOrderItem key={i} orderItem={item} />
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </OrderDetailItem>
                                    </ContentOrder>
                                </Scroll>
                            </>
                        )}
                    </Container>
                    <ModalLoading visible={loading} />
                    <ModalOrderCancel ref={modalOrderCancelRef} />
                </LateralModalBase>
            </>
        )
    })
)

type CanvasComponentProps = {
    destinies: IDestiny[]
}
const CanvasComponent: React.FC<CanvasComponentProps> = memo(({ destinies }) => {
    const [destiny, setDestiny] = useState<IDestiny>()

    useEffect(() => {
        if (destinies.length > 0) {
            setDestiny(destinies[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (!destiny) {
        return null
    }
    return (
        <>
            <Canvas destiny={destiny} />
        </>
    )
})

function getTypeName(type: 'takeout' | 'delivery' | 'indoor') {
    return {
        takeout: 'Balcão',
        delivery: 'Delivery',
        indoor: 'Mesa',
    }[type]
}

function getStatusName(type: 'payed' | 'pending') {
    return {
        payed: 'Pago',
        pending: 'Pendente',
    }[type]
}

export { ModalLateralBag }
