import AppliedFilters from '../applied-filters/applied-filters'
import RoutedOrderItem from '../routed-order-item/routed-order-item'

import { routed_orders } from './mock-routes'
import { ListBox, ListsContainer, ListTitle, ListTitleRow, MapContainer, NumberContainer } from './routed-orders.styles'

const selected_filters = ['HOY Pizza']

const RoutedOrders: React.FC = () => {
    return (
        <>
            <ListsContainer>
                <AppliedFilters selectedFilters={selected_filters} />
                <ListTitleRow>
                    <ListTitle>Aguardando entregador</ListTitle>
                    <NumberContainer>45</NumberContainer>
                </ListTitleRow>
                <ListBox>
                    {routed_orders.map((item, index) => (
                        <RoutedOrderItem
                            orderStatus={item.order_status}
                            orders={item.orders}
                            key={index}
                            color={item.route_color}
                            isWaitingBiker={item.waiting_biker}
                            isDraft={item.drafted_order}
                        />
                    ))}
                </ListBox>
            </ListsContainer>
            <MapContainer>mapa</MapContainer>
        </>
    )
}

export default RoutedOrders
