/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useEffect, useState } from 'react'

type DistrictContextType = {
    districts: string[]
    selectedDistricts: string[]
    toogleDistrict(district: string): void
    setDistrict(district: string[]): void
    removeDistrict(district: string): void
}

const InitialValue: DistrictContextType = {
    districts: [],
    selectedDistricts: [],
    toogleDistrict: () => {},
    setDistrict: () => {},
    removeDistrict: () => {},
}

const DistrictContext = createContext<DistrictContextType>(InitialValue)

type DistrictContextProps = {
    children: any
    districts: string[]
    onChangeSelected(districts: string[]): void
}

const DistrictContextProvider: React.FC<DistrictContextProps> = memo(({ children, districts, onChangeSelected }) => {
    const [selectedDistricts, setSelectedDistricts] = useState<string[]>([])

    const _toogleDistrict = useCallback(
        (district: string) => {
            const exits = selectedDistricts.find(e => e === district)

            if (exits) {
                setSelectedDistricts(selectedDistricts.filter(e => e !== district))
            } else {
                setSelectedDistricts([...selectedDistricts, district])
            }
        },
        [selectedDistricts]
    )
    const _setDistrict = useCallback((districts: string[]) => {
        setSelectedDistricts(districts)
    }, [])

    const _removeDistrict = useCallback(
        (district: string) => {
            setSelectedDistricts(selectedDistricts.filter(e => e !== district))
        },
        [selectedDistricts]
    )

    useEffect(() => {
        if (onChangeSelected) {
            onChangeSelected(selectedDistricts)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDistricts])

    useEffect(() => {
        setSelectedDistricts(selectedDistricts.filter(x => districts.includes(x)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districts])

    return (
        <DistrictContext.Provider
            value={{
                districts,
                selectedDistricts,
                toogleDistrict: _toogleDistrict,
                setDistrict: _setDistrict,
                removeDistrict: _removeDistrict,
            }}
        >
            {children}
        </DistrictContext.Provider>
    )
})

export { DistrictContext, DistrictContextProvider }
