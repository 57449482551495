import styled from 'styled-components'

const Container = styled.div`
    padding: 20px;
`

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item-input-form {
        width: 47%;
    }
`

const StoresCheckboxContainer = styled.div`
    margin-top: 10px;
`

const StoreLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    width: auto;
    color: black;
    letter-spacing: 1px;

    &.faded {
        color: grey;
    }
`

const StoresCheckboxGroup = styled.div`
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
`

const Row = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
`

const TextButton = styled.div<{ isRemove?: boolean }>`
    color: ${({ theme, isRemove }) => (isRemove ? theme.colors.errorMessage : theme.colors.primary)};
    margin-right: 30px;
    font-size: 16px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
    span {
        margin-left: 4px;
    }
`

const ConfirmButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

const ContainerAccordion = styled.div`
    margin-top: 40px;
`

export {
    Container,
    InputRow,
    StoresCheckboxContainer,
    StoreLabel,
    StoresCheckboxGroup,
    Row,
    ContainerAccordion,
    TextButton,
    ConfirmButton,
}
