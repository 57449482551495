import styled from 'styled-components'

import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: 0.75rem;
    padding: 1rem 0.75rem;

    background-color: ${({ theme }) => theme.colors.gray};
`

const Content = styled.div`
    border-radius: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0.75rem 1.25rem;

    background-color: ${({ theme }) => theme.colors.white};

    border-radius: 0.5rem;
`

const HeaderTitle = styled.h3`
    padding: 0;
    margin: 0;

    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primary};
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
`

const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    color: ${({ theme }) => theme.colors.white};
`
const StatusItem = styled.div<{ isActive?: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.danger)};
`

export { Container, Header, HeaderTitle, ButtonRow, DeleteIcon, Content, StatusItem }
