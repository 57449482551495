import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const CheckBoxContainer = styled.div`
    height: 18.75rem;
    overflow-y: scroll;
    margin-bottom: 1.563rem;
`

const ContentContainer = styled.div`
    flex: 1;
    padding: 0.938rem;
    overflow-y: auto;
    position: relative;

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 1.563rem;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 1.5rem;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 1.75rem;
    }
    &::after {
        content: '';
        width: 3.125rem;
        height: 0.188rem;
        position: absolute;
        bottom: -0.625rem;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`
const FormLabel = styled.div`
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 0.875rem;
`

const FormSection = styled.div`
    margin-bottom: 1.875rem;
`

const FormSectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
    font-weight: 600;
`

const InputsRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const InputContainer = styled.div``

const ButtonRow = styled.div<{ justify?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => justify || 'space-between'};
    padding: 1.25rem;
    background-color: #f7f7f7;

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        padding: 0.625rem;
    }
`

const Row = styled.div`
    display: flex;
    align-items: flex-end;
`

const TextButton = styled.div<{ isRemove?: boolean }>`
    color: ${({ theme, isRemove }) => (isRemove ? theme.colors.errorMessage : theme.colors.primary)};
    margin-right: 1.875rem;
    font-size: 1rem;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 0.563rem 1.25rem;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
    span {
        margin-left: 0.25;
    }

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        margin-right: 0px;
    }
`

const ConfirmButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.25rem;
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
`

const DeleteButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.danger)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.25rem;
    text-align: center;
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    &:hover {
        opacity: 0.8;
    }
`

const CloseContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    cursor: pointer;
    z-index: 3;
`

const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.28rem;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

const LinkButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    text-align: end;
    transition: 400ms;
    font-size: 0.875rem;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

const ErrorMessage = styled.div`
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.errorMessage};
    left: 0;
`

const FormRow = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    div:nth-child(n) {
        width: 100%;
    }

    label {
        font-weight: bold;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.textLight};
    }

    @media all and (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: row;

        div:nth-child(n) {
            width: 50%;
        }
    }
`

const AttachmentContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const AttachmentContentLabel = styled.label`
    margin-bottom: 0.5rem;
    width: 100%;
`

const ButtonItem = styled.div`
    input[type='file'] {
        display: none;
    }
    label {
        cursor: pointer;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    border: 0.063rem solid ${({ theme }) => theme.colors.primary};
    border-radius: 0.375rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1.25rem;
    user-select: none;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    width: 100% !important;
    height: 3rem;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

const DeleteButtonContent = styled.div`
    width: fit-content;
`

const MapContainer = styled.div`
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18.75rem;
    border: 0.063rem solid #f1f1f1;
    margin-bottom: 1.25rem;
`

export {
    CloseButton,
    CloseContainer,
    ConfirmButton,
    TextButton,
    ButtonRow,
    Row,
    InputContainer,
    InputsRow,
    FormSectionTitle,
    FormLabel,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
    LinkButton,
    CheckBoxContainer,
    ErrorMessage,
    FormRow,
    ButtonItem,
    AttachmentContent,
    AttachmentContentLabel,
    MapContainer,
    DeleteButtonContent,
    DeleteButton,
}
