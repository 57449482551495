const initialState = {
    type: null,
    payload: null,
    meta: null,
    error: false,
}

export const actionsReducer = (state = initialState, action) => {
    return {
        ...state,
        ...action,
    }
}
