import { faDollarSign, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const IncomeTotals = styled.div`
    margin-bottom: 20px;
`

const IncomeNumber = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 32px;
    font-weight: 600;
`

const IncomeLabel = styled.div`
    font-size: 14px;
    color: #717171;
`

const ResumeRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
    }
`

const ResumeCard = styled.div`
    border-radius: 12px;
    background-color: #f7f7f7;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
`

const BagIcon = styled(FontAwesomeIcon).attrs({
    icon: faShoppingBag,
})`
    color: #ababab;
    font-size: 40px;
    margin-right: 20px;
`

const ResumeText = styled.div``

const ResumeNumber = styled.div`
    font-size: 22px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`
const ResumeTitle = styled.div`
    font-size: 16px;
    color: #717171;
`
const MoneySign = styled(FontAwesomeIcon).attrs({
    icon: faDollarSign,
})`
    color: #ababab;
    font-size: 40px;
    margin-right: 20px;
`

const RowItem = styled.div`
    height: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 49%;
    }
`

export {
    IncomeTotals,
    RowItem,
    MoneySign,
    IncomeNumber,
    IncomeLabel,
    ResumeRow,
    ResumeCard,
    BagIcon,
    ResumeText,
    ResumeNumber,
    ResumeTitle,
}
