/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import ReactMapboxGl, { Layer, Source, Marker } from 'react-mapbox-gl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { points } from '@turf/turf'

import { CircleLines } from 'components/mapbox-view'
import { Slider } from 'components/slider/slider'

import { mapboxAccessToken } from 'services/mapbox'

import storeSource from '../../assets/images/store.png'

import { Container, IconButton, ContainerMap, ContainerImage, ContinerButtons } from './heatmap.styled'

interface ContextHeatMapProps {
    intensity: number
    setIntensity: (value: number) => void
}

const ContextHeatMap = React.createContext({
    intensity: 5,
    setIntensity: () => {},
} as ContextHeatMapProps)

const Map = ReactMapboxGl({
    accessToken: mapboxAccessToken,
})

interface HeatMapProps {
    data: number[][]
    center?: any
}
const HeatMap: React.FC<HeatMapProps> = memo(({ data, center }) => {
    const mapRefreshRef = useRef<any>()

    const centerMap = useMemo(() => (center ? center : [-51.6268054, -14.896146]), [center])
    const zoomMap = useMemo(() => (center ? [12] : [3.5]), [center]) as any

    const _data = useMemo(() => points(data || []), [data])
    const [expand, setExpand] = useState(false)
    const [displayMark, setDisplayMark] = useState(true)

    const _toggleExpand = useCallback(() => {
        setExpand(!expand)
        setTimeout(() => {
            mapRefreshRef.current?.resize()
        }, 300)
    }, [expand])

    const _toggleDiplayMark = useCallback(() => {
        setDisplayMark(!displayMark)
    }, [displayMark])

    const _onStyleLoad = useCallback(e => {
        mapRefreshRef.current = e
        if (mapRefreshRef.current == null) {
            e.resize()
        }
    }, [])

    return (
        <ContextContent>
            <Container expand={expand}>
                <ContainerMap>
                    <SliderContent />
                    <Map
                        style="mapbox://styles/mapbox/streets-v11"
                        center={centerMap}
                        zoom={zoomMap}
                        containerStyle={{ flex: 1 }}
                        onStyleLoad={_onStyleLoad}
                    >
                        <Source
                            id="points-heat-map"
                            geoJsonSource={{
                                type: 'geojson',
                                data: _data,
                            }}
                        />
                        <LayerContent />
                        <CircleLines id="circle-lines" center={center ? center : [-51.6268054, -14.896146]} />
                        {displayMark && center && (
                            <Marker coordinates={center} anchor="center">
                                <ContainerImage>
                                    <img src={storeSource} alt="mall" />
                                </ContainerImage>
                            </Marker>
                        )}
                    </Map>
                    <ContinerButtons>
                        <IconButton onClick={_toggleDiplayMark}>
                            <FontAwesomeIcon icon={displayMark ? 'eye' : 'eye-slash'} />
                        </IconButton>
                        <IconButton onClick={_toggleExpand}>
                            <FontAwesomeIcon icon={expand ? 'compress-arrows-alt' : 'expand-arrows-alt'} />
                        </IconButton>
                    </ContinerButtons>
                </ContainerMap>
            </Container>
        </ContextContent>
    )
})

const ContextContent: React.FC = memo(({ children }) => {
    const [intensity, setIntensity] = useState(10)

    return (
        <ContextHeatMap.Provider
            value={{
                intensity,
                setIntensity: value => setIntensity(value),
            }}
        >
            {children}
        </ContextHeatMap.Provider>
    )
})

const SliderContent: React.FC = memo(() => {
    const { intensity, setIntensity } = useContext(ContextHeatMap)
    return <Slider defaultValue={intensity} onValueChange={setIntensity} />
})

const LayerContent: React.FC = memo(() => {
    const { intensity } = useContext(ContextHeatMap)

    const layerPaint = useMemo(
        () => ({
            'heatmap-weight': {
                property: 'priceIndicator',
                type: 'exponential',
                stops: [
                    [0, 0],
                    [5, 2],
                ],
            },
            'heatmap-intensity': {
                stops: [
                    [0, 0],
                    [5, 1.2],
                ],
            },
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(33,102,172,0)',
                0.25,
                'rgba(29,72,119,0.7)',
                0.5,
                'rgba(27,138,90,0.7)',
                0.75,
                'rgba(246,136,56,0.7)',
                1,
                'rgba(238,62,50,0.7)',
            ],
            'heatmap-radius': {
                stops: [
                    [0, 1],
                    [5, intensity],
                ],
            },
        }),
        [intensity]
    )
    return <Layer id="heat-points-heat-map" type="heatmap" sourceId="points-heat-map" paint={layerPaint} />
})

export { HeatMap }
