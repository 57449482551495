import { useEffect, useState, useReducer, useRef, useCallback, useContext, useLayoutEffect, useMemo } from 'react'

import { faSync } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContainerRoutesContext } from 'contexts'
import { DeliveryProblemsModal, RoutesNotAcceptedModal, AvailableDeliverymenModal, ModalEditOrder } from 'modals'
import { SearchModal } from 'modals/search-modal/search-modal'

import { ConnectionStatusAlert } from 'components/_common'
import { RefreshButton } from 'components/_common/'
// import { SwitchButton } from 'components/_marketplace-management'
import { MirrorMap, GlobalMapContext } from 'components/global-map'
import {
    CircleLines,
    OrdersMarkers,
    PolygonFill,
    WindRoseLines,
    BikersInRouteMarkers,
    CircleFill,
} from 'components/mapbox-view'
import OperationRoutesInProgress from 'components/operation-routes-in-progress/operation-routes-in-progress'
import OperationRoutesNoStarting from 'components/operation-routes-no-starting/operation-routes-no-starting'
import SelectInput from 'components/select-input'

import { setSearchParams } from 'helpers'
import { useAgentsAvailable, useAuth, useRoutesChanges } from 'hooks'
import { useOrdersInProgress } from 'hooks/orders'

import ModalCallUpBikers from '../../components/modal-call-up-bikers'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import Notification from '../../components/notification/notification'
import OperationAgentsAvailable from '../../components/operation-agents-available/operation-agents-available'
import OperationListingProduction from '../../components/operation-listing-production/operation-listing-production'
import OperationListingScheduled from '../../components/operation-listing-scheduled/operation-listing-scheduled'
import OperationTopRow from '../../components/operation-top-row/operation-top-row'
import api, { api2 } from '../../services/api'

import './home-page.styles.scss'
import { Footer } from './home-page.styled'

const HomePage = ({ history, location }) => {
    const searchModalRef = useRef()
    const { setMapBoxContentComponent, setCenter, setZoom, setContentComponent } = useContext(GlobalMapContext)
    const [loading, toggleLoading] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)

    const [paymentAreas, setPaymentAreas] = useState({
        items: [],
        current_page: 1,
        totals: 0,
    })

    const [stores, setStores] = useState([])

    const [loadingQueue, toggleLoadingQueue] = useState(false)
    const [removeQueueData, setRemoveQueueData] = useState({})
    const [tabActive, setTabActive] = useState('in-production')
    // const [autoRouting, setAutoRouting] = useState(false)
    const [notifications, setNotifications] = useReducer((state, action) => {
        switch (action.type) {
            case 'add':
                return [...state.filter(item => item.type !== action.item.type), action.item]
            case 'remove':
                return state.filter(item => item.type !== action.item.type)
            default:
                throw new Error('Unexpected action')
        }
    }, [])

    const modalCallUpRef = useRef()
    const modalDeleteRef = useRef(null)
    const modalEditOrderRef = useRef(null)
    const deliveryProblemsModalRef = useRef(null)
    const routesNotAcceptedModalRef = useRef(null)
    const availableDeliverymenModalRef = useRef(null)

    const { basePath, mall } = useAuth()

    const {
        highlightedOrderId,
        isSocketConnected,
        orders,
        revalidateOrders,
        scoreboard: ordersScoreboard,
    } = useOrdersInProgress()
    const { agents, loading: agentsLoading, revalidateAgents } = useAgentsAvailable()
    const { scoreboard: routesScoreboard, revalidateRoutesScoreboard } = useRoutesChanges()
    const [avaliableAgents, setAvaliableAgents] = useState([])
    const [routesInProgress, setRoutesInProgress] = useState([])
    const [agentsInRoutes, setAgentsInRoutes] = useState([[]])
    const [hoverAgent, setHoverAgent] = useState(null)
    const [hoverRoute, setHoverRoute] = useState(null)

    const [clickedOrder, setOrderClicked] = useState(null)
    const [clickedBiker, setBikerClicked] = useState(false)
    const [clickedRouteOrder, setRouteOrderClicked] = useState(null)

    const storeFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return query.get('store')
    }, [location.search])

    const getPaymentAreas = useCallback(async mallId => {
        try {
            toggleLoading(true)

            const { data } = await api.get(`/painel/payment-areas`, {
                params: {
                    per_page: -1,
                    mall_id: mallId,
                    status: [1],
                },
            })

            setPaymentAreas(data)
        } catch (error) {
            console.log(error)
        } finally {
            toggleLoading(false)
        }
    }, [])

    useEffect(() => {
        if (mall.id) getPaymentAreas(mall.id)
    }, [getPaymentAreas, mall.id])

    const getStores = useCallback(async () => {
        try {
            toggleLoading(true)

            const { data } = await api.get('/painel/stores-to-select', {
                params: {
                    mall_id: mall.id,
                    order_by: 'name',
                },
            })

            setStores([{ label: 'Todas as lojas' }, ...data.items])
        } catch (error) {
            console.log(error)
        } finally {
            toggleLoading(false)
        }
    }, [mall.id])

    useEffect(() => {
        getStores()
    }, [getStores])

    useEffect(() => {
        if (routesScoreboard.waiting > 0) {
            setNotifications({
                type: 'add',
                item: {
                    title: 'Rotas não aceitas',
                    type: 'route-waiting',
                    message: `Existem ${routesScoreboard.waiting} não aceitas`,
                    onClick: _openNotAccepted,
                },
            })
        } else {
            setNotifications({ type: 'remove', item: { type: 'route-waiting' } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routesScoreboard.waiting, history])

    useEffect(() => {
        if (ordersScoreboard.failed > 0) {
            setNotifications({
                type: 'add',
                item: {
                    title: 'Pedidos com problema',
                    type: 'orders-failed',
                    message: `${
                        ordersScoreboard.failed > 1
                            ? `${ordersScoreboard.failed} pedidos`
                            : `${ordersScoreboard.failed} pedido`
                    } com problema`,
                    onClick: _openProblems,
                },
            })
        } else {
            setNotifications({ type: 'remove', item: { type: 'orders-failed' } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersScoreboard.failed, history])

    const _getOrdersFiltered = useCallback(
        (key, keys = []) => {
            const isOrderFromStore = item =>
                storeFilter && !isNaN(storeFilter) ? item.merchant.id.toString() === storeFilter : true

            return {
                ...orders[key],
                items:
                    keys.length > 0
                        ? keys.reduce((acc, key) => [...acc, ...orders[key].items.filter(isOrderFromStore)], [])
                        : orders[key].items.filter(isOrderFromStore),
            }
        },
        [storeFilter, orders]
    )

    const setRemoveFromQueue = user => {
        availableDeliverymenModalRef.current?.close()
        setRemoveQueueData(user)
        modalDeleteRef.current.openModal()
    }

    const removeQueuePosition = async () => {
        try {
            toggleLoadingQueue(true)

            availableDeliverymenModalRef.current?.show()

            await api2.delete(`/malls/${mall.slug}/kick`, {
                data: { agent: removeQueueData.id },
            })
        } catch (error) {
            console.log({ error })
        } finally {
            toggleLoadingQueue(false)
            availableDeliverymenModalRef.current?.show()
        }
    }

    const closeNotification = notification => {
        setNotifications({ type: 'remove', item: notification })
    }

    const _refreshInfo = useCallback(async () => {
        setIsRefreshing(true)

        await Promise.all([revalidateAgents(), revalidateOrders(), revalidateRoutesScoreboard()])

        setIsRefreshing(false)
    }, [revalidateAgents, revalidateOrders, revalidateRoutesScoreboard])

    const _openProblems = useCallback(() => {
        deliveryProblemsModalRef.current?.show()
    }, [deliveryProblemsModalRef])

    const _openNotAccepted = useCallback(() => {
        routesNotAcceptedModalRef.current?.show()
    }, [routesNotAcceptedModalRef])

    // const _handleAutoRouting = useCallback(
    //     async status => {
    //         try {
    //             // setLoading(true)
    //             await api.put(`/painel/mall/${mall.id}`, {
    //                 configs: {
    //                     route_automatic: {
    //                         status: !status,
    //                     },
    //                 },
    //             })
    //             setAutoRouting(!autoRouting)
    //             localStorage.removeItem(mallsKey)
    //         } catch (error) {
    //             console.log(error)
    //         } finally {
    //             // setLoading(false)
    //         }
    //     },
    //     [autoRouting, mall?.id, mallsKey]
    // )

    const _setStore = useCallback(
        store => {
            let value = store
            if (store === 'Todas as lojas') {
                value = undefined
            }
            setSearchParams({ store: value }, location, history)
        },
        [location, history]
    )

    useEffect(() => {
        setCenter()
        setZoom(14)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabActive])

    const _renderFooter = useCallback(() => {
        return (
            <Footer>
                {stores.length > 1 && (
                    <div className="filter-container row-item">
                        <div className="filter-select">
                            <SelectInput
                                bgColor="transparent"
                                data={stores}
                                onChange={({ target: { value } }) => _setStore(value)}
                                value={storeFilter}
                            />
                        </div>
                    </div>
                )}

                {/* <div className="auto-routing-container row-item">
                    <SwitchButton
                        isFlex
                        isActive={autoRouting}
                        onClick={() => _handleAutoRouting(autoRouting)}
                        activeLabel="Roteirização automática ativa"
                        inactiveLabel="Roteirização automática inativa"
                    />
                </div> */}
                <div className="row-item">
                    <RefreshButton
                        icon={faSync}
                        isRefreshing={isRefreshing}
                        label="Atualizar informações"
                        onClick={_refreshInfo}
                        type="link"
                    />
                </div>
            </Footer>
        )
    }, [isRefreshing, storeFilter, stores, _refreshInfo, _setStore])

    useLayoutEffect(() => {
        setContentComponent(_renderFooter)
    }, [_renderFooter, setContentComponent])

    const _renderMapContent = useCallback(() => {
        if (['scheduled', 'in-production'].includes(tabActive)) {
            return (
                <>
                    <OrdersMarkers
                        configs={mall.configs}
                        onOrderClick={id => (clickedOrder === id ? setOrderClicked(null) : setOrderClicked(id))}
                        orders={
                            tabActive === 'scheduled'
                                ? _getOrdersFiltered('scheduled').items
                                : _getOrdersFiltered('list', ['inProduction', 'toCollect']).items
                        }
                        seeDetails={id => history.push(`${basePath}/detalhes-pedido/${id}`)}
                        newRouteClick={order => history.push(`${basePath}/rotas/criar-rota`, { initial: order })}
                    />

                    <PolygonFill id="coverage-areas" mapControls areas={paymentAreas.items} />
                    <CircleLines id="circle-lines" center={[mall.address?.lng, mall.address?.lat]} />
                    <WindRoseLines id="wind-rose-lines" mapControls center={[mall.address?.lng, mall.address?.lat]} />
                </>
            )
        }
        if (['routes-no-starting', 'routes-in-progress'].includes(tabActive)) {
            return (
                <>
                    <BikersInRouteMarkers
                        configs={mall.configs}
                        bikers={agentsInRoutes}
                        routes={routesInProgress}
                        hoverRoute={hoverRoute}
                        history={history}
                        onBikerClick={biker_id => {
                            const route = routesInProgress.find(route => route.biker?.id === biker_id)

                            if (clickedBiker === biker_id) {
                                setBikerClicked(null)
                                setRouteOrderClicked(null)
                            } else {
                                setBikerClicked(biker_id)
                                setRouteOrderClicked(route)
                            }
                        }}
                        onOrderClick={order_id => {
                            const route = routesInProgress.find(route =>
                                route.orders.some(order => order.id === order_id)
                            )

                            if (clickedOrder === order_id) {
                                setOrderClicked(null)
                                setRouteOrderClicked(null)
                            } else {
                                setOrderClicked(order_id)
                                setRouteOrderClicked(route)
                            }
                        }}
                    />
                </>
            )
        }

        if (tabActive === 'agents-in-base') {
            return (
                <>
                    <BikersInRouteMarkers
                        disableHouver
                        configs={mall.configs}
                        bikers={avaliableAgents}
                        history={history}
                        hoverRoute={{ biker: hoverAgent }}
                    />
                    <CircleFill radius={mall.radius} center={[mall.address?.lng, mall.address?.lat]} />
                </>
            )
        }
        return null
    }, [
        mall,
        clickedOrder,
        tabActive,
        history,
        paymentAreas,
        clickedBiker,
        _getOrdersFiltered,
        basePath,
        avaliableAgents,
        hoverAgent,
        hoverRoute,
        agentsInRoutes,
        routesInProgress,
    ])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
    }, [_renderMapContent, setMapBoxContentComponent])

    useEffect(() => {
        return () => {
            setContentComponent(null)
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-container">
            <ModalLoading visible={loading} />

            <AvailableDeliverymenModal
                ref={availableDeliverymenModalRef}
                loading={loadingQueue || agentsLoading}
                onRemoveClick={item => setRemoveFromQueue(item)}
            />

            <ModalConfirmation
                ref={modalDeleteRef}
                title="Remover da fila"
                message={`Deseja realmente remover ${removeQueueData?.name} da fila de entregadores?`}
                onYes={() => removeQueuePosition()}
                onNo={() => {
                    modalDeleteRef.current.closeModal()
                    availableDeliverymenModalRef.current?.show()
                }}
            />

            <ModalCallUpBikers ref={modalCallUpRef} />
            <RoutesNotAcceptedModal ref={routesNotAcceptedModalRef} />
            <OperationTopRow
                agentsAvailable={agents?.totals}
                ordersScoreboard={ordersScoreboard}
                routesScoreboard={routesScoreboard}
                onChangeTab={tab => {
                    setAvaliableAgents([])
                    setRoutesInProgress([])
                    setAgentsInRoutes([])
                    setTabActive(tab)
                }}
                agentsAvailableClick={() => availableDeliverymenModalRef.current?.show()}
            />

            <div className="content-container home">
                <SearchModal ref={searchModalRef} />

                <div className="left-column">
                    {tabActive === 'scheduled' && (
                        <OperationListingScheduled
                            loading={orders.scheduled.loading}
                            ordersScheduled={_getOrdersFiltered('scheduled') || {}}
                            storeFilterEnabled={Boolean(storeFilter)}
                            clicked={clickedOrder || highlightedOrderId}
                        />
                    )}
                    {tabActive === 'in-production' && (
                        <OperationListingProduction
                            loading={orders.inProduction.loading || orders.toCollect.loading}
                            storeFilterEnabled={Boolean(storeFilter)}
                            ordersProduction={_getOrdersFiltered('inProduction') || {}}
                            ordersReadyDeliver={_getOrdersFiltered('toCollect') || {}}
                            clicked={clickedOrder || highlightedOrderId}
                        />
                    )}
                    {['routes-no-starting', 'routes-in-progress'].includes(tabActive) && (
                        <ContainerRoutesContext
                            onAgentsChanges={agents => setAgentsInRoutes(agents)}
                            onRoutesChanges={routes => setRoutesInProgress(routes)}
                            tabActive={tabActive}
                        >
                            {tabActive === 'routes-no-starting' && (
                                <OperationRoutesNoStarting
                                    hoverRoute={hoverRoute}
                                    setHoverRoute={route => setHoverRoute(route)}
                                />
                            )}
                            {tabActive === 'routes-in-progress' && (
                                <OperationRoutesInProgress
                                    hoverRoute={hoverRoute}
                                    setHoverRoute={route => setHoverRoute(route)}
                                />
                            )}
                        </ContainerRoutesContext>
                    )}
                    {tabActive === 'agents-in-base' && (
                        <OperationAgentsAvailable
                            onAgentsListChange={agents => setAvaliableAgents(agents)}
                            onHoverAgent={e => setHoverAgent(e)}
                            clickedRouteOrder={clickedRouteOrder}
                        />
                    )}
                </div>

                <div className="right-column">
                    <MirrorMap />
                </div>

                {notifications?.map((notification, index) => (
                    <Notification
                        visible
                        key={index}
                        index={index}
                        title={notification.title}
                        message={notification.message}
                        type={notification.type}
                        onMessageClick={notification.onClick}
                        onCloseNotification={() => closeNotification(notification)}
                    />
                ))}

                {!isSocketConnected && (
                    <ConnectionStatusAlert
                        title="Perda de sincronismo"
                        description="Pedidos e contadores podem estar desatualizados"
                        buttonLabel="Atualizar pedidos"
                        onClickButton={_refreshInfo}
                    />
                )}
            </div>
            <DeliveryProblemsModal
                ref={deliveryProblemsModalRef}
                onEditClick={order => modalEditOrderRef.current?.show(order.id)}
            />

            <ModalEditOrder ref={modalEditOrderRef} />
        </div>
    )
}

export default HomePage
