import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useEventListener } from 'hooks'
import { Creators as UtilsActions } from 'store/reducers/utils'

import './style.scss'

interface ModalMessageData {
    isActive: boolean
    message: string
    onClose(): void
    textButton: string
    title: string
}

interface Utils {
    modalMessageData: ModalMessageData
}

interface RootState {
    utils: Utils
}

export interface ModalMessageProps {
    isActive?: boolean
    message: string
    onClose?(): void
    textButton?: string
    title: string
    width?: string
}

export interface ModalMessageRef {
    openModal(): void
    closeModal(): void
}

const ModalMessage: React.ForwardRefRenderFunction<ModalMessageRef, ModalMessageProps> = (
    { children, isActive, message, onClose, textButton, title, width },
    ref
) => {
    const dispatch = useDispatch()
    const { modalMessageData } = useSelector<RootState, Utils>(({ utils }) => utils)

    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(modalMessageData.isActive || Boolean(isActive))
    }, [modalMessageData.isActive, isActive])

    const closeModal = useCallback(() => {
        setActive(false)

        if (modalMessageData.onClose) {
            modalMessageData.onClose()
        } else if (onClose) {
            onClose()
        }

        dispatch(UtilsActions.clearModalMessageData())
    }, [dispatch, modalMessageData, onClose])

    const handleEscPress = useCallback(
        ({ code }) => {
            if (code === 'Escape') {
                closeModal()
            }
        },
        [closeModal]
    )

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setActive(true)
        },
        closeModal,
    }))

    return (
        <div className={`Modal-message ${active ? 'is-active' : ''}`}>
            <div className="Modal-bg">
                <div className="Modal-box" style={{ width: width }}>
                    <div className="Modal-header">
                        <div className="Modal-title">{modalMessageData.title || title}</div>
                        <div className="Modal-close" onClick={closeModal}>
                            <FontAwesomeIcon size="lg" icon="times" />
                        </div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">{modalMessageData.message || message || children}</div>
                    </div>
                    {(modalMessageData.textButton || textButton) && (
                        <div className="Modal-buttons">
                            <button className="Modal-button primary" onClick={closeModal}>
                                {modalMessageData.textButton || textButton}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ModalMessage)
