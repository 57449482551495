import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'
import OrderSummary from 'components/_store-general-vision/order-summary'

import { showErrors } from 'helpers'
import { Order } from 'types'

import { useModalRemoveOrder } from './modal-remove-order.controller'
import {
    Box,
    Container,
    ContainerButtons,
    Footer,
    Icon,
    Message,
    Separator,
    Title,
    Button,
    ContainerSelect,
} from './modal-remove-order.styled'

const OPTIONS = [
    { title: 'ENTREGADOR COM PROBLEMA NO VEÍCULO', value: 'Entregador com problemas no veículo' },
    { title: 'ENTREGADOR SE RECUSOU A ENTREGAR', value: 'Entregador se recusou a entregar' },
    { title: 'ERRO DE ROTEIRIZAÇÃO', value: 'Erro de roteirização' },
]

type Params = {
    order: Order
    onSelectReason?(reason: string): void
}

export type ModalRemoveOrderRef = {
    show?(params: Params): void
}

type Props = {
    revalidate(): void
}

const ModalRemoveOrder = memo(
    forwardRef<ModalRemoveOrderRef, Props>(({ revalidate }, ref) => {
        const {
            visible,
            show,
            order,
            isValid,
            touched,
            errors,
            getFieldProps,
            handleSubmit,
            _setShow,
            _onClose,
            _changeFormValue,
        } = useModalRemoveOrder(revalidate)

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
            }),
            [_setShow]
        )

        if (!visible) {
            return null
        }

        return (
            <Container id="global-modal" visible={visible}>
                <Box show={show}>
                    <>
                        <Title id="title-modal" className="title">
                            Remover Pedido
                        </Title>
                        <Icon icon="exclamation-circle" />
                        <Message id="message-modal">
                            Ao remover o pedido da rota, o mesmo será disponibilizado para ser adicionado a uma nova
                            rota
                        </Message>

                        {order && <OrderSummary order={order} />}
                        <ContainerSelect>
                            <InputItem
                                labelText="Motivo"
                                type="select"
                                options={OPTIONS.map(({ title, value }) => ({ label: title, value }))}
                                inputProps={{
                                    ...getFieldProps('reason'),
                                    onChange: _changeFormValue,
                                    placeholder: 'Selecione o motivo do cancelamento',
                                }}
                            />
                            {touched.reason && errors.reason && showErrors(errors)}
                        </ContainerSelect>
                        <Footer>
                            <>
                                <ContainerButtons>
                                    <Button id="yes-button" onClick={() => handleSubmit()} disabled={!isValid}>
                                        Sim
                                    </Button>
                                </ContainerButtons>
                                <Separator />
                                <ContainerButtons>
                                    <Button id="no-button" isOutline onClick={_onClose}>
                                        Não
                                    </Button>
                                </ContainerButtons>
                            </>
                        </Footer>
                    </>
                </Box>
            </Container>
        )
    })
)

export { ModalRemoveOrder }
