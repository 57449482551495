import styled from 'styled-components'

const RateTable = styled.div`
    width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
`

const TableHeader = styled.div`
    width: 100%;
    padding: 20px;
    border-bottom: 2px solid #e8e8e8;
    height: 30px;
    padding-left: 50px;
    display: none;
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const RateId = styled.div`
    width: 5%;
`

const RateLabel = styled.div`
    width: 15%;
`

const RateDelivery = styled.div`
    width: 18%;
`

const RateStatus = styled.div`
    width: 8%;
`

const TableActions = styled.div`
    width: 30%;
`

const TableBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TableItem = styled.div`
    width: 100%;
`

export { RateTable, TableHeader, RateLabel, RateId, RateDelivery, RateStatus, TableActions, TableBody, TableItem }
