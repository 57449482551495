import { forwardRef, memo, useCallback, useImperativeHandle, useRef, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Mall } from 'types'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    CollapseContainer,
    CollapseContentBody,
    CollapseContentHead,
    CollapseLabel,
    TextButton,
    InputRow,
    Icon,
    TextInfo,
    Title,
    TextContent,
    InputInfo,
    FormLabel,
} from './modal-delivery-modality.styled'

interface ModalDeliveryModality {
    show(): void
    close(): void
}

type IForm = {
    modalities: {
        car?: IModalityData
        van?: IModalityData
        bike?: IModalityData
        moto?: IModalityData
        truck?: IModalityData
    }
}

type IModality = {
    [key: string]: IModalityData
}

type IModalityData = {
    status: boolean
    min_distance?: number
    max_distance?: number
} | null

type IModalityInfo = {
    key: string
    name: string
    icon: IconProp
    info: string
    placeholder: {
        min: string
        max: string
    }
}

const modalities: IModalityInfo[] = [
    {
        key: 'bike',
        name: 'Bicicleta',
        icon: 'bicycle',
        info: 'Esse tipo de Modal é ideal para entregas rápidas e em locais próximos.',
        placeholder: { min: 'EX: 0', max: 'EX: 2' },
    },
    {
        key: 'moto',
        name: 'Motocicleta',
        icon: 'motorcycle',
        info: 'Esse tipo de Modal é ideal para entregas de Média distância e média demanda como rotas com mais de um pedido.',
        placeholder: { min: 'EX: 0', max: 'EX: 6' },
    },
    {
        key: 'car',
        name: 'Carro',
        icon: 'car',
        info: 'Esse tipo de Modal é ideal para entregas de Longa e Média distância e rotas mais complexas como entregas de lojas online.',
        placeholder: { min: 'EX: 0', max: 'EX: 15' },
    },
    {
        key: 'van',
        name: 'Van',
        icon: 'shuttle-van',
        info: 'Esse tipo de Modal é ideal para entregas de Longa e Média distância e rotas  complexa como grandes entregas de lojas online.',
        placeholder: { min: 'EX: 0', max: 'EX: 20' },
    },
    {
        key: 'truck',
        name: 'Caminhão',
        icon: 'truck',
        info: 'Esse tipo de Modal é ideal para entregas de Longa distância e rotas  complexas com grandes entregas de lojas online com produtos grandes e em grande quantidade.',
        placeholder: { min: 'EX: 20', max: 'EX: 100' },
    },
]

const ModalDeliveryModality = memo(
    forwardRef<ModalDeliveryModality>((_, ref) => {
        const { mall } = useAuth()
        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [isLoading, setIsLoading] = useState<boolean>()

        const validateModalityValues = useMemo(
            () =>
                Yup.object().shape({
                    min_distance: Yup.number().nullable(),
                    max_distance: Yup.number()
                        .when('status', {
                            is: status => status === true,
                            then: (schema: Yup.NumberSchema) =>
                                schema.min(0.1, 'Valor de distância máxima é obrigatório.'),
                        })
                        .nullable(),
                }),
            []
        )

        const headerComponent = useMemo(
            () => <FormLabel>Altere os valores para personalizar sua fila de entregadores</FormLabel>,
            []
        )

        const { isSubmitting, handleSubmit, setFieldValue, setValues, getFieldProps, values, touched, errors } =
            useFormik<IForm>({
                initialValues: {
                    modalities: {
                        car: { status: false, min_distance: 0, max_distance: 0 },
                        van: { status: false, min_distance: 0, max_distance: 0 },
                        bike: { status: false, min_distance: 0, max_distance: 0 },
                        moto: { status: false, min_distance: 0, max_distance: 0 },
                        truck: { status: false, min_distance: 0, max_distance: 0 },
                    },
                },
                validationSchema: Yup.object().shape({
                    modalities: Yup.object().shape({
                        car: validateModalityValues,
                        van: validateModalityValues,
                        bike: validateModalityValues,
                        moto: validateModalityValues,
                        truck: validateModalityValues,
                    }),
                }),
                onSubmit: async values => {
                    try {
                        await api.put<Mall>(`/painel/mall/${mall.id}`, {
                            configs: {
                                modality: values.modalities,
                            },
                        })

                        setSuccessModal({
                            title: 'Sucesso!',
                            subtitle: 'Modalidades de entrega atualizadas!',
                            singleButtonClick: () => lateralModalBaseRef.current?.close(),
                        })
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro!',
                            subtitle: 'Ocurreu um erro inesperado, verifique o formulário e tente novamente.',
                        })
                    }
                },
            })

        useImperativeHandle(ref, () => ({
            show: () => {
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
        }))

        const _getModalities = useCallback(async () => {
            setIsLoading(true)
            const {
                data: {
                    configs: { modality },
                },
            } = await api.get<Mall>(`/painel/mall/${mall.id}`)

            _setFormValues(modality)
            setIsLoading(false)
        }, [mall.id])

        const _setFormValues = useCallback((data: IModality) => {
            if (!data) return

            let filteredValues = {} as IModality
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'boolean') {
                    filteredValues = {
                        ...filteredValues,
                        [key]: { status: !!data[key], min_distance: 0, max_distance: 0 },
                    }
                    return
                }
                filteredValues = {
                    ...filteredValues,
                    [key]: {
                        ...data[key],
                    },
                }
            })
            setValues({ modalities: filteredValues })
        }, [])

        const _isModalityActive = useCallback(
            (key: string): boolean => {
                return values.modalities[key]?.status
            },
            [values.modalities]
        )

        const _handleToggle = useCallback(
            (field: string) => () => {
                setFieldValue(`modalities.${field}.status`, !values.modalities[field]?.status)
            },
            [values]
        )

        useEffect(() => {
            _getModalities()
        }, [_getModalities])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title={'Modalidades de Entrega'}
                headerComponent={headerComponent}
            >
                <OutsideContainer>
                    <ContentContainer>
                        <TextInfo>
                            <Title>Como configurar</Title>
                            <TextContent>
                                Cada Modal tem seu contexto ideal de aplicação, seja por distância, volume individual ou
                                volume total das entregas ou tipo de entrega, Configure as distâncias considerando as
                                sugestões e suas próprias necessidades de negócio.
                            </TextContent>
                        </TextInfo>
                        <FormSection>
                            {modalities?.map((item, index) => (
                                <CollapseContainer key={index}>
                                    <CollapseContentHead>
                                        <CollapseLabel>
                                            <Icon icon={item.icon} />
                                            {item.name}
                                        </CollapseLabel>
                                        <InputContainer>
                                            <SwitchButton
                                                isFlex
                                                isActive={_isModalityActive(item.key)}
                                                onClick={_handleToggle(item.key)}
                                                activeLabel={''}
                                                inactiveLabel={''}
                                            />
                                        </InputContainer>
                                    </CollapseContentHead>
                                    <CollapseContentBody isCollapse={_isModalityActive(item.key)}>
                                        <InputRow>
                                            <InputContainer id="input-content">
                                                <InputItem
                                                    type="number"
                                                    labelText="Distância MIN (em metros)"
                                                    inputProps={{
                                                        ...getFieldProps(`modalities.${item.key}.min_distance`),
                                                        placeholder: item.placeholder.min,
                                                        min: 0,
                                                    }}
                                                    errorMessage={
                                                        touched.modalities &&
                                                        touched?.modalities[item.key]?.min_distance &&
                                                        errors.modalities &&
                                                        errors?.modalities[item.key]?.min_distance
                                                    }
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <InputItem
                                                    type="number"
                                                    labelText="Distância MAX (em metros)"
                                                    inputProps={{
                                                        ...getFieldProps(`modalities.${item.key}.max_distance`),
                                                        placeholder: item.placeholder.max,
                                                        min: 0,
                                                    }}
                                                    errorMessage={
                                                        touched.modalities &&
                                                        touched?.modalities[item.key]?.max_distance &&
                                                        errors.modalities &&
                                                        errors?.modalities[item.key]?.max_distance
                                                    }
                                                />
                                            </InputContainer>
                                        </InputRow>
                                        <InputInfo>{item.info}</InputInfo>
                                    </CollapseContentBody>
                                </CollapseContainer>
                            ))}
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="flex-end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>Salvar</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
                <ModalLoading visible={isSubmitting || isLoading} />
            </LateralModalBase>
        )
    })
)

export { ModalDeliveryModality }
