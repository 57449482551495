import { memo } from 'react'
import { useHistory } from 'react-router'

import { format } from 'date-fns'

import { LoadingMessage, LoadingMoreButton } from 'components/_common'

import { formatCurrency } from 'helpers'
import { useAuth } from 'hooks'
import { OrderReport, PerformanceFilters } from 'types'

import {
    CustomerAddress,
    CustomerName,
    DetailsItem,
    OrderClient,
    OrderDate,
    OrderId,
    OrderNeighborhood,
    OrderSmallValue,
    OrderStore,
    OrderTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    ItemButton,
    ButtonText,
    EmptyMessageContainer,
    EmptyImage,
    EmptyMessage,
    ActionHeader,
    ActionTitle,
    ButtonsContainer,
    ActionButton,
} from './period-orders-table.styles'

interface Props {
    filters: PerformanceFilters
    loading: boolean
    onLoadMore(): void
    orders: OrderReport[]
    total: number
    exportClick?(): void
}

const PeriodOrdersTable: React.FC<Props> = memo(({ loading, onLoadMore, orders, total, exportClick }) => {
    const { basePath } = useAuth()
    const history = useHistory()

    return (
        <TableContainer>
            <OrderTable>
                <ActionHeader>
                    <ActionTitle>Relação de pedidos do período</ActionTitle>
                    {orders.length ? (
                        <ButtonsContainer>
                            <ActionButton onClick={exportClick}>Exportar Relatório</ActionButton>
                        </ButtonsContainer>
                    ) : null}
                </ActionHeader>
                <TableHeader>
                    <OrderId>ID</OrderId>
                    <OrderDate>Recebido</OrderDate>
                    <OrderStore>Loja</OrderStore>
                    <OrderClient>Canal de venda</OrderClient>
                    <OrderClient>Cliente e localidade</OrderClient>
                    <OrderNeighborhood>Bairro</OrderNeighborhood>
                    <OrderDate>Entrega</OrderDate>
                    <OrderSmallValue>Tarifa</OrderSmallValue>
                    <OrderSmallValue>Valor</OrderSmallValue>
                    <OrderSmallValue></OrderSmallValue>
                </TableHeader>
                <TableContent>
                    {loading && orders.length === 0 ? (
                        <LoadingMessage />
                    ) : orders.length > 0 ? (
                        <>
                            {orders.map((order, index) => (
                                <TableItem key={index}>
                                    <OrderId>
                                        <span>ID:</span> {order.id}
                                    </OrderId>
                                    <OrderDate>
                                        <span>Recebido:</span>
                                        {format(order.birth, 'DD/MM/YYYY [às] HH:mm')}
                                    </OrderDate>
                                    <OrderStore>
                                        <span>Loja:</span> {order.store}
                                    </OrderStore>
                                    <OrderClient>
                                        <span>Canal de Venda :</span>
                                        {order?.sale_channel}
                                    </OrderClient>
                                    <OrderClient>
                                        <CustomerName>
                                            <span>Cliente:</span>
                                            {order?.client}
                                        </CustomerName>
                                        <CustomerAddress>
                                            <span>Endereço:</span>
                                            {order.street}, {order.number}
                                        </CustomerAddress>
                                    </OrderClient>

                                    <OrderNeighborhood>
                                        <span>Bairro:</span>
                                        {order.neighborhood}
                                    </OrderNeighborhood>
                                    <OrderDate>
                                        <span>Entrega:</span>
                                        {format(order.end_delivery, 'DD/MM/YYYY [às] HH:mm')}
                                    </OrderDate>
                                    <OrderSmallValue>
                                        <span>Tarifa:</span>
                                        {formatCurrency(order.store_delivery_total)}
                                    </OrderSmallValue>
                                    <OrderSmallValue>
                                        <span>Valor:</span>
                                        {formatCurrency(order.total_price)}
                                    </OrderSmallValue>

                                    <OrderSmallValue>
                                        {basePath?.length ? (
                                            <ItemButton
                                                onClick={() => history.push(`${basePath}/detalhes-pedido/${order.id}`)}
                                            >
                                                <DetailsItem />
                                                <ButtonText>Ver detalhes</ButtonText>
                                            </ItemButton>
                                        ) : null}
                                    </OrderSmallValue>
                                </TableItem>
                            ))}
                            {orders.length < total && <LoadingMoreButton loading={loading} onClick={onLoadMore} />}
                        </>
                    ) : (
                        <EmptyMessageContainer>
                            <EmptyImage />
                            <EmptyMessage>Nenhum relatório localizado</EmptyMessage>
                        </EmptyMessageContainer>
                    )}
                </TableContent>
            </OrderTable>
        </TableContainer>
    )
})

export default PeriodOrdersTable
