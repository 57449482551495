import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import ViewControl from 'components/view-control/view-control'

import { Service, Services } from 'containers/stone-management/types'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

import ModalCreateServiceBind, { ModalCreateServiceBindRef } from './components/modal-create-service-bind'

interface ProfileService {
    profile_service_id: string
    profile_service_name: string
    is_active: boolean
}

interface ProfileServices {
    profile_services: ProfileService[]
}

const ProfileServicesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const modalCreate = useRef<ModalCreateServiceBindRef>(null)
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [profileServices, setProfileServices] = useState<ProfileServices>({ profile_services: [] })
    const [services, setServices] = useState<Service[]>([])
    const [establishments, setEstablishments] = useState({ establishment_list: [] })
    const [selectedProfile, setSelectedProfile] = useState('')
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const getProfileServices = useCallback(
        async (profile_id: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get<ProfileServices>(
                    `/services/profile/get-bound-services-list/${profile_id}`
                )

                setSelectedProfile(profile_id)
                setProfileServices(data)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/painel-malls'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    useEffect(() => {
        selectedProfile && getProfileServices(selectedProfile)
    }, [getProfileServices, selectedProfile])

    const getServices = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get<Services>('/services/integrator/get-services-list')

            setServices(data.services)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getServices()
    }, [getServices])

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const deactivateServiceBind = async (profile_service_id: string, profile_id: string) => {
        try {
            setLoadingSubmit(true)

            const { data } = await siclosPagApi.delete(`/services/profile/deactivate/${profile_service_id}`)

            if (data?.error) {
                throw data.msg
            }

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Vínculo de serviço com o perfil desativado com sucesso!',
            })

            getProfileServices(profile_id)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })
        } finally {
            setLoadingSubmit(false)
            modalMessage.current?.openModal()
        }
    }

    const renderEmptyMessage = () => {
        return (
            <EmptyMessage>
                {selectedProfile.length
                    ? 'Não foram encontrados recebedores cadastrados.'
                    : 'Selecione um estabelecimento para filtrar.'}
            </EmptyMessage>
        )
    }

    return (
        <div>
            <ModalLoading visible={loadingSubmit || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <RowTitle
                title="Áreas/Itens/Serviços"
                buttonRow={[
                    {
                        label: 'Criar Vínculo com o perfil',
                        onClick: () => modalCreate.current?.openModal(),
                    },
                ]}
            >
                <SelectInputForm
                    style={{ margin: 0, marginRight: '10px' }}
                    placeholder="Filtrar por estabelecimento"
                    data={establishments.establishment_list?.map(({ id, business_name }) => ({
                        name: business_name,
                        value: id,
                    }))}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedProfile(value)
                    }}
                    value={selectedProfile}
                />
                <div style={{ marginRight: '10px' }}>
                    <ViewControl
                        leftItem="Todos os serviços"
                        rightItem="Vínculos"
                        leftItemActive={false}
                        rightItemActive={true}
                        setLeftItemActive={() => history.replace('/painel-malls/gestao-stone/servicos')}
                        setRightItemActive={() => null}
                    />
                </div>
            </RowTitle>

            <ModalCreateServiceBind
                ref={modalCreate}
                title="Criar novo vínculo"
                setLoadingSubmit={setLoadingSubmit}
                services={services}
                profiles={establishments.establishment_list}
                getProfileServices={(profile_service_id: string) => getProfileServices(profile_service_id)}
                modalMessageRef={modalMessage}
                setModalMessageData={setModalMessageData}
            />

            <FlexTable
                columns={[
                    { name: 'ID', width: '25%' },
                    { name: 'Nome', width: '30%' },
                    { name: 'Status', width: '10%' },
                    { name: '', width: '30%' },
                ]}
                list={profileServices.profile_services?.map(profile_service => {
                    const { profile_service_id, profile_service_name, is_active } = profile_service

                    return {
                        profile_service_id,
                        profile_service_name,
                        is_active: is_active ? 'Ativo' : 'Inativo',
                        buttonRow: (
                            <div className="flex-table-row-content button-row">
                                <div
                                    className="button-item outline"
                                    onClick={() => {
                                        setModalConfirmationData({
                                            title: 'Desativar vínculo de serviço',
                                            message:
                                                'Tem certeza de que deseja desativar o vínculo deste serviço com o perfil?',
                                            onYes: () => deactivateServiceBind(profile_service_id, selectedProfile),
                                        })

                                        modalConfirmation.current?.openModal()
                                    }}
                                >
                                    Desativar
                                </div>
                            </div>
                        ),
                    }
                })}
            />

            {!profileServices.profile_services.length && !loading && renderEmptyMessage()}
        </div>
    )
}

export default ProfileServicesListing
