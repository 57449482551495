import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import DropzoneInput from 'components/dropzone-input/dropzone-input'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import TextArea from 'components/text-area'
import TextInputForm from 'components/text-input-form'

import { getFormInputError, showErrors } from 'helpers'
import { useAxiosRequest } from 'hooks'
import api from 'services/api'
import { ApiPhoto, StoreType } from 'types'

const StoreTypeForm: React.FC<RouteComponentProps<{ store_type_id: string }>> = ({ history, match: { params } }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const { loading } = useAxiosRequest<StoreType>({
        api,
        url: `/painel/store-type/${params.store_type_id}`,
        executeOnStart: params.store_type_id ? true : false,
        onComplete: ({ data }) => {
            const { id } = data

            setValues({
                ...values,
                ...data,
                id: id.toString(),
            })
        },
        onError: onRequestError,
    })

    const { isSubmitting, errors, getFieldProps, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                id: '',
                name: '',
                description: '',
                icon: { id: '' } as ApiPhoto | File,
                banner: { id: '' } as ApiPhoto | File,
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome da vertical é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    const formData = new FormData()

                    formData.set('name', values.name)
                    formData.set('description', values.description)
                    values.icon instanceof File && formData.set('icon', values.icon as File)
                    values.banner instanceof File && formData.set('banner', values.banner as File)
                    formData.set('status', values.status.toString())

                    if (values.id) {
                        formData.set('_method', 'PUT')
                        await api.post(`/painel/store-type/${values.id}`, formData)
                    } else {
                        await api.post('/painel/store-type', formData)
                    }

                    setModalMessageData({
                        title: 'Sucesso!',
                        message: `Vertical de loja ${values.id ? 'editada' : 'criada'} com sucesso!`,
                        onClose: () => {
                            resetForm()
                            history.push('/marketplace/gestao-marketplace/verticais-loja')
                        },
                    })

                    modalMessage.current?.openModal()
                } catch (error) {
                    onRequestError(error)
                } finally {
                    setSubmitting(false)
                }
            },
        })

    return (
        <div>
            <RowTitle
                title={params.store_type_id ? 'Editar vertical de loja' : 'Criar vertical de loja'}
                backClick={() => history.push('/marketplace/gestao-marketplace/verticais-loja')}
            />

            <ModalLoading visible={isSubmitting || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="marketplace-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome*"
                        {...getFieldProps('name')}
                        msgErro={getFormInputError('name', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <TextArea
                        label="Descrição (opcional)"
                        {...getFieldProps('description')}
                        msgErro={getFormInputError('description', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <div className="inputs-row">
                        <DropzoneInput
                            label="Ícone da vertical"
                            onChange={(file: File) => setFieldValue('icon', file)}
                            preview={values.icon}
                            width={200}
                        />
                        <div style={{ margin: '0 15px' }}></div>
                        <DropzoneInput
                            label="Banner da vertical"
                            onChange={(file: File) => setFieldValue('banner', file)}
                            preview={values.banner}
                            width={350}
                        />
                    </div>
                </div>

                <div className="inputs-row col-2">
                    <div className="radio-group">
                        <label>Status</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="true-status"
                                label="Ativa"
                                checked={Boolean(values.status)}
                                onClick={() => setFieldValue('status', 1)}
                            />

                            <InputRadio
                                id="false-status"
                                label="Inativa"
                                checked={!values.status}
                                onClick={() => setFieldValue('status', 0)}
                            />
                        </div>
                    </div>
                </div>

                <ButtonForm type="submit" buttonText="Salvar" style={{ marginTop: 30 }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default StoreTypeForm
