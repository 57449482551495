import { useCallback, useMemo, useState } from 'react'

import { getIn, useFormik } from 'formik'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { api2 } from 'services/api'

type Form = {
    orderId?: number
    customer?: {
        name: string
    }
}

type TCallback = () => void

function useModalChangeCustomerName(refresh: TCallback) {
    const { setErrorModal, setConfirmationModal, setSuccessModal } = useUI()
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const { values, errors, touched, getFieldProps, handleSubmit, resetForm } = useFormik<Form>({
        initialValues: {
            orderId: undefined,
            customer: undefined,
        },
        validationSchema: Yup.object().shape({
            customer: Yup.object().shape({
                name: Yup.string().required('Cliente é obrigatória'),
            }),
        }),
        onSubmit: async values => {
            const _save = async () => {
                setLoading(true)
                try {
                    const { orderId, customer } = values
                    await api2.put(`orders/${orderId}/customer`, customer)
                    setSuccessModal({
                        type: 'success',
                        title: 'Sucesso!',
                        subtitle: 'Cliente atualizado',
                        singleButtonClick() {
                            refresh()
                            _close()
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao atualizar cliente',
                        subtitle: showErrors(error),
                    })
                }
                setLoading(false)
            }
            setConfirmationModal({
                title: 'Atualizar Cliente',
                subtitle: 'Deseja atualizar o cliente do pedido?',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Atualizar',
                type: 'alert',
                rightButtonClick: _save,
            })
        },
    })

    const customer = getIn(values, 'customer.name')

    const isValid = useMemo(() => !!customer && customer !== 'undefined', [customer])

    const _show = useCallback(({ orderId, customerName }: { orderId: number; customerName: string }) => {
        setVisible(true)
        resetForm({
            values: {
                orderId,
                customer: {
                    name: customerName,
                },
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _close = useCallback(() => {
        setVisible(false)
        resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleSubmit = useCallback(() => {
        if (isValid) {
            handleSubmit()
        }
    }, [isValid, handleSubmit])

    return {
        isValid,
        visible,
        loading,
        errors,
        touched,
        getFieldProps,
        _show,
        _close,
        _handleSubmit,
    }
}

export { useModalChangeCustomerName }
