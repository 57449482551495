import { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { Marker, Popup, Cluster } from 'react-mapbox-gl'

import { AgentMarker } from 'containers/agent-marker/agent-marker'

import { useAuth } from 'hooks'

import AgentPopup from '../agent-popup'
import RouteOrdersMarkers from '../route-orders-markers'

const BikersInRouteMarkers = ({
    configs = undefined,
    bikers = undefined,
    routes = undefined,
    history = undefined,
    hoverRoute = undefined,
    onBikerClick = undefined,
    onOrderClick = undefined,
    disableHouver = undefined,
}) => {
    const { basePath } = useAuth()

    const [hoverRouteIdByOrder, setHoverRouteIdByOrder] = useState(null)
    const [hoverBiker, setHoverBiker] = useState(null)
    const [popupBiker, setPopupBiker] = useState(null)

    const agents = useMemo(() => {
        if (hoverBiker) {
            return bikers.filter(item => item?.id === hoverBiker?.id)
        }
        return bikers || []
    }, [bikers, hoverBiker])

    const filteredRoutes = useMemo(() => {
        if (routes && (hoverRouteIdByOrder || hoverBiker || hoverRoute)) {
            return routes.filter(
                r => r.id === hoverRoute?.id || r.id === hoverRouteIdByOrder || r.biker?.id === hoverBiker?.id
            )
        }
        return routes || []
    }, [routes, hoverBiker, hoverRouteIdByOrder, hoverRoute])

    useEffect(() => {
        if (hoverRouteIdByOrder) {
            const route = routes.find(r => r.id === hoverRouteIdByOrder)
            if (route) {
                setHoverBiker(route?.biker)
            }
        } else {
            setHoverBiker(hoverRoute?.biker)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoverRoute, hoverRouteIdByOrder])

    const _clusterMarker = useCallback((coordinates, pointCount) => {
        return (
            <Marker
                key={getRandomInt()}
                coordinates={coordinates}
                style={{
                    width: 40,
                    height: 40,
                    backgroundColor: '#fff',
                    border: 'solid #FF7A20 3px',
                    color: '#FF7A20',
                    display: 'flex',
                    userSelect: 'none',
                    cursor: 'pointer',
                    fontSize: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    borderRadius: '50%',
                }}
            >
                {pointCount}
            </Marker>
        )
    }, [])

    return (
        <>
            {agents && (
                <Cluster ClusterMarkerFactory={_clusterMarker} zoomOnClick>
                    {agents.map(biker => (
                        <Marker
                            key={`${getRandomInt()}-${biker?.id}`}
                            style={{ cursor: 'pointer' }}
                            anchor="bottom"
                            coordinates={[biker?.location?.lng, biker?.location?.lat]}
                            onMouseEnter={!disableHouver ? () => setHoverBiker(biker) : null}
                            onMouseLeave={!disableHouver ? () => setHoverBiker(null) : null}
                            onClick={() => {
                                if (onBikerClick) {
                                    onBikerClick(biker?.id)
                                }
                                setPopupBiker(popupBiker?.id === biker?.id ? null : biker)
                            }}
                        >
                            <AgentMarker
                                routeId={routes?.find(item => item.biker?.id === biker?.id)?.id}
                                alt={`biker-${biker?.id}`}
                                src={biker?.avatar}
                            />
                        </Marker>
                    ))}
                </Cluster>
            )}
            {filteredRoutes.map(route => (
                <RouteOrdersMarkers
                    key={route.id}
                    configs={configs}
                    routeOrders={route?.orders}
                    routeId={route.id}
                    onOrderClick={onOrderClick}
                    onHoverRouteIdByOrder={routeId => setHoverRouteIdByOrder(routeId)}
                    seeDetails={id => history.push(`${basePath}/detalhes-pedido/${id}`)}
                />
            ))}

            {popupBiker && (
                <Popup coordinates={[popupBiker.location?.lng || 0, popupBiker.location?.lat || 0]} offset={[0, -38]}>
                    <AgentPopup
                        routeId={routes?.find(item => item.biker?.id === popupBiker.id)?.id}
                        agent={popupBiker}
                        onClosePopup={() => setPopupBiker(null)}
                    />
                </Popup>
            )}
        </>
    )
}

function getRandomInt() {
    let min = Math.ceil(0)
    let max = Math.floor(9999)
    return Math.floor(Math.random() * (max - min)) + min
}

export default memo(BikersInRouteMarkers)
