import { MouseEventHandler } from 'react'

import { formatPhone, getBikerType } from 'helpers'
import { IAgent } from 'types'
import { getVehicleIcon, getVehicleName } from 'utils/get-vehicle-icon'

import {
    AvailableBikerCardContainer,
    BikerPhotoContainer,
    BikerImage,
    BikerTextContainer,
    BikerName,
    BikerPhoneContainer,
    PhoneIcon,
    PhoneValue,
    InfoRow,
    InfoItem,
    BatteryIcon,
    InfoText,
    WifiIcon,
    CellphoneIcon,
    AndroidIcon,
    MainButton,
    EyeIcon,
    MallIcon,
    Vehicle,
    VehicleIcon,
} from './available-biker-card.styles'

interface Props {
    biker: IAgent
    onRemove?(): void
    onMouseOver?: MouseEventHandler | undefined
    onMouseLeave?: MouseEventHandler | undefined
}

const AvailableBikerCard: React.FC<Props> = ({ biker, onRemove, onMouseOver, onMouseLeave }) => {
    return (
        <AvailableBikerCardContainer onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <BikerPhotoContainer>
                <BikerImage src={biker.avatar} />
            </BikerPhotoContainer>
            <BikerTextContainer>
                <Vehicle>{getBikerType(biker.type)}</Vehicle>
                <BikerName>{biker.name}</BikerName>
                <BikerPhoneContainer>
                    <PhoneIcon />
                    <PhoneValue>{formatPhone(biker.phone)}</PhoneValue>
                </BikerPhoneContainer>
                <BikerPhoneContainer>
                    <MallIcon />
                    <PhoneValue>{biker.device.checkpoint}</PhoneValue>
                </BikerPhoneContainer>
                {!!biker.type && (
                    <BikerPhoneContainer>
                        <VehicleIcon icon={getVehicleIcon(biker.type)} />
                        <PhoneValue>{getVehicleName(biker.type)}</PhoneValue>
                    </BikerPhoneContainer>
                )}

                <InfoRow>
                    <InfoItem>
                        <BatteryIcon />
                        <InfoText>{biker.device.battery}</InfoText>
                    </InfoItem>
                    <InfoItem>
                        <WifiIcon />
                        <InfoText>{biker.device.signal}</InfoText>
                    </InfoItem>
                    <InfoItem>
                        <CellphoneIcon />
                        <InfoText>{biker.device.app}</InfoText>
                    </InfoItem>
                    <InfoItem>
                        <AndroidIcon />
                        <InfoText>{biker.device.android}</InfoText>
                    </InfoItem>

                    {['active', 'suspended'].includes(biker.device.app_status || '') && (
                        <InfoItem>
                            <EyeIcon status={biker.device.app_status} />
                        </InfoItem>
                    )}
                </InfoRow>
            </BikerTextContainer>
            <MainButton onClick={onRemove}>Remover da fila</MainButton>
        </AvailableBikerCardContainer>
    )
}

export default AvailableBikerCard
