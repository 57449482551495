import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'

import { IntegratorStoreListingItem } from 'containers/integrators-page/components/integrator-store-listing-item/integrator-store-listing-item'
import {
    ManagerMercadooIntegrationModal,
    ManagerMercadooIntegrationModalRef,
} from 'containers/integrators-page/components/manager-mercadoo-integration-modal/manager-mercadoo-integration-modal'

import { useUI } from 'contexts'
import api from 'services/api'
import { PagedList, Store } from 'types'

import { ButtonText, Container, ContainerList, ContainerTitle, Content, Title } from './integration-mercadoo.styled'

const IntegrationMercadoo: React.FC = memo(() => {
    const { setErrorModal, setSuccessModal, setConfirmationModal } = useUI()

    const managerMercadooIntegrationModalRef = useRef<ManagerMercadooIntegrationModalRef>()

    const [enabledStores, setEnabledStores] = useState<Store[]>()
    const [loading, setLoading] = useState<boolean>()

    const _getStores = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<Store>>('/painel/stores', {
                params: {
                    marketplace_enable: 1,
                },
            })

            setEnabledStores(data.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Error ao listar lojas.',
            })
        }
        setLoading(false)
    }, [])

    const _updateIntegrations = useCallback(async (storeId: number, body: any) => {
        return await api.put(`/painel/store/${storeId}/mercadoo-integration`, body)
    }, [])

    const _openModal = useCallback(() => {
        managerMercadooIntegrationModalRef.current?.show()
    }, [])

    const _closeModal = useCallback(() => {
        managerMercadooIntegrationModalRef.current?.close()
    }, [])

    const _editIntegrator = useCallback((store: Store) => {
        _openModal()
        managerMercadooIntegrationModalRef.current?.setStore(store)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleRemove = useCallback((storeId: number) => {
        const callback = async () => {
            setLoading(true)
            try {
                await _updateIntegrations(storeId, {
                    marketplace_enable: false,
                })

                _closeModal()

                setSuccessModal({
                    title: 'Sucesso!',
                    subtitle: 'A integração foi removida',
                    singleButtonClick: _getStores,
                })
            } catch (error) {
                console.log(error)

                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível remover integração',
                })
            }
            setLoading(false)
        }

        return () => {
            setConfirmationModal({
                title: 'Excluir Integração.',
                subtitle: 'Deseja mesmo excluir esta integração?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: callback,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        _getStores()
    }, [_getStores])

    return (
        <Container>
            <ManagerMercadooIntegrationModal
                ref={managerMercadooIntegrationModalRef}
                onUpdate={_updateIntegrations}
                onRemove={_handleRemove}
                onClose={_getStores}
            />
            <ModalLoading visible={loading} />
            <Content>
                <ContainerList>
                    <ContainerTitle>
                        <Title>Mercadoo</Title>
                        <ButtonText onClick={_openModal}>
                            Adicionar integrador <FontAwesomeIcon icon="plus-circle" />
                        </ButtonText>
                    </ContainerTitle>

                    <Scroll>
                        {enabledStores?.map((item, i) => (
                            <IntegratorStoreListingItem
                                key={i}
                                image={item.logo}
                                mallName={`${item?.mall?.name ?? 'Sem vínculo a um Mall'} | ${item.name}`}
                                isMercadoo
                                seller={item?.seller_id}
                                integrationsStatus={{
                                    auto_integration: item?.configs?.auto_integration,
                                    marketplace_start_at: Boolean(item.marketplace_start_at),
                                }}
                                status={!!item.status}
                                name={item.name}
                                buttonText="Editar"
                                onClickButton={() => _editIntegrator(item)}
                            />
                        ))}
                    </Scroll>
                </ContainerList>
            </Content>
        </Container>
    )
})

export { IntegrationMercadoo }
