import styled from 'styled-components'

export const InProgressOrders = styled.div`
    width: 90vw;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    min-height: 80vh;
    overflow: hidden;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 94.5vw;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 90vw;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 92vw;
    }
`

export const CardsContainer = styled.div`
    padding: 5px;
    margin: 0;
    margin-left: -5px;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
`

export const ContainerItemIntegration = styled.div`
    width: 90.5%;
    display: flex;
    padding: 8px;
    align-items: center;
    user-select: none;
`

export const Logo = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
    margin-right: 10px;
`
export const StoreName = styled.div`
    font-size: 14px;
`

export const StoreMessage = styled.div`
    font-size: 12px;
    color: #a0a0a0;
`

export const ContainerIntegrationInfo = styled.div`
    flex: 1;
`

export const Status = styled.div<{ active?: boolean; pause?: boolean }>`
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ active, pause, theme }) =>
        pause ? theme.colors.yellow : active ? theme.colors.success : theme.colors.errorMessage};
`
export const StatusBall = styled.div<{ active?: boolean; pause?: boolean }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ active, pause, theme }) =>
        pause ? theme.colors.yellow : active ? theme.colors.success : theme.colors.errorMessage};
`
