import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { formatPhone } from 'helpers'
import { useAuth } from 'hooks'
import { Order } from 'types'

import {
    Contaienr,
    ContainerAvatar,
    Avatar,
    OrderItem,
    ContainerInfo,
    RiderName,
    RiderPhone,
    ContainerOrders,
    DetailsButton,
} from './item-order-card.styled'

interface IData {
    id: number
    referenceId: string
    agentName: string
    channel: string
    payment: string
    agentImage: string
    customer: string
    agentType: 'bike' | 'moto' | null
    agentPhone: string
    storeLogo: string
    orders?: Order[]
}

interface ItemOrderCardProps {
    order?: Order
    onAddOrderClick?(order: Order): void
}

const ItemOrderCard: React.FC<ItemOrderCardProps> = ({ order, onAddOrderClick }) => {
    const { basePath } = useAuth()
    const history = useHistory()

    const data = useMemo<IData | null>(() => {
        if (order) {
            return {
                id: order.id,
                referenceId: order.reference_id,
                agentName: order.agent?.name,
                agentImage: order.agent?.avatar,
                agentType: order.agent?.type_name,
                agentPhone: order.agent?.cellphone,
                channel: order.channel,
                customer: order.customer.name,
                payment: order.payment.name,
                storeLogo: order.merchant.logo,
                orders: [order],
            }
        }

        return null
    }, [order])

    if (!data) {
        return null
    }
    return (
        <Contaienr>
            <ContainerAvatar>
                <Avatar key={data.id} src={data.storeLogo} />
            </ContainerAvatar>
            <ContainerInfo>
                <RiderName>{data.agentName}</RiderName>
                <RiderPhone>{data.agentPhone && formatPhone(data.agentPhone)}</RiderPhone>
                <ContainerOrders>
                    {data.orders.map((item, i) => (
                        <OrderItem key={i} onClick={() => history.push(`${basePath}/detalhes-pedido/${item.id}`)}>
                            #{data.referenceId} <span className="identificator">#{item.id}</span>
                            <div className="merchant">{item.merchant.name}</div>
                            <div className="status">{data.customer}</div>
                            <div className="status">
                                {data.channel} - {data.payment}
                            </div>
                        </OrderItem>
                    ))}
                </ContainerOrders>
            </ContainerInfo>
            <DetailsButton onClick={() => onAddOrderClick(order)}>Adicionar pedido</DetailsButton>
        </Contaienr>
    )
}

export { ItemOrderCard }
