import { IntegrationType } from 'types/integration'

function getIntegrationName(type: IntegrationType) {
    switch (type) {
        case 'ifood':
            return 'Ifood'
        case 'americanas-delivery':
            return 'Americanas Delivery'
        case 'rappi':
            return 'Rappi'
        case 'azul-cargo':
            return 'Azul Cargo'
    }
}

export { getIntegrationName }
