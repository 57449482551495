import { useState } from 'react'

import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'

import { formatCurrency } from 'helpers'
import { SideDish } from 'types'

import {
    ButtonRow,
    CollapsableListContainer,
    CollapseButton,
    CollapsedIcon,
    CollapsedRow,
    DeleteIcon,
    FlexRow,
    GroupTitleText,
    SideDishCategoryContainer,
    SideDishTitleContainer,
    StatusItem,
} from './side-dishes-listing.styled'

interface DishGroup {
    title: string
    items: SideDish[]
}

interface Props {
    group: DishGroup
    deleteClick(id: number): void
    editClick(sideDish: SideDish): void
}

const SideDishComponent: React.FC<Props> = ({ group, editClick, deleteClick }) => {
    const [isListActive, setIsListActive] = useState(false)
    return (
        <SideDishCategoryContainer>
            <SideDishTitleContainer>
                <FlexRow>
                    <GroupTitleText>
                        {group.title} - ({group?.items?.length} {group?.items?.length === 1 ? 'item' : 'itens'})
                    </GroupTitleText>
                    <CollapseButton onClick={() => setIsListActive(!isListActive)}>
                        <CollapsedRow isActive={isListActive}>
                            {isListActive ? 'Ver menos' : 'Ver mais'}
                            <CollapsedIcon />
                        </CollapsedRow>
                    </CollapseButton>
                </FlexRow>
            </SideDishTitleContainer>
            <CollapsableListContainer isActive={isListActive}>
                <FlexTable
                    columns={[
                        { name: 'ID', width: '5%' },
                        { name: 'Nome', width: '12%' },
                        { name: 'Descrição', width: '25%' },
                        { name: 'SKU', width: '10%' },
                        { name: 'Preço', width: '10%' },
                        { name: 'Status', width: '10%' },
                        { name: '', width: '10%' },
                    ]}
                    list={group?.items?.map(sideDish => {
                        const { id, name, description, code_pdv, price, status } = sideDish
                        return {
                            id: id.toString(),
                            name,
                            description,
                            sku: code_pdv,
                            price: formatCurrency(price),
                            status: <StatusItem isActive={Boolean(status)}>{status ? 'Ativo' : 'Inativo'}</StatusItem>,
                            buttonRow: (
                                <ButtonRow>
                                    <DefaultButton
                                        title="Editar"
                                        widthFitContainer
                                        outline
                                        onClick={() => editClick(sideDish)}
                                    />
                                    <DefaultButton
                                        variant="danger"
                                        widthFitContainer
                                        onClick={() => deleteClick(sideDish?.id)}
                                    >
                                        <DeleteIcon />
                                    </DefaultButton>
                                </ButtonRow>
                            ),
                        }
                    })}
                />
            </CollapsableListContainer>
        </SideDishCategoryContainer>
    )
}

export default SideDishComponent
