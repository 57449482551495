import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import * as Feather from 'react-feather'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'

import { useUI } from 'contexts'
import { getFormInputError } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IFloor } from 'types/floor-map'
import { IErrorImport } from 'types/import-error'

import {
    ButtonRow,
    CardFile,
    ConfirmButton,
    ContainerInfoCard,
    ContentContainer,
    DeleteButton,
    DeleteIcon,
    FormLabel,
    FormSection,
    ImagePreview,
    ImagePreviewContent,
    InputContainer,
    Message,
    OutsideContainer,
    Row,
    TextButton,
    TitleFile,
} from './create-floor-modal.styled'

export type CreateFloorModalRef = {
    show(): void
    close(): void
    setFloor(floor: IFloor): void
}

type IForm = {
    id?: number
    mall_id: number
    image?: File | string
    status: number
    label: string
}

type IProps = {
    onClose?(refresh?: boolean): void
    onRemove?(id?: number): () => void
}

const CreateFloorModal = memo(
    forwardRef<CreateFloorModalRef, IProps>(({ onClose, onRemove }, ref) => {
        const { mall } = useAuth()
        const { setLoading, setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [problem, setProblem] = useState<IErrorImport>()
        const [floor, setFloor] = useState<IFloor>()

        const { values, touched, errors, getFieldProps, setFieldValue, setValues, resetForm, handleSubmit } =
            useFormik<IForm>({
                initialValues: {
                    mall_id: mall.id,
                    label: '',
                    status: 1,
                },
                validationSchema: Yup.object().shape({
                    label: Yup.string().required('Título do piso é obrigatório.'),
                }),
                onSubmit: async values => {
                    const formData = new FormData()
                    if (values.id) {
                        formData.append('_method', 'PUT')
                    }

                    Object.keys(values).forEach(key => {
                        if (key !== 'image') {
                            formData.append(key, values[key])
                        }

                        if (values['image'] instanceof File) {
                            formData.append('image', values[key])
                        } else {
                            formData.delete('image')
                        }
                    })

                    const url = `painel/floors/${values.id ?? ''}`

                    setLoading(true)
                    try {
                        await api.post(url, formData)
                        setSuccessModal({
                            title: 'Sucesso!',
                            subtitle: 'Piso registrado com sucesso!',
                            singleButtonClick: _onClose(true),
                        })
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro',
                            subtitle: 'Não foi possível registrar piso.',
                        })
                    } finally {
                        setLoading(false)
                    }
                },
            })

        useImperativeHandle(ref, () => ({
            show: () => {
                resetForm()
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
            setFloor: (floor: IFloor) => {
                setValues({
                    ...values,
                    id: floor.id,
                    label: floor.label,
                    status: Number(floor.status),
                    image: floor.image,
                })
                setFloor(floor)
            },
        }))

        const fileError = useMemo(() => {
            return problem?.errors?.file
        }, [problem])

        const selectedFilePreviewURL = useMemo(() => {
            if (values.image && values.image instanceof File) {
                return window.URL.createObjectURL(values.image)
            }
            return String(values?.image)
        }, [values.image])

        const isEdit = useMemo(() => !!floor, [floor])

        const _onClose = useCallback(
            (refresh: boolean) => () => {
                if (refresh) {
                    onClose(true)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )
        const _handleChangeFile = useCallback((file: File) => {
            setProblem(undefined)
            setFieldValue('image', file)
        }, [])

        const _handleRemoveFile = useCallback(() => {
            setProblem(undefined)
            setFieldValue('image', null)
        }, [])

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title={'Importar Mapa de Atendimento'}
                headerComponent={
                    isEdit &&
                    onRemove && (
                        <DefaultButton widthFitContainer variant="danger" onClick={onRemove(floor.id)}>
                            <DeleteIcon /> Remover
                        </DefaultButton>
                    )
                }
            >
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <FormLabel>
                                {!values.image ? (
                                    <>
                                        <FontAwesomeIcon icon="paperclip" /> Mapa de atendimento
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon="archive" /> Detalhes da imagem
                                    </>
                                )}
                            </FormLabel>
                            {!values.image ? (
                                <FileUploader
                                    hoverTitle="Solte o arquivo"
                                    handleChange={_handleChangeFile}
                                    name="file"
                                    types={['PNG', 'JPG', 'SVG']}
                                >
                                    <CardFile showBorder pointer>
                                        <ContainerInfoCard>
                                            <TitleFile hasFile={!!values.image}>
                                                {(values?.image as File)?.name || 'Selecionar arquivo'}
                                            </TitleFile>
                                            <Message>
                                                Arraste o <span>arquivo</span> para fazer <span>Upload</span> ou{' '}
                                                <span className="link">Clique aqui</span>
                                            </Message>
                                        </ContainerInfoCard>
                                        <Feather.Share className="upload" />
                                    </CardFile>
                                </FileUploader>
                            ) : (
                                <>
                                    <Row>
                                        <DeleteButton onClick={_handleRemoveFile}>
                                            <DeleteIcon />
                                        </DeleteButton>
                                    </Row>
                                    <ImagePreviewContent>
                                        <ImagePreview src={selectedFilePreviewURL} />
                                    </ImagePreviewContent>

                                    <InputContainer>
                                        <InputItem
                                            labelText="Título do piso"
                                            inputProps={getFieldProps('label')}
                                            errorMessage={getFormInputError('label', errors, touched)}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <InputItem
                                            type="select"
                                            labelText="Status"
                                            options={[
                                                { label: 'Ativo', value: 1 },
                                                { label: 'Inativo', value: 0 },
                                            ]}
                                            inputProps={getFieldProps('status')}
                                            errorMessage={getFormInputError('status', errors, touched)}
                                        />
                                    </InputContainer>
                                </>
                            )}
                        </FormSection>
                    </ContentContainer>
                </OutsideContainer>
                {values?.image && (
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>

                            <ConfirmButton type="button" onClick={() => handleSubmit()}>
                                Salvar Mapa
                            </ConfirmButton>
                        </Row>
                    </ButtonRow>
                )}
            </LateralModalBase>
        )
    })
)

export { CreateFloorModal }
