/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { stringify } from 'query-string'
import useSound from 'use-sound'

import { useReduxAction } from 'hooks/useActionRedux'
import { useAuth } from 'hooks/useAuth'
import { useDataFetching } from 'hooks/useDataFetching'
import api from 'services/api'
import {
    AddOrderAction,
    PendingOrdersActions,
    PendingOrdersTypes,
    UpdateOrderAction,
} from 'store/reducers/pendingOrders'
import RootState, { PendingOrdersState } from 'store/reducers/types'
import { PagedList, PendingOrder } from 'types'

const swissBell = '/swiss-bell.mp3'

interface Params {
    mall_id?: number
    store_id?: number
    pending?: number
    status?: number[]
}

interface usePendingOrders {
    pendingOrders: PendingOrdersState
    revalidateOrders: () => Promise<void>
}

export function usePendingOrders(): usePendingOrders {
    const dispatch = useDispatch()
    const pendingOrders = useSelector<RootState, PendingOrdersState>(({ pendingOrders }) => pendingOrders)

    const { mall, store } = useAuth()

    const [playSwissBell] = useSound(swissBell)

    const { setHighlightedOrderId, setLoading, setOrders, setWebSocketConnection } = PendingOrdersActions

    function onOrderChange(order: PendingOrder) {
        playSwissBell()
        dispatch(setHighlightedOrderId(order.id))

        setTimeout(() => {
            dispatch(setHighlightedOrderId(undefined))
        }, 2000)
    }

    useReduxAction<AddOrderAction>(action => onOrderChange(action?.order), PendingOrdersTypes.ADD_ORDER)
    useReduxAction<UpdateOrderAction>(action => onOrderChange(action?.order), PendingOrdersTypes.UPDATE_ORDER)

    useEffect(() => {
        dispatch(setWebSocketConnection('connected', mall, store))

        return () => {
            dispatch(setWebSocketConnection('disconnected', mall, store))
        }
    }, [dispatch, setWebSocketConnection, mall, store])

    async function fetchPendingOrders(params: Params) {
        dispatch(setLoading(true))

        const { data } = await api.get('/mercadoo/orders', { params })

        dispatch(setOrders(data))
        dispatch(setLoading(false))

        return data
    }

    const params = { store_id: store?.id, pending: 1 }
    const { revalidate } = useDataFetching<PagedList<PendingOrder>>(`/mercadoo/orders?${stringify(params)}`, {
        fetcher: () => fetchPendingOrders(params as Params),
    })

    const revalidateOrders = useCallback(async () => {
        await revalidate()
    }, [revalidate])

    return { pendingOrders, revalidateOrders }
}
