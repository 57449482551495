import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchModal } from 'modals/search-modal/search-modal'

import { ContextModal } from 'components/_common'
import BurgerButton from 'components/_common/burger-button'
import ChangePasswordModal from 'components/_new-general-vision/change-password-modal'
import PersonalDataModal from 'components/_new-general-vision/personal-data-modal'

import { useAuth } from 'hooks'

import MobileMenuContent from '../mobile-menu-content/mobile-menu-content'

import {
    LeftSide,
    MenuTitle,
    MobileMenuContainer,
    RightSide,
    NavigationItemContainer,
    SearchIcon,
    SettingIcon,
    ContextIcon,
    SpanId,
} from './mobile-menu.styles'

const MobileMenu: React.FC = () => {
    const searchModalRef = useRef<SearchModal>()
    const history = useHistory()
    const { basePath, mall, store, user } = useAuth()
    const [isContextModalActive, setIsContextModalActive] = useState(false)
    const [isMenuOpened, setIsMenuOpened] = useState(false)

    const [isChangePasswordActive, setIsChangePasswordActive] = useState(false)
    const [isChangeInfoActive, setIsChangeInfoActive] = useState(false)

    function navigate(route: string) {
        if (!route?.length) return
        history.push(route)
    }

    function checkAdmin() {
        return user?.isMallManager
    }

    return (
        <>
            <MobileMenuContainer>
                <LeftSide>
                    <BurgerButton isActive={isMenuOpened} burgerClick={() => setIsMenuOpened(!isMenuOpened)} />
                    <MenuTitle>
                        {store?.name ? store.name : mall?.name ? mall?.name : user?.first_name}{' '}
                        {mall?.type === 'private' && <FontAwesomeIcon style={{ marginLeft: 5 }} icon="lock" />}
                        {store?.id && <SpanId>(ID: {store.id})</SpanId>}
                    </MenuTitle>
                </LeftSide>
                <RightSide>
                    {(mall?.id || store?.id) && (
                        <NavigationItemContainer onClick={() => searchModalRef.current?.show()}>
                            <SearchIcon />
                        </NavigationItemContainer>
                    )}
                    {(!!user?.isMallManager || user?.stores?.length > 1) && (
                        <NavigationItemContainer onClick={() => setIsContextModalActive(true)}>
                            <ContextIcon />
                        </NavigationItemContainer>
                    )}
                    {checkAdmin() && mall?.id && !store?.id && (
                        <NavigationItemContainer onClick={() => navigate(`${basePath}/gestao-mall`)}>
                            <SettingIcon />
                        </NavigationItemContainer>
                    )}
                    {/* <NavigationItemContainer>
                        <PlatformIcon onClick={() => setIsPlatformDropdownOpened(!isPlatformDropdownOpened)} />
                        <PlatformDropdown isActive={isPlatformDropdownOpened}>
                            <CloseButton onClick={() => setIsPlatformDropdownOpened(false)} />
                            <PlatformListing>
                                <PlatformItem>
                                    <RouterImage />
                                    <PlatformText>Roteirizar</PlatformText>
                                </PlatformItem>
                                <PlatformItem>
                                    <DelivererImage />
                                    <PlatformText>Entregador</PlatformText>
                                </PlatformItem>
                                <PlatformItem>
                                    <MercadooImage />
                                    <PlatformText>Mercadoo</PlatformText>
                                </PlatformItem>
                                <PlatformItem>
                                    <PlugImage />
                                    <PlatformText>Plug</PlatformText>
                                </PlatformItem>
                            </PlatformListing>
                            <MallManagementCall>Gerenciar Malls</MallManagementCall>
                        </PlatformDropdown>
                    </NavigationItemContainer> */}
                </RightSide>
            </MobileMenuContainer>
            <ContextModal
                isActive={isContextModalActive}
                setIsActive={setIsContextModalActive}
                closeClick={() => setIsContextModalActive(false)}
            />
            <MobileMenuContent
                isActive={isMenuOpened}
                setIsActive={setIsMenuOpened}
                closeClick={() => setIsMenuOpened(false)}
                infoClick={() => setIsChangeInfoActive(true)}
                passwordClick={() => setIsChangePasswordActive(true)}
            />
            <SearchModal ref={searchModalRef} />
            <ChangePasswordModal
                isActive={isChangePasswordActive}
                closeClick={() => setIsChangePasswordActive(false)}
            />
            <PersonalDataModal isActive={isChangeInfoActive} closeClick={() => setIsChangeInfoActive(false)} />
        </>
    )
}

export default MobileMenu
