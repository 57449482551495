import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useAuth } from 'hooks'
import { AuthThunk } from 'store/thunks'

import {
    adminContent,
    bikersContent,
    financialContent,
    mallsContent,
    storeContent,
    marketplaceContent,
} from '../menu-content'

import {
    ContentContainer,
    LeftSide,
    UserIcon,
    UserName,
    UserRow,
    CloseButton,
    MobileMenuSection,
    MenuSectionTitle,
    ExtranetItem,
    ExtranetText,
    ExtranetIconContainer,
    DoorIcon,
    ExtranetIcon,
    LockIcon,
    AccountIcon,
} from './mobile-menu-content.styles'

interface Props {
    isActive?: boolean
    setIsActive(active: boolean): void
    closeClick(): void
    infoClick(): void
    passwordClick(): void
}

const MobileMenuContent: React.FC<Props> = ({ isActive, closeClick, setIsActive, infoClick, passwordClick }) => {
    const dispatch = useDispatch()
    const { basePath, store, user, mall } = useAuth()
    const history = useHistory()
    const { pathname } = history.location

    const bikers = pathname.includes('entregador') && basePath?.length === 0
    const malls = pathname.includes('/painel-malls')
    const marketplace = pathname.includes('/marketplace')
    const isShopkeeper = user?.isShopkeeper
    const isFinancial =
        user?.isFinancial ||
        pathname.includes('financeiro') ||
        pathname.includes('notas') ||
        pathname.includes('relatorio-individual')

    const params = useMemo(() => {
        const query = new URLSearchParams(history.location.search)
        const store = query.get('store')
        if (store) {
            return `?store=${store}`
        }
        return ''
    }, [history.location.search])

    function navigate(route: string) {
        if (!route?.length) return

        if (route.includes('solicitar-entrega')) {
            const url = new URL(window.location.href)
            if (!url.searchParams.has('modal-request-order')) {
                url.searchParams.append('modal-request-order', '')
                history.push({ search: url.search })
            }
        } else {
            history.push(route)
        }

        setIsActive(false)
    }

    function checkMarketplace(route: string) {
        if (route === 'gestao-marketplace' && store.marketplace_enable === 0) {
            return false
        } else {
            return true
        }
    }

    return (
        <ContentContainer isActive={isActive}>
            <UserRow>
                <LeftSide>
                    <UserIcon />
                    <UserName>{user?.first_name}</UserName>
                </LeftSide>
                <CloseButton onClick={closeClick} />
            </UserRow>

            <MobileMenuSection>
                <MenuSectionTitle>Seções da Extranet</MenuSectionTitle>
                {bikers ? (
                    <>
                        {bikersContent.map((bikerMenuItem, bikerIndex) => (
                            <ExtranetItem key={bikerIndex} onClick={() => navigate(bikerMenuItem.route)}>
                                <ExtranetIconContainer>
                                    <ExtranetIcon icon={bikerMenuItem.icon as IconProp} />
                                </ExtranetIconContainer>
                                <ExtranetText>{bikerMenuItem.label}</ExtranetText>
                            </ExtranetItem>
                        ))}
                    </>
                ) : malls ? (
                    <>
                        {mallsContent.map((mallsMenuItem, mallsIndex) => (
                            <ExtranetItem key={mallsIndex} onClick={() => navigate(mallsMenuItem.route)}>
                                <ExtranetIconContainer>
                                    <ExtranetIcon icon={mallsMenuItem.icon as IconProp} />
                                </ExtranetIconContainer>
                                <ExtranetText>{mallsMenuItem.label}</ExtranetText>
                            </ExtranetItem>
                        ))}
                    </>
                ) : marketplace ? (
                    <>
                        {marketplaceContent.map((item, i) => (
                            <ExtranetItem key={i} onClick={() => navigate(item.route)}>
                                <ExtranetIconContainer>
                                    <ExtranetIcon icon={item.icon as IconProp} />
                                </ExtranetIconContainer>
                                <ExtranetText>{item.label}</ExtranetText>
                            </ExtranetItem>
                        ))}
                    </>
                ) : isShopkeeper || store?.id ? (
                    <>
                        {storeContent.map((storeMenuItem, storeMenuIndex) => {
                            const route = basePath.length ? `${basePath}/${storeMenuItem.route}` : ''
                            return (
                                <ExtranetItem
                                    key={storeMenuIndex}
                                    isShown={checkMarketplace(storeMenuItem.route)}
                                    onClick={() => navigate(route)}
                                >
                                    <ExtranetIconContainer>
                                        <ExtranetIcon icon={storeMenuItem.icon as IconProp} />
                                    </ExtranetIconContainer>
                                    <ExtranetText>{storeMenuItem.label}</ExtranetText>
                                </ExtranetItem>
                            )
                        })}
                    </>
                ) : isFinancial ? (
                    <>
                        {financialContent.map((financialMenuItem, financialMenuItemIndex) => {
                            return (
                                <ExtranetItem
                                    key={financialMenuItemIndex}
                                    onClick={() => navigate(financialMenuItem.route)}
                                >
                                    <ExtranetIconContainer>
                                        <ExtranetIcon icon={financialMenuItem.icon as IconProp} />
                                    </ExtranetIconContainer>
                                    <ExtranetText>{financialMenuItem.label}</ExtranetText>
                                </ExtranetItem>
                            )
                        })}
                    </>
                ) : (
                    <>
                        {adminContent.map((adminMenuItem, i) => {
                            if (
                                ['financeiro', 'entregadores-autorizados'].includes(adminMenuItem.route) &&
                                mall.type === 'public'
                            ) {
                                return null
                            }

                            const route = basePath.length ? `${basePath}/${adminMenuItem.route}${params}` : ''
                            return (
                                <ExtranetItem key={i} onClick={() => navigate(route)}>
                                    <ExtranetIconContainer>
                                        <ExtranetIcon icon={adminMenuItem.icon as IconProp} />
                                    </ExtranetIconContainer>
                                    <ExtranetText>{adminMenuItem.label}</ExtranetText>
                                </ExtranetItem>
                            )
                        })}
                    </>
                )}
            </MobileMenuSection>
            <MobileMenuSection>
                <MenuSectionTitle>Conta</MenuSectionTitle>
                <ExtranetItem onClick={infoClick}>
                    <ExtranetIconContainer>
                        <AccountIcon />
                    </ExtranetIconContainer>
                    <ExtranetText>Alterar Informações</ExtranetText>
                </ExtranetItem>
                <ExtranetItem onClick={passwordClick}>
                    <ExtranetIconContainer>
                        <LockIcon />
                    </ExtranetIconContainer>
                    <ExtranetText>Alterar Senha</ExtranetText>
                </ExtranetItem>
                <ExtranetItem onClick={() => dispatch(AuthThunk.logout())}>
                    <ExtranetIconContainer>
                        <DoorIcon />
                    </ExtranetIconContainer>
                    <ExtranetText>Sair</ExtranetText>
                </ExtranetItem>
            </MobileMenuSection>
        </ContentContainer>
    )
}

export default MobileMenuContent
