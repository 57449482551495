import { push } from 'connected-react-router'

import { getTokenPlooga } from 'helpers/plooga-utils'
import { updateIntegration } from 'store/reducers/integrations/integrations'

import api from '../../services/api'
import { Creators as AuthActions } from '../reducers/auth'
import { Creators as UtilsActions } from '../reducers/utils'

const login = (email, password) => {
    return async dispatch => {
        try {
            dispatch(AuthActions.authLoading(true))
            const { data } = await api.post('/administrator/login', { email, password })

            localStorage.setItem('@extranet.logaroo:token', data.token)

            const isAdmin = data.roles.some(item => item.role === 'admin')
            const isManager = data.roles.some(item => item.role === 'manager')
            const isMallManager = data.roles.some(item => item.role === 'admin' || item.role === 'manager')
            const isFinancial = data.roles.some(item => item.role === 'financial')
            const isShopkeeper = data.roles.some(item => item.role === 'owner' || item.role === 'staff')
            const isStaff = data.roles.some(item => item.role === 'staff')

            dispatch(
                AuthActions.setUserData({
                    ...data,
                    isAdmin,
                    isMallManager,
                    isManager,
                    isShopkeeper,
                    isStaff,
                    isFinancial,
                })
            )

            if (isShopkeeper) {
                const [store] = data.stores

                if (data.stores && data.stores.length) {
                    await Promise.all([
                        dispatch(AuthActions.setStore(store)),
                        dispatch(AuthActions.setMall(store.mall)),
                    ])

                    dispatch(push(`/${store.mall?.slug}/${store.slug}/visao-geral`))
                } else {
                    dispatch(
                        UtilsActions.setModalMessageData({
                            isActive: true,
                            title: 'Erro no login.',
                            message: 'O lojista não possui nenhuma loja',
                        })
                    )
                }
            } else {
                dispatch(push('/escolher-unidade'))
            }
        } catch (error) {
            localStorage.clear()
            console.log('error login', error)
            console.log('error login', error.response)

            let modalMessageData = {
                isActive: true,
                title: 'Erro no login.',
                message: 'Ocorreu um erro no login. Tente novamente mais tarde.',
            }

            if (error.response) {
                if (error.response.status === 422) {
                    modalMessageData.title = 'Login inválido.'
                    modalMessageData.message = 'Não foi possível realizar login. Verifique os campos e tente novamente.'
                } else if (error.response.status === 401 || error.response.status === 403) {
                    modalMessageData.title = 'Não autorizado.'
                    modalMessageData.message = 'Email ou senha incorretos. Verifique os campos e tente novamente.'
                }
            }

            dispatch(UtilsActions.setModalMessageData(modalMessageData))
        } finally {
            dispatch(AuthActions.authLoading(false))
        }
    }
}

const logout = () => {
    return async dispatch => {
        try {
            localStorage.clear()
            dispatch(AuthActions.removeUserData())
        } catch (error) {
            console.log(error)
            dispatch(AuthActions.authLoading(false))
        }
    }
}

const refreshToken = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(AuthActions.refreshLoading(true))

            const response = await api.get('/painel/refresh')
            localStorage.setItem('@extranet.logaroo:token', response.data.token)
            const ploogaToken = await getTokenPlooga()
            if (ploogaToken) {
                dispatch(updateIntegration({ token: ploogaToken }))
            }
            dispatch(AuthActions.setUserData(getState().auth.user))
            dispatch(push('/escolher-unidade'))
        } catch (error) {
            console.log(error)
            localStorage.clear()
        } finally {
            dispatch(AuthActions.refreshLoading(false))
        }
    }
}

const recoverPassword = email => {
    return async dispatch => {
        try {
            dispatch(AuthActions.authLoading(true))

            const response = await api.post('/recover', { email, origin: 'router' })

            const modalMessageData = {
                isActive: true,
                title: 'Sucesso',
                message: response.data,
            }

            dispatch(UtilsActions.setModalMessageData(modalMessageData))
        } catch (error) {
            let modalMessageData = {}

            if (error.response.status === 422) {
                modalMessageData.isActive = true
                modalMessageData.title = 'Email inválido'
                modalMessageData.message =
                    'Não foi possível realizar o processo de recuperação de senha. Verifique os dados digitados e tente novamente.'
            } else {
                modalMessageData.isActive = true
                modalMessageData.title = 'Erro'
                modalMessageData.message = 'Ocorreu um erro na recuperação de senha. Tente novamente mais tarde.'
            }

            dispatch(UtilsActions.setModalMessageData(modalMessageData))
        } finally {
            dispatch(AuthActions.authLoading(false))
        }
    }
}

export const AuthThunk = {
    login,
    logout,
    refreshToken,
    recoverPassword,
}
