import {
    faStoreAlt,
    faCog,
    faThLarge,
    faSearch,
    faUser,
    faLock,
    faDoorOpen,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import deliverer from '../../../assets/images/deliverer-icon.png'
import mercadoo from '../../../assets/images/mercadoo.png'
import plug from '../../../assets/images/plug.png'
import router from '../../../assets/images/router.png'

export const NavigationBarContainer = styled.div`
    display: none;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 16px;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
    }
`

export const NavigationBarTitle = styled.div`
    font-weight: bold;
    display: flex;
    font-size: 22px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 30px;
    }
`

export const NavigationIconsRow = styled.div`
    display: flex;
    align-items: center;
`

export const NavigationItemContainer = styled.div`
    margin-left: 30px;
    font-size: 20px;
    color: #717171;
    display: flex;
    align-items: flex-end;
    position: relative;
`

export const StoreIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})`
    cursor: pointer;
`

export const SettingIcon = styled(FontAwesomeIcon).attrs({
    icon: faCog,
})`
    cursor: pointer;
`

export const PlatformIcon = styled(FontAwesomeIcon).attrs({
    icon: faThLarge,
})`
    cursor: pointer;
`

export const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    margin-left: 10px;
    cursor: pointer;
`

export const SearchInput = styled.input.attrs({
    placeholder: 'Buscar...',
})<{ isOpened?: boolean }>`
    border: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
    font-size: 16px;
    width: ${({ isOpened }) => (isOpened ? '200px' : 0)};
    outline: none;
    padding: ${({ isOpened }) => (isOpened ? '5px' : 0)};
    padding-top: 0;
    padding-bottom: 0;
    transition: 400ms;
`

export const UserInfoContainer = styled.div`
    border-radius: 6px;
    margin-left: 40px;
    position: relative;
    max-width: 350px;
`

export const UserTitleContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    flex: 1;
    padding: 10px;
    user-select: none;
`

export const UserIcon = styled(FontAwesomeIcon).attrs({
    icon: faUser,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
`
export const IconLock = styled(FontAwesomeIcon).attrs({
    icon: faLock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 10px;
`

export const UserName = styled.div`
    font-weight: 600;
    flex: 1;
    max-height: 36px;
    overflow-y: hidden;
    font-size: 14px;
`

export const UserDropdown = styled.div<{ isActive?: boolean }>`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 110%;
    left: 0;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    z-index: 50;
`

export const UserItem = styled.div`
    display: flex;
    padding: 0px 15px;
    align-items: center;
    color: #666;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const UserIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`

export const DropdownDataIcon = styled(FontAwesomeIcon).attrs({
    icon: faUser,
})``

export const DropdownLockIcon = styled(FontAwesomeIcon).attrs({
    icon: faLock,
})``

export const DropdownLogoutIcon = styled(FontAwesomeIcon).attrs({
    icon: faDoorOpen,
})``

export const DropdownText = styled.div`
    font-size: 12px;
`

export const PlatformDropdown = styled.div<{ isActive?: boolean }>`
    user-select: none;
    width: 180px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 40px;
    left: -80px;
    border-radius: 6px;
    padding-bottom: 10px;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    padding-top: 20px;
    z-index: 999;
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: #ccc;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    user-select: none;
`

export const PlatformListing = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const PlatformItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 40%;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 5px;
    transition: 400ms;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`

export const PlatformText = styled.div`
    font-size: 10px;
    margin-top: 5px;
`

export const MercadooImage = styled.img.attrs({
    src: mercadoo,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const PlugImage = styled.img.attrs({
    src: plug,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const RouterImage = styled.img.attrs({
    src: router,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const DelivererImage = styled.img.attrs({
    src: deliverer,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const MallManagementCall = styled.div`
    text-align: center;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.lightGray};
    padding-top: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const SpanId = styled.span`
    font-size: 12px;
    color: #c1c1c1;
    margin-left: 10px;
`
