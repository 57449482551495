/**
 * Returns whether an object is empty or not
 *
 * @param {Object} obj
 */
const objectIsEmpty = obj => {
    for (const prop in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj?.hasOwnProperty(prop)) return false
    }

    return true
}

export { objectIsEmpty }
