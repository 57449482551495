import React, { memo } from 'react'

import { Actions, Color, HeaderContainer, ID, Label, Status } from './sales-channels-listing-table-header.styled'

const SalesChannelsListingTableHeader: React.FC<{ bordered?: boolean }> = memo(({ bordered }) => {
    return (
        <HeaderContainer bordered={bordered}>
            <ID>ID</ID>
            <Label>Nome do Canal</Label>
            <Color>Cor</Color>
            <Status>Status</Status>
            <Actions />
        </HeaderContainer>
    )
})

export { SalesChannelsListingTableHeader }
