/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState, useContext, useRef, useEffect, useLayoutEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { useAuth } from 'hooks'

import MapboxView from '../mapbox-view'

import GlobalMapContext from './global-map.contexts'
import { Container, ContainerMirror } from './global-map.styled'
import { MapLayoutProps } from './global-map.types'
const GlobalMap: React.FC = ({ children }) => {
    const { user } = useAuth()
    const mapBoxRef = useRef(null)
    const [mapBoxComponent, setMapBoxComponent] = useState(null)
    const [display, setDisplay] = useState<'block' | 'none'>('none')
    const [bounds, setBounds] = useState<any>(null)
    const [boundsOptions, setBoundsOptions] = useState<any>(null)
    const [markerCenter, setMarkerCenter] = useState<number[] | null>(null)
    const [contentComponent, setContentComponent] = useState(null)
    const [onClick, setOnclick] = useState({ exec: () => {} })

    const [layoutProps, setLayoutProps] = useState<MapLayoutProps>({
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'none',
    })
    const _setMapBoxContentComponent = useCallback(component => {
        setMapBoxComponent(component)
    }, [])

    const _setContentComponent = useCallback(component => {
        setContentComponent(component)
    }, [])

    const _setLayoutProps = useCallback(props => {
        setLayoutProps(props)
    }, [])

    const _setDisplay = useCallback(value => {
        setDisplay(value)
    }, [])

    const _setBounds = useCallback(bounds => {
        setBounds(bounds)
    }, [])

    const _setBoundsOptions = useCallback(options => {
        setBoundsOptions(options)
    }, [])

    const _setCenter = useCallback(() => {
        mapBoxRef.current?.center()
    }, [])
    const _setZoom = useCallback((zoom: number) => {
        mapBoxRef.current?.setZoom(zoom)
    }, [])

    const _setMarkerCenter = useCallback(markr => {
        setMarkerCenter(markr)
    }, [])
    const _onClick = useCallback(callback => {
        setOnclick({ exec: callback })
    }, [])

    const _resizeMap = useCallback(() => {
        mapBoxRef.current?.resize()
    }, [mapBoxRef])

    return (
        <GlobalMapContext.Provider
            value={{
                setMapLayoutProps: _setLayoutProps,
                setMapBoxContentComponent: _setMapBoxContentComponent,
                setDisplay: _setDisplay,
                resizeMap: _resizeMap,
                setFitBounds: _setBounds,
                setFitBoundsOptions: _setBoundsOptions,
                setCenter: _setCenter,
                setZoom: _setZoom,
                setMarkerCenter: _setMarkerCenter,
                setContentComponent: _setContentComponent,
                onClickMap: _onClick,
            }}
        >
            <Container className="global-map" {...layoutProps} display={display}>
                {!!user && (
                    <MapboxView
                        ref={mapBoxRef}
                        markerCenter={markerCenter}
                        fitBounds={bounds}
                        fitBoundsOptions={boundsOptions}
                        onClick={onClick?.exec}
                    >
                        {mapBoxComponent}
                    </MapboxView>
                )}
                {contentComponent}
            </Container>
            {children}
        </GlobalMapContext.Provider>
    )
}

const MirrorMap: React.FC = () => {
    const { setMapLayoutProps, setDisplay, resizeMap, setFitBoundsOptions, setFitBounds, setCenter, setMarkerCenter } =
        useContext(GlobalMapContext)

    const { width, height, ref } = useResizeDetector()
    useEffect(() => {
        if (ref.current) {
            const { top, left } = ref.current.getBoundingClientRect()
            const windowTop = window.scrollY + top
            const windowLeft = window.scrollX + left
            const props = { width: width, height: height, top: windowTop, left: windowLeft }
            if (setMapLayoutProps) {
                setMapLayoutProps(props)
            }
        }
    }, [width, height, ref])

    useLayoutEffect(() => {
        setTimeout(() => {
            resizeMap()
        }, 300)
        setMarkerCenter(null)
        setCenter()
        setDisplay('block')
        return () => {
            setDisplay('none')
            setFitBounds(null)
            setFitBoundsOptions(null)
        }
    }, [])

    return <ContainerMirror ref={ref} className="container-mirror" />
}

export { GlobalMap, MirrorMap }
