import { Component } from 'react'

import './style.scss'
export default class OrderModerationTopRow extends Component {
    render() {
        return (
            <div className="route-creation-top-row-container">
                <div className="row-item">
                    <div className="left-column">
                        <div className="top-row-title">Moderação de Pedidos</div>
                    </div>
                </div>
            </div>
        )
    }
}
