import styled from 'styled-components'

import detail from '../../../assets/images/detail_icon.png'

export const TableContainer = styled.div`
    background-color: #d8d8d8;
    padding: 20px;
    flex: 1;
    min-height: 500px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        min-height: 0;
        height: unset;
    }
`

export const RouteTable = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`

export const TableHeader = styled.div`
    display: none;
    align-items: center;
    background-color: #f6f6f6;
    padding: 10px;
    overflow: hidden;
    border-radius: 12px 12px 1px 1px;
    border-bottom: 2px solid #ded3d3;
    color: #999;
    font-weight: 600;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
    }
`

export const RouteDate = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 15%;
        padding-right: 3%;
    }
`

export const RouteId = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 5%;
        padding-right: 2%;
    }
`

export const RouteType = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 15%;
        padding-right: 2%;
    }
`

export const RouteOrders = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 30%;
        padding-right: 2%;
    }
`

export const RouteBiker = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 20%;
        padding-right: 2%;
    }
`

export const RouteSmallValue = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 10%;
        padding-right: 2%;
    }
`

export const TableContent = styled.div`
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const TableItem = styled.div`
    flex: 1;
    padding: 20px;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        align-items: center;
        padding: 10px;
    }
    &:nth-child(even) {
        background-color: #eee;
    }
    span {
        font-weight: bold;
        margin-right: 5px;
        @media (min-width: ${({ theme }) => theme.devices.desktop}) {
            display: none;
        }
    }
`

export const CustomerName = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
    }
`

export const CustomerAddress = styled.div``

export const DetailsItem = styled.img.attrs({
    src: detail,
})`
    width: 25px;
    cursor: pointer;
    transition: 400ms;
`

export const ItemButton = styled.div`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    background-color: transparent;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        border: 0;
        justify-content: flex-end;
        margin-top: 0;
    }
`

export const ButtonText = styled.div`
    margin-left: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const OrderItem = styled.div`
    &:not(:last-child) {
        margin-bottom: 10px;
    }
`

export const OrderId = styled.div`
    font-size: 12px;
    color: #aaa;
    font-weight: bold;
`
