import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatPhone } from 'helpers'
import { Biker } from 'types'

import {
    Icon,
    ContainerButtons,
    ButtonIcon,
    Contaienr,
    ContainerAvatar,
    ContainerBadge,
    Avatar,
    ContainerInfo,
    RiderName,
    TypeAgent,
    RiderPhone,
    TextButton,
} from './item-allowed-deliveryman.styled'

interface ItemAllowedDeliverymanProps {
    biker: Biker
    viewClick?(): void
    blockClick?(): void
}
const Vehicles = {
    bike: 'Bicicleta',
    moto: 'Moto',
    car: 'Carro',
    van: 'Van',
    truck: 'Caminhão',
}
const VehiclesIcon = {
    bike: 'bicycle',
    moto: 'motorcycle',
    car: 'car',
    van: 'shuttle-van',
    truck: 'truck',
}

const ItemAllowedDeliveryman: React.FC<ItemAllowedDeliverymanProps> = ({ biker, viewClick, blockClick }) => {
    if (!biker) {
        return null
    }
    return (
        <>
            <Contaienr>
                <ContainerAvatar>
                    {!!biker.type_name && (
                        <ContainerBadge>{<Icon icon={VehiclesIcon[biker.type_name] as any} />}</ContainerBadge>
                    )}
                    <Avatar src={biker.avatar + '?width=100&height=100'} />
                </ContainerAvatar>
                <ContainerInfo>
                    {!!biker.type_name && <TypeAgent>{Vehicles[biker.type_name]}</TypeAgent>}
                    <RiderName>{biker.name}</RiderName>
                    <RiderPhone>{biker.cellphone && formatPhone(biker.cellphone)}</RiderPhone>
                </ContainerInfo>
                <ContainerButtons>
                    {!!viewClick && (
                        <ButtonIcon onClick={viewClick}>
                            <FontAwesomeIcon className="icon" icon="user" />
                            <TextButton>Moderar</TextButton>
                        </ButtonIcon>
                    )}
                    {/* {!!blockClick && (
                        <ButtonIcon onClick={blockClick}>
                            <FontAwesomeIcon className="icon" icon="exclamation-circle" />
                            <TextButton>Bloquear</TextButton>
                        </ButtonIcon>
                    )} */}
                </ContainerButtons>
            </Contaienr>
        </>
    )
}

export { ItemAllowedDeliveryman }
