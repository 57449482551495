import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { formatCurrency, getFormInputError, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList, SideDish, SideDishCategory } from 'types'

import {
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    ButtonRow,
    ConfirmButton,
} from './side-dish-modal.styled'

export type SideDishModalRef = {
    show(): void
    close(): void
    setSideDish?(sideDish: SideDishCategory): void
}

type Props = {
    onClose?(refresh?: boolean): void
}

type IForm = {
    id?: number
    name: string
    description?: string
    category_id: number
    code_pdv: string
    price: number
    status: number
}

const SideDishModal = memo(
    forwardRef<SideDishModalRef, Props>(({ onClose }, ref) => {
        const { store } = useAuth()
        const { setLoading, setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [sideDish, setSideDish] = useState<SideDish>()
        const [sideDishCategories, setSideDishCategories] = useState<SideDishCategory[]>()

        const {
            isSubmitting,
            errors,
            getFieldProps,
            handleSubmit,
            resetForm,
            setFieldValue,
            setValues,
            touched,
            values,
        } = useFormik<IForm>({
            initialValues: {
                name: '',
                description: '',
                code_pdv: '',
                category_id: null,
                price: null,
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome do acompanhamento é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    if (values.id) {
                        await api.put(`/painel/side-dish/${values.id}`, values)
                    } else {
                        await api.post('/painel/side-dish', {
                            ...values,
                            store_id: store.id,
                        })
                    }

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: `Acompanhamento ${isEdit ? 'atualizado' : 'registrado'} com sucesso`,
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Error',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setSubmitting(false)
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setSideDish(null)
                    setFieldValue('category_id', 0)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setSideDish: (sideDish: SideDish & { category_id: number }) => {
                    setSideDish(sideDish)
                    setValues({ ...sideDish, category_id: sideDish?.category.id })
                },
            }),
            []
        )

        const isEdit = useMemo(() => {
            return !!sideDish
        }, [sideDish])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Editar' : 'Criar'} Acompanhamento`
        }, [isEdit])

        const _getSideDishCategories = useCallback(async () => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<SideDishCategory>>('/painel/side-dish-categories', {
                    params: { store_id: store.id, per_page: 999 },
                })

                setSideDishCategories(state =>
                    Number(data?.current_page) > 1 && state ? [...state, ...data.items] : data.items
                )
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: showErrors(error),
                })
            } finally {
                setLoading(false)
            }
        }, [store])

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }
                resetForm()
                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        useEffect(() => {
            _getSideDishCategories()
        }, [_getSideDishCategories])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={{
                                        ...getFieldProps('name'),
                                        placeholder: 'Dê um nome para esse novo acompanhamento',
                                    }}
                                    errorMessage={getFormInputError('name', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="textarea"
                                    big
                                    labelText="Descrição"
                                    inputProps={{
                                        ...getFieldProps('description'),
                                        placeholder: '(opcional) Descreve do que se trata esse acompanhamento',
                                    }}
                                    errorMessage={getFormInputError('description', errors, touched)}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="SKU"
                                    inputProps={{
                                        ...getFieldProps('code_pdv'),
                                        placeholder: 'SKU de acompanhamento',
                                    }}
                                    errorMessage={getFormInputError('name', errors, touched)}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="Categorias de Acompanhamento"
                                    type="select"
                                    options={sideDishCategories?.map(sideDishCategory => ({
                                        label: sideDishCategory.name,
                                        value: sideDishCategory.id,
                                    }))}
                                    inputProps={{
                                        ...getFieldProps('category_id'),
                                        placeholder: 'Selecione uma das categorias pré cadastradas',
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="Preço"
                                    type="currency"
                                    inputProps={{
                                        ...getFieldProps('price'),
                                        value: formatCurrency(values.price),
                                        placeholder: 'Valor em reais do produto, ex: R$ 1,00',
                                    }}
                                    errorMessage={getFormInputError('name', errors, touched)}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={[
                                        { label: 'Ativo', value: 1 },
                                        { label: 'Inativo', value: 0 },
                                    ]}
                                    inputProps={getFieldProps('status')}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <ConfirmButton title="salvar" onClick={() => handleSubmit()} />
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { SideDishModal }
