import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const EmptyMessageContainer = styled.div`
    margin: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    padding: 70px;
    min-height: 300px;
`

export const EmptyImage = styled.img`
    height: 160px;
    margin-bottom: 20px;
`

export const EmptyMessage = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: bold;
`

export const EmptyMessageIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 42px;
    margin-bottom: 20px;
`
