import colors from '../themes/colors'

/**
 *
 * @param {number} minutes
 */

const getStatusColorProduction = (minutes, configs) => {
    const seconds = minutes * 60

    if (configs?.production) {
        const { on_time, attention, late } = configs.production

        switch (true) {
            case seconds <= on_time:
                return colors.green
            case seconds > on_time && seconds <= attention:
                return colors.yellow
            case seconds >= late:
                return colors.red
            default:
                return colors.light_gray_color
        }
    } else {
        return colors.light_gray_color
    }
}

export { getStatusColorProduction }
