import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import TextInputForm from 'components/text-input-form'
import TextInputMask from 'components/text-input-mask'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

const EstablishmentsCreateCredentialed: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const { isSubmitting, errors, handleSubmit, resetForm, setFieldValue, touched, values } = useFormik({
        initialValues: {
            is_establishment_to_production: false,
            legal_name: '',
            business_name: '',
            document_number: '',
            stone_code: '',
            address: {
                zip_code: '',
                number: '',
                complement: '',
            },
        },
        validationSchema: Yup.object().shape({
            legal_name: Yup.string().trim().required('Razão Social é obrigatório'),
            business_name: Yup.string().trim().required('Nome Fantasia é obrigatório'),
            document_number: Yup.string().trim().required('CNPJ é obrigatório'),
            address: Yup.object().shape({
                zip_code: Yup.string().trim().required('CEP é obrigatório'),
                number: Yup.string().trim().required('Número é obrigatório'),
            }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                const { data } = await siclosPagApi.post('/establishment/create-stone-credentialed', {
                    ...values,
                    document_number: values.document_number.replace(/\D+/g, ''),
                    address: {
                        ...values.address,
                        zip_code: values.address.zip_code.replace(/\D+/g, ''),
                    },
                })

                if (data?.error) {
                    throw data.msg
                }

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Estabelecimento criado com sucesso!',
                    onClose: () => history.push('/painel-malls/gestao-stone'),
                })

                modalMessage.current?.openModal()
                resetForm()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    isActive: true,
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessage.current?.openModal()
            } finally {
                setSubmitting(false)
            }
        },
    })

    function handleInputChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field, value)
        }
    }

    return (
        <div>
            <RowTitle
                title="Criar estabelecimento já credenciado"
                backClick={() => history.push('/painel-malls/gestao-stone')}
            />

            <ModalLoading visible={isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="stone-form-container">
                <div className="inputs-row col-2">
                    <TextInputForm
                        label="Razão Social*"
                        onChange={handleInputChange('legal_name')}
                        value={values.legal_name}
                        msgErro={touched.legal_name && errors.legal_name}
                    />
                    <TextInputForm
                        label="Nome Fantasia*"
                        onChange={handleInputChange('business_name')}
                        value={values.business_name}
                        msgErro={touched.business_name && errors.business_name}
                    />
                </div>

                <div className="inputs-row col-2">
                    <TextInputMask
                        label="CNPJ do estabelecimento*"
                        mask="99.999.999/9999-99"
                        onChange={handleInputChange('document_number')}
                        value={values.document_number}
                        msgErro={touched.document_number && errors.document_number}
                    />

                    <TextInputForm
                        label="Stone code*"
                        onChange={handleInputChange('stone_code')}
                        value={values.stone_code}
                        msgErro={touched.stone_code && errors.stone_code}
                    />

                    <div className="radio-group">
                        <label>Ativo em produção?</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="is_establishment_to_production"
                                label="Sim"
                                checked={values.is_establishment_to_production}
                                onClick={() => setFieldValue('is_establishment_to_production', true)}
                            />

                            <InputRadio
                                id="is_establishment_not_to_production"
                                label="Não"
                                checked={!values.is_establishment_to_production}
                                onClick={() => setFieldValue('is_establishment_to_production', false)}
                            />
                        </div>
                    </div>
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Endereço
                </div>

                <div className="inputs-row col-3">
                    <TextInputForm
                        label="CEP*"
                        mask="99999-999"
                        onChange={handleInputChange('address.zip_code')}
                        value={values.address.zip_code}
                        msgErro={touched.address?.zip_code && errors.address?.zip_code}
                    />
                    <TextInputForm
                        label="Número*"
                        onChange={handleInputChange('address.number')}
                        value={values.address.number}
                        msgErro={touched.address?.number && errors.address?.number}
                    />
                    <TextInputForm
                        label="Complemento"
                        onChange={handleInputChange('address.complement')}
                        value={values.address.complement}
                        msgErro={touched.address?.complement && errors.address?.complement}
                    />
                </div>

                <ButtonForm
                    buttonText="Salvar"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
                />
            </div>
        </div>
    )
}

export default EstablishmentsCreateCredentialed
