const getRoles = removeManager => {
    if (removeManager) {
        return [
            { name: 'Proprietário', value: 3 },
            { name: 'Funcionário', value: 5 },
        ]
    }
    return [
        { name: 'Proprietário', value: 3 },
        { name: 'Gerente', value: 4 },
        { name: 'Funcionário', value: 5 },
    ]
}

export { getRoles }
