import { useState, useEffect, useRef, useCallback } from 'react'

import ManageDeliverersCard from '../../components/manage-deliverers-card-item/manage-deliverers-card-item'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import api from '../../services/api'

import './style.scss'

const RankingBikersList = ({ title, list, orderAttribute }) => (
    <div className="ranking-bikers-list">
        <div className="ranking-bikers-title">{title}</div>
        {list.map((item, index) => (
            <div className="biker-ranking-item" key={index}>
                <div className="left-side-container">
                    <div className="biker-position">{index + 1 < 10 ? `#0${index + 1}` : `#${index + 1}`}</div>
                    <div className="info-row">
                        <div className="biker-name">{`${item.agent?.first_name} ${item.agent?.last_name}`}</div>
                        <div className="biker-vehicle">{item.agent?.type === 1 ? 'Motocicleta' : 'Bicicleta'}</div>
                    </div>
                    <div className="time">{item[orderAttribute]}</div>
                </div>
            </div>
        ))}
    </div>
)

const ManageDeliverers = ({ history }) => {
    const modalMessage = useRef()
    const [loading, setLoading] = useState(false)
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [score, setScore] = useState({})
    const [metrics, setMetrics] = useState([])
    const [descOrder, setDescOrder] = useState(false)

    const getScoreboard = useCallback(async () => {
        try {
            setLoading(true)
            const response = await api.get('/painel/bikers/scoreboard')
            setScore(response)
        } catch (err) {
            console.log('getScoreboard:', err)

            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar as informações de entregadores.',
                onClose: () => history.push('/gestao-mall'),
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getScoreboard()
    }, [getScoreboard])

    const getMetrics = useCallback(async () => {
        try {
            setLoading(true)
            const promises = await Promise.all(
                [
                    { order_by: 'collect_time' },
                    { order_by: 'delivery_time' },
                    { order_by: 'start_route_time' },
                    { order_by: 'total_route_time' },
                ].map(async item => {
                    const {
                        data: { items },
                    } = await api.get('/painel/metrics', {
                        params: {
                            order_by: item.order_by,
                            order: descOrder ? 'DESC' : 'ASC',
                        },
                    })

                    return { items, order_by: item.order_by }
                })
            )

            setMetrics(promises)
        } catch (err) {
            console.log('getMetrics:', err)

            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar as métricas dos entregadores.',
                onClose: () => history.push('/gestao-mall'),
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }, [history, descOrder])

    useEffect(() => {
        getMetrics()
    }, [getMetrics])

    const getTitleByOrderAttribute = orderAttribute => {
        return {
            collect_time: 'Tempo de coleta',
            delivery_time: 'Tempo de entrega',
            start_route_time: 'Tempo de início de rota',
            total_route_time: 'Tempo total de rota',
        }[orderAttribute || '']
    }

    return (
        <>
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="manage-deliverers">
                <div className="manage-deliverers-card-listing">
                    <ManageDeliverersCard
                        icon="list-alt"
                        title="Cadastros em andamento"
                        number={score.pending}
                        linkRoute="/entregadores-moderacao/pendentes"
                        linkText="Ver pendentes"
                    />
                    <ManageDeliverersCard
                        icon="clock"
                        title="Aguardando moderação"
                        number={score.in_moderation}
                        linkRoute="/entregadores-moderacao"
                        linkText="Ver moderação"
                    />
                    <ManageDeliverersCard
                        icon="thumbs-up"
                        title="Entregadores aprovados"
                        number={score.approved}
                        linkRoute="/entregadores-moderacao/aprovados"
                        linkText="Ver aprovados"
                    />
                    <ManageDeliverersCard
                        icon="thumbs-down"
                        title="Entregadores reprovados"
                        number={score.disapproved}
                        linkRoute="/entregadores-moderacao/reprovados"
                        linkText="Ver reprovados"
                    />
                    <ManageDeliverersCard
                        icon="ban"
                        title="Entregadores bloqueados"
                        number={score.blocked}
                        linkRoute="/entregadores-moderacao/bloqueados"
                        linkText="Ver bloqueados"
                    />
                </div>
                <div className="ranking-bikers-container">
                    <div className="ranking-bikers-top-row">
                        <div className="title">Performance por etapa</div>

                        <div className="view-control">
                            <div
                                className={`button-item ${!descOrder ? 'active' : ''}`}
                                onClick={() => setDescOrder(false)}
                            >
                                Melhores
                            </div>
                            <div
                                className={`button-item ${descOrder ? 'active' : ''}`}
                                onClick={() => setDescOrder(true)}
                            >
                                Piores
                            </div>
                        </div>
                    </div>
                    <div className="ranking-bikers-container-list">
                        {metrics.map((metric, index) => (
                            <RankingBikersList
                                key={index}
                                title={getTitleByOrderAttribute(metric.order_by)}
                                list={metric.items}
                                orderAttribute={metric.order_by}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageDeliverers
