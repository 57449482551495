import { FC, memo } from 'react'

import { FieldInputProps } from 'formik'

import { InputItem } from 'components/_common'
import { TableOrderItem } from 'components/_store-general-vision'

import { formatCurrency } from 'helpers'
import { OrderItem } from 'types'

import { IReimbursementForm } from '../sales-detail-modal.controller'
import {
    InputContainer,
    LabelText,
    OrderDetailItem,
    ReimbursementContainer,
    SectionTitle,
    TotalValueText,
} from '../sales-detail-modal.styled'

type Props = {
    orderItems: OrderItem[]
    values: IReimbursementForm
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
}

const ReimbursementContent: FC<Props> = memo(({ orderItems, values, getFieldProps }) => {
    return (
        <>
            <InputContainer>
                <InputItem
                    labelText="Tipo de Estorno"
                    type="select"
                    options={[
                        { label: 'Estorno Total', value: 'total' },
                        { label: 'Estorno Parcial', value: 'partial' },
                    ]}
                    inputProps={getFieldProps('type')}
                />
            </InputContainer>
            {values.type === 'total' && (
                <ReimbursementContainer>
                    <LabelText>Valor a ser reembolsado</LabelText>
                    <TotalValueText>{formatCurrency(values.totalValue)}</TotalValueText>
                </ReimbursementContainer>
            )}

            {values.type === 'partial' && (
                <>
                    <InputContainer>
                        <InputItem
                            type="currency"
                            labelText="Valor"
                            inputProps={{ ...getFieldProps('amount'), value: formatCurrency(values.amount) }}
                        />
                    </InputContainer>
                    <OrderDetailItem>
                        <SectionTitle>Itens do Pedido</SectionTitle>
                        {orderItems?.map((item, i) => (
                            <TableOrderItem key={i} orderItem={item} />
                        ))}
                    </OrderDetailItem>
                </>
            )}
        </>
    )
})

export { ReimbursementContent }
