import React, { forwardRef, useRef, useImperativeHandle, useState, useCallback, memo, useEffect, useMemo } from 'react'

import { format } from 'date-fns'
import _ from 'lodash'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import ModalLoading from 'components/modal-loading'

import { getSalesChannelIcon } from 'helpers'
import api from 'services/api'
import { PagedList } from 'types'
import { IAddress } from 'types/address'

export interface OrderProblem {
    address: IAddress
    canceler: any
    client: string
    creator: {
        id: number
        name: string
    }
    id: number
    reason: string
    sale_channel: any
    mall: {
        name: string
        slug: string
    }
    reference_id: string
    store: string
}

import {
    Header,
    SeeOrder,
    TextProblem,
    ContainerActions,
    StoreName,
    CustomerID,
    ContainerItem,
    ChannelLogo,
    CustomerInfo,
    CustomerName,
} from './order-problem-modal.styled'
type OrderProblemModal = {
    show?(mallId: number): void
    close?(): void
}

const OrderProblemModal = memo(
    forwardRef<OrderProblemModal, any>((props, ref) => {
        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

        const [loading, setLoading] = useState<boolean>(false)
        const [mallId, setMallId] = useState<number>()

        const [orders, setOrders] = useState<PagedList<OrderProblem>>({
            totals: 0,
            current_page: 1,
            items: [],
        })

        const orderGroups = useMemo(() => {
            if (orders?.items) {
                const groups = _.chain(orders.items.map(e => ({ ...e, title: e.mall.name })))
                    .groupBy('title')
                    .map((items, title) => ({ title, orders: items }))
                    .value()

                return groups
            }
            return []
        }, [orders?.items])

        useImperativeHandle(
            ref,
            () => ({
                show: mallId => {
                    setMallId(mallId)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [lateralModalBaseRef]
        )

        const _getOrders = useCallback(async mallId => {
            setLoading(true)
            try {
                const today = format(new Date(), 'YYYY-MM-DD')
                const { data } = await api.get<PagedList<OrderProblem>>(`/painel/report/orders/canceled`, {
                    params: { mall_id: mallId, start_date: today, end_date: today },
                })

                setOrders(data)
            } catch (error) {
                console.log({ error })
            }

            setLoading(false)
        }, [])

        const _goToDetails = useCallback((order: OrderProblem) => {
            window.open(`/${order.mall.slug}/detalhes-pedido/${order.id}`, '_blank')
        }, [])

        const _onClose = useCallback(() => {
            setOrders(null)
            setMallId(null)
        }, [])

        useEffect(() => {
            if (mallId) {
                _getOrders(mallId)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [mallId])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title="Pedidos Cancelados" onClose={_onClose}>
                {orderGroups.map((item, i) => (
                    <React.Fragment key={i}>
                        <Header>{item.title}</Header>
                        {item.orders.map((o, i) => (
                            <ItemOrder key={i} order={o} onSeeDetailsClick={_goToDetails} />
                        ))}
                    </React.Fragment>
                ))}
                <ModalLoading visible={loading} />
            </LateralModalBase>
        )
    })
)

type ItemOrderProp = {
    order: any
    onSeeDetailsClick?(order: OrderProblem): void
}

const ItemOrder: React.FC<ItemOrderProp> = memo(({ order, onSeeDetailsClick }) => {
    const _onSeeDetailsClick = useCallback(() => {
        if (onSeeDetailsClick) {
            onSeeDetailsClick(order)
        }
    }, [order, onSeeDetailsClick])

    return (
        <ContainerItem>
            <ChannelLogo src={getSalesChannelIcon(order)} />
            <CustomerInfo>
                <CustomerID>
                    #{order.id} {order.reference_id && ` | #${order.reference_id}`}
                </CustomerID>
                <StoreName>{order.store}</StoreName>
                <CustomerName>{order.client}</CustomerName>
                {order?.reason && <TextProblem>{order.reason}</TextProblem>}
            </CustomerInfo>
            <ContainerActions>
                <SeeOrder onClick={_onSeeDetailsClick}>Ver Pedido</SeeOrder>
            </ContainerActions>
        </ContainerItem>
    )
})

export { OrderProblemModal }
