import styled, { css } from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .content-order {
        margin-bottom: 20px;
    }
    width: 800px;
    min-height: 600px;
    @media (max-width: 870px) {
        width: 100%;
    }
`

const ContainerTabs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Tab = styled.div<{ selected?: boolean }>`
    cursor: pointer;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    margin: 5px;
    &:hover {
        text-decoration: underline;
        font-weight: 600;
    }
    ${({ selected }) =>
        selected &&
        css`
            text-decoration: underline;
            font-weight: 600;
        `}
`

const ContentCanvas = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export { Container, ContainerTabs, ContentCanvas, Tab }
