import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
    authLoading: ['bool'],
    refreshLoading: ['bool'],
    setMall: ['data'],
    setStore: ['data'],
    setUserData: ['data'],
    removeUserData: ['data'],
})

const INITIAL_STATE = {
    loading: false,
    loadingRefresh: false,
    user: null,
    mall: null,
    store: null,
}

const authLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loading: action.bool,
})

const refreshLoading = (state = INITIAL_STATE, action) => ({
    ...state,
    loadingRefresh: action.bool,
})

const setMall = (state = INITIAL_STATE, action) => ({
    ...state,
    mall: action.data,
})

const setStore = (state = INITIAL_STATE, action) => ({
    ...state,
    store: action.data,
})

const setUserData = (state = INITIAL_STATE, action) => ({
    ...state,
    user: action.data,
})

const removeUserData = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
})

export const authReducer = createReducer(INITIAL_STATE, {
    [Types.AUTH_LOADING]: authLoading,
    [Types.REFRESH_LOADING]: refreshLoading,
    [Types.SET_MALL]: setMall,
    [Types.SET_STORE]: setStore,
    [Types.SET_USER_DATA]: setUserData,
    [Types.REMOVE_USER_DATA]: removeUserData,
})
