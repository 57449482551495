import styled, { css } from 'styled-components'

type Props = {
    isCollect?: boolean
    color?: string
}

const Marker = styled.div<Props>`
    width: 18px;
    height: 18px;
    background-color: ${({ color }) => color};
    border: 4px #fff solid;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
    transform: scale(1);

    ${({ isCollect }) =>
        isCollect &&
        css`
            animation: pulse 2.5s infinite;
        `}

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 #fc5027b3;
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`

export { Marker }
