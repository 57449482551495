import { ClockIcon } from 'containers/new-order-details/new-order-details.styles'

import AppliedFilters from '../applied-filters/applied-filters'
import OrderItem from '../order-item/order-item'

import { scheduled_orders } from './mock_orders'
import {
    ListBox,
    ListsContainer,
    ListTitle,
    ListTitleRow,
    MapContainer,
    NumberContainer,
    TimeIntervalRow,
} from './scheduled-orders.styles'

const selected_filters = ['HOY Pizza']

const ScheduledOrders: React.FC = () => {
    return (
        <>
            <ListsContainer>
                <AppliedFilters selectedFilters={selected_filters} />
                <ListBox>
                    {scheduled_orders.map(item => (
                        <>
                            <ListTitleRow>
                                <ListTitle>Agendados para {item.date}</ListTitle>
                                <NumberContainer>{item.totals}</NumberContainer>
                            </ListTitleRow>
                            {item.orders_list.map(interval => (
                                <>
                                    <TimeIntervalRow>
                                        <ClockIcon /> {interval.time_interval}
                                    </TimeIntervalRow>
                                    {interval.orders.map((orderItem, index) => (
                                        <OrderItem
                                            key={index}
                                            storeImage={orderItem.storeImage}
                                            channel={orderItem.channel}
                                            customerName={orderItem.customerName}
                                            customerNeighborhood={orderItem.customerNeighborhood}
                                            customerStreet={orderItem.customerStreet}
                                            customerNumber={orderItem.customerNumber}
                                            orderValue={orderItem.orderValue}
                                            payment={orderItem.payment}
                                            distance={orderItem.distance}
                                            scheduleTime={orderItem.scheduleTime}
                                        />
                                    ))}
                                </>
                            ))}
                        </>
                    ))}
                </ListBox>
            </ListsContainer>
            <MapContainer>teste</MapContainer>
        </>
    )
}

export default ScheduledOrders
