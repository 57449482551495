import styled, { css } from 'styled-components'

const BannerImage = styled.img`
    width: 128px;
    object-fit: contain;
`

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    /* overflow: auto; */
`

const Content = styled.div`
    height: 100%;
    position: relative;
`

const ContentTop = styled.div``

const ContentBody = styled.div<{ hasPagination?: boolean }>`
    overflow-y: auto;
    overflow-x: hidden;
    ${({ hasPagination }) =>
        hasPagination
            ? css`
                  height: calc(100% - 10.438rem);
              `
            : css`
                  height: 90%;
              `};
`

const TableColumnContent = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.strong`
    font-size: 1rem;
`
const Description = styled.small``

const ContentFooter = styled.div<{ isVisible?: boolean }>`
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 0px 8px 2px rgb(138, 138, 138);

    ${({ isVisible }) =>
        isVisible &&
        css`
            display: block;
        `}
`

const ButtonRow = styled.div`
    display: flex;
    gap: 0.438rem;
    flex-direction: column;
    width: 100%;

    button {
        width: 100%;
        /* margin: 0.5rem 0; */
    }

    @media all and (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        /* width: 20%; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
            /* width: fit-content; */
        }
    }
`

export {
    BannerImage,
    ButtonRow,
    Container,
    Content,
    ContentTop,
    ContentBody,
    ContentFooter,
    TableColumnContent,
    Title,
    Description,
}
