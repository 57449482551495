import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, setFormFieldErrors, showErrors } from 'helpers'
import { useEventListener } from 'hooks'
import api from 'services/api'
import { Creators as AuthActions } from 'store/reducers/auth'
import { Role } from 'store/reducers/types'

import login1 from '../../assets/images/login1.png'
import login2 from '../../assets/images/login2.png'
import login3 from '../../assets/images/login3.png'

import {
    ButtonsRow,
    LeftLogo,
    LeftSide,
    LoginBox,
    LoginImage,
    LoginImageContainer,
    LoginPageContainer,
    LoginTitle,
    MainButton,
    MobileLogo,
    RightSide,
    TextButton,
    MapImage,
    ErrorDialog,
} from './login-page.styles'

export default function LoginPage(): JSX.Element {
    const dispatch = useDispatch()
    const [errorData, setErrorData] = useState('')

    const history = useHistory()

    const { setLoading } = useUI()
    const [loginImage, setLoginImage] = useState(login1)
    const [isChanging, setIsChanging] = useState(false)

    const changeImage = useCallback(() => {
        if (loginImage === login1) {
            setLoginImage(login2)
        }
        if (loginImage === login2) {
            setLoginImage(login3)
        }
        if (loginImage === login3) {
            setLoginImage(login1)
        }
    }, [loginImage])

    useEffect(() => {
        setTimeout(() => {
            if (isChanging) {
                setIsChanging(false)
                changeImage()
            }
        }, 1000)

        setTimeout(() => {
            if (!isChanging) {
                setIsChanging(true)
            }
        }, 5000)
    }, [isChanging, changeImage])

    const { errors, getFieldProps, handleSubmit, touched } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().trim().required('Preencha com o nome'),
            password: Yup.string()
                .trim()
                .min(8, 'Senha deve ter no mínimo 8 caracteres')
                .required('Preencha com sua senha'),
        }),
        onSubmit: async (values, { resetForm, setFieldError }) => {
            try {
                setLoading(true)
                const { data } = await api.post(`/administrator/login`, {
                    email: values.email,
                    password: values.password,
                })
                const isBiker = data.roles.some((item: Role) => item.role === 'biker')
                if (isBiker) {
                    return
                }

                localStorage.setItem('@extranet.logaroo:token', data.token)

                const isAdmin = data.roles.some((item: Role) => item.role === 'admin')
                const isManager = data.roles.some((item: Role) => item.role === 'manager')
                const isMallManager = data.roles.some(item => item.role === 'admin' || item.role === 'manager')
                const isFinancial = data.roles.some((item: Role) => item.role === 'financial')
                const isShopkeeper = data.roles.some((item: Role) => item.role === 'owner' || item.role === 'staff')
                const isStaff = data.roles.some((item: Role) => item.role === 'staff')

                dispatch(
                    AuthActions.setUserData({
                        ...data,
                        isAdmin,
                        isManager,
                        isMallManager,
                        isFinancial,
                        isShopkeeper,
                        isStaff,
                    })
                )

                if (isShopkeeper) {
                    const [store] = data.stores

                    if (data.stores && data.stores.length) {
                        await Promise.all([
                            dispatch(AuthActions.setStore(store)),
                            dispatch(AuthActions.setMall(store.mall)),
                        ])
                        if (data.stores.length > 1) {
                            history.push(`/escolher-unidade`)
                        } else {
                            history.push(`/${store.mall?.slug}/${store.slug}/visao-geral/em-andamento`)
                        }
                    }
                } else if (isFinancial) {
                    await Promise.all([dispatch(AuthActions.setStore(null)), dispatch(AuthActions.setMall(null))])
                    history.push('/financeiro')
                } else {
                    let route = '/escolher-area'

                    if (isManager) {
                        route = data.malls.length > 1 ? '/escolher-unidade' : `/${data.malls[0].slug}/rotas/visao-geral`
                    }

                    history.push(route)
                }
                resetForm()
            } catch (error) {
                setFormFieldErrors(error, setFieldError)

                setErrorData(showErrors(error))
            } finally {
                setLoading(false)
            }
        },
    })

    const handleKeyPress = useCallback(
        ({ code }) => {
            if (code === 'Enter') {
                handleSubmit()
            }
        },
        [handleSubmit]
    )

    useEventListener('keydown', handleKeyPress)

    return (
        <LoginPageContainer>
            <LeftSide>
                <MapImage />
                <LeftLogo />
                <LoginImageContainer>
                    <LoginImage src={loginImage} isChanging={isChanging} />
                </LoginImageContainer>
            </LeftSide>
            <RightSide>
                <LoginBox>
                    <MobileLogo />
                    <LoginTitle>Acessar Conta</LoginTitle>
                    {errorData.length > 0 && <ErrorDialog>{errorData}</ErrorDialog>}
                    <InputItem
                        labelText="E-mail"
                        errorMessage={getFormInputError('email', errors, touched)}
                        inputProps={getFieldProps('email')}
                    />
                    <InputItem
                        labelText="Senha"
                        type="password"
                        errorMessage={getFormInputError('password', errors, touched)}
                        inputProps={getFieldProps('password')}
                    />
                    <ButtonsRow>
                        <TextButton onClick={() => history.push('/recuperar-senha')}>Recuperar Senha</TextButton>
                        <MainButton onClick={() => handleSubmit()}>Entrar</MainButton>
                    </ButtonsRow>
                </LoginBox>
            </RightSide>
        </LoginPageContainer>
    )
}
