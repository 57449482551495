import { collects } from './mock_orders'
import {
    ListBox,
    ListsContainer,
    ListTitle,
    ListTitleRow,
    OrderDetails,
    NumberContainer,
    EmptyText,
    OrderCallClick,
    OrderCallIcon,
    EmptyTitle,
    StoreTitleRow,
    StoreIcon,
    ButtonRow,
    ConfirmButton,
} from './deliver-on-dispatch.styles'

import { PickerOrderItem } from '..'

interface Props {
    orderCardClick(): void
    problemClick(): void
    hasProblems?: boolean
}

const DeliverOnDispatch: React.FC<Props> = ({ orderCardClick, problemClick, hasProblems }) => {
    return (
        <>
            <ListsContainer>
                <ListTitleRow>
                    <ListTitle>Entregar Pedidos</ListTitle>
                    <NumberContainer>0/{collects.totals}</NumberContainer>
                </ListTitleRow>
                <ListBox>
                    <>
                        {collects.stores.map(item => (
                            <>
                                <StoreTitleRow>
                                    <StoreIcon /> {item.storeName}
                                </StoreTitleRow>
                                <>
                                    {item.orders.map((item, index) => (
                                        <PickerOrderItem
                                            storeLogo={item.storeImage}
                                            key={index}
                                            orderID={item.orderID}
                                            customerName={item.customerName}
                                            channel={item.channel}
                                            productionTime={item.productionTime}
                                            totalTime={item.elapsedTime}
                                            orderValue={item.orderValue}
                                            payment={item.payment}
                                            confirmClick={() => null}
                                            cardClick={orderCardClick}
                                            detailClick={orderCardClick}
                                            problemClick={problemClick}
                                            showDeliverButton
                                            isGivingBack
                                        />
                                    ))}
                                </>
                            </>
                        ))}
                    </>
                </ListBox>
                <ButtonRow>
                    <ConfirmButton disabled>{hasProblems ? 'Iniciar Devolução' : 'Finalizar Rota'}</ConfirmButton>
                </ButtonRow>
            </ListsContainer>
            <OrderDetails>
                <OrderCallClick>
                    <OrderCallIcon />
                    <EmptyTitle>Entrega em andamento</EmptyTitle>
                    <EmptyText>
                        Agora, basta entregar os pedidos nas respectivas docas e para os entregadores responsáveis.
                        Lembre-se de conferir os detalhes do pedido antes de confirmar a entrega.
                    </EmptyText>
                </OrderCallClick>
            </OrderDetails>
        </>
    )
}

export default DeliverOnDispatch
