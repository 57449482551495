/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from 'react'

import api from 'services/api'
import { IAttachment } from 'types'

type Params = {
    attachment: IAttachment
}

function useModalFileCTL() {
    const modalShowRef = useRef<boolean>()

    const [attachment, setAttachment] = useState<IAttachment>()
    const [base64, setBase64] = useState<string>()
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const _setShow = useCallback(async ({ attachment }: Params) => {
        setShow(true)

        setLoading(true)
        try {
            const data = await api.get<string>(`/painel/order/attachment/${attachment.id}`)
            let base64 = ''

            setAttachment(attachment)

            if (attachment.extension === 'pdf') {
                base64 += 'data:application/pdf;base64,'
            }
            if (attachment.extension === 'png') {
                base64 += 'data:image/png;base64,'
            }
            if (['jpg', 'jpeg'].includes(attachment.extension)) {
                base64 += 'data:image/jpeg;base64,'
            }
            base64 += data
            setBase64(base64)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    const _download = useCallback(() => {
        const a = document.createElement('a')
        a.href = base64
        a.download = attachment.original_name
        a.click()
    }, [attachment, base64])

    const _onClose = useCallback(() => {
        setShow(false)
        setBase64(null)
        setAttachment(null)
    }, [])

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setVisible(false)
            setBase64(null)
            setAttachment(null)
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    return {
        loading,
        visible,
        show,
        attachment,
        base64,
        _download,
        _setShow,
        _onClose,
    }
}

export { useModalFileCTL }
