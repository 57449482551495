import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: calc(100% - 82px);
    display: flex;
    flex-direction: column;
    * {
        box-sizing: border-box;
    }
`

const Content = styled.div`
    flex: 1;
    display: flex;
`

const ContainerList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ButtonText = styled.div`
    color: var(--primary-color);
    cursor: pointer;
`
const Title = styled.div`
    font-size: 22px;
    font-weight: bold;
`
const ContainerTitle = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;

    ${Title} {
        margin-bottom: 20px;
    }
    @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        ${Title} {
            margin-bottom: 0px;
        }
    }
`

export { Container, ContainerList, Content, Title, ContainerTitle, ButtonText }
