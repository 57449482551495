import styled from 'styled-components'

const Container = styled.div``

const Table = styled.table`
    width: 100%;
    border: none;
`

const RowTable = styled.tr<{ gray?: boolean }>`
    background-color: ${({ gray }) => (gray ? '#F6F6F6' : 'transparent')};
`

const CellHeader = styled.div`
    border-right: solid 1px ${({ theme }) => theme.colors.primary};
    border-left: solid 1px ${({ theme }) => theme.colors.primary};
    padding: 0px 6px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ColumnHeader = styled.th`
    padding: 10px 0px;
    &:first-child {
        ${CellHeader} {
            border-left: none;
        }
    }
    &:last-child {
        ${CellHeader} {
            border-right: none;
        }
    }
`

const ColumnTable = styled.td`
    padding: 10px;
    border-bottom: 1px solid #dfdfdf;
`

export { Container, Table, RowTable, ColumnTable, ColumnHeader, CellHeader }
