import styled from 'styled-components'

const ContentContainer = styled.div`
    flex: 1;
    padding: 0.938rem;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 1.563rem;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const OrderDetailItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .container-canvas {
        min-height: 400px;
        margin-bottom: 20px;
    }
`

const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
`

const InputContainer = styled.div``

const CheckBoxContainer = styled.div`
    max-height: 12.5rem;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 1rem;
`
const CheckBoxContend = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const LabelText = styled.div`
    margin-bottom: 1rem;
`

const ReimbursementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`

const TotalValueText = styled.div`
    font-size: 1.75rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
`

const MapContainer = styled.div`
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
    border: 0.063rem solid #f1f1f1;
    margin: 1.25rem 0rem;
`

const ButtonRow = styled.div<{ justify?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => justify || 'space-between'};
    padding: 1.25rem;
    background-color: #f7f7f7;
    gap: 0.5rem;

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        padding: 0.625rem;
    }
`

const TextButton = styled.div<{ isRemove?: boolean }>`
    color: ${({ theme, isRemove }) => (isRemove ? theme.colors.errorMessage : theme.colors.primary)};
    margin-right: 1.875rem;
    font-size: 1rem;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 0.563rem 1.25rem;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
    span {
        margin-left: 0.25;
    }

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        margin-right: 0px;
    }
`

const ConfirmButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
`

export {
    ContentContainer,
    OutsideContainer,
    OrderDetailItem,
    SectionTitle,
    Row,
    MapContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    InputContainer,
    CheckBoxContainer,
    CheckBoxContend,
    LabelText,
    TotalValueText,
    ReimbursementContainer,
}
