import styled from 'styled-components'

const Container = styled.div`
    overflow: auto;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: calc(100vh - 4.4rem);
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: calc(100vh - 9.75rem);
    }
`

export { Container, Content }
