import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
    setModalMessageData: ['data'],
    clearModalMessageData: [''],
})

const INITIAL_STATE = {
    modalMessageData: {
        isActive: false,
        title: '',
        message: '',
    },
}

const setModalMessageData = (state = INITIAL_STATE, action) => ({
    ...state,
    modalMessageData: action.data,
})

const clearModalMessageData = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
})

export const utilsReducer = createReducer(INITIAL_STATE, {
    [Types.SET_MODAL_MESSAGE_DATA]: setModalMessageData,
    [Types.CLEAR_MODAL_MESSAGE_DATA]: clearModalMessageData,
})
