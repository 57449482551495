import { useEffect, useState } from 'react'

import { PendingOrder } from 'types'

import { OrderDetailsContent } from '..'

import {
    OrderDetailsModalBackground,
    OrderDetailsModalBox,
    OrderDetailsModalContainer,
} from './order-details-modal.styles'

interface Props {
    orderId?: number
    isActive: boolean
    closeClick(): void
    onConfirm(order: PendingOrder): void
    onReject(order: PendingOrder): void
}

const OrderDetailsModal: React.FC<Props> = ({ isActive, orderId, closeClick, onConfirm, onReject }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive && !!orderId) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        }
    }, [isActive, orderId])

    return (
        <OrderDetailsModalContainer isDisplayed={isDisplayed}>
            <OrderDetailsModalBox isEffectActive={isEffectActive}>
                <OrderDetailsContent onConfirm={onConfirm} onReject={onReject} orderId={orderId} onClose={closeClick} />
            </OrderDetailsModalBox>
            <OrderDetailsModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </OrderDetailsModalContainer>
    )
}

export default OrderDetailsModal
