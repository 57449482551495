import { Subject } from 'rxjs'

import { Order } from 'types'

type ChangeOrder = {
    order: Order
    type: 'add' | 'update' | 'delete' | 'schedule'
}

const RefreshRomaneioObservable = new Subject()
const ChangeOrderObservable = new Subject<ChangeOrder>()

export { RefreshRomaneioObservable, ChangeOrderObservable }
