import { createReducer, createActions } from 'reduxsauce'

import { RemoveOrderAction } from '../ordersInProgress'

import {
    ActionCreators,
    ActionTypes,
    Actions,
    AddOrderAction,
    SetOrdersAction,
    State,
    UpdateOrderAction,
    SetLoadingAction,
    SetWebSocketConnectionAction,
    SetHighlightedOrderIdAction,
} from './types'

/* Types & Action Creators */

export const { Types: PendingOrdersTypes, Creators: PendingOrdersActions } = createActions<ActionTypes, ActionCreators>(
    {
        setHighlightedOrderId: ['orderId'],
        setWebSocketConnection: ['connection', 'mall', 'store'],
        setLoading: ['loading'],
        setOrders: ['orders'],
        addOrder: ['order'],
        updateOrder: ['order'],
        removeOrder: ['order'],
    },
    { prefix: 'pendingOrders/' }
)

/* Initial State */

const initialState: State = {
    highlightedOrderId: undefined,
    webSocketConnection: 'disconnected',
    orders: { loading: false, totals: 0, items: [] },
}

/* Reducers */

function setHighlightedOrderId(state = initialState, action: SetHighlightedOrderIdAction): State {
    return {
        ...state,
        highlightedOrderId: action.orderId,
    }
}

function setWebSocketConnection(state = initialState, action: SetWebSocketConnectionAction): State {
    return {
        ...state,
        webSocketConnection: action.connection,
    }
}

function setLoading(state = initialState, action: SetLoadingAction): State {
    return {
        ...state,
        orders: {
            ...state.orders,
            loading: action.loading,
        },
    }
}

function setOrders(state = initialState, action: SetOrdersAction): State {
    return {
        ...state,
        orders: action.orders,
    }
}

function addOrder(state = initialState, action: AddOrderAction): State {
    return {
        ...state,
        orders: {
            ...state.orders,
            totals: Number(state.orders?.totals) + 1,
            items: [...state.orders.items.filter(order => order.id !== action.order.id), action.order],
        },
    }
}

function updateOrder(state = initialState, action: UpdateOrderAction): State {
    return {
        ...state,
        orders: {
            ...state.orders,
            items: state.orders.items?.filter(order => (order.id === action.order.id ? action.order : order.id)),
        },
    }
}

function removeOrder(state = initialState, action: RemoveOrderAction): State {
    return {
        ...state,
        orders: {
            ...state.orders,
            totals: Number(state.orders?.totals) - 1,
            items: state.orders.items.filter(order => order.id !== action.order.id),
        },
    }
}

/* Reducers to types */

export const pendingOrdersReducer = createReducer<State, Actions>(initialState, {
    [PendingOrdersTypes.SET_HIGHLIGHTED_ORDER_ID]: setHighlightedOrderId,
    [PendingOrdersTypes.SET_WEB_SOCKET_CONNECTION]: setWebSocketConnection,
    [PendingOrdersTypes.SET_LOADING]: setLoading,
    [PendingOrdersTypes.SET_ORDERS]: setOrders,
    [PendingOrdersTypes.ADD_ORDER]: addOrder,
    [PendingOrdersTypes.UPDATE_ORDER]: updateOrder,
    [PendingOrdersTypes.REMOVE_ORDER]: removeOrder,
})
