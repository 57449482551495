import { useContext } from 'react'

import { PrinterContext } from 'contexts/printer-context'

function usePrinter() {
    const printer = useContext(PrinterContext)

    return printer
}

export { usePrinter }
