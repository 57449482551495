import {
    ProductId,
    ProductTitle,
    Observation,
    ProductTitleText,
    ProductInfoText,
    ProductTitleRow,
    ProductItemContainer,
} from './product-item.styles'

interface Props {
    id: number
    title: string
    observation: string
    amount: number
    value: number
}

const ProductItem: React.FC<Props> = ({ id, title, observation, amount, value }) => {
    return (
        <ProductItemContainer>
            <ProductTitleRow>
                <ProductId>#{id}</ProductId>
                <ProductTitle>
                    <ProductTitleText>{title}</ProductTitleText>
                    <Observation>Obs: {observation}</Observation>
                </ProductTitle>
            </ProductTitleRow>
            <ProductInfoText>{amount}</ProductInfoText>
            <ProductInfoText>R$ {value}</ProductInfoText>
        </ProductItemContainer>
    )
}

export default ProductItem
