import { faSortAmountDownAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const FilterModalContainer = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transition: 400ms;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
`

export const FilterModalBackground = styled.div<{ isEffectActive: boolean }>`
    z-index: ${({ theme }) => theme.commonModalZIndex};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    transition: 400ms;
    opacity: ${({ isEffectActive }) => (isEffectActive ? '1' : '0')};
`

export const FilterModalBox = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 650px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

export const FilterHeader = styled.div`
    position: relative;
    font-size: 24px;
    padding: 20px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.colors.black};
    letter-spacing: 2px;
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 40px;
        margin-top: 20px;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 70px;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.primary};
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            left: 40px;
        }
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    width: 30px;
    position: absolute;
    top: 15px;
    right: 20px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

export const FilterModalContent = styled.div`
    flex: 1;
    overflow: auto;
    margin-bottom: 0;
    &::-webkit-scrollbar {
        display: none;
    }
`

export const EmptyDisclaimer = styled.div`
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: -50px;
`

export const ButtonContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.gray};
    padding-top: 20px;
`

export const FilterBox = styled.div`
    padding: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 20px;
        padding: 0px 40px;
    }
`

export const FilterTitle = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
`

export const FilterOrderContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
    }
`

export const FilterOrderButton = styled.div<{ isActive?: boolean }>`
    padding: 7px 20px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    background-color: ${({ theme, isActive }) => (isActive ? 'rgba(255, 134, 10, 0.1)' : theme.colors.gray)};
    border: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 12px;
        margin-right: 20px;
    }
`

export const OrderIcon = styled(FontAwesomeIcon).attrs({
    icon: faSortAmountDownAlt,
})`
    margin-right: 5px;
    &:last-child {
        transform: rotate(180deg);
    }
`

export const CheckboxListing = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        height: 200px;
        overflow-y: auto;
        align-items: center;
    }
`

export const ListItem = styled.div`
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 48%;
        margin-right: 2%;
    }
`

export const ChannelsOrder = styled.div`
    display: flex;
    flex-wrap: wrap;
`
