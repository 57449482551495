import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #c6c6c6;

    * {
        box-sizing: border-box;
    }
`

const Header = styled.div`
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #fff;
`

const HeaderTitle = styled.div`
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

export { Container, Header, Content, HeaderTitle, Row }
