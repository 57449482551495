import styled from 'styled-components'

const ContainerFilters = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0px;
    .item-input {
        margin-left: 20px;
        width: 100px;
    }
`

const ContainerTotals = styled.div`
    margin-left: 20px;
    font-size: 14px;
    margin-bottom: 16px;
    span {
        font-weight: bold;
    }
`

const FilterButon = styled.div`
    background-color: var(--primary-color);
    color: white;
    padding: 8px 20px;
    border-radius: 20px;
    border: 1px solid var(--primary-color);
    margin-left: 20px;
    display: inline-flex;
    cursor: pointer;
    @media (min-width: 1200px) {
        margin: 0;
        margin-left: 20px;
    }
`

export { ContainerFilters, ContainerTotals, FilterButon }
