import { forwardRef, useEffect, useImperativeHandle, useState, useCallback } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useAuth } from 'hooks'

import CheckboxInput from '../../components/checkbox-input'
import MensagemErro from '../../components/mensagem-erro'
import { showErrors, getRoleId, getRoles } from '../../helpers'
import { useEventListener } from '../../hooks'
import api from '../../services/api'
import PasswordInputForm from '../password-input-form'
import SelectInputForm from '../select-input-form'
import TextInputForm from '../text-input-form'

import './style.scss'
import { useLocation } from 'react-router-dom'

const ModalCreateStaff = forwardRef(
    (
        {
            isActive,
            onClose,
            setLoadingSubmit,
            staffs,
            setStaffs,
            modalMessageRef,
            setModalMessageData,
            title,
            subtitle,
            stores,
            user,
            isCreation,
        },
        ref
    ) => {
        const { mall } = useAuth()
        const { pathname } = useLocation()
        const [active, setActive] = useState(isActive || false)

        const { errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } = useFormik({
            initialValues: {
                mall_id: mall?.id,
                stores: [],
                role: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirmation: '',
                status: 1,
            },
            validationSchema: Yup.object().shape({
                role: Yup.string().trim().required('Selecione uma Função'),
                stores: Yup.array().when('role', {
                    is: '4',
                    then: Yup.array(),
                    otherwise: Yup.array().min(1, 'Selecione pelo menos uma loja'),
                }),
                first_name: Yup.string().trim().required('Insira o Nome'),
                last_name: Yup.string().trim(),
                email: Yup.string().trim().email('Deve ser um email válido').required('Insira o Email'),
                password:
                    isCreation &&
                    Yup.string().min(8, 'Deve ter no mínimo 8 caracteres').trim().required('Insira a Senha'),
                password_confirmation:
                    isCreation &&
                    Yup.string()
                        .trim()
                        .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
                        .required('Confirme a Senha'),
                status: Yup.string().required('Selecione o Status'),
            }),
            onSubmit: async values => {
                try {
                    setLoadingSubmit(true)
                    const form = Object.keys(values).reduce((acc, key) => {
                        if (values[key]) {
                            acc[key] = values[key]
                        }

                        return acc
                    }, {})

                    const { data } = isCreation
                        ? await api.post(`painel/staff`, {
                              ...form,
                          })
                        : await api.put(`painel/staff/${values.id}`, {
                              ...form,
                          })

                    setStaffs({
                        ...staffs,
                        items: isCreation
                            ? [...staffs.items, data]
                            : [...staffs.items.map(item => (item.id === values.id ? data : item))],
                    })

                    ref.current.closeModal()

                    setModalMessageData({
                        title: 'Sucesso',
                        message: isCreation ? 'Lojista criado com sucesso!' : 'Lojista atualizado com sucesso!',
                    })

                    modalMessageRef.current.openModal()
                    resetForm()
                } catch (error) {
                    setModalMessageData({
                        isActive: true,
                        title: 'Erro',
                        message: showErrors(error),
                        textButton: 'Revisar alterações',
                    })

                    modalMessageRef.current.openModal()
                } finally {
                    setLoadingSubmit(false)
                }
            },
        })

        const handleEscPress = useCallback(
            ({ code }) => {
                if (code === 'Escape') {
                    ref.current.closeModal()
                }
            },
            [ref]
        )

        useEventListener('keydown', handleEscPress)

        const setFormValues = useCallback(() => {
            if (!isCreation) {
                const storeIds = user.stores?.map(item => item.id)
                const [role] = user.role
                setValues({
                    ...Object.keys(user).reduce((acc, key) => {
                        acc[key] = user[key] || ''

                        return acc
                    }, {}),
                    status: user.status.toString(),
                    role: getRoleId(role),
                    stores: storeIds,
                })
            }
        }, [isCreation, setValues, user])

        const onChangeStores = (store_id, checked) => {
            if (checked) {
                setFieldValue('stores', [...values.stores, store_id])
            } else {
                setFieldValue(
                    'stores',
                    values.stores.filter(id => id !== store_id)
                )
            }
        }

        useEffect(() => {
            setFormValues()
        }, [setFormValues])

        useImperativeHandle(ref, () => ({
            openModal: () => {
                setActive(true)
            },
            closeModal: () => {
                setActive(false)
                onClose && onClose()
                resetForm()
            },
        }))

        return (
            <div className={`modal-create-staff ${active ? 'is-active' : ''}`}>
                <div className="Modal-bg">
                    <div className="Modal-box">
                        <div className="Modal-header">
                            <div className="Modal-title">{title}</div>
                            <div className="Modal-subtitle">{subtitle}</div>
                        </div>
                        <div className="Modal-body">
                            <div className="Modal-content">
                                <div className="inputs-container">
                                    <SelectInputForm
                                        label="Função"
                                        data={getRoles(pathname.includes('painel-malls'))}
                                        value={values.role}
                                        onChange={e => {
                                            if (e.target.value === '4') {
                                                setFieldValue('stores', [])
                                            }
                                            setFieldValue('role', e.target.value)
                                        }}
                                        msgErro={touched.role && errors.role}
                                    />
                                    {values.role !== '4' && (
                                        <div className="stores-checkbox-container">
                                            <label className="stores-label">Lojas</label>
                                            <div className="stores-checkbox-group">
                                                {stores.map(store => (
                                                    <CheckboxInput
                                                        key={store.id}
                                                        id={`store-${store.id}`}
                                                        label={store.name}
                                                        onChange={({ target: { checked } }) =>
                                                            onChangeStores(store.id, checked)
                                                        }
                                                        checked={values.stores.some(id => id === store.id)}
                                                    />
                                                ))}
                                            </div>
                                            <MensagemErro msgErro={touched.stores && errors.stores} />
                                        </div>
                                    )}
                                    <div className="inputs-row">
                                        <TextInputForm
                                            label="Nome"
                                            value={values.first_name}
                                            onChange={e => setFieldValue('first_name', e.target.value)}
                                            msgErro={touched.first_name && errors.first_name}
                                        />
                                        <TextInputForm
                                            label="Sobrenome"
                                            value={values.last_name}
                                            onChange={e => setFieldValue('last_name', e.target.value)}
                                            msgErro={touched.last_name && errors.last_name}
                                        />
                                    </div>

                                    <TextInputForm
                                        label="E-mail"
                                        value={values.email}
                                        onChange={e => setFieldValue('email', e.target.value)}
                                        msgErro={touched.email && errors.email}
                                    />

                                    {isCreation ? (
                                        <div className="inputs-row">
                                            <PasswordInputForm
                                                label="Senha"
                                                value={values.password}
                                                onChange={e => setFieldValue('password', e.target.value)}
                                                msgErro={touched.password && errors.password}
                                            />
                                            <PasswordInputForm
                                                label="Confirmar senha"
                                                value={values.password_confirmation}
                                                onChange={e => setFieldValue('password_confirmation', e.target.value)}
                                                msgErro={touched.password_confirmation && errors.password_confirmation}
                                            />
                                        </div>
                                    ) : null}
                                    <div style={{ width: 200 }}>
                                        <SelectInputForm
                                            label="Status"
                                            data={[
                                                { name: 'Ativo', value: '1' },
                                                { name: 'Inativo', value: '0' },
                                            ]}
                                            value={values.status}
                                            onChange={e => setFieldValue('status', e.target.value)}
                                            msgErro={touched.status && errors.status}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="Modal-buttons">
                                <button className="Modal-button primary" type="submit" onClick={handleSubmit}>
                                    Salvar
                                </button>
                                <button className="Modal-button outline" onClick={() => ref.current.closeModal()}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)

export default ModalCreateStaff
