import { faBiking, faMotorcycle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Contaienr = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 20px;
    display: flex;
    align-items: center;
`

const ContainerAvatar = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    margin-right: 20px;
    position: relative;
`

const ContainerBadge = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Avatar = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`

const Icon = styled(FontAwesomeIcon)`
    color: #fff;
    font-size: 14px;
`

const ContainerInfo = styled.div`
    flex: 1;
`
const TypeAgent = styled.div`
    color: ${({ theme }) => theme.colors.darkOrange};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
`

const RiderName = styled.div`
    font-size: 14px;
`

const RiderPhone = styled.div`
    font-weight: bold;
    font-size: 12px;
    color: #999;
`

const ContainerButtons = styled.div`
    display: flex;
    margin-top: 40px;
    @media (min-width: 1200px) {
        margin-top: 0;
    }
`

const ButtonIcon = styled.div`
    cursor: pointer;
    user-select: none;
    color: var(--light-primary-color);
    text-align: center;
    margin-right: 20px;
    @media (min-width: 1200px) {
        margin-left: 20px;
        margin-right: 0;
    }
    .icon {
        margin-bottom: 5px;
        font-size: 18px;
    }
`

const TextButton = styled.div`
    font-size: 12px;
`

export {
    Icon,
    ContainerButtons,
    ButtonIcon,
    Contaienr,
    ContainerAvatar,
    ContainerBadge,
    Avatar,
    TypeAgent,
    ContainerInfo,
    RiderName,
    RiderPhone,
    TextButton,
}
