import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { MarketplaceStoreInfo, ProductTabContent, SwitchButton } from 'components/_marketplace-management'
import FeaturedStoresListing from 'components/_marketplace-management/featured-stores/featured-stores'
import FeaturedStoresForm from 'components/_marketplace-management/featured-stores/featured-stores.form'
import { MobileSelectTab } from 'components/_new-general-vision/navigation-row/navigation-row.styles'

import { useUI } from 'contexts'
import { useAuth, useDataFetching } from 'hooks'
import api from 'services/api'
import { Store } from 'types'

import {
    ContentContainer,
    DetailsContainer,
    MarketplaceManagementContainer,
    TabsContainer,
    TabItem,
    StoreInfoContainer,
    StoreLogo,
    LogoImage,
    StoreTextContainer,
    StoreName,
    StoreRatingContainer,
    StarIcon,
    StoreDetailButton,
    ButtonsContainer,
    ExternalIcon,
} from './marketplace-management.styles'
import {
    PostpaidSalesScreen,
    ProductCategoriesListing,
    ProductCategoryForm,
    ShowcaseForm,
    ShowcasesListing,
    SideDishCategoriesListing,
    SideDishCategoryForm,
    SideDishForm,
    SideDishesListing,
    StoreCategoriesListing,
    StoreCategoryForm,
    StoreTypeForm,
    StoreTypesListing,
    TagForm,
    TagsListing,
} from './pages'

import './style.scss'
import { OpeningHours } from './pages/opening-hours/opening-hours'
import { SalesScreen } from './pages/sales/sales'

const MarketplaceManagement: React.FC = memo(() => {
    const { basePath, store, mall, user } = useAuth()
    const { setLoading, setErrorModal } = useUI()
    const history = useHistory()
    const { pathname } = history.location
    const [storeStatus, setStoreStatus] = useState(false)
    const [storeData, setStoreData] = useState<Store>()

    // const { data: storeData, revalidate: revalidateStore } = useDataFetching<Store>(`/painel/store/${store.id}`)

    const parentPath = useMemo(
        () => (store?.id ? `${basePath}/gestao-marketplace` : '/marketplace/gestao-marketplace'),
        [store?.id, basePath]
    )

    const links = useMemo(() => {
        if (store?.id) {
            const menus = [
                { label: 'Vendas (Pré pago)', destiny: 'vendas' },
                { label: 'Informações da Loja', destiny: 'informacoes-loja' },
                { label: 'Categorias de acompanhamento', destiny: 'categorias-acompanhamento' },
                { label: 'Acompanhamentos', destiny: 'acompanhamentos' },
                { label: 'Produtos', destiny: 'produtos' },
                { label: 'Vitrine', destiny: 'vitrine' },
                { label: 'Horários de Funcionamento', destiny: 'horarios-de-funcionamento' },
            ]
            if (user.roles.some(x => x.role === 'superadmin')) {
                menus.splice(6, 0, { label: 'Vitrine Expressa', destiny: 'expressa' })
            }

            if (mall.configs?.operation?.types?.includes('postpaid')) {
                menus.splice(1, 0, { label: 'Vendas (Pós pago)', destiny: 'vendas-pospago' })
            }

            return menus
        }

        return [
            { label: 'Categorias de produto', destiny: 'categorias-produto' },
            { label: 'Tags', destiny: 'tags' },
            { label: 'Verticais de loja', destiny: 'verticais-loja' },
            { label: 'Categorias de loja', destiny: 'categorias-loja' },
            { label: 'Lojas em destaque', destiny: 'lojas-em-destaque' },
        ]
    }, [store?.id, user])

    const _getMarketplaceShopkeeperRoutes = useCallback(() => {
        return (
            <>
                {/* SideDishCategories */}
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/categorias-acompanhamento`}
                    component={SideDishCategoriesListing}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/categorias-acompanhamento/criar-categoria-acompanhamento`}
                    component={SideDishCategoryForm}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/categorias-acompanhamento/editar-categoria-acompanhamento/:side_dish_category_id`}
                    component={SideDishCategoryForm}
                />

                {/* SideDishes */}
                <Route exact path={`${basePath}/gestao-marketplace/acompanhamentos`} component={SideDishesListing} />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/acompanhamentos/criar-acompanhamento`}
                    component={SideDishForm}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/acompanhamentos/editar-acompanhamento/:side_dish_id`}
                    component={SideDishForm}
                />

                {/* Products */}
                <Route exact path={`${basePath}/gestao-marketplace/produtos`} component={ProductTabContent} />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/produtos/criar-produto`}
                    component={ProductTabContent}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/produtos/criar-produto/criar-grupo`}
                    component={ProductTabContent}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/produtos/editar-produto/:id`}
                    component={ProductTabContent}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/produtos/editar-produto/:id/criar-grupo`}
                    component={ProductTabContent}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/produtos/editar-produto/:id/editar-grupo/:groupId`}
                    component={ProductTabContent}
                />

                {/* Showcases */}
                <Route exact path={`${basePath}/gestao-marketplace/vitrine`} component={ShowcasesListing} />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/vitrine/criar-agrupamento`}
                    component={ShowcaseForm}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/vitrine/editar-agrupamento/:showcase_id`}
                    component={ShowcaseForm}
                />
                {/* Showcases Express*/}
                <Route exact path={`${basePath}/gestao-marketplace/expressa`} component={ShowcasesListing} />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/expressa/criar-agrupamento`}
                    component={ShowcaseForm}
                />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/expressa/editar-agrupamento/:showcase_id`}
                    component={ShowcaseForm}
                />

                {/* Store Info */}
                <Route exact path={`${basePath}/gestao-marketplace/vendas`} component={SalesScreen} />
                <Route exact path={`${basePath}/gestao-marketplace/vendas-pospago`} component={PostpaidSalesScreen} />
                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/informacoes-loja`}
                    component={MarketplaceStoreInfo}
                />

                <Route
                    exact
                    path={`${basePath}/gestao-marketplace/horarios-de-funcionamento`}
                    component={OpeningHours}
                />
            </>
        )
    }, [basePath])

    const _getMarketPlaceAdminRoutes = useCallback(() => {
        return (
            <>
                {/* ProductCategories */}
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/categorias-produto"
                    component={ProductCategoriesListing}
                />
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/categorias-produto/criar-categoria-produto"
                    component={ProductCategoryForm}
                />
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/categorias-produto/editar-categoria-produto/:product_category_id"
                    component={ProductCategoryForm}
                />

                {/* Tags */}
                <Route exact path="/marketplace/gestao-marketplace/tags" component={TagsListing} />
                <Route exact path="/marketplace/gestao-marketplace/tags/criar-tag" component={TagForm} />
                <Route exact path="/marketplace/gestao-marketplace/tags/editar-tag/:tag_id" component={TagForm} />

                {/* StoreTypes */}
                <Route exact path="/marketplace/gestao-marketplace/verticais-loja" component={StoreTypesListing} />
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/verticais-loja/criar-vertical-loja"
                    component={StoreTypeForm}
                />
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/verticais-loja/editar-vertical-loja/:store_type_id"
                    component={StoreTypeForm}
                />

                {/* StoreCategories */}
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/categorias-loja"
                    component={StoreCategoriesListing}
                />
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/categorias-loja/criar-categoria-loja"
                    component={StoreCategoryForm}
                />
                <Route
                    exact
                    path="/marketplace/gestao-marketplace/categorias-loja/editar-categoria-loja/:store_category_id"
                    component={StoreCategoryForm}
                />

                {/* FeaturedStores */}
                <Route
                    exact
                    path={`${basePath}/marketplace/gestao-marketplace/lojas-em-destaque`}
                    component={FeaturedStoresListing}
                />
                <Route
                    exact
                    path={`${basePath}/marketplace/gestao-marketplace/lojas-em-destaque/criar-loja-destaque`}
                    component={FeaturedStoresForm}
                />
                <Route
                    exact
                    path={`${basePath}/marketplace/gestao-marketplace/lojas-em-destaque/editar-loja-destaque/:featured_store_id`}
                    component={FeaturedStoresForm}
                />
            </>
        )
    }, [basePath])

    const _getStore = useCallback(async () => {
        try {
            const { data } = await api.get<Store>(`/painel/marketplace/store/${store.id}`)
            setStoreData(data)
            setStoreStatus(Boolean(data?.status))
        } catch (error) {
            // setErrorModal({
            //     title: 'Erro',
            //     subtitle: 'Não foi possível carregar os dados da loja.',
            // })
        }
    }, [store.id])

    const _updateStoreStatus = useCallback(async () => {
        try {
            setLoading(true)
            const {
                data: { status },
            } = await api.put<{ status: boolean }>(`/painel/marketplace/store/${store.id}/change-status`)
            setStoreStatus(status)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [store?.id, setLoading])

    const _checkIsActive = useCallback((pathname: string, destiny: string) => {
        const pathParts = pathname.split('/')
        for (const pathPart of pathParts) {
            if (pathPart === destiny) return true
        }
        return false
    }, [])

    //useEffect(() => {
    //    revalidateStore()
    //}, [store])

    useEffect(() => {
        _getStore()
    }, [_getStore])

    //useEffect(() => {
    //    setStoreStatus(Boolean(storeData?.is_open))
    //}, [storeData])

    useEffect(() => {
        document.body.style.overflow = 'hidden'
    }, [pathname])

    return (
        <MarketplaceManagementContainer>
            <ContentContainer>
                <TabsContainer>
                    {storeData && (
                        <StoreInfoContainer>
                            <StoreLogo>
                                <LogoImage src={storeData?.logo} />
                            </StoreLogo>
                            <StoreTextContainer>
                                <StoreName>{storeData?.name}</StoreName>
                                <ButtonsContainer>
                                    <StoreRatingContainer>
                                        <StarIcon />
                                        {storeData?.global_rating > 0 ? storeData?.global_rating : 'Loja Nova'}
                                    </StoreRatingContainer>
                                    <StoreDetailButton
                                        href={`https://shop.deliverymall.com.br/${storeData?.mall?.slug}/loja/${storeData?.slug}`}
                                        target="_blank"
                                    >
                                        <ExternalIcon /> Visitar loja
                                    </StoreDetailButton>
                                </ButtonsContainer>
                                <SwitchButton
                                    isActive={Boolean(storeStatus)}
                                    activeLabel={'Loja aberta'}
                                    inactiveLabel={'Loja fechada'}
                                    onClick={_updateStoreStatus}
                                    isFlex
                                />
                            </StoreTextContainer>
                        </StoreInfoContainer>
                    )}
                    {links.map((link, index) => (
                        <TabItem
                            key={index}
                            isActive={_checkIsActive(pathname, link.destiny)}
                            to={`${parentPath}/${link.destiny}`}
                        >
                            {link.label}
                        </TabItem>
                    ))}

                    <MobileSelectTab
                        defaultValue={pathname.split('/').pop()}
                        onChange={({ target: { value } }) => history.push(value)}
                    >
                        {links.map((link, index) => (
                            <option key={index} value={link.destiny}>
                                {link.label}
                            </option>
                        ))}
                    </MobileSelectTab>
                </TabsContainer>
                <DetailsContainer>
                    <div className="marketplace-management-page-container">
                        <div className="right-column">
                            <Switch>
                                {store?.id ? (
                                    <>
                                        <Route exact path={`${basePath}/gestao-marketplace`}>
                                            <Redirect to={`${basePath}/gestao-marketplace/vendas`} />
                                        </Route>

                                        {_getMarketplaceShopkeeperRoutes()}
                                    </>
                                ) : (
                                    <>
                                        <Route exact path="/marketplace/gestao-marketplace">
                                            <Redirect to="/marketplace/gestao-marketplace/categorias-produto" />
                                        </Route>

                                        {_getMarketPlaceAdminRoutes()}
                                    </>
                                )}
                            </Switch>
                        </div>
                    </div>
                </DetailsContainer>
            </ContentContainer>
        </MarketplaceManagementContainer>
    )
})

export default MarketplaceManagement
