import { memo } from 'react'
import * as Feather from 'react-feather'
import Skeleton from 'react-loading-skeleton'

import { format } from 'date-fns'
import { ModalRomaneioReject } from 'modals/modal-romaneio-reject/modal-romaneio-reject'
import { ModalRomaneio } from 'modals/modal-romaneio/modal-romaneio'

import integration from 'assets/images/bg-integracoes.png'

import { DefaultButton } from 'components/default-button/default-button'
import { Text } from 'components/text/text'

import { useRomaneioController } from './romaneio-card-controller'
import {
    ActionRow,
    Row,
    CardHeader,
    CardNumber,
    ClockIcon,
    CardTitle,
    EmptyIcon,
    EmptyText,
    EmptyMessageContainer,
    OrdersList,
    ProgressCardContainer,
    LoadingContainer,
    DateGroup,
    Groupheader,
    GroupheaderTitle,
    GroupheaderSize,
    ContentInfo,
    Header,
    ContainerItem,
} from './romaneio-card.styled'

type Props = {
    header?: any
}
const RomaneioCard: React.FC<Props> = memo(({ header }) => {
    const {
        amount,
        loading,
        groupedRomaneio,
        ModalRomaneioRef,
        modalRomaneioRejectRef,
        _accept,
        _onClickRomaneio,
        _preview,
    } = useRomaneioController()

    return (
        <>
            <ProgressCardContainer>
                <CardHeader>
                    <CardTitle>Romaneios</CardTitle>
                    <CardNumber>{amount}</CardNumber>
                </CardHeader>
                {!!header && <Header>{header}</Header>}
                {loading ? (
                    <OrdersList>
                        {[...Array(6)].map((_item, index) => (
                            <LoadingContainer key={index}>
                                <Skeleton circle height={60} width={60} style={{ marginRight: 20 }} />

                                <Skeleton height={50} />
                            </LoadingContainer>
                        ))}
                    </OrdersList>
                ) : (
                    <OrdersList>
                        <>
                            {groupedRomaneio.length > 0 ? (
                                <>
                                    <>
                                        {groupedRomaneio.map((group, index) => (
                                            <DateGroup key={index}>
                                                <Groupheader>
                                                    <GroupheaderTitle>{group.label}</GroupheaderTitle>
                                                    <GroupheaderSize>{group.items.length}</GroupheaderSize>
                                                </Groupheader>
                                                {group.items.map((romaneio, index) => (
                                                    <ContainerItem key={index}>
                                                        <Row onClick={_onClickRomaneio(romaneio)}>
                                                            <ContentInfo>
                                                                <Text
                                                                    className="id"
                                                                    text={`#${romaneio.id}`}
                                                                    light
                                                                    dimensions="xxSmall"
                                                                />
                                                                <Text
                                                                    text={format(
                                                                        romaneio.created_at,
                                                                        'DD/MM/YYYY HH:mm'
                                                                    )}
                                                                    dimensions="normal"
                                                                    bold
                                                                />
                                                            </ContentInfo>
                                                            <Text className="times" light dimensions="xSmall">
                                                                <Feather.Package className="icon" /> Total:{' '}
                                                                {romaneio.total_awb}
                                                                {romaneio.total_awb > 1 ? 'AWBs' : 'AWB'}
                                                            </Text>
                                                        </Row>
                                                        <ActionRow>
                                                            <DefaultButton
                                                                onClick={() => _accept(romaneio)}
                                                                className="accept"
                                                                title="Aceitar"
                                                                variant="success"
                                                                simple
                                                            />
                                                            <DefaultButton simple onClick={() => _preview(romaneio)}>
                                                                Preview
                                                            </DefaultButton>
                                                        </ActionRow>
                                                    </ContainerItem>
                                                ))}
                                            </DateGroup>
                                        ))}
                                    </>
                                </>
                            ) : (
                                <EmptyMessageContainer>
                                    <EmptyIcon src={integration} />
                                    <EmptyText>
                                        Aqui você visualizará os pedidos do integrador para aceitar ou negar.
                                    </EmptyText>
                                </EmptyMessageContainer>
                            )}
                        </>
                    </OrdersList>
                )}
            </ProgressCardContainer>
            <ModalRomaneio ref={ModalRomaneioRef} />
            <ModalRomaneioReject ref={modalRomaneioRejectRef} />
        </>
    )
})

export { RomaneioCard }
