import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const SearchContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 180px;
    margin: 0px 10px;
`

const SearchInput = styled.input`
    border: 0;
    outline: none;
    flex: 1;
    background-color: transparent;
`

const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    margin-left: 10px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }
`

export { SearchContainer, SearchInput, SearchIcon }
