import { useEffect } from 'react'

import { format, subDays } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, showErrors } from 'helpers'
import { useDataFetching } from 'hooks'
import api from 'services/api'
import { Order } from 'types'

import DetailsTab from './details-tab/details-tab'
import {
    ContentContainer,
    ContentTitle,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
    TitleContainer,
    DetailsTabContainer,
    InfoSection,
    SectionTitle,
    InputsRow,
    SectionSubtitle,
    InputContainer,
} from './reschedule-content.styles'

interface Props {
    rescheduleOrderId: number | undefined
    closeClick(): void
}

const RescheduleContent: React.FC<Props> = ({ rescheduleOrderId, closeClick }) => {
    const { setErrorModal, setLoading, setSnackbar } = useUI()

    const { errors, getFieldProps, handleSubmit, setValues, isValid, touched } = useFormik({
        initialValues: {
            date: '',
            time: '',
        },
        validationSchema: Yup.object().shape({
            date: Yup.date()
                .min(subDays(new Date(), 1), 'Não é possível agendar um pedido em uma data passada.')
                .required('Por favor, insira a Data'),
            time: Yup.string()
                .trim()
                .when('date', (date: Date, schema: Yup.StringSchema) => {
                    return schema.test({
                        test: time => {
                            if (time) {
                                const [hour, minutes] = time?.split(':')
                                date.setHours(hour)
                                date.setMinutes(minutes)

                                const currentDate = new Date()

                                return date <= currentDate ? false : true
                            } else {
                                return false
                            }
                        },
                        message: time =>
                            time?.value
                                ? 'Não é possível agendar um pedido em horários passados.'
                                : 'Por favor, insira o Horário',
                    })
                }),
        }),
        onSubmit: async values => {
            try {
                setLoading(true)

                const { data } = await api.put(`/painel/dm-order/${rescheduleOrderId}/scheduled`, {
                    delivery_forecast: `${values.date} ${values.time + (values.time?.length < 8 ? ':00' : '')}`,
                })

                mutate(data)

                closeClick()
                setSnackbar({ message: 'Pedido reagendado' })
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao reagendar pedido',
                    subtitle: showErrors(error),
                })
            } finally {
                setLoading(false)
            }
        },
    })

    const { data, mutate } = useDataFetching(rescheduleOrderId ? `/painel/dm-order/${rescheduleOrderId}` : null, {
        onSuccess: (data: Order) => {
            if (data?.delivery_forecast) {
                const [date, time] = data.delivery_forecast.split(' ')

                setValues({ date: date, time: time.split(':').slice(0, 2)?.join(':') })
            }
        },
    })

    useEffect(() => {
        if (data?.delivery_forecast) {
            const [d, t] = data.delivery_forecast.split(' ')
            const date = d
            const time = t

            setValues({
                date,
                time,
            })
        } else {
            const [d, t] = format(new Date(), 'YYYY-MM-DD HH:mm').split(' ')

            const date = d
            const time = t

            setValues({
                date,
                time,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={closeClick}>
                    <CloseButton />
                </CloseContainer>
                <TitleContainer>
                    <ContentTitle>Reagendar Pedido</ContentTitle>
                </TitleContainer>

                <DetailsTabContainer>
                    <DetailsTab order={data} />

                    <InfoSection>
                        <SectionTitle>Reagendamento</SectionTitle>
                        <SectionSubtitle>Você poderá definir uma nova data e horário para entrega</SectionSubtitle>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="Data de entrega"
                                    type="date"
                                    inputProps={getFieldProps('date')}
                                    errorMessage={getFormInputError('date', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Horário"
                                    type="time"
                                    inputProps={getFieldProps('time')}
                                    errorMessage={getFormInputError('time', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                    </InfoSection>
                </DetailsTabContainer>
            </ContentContainer>
            <ButtonRow>
                <TextButton onClick={closeClick}>Cancelar</TextButton>
                <ConfirmButton disabled={!isValid} onClick={() => isValid && handleSubmit()}>
                    Reagendar
                </ConfirmButton>
            </ButtonRow>
        </OutsideContainer>
    )
}

export default RescheduleContent
