/* eslint-disable react/display-name */
import React, { memo, useCallback, useEffect, useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'

import { IAddress } from 'types/address'

import { Container, TextButton } from './input-place.styled'
const REACT_APP_GOOGLE_MAP_JS_KEY = process.env.REACT_APP_GOOGLE_MAP_JS_KEY

interface InputPlaceProps {
    onChange?(address: IAddress): void
    placeholder?: string
    showButtonText?: boolean
    onButtonTextClick?(): void
}

const InputPlace: React.FC<InputPlaceProps> = memo(({ onChange, placeholder, showButtonText, onButtonTextClick }) => {
    const [place, setPlace] = useState(null)

    const _getPlace = useCallback(
        async (placeId: string) => {
            try {
                const result = await geocodeByPlaceId(placeId)
                if (result.length > 0) {
                    const place = result[0]
                    const addressComponents = place.address_components

                    const address = {
                        city: getValueByType('administrative_area_level_2', addressComponents),
                        complement: '',
                        landmark: '',
                        location: {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng(),
                        },
                        neighborhood: getValueByType('sublocality_level_1', addressComponents),
                        number: getValueByType('street_number', addressComponents),
                        state: getValueByType('administrative_area_level_1', addressComponents),
                        street: getValueByType('route', addressComponents),
                        zipcode: getValueByType('postal_code', addressComponents),
                    }

                    if (onChange) {
                        onChange(address)
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
        },
        [onChange]
    )

    useEffect(() => {
        if (place?.value?.place_id) {
            _getPlace(place.value.place_id)
        }
    }, [place])

    return (
        <Container>
            <GooglePlacesAutocomplete
                apiKey={REACT_APP_GOOGLE_MAP_JS_KEY}
                apiOptions={{
                    language: 'pt-BR',
                    region: 'br',
                    libraries: ['places'],
                }}
                selectProps={{
                    placeholder: placeholder || 'Procurar Endereço',
                    noOptionsMessage: () => 'Endereço não encontrado',
                    value: place,
                    onChange: setPlace,
                }}
            />
            {showButtonText && <TextButton onClick={onButtonTextClick}>Inserir endereço manualmente</TextButton>}
        </Container>
    )
})

function getValueByType(type: any, addressComponents: any) {
    return addressComponents.find(item => item.types.includes(type))?.short_name
}

export { InputPlace }
