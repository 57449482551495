import {
    NavigationRowContainer,
    NavigationLeftSide,
    NavigationItem,
    NavigationItemContainer,
    NewItemIcon,
    NumbersContainer,
    NumberItem,
    NumberValue,
    NumberLabel,
    ButtonsContainer,
    MobileSelectTab,
} from './picker-navigation-row.styles'

interface Props {
    isNewActive?: boolean
    isInRouteActive?: boolean
    isProductionActive?: boolean
    inProductionValue?: number
    deliveredValue?: number
    newsClick?(): void
    productionClick?(): void
    inRouteClick?(): void
    openCollectModal?(): void
    showRequestButton?: boolean
}

const PickerNavigationRow: React.FC<Props> = ({ inProductionValue, deliveredValue, inRouteClick, isInRouteActive }) => {
    return (
        <NavigationRowContainer>
            <NavigationLeftSide>
                <MobileSelectTab>
                    <option value="delivery">Coletas</option>
                </MobileSelectTab>
                <ButtonsContainer>
                    <NavigationItemContainer isActive={isInRouteActive}>
                        <NavigationItem onClick={inRouteClick} isActive={isInRouteActive}>
                            Coletas
                        </NavigationItem>
                        <NewItemIcon>!</NewItemIcon>
                    </NavigationItemContainer>
                </ButtonsContainer>
            </NavigationLeftSide>
            <NumbersContainer>
                <NumberItem>
                    <NumberValue>{inProductionValue}</NumberValue>
                    <NumberLabel>Pedidos em andamento</NumberLabel>
                </NumberItem>
                <NumberItem>
                    <NumberValue>{deliveredValue}</NumberValue>
                    <NumberLabel>Pedidos entregues</NumberLabel>
                </NumberItem>
            </NumbersContainer>
        </NavigationRowContainer>
    )
}

export default PickerNavigationRow
