import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { values } from 'lodash'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import api from 'services/api'
import { PagedList, StoreCategory, StoreType } from 'types'

import {
    ButtonRow,
    CategoriesList,
    CategoryListItem,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './modal-delete-store-type.styled'

export interface ModalDeleteStoreTypeRef {
    show?(id: number, storeCategories: StoreCategory[]): void
    close?(): void
}

type Props = {
    onClose: (refresh: boolean) => void
}

type IForm = {
    store_type_id: string
}

const ModalDeleteStoreType = memo(
    forwardRef<ModalDeleteStoreTypeRef, Props>(({ onClose }, ref) => {
        const { setErrorModal, setConfirmationModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [storeTypeId, setStoreTypeId] = useState<number>()
        const [storeTypes, setStoreTypes] = useState<StoreType[]>()
        const [storeCategory, setStoreCategory] = useState<StoreCategory[]>()
        const [isLoading, setIsLoading] = useState<boolean>()

        const filteredOptions = useMemo(() => {
            return storeTypes
                ?.filter(item => item.id !== storeTypeId)
                ?.map(item => ({
                    label: item.name,
                    value: item.id,
                }))
        }, [storeTypeId])

        const { resetForm, setFieldValue, handleSubmit, errors, touched, values } = useFormik<IForm>({
            initialValues: {
                store_type_id: '',
            },
            validationSchema: Yup.object().shape({
                store_type_id: Yup.string().trim().required('vertical de loja é obrigatório'),
            }),
            onSubmit: values => {
                const storeTypeDelete = async () => {
                    setIsLoading(true)

                    try {
                        await api.delete(`/painel/store-types/${storeTypeId}`, {
                            params: {
                                move_to: values.store_type_id,
                            },
                        })

                        lateralModalBaseRef.current?.close()
                        onClose(true)
                        _cleanModal()
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro!',
                            subtitle: 'Não foi possível excluir vertical de loja!',
                        })
                    }
                    setIsLoading(false)
                }

                setConfirmationModal({
                    title: 'Excluir vertical de loja',
                    subtitle: 'Tem certeza de que deseja excluir esta vertical de loja?',
                    type: 'alert',
                    modalIcon: 'trash-alt',
                    leftButtonText: 'Cancelar',
                    rightButtonText: 'Sim, excluir!',
                    rightButtonClick: storeTypeDelete,
                })
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: (id: number, storeCategories: StoreCategory[]) => {
                    _cleanModal()

                    setStoreTypeId(id)
                    setStoreCategory(storeCategories)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    _cleanModal()
                    lateralModalBaseRef.current?.close()
                },
            }),
            []
        )

        const _cleanModal = useCallback(() => {
            setStoreTypeId(null)
            setStoreCategory(null)
            resetForm()
        }, [])

        const _handleChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('store_type_id', Number(value))
        }, [])

        const _getStoreTypes = useCallback(async () => {
            const {
                data: { items },
            } = await api.get<PagedList<StoreType>>('painel/store-types', {
                params: { status: [1] },
            })

            setStoreTypes(items)
        }, [storeTypeId])

        const _handleDelete = useCallback(() => handleSubmit(), [])

        useEffect(() => {
            _getStoreTypes()
        }, [_getStoreTypes])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={'Transferência de categorias'}>
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <h3>Esta vertical de loja possui categorias atreladas.</h3>
                            <p>Selecione uma vertical de loja para que suas categorias sejam transferidas.</p>
                        </FormSection>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Vertical de loja"
                                    options={filteredOptions}
                                    inputProps={{
                                        value: values.store_type_id,
                                        onChange: _handleChange,
                                    }}
                                    errorMessage={touched.store_type_id && errors.store_type_id}
                                />
                            </InputContainer>
                        </FormSection>

                        <FormSection>
                            <h3>Categorias a serem transferidas:</h3>

                            <CategoriesList>
                                {storeCategory?.map((item, key) => (
                                    <CategoryListItem key={key} isActive={Boolean(item.status)}>
                                        {item.name}
                                    </CategoryListItem>
                                ))}
                            </CategoriesList>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify={'flex-end'}>
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={_handleDelete}>Excluir</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isLoading} />
            </LateralModalBase>
        )
    })
)

export { ModalDeleteStoreType }
