import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { DefaultButton } from 'components/default-button/default-button'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: 0.75rem;
    padding: 1rem 0.75rem;

    background-color: ${({ theme }) => theme.colors.gray};
`

const DraggableShowcaseContainer = styled.div`
    display: flex !important;
    align-items: center;
    gap: 0.5rem;
`

const DraggableShowcaseContent = styled.div<{ openList?: boolean }>`
    overflow: hidden;
    transition: all 400ms ease-out;
    min-height: ${({ openList }) => (openList ? '300px' : '46px')};
    max-height: ${({ openList }) => (openList ? 'fit-content' : '46px')};
    //border: 0.063rem solid #ccc;
    //background-color: #fff;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    width: 100%;
`

const DraggableContent = styled.div<{ openList?: boolean }>`
    overflow: hidden;
    min-height: ${({ openList }) => (openList ? '100px' : '0')};
    max-height: ${({ openList }) => (openList ? 'fit-content' : '46px')};

    transition: all 400ms ease-out;
    transform: ${({ openList }) => (openList ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
`

const ShowcaseItemHeader = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //padding-right: 1.25rem;
    padding: 0.5rem;

    background-color: #eaeaea;
`

const ShowcaseItemHeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 0.75rem;
    color: ${({ theme }) => theme.colors.textLight};
`

const ShowcaseItemHeaderActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`

const ProductInfo = styled.div`
    display: flex;
    //margin-right: 5%;
    margin-bottom: 20px;
    flex-basis: 100%;
    margin-top: 20px;
    flex: 1;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 49%;
        margin-bottom: 0;
        margin-top: 0;
    }
`
const ProductPhoto = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 12px;
    object-fit: cover;
    margin-right: 20px;
`

const ProductText = styled.div`
    flex: 1;
`

const ProductName = styled.div`
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3a3a3a;
`

const ProductDescription = styled.div`
    //background-color: #f1f1f1;
    //padding: 10px;
    //border-radius: 12px;
    height: 55px;
    overflow: auto;
    font-size: 0.75rem;
`

const ActionButton = styled(DefaultButton)`
    width: 2rem;
    height: 2rem;

    padding: 0.25rem;

    transition: all 500ms;
    svg {
        margin: 0;
    }
`

const EditIcon = styled(FontAwesomeIcon).attrs({
    icon: 'edit',
})`
    svg {
        margin: 0rem;
    }

    color: ${({ theme }) => theme.colors.white};
`

const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
`
const ViewIcon = styled(FontAwesomeIcon).attrs({
    icon: 'eye',
})`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
`

const ClosedViewIcon = styled(FontAwesomeIcon).attrs({
    icon: 'eye-slash',
})`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
`

const StatusItem = styled.div<{ isActive?: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.danger)};
`

const ReorderRowActions = styled.div`
    width: 2rem;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    /* justify-content: center; */
    gap: 0.5rem;

    .arrow-down {
        justify-self: end;
    }
`

const ReorderActions = styled(FontAwesomeIcon)<{ isActive?: boolean }>`
    width: 0.5rem;
    height: 0.875rem;
    cursor: pointer;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    align-items: center;
    justify-content: end;
`

const ShowCaseStatus = styled.span<{ isActive?: boolean }>`
    font-size: 0.75rem;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.danger)};
`

export {
    DraggableShowcaseContainer,
    DeleteIcon,
    ViewIcon,
    ClosedViewIcon,
    EditIcon,
    ActionButton,
    ShowcaseItemHeader,
    ShowcaseItemHeaderContent,
    ShowcaseItemHeaderActions,
    Container,
    DraggableShowcaseContent,
    DraggableContent,
    ProductPhoto,
    ProductDescription,
    ProductInfo,
    ProductName,
    ProductText,
    StatusItem,
    ReorderRowActions,
    ReorderActions,
    ActionsContainer,
    ShowCaseStatus,
}
