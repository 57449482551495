import { subDays, format } from 'date-fns'
import { FormikErrors } from 'formik'

type SetFilter<Values> = (
    values: React.SetStateAction<Values>,
    shouldValidate?: boolean | undefined
) => Promise<FormikErrors<Values>> | Promise<void>

interface FilterProps {
    label: string
    key: 'today' | 'yesterday' | 'this_week' | 'this_month' | 'last_month'
    onClick(): void
}

function getFormattedDateFilters<Values>(filters: Values, setFilter: SetFilter<Values>): FilterProps[] {
    const today = new Date()

    return [
        {
            label: 'Hoje',
            key: 'today',
            onClick: () => {
                setFilter({
                    ...filters,
                    selectedPeriod: 'today',
                    start_date: format(today, 'YYYY-MM-DD'),
                    end_date: format(today, 'YYYY-MM-DD'),
                })
            },
        },
        {
            label: 'Ontem',
            key: 'yesterday',
            onClick: () => {
                const yesterday = format(subDays(today, 1), 'YYYY-MM-DD')

                setFilter({
                    ...filters,
                    selectedPeriod: 'yesterday',
                    start_date: yesterday,
                    end_date: yesterday,
                })
            },
        },
        {
            label: 'Esta semana',
            key: 'this_week',
            onClick: () => {
                const lastSunday = format(new Date(today.setDate(today.getDate() - today.getDay())), 'YYYY-MM-DD')

                setFilter({
                    ...filters,
                    selectedPeriod: 'this_week',
                    start_date: lastSunday,
                    end_date: format(new Date(), 'YYYY-MM-DD'),
                })
            },
        },
        {
            label: 'Este mês',
            key: 'this_month',
            onClick: () => {
                const firstDay = format(today.setDate(1), 'YYYY-MM-DD')
                setFilter({
                    ...filters,
                    selectedPeriod: 'this_month',
                    start_date: firstDay,
                    end_date: format(new Date(), 'YYYY-MM-DD'),
                })
            },
        },
        {
            label: 'Mês anterior',
            key: 'last_month',
            onClick: () => {
                today.setDate(1)
                setFilter({
                    ...filters,
                    selectedPeriod: 'last_month',
                    start_date: format(new Date(today.getFullYear(), today.getMonth() - 1), 'YYYY-MM-DD'),
                    end_date: format(new Date(today.getFullYear(), today.getMonth(), 0), 'YYYY-MM-DD'),
                })
            },
        },
    ]
}

export { getFormattedDateFilters }
