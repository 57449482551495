import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import ReactTooltip from 'react-tooltip'

import { Accordion } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'

import { IntegrationsContext } from 'contexts/integration-context'
import { plugApi } from 'services/api'
import { IIntegration } from 'types/integration'

import {
    CloseButton,
    CloseContainer,
    FormSectionTitle,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
    ContentAccordion,
    AwaitMessage,
    StoreName,
    Message,
} from './settings-integrator-content.styled'

interface Props {
    closeClick(): void
    isActive: boolean
    refresh(): void
}

const SettingsIntegratorContent: React.FC<Props> = memo(({ closeClick, isActive, refresh }) => {
    const { loadingIntegrations, integrators, refreshIntegrations } = useContext(IntegrationsContext)

    const [hasChanges, setHasChanges] = useState(false)
    const [loading, setLoading] = useState(false)

    const _onClose = useCallback(() => {
        if (hasChanges) {
            setHasChanges(false)
            refresh()
        }
        if (closeClick) {
            closeClick()
        }
    }, [hasChanges, closeClick, refresh])

    useEffect(() => {
        if (isActive) {
            refreshIntegrations()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive])

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={_onClose}>
                    <CloseButton />
                </CloseContainer>
                <ContentTitle>Integrações</ContentTitle>

                <FormSection>
                    {!(loading || loadingIntegrations) && (
                        <>
                            {integrators.length > 0 ? (
                                <>
                                    <FormSectionTitle>Integrações disponíveis</FormSectionTitle>
                                    {integrators.map((item, i) => (
                                        <Accordion key={i} iconType="sliders" accordionTitle={item.name?.toUpperCase()}>
                                            {item.integration.map((item, i) => (
                                                <IntegrationItem
                                                    key={i}
                                                    item={item}
                                                    refresh={() => {
                                                        setHasChanges(true)
                                                        refreshIntegrations()
                                                    }}
                                                    setLoading={v => setLoading(v)}
                                                />
                                            ))}
                                        </Accordion>
                                    ))}
                                </>
                            ) : (
                                <div>Nenhuma integração configurada</div>
                            )}
                        </>
                    )}
                </FormSection>
            </ContentContainer>
            <ModalLoading visible={loading || loadingIntegrations} />
        </OutsideContainer>
    )
})

type IntegrationItemProps = {
    item: IIntegration
    refresh(): void
    setLoading(value: boolean): void
}
const IntegrationItem: React.FC<IntegrationItemProps> = memo(({ item, refresh, setLoading }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [disabledToggleStore, setDisabledToggleStore] = useState(false)

    const _changeStatus = useCallback(async () => {
        const { id, status } = item
        setLoading(true)
        try {
            await plugApi.put<IIntegration>(`/integrations/${id}`, {
                status: !status,
            })
            await refresh()
        } catch (error) {
            setLoading(false)
            console.log('error')
        }
    }, [item, refresh, setLoading])

    const _onChange = useCallback(async () => {
        setIsOpen(!isOpen)
        try {
            const { origin } = item
            await plugApi.post<{ status: boolean }>(`/store/${isOpen ? 'close' : 'open'}`, {
                origin_id: origin.id,
            })

            refresh()

            setDisabledToggleStore(true)
        } catch (error) {
            setIsOpen(!isOpen)
        }
    }, [item, isOpen, refresh])

    const _onFinished = useCallback(() => {
        setDisabledToggleStore(false)
    }, [])

    useEffect(() => {
        setIsOpen(!!item.extra?.store_status)
    }, [item.extra?.store_status])

    return (
        <ContentAccordion>
            <StoreName>{item.origin.name}</StoreName>
            <SwitchButton
                isFlex
                isActive={item.status}
                onClick={_changeStatus}
                activeLabel="Integração Ativa"
                inactiveLabel="Integração Inativa"
            />
            <SwitchButton
                isFlex
                disabled={disabledToggleStore || item.type === 'ifood'}
                isActive={isOpen}
                onClick={_onChange}
                activeLabel="Loja Aberta"
                inactiveLabel="Loja Fechada"
                data-for="switch-status-tooltip"
                data-tip={'Abrir/Fechar Loja somente pelo Ifood'}
            />
            {!!item.message?.subtitle && <Message>{item.message.subtitle}</Message>}
            {item.type === 'ifood' && <ReactTooltip id="switch-status-tooltip" />}
            {disabledToggleStore && (
                <AwaitMessage>
                    Aguarde <Timer awaitTime={disabledToggleStore} onFinished={_onFinished} /> segundos alterar
                    novamente
                </AwaitMessage>
            )}
        </ContentAccordion>
    )
})

type TimerProps = {
    awaitTime: boolean
    onFinished(): void
}

const Timer: React.FC<TimerProps> = memo(({ awaitTime, onFinished }) => {
    const intervalRef = useRef<NodeJS.Timer>()
    const counterRef = useRef<number>(0)
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        if (awaitTime) {
            setTimer(30)
            counterRef.current = 30

            intervalRef.current = setInterval(() => {
                const count = counterRef.current - 1

                if (count === 0) {
                    if (onFinished) {
                        onFinished()
                    }
                    clearInterval(intervalRef.current)
                    return
                }
                counterRef.current = count
                setTimer(count)
            }, 1000)
        } else {
            setTimer(0)
            counterRef.current = 0
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [awaitTime])

    return <span>{timer}</span>
})

export { SettingsIntegratorContent }
