import styled from 'styled-components'

export const ListBox = styled.div`
    height: 250px;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.white};
    @media (min-width: ${({ theme }) => theme.devices.tabletPro}) {
        height: 350px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: unset;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        /* for Firefox */
        min-height: 0;
    }
`

export const ListScroll = styled.div`
    flex: 1;
    overflow: auto;
`

export const ListTitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.gray};
`

export const ListTitle = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`

export const NumberContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.white};
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`

export const RouteCreationContainer = styled.div`
    display: flex;
    padding: 10px 20px;
    justify-content: flex-end;
    background-color: ${({ theme }) => theme.colors.gray};
`

export const RouteCreationButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
`

export const ListsContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    min-height: 0;
    position: relative;
`

export const MapContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 60%;
        background-color: green;
    }
`

export const RouteCreationContentContainer = styled.div<{ isActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: -2px;
    left: 0;
    transition: 400ms;
    transform: translateY(${({ isActive }) => (isActive ? '0%' : '100%')});
    display: flex;
    flex-direction: column;
    min-height: 0;
`
