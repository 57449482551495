import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { SwitchButton } from 'components/_marketplace-management'
import ModalLoading from 'components/modal-loading'

import { removeMask } from 'containers/store-creation/store-creation-helpers'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { IPicker } from 'types/picker'

import {
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    InputsRow,
    StatusContainer,
    ButtonRow,
    ConfirmButton,
    Row,
    TextButton,
} from './picker-management-modal.styled'

export type PickerManagementModalRef = {
    show(): void
    close(): void
    setPicker?(picker: IPicker): void
}

type Props = {
    onClose?(isRefresh: boolean): void
}

type IForm = {
    first_name?: string
    last_name?: string
    email?: string
    cellphone?: string
    status?: 0 | 1
    password?: string
    password_confirmation?: string
}

const atLeastOneDigit = /.*\d.*/
const atLeastOneLowercaseCharacter = /.*[a-z].*/
const atLeastOneUppercaseCharacter = /.*[A-Z].*/

const PickerManagementModal = memo(
    forwardRef<PickerManagementModalRef, Props>(({ onClose }, ref) => {
        const { mall } = useAuth()
        const { setSuccessModal, setErrorModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [picker, setPicker] = useState<IPicker>()

        const {
            isSubmitting,
            values,
            touched,
            errors,
            resetForm,
            setValues,
            setFieldValue,
            getFieldProps,
            handleSubmit,
        } = useFormik<IForm>({
            initialValues: {
                first_name: '',
                last_name: '',
                email: '',
                cellphone: '',
                status: 1,
            } as IForm,
            validationSchema: Yup.object().shape({
                first_name: Yup.string().required('Nome é um campo obrigatório'),
                last_name: Yup.string().required('Sobrenome é um campo obrigatório'),
                email: Yup.string().email('E-mail inválido').required('E-mail é um campo obrigatório'),
                status: Yup.number().required('Status é obrigatório'),
                password: Yup.string().when('status', {
                    is: () => !isEdit,
                    then: schema =>
                        schema
                            .trim()
                            .min(8, 'Senha deve ter no mínimo 8 caracteres')
                            .required('Preencha com sua Nova senha')
                            .matches(atLeastOneDigit, 'Deve conter um número')
                            .matches(atLeastOneLowercaseCharacter, 'Deve conter uma letra mínuscula')
                            .matches(atLeastOneUppercaseCharacter, 'Deve conter uma letra maiúscula'),
                    otherwise: schema => schema.notRequired(),
                }),
                password_confirmation: Yup.string().when('status', {
                    is: () => !isEdit,
                    then: schema =>
                        schema
                            .trim()
                            .oneOf([Yup.ref('password'), ''], 'Senhas não correspondem')
                            .required('Confirme a Nova senha'),
                    otherwise: schema => schema.notRequired(),
                }),
            }),
            onSubmit: async values => {
                const url = isEdit ? `/painel/pickers/${picker?.id}` : `/painel/pickers`
                const body = {
                    ...values,
                    mall_id: mall.id,
                    cellphone: `+55${removeMask(values.cellphone)}`,
                }
                try {
                    isEdit ? await api.put(url, body) : await api.post(url, body)

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: `Picker ${isEdit ? 'atualizado' : 'registrado'} com sucesso`,
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: `Houve um erro ao ${isEdit ? 'atualizar' : 'registrar'} picker`,
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setPicker(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    resetForm()
                    setPicker(null)
                    lateralModalBaseRef.current?.close()
                },
                setPicker: (picker: IPicker) => {
                    setPicker(picker)
                    setValues(picker)
                },
            }),
            []
        )

        const isEdit = useMemo(() => {
            return !!picker
        }, [picker])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Atualizar' : 'Criar'} Usuário Picker`
        }, [isEdit])

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        const _handleChange = useCallback(
            (fieldName: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(fieldName, value)
                },
            []
        )

        const _handleSwitchChange = useCallback(() => {
            setFieldValue('status', !values.status ? 1 : 0)
        }, [values])

        useEffect(() => {
            if (picker) {
                setValues({
                    first_name: picker.first_name,
                    last_name: picker.last_name,
                    email: picker.email,
                    cellphone: picker.cellphone.replace('+55', ''),
                    status: picker.status ? 1 : 0,
                })
            }
        }, [picker])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputsRow>
                                    <InputItem
                                        labelText="Nome"
                                        inputProps={{
                                            ...getFieldProps('first_name'),
                                            onChange: _handleChange('first_name'),
                                        }}
                                        errorMessage={touched.first_name && errors.first_name}
                                    />
                                    <InputItem
                                        labelText="Sobrenome"
                                        inputProps={{
                                            ...getFieldProps('last_name'),
                                            onChange: _handleChange('last_name'),
                                        }}
                                        errorMessage={touched.last_name && errors.last_name}
                                    />
                                </InputsRow>
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    labelText="E-mail"
                                    inputProps={{
                                        ...getFieldProps('email'),
                                        type: 'email',
                                    }}
                                    errorMessage={touched.email && errors.email}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Telefone"
                                    inputProps={{
                                        ...getFieldProps('cellphone'),
                                        mask: '(99) 99999-9999',
                                    }}
                                    errorMessage={touched.cellphone && errors.cellphone}
                                />
                            </InputContainer>

                            {!isEdit && (
                                <>
                                    <InputContainer>
                                        <InputItem
                                            type="password"
                                            labelText="Senha"
                                            inputProps={getFieldProps('password')}
                                            errorMessage={touched.password && errors.password}
                                        />
                                    </InputContainer>
                                    <InputContainer hasMargin>
                                        <InputItem
                                            type="password"
                                            labelText="Confirmação de Senha"
                                            inputProps={getFieldProps('password_confirmation')}
                                            errorMessage={touched.password_confirmation && errors.password_confirmation}
                                        />
                                    </InputContainer>
                                </>
                            )}

                            <InputContainer>
                                <StatusContainer>
                                    Status
                                    <SwitchButton
                                        isFlex
                                        isActive={Boolean(values.status)}
                                        onClick={_handleSwitchChange}
                                    />
                                </StatusContainer>
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{'Salvar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { PickerManagementModal }
