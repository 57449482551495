import { faTimes, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ContentContainer = styled.div`
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
        padding-top: 0;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const TitleContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
    }
`

const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 30px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

const OrderStatus = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
        padding-top: 10px;
    }
`

const StatusCircle = styled.div<{ isFinished: boolean }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ isFinished, theme }) => (isFinished ? theme.colors.green : theme.colors.darkOrange)};
`

const StatusTitle = styled.div`
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
`

const HeaderContainer = styled.div`
    padding: 15px;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
        padding-top: 30px;
        padding-bottom: 0;
    }
`

const ButtonsRow = styled.div`
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: #f6f6f6;

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
    }
`

const TextAccept = styled.div`
    user-select: none;
    color: ${({ theme }) => theme.colors.success};
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
    transition: 400ms;
    &:hover {
        text-decoration: underline;
    }
    &:not(:last-child) {
        margin-right: 30px;
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            margin-right: 50px;
        }
    }
`
const TextReject = styled.div`
    user-select: none;
    color: ${({ theme }) => theme.colors.errorMessage};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        text-decoration: underline;
    }
    &:not(:last-child) {
        margin-right: 30px;
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            margin-right: 50px;
        }
    }
`

const CancelIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimesCircle,
})`
    margin-right: 5px;
`

const ApproveIcon = styled(FontAwesomeIcon).attrs({
    icon: faCheckCircle,
})`
    margin-right: 5px;
`

export {
    ContentContainer,
    ContentTitle,
    OutsideContainer,
    CloseButton,
    CloseContainer,
    TitleContainer,
    OrderStatus,
    ApproveIcon,
    StatusCircle,
    StatusTitle,
    HeaderContainer,
    ButtonsRow,
    TextAccept,
    TextReject,
    CancelIcon,
}
