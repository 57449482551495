import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 71px);
    width: 100vw;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: calc(100vw - 80px);
    }

    * {
        box-sizing: border-box;
    }

    @keyframes bgColorAlert {
        0% {
            background-color: #c0392b;
        }
        50% {
            background-color: #ff0000;
        }
        100% {
            background-color: #c0392b;
        }
    }

    @keyframes shake {
        10%,
        90% {
            background-color: rgba(255, 122, 32, 0.2);
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            background-color: rgba(255, 122, 32);
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            background-color: rgba(255, 122, 32, 0.1);
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            background-color: rgba(255, 122, 32);
            transform: translate3d(4px, 0, 0);
        }
    }
`

const Header = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    .input-item {
        min-width: 400px;
    }
    @media (max-width: ${({ theme }) => theme.devices.tabletPro}) {
        flex-direction: column;
        .input-item {
            min-width: inherit;
            width: 100%;
        }
    }
`

const RowButtons = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.devices.tabletPro}) {
        width: 100%;
    }
`

const ContainerCheckbox = styled.div``

const RowCheckbox = styled.div`
    gap: 10px;
    display: flex;
    align-items: center;
    margin-top: 16px;
`

const ButtonOutline = styled.div`
    cursor: pointer;
    color: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: ${({ theme }) => theme.colors.primary};
    margin: 0px 10px;
    &:hover {
        opacity: 0.6;
    }
`

const ContainerBoard = styled.div<{ expand?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #e0e0e0;
    position: relative;
    ${({ expand }) =>
        expand &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999999999999999;
        `}
`

const ContainerColumns = styled.div`
    width: fit-content;
    display: flex;
    height: 100%;
    position: relative;
`

const ContainerHeaderFill = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
`

const TitleFill = styled.div`
    font-weight: bold;
    font-size: 18px;
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
`

const ContainerButtons = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`

export {
    ContainerButtons,
    ButtonOutline,
    Container,
    ContainerBoard,
    ContainerColumns,
    Header,
    ContainerHeaderFill,
    TitleFill,
    ContainerCheckbox,
    RowCheckbox,
    RowButtons,
}
