export const bikers = [
    {
        name: 'Yan Gondim',
        vehicle: 'Motocicleta',
        bikerRating: 4.7,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 55,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 7,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 95,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 8,
    },

    {
        name: 'Erlon Charles',
        vehicle: 'Motocicleta',
        bikerRating: 4.9,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 35,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 12,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },
    {
        name: 'Yan Gondim',
        vehicle: 'Motocicleta',
        bikerRating: 4.7,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 55,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 7,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 95,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 8,
    },

    {
        name: 'Erlon Charles',
        vehicle: 'Motocicleta',
        bikerRating: 4.9,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 35,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 12,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },

    {
        name: 'Samuel Batista',
        vehicle: 'Motocicleta',
        bikerRating: 4.2,
        photo: 'https://i.pravatar.cc/150?img:8',
        batteryLevel: 75,
        hasWifi: true,
        buildVersion: '1.10.20',
        androidVersion: 9,
    },
]
