import { Feature, Layer } from 'react-mapbox-gl'

import colors from '../../../themes/colors'

const DraggableMarker = ({ coordinates, onDragEnd }) => {
    return coordinates?.length ? (
        <>
            <Layer
                type="circle"
                id="position-marker"
                paint={{
                    'circle-radius': 15,
                    'circle-blur': 0.15,
                    'circle-color': colors.dark_primary_color,
                    'circle-stroke-width': 4,
                    'circle-stroke-color': 'white',
                }}
            >
                <Feature coordinates={coordinates} draggable onDragEnd={onDragEnd} />
            </Layer>
        </>
    ) : null
}

export default DraggableMarker
