import styled from 'styled-components'

const Container = styled.div``

const Table = styled.table`
    width: 100%;
`

const RowTable = styled.tr``

const ColumnTable = styled.td`
    padding: 10px 5px 20px 5px;
    .eye {
        cursor: pointer;

        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export { Container, Table, RowTable, ColumnTable }
