import { AmountCardContainer, AmountSubtitle, AmountTitle, Number } from './amount-card.styles'

interface Props {
    number: string | number | undefined
    title: string
    subtitle?: string
    width?: string
}

const AmountCard: React.FC<Props> = ({ number, title, subtitle, width }) => {
    return (
        <AmountCardContainer style={{ width: width || '100%' }}>
            <Number>{number !== undefined ? number : '-'}</Number>
            <AmountTitle>{title}</AmountTitle>
            {!!subtitle && <AmountSubtitle>{subtitle}</AmountSubtitle>}
        </AmountCardContainer>
    )
}

export default AmountCard
