import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAuth } from 'hooks/useAuth'
import { api2 } from 'services/api'
import { AgentsActions } from 'store/reducers/agents'
import RootState, { AgentsState } from 'store/reducers/types'
import { IPagination } from 'types/paginate'
import { IAgent } from 'types/route'

import { useEchoConnection } from './use-echo-connection'

type Hook = AgentsState & {
    isSocketConnected: boolean
    loading: boolean
    revalidateAgents(): Promise<void>
}

export function useAgentsAvailable(): Hook {
    const dispatch = useDispatch()
    const { mall } = useAuth()
    const agentsState = useSelector<RootState, AgentsState>(({ agents }) => agents)

    const [loading, setLoading] = useState(false)

    const { setAgentsAvailable, updateAgentsAvailable, addAgentsAvailable, removeAgentsAvailable } = AgentsActions

    const events = useMemo(() => {
        return [
            {
                name: '.added',
                callback: (payload: IAgent) => {
                    dispatch(addAgentsAvailable(payload))
                },
            },
            {
                name: '.location',
                callback: (payload: any) => {
                    dispatch(updateAgentsAvailable(payload))
                },
            },
            {
                name: '.device-update',
                callback: (payload: any) => {
                    dispatch(updateAgentsAvailable(payload))
                },
            },
            {
                name: '.removed',
                callback: (payload: IAgent) => {
                    dispatch(removeAgentsAvailable(payload))
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { connected } = useEchoConnection<any, any>({
        enable: !!mall?.id,
        channelName: mall?.id ? `v2.queue.mall.${mall?.id}` : null,
        events,
    })

    const _listAgents = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api2.get<IPagination<IAgent>>(`malls/${mall.slug}/queue`)

            dispatch(setAgentsAvailable({ items: data.items, totals: data.meta.paginate.totals }))
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [mall, dispatch, setAgentsAvailable])

    const revalidateAgents = useCallback(async () => {
        await _listAgents()
    }, [_listAgents])

    useEffect(() => {
        _listAgents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { ...agentsState, isSocketConnected: connected, loading, revalidateAgents }
}
