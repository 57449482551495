import { useState } from 'react'
import { useHistory } from 'react-router'

import { format } from 'date-fns'

import RoutesInProgressModal from 'components/_common/routes-in-progress-modal'

import { useAuth } from 'hooks'

import api from '../../services/api'

import TopTagItem from './top-tag-item/top-tag-item'

import './top-tags.scss'

const TopTags = ({ agentsAvailable, agentsAvailableClick, routesInProgress, routesFinished, ordersFinished }) => {
    const history = useHistory()
    const [routes, setRoutes] = useState([])
    const [routesModal, setRoutesModal] = useState(false)
    const { basePath, mall } = useAuth()

    const _onRoutesClick = async () => {
        try {
            setRoutesModal(true)
            const { data } = await api.get(`/painel/routes/running`, {
                params: { mall: mall.id, per_page: -1 },
            })
            setRoutes(data.items.reverse())
        } catch (err) {
            console.log('ON ROUTES CLICK ERROR:', err)
            setRoutes([])
        }
    }

    return (
        <div className="top-tags-container">
            <TopTagItem
                iconName="users"
                value={agentsAvailable || 0}
                label={agentsAvailable === 1 ? 'Entregador disponível' : `Entregadores disponíveis`}
                onClick={agentsAvailableClick}
            />

            <TopTagItem
                iconName="paper-plane"
                value={routesInProgress}
                label="Rotas em andamento"
                onClick={_onRoutesClick}
            />

            <div className="desktop-only">
                <TopTagItem
                    iconName="flag"
                    value={routesFinished}
                    label="Rotas realizadas"
                    onClick={() =>
                        history.push({
                            pathname: `${basePath}/rotas`,
                            search: `?start_date=${format(new Date(), 'YYYY-MM-DD')}&end_date=${format(
                                new Date(),
                                'YYYY-MM-DD'
                            )}&status=3`,
                        })
                    }
                />
            </div>

            <TopTagItem
                iconName="users"
                value={ordersFinished}
                label="Pedidos entregues"
                onClick={() =>
                    history.push({
                        pathname: `${basePath}/pedidos`,
                        search: `?start_date=${format(new Date(), 'YYYY-MM-DD')}&end_date=${format(
                            new Date(),
                            'YYYY-MM-DD'
                        )}&status=9`,
                    })
                }
            />

            <RoutesInProgressModal
                isActive={routesModal}
                data={routes}
                refresh={_onRoutesClick}
                closeClick={() => setRoutesModal(false)}
            />
        </div>
    )
}

export default TopTags
