import Spinner from 'react-spinner-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFiltersByTimeOptions } from '../../helpers'
import colors from '../../themes/colors'
import SelectInput from '../select-input'

import { SearchContainer, SearchInput, SearchIcon } from './operation-row.styled'
const OperationRow = ({
    hideSelect = undefined,
    style = undefined,
    loading = undefined,
    iconName = undefined,
    columnTitle = undefined,
    sorting = undefined,
    setSorting = undefined,
    status = undefined,
    children = undefined,
    onSearch = undefined,
}) => {
    return (
        <div className="operation-row-container" style={style}>
            <div className="row-title-container">
                <div className="title-flex">
                    <div className="icon">
                        {loading ? (
                            <Spinner
                                size={16}
                                color={colors.light_primary_color}
                                stroke={2}
                                radius={20}
                                visible={true}
                            />
                        ) : (
                            <FontAwesomeIcon icon={iconName} />
                        )}
                    </div>
                    <div className="text">{columnTitle}</div>
                </div>

                <SearchContainer>
                    <SearchInput
                        placeholder="Buscar por Nº do Pedido ou Nome do Cliente"
                        onChange={({ target: { value } }) => {
                            if (onSearch) {
                                onSearch(value)
                            }
                        }}
                    />
                    <SearchIcon />
                </SearchContainer>

                {!hideSelect && (
                    <div className="sort-button">
                        <SelectInput
                            bgColor="transparent"
                            borderColor="transparent"
                            data={getFiltersByTimeOptions(status)}
                            onChange={({ target: { value } }) => setSorting(value)}
                            value={sorting}
                        />
                    </div>
                )}
            </div>
            <div className="orders-listing" style={style}>
                {children}
            </div>
        </div>
    )
}

export default OperationRow
