import { ButtonText, Circle, RoundedContainer, SwitchButtonContainer } from './switch-button.styles'

interface Props {
    isActive?: boolean
    onClick?(): void
    activeLabel?: string
    inactiveLabel?: string
    isFlex?: boolean
    disabled?: boolean
}

const SwitchButton: React.FC<Props> = ({
    isActive,
    onClick,
    activeLabel,
    inactiveLabel,
    isFlex,
    disabled,
    ...props
}) => {
    return (
        <SwitchButtonContainer {...props} onClick={!disabled ? onClick : null} isFlex={isFlex} className="switch">
            <RoundedContainer isActive={isActive}>
                <Circle isActive={isActive} />
            </RoundedContainer>
            <ButtonText isFlex={isFlex}>
                {isActive ? (activeLabel ? activeLabel : '') : inactiveLabel ? inactiveLabel : ''}
            </ButtonText>
        </SwitchButtonContainer>
    )
}

export default SwitchButton
