import { useState, useEffect } from 'react'

import { AppliedFiltersContainer, FilterIcon, FilterItem, FilterText, RemoveIcon } from './applied-filters.styles'

interface Filter {
    id: number
    label: string
    value: number
}

interface Props {
    filters?: Array<Filter>
}

const AppliedFilters: React.FC<Props> = ({ filters }) => {
    const [list, setList] = useState([] as Array<Filter> | undefined)

    function handleRemove(element: number) {
        const newList = list?.filter(item => item.id !== element)
        setList(newList)
        localStorage.setItem('applied-filters', JSON.stringify(newList))
    }

    useEffect(() => {
        const storedList = localStorage.getItem('applied-filters')
        if (storedList) {
            setList(JSON.parse(storedList))
        } else {
            setList(filters)
        }
    }, [filters])

    return (
        <AppliedFiltersContainer>
            {list?.map((item, index) => (
                <FilterItem key={index}>
                    <FilterText>{item.label}</FilterText>
                    <FilterIcon onClick={() => handleRemove(item.id)}>
                        <RemoveIcon />
                    </FilterIcon>
                </FilterItem>
            ))}
        </AppliedFiltersContainer>
    )
}

export default AppliedFilters
