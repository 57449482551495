import { useState, useEffect } from 'react'
import { Layer, Feature } from 'react-mapbox-gl'

import { getCircleCoordinates } from '../../../helpers'
import colors from '../../../themes/colors'

const CircleFill = ({ radius, center }) => {
    const [circleCoords, setCircleCoords] = useState([])

    useEffect(() => {
        if (!center) return

        const coords = getCircleCoordinates(center, radius)

        setCircleCoords(coords)
    }, [center, radius])

    return circleCoords?.length ? (
        <>
            <Layer
                type="fill"
                paint={{
                    'fill-color': colors.secondary_color,
                    'fill-opacity': 0.2,
                }}
            >
                <Feature coordinates={circleCoords} />
            </Layer>

            <Layer
                type="line"
                paint={{
                    'line-color': colors.light_primary_color,
                    'line-width': 1,
                }}
            >
                <Feature coordinates={circleCoords[0]} />
            </Layer>
        </>
    ) : null
}

export default CircleFill
