import { Component } from 'react'

import './style.scss'
import motoqueiro from '../../assets/images/motoqueiro.png'
import colors from '../../themes/colors'
import Button from '../button/Button'

export default class RouteCreatedModal extends Component {
    render() {
        return (
            <div className="route-created-modal" style={{ display: this.props.visible ? 'flex' : 'none' }}>
                <div className="modal-content">
                    <div className="icone">
                        <img src={motoqueiro} alt="" />
                    </div>
                    <div className="title-route">Rota criada com sucesso!</div>
                    <Button
                        onClick={this.props.closeClick}
                        buttonText="Continuar"
                        bgColor={colors.primary_color}
                        textColor="#fff"
                    />
                </div>
            </div>
        )
    }
}
