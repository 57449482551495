import { Product } from 'types/marketplace'

import { ProductItem } from '..'

import {
    BoldText,
    CategoryHeader,
    CategoryProductsContainer,
    CategoryTitle,
    EditButton,
    ProductAmount,
    ProductCategoryContainer,
    ProductId,
    ProductInfo,
    ProductPrice,
    ProductSku,
    ProductStatus,
    TableRow,
} from './product-category.styles'

interface Props {
    categoryTitle: string
    editClick(productId: number): void
    products: Product[]
    updateProductStatus(productId: number, status: number): void
}

const ProductCategory: React.FC<Props> = ({ products, categoryTitle, updateProductStatus, editClick }) => {
    return (
        <ProductCategoryContainer>
            <CategoryHeader>
                <CategoryTitle>{categoryTitle}</CategoryTitle>
                <TableRow>
                    <ProductId>
                        <BoldText>ID</BoldText>
                    </ProductId>
                    <ProductStatus>
                        <BoldText>Status</BoldText>
                    </ProductStatus>
                    <ProductInfo>
                        <BoldText>Produto</BoldText>
                    </ProductInfo>
                    {/*
                        <ProductAmount>
                            <BoldText>Estoque</BoldText>
                        </ProductAmount>
                    */}
                    <ProductSku>
                        <BoldText>SKU</BoldText>
                    </ProductSku>
                    <ProductPrice>
                        <BoldText>Preço</BoldText>
                    </ProductPrice>
                    <EditButton></EditButton>
                </TableRow>
            </CategoryHeader>
            <CategoryProductsContainer>
                {products.map((product, index) => (
                    <ProductItem
                        key={index}
                        product={product}
                        updateProductStatus={updateProductStatus}
                        editClick={editClick}
                    />
                ))}
            </CategoryProductsContainer>
        </ProductCategoryContainer>
    )
}

export default ProductCategory
