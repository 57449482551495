import TextInputForm from 'components/text-input-form'

import DatepickerInput from '../datepicker-input'
import SelectInput, { SelectItems } from '../select-input'
import './style.scss'

interface Props {
    title: string
    selectInputFilters: Array<{
        placeholder: string
        data: SelectItems[]
        onChange(e: React.ChangeEvent<HTMLSelectElement>): void
        value: string | number
    }>
    textInputFilters?: React.InputHTMLAttributes<HTMLInputElement>[] | undefined

    selectStartDate?(date: Date): void
    startDate?: string

    selectEndDate?(date: Date): void
    endDate?: string

    onFilterClick(): void
    dateFilters?: {
        label: string
        onClick(): void
    }[]
}

const PerformancePageTopRow: React.FC<Props> = ({
    title,
    selectInputFilters,
    textInputFilters,
    selectStartDate,
    startDate,
    selectEndDate,
    endDate,
    onFilterClick,
    dateFilters,
}) => {
    return (
        <div className="performance-page-top-row-container">
            <div className="row-item">
                <div className="left-column">
                    <div className="top-row-title">{title}</div>
                </div>
            </div>
            <div className="inputs-row" style={{ marginLeft: !selectInputFilters.length ? '-10px' : '' }}>
                {selectInputFilters?.map(({ placeholder, data, onChange, value }, index) => (
                    <div className="filter-select" key={index}>
                        <SelectInput
                            placeholder={placeholder}
                            bgColor="transparent"
                            data={data}
                            onChange={onChange}
                            value={value}
                        />
                    </div>
                ))}

                {textInputFilters?.map(({ placeholder, type, disabled, onChange, value, ...props }, index) => (
                    <TextInputForm
                        key={index}
                        {...props}
                        placeholder={placeholder}
                        disabled={disabled}
                        type={type}
                        onChange={onChange}
                        value={value}
                    />
                ))}

                {selectStartDate && (
                    <DatepickerInput placeholder="Data inicial" onChange={selectStartDate} value={startDate} />
                )}

                {selectEndDate && <DatepickerInput placeholder="Data final" onChange={selectEndDate} value={endDate} />}

                {onFilterClick && (
                    <div className="filter-button" onClick={onFilterClick}>
                        Filtrar
                    </div>
                )}

                <div className="date-filters">
                    {dateFilters?.map(filter => (
                        <div key={filter.label} className="filter-button outline" onClick={filter.onClick}>
                            {filter.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PerformancePageTopRow
