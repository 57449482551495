import { memo, useCallback, useMemo, useRef, useState } from 'react'

import { AxiosError } from 'axios'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import { DefaultButton } from 'components/default-button/default-button'
import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalLoading from 'components/modal-loading'
import RowTitle from 'components/row-title'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, Tag } from 'types'

import { ModalTags, ModalTagsRef } from './components/modal-tags/modal-tags'
import { ITagIcons, TagIcon } from './components/tag-icon/tag-icon'
import {
    ButtonRow,
    Container,
    Content,
    ContentBody,
    ContentFooter,
    ContentTop,
    Description,
    PreviewContent,
    TagIconCircle,
    TagIconTooltip,
    TagIconTooltipText,
    TextContent,
    Title,
} from './tags-listing.styled'

const TagsListing: React.FC = memo(() => {
    const { setErrorModal, setConfirmationModal } = useUI()

    const modalTagsRef = useRef<ModalTagsRef>()

    const [isLoading, setIsLoading] = useState<boolean>()

    const onRequestError = useCallback((error: AxiosError) => {
        setErrorModal({
            title: 'Erro',
            subtitle: showErrors(error),
        })
    }, [])

    const tags = useAxiosRequest<PagedList<Tag>>({
        api,
        url: '/painel/tags',
        initialConfig: { params: { per_page: 50 } },
        transformData: (state, { data }) => ({
            ...data,
            items: Number(data?.current_page) > 1 && state?.items ? [...state.items, ...data.items] : data.items,
        }),
        onError: onRequestError,
    })

    const hasPagination = useMemo(() => {
        return Number(tags.data?.totals) > 0 && Number(tags.data?.items?.length) < Number(tags.data?.totals)
    }, [tags])

    const _refreshPagination = useCallback(() => {
        tags.request({
            params: {
                current_page: Number(tags.data?.current_page) + 1,
            },
        })
    }, [tags])

    const _openModal = useCallback(() => {
        modalTagsRef.current?.show()
    }, [])

    const _onClose = useCallback((refresh: boolean) => {
        if (refresh) {
            tags.request()
        }
    }, [])

    const _handleEdit = useCallback(
        (tag: Tag) => () => {
            _openModal()
            modalTagsRef.current?.setTag(tag)
        },
        []
    )

    const _handleDelete = useCallback((id: number) => {
        const deleteTag = async () => {
            modalTagsRef.current?.close()
            setIsLoading(true)

            try {
                await api.delete(`/painel/tag/${id}`)

                tags.request()
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir a tag!',
                })
            }
            setIsLoading(false)
        }

        return (): void => {
            setConfirmationModal({
                title: 'Excluir Tag',
                subtitle: 'Tem certeza de que deseja excluir este Tag?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: deleteTag,
            })
        }
    }, [])

    return (
        <Container>
            <ModalLoading visible={tags.loading || isLoading} />
            <ModalTags ref={modalTagsRef} onRemove={_handleDelete} onClose={_onClose} />

            <Content>
                <ContentTop>
                    <RowTitle
                        title="Tags"
                        buttonRow={[
                            {
                                label: 'Criar Tag',
                                onClick: _openModal,
                            },
                        ]}
                    />
                </ContentTop>

                <ContentBody hasPagination={hasPagination}>
                    <FlexTable
                        columns={[
                            { name: 'ID', width: '5%' },
                            { name: 'Nome', width: '20%' },
                            { name: 'Preview', width: '25%' },
                            { name: 'Status', width: '10%' },
                            { name: '', width: '20%' },
                        ]}
                        list={tags.data?.items?.map(tag => {
                            const { id, name, description, status } = tag

                            return {
                                id: id.toString(),
                                name: (
                                    <TextContent>
                                        <Title>{name}</Title>
                                        <Description>{description}</Description>
                                    </TextContent>
                                ),
                                preview: (
                                    <PreviewContent>
                                        {!!tag.icon && (
                                            <TagIconCircle bgColor={tag.color}>
                                                <TagIcon icon={tag.icon as ITagIcons} />
                                            </TagIconCircle>
                                        )}
                                        <TagIconTooltip color={tag.color}>
                                            {!!tag.icon && <TagIcon icon={tag.icon as ITagIcons} />}
                                            <TagIconTooltipText>{name}</TagIconTooltipText>
                                        </TagIconTooltip>
                                    </PreviewContent>
                                ),
                                status: status ? 'Ativa' : 'Inativa',
                                buttonRow: (
                                    <ButtonRow>
                                        <DefaultButton outline onClick={_handleEdit(tag)}>
                                            Editar
                                        </DefaultButton>
                                        <DefaultButton onClick={_handleDelete(id)}>Excluir</DefaultButton>
                                    </ButtonRow>
                                ),
                            }
                        })}
                    />

                    {!tags.data?.items.length && !tags.loading && (
                        <EmptyMessage>Nenhum Tag criada para esta loja</EmptyMessage>
                    )}
                </ContentBody>
                <ContentFooter isVisible={hasPagination}>
                    <ButtonLoadMore loading={tags.loading} visible={hasPagination} onClick={_refreshPagination} />
                </ContentFooter>
            </Content>
        </Container>
    )
})

export { TagsListing }
