import { faDollarSign, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const PerformancePageContainer = styled.div`
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
`

export const PerformanceContent = styled.div`
    background-color: #e8e8e8;
    flex: 1;
    padding: 20px;
`

export const CardRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        justify-content: space-between;
    }
`

export const RowItem = styled.div`
    height: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 49%;
    }
`

export const GraphicMock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    background-color: red;
`

export const AmountRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

export const AmountItem = styled.div`
    margin-bottom: 20px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 49%;
    }
`

export const IncomeTotals = styled.div`
    margin-bottom: 20px;
`

export const IncomeNumber = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 32px;
    font-weight: 600;
`

export const IncomeLabel = styled.div`
    font-size: 14px;
    color: #717171;
`

export const ResumeCard = styled.div`
    border-radius: 12px;
    background-color: #f7f7f7;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
`
export const ResumeRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
    }
`

export const BagIcon = styled(FontAwesomeIcon).attrs({
    icon: faShoppingBag,
})`
    color: #ababab;
    font-size: 40px;
    margin-right: 20px;
`

export const MoneySign = styled(FontAwesomeIcon).attrs({
    icon: faDollarSign,
})`
    color: #ababab;
    font-size: 40px;
    margin-right: 20px;
`

export const ResumeText = styled.div``

export const ResumeNumber = styled.div`
    font-size: 22px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`

export const ResumeTitle = styled.div`
    font-size: 16px;
    color: #717171;
`
