import React, { memo, useCallback, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { IncomeListing, LoadingMessage, PerformanceCard } from 'components/_common'

import { formatCurrency } from 'helpers'
import { getDeliveredChart } from 'helpers/performance-page'
import api from 'services/api'
import { ChartObject, Invoice, ReportIncoming, ReportInvoicing } from 'types'

import {
    IncomeTotals,
    RowItem,
    MoneySign,
    IncomeNumber,
    IncomeLabel,
    ResumeRow,
    ResumeCard,
    BagIcon,
    ResumeText,
    ResumeNumber,
    ResumeTitle,
} from './invoicing-card.styled'
interface Props {
    params: any
}

const InvoicingCard: React.FC<Props> = memo(({ params }) => {
    const [data, setData] = useState<ReportIncoming>()
    const [chart, setChart] = useState<ChartObject>()
    const [invoices, setInvoices] = useState<Invoice[]>([])
    const [invoicesTotal, setInvoicesTotal] = useState<number>(0)

    const [loading, setLoading] = useState(false)

    const _loadDataIncoming = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api.get<ReportIncoming>('/painel/report/incoming', { params })
            setData(response.data)
            const { delivered } = response.data
            setChart(getDeliveredChart(delivered.data))
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])
    const _loadDataInvoicing = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api.get<ReportInvoicing>('/painel/report/invoicing', { params })
            setInvoices(response.data.items)
            setInvoicesTotal(response.data.header.total)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (params) {
            _loadDataIncoming(params)
            _loadDataInvoicing(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <>
            <RowItem>
                <PerformanceCard title="Faturamento" subtitle="Baseado no total de venda de todos os canais">
                    {loading ? (
                        <LoadingMessage />
                    ) : (
                        <>
                            {!!data && (
                                <>
                                    <IncomeTotals>
                                        <IncomeNumber>{formatCurrency(invoicesTotal)}</IncomeNumber>
                                        <IncomeLabel>Total do Período</IncomeLabel>
                                    </IncomeTotals>
                                    <IncomeListing incomingList={invoices} />
                                </>
                            )}
                        </>
                    )}
                </PerformanceCard>
            </RowItem>
            <RowItem>
                <PerformanceCard title="Resumo de Entregas" subtitle="Baseado no total de entregas feitas no período">
                    {loading ? (
                        <LoadingMessage />
                    ) : (
                        <>
                            {!!data && (
                                <ResumeRow>
                                    <ResumeCard>
                                        <BagIcon />
                                        <ResumeText>
                                            <ResumeNumber>{data.delivered.total}</ResumeNumber>
                                            <ResumeTitle>Entregas Realizadas</ResumeTitle>
                                        </ResumeText>
                                    </ResumeCard>
                                    <ResumeCard>
                                        <MoneySign />
                                        <ResumeText>
                                            <ResumeNumber>
                                                {formatCurrency(data.delivered.liquid_incoming)}
                                            </ResumeNumber>
                                            <ResumeTitle>A ser faturado ao Logaroo</ResumeTitle>
                                        </ResumeText>
                                    </ResumeCard>
                                </ResumeRow>
                            )}

                            {!!chart && (
                                <Line
                                    height={120}
                                    data={chart.data}
                                    options={{
                                        plugins: {
                                            tooltip: {
                                                callbacks: chart.callbacks,
                                            },
                                        },
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                            },
                                        },
                                    }}
                                />
                            )}
                        </>
                    )}
                </PerformanceCard>
            </RowItem>
        </>
    )
})
export { InvoicingCard }
