export const collects = {
    totals: 20,
    stores: [
        {
            storeName: 'Mc Donalds',
            orders: [
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
            ],
        },
        {
            storeName: 'Burger King',
            orders: [
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                    channel: 1,
                    orderID: 12345,
                    customerName: 'Carmen Li',
                    customerNeighborhood: 'Varjota',
                    customerStreet: 'Floriano Peixoto',
                    customerNumber: 762,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 3,
                    productionTime: 20,
                    elapsedTime: 30,
                },
                {
                    storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                    channel: 2,
                    orderID: 12345,
                    customerName: 'Yan Gondim',
                    customerNeighborhood: 'Edson Queiroz',
                    customerStreet: 'Avenida Washington Soares',
                    customerNumber: 800,
                    orderValue: 72.3,
                    payment: 'Dinheiro',
                    distance: 7,
                    productionTime: 10,
                    elapsedTime: 25,
                },
            ],
        },
    ],
}
