import { faClock, faShoppingBag, faBiking } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const OrderDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: 100vh;
    }
`

export const ContentContainer = styled.div`
    flex: 1;
    min-height: 0;
    overflow: auto;
    padding: 20px;
    padding-bottom: 50px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        justify-content: space-between;
        padding: 40px;
    }
`

export const LeftColumn = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 40%;
    }
`

export const RightColumn = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 58%;
    }
`

export const InfoCard = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 30px;
    position: relative;
`

export const InfoRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const BigSide = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 60%;
    }
`

export const EditButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    padding: 8px 20px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px;
    user-select: none;
    cursor: pointer;
    transition: 400ms;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const CardTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`

export const BagIcon = styled(FontAwesomeIcon).attrs({
    icon: faShoppingBag,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
`

export const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
`

export const TitleText = styled.div`
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: bold;
`

export const OrderTable = styled.div``

export const ProductItem = styled.div`
    display: flex;
    margin-bottom: 20px;
`

export const TableHeader = styled.div`
    margin-bottom: 10px;
    display: flex;
`

export const ItensHeader = styled.div`
    width: 55%;
    margin-right: 5%;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const AmountHeader = styled.div`
    width: 20%;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const ValueHeader = styled.div`
    width: 20%;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const ColumnsContainer = styled.div`
    display: flex;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: column;
    }
`

export const TimesColumn = styled.div`
    width: 45%;
    margin-left: 50%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: space-between;
    }
`

export const TimeBox = styled.div`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.gray};
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 20%;
    }
`

export const BoxTitle = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
`

export const BoxValue = styled.div`
    font-size: 18px;
    font-weight: bold;
`

export const InsideColumnsContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        justify-content: space-between;
    }
`

export const InsideColumn = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 48%;
    }
`

export const MapContainer = styled.div`
    width: 100%;
    height: 100px;
    background-color: ${({ theme }) => theme.colors.success};
    margin-top: 30px;
    margin-bottom: 10px;
`

export const BikerIcon = styled(FontAwesomeIcon).attrs({
    icon: faBiking,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
`

export const TaxTitle = styled.div`
    flex: 1;
    margin-right: 20px;
`

export const TaxValue = styled.div``

export const TaxesContainer = styled.div`
    margin-top: 40px;
`

export const TaxItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    &:last-child {
        font-weight: bold;
        border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
        padding-top: 10px;
    }
`
