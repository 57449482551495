import { FC, memo } from 'react'

import { TableOrderItem } from 'components/_store-general-vision'
import { Text } from 'components/text/text'

import { TOrderDetail } from 'types/postpaid'

import { OrderDetailItem } from '../attendance-detail-modal.styled'

type Props = {
    order: TOrderDetail
}

const OrderDetails: FC<Props> = memo(({ order }) => {
    return (
        <OrderDetailItem>
            {order?.items?.map((item, i) => (
                <>
                    {order.canceled && <Text text={'Cancelado'} variant="danger" dimensions="xSmall" />}
                    <TableOrderItem key={i} orderItem={item as any} />
                </>
            ))}
        </OrderDetailItem>
    )
})

export { OrderDetails }
