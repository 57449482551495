const obg = 'Campo Obrigatório.'
const size = 'Campo deve ter no máximo 255 caracteres.'
// const emailRegex = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/

export const validateFields = (store, collectFee, setErrors) => {
    try {
        const {
            //loja
            name,
            slug,
            description,
            trade_name,
            company_name,
            cnpj,
            store_type_id,
            store_category_id,
            //proprietário
            // owner_name,
            // owner_cpf,
            // owner_email,
            // owner_cellphone,
            //configuração
            operating_hour,
            preparation_time,
            time_rating,
            price_rating,
            neighborhood,
            phone,

            //situação
            status,
            //satélite
            is_satelite,
            zipcode,
            street,
            number,
            complement,
            landmark,
        } = store

        let err = {}

        //loja
        if (!name) err.name = obg
        else if (name.length > 255) err.name = size
        if (!slug) err.slug = obg
        if (!trade_name) err.trade_name = obg
        else if (trade_name.length > 255) err.trade_name = size
        if (!company_name) err.company_name = obg
        else if (company_name.length > 255) err.company_name = size
        if (!description) err.description = obg
        if (!store_type_id) err.store_type_id = obg
        if (!store_category_id) err.store_category_id = obg
        if (!cnpj) err.cnpj = obg
        else if (!validateCNPJ(cnpj)) err.cnpj = 'CNPJ Inválido'

        //proprietário
        // if (!owner_name) err.owner_name = obg
        // else if (owner_name.length > 255) err.owner_name = size
        // if (!owner_email) err.owner_email = obg
        // else if (owner_email && !emailRegex.test(owner_email)) err.owner_email = 'E-mail inválido.'
        // if (!owner_cellphone) err.owner_cellphone = obg
        // else if (owner_cellphone && removeMask(owner_cellphone).length < 11) err.owner_cellphone = 'Número inválido.'
        // if (!owner_cpf) err.owner_cpf = obg
        // else if (!validateCPF(owner_cpf)) err.owner_cpf = 'CPF Inválido'

        //configuração
        if (!operating_hour) err.operating_hour = obg
        else if (operating_hour.length > 255) err.operating_hour = size
        if (!preparation_time) err.preparation_time = obg
        else if (removeMask(preparation_time).length < 6)
            err.preparation_time = 'Horário deve estar no formato HH:mm:ss'
        if (!time_rating) err.time_rating = obg
        if (!price_rating) err.price_rating = obg

        if (!phone) err.phone = obg
        else if (phone && removeMask(phone).length < 10) err.phone = 'Número inválido.'

        //situação
        if (!status) err.status = obg

        //satélite
        if (is_satelite) {
            if (!neighborhood) err.neighborhood = obg

            if (collectFee.agent < 0.0 || collectFee.agent > 99.99) {
                err.collectFee_agent = 'Valor deve ser entre 0,00 e 99,99.'
            }

            if (collectFee.store < 0.0 || collectFee.store > 99.99) {
                err.collectFee_store = 'Valor deve ser entre 0,00 e 99,99.'
            }

            if (!street) {
                err.street = obg
            } else if (street.length > 255) {
                err.street = size
            }

            if (!number) {
                err.number = obg
            } else if (number.length > 255) {
                err.number = size
            }

            if (!zipcode) {
                err.zipcode = obg
            } else if (removeMask(zipcode).length < 8) {
                err.zipcode = 'CEP inválido'
            }

            if (complement && complement.length > 255) {
                err.complement = size
            }

            if (landmark && landmark.length > 255) {
                err.landmark = size
            }
        }

        console.log('ERRORS', err)

        setErrors(err)

        return JSON.stringify(err) === '{}'
    } catch (err) {
        console.log('VALIDATE ERRORS ERROR:', err)
    }
}

export const removeMask = value => {
    return value ? value.replace(/\D+/g, '') : null
}

export const validateCPF = cpf => {
    if ((cpf = cpf.replace(/[^\d]/g, '')).length !== 11) return false

    if (
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    )
        return false

    let r
    let s = 0

    for (let i = 1; i <= 9; i++) s = s + parseInt(cpf[i - 1], 10) * (11 - i)

    r = (s * 10) % 11

    if (r === 10 || r === 11) r = 0

    if (r !== parseInt(cpf[9], 10)) return false

    s = 0

    for (let i = 1; i <= 10; i++) s = s + parseInt(cpf[i - 1], 10) * (12 - i)

    r = (s * 10) % 11

    if (r === 10 || r === 11) r = 0

    if (r !== parseInt(cpf[10], 10)) return false

    return true
}

export const validateCNPJ = cnpj => {
    let string = cnpj.replace(/[^\d]+/g, '')

    // Valida a quantidade de caracteres
    if (string.length !== 14) return false

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(string)) return false

    // Cáculo de validação
    let t = string.length - 2,
        d = string.substring(t),
        d1 = parseInt(d.charAt(0)),
        d2 = parseInt(d.charAt(1)),
        calc = x => {
            let n = string.substring(0, x),
                y = x - 7,
                s = 0,
                r = 0

            for (let i = x; i >= 1; i--) {
                s += n.charAt(x - i) * y--
                if (y < 2) y = 9
            }

            r = 11 - (s % 11)
            return r > 9 ? 0 : r
        }

    return calc(t) === d1 && calc(t + 1) === d2
}

export const statusArray = [
    {
        name: 'Ativo',
        value: 1,
    },
    {
        name: 'Inativo',
        value: 0,
    },
]

export const preparationArray = [
    {
        name: '*',
        value: 1,
    },
    {
        name: '**',
        value: 2,
    },
    {
        name: '***',
        value: 3,
    },
]

export const priceArray = [
    {
        name: '$',
        value: 1,
    },
    {
        name: '$$',
        value: 2,
    },
    {
        name: '$$$',
        value: 3,
    },
]

export const openArray = [
    {
        name: 'Sim',
        value: true,
    },
    {
        name: 'Não',
        value: false,
    },
]
