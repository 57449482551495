/* eslint-disable react/display-name */
import { memo } from 'react'

import { PaymentArea } from 'types'

import { PopupContainer, PopupTitle } from './area-popup.styles'

interface Props {
    paymentArea: PaymentArea
}

const AreaPopup: React.FC<Props> = memo(({ paymentArea }) => {
    return (
        <PopupContainer areaColor={paymentArea.color}>
            <PopupTitle>{paymentArea.label}</PopupTitle>
        </PopupContainer>
    )
})

export { AreaPopup }
