import { useState, useRef, useMemo, useCallback, memo } from 'react'

import { AxiosError } from 'axios'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import { DefaultButton } from 'components/default-button/default-button'
import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalLoading from 'components/modal-loading'
import RowTitle from 'components/row-title'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, StoreCategory, StoreType } from 'types'

import { ModalDeleteStoreType, ModalStoreTypes } from './components'
import { ModalDeleteStoreTypeRef } from './components/modal-delete-store-type/modal-delete-store-type'
import { ModalStoreTypesRef } from './components/modal-store-types/modal-store-types'
import {
    BannerImage,
    ButtonRow,
    Container,
    Content,
    ContentBody,
    ContentFooter,
    ContentTop,
    Description,
    TableColumnContent,
    Title,
} from './store-types-listing.styled'

const StoreTypesListing: React.FC = memo(() => {
    const { setErrorModal, setConfirmationModal } = useUI()

    const modalStoreTypesRef = useRef<ModalStoreTypesRef>()
    const modalDeleteStoreTypeRef = useRef<ModalDeleteStoreTypeRef>()

    const [isLoading, setIsLoading] = useState<boolean>()

    const onRequestError = useCallback((error: AxiosError) => {
        setErrorModal({
            title: 'Erro',
            subtitle: showErrors(error),
        })
    }, [])

    const storeTypes = useAxiosRequest<PagedList<StoreType>>({
        api,
        url: '/painel/store-types',
        initialConfig: { params: { status: [0, 1], per_page: 50 } },
        transformData: (state, { data }) => ({
            ...data,
            items: Number(data?.current_page) > 1 && state?.items ? [...state.items, ...data.items] : data.items,
        }),
        onError: onRequestError,
    })

    const hasPagination = useMemo(() => {
        return (
            Number(storeTypes.data?.totals) > 0 &&
            Number(storeTypes.data?.items?.length) < Number(storeTypes.data?.totals)
        )
    }, [storeTypes])

    const _getPagination = useCallback(() => {
        storeTypes.request({
            params: {
                current_page: Number(storeTypes.data?.current_page) + 1,
            },
        })
    }, [storeTypes])

    const _openModal = useCallback(() => {
        modalStoreTypesRef.current?.show()
    }, [])

    const _onClose = useCallback((refresh: boolean) => {
        if (refresh) {
            storeTypes.request()
        }
    }, [])

    const _handleEdit = useCallback(
        (storeType: StoreType) => () => {
            _openModal()
            modalStoreTypesRef.current?.setStoreType(storeType)
        },
        []
    )

    const _getCategories = useCallback(async (id: number) => {
        setIsLoading(true)
        const {
            data: { items },
        } = await api.get<PagedList<StoreCategory>>('painel/store-categories', {
            params: { store_type_id: [id], status: [0, 1] },
        })
        setIsLoading(false)

        return items
    }, [])

    const _handleDelete = useCallback((id: number) => {
        return async () => {
            const categories = await _getCategories(id)

            if (categories.length) {
                modalStoreTypesRef.current?.close()
                modalDeleteStoreTypeRef.current?.show(id, categories)
                return
            }

            const storeTypeDelete = async () => {
                modalStoreTypesRef.current?.close()

                setIsLoading(true)

                try {
                    await api.delete(`/painel/store-types/${id}`)

                    storeTypes.request()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro!',
                        subtitle: 'Não foi possível excluir vertical de loja!',
                    })
                }
                setIsLoading(false)
            }

            setConfirmationModal({
                title: 'Excluir vertical de loja',
                subtitle: 'Tem certeza de que deseja excluir esta vertical de loja?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: storeTypeDelete,
            })
        }
    }, [])

    return (
        <Container>
            <ModalLoading visible={storeTypes.loading || isLoading} />
            <ModalStoreTypes ref={modalStoreTypesRef} onRemove={_handleDelete} onClose={_onClose} />
            <ModalDeleteStoreType ref={modalDeleteStoreTypeRef} onClose={_onClose} />

            <Content>
                <ContentTop>
                    <RowTitle
                        title="Verticais de loja"
                        buttonRow={[
                            {
                                label: 'Criar vertical de loja',
                                onClick: _openModal,
                            },
                        ]}
                    />
                </ContentTop>

                <ContentBody hasPagination={hasPagination}>
                    <FlexTable
                        columns={[
                            { name: 'ID', width: '3%' },
                            { name: 'Nome', width: '27%' },
                            { name: 'Ícone', width: '15%' },
                            { name: 'Banner', width: '20%' },
                            { name: 'Status', width: '10%' },
                            { name: '', width: '25%' },
                        ]}
                        list={storeTypes.data?.items?.map((storeType: StoreType) => {
                            const { id, icon, banner, name, description, status } = storeType

                            return {
                                id: id.toString(),
                                name: (
                                    <TableColumnContent>
                                        <Title>{name}</Title>
                                        <Description>{description}</Description>
                                    </TableColumnContent>
                                ),
                                icon: icon && <BannerImage alt={`icon-${id}`} src={`${icon.path}`} />,
                                banner: banner && <BannerImage alt={`banner-${id}`} src={`${banner.path}`} />,
                                status: status ? 'Ativo' : 'Inativo',
                                buttonRow: (
                                    <ButtonRow>
                                        <DefaultButton outline onClick={_handleEdit(storeType)}>
                                            Editar
                                        </DefaultButton>
                                        <DefaultButton onClick={_handleDelete(id)}>Excluir</DefaultButton>
                                    </ButtonRow>
                                ),
                            }
                        })}
                    />

                    {!storeTypes.data?.items.length && !storeTypes.loading && (
                        <EmptyMessage>Nenhuma Vertical de loja criada</EmptyMessage>
                    )}
                </ContentBody>

                <ContentFooter isVisible={hasPagination}>
                    <ButtonLoadMore loading={storeTypes.loading} visible={hasPagination} onClick={_getPagination} />
                </ContentFooter>
            </Content>
        </Container>
    )
})

export default StoreTypesListing
