import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'
import { Tag } from 'types'

import { TagIcon, ITagIcons } from '../tag-icon/tag-icon'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TagIconPreviewCircle,
    TagIconPreviewContent,
    TagIconPreviewTooltip,
    TagIconPreviewTooltipText,
    TextButton,
} from './modal-tags.styled'

export interface ModalTagsRef {
    show(): void
    close(): void
    setTag(tag: Tag): void
}

type Props = {
    onRemove(id: number): () => void
    onClose(refresh: boolean): void
}

type IForm = {
    id?: number
    name: string
    description: string
    status: 0 | 1
    icon?: ITagIcons | null | 'undefined'
    iconLabel?: string
    color?: string
}

const statusOptions = [
    {
        label: 'Ativo',
        value: 1,
    },
    {
        label: 'Inativo',
        value: 0,
    },
]

const tagIcons: { label: string; value: ITagIcons }[] = [
    {
        label: 'Amendoas',
        value: 'almonds',
    },
    {
        label: 'Castanhas',
        value: 'chestnuts',
    },
    {
        label: 'Crustáceos',
        value: 'crustaceans',
    },
    {
        label: 'Corantes',
        value: 'dyes',
    },
    {
        label: 'Ovos',
        value: 'eggs',
    },
    {
        label: 'Peixe',
        value: 'fish',
    },
    {
        label: 'Glúten',
        value: 'gluten',
    },
    {
        label: 'Lactose',
        value: 'lactose',
    },
    {
        label: 'Leite',
        value: 'milk',
    },
    {
        label: 'Soja',
        value: 'soy',
    },
]

const ModalTags = memo(
    forwardRef<ModalTagsRef, Props>(({ onClose, onRemove }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [tag, setTag] = useState<Tag>()

        const {
            isSubmitting,
            errors,
            getFieldProps,
            handleSubmit,
            resetForm,
            setFieldValue,
            setValues,
            touched,
            values,
        } = useFormik<IForm>({
            initialValues: {
                name: '',
                description: '',
                status: 1,
                icon: 'undefined',
                color: '#000000',
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome da tag é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)
                    if (values.icon === 'undefined') {
                        values.icon = null
                    }

                    if (values.id) {
                        await api.put(`/painel/tag/${values.id}`, values)
                    } else {
                        await api.post(`/painel/tag`, values)
                    }

                    setSuccessModal({
                        title: 'Sucesso!',
                        subtitle: `Tag ${isEdit ? 'editada' : 'criada'} com sucesso!`,
                        singleButtonClick: _onClose(true),
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Error',
                        subtitle: showErrors(error),
                    })
                }

                setSubmitting(false)
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setTag(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setTag: (tag: Tag) => {
                    setTag(tag)
                    setValues({
                        id: tag.id,
                        name: tag.name,
                        description: tag.description,
                        status: tag.status,
                        icon: (tag?.icon || 'undefined') as ITagIcons,
                        color: tag?.color || '#000000',
                    })
                },
            }),
            []
        )

        const isEdit = useMemo(() => {
            return !!tag
        }, [tag])

        const isShowIcon = useMemo(() => !!values.icon && values.icon !== 'undefined', [values.icon])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Gerenciar' : 'Criar'} Tag`
        }, [isEdit])

        const _onClose = useCallback(
            (refresh?: boolean) => () => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            []
        )

        const _handleChange = useCallback(
            (fieldName: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(fieldName, value)
                },
            []
        )

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={{
                                        ...getFieldProps('name'),
                                        onChange: _handleChange('name'),
                                    }}
                                    errorMessage={touched.name && errors.name}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="textarea"
                                    labelText="Descrição"
                                    big
                                    inputProps={{
                                        ...getFieldProps('description'),
                                        onChange: _handleChange('description'),
                                    }}
                                    errorMessage={touched.description && errors.description}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={statusOptions}
                                    inputProps={{
                                        value: values.status,
                                        onChange: _handleChange('status'),
                                    }}
                                    errorMessage={touched.status && errors.status}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Ícone"
                                    options={tagIcons}
                                    inputProps={{
                                        ...getFieldProps('icon'),
                                        onChange: _handleChange('icon'),
                                    }}
                                    errorMessage={touched.icon && errors.icon}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Cor"
                                    inputProps={{
                                        ...getFieldProps('color'),
                                        onChange: _handleChange('color'),
                                    }}
                                    errorMessage={touched.color && errors.color}
                                />
                            </InputContainer>

                            <InputContainer>
                                <>
                                    <FormLabel>Preview</FormLabel>
                                    <TagIconPreviewContent>
                                        {isShowIcon && (
                                            <TagIconPreviewCircle bgColor={values.color}>
                                                <TagIcon icon={values.icon as ITagIcons} />
                                            </TagIconPreviewCircle>
                                        )}

                                        {!!values.name && (
                                            <TagIconPreviewTooltip color={values.color}>
                                                {isShowIcon && <TagIcon icon={values.icon as ITagIcons} />}
                                                <TagIconPreviewTooltipText>{values.name}</TagIconPreviewTooltipText>
                                            </TagIconPreviewTooltip>
                                        )}
                                    </TagIconPreviewContent>
                                </>
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <div>
                            {isEdit && onRemove && (
                                <TextButton isRemove onClick={onRemove(tag.id)}>
                                    <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={_onClose()}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting} />
            </LateralModalBase>
        )
    })
)

export { ModalTags }
