import { LoadingMessageContainer, LoadingIcon, Message } from './loading-message.styles'

interface Props {
    message?: string
    containerStyle?: React.CSSProperties
}

const LoadingMessage: React.FC<Props> = ({ message, containerStyle }) => {
    return (
        <LoadingMessageContainer style={containerStyle}>
            <LoadingIcon />
            <Message>{message || 'Carregando informações...'}</Message>
        </LoadingMessageContainer>
    )
}

export default LoadingMessage
