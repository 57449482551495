import { FC, createContext, memo, useCallback, useEffect, useState } from 'react'

import { formatCurrency } from 'helpers'
import { printerServer } from 'services/api'
import { Order } from 'types'
import { IPrinter, IPrinterConfig, IPrinterOrder, IPrinterType } from 'types/printer'

import { useUI } from './ui'

type IPrinterContext = {
    printers: {
        [key in IPrinterType]: IPrinter | null
    }
    printerList: IPrinter[]
    hasConnectionPrinterApp: boolean
    setSelectedPrinter(type: IPrinterType, printer: IPrinter): Promise<void>
    checkPrinterAppConnection(): Promise<void>
    convertToPrintOrder?(order: Order): IPrinterOrder
    print(type: IPrinterType, order: IPrinterOrder, config?: IPrinterConfig): Promise<void>
    getPrinters(): void
}

const PrinterContext = createContext<IPrinterContext>({} as IPrinterContext)

const PrinterContextProvider: FC = memo(({ children }) => {
    const { setErrorModal } = useUI()

    const [printers, setPrinters] = useState<IPrinterContext['printers']>(null)
    const [hasConnectionPrinterApp, setHasConnectionPrinterApp] = useState<boolean>(false)
    const [printerList, setPrinterList] = useState<IPrinter[]>([])

    const _convertToPrintOrder = useCallback((order: Order & { destinies: any }): IPrinterOrder => {
        return {
            address: order.address,
            customer: order?.customer?.name,
            items: order.items,
            payment: order?.payment?.name,
            reference_id: order?.reference_id,
            sale_channel: order?.sales_channel?.name,
            store: order.store,
            type: order.type,
            observation: order?.observation,
            value: typeof order.total_price === 'string' ? order.total_price : formatCurrency(order.total_price),
            destiny:
                order?.destinies?.length > 0
                    ? {
                          floor: { name: order.destinies[0].name, zone: { name: order.destinies[0].maps[0].name } },
                      }
                    : null,
            created_at: order?.created_at,
        } as IPrinterOrder
    }, [])

    const _checkPrinterAppConnection = useCallback(async () => {
        try {
            await printerServer.get('/')
            setHasConnectionPrinterApp(true)
        } catch (error) {
            setHasConnectionPrinterApp(false)
        }
    }, [])

    const _getPrintersConfig = useCallback(async () => {
        try {
            const { data } = await printerServer.get<{ device: IPrinterContext['printers'] }>('/get-selected-device')
            setPrinters(data.device)
        } catch (error) {
            console.log({ error })
        }
    }, [])

    const _getPrinters = useCallback(async () => {
        try {
            const { data } = await printerServer.get<IPrinter[]>('/printers')
            setPrinterList(data)
        } catch (error) {
            console.log({ error })
        }
    }, [])

    const _setSelectedPrinter = useCallback(async (type: IPrinterType, printer: IPrinter) => {
        try {
            if (printer) {
                const printerName = type === 'dispatch' ? 'Impressora de Envio' : 'Impressora de Produção'
                const newPrinter = { ...printer, name: printerName }
                await printerServer.post('/set-select-printer', {
                    type,
                    device: newPrinter,
                })
                setPrinters(state => ({ ...state, [type]: newPrinter }))
            } else {
                setPrinters(state => ({ ...state, [type]: printer }))
            }
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível salvar as informações da impressora selecionada',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _print = useCallback(async (type: IPrinterType, order: IPrinterOrder, config?: IPrinterConfig) => {
        try {
            await printerServer.post('/print-dispatch-order', {
                type,
                order,
                config,
            })
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível realizar a impressão da comanda',
            })
        }
    }, [])

    useEffect(() => {
        if (hasConnectionPrinterApp) {
            _getPrintersConfig()
        }
    }, [hasConnectionPrinterApp, _getPrintersConfig])

    useEffect(() => {
        if (hasConnectionPrinterApp && !printerList.length) {
            _getPrinters()
        }
    }, [hasConnectionPrinterApp, _getPrinters])

    useEffect(() => {
        _checkPrinterAppConnection()
    }, [_checkPrinterAppConnection])

    return (
        <PrinterContext.Provider
            value={{
                printers,
                printerList,
                hasConnectionPrinterApp,
                setSelectedPrinter: _setSelectedPrinter,
                checkPrinterAppConnection: _checkPrinterAppConnection,
                print: _print,
                convertToPrintOrder: _convertToPrintOrder,
                getPrinters: _getPrinters,
            }}
        >
            {children}
        </PrinterContext.Provider>
    )
})

export { PrinterContext, PrinterContextProvider }
