import { faBiking, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ExchangeRouteBikerModalContainer = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transition: 400ms;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
`

export const ExchangeRouteBikerModalBackground = styled.div<{ isEffectActive: boolean }>`
    z-index: ${({ theme }) => theme.commonModalZIndex};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    transition: 400ms;
    opacity: ${({ isEffectActive }) => (isEffectActive ? '1' : '0')};
`

export const ExchangeRouteBikerModalBox = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 550px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

export const ExchangeRouteBikerHeader = styled.div`
    padding: 20px;
    padding-bottom: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 30px;
        padding: 20px 30px;
    }
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    width: 30px;
    position: absolute;
    top: 15px;
    right: 20px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

export const ExchangeRouteBikerModalContent = styled.div`
    flex: 1;
    overflow: auto;
    margin-bottom: 0;
    padding: 20px;
    &::-webkit-scrollbar {
        display: none;
    }
`

export const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 30px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const ResetSearch = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    transition: 400ms;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`

export const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

export const AvailableBikerCard = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 15px 30px;
    }
`

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

export const BikerIcon = styled(FontAwesomeIcon).attrs({
    icon: faBiking,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 42px;
    margin-bottom: 20px;
`

export const EmptyText = styled.div`
    font-weight: bold;
    color: #aaa;
`

export const TabsContainer = styled.div`
    display: flex;
    align-items: center;
`

export const TabItem = styled.div<{ isActive: boolean }>`
    margin-right: 20px;
    color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : '#aaa')};
    border-bottom: 2px solid transparent;
    border-color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
    text-transform: uppercase;
    transition: 400ms;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    &:hover {
        opacity: 0.6;
    }
`

export const FormContainer = styled.div`
    padding: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 20px 30px;
    }
`

export const SectionTitle = styled.div<{ spaced?: boolean }>`
    font-weight: bold;
    font-size: 18px;
    margin-top: ${({ spaced }) => (spaced ? '50px' : 0)};
    margin-bottom: ${({ spaced }) => (spaced ? '20px' : '10px')};
`

export const SectionSubtitle = styled.div`
    font-size: 14px;
    margin-bottom: 20px;
`

export const ButtonsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
`

export const CancelButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
`

export const SendButton = styled.div<{ isActive?: boolean }>`
    border-radius: 4px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#aaa')};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    transition: 400ms;
    margin-left: 20px;
    &:hover {
        opacity: 0.6;
    }
`

export const RadiosError = styled.div`
    background-color: rgba(255, 0, 0, 0.1);
    padding: 10px;
    border-left: 3px solid red;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 20px;
`

export const MessageSent = styled.div`
    background-color: rgba(0, 255, 0, 0.1);
    padding: 10px;
    border-left: 3px solid green;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 20px;
    margin-top: 30px;
`
