import { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

export default class ResultItem extends Component {
    render() {
        return (
            <div className="search-result-item" onClick={this.props.onClick}>
                <div className="left-side">
                    <div className="order-info">
                        Pedido: {this.props.orderNumber} • Valor: R$ {this.props.orderValue}
                    </div>
                    <div className="restaurant-name">{this.props.restaurantName}</div>
                </div>
                <div className="right-side">
                    <FontAwesomeIcon icon="chevron-right" color="#ababab" />
                </div>
            </div>
        )
    }
}
