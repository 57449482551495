import { useCallback, useEffect, useState } from 'react'
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom'

import ModalLoading from 'components/modal-loading'
import TopRowDefault from 'components/top-row-default/top-row-default'

import siclosPagApi from 'services/siclospag'

import EstablishmentsCreate from './pages/establishments/establishment-create'
import EstablishmentsCreateCredentialed from './pages/establishments/establishment-create-credentialed'
import EstablishmentView from './pages/establishments/establishment-view'
import EstablishmentsListing from './pages/establishments/establishments-list'
import FinanceExtractPage from './pages/finance-extract-page/finance-extract-page'
import FinanceExtractTransactions from './pages/finance-extract-transactions/finance-extract-transactions'
import PartnerValidations from './pages/partner-validations/partner-validations'
import PosViewControl from './pages/pos-view-control/pos-view-control'
import RecipientCreate from './pages/recipients/recipient-create'
import RecipientCreateCredentialed from './pages/recipients/recipient-create-credentialed'
import RecipientView from './pages/recipients/recipient-view'
import RecipientsListing from './pages/recipients/recipients-listing'
import BoundServicesListing from './pages/services-listing/bound-services-listing'
import ServicesListing from './pages/services-listing/services-listing'
import TransactionAdjustmentConfiguration from './pages/transaction-adjustment-configuration/transaction-adjustment-configuration'
import TransactionListPage from './pages/transaction-list-page/transaction-list-page'

import './style.scss'

const StoneManagement: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const stoneToken = localStorage.getItem('@extranet.stone:token')

    const links = [
        { label: 'Estabelecimentos', destiny: '/painel-malls/gestao-stone' },
        { label: 'Recebedores', destiny: '/painel-malls/gestao-stone/recebedores' },
        {
            label: 'Áreas/Itens/Serviços',
            destiny: '/painel-malls/gestao-stone/servicos',
        },
        { label: 'Configurações da maquininha', destiny: '/painel-malls/gestao-stone/configuracoes-maquininha' },
        { label: 'Validações', destiny: '/painel-malls/gestao-stone/validacoes' },
        { label: 'Ajustes de transação', destiny: '/painel-malls/gestao-stone/ajustes-transacao' },
        { label: 'Transações', destiny: '/painel-malls/gestao-stone/transacoes' },
        { label: 'Extrato', destiny: '/painel-malls/gestao-stone/extrato' },
    ]

    const [loading, setLoading] = useState(false)

    const getStoneToken = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await siclosPagApi.get('/token')

            localStorage.setItem('@extranet.stone:token', data.token)
        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getStoneToken()
    }, [getStoneToken])

    return (
        <div className="stone-management-page-container">
            <TopRowDefault title="Gestão Stone" />
            <ModalLoading visible={loading} />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="card-button-listing">
                            {links.map((item, index) => (
                                <Link
                                    key={index}
                                    className={`card-button-item ${pathname === item.destiny ? 'active' : ''}`}
                                    to={{
                                        pathname: item.destiny,
                                        state: { backButton: true },
                                    }}
                                >
                                    <div className="text">{item.label}</div>
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className="right-column">
                        {stoneToken && (
                            <Switch>
                                <Route exact path="/painel-malls/gestao-stone" component={EstablishmentsListing} />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/detalhes-estabelecimento/:establishment_id"
                                    component={EstablishmentView}
                                />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/criar-estabelecimento"
                                    component={EstablishmentsCreate}
                                />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/criar-estabelecimento-credenciado"
                                    component={EstablishmentsCreateCredentialed}
                                />

                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/recebedores"
                                    component={RecipientsListing}
                                />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/recebedores/detalhes-recebedor/:recipient_id"
                                    component={RecipientView}
                                />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/recebedores/criar-recebedor"
                                    component={RecipientCreate}
                                />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/recebedores/criar-recebedor-credenciado"
                                    component={RecipientCreateCredentialed}
                                />

                                <Route exact path="/painel-malls/gestao-stone/servicos" component={ServicesListing} />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/servicos/vinculos"
                                    component={BoundServicesListing}
                                />

                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/configuracoes-maquininha"
                                    component={PosViewControl}
                                />

                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/validacoes"
                                    component={PartnerValidations}
                                />

                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/ajustes-transacao"
                                    component={TransactionAdjustmentConfiguration}
                                />

                                <Route exact path="/painel-malls/gestao-stone/extrato" component={FinanceExtractPage} />

                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/transacoes"
                                    component={TransactionListPage}
                                />
                                <Route
                                    exact
                                    path="/painel-malls/gestao-stone/transacoes-extrato"
                                    component={FinanceExtractTransactions}
                                />
                            </Switch>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoneManagement
