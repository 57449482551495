import * as Feather from 'react-feather'

import styled from 'styled-components'

const Container = styled.div`
    background-color: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    user-select: none;

    .confirm-button {
        width: 200px;
        margin-right: 20px;
    }
`

const SectionTitle = styled.div`
    color: #2d2d2d;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
        color: ${({ theme }) => theme.colors.primary};
        margin-right: 5px;
        width: 18px;
        height: 18px;
    }
`

const ContainerDetails = styled.div`
    display: flex;
    margin-bottom: 80px;
`

const ContainerInfo = styled.div`
    flex: 1;
`

const ContainerCard = styled.div`
    margin-right: 20px;
    .message {
        max-width: 120px;
    }
`

const CardInfo = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    min-width: 120px;
    height: 140px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    .text {
        color: #fff;
    }
    .counter {
        font-size: 55px;
        font-weight: bold;
    }
    .label {
        font-size: 12px;
        margin-top: -15px;
    }
`

const DocIcon = styled(Feather.Package)`
    align-self: flex-end;
    opacity: 0.8;
`

const ContainerAmounts = styled.div``

const Footer = styled.div`
    width: 100%;
`

const ContianerButton = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    .button {
        margin-right: 10px;
    }
`

export {
    Container,
    SectionTitle,
    ContainerDetails,
    ContainerInfo,
    CardInfo,
    DocIcon,
    Footer,
    ContainerCard,
    ContianerButton,
    ContainerAmounts,
}
