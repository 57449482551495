import { useState } from 'react'

import AppliedFilters from 'components/_new-general-vision/applied-filters/applied-filters'
import OrderItem from 'components/_new-general-vision/order-item/order-item'

import BikerSelectModal from '../biker-select-modal'
import RouteCreationContent from '../route-creation-content/route-creation-content'

import { orders, ready_orders } from './mock_orders'
import {
    ListBox,
    ListsContainer,
    ListTitle,
    ListTitleRow,
    MapContainer,
    NumberContainer,
    RouteCreationButton,
    RouteCreationContainer,
    RouteCreationContentContainer,
} from './new-orders.styles'

const selected_filters = ['HOY Pizza', 'HOY Pizza', 'HOY Pizza', 'HOY Pizza', 'HOY Pizza']

const NewOrders: React.FC = () => {
    const [isCreationActive, setIsCreationActive] = useState(false)
    const [isBikerModalOpened, setIsBikerModalOpened] = useState(false)

    return (
        <>
            <ListsContainer>
                <AppliedFilters selectedFilters={selected_filters} />
                <ListTitleRow>
                    <ListTitle>Pedidos em produção</ListTitle>
                    <NumberContainer>42</NumberContainer>
                </ListTitleRow>
                <ListBox>
                    {orders.map((item, index) => (
                        <OrderItem
                            key={index}
                            storeImage={item.storeImage}
                            channel={item.channel}
                            customerName={item.customerName}
                            customerNeighborhood={item.customerNeighborhood}
                            customerStreet={item.customerStreet}
                            customerNumber={item.customerNumber}
                            orderValue={item.orderValue}
                            payment={item.payment}
                            distance={item.distance}
                            elapsedTime={item.elapsedTime}
                            productionTime={item.productionTime}
                        />
                    ))}
                </ListBox>
                <ListTitleRow>
                    <ListTitle>Pedidos prontos para coleta</ListTitle>
                    <NumberContainer>42</NumberContainer>
                </ListTitleRow>
                <ListBox>
                    {ready_orders.map((item, index) => (
                        <OrderItem
                            key={index}
                            storeImage={item.storeImage}
                            channel={item.channel}
                            customerName={item.customerName}
                            customerNeighborhood={item.customerNeighborhood}
                            customerStreet={item.customerStreet}
                            customerNumber={item.customerNumber}
                            orderValue={item.orderValue}
                            payment={item.payment}
                            distance={item.distance}
                            elapsedTime={item.elapsedTime}
                            productionTime={item.productionTime}
                            readyTime={item.readyTime}
                        />
                    ))}
                </ListBox>
                <RouteCreationContentContainer isActive={isCreationActive}>
                    <RouteCreationContent
                        toggleBikersClick={() => setIsBikerModalOpened(true)}
                        backClick={() => setIsCreationActive(false)}
                    />
                </RouteCreationContentContainer>
                <RouteCreationContainer>
                    <RouteCreationButton onClick={() => setIsCreationActive(true)}>Criar Rota</RouteCreationButton>
                </RouteCreationContainer>
                <BikerSelectModal isActive={isBikerModalOpened} closeClick={() => setIsBikerModalOpened(false)} />
            </ListsContainer>
            <MapContainer>teste</MapContainer>
        </>
    )
}

export default NewOrders
