import circle from '@turf/circle'

const getCircleCoordinates = (center, radius) => {
    if (!center || !radius) return

    const {
        geometry: { coordinates },
    } = circle(center, radius, { steps: 50, units: 'meters' })

    return coordinates
}

export { getCircleCoordinates }
