import axios from 'axios'

export const mapboxApiURL = process.env.REACT_APP_MAPBOX_API_URL
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const mapboxApi = axios.create({ baseURL: mapboxApiURL })

mapboxApi.interceptors.request.use(
    async config => {
        config.params = {
            ...config.params,
            access_token: mapboxAccessToken,
        }

        return config
    },
    error => Promise.reject(error)
)

mapboxApi.interceptors.response.use(
    async response => response,
    error => Promise.reject(error)
)

export default mapboxApi
