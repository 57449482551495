import { useState } from 'react'

import Axios, { AxiosError } from 'axios'

import { googleMapsKey } from 'services/api'

interface Address {
    state?: string
    city?: string
    zipcode?: string
    neighborhood?: string
    street?: string
    number?: string
}

interface HookParams {
    beforeSearch?(): void
    afterSearch?(): void
    onSuccess?(response: { lat: number; lng: number }): void
    onError?(error: AxiosError): void
}

export function useGeocodingSearch({ beforeSearch, afterSearch, onSuccess, onError }: HookParams): {
    loading: boolean
    getLatLngByAddress: (address: Address) => void
} {
    const [loading, setLoading] = useState(false)

    const getLatLngByAddress = async (address: Address) => {
        try {
            beforeSearch && beforeSearch()
            setLoading(true)

            const formattedAddress = {
                number: address?.number || '',
                street: address?.street || '',
                neighborhood: address?.neighborhood || '',
                city: address?.city || '',
                state: address?.state || '',
                zipcode: address?.zipcode || '',
            } as Address

            const { number, street, zipcode, neighborhood, city, state } = formattedAddress
            const addressSearch = `${number} ${street}, ${zipcode}, ${neighborhood}, ${city}, ${state}`.replaceAll(
                ' ',
                '+'
            )

            const {
                data: {
                    results: [first_result],
                },
            } = await Axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: addressSearch,
                    key: googleMapsKey,
                },
            })

            const {
                geometry: { location },
            } = first_result

            onSuccess && onSuccess({ lat: location.lat, lng: location.lng })
        } catch (error) {
            onError && onError(error)
        } finally {
            setLoading(false)
            afterSearch && afterSearch()
        }
    }

    return { loading, getLatLngByAddress }
}
