import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import sourcePin from 'assets/images/map-pin-collect.png'

const Marker = styled.img.attrs({ src: sourcePin })`
    height: 35px;
    width: 35px;
    object-fit: contain;
`

const ContainerPopup = styled.div`
    background-color: #f1f5fe;
    user-select: none;
    padding: 10px;
    border-radius: 8px;
    overflow: hidden;
    width: 220px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
`
const ContainerTopInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 9px;
    color: #909090;
`
const StoreName = styled.div`
    font-weight: bold;
    color: #333;
    font-size: 14px;
    flex: 1;
`
const ContainerClose = styled.div`
    cursor: pointer;
`
const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 8px;
    font-size: 14px;
`

const Label = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #ff6600;
`

const TextInfo = styled.div`
    font-size: 11px;
`

const OrderLink = styled.div`
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #878787;
    align-self: flex-start;
    &:hover {
        color: #ff6600;
        text-decoration: underline;
    }
`

export { Marker, ContainerPopup, ContainerTopInfo, StoreName, ContainerClose, Icon, TextInfo, OrderLink, Label }
