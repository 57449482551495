import styled from 'styled-components'

export const StatusContainer = styled.div`
    border: 1px solid #ababab;
    border-radius: 8px;
    padding: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 20px;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    width: calc(100% - 16px);
    &:hover {
        border-color: ${({ theme }) => theme.colors.primary};
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
        width: unset;
    }
`

export const StatusCircle = styled.div<{ status?: number }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
    background-color: ${({ theme, status }) => (status === 1 ? theme.colors.success : theme.colors.errorMessage)};
`

export const StatusText = styled.div`
    font-size: 14px;
    margin-left: 10px;
    font-weight: 600;
    color: #717171;
`
