import { useCallback, useEffect, useRef, useState } from 'react'
import Spinner from 'react-spinner-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import ButtonLink from 'components/button-link/button-link'
import ButtonLoadMore from 'components/button-load-more/button-load-more'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { showErrors } from 'helpers'
import api from 'services/api'
import colors from 'themes/colors'

interface Activation {
    email: string
    activation_token: string
    created_at: string // YYYY-MM-DD HH:mm:ss
    loading: boolean
    checked: boolean
    first_name: string
    cellphone: string
}

interface Activations {
    current_page: number | undefined
    totals: number | undefined
    items: Activation[] | undefined
}

const DeliverersActivations: React.FC = () => {
    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [activations, setActivations] = useState<Activations>()

    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const getActivations = useCallback(async (current_page = 1) => {
        try {
            setLoading(true)
            const { data } = await api.get('/painel/activations', {
                params: {
                    current_page,
                    per_page: 50,
                },
            })

            setActivations(state => ({
                ...data,
                items: current_page > 1 && state?.items ? [...state?.items, ...data.items] : data.items,
            }))
        } catch (error) {
            console.log(error)

            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getActivations()
    }, [getActivations])

    async function getActivation(email: string) {
        try {
            setActivationLoading(true, 'email', email)

            const { data } = await api.get(`/painel/activation/${email}`)

            setActivations(state => ({
                current_page: state?.current_page,
                totals: state?.totals,
                items: state?.items?.map(item => (item.email === email ? { ...data, checked: true } : item)),
            }))
        } catch (error) {
            console.log({ error })

            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            setActivationLoading(false, 'email', email)
        }
    }

    async function deleteActivation(activation_token: string) {
        try {
            setActivationLoading(true, 'activation_token', activation_token)

            await api.delete(`/painel/activation/${activation_token}`)

            setActivations(state => ({
                current_page: state?.current_page,
                totals: state?.totals,
                items: state?.items?.filter(item => item.activation_token !== activation_token),
            }))
        } catch (error) {
            console.log({ error })

            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            setActivationLoading(false, 'activation_token', activation_token)
        }
    }

    function setActivationLoading(loading: boolean, compareKey: keyof Activation, compareValue: string) {
        setActivations(state => ({
            current_page: state?.current_page,
            totals: state?.totals,
            items: state?.items?.map(item =>
                item[compareKey] === compareValue ? { ...item, loading: loading } : item
            ),
        }))
    }

    return (
        <>
            <ModalLoading visible={loading && !Number(activations?.items?.length)} />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <TopRowDefault title="Pré-Cadastro" />

            <FlexTable
                columns={[
                    { name: 'Informações', width: '55%' },
                    { name: 'Código de ativação', width: '15%' },
                    { name: 'Data', width: '15%' },
                    { name: '', width: '15%' },
                ]}
                list={activations?.items?.map(user => {
                    const { email, activation_token, created_at, checked, loading, first_name, cellphone } = user

                    return {
                        informacoes: (
                            <div>
                                <div style={{ marginBottom: 15, fontWeight: 'bold' }}>{first_name}</div>
                                <div style={{ marginBottom: 5 }}>{email}</div>
                                <div style={{ marginBottom: 5 }}>{cellphone}</div>
                            </div>
                        ),
                        activation_token: (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                    <strong>{activation_token}</strong>
                                    <div style={{ marginLeft: 8 }}>
                                        {loading ? (
                                            <Spinner
                                                size={10}
                                                color={colors.light_primary_color}
                                                stroke={2}
                                                radius={20}
                                                visible={true}
                                            />
                                        ) : checked ? (
                                            <FontAwesomeIcon icon="check" color={colors.green} />
                                        ) : null}
                                    </div>
                                </div>

                                <ButtonLink
                                    style={{ alignItems: 'flex-start' }}
                                    label="Verificar código"
                                    onClick={() => getActivation(email)}
                                />
                            </>
                        ),
                        created_at: format(created_at, 'DD/MM/YYYY [às] HH:mm:ss'),
                        button: (
                            <div className="flex-table-row-content button-row">
                                <div
                                    className="button-item outline"
                                    onClick={() => {
                                        setModalConfirmationData({
                                            title: 'Excluir pré-cadastro',
                                            message: 'Deseja mesmo excluir este pré-cadastro?',
                                            onYes: () => deleteActivation(activation_token),
                                        })

                                        modalConfirmation.current?.openModal()
                                    }}
                                >
                                    Excluir
                                </div>
                            </div>
                        ),
                    }
                })}
            />

            <ButtonLoadMore
                loading={loading}
                visible={
                    Number(activations?.totals) > 0 && Number(activations?.items?.length) < Number(activations?.totals)
                }
                onClick={() => getActivations(Number(activations?.current_page) + 1)}
            />
        </>
    )
}

export default DeliverersActivations
