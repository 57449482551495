import React, { memo, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { formatAddress } from 'helpers'
import { useAuth } from 'hooks'
import { Order, Store } from 'types'

import {
    Marker,
    ContainerPopup,
    ContainerTopInfo,
    StoreName,
    ContainerClose,
    Icon,
    TextInfo,
    OrderLink,
    Label,
} from './marker-satellite.styled'

const MarkerSatellite: React.FC = memo(() => {
    return <Marker />
})

type PopUpContentSatelliteProps = {
    satellite: Order
    onClose(): void
}
const PopUpContentSatellite: React.FC<PopUpContentSatelliteProps> = memo(({ satellite, onClose }) => {
    const { basePath } = useAuth()
    const history = useHistory()
    const _onClose = useCallback(() => {
        if (onClose) {
            onClose()
        }
    }, [onClose])

    const _goToDetails = useCallback(
        (order: Order) => {
            history.push(`${basePath}/detalhes-pedido/${order.id}`)
        },
        [basePath, history]
    )

    useEffect(() => {
        console.log('satellite', satellite)
    }, [satellite])

    return (
        <ContainerPopup>
            <ContainerTopInfo>
                <StoreName>{satellite.merchant.name}</StoreName>
                <ContainerClose onClick={_onClose}>
                    <Icon icon="times" />
                </ContainerClose>
            </ContainerTopInfo>
            <TextInfo> {formatAddress(satellite.merchant.address).toUpperCase()}</TextInfo>
            <Label>Pedido a coletar</Label>
            <OrderLink onClick={() => _goToDetails(satellite)}>{`#${satellite.reference_id || satellite.id} - ${
                satellite.customer.name
            }`}</OrderLink>
        </ContainerPopup>
    )
})

export { MarkerSatellite, PopUpContentSatellite }
