export const scheduled_orders = [
    {
        date: '21/02/2021',
        totals: 40,
        orders_list: [
            {
                time_interval: '11:00 - 12:30',
                orders: [
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                ],
            },
            {
                time_interval: '13:00 - 14:30',
                orders: [
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                ],
            },
        ],
    },
    {
        date: '24/02/2021',
        totals: 120,
        orders_list: [
            {
                time_interval: '19:00 - 20:30',
                orders: [
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                ],
            },
            {
                time_interval: '21:00 - 22:30',
                orders: [
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2020/0918/oferta_16004619031034_logo.jpg',
                        channel: 1,
                        customerName: 'Carmen Li',
                        customerNeighborhood: 'Varjota',
                        customerStreet: 'Floriano Peixoto',
                        customerNumber: 762,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 3,
                        scheduleTime: '11:00 às 12:00',
                    },
                    {
                        storeImage: 'https://static.baratocoletivo.com.br/2018/1219/oferta_15452582379171_AVATAR.jpg',
                        channel: 2,
                        customerName: 'Yan Gondim',
                        customerNeighborhood: 'Edson Queiroz',
                        customerStreet: 'Avenida Washington Soares',
                        customerNumber: 800,
                        orderValue: 72.3,
                        payment: 'Dinheiro',
                        distance: 7,
                        scheduleTime: '11:00 às 12:00',
                    },
                ],
            },
        ],
    },
]
