import { useState, useRef } from 'react'
import { DraggableLocation, DropResult } from 'react-beautiful-dnd'
import { RouteComponentProps } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'
import { FormikValues, useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import EmptyMessage from 'components/empty-message/empty-message'
import FlexTableDraggable from 'components/flex-table-draggable'
import InputRadio from 'components/input-radio'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import TextInputForm from 'components/text-input-form'

import { getFormInputError, showErrors } from 'helpers'
import { useAuth, useAxiosRequest } from 'hooks'
import api from 'services/api'
import { Product, Showcase } from 'types'

import ModalProductsShowcase, { ModalProductsShowcaseRef } from './modal-products-showcase/modal-products-showcase'

const ShowcaseForm: React.FC<RouteComponentProps<{ showcase_id: string }>> = ({ history, match: { params } }) => {
    const { basePath, store } = useAuth()

    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalProductsShowcase = useRef<ModalProductsShowcaseRef>(null)

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const { loading } = useAxiosRequest<Showcase>({
        api,
        url: `/painel/showcase/${params.showcase_id}`,
        executeOnStart: params.showcase_id ? true : false,
        onComplete: ({ data }) => {
            const { id, products } = data

            setValues({
                ...values,
                ...data,
                id: id.toString(),
                products: products.map((product, index) => ({ ...product, order: index + 1 })),
            })
        },
        onError: onRequestError,
    })

    const submitShowcase = useAxiosRequest({
        api,
        method: params.showcase_id ? 'put' : 'post',
        executeOnStart: false,
        onComplete: () => {
            resetForm()

            setModalMessageData({
                title: 'Sucesso!',
                message: `Agrupamento ${params.showcase_id ? 'editado' : 'criado'} com sucesso!`,
                onClose: () => history.push(`${basePath}/gestao-marketplace/vitrine`),
            })

            modalMessage.current?.openModal()
        },
        onError: onRequestError,
    })

    const { isSubmitting, errors, getFieldProps, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                id: '',
                name: '',
                products: [] as (Product & { order?: number })[],
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome do agrupamento é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    await submitShowcase.request({
                        url: values.id ? `/painel/showcase/${values.id}` : '/painel/showcase',
                        data: {
                            ...values,
                            order: values.id ? undefined : 0,
                            products: values.products.map(({ id, order }) => ({ id, order })),
                            store_id: store.id,
                        },
                    })
                } finally {
                    setSubmitting(false)
                }
            },
        })

    function reorderProducts(items: Array<FormikValues>, source: DraggableLocation, destination: DraggableLocation) {
        const reordered = Array.from(items)
        const sourceIndex = source.index

        const sourceObject = reordered[sourceIndex]

        reordered.splice(source.index, 1)
        reordered.splice(destination.index, 0, sourceObject)

        return reordered
    }

    function onDragEnd(result: DropResult) {
        const { source, destination } = result

        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return

        const reordered = reorderProducts(values.products, source, destination)

        setFieldValue(
            'products',
            reordered.map((product, index) => ({ ...product, order: index + 1 }))
        )
    }

    return (
        <div>
            <RowTitle
                title={params.showcase_id ? 'Editar Agrupamento' : 'Criar Agrupamento'}
                backClick={() => history.push(`${basePath}/gestao-marketplace/vitrine`)}
            />

            <ModalLoading visible={isSubmitting || loading} />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalProductsShowcase
                ref={modalProductsShowcase}
                onUpdateProducts={products =>
                    setFieldValue(
                        'products',
                        products.map((product, index) => ({ ...product, order: index + 1 }))
                    )
                }
            />

            <div className="marketplace-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome*"
                        {...getFieldProps('name')}
                        msgErro={getFormInputError('name', errors, touched)}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Produtos
                </div>

                <FlexTableDraggable
                    onDragEnd={onDragEnd}
                    columns={[
                        { name: '', width: '5%' },
                        { name: 'ID', width: '5%' },
                        { name: 'Nome', width: '25%' },
                        { name: 'Em estoque', width: '15%' },
                        { name: 'Estoque gerenciável', width: '15%' },
                        { name: 'Status', width: '10%' },
                        { name: '', width: '15%' },
                    ]}
                    list={values.products.map(product => {
                        const { id, name, stock, manage_stock, status } = product

                        return {
                            grip_line: <FontAwesomeIcon icon="grip-lines" />,
                            id: id.toString(),
                            name,
                            stock: stock.toString(),
                            manage_stock: manage_stock ? 'Sim' : 'Não',
                            status: status ? 'Ativo' : 'Inativo',
                            buttonRow: (
                                <div className="flex-table-row-content button-row">
                                    <div
                                        className="button-item"
                                        onClick={() => {
                                            setModalConfirmationData({
                                                title: 'Remover produto do agrupamento',
                                                message: 'Tem certeza de que deseja remover este produto Agrupamento?',
                                                onYes: () =>
                                                    setFieldValue(
                                                        'products',
                                                        values.products.filter(item => item.id !== product.id)
                                                    ),
                                            })

                                            modalConfirmation.current?.openModal()
                                        }}
                                    >
                                        Remover
                                    </div>
                                </div>
                            ),
                        }
                    })}
                />

                {!values.products.length && (
                    <div style={{ marginBottom: '30px' }}>
                        <EmptyMessage>Nenhum produto neste agrupamento</EmptyMessage>
                    </div>
                )}

                <div className="inputs-row">
                    <ButtonForm
                        secondary
                        buttonText="Adicionar produtos ao agrupamento"
                        onClick={() => {
                            modalProductsShowcase.current?.openModal(
                                Number(values.id),
                                values.products.map(product => product.id)
                            )
                        }}
                    />
                </div>

                <div className="inputs-row col-2">
                    <div className="radio-group">
                        <label>Status</label>
                        <div className="radio-group-row">
                            <InputRadio
                                id="true-status"
                                label="Ativo"
                                checked={Boolean(values.status)}
                                onClick={() => setFieldValue('status', 1)}
                            />

                            <InputRadio
                                id="false-status"
                                label="Inativo"
                                checked={!values.status}
                                onClick={() => setFieldValue('status', 0)}
                            />
                        </div>
                    </div>
                </div>

                <ButtonForm type="submit" buttonText="Salvar" style={{ marginTop: 30 }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default ShowcaseForm
