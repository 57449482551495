import { useCallback, useContext, useState } from 'react'

import { useUI } from 'contexts'
import { ChangeOrderTypeContext } from 'contexts/change-order-type-context'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'

function useChangeTypeContent() {
    const { mall } = useAuth()
    const { order, options, setShow, setType, setLoading } = useContext(ChangeOrderTypeContext)
    const [selectedType, setSelectedType] = useState<string>()

    const { setErrorModal } = useUI()

    const _close = useCallback(() => {
        setShow(false)
    }, [setShow])

    const _updateOrder = useCallback(async () => {
        setLoading(true)
        try {
            const { address } = mall
            await api.put(`painel/dm-order/${order.id}`, {
                ...order,
                address: {
                    city: address.city.name,
                    state: address.city.state.uf,
                    street: address.street,
                    number: address.number,
                    zipcode: address.zipcode,
                    neighborhood:
                        typeof address.neighborhood === 'string' ? address.neighborhood : address.neighborhood.name,
                    complement: address.complement,
                    landmark: address.landmark,
                    lat: address.lat,
                    lng: address.lng,
                },
                destiny: null,
                type: 'takeout',
            })
            setShow(false)
        } catch (error) {
            setErrorModal({
                title: 'Erro ao alterar o tipo de entrega',
                subtitle: showErrors(error),
            })
        }
        setLoading(false)
    }, [order, mall, setShow, setErrorModal, setLoading])

    const _confirm = useCallback(() => {
        if (selectedType === 'takeout') {
            _updateOrder()
        } else {
            setType(selectedType)
        }
    }, [selectedType, setType, _updateOrder])

    return { order, options, selectedType, setSelectedType, _close, _confirm }
}

export { useChangeTypeContent }
