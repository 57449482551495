import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { DraggableLocation, DropResult } from 'react-beautiful-dnd'

import { FormikValues, useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'
import FlexTableDraggable from 'components/flex-table-draggable'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { formatCurrency, getFormInputError, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Product, Showcase } from 'types'

import { ActionButton, ReorderActions, ReorderRowActions, StatusItem } from '../showcases-listing.styled'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    DeleteIcon,
    FormSection,
    InputContainer,
    OutsideContainer,
    ProductDescription,
    ProductInfo,
    ProductName,
    ProductPhoto,
    ProductText,
} from './showcase-modal.styled'

type IForm = {
    id?: string | number
    name: string
    order?: number
    products: (Product & { order?: number })[]
    status: 0 | 1
}

export type ShowcaseModalRef = {
    show(): void
    close(): void
    setShowcase?(showcase: Showcase): void
    updateProducts?(products: Product[]): void
}

type Props = {
    isExpress?: boolean
    onClose?(refresh?: boolean): void
    onRemove?(id: number): () => void
    openProductModal?(showcaseId: number, selectedProducts: number[]): void
}

const ShowcaseModal = memo(
    forwardRef<ShowcaseModalRef, Props>(({ onClose, onRemove, openProductModal, isExpress }, ref) => {
        const { store } = useAuth()
        const { setErrorModal, setSuccessModal, setConfirmationModal } = useUI()
        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [showcase, setShowcase] = useState<Showcase>()

        const {
            isSubmitting,
            errors,
            touched,
            values,
            getFieldProps,
            setFieldValue,
            resetForm,
            setValues,
            handleSubmit,
        } = useFormik<IForm>({
            initialValues: {
                name: '',
                products: [],
                status: 1,
            } as IForm,
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome do agrupamento é obrigatório'),
            }),
            onSubmit: async values => {
                const url = isEdit ? `/painel/showcase/${values.id}` : '/painel/showcase'
                const body = {
                    type: isExpress ? 'express' : 'default',
                    ...values,
                    order: values.id ? values.order : 0,
                    products: values.products.map(({ id, order }) => ({ id, order: order ?? 0 })),
                    store_id: store.id,
                }

                try {
                    if (values.id) {
                        await api.put(url, body)
                    } else {
                        await api.post(url, body)
                    }

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: `Agrupamento ${isEdit ? 'atualizado' : 'criado'} com sucesso`,
                        singleButtonClick() {
                            _onClose(true)
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setShowcase(null)
                    setFieldValue('category_id', 0)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setShowcase: (showcase: Showcase) => {
                    setShowcase(showcase)
                    setValues(showcase)
                },
                updateProducts: (products: Product[]) => {
                    setFieldValue(
                        'products',
                        products.map((product, index) => ({ ...product, order: index + 1 }))
                    )
                },
            }),
            []
        )

        const isEdit = useMemo(() => {
            return !!showcase
        }, [showcase])

        const modalTitle = useMemo(() => `${isEdit ? 'Atualizar' : 'Criar'} Agrupamento`, [isEdit])

        const columnStyle = useMemo(() => ({ display: 'flex', justifyContent: 'center' }), [])

        const modalHeader = useMemo(
            () =>
                isEdit && (
                    <DefaultButton widthFitContainer variant="danger" onClick={onRemove(showcase.id)}>
                        <DeleteIcon /> Excluir
                    </DefaultButton>
                ),
            [isEdit, showcase, onRemove]
        )

        const _onClose = useCallback(
            (refresh?: boolean) => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            [onClose]
        )

        const _handleDeleteProduct = useCallback(
            (id: number) => {
                const remove = () => {
                    setFieldValue(
                        'products',
                        values.products.filter(item => item.id !== id)
                    )
                }

                return () => {
                    setConfirmationModal({
                        title: 'Remover Produto',
                        subtitle: 'Tem certeza de que deseja remover este produto?',
                        type: 'alert',
                        modalIcon: 'trash-alt',
                        leftButtonText: 'Cancelar',
                        rightButtonText: 'Sim, remover!',
                        rightButtonClick: remove,
                    })
                }
            },
            [values?.products]
        )

        const _reorderProducts = useCallback(
            (items: Array<FormikValues>, source: DraggableLocation, destination: DraggableLocation) => {
                const reordered = Array.from(items)
                const sourceIndex = source.index

                const sourceObject = reordered[sourceIndex]

                reordered.splice(source.index, 1)
                reordered.splice(destination.index, 0, sourceObject)

                return reordered
            },
            []
        )

        const _onDragEnd = useCallback(
            (result: DropResult) => {
                const { source, destination } = result

                if (!destination) return
                if (destination.droppableId === source.droppableId && destination.index === source.index) return

                const reordered = _reorderProducts(values.products, source, destination)

                setFieldValue(
                    'products',
                    reordered.map((product, index) => ({ ...product, order: index + 1 }))
                )
            },
            [values.products]
        )

        const _handleReorder = useCallback(
            async (from: number, to: number) => {
                const showcaseSource = values.products[from]
                const reordered = values.products
                reordered.splice(from, 1)
                reordered.splice(to, 0, showcaseSource)

                setFieldValue(
                    'products',
                    reordered.map((product, index) => ({ ...product, order: index + 1 }))
                )
            },
            [values.products]
        )

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle} headerComponent={modalHeader}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Nome"
                                    inputProps={{
                                        ...getFieldProps('name'),
                                        placeholder: 'Dê um nome para esse novo agrupamento',
                                    }}
                                    errorMessage={getFormInputError('name', errors, touched)}
                                />
                            </InputContainer>

                            <InputContainer hasMargin>
                                <FlexTableDraggable
                                    onDragEnd={_onDragEnd}
                                    columns={[
                                        {
                                            name: '',
                                            width: '5%',
                                            style: columnStyle,
                                        },
                                        {
                                            name: 'ID',
                                            width: '5%',
                                            style: columnStyle,
                                        },
                                        {
                                            name: 'Produto',
                                            width: '45%',
                                            style: columnStyle,
                                        },
                                        {
                                            name: 'Preço',
                                            width: '15%',
                                            style: columnStyle,
                                        },
                                        {
                                            name: 'Status',
                                            width: '5%',
                                            style: columnStyle,
                                        },
                                        { name: '', width: '7%', style: columnStyle },
                                    ]}
                                    list={values.products?.map((product, index) => {
                                        const { id, name, description, photo, price, status } = product
                                        const isFirst = index === 0
                                        const isLast = values.products?.length === index + 1
                                        return {
                                            reorderActions: (
                                                <ReorderRowActions>
                                                    <ReorderActions
                                                        icon={'arrow-up'}
                                                        isActive={!isFirst}
                                                        onClick={() =>
                                                            !isFirst ? _handleReorder(index, index - 1) : null
                                                        }
                                                    />

                                                    <ReorderActions
                                                        icon={'arrow-down'}
                                                        isActive={!isLast}
                                                        onClick={() =>
                                                            !isLast ? _handleReorder(index, index + 1) : null
                                                        }
                                                    />
                                                </ReorderRowActions>
                                            ),
                                            id: id.toString(),
                                            product: (
                                                <ProductInfo>
                                                    <ProductPhoto src={`${photo}?width=340`} />
                                                    <ProductText>
                                                        <ProductName>{name}</ProductName>
                                                        <ProductDescription>{description}</ProductDescription>
                                                    </ProductText>
                                                </ProductInfo>
                                            ),
                                            price: formatCurrency(price),
                                            status: (
                                                <StatusItem isActive={Boolean(status)}>
                                                    {status ? 'Ativo' : 'Inativo'}
                                                </StatusItem>
                                            ),
                                            buttonRow: (
                                                <ActionButton
                                                    widthFitContainer
                                                    variant="danger"
                                                    onClick={_handleDeleteProduct(id)}
                                                >
                                                    <DeleteIcon />
                                                </ActionButton>
                                            ),
                                        }
                                    })}
                                />
                            </InputContainer>

                            <InputContainer hasMargin>
                                <DefaultButton
                                    outline
                                    title="Adicionar produtos ao agrupamento"
                                    onClick={() => {
                                        openProductModal(
                                            Number(values.id),
                                            values.products.map(product => product.id)
                                        )
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    options={[
                                        { label: 'Ativo', value: 1 },
                                        { label: 'Inativo', value: 0 },
                                    ]}
                                    labelText="Status"
                                    inputProps={getFieldProps('status')}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <ConfirmButton title="salvar" onClick={() => handleSubmit()} />
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { ShowcaseModal }
