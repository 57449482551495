import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
    export interface DefaultTheme {
        colors: {
            primary: string
            darkOrange: string
            secondary: string
            green: string
            cream: string
            gray: string
            darkGray: string
            mediumGray: string
            lightGray: string
            yellow: string
            black: string
            white: string
            success: string
            errorMessage: string
            text: string
            textLight: string
            alert: string
            danger: string
            foregroundPrimary: string
        }
        actionModalIndex: number
        bikersSelectIndex: number
        commonModalZIndex: number
        snackbarIndex: number
        devices: {
            mobile: string
            tablet: string
            tabletPro: string
            desktop: string
            desktopLarge: string
            desktopFull: string
        }
        orders: {
            green: string
            yellow: string
            red: string
        }
        dimensions: {
            heightButton: number
            heightForm: number
            headerHeight: number
            borderWidth: number
            paddingForm: number
            radius: number
            radiusForm: number
            maxWidthContent: number
        }
        fontSizes: {
            large: number
            normal: number
            medium: number
            small: number
            caption: number
            xLarge: number
            xSmall: number
            xxLarge: number
            xxSmall: number
        }
    }
}

const theme: DefaultTheme = {
    colors: {
        primary: '#FF860A',
        darkOrange: '#F16C07',
        secondary: '#C82101',
        green: '#102D1B',
        cream: '#DFC67E',
        gray: '#f1f1f1',
        darkGray: '#666',
        mediumGray: '#333',
        lightGray: '#c1c1c1',
        yellow: '#FFC612',
        black: '#212121',
        white: '#fff',
        success: '#27ae60',
        errorMessage: '#e74c3c',
        text: '#2D2D2D',
        textLight: '#666666',
        danger: '#FA2D37',
        alert: '#FFBC32',
        foregroundPrimary: '#fff',
    },
    commonModalZIndex: 10000,
    actionModalIndex: 5000000000000,
    bikersSelectIndex: 9000000000000,
    snackbarIndex: 90000000000000,
    devices: {
        mobile: '320px',
        tablet: '720px',
        tabletPro: '1020px',
        desktop: '1200px',
        desktopLarge: '1600px',
        desktopFull: '1920px',
    },
    orders: {
        green: '#7ED321',
        yellow: '#F5A623',
        red: '#D0021B',
    },
    dimensions: {
        heightButton: 38,
        heightForm: 50,
        headerHeight: 64,
        borderWidth: 1,
        paddingForm: 6,
        radius: 8,
        radiusForm: 8,
        maxWidthContent: 1000,
    },
    fontSizes: {
        large: 18,
        medium: 16,
        normal: 14,
        caption: 8,
        small: 10,
        xLarge: 20,
        xSmall: 12,
        xxLarge: 22,
        xxSmall: 14,
    },
}

export default theme
