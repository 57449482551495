import { useEffect, useMemo, useState } from 'react'

import Checkbox from 'components/_common/checkbox/checkbox'

import { useUI } from 'contexts'
import { useAuth, useDataFetching } from 'hooks'
import api from 'services/api'
import { PagedList, Product } from 'types'

import {
    ContentTitle,
    DuplicateGroupModalBackground,
    DuplicateGroupModalBox,
    DuplicateGroupModalContainer,
    DuplicateGroupModalContent,
    CloseButton,
    CloseContainer,
    DuplicateGroupHeader,
    ButtonRow,
    TextButton,
    ConfirmButton,
    ContentSubtitle,
    SectionContainer,
    SectionTitle,
} from './duplicate-group-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
    productId: number
    revalidateProduct(): void
}

const DuplicateGroupModal: React.FC<Props> = ({ isActive, closeClick, productId, revalidateProduct }) => {
    const { store } = useAuth()
    const { setErrorModal, setLoading, setSnackbar } = useUI()
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(-1)

    const { data: products } = useDataFetching<PagedList<Product>>(`/painel/products`, {
        params: { store_id: store.id, order_by: 'name', per_page: -1 },
    })

    const groupedProducts:
        | Array<{
              categoryName: string
              products: Product[]
          }>
        | undefined = useMemo(() => {
        const groupedProductsByCategory = products?.items?.reduce(
            (acc, product) => ({
                ...acc,
                [product.category.name]: [...(acc[product.category.name] || []), product],
            }),
            {} as { [key: string]: Product[] }
        )

        if (!groupedProductsByCategory) return undefined

        return Object.keys(groupedProductsByCategory).map(categoryName => ({
            categoryName: categoryName,
            products: groupedProductsByCategory[categoryName],
        }))
    }, [products])

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    function isChecked(productID: number, selectedValue: number) {
        if (productID === selectedValue) {
            return productID
        } else {
            return undefined
        }
    }

    async function handleSubmit() {
        if (selectedProduct === -1) {
            setErrorModal({
                title: 'Impossivel importar',
                subtitle: 'Selecione um produto para importar acompanhamentos',
            })
        } else {
            try {
                setLoading(true)
                await api.post(`/painel/product-part/duplicate`, {
                    from: selectedProduct,
                    to: productId,
                })
                closeClick()
                setSnackbar({ message: 'Acompanhamentos importados' })
                revalidateProduct()
                setSelectedProduct(-1)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <DuplicateGroupModalContainer isDisplayed={isDisplayed}>
            <DuplicateGroupModalBox isEffectActive={isEffectActive}>
                <DuplicateGroupHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Importar Acompanhamentos</ContentTitle>
                    <ContentSubtitle>Escolha um produto de onde serão importados os acompanhamentos</ContentSubtitle>
                </DuplicateGroupHeader>
                <DuplicateGroupModalContent>
                    {groupedProducts?.map((group, index) => (
                        <SectionContainer key={index}>
                            <SectionTitle>{group.categoryName}</SectionTitle>
                            {group.products?.map((product, index) => (
                                <Checkbox
                                    checkboxTitle={product.name}
                                    key={index}
                                    inputProps={{
                                        value: isChecked(product.id, selectedProduct),
                                        checked: false,
                                        onChange: () => setSelectedProduct(product.id),
                                    }}
                                />
                            ))}
                        </SectionContainer>
                    ))}
                </DuplicateGroupModalContent>
                <ButtonRow>
                    <TextButton onClick={closeClick}>Cancelar</TextButton>
                    <ConfirmButton isActive={selectedProduct !== -1} onClick={() => handleSubmit()}>
                        Importar
                    </ConfirmButton>
                </ButtonRow>
            </DuplicateGroupModalBox>
            <DuplicateGroupModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </DuplicateGroupModalContainer>
    )
}

export default DuplicateGroupModal
