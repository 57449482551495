import { PropsWithChildren } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import theme from 'styles/theme'

import {
    ActionModalBox,
    ActionModalContainer,
    CloseButton,
    ModalIcon,
    ModalTitle,
    ModalSubtitle,
    ButtonRow,
    SingleButton,
    TextButton,
} from './action-modal.styles'

type ModalType = 'alert' | 'delete' | 'success'

export interface Props {
    hideRow?: boolean
    isActive?: boolean
    rowMode?: boolean
    hasOutlineButton?: boolean
    leftButtonText?: string
    title?: string
    subtitle?: string
    rightButtonText?: string
    singleButtonText?: string
    singleButtonClick?(): void
    textButtonText?: string
    closeClick?(): void
    leftButtonClick?(): void
    rightButtonClick?(): void
    textButtonClick?(): void
    type?: ModalType
    modalIcon?: IconProp
}

export type ActionModalProps = PropsWithChildren<Props>

const ActionModal: React.FC<ActionModalProps> = ({
    isActive,
    rowMode,
    hideRow,
    singleButtonText,
    singleButtonClick,
    textButtonText,
    hasOutlineButton,
    leftButtonClick,
    rightButtonClick,
    leftButtonText,
    rightButtonText,
    textButtonClick,
    title,
    subtitle,
    closeClick,
    type,
    children,
    modalIcon,
}) => {
    function getIconByType(type: ModalType): IconProp {
        if (modalIcon) return modalIcon

        return {
            alert: 'exclamation-circle' as IconProp,
            delete: 'trash-alt' as IconProp,
            success: 'check-circle' as IconProp,
        }[type]
    }

    function getColorByType(type: ModalType) {
        return {
            alert: theme.colors.primary,
            delete: theme.colors.errorMessage,
            success: theme.colors.success,
        }[type]
    }

    return (
        <ActionModalContainer isActive={isActive}>
            <ActionModalBox isActive={isActive}>
                <CloseButton onClick={closeClick} />
                {type && <ModalIcon icon={getIconByType(type)} style={{ color: getColorByType(type) }} />}
                <ModalTitle>{title}</ModalTitle>
                <ModalSubtitle>{subtitle}</ModalSubtitle>
                {children}
                {!hideRow && leftButtonText && rightButtonText && (
                    <ButtonRow rowMode={rowMode}>
                        <SingleButton
                            rowMode={rowMode}
                            isOutline={hasOutlineButton}
                            onClick={() => {
                                leftButtonClick && leftButtonClick()
                                closeClick && closeClick()
                            }}
                        >
                            {leftButtonText}
                        </SingleButton>
                        <SingleButton
                            type={type}
                            rowMode={rowMode}
                            onClick={() => {
                                rightButtonClick && rightButtonClick()
                                closeClick && closeClick()
                            }}
                        >
                            {rightButtonText}
                        </SingleButton>
                    </ButtonRow>
                )}
                {singleButtonText && (
                    <SingleButton
                        isOutline={hasOutlineButton}
                        onClick={() => {
                            singleButtonClick && singleButtonClick()
                            closeClick && closeClick()
                        }}
                    >
                        {singleButtonText}
                    </SingleButton>
                )}
                {textButtonText && <TextButton onClick={textButtonClick}>{textButtonText}</TextButton>}
            </ActionModalBox>
        </ActionModalContainer>
    )
}

export default ActionModal
