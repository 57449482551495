import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const SearchContainer = styled.div`
    border: 1px solid rgb(153, 153, 153);
    height: 37px;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex: 1;
    margin-top: 10px;
    box-sizing: border-box;
`

const SearchInput = styled.input`
    border: 0;
    outline: none;
    flex: 1;
    background-color: transparent;
`

const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    margin-left: 10px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }
`

const Button = styled.div`
    height: 26px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: 1px solid rgb(153, 153, 153);
    color: #000;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    .icon {
        margin-right: 10px;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
        color: #fff;
    }
`

const ContainerDistrics = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
`

const Badge = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border-radius: 16px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 4px 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

    .icon {
        cursor: pointer;
        margin-left: 8px;
    }
`

export { SearchContainer, SearchInput, SearchIcon, Button, ContainerDistrics, Badge }
