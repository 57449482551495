import { faBiking, faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>

export const TariffInputContainer = styled.div<{ showBorder?: boolean }>`
    display: flex;
    align-items: center;
    padding: ${({ showBorder }) => (showBorder ? '12px 8px' : 0)};
    background-color: ${({ showBorder }) => (showBorder ? '#fff' : 'transparent')};
    border: ${({ showBorder }) => (showBorder ? '1px solid #aaa' : 0)};
    border-radius: 4px;
    width: 40%;
    margin-bottom: 10px;
    margin-right: 5px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 30%;
        margin-right: 1%;
        margin-bottom: 0;
    }
`

export const IconContainer = styled.div`
    width: 20px;
    margin-right: 15px;
`

export const StoreIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const BikerIcon = styled(FontAwesomeIcon).attrs({
    icon: faBiking,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
`

export const TariffMainInput = styled.input<HTMLInputProps>`
    flex: 1;
    border: 0;
    background-color: transparent;
    width: 80px;
    outline: none;
    height: 15px;
    position: relative;
`

export const MoneyTag = styled.div`
    font-size: 12px;
    margin-right: 2px;
`
