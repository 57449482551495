import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { getFormInputError } from 'helpers'

import { useModalChangePaymentType } from './modal-change-payment-type.controller'
import {
    Box,
    Button,
    Container,
    ContainerButtons,
    Content,
    Footer,
    Icon,
    InputContent,
    Message,
    Separator,
    Title,
} from './modal-change-payment-type.styled'

export type ModalChangePaymentTypeRef = {
    show(data: { orderId: number; orderPaymentId: number; orderChannelId: number }): void
    close(): void
}

type Props = {
    refresh?(): void
}

const ModalChangePaymentType = memo(
    forwardRef<ModalChangePaymentTypeRef, Props>(({ refresh }, ref) => {
        const {
            isValid,
            visible,
            loading,
            paymentOptions,
            errors,
            touched,
            getFieldProps,
            _show,
            _close,
            _handleSubmit,
        } = useModalChangePaymentType(refresh)

        useImperativeHandle(
            ref,
            () => ({
                show: _show,
                close: _close,
            }),
            [_show, _close]
        )

        if (!visible) return null

        return (
            <Container>
                <Box show={visible}>
                    <Content>
                        <Title id="title-modal" className="title">
                            Alterar Forma de Pagamento
                        </Title>
                        <Icon icon="exclamation-circle" />
                        <Message>Selecione uma das formas de pagamento</Message>

                        <InputContent>
                            <InputItem
                                labelText="Forma de Pagamento"
                                type="select"
                                options={paymentOptions}
                                inputProps={{
                                    ...getFieldProps('payment.id'),
                                }}
                                errorMessage={getFormInputError('payment.id', errors, touched)}
                            />
                        </InputContent>
                    </Content>
                    <Footer>
                        <ContainerButtons>
                            <Button id="yes-button" onClick={_handleSubmit} disabled={!isValid}>
                                Sim
                            </Button>
                        </ContainerButtons>
                        <Separator />
                        <ContainerButtons>
                            <Button id="no-button" isOutline onClick={_close}>
                                Não
                            </Button>
                        </ContainerButtons>
                    </Footer>
                </Box>
                <ModalLoading visible={loading} />
            </Container>
        )
    })
)

export { ModalChangePaymentType }
