import { collects } from './mock_orders'
import {
    ListBox,
    ListsContainer,
    ListTitle,
    ListTitleRow,
    OrderDetails,
    NumberContainer,
    EmptyText,
    OrderCallClick,
    OrderCallIcon,
    EmptyTitle,
    StoreTitleRow,
    StoreIcon,
    ButtonRow,
    TextButton,
    ConfirmButton,
} from './collects-in-progress.styles'

import { PickerOrderItem } from '..'

interface Props {
    orderCardClick(): void
    problemClick(): void
}

const CollectsInProgress: React.FC<Props> = ({ orderCardClick, problemClick }) => {
    return (
        <>
            <ListsContainer>
                <ListTitleRow>
                    <ListTitle>Coletar Pedidos</ListTitle>
                    <NumberContainer>0/{collects.totals}</NumberContainer>
                </ListTitleRow>
                <ListBox>
                    <>
                        {collects.stores.map(item => (
                            <>
                                <StoreTitleRow>
                                    <StoreIcon /> {item.storeName}
                                </StoreTitleRow>
                                <>
                                    {item.orders.map((item, index) => (
                                        <PickerOrderItem
                                            storeLogo={item.storeImage}
                                            key={index}
                                            orderID={item.orderID}
                                            customerName={item.customerName}
                                            channel={item.channel}
                                            productionTime={item.productionTime}
                                            totalTime={item.elapsedTime}
                                            orderValue={item.orderValue}
                                            payment={item.payment}
                                            confirmClick={() => null}
                                            cardClick={orderCardClick}
                                            detailClick={orderCardClick}
                                            problemClick={problemClick}
                                            showCollectButton
                                        />
                                    ))}
                                </>
                            </>
                        ))}
                    </>
                </ListBox>
                <ButtonRow>
                    <TextButton>Adicionar Pedidos</TextButton>
                    <ConfirmButton>Iniciar Entregas</ConfirmButton>
                </ButtonRow>
            </ListsContainer>
            <OrderDetails>
                <OrderCallClick>
                    <OrderCallIcon />
                    <EmptyTitle>Rota de coleta em andamento</EmptyTitle>
                    <EmptyText>Confirme a coleta de todos os pedidos para iniciar as entregas no dispatch</EmptyText>
                </OrderCallClick>
            </OrderDetails>
        </>
    )
}

export default CollectsInProgress
