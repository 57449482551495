import { format } from 'date-fns'

import { LoadingMoreButton } from 'components/_common'

import { useUI } from 'contexts'
import { formatCurrency } from 'helpers'
import api from 'services/api'
import { PerformanceFilters, ReportOrderItem } from 'types'

import {
    OrderTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    EmptyMessageContainer,
    EmptyImage,
    EmptyMessage,
    ActionHeader,
    ActionTitle,
    ButtonsContainer,
    ActionButton,
    TableId,
    TableBiker,
    TableSocial,
    TableVehicle,
    TableTotals,
    TableValue,
} from './personal-deliveries-table.styles'

interface Props {
    deliveries: ReportOrderItem[]
    totals: number
    loading: boolean
    loadClick(): void
    filters: PerformanceFilters
    id: number
}

const PersonalDeliveriesTable: React.FC<Props> = ({ deliveries, totals, loading, loadClick, filters, id }) => {
    const { setLoading, setSnackbar } = useUI()

    const exportIndividualSpreadsheet = async (id: number) => {
        try {
            setLoading(true)
            const response = await api.get(`/painel/report/payments/agent/${id}`, {
                responseType: 'blob',
                params: {
                    ...filters,
                    download: 'xlsx',
                },
            })

            const downloadUrl = window.URL.createObjectURL(response)

            const timestamp = `${filters.start_date} a ${filters.end_date}`
            const filename = `Relatório Individual - ${timestamp}.xlsx`
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
            setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100)
        } catch (error) {
            setSnackbar({ message: 'Erro ao exportar relatório', type: 'error' })
        } finally {
            setLoading(false)
        }
    }
    return (
        <TableContainer>
            <OrderTable>
                <ActionHeader>
                    <ActionTitle>Relação de entregadores e valor a ser pago</ActionTitle>
                    <ButtonsContainer>
                        <ActionButton onClick={() => exportIndividualSpreadsheet(id)}>Exportar Relatório</ActionButton>
                    </ButtonsContainer>
                </ActionHeader>
                <TableHeader>
                    <TableId>ID</TableId>
                    <TableBiker>Loja</TableBiker>
                    <TableSocial>Endereço de Entrega</TableSocial>
                    <TableVehicle>Recebido</TableVehicle>
                    <TableTotals>Entrega</TableTotals>
                    <TableValue>Taxa Padrão</TableValue>
                    <TableValue>Adicional</TableValue>
                    <TableValue>Total</TableValue>
                </TableHeader>
                <TableContent>
                    {deliveries.length > 0 ? (
                        <>
                            {deliveries.map((item, index) => (
                                <TableItem key={index}>
                                    <TableId>
                                        <span>ID: </span>
                                        {item.id}
                                    </TableId>
                                    <TableBiker>
                                        <span>Loja: </span> {item.store}
                                    </TableBiker>
                                    <TableSocial>
                                        <span>Endereço de Entrega: </span>{' '}
                                        <b>
                                            {item.address.street}, {item.address.number}
                                        </b>
                                        <br />
                                        {item.address.neighborhood}
                                    </TableSocial>
                                    <TableVehicle>
                                        <span>Recebido: </span> {format(new Date(item.birth), `DD/MM/YYYY - HH:mm`)}
                                    </TableVehicle>
                                    <TableTotals>
                                        <span>Entrega: </span>{' '}
                                        {format(new Date(item.end_delivery), `DD/MM/YYYY - HH:mm`)}
                                    </TableTotals>
                                    <TableValue>
                                        <span>Taxa Padrão: </span> {formatCurrency(item.agent_rate)}
                                    </TableValue>
                                    <TableValue>
                                        <span>Adicional: </span> {formatCurrency(item.additional)}
                                    </TableValue>
                                    <TableValue>
                                        <span>Total: </span> {formatCurrency(item.agent_total)}
                                    </TableValue>
                                </TableItem>
                            ))}
                            {deliveries.length !== totals && (
                                <LoadingMoreButton loading={loading} onClick={loadClick} />
                            )}
                        </>
                    ) : (
                        <EmptyMessageContainer>
                            <EmptyImage />
                            <EmptyMessage>Nenhum relatório localizado</EmptyMessage>
                        </EmptyMessageContainer>
                    )}
                </TableContent>
            </OrderTable>
        </TableContainer>
    )
}

export default PersonalDeliveriesTable
