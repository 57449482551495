import { differenceInMinutes } from 'date-fns'
import moment from 'moment'

/**
 *
 * @param {string} startDate
 */

const getTimeDiff = (startDate, finalDate = null) => {
    const totalMinutes = differenceInMinutes(finalDate || new Date(), startDate)
    const { days, hours, minutes, seconds } = moment(finalDate || new Date()).preciseDiff(startDate, true)

    const timeDiff = {
        days: days.toString(),
        hours: hours < 10 ? `0${hours}` : hours.toString(),
        minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
        seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
    }

    let text = `${minutes}m`

    if (hours > 0) text = `${timeDiff.hours}h${timeDiff.minutes}m`
    if (days > 0) text = `${timeDiff.days}d${timeDiff.hours}h${timeDiff.minutes}m`

    return {
        ...timeDiff,
        minutes: totalMinutes,
        text,
    }
}

export { getTimeDiff }
