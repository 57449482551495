import { memo } from 'react'

import {
    Container,
    Avatar,
    ContainerInfo,
    TextHighlights,
    TextName,
    TextStatus,
    Button,
    TextContent,
    IntegrationStatusContent,
} from './integrator-store-listing-item.styled'

type Props = {
    image: any
    name: string
    mallName?: string
    status?: boolean
    seller?: string
    integrationsStatus?: { auto_integration: boolean; marketplace_start_at: boolean }
    buttonText?: string
    isMercadoo?: boolean
    onClickButton?: () => void
}

const IntegratorStoreListingItem = memo<Props>(
    ({ image, name, mallName, integrationsStatus, seller, status, buttonText, isMercadoo, onClickButton }) => {
        return (
            <Container>
                <Avatar src={image} />
                <ContainerInfo>
                    {!!mallName && <TextHighlights>{mallName}</TextHighlights>}
                    <TextName>{name}</TextName>
                    {isMercadoo ? (
                        <>
                            <TextContent>
                                <IntegrationStatusContent>
                                    <TextContent>
                                        Exibir no Marketpalce:
                                        <TextStatus active={integrationsStatus.marketplace_start_at}>
                                            {integrationsStatus.marketplace_start_at ? 'Ativo' : 'Inativo'}
                                        </TextStatus>
                                    </TextContent>
                                    <TextContent>
                                        | Criação Automática de Pedido no Aceite:
                                        <TextStatus active={integrationsStatus.auto_integration}>
                                            {integrationsStatus.auto_integration ? 'Ativo' : 'Inativo '}
                                        </TextStatus>
                                    </TextContent>
                                </IntegrationStatusContent>
                            </TextContent>
                            {
                                <TextContent isActive={!seller}>
                                    Zoop ID: <span>{seller ?? 'Não configurado'}</span>
                                </TextContent>
                            }
                        </>
                    ) : (
                        !!status && <TextStatus active={status}>{status ? 'Ativo' : 'Inativo'}</TextStatus>
                    )}
                </ContainerInfo>
                {!!buttonText && <Button onClick={onClickButton}>{buttonText}</Button>}
            </Container>
        )
    }
)

export { IntegratorStoreListingItem }
