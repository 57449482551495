import { useState, useContext, useLayoutEffect, useCallback, useEffect, useRef, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Attendance from 'assets/images/svg/attendance.svg'
import Picker from 'assets/images/svg/picker.svg'

import ConfigAutoRouteModal from 'components/_new-general-vision/config-auto-route-modal'
import { MirrorMap, GlobalMapContext } from 'components/global-map'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'
import { ModalConfigQueue, ModalConfigQueueRef } from 'components/modal-config-queue/modal-config-queue'
import { ModalDeliveryModality } from 'components/modal-delivery-modality/modal-delivery-modality'

import { useDataFetching } from 'hooks'
import { useAuth } from 'hooks'
import { ILogarooIcons } from 'types/logaroo-icons'

import { CircleFill } from '../../components/mapbox-view'
import TopRowDefault from '../../components/top-row-default/top-row-default'

import './style.scss'
import { PickerTaxModal, PickerTaxModalRef } from './components/picker-tax-modal/picker-tax-modal'

type ILinks = {
    icon: IconProp | ILogarooIcons
    isLogarooIcon?: boolean
    img?: any
    label: string
    destiny: string
    hasClick?: boolean
    isVisible: boolean
    clickAction?: () => void
}

const MallManagement = () => {
    const { mall, user } = useAuth()

    const modalDeliveryModalityRef = useRef<ModalDeliveryModality>()
    const modalConfigQueueRef = useRef<ModalConfigQueueRef>()
    const pickerTaxModalRef = useRef<PickerTaxModalRef>()

    const { setMapBoxContentComponent } = useContext(GlobalMapContext)
    const [configModalActive, setConfigModalActive] = useState(false)

    function storePushValue(value) {
        localStorage.setItem('@logaroo/push_notification', JSON.stringify(value))
    }

    useDataFetching('/painel/push-notification/status', {
        onSuccess: (data: { status: boolean }) => {
            storePushValue(data.status)
        },
    })

    const enabledFeatures = useMemo(() => {
        return {
            marketplace: Boolean(mall.configs?.marketplace_enable),
            pickers: Boolean(mall.configs?.picker_ops),
        }
    }, [mall])

    const links: ILinks[] = [
        // { icon: 'bullseye', label: 'Área de Roteirização', destiny: 'area-de-roteirizacao' },
        { icon: 'map', label: 'Área de Cobrança Logística', destiny: 'area-de-cobranca', isVisible: true },
        {
            isLogarooIcon: true,
            icon: 'shop',
            label: 'Área de Cobrança Marketplace',
            destiny: `area-cobranca-marketplace`,
            isVisible: enabledFeatures.marketplace,
        },
        {
            isLogarooIcon: true,
            icon: 'tray',
            label: 'Área de Atendimento Presencial',
            destiny: `area-atendimento-presencial`,
            img: <img src={Attendance} />,
            isVisible: enabledFeatures.marketplace,
        },
        {
            isLogarooIcon: true,
            icon: 'tray',
            label: 'Horário de Atendimento do Picker',
            destiny: 'horarios-de-atendimento-picker',
            img: <img src={Picker} />,
            isVisible: enabledFeatures.marketplace,
        },
        {
            isLogarooIcon: true,
            icon: 'real',
            label: 'Tarifa de Atendimento Presencial',
            destiny: `/${mall.slug}/gestao-mall`,
            hasClick: true,
            clickAction: pickerTaxModalRef.current?.show,
            isVisible: enabledFeatures.marketplace,
        },
        {
            icon: 'dot-circle',
            label: 'Configurar Fila',
            destiny: `/${mall.slug}/gestao-mall`,
            hasClick: true,
            clickAction: modalConfigQueueRef.current?.show,
            isVisible: true,
        },
        // {
        //     icon: 'road',
        //     label: 'Modalidades de Entrega',
        //     destiny: `/${mall.slug}/gestao-mall`,
        //     hasClick: true,
        //     clickAction: modalDeliveryModalityRef.current?.show,
        // },
        {
            icon: 'stopwatch',
            label: 'Tempos de Performance',
            destiny: 'tempos-performance',
            isVisible: true,
        },
        {
            icon: 'robot',
            label: 'Roteirização Automática',
            destiny: `/${mall.slug}/gestao-mall`,
            hasClick: true,
            clickAction: () => setConfigModalActive(true),
            isVisible: true,
        },
        { icon: 'dollar-sign', label: 'Tarifas', destiny: 'tarifas', isVisible: true },
        { icon: 'tags', label: 'Tarifas Dinâmicas', destiny: 'tarifas-dinamicas', isVisible: true },
        { icon: 'exchange-alt', label: 'Tarifas de Retorno', destiny: 'tarifas-de-retorno', isVisible: true },
        { icon: 'bolt', label: 'Tarifas Expressas', destiny: 'tarifas-expressas', isVisible: true },
        { icon: 'link', label: 'Tarifa de Rota Corrente', destiny: 'tarifa-corrente', isVisible: true },
        {
            icon: 'store',
            label: 'Gerenciar Lojas',
            destiny: 'lojas',
            isVisible: !!user?.roles?.some(x => x.role === 'superadmin'),
        },
        { icon: 'user-friends', label: 'Gerenciar Lojistas', destiny: 'painel-lojistas', isVisible: true },
        {
            isLogarooIcon: false,
            icon: 'users',
            label: 'Gerenciar Pickers',
            destiny: `painel-pickers`,
            isVisible: enabledFeatures.pickers,
        },
    ]

    const _toggleConfigModal = useCallback((hasClick: boolean, clickAction?: () => void) => {
        if (!hasClick) return
        clickAction()
    }, [])

    const _renderMapContent = useCallback(() => {
        return (
            <>
                <CircleFill radius={mall.radius} center={[mall.address.lng, mall.address.lat]} />
            </>
        )
    }, [mall])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
    }, [_renderMapContent, setMapBoxContentComponent])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="mall-management-page-container">
            <TopRowDefault title="Gestão do Mall" />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title-row">
                            <div className="icon">
                                <FontAwesomeIcon icon="store-alt" />
                            </div>
                            <div className="text">
                                <div className="title-text">{`${mall?.address?.city.state.uf} - ${mall?.name}`}</div>
                                <div className="mall-address">{`${mall?.address?.street}, ${mall?.address?.number}`}</div>
                            </div>
                        </div>

                        <div className="card-button-listing">
                            {links.map(
                                (item, index) =>
                                    item.isVisible && (
                                        <Link
                                            key={index}
                                            className="card-button-item"
                                            onClick={() => _toggleConfigModal(item.hasClick, item?.clickAction)}
                                            to={{
                                                pathname: item.destiny,
                                                state: { backButton: true },
                                            }}
                                        >
                                            <div className="icon">
                                                {item.isLogarooIcon ? (
                                                    item.img ?? <LogarooIcon icon={item.icon as ILogarooIcons} />
                                                ) : (
                                                    <FontAwesomeIcon icon={item.icon as IconProp} />
                                                )}
                                            </div>
                                            <div className="text">{item.label}</div>
                                        </Link>
                                    )
                            )}
                        </div>
                    </div>

                    <div className="right-column">
                        <MirrorMap />
                    </div>
                </div>
            </div>

            <ConfigAutoRouteModal isActive={configModalActive} closeClick={() => setConfigModalActive(false)} />
            <ModalDeliveryModality ref={modalDeliveryModalityRef} />
            <ModalConfigQueue ref={modalConfigQueueRef} />
            <PickerTaxModal ref={pickerTaxModalRef} />
        </div>
    )
}

export default memo(MallManagement)
