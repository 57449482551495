import { memo } from 'react'

import { InputItem } from 'components/_common'
import OrderSummary from 'components/_store-general-vision/order-summary'
import { DefaultButton } from 'components/default-button/default-button'
import { Grid } from 'components/grid/grid'
import { Text } from 'components/text/text'

import { useChangeTypeContent } from './change-type-content.controller'
import { Container, Primary, ExchangeIcon } from './change-type-content.styled'

const ChangeTypeContent = memo(() => {
    const { order, options, selectedType, setSelectedType, _close, _confirm } = useChangeTypeContent()

    return (
        <Container>
            <ExchangeIcon />
            <Text text="Alterar tipo de entrega" bold dimensions="large" align="center" marginBottom={20} />
            {!!order && <OrderSummary order={order as any} />}

            <Text
                text="Para Altetrar o tipo de entrega desse pedido, clique no select abaixo"
                variant="primary"
                align="center"
                dimensions="small"
                marginBottom={20}
            />
            <InputItem
                type="select"
                options={options}
                inputProps={{
                    value: selectedType,
                    onChange: e =>
                        setSelectedType(
                            e?.nativeEvent?.target?.value === 'undefined' ? undefined : e?.nativeEvent?.target?.value
                        ),
                }}
            />

            <Text align="center" dimensions="small" marginBottom={20}>
                Ao alterar o tipo de entrega, alguns <Primary>dados adicionais podem ser solicitados</Primary>, como
                também, taxas adicionais (como na mudança de balcão para delivery, por exemplo), etc.{' '}
                <Primary>Deseja continuar mesmo assim?</Primary>
            </Text>

            <Grid.Row className="container-buttons" justify="space-between" align="center">
                <DefaultButton title="Cancelar" outline variant="danger" onClick={_close} />
                <DefaultButton title="Selecionar" disabled={!selectedType} onClick={_confirm} />
            </Grid.Row>
        </Container>
    )
})

export { ChangeTypeContent }
