import { InputItem } from 'components/_common'

import {
    ContentContainer,
    ContentTitle,
    FormSection,
    FormSectionTitle,
    InputContainer,
    InputsRow,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
} from './request-collect-content.styles'

interface Props {
    closeClick(): void
}

const RequestCollectContent: React.FC<Props> = ({ closeClick }) => {
    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={closeClick}>
                    <CloseButton />
                </CloseContainer>
                <ContentTitle>Solicitar Coleta</ContentTitle>
                <FormSection>
                    <FormSectionTitle>Informações sobre o pedido</FormSectionTitle>
                    <InputsRow>
                        <InputContainer>
                            <InputItem
                                labelText="Canal de venda"
                                type="select"
                                options={[
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                ]}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem labelText="Número do pedido" />
                        </InputContainer>
                    </InputsRow>
                    <InputsRow>
                        <InputContainer>
                            <InputItem labelText="Nome do cliente" />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Bairro de entrega"
                                type="select"
                                options={[
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                ]}
                            />
                        </InputContainer>
                    </InputsRow>
                    <InputsRow>
                        <InputContainer>
                            <InputItem
                                labelText="Forma de Pagamento"
                                type="select"
                                options={[
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                    { label: 'value', value: 'Item' },
                                ]}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem labelText="Valor do pedido" />
                        </InputContainer>
                    </InputsRow>
                </FormSection>
                <FormSection>
                    <FormSectionTitle>Itens e observações</FormSectionTitle>
                    <InputItem type="textarea" labelText="Itens Solicitados" big />
                    <InputItem type="textarea" labelText="Observações sobre coleta" />
                </FormSection>
            </ContentContainer>
            <ButtonRow>
                <TextButton onClick={closeClick}>Cancelar</TextButton>
                <ConfirmButton>Solicitar coleta</ConfirmButton>
            </ButtonRow>
        </OutsideContainer>
    )
}

export default RequestCollectContent
