/*
 * It's important to also use a action reducer
 * which keeps track of the latest action for
 * this to work.
 */

import { useRef, useEffect, useCallback } from 'react'
import { useStore } from 'react-redux'

import { Action } from 'redux'

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
export function useReduxAction<ReduxAction = Action>(effect: (action: ReduxAction) => void, type: string): void {
    const currentValue = useRef(null)
    const store = useStore()

    const handleChange = useCallback(() => {
        const state = store.getState()
        const action = state.actions
        const previousValue = currentValue.current
        currentValue.current = action?.type

        if (previousValue !== action?.type && type === action?.type) {
            effect(action)
        }
    }, [effect, store, type])

    useEffect(() => {
        const unsubscribe = store.subscribe(handleChange)
        return () => unsubscribe()
    }, [handleChange, store])
}
