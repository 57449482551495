import { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class EmptyMessage extends Component {
    render() {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: this.props.bgColor,
                    paddingTop: 30,
                    paddingLeft: 20,
                    paddingRight: 20,
                }}
            >
                <div style={{ marginBottom: 20, color: '#979797', fontSize: 30 }}>
                    <FontAwesomeIcon icon={this.props.icon} />
                </div>
                {this.props.children}
            </div>
        )
    }
}
