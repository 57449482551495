import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { feature, featureCollection } from '@turf/turf'
import { format } from 'date-fns'
import tokml from 'geojson-to-kml'

import { EmptyListMessage } from 'components/_common'
import MapBoxView, { CircleLines, PolygonFill } from 'components/mapbox-view'
import Spinner from 'components/spinner/spinner'

import { formatCurrency } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import { PagedList, PaymentArea, StoreRateValues } from 'types'

import {
    BikeIcon,
    ChargeItem,
    ChargeTitle,
    ChargeValuesRow,
    ListingColumn,
    StoreChargeAreasContainer,
    ValueItem,
    ValueText,
    MotoIcon,
    ShiftContainer,
    ShiftTitle,
    SpinnerContainer,
    MapColumn,
    TitleRow,
    TitleText,
    ExportButton,
} from './store-charge-areas.styles'

import './tokml.d.ts'

export default function StoreChargeAreas(): JSX.Element {
    const { mall } = useAuth()
    const { data: areas, loading } = useDataFetching<PagedList<PaymentArea>>(`/painel/payment-areas/store`, {
        params: {
            per_page: -1,
            mall_id: mall.id,
        },
    })

    function download(filename: string, text: string) {
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', filename)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }

    function exportKML(paymentAreas: PaymentArea[]) {
        const geoJsonData = featureCollection(
            paymentAreas.filter(paymentArea => paymentArea.rates.length > 0).map(({ area }) => feature(area))
        )

        const kml = tokml(geoJsonData)
        const dateStamp = format(new Date(), 'DDMMYYYY - HHmm')

        download(`KML ${mall.name} - ${dateStamp}.kml`, kml)
    }

    return (
        <>
            <TitleRow>
                <TitleText>Áreas de Cobrança</TitleText>
                <ExportButton onClick={() => exportKML(areas?.items || ([] as PaymentArea[]))}>
                    Exportar KML
                </ExportButton>
            </TitleRow>
            <StoreChargeAreasContainer>
                <ListingColumn>
                    {loading ? (
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                    ) : (
                        <>
                            {areas?.items.length > 0 ? (
                                <>
                                    {areas?.items?.map((area, areaIndex) => (
                                        <>
                                            {area?.rates.length > 0 && (
                                                <ChargeItem key={areaIndex} areacolor={area.color}>
                                                    <ChargeTitle>{area.label}</ChargeTitle>
                                                    {area?.rates?.map((rate, rateIndex) => (
                                                        <ShiftContainer key={rateIndex}>
                                                            <ShiftTitle>
                                                                {rate.shifts.map((shift, shiftIndex) => (
                                                                    <span key={shiftIndex}>{`${shift.name}`}</span>
                                                                ))}
                                                            </ShiftTitle>
                                                            <ChargeValuesRow>
                                                                <ValueItem>
                                                                    <BikeIcon />
                                                                    <ValueText>
                                                                        {formatCurrency(
                                                                            (rate.values as StoreRateValues).bike
                                                                        )}
                                                                    </ValueText>
                                                                </ValueItem>
                                                                <ValueItem>
                                                                    <MotoIcon />
                                                                    <ValueText>
                                                                        {formatCurrency(
                                                                            (rate.values as StoreRateValues).moto
                                                                        )}
                                                                    </ValueText>
                                                                </ValueItem>
                                                            </ChargeValuesRow>
                                                        </ShiftContainer>
                                                    ))}
                                                </ChargeItem>
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : (
                                <EmptyListMessage message="Nao há áreas de cobrança configuradas" icon={faFileAlt} />
                            )}
                        </>
                    )}
                </ListingColumn>
                <MapColumn>
                    <MapBoxView zoom={11}>
                        <CircleLines id="circle-lines" center={[mall.address?.lng, mall.address?.lat]} />
                        <PolygonFill showPopUpArea id="payment-areas" areaType="payment-area" areas={areas?.items} />
                    </MapBoxView>
                </MapColumn>
            </StoreChargeAreasContainer>
        </>
    )
}
