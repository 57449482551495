import DesktopMenu from './desktop-menu/desktop-menu'
import { MainMenuContainer } from './main-menu.styles'
import MobileMenu from './mobile-menu/mobile-menu'

const MainMenu: React.FC = () => {
    return (
        <MainMenuContainer id="menuContainer">
            <MobileMenu />
            <DesktopMenu />
        </MainMenuContainer>
    )
}

export default MainMenu
