import styled from 'styled-components'

const TabsContainer = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
`

const TabItem = styled.div<{ isActive?: boolean }>`
    text-transform: uppercase;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export { TabsContainer, TabItem }
