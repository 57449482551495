import { useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { getFormInputError, getFormattedDateFilters } from 'helpers'
import api from 'services/api'
import { Mall, PagedList } from 'types'

import AppliedFilters from '../applied-filters'

import {
    ButtonsContainer,
    FiltersContainer,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    PeriodButton,
    InputsRow,
    MiddleContainer,
    PrimaryButton,
    SearchResultsTitle,
} from './bags-listing-header.styles'
import { selected_filters } from './mock-selected-filters'

export type TBagFilter = {
    mall_id?: number
    store_id?: number
    start_date?: string
    order_by?: string
    end_date?: string
    type?: string
}

interface Props {
    title?: string
    showFilters?: boolean
    total?: number
    filters?: TBagFilter
    onFilterData?(filters: TBagFilter): void
}

const optionsType = [
    {
        label: 'Retirada em Balcão',
        value: 'takeout',
    },
    {
        label: 'Delivery',
        value: 'delivery',
    },
    {
        label: 'Entrega em Mesa',
        value: 'indoor',
    },
]
const optionsOrderBy = [
    {
        label: 'Crescente',
        value: 'asc',
    },
    {
        label: 'Decrescente',
        value: 'desc',
    },
]

const BagsListingHeader: React.FC<Props> = ({ title, showFilters, total, filters, onFilterData }) => {
    const [malls, setMalls] = useState<Mall[]>([])

    const { values, errors, setValues, getFieldProps, handleSubmit, setFieldValue, touched } = useFormik({
        initialValues: {
            start_date: '',
            end_date: '',
            type: undefined,
            mall_id: undefined,
            order_by: 'desc',
            ...filters,
        },
        validationSchema: Yup.object().shape({
            mall_id: Yup.number().min(1, 'Selecione um mall').required('Selecione um mall'),
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
        }),
        onSubmit: async values => {
            console.log('values', values)

            if (onFilterData) {
                onFilterData({
                    ...values,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                })
            }
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    const _getMalls = useCallback(async () => {
        try {
            const result = await api.get<PagedList<Mall>>('/painel/malls', {
                params: { order_by: 'name', status: [1], per_page: 100 },
            })
            setMalls(result.data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    const _setValueSelect = useCallback(
        (field: string) => {
            return (event: React.ChangeEvent<HTMLInputElement>) => {
                const {
                    target: { value },
                } = event
                setFieldValue(field, value !== 'undefined' ? value : undefined)
            }
        },
        [setFieldValue]
    )

    useEffect(() => {
        _getMalls()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setValues({
            mall_id: filters.mall_id,
            type: filters.type,
            end_date: filters.end_date,
            start_date: filters.start_date,
            order_by: filters.order_by,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{title}</HeaderTitle>
                <MiddleContainer>
                    <InputsRow>
                        <InputContainer>
                            <InputItem
                                labelText="Mall"
                                type="select"
                                options={malls.map(mall => ({
                                    label: mall.name,
                                    value: mall.id,
                                }))}
                                inputProps={{
                                    ...getFieldProps('mall_id'),
                                    onChange: _setValueSelect('mall_id'),
                                }}
                                errorMessage={getFormInputError('mall_id', errors, touched)}
                            />
                        </InputContainer>

                        <InputContainer>
                            <InputItem
                                labelText="Data inicial"
                                type="date"
                                inputProps={getFieldProps('start_date')}
                                errorMessage={getFormInputError('start_date', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Data final"
                                type="date"
                                inputProps={getFieldProps('end_date')}
                                errorMessage={getFormInputError('end_date', errors, touched)}
                            />
                        </InputContainer>

                        <InputContainer>
                            <InputItem
                                labelText="Tipo"
                                type="select"
                                options={optionsType}
                                inputProps={{
                                    placeholder: 'Todos',
                                    ...getFieldProps('type'),
                                    onChange: _setValueSelect('type'),
                                }}
                                errorMessage={getFormInputError('type', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Ordenação"
                                type="select"
                                options={optionsOrderBy}
                                inputProps={{
                                    ...getFieldProps('order_by'),
                                    onChange: _setValueSelect('order_by'),
                                }}
                                errorMessage={getFormInputError('order_by', errors, touched)}
                            />
                        </InputContainer>
                        <PrimaryButton onClick={() => handleSubmit()}>Consultar</PrimaryButton>
                    </InputsRow>
                </MiddleContainer>
                <ButtonsContainer>
                    {periodFilters.map((filter, filterIndex) => (
                        <PeriodButton key={filterIndex} onClick={filter.onClick}>
                            {filter.label}
                        </PeriodButton>
                    ))}
                </ButtonsContainer>
                <FiltersContainer>
                    {showFilters && <AppliedFilters filters={selected_filters} />}
                    {Number(total) > 0 && (
                        <SearchResultsTitle>
                            Sua busca retornou <span>{total}</span> {total > 1 ? 'resultados' : 'resultado'}
                        </SearchResultsTitle>
                    )}
                </FiltersContainer>
            </HeaderContainer>
        </>
    )
}

export default BagsListingHeader
