import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { resettableReducer } from 'reduxsauce'

import { actionsReducer } from './actions'
import { agentsReducer } from './agents'
import { authReducer } from './auth'
import IntegrationsReducer from './integrations/integrations'
import { ordersInProgressReducer } from './ordersInProgress'
import { pendingOrdersReducer } from './pendingOrders'
import { routesReducer } from './routes'
import { utilsReducer } from './utils'

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['loading', 'loadingRefresh'],
    stateReconciler: autoMergeLevel2,
}
const integrationsPersistConfig = {
    key: 'integration',
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const resettable = resettableReducer('REMOVE_USER_DATA')
const setMallResettable = resettableReducer('SET_MALL')

const reducers = history =>
    combineReducers({
        router: connectRouter(history),
        actions: resettable(actionsReducer),
        agents: setMallResettable(resettable(agentsReducer)),
        auth: persistReducer(authPersistConfig, resettable(authReducer)),
        ordersInProgress: setMallResettable(resettable(ordersInProgressReducer)),
        pendingOrders: setMallResettable(resettable(pendingOrdersReducer)),
        routes: setMallResettable(resettable(routesReducer)),
        utils: resettable(utilsReducer),
        integrations: persistReducer(integrationsPersistConfig, resettable(IntegrationsReducer)),
    })

export default reducers
