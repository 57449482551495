import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { format } from 'date-fns'

import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { ContentHeader, ContentHeaderTitle } from 'containers/marketplace-management/marketplace-management.styles'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { IScheduledPeriod } from 'types/scheduledPeriods'

import { WeekDaysSelect } from '../../../../components/week-days-select/week-days-select'

import { PickerHoursModal, PickerHoursModalRef } from './components/picker-hours-modal/picker-hours-modal'
import { ButtonRow, Container, Content, DeleteIcon, PeriodContainer, PeriodItem } from './picker-hours.styled'

const PickerHours: FC<RouteComponentProps> = memo(({ history }) => {
    const { basePath, mall } = useAuth()
    const { setErrorModal, setLoading, setConfirmationModal } = useUI()
    const pickerHoursModalRef = useRef<PickerHoursModalRef>()

    const [schedules, setSchedules] = useState<IScheduledPeriod<'picker'>[]>()

    const _openModal = useCallback(() => {
        pickerHoursModalRef.current.show()
    }, [])

    const _openEdit = useCallback(
        (schedule: IScheduledPeriod<'picker'>) => () => {
            _openModal()
            pickerHoursModalRef.current.setSchedule(schedule)
        },
        []
    )

    const _getSchedules = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IScheduledPeriod<'picker'>>>('/painel/schedules/picker', {
                params: {
                    mall_id: mall.id,
                    type: 'picker',
                },
            })

            setSchedules(data.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível listar os horários de atendimento',
            })
        } finally {
            setLoading(false)
        }
    }, [mall])

    const _handleCloseModal = useCallback(
        (refresh?: boolean) => {
            if (refresh) {
                _getSchedules()
            }

            pickerHoursModalRef.current?.close()
        },
        [_getSchedules]
    )

    const _handleDelete = useCallback((id: number) => {
        const deleteSchedule = async () => {
            pickerHoursModalRef.current?.close()
            setLoading(true)

            try {
                await api.delete(`/painel/schedules/${id}`)

                _getSchedules()
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir a horário de funcionamento!',
                })
            }
            setLoading(false)
        }

        return (): void => {
            setConfirmationModal({
                title: 'Excluir horário de funcionamento',
                subtitle: 'Tem certeza de que deseja excluir este horário de funcionamento?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: deleteSchedule,
            })
        }
    }, [])

    useEffect(() => {
        _getSchedules()
    }, [_getSchedules])

    return (
        <Container>
            <TopRowDefault
                title="Horários de Atendimento do Picker"
                onBackButtonClick={() => history.replace(`${basePath}/gestao-mall`)}
                buttons={[
                    {
                        title: 'Criar Horário',
                        //icon: 'trash-alt',
                        onClick: _openModal,
                    },
                ]}
            />
            <PickerHoursModal ref={pickerHoursModalRef} onClose={_handleCloseModal} />
            {/* <ContentHeader>
                <ContentHeaderTitle>Horários de Atentimento do Picker</ContentHeaderTitle>
                <DefaultButton widthFitContainer onClick={_openModal}>
                    Criar Horário
                </DefaultButton>
            </ContentHeader> */}
            <Content>
                <FlexTable
                    columns={[
                        { name: 'ID', width: '10%', style: { display: 'flex', justifyContent: 'start' } },
                        { name: 'Nome', width: '15%', style: { display: 'flex', justifyContent: 'center' } },
                        {
                            name: 'Período',
                            width: '15%',
                            style: { display: 'flex', justifyContent: 'center', textAlign: 'center' },
                        },
                        { name: 'Dias das Semana', width: '25%', style: { display: 'flex', justifyContent: 'center' } },
                        // { name: 'Status', width: '10%' },
                        { name: '', width: '15%' },
                    ]}
                    list={schedules?.map(schedule => ({
                        id: String(schedule.id),
                        name: schedule.label,
                        period: (
                            <PeriodContainer>
                                {schedule.start_date && schedule.end_date && (
                                    <PeriodItem>
                                        {format(schedule.start_date, 'DD/MM/YYYY')} até{' '}
                                        {format(schedule.end_date, 'DD/MM/YYYY')}
                                    </PeriodItem>
                                )}
                                <PeriodItem>
                                    {schedule.start_time?.substring(0, 5)} às {schedule.end_time?.substring(0, 5)}
                                </PeriodItem>
                            </PeriodContainer>
                        ),
                        days_on_the_week: <WeekDaysSelect weekdays={schedule.week_days} />,
                        // status: <SwitchButton isActive />,
                        buttonRow: (
                            <ButtonRow>
                                <DefaultButton title="Editar" widthFitContainer outline onClick={_openEdit(schedule)} />
                                <DefaultButton widthFitContainer variant="danger" onClick={_handleDelete(schedule.id)}>
                                    <DeleteIcon />
                                </DefaultButton>
                            </ButtonRow>
                        ),
                    }))}
                />
            </Content>
        </Container>
    )
})

export { PickerHours }
