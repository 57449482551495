import { memo, useCallback, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { PaymentsContext } from 'contexts/payments-context'
import { getFormInputError, showErrors } from 'helpers'
import { plugApi } from 'services/api'
import { getIntegrationName } from 'utils/get-integration-name'

import {
    ContainerCheck,
    Check,
    LabelCheckBox,
    Row,
    CloseButton,
    CloseContainer,
    ConfirmButton,
    TextButton,
    ButtonRow,
    InputContainer,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
} from './manager-payments-content.styled'

export interface Form {
    origin?: {
        name: string
        value: string
    }
    logaroo?: {
        name: string
        value: string
    }
    offline?: boolean
    type: string
    status: boolean
}

interface Props {
    closeClick(isRefresh?: boolean): void
    isActive: boolean
}

const ManagerPaymentsContent: React.FC<Props> = memo(({ closeClick, isActive }) => {
    const { paymentsPlooga, payments, type, edit, refresh } = useContext(PaymentsContext)

    const { setSuccessModal, setErrorModal, setConfirmationModal } = useUI()

    const {
        values,
        errors,
        setValues,
        setFieldValue,
        setFieldTouched,
        getFieldProps,
        handleSubmit,
        resetForm,
        touched,
    } = useFormik<Form>({
        initialValues: {
            type: type,
            status: true,
        },
        validationSchema: Yup.object().shape({
            logaroo: Yup.object().required('Forma de pagamento da Origen é obrigatório'),
            origin: Yup.object().required('Forma de pagamento Logaroo é obrigatório'),
            status: Yup.boolean().required('Status é obrigatório'),
        }),
        onSubmit: async ({ logaroo, origin, offline, ...values }, { resetForm }) => {
            try {
                let originValue

                if (type === 'ifood') {
                    if (offline) {
                        originValue = origin.value.toString() + '_OFFLINE'
                    } else {
                        originValue = origin.value.toString() + '_ONLINE'
                    }
                } else {
                    originValue = origin.value.toString()
                }
                if (edit?.id) {
                    await plugApi.put(`/payment-methods/${edit.id}`, {
                        ...values,
                        origin: {
                            name: origin.name,
                            value: originValue,
                        },
                        logaroo: {
                            name: logaroo.name,
                            value: logaroo.value.toString(),
                        },
                    })
                    setSuccessModal({
                        title: 'Forma de Pagamento',
                        subtitle: 'A origem foi atualizada com sucesso',
                    })
                } else {
                    await plugApi.post(`/payment-methods`, {
                        ...values,
                        origin: {
                            name: origin.name,
                            value: originValue,
                        },
                        logaroo: {
                            name: logaroo.name,
                            value: logaroo.value.toString(),
                        },
                    })

                    setSuccessModal({
                        title: 'Forma de Pagamento',
                        subtitle: 'A Forma de Pagamento atualizada',
                    })
                }
                resetForm()
                refresh()
                if (closeClick) {
                    closeClick(true)
                }
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao alterar status',
                    subtitle: showErrors(error),
                })
            }
        },
    })

    const _remove = useCallback(() => {
        if (edit) {
            const callback = async () => {
                try {
                    await plugApi.delete(`/payment-methods/${edit.id}`)
                    setSuccessModal({
                        title: 'Vinculação removida',
                        subtitle: 'A vinculação foi removida com sucesso',
                    })
                    resetForm()
                    refresh()
                    if (closeClick) {
                        closeClick()
                    }
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao alterar status',
                        subtitle: showErrors(error),
                    })
                }
            }
            setConfirmationModal({
                title: 'Remover Vinculação',
                subtitle: 'Deseja Realmente remover está vinculação?',
                leftButtonText: 'Não',
                rightButtonText: 'Sim',
                rightButtonClick: callback,
            })
        }
    }, [edit, setConfirmationModal, setSuccessModal, closeClick, resetForm, refresh, setErrorModal])

    const _onClose = useCallback(() => {
        resetForm()

        if (closeClick) {
            closeClick()
        }
    }, [closeClick, resetForm])

    useEffect(() => {
        if (edit) {
            const origin = { ...edit.origin }
            let isOffline = false
            if (type === 'ifood') {
                if (origin.value.includes('_OFFLINE')) {
                    isOffline = true
                }
                origin.value = origin.value.replace('_ONLINE', '').replace('_OFFLINE', '')
            }
            setValues({
                origin: origin,
                logaroo: edit.logaroo,
                type: edit.type,
                status: edit.status,
                offline: isOffline,
            })
        } else {
            setValues({
                type: type,
                status: true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    useEffect(() => {
        if (!isActive) {
            resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isActive) {
        return null
    }

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={_onClose}>
                    <CloseButton />
                </CloseContainer>
                <ContentTitle>Vincular Pagamentos</ContentTitle>
                <FormSection>
                    <InputContainer>
                        <InputItem
                            labelText="Forma de Pagamento Logaroo"
                            name="select-payment"
                            type="select"
                            options={payments.map(item => ({ label: item.name, value: item.value }))}
                            inputProps={{
                                placeholder: 'Selecione uma forma de pagamento',
                                ...getFieldProps('logaroo'),
                                value: values.logaroo?.value,
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(
                                        'logaroo',
                                        payments.find(item => item.value.toString() === value)
                                    )
                                    setFieldTouched('logaroo', true)
                                },
                            }}
                            errorMessage={getFormInputError('logaroo', errors, touched)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText={'Forma de Pagamento ' + getIntegrationName(type)}
                            name="select-payment"
                            type="select"
                            options={paymentsPlooga.map(item => ({ label: item.name, value: item.value }))}
                            inputProps={{
                                placeholder: 'Selecione uma forma de pagamento',
                                ...getFieldProps('origin'),
                                value: values.origin?.value,
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(
                                        'origin',
                                        paymentsPlooga.find(item => item.value.toString() === value)
                                    )
                                    setFieldTouched('origin', true)
                                },
                            }}
                            errorMessage={getFormInputError('origin', errors, touched)}
                        />
                    </InputContainer>
                    {type === 'ifood' && (
                        <ContainerCheck onClick={() => setFieldValue('offline', !values.offline)}>
                            <Check>{values.offline && <FontAwesomeIcon icon="check" />}</Check>
                            <LabelCheckBox>Pagamento no local</LabelCheckBox>
                        </ContainerCheck>
                    )}
                    <InputContainer>
                        <InputItem
                            labelText="Status"
                            type="select"
                            options={[
                                { label: 'Ativo', value: 'true' },
                                { label: 'Inativo', value: 'false' },
                            ]}
                            inputProps={{
                                placeholder: 'Selecione um status',
                                ...getFieldProps('status'),
                                value: values.status.toString(),
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('status', value === 'true')
                                    setFieldTouched('status', true)
                                },
                            }}
                            errorMessage={getFormInputError('status', errors, touched)}
                        />
                    </InputContainer>
                </FormSection>
            </ContentContainer>
            <ButtonRow>
                <div>
                    {!!edit?.id && (
                        <TextButton isRemove onClick={_remove}>
                            <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                        </TextButton>
                    )}
                </div>
                <Row>
                    <TextButton onClick={_onClose}>Cancelar</TextButton>
                    <ConfirmButton onClick={() => handleSubmit()}>Salvar</ConfirmButton>
                </Row>
            </ButtonRow>
        </OutsideContainer>
    )
})

export { ManagerPaymentsContent }
