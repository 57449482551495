/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from 'react'

import { Order } from 'types'

type Params = {
    order: Order
    onSelectReason(reason: string): void
}

type Reason = {
    title: string
    code: number
}

type TypeButton = 'yes' | 'no'

interface Props {
    onClose?(success?: boolean): void
    onNoClick?(): void
    onYesClick?(): void
}

function useModalOrderCancelCTL({ onClose }: Props) {
    const modalShowRef = useRef<boolean>()

    const [reason, setReason] = useState<Reason>()
    const [order, setOrder] = useState<Order>()
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)
    const [callback, setCallback] = useState<{ exec(reason: string): void }>({ exec: () => {} })

    const _setShow = useCallback(({ order, onSelectReason }: Params) => {
        setOrder(order)
        setCallback({ exec: onSelectReason })
        setShow(true)
    }, [])

    const _onClose = useCallback(
        (success?: boolean) => {
            setShow(false)
            setOrder(null)
            setReason(null)
            setCallback({ exec: () => {} })
            if (onClose) {
                onClose(success)
            }
        },
        [onClose]
    )

    const _setOptionCancel = useCallback((reason: Reason) => {
        setReason(reason)
    }, [])

    const _onButtonClick = useCallback(
        (type: TypeButton) => {
            return async () => {
                if (type === 'no') {
                    _onClose(false)
                }
                if (type === 'yes') {
                    callback.exec(reason.title)
                    _onClose(false)
                }
            }
        },
        [callback, reason, _onClose]
    )

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setVisible(false)
            setOrder(null)
            setReason(null)
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    return {
        visible,
        show,
        order,
        reason,
        _setOptionCancel,
        _onButtonClick,
        _setShow,
    }
}

export { useModalOrderCancelCTL }
