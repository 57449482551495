import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EmptyMessage from '../empty-message/empty-message'
import Spinner from '../spinner/spinner'

import './style.scss'

const ChargeAreasListingView = ({ loading, data, goToEdit, title }) => {
    if (loading) {
        return (
            <div className="coverage-listing-row-container" style={{ display: 'flex' }}>
                <Spinner />
            </div>
        )
    }

    if (data.length === 0) {
        return (
            <div className="coverage-listing-row-container centered-payment-text" style={{ display: 'flex' }}>
                <EmptyMessage icon="map">Não há áreas de cobrança cadastradas para {title}</EmptyMessage>
            </div>
        )
    }

    return (
        <div className="coverage-listing-row-container">
            <div className="row-title-container" style={{ padding: 0 }}>
                <div className="payment-area-title-row-container">
                    <div className="payment-area-title-row__title">{title}</div>
                    <div className="payment-area-title-row__amount">
                        {` ${data.length} ${data.length === 1 ? `área criada` : `áreas criadas`}`}
                    </div>
                </div>
            </div>
            <div className="areas-listing">
                <div>
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className="coverage-area-row"
                            style={{
                                borderLeftColor: item.color,
                            }}
                        >
                            <div className="coverage-area-info">
                                <div>
                                    <div className="black-text name">{item.label}</div>
                                    <span className="coverage-status">
                                        {item.status === 1 ? 'Ativa' : 'Inativa'}
                                        {/* {item.marketplace_enable ? ' | Atende Mercadoo' : ''} */}
                                    </span>
                                </div>

                                <div className="black-text edit remove-content">
                                    {item.need_moderation === true && (
                                        <FontAwesomeIcon icon="exclamation-triangle" style={{ marginRight: 15 }} />
                                    )}
                                    <FontAwesomeIcon icon="edit" onClick={() => goToEdit(item.id)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ChargeAreasListingView
