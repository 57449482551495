import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { Establishment, PosConfiguration } from 'containers/stone-management/types'

import { formatPhone, formatCnpj, formatCpf, objectIsEmpty, showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'
import stoneBanks from 'utils/stoneBanks'

const InfoItem: React.FC<{ label: string; value?: string }> = ({ label, value }) => {
    return (
        <div className="info-item">
            <div className="label">{label}</div>
            <div className="value">{value || '-'}</div>
        </div>
    )
}

const EstablishmentView: React.FC<RouteComponentProps<{ establishment_id: string }>> = ({
    history,
    match: { params },
}) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [establishment, setEstablishment] = useState<Establishment>()
    const [posConfiguration, setPosConfiguration] = useState<PosConfiguration>()
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const getEstablishment = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get(`/establishment/get-establishment/${params.establishment_id}`)

            if (data.establishment.establishment_is_active) {
                const posConfigResponse = await siclosPagApi.get(
                    `/pos-configuration/get-config/${params.establishment_id}`
                )

                setPosConfiguration(posConfigResponse.data.pos_configuration)
            }

            if (data?.error) {
                throw data.msg
            }

            setEstablishment(data.establishment)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history, params.establishment_id])

    useEffect(() => {
        getEstablishment()
    }, [getEstablishment])

    async function updatePosConfig(pos_configuration: PosConfiguration) {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.put(`/pos-configuration/update-config`, {
                profile_id: params.establishment_id,
                ...pos_configuration,
            })

            if (data?.error) {
                throw data.msg
            }

            setPosConfiguration(data.pos_configuration)

            setModalMessageData({
                title: 'Configuração alterada',
                message: 'Configuração alterada com sucesso!',
            })

            modalMessage.current?.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }

    function formatPercentage(value: string | number | undefined): string | undefined {
        return value ? `${value.toString().replace('.', ',')}%` : undefined
    }

    function getStoneBank(bankCode: string | undefined): string {
        const bank = stoneBanks.find(bank => bank.value === Number(bankCode))

        return (bank && `${bank.value} - ${bank.name}`) || ''
    }

    return (
        <div>
            <RowTitle
                title="Detalhes do estabelecimento"
                backClick={() => history.push('/painel-malls/gestao-stone')}
            />

            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="info-view-container">
                <div className="info-row">
                    <InfoItem label="Razão social" value={establishment?.legal_name} />
                    <InfoItem label="Nome fantasia" value={establishment?.business_name} />
                    <InfoItem
                        label={establishment?.is_cnpj ? 'CNPJ' : 'CPF'}
                        value={
                            establishment?.is_cnpj
                                ? formatCnpj(establishment?.document_number)
                                : formatCpf(establishment?.document_number)
                        }
                    />

                    {posConfiguration && (
                        <div className="radio-group">
                            <label>Exigir senha para sair do aplicativo?</label>
                            <div className="radio-group-row">
                                <InputRadio
                                    id="true-require_password_for_app_exit"
                                    label="Sim"
                                    checked={posConfiguration.require_password_for_app_exit}
                                    onClick={() =>
                                        updatePosConfig({
                                            ...posConfiguration,
                                            require_password_for_app_exit: true,
                                        })
                                    }
                                />

                                <InputRadio
                                    id="false-require_password_for_app_exit"
                                    label="Não"
                                    checked={!posConfiguration.require_password_for_app_exit}
                                    onClick={() =>
                                        updatePosConfig({
                                            ...posConfiguration,
                                            require_password_for_app_exit: false,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="info-row">
                    <InfoItem label="Stone code" value={establishment?.stone_code} />
                    <InfoItem
                        label="Status"
                        value={
                            establishment?.establishment_is_active
                                ? establishment?.establishment_is_active
                                    ? 'Ativo'
                                    : 'Inativo'
                                : undefined
                        }
                    />
                    <InfoItem
                        label="Ativo para produção?"
                        value={
                            establishment?.is_establishment_to_production
                                ? establishment?.is_establishment_to_production
                                    ? 'Sim'
                                    : 'Não'
                                : undefined
                        }
                    />
                    <InfoItem
                        label="Lançado no Mamba?"
                        value={
                            establishment?.mamba_released ? (establishment?.mamba_released ? 'Sim' : 'Não') : undefined
                        }
                    />
                    <InfoItem
                        label="Split habilitado?"
                        value={
                            establishment?.enabled_split ? (establishment?.enabled_split ? 'Sim' : 'Não') : undefined
                        }
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Endereço
                </div>

                <div className="info-row">
                    <InfoItem label="Logradouro" value={establishment?.address.street_name} />
                    <InfoItem label="Número" value={establishment?.address.number} />
                    <InfoItem label="Complemento" value={establishment?.address.complement} />
                    <InfoItem label="Bairro" value={establishment?.address.neighborhood} />
                    <InfoItem label="CEP" value={establishment?.address.zip_code} />
                    <InfoItem label="Cidade" value={establishment?.address.city} />
                    <InfoItem label="Estado" value={establishment?.address.state} />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Cartões
                </div>

                {establishment?.cards_rate
                    .filter(item => !objectIsEmpty(item))
                    .map(item => (
                        <div className="info-row" key={item.type_id}>
                            <div className="card-rate-type">{item.type}</div>

                            <InfoItem label="Visa" value={formatPercentage(item.visa)} />
                            <InfoItem label="Mastercard" value={formatPercentage(item.master)} />
                            <InfoItem label="Elo" value={formatPercentage(item.elo)} />
                            <InfoItem label="Hiper" value={formatPercentage(item.hiper)} />
                            <InfoItem label="Amex" value={formatPercentage(item.amex)} />
                        </div>
                    ))}

                <div className="title" style={{ marginTop: '20px' }}>
                    Banco
                </div>

                <div className="info-row">
                    <InfoItem label="Banco" value={getStoneBank(establishment?.bank.bank_code)} />
                    <InfoItem
                        label="Agência"
                        value={
                            establishment?.bank.agency &&
                            `${establishment?.bank.agency}-${establishment?.bank.agency_dv}`
                        }
                    />
                    <InfoItem
                        label="Conta"
                        value={
                            establishment?.bank.account &&
                            `${establishment?.bank.account}-${establishment?.bank.account_dv}`
                        }
                    />
                    <InfoItem
                        label="Tipo de conta"
                        value={
                            establishment?.bank.account_type &&
                            (establishment?.bank.account_type === '1' ? 'Corrente' : 'Poupança')
                        }
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Antecipação
                </div>

                <div className="info-row">
                    <InfoItem
                        label="Automática?"
                        value={
                            establishment?.anticipation.is_automatic
                                ? establishment?.anticipation.is_automatic
                                    ? 'Sim'
                                    : 'Não'
                                : undefined
                        }
                    />
                    <InfoItem
                        label="Taxa da antecipação automática"
                        value={formatPercentage(establishment?.anticipation.automatic_rate)}
                    />
                    <InfoItem
                        label="Taxa da antecipação pontual"
                        value={formatPercentage(establishment?.anticipation.spot_rate)}
                    />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Contato
                </div>

                <div className="info-row">
                    <InfoItem label="Nome" value={establishment?.contact.name} />
                    <InfoItem label="Telefone" value={formatPhone(establishment?.contact.phone, false)} />
                    <InfoItem label="Celular" value={formatPhone(establishment?.contact.cell_phone, false)} />
                </div>

                <div className="title" style={{ marginTop: '20px' }}>
                    Responsável
                </div>

                <div className="info-row">
                    <InfoItem label="Nome" value={establishment?.responsible.name} />
                    <InfoItem label="Documento" value={establishment?.responsible.document_number} />
                </div>
            </div>
        </div>
    )
}

export default EstablishmentView
