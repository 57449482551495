import { useEffect, useState, useCallback, useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { RouteComponentProps } from 'react-router-dom'

import { format } from 'date-fns'

import CheckboxInput from 'components/checkbox-input'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalLoading from 'components/modal-loading'
import ModalMessage from 'components/modal-message'
import PerformancePageTopRow from 'components/performance-page-top-row/performance-page-top-row'
import Spinner from 'components/spinner/spinner'

import { formatCurrency, showErrors, setSearchParams } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import colors from 'themes/colors'

import './style.scss'
import { ChartObject } from 'types'

interface MallMongo {
    _id: string
    id: string
    mall_id: number
    name: string
}
interface StoreMongo {
    _id: string
    id: string
    mall_id: number
    name: string
    store_id: number
}

interface InfoItem {
    title: string
    subtitle?: string
    value: number | string
}

interface Report {
    tracking_chart: ChartObject
    qtd_info_items: InfoItem[]
    amount_info_items: InfoItem[]
}

const PerformanceGlobal: React.FC<RouteComponentProps> = ({ location, history }) => {
    const { mall, user } = useAuth()

    const initialFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        const stores = query.get('stores')

        return {
            mall_id: user.isShopkeeper ? mall.id : query.get('mall_id') || '',
            stores: stores?.length ? stores.split(',') : ([] as string[]),
            period_type: query.get('period_type') || '',
            period: query.get('period') || '',
            start_hour: query.get('start_hour') || '',
            end_hour: query.get('end_hour') || '',
        }
    }, [location.search, mall, user])

    const [loading, setLoading] = useState(false)
    const [loadingReport, setLoadingReport] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [malls, setMalls] = useState<MallMongo[]>([])
    const [filter, setFilter] = useState(initialFilter)
    const [stores, setStores] = useState<StoreMongo[]>([])
    const [report, setReport] = useState<Report>()

    const getReport = useCallback(
        async ({ filter = initialFilter } = {}) => {
            try {
                setLoadingReport(true)
                if (!filter.mall_id.length || !filter.period_type.length || !filter.period.length) return

                const filters = {
                    mall_id: filter.mall_id || undefined,
                    stores: filter.stores.length ? filter.stores : undefined,
                    period: filter.period,
                    start_hour: filter.start_hour ? `${filter.start_hour}` : undefined,
                    end_hour: filter.end_hour ? `${filter.end_hour}` : undefined,
                }

                const { data } = await api.get(
                    `painel/report/${filter.period_type === 'year' ? 'annual-attendance' : 'monthly-attendance'}`,
                    {
                        params: {
                            ...filters,
                        },
                    }
                )

                setReport({
                    qtd_info_items: [
                        { title: 'Quantidade de pedidos', subtitle: '', value: data.total_orders },
                        { title: 'Quantidade de entregadores', subtitle: '', value: data.total_bikers },
                    ],
                    amount_info_items: [
                        { title: 'Valor em vendas', subtitle: '', value: formatCurrency(data.amount_total) },
                        { title: 'Valor em frete a receber', subtitle: '', value: formatCurrency(data.incoming_total) },
                        { title: 'Valor em frete a pagar', subtitle: '', value: formatCurrency(data.payable_total) },
                    ],
                    tracking_chart: {
                        data: {
                            labels: data.periods?.map((item: string) =>
                                filter.period_type === 'year' ? format(item, 'MM/YYYY') : format(item, 'DD/MM')
                            ),
                            datasets: data.stores?.map(
                                (store: { name: string; qtd_orders: number[] }, index: number) => ({
                                    label: store.name,
                                    data: store.qtd_orders,
                                    fill: false,
                                    backgroundColor: `hsl(${index * (360 / data.stores.length)}, 50%, 50%)`,
                                    borderColor: `hsl(${index * (360 / data.stores.length)}, 50%, 50%)`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    lineTension: 0,
                                })
                            ),
                        },
                    },
                })
            } catch (error) {
                setModalMessage(showErrors(error))
            } finally {
                setLoadingReport(false)
            }
        },
        [initialFilter]
    )

    useEffect(() => {
        getReport()
    }, [getReport])

    const getMalls = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/malls-mongo')

            setMalls(data.map((item: MallMongo) => ({ ...item, id: item._id })))
        } catch (error) {
            setModalMessage(showErrors(error))
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        !user.isShopkeeper && getMalls()
    }, [getMalls, user.isShopkeeper])

    const getStores = useCallback(async mall_id => {
        try {
            setLoading(true)

            const { data } = await api.get('/painel/stores-mongo', {
                params: { mall_id },
            })

            setStores(data.map((item: StoreMongo) => ({ ...item, id: item._id })))
        } catch (error) {
            setModalMessage(showErrors(error))
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        !user.isShopkeeper && filter.mall_id && getStores(filter.mall_id)
    }, [getStores, filter.mall_id, user.isShopkeeper])

    function onChangeStores(store_id: string, checked: boolean) {
        if (checked) {
            setFilterParam('stores', [...(filter.stores as string[]), store_id])
        } else {
            setFilterParam(
                'stores',
                (filter.stores as string[]).filter(id => id !== store_id)
            )
        }
    }

    function setFilterParam(key: string, value: string | Date | number | string[]) {
        setFilter({ ...filter, [key]: value })
    }

    const initialSelectInputs = [
        {
            placeholder: 'Selecione um Mall',
            data: malls,
            onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => setFilterParam('mall_id', value),
            value: filter.mall_id,
        },
        {
            placeholder: 'Tipo de período',
            data: [
                { name: 'Mensal', value: 'month' },
                { name: 'Anual', value: 'year' },
            ],
            onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                setFilterParam('period_type', value)
            },
            value: filter.period_type,
        },
    ]

    const selectInputFilters =
        filter.period_type === 'year'
            ? [
                  ...initialSelectInputs,
                  {
                      placeholder: 'Período',
                      data: Array(new Date().getFullYear() - 2020 + 1)
                          .fill(2020)
                          .map((year, index) => ({ name: year + index, value: year + index })),
                      onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                          setFilterParam('period', value)
                      },
                      value: filter.period,
                  },
              ]
            : initialSelectInputs

    const initialTextInputs = [
        {
            label: 'Hora inicial*',
            type: 'time',
            onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setFilterParam('start_hour', value)
            },
            value: filter.start_hour,
        },
        {
            label: 'Hora final*',
            type: 'time',
            onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setFilterParam('end_hour', value)
            },
            value: filter.end_hour,
        },
    ]

    const textInputFilters =
        filter.period_type === 'month'
            ? [
                  {
                      placeholder: 'Período',
                      type: 'month',
                      onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                          setFilterParam('period', value)
                      },
                      value: filter.period,
                  },
                  ...initialTextInputs,
              ]
            : initialTextInputs

    return (
        <div className="performance-page-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                isActive={Boolean(modalMessage.length)}
                message={modalMessage}
                title={'Erro'}
                textButton={'Fechar'}
                onClose={() => setModalMessage('')}
            />

            <PerformancePageTopRow
                title="Performance - Global"
                selectInputFilters={!user.isShopkeeper ? selectInputFilters : []}
                textInputFilters={textInputFilters}
                onFilterClick={() => {
                    setSearchParams({ ...filter }, location, history, getReport)
                }}
            />

            <div className="performance-content-container">
                <div className="mid-container">
                    <div className="box mid">
                        {Number(stores?.length) > 0 ? (
                            <>
                                <div className="title">Lojas</div>

                                <div className="stores-checkboxes-container">
                                    {stores?.map(store => (
                                        <CheckboxInput
                                            key={store.id}
                                            id={store.id.toString()}
                                            label={store.name}
                                            onChange={({ target: { checked } }) => onChangeStores(store.id, checked)}
                                            checked={(filter.stores as string[])?.some(id => id === store.id)}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <EmptyMessage icon="store">
                                {filter.mall_id && !loading
                                    ? 'Não foram encontradas lojas nesse Mall'
                                    : 'Selecione um Mall para continuar'}
                            </EmptyMessage>
                        )}
                    </div>
                    <div className="box full">
                        {loadingReport ? (
                            <Spinner color={colors.primary_color} size={10} />
                        ) : (
                            <>
                                <div className="title">Gráfico de acompanhamento</div>
                                <div className="subtitle">Acompanhamento de acordo com os filtros</div>

                                <div className="chart-container">
                                    <Line height={120} data={{ ...report?.tracking_chart.data }} />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="first-row">
                    <div className="box mid">
                        {loadingReport ? (
                            <Spinner color={colors.primary_color} size={10} />
                        ) : (
                            report?.qtd_info_items.map((item, index) => (
                                <div className="info-item" key={index}>
                                    <div className="text">
                                        <div className="title">{item.title}</div>
                                        <div className="subtitle">{item.subtitle}</div>
                                    </div>
                                    <div className="number">{item.value}</div>
                                </div>
                            ))
                        )}
                    </div>
                    <div className="box mid">
                        {loadingReport ? (
                            <Spinner color={colors.primary_color} size={10} />
                        ) : (
                            report?.amount_info_items.map((item, index) => (
                                <div className="info-item" key={index}>
                                    <div className="text">
                                        <div className="title">{item.title}</div>
                                        <div className="subtitle">{item.subtitle}</div>
                                    </div>
                                    <div className="number">{item.value}</div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerformanceGlobal
