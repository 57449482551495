import styled, { css } from 'styled-components'

const Container = styled.div<{ hasCopy?: boolean; simple?: boolean }>`
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    transition: box-shadow 500ms;
    width: fit-content;
    padding: ${({ simple }) => (simple ? 2 : 8)}px;
    background-color: #ececec;
    user-select: none;
    ${({ hasCopy }) =>
        hasCopy &&
        css`
            .content {
                transition: border 500ms;
                border-width: 2px;
                border-color: ${({ theme }) => theme.colors.success};
            }
            .icon {
                transition: background 500ms;
                background-color: ${({ theme }) => theme.colors.success};
                color: #fff !important;
            }
        `}

    &:hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        ${({ hasCopy }) =>
            !hasCopy &&
            css`
                .content {
                    transition: border 500ms;
                    border-color: ${({ theme }) => theme.colors.primary};
                }
                .icon {
                    transition: background 500ms;
                    background-color: ${({ theme }) => theme.colors.primary};
                    color: #fff;
                }
            `}
    }
`

const Content = styled.div<{ simple?: boolean }>`
    flex: 1;
    align-items: center;
    padding: ${({ simple }) => (simple ? '2px' : '4px 8px')};
    background-color: #fff;
    border-radius: 8px;
    border: #fff solid 2px;
    display: flex;
    overflow: hidden;
`

const ContainerIcon = styled.div<{ simple?: boolean }>`
    border-radius: 4px;
    padding: 4px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    ${({ simple }) =>
        simple &&
        css`
            width: 10px;
            height: 10px;
            font-size: 12px;
        `}
`

const Label = styled.div`
    color: #a0a0a0;
    font-weight: bold;
    margin-right: 4px;
    font-size: 14px;
`

const Path = styled.div<{ simple?: boolean }>`
    color: #333;
    font-size: ${({ simple }) => (simple ? 10 : 14)}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const Message = styled.div<{ show?: boolean }>`
    color: ${({ theme }) => theme.colors.success};
    font-size: 11px;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: opacity 400ms;
    margin-left: 2px;
`
const Link = styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    text-align: end;
    width: fit-content;
    margin-left: auto;
    font-size: 12px;
    &:hover {
        text-decoration: underline;
    }
    span {
        margin-right: 5px;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 20px;
`
export { Container, Content, ContainerIcon, Label, Path, Message, Link, Row }
