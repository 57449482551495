import { useContext, useEffect, useRef, memo } from 'react'
import { useState, useCallback } from 'react'
import { useHistory } from 'react-router'

import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { RoutesContext } from 'contexts'
import { useUI } from 'contexts'
import { ModalRouteFinish } from 'modals/modal-route-cancel/modal-route-cancel'

import { EmptyListMessage, LoadingMessage } from 'components/_common'
import RoutesRunningList from 'components/routes-running-list/routes-running-list'

import { showErrors } from 'helpers'
import { useAuth } from 'hooks'

import api from '../../services/api'

import {
    Container,
    ContainerCounter,
    IconCounter,
    TitleCounter,
    SearchContainer,
    SearchInput,
    SearchIcon,
    Row,
} from './operation-routes-in-progress.styled'

const OperationRoutesInProgress = memo(
    ({ clickedRouteOrder = undefined, hoverRoute = undefined, setHoverRoute = undefined }) => {
        const timeoutRef = useRef()
        const history = useHistory()
        const { basePath } = useAuth()
        const [loader, setLoader] = useState(false)
        const [filteredRoutes, setFilteredRoutes] = useState([])
        const [search, setSearch] = useState('')

        const { loading, routes, refresh } = useContext(RoutesContext)
        const { setSuccessModal, setErrorModal } = useUI()

        const modalRouteFinishRef = useRef(null)

        const _finishRoute = useCallback(
            async (route_id, reason) => {
                setLoader(true)

                try {
                    await api.post(`/painel/route/finish`, { route_id, reason })

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Rota finalizada com sucesso!',
                    })
                    if (refresh) {
                        refresh()
                    }
                } catch (error) {
                    console.log('error', error)
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                }
                setLoader(false)
            },
            [setErrorModal, setSuccessModal, refresh]
        )

        const _confirmFinish = useCallback(
            route => {
                modalRouteFinishRef.current?.show({
                    route,
                    onSelectReason: reason => {
                        _finishRoute(route.id, reason)
                    },
                })
            },
            [_finishRoute]
        )

        const _filterRoute = useCallback(
            search => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                }

                timeoutRef.current = setTimeout(() => {
                    if (search) {
                        const filtered = routes.filter(item => {
                            if (
                                (item.agent?.first_name || '').toLowerCase().includes(search.toLowerCase()) ||
                                (item.agent?.last_name || '').toLowerCase().includes(search.toLowerCase())
                            ) {
                                return true
                            }

                            if (item.orders) {
                                for (let i = 0; i < item.orders.length; i++) {
                                    const e = item.orders[i]

                                    if (
                                        (e.store?.name || '').toLowerCase().includes(search.toLowerCase()) ||
                                        (e.customer?.name || '').toLowerCase().includes(search.toLowerCase()) ||
                                        (e.reference_id || '').toLowerCase().includes(search.toLowerCase()) ||
                                        e.id.toString().includes(search)
                                    ) {
                                        return true
                                    }
                                }
                            }

                            return false
                        })
                        setFilteredRoutes(filtered)
                    } else {
                        setFilteredRoutes(routes)
                    }
                }, 500)
            },
            [routes]
        )

        useEffect(() => {
            _filterRoute(search)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [routes, search])

        return (
            <>
                <Container>
                    <ContainerCounter>
                        <Row>
                            <IconCounter icon={faRoute} />
                            <TitleCounter>{routes.length} rotas em andamento</TitleCounter>
                        </Row>

                        <SearchContainer>
                            <SearchInput
                                placeholder="Buscar por Nº do Pedido, Loja, Cliente ou entregador"
                                onChange={({ target: { value } }) => {
                                    setSearch(value)
                                }}
                            />
                            <SearchIcon />
                        </SearchContainer>
                    </ContainerCounter>

                    {loading || filteredRoutes.length ? (
                        <RoutesRunningList
                            list={filteredRoutes}
                            clickedRoute={clickedRouteOrder}
                            setHoverRoute={setHoverRoute}
                            hoverRoute={hoverRoute}
                            routeDetailsClick={id => history.push(`${basePath}/detalhes-rota/${id}`)}
                            finishRoute={_confirmFinish}
                        />
                    ) : (
                        <EmptyListMessage message="Não há rotas em andamento no momento" icon={faRoute} />
                    )}

                    {(loading || loader) && <LoadingMessage />}
                </Container>
                <ModalRouteFinish ref={modalRouteFinishRef} />
            </>
        )
    }
)

export default OperationRoutesInProgress
