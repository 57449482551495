const stoneBanks = [
    { value: 1, name: 'Banco do Brasil' },
    { value: 3, name: 'Banco da Amazônia S.A' },
    { value: 4, name: 'Banco do Nordeste do Brasil' },
    { value: 21, name: 'Banestes' },
    { value: 25, name: 'Banco Alfa' },
    { value: 33, name: 'Santander' },
    { value: 37, name: 'Banco do Estado do Pará' },
    { value: 41, name: 'Banco do Estado do Rio Grande do Sul' },
    { value: 47, name: 'Banco do Estado de Sergipe' },
    { value: 70, name: 'BRB' },
    { value: 77, name: 'Intermedium' },
    { value: 82, name: 'Banco Topázio' },
    { value: 84, name: 'Unicred Norte do Paraná' },
    { value: 85, name: 'CECRED' },
    { value: 89, name: 'Cooperativa de Crédito Rural da Região de Mogiana' },
    { value: 99, name: 'Cooperativa Central de Economia e Crédito Mutuo das Unicreds' },
    { value: 104, name: 'Caixa Econômica' },
    { value: 136, name: 'Confederação Nacional das Cooperativas Centrais UNICREDS' },
    { value: 197, name: 'Stone Pagamentos' },
    { value: 212, name: 'Banco Original' },
    { value: 213, name: 'Banco Arbi' },
    { value: 218, name: 'Banco Bonsucesso' },
    { value: 224, name: 'Banco Fibra' },
    { value: 237, name: 'Banco Bradesco' },
    { value: 246, name: 'Banco ABC Brasil' },
    { value: 341, name: 'Itaú' },
    { value: 376, name: 'Banco J.P. Morgan' },
    { value: 389, name: 'Banco Mercantil do Brasil' },
    { value: 422, name: 'Banco Safra' },
    { value: 600, name: 'Banco Luso Brasileiro' },
    { value: 604, name: 'Banco Industrial do Brasil' },
    { value: 612, name: 'Banco Guanabara' },
    { value: 633, name: 'Banco Rendimento' },
    { value: 634, name: 'Banco Triângulo' },
    { value: 637, name: 'Banco Sofisa' },
    { value: 655, name: 'Banco Votorantim' },
    { value: 707, name: 'Banco Daycoval' },
    { value: 741, name: 'Banco Ribeirão Preto' },
    { value: 743, name: 'Banco Semear' },
    { value: 745, name: 'Banco Citibank' },
    { value: 748, name: 'Sicredi' },
    { value: 755, name: 'Bank of America Merril Lynch Banco Multiplo' },
    { value: 756, name: 'Bancoob' },
]

export default stoneBanks
