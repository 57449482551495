import { useEffect, useMemo, useState } from 'react'

import Echo from 'laravel-echo'
import { SocketIoConnector } from 'laravel-echo/dist/connector'
import { Socket } from 'socket.io'

import { echoConnection } from 'services/api'

interface EchoEvent {
    name: string
    callback: (data: unknown) => void
}

interface HookParams {
    channelName: string | null
    events: EchoEvent[]
}

export function useEchoPrivateChannel({ channelName, events }: HookParams) {
    const [isChannelConnected, setIsChannelConnected] = useState(false)
    const [isSocketConnected, setIsSocketConnected] = useState(false)

    const echo = useMemo(() => {
        const echo = echoConnection() as Echo

        const connector = echo.connector as SocketIoConnector
        const socket = connector.socket as Socket

        socket.on('connect', () => {
            setIsSocketConnected(true)
        })
        socket.on('disconnect', () => {
            setIsSocketConnected(false)
        })

        return echo
    }, [])

    function listenToPrivateChannel(echo: Echo, channelName: string) {
        const channel = echo.private(channelName)

        events.forEach(event => {
            channel.listen(event.name, ({ data }) => {
                event.callback(data)
            })
        })

        setIsChannelConnected(true)
        console.log(`listening to ${channelName}`)
    }

    function leaveChannel() {
        if (!channelName) return

        echo.leave(channelName)
        setIsChannelConnected(false)
        console.log(`leave ${channelName}`)
    }

    useEffect(() => {
        if (!echo || isChannelConnected || !channelName?.length) return

        listenToPrivateChannel(echo, channelName)
    }, [channelName, echo, isChannelConnected, listenToPrivateChannel])

    useEffect(() => {
        return () => {
            leaveChannel()
        }
    }, [channelName])

    return { isChannelConnected, isSocketConnected, leaveChannel }
}
