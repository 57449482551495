const getBikerType = type => {
    return {
        moto: 'Moto',
        bike: 'Bicileta',
        car: 'Carro',
        van: 'Van',
        truck: 'Caminhão',
        picker: 'Picker',
    }[type || '']
}

export { getBikerType }
