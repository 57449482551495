import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import colors from '../../themes/colors'

const DelivererInfoItem = ({ label, value, isPerformanceValue, notRequired }) => {
    return (
        <div className="deliverer-info-item">
            <div className="label">{label}</div>
            {!!value && value !== '-' ? (
                <div className="value" style={{ color: isPerformanceValue ? colors.primary_color : null }}>
                    {value}
                </div>
            ) : (
                <div className="error">
                    {!notRequired && <FontAwesomeIcon icon="exclamation-circle" />}{' '}
                    {notRequired ? '-' : 'informação pendente'}
                </div>
            )}
        </div>
    )
}

export default DelivererInfoItem
