import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Echo from 'laravel-echo'
import socketIOClient from 'socket.io-client'

import { useList } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'
import { IRomaneioSimple } from 'types/romaneio-simple'

function useFetchRomaneios(storeID?: number) {
    const integrationSettings = useSelector(state => state.integrations)

    const { data: romaneios, dispatchData } = useList<IRomaneioSimple>()

    const alternativeConnection = useMemo(() => {
        return new Echo({
            host: process.env.REACT_APP_PLOOGA_WS_URL,
            broadcaster: 'socket.io',
            client: socketIOClient,
            namespace: '.',
            auth: {
                headers: {
                    Authorization: `Bearer ${integrationSettings.token}`,
                },
            },
        })
    }, [integrationSettings])

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.new',
                callback: (payload: IRomaneioSimple) => {
                    dispatchData({ type: 'ADD', payload })
                },
            },
        ]
    }, [dispatchData])

    useEchoConnection<IRomaneioSimple, IRomaneioSimple>({
        enable: !!storeID,
        channelName: storeID ? `plooga.webhooks.${storeID}` : null,
        events: socketEvents,
        alternativeConnection: alternativeConnection,
    })

    return { romaneios, dispatchData }
}

export { useFetchRomaneios }
