import { formatCurrency } from 'helpers'
import { PaymentArea, StoreRateValues } from 'types'

import {
    ChargesContainer,
    PopupContainer,
    PopupTitle,
    ChargeItem,
    ShiftContainer,
    ShiftTitle,
    ChargeValuesRow,
    ValueItem,
    BikeIcon,
    ValueText,
    MotoIcon,
} from './map-popup.styles'

interface Props {
    paymentArea: PaymentArea
    showRates: boolean
}

const MapPopup: React.FC<Props> = ({ paymentArea, showRates = false }) => {
    return (
        <PopupContainer areaColor={paymentArea.color}>
            <PopupTitle>{paymentArea.label}</PopupTitle>
            {showRates && paymentArea.rates?.length > 0 && (
                <ChargesContainer>
                    {paymentArea.rates?.map((rate, rateIndex) => (
                        <ChargeItem key={rateIndex}>
                            <ShiftContainer>
                                <ShiftTitle>
                                    {rate.shifts.map((shift, shiftIndex) => (
                                        <span key={shiftIndex}>{shift.name}</span>
                                    ))}
                                </ShiftTitle>
                                <ChargeValuesRow>
                                    <ValueItem>
                                        <BikeIcon />
                                        <ValueText>{formatCurrency((rate?.values as StoreRateValues).bike)}</ValueText>
                                    </ValueItem>
                                    <ValueItem>
                                        <MotoIcon />
                                        <ValueText>{formatCurrency((rate?.values as StoreRateValues).moto)}</ValueText>
                                    </ValueItem>
                                </ChargeValuesRow>
                            </ShiftContainer>
                        </ChargeItem>
                    ))}
                </ChargesContainer>
            )}
        </PopupContainer>
    )
}

export default MapPopup
