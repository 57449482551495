import { ChartData } from 'chart.js'

import { formatCurrency } from 'helpers/currency'
import { ChartObject } from 'types'

export function getDeliveredChart(deliveredData: ChartData<any>): ChartObject {
    return {
        data: deliveredData,
        // callbacks: {
        //     label: context => {
        //         const { Y } = deliveredData[context.dataIndex as number]
        //         return `${formatCurrency(Y)}`
        //     },
        // },
    }
}
