import styled from 'styled-components'

const ContainerCardListing = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    @media (min-width: 1200px) {
        width: 100%;
        height: calc(100vh - 200px);
        overflow-y: auto;
    }

    .empty-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        @media (min-width: 1200px) {
            margin-top: -30px;
        }
    }
`

export { ContainerCardListing }
