import { useEffect, useCallback, useRef, useState, useMemo, useContext, useLayoutEffect } from 'react'
import { Marker } from 'react-mapbox-gl'

import { OrdersModal } from 'modals'
import { ModalRouteFinish } from 'modals/modal-route-cancel/modal-route-cancel'

import ExchangeBikerModal from 'components/_bikers/exchange-route-biker-modal'
import { MirrorMap, GlobalMapContext } from 'components/global-map'

import { AgentMarker } from 'containers/agent-marker/agent-marker'

import { useAuth } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'

import ButtonForm from '../../components/button-form'
import { RouteOrdersMarkers } from '../../components/mapbox-view'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import OrderDeliveryman from '../../components/order-detail-deliveryman/order-detail-deliveryman'
import RouteDetailHistory from '../../components/route-detail-history/route-detail-history'
import RouteDetailStepsNew from '../../components/route-detail-step-new/route-detail-step-new'
import RouteDetailTopRow from '../../components/route-detail-top-row/order-detail-top-row'
import { getBikerType, showErrors } from '../../helpers'
import api, { echoConnection } from '../../services/api'

import { TabsContainer, TabItem } from './route-details.styled'

import './style.scss'
import { MapLineTrail } from '../../components/map-line'

import OrderRatesSideModal from './order-rates-side-modal'

import { routeColors } from 'utils'
import { useUI } from 'contexts'
import { Biker, Order } from 'types'

import { MapActionsContainer } from './route-details.styled'

import CheckboxInput from 'components/checkbox-input'

import { TrailData } from 'types/map-line'

import { ModalRemoveOrder, ModalRemoveOrderRef } from './modal-remove-order/modal-remove-order'

const echo = echoConnection()

const RouteStatus = {
    Started: 2,
    Finished: 3,
    Cancelled: 9,
} as const

const RouteDetails = ({ history, match: { params } }) => {
    const { basePath, mall, user } = useAuth()
    const { setErrorModal, setSuccessModal } = useUI()
    const { setMapBoxContentComponent } = useContext(GlobalMapContext)
    const { agentLocation } = useAgentsFetch({ routeId: params.route_id })

    const modalRouteFinishRef = useRef(null)
    const modalConfirmationRef = useRef(null)
    const modalExchangeRef = useRef(null)
    const ordersModalRef = useRef(null)
    const modalRemoveOrderRef = useRef<ModalRemoveOrderRef>(null)

    const [routeSocketConnected, setRouteSocketConnected] = useState<boolean>(false)
    const [modalDeliverersVisible, setModalDeliverersVisible] = useState<boolean>(false)
    const [sideRateOrder, setSideRateOrder] = useState<Order>()
    const [isSideRatesModalActive, setIsSideRatesModalActive] = useState<boolean>(false)
    const [deliveryman, setDeliveryman] = useState(null)
    const [route, setRoute] = useState(null)
    const [trails, setTrails] = useState<TrailData[] | null>(null)
    const [showRouteLines, setShowRouteLines] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(false)
    const [trailLoading, setTrailLoading] = useState<boolean>(false)

    const [agentInRouteInfo, setAgentInRouteInfo] = useState<any>({})

    const [tab, setTab] = useState('details')
    const [historic, setHistoric] = useState()

    const [modalConfirmation, setModalConfirmation] = useState({
        title: '',
        message: '',
        onYes: () => null,
    })

    const ordersPositions = useMemo(() => {
        return (route?.orders || []).map(item => item.id)
    }, [route?.orders])

    // comentado caso volte a ser utilizado
    //const isAllowedToCancelRoute = useMemo((): boolean => {
    //    if (!route) return false
    //    if (route?.orders?.length === 0) return true
    //
    //    const orderCollected = 5
    //
    //    const hasSomeOrderCollected = route.orders.some(order => Number(order.status) === orderCollected)
    //
    //    return (
    //        ![RouteStatus.Started, RouteStatus.Finished, RouteStatus.Cancelled].includes(route.status) &&
    //        !hasSomeOrderCollected
    //    )
    //}, [route])

    const canCancelRoute = useMemo(() => {
        if (route?.orders?.length === 0) return true
        return false
    }, [route])

    const canFinishRoute = useMemo(() => {
        return route && ![RouteStatus.Finished, RouteStatus.Cancelled].includes(route.status) && !!route.biker
    }, [route])

    const canUpdateOrderRates = useMemo(() => {
        const inProgress = Number(route?.status) === 2
        const isFinished = Number(route?.status) === 3
        const hasEnabledStatus = inProgress || isFinished
        const hasUserPermission = user.isAdmin || user.isManager

        if (hasEnabledStatus && hasUserPermission) {
            return true
        }

        return (
            hasEnabledStatus &&
            [
                'tech@logaroo.com.br',
                'germano@logaroo.com.br',
                'financeiro@logaroo.com.br',
                'samuel@logaroo.com.br',
                'leonardo@deliverymall.com.br',
            ].includes(user.email)
        )
    }, [route?.status, user])

    const _openSideRatesModal = useCallback((order: Order) => {
        setIsSideRatesModalActive(true)
        setSideRateOrder(order)
    }, [])

    const _getRoute = useCallback(async () => {
        try {
            const { data } = await api.get(`/painel/route/${params.route_id}`)
            setRoute(data)
            if (data.status !== RouteStatus.Finished && data.biker) {
                setAgentInRouteInfo(data.biker)
            }
        } catch (error) {
            setErrorModal({
                title: 'Alerta',
                subtitle: 'Não foi possível carregar os dados da rota. Tente novamente mais tarde.',
                singleButtonClick: () => history.push('/rotas'),
            })
        }
    }, [])

    const _getTrails = useCallback(async () => {
        setTrailLoading(true)
        try {
            const { data } = await api.get(`/painel/route/${params.route_id}/trail`)
            const trails = _getFormattedTrail(data)
            setTrails(trails)
        } catch (error) {
            setErrorModal({
                title: 'Alerta',
                subtitle: 'Não foi possível carregar os dados da trajeto. Tente novamente mais tarde.',
            })
        }
        setTrailLoading(false)
    }, [params.route_id, route])

    const _toggleShowRoute = useCallback(async () => {
        setShowRouteLines(state => !state)
        if (trails == null) {
            await _getTrails()
        }
    }, [trails, _getTrails])

    const _getFormattedTrail = useCallback(
        (trailData): TrailData[] => {
            const coordinateData = item => ({
                value: item.geometry.coordinates,
                props: item.properties,
            })

            const filterCoordinates = order => {
                return trailData?.features
                    .filter(item => item.properties.current_order === order.id)
                    .map(item => {
                        return coordinateData(item)
                    })
            }

            const getFinalTrail = (trailFeatures, order) => {
                const endDelivery = new Date(order?.end_delivery)

                const lastOrderTrail = trailFeatures
                    .filter(
                        item =>
                            new Date(item.properties.created_at) < endDelivery &&
                            item.properties.current_order === order.id
                    )
                    .map(item => {
                        return coordinateData(item)
                    })

                const returnedTrail = trailFeatures
                    .filter(item => new Date(item.properties.created_at) > endDelivery)
                    .map(item => {
                        return coordinateData(item)
                    })

                return {
                    lastOrderTrail,
                    returnedTrail,
                }
            }

            const orderLabel = index => {
                return [index + 1, route?.orders.length].join('/')
            }

            const trailOrders = []
            //console.log(route?.orders)

            route?.orders.map((order, index) => {
                if (route?.orders[index].id === route?.orders[route?.orders.length - 1].id) {
                    const { lastOrderTrail, returnedTrail } = getFinalTrail(trailData?.features, order)
                    return trailOrders.push(
                        {
                            color: _getRouteColor(index),
                            coordinates: lastOrderTrail,
                            properties: {
                                order,
                                label: orderLabel(index),
                            },
                        },
                        {
                            color: 'gray',
                            coordinates: returnedTrail,
                            properties: {
                                order,
                                label: 'Retorno',
                            },
                        }
                    )
                }

                return trailOrders.push({
                    color: _getRouteColor(index),
                    coordinates: filterCoordinates(order),
                    properties: {
                        order,
                        label: orderLabel(index),
                    },
                })
            })

            return trailOrders
        },
        [route?.orders, trails]
    )

    const _getRouteColor = useCallback(index => routeColors[index % routeColors.length], [])

    const exchangeDeliveryman = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.put(`/painel/route/${route.id}`, {
                biker_id: deliveryman?.id,
                status: 5,
            })

            setRoute(data)
            setErrorModal({
                title: 'Sucesso',
                subtitle: 'Rota atualizada com sucesso!',
            })
        } catch (error) {
            setErrorModal({
                title: 'Alerta',
                subtitle: showErrors(error),
            })
        } finally {
            setLoading(false)
        }
    }, [deliveryman?.id, route?.id])

    const cancelRoute = useCallback(async () => {
        setLoading(true)
        try {
            await api.delete(`/painel/route/${route.id}`)

            setSuccessModal({
                title: 'Sucesso',
                subtitle: 'Rota cancelada com sucesso!',
                singleButtonClick: () => history.replace(`/${mall.slug}/rotas/visao-geral`),
            })
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: showErrors(error),
            })
        }
        setLoading(false)
    }, [route?.id, mall.slug])

    const _finishRoute = useCallback(async (route_id, reason) => {
        try {
            setLoading(true)

            await api.post(`/painel/route/finish`, { route_id, reason })
            _getRoute()

            setErrorModal({
                title: 'Sucesso',
                subtitle: 'Rota finalizada com sucesso!',
            })
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: showErrors(error),
            })
        } finally {
            setLoading(false)
        }
    }, [])

    const _confirmFinish = useCallback(() => {
        modalRouteFinishRef.current?.show({
            route,
            onSelectReason: reason => {
                _finishRoute(route.id, reason)
            },
        })
    }, [_finishRoute, route])

    const closeModalExchange = useCallback(() => {
        setDeliveryman({})
        modalExchangeRef.current.closeModal()
    }, [])

    const _openModalOrders = useCallback(
        position => {
            ordersModalRef.current?.show({ position, routeId: route.id })
        },
        [ordersModalRef, route]
    )

    const _getHistoric = useCallback(async () => {
        if (!route) {
            return
        }
        setLoading(true)
        try {
            const {
                data: { items },
            } = await api.get(`/painel/route/${route.id}/logs`)
            setHistoric(items)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [route])

    const _removeOrderToRoute = useCallback(
        (order: Order) => () => {
            modalRemoveOrderRef.current.show({
                order,
            })
        },
        []
    )

    const _setTab = useCallback(
        tab => {
            return () => {
                if (tab === 'historic') {
                    _getHistoric()
                }

                setTab(tab)
            }
        },
        [_getHistoric]
    )

    const _renderMapContent = useCallback(() => {
        return (
            <>
                {agentInRouteInfo?.id && (
                    <Marker
                        style={{ cursor: 'pointer' }}
                        anchor="bottom"
                        coordinates={[agentInRouteInfo?.location?.lng || 0, agentInRouteInfo?.location?.lat || 0]}
                    >
                        <AgentMarker
                            alt={`agent-${agentInRouteInfo?.id}`}
                            src={agentInRouteInfo.avatar}
                            routeId={route?.id}
                        />
                    </Marker>
                )}

                <RouteOrdersMarkers routeOrders={route?.orders} routeId={route?.id} configs={mall?.configs} />
                {showRouteLines && <MapLineTrail trails={trails} />}

                <MapActionsContainer>
                    <CheckboxInput label={`Visualizar Rota`} checked={showRouteLines} onChange={_toggleShowRoute} />
                </MapActionsContainer>
            </>
        )
    }, [mall, route, agentInRouteInfo, trails, showRouteLines, _toggleShowRoute])

    const listenToRouteChanges = useCallback(() => {
        try {
            if (route?.id) {
                echo.private(`route.${route.id}`).listen('.updated', ({ data }) => {
                    console.log(`route.${route.id} - updated`, { data })
                    setRoute({ ...route, ...data })
                })

                setRouteSocketConnected(true)
            }
        } catch (error) {
            console.log({ error })
        }
    }, [route])

    useEffect(() => {
        if (!routeSocketConnected) listenToRouteChanges()

        return () => {
            echo.leave(`route.${route?.id}`)
        }
    }, [listenToRouteChanges, routeSocketConnected, route])

    useEffect(() => {
        _getRoute()
    }, [_getRoute])

    useEffect(() => {
        if (route?.biker && agentLocation) {
            setAgentInRouteInfo({ ...route.biker, ...(agentLocation as Biker) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentLocation])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_renderMapContent])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="page-container">
                <ModalLoading visible={loading || trailLoading} />

                <ModalConfirmation
                    ref={modalConfirmationRef}
                    title={modalConfirmation.title}
                    message={modalConfirmation.message}
                    onYes={modalConfirmation.onYes}
                />

                <ModalConfirmation
                    ref={modalExchangeRef}
                    title="Trocar entregador"
                    message="Deseja realmente trocar o entregador desta rota?"
                    onYes={exchangeDeliveryman}
                    onNo={closeModalExchange}
                />

                <ExchangeBikerModal
                    isActive={modalDeliverersVisible}
                    closeClick={() => setModalDeliverersVisible(false)}
                    route={route}
                    revalidate={_getRoute}
                />

                <RouteDetailTopRow backClick={() => history.goBack()} />

                <OrderRatesSideModal
                    isActive={isSideRatesModalActive}
                    closeClick={() => setIsSideRatesModalActive(false)}
                    order={sideRateOrder}
                    revalidate={_getRoute}
                />

                <div className="content-container">
                    <div className="route-detail-content-container">
                        <div className="left-column">
                            <TabsContainer>
                                <TabItem isActive={tab === 'details'} onClick={_setTab('details')}>
                                    Detalhes
                                </TabItem>

                                <TabItem isActive={tab === 'historic'} onClick={_setTab('historic')}>
                                    Histórico
                                </TabItem>
                            </TabsContainer>

                            {tab === 'details' && (
                                <>
                                    {!loading && (
                                        <div>
                                            <RouteDetailStepsNew
                                                routeDetails
                                                status={route?.status}
                                                routeOrders={route?.orders || []}
                                                mallName={mall.name}
                                                mallAddress={`${mall.address.street}, ${mall.address.number}`}
                                                textButton="Ver pedido"
                                                onTextClick={order =>
                                                    history.push(`${basePath}/detalhes-pedido/${order.id}`)
                                                }
                                                routeID={route?.id}
                                                revalidate={_getRoute}
                                                onAddOrderClick={_openModalOrders}
                                                updateOrderRates={
                                                    canUpdateOrderRates
                                                        ? (order: Order) => _openSideRatesModal(order)
                                                        : undefined
                                                }
                                                removeOrderToRoute={_removeOrderToRoute}
                                            />

                                            {!canCancelRoute && canFinishRoute && (
                                                <div className="route-finish-button" onClick={_confirmFinish}>
                                                    <div className="route-finish-button__circle" />
                                                    <div className="route-finish-button__text">Finalizar Rota</div>
                                                </div>
                                            )}

                                            {route?.biker && (
                                                <OrderDeliveryman
                                                    delivererName={`${route?.biker?.first_name} ${route?.biker?.last_name}`}
                                                    vehicle={getBikerType(route?.biker?.type)}
                                                    photoPath={route?.biker?.avatar}
                                                    routeStatus={Number(route?.status)}
                                                    exchangeClick={() => setModalDeliverersVisible(true)}
                                                />
                                            )}

                                            <div className="buttons-container">
                                                {canCancelRoute && (
                                                    <ButtonForm
                                                        buttonText="Cancelar rota"
                                                        onClick={() => {
                                                            setModalConfirmation({
                                                                title: 'Cancelar Rota',
                                                                message: 'Deseja mesmo cancelar esta rota?',
                                                                onYes: cancelRoute,
                                                            })

                                                            modalConfirmationRef.current.openModal()
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {tab === 'historic' && (
                                <RouteDetailHistory orders={route?.orders || []} historic={historic || []} />
                            )}
                        </div>

                        <div className="right-column">{!!route && <MirrorMap />}</div>
                    </div>
                </div>
            </div>
            <OrdersModal
                ordersPositions={ordersPositions}
                onUpdateRoute={e => setRoute({ ...route, ...e })}
                ref={ordersModalRef}
            />
            <ModalRouteFinish ref={modalRouteFinishRef} />
            <ModalRemoveOrder ref={modalRemoveOrderRef} revalidate={_getRoute} />
        </>
    )
}

function useAgentsFetch({ routeId }) {
    const [agentLocation, setAgentLocation] = useState()
    const socketEvents = useMemo(() => {
        return [
            {
                name: '.location',
                callback: agent => {
                    setAgentLocation(agent)
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEchoConnection({
        enable: !!routeId,
        channelName: routeId ? `route.${routeId}` : null,
        events: socketEvents,
    })

    return { agentLocation }
}

export default RouteDetails
