import { faAndroid } from '@fortawesome/free-brands-svg-icons'
import { faBatteryHalf, faMobileAlt, faEye, faPhoneAlt, faWifi, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const AvailableBikerCardContainer = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 16px;
    }

    &:hover {
        background-color: rgba(255, 232, 162, 0.4);
    }
`

export const BikerPhotoContainer = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.colors.lightGray};
    position: relative;
    margin-right: 20px;
`

export const BikerImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
`

export const BikerTextContainer = styled.div`
    flex: 1;
`

export const BikerName = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 70%;
    }
`

export const BikerPhoneContainer = styled.div`
    display: flex;
    margin-bottom: 2px;
`

export const PhoneIcon = styled(FontAwesomeIcon).attrs({
    icon: faPhoneAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    padding-top: 4px;
    font-size: 12px;
`
export const MallIcon = styled(FontAwesomeIcon).attrs({
    icon: faHome,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    padding-top: 4px;
    font-size: 12px;
`

export const PhoneValue = styled.div`
    font-size: 14px;
`

export const InfoRow = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    margin-top: 10px;
`

export const InfoItem = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
`

export const BatteryIcon = styled(FontAwesomeIcon).attrs({
    icon: faBatteryHalf,
})`
    transform: rotate(-90deg);
    color: #aaa;
    margin-right: 5px;
`

export const WifiIcon = styled(FontAwesomeIcon).attrs({
    icon: faWifi,
})`
    color: #aaa;
    margin-right: 5px;
`

export const CellphoneIcon = styled(FontAwesomeIcon).attrs({
    icon: faMobileAlt,
})`
    color: #aaa;
    margin-right: 5px;
`
export const EyeIcon = styled(FontAwesomeIcon).attrs({
    icon: faEye,
})<{ status?: 'active' | 'suspended' | 'closed' }>`
    color: ${({ status, theme }) => {
        if (status === 'active') {
            return theme.colors.success
        }
        if (status === 'suspended') {
            return theme.colors.yellow
        }
        if (status === 'closed') {
            return theme.colors.errorMessage
        }
        return '#aaa'
    }};
    margin-right: 5px;
`

export const AndroidIcon = styled(FontAwesomeIcon).attrs({
    icon: faAndroid,
})`
    color: #aaa;
    margin-right: 5px;
`

export const VehicleIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    padding-top: 4px;
    font-size: 12px;
`

export const InfoText = styled.div`
    font-weight: bold;
    font-size: 12px;
`

export const MainButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px;
    flex: 1;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
    font-size: 10px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
    @media (min-width: 768px) {
        margin-top: 0;
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 20px;
        padding: 5px 15px;
    }
`

export const Vehicle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
`
