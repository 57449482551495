import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatepickerInput from '../datepicker-input'
import SelectInput from '../select-input'
import './style.scss'

const PaymentReportTopRow = ({
    backClick,
    stores,
    selectStore,
    storeFilter,
    selectStartDate,
    startDate,
    selectEndDate,
    endDate,
    titleText,
    onFilterClick,
}) => {
    return (
        <div className="payment-report-top-row-container">
            <div className="row-item">
                <div className="left-column">
                    {backClick && (
                        <div className="back-icon" onClick={backClick}>
                            <FontAwesomeIcon icon="arrow-left" />
                        </div>
                    )}
                    <div className="top-row-title">{titleText}</div>
                </div>
            </div>
            <div className="inputs-row" style={{ marginLeft: !stores ? '-10px' : null }}>
                {stores && (
                    <div className="mobile-spacing">
                        <SelectInput
                            placeholder="Todas as lojas"
                            bgColor="transparent"
                            data={stores}
                            onChange={selectStore}
                            value={storeFilter}
                        />
                    </div>
                )}

                <div className="mobile-spacing">
                    <DatepickerInput placeholder="Data inicial" onChange={selectStartDate} value={startDate} />
                </div>

                <div className="mobile-spacing">
                    <DatepickerInput placeholder="Data final" onChange={selectEndDate} value={endDate} />
                </div>

                {onFilterClick && (
                    <div className="filter-button" onClick={onFilterClick}>
                        Filtrar
                    </div>
                )}
            </div>
        </div>
    )
}

export default PaymentReportTopRow
