import { useContext } from 'react'

import { OrdersContext } from 'contexts/orders-context'
import { OrdersInProgressState } from 'store/reducers/types'

type Hook = OrdersInProgressState & {
    isSocketConnected: boolean
    revalidateOrders(): Promise<void>
}

export function useOrdersInProgress(): Hook {
    const { ordersInProgress, refresh, isSocketConnected } = useContext(OrdersContext)

    return { ...ordersInProgress, isSocketConnected, revalidateOrders: refresh }
}
