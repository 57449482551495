import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Accordion, LoadingMessage } from 'components/_common'

import { useAuth, useDataFetching } from 'hooks'
import { Creators as AuthActions } from 'store/reducers/auth'
import { AuthThunk } from 'store/thunks'
import { Mall, PagedList, Store } from 'types'

import login1 from '../../assets/images/login1.png'
import login2 from '../../assets/images/login2.png'
import login3 from '../../assets/images/login3.png'
import { BackButtonContainer, BackIcon, BackText } from '../area-page/area-page.styles'

import {
    LeftLogo,
    LeftSide,
    LoginBox,
    LoginImage,
    LoginImageContainer,
    LoginPageContainer,
    LoginTitle,
    MobileLogo,
    RightSide,
    StoreItem,
    MapImage,
    GroupedListContainer,
    LogoutButton,
    StoreName,
    NewTab,
    External,
} from './unity-page.styles'

interface GroupedListItem {
    mall: Mall
    stores: Array<Store>
}

export default function UnityPage(): JSX.Element {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const history = useHistory()

    const [loginImage, setLoginImage] = useState(login1)
    const [isChanging, setIsChanging] = useState(false)

    const { data: malls, loading: loadingMalls } = useDataFetching<PagedList<Mall>>(
        user?.isAdmin || user?.isManager ? '/painel/malls' : null,
        {
            params: {
                order_by: 'name',
                status: [1],
                per_page: 100,
            },
        }
    )

    const { data: stores, loading: loadingStores } = useDataFetching<PagedList<Store>>(
        user?.isAdmin || user?.isManager ? '/painel/stores' : null
    )

    function getGroupedList() {
        const groupedList = [] as GroupedListItem[]

        malls?.items.forEach(mall => {
            groupedList.push({ mall: mall, stores: [] })
        })

        stores?.items.forEach(store => {
            groupedList.forEach((listItem, index) => {
                if (listItem?.mall?.id === store?.mall?.id) {
                    groupedList[index].stores.push(store)
                }
            })
        })

        return groupedList
    }

    const changeImage = useCallback(() => {
        if (loginImage === login1) {
            setLoginImage(login2)
        }
        if (loginImage === login2) {
            setLoginImage(login3)
        }
        if (loginImage === login3) {
            setLoginImage(login1)
        }
    }, [loginImage])

    useEffect(() => {
        setTimeout(() => {
            if (isChanging) {
                setIsChanging(false)
                changeImage()
            }
        }, 1000)

        setTimeout(() => {
            if (!isChanging) {
                setIsChanging(true)
            }
        }, 5000)
    }, [isChanging, changeImage])

    const goToNextScreen = async (selectedMall: number, selectedStore: number | null, blank?: boolean) => {
        try {
            const mall = malls?.items.find(mall => mall.id === Number(selectedMall))
            const store = stores?.items.find(store => store.id === Number(selectedStore))

            if (blank) {
                window.open(
                    store ? `/${mall?.slug}/${store.slug}/visao-geral` : `/${mall?.slug}/rotas/visao-geral`,
                    '_blank'
                )
            } else {
                dispatch(AuthActions.setStore(store || null))
                dispatch(AuthActions.setMall(mall || null))

                history.push(store ? `/${mall?.slug}/${store.slug}/visao-geral` : `/${mall?.slug}/rotas/visao-geral`)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const goToStore = async (store: Store, blank?: boolean) => {
        try {
            dispatch(AuthActions.setStore(store || null))
            dispatch(AuthActions.setMall(store.mall || null))

            const url = `/${store.mall?.slug}/${store.slug}/visao-geral/em-andamento`

            if (blank) {
                window.open(url, '_blank')
            } else {
                history.push(url)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const groupedList = useMemo(getGroupedList, [malls, stores])

    return (
        <LoginPageContainer>
            <LeftSide>
                <MapImage />
                <LeftLogo />
                <LoginImageContainer>
                    <LoginImage src={loginImage} isChanging={isChanging} />
                </LoginImageContainer>
            </LeftSide>
            <RightSide>
                <LoginBox>
                    <MobileLogo />
                    {(user?.isAdmin || user?.isManager) && (
                        <BackButtonContainer>
                            <BackIcon onClick={() => history.goBack()} />
                            <BackText onClick={() => history.goBack()}>Voltar para seleção de área</BackText>
                        </BackButtonContainer>
                    )}
                    <LoginTitle>Escolher Unidade</LoginTitle>

                    {loadingMalls || loadingStores ? (
                        <LoadingMessage message="Carregando unidades..." />
                    ) : (
                        <GroupedListContainer>
                            {groupedList.map((item, index) => (
                                <Accordion
                                    accordionTitle={item.mall.name}
                                    isPrivate={item.mall.type === 'private'}
                                    key={index}
                                >
                                    <StoreItem>
                                        <StoreName onClick={() => goToNextScreen(item.mall.id, null)}>
                                            Roteirização
                                        </StoreName>
                                        <NewTab onClick={() => goToNextScreen(item.mall.id, null, true)}>
                                            <External />
                                        </NewTab>
                                    </StoreItem>
                                    {item.stores.map((store, storeIndex) => (
                                        <StoreItem key={storeIndex}>
                                            <StoreName onClick={() => goToNextScreen(item.mall.id, store.id)}>
                                                {store.name}
                                            </StoreName>
                                            <NewTab onClick={() => goToNextScreen(item.mall.id, store.id, true)}>
                                                <External />
                                            </NewTab>
                                        </StoreItem>
                                    ))}
                                </Accordion>
                            ))}

                            {user?.isShopkeeper && user?.stores && (
                                <Accordion accordionTitle="Suas lojas" open>
                                    {user.stores.map((store, storeIndex) => (
                                        <StoreItem key={storeIndex}>
                                            <StoreName onClick={() => goToStore(store)}>{store.name}</StoreName>
                                            <NewTab onClick={() => goToStore(store, true)}>
                                                <External />
                                            </NewTab>
                                        </StoreItem>
                                    ))}
                                </Accordion>
                            )}
                        </GroupedListContainer>
                    )}
                    <LogoutButton onClick={() => dispatch(AuthThunk.logout())}>Sair</LogoutButton>
                </LoginBox>
            </RightSide>
        </LoginPageContainer>
    )
}
