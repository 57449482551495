import { AppliedFilterItem, AppliedFiltersContainer } from './applied-filters.styles'

interface Props {
    selectedFilters: string[]
}

const AppliedFilters: React.FC<Props> = ({ selectedFilters }) => {
    return (
        <>
            {selectedFilters.length > 0 ? (
                <AppliedFiltersContainer>
                    {selectedFilters?.map((item, index) => (
                        <AppliedFilterItem key={index}>{item}</AppliedFilterItem>
                    ))}
                </AppliedFiltersContainer>
            ) : null}
        </>
    )
}

export default AppliedFilters
