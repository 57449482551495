import {
    ClientName,
    CheckIcon,
    DraftIcon,
    ErrorIcon,
    FlagIcon,
    RoutedStepItemContainer,
    StepItemContent,
    StoreIcon,
    StoreLogo,
    TagContainer,
    WaitingBikerIcon,
    WaitingIcon,
    BikerTag,
} from './routed-step-item.styles'

interface Props {
    clientName?: string
    showMallIcon?: boolean
    showFlagIcon?: boolean
    storeLogo?: string
    color?: string
    isActive?: boolean
    showSuccessTag?: boolean
    showErrorTag?: boolean
    showStatusTag?: boolean
    showWaitingIcon?: boolean
    showDraftIcon?: boolean
    showWaitingBikerIcon?: boolean
    bikerPhoto?: string
    showBikerTag?: boolean
    bikerVehicle?: number
}

const RoutedStepItem: React.FC<Props> = ({
    showMallIcon,
    storeLogo,
    color,
    isActive,
    showBikerTag,
    showFlagIcon,
    showSuccessTag,
    showErrorTag,
    showStatusTag,
    showWaitingIcon,
    showDraftIcon,
    showWaitingBikerIcon,
    clientName,
    bikerPhoto,
    bikerVehicle,
}) => {
    return (
        <RoutedStepItemContainer itemColor={color} isActive={isActive}>
            {showStatusTag && (
                <TagContainer error={showErrorTag} waiting={showWaitingIcon}>
                    {showSuccessTag && <CheckIcon />}
                    {showErrorTag && <ErrorIcon />}
                    {showWaitingIcon && <WaitingIcon />}
                </TagContainer>
            )}
            {showBikerTag && (
                <BikerTag>
                    {bikerVehicle === 1 && <div>Moto</div>}
                    {bikerVehicle === 2 && <div>Bike</div>}
                </BikerTag>
            )}
            <StepItemContent itemColor={color} isActive={isActive}>
                {showDraftIcon && <DraftIcon />}
                {showWaitingBikerIcon && <WaitingBikerIcon />}
                {showMallIcon && <StoreIcon />}
                {showFlagIcon && <FlagIcon />}
                {storeLogo && <StoreLogo src={storeLogo} />}
                {bikerPhoto && <StoreLogo src={bikerPhoto} />}
            </StepItemContent>
            <ClientName>{clientName}</ClientName>
        </RoutedStepItemContainer>
    )
}

export default RoutedStepItem
