import styled from 'styled-components'

const Row = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 20px;
    font-size: 14px;
    padding-left: 50px;
    margin-bottom: 10px;
    background-color: #fff;
    &:nth-child(odd) {
        background: #f0f0f0;
    }
    @media (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }
`

const RateId = styled.div`
    padding-top: 0.3rem;
    @media (min-width: 1200px) {
        width: 5%;
    }
`

const RateLabel = styled.div`
    font-weight: 500;
    padding-top: 0.3rem;

    @media (min-width: 1200px) {
        width: 15%;
    }
`

const RateDelivery = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.7rem;

    @media (min-width: 1200px) {
        width: 18%;
    }
`

const TextGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Icon = styled.div`
    width: 1rem;
    padding-right: 10px;

    @media (min-width: 1200px) {
        width: 8%;
        padding: 1px 10px;
    }
`
const CurrencyText = styled.p`
    margin: 0;
`

const Text = styled.p`
    margin: 0;
    @media (min-width: 1200px) {
        font-size: 0.8rem;
    }
`

const RateStatus = styled.div`
    padding-top: 0.7rem;
    font-weight: bold;
    @media (min-width: 1200px) {
        width: 8%;
        font-weight: normal;
    }
`

const TableActions = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 1.5rem;

    button {
        width: 50%;
        margin-right: 0.5rem;
    }

    @media (min-width: 1200px) {
        width: 30%;
        padding-top: 0;

        button {
            width: auto;
        }
    }
`

export { CurrencyText, Text, TextGroup, Icon, Row, RateDelivery, RateId, RateLabel, RateStatus, TableActions }
