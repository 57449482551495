import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import EmptyMessage from 'components/empty-message/empty-message'
import ExpressRatesTableHeader from 'components/express-rates-table-header/express-rates-table-header'
import ExpressRatesTableRow from 'components/express-rates-table-row/express-rates-table-row'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { RateExpress } from 'types'

import './style.scss'

const ExpressRatesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { basePath, mall } = useAuth()

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)

    const [loading, toggleLoading] = useState(false)
    const [expressRates, setExpressRates] = useState<{
        current_page: number
        items: RateExpress[]
        totals: number
    }>({ current_page: 1, items: [], totals: 1 })
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [deleteID, setDeleteID] = useState<number | null>(null)

    const getExpressRates = useCallback(
        async ({ current_page = 1, expressRates = { items: [] } } = {}) => {
            try {
                toggleLoading(true)

                const { data } = await api.get(`/painel/rate_express`, {
                    params: {
                        current_page,
                        mall_id: mall.id,
                        status: [0, 1],
                    },
                })

                setExpressRates({
                    ...data,
                    items: current_page > 1 ? [...expressRates.items, ...data.items] : data.items,
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push(`${basePath}/tarifas`),
                })

                modalMessage.current?.openModal()
            } finally {
                toggleLoading(false)
            }
        },
        [history, mall.id]
    )

    useEffect(() => {
        getExpressRates()
    }, [getExpressRates])

    const deleteRate = async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate_express/${deleteID}`)

            getExpressRates()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="dollar-sign">Esta unidade não possui tarifas cadastradas.</EmptyMessage>
    }

    return (
        <div className="express-rates-listing-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Atenção! Esta tarifa será removida porém as entregas já realizadas que foram afetadas por ela não serão atualizadas. Deseja continuar?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Tarifas Expressas"
                buttons={[
                    {
                        title: 'Criar tarifa',
                        onClick: () => history.push(`${basePath}/criar-tarifa-expressa`),
                    },
                ]}
            />

            <ExpressRatesTableHeader />

            {expressRates.items?.map((item, index) => (
                <ExpressRatesTableRow
                    key={index}
                    expressRate={item}
                    editClick={() => history.push(`${basePath}/tarifa-expressa/${item.id}`)}
                    deleteClick={() => {
                        setDeleteID(item.id)

                        modalDelete.current?.openModal()
                    }}
                />
            ))}

            {!expressRates?.items?.length && !loading && renderEmptyMessage()}

            <ButtonLoadMore
                loading={loading}
                visible={expressRates?.totals > 0 && Number(expressRates?.items?.length) < Number(expressRates?.totals)}
                onClick={() => getExpressRates({ current_page: Number(++expressRates.current_page), expressRates })}
            />
        </div>
    )
}

export default ExpressRatesListing
