import { useState, useRef, useEffect, useCallback, memo, useMemo } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'

import { DefaultButton } from 'components/default-button/default-button'
import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalLoading from 'components/modal-loading'

import { ContentHeader, ContentHeaderTitle } from 'containers/marketplace-management/marketplace-management.styles'

import { useUI } from 'contexts'
import { formatCurrency, showErrors } from 'helpers'
import { useAuth, useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, Showcase } from 'types'

import './style.scss'
import { ShowcaseModal, ShowcaseModalRef } from './components/showcase-modal'
import ModalProductsShowcase, { ModalProductsShowcaseRef } from './modal-products-showcase/modal-products-showcase'
import {
    ActionButton,
    ActionsContainer,
    ClosedViewIcon,
    Container,
    DeleteIcon,
    DraggableContent,
    DraggableShowcaseContainer,
    DraggableShowcaseContent,
    EditIcon,
    ProductDescription,
    ProductInfo,
    ProductName,
    ProductPhoto,
    ProductText,
    ReorderActions,
    ReorderRowActions,
    ShowCaseStatus,
    ShowcaseItemHeader,
    ShowcaseItemHeaderActions,
    ShowcaseItemHeaderContent,
    StatusItem,
    ViewIcon,
} from './showcases-listing.styled'

interface DraggableShowcaseProps {
    basePath: string
    showcase: Showcase
    index: number
    productsVisible: boolean
    isDragging: boolean
    isFirst?: boolean
    isLast?: boolean
    onRemoveShowcase(): void
    handleEdit?(): void
    handleReorder?(from: number, to: number): void
}

const DraggableShowcase: React.FC<DraggableShowcaseProps> = memo(
    ({
        basePath,
        showcase,
        index,
        productsVisible,
        isDragging,
        isFirst,
        isLast,
        onRemoveShowcase,
        handleEdit,
        handleReorder,
    }) => {
        const content = useRef<HTMLDivElement>(null)

        const [openList, setOpenList] = useState<boolean>(false)

        const { id, name, products, status } = showcase

        return (
            <Draggable key={id} draggableId={id.toString()} index={index}>
                {provided => (
                    <DraggableShowcaseContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <DraggableShowcaseContent ref={content} openList={openList}>
                            <ShowcaseItemHeader>
                                <ShowcaseItemHeaderContent>
                                    <ReorderRowActions>
                                        <ReorderActions
                                            icon={'arrow-up'}
                                            isActive={!isFirst}
                                            onClick={() => (!isFirst ? handleReorder(index, index - 1) : null)}
                                        />

                                        <ReorderActions
                                            icon={'arrow-down'}
                                            isActive={!isLast}
                                            onClick={() => (!isLast ? handleReorder(index, index + 1) : null)}
                                        />
                                    </ReorderRowActions>
                                    <div className="title">{name}</div>
                                    <ShowCaseStatus isActive={!!status}>{status ? 'Ativo' : 'Inativo'}</ShowCaseStatus>
                                </ShowcaseItemHeaderContent>
                                <ShowcaseItemHeaderActions>
                                    <ActionButton widthFitContainer onClick={handleEdit}>
                                        <EditIcon />
                                    </ActionButton>
                                    <ActionButton variant="danger" widthFitContainer onClick={onRemoveShowcase}>
                                        <DeleteIcon />
                                    </ActionButton>

                                    <ActionButton onClick={() => setOpenList(state => !state)}>
                                        {!openList ? <ViewIcon /> : <ClosedViewIcon />}
                                    </ActionButton>
                                </ShowcaseItemHeaderActions>
                            </ShowcaseItemHeader>

                            <DraggableContent openList={openList}>
                                <FlexTable
                                    columns={[
                                        {
                                            name: 'ID',
                                            width: '20%',
                                            style: { display: 'flex', justifyContent: 'start' },
                                        },
                                        {
                                            name: 'Produto',
                                            width: '40%',
                                            style: { display: 'flex', justifyContent: 'center' },
                                        },
                                        {
                                            name: 'Estoque',
                                            width: '20%',
                                            style: { display: 'flex', justifyContent: 'center' },
                                        },
                                        {
                                            name: 'Preço',
                                            width: '20%',
                                            style: { display: 'flex', justifyContent: 'center' },
                                        },
                                        {
                                            name: 'Status',
                                            width: '20%',
                                            style: { display: 'flex', justifyContent: 'center' },
                                        },
                                    ]}
                                    list={products?.map(product => {
                                        const {
                                            id,
                                            photo,
                                            name,
                                            description,
                                            manage_stock,
                                            stock,
                                            price,
                                            is_offer,
                                            price_offer,
                                            status,
                                        } = product

                                        return {
                                            id: id.toString(),
                                            product: (
                                                <ProductInfo>
                                                    <ProductPhoto src={`${photo}?width=200`} />
                                                    <ProductText>
                                                        <ProductName>{name}</ProductName>
                                                        <ProductDescription>{description}</ProductDescription>
                                                    </ProductText>
                                                </ProductInfo>
                                            ),
                                            stock: manage_stock ? (
                                                <>
                                                    <strong>{stock}</strong> em estoque
                                                </>
                                            ) : (
                                                'Não gerenciável'
                                            ),
                                            price: (
                                                <>
                                                    {is_offer ? (
                                                        <div
                                                            style={{
                                                                textDecoration: 'line-through',
                                                                color: 'var(--gray-color)',
                                                            }}
                                                        >
                                                            {formatCurrency(price)}
                                                        </div>
                                                    ) : null}
                                                    <strong>{formatCurrency(is_offer ? price_offer : price)}</strong>
                                                </>
                                            ),
                                            status: (
                                                <StatusItem isActive={Boolean(status)}>
                                                    {status ? 'Ativo' : 'Inativo'}
                                                </StatusItem>
                                            ),
                                        }
                                    })}
                                />
                            </DraggableContent>
                        </DraggableShowcaseContent>
                    </DraggableShowcaseContainer>
                )}
            </Draggable>
        )
    }
)

const ShowcasesListing: React.FC = memo(_ => {
    const { basePath, store } = useAuth()
    const { setErrorModal, setSuccessModal, setConfirmationModal } = useUI()

    const showcaseModalRef = useRef<ShowcaseModalRef>()
    const modalProductsShowcase = useRef<ModalProductsShowcaseRef>(null)

    const [productsVisible, setProductsVisible] = useState(true)
    const [isDragging, setIsDragging] = useState(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showcases, setShowcases] = useState<Showcase[]>()
    const [isReorder, setIsReorder] = useState<boolean>(false)

    const isExpress = useMemo(() => {
        return location.href.includes('expressa')
    }, [])

    const _updateShowcaseOrder = useCallback(async () => {
        const updateValues = showcases.map(item => item.id)

        try {
            await api.post(`painel/showcases/${store.id}/reorder`, {
                showcases: updateValues,
            })

            setIsReorder(false)
            setSuccessModal({
                //title: 'Sucesso!',
                subtitle: 'Vitrine atualizada com sucesso.',
            })
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possivel atualizar vitrine',
            })
        }
    }, [showcases, store])

    const _getShowcases = useCallback(async () => {
        setIsLoading(true)
        try {
            const {
                data: { items },
            } = await api.get<PagedList<Showcase>>('/painel/showcases', {
                params: { store_id: store.id, per_page: 999, type: isExpress ? 'express' : 'default' },
            })
            setShowcases(items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possivel carregar informações da vitrine',
            })
        }
        setIsLoading(false)
    }, [store.id, isExpress])

    const _reorderShowCase = useCallback((items: Showcase[]) => {
        return items.map((item, i) => ({
            ...item,
            order: i + 1,
        }))
    }, [])

    const onDragEnd = useCallback(
        async (result: DropResult) => {
            const { source, destination } = result
            setProductsVisible(true)
            setIsDragging(false)

            if (!destination) return
            if (destination.droppableId === source.droppableId && destination.index === source.index) return

            const showcaseSource = showcases[source.index]
            const showcaseDestination = showcases[destination.index]

            setShowcases(state => {
                state.splice(source.index, 1)
                state.splice(destination.index, 0, showcaseSource)
                return _reorderShowCase(state)
            })
        },
        [showcases, _getShowcases]
    )

    const _openModal = useCallback(() => {
        showcaseModalRef.current?.show()
    }, [])

    const _openEdit = useCallback(
        (showcase: Showcase) => () => {
            _openModal()
            showcaseModalRef.current?.setShowcase(showcase)
        },
        []
    )

    const _handleCloseModal = useCallback(
        (refresh?: boolean) => {
            if (refresh) {
                _getShowcases()
            }

            showcaseModalRef.current?.close()
        },
        [_getShowcases]
    )

    const _handleRemoveShowcase = useCallback((id: number) => {
        const deleteShowcase = async () => {
            showcaseModalRef.current?.close()
            setIsLoading(true)

            try {
                await api.delete(`/painel/showcase/${id}`)

                _getShowcases()
            } catch (error) {
                setErrorModal({
                    title: 'Erro!',
                    subtitle: 'Não foi possível excluir a tag!',
                })
            }
            setIsLoading(false)
        }

        return (): void => {
            setConfirmationModal({
                title: 'Excluir Agrupamento de Vitrine',
                subtitle: 'Tem certeza de que deseja este agrupamento da vitrine?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: deleteShowcase,
            })
        }
    }, [])

    const _handleReorderShowCase = useCallback(
        async (from: number, to: number) => {
            if (!isReorder) {
                setIsReorder(true)
            }
            const showcaseSource = showcases[from]
            const showcaseDestination = showcases[to]

            setShowcases(state => {
                state.splice(from, 1)
                state.splice(to, 0, showcaseSource)
                return _reorderShowCase(state)
            })
        },
        [showcases, isReorder, _getShowcases]
    )

    useEffect(() => {
        _getShowcases()
    }, [_getShowcases])

    return (
        <Container>
            <ModalLoading visible={isLoading} />

            <ShowcaseModal
                ref={showcaseModalRef}
                isExpress={isExpress}
                openProductModal={modalProductsShowcase.current?.openModal}
                onClose={_handleCloseModal}
                onRemove={_handleRemoveShowcase}
            />

            <ModalProductsShowcase
                isExpress={isExpress}
                ref={modalProductsShowcase}
                onUpdateProducts={products => showcaseModalRef.current.updateProducts(products)}
            />

            <ContentHeader>
                <ContentHeaderTitle>Vitrine {isExpress ? 'Expressa' : ''}</ContentHeaderTitle>
                <DefaultButton widthFitContainer onClick={_openModal}>
                    Criar Agrupamento
                </DefaultButton>
            </ContentHeader>

            <DragDropContext
                onDragStart={() => {
                    setIsReorder(true)
                    setIsDragging(true)
                    setProductsVisible(false)
                }}
                onDragEnd={onDragEnd}
            >
                <Droppable droppableId="showcases-listing-draggable">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {showcases?.map((showcase, index) => (
                                <DraggableShowcase
                                    key={showcase.id}
                                    basePath={basePath}
                                    showcase={showcase}
                                    index={index}
                                    productsVisible={productsVisible}
                                    isDragging={isDragging}
                                    isFirst={Boolean(index === 0)}
                                    isLast={Boolean(showcases.length === index + 1)}
                                    handleReorder={_handleReorderShowCase}
                                    handleEdit={_openEdit(showcase)}
                                    onRemoveShowcase={_handleRemoveShowcase(showcase.id)}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {isReorder && (
                <ActionsContainer>
                    <DefaultButton title={'Salvar'} widthFitContainer onClick={_updateShowcaseOrder} />
                </ActionsContainer>
            )}

            {!showcases?.length && !isLoading && (
                <EmptyMessage>Nenhum Agrupamento da vitrine criado para esta loja</EmptyMessage>
            )}
        </Container>
    )
})

export default ShowcasesListing
