/* eslint-disable no-case-declarations */
import { useRef, useState, memo, useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import { ModalOrderCancel, ModalOrderCancelRef } from 'modals/modal-order-cancel/modal-order-cancel'
import { ChangeOrderObservable } from 'observables'

import { LoadingMoreButton } from 'components/_common'
import { DetailsTab } from 'components/_store-general-vision/order-content/details-tab/details-tab'
import OrderSummary from 'components/_store-general-vision/order-summary/order-summary'
import ModalLoading from 'components/modal-loading'
import OrderDetailHistory from 'components/order-detail-history/order-detail-history'
import { Scroll } from 'components/scroll/scroll'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Order, PagedList } from 'types'
import { IOrderHistoric } from 'types/historic'
import { IRomaneioSimple } from 'types/romaneio-simple'

import { ManagerOrderContent } from './contents/manager-order-content/manager-order-content'
import { RescheduleContent } from './contents/reschedule-content/reschedule-content'
import {
    TabsContainer,
    TabItem,
    IconButton,
    Container,
    Footer,
    TextButton,
    ContentOrder,
    ContainerSubModal,
} from './modal-lateral-order.styled'
type Params = {
    romaneio: IRomaneioSimple
    onChange(romaneio: IRomaneioSimple, type: 'add' | 'update' | 'delete'): void
}
type TabDetailsType = 'details' | 'historic'
type TypeModal = 'details' | 'import' | 'new-order' | 'update' | 'schedule'

type PaginationFilters = {
    has_more: boolean
    page: number
    type: string
}

export type ModalLateralOrderRef = {
    show?(params?: Params): void
    close?(): void
}

const defaultPaginationParams = {
    has_more: false,
    page: 1,
    type: 'log',
}

const ModalLateralOrder = memo(() => {
    const history = useHistory()
    const location = useLocation()
    const { user } = useAuth()
    const { setConfirmationModal, setErrorModal, setSnackbar } = useUI()

    const modalOrderCancelRef = useRef<ModalOrderCancelRef>(null)
    const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

    const [isEffectActive, setIsEffectActive] = useState(false)
    const [typeModal, setTypeModal] = useState<TypeModal>()
    const [tab, setTab] = useState<TabDetailsType>('details')
    const [order, setOrder] = useState<Order>()
    const [historic, setHistoric] = useState<IOrderHistoric[] | null>(null)
    const [paginationFilter, setPaginationFilter] = useState<PaginationFilters>(defaultPaginationParams)
    const [loading, setLoading] = useState(false)

    const isActive = useMemo(() => {
        return typeModal && typeModal !== 'details'
    }, [typeModal])

    const _clear = useCallback(() => {
        setOrder(undefined)
        setTypeModal(undefined)
        setTab('details')
        setHistoric(undefined)
        setLoading(false)
        setIsEffectActive(false)
    }, [])

    const _onClose = useCallback(() => {
        if (typeModal !== 'details' && order) {
            setTypeModal('details')
            setTab('details')
            return false
        } else {
            history.replace({ search: clearURl() })
            _clear()
            return true
        }
    }, [history, typeModal, order, _clear])

    const _getPaginatedHistoric = useCallback(
        async (currentPage = 1) => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<IOrderHistoric>>(`/painel/dm-order/${order.id}/logs`, {
                    params: {
                        type: [paginationFilter.type],
                        current_page: currentPage,
                    },
                })
                setHistoric(state => (state && currentPage !== 1 ? [...state, ...data.items] : data.items))
                setPaginationFilter(state => ({
                    ...state,
                    has_more: data.has_more,
                    page: data.current_page,
                }))
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        },
        [order, paginationFilter]
    )

    const _setTab = useCallback(
        (tab: TabDetailsType) => {
            return () => {
                if (tab === 'historic') {
                    _getPaginatedHistoric()
                }

                setTab(tab)
            }
        },
        [_getPaginatedHistoric]
    )

    const _getOrder = useCallback(async (orderId: number) => {
        setLoading(true)
        try {
            const { data } = await api.get<Order>(`/painel/dm-order/${orderId}`)
            setOrder(data)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])

    const _copyOrder = useCallback(() => {
        setTypeModal('import')
    }, [])

    const _editOrder = useCallback(() => {
        setTypeModal('update')
    }, [])

    const _changeOrderStatus = useCallback(
        async (order: Order, status: number, successMessage: string, reason?: string) => {
            setLoading(true)
            try {
                let o!: Order

                if (status === 0) {
                    const { data } = await api.put<Order>(`/painel/dm-order/${order.id}/cancel`, { reason })
                    o = data
                }
                if (status === 3) {
                    const { data } = await api.put(`/painel/dm-order/${order.id}/in_production`)
                    o = data
                }
                if (status === 4) {
                    const { data } = await api.put(`/painel/dm-order/${order.id}/ready_to_delivery`)
                    o = data
                }
                if (![0, 1, 3, 4].includes(status)) {
                    const { data } = await api.put(`/painel/dm-order/${order.id}`, { status })
                    o = data
                }
                ChangeOrderObservable.next({ order: o, type: 'update' })
                setOrder(o)
                setSnackbar({ message: successMessage })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: showErrors(error),
                })
            }
            setLoading(false)
        },
        [setSnackbar, setErrorModal]
    )

    const _cancelOrder = useCallback(() => {
        modalOrderCancelRef.current?.show({
            order,
            onSelectReason: reason => {
                _changeOrderStatus(order, 0, 'Pedido cancelado', reason)
            },
        })
    }, [order, _changeOrderStatus])

    const _deleteOrder = useCallback(() => {
        const callback = async () => {
            try {
                setLoading(true)

                await api.delete(`/painel/dm-order/${order?.id}`)
                ChangeOrderObservable.next({ order, type: 'delete' })
                lateralModalBaseRef.current?.close()
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        setConfirmationModal({
            title: 'Excluir pedido?',
            subtitle: 'Ao excluir o pedido, ele será retirado do sistema e não poderá ser recuperado.',
            type: 'alert',
            modalIcon: 'trash-alt',
            leftButtonText: 'Fechar',
            rightButtonText: 'Sim, excluir',
            children: <OrderSummary order={order} />,
            rightButtonClick: callback,
        })
    }, [order, setConfirmationModal])

    const _onScheduleOrder = useCallback(() => {
        setTypeModal('schedule')
    }, [])

    const _onCloseSubModal = useCallback(
        (type?: 'add' | 'update') => {
            if (!type && !order) {
                lateralModalBaseRef.current?.close()
                return
            }

            if (type === 'add') {
                lateralModalBaseRef.current?.close()
            }

            if (type !== 'add') {
                setTypeModal('details')
            }

            if (type === 'update') {
                _getOrder(order?.id)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [order]
    )

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        let orderId!: number

        if (query.has('modal-order-id')) {
            _clear()

            setTypeModal('details')
            orderId = Number(query.get('modal-order-id'))

            lateralModalBaseRef.current?.show()
            _getOrder(Number(orderId))
        }

        if (query.has('modal-request-order')) {
            _clear()

            orderId = Number(query.get('modal-request-order'))
            setTypeModal(orderId ? 'import' : 'new-order')

            lateralModalBaseRef.current?.show()

            if (orderId) {
                _getOrder(Number(orderId))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    useEffect(() => {
        if (isActive) {
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
        }
    }, [isActive])

    useEffect(() => {
        return () => {
            setPaginationFilter({
                has_more: false,
                page: 1,
                type: 'log',
            })
        }
    }, [])

    return (
        <>
            <LateralModalBase
                zIndex={9900000000000}
                ref={lateralModalBaseRef}
                title="Detalhes do Pedido"
                onClose={_onClose}
            >
                <Container>
                    <>
                        <TabsContainer>
                            <TabItem isActive={tab === 'details'} onClick={_setTab('details')}>
                                Informações gerais
                            </TabItem>
                            <TabItem isActive={tab === 'historic'} onClick={_setTab('historic')}>
                                Histórico
                            </TabItem>
                        </TabsContainer>
                        <Scroll>
                            {!!order && (
                                <ContentOrder>
                                    {tab === 'details' && (
                                        <DetailsTab order={order} onRescheduleOrder={_onScheduleOrder} />
                                    )}
                                    {tab === 'historic' && <OrderDetailHistory historic={historic} />}
                                    {tab === 'historic' && paginationFilter.has_more && (
                                        <LoadingMoreButton
                                            loading={false}
                                            onClick={() => _getPaginatedHistoric(paginationFilter.page + 1)}
                                        />
                                    )}
                                </ContentOrder>
                            )}
                        </Scroll>
                        <Footer>
                            {!!order && (
                                <>
                                    {user?.isMallManager && (
                                        <TextButton onClick={_deleteOrder}>
                                            <IconButton icon="trash-alt" /> Excluir
                                        </TextButton>
                                    )}
                                    <TextButton onClick={_cancelOrder}>
                                        <IconButton icon="times-circle" /> Cancelar Pedido
                                    </TextButton>
                                    <TextButton onClick={_editOrder}>
                                        <IconButton icon="edit" /> Editar
                                    </TextButton>
                                    <TextButton onClick={_copyOrder}>
                                        <IconButton icon="paper-plane" /> Reenviar
                                    </TextButton>
                                </>
                            )}
                        </Footer>
                    </>
                    <ContainerSubModal animated={typeModal !== 'new-order'} isEffectActive={isEffectActive}>
                        {['import', 'new-order', 'update'].includes(typeModal) && (
                            <ManagerOrderContent
                                order={order}
                                onClose={_onCloseSubModal}
                                isImport={typeModal === 'import'}
                            />
                        )}

                        {order && typeModal === 'schedule' && (
                            <RescheduleContent order={order} onClose={_onCloseSubModal} />
                        )}
                    </ContainerSubModal>
                </Container>
                <ModalLoading visible={loading} />
                <ModalOrderCancel ref={modalOrderCancelRef} />
            </LateralModalBase>
        </>
    )
})

function clearURl() {
    const url = new URL(window.location.href)
    url.searchParams
    url.searchParams.delete('modal-order-id')
    url.searchParams.delete('modal-request-order')

    return url.search
}

export { ModalLateralOrder }
