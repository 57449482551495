import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ContainerCardListing = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    @media (min-width: 1200px) {
        width: 100%;
        height: calc(100vh - 200px);
        overflow-y: auto;
    }

    .empty-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        @media (min-width: 1200px) {
            margin-top: -30px;
        }
    }
`

const ContainerFilter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    justify-content: space-between;
    .item-input {
        border: #cecece solid 1px;
        border-radius: 4px;
        &:first-child {
            margin-right: 8px;
        }
    }
`

const RefreshIcon = styled(FontAwesomeIcon).attrs({
    icon: faSyncAlt,
})`
    color: ${({ theme }) => theme.colors.darkOrange};
`

const ContainerRefresh = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
    height: 100%;
    margin-left: 10px;
`

const TextRefresh = styled.div`
    color: ${({ theme }) => theme.colors.darkOrange};
    font-size: 12px;
    margin-left: 5px;
`

export { ContainerCardListing, ContainerFilter, RefreshIcon, ContainerRefresh, TextRefresh }
