import { useState, useRef } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormikValues } from 'formik'

import CheckboxInput from 'components/checkbox-input'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'

import { useUI } from 'contexts'
import { formatCurrency, formatDistance, getStatusOrder } from 'helpers'
import api from 'services/api'
import colors from 'themes/colors'
import { Order } from 'types'
import { routeColors } from 'utils'

import './style.scss'

interface Props {
    routeOrders: Order[]
    mallName: string
    mallAddress: string
    routeCreation?: boolean
    droppable?: string
    onRemoveClick?(order: Order): void
    textButton: string
    routeDetails?: boolean
    onTextClick?(order: Order): void
    ordersHasReturn?: number[]
    onChangeOrdersHasReturn?(orderId: number): void
    updateOrderRates?(values: FormikValues): void
    routeID?: number
    status?: number
    revalidate?(): void
    onAddOrderClick?(position: number): void
    removeOrderToRoute?(order: Order): () => void
}

const RouteDetailStepNew: React.FC<Props> = ({
    routeOrders,
    mallName,
    status,
    mallAddress,
    routeCreation,
    droppable,
    onRemoveClick,
    textButton,
    routeDetails,
    onAddOrderClick,
    onTextClick,
    ordersHasReturn,
    onChangeOrdersHasReturn,
    updateOrderRates,
    routeID,
    revalidate,
    removeOrderToRoute,
}) => {
    const { setLoading, setSnackbar } = useUI()

    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    function getBGColor(status: string) {
        if (
            status === '1' ||
            status === '2' ||
            status === '3' ||
            status === '4' ||
            status === '5' ||
            status === '6' ||
            status === '7'
        ) {
            return '#FF860A'
        } else if (status === '8' || status === '9' || status === '11') {
            return '#00bb00'
        } else {
            return '#cc0000'
        }
    }

    async function onRemoveFromRoute(route_id: number, order_id: number) {
        try {
            setLoading(true)
            await api.post('/painel/route/remove-order', {
                route_id: route_id,
                order_id: order_id,
            })
            revalidate()
            setSnackbar({ message: 'Removido da rota' })
        } catch (error) {
            console.log(error)
            setSnackbar({ message: 'Erro ao remover da rota' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="route-detail-steps-new-container">
            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />
            <div className="title-row">
                <FontAwesomeIcon icon="store" />
                <div>
                    <div className="title-text">{mallName}</div>
                    <div className="mall-address">{mallAddress}</div>
                </div>
            </div>

            <div className="route-list-container">
                {routeCreation && droppable && (
                    <div className="tags-container">
                        {routeOrders.length ? (
                            <Droppable droppableId={droppable}>
                                {provided => {
                                    return (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {routeOrders.map((order, index) => {
                                                return (
                                                    <div key={index} className="address-item">
                                                        <span className="circle-span" />

                                                        <Draggable
                                                            key={order.id}
                                                            draggableId={order.id.toString()}
                                                            index={index}
                                                        >
                                                            {provided => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                    className="draggable-container"
                                                                >
                                                                    <div>{order.merchant.name}</div>
                                                                    <div className="address-value-container">
                                                                        <div className="address-value">
                                                                            {order.address?.street},{' '}
                                                                            {order.address?.number}
                                                                        </div>

                                                                        {routeOrders.length > 1 && (
                                                                            <div className="swap-icon-container">
                                                                                <FontAwesomeIcon
                                                                                    icon="grip-lines"
                                                                                    color="#999"
                                                                                    size="lg"
                                                                                    className="swap-icon"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    {order.id && (
                                                                        <>
                                                                            <div>
                                                                                {order.reference_name} (#
                                                                                {order.id})
                                                                            </div>

                                                                            <div>{order.payment.name}</div>
                                                                        </>
                                                                    )}

                                                                    <div className="rate-return-checkbox-container">
                                                                        <CheckboxInput
                                                                            id={`${order.id}-has-rate-return`}
                                                                            label="Adicionar tarifa de retorno"
                                                                            onChange={() =>
                                                                                onChangeOrdersHasReturn &&
                                                                                onChangeOrdersHasReturn(order.id)
                                                                            }
                                                                            checked={ordersHasReturn.some(
                                                                                id => id === order.id
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    {Number(order.status) === 0 && (
                                                                        <div className="order-canceled-container">
                                                                            <div className="text">Pedido cancelado</div>
                                                                            <FontAwesomeIcon
                                                                                icon="exclamation-circle"
                                                                                color={colors.red}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {order.id && (
                                                                        <div
                                                                            className="order-action"
                                                                            onClick={() => onRemoveClick(order)}
                                                                        >
                                                                            {textButton}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    </div>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )
                                }}
                            </Droppable>
                        ) : (
                            <div className="address-item">
                                <div className="address-value">Adicione um pedido para iniciar a rota</div>
                            </div>
                        )}
                    </div>
                )}

                {routeDetails && (
                    <div className="tags-container">
                        {routeOrders?.map((order, index) => (
                            <div key={index} className="address-item route-details">
                                <div className="content-info">
                                    {[0, 1].includes(status) && (
                                        <div className="container-row">
                                            <span className="circle-span">+</span>

                                            <div className="add-order" onClick={() => onAddOrderClick(index)}>
                                                Adicionar Pedido
                                            </div>
                                        </div>
                                    )}
                                    <div className="container-row">
                                        <span
                                            className="circle-span"
                                            style={{
                                                backgroundColor: routeColors[index % routeColors.length],
                                            }}
                                        >
                                            {index + 1}
                                        </span>
                                        <div
                                            className="order-item__order-status"
                                            style={{ backgroundColor: getBGColor(order.status) }}
                                        >
                                            {getStatusOrder(order.status)}
                                        </div>
                                    </div>
                                    <div className="contianer-mall">
                                        <small>
                                            {order.address?.neighborhood} ({formatDistance(order.distance)})
                                        </small>
                                        <div className="order-item__merchant-name">
                                            {order.merchant.name} (#{order.reference_id || order.id})
                                        </div>
                                        <div className="address-value-container">
                                            <div className="address-value">
                                                {order.address?.street}, {order.address?.number}
                                            </div>
                                        </div>
                                        <small>{order.payment.name}</small>

                                        {Number(order.status) === 0 && (
                                            <div className="order-canceled-container">
                                                <div className="text">Pedido cancelado</div>
                                                <FontAwesomeIcon icon="exclamation-circle" color={colors.red} />
                                            </div>
                                        )}

                                        <div className="route-orders-rates-container">
                                            {(order.agent_pick > 0 || order.store_delivery_pick > 0) && (
                                                <section className="order-rates-section">
                                                    <div className="rate-container">
                                                        <strong className="rate-section-title">Tarifa de coleta</strong>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="store" />
                                                            {formatCurrency(order.store_delivery_pick)}
                                                        </div>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="motorcycle" />
                                                            {formatCurrency(order.agent_pick)}
                                                        </div>
                                                    </div>
                                                </section>
                                            )}

                                            {(order.agent_express > 0 || order.store_delivery_express > 0) && (
                                                <section className="order-rates-section">
                                                    <div className="rate-container">
                                                        <strong className="rate-section-title">Tarifa expressa</strong>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="store" />
                                                            {formatCurrency(order.store_delivery_express)}
                                                        </div>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="motorcycle" />
                                                            {formatCurrency(order.agent_express)}
                                                        </div>
                                                    </div>
                                                </section>
                                            )}

                                            {(order.agent_rate > 0 || order.store_delivery_rate > 0) && (
                                                <section className="order-rates-section">
                                                    <div className="rate-container">
                                                        <strong className="rate-section-title">Tarifa padrão</strong>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="store" />
                                                            {formatCurrency(order.store_delivery_rate)}
                                                        </div>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="motorcycle" />
                                                            {formatCurrency(order.agent_rate)}
                                                        </div>
                                                    </div>
                                                </section>
                                            )}

                                            {(order.agent_rate_return > 0 || order.store_rate_return > 0) && (
                                                <section className="order-rates-section">
                                                    <div className="rate-container">
                                                        <strong className="rate-section-title">
                                                            Tarifa de retorno
                                                        </strong>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="store" />
                                                            {formatCurrency(order.store_rate_return)}
                                                        </div>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="motorcycle" />
                                                            {formatCurrency(order.agent_rate_return)}
                                                        </div>
                                                    </div>
                                                </section>
                                            )}

                                            {(order.agent_dynamic_rate > 0 ||
                                                order.store_delivery_dynamic_rate > 0) && (
                                                <section className="order-rates-section">
                                                    <div className="rate-container">
                                                        <strong className="rate-section-title">Tarifa dinâmica</strong>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="store" />
                                                            {formatCurrency(order.store_delivery_dynamic_rate)}
                                                        </div>
                                                        <div className="rate-value">
                                                            <FontAwesomeIcon icon="motorcycle" />
                                                            {formatCurrency(order.agent_dynamic_rate)}
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </div>

                                        <div className="order-actions-container">
                                            {onTextClick && (
                                                <div className="order-action" onClick={() => onTextClick(order)}>
                                                    <FontAwesomeIcon icon="file-invoice" /> {textButton}
                                                </div>
                                            )}
                                            {updateOrderRates && (
                                                <div className="order-action" onClick={() => updateOrderRates(order)}>
                                                    <FontAwesomeIcon icon="dollar-sign" /> Alterar tarifas
                                                </div>
                                            )}

                                            {['1', '2', '3', '4', '5', '6'].includes(order.status) && (
                                                <div
                                                    className="order-action remove"
                                                    onClick={removeOrderToRoute(order)}
                                                    //onClick={() => {
                                                    //    setModalConfirmationData({
                                                    //        title: 'Excluir da rota',
                                                    //        message:
                                                    //            'Tem certeza de que deseja excluir este pedido da rota?',
                                                    //        onYes: () => onRemoveFromRoute(routeID, order.id),
                                                    //    })
                                                    //    modalConfirmation.current?.openModal()
                                                    //}}
                                                >
                                                    <FontAwesomeIcon icon="times-circle" /> Remover da rota
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="address-item route-details">
                            {[0, 1].includes(status) && (
                                <div className="container-row">
                                    <span className="circle-span">+</span>
                                    <div className="content-info">
                                        <div className="add-order" onClick={() => onAddOrderClick(routeOrders.length)}>
                                            Adicionar Pedido
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RouteDetailStepNew
