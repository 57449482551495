import styled from 'styled-components'

const CheckboxContainer = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    user-select: none;
`

const CircleContainer = styled.div<{ isSelected?: boolean; isSquared?: boolean }>`
    width: 20px;
    height: 20px;
    border-radius: ${({ isSquared }) => (isSquared ? '4px' : '50%')};
    border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : '#aaa')};
    cursor: pointer;
    margin-right: 10px;
`

const InnerCircle = styled.div<{ isSelected?: boolean; isSquared?: boolean }>`
    width: 100%;
    height: 100%;
    border-radius: ${({ isSquared }) => (isSquared ? '1px' : '50%')};
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.white)};
`

export { CheckboxContainer, CircleContainer, InnerCircle }
