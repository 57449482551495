import { useState, useEffect, useRef, useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { ModalModeration } from 'modals'
import StringMask from 'string-mask'

import DelivererDocItem from '../../components/deliverer-doc-item/deliverer-doc-item'
import DelivererInfoItem from '../../components/deliverer-info-item/deliverer-info-item'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalImage from '../../components/modal-image'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { formatCpf, formatCnpj, formatPhone } from '../../helpers'
import api from '../../services/api'

import './style.scss'

const DelivererInfo = ({ history, match }) => {
    const modalMessage = useRef()
    const modalDelete = useRef()
    const modalModerationRef = useRef(null)
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [modalImage, setModalImage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [isRejected, setIsRejected] = useState(false)
    const [fullProfile, setFullProfile] = useState({})
    const [bikerPerformance, setBikerPerformance] = useState(null)
    const [currentImage, setCurrentImage] = useState('')
    const [reason, setReason] = useState('')

    const getBikerPerformance = useCallback(async agent => {
        try {
            setLoading(true)

            if (agent?.status === 1) {
                const { data } = await api.get(`/painel/metrics/agent/${agent?.id}`)

                setBikerPerformance(data)
            }
        } catch (err) {
            console.log('getBikers:', err)

            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar as informações de performance do entregador.',
                onClose: () => null,
            })
        } finally {
            setLoading(false)
        }
    }, [])

    const getBiker = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api.get(`/painel/agent/${match.params.id}`)

            getBikerPerformance(data)
            setFullProfile(data)
        } catch (err) {
            console.log('getBikers:', err)

            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar as informações do entregador.',
                onClose: () => history.push('/painel-entregadores'),
            })
        } finally {
            setLoading(false)
        }
    }, [history, match.params.id, getBikerPerformance])

    const _reprove = useCallback(() => {
        modalModerationRef.current?.show(fullProfile.agent)
    }, [fullProfile])

    useEffect(() => {
        getBiker()
    }, [getBiker])

    useEffect(() => {
        modalMessageData.title && modalMessage.current.openModal()
    }, [modalMessageData])

    const deleteUser = async () => {
        try {
            modalDelete.current.closeModal()

            setLoading(true)

            await api.delete(`/painel/biker/${match.params.id}`)

            setModalMessageData({
                title: 'Sucesso',
                message: 'Usuário removido com sucesso!',
                onClose: () => history.push('/painel-entregadores'),
            })
        } catch (err) {
            console.log('deleteUser:', err)

            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível remover o usuário.',
                onClose: () => {},
            })
        } finally {
            setLoading(false)
        }
    }

    const updateUser = async status => {
        try {
            setIsRejected(false)
            setIsApproved(false)
            setLoading(true)

            let body = { status }

            if (status === 4 || status === 3) {
                body.reason = reason
            }

            await api.put(`/painel/biker/${match.params.id}`, body)

            getBiker()

            setModalMessageData({
                title: 'Sucesso',
                message: `Usuário ${status === 1 ? 'aprovado' : 'reprovado'} com sucesso!`,
                onClose: () => {},
            })
        } catch (err) {
            console.log('deleteUser:', err)

            setModalMessageData({
                title: 'Erro',
                message: `Não foi possível ${status === 1 ? 'aprovar' : 'reprovar'} o usuário.`,
                onClose: () => {},
            })
        } finally {
            setLoading(false)
        }
    }
    const approveUser = async () => {
        try {
            setIsRejected(false)
            setIsApproved(false)
            setLoading(true)
            await api.put(`painel/agent/${match.params.id}/approve`)
            getBiker()
            setModalMessageData({
                title: 'Sucesso',
                message: `Usuário aprovado com sucesso!`,
                onClose: () => {},
            })
        } catch (err) {
            console.log('deleteUser:', err)

            setModalMessageData({
                title: 'Erro',
                message: `Não foi possível aprovar o usuário.`,
                onClose: () => {},
            })
        } finally {
            setLoading(false)
        }
    }

    const { agent, address, bank, profile } = fullProfile
    return (
        <>
            {!!agent && (
                <div className="deliverer-info-page">
                    <ModalImage visible={modalImage} image={currentImage} onClose={() => setModalImage(false)} />

                    <ModalMessage
                        ref={modalMessage}
                        title={modalMessageData.title}
                        message={modalMessageData.message}
                        onClose={modalMessageData.onClose}
                    />
                    <ModalConfirmation
                        ref={modalDelete}
                        title="Remover entregador"
                        message={`Deseja mesmo remover o usuário ${agent?.first_name} ${agent?.last_name}?`}
                        onYes={deleteUser}
                    />

                    <TopRowDefault title="Informações do Entregador" onBackButtonClick={() => history.goBack()} />

                    <div className="deliverer-info-page-content">
                        <div className="box left-side">
                            <div className="top-row">
                                <div className="user-image">{agent?.avatar && <img src={agent?.avatar} alt="" />}</div>
                                <div className="user-name">
                                    {agent?.first_name} {agent?.last_name}
                                </div>
                            </div>

                            <div className="info-block">
                                <DelivererInfoItem label="Nome" value={agent?.first_name} />
                                <DelivererInfoItem label="Sobrenome" value={agent?.last_name} />
                                <DelivererInfoItem label="CPF" value={formatCpf(agent?.cpf)} />
                                <DelivererInfoItem
                                    label="Data de Nascimento"
                                    value={agent?.dob && format(agent.dob, 'DD/MM/YYYY')}
                                />
                                <DelivererInfoItem
                                    label="Modalidade de entrega"
                                    value={formatTypeName(agent?.type_name)}
                                />
                            </div>

                            <div className="info-block">
                                <DelivererInfoItem label="E-mail" value={agent?.email} />
                                <DelivererInfoItem label="Celular" value={formatPhone(agent?.cellphone)} />
                            </div>

                            <div className="info-block">
                                <DelivererInfoItem notRequired label="Razão Social" value={profile.company_name} />
                                <DelivererInfoItem notRequired label="CNPJ" value={formatCnpj(profile.cnpj)} />
                            </div>

                            {agent?.status === 4 && (
                                <div>
                                    {!isApproved && !isRejected && (
                                        <div className="buttons-row">
                                            <div className="button-item outline" onClick={_reprove}>
                                                Reprovar
                                            </div>
                                            <div className="button-item" onClick={() => setIsApproved(true)}>
                                                Aprovar
                                            </div>
                                        </div>
                                    )}

                                    {isRejected && (
                                        <div className="reject-deliverer">
                                            <div className="title">Motivo da reprovação:</div>
                                            <textarea
                                                name=""
                                                id=""
                                                onChange={e => setReason(e.target.value)}
                                            ></textarea>
                                            <div className="buttons-row">
                                                <div
                                                    className="button-item outline"
                                                    onClick={() => setIsRejected(false)}
                                                >
                                                    Cancelar
                                                </div>
                                                <div className="button-item" onClick={() => updateUser(3)}>
                                                    Reprovar
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {isApproved && (
                                        <div className="reject-deliverer">
                                            <div className="title">Confirmar aprovação:</div>
                                            <div className="buttons-row">
                                                <div
                                                    className="button-item outline"
                                                    onClick={() => setIsApproved(false)}
                                                >
                                                    Cancelar
                                                </div>
                                                <div className="button-item" onClick={approveUser}>
                                                    Aprovar
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="remove" onClick={() => modalDelete.current.openModal()}>
                                Remover entregador
                            </div>
                        </div>
                        <div className="box right-side">
                            {agent?.status === 1 && bikerPerformance ? (
                                <div className="info-section">
                                    <div className="title">Performance</div>
                                    <div className="flex-container">
                                        <div className="column">
                                            <DelivererInfoItem
                                                label="Tempo de coleta"
                                                value={bikerPerformance.collect_time}
                                                isPerformanceValue
                                            />
                                        </div>
                                        <div className="column">
                                            <DelivererInfoItem
                                                label="Tempo de entrega"
                                                value={bikerPerformance.delivery_time}
                                                isPerformanceValue
                                            />
                                        </div>
                                        <div className="column">
                                            <DelivererInfoItem
                                                label="Tempo de início de rota"
                                                value={bikerPerformance.start_route_time}
                                                isPerformanceValue
                                            />
                                        </div>
                                        <div className="column">
                                            <DelivererInfoItem
                                                label="Tempo de rota"
                                                value={bikerPerformance.total_route_time}
                                                isPerformanceValue
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="info-section">
                                <div className="title">Endereço</div>
                                <div className="flex-container">
                                    <div className="column">
                                        <DelivererInfoItem label="Estado" value={address.state} />
                                        <DelivererInfoItem label="Bairro" value={address.neighborhood} />
                                        <DelivererInfoItem notRequired label="Complemento" value={address.complement} />
                                    </div>
                                    <div className="column">
                                        <DelivererInfoItem label="Município" value={address.city} />
                                        <DelivererInfoItem label="Logradouro" value={address.street} />
                                        <DelivererInfoItem notRequired label="Referência" value={address.landmark} />
                                    </div>
                                    <div className="column">
                                        <DelivererInfoItem label="Número" value={address.number} />
                                    </div>
                                </div>
                            </div>

                            <div className="info-section">
                                <div className="title">Informações Bancárias</div>
                                <div className="flex-container">
                                    {bank.account_type === 'pix' ? (
                                        <div className="column">
                                            <DelivererInfoItem
                                                label="Tipo de chave PIX"
                                                value={transformPixKey(bank.pix_type)}
                                            />
                                            <DelivererInfoItem
                                                label="Chave PIX"
                                                value={transformPixCode(bank.pix_type, bank.pix_code)}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="column">
                                                <DelivererInfoItem label="Banco" value={bank.bank} />
                                                <DelivererInfoItem label="Conta" value={bank.account_number} />
                                                <DelivererInfoItem
                                                    notRequired
                                                    label="Nome do Titular"
                                                    value={bank.owner_account}
                                                />
                                            </div>
                                            <div className="column">
                                                <DelivererInfoItem label="Agência" value={bank.bank_branch} />
                                                <DelivererInfoItem label="Operação" value={bank.operation_number} />
                                                <DelivererInfoItem
                                                    notRequired
                                                    label="CPF/CNPJ do Titular"
                                                    value={
                                                        bank.cpf_cnpj?.length === 11
                                                            ? formatCpf(bank.cpf_cnpj)
                                                            : formatCnpj(bank.cpf_cnpj)
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="info-section">
                                <div className="title">Contato de emergência</div>
                                <div className="flex-container">
                                    <div className="column">
                                        <DelivererInfoItem
                                            label="Contato de emergência"
                                            value={profile?.emergency?.name}
                                        />
                                        <DelivererInfoItem
                                            label="Parentesco"
                                            value={profile?.emergency?.relationship}
                                        />
                                    </div>
                                    <div className="column">
                                        <DelivererInfoItem
                                            label="Parentesco"
                                            value={formatPhone(profile?.emergency?.cellphone)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* {biker?.crlv && biker?.type === 1 && (
                        <div className="info-section">
                            <div className="title">Veículo</div>
                            <div className="flex-container">
                                <div className="column">
                                    <DelivererInfoItem label="Placa" value={formatPlate(biker?.vehicle.license_plate)} />
                                    <DelivererInfoItem label="Número do Chassi" value={biker?.vehicle.chassis_number} />
                                    <DelivererInfoItem label="Modelo" value={biker?.vehicle.model} />
                                    <DelivererInfoItem
                                        label="Ano de Fabricação"
                                        value={biker?.vehicle.year_manufacture}
                                    />
                                </div>
                                <div className="column">
                                    <DelivererInfoItem label="Renavam" value={biker?.vehicle.renavam} />
                                    <DelivererInfoItem label="Marca" value={biker?.vehicle.brand} />
                                    <DelivererInfoItem label="Ano do Modelo" value={biker?.vehicle.year_model} />
                                    <DelivererInfoItem
                                        label="Moto Própria?"
                                        value={biker?.vehicle.is_owner ? 'Sim' : 'Não'}
                                    />
                                </div>
                                {biker?.type === 1 && (
                                    <div className="column">
                                        <DelivererDocItem
                                            docImage={`${baseURL}/${biker?.crlv.path}`}
                                            expandClick={() => {
                                                setCurrentImage(`${baseURL}/${biker?.crlv.path}`)
                                                setModalImage(true)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )} */}

                            <div className="info-section">
                                <div className="title">Documento de identificação</div>
                                {!profile.document_type && (
                                    <div className="error">
                                        <FontAwesomeIcon icon="exclamation-circle" /> informação pendente
                                    </div>
                                )}
                                <div className="flex-container">
                                    <div className="flex-container">
                                        <DelivererDocItem
                                            label="frente"
                                            docImage={`${profile.document_front}?${new Date().getTime().toString(32)}`}
                                            expandClick={() => {
                                                setCurrentImage(
                                                    `${profile.document_front}?${new Date().getTime().toString(32)}`
                                                )
                                                setModalImage(true)
                                            }}
                                        />
                                        {profile.document_verse && profile.document_type === 'rg' && (
                                            <DelivererDocItem
                                                label="verso"
                                                docImage={`${profile.document_verse}?${new Date()
                                                    .getTime()
                                                    .toString(32)}`}
                                                expandClick={() => {
                                                    setCurrentImage(
                                                        `${profile.document_verse}?${new Date().getTime().toString(32)}`
                                                    )
                                                    setModalImage(true)
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ModalModeration ref={modalModerationRef} onRefresh={getBiker} />
            <ModalLoading visible={loading} />
        </>
    )
}

function transformPixKey(key) {
    if (!key) {
        return null
    }
    switch (key) {
        case 'email':
            return 'E-mail'
        case 'phone':
            return 'Número do Telefone'
        case 'cpf':
            return 'Número do CPF'
        case 'cnpj':
            return 'Número do CNPJ'
        case 'random_key':
            return 'Chave aleatória'
    }

    return key
}
function formatTypeName(type_name) {
    switch (type_name) {
        case 'bike':
            return 'BICICLETA'
        case 'car':
            return 'CARRO'
        case 'moto':
            return 'MOTO'
        case 'truck':
            return 'CAMINHÃO'
        case 'van':
            return 'VAN'

        default:
            return ''
    }
}

function transformPixCode(key, value) {
    if (!key) {
        return null
    }
    switch (key) {
        case 'phone':
            return phoneMask(value)
        case 'cpf':
            const formatteerCPF = new StringMask('000.000.000-00')
            return formatteerCPF.apply((value || '').replace(/\D/g, ''))
        case 'cnpj':
            const formatterCNPJ = new StringMask('00.000.000/0000-00')
            return formatterCNPJ.apply((value || '').replace(/\D/g, ''))
    }

    return value
}

export function phoneMask(phone) {
    if (!phone) {
        return ''
    }
    let number = phone.replace('+55', '')
    if (number.length === 12 && number[0] === '0') {
        number = number.substring(1)
    }
    number = number.replace(/\D/g, '')
    number = number.replace(/^(\d{2})(\d)/g, '($1) $2')
    number = number.replace(/(\d)(\d{4})$/, '$1-$2')

    return number
}

export default DelivererInfo
