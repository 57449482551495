import styled from 'styled-components'

export const TimelineTitle = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`
export const TimelineContainer = styled.div``

export const TimelineItem = styled.div`
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    font-size: 12px;
`

export const ItemTitle = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`

export const ItemValue = styled.div``
