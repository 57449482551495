import { useState, useEffect, useRef, useCallback, useContext, useLayoutEffect } from 'react'

import { circle, difference, intersect } from '@turf/turf'

// import Checkbox from 'components/_common/checkbox/checkbox'
import { MirrorMap, GlobalMapContext } from 'components/global-map'

import { useAuth } from 'hooks'

import ButtonForm from '../../components/button-form'
import { GeoJsonPolygon, PolygonFill } from '../../components/mapbox-view'
import MensagemErro from '../../components/mensagem-erro'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import SelectInputForm from '../../components/select-input-form'
import TextArea from '../../components/text-area'
import TextInputForm from '../../components/text-input-form'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { showErrors } from '../../helpers'
import api from '../../services/api'
import fortalezaPolygon from '../../utils/fortaleza-polygon.json'
import PEPolygon from '../../utils/pe-polygon.json'
import PTpolygon from '../../utils/pt-polygon.json'
import RNPolygon from '../../utils/rn-polygon.json'

import './style.scss'
const colors = [
    '#FFC312',
    '#F79F1F',
    '#EE5A24',
    '#EA2027',
    '#C4E538',
    '#A3CB38',
    '#009432',
    '#006266',
    '#12CBC4',
    '#1289A7',
    '#0652DD',
    '#1B1464',
    '#FDA7DF',
    '#D980FA',
    '#9980FA',
    '#5758BB',
    '#ED4C67',
    '#B53471',
    '#833471',
    '#6F1E51',
    '#6E4480',
    '#7F8C8D',
    '#95A5A6',
    '#2C3E50',
]

const statusArray = [
    {
        name: 'Ativo',
        value: 1,
    },
    {
        name: 'Inativo',
        value: 0,
    },
]

const ChargeAreaCreation = ({ history, match: { params } }) => {
    const { basePath, mall } = useAuth()
    const { setMapBoxContentComponent, setMarkerCenter } = useContext(GlobalMapContext)
    const [loading, setLoading] = useState(false)
    const [areaServesMercadoo, setAreaServesMercadoo] = useState(false)
    const [modalMessage, setModalMessage] = useState({
        title: '',
        message: '',
        textButton: null,
        onClose: null,
    })
    const [errors, setErrors] = useState({})
    const [chargeAreas, setChargeAreas] = useState({ items: [] })
    const modalMessageRef = useRef()
    const modalDeleteRef = useRef()
    const [availableStores, setAvailableStores] = useState([])
    const [selectedStore, setSelectedStore] = useState()
    const [selectedStoreData, setSelectedStoreData] = useState({})
    const [selectOptions, setSelectOptions] = useState([])

    const [chargeArea, setChargeArea] = useState({
        label: '',
        mall_id: selectedStore?.length > 0 ? null : mall.id,
        store_id: selectedStore?.length > 0 ? selectedStore : null,
        description: '',
        area: [],
        rangeStart: 0,
        rangeEnd: 0,
        color: '',
        status: '',
    })

    const getSelectedStoreData = useCallback(async () => {
        if (selectedStore !== 'undefined' && selectedStore?.length > 0) {
            try {
                setLoading(true)
                const { data } = await api.get(`/painel/store/${selectedStore}`)

                setSelectedStoreData(data)
            } catch (error) {
                console.log('error', error)
            } finally {
                setLoading(false)
            }
        }
    }, [selectedStore])

    useEffect(() => {
        getSelectedStoreData()
    }, [selectedStore])

    const getAvailableStores = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/stores`, {
                params: {
                    mall_id: mall.id,
                    store_id: selectedStore?.id || null,
                    per_page: -1,
                },
            })

            setAvailableStores(data)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    useEffect(() => {
        getAvailableStores()
    }, [getAvailableStores])

    useEffect(() => {
        if (availableStores) {
            let available = []
            availableStores?.items?.map(item => available.push({ name: item.name, value: item.id }))

            setSelectOptions([{ name: mall?.name, value: 'mall' }, ...available])

            const select = document.getElementsByTagName('select')
            select[0].selectedIndex = 1
        }
    }, [availableStores])

    const handleSelect = value => {
        if (value === 'mall') {
            setSelectedStore(null)
            setSelectedStoreData(null)
        } else {
            setSelectedStore(value)
        }
    }

    const getChargeArea = useCallback(
        async areaId => {
            try {
                const { data } = await api.get(`painel/payment-area/${areaId}`, {
                    mall_id: selectedStore?.length > 0 ? null : mall.id,
                    store_id: selectedStore?.length > 0 ? selectedStore : null,
                    per_page: -1,
                    status: [0, 1],
                })

                setChargeArea({ ...data, rangeStart: data.range?.start, rangeEnd: data.range?.end })
                setAreaServesMercadoo(data.marketplace_enable !== undefined ? data.marketplace_enable : false)
            } catch (error) {
                setModalMessage({
                    title: 'Erro',
                    message: 'Ocorreu um erro ao carregar a área. Por favor, tente novamente mais tarde.',
                    onClose: () => history.push(`${basePath}/area-de-cobranca`),
                })
                modalMessageRef.current.openModal()
            }
        },
        [selectedStore, history]
    )

    useEffect(() => {
        if (params.id) getChargeArea(params.id)
        if (params.store !== undefined) {
            setSelectedStore(params.store)
        }
    }, [params.id, params.store, getChargeArea, selectedStore, history])

    const getChargeAreas = async () => {
        try {
            setLoading(true)
            if (params.store) {
                const { data } = await api.get(`/painel/payment-areas`, {
                    params: {
                        mall_id:
                            params.store && params.store !== 'undefined' && params.store !== undefined
                                ? null
                                : mall?.id,
                        store_id:
                            params.store && params.store !== 'undefined' && params.store !== undefined
                                ? params.store
                                : null,
                        per_page: -1,
                        status: [0, 1],
                    },
                })
                setChargeAreas(data)
            }
            if (selectedStore && selectedStore !== 'undefined' && selectedStore !== undefined) {
                const { data } = await api.get(`/painel/payment-areas`, {
                    params: {
                        store_id: selectedStore,
                        per_page: -1,
                        status: [0, 1],
                    },
                })
                setChargeAreas(data)
            }

            if (!params.store && !selectedStore) {
                const { data } = await api.get(`/painel/payment-areas`, {
                    params: {
                        mall_id: mall?.id,
                        per_page: -1,
                        status: [0, 1],
                    },
                })
                setChargeAreas(data)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getChargeAreas()
    }, [selectedStore, params.store, params.id, history])

    const deleteArea = async () => {
        try {
            setLoading(true)
            await api.delete(`painel/payment-area/${params.id}`)

            setModalMessage({
                title: 'Sucesso',
                message: 'Área de cobrança excluída com sucesso!',
                onClose: () => history.push(`${basePath}/area-de-cobranca`),
            })
            modalMessageRef.current.openModal()
        } catch (error) {
            setModalMessage({
                title: 'Erro',
                message: showErrors(error),
            })
            modalMessageRef.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    function validatePolygonCircle(rangeStart, rangeEnd) {
        return isNaN(rangeStart) || isNaN(rangeEnd) || rangeEnd <= rangeStart ? false : true
    }

    function getPolygonCircleData(rangeStart = 0, rangeEnd = 1000, color) {
        if (!validatePolygonCircle(rangeStart, rangeEnd)) return null

        const center = [selectedStoreData?.lng || mall.address?.lng, selectedStoreData?.lat || mall.address?.lat]

        let poligon
        if (mall.address.city.state.uf === 'PE') {
            poligon = PEPolygon
        }
        if (mall.address.city.state.uf === 'RN') {
            poligon = RNPolygon
        }
        if (mall.address.city.name === 'Fortaleza') {
            poligon = fortalezaPolygon
        }
        if (mall.address.complement === 'PORTUGAL') {
            poligon = PTpolygon
        }

        const properties = {
            color,
            opacity: 0.5,
        }

        const circleOptions = { steps: 100, units: 'meters' }

        const outerCirclePolygon = circle(center, rangeEnd, {
            ...circleOptions,
            properties,
        })

        if (rangeStart > 0) {
            const innerCirclePolygon = circle(center, rangeStart, {
                ...circleOptions,
            })

            const donutPolygon = difference(outerCirclePolygon, innerCirclePolygon)
            if (poligon) {
                return intersect(donutPolygon, poligon, { properties })
            }
            return donutPolygon
        }
        if (poligon) {
            return intersect(outerCirclePolygon, poligon, { properties })
        }
        return outerCirclePolygon
    }

    const validateFields = () => {
        const { label, color, status, rangeStart, rangeEnd } = chargeArea

        let errors = {}

        if (label.length < 3) errors.label = 'O nome deve ter ao menos 3 caracteres'
        if (!color) errors.color = 'Selecione uma cor'
        if (Number(status) !== 1 && Number(status) !== 0) errors.status = 'Selecione um status'

        setErrors(errors)

        if (!validatePolygonCircle(Number(rangeStart), Number(rangeEnd))) {
            setModalMessage({
                title: 'Erro',
                message: 'Preencha com o Raio inicial e o Raio final para criação da área de cobrança',
            })

            modalMessageRef.current.openModal()
        }

        return JSON.stringify(errors) === '{}'
    }

    const handleSubmit = async () => {
        try {
            if (!validateFields()) return

            setLoading(true)

            const polygonCircleData = getPolygonCircleData(
                Number(chargeArea.rangeStart),
                Number(chargeArea.rangeEnd),
                chargeArea.color
            )

            const body = {
                label: chargeArea.label,
                description: chargeArea.description,
                mall_id: selectedStore && selectedStore !== undefined && selectedStore !== 'undefined' ? null : mall.id,
                store_id:
                    selectedStore && selectedStore !== undefined && selectedStore !== 'undefined'
                        ? selectedStore
                        : null,
                area: polygonCircleData.geometry,
                marketplace_enable: areaServesMercadoo ? 1 : 0,
                range: {
                    start: Number(chargeArea.rangeStart),
                    end: Number(chargeArea.rangeEnd),
                },
                color: chargeArea.color,
                status: chargeArea.status,
            }

            if (params.id) await api.put(`painel/payment-area/${params.id}`, body)
            else await api.post(`painel/payment-area`, body)

            setModalMessage({
                title: 'Sucesso',
                message: params.id ? 'Área de cobrança editada com sucesso!' : 'Área de cobrança criada com sucesso!',
                textButton: 'Continuar',
                onClose: () => history.push(`${basePath}/area-de-cobranca`),
            })

            modalMessageRef.current.openModal()
        } catch (error) {
            console.log({ error })
            setModalMessage({
                title: 'Erro',
                message: showErrors(error),
                textButton: 'Revisar alterações',
            })

            modalMessageRef.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    const onColorChange = value => {
        handleChange('color', value)
    }

    const handleChange = (key, value) => {
        setChargeArea({ ...chargeArea, [key]: value })
    }

    useEffect(() => {
        if (selectedStoreData?.lng && selectedStoreData?.lat) {
            setMarkerCenter([selectedStoreData.lng, selectedStoreData.lat])
            return
        }
        if (mall?.address) {
            setMarkerCenter([mall.address?.lng, mall.address?.lat])
            return
        }
    }, [selectedStoreData, mall])

    const _renderMapContent = useCallback(() => {
        return (
            <>
                <GeoJsonPolygon
                    id="polygon-circle"
                    geoJsonData={getPolygonCircleData(
                        Number(chargeArea.rangeStart),
                        Number(chargeArea.rangeEnd),
                        chargeArea.color
                    )}
                />

                <PolygonFill
                    showPopUpArea
                    id="charge-area-creation"
                    areaType="charge-area-creation"
                    areas={
                        chargeArea?.id ? chargeAreas.items.filter(item => item.id !== chargeArea.id) : chargeAreas.items
                    }
                    editionArea={chargeArea?.id ? { area: chargeArea.area, id: chargeArea.id } : undefined}
                    editionColor={chargeArea.color}
                    defaultColor="#333"
                    defaultOpacity={0.4}
                />
            </>
        )
    }, [chargeArea, chargeAreas])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
    }, [_renderMapContent])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="coverage-area-creation-page-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessageRef}
                isActive={modalMessage.isActive}
                title={modalMessage.title}
                message={modalMessage.message}
                textButton={modalMessage.textButton}
                onClose={modalMessage.onClose}
            />

            <ModalConfirmation
                ref={modalDeleteRef}
                title="Excluir Área"
                message="Deseja mesmo excluir esta área de cobrança?"
                onYes={deleteArea}
            />

            <TopRowDefault
                title="Área de Cobrança"
                onBackButtonClick={() => history.push(`${basePath}/area-de-cobranca`)}
                buttons={
                    params.id && [
                        {
                            title: 'Excluir Área',
                            onClick: () => modalDeleteRef.current.openModal(),
                        },
                    ]
                }
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title">
                            {params.id ? 'Edição' : 'Criação'} -{' '}
                            {selectedStoreData?.name ? selectedStoreData?.name : mall?.name}
                        </div>

                        {!params?.id && (
                            <SelectInputForm
                                label="Estabelecimento"
                                placeholder="Filtrar por estabelecimento"
                                data={selectOptions}
                                onChange={({ target: { value } }) => {
                                    handleSelect(value)
                                }}
                                value={selectedStore}
                            />
                        )}

                        <TextInputForm
                            label="Nome*"
                            value={chargeArea.label}
                            onChange={e => handleChange('label', e.target.value)}
                            msgErro={errors.label}
                        />

                        <TextArea
                            label="Descrição (opcional)"
                            value={chargeArea.description || ''}
                            onChange={e => handleChange('description', e.target.value)}
                        />

                        <TextInputForm
                            label="Raio inicial em metros*"
                            type="number"
                            min="0"
                            step="500"
                            onChange={({ target: { value } }) => {
                                const { rangeStart, rangeEnd } = chargeArea
                                const valueDifference = Math.abs(Number(value) - Number(rangeStart))

                                if (Number(value) >= Number(rangeEnd) || (!rangeEnd && valueDifference === 500)) {
                                    setChargeArea({
                                        ...chargeArea,
                                        rangeStart: value,
                                        rangeEnd: Number(value) + 500,
                                    })
                                } else {
                                    handleChange('rangeStart', value)
                                }
                            }}
                            value={chargeArea.rangeStart}
                        />

                        <TextInputForm
                            label="Raio final em metros*"
                            type="number"
                            min="500"
                            step="500"
                            onChange={({ target: { value } }) => {
                                const { rangeStart, rangeEnd } = chargeArea
                                const valueDifference = Math.abs(Number(value) - Number(rangeEnd))

                                if (Number(value) <= Number(rangeStart) && valueDifference === 500) {
                                    setChargeArea({
                                        ...chargeArea,
                                        rangeEnd: value,
                                        rangeStart: Number(rangeStart) - 500,
                                    })
                                } else if (!rangeStart) {
                                    setChargeArea({
                                        ...chargeArea,
                                        rangeStart: 0,
                                        rangeEnd: value,
                                    })
                                } else {
                                    handleChange('rangeEnd', value)
                                }
                            }}
                            value={chargeArea.rangeEnd}
                        />

                        <div className="colors-container">
                            {colors.map((item, index) => (
                                <label
                                    key={item}
                                    htmlFor={index}
                                    className="color-square"
                                    style={{ backgroundColor: item }}
                                >
                                    <input
                                        type="radio"
                                        name="charge-area-color"
                                        id={index}
                                        checked={chargeArea.color === item}
                                        onChange={() => onColorChange(item)}
                                    />
                                </label>
                            ))}
                        </div>

                        <MensagemErro msgErro={errors.color} />

                        <div style={{ width: 200 }}>
                            <SelectInputForm
                                label="Status"
                                data={statusArray}
                                value={chargeArea.status}
                                onChange={e => handleChange('status', e.target.value)}
                                msgErro={errors.status}
                            />
                        </div>
                        {/* <div style={{ marginTop: 30 }}>
                            <Checkbox
                                checkboxTitle="A área atende ao Mercadoo"
                                isSquared
                                inputProps={{
                                    onChange: () => {
                                        setAreaServesMercadoo(!areaServesMercadoo)
                                    },
                                    value: areaServesMercadoo,
                                }}
                            />
                        </div> */}

                        <ButtonForm buttonText="Salvar" onClick={handleSubmit} style={{ width: '100%' }} />
                    </div>

                    <div className="right-column">
                        <MirrorMap />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChargeAreaCreation
