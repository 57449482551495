import styled from 'styled-components'

export const StoresListingContainer = styled.div`
    margin-top: 50px;
`

export const StoresListingTitle = styled.div`
    color: #666;
    font-weight: bold;
    margin-bottom: 20px;
`

export const StoresError = styled.div`
    font-size: 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    font-weight: bold;
    letter-spacing: 2px;
`

export const EmptyMessage = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
`

export const EmptyMessageTitle = styled.div`
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
`

export const EmptyMessageSubtitle = styled.div`
    font-size: 16px;
`
