import { faCog, faThLarge, faSearch, faTimes, faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import deliverer from '../../../../assets/images/deliverer-icon.png'
import mercadoo from '../../../../assets/images/mercadoo.png'
import plug from '../../../../assets/images/plug.png'
import router from '../../../../assets/images/router.png'

export const MobileMenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 70px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 40%;
`

export const MenuTitle = styled.div`
    font-weight: 600;
    margin-left: 15px;
    flex: 1;
`

export const RightSide = styled.div`
    display: flex;
    align-items: center;
    padding-right: 20px;
    width: 40%;
    justify-content: flex-end;
`

export const SettingIcon = styled(FontAwesomeIcon).attrs({
    icon: faCog,
})`
    cursor: pointer;
`

export const PlatformIcon = styled(FontAwesomeIcon).attrs({
    icon: faThLarge,
})`
    cursor: pointer;
`

export const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    margin-left: 10px;
    cursor: pointer;
`

export const ContextIcon = styled(FontAwesomeIcon).attrs({
    icon: faStoreAlt,
})`
    cursor: pointer;
`

export const NavigationItemContainer = styled.div`
    margin-left: 20px;
    font-size: 18px;
    color: #717171;
    position: relative;
`

export const PlatformDropdown = styled.div<{ isActive?: boolean }>`
    user-select: none;
    width: 180px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 6px;
    padding-bottom: 10px;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    padding-top: 20px;
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: #ccc;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    user-select: none;
`

export const PlatformListing = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const PlatformItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 40%;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 5px;
    transition: 400ms;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`

export const PlatformText = styled.div`
    font-size: 10px;
    margin-top: 5px;
`

export const MercadooImage = styled.img.attrs({
    src: mercadoo,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const PlugImage = styled.img.attrs({
    src: plug,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const RouterImage = styled.img.attrs({
    src: router,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const DelivererImage = styled.img.attrs({
    src: deliverer,
})`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

export const MallManagementCall = styled.div`
    text-align: center;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.lightGray};
    padding-top: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const SearchInput = styled.div<{ isActive?: boolean }>`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    z-index: 90;
    background-color: #fff;
    transform: translateX(${({ isActive }) => (isActive ? 0 : '-600%')});
    transition: 400ms;
`

export const BorderedInput = styled.input.attrs({
    placeholder: 'Buscar...',
})`
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    background-color: transparent;
    width: 90%;
    margin-left: 5%;
    outline: none;
    padding-top: 10px;
    font-size: 16px;
`

export const SpanId = styled.span`
    font-size: 12px;
    color: #c1c1c1;
    margin-left: 5px;
`
