import { useEffect, useState } from 'react'

import Checkbox from '../../_common/checkbox/checkbox'
import ChannelItem from '../channel-item/channel-item'
import { ButtonsContainer, PrimaryButton, TextButton } from '../route-creation-content/route-creation-content.styles'

import {
    ChannelsOrder,
    FilterHeader,
    FilterModalBackground,
    FilterModalBox,
    FilterModalContainer,
    FilterModalContent,
    CloseButton,
    FilterTitle,
    FilterBox,
    FilterOrderContainer,
    FilterOrderButton,
    OrderIcon,
    CheckboxListing,
    ListItem,
} from './filter-modal.styles'
import { stores, payments, channels } from './mock-filter'

interface Props {
    isActive: boolean
    closeClick(): void
}

const FilterModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [isButtonActive, setIsButtonActive] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <FilterModalContainer isDisplayed={isDisplayed}>
            <FilterModalBox isEffectActive={isEffectActive}>
                <FilterHeader>
                    <CloseButton onClick={closeClick} />
                    Filtrar Pedidos
                </FilterHeader>
                <FilterModalContent>
                    <FilterBox>
                        <FilterTitle>Ordem</FilterTitle>
                        <FilterOrderContainer>
                            <FilterOrderButton isActive={!isButtonActive} onClick={() => setIsButtonActive(false)}>
                                <OrderIcon /> Maior tempo total primeiro
                            </FilterOrderButton>
                            <FilterOrderButton isActive={isButtonActive} onClick={() => setIsButtonActive(true)}>
                                <OrderIcon /> Menor tempo total primeiro
                            </FilterOrderButton>
                        </FilterOrderContainer>
                    </FilterBox>
                    <FilterBox>
                        <FilterTitle>Lojas</FilterTitle>
                        <CheckboxListing>
                            {stores.map((item, index) => (
                                <ListItem key={index}>
                                    <Checkbox isSquared checkboxTitle={item} />
                                </ListItem>
                            ))}
                        </CheckboxListing>
                    </FilterBox>
                    <FilterBox>
                        <FilterTitle>Formas de Pagamento</FilterTitle>
                        <CheckboxListing>
                            {payments.map((item, index) => (
                                <ListItem key={index}>
                                    <Checkbox isSquared checkboxTitle={item} />
                                </ListItem>
                            ))}
                        </CheckboxListing>
                    </FilterBox>
                    <FilterBox>
                        <FilterTitle>Canais de Venda</FilterTitle>
                        <ChannelsOrder>
                            <ListItem>
                                <Checkbox checkboxTitle={'Todos os Canais'} />
                            </ListItem>
                            <ListItem>
                                <Checkbox checkboxTitle={'Especificar canais'} />
                            </ListItem>
                        </ChannelsOrder>
                        <CheckboxListing>
                            {channels.map((item, index) => (
                                <ChannelItem key={index} image={item.logo} />
                            ))}
                        </CheckboxListing>
                    </FilterBox>
                </FilterModalContent>
                <ButtonsContainer>
                    <TextButton>Limpar Filtro</TextButton>
                    <PrimaryButton>Aplicar filtro</PrimaryButton>
                </ButtonsContainer>
            </FilterModalBox>
            <FilterModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </FilterModalContainer>
    )
}

export default FilterModal
