import { faEdit, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const DetailsTabContainer = styled.div`
    .container-canvas {
        min-height: 400px;
        margin-bottom: 20px;
    }
`

export const PickerContainer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 300px;
    }
`

export const PickerPhoto = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #eee;
    overflow: hidden;
    object-fit: cover;
`

export const PickerTextContainer = styled.div`
    margin-left: 20px;
    flex: 1;
`

export const PickerTitle = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 5px;
`

export const PickerName = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 3px;
`
export const Takeout = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
`

export const PickerPhone = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
`

export const PhoneIcon = styled(FontAwesomeIcon).attrs({
    icon: faPhoneAlt,
})`
    margin-right: 5px;
`

export const InfoSection = styled.div`
    margin-bottom: 20px;
`

export const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
`

export const InfoRow = styled.div`
    display: flex;
    align-items: flex-start;
`

export const MapContainer = styled.div`
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border: 1px solid #f1f1f1;
    margin-bottom: 20px;
`

export const ScheduleButton = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    text-align: center;
    border-radius: 4px;
    padding: 10px 60px;
    display: inline-flex;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const EditIcon = styled(FontAwesomeIcon).attrs({
    icon: faEdit,
})`
    margin-left: 20px;
    position: absolute;
    top: 10px;
    right: 5px;
`

export const CollectButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    transition: 400ms;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const Tag = styled.div`
    background-color: ${({ theme }) => theme.colors.alert}66;
    padding: 2px 6px;
    width: fit-content;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 0.5rem;
`
export const PointTag = styled.div`
    background-color: ${({ theme }) => theme.colors.alert};
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 4px;
`
