import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 9999999999999999999999999;
`

const Box = styled.div<{ show?: boolean }>`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 80%;
    max-width: 80%;
    overflow: auto;
    position: relative;
    .container-buttons {
        .button {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    ${({ show }) =>
        show
            ? css`
                  animation-name: show;
              `
            : css`
                  animation-name: close;
              `}
    transition: 400ms;
    animation-duration: 400ms;
`

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const CloseIcon = styled(FontAwesomeIcon).attrs({
    icon: 'times',
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    cursor: pointer;
`

export { Box, Container, Content, CloseIcon }
