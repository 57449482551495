import styled from 'styled-components'

export const SwitchButtonContainer = styled.div<{ isFlex?: boolean; justify?: string }>`
    cursor: pointer;
    user-select: none;
    display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
    align-items: center;
    justify-content: ${({ justify }) => justify ?? 'start'};
`

export const RoundedContainer = styled.div<{ isActive?: boolean }>`
    width: 30px;
    height: 12px;
    border-radius: 20px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.errorMessage)};
    position: relative;
    transition: 400ms;
`

export const Circle = styled.div<{ isActive?: boolean }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -4px;
    left: 0;
    transition: 400ms;
    transform: translateX(${({ isActive }) => (isActive ? '60%' : 0)});
`

export const ButtonText = styled.div<{ isFlex?: boolean }>`
    font-size: ${({ isFlex }) => (isFlex ? '16px' : '10px')};
    text-transform: ${({ isFlex }) => (isFlex ? 'normal' : 'uppercas')};
    margin-top: ${({ isFlex }) => (isFlex ? '0px' : '5px')};
    margin-left: ${({ isFlex }) => (isFlex ? '10px' : '0px')};
`
