import { FC, memo, useCallback } from 'react'

import { IWeekDay, IWeekDays } from 'types/scheduledPeriods'

import { BoxDayWeek, ContainerDayWeek } from './week-days-select.styled'

type Props = {
    weekdays: IWeekDays
    inForm?: boolean
    onClick?: (key: IWeekDay) => void
}

const WeekDaysSelect: FC<Props> = memo(({ weekdays, inForm, onClick }) => {
    if (!weekdays) return null
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = weekdays

    const _handleClick = useCallback(
        (key: IWeekDay) => () => {
            if (onClick) {
                onClick(key)
            }
        },
        [onClick]
    )

    return (
        <ContainerDayWeek inForm={inForm}>
            <BoxDayWeek onClick={_handleClick('monday')} active={monday}>
                S
            </BoxDayWeek>
            <BoxDayWeek onClick={_handleClick('tuesday')} active={tuesday}>
                T
            </BoxDayWeek>
            <BoxDayWeek onClick={_handleClick('wednesday')} active={wednesday}>
                Q
            </BoxDayWeek>
            <BoxDayWeek onClick={_handleClick('thursday')} active={thursday}>
                Q
            </BoxDayWeek>
            <BoxDayWeek onClick={_handleClick('friday')} active={friday}>
                S
            </BoxDayWeek>
            <BoxDayWeek onClick={_handleClick('saturday')} active={saturday}>
                S
            </BoxDayWeek>
            <BoxDayWeek onClick={_handleClick('sunday')} active={sunday}>
                D
            </BoxDayWeek>
        </ContainerDayWeek>
    )
})

export { WeekDaysSelect }
