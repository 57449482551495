import { useCallback, useRef, useState } from 'react'

import { LateralModalBase } from 'modals'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import { marketplaceV1 } from 'services/api'
import { TAttendanceDetail } from 'types/postpaid'

function useAttendanceDetailModalController() {
    const { store } = useAuth()
    const { setErrorModal, setSuccessModal, setLoading } = useUI()

    const lateralModalBaseRef = useRef<LateralModalBase>()

    const [attendance, setAttendance] = useState<TAttendanceDetail>()

    const _getDetails = useCallback(
        async (id: number) => {
            setLoading(true)
            try {
                const { data } = await marketplaceV1.get<TAttendanceDetail>(`/attendances/${id}`, {
                    params: {
                        stores: [store.id],
                    },
                })
                setAttendance(data)
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível consultar as informações do pedido',
                })
            } finally {
                setLoading(false)
            }
        },
        [store]
    )

    return { attendance, lateralModalBaseRef, _getDetails }
}
export { useAttendanceDetailModalController }
