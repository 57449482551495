import { memo } from 'react'

import { formatCurrency } from 'helpers'
import { IOrderIntegrationList } from 'types/order-integration-list'

import {
    OrderSummaryContainer,
    OrderSummaryCustomer,
    OrderSummaryId,
    OrderSummaryInfo,
} from './order-integration-summary.styled'

interface Props {
    order: IOrderIntegrationList
}

const OrderIntegrationSummary: React.FC<Props> = memo(({ order }) => {
    const { header, owner, customer } = order

    return (
        <OrderSummaryContainer>
            <OrderSummaryId>Pedido #{order.id}</OrderSummaryId>
            <OrderSummaryCustomer>{customer?.full_name || owner?.name}</OrderSummaryCustomer>
            <OrderSummaryInfo>{formatCurrency(header.total_price)}</OrderSummaryInfo>
        </OrderSummaryContainer>
    )
})

export { OrderIntegrationSummary }
