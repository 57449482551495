import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Container, Content, ContainerIcon, Label, Path, Message, Link, Row } from './tracker-code.styled'
type Props = {
    orderId: string | number
    simple?: boolean
}

const TrackerCode: React.FC<Props> = memo(({ orderId, simple }) => {
    const [code, setCode] = useState<string>()
    const [hasCopy, setHasCopy] = useState(false)

    const url = useMemo(() => {
        if (code) {
            return `${process.env.REACT_APP_TRACKER_URL}/${code}`
        }
        return null
    }, [code])

    const _getCode = useCallback(async (orderId: number | string) => {
        const id = orderId.toString()
        const hash = baseConvert(id, 10, 36)
        setCode(hash)
    }, [])

    const _copy = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()
            if (url) {
                setHasCopy(true)
                navigator.clipboard.writeText(url)
                setTimeout(() => {
                    setHasCopy(false)
                }, 3000)
            }
        },
        [url]
    )

    const _open = useCallback(() => {
        window.open(url, '_blank')
    }, [url])

    useEffect(() => {
        if (orderId) {
            _getCode(orderId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])

    if (!url) {
        return null
    }

    return (
        <div className="tracker" style={{ width: 'fit-content' }} onClick={_copy}>
            <Container hasCopy={hasCopy} simple={simple}>
                <Content className="content" simple={simple}>
                    <ContainerIcon className="icon" simple={simple}>
                        <FontAwesomeIcon icon="clone" />
                    </ContainerIcon>
                    {!simple && <Label>Rastreio:</Label>}
                    <Path>{simple ? (url || '').replace('https://', '') : url}</Path>
                </Content>
            </Container>
            {simple ? (
                <Message show={hasCopy}>Link copiado!</Message>
            ) : (
                <Row>
                    <Message show={hasCopy}>Link copiado!</Message>
                    <Link onClick={_open}>
                        <span>
                            <FontAwesomeIcon icon="external-link-alt" />
                        </span>
                        Abrir rastreio em nova aba
                    </Link>
                </Row>
            )}
        </div>
    )
})

function baseConvert(value: string, fromBase: number, toBase: number): string {
    if ((fromBase && toBase) < 2 || (fromBase && toBase) > 36) {
        throw new Error('Argument must be between 2 and 36')
    }

    return parseInt(value, fromBase).toString(toBase)
}

export { TrackerCode }
