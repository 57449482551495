import Axios from 'axios'

import { PagedList } from 'types'
import { ISettings } from 'types/settings'

const baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`

async function getTokenPlooga() {
    const token = localStorage.getItem('@extranet.logaroo:token')
    const alternativeAPI = Axios.create({ baseURL })

    alternativeAPI.interceptors.response.use(
        async response => response.data,
        error => Promise.reject(error)
    )

    if (token) {
        try {
            const { data } = await alternativeAPI.get<PagedList<ISettings>>(`/painel/settings`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            const setting = data.items.find(item => item.name === 'plooga')

            return setting.values.token as string
        } catch (error) {
            console.log('error', error)
        }
    }
}

export { getTokenPlooga }
