import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react'
import * as Feather from 'react-feather'
import { useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, subDays } from 'date-fns'
import { useFormik } from 'formik'
import { Marker } from 'mapbox-gl'
import { ModalFile, ModalFileRef } from 'modals/modal-file/modal-file'
import { mutate } from 'swr'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import { CollectItem } from 'components/collect-item/collect-item'
import { FileItem } from 'components/file-item/file-item'
import { InputPlace } from 'components/input-place/input-place'
import OrderItemsTable from 'components/order-items-table/order-items-table'

import { useUI } from 'contexts'
import { formatOrderItemsToString, formatToOrderItems, getFormInputError, showErrors } from 'helpers'
import { useAuth, useCEPSearch, useGeocodingSearch, useMapboxView } from 'hooks'
import api from 'services/api'
import { IAttachment, IDeliveryOrigin, Order, PagedList, Payment, SalesChannel } from 'types'
import { IAddress } from 'types/address'
import { transformFormData } from 'utils/transform-formdata'

import {
    ContentContainer,
    ContentTitle,
    FormSection,
    FormSectionTitle,
    InputContainer,
    SmallTextButton,
    ButtonItem,
    InputsRow,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
    HeaderContainer,
    MapContainer,
    LocateButton,
} from './manager-order-content.styled'

interface Props {
    onClose(type?: 'add' | 'update'): void
    isImport?: boolean
    order?: Order
}

const ManagerOrderContent: React.FC<Props> = memo(({ onClose, order, isImport }) => {
    const { ...location } = useLocation()
    const modalFileRef = useRef<ModalFileRef>()
    const draggableMarkerRef = useRef<Marker>()

    const { mall, store } = useAuth()
    const { setErrorModal, setLoading, setSnackbar, setConfirmationModal } = useUI()

    const [showAllAddress, setShowAllAddress] = useState(false)
    const [isCollected, setIsCollected] = useState(false)

    const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([])
    const [payments, setPayment] = useState<Payment[]>([])
    const [allocatedAttachments, setAllocatedAttachments] = useState<IAttachment[]>([])
    const [storeOrigins, setStoreOrigins] = useState<IDeliveryOrigin[]>([])

    const isEdit = useMemo(() => {
        return !!order && !isImport
    }, [order, isImport])

    const {
        errors,
        handleSubmit,
        initialValues,
        isValid,
        getFieldProps,
        setFieldError,
        setFieldValue,
        setValues,
        touched,
        values,
    } = useFormik({
        initialValues: {
            sale_channel: '',
            reference_id: '',
            payment_code: '',
            total_price: '',
            customer_name: '',
            customer_phone: '',
            customer_email: '',
            zipcode: '',
            request_collect_attachments: [],
            neighborhood: '',
            street: '',
            number: '',
            complement: '',
            landmark: '',
            lat: null as unknown,
            lng: null as unknown,
            items: '',
            observation: '',
            is_forecast: false,
            delivery_forecast: {
                date: '',
                time: '',
            },
            collect: {
                observations: '',
                confirmation_required: false,
                confirmation_delivery: false,
                confirmation_fail: false,
            },
            delivery_origin: null as IDeliveryOrigin,
            delivery_origins: [] as IDeliveryOrigin[],
            status: '',
        },
        validationSchema: Yup.object().shape({
            sale_channel: Yup.string().trim().required('Por favor, selecione um Canal de venda.'),
            payment_code: Yup.string().trim().required('Por favor, selecione uma Forma de pagamento.'),
            total_price: Yup.string().trim().required('Por favor, insira o valor do pedido.'),
            customer_name: Yup.string().trim().required('Por favor, insira o Nome do cliente'),
            customer_email: Yup.string().nullable().trim().email('Deve ser um email válido'),
            zipcode: Yup.string().trim().min(8, 'CEP deve ter 8 números').required('Por favor, insira o CEP.'),
            street: Yup.string().trim().required('Por favor, insira o Logradouro'),
            number: Yup.string().trim().required('Por favor, insira o Número'),
            location_changed: Yup.bool().oneOf([true], ''),
            customer_phone: Yup.string()
                .trim()
                .min(10, 'Telefone deve ter 11 números')
                .required('Por favor, insira o Telefone'),
            items: Yup.string().trim().required('Por favor, insira os items do pedido.'),
            delivery_forecast: Yup.object().when('is_forecast', {
                is: is_forecast => is_forecast,
                then: (schema: Yup.ObjectSchema) =>
                    schema.shape({
                        date: Yup.date()
                            .min(subDays(new Date(), 1), 'Não é possível agendar um pedido em uma data passada.')
                            .required('Por favor, insira a Data'),
                        time: Yup.string()
                            .trim()
                            .when('date', (date: Date, schema: Yup.StringSchema) => {
                                return schema.test({
                                    test: time => {
                                        if (time) {
                                            const [hour, minutes] = time?.split(':')
                                            date.setHours(hour)
                                            date.setMinutes(minutes)

                                            const currentDate = new Date()

                                            return date <= currentDate ? false : true
                                        } else {
                                            return false
                                        }
                                    },
                                    message: time =>
                                        time?.value
                                            ? 'Não é possível agendar um pedido em horários passados.'
                                            : 'Por favor, insira o Horário',
                                })
                            }),
                    }),
            }),
        }),
        onSubmit: async values => {
            try {
                setLoading(true)

                const delivery_forecast = values.is_forecast
                    ? `${values.delivery_forecast.date} ${
                          values.delivery_forecast.time + (values.delivery_forecast.time?.length < 8 ? ':00' : '')
                      }`
                    : undefined

                let delivery_origin = values.delivery_origin
                let delivery_origins = values.delivery_origins

                if (delivery_origins?.length > 0 && delivery_origin) {
                    delivery_origins = [...delivery_origins, delivery_origin]
                    delivery_origin = null
                }

                if (delivery_origins?.length === 0) {
                    delivery_origins = null
                }

                if (delivery_origin?.id === store?.id) {
                    delivery_origin = null
                }

                const body = {
                    ...values,
                    sale_channel: undefined,
                    reference_name: values.sale_channel,
                    state: mall.address.city.state.id,
                    city: mall.address.city.id,
                    neighborhood: values.neighborhood,
                    customer_id_number: Math.random() * (999999 - 100000) + 100000,
                    items: formatToOrderItems(values.items),
                    delivery_forecast,
                    merchant_id: store.id,
                    origin: 'Logaroo Extranet',
                    status: values.is_forecast ? 1 : 3,
                    sub_total: 0,
                    total_price: Number(values.total_price),
                    birth: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                    delivery_origin,
                    delivery_origins,
                }

                if (isEdit) {
                    const { data } = await api.put(`painel/dm-order/${order.id}`, {
                        ...body,
                        status: values.is_forecast ? 1 : values.status,
                    })

                    mutate(`painel/dm-order/${order.id}`, data)
                } else {
                    await api.post(
                        delivery_origins?.length > 0 ? 'painel/order/import-multiple' : 'painel/order/import',
                        transformFormData(body),
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    )
                }

                setSnackbar({ message: isEdit ? 'Pedido atualizado' : 'Entrega solicitada' })
                _onClose(isEdit ? 'update' : 'add')
            } catch (error) {
                setErrorModal({
                    title: isEdit ? 'Erro ao atualizar pedido' : 'Erro ao solicitar entrega',
                    subtitle: showErrors(error),
                })
            } finally {
                setLoading(false)
            }
        },
    })

    const stringAddress = useMemo(() => {
        if (values.street && values.number && values.neighborhood && values.zipcode) {
            return `${values.street}, ${values.number} - ${values.neighborhood} (${values.zipcode})`
        }
    }, [values])

    const optionsOrigins = useMemo(() => {
        return (storeOrigins || []).map(item => ({ label: item.name, value: item.id }))
    }, [storeOrigins])

    const availableOrigins = useMemo(() => {
        const selectedOriginsId = (values?.delivery_origins || []).map(item => Number(item.id))
        return optionsOrigins.filter(item => !selectedOriginsId.includes(Number(item.value)))
    }, [optionsOrigins, values.delivery_origins])

    const enableAddMoreOrigins = useMemo(() => {
        return availableOrigins.length - 1 > 0
    }, [availableOrigins])

    const { map, mapContainerRef, addDraggableMarker } = useMapboxView({
        zoom: 13,
        onLoad: map => {
            if (!location?.state) {
                return
            }
            const { order } = location?.state as { order: Order }
            if (order?.address) {
                const { lat, lng } = order.address
                if (lat && lng) {
                    const draggableMarker = draggableMarkerRef.current

                    if (draggableMarker && map) {
                        draggableMarker.setLngLat({ lng, lat })
                        map.flyTo({ center: { lat, lng } })
                    } else {
                        const marker = addDraggableMarker({ lat, lng }, ({ lat, lng }) => {
                            console.log('lat', lat)
                            console.log('lng', lng)
                        })
                        draggableMarkerRef.current = marker
                    }
                }
            }
        },
    })

    const { getAddressByCEP } = useCEPSearch({
        onSuccess: response => {
            setValues({
                ...values,
                neighborhood: response.neighborhood,
                street: response.street,
                zipcode: response.cep,
            })
        },
        onError: error => setFieldError('zipcode', error.message),
    })

    const { getLatLngByAddress } = useGeocodingSearch({
        onSuccess: ({ lat, lng }) => {
            const draggableMarker = draggableMarkerRef.current
            if (draggableMarker && map) {
                draggableMarker.setLngLat({ lng, lat })
                map.flyTo({ center: { lat, lng } })
            } else {
                const marker = addDraggableMarker({ lat, lng }, ({ lat, lng }) => setValues({ ...values, lat, lng }))
                draggableMarkerRef.current = marker
            }

            setValues({ ...values, lat, lng })
        },
    })

    const _getSalesChannels = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<SalesChannel>>('/painel/sales-channels', {
                params: { status: [1] },
            })
            setSalesChannels(data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [])
    const _getPayments = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<Payment>>('/painel/payments')
            setPayment(data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    const _setAddress = useCallback(
        (address: IAddress) => {
            if (address) {
                const lat = Number(address.location.lat || '')
                const lng = Number(address.location.lng || '')
                setFieldValue('street', address.street || '')
                setFieldValue('number', address.number || '')
                setFieldValue('neighborhood', address.neighborhood || '')
                setFieldValue('zipcode', (address.zipcode || '').replace(/\D/g, ''))
                setFieldValue('lat', lat || null)
                setFieldValue('lng', lng || null)

                const draggableMarker = draggableMarkerRef.current
                if (draggableMarker && map) {
                    draggableMarker.setLngLat({ lng, lat })
                    map.flyTo({ center: { lat, lng } })
                } else {
                    const marker = addDraggableMarker({ lat, lng }, ({ lat, lng }) => {
                        console.log('lat', lat)
                        console.log('lng', lng)
                    })
                    draggableMarkerRef.current = marker
                }

                setShowAllAddress(true)
            }
        },
        [setFieldValue, addDraggableMarker, map]
    )

    const _addOrigin = useCallback(() => {
        if (values.delivery_origin) {
            const delivery_origins = values.delivery_origins || []
            setFieldValue('delivery_origins', [...delivery_origins, values.delivery_origin])
        }
        setFieldValue('delivery_origin', null)
    }, [values.delivery_origin, values.delivery_origins, setFieldValue])

    const _removeOrigin = useCallback(
        (id: number) => {
            if (id) {
                setFieldValue(
                    'delivery_origins',
                    (values.delivery_origins || []).filter(item => item.id !== id)
                )
            }
        },
        [values.delivery_origins, setFieldValue]
    )

    const _addDoc = useCallback(
        async (files: any) => {
            for (let i = 0; i < files.length; i++) {
                const file = files[i]

                const fileSize = file.size / 1024 / 1024
                if (fileSize > 50) {
                    setErrorModal({
                        title: 'Anexo grande de mais',
                        subtitle: 'O Anexo não pode passar de 50MB',
                    })

                    return
                }
            }
            if (isEdit) {
                setLoading(true)

                try {
                    const { data } = await api.post<IAttachment[]>(
                        `/painel/order/${order.id}/collect-attachment`,
                        transformFormData({ files })
                    )
                    setAllocatedAttachments([...allocatedAttachments, ...data])
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao adicionar anexo',
                        subtitle: showErrors(error),
                    })
                }
                setLoading(false)
            } else {
                setFieldValue('request_collect_attachments', [...values.request_collect_attachments, ...files])
            }
        },
        [
            isEdit,
            order?.id,
            allocatedAttachments,
            values.request_collect_attachments,
            setFieldValue,
            setErrorModal,
            setLoading,
        ]
    )

    const _removeDoc = useCallback(
        (index: number, attachment?: IAttachment) => {
            if (!isEdit) {
                setFieldValue(
                    'request_collect_attachments',
                    values.request_collect_attachments.filter((item, i) => i !== index)
                )
            } else {
                const callback = async () => {
                    setLoading(true)
                    try {
                        await api.delete(`/painel/order/attachment/${attachment.id}`)
                        setAllocatedAttachments(allocatedAttachments.filter(item => item.id !== attachment.id))
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro ao remover anexo',
                            subtitle: showErrors(error),
                        })
                    }
                    setLoading(false)
                }

                setConfirmationModal({
                    title: 'Remover Anexo',
                    subtitle: 'Deseja mesmo remover este anexo?',
                    type: 'alert',
                    modalIcon: 'times-circle',
                    leftButtonText: 'Não',
                    rightButtonText: 'Sim, remover',
                    rightButtonClick: callback,
                })
            }
        },
        [
            isEdit,
            allocatedAttachments,
            values.request_collect_attachments,
            setFieldValue,
            setLoading,
            setErrorModal,
            setConfirmationModal,
        ]
    )

    const _getStoresOrigins = useCallback(async () => {
        if (isCollected) {
            return
        }
        try {
            const { data } = await api.get<PagedList<IDeliveryOrigin>>('/painel/delivery-origins')

            if (!order) {
                const storeOrigin = data.items.find(item => item.id === store.id)
                setFieldValue('delivery_origin', storeOrigin)
            }
            setStoreOrigins(data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [isCollected, store, order, setFieldValue])

    const _setDeliveryOrigin = useCallback(
        (storeId: number) => {
            const origin = storeOrigins.find(item => Number(item.id) === Number(storeId))
            setFieldValue('delivery_origin', origin)
        },
        [storeOrigins, setFieldValue]
    )

    const _openFile = useCallback((attachment: IAttachment) => {
        modalFileRef.current?.show({ attachment })
    }, [])

    const _onClose = useCallback(
        (type?: 'add' | 'update') => {
            if (onClose) {
                onClose(type)
            }
        },
        [onClose]
    )

    useEffect(() => {
        if (order) {
            if (isEdit) {
                setIsCollected(!['1', '2', '3', '4'].includes(order.status))
                setAllocatedAttachments(order?.attachments?.request_collect || [])
            }

            let date = ''
            let time = ''
            if (order.delivery_forecast) {
                const [d, t] = order.delivery_forecast.split(' ')
                date = d
                time = t
            }
            const body = isEdit
                ? {
                      is_forecast: !!order.delivery_forecast,
                      total_price: order.total_price.toString(),
                      request_collect_attachments: order.attachments?.request_collect || [],
                      delivery_forecast: {
                          date,
                          time,
                      },
                      items: formatOrderItemsToString(order.items),
                      status: order.status,
                  }
                : null

            setValues({
                ...initialValues,
                sale_channel: order.reference_name,
                reference_id: order.reference_id,
                payment_code: order.payment.id.toString(),
                customer_name: order.customer?.name || '',
                customer_phone: order.customer?.phone || '',
                customer_email: order.customer?.email || '',
                zipcode: (order.address?.zipcode || '').replace(/\D/g, ''),
                neighborhood: order.address?.neighborhood || '',
                street: order.address?.street || '',
                number: order.address?.number || '',
                complement: order.address?.complement || '',
                landmark: order.address?.landmark || '',
                lat: order.address?.lat || null,
                lng: order.address?.lng || null,
                observation: order.observation,
                collect: {
                    observations: order.collect?.observations || '',
                    confirmation_required: !!Number(order.collect?.confirmation_required),
                    confirmation_delivery: !!Number(order.collect?.confirmation_delivery),
                    confirmation_fail: !!Number(order.collect?.confirmation_fail),
                },
                delivery_origin: order.delivery_origin,
                delivery_origins: order.delivery_origins,
                ...body,
            })

            if (order.address?.lat && order.address?.lng) {
                const marker = addDraggableMarker(
                    { lat: order.address?.lat, lng: order.address?.lng },
                    ({ lat, lng }) => {
                        setFieldValue('lat', lat)
                        setFieldValue('lng', lng)
                    }
                )
                draggableMarkerRef.current = marker
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    useEffect(() => {
        _getStoresOrigins()
        _getSalesChannels()
        _getPayments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <OutsideContainer>
                <HeaderContainer>
                    <ContentTitle>{isEdit ? 'Editar pedido' : 'Solicitar Entrega'}</ContentTitle>
                    <CloseContainer onClick={() => _onClose()}>
                        <CloseButton />
                    </CloseContainer>
                </HeaderContainer>
                <ContentContainer>
                    <FormSection>
                        <FormSectionTitle>
                            <Feather.File /> Informações sobre o pedido
                        </FormSectionTitle>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="Canal de venda"
                                    type="select"
                                    options={salesChannels?.map(({ name }) => ({
                                        label: name,
                                        value: name,
                                    }))}
                                    inputProps={{
                                        placeholder: 'Selecione um Canal de venda',
                                        ...getFieldProps('sale_channel'),
                                    }}
                                    errorMessage={getFormInputError('sale_channel', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Número do pedido"
                                    inputProps={getFieldProps('reference_id')}
                                    errorMessage={getFormInputError('reference_id', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="Forma de Pagamento"
                                    type="select"
                                    options={payments?.map(({ id, name }) => ({
                                        label: name,
                                        value: id,
                                    }))}
                                    inputProps={{
                                        placeholder: 'Selecione uma Forma de Pagamento',
                                        ...getFieldProps('payment_code'),
                                    }}
                                    errorMessage={getFormInputError('payment_code', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Valor do pedido"
                                    type="currency"
                                    inputProps={getFieldProps('total_price')}
                                    errorMessage={getFormInputError('total_price', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                    </FormSection>
                    <FormSection>
                        <FormSectionTitle>
                            <Feather.User />
                            Cliente
                        </FormSectionTitle>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="Nome do cliente"
                                    inputProps={getFieldProps('customer_name')}
                                    errorMessage={getFormInputError('customer_name', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Telefone"
                                    inputProps={{
                                        placeholder: '(00) 00000-0000',
                                        mask: '(99) 99999-9999',
                                        ...getFieldProps('customer_phone'),
                                    }}
                                    errorMessage={getFormInputError('customer_phone', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="E-mail do cliente"
                                    inputProps={{
                                        placeholder: 'cliente@email.com',
                                        ...getFieldProps('customer_email'),
                                    }}
                                    errorMessage={getFormInputError('customer_email', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                    </FormSection>
                    <FormSection>
                        <FormSectionTitle>
                            <Feather.Map />
                            Endereço
                        </FormSectionTitle>
                        <InputPlace
                            placeholder={stringAddress}
                            onChange={_setAddress}
                            onButtonTextClick={() => setShowAllAddress(true)}
                            showButtonText={!showAllAddress}
                        />
                        {showAllAddress && (
                            <>
                                <InputsRow>
                                    <InputContainer>
                                        <InputItem
                                            labelText="CEP"
                                            inputProps={{
                                                ...getFieldProps('zipcode'),
                                                mask: '99999-999',
                                                onChange: ({
                                                    target: { value },
                                                }: React.ChangeEvent<HTMLInputElement>) => {
                                                    getAddressByCEP(value.replace(/\D/g, ''))
                                                    setFieldValue('zipcode', value.replace(/\D/g, ''))
                                                },
                                            }}
                                            errorMessage={getFormInputError('zipcode', errors, touched)}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <InputItem
                                            labelText="Bairro"
                                            inputProps={{ ...getFieldProps('neighborhood') }}
                                            errorMessage={getFormInputError('neighborhood', errors, touched)}
                                        />
                                    </InputContainer>
                                </InputsRow>
                                <InputsRow>
                                    <InputContainer>
                                        <InputItem
                                            labelText="Logradouro"
                                            inputProps={{ ...getFieldProps('street') }}
                                            errorMessage={getFormInputError('street', errors, touched)}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <InputItem
                                            labelText="Número"
                                            inputProps={{ ...getFieldProps('number') }}
                                            errorMessage={getFormInputError('number', errors, touched)}
                                        />
                                    </InputContainer>
                                </InputsRow>
                                <InputsRow>
                                    <InputContainer>
                                        <InputItem
                                            labelText="Complemento"
                                            inputProps={{ ...getFieldProps('complement') }}
                                            errorMessage={getFormInputError('complement', errors, touched)}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <InputItem
                                            labelText="Referência"
                                            inputProps={{ ...getFieldProps('landmark') }}
                                            errorMessage={getFormInputError('landmark', errors, touched)}
                                        />
                                    </InputContainer>
                                </InputsRow>
                            </>
                        )}
                    </FormSection>
                    <FormSection hide={!showAllAddress}>
                        {values.street && values.number && (
                            <LocateButton
                                onClick={() => {
                                    const { city } = mall.address

                                    getLatLngByAddress({
                                        city: city.name,
                                        state: city.state.uf,
                                        neighborhood: values.neighborhood,
                                        street: values.street,
                                        number: values.number,
                                    })
                                }}
                            >
                                Localizar no mapa
                            </LocateButton>
                        )}
                        <MapContainer ref={mapContainerRef}></MapContainer>
                    </FormSection>
                    <FormSection>
                        <FormSectionTitle>
                            <Feather.MessageSquare />
                            Itens e observações
                        </FormSectionTitle>
                        {values.items && <OrderItemsTable orderItems={formatToOrderItems(values.items)} />}
                        <InputItem
                            type="textarea"
                            labelText="Itens Solicitados"
                            big
                            inputProps={{ ...getFieldProps('items') }}
                            errorMessage={getFormInputError('items', errors, touched)}
                        />
                        <InputItem
                            type="textarea"
                            labelText="Observações sobre a entrega"
                            inputProps={{ ...getFieldProps('observation') }}
                            errorMessage={getFormInputError('observation', errors, touched)}
                        />
                    </FormSection>

                    <FormSection>
                        <FormSectionTitle>
                            <Feather.Calendar /> Agendamento
                        </FormSectionTitle>
                        <Checkbox checkboxTitle="Pedido agendado" isSquared inputProps={getFieldProps('is_forecast')} />

                        {values.is_forecast && (
                            <InputsRow>
                                <InputContainer>
                                    <InputItem
                                        labelText="Data de entrega"
                                        type="date"
                                        inputProps={getFieldProps('delivery_forecast.date')}
                                        errorMessage={getFormInputError('delivery_forecast.date', errors, touched)}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <InputItem
                                        labelText="Horário"
                                        type="time"
                                        inputProps={getFieldProps('delivery_forecast.time')}
                                        errorMessage={getFormInputError('delivery_forecast.time', errors, touched)}
                                    />
                                </InputContainer>
                            </InputsRow>
                        )}
                    </FormSection>
                    <>
                        <FormSection>
                            <FormSectionTitle>
                                <Feather.MapPin /> Coleta
                            </FormSectionTitle>

                            {values.delivery_origins?.map((item, i) => (
                                <CollectItem
                                    key={i}
                                    collect={item}
                                    index={i + 1}
                                    onRemoveClick={() => _removeOrigin(item.id)}
                                />
                            ))}

                            {availableOrigins.length > 0 && (
                                <InputItem
                                    type="select"
                                    labelText={
                                        values.delivery_origins?.length > 0
                                            ? `Coleta ${(values.delivery_origins?.length || 0) + 1}`
                                            : 'Loja de coletaa'
                                    }
                                    options={availableOrigins}
                                    inputProps={{
                                        placeholder: 'Selecione a loja de coleta',
                                        value: values.delivery_origin?.id || 'undefined',
                                        onChange: ({ target: { value } }) => {
                                            _setDeliveryOrigin(value)
                                        },
                                    }}
                                    errorMessage={getFormInputError('delivery_origin', errors, touched)}
                                    footer={
                                        enableAddMoreOrigins && (
                                            <SmallTextButton onClick={_addOrigin}>
                                                <span>
                                                    <FontAwesomeIcon icon="plus" />
                                                </span>
                                                Adicionar mais
                                            </SmallTextButton>
                                        )
                                    }
                                />
                            )}

                            <InputItem
                                type="textarea"
                                labelText="Observações sobre a coleta"
                                inputProps={{ ...getFieldProps('collect.observations') }}
                                errorMessage={getFormInputError('collect.observations', errors, touched)}
                            />
                            {store?.store_category?.store_type?.slug !== 'restaurantes' && (
                                <>
                                    <Checkbox
                                        checkboxTitle="Requer comprovação de coleta"
                                        isSquared
                                        inputProps={getFieldProps('collect.confirmation_required')}
                                    />
                                    <Checkbox
                                        checkboxTitle="Requer comprovação de entrega"
                                        isSquared
                                        inputProps={getFieldProps('collect.confirmation_delivery')}
                                    />
                                    <Checkbox
                                        checkboxTitle="Requer comprovação de problema"
                                        isSquared
                                        inputProps={getFieldProps('collect.confirmation_fail')}
                                    />
                                </>
                            )}
                        </FormSection>
                        {store?.store_category?.store_type?.slug !== 'restaurantes' && (
                            <FormSection>
                                <FormSectionTitle>
                                    <Feather.Clipboard /> Axenar arquivos de coleta
                                </FormSectionTitle>

                                {allocatedAttachments.map((attachment, i) => (
                                    <FileItem
                                        key={i}
                                        isView={isCollected}
                                        attachment={attachment}
                                        onClick={_openFile}
                                        onRemove={() => _removeDoc(i, attachment)}
                                    />
                                ))}
                                {values?.request_collect_attachments.map((file, i) => (
                                    <FileItem
                                        key={i}
                                        isView={isCollected}
                                        index={i}
                                        file={file}
                                        onRemove={_removeDoc}
                                        setFieldValue={setFieldValue}
                                    />
                                ))}
                                {!isCollected && (
                                    <ButtonItem>
                                        <label htmlFor="photo">Anexar</label>
                                        <input
                                            type="file"
                                            name="photo"
                                            id="photo"
                                            multiple
                                            accept="image/png,image/jpeg,application/pdf"
                                            onChange={({ target: { files } }) => _addDoc(files)}
                                        />
                                    </ButtonItem>
                                )}
                            </FormSection>
                        )}
                    </>
                </ContentContainer>
                <ButtonRow>
                    <TextButton onClick={() => _onClose()}>Cancelar</TextButton>
                    <ConfirmButton
                        onClick={() => {
                            if (!isValid) {
                                setErrorModal({
                                    title: 'Revise as informações',
                                    subtitle: 'Por favor, preencha as informações corretamente',
                                })
                            }
                            handleSubmit()
                        }}
                    >
                        {isEdit ? 'Editar pedido' : 'Solicitar Entrega'}
                    </ConfirmButton>
                </ButtonRow>
            </OutsideContainer>
            <ModalFile ref={modalFileRef} />
        </>
    )
})

export { ManagerOrderContent }
