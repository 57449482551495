import { useMemo } from 'react'
import { useHistory } from 'react-router'

import { stringify } from 'query-string'

import { RoutesListingHeader, RoutesListingTable } from 'components/_routes-listing'

import { useUI } from 'contexts'
import { setSearchParams, showErrors } from 'helpers'
import { useAuth, useFetchPagination } from 'hooks'
import { RouteFilters, RouteType } from 'types'

import { PageContainer } from './routes-listing-page.styles'

export interface RouteList {
    id: number
    status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
    biker: string
    orders: Order[]
    type: 'direct' | 'offer' | 'fix'
    start_at: string
    finished_at: string
    created_at: string
    updated_at: string
}

export interface Order {
    id: number
    sale_chennel: string
    store: string
}

export default function RoutesListingPage(): JSX.Element {
    const { mall } = useAuth()
    const history = useHistory()
    const { setSnackbar } = useUI()

    const filters: RouteFilters = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            store: query.get('store') ? Number(query.get('store')) : undefined,
            start_date: query.get('start_date') || undefined,
            end_date: query.get('end_date') || undefined,
            status: query.get('status') || undefined,
            search: query.get('search') || undefined,
            type: (query.get('type') as RouteType) || undefined,
        }
    }, [location.search])

    const { loading, items, setSize, size, totals, has_more } = useFetchPagination<RouteList>('/painel/route/list', {
        revalidateOnMount: Boolean(stringify(filters).length),
        params: {
            per_page: 100,
            ...filters,
            type: !filters?.type || filters?.type === 'undefined' ? undefined : filters?.type,
            status: !filters?.status || filters?.status === 'undefined' ? undefined : [filters?.status],
            mall: mall.id,
        },
        onError: error => {
            setSnackbar({ message: showErrors(error), type: 'error' })
        },
    })

    return (
        <PageContainer>
            <RoutesListingHeader
                title="Listagem de rotas"
                filters={filters}
                total={totals}
                onFilterData={filters => setSearchParams(filters, location, history)}
            />
            <RoutesListingTable
                hasMore={has_more}
                loading={loading}
                onLoadMore={() => setSize(size + 1)}
                routes={items || ([] as RouteList[])}
                total={totals}
            />
        </PageContainer>
    )
}
