import React, { memo } from 'react'

import { IPayment } from 'types/payment'

import { PaymentsListingTableHeader } from './payments-listing-table-header/payments-listing-table-header'
import { PaymentsListingTableRow } from './payments-listing-table-row/payments-listing-table-row'

type Props = {
    paymentMethods: IPayment[]
    handleDelete(id: number): () => void
    handleEdit(payment: IPayment): () => void
}

const PaymentsListingTable: React.FC<Props> = memo(({ paymentMethods, handleDelete, handleEdit }) => {
    return (
        <>
            <PaymentsListingTableHeader />

            {paymentMethods?.map((item, index) => (
                <PaymentsListingTableRow
                    key={index}
                    paymentMethod={item}
                    handleEdit={handleEdit(item)}
                    handleDelete={handleDelete(item.id)}
                />
            ))}
        </>
    )
})

export { PaymentsListingTable }
