import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

const TopRowDefault = props => {
    return (
        <div className="top-row-default-container">
            <div className="row-item">
                <div className="left-column">
                    {props.onBackButtonClick && (
                        <div
                            onClick={props.onBackButtonClick}
                            style={{ cursor: 'pointer' }}
                            className="top-row-back-button"
                        >
                            <FontAwesomeIcon icon="arrow-left" />
                        </div>
                    )}
                    <div className="top-row-title">{props.title}</div>
                    {props.children}
                </div>

                <div className="right-column">
                    <div className="buttons-container">
                        {props.buttons &&
                            props.buttons.map((item, index) => (
                                <button className="small-button rounded" onClick={item.onClick} key={index}>
                                    {`${item.title} `}
                                    {item.icon ? <FontAwesomeIcon icon={item.icon} color="#fff" /> : null}
                                </button>
                            ))}
                    </div>

                    {props.bikersLabel && (
                        <div className="deliverers-tag">
                            <div className="deliverer-tag-item">
                                <div className="icon">
                                    <FontAwesomeIcon icon={props.bikersIcon} />
                                </div>
                                <div className="number">{props.bikersAmount}</div>
                                <div className="tag-title">{props.bikersLabel}</div>
                            </div>
                        </div>
                    )}

                    {props.intervalSelect && (
                        <div className="interval-select">
                            <div className="label">Período:</div>
                            <div className="select-container">
                                <select name="" id="">
                                    {props.options.map((item, index) => (
                                        <option key={index} value="">
                                            {item.text}
                                        </option>
                                    ))}
                                </select>
                                <div className="icon">
                                    <FontAwesomeIcon icon="chevron-down" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TopRowDefault
