import { Fragment } from 'react'

import { formatCurrency } from 'helpers'
import { OrderItem } from 'types'
import './style.scss'

interface Props {
    orderItems: OrderItem[]
}

const OrderItemsTable: React.FC<Props> = ({ orderItems }) => {
    return (
        <table className="order-items-table">
            <thead>
                <tr>
                    <th>Item</th>
                    <th className="order-qty">Qtd</th>
                </tr>
            </thead>
            <tbody>
                {orderItems.map((item, index) => (
                    <Fragment key={index}>
                        <tr>
                            <td>
                                <strong>
                                    #{item.cod} {item.name} {item.price ? `- ${formatCurrency(item.price)} ` : ''}
                                </strong>
                            </td>
                            <td className="order-qty">x{item.quantity}</td>
                        </tr>

                        {item.subitems?.map((subitem, index) => (
                            <tr key={`subitem${index}`}>
                                <td>
                                    #{subitem.cod} {subitem.name}{' '}
                                    {subitem.price ? `- ${formatCurrency(subitem.price)} ` : ''}
                                </td>
                                <td className="order-qty">x{subitem.quantity}</td>
                            </tr>
                        ))}

                        <tr>
                            <td className="order-obs" colSpan={2}>
                                {item.obs && `Obs: ${item.obs}`}
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        </table>
    )
}

export default OrderItemsTable
