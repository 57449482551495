import React, { memo } from 'react'

import { RateChain } from 'types'

import { ChainRateTableRowItem } from './chain-rates-table-row-item/chain-rates-table-row-item'
import {
    RateDelivery,
    RateId,
    RateLabel,
    RateStatus,
    RateTable,
    TableActions,
    TableBody,
    TableHeader,
} from './chain-rates-table.styled'

interface props {
    rates: RateChain[]
    actions: {
        handleDelete(id: number): void
        handleEdit(id: number): void
    }
}

const ChainRatesTable: React.FC<props> = memo(({ rates, actions }) => {
    const { handleDelete, handleEdit } = actions

    return (
        <RateTable>
            <TableHeader>
                <RateId>ID</RateId>
                <RateLabel>Áreas de Cobrança</RateLabel>
                <RateDelivery>Bicicleta</RateDelivery>
                <RateDelivery>Motocicleta</RateDelivery>
                <RateDelivery>Carro</RateDelivery>
                <RateDelivery>Van</RateDelivery>
                <RateDelivery>Caminhão</RateDelivery>
                <RateStatus>Status</RateStatus>
                <TableActions />
            </TableHeader>

            <TableBody>
                {rates?.map((rate, key) => (
                    <ChainRateTableRowItem key={key} rate={rate} handleDelete={handleDelete} handleEdit={handleEdit} />
                ))}
            </TableBody>
        </RateTable>
    )
})

export { ChainRatesTable }
