import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'

import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { showErrors } from 'helpers'
import { useAuth, useAxiosRequest, useDataFetching } from 'hooks'
import api from 'services/api'
import { PagedList, ProductCategory } from 'types'

import { Container } from './product-categories-listing.styled'

const ProductCategoriesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { mall } = useAuth()
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const {
        data: productCategories,
        revalidate: revalidateCategories,
        loading: productCategoriesLoading,
    } = useDataFetching<PagedList<ProductCategory>>('/painel/product-categories', {
        params: { mall_id: mall.id, per_page: -1, order_by: 'name' },
    })

    const removeProductCategory = useAxiosRequest({
        api,
        method: 'delete',
        executeOnStart: false,
        onComplete: () => {
            setModalMessageData({
                title: 'Sucesso!',
                message: 'Categoria de produto excluída com sucesso',
            })

            modalMessage.current?.openModal()
            revalidateCategories()
        },
        onError: onRequestError,
    })

    return (
        <Container>
            <ModalLoading visible={productCategoriesLoading || removeProductCategory.loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <RowTitle
                title="Categorias de produto"
                buttonRow={[
                    {
                        label: 'Criar categoria de produto',
                        onClick: () =>
                            history.push('/marketplace/gestao-marketplace/categorias-produto/criar-categoria-produto'),
                    },
                ]}
            />

            <FlexTable
                columns={[
                    { name: 'ID', width: '5%' },
                    { name: 'Nome', width: '15%' },
                    { name: 'Descrição', width: '20%' },
                    { name: 'Tipo de loja', width: '15%' },
                    { name: 'Venda Multi-loja', width: '10%' },
                    { name: 'Status', width: '10%' },
                    { name: '', width: '25%' },
                ]}
                list={productCategories?.items?.map(productCategory => {
                    const { id, name, description, types, sell_multistore, status } = productCategory

                    return {
                        id: id.toString(),
                        name,
                        description,
                        types: (
                            <>
                                {types.map(type => (
                                    <div key={type.id}>{type.name}</div>
                                ))}
                            </>
                        ),
                        sell_multistore: sell_multistore ? 'Sim' : 'Não',
                        status: status ? 'Ativo' : 'Inativo',
                        buttonRow: (
                            <div className="flex-table-row-content button-row">
                                <div
                                    className="button-item outline"
                                    onClick={() =>
                                        history.push(
                                            `/marketplace/gestao-marketplace/categorias-produto/editar-categoria-produto/${id}`
                                        )
                                    }
                                >
                                    Editar
                                </div>
                                <div
                                    className="button-item"
                                    onClick={() => {
                                        setModalConfirmationData({
                                            title: 'Excluir Categoria de produto',
                                            message: 'Tem certeza de que deseja excluir esta Categoria de produto?',
                                            onYes: () =>
                                                removeProductCategory.request({
                                                    url: `/painel/product-category/${id}`,
                                                }),
                                        })

                                        modalConfirmation.current?.openModal()
                                    }}
                                >
                                    Excluir
                                </div>
                            </div>
                        ),
                    }
                })}
            />

            {!productCategories?.items.length && !productCategoriesLoading && (
                <EmptyMessage>Nenhuma Categoria de produto criada</EmptyMessage>
            )}
        </Container>
    )
}

export default ProductCategoriesListing
