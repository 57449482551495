import { useCallback, useContext, useEffect, useState } from 'react'

import { ChangeOrderTypeContext } from 'contexts/change-order-type-context'
import { Order } from 'types'

function useModalChangeTypeOrderController() {
    const { type, show, loading, setType, setOrder, setShow } = useContext(ChangeOrderTypeContext)

    const [visible, setVisible] = useState<boolean>(false)

    const _setOrder = useCallback(
        (order?: Order) => {
            setOrder(order)
        },
        [setOrder]
    )

    const _setShow = useCallback((isOpen?: boolean) => {
        setShow(!!isOpen)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
            setVisible(true)
            setTimeout(() => {
                setShow(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setShow(false)
            setTimeout(() => {
                setVisible(false)
            }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    useEffect(() => {
        if (!visible) {
            setShow(false)
            setOrder(undefined)
            setType(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    return { type, visible, show, loading, _setShow, _setOrder }
}

export { useModalChangeTypeOrderController }
