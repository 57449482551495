import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const NotifyProblemModalContainer = styled.div<{ isDisplayed: boolean }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.bikersSelectIndex};
    transition: 400ms;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
`

export const NotifyProblemModalBackground = styled.div<{ isEffectActive: boolean }>`
    z-index: ${({ theme }) => theme.commonModalZIndex};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    transition: 400ms;
    opacity: ${({ isEffectActive }) => (isEffectActive ? '1' : '0')};
`

export const NotifyProblemModalBox = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 550px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

export const NotifyProblemHeader = styled.div`
    padding: 40px;
    padding-bottom: 0;
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    width: 30px;
    position: absolute;
    top: 15px;
    right: 20px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

export const NotifyProblemModalContent = styled.div`
    flex: 1;
    overflow: auto;
    margin-bottom: 0;
    padding: 40px;
    padding-top: 0;
    &::-webkit-scrollbar {
        display: none;
    }
`

export const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 30px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

export const FormContainer = styled.div`
    padding-top: 0;
    margin-top: 20px;
`

export const FormSide = styled.div`
    width: 100%;
`

export const ImageSide = styled.div`
    width: 70%;
    margin: auto;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 100%;
    }
`

export const AnimatedImage = styled.img`
    width: 100%;
`

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 16px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const ConfirmButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const TextContent = styled.div`
    font-size: 18px;
`
export const OrderID = styled.div`
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`

export const CustomerName = styled.div`
    font-weight: bold;
`

export const OrderInfo = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`

export const OrderDetails = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
`

export const FormTitle = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
`
