import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, showErrors } from 'helpers'
import api, { plugApi } from 'services/api'
import { updateIntegration } from 'store/reducers/integrations/integrations'
import { IPlooga } from 'types/plooga'

import {
    CloseButton,
    CloseContainer,
    ConfirmButton,
    TextButton,
    ButtonRow,
    InputContainer,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
} from './plooga-modal-content.styled'

interface Props {
    plooga?: IPlooga
    closeClick(isRefresh?: boolean): void
    isActive: boolean
}

const PloogaContent: React.FC<Props> = memo(({ closeClick, isActive }) => {
    const { setSuccessModal, setErrorModal } = useUI()
    const dispatch = useDispatch()

    const { errors, getFieldProps, handleSubmit, resetForm, touched } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().trim().required('Preencha com o nome'),
            password: Yup.string()
                .trim()
                .min(8, 'Senha deve ter no mínimo 8 caracteres')
                .required('Preencha com sua senha'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { data } = await plugApi.post<{
                    access_token: string
                }>(`/login`, values)

                dispatch(updateIntegration({ token: data.access_token }))

                await api.post(`/painel/plooga-token`, { token: data.access_token, status: true })
                setSuccessModal({
                    title: 'Token atualizado',
                    subtitle: 'O token foi atualizado com sucesso',
                })

                resetForm()
                if (closeClick) {
                    closeClick(true)
                }
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao alterar status',
                    subtitle: showErrors(error),
                })
            }
        },
    })

    const _onClose = useCallback(() => {
        resetForm()

        if (closeClick) {
            closeClick()
        }
    }, [closeClick, resetForm])

    useEffect(() => {
        if (!isActive) {
            resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isActive) {
        return null
    }

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={_onClose}>
                    <CloseButton />
                </CloseContainer>
                <ContentTitle>{'Atualizar Credencial do Plooga'}</ContentTitle>
                <FormSection>
                    <InputContainer>
                        <InputItem
                            name="plooga-email"
                            labelText="E-mail"
                            inputProps={getFieldProps('email')}
                            errorMessage={getFormInputError('email', errors, touched)}
                        />
                    </InputContainer>

                    <InputContainer>
                        <InputItem
                            name="password-email"
                            labelText="Senha"
                            type="password"
                            inputProps={getFieldProps('password')}
                            errorMessage={getFormInputError('password', errors, touched)}
                        />
                    </InputContainer>
                </FormSection>
            </ContentContainer>
            <ButtonRow>
                <TextButton onClick={_onClose}>Cancelar</TextButton>
                <ConfirmButton onClick={() => handleSubmit()}>Salvar</ConfirmButton>
            </ButtonRow>
        </OutsideContainer>
    )
})

export { PloogaContent }
