import { useEffect, useState } from 'react'

import { RequestCollectContent } from 'components/_store-general-vision'

import {
    RequestCollectModalBackground,
    RequestCollectModalBox,
    RequestCollectModalContainer,
} from './request-collect-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
}

const RequestCollectModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <RequestCollectModalContainer isDisplayed={isDisplayed}>
            <RequestCollectModalBox isEffectActive={isEffectActive}>
                <RequestCollectContent closeClick={closeClick} />
            </RequestCollectModalBox>
            <RequestCollectModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </RequestCollectModalContainer>
    )
}

export default RequestCollectModal
