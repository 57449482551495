import { useCallback, useContext, useMemo } from 'react'
import { Printer } from 'react-feather'
import { useHistory } from 'react-router'
import ReactTooltip from 'react-tooltip'

import { format, differenceInMinutes } from 'date-fns'
import { useTheme } from 'styled-components'

import { IconContainer } from 'components/_new-general-vision/order-item/order-item.styles'
import { TrackerCode } from 'components/tracker-code/tracker-code'

import { useUI } from 'contexts'
import { ShopkeeperContext } from 'contexts/shopkeeper.context'
import { formatCurrency, getSalesChannelIcon, getStatusOrder } from 'helpers'
import { useAuth } from 'hooks'
import { usePrinter } from 'hooks/printer/usePrinter'
import api from 'services/api'
import { Order } from 'types'
import { IPrinterType } from 'types/printer'

import {
    ChannelLogo,
    OrderItemContainer,
    CustomerInfo,
    CustomerID,
    CustomerName,
    TotalPrice,
    TimesContainer,
    ClockIcon,
    GreenClockIcon,
    TimeItem,
    TimeText,
    CalendarIcon,
    ActionRow,
    AcceptButton,
    TextButton,
    TextButtonIcon,
    OptionsDropdown,
    DropdownItem,
    CloseDropdown,
    CancelIcon,
    DropdownText,
    MoveIcon,
    DetailIcon,
    Line,
    RescheduleIcon,
    BikerPhoto,
    BikerName,
    PointTag,
    Tag,
    BikerInfoContainer,
    InfoBadgeContainer,
    CopyIcon,
    InfoBadgeStatus,
    ProblemIcon,
    TakeoutIcon,
    DeliveredIcon,
    PrintButton,
} from './order-item.styles'

interface Props {
    order: Order
    isHighlighted: boolean
    onClickOrder(order: Order): void
    dropdownOrderIdActive?: number | undefined
    setDropdownOrderIdActive?(orderId: number | undefined): void
    onRescheduleOrder?(order: Order): void
    onConfirmOrder?(order: Order): void
    onMoveOrder?(order: Order): void
    onCancelOrder?(order: Order): void
    onDelivered?(order: Order): void
    onToTakeout?(order: Order): void
    hasBikerInfo?: boolean
    type?: 'in-production' | 'ready-for-collect' | 'awaiting-collect' | 'on-route' | 'delivered-today' | 'scheduled'
}

const OrderItem: React.FC<Props> = ({
    order,
    onClickOrder,
    dropdownOrderIdActive,
    setDropdownOrderIdActive,
    onRescheduleOrder,
    onConfirmOrder,
    onMoveOrder,
    onCancelOrder,
    onDelivered,
    onToTakeout,
    isHighlighted,
    hasBikerInfo,
    type,
}) => {
    const history = useHistory()
    const { changeTypeOrder } = useContext(ShopkeeperContext)
    const { colors } = useTheme()
    const { setLoading } = useUI()
    const { store } = useAuth()
    const { hasConnectionPrinterApp, printers, convertToPrintOrder, print } = usePrinter()
    const { pathname } = history.location
    const handleClick = (e: React.MouseEvent, callback?: () => void) => {
        e.stopPropagation()
        setDropdownOrderIdActive && setDropdownOrderIdActive(undefined)
        callback && callback()
    }

    const orderType = useMemo(() => {
        if (order.type === 'delivery') {
            return 'Delivery'
        }
        if (order.type === 'indoor') {
            return 'Atendimento em Mesa'
        }
        if (order.type === 'takeout') {
            return 'Retirada em Balcão'
        }
        if (order.type === 'cargo') {
            return 'Cargo'
        }

        return ''
    }, [order.type])

    const _print = useCallback(
        (type: IPrinterType, orderId: number) => async (e: React.MouseEvent) => {
            e.stopPropagation()
            setLoading(true)
            const { data } = await api.get<Order>(`/painel/dm-order/${orderId}`)

            if (type === 'production') {
                print(type, convertToPrintOrder(data))
                setLoading(false)
                return
            }

            print(type, convertToPrintOrder(data), { with_checklist: store?.configs?.print?.dispatch?.with_checklist })
            setLoading(false)
        },
        [store, convertToPrintOrder, print]
    )

    const getPrinterActionButton = useCallback(() => {
        const type = Number(order.status) === 4 ? 'dispatch' : 'production'
        const hasPrinterConfig = printers ? printers[type] : false
        return (
            hasConnectionPrinterApp &&
            [3, 4].includes(Number(order.status)) &&
            !order.routes && (
                <PrintButton disabled={!hasPrinterConfig} onClick={_print(type, order.id)}>
                    <Printer />
                </PrintButton>
            )
        )
    }, [hasConnectionPrinterApp, printers, order.id, order.status, order.routes, _print])

    const _getTimeBadges = useCallback(() => {
        if (type) {
            if (type === 'in-production') {
                return (
                    <TimeItem>
                        <ClockIcon />
                        <TimeText>Total: {getTimeLife(order.birth)}</TimeText>
                    </TimeItem>
                )
            }
            if (type === 'ready-for-collect') {
                return (
                    <>
                        <TimeItem>
                            <GreenClockIcon />
                            <TimeText>Pronto: {getTimeAwait(order.end_production)}</TimeText>
                        </TimeItem>

                        <TimeItem>
                            <ClockIcon />
                            <TimeText>Total: {getTimeLife(order.birth)}</TimeText>
                        </TimeItem>
                    </>
                )
            }
            if (type === 'awaiting-collect') {
                return (
                    <>
                        <TimeItem>
                            <GreenClockIcon />
                            <TimeText>Pronto: {getTimeAwait(order.end_production)}</TimeText>
                        </TimeItem>

                        <TimeItem>
                            <ClockIcon />
                            <TimeText>Total: {getTimeLife(order.birth)}</TimeText>
                        </TimeItem>
                    </>
                )
            }

            if (type === 'on-route') {
                return (
                    <>
                        {order.start_delivery && (
                            <TimeItem>
                                <GreenClockIcon />
                                <TimeText>Rota: {getTimeDelivery(order.start_delivery)}</TimeText>
                            </TimeItem>
                        )}

                        <TimeItem>
                            <ClockIcon />
                            <TimeText>Total: {getTimeLife(order.birth)}</TimeText>
                        </TimeItem>
                    </>
                )
            }
            if (type === 'delivered-today') {
                return (
                    <>
                        <TimeItem>
                            <GreenClockIcon />
                            <TimeText>
                                Produção: {getTimeProduction(order.start_production, order.end_production)}
                            </TimeText>
                        </TimeItem>

                        {/* <TimeItem>
                            <GreenClockIcon />
                            <TimeText>Entrega: {getTimeRoute(order.start_delivery, order.end_delivery)}</TimeText>
                        </TimeItem> */}

                        {/* <TimeItem>
                            <GreenClockIcon />
                            <TimeText>Entrega: {getTimeDelivered(order.birth, order.end_delivery)}</TimeText>
                        </TimeItem> */}

                        <TimeItem>
                            <ClockIcon />
                            <TimeText>Total: {getTotalTime(order.birth, order.end_delivery)}</TimeText>
                        </TimeItem>
                    </>
                )
            }
        }
        if (order.status === '1' && order.delivery_forecast) {
            return (
                <TimeItem>
                    <CalendarIcon />
                    <TimeText>{format(order.delivery_forecast, 'DD/MM [às] HH:mm')}</TimeText>
                </TimeItem>
            )
        }

        if (order.status === '3') {
            return (
                <TimeItem>
                    <ClockIcon />
                    <TimeText>Prod: {order.total_time?.text}</TimeText>
                </TimeItem>
            )
        }

        if (['4', '5', '6', '7'].includes(order.status) || order.status.includes('10')) {
            return (
                <>
                    <TimeItem>
                        <GreenClockIcon />
                        <TimeText>Prod: {order.production_time.text}</TimeText>
                    </TimeItem>

                    <TimeItem>
                        <ClockIcon />
                        <TimeText>Total: {order.total_time?.text}</TimeText>
                    </TimeItem>
                </>
            )
        }

        if (['8', '9'].includes(order.status)) {
            return (
                <>
                    <TimeItem>
                        <GreenClockIcon />
                        <TimeText>Prod: {order.production_time.text}</TimeText>
                    </TimeItem>

                    <TimeItem>
                        <GreenClockIcon />
                        <TimeText>Total: {order.delivery_time.text}</TimeText>
                    </TimeItem>
                </>
            )
        }
    }, [order, type])

    function getAcceptButton() {
        if (order.status === '1') {
            return (
                <AcceptButton
                    onClick={(e: React.MouseEvent) => handleClick(e, () => onConfirmOrder && onConfirmOrder(order))}
                >
                    Confirmar
                </AcceptButton>
            )
        }

        if (order.status === '3') {
            return (
                <AcceptButton
                    onClick={(e: React.MouseEvent) => handleClick(e, () => onConfirmOrder && onConfirmOrder(order))}
                >
                    Pronto
                </AcceptButton>
            )
        }
        if (order.status === '4' && order.type === 'takeout') {
            return (
                <AcceptButton
                    onClick={(e: React.MouseEvent) => handleClick(e, () => onDelivered && onDelivered(order))}
                >
                    Entregar
                </AcceptButton>
            )
        }

        return null
    }

    const showCopyOption = useMemo(() => {
        return pathname.includes('em-andamento') || pathname.includes('novos')
    }, [pathname])

    const _copyOrder = useCallback(
        (order: Order) => {
            const url = new URL(window.location.href)
            if (!url.searchParams.has('modal-request-order')) {
                url.searchParams.append('modal-request-order', order.id.toString())
                history.push({ search: url.search })
            }
        },
        [history]
    )

    return (
        <OrderItemContainer
            isHighlighted={isHighlighted}
            onClick={(e: React.MouseEvent) => handleClick(e, () => onClickOrder(order))}
        >
            {hasBikerInfo && order.agent && (
                <BikerInfoContainer>
                    <BikerPhoto
                        src={order?.agent?.avatar}
                        data-for="biker-name-tooltip"
                        data-tip={order?.agent?.name}
                    />
                    <ReactTooltip id="biker-name-tooltip" />
                    {/* <BikerName>{order?.agent?.name}</BikerName> */}
                </BikerInfoContainer>
            )}

            <ChannelLogo src={getSalesChannelIcon(order as Order)} />
            <CustomerInfo>
                {!!orderType && (
                    <Tag>
                        <PointTag /> {orderType}
                    </Tag>
                )}
                <CustomerID>
                    #{order.id} {order.reference_id && <> {` | #${order.reference_id}`}</>}
                </CustomerID>
                <CustomerName>{order.customer.name}</CustomerName>
                <TotalPrice>{formatCurrency(order.total_price)}</TotalPrice>

                {order.status === '1' && !order.delivery_forecast && (
                    <InfoBadgeContainer color={colors.primary}>
                        <ProblemIcon />
                        <InfoBadgeStatus>Aguard. p/ produzir</InfoBadgeStatus>
                    </InfoBadgeContainer>
                )}

                {order.status.includes('10') && (
                    <InfoBadgeContainer color={colors.errorMessage}>
                        <ProblemIcon />
                        <InfoBadgeStatus>{getStatusOrder(order.status)}</InfoBadgeStatus>
                    </InfoBadgeContainer>
                )}
                {!['in-production', 'scheduled'].includes(type) && <TrackerCode orderId={order.id} simple />}
            </CustomerInfo>
            <TimesContainer>{_getTimeBadges()}</TimesContainer>
            <ActionRow>
                {getAcceptButton()}
                {getPrinterActionButton()}
                <TextButton
                    onClick={(e: React.MouseEvent) =>
                        handleClick(e, () => setDropdownOrderIdActive && setDropdownOrderIdActive(order.id))
                    }
                >
                    <TextButtonIcon /> Opções
                </TextButton>
                <OptionsDropdown isActive={dropdownOrderIdActive === order.id}>
                    <CloseDropdown
                        onClick={(e: React.MouseEvent) =>
                            handleClick(e, () => setDropdownOrderIdActive && setDropdownOrderIdActive(undefined))
                        }
                    />
                    <DropdownItem>
                        <IconContainer>
                            <DetailIcon />
                        </IconContainer>
                        <DropdownText>Ver detalhes</DropdownText>
                        <Line />
                    </DropdownItem>
                    {/* 
                    {['ready-for-collect', 'in-production', 'scheduled'].includes(type || '') && (
                        <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, () => changeTypeOrder(order))}>
                            <IconContainer>
                                <TakeoutIcon />
                            </IconContainer>
                            <DropdownText>Alterar Tipo de entrega</DropdownText>
                            <Line />
                        </DropdownItem>
                    )} */}

                    {!!onDelivered && order.type === 'delivery' && (
                        <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, () => onDelivered(order))}>
                            <IconContainer>
                                <DeliveredIcon />
                            </IconContainer>
                            <DropdownText>Entregue em mãos</DropdownText>
                            <Line />
                        </DropdownItem>
                    )}

                    {onRescheduleOrder && (
                        <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, () => onRescheduleOrder(order))}>
                            <IconContainer>
                                <RescheduleIcon />
                            </IconContainer>
                            <DropdownText>Reagendar pedido</DropdownText>
                            <Line />
                        </DropdownItem>
                    )}

                    {showCopyOption && (
                        <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, () => _copyOrder(order))}>
                            <IconContainer>
                                <CopyIcon />
                            </IconContainer>
                            <DropdownText>Reenviar</DropdownText>
                            <Line />
                        </DropdownItem>
                    )}

                    {onMoveOrder && (
                        <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, () => onMoveOrder(order))}>
                            <IconContainer>
                                <MoveIcon />
                            </IconContainer>
                            <DropdownText>Mover pedido</DropdownText>
                            <Line />
                        </DropdownItem>
                    )}

                    {onCancelOrder && (
                        <DropdownItem onClick={(e: React.MouseEvent) => handleClick(e, () => onCancelOrder(order))}>
                            <IconContainer>
                                <CancelIcon />
                            </IconContainer>
                            <DropdownText>Cancelar pedido</DropdownText>
                            <Line />
                        </DropdownItem>
                    )}
                </OptionsDropdown>
            </ActionRow>
        </OrderItemContainer>
    )
}

function getTimeLife(birth: string) {
    if (!birth) {
        return ''
    }
    return `${differenceInMinutes(new Date(), birth)}m`
}
function getTotalTime(birth: string, endDelivery: string) {
    if (!birth || !endDelivery) {
        return ''
    }
    return `${differenceInMinutes(endDelivery, birth)}m`
}

function getTimeProduction(startProduction: string, endProduction: string) {
    if (!startProduction || !endProduction) {
        return ''
    }

    return `${differenceInMinutes(endProduction, startProduction)}m`
}

function getTimeAwait(endProduction: string) {
    if (!endProduction) {
        return ''
    }

    return `${differenceInMinutes(new Date(), endProduction)}m`
}

function getTimeDelivery(startDelivery: string) {
    if (!startDelivery) {
        return ''
    }
    return `${differenceInMinutes(new Date(), startDelivery)}m`
}

function getTimeRoute(startDelivery: string, endDelivery: string) {
    if (!startDelivery || !endDelivery) {
        return ''
    }
    return `${differenceInMinutes(endDelivery, startDelivery)}m`
}

export default OrderItem
