import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
const Container = styled.div`
    height: 80%;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;

    .empty-list-message {
        background-color: #f5f5f5;
    }
`
const ContainerCounter = styled.div`
    display: flex;
    align-items: center;
    padding: 30px;
    background-color: #f5f5f5;
`

const IconCounter = styled(FontAwesomeIcon)`
    margin-right: 10px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
`

const TitleCounter = styled.div``

export { Container, ContainerCounter, IconCounter, TitleCounter }
