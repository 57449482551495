import styled from 'styled-components'

const ContainerTitle = styled.div<{ alert?: boolean }>`
    border-bottom: 1.5px solid #f2f2f2;
    position: relative;
    padding: 20px 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`

const ContentInfo = styled.div<{ alert?: boolean }>`
    border-bottom: 1.5px solid #f2f2f2;
    margin: 0 0 25px;
    padding: 0 0 25px;
`

const ContainerColumn = styled.div<{ alert?: boolean; attention?: boolean; shake?: boolean }>`
    display: flex;
    flex-direction: column;
    user-select: none;
    border-radius: 8px;
    margin: 10px;
    max-width: 300px;
    min-width: 300px;
    height: calc(100% - 20px);
    background-color: #fff;
    color: #333;
    &:first-child {
        margin-left: 20px;
    }
    &:last-child {
        margin-right: 20px;
        border-bottom: none;
    }
`

const UserName = styled.div`
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    flex: 1;
`
const DateTime = styled.div`
    font-size: 0.62rem;
    color: ${({ theme }) => theme.colors.textLight};
    margin-top: 8px;
`
const Observation = styled.p<{ noMargin?: boolean }>`
    margin: 0;
    padding: 0;
    font-size: 0.625rem;
    margin-top: ${({ noMargin }) => (noMargin ? 0 : '0.625rem')};
    max-height: 2rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ContainerInfo = styled.div`
    padding: 10px 20px;
    flex: 1;
`

const BadgeType = styled.div`
    background-color: ${({ theme }) => theme.colors.gray};
    padding: 2px 6px;
    align-self: center;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 0.87rem;
`

const TextInfo = styled.div<{ header?: boolean }>`
    font-size: ${({ header }) => (header ? '1em' : '0.87em')};
    margin: 10px 0 0;
    color: ${({ header, theme }) => (header ? theme.colors.primary : theme.colors.text)};
    font-weight: ${({ header }) => (header ? 'bold' : '500')};
    display: flex;
    gap: 9px;
    span {
        padding: 2px 4px;
        height: fit-content;
        border-radius: 25%;
        font-size: ${({ header }) => (header ? '1em' : '0.87em')};
        font-weight: bold;
        color: #fff;
        background-color: ${({ header, theme }) => (header ? theme.colors.primary : theme.colors.text)};
    }
`

const Footer = styled.div`
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

export {
    ContentInfo,
    Footer,
    TextInfo,
    ContainerInfo,
    UserName,
    BadgeType,
    ContainerColumn,
    ContainerTitle,
    DateTime,
    Observation,
}
