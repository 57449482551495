import { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'

import { booleanPointInPolygon, point } from '@turf/turf'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import { GlobalMapContext, MirrorMap } from 'components/global-map'
import { CircleLines, InfoMapMessage, PolygonFill } from 'components/mapbox-view'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { AvailableStores, PaymentArea, Store } from 'types'

import './style.scss'

const RateCreation: React.FC<RouteComponentProps<{ id: string; store: string }>> = ({ history, match: { params } }) => {
    const { basePath, mall, store } = useAuth()
    const { setLoading } = useUI()

    const { state: locationState } = useLocation<{ store_id: number }>()

    const { setMapBoxContentComponent, setMarkerCenter } = useContext(GlobalMapContext)

    const [loading, toggleLoading] = useState(false)
    const [paymentAreas, setPaymentAreas] = useState<PaymentArea[]>([])
    const [availableStores, setAvailableStores] = useState<AvailableStores>()
    const [selectedStore, setSelectedStore] = useState<number | null>()
    const [selectedStoreData, setSelectedStoreData] = useState<Store>()
    const [selectOptions, setSelectOptions] = useState([])

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const statusArray = [
        {
            name: 'Ativo',
            value: 1,
        },
        {
            name: 'Inativo',
            value: 0,
        },
    ]

    const validateMinRateValue = Yup.number()
        .when('not_work', {
            is: not_work => not_work === false,
            then: (schema: Yup.NumberSchema) => schema.min(0.0, 'Tarifa é obrigatória'),
        })
        .nullable()

    const { initialValues, isSubmitting, values, touched, errors, handleSubmit, setFieldValue, setValues } = useFormik({
        initialValues: {
            mall_id: mall?.id || null,
            store_id: store?.id || null,
            id: null,
            label: '',
            payment_areas: [],
            values: {
                bike: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                moto: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                car: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                van: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                truck: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
            },
            status: '',
        },
        validationSchema: Yup.object().shape({
            label: Yup.string().trim().required('Por favor, preencha com o Nome da tarifa'),
            values: Yup.object().shape({
                bike: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                moto: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                car: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                van: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                truck: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
            }),
            status: Yup.string().trim().required('Por favor, selecione o Status'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                const { bike, moto, car, van, truck } = values.values

                const body = {
                    ...values,
                    mall_id: selectedStore ? null : mall?.id,
                    store_id: selectedStore ? selectedStore : null,
                    payment_areas: values.payment_areas.map((item: PaymentArea) => item.id),
                    values: {
                        agent: {
                            bike: bike.agent,
                            moto: moto.agent,
                            car: car.agent,
                            van: van.agent,
                            truck: truck.agent,
                        },
                        store: {
                            bike: bike.store,
                            moto: moto.store,
                            car: car.store,
                            van: van.store,
                            truck: truck.store,
                        },
                    },
                }

                if (values?.id) {
                    await api.put(`/painel/rate/${values.id}`, body)
                } else {
                    await api.post('/painel/rate', body)
                }

                setModalMessageData({
                    title: 'Sucesso!',
                    message: `Tarifa ${values?.id ? 'atualizada' : 'criada'} com sucesso.`,
                    onClose: () => history.push(`${basePath}/tarifas`),
                })
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                })
            } finally {
                setSubmitting(false)
                modalMessage.current?.openModal()
            }
        },
    })

    const getSelectData = useCallback(async () => {
        try {
            toggleLoading(true)

            if (selectedStore !== undefined) {
                const filter = Number(selectedStore) ? { store_id: selectedStore } : { mall_id: mall.id }
                const { data } = await api.get(`/painel/payment-areas`, {
                    params: {
                        ...filter,
                        per_page: -1,
                        status: [0, 1],
                    },
                })
                setPaymentAreas(data.items)
            }
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados para áreas de pagamento.',
                onClose: () => history.push(`${basePath}/tarifas`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [basePath, history, mall, selectedStore])

    const getRate = useCallback(
        async id => {
            try {
                toggleLoading(true)

                const { data } = await api.get(`/painel/rate/${id}`)

                const { agent, store } = data.values

                setValues({
                    ...initialValues,
                    ...data,
                    values: {
                        bike: {
                            not_work: agent.bike == null && store.bike == null,
                            agent: agent.bike,
                            store: store.bike,
                        },
                        moto: {
                            not_work: agent.moto == null && store.moto == null,
                            agent: agent.moto,
                            store: store.moto,
                        },
                        car: {
                            not_work: agent.car == null && store.car == null,
                            agent: agent.car,
                            store: store.car,
                        },
                        van: {
                            not_work: agent.van == null && store.van == null,
                            agent: agent.van,
                            store: store.van,
                        },
                        truck: {
                            not_work: agent.truck == null && store.truck == null,
                            agent: agent.truck,
                            store: store.truck,
                        },
                    },
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push(`${basePath}/tarifas`),
                })

                modalMessage.current?.openModal()
            } finally {
                toggleLoading(false)
            }
        },
        [basePath, history, initialValues, setValues]
    )

    const deleteRate = useCallback(async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate/${params.id}`)

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Tarifa removida com sucesso.',
                onClose: () => history.push(`${basePath}/tarifas`),
            })

            modalMessage.current?.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/tarifas`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [basePath, history, params.id])

    const checkPaymentArea = useCallback(
        ({ lngLat }: { lngLat: { lat: number; lng: number } }) => {
            const searchPoint = point([lngLat.lng, lngLat.lat])
            const areaClicked = paymentAreas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

            if (!areaClicked) return

            if (values.payment_areas.some((item: PaymentArea) => item.id === areaClicked?.id)) {
                setFieldValue(
                    'payment_areas',
                    values.payment_areas.filter((item: PaymentArea) => item.id !== areaClicked?.id)
                )
            } else {
                setFieldValue('payment_areas', [...values.payment_areas, areaClicked])
            }
        },
        [paymentAreas, values.payment_areas, setFieldValue]
    )

    const getSelectedStoreData = useCallback(async () => {
        if (Number(selectedStore)) {
            try {
                setLoading(true)
                const { data } = await api.get(`/painel/store/${selectedStore}`)

                setSelectedStoreData(data)
            } catch (error) {
                console.log('error', error)
            } finally {
                setLoading(false)
            }
        }
    }, [selectedStore])

    const handleSelect = useCallback(value => {
        if (value === 'mall') {
            setSelectedStore(null)
            setSelectedStoreData(null)
        } else {
            setSelectedStore(Number(value))
        }
    }, [])

    const getAvailableStores = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/stores`, {
                params: {
                    mall_id: mall.id,
                    per_page: -1,
                },
            })

            setAvailableStores(data)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    const _renderContentMap = useCallback(() => {
        if (selectedStoreData) {
            setMarkerCenter([selectedStoreData?.lng, selectedStoreData?.lat])
        }

        return (
            <>
                <PolygonFill
                    id="rate-areas"
                    areaType="rate-area"
                    showPopUpArea
                    areas={paymentAreas}
                    onClick={checkPaymentArea}
                    defaultColor="#333"
                    defaultOpacity={0.5}
                    rateCoverageAreas={values.payment_areas}
                />

                <InfoMapMessage message="Clique nos polígonos destacados no mapa para marcar ou desmarcar uma área de cobrança." />
                <CircleLines
                    id="circle-lines"
                    center={[
                        selectedStoreData?.lng ? selectedStoreData?.lng : mall?.address?.lng,
                        selectedStoreData?.lat ? selectedStoreData?.lat : mall?.address?.lat,
                    ]}
                />
            </>
        )
    }, [values.payment_areas, mall, selectedStoreData, paymentAreas, checkPaymentArea, setMarkerCenter])

    useEffect(() => {
        getAvailableStores()
    }, [getAvailableStores])

    useEffect(() => {
        getSelectedStoreData()
    }, [getSelectedStoreData])

    useEffect(() => {
        getSelectData()
    }, [getSelectData])

    useEffect(() => {
        setMapBoxContentComponent(_renderContentMap)
    }, [_renderContentMap])

    useEffect(() => {
        if (params.id) {
            getRate(params.id)
        }
        if (params.store === 'mall' || params.store === 'undefined') {
            setSelectedStore(null)
        }

        if (params.store !== 'mall' && params.store !== 'undefined') {
            setSelectedStore(Number(params.store))
        }
    }, [getRate, params.id, params.store])

    useEffect(() => {
        if (availableStores) {
            const available = []
            availableStores?.items?.map(item => available.push({ name: item.name, value: item.id }))

            setSelectOptions([{ name: mall?.name, value: 'mall' }, ...available])

            const select = document.getElementsByTagName('select')
            select[0].selectedIndex = 1
        }
    }, [availableStores, mall])

    useEffect(() => {
        if (locationState?.store_id && !!Number(locationState?.store_id)) {
            setSelectedStore(locationState.store_id)
        }
    }, [locationState?.store_id])

    const { bike, moto, car, truck, van } = values.values

    return (
        <div className="page-container">
            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Deseja realmente excluir esta tarifa?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                title="Tarifa"
                onBackButtonClick={() => history.replace(`${basePath}/tarifas`, { store_id: selectedStore })}
                buttons={
                    params.id && [
                        {
                            title: 'Excluir Tarifa',
                            icon: 'trash-alt',
                            onClick: () => modalDelete.current?.openModal(),
                        },
                    ]
                }
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title">{params.id ? 'Edição' : 'Criação'}</div>
                        {!params.store && (
                            <div className="creation-select-input-container">
                                <SelectInputForm
                                    label="Estabelecimento"
                                    data={selectOptions}
                                    onChange={({ target: { value } }) => {
                                        handleSelect(value)
                                    }}
                                    value={selectedStore}
                                />
                            </div>
                        )}
                        <div className="rate-label">
                            <TextInputForm
                                label={'Nome da Tarifa'}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('label', value)
                                }
                                value={values.label}
                                msgErro={touched.label && errors.label}
                            />
                        </div>

                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Bicicleta</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={bike.store}
                                    onChange={(floatValue: number) => setFieldValue('values.bike.store', floatValue)}
                                    msgErro={touched.values?.bike?.store && errors.values?.bike?.store}
                                    disabled={bike.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={bike.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.bike.agent', floatValue)}
                                    msgErro={touched.values?.bike?.agent && errors.values?.bike?.agent}
                                    disabled={bike.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Motocicleta</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={moto.store}
                                    onChange={(floatValue: number) => setFieldValue('values.moto.store', floatValue)}
                                    msgErro={touched.values?.moto?.store && errors.values?.moto?.store}
                                    disabled={moto.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={moto.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.moto.agent', floatValue)}
                                    msgErro={touched.values?.moto?.agent && errors.values?.moto?.agent}
                                    disabled={moto.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Carro</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={car.store}
                                    onChange={(floatValue: number) => setFieldValue('values.car.store', floatValue)}
                                    msgErro={touched.values?.car?.store && errors.values?.car?.store}
                                    disabled={car.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={car.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.car.agent', floatValue)}
                                    msgErro={touched.values?.car?.agent && errors.values?.car?.agent}
                                    disabled={car.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>
                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Van</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={van.store}
                                    onChange={(floatValue: number) => setFieldValue('values.van.store', floatValue)}
                                    msgErro={touched.values?.van?.store && errors.values?.van?.store}
                                    disabled={van.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={van.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.van.agent', floatValue)}
                                    msgErro={touched.values?.van?.agent && errors.values?.van?.agent}
                                    disabled={van.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>
                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Caminhão</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={truck.store}
                                    onChange={(floatValue: number) => setFieldValue('values.truck.store', floatValue)}
                                    msgErro={touched.values?.truck?.store && errors.values?.truck?.store}
                                    disabled={truck.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={truck.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.truck.agent', floatValue)}
                                    msgErro={touched.values?.truck?.agent && errors.values?.truck?.agent}
                                    disabled={truck.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div style={{ width: 200 }}>
                            <SelectInputForm
                                label="Status"
                                data={statusArray}
                                value={values.status}
                                onChange={({ target: { value } }) => setFieldValue('status', value)}
                                msgErro={touched.status && errors.status}
                            />
                        </div>

                        <ButtonForm
                            type="submit"
                            buttonText="Salvar"
                            onClick={() => handleSubmit()}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="right-column">
                        <MirrorMap />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RateCreation
