import { Order } from 'types'

import ProgressCard from '../progress-card/progress-card'

import { orders } from './mock-orders'
import { CardsContainer, InProgressOrders } from './orders-collect-tab.styles'

interface Props {
    onClickOrder(order: Order): void
}

const OrdersCollectTab: React.FC<Props> = ({ onClickOrder }) => {
    return (
        <InProgressOrders>
            <CardsContainer>
                <ProgressCard
                    cardTitle="Em preparação"
                    amount={orders.length}
                    // orders={orders}
                    emptyText={'Mensagem de card vazio'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    onClickOrder={onClickOrder}
                />
                <ProgressCard
                    cardTitle="Prontos para coleta"
                    amount={orders.length}
                    // orders={orders}
                    emptyText={'Mensagem de cad vazio lorem ipsum dolot sit amet'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    onClickOrder={onClickOrder}
                />
                <ProgressCard
                    cardTitle="Em trãnsito para dispatch"
                    amount={orders.length}
                    // orders={orders}
                    emptyText={'Mensagem de card vazio'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    onClickOrder={onClickOrder}
                />
                <ProgressCard
                    cardTitle="Entregues no dispatch"
                    amount={orders.length}
                    // orders={orders}
                    emptyText={'Mensagem de card vazio'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    onClickOrder={onClickOrder}
                />
            </CardsContainer>
        </InProgressOrders>
    )
}

export default OrdersCollectTab
