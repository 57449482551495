import { Map, Marker } from 'mapbox-gl'

import colors from 'themes/colors'

import { Coordinates } from '../types'

export function addDraggableMarker(
    map: Map,
    coordinates: Coordinates,
    onDragEnd: (coords: Coordinates) => void
): Marker {
    function setCoords(coords: Coordinates) {
        map.setCenter(coords)
        map.setZoom(15)
    }

    function handleDragEnd() {
        const coords = marker.getLngLat()

        onDragEnd(coords)
        setCoords(coords)
    }

    const marker = new Marker({
        draggable: true,
        color: colors.light_primary_color,
    })
        .setLngLat([coordinates.lng, coordinates.lat])
        .addTo(map)

    marker.on('dragend', handleDragEnd)
    setCoords(coordinates)

    return marker
}
