import { faArrowLeft, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ContentTitleRow = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray};
    border-top: 3px solid ${({ theme }) => theme.colors.lightGray};
    padding: 20px;
    font-size: 14px;
`

export const BackIcon = styled(FontAwesomeIcon).attrs({
    icon: faArrowLeft,
})`
    cursor: pointer;
`

export const TitleText = styled.div`
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: bold;
`

export const TabsContainer = styled.div`
    display: flex;
    align-items: center;
`

export const TabItem = styled.div<{ isActive?: boolean }>`
    flex-basis: 50%;
    text-align: center;
    padding: 15px;
    font-weight: bold;
    transition: 400ms;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.darkGray)};
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray)};
    cursor: pointer;
    user-select: none;
    &:hover {
        opacity: 0.8;
    }
`

export const ListTitleRow = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`

export const ListTitleItem = styled.div`
    &:last-child {
        font-size: 12px;
    }
`

export const ListBox = styled.div`
    height: 100%;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.white};

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: unset;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        /* for Firefox */
        min-height: 0;
    }
`

export const ConfigSection = styled.div`
    padding: 20px;
`

export const ConfigTitle = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.gray};
`

export const PrimaryButton = styled.div<{ isActive?: boolean }>`
    padding: 10px 20px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.8;
    }
`

export const TextButton = styled.div`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
`

export const BikerCardContainer = styled.div`
    padding: 20px;
    padding-top: 0;
`

export const BikerCard = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    padding: 20px;
    padding-top: 0;
    justify-content: space-between;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 80%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 400px;
    }
`

export const ExchangeBiker = styled(FontAwesomeIcon).attrs({
    icon: faSyncAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 22px;
    margin-top: 20px;
    transition: 400ms;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        position: relative;
        top: unset;
        right: unset;
    }
    &:hover {
        transform: rotate(30deg);
    }
`
