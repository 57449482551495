export const malls = [
    {
        section_title: 'Áreas',
        type: 'areas',
        stores: ['Áreas de roteirização', 'Área de Cobrança', 'Configurar Fila'],
    },
    {
        section_title: 'Tarifas',
        type: 'tariffs',
        stores: ['Tarifas por Zona', 'Tarifa Dinâmica', 'Tarifas Expressas', 'Tarifas de Rota Corrente'],
    },
    {
        section_title: 'Lojas',
        type: 'store',
        stores: ['Listagem de Lojas', 'Gerenciar Lojistas'],
    },
    {
        section_title: 'Administradores',
        type: 'admin',
        stores: ['Gerenciar Administração', 'Gerenciar Integrações'],
    },
    {
        section_title: 'Configurações Adicionais',
        type: 'settings',
        stores: ['Tempos de Performance', 'Turnos'],
    },
]
