import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import FlexTable from 'components/flex-table'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'

import { Establishments, FinanceExtract, Recipients } from 'containers/stone-management/types'

import { formatCurrency, showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

import './style.scss'

interface Filters {
    profile_id: string
    initial_date: string
    till_date: string
}

const FinanceExtractPage: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [financeExtract, setFinanceExtract] = useState<FinanceExtract>({
        received: {
            received_period_amount: '9.00',
            received_details: [
                {
                    detail_date: '15/11/2020',
                    date_amount: 'R$ 3,00',
                    received_lots: [
                        {
                            lot: {
                                lot_amount: 'R$ 3,00',
                                lot_brand: 'string',
                                lot_payment_type: 'string',
                                lot_stone_payment_id: 'string',
                            },
                            transaction_details: [
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                            ],
                        },
                    ],
                },
                {
                    detail_date: '16/11/2020',
                    date_amount: 'R$ 6,00',
                    received_lots: [
                        {
                            lot: {
                                lot_amount: 'R$ 6,00',
                                lot_brand: 'string',
                                lot_payment_type: 'string',
                                lot_stone_payment_id: 'string',
                            },
                            transaction_details: [
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        to_receive: {
            to_receive_period_amount: '9.00',
            to_receive_details: [
                {
                    detail_date: '15/11/2020',
                    date_amount: 'R$ 5,00',
                    to_receive_lots: [
                        {
                            lot: {
                                lot_amount: 'R$ 2,00',
                                lot_brand: 'Visa',
                                lot_payment_type: 'Débito',
                                lot_stone_payment_id: '1',
                            },
                            transaction_details: [
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                            ],
                        },
                        {
                            lot: {
                                lot_amount: 'R$ 2,00',
                                lot_brand: 'Mastercard',
                                lot_payment_type: 'Crédito',
                                lot_stone_payment_id: '2',
                            },
                            transaction_details: [
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                            ],
                        },
                        {
                            lot: {
                                lot_amount: 'R$ 1,00',
                                lot_brand: 'Mastercard',
                                lot_payment_type: 'Débito',
                                lot_stone_payment_id: 'string',
                            },
                            transaction_details: [
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                            ],
                        },
                    ],
                },
                {
                    detail_date: '16/11/2020',
                    date_amount: 'R$ 4,00',
                    to_receive_lots: [
                        {
                            lot: {
                                lot_amount: 'R$ 4,00',
                                lot_brand: 'string',
                                lot_payment_type: 'string',
                                lot_stone_payment_id: 'string',
                            },
                            transaction_details: [
                                {
                                    siclos_transaction_id: 'string',
                                    stone_transaction_id: 'string',
                                    order_id: 'string',
                                    gross_amount: 'string',
                                    net_amount: 'string',
                                    installments_number: 0,
                                    actual_installment: 0,
                                    anticipation_rate_amount: 'string',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })
    const [establishments, setEstablishments] = useState<Establishments>({ establishment_list: [] })
    const [recipients, setRecipients] = useState<Recipients>({ recipients: [] })
    const [selectedEstablishment, setSelectedEstablishment] = useState('')
    const [filter, setFilter] = useState<Filters>({
        profile_id: '',
        initial_date: '',
        till_date: '',
    })
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const getFinanceExtract = useCallback(
        async (filters: Filters) => {
            try {
                if (!filters.profile_id || !filters.initial_date || !filters.till_date) {
                    setModalMessageData({
                        title: 'Atenção',
                        message:
                            'Selecione pelo menos um perfil (estabelecimento ou recebedor), data inicial e data final para filtrar',
                    })

                    modalMessage.current?.openModal()
                    return
                }

                setLoading(true)

                const { data } = await siclosPagApi.post('/finance/extract', {
                    profile_id: filters.profile_id,
                    initial_date: filters.initial_date.replaceAll('-', ''),
                    till_date: filters.till_date.replaceAll('-', ''),
                })

                if (data?.error) {
                    throw data.msg
                }

                setFinanceExtract(data)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                    onClose: () => history.push('/painel-malls/gestao-stone'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const getRecipients = useCallback(
        async (selectedEstablishment: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get<Recipients>(
                    `/recipient/get-all-establishment-recipients/${selectedEstablishment}`
                )

                setRecipients(data)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/painel-malls'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    useEffect(() => {
        selectedEstablishment && getRecipients(selectedEstablishment)
    }, [getRecipients, selectedEstablishment])

    function setFilterParam(key: keyof Filters, value: string) {
        setFilter({ ...filter, [key]: value })
    }

    return (
        <div className="finance-extract-page">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <RowTitle
                title="Extrato"
                buttonRow={[
                    {
                        label: 'Filtrar',
                        onClick: () => getFinanceExtract({ ...filter }),
                    },
                ]}
            >
                <div className="filters-input-stack">
                    <SelectInputForm
                        label="Estabelecimento"
                        placeholder="Filtrar por estabelecimento"
                        data={establishments.establishment_list?.map(({ id, business_name }) => ({
                            name: business_name,
                            value: id,
                        }))}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedEstablishment(value)
                            setFilterParam('profile_id', value)
                        }}
                        value={selectedEstablishment}
                    />

                    <SelectInputForm
                        label="Recebedor"
                        placeholder="Filtrar por recebedor"
                        data={recipients.recipients?.map(({ recipient_id, business_name }) => ({
                            name: business_name,
                            value: recipient_id,
                        }))}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                            setFilterParam('profile_id', value)
                        }}
                        value={filter?.profile_id}
                    />
                </div>

                <div className="filters-input-stack">
                    <TextInputForm
                        label="Data inicial*"
                        type={'date'}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                            setFilterParam('initial_date', value)
                        }}
                        value={filter.initial_date}
                    />
                    <TextInputForm
                        label="Data Final*"
                        type={'date'}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                            setFilterParam('till_date', value)
                        }}
                        value={filter.till_date}
                    />
                </div>
            </RowTitle>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 60,
                    margin: '0 100px',
                }}
            >
                <div style={{ flexBasis: '45%' }}>
                    <div className="title">Valor total recebido</div>
                    <div className="value">{formatCurrency(financeExtract?.received.received_period_amount)}</div>

                    <FlexTable
                        columns={[
                            { name: 'Data', width: '35%' },
                            { name: 'Total da data', width: '35%' },
                            { name: '', width: '30%' },
                        ]}
                        list={financeExtract?.received.received_details.map(item => {
                            const { detail_date, date_amount, received_lots } = item

                            return {
                                detail_date,
                                date_amount,
                                buttonRow: (
                                    <div className="flex-table-row-content button-row">
                                        <div
                                            className="button-item outline"
                                            onClick={() =>
                                                history.push('/painel-malls/gestao-stone/transacoes-extrato', {
                                                    title: `Transações recebidas - ${detail_date}`,
                                                    date_amount,
                                                    extract_lots: received_lots,
                                                })
                                            }
                                        >
                                            Visualizar
                                        </div>
                                    </div>
                                ),
                            }
                        })}
                    />
                </div>
                <div style={{ flexBasis: '45%' }}>
                    <div className="title">Valor total a receber</div>
                    <div className="value">{formatCurrency(financeExtract?.to_receive.to_receive_period_amount)}</div>

                    <FlexTable
                        columns={[
                            { name: 'Data', width: '35%' },
                            { name: 'Total da data', width: '35%' },
                            { name: '', width: '30%' },
                        ]}
                        list={financeExtract?.to_receive.to_receive_details.map(item => {
                            const { detail_date, date_amount, to_receive_lots } = item

                            return {
                                detail_date,
                                date_amount,
                                buttonRow: (
                                    <div className="flex-table-row-content button-row">
                                        <div
                                            className="button-item outline"
                                            onClick={() =>
                                                history.push('/painel-malls/gestao-stone/transacoes-extrato', {
                                                    title: `Transações a receber - ${detail_date}`,
                                                    date_amount,
                                                    extract_lots: to_receive_lots,
                                                })
                                            }
                                        >
                                            Visualizar
                                        </div>
                                    </div>
                                ),
                            }
                        })}
                    />
                </div>
            </div>
        </div>
    )
}

export default FinanceExtractPage
