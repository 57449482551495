import MensagemErro from '../mensagem-erro'

export interface SelectItems {
    id?: number | string
    name: string
    value: string | number
}

interface Props {
    data?: SelectItems[]
    style?: React.CSSProperties
    label?: string
    labelStyle?: React.CSSProperties
    placeholder?: string
    onChange(e: React.ChangeEvent<HTMLSelectElement>): void
    value: string | number
    disabled?: boolean
    selectStyle?: React.CSSProperties
    msgColor?: string
    msgErro?: string | boolean | null
}

const SelectInputForm: React.FC<Props> = ({
    data,
    style,
    label,
    labelStyle,
    placeholder,
    onChange,
    value,
    disabled,
    selectStyle,
    msgColor,
    msgErro,
    ...props
}) => {
    return (
        <div className="item-input-form" style={style}>
            <label className="label" style={labelStyle}>
                {label}
            </label>
            <select onChange={onChange} value={value} disabled={disabled || false} style={selectStyle} {...props}>
                <option value={''} disabled selected>
                    {placeholder || 'Selecionar'}
                </option>
                {data?.map((item, index) => (
                    <option key={index} value={item.id || item.value}>
                        {item.name || item.value}
                    </option>
                ))}
            </select>
            <MensagemErro msgColor={msgColor} msgErro={msgErro} />
        </div>
    )
}

export default SelectInputForm
