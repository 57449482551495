/* eslint-disable react/style-prop-object */
import { memo, useState, useCallback, useRef, forwardRef, useImperativeHandle, useMemo, useEffect } from 'react'
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl'
import DrawControl from 'react-mapbox-gl-draw'

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { useAuth } from 'hooks'
import { mapboxAccessToken } from 'services/mapbox'

import store from '../../assets/images/store.png'

import AgentPopup from './agent-popup'
import BikersInRouteMarkers from './bikers-in-route-markers'
import CircleFill from './circle-fill'
import CircleLines from './circle-lines'
import DraggableMarker from './draggable-marker'
import GeoJsonLines from './geo-json-lines'
import GeoJsonPolygon from './geo-json-polygon'
import InfoMapMessage from './info-map-message'
import OrderMarker from './order-marker'
import OrdersMarkers from './orders-markers'
import PolygonFill from './polygon-fill'
import RouteOrdersMarkers from './route-orders-markers'
import WindRoseLines from './wind-rose-lines'

import './style.scss'

const Map = ReactMapboxGl({
    accessToken: mapboxAccessToken,
    trackResize: false,
})

const MapboxView = (
    {
        containerStyle = undefined,
        center = undefined,
        children = undefined,
        fitBounds = undefined,
        fitBoundsOptions = undefined,
        zoom = 12,
        markerCenter = undefined,
        onClick = undefined,
    },
    ref
) => {
    const { mall } = useAuth()
    const mapRefreshRef = useRef(null)
    const [defaultZoom, setDefaultZoom] = useState(zoom)

    const centerPosition = useMemo(() => {
        if (center) {
            return center
        }

        if (mall?.address) {
            return { lat: mall.address.lat, lng: mall.address.lng }
        }

        if (fitBounds && fitBounds.length > 0) {
            return fitBounds[0]
        }

        return [-38.5027509, -3.7438823]
    }, [center, fitBounds, mall])

    const _onStyleLoad = useCallback(e => {
        mapRefreshRef.current = e
        e.resize()
        let timeout
        window.onresize = () => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                e.resize()
            }, 500)
        }
    }, [])

    const _resize = useCallback(() => {
        mapRefreshRef.current?.resize()
    }, [mapRefreshRef])

    const _setCenter = useCallback(() => {
        if (centerPosition) {
            mapRefreshRef.current?.setCenter(centerPosition)
            mapRefreshRef.current?.setZoom(12)
        }
    }, [mapRefreshRef, centerPosition])

    const _setZoom = useCallback(
        zoom => {
            if (zoom) {
                mapRefreshRef.current?.setZoom(zoom)
            }
        },
        [mapRefreshRef]
    )

    useImperativeHandle(
        ref,
        () => ({
            resize: _resize,
            center: _setCenter,
            setZoom: _setZoom,
        }),
        [_resize, _setCenter, _setZoom]
    )

    useEffect(() => {
        if (mall?.address?.lat && mall?.address?.lng)
            [mapRefreshRef.current?.setCenter({ lat: mall.address.lat, lng: mall.address.lng })]
    }, [mall])
    return (
        <Map
            style="mapbox://styles/mapbox/streets-v11"
            containerStyle={{ minHeight: '75vh', height: '100%', ...containerStyle }}
            center={centerPosition}
            zoom={!fitBounds ? [defaultZoom] : undefined}
            onZoomEnd={(_, event) => setDefaultZoom(event.target.transform._zoom)}
            fitBounds={fitBounds}
            onClick={onClick}
            onStyleLoad={_onStyleLoad}
            fitBoundsOptions={fitBounds ? { padding: 200, ...fitBoundsOptions } : undefined}
        >
            <ZoomControl />

            {mall?.address && (
                <Marker
                    coordinates={markerCenter ? markerCenter : [mall.address.lng, mall.address.lat]}
                    anchor="bottom"
                >
                    <img src={store} alt="mall" style={{ width: 32, height: 32 }} />
                </Marker>
            )}

            {children}
        </Map>
    )
}

export {
    AgentPopup,
    DrawControl,
    BikersInRouteMarkers,
    CircleFill,
    CircleLines,
    DraggableMarker,
    InfoMapMessage,
    OrderMarker,
    OrdersMarkers,
    GeoJsonLines,
    GeoJsonPolygon,
    PolygonFill,
    RouteOrdersMarkers,
    WindRoseLines,
}

export default memo(forwardRef(MapboxView))
