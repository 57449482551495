import { forwardRef, memo, useImperativeHandle } from 'react'

import { Primary } from 'modals/modal-change-type-order/contents/change-type-content/change-type-content.styled'

import { InputItem } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'
import { Grid } from 'components/grid/grid'
import { Text } from 'components/text/text'

import { IAgent } from 'types/agent'

import { useChangeAgentModalityController } from './modal-change-agent-modality.controller'
import { Box, Container, ModalIcon } from './modal-change-agent-modality.styled'

type ModalChangeAgentModalityRef = {
    show(agent: IAgent): void
    close(): void
}

type Props = {
    onRefresh(): void
}

const ModalChangeAgentModality = memo(
    forwardRef<ModalChangeAgentModalityRef, Props>(({ onRefresh }, ref) => {
        const {
            selectOptions,
            selectedAgent,
            values,
            show,
            visible,
            _setShow,
            _close,
            _handleChangeModality,
            handleSubmit,
        } = useChangeAgentModalityController(onRefresh)

        useImperativeHandle(
            ref,
            () => ({
                show: agent => {
                    _setShow(agent)
                },
                close: _close,
            }),
            [_setShow, _close]
        )

        if (!visible) {
            return null
        }

        return (
            <Container>
                <Box show={show}>
                    <Grid.Column maxWidth align="center" justify="center" marginBottom={16}>
                        <ModalIcon />
                        <Text dimensions="medium" bold>
                            {selectedAgent?.full_name}
                        </Text>
                        <Text dimensions="xSmall" light>
                            {formatTypeName(selectedAgent?.type_name)}
                        </Text>
                    </Grid.Column>

                    <InputItem
                        type="select"
                        labelText="Alterar Modal de Entrega?"
                        options={selectOptions}
                        inputProps={{
                            value: values.modality,
                            onChange: _handleChangeModality,
                        }}
                    />

                    <Text align="center" dimensions="small" marginBottom={16}>
                        Ao alterar o tipo de entrega, alguns <Primary>dados adicionais podem ser solicitados</Primary>,
                        como a inclusão de uma CNH <Primary>Deseja continuar mesmo assim?</Primary>
                    </Text>

                    <Grid.Column className="container-buttons" justify="space-between" align="center">
                        <DefaultButton
                            title="Atualizar"
                            variant="success"
                            disabled={!values?.modality}
                            onClick={handleSubmit}
                        />
                        <DefaultButton title="Cancelar" withoutBorder outline variant="danger" onClick={_close} />
                    </Grid.Column>
                </Box>
            </Container>
        )
    })
)

function formatTypeName(typeName: string): string {
    return {
        bike: 'BICICLETA',
        car: 'CARRO',
        moto: 'MOTO',
        truck: 'CAMINHÃO',
        van: 'VAN',
    }[typeName]
}

export { ModalChangeAgentModality }
