import { useState, useEffect } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import theme from 'styles/theme'

import {
    CloseContainer,
    CloseIcon,
    SnackbarIconContainer,
    SnackbarIcon,
    SnackbarContainer,
    TextContainer,
} from './snackbar.styles'

type SnackbarType = 'alert' | 'error' | 'success'

interface Props {
    isActive?: boolean
    message?: string
    closeClick?(): void
    type?: SnackbarType
    rightSide?: boolean
}

const Snackbar: React.FC<Props> = ({ isActive, message, closeClick, type, rightSide }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 1000)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 1000)
        }
    }, [isActive])

    function getIconByType(type: SnackbarType): IconProp {
        return {
            alert: 'exclamation-circle' as IconProp,
            error: 'exclamation-circle' as IconProp,
            success: 'check-circle' as IconProp,
        }[type]
    }

    function getColorByType(type: SnackbarType): string {
        return {
            alert: theme.colors.primary,
            error: theme.colors.errorMessage,
            success: theme.colors.success,
        }[type]
    }

    return (
        <SnackbarContainer isDisplayed={isDisplayed} isEffectActive={isEffectActive} rightSide={rightSide}>
            <SnackbarIconContainer>
                {type && <SnackbarIcon icon={getIconByType(type)} color={getColorByType(type)} />}
            </SnackbarIconContainer>
            <TextContainer>{message}</TextContainer>
            <CloseContainer onClick={closeClick}>
                <CloseIcon />
            </CloseContainer>
        </SnackbarContainer>
    )
}

export default Snackbar
