import { useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'

import './style.scss'
import {
    DraggableShowcaseContainer,
    TableHeaderRow,
    TableRow,
    TableRowContent,
    TableRowItem,
} from './flex-table-draggable.styled'

type FlexTableRowProps = {
    columns: Column[]
    item: ListItem
    accordionComponent?: string | JSX.Element | JSX.Element[]
    onClickRow?(): void
}

const FlexTableRowItem: React.FC<FlexTableRowProps> = ({ columns, item, accordionComponent, onClickRow }) => {
    const [active, setActive] = useState('')
    const [height, setHeight] = useState('0px')

    const content = useRef<HTMLDivElement>(null)

    function toggleAccordion() {
        setActive(active === '' ? 'active' : '')
        setHeight(active === 'active' ? '0px' : `${content?.current?.scrollHeight}px`)
    }

    return (
        <TableRow onClick={() => onClickRow && onClickRow()}>
            <TableRowItem
                onClick={accordionComponent ? toggleAccordion : undefined}
                style={{ cursor: onClickRow && 'pointer' }}
            >
                {Object.keys(item)
                    .filter(key => typeof item[key] !== 'function')
                    .map((key, index) => (
                        <TableRowContent
                            key={index}
                            className="flex-table-row-content"
                            style={{ width: columns[index].width, ...columns[index].style }}
                        >
                            {item[key]}
                        </TableRowContent>
                    ))}
            </TableRowItem>

            <div ref={content} style={{ maxHeight: `${height}` }} className="accordion-content">
                {accordionComponent}
            </div>
        </TableRow>
    )
}

interface Column {
    name: string
    width: string
    style?: React.CSSProperties
}

interface ListItem {
    [key: string]: string | JSX.Element
}

type Props = {
    accordionList?: JSX.Element[] | undefined
    columns: Column[]
    list: ListItem[] | undefined
    onClickRow?(index: number): void
    onDragEnd(result: DropResult, provided: ResponderProvided): void
}

const FlexTableDraggable: React.FC<Props> = ({ accordionList, columns, list, onClickRow, onDragEnd }) => {
    return (
        <div className="flex-table-draggable">
            <TableHeaderRow>
                {columns.map((column, index) => (
                    <div key={index} className="table-head-item" style={{ width: column?.width, ...column.style }}>
                        {column.name}
                    </div>
                ))}
            </TableHeaderRow>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'flex-table-draggable'}>
                    {provided => {
                        return (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {list?.map((item, index) => (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {provided => (
                                            <DraggableShowcaseContainer
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <FlexTableRowItem
                                                    key={index}
                                                    columns={columns}
                                                    item={item}
                                                    accordionComponent={accordionList && accordionList[index]}
                                                    onClickRow={onClickRow ? () => onClickRow(index) : undefined}
                                                />
                                            </DraggableShowcaseContainer>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default FlexTableDraggable
