import styled from 'styled-components'

export const ProductItemContainer = styled.div`
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    user-select: none;
    //border-bottom: 1px solid #e1e1e1;

    background-color: #eaeaea;
    &:nth-child(odd) {
        background-color: #fff;
    }

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px;
    }
    span {
        font-weight: bold;
        font-size: 12px;
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            display: none;
        }
    }

    div {
        display: flex;
        justify-content: center;

        &:first-child {
            justify-content: start;
        }
    }
`

export const ProductStatus = styled.div`
    flex-basis: 35%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 9%;
    }
`

export const ProductSku = styled.div`
    flex-basis: 65%;
    text-align: right;
    font-size: 0.75rem;
    justify-content: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 9%;
        text-align: left;
    }
`

export const ProductInfo = styled.div`
    display: flex;
    //margin-right: 5%;
    margin-bottom: 20px;
    flex-basis: 100%;
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 49%;
        margin-bottom: 0;
        margin-top: 0;
    }
`

export const ProductAmount = styled.div`
    flex-basis: 30%;
    color: #3a3a3a;
    font-size: 0.75rem;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 9%;
    }
`

export const ProductPrice = styled.div`
    flex-basis: 30%;
    color: #3a3a3a;
    font-size: 0.75rem;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 9%;
    }
`

export const ProductPriceInOffer = styled.div`
    text-decoration: line-through;
`

export const EditButton = styled.div`
    flex-basis: 30%;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 0px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 9%;
    }
`

export const ProductPhoto = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 12px;
    object-fit: cover;
    margin-right: 20px;
`

export const ProductText = styled.div`
    flex: 1;
    flex-direction: column;
`

export const ProductName = styled.div`
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3a3a3a;
`

export const ProductDescription = styled.div`
    //background-color: #f1f1f1;
    //padding: 10px;
    //border-radius: 12px;
    height: 55px;
    overflow: auto;
    font-size: 0.75rem;
`

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 9%;
    }
`
