import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

import { format } from 'date-fns'

import { formatCEP, formatCurrency, formatPhone, getSalesChannelIcon } from 'helpers'
import { useDataFetching } from 'hooks/useDataFetching'
import { PendingOrder } from 'types'

import { TableOrderItem } from '..'

import {
    ButtonRow,
    ConfirmButton,
    OrderDetailsContainer,
    OrderDetailTitleContainer,
    OrderHeader,
    OrderSubtitle,
    OrderTitle,
    ScrollBox,
    TextButton,
    ChannelIcon,
    CustomerInfo,
    OrderPrice,
    CustomerId,
    CustomerName,
    PriceValue,
    PaymentMethod,
    Address,
    AddressTitle,
    AddressValue,
    OrderItensContainer,
    ItensTitle,
    CloseButton,
    FinalValuesContainer,
    FinalValueItem,
} from './order-details-content.styles'

interface Props {
    orderId?: number
    onClose?(): void
    onConfirm(order: PendingOrder): void
    onReject(order: PendingOrder): void
}

const OrderDetailsContent: React.FC<Props> = ({ orderId, onClose, onConfirm, onReject }) => {
    const { data, loading } = useDataFetching<PendingOrder>(orderId ? `/mercadoo/order/${orderId}` : null)

    const captureDate = useMemo(() => {
        if (!data?.created_at) return

        return format(new Date(data.created_at), 'DD/MM/YYYY [às] HH:mm')
    }, [data])

    const formattedAddress = useMemo(() => {
        const address = data?.address

        if (!address) return

        const { street, number, neighborhood, zipcode } = address

        return `${street}, ${number} - ${neighborhood} - CEP: ${formatCEP(zipcode)}`
    }, [data])

    return (
        <OrderDetailsContainer>
            {loading || !data ? (
                <>
                    <OrderDetailTitleContainer>
                        <Skeleton height={40} width={300} />
                        <Skeleton height={2} width={300} />
                        <Skeleton height={30} width={500} />
                    </OrderDetailTitleContainer>

                    <ScrollBox>
                        <OrderHeader>
                            <Skeleton circle width={80} height={80} style={{ marginRight: 20 }} />
                            <Skeleton height={80} width={250} style={{ marginRight: 20 }} />
                            <Skeleton height={80} width={250} style={{ marginRight: 20 }} />
                        </OrderHeader>
                        <OrderItensContainer>
                            <Skeleton height={30} width={200} style={{ marginBottom: 20 }} />

                            <Skeleton count={3} height={100} style={{ marginBottom: 10 }} />
                        </OrderItensContainer>
                    </ScrollBox>
                </>
            ) : (
                <>
                    <OrderDetailTitleContainer>
                        <OrderTitle>Pedido #{data.id}</OrderTitle>
                        <OrderSubtitle>
                            Capturado em <b>{captureDate}</b>
                        </OrderSubtitle>
                        <CloseButton onClick={onClose} />
                    </OrderDetailTitleContainer>
                    <ScrollBox>
                        <OrderHeader>
                            <ChannelIcon src={getSalesChannelIcon(data)} />
                            <CustomerInfo>
                                <CustomerId>#{data.customer.id}</CustomerId>
                                <CustomerName>{data.customer.name}</CustomerName>
                                <CustomerId>{formatPhone(data.customer.cellphone, true)}</CustomerId>
                            </CustomerInfo>
                            <OrderPrice>
                                <PriceValue>{formatCurrency(data.price.total)}</PriceValue>
                                <PaymentMethod>{data.payment?.name}</PaymentMethod>
                            </OrderPrice>
                            <Address>
                                <AddressTitle>Endereço de entrega</AddressTitle>
                                <AddressValue>{formattedAddress}</AddressValue>
                                <AddressValue>
                                    {[data.address.complement, data.address.landmark].filter(item => item).join(' - ')}
                                </AddressValue>
                            </Address>
                        </OrderHeader>
                        <OrderItensContainer>
                            <ItensTitle>Itens do pedido</ItensTitle>
                            {data.items.map((orderItem, index) => (
                                <TableOrderItem key={index} orderItem={orderItem} />
                            ))}
                        </OrderItensContainer>
                        <FinalValuesContainer>
                            <FinalValueItem>Subtotal: {formatCurrency(data.price.total)}</FinalValueItem>
                            <FinalValueItem>Taxa de entrega: {formatCurrency(data.price.delivery_fee)}</FinalValueItem>
                            <FinalValueItem>
                                Total do pedido:{' '}
                                {data.price.delivery_fee
                                    ? formatCurrency(data.price.total + data.price.delivery_fee)
                                    : formatCurrency(data.price.total)}
                            </FinalValueItem>
                        </FinalValuesContainer>
                    </ScrollBox>
                    {!data.status && (
                        <ButtonRow>
                            <TextButton onClick={() => onReject(data)}>Não aceitar</TextButton>
                            <ConfirmButton onClick={() => onConfirm(data)}>Confirmar</ConfirmButton>
                        </ButtonRow>
                    )}
                </>
            )}
        </OrderDetailsContainer>
    )
}

export default OrderDetailsContent
