import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ContentContainer = styled.div`
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
        padding-top: 30px;
    }
`

export const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const TitleContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
    }
`

export const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 30px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

export const FormSection = styled.div`
    margin-bottom: 30px;
`

export const FormSectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
`

export const InputsRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const InputContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 48%;
    }
`

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

export const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 12px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const ConfirmButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

export const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

export const OrderStatus = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
        padding-top: 10px;
    }
`

export const StatusCircle = styled.div<{ status: number }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ status }) => (status === 1 ? 'red' : status === 2 ? 'green' : 'blue')};
`

export const StatusTitle = styled.div`
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
`

export const TabsContainer = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
`

export const TabItem = styled.div<{ isActive?: boolean }>`
    text-transform: uppercase;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.lightGray)};
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`
