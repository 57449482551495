import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ModalLateralBag, ModalLateralBagRef } from 'modals/modal-lateral-bag/modal-lateral-bag'

import BagsListingHeader, { TBagFilter } from 'components/_bags/bags-listing-header/bags-listing-header'
import { AmountCard, PerformanceCard } from 'components/_common'
import { BagsListingTable } from 'components/_store-general-vision'
import ModalLoading from 'components/modal-loading'

import { formatCurrency, setSearchParams } from 'helpers'
import { mercadooApi } from 'services/api'
import { IBagItem, PagedList } from 'types'

import {
    AmountItem,
    AmountRow,
    CardRow,
    PerformanceContent,
    PerformancePageContainer,
    RowItem,
} from './mercadoo-bags-listing.styles'

interface Amount {
    quantity: number
    amount: number
}
interface IHeader {
    bag: {
        quantity: number
        multi_store: number
    }
    order: {
        indoor: Amount
        takeout: Amount
        delivery: Amount
        total: Amount
    }
}

export default function MercadooBagsListing(): JSX.Element {
    const history = useHistory()
    const location = useLocation()

    const modalLateralBag = useRef<ModalLateralBagRef>()

    const [filters, setFilters] = useState<TBagFilter>()
    const [bags, setBags] = useState<IBagItem[]>([])
    const [page, setPage] = useState<number>(1)
    const [header, setHeader] = useState<IHeader>()
    const [hasMore, setHasMore] = useState<boolean>(false)

    const [loading, setLoading] = useState(false)

    const _openBag = useCallback((id: number) => {
        modalLateralBag.current?.show({ bag_id: id })
    }, [])

    const _getBags = useCallback(async (page: number, filter: TBagFilter) => {
        if (!filter.mall_id || !filter.start_date || !filter.end_date) {
            return
        }
        setLoading(true)

        try {
            const { data } = await mercadooApi.get<PagedList<IBagItem, IHeader>>('/bags/report', {
                params: {
                    mall_id: filter.mall_id || undefined,
                    start_date: filter.start_date || undefined,
                    end_date: filter.end_date || undefined,
                    type: filter.type || undefined,
                    order: filter.order_by || 'desc',
                    current_page: page,
                    per_page: 100,
                },
            })
            setHeader(data.header)
            if (page > 1) {
                setBags(state => [...state, ...data.items])
            } else {
                setBags(data.items)
            }
            setPage(data.current_page)
            setHasMore(data.has_more)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        const query = new URLSearchParams(location.search)

        const filter = {
            mall_id: Number(query.get('mall_id')) || undefined,
            start_date: query.get('start_date') || undefined,
            end_date: query.get('end_date') || undefined,
            type: query.get('type') || undefined,
            order_by: query.get('order_by') || 'desc',
        }
        setFilters(filter)
        _getBags(1, filter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (page > 1) {
            _getBags(page, filters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <>
            <PerformancePageContainer>
                {!!filters && (
                    <BagsListingHeader
                        title="Mercadoo - Vendas"
                        filters={filters}
                        onFilterData={filters => {
                            setSearchParams(filters, location, history)
                            _getBags(1, filters)
                            setFilters(filters)
                        }}
                    />
                )}
                <PerformanceContent>
                    {!!header && (
                        <>
                            <PerformanceCard title="Geral">
                                <AmountRow>
                                    <AmountItem>
                                        <AmountCard title="Vendas totais" number={header.bag.quantity} />
                                    </AmountItem>
                                    <AmountItem>
                                        <AmountCard title="Vendas multilojas" number={header.bag.multi_store} />
                                    </AmountItem>
                                    <AmountItem>
                                        <AmountCard title="Total de pedidos" number={header.order.total.quantity} />
                                    </AmountItem>
                                    <AmountItem>
                                        <AmountCard
                                            title="Total vendido"
                                            number={formatCurrency(header.order.total.amount)}
                                        />
                                    </AmountItem>
                                </AmountRow>
                            </PerformanceCard>
                            <CardRow>
                                <RowItem>
                                    <PerformanceCard title="Atendimento em mesa">
                                        <AmountRow>
                                            <AmountItem>
                                                <AmountCard title="Quantidade" number={header.order.indoor.quantity} />
                                            </AmountItem>
                                            <AmountItem>
                                                <AmountCard
                                                    title="Valor Total"
                                                    number={formatCurrency(header.order.indoor.amount)}
                                                />
                                            </AmountItem>
                                        </AmountRow>
                                    </PerformanceCard>
                                </RowItem>
                                <RowItem>
                                    <PerformanceCard title="Retirada em balcão">
                                        <AmountRow>
                                            <AmountItem>
                                                <AmountCard title="Quantidade" number={header.order.takeout.quantity} />
                                            </AmountItem>
                                            <AmountItem>
                                                <AmountCard
                                                    title="Valor Total"
                                                    number={formatCurrency(header.order.takeout.amount)}
                                                />
                                            </AmountItem>
                                        </AmountRow>
                                    </PerformanceCard>
                                </RowItem>
                            </CardRow>
                        </>
                    )}
                    <BagsListingTable
                        bags={bags}
                        detailClick={_openBag}
                        loading={false}
                        hasMore={hasMore}
                        loadMoreClick={() => setPage(state => state + 1)}
                        totals={0}
                    />
                </PerformanceContent>
                <ModalLoading visible={loading} />
            </PerformancePageContainer>
            <ModalLateralBag ref={modalLateralBag} />
        </>
    )
}
