import styled from 'styled-components'

export const ProductCategoryContainer = styled.div`
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    overflow: hidden;
`

export const CategoryHeader = styled.div`
    background-color: #eaeaea;
    padding: 20px;
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 2;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px;
    }
`

export const CategoryTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 16px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.textLight};
    display: inline-flex;
    padding-bottom: 7px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 18px;
        margin-bottom: 20px;
    }
`

export const BoldText = styled.span`
    font-weight: bold;
    font-size: 12px;
`

export const TableRow = styled.div`
    display: none;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.textLight};
    div {
        display: flex;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
    }
`

export const ProductStatus = styled.div`
    flex-basis: 9%;
    justify-content: center;
`

export const ProductId = styled.div`
    flex-basis: 9%;
`
export const ProductSku = styled.div`
    flex-basis: 9%;
    justify-content: center;
`

export const ProductInfo = styled.div`
    flex-basis: 49%;
    text-align: center;
    justify-content: center;
`

export const ProductAmount = styled.div`
    flex-basis: 9%;
    justify-content: center;
`

export const ProductPrice = styled.div`
    flex-basis: 9%;
    justify-content: center;
`

export const EditButton = styled.div`
    flex-basis: 9%;
`

export const CategoryProductsContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
`
