import { Component } from 'react'

import './style.scss'
import icon from '../../assets/images/alert-circle.png'

export default class OfflineScreen extends Component {
    render() {
        return (
            <div className="offline-screen">
                <div className="icon-container">
                    <img src={icon} alt="" />
                </div>

                <div className="main-title">Você não está conectado a internet!</div>

                <div className="small-text">
                    Para continuar sua operação é fundamental que você esteja conectado a um sinal de Internet para
                    continuar trabalhando.
                </div>
                <div className="small-text">Verifique se tudo está ok e tente novamente!</div>
            </div>
        )
    }
}
