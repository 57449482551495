import { faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import empty from '../../../assets/images/empty-table.png'
export const TableContainer = styled.div`
    flex: 1;
    min-height: 500px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        min-height: 0;
        height: unset;
    }
`

export const OrderTable = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`

export const TableHeader = styled.div`
    display: none;
    align-items: center;
    background-color: #f6f6f6;
    padding: 10px;
    overflow: hidden;
    border-bottom: 2px solid #ded3d3;
    color: #999;
    font-weight: 600;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
    }
`

export const TableContent = styled.div`
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const TableItem = styled.div`
    flex: 1;
    padding: 20px;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        align-items: center;
        padding: 10px;
    }
    &:nth-child(even) {
        background-color: #eee;
    }
    span {
        font-weight: bold;
        margin-right: 5px;
        @media (min-width: ${({ theme }) => theme.devices.desktop}) {
            display: none;
        }
    }
`

export const CustomerName = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
    }
`

export const CustomerAddress = styled.div``

export const DetailsItem = styled(FontAwesomeIcon).attrs({
    icon: faFileDownload,
})`
    font-size: 20px;
    cursor: pointer;
    transition: 400ms;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-top: 0;
    }
`

export const ItemButton = styled.div`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    background-color: transparent;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        border: 0;
        justify-content: flex-end;
        margin-top: 0;
    }
`

export const ButtonText = styled.div`
    margin-left: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const EmptyMessageContainer = styled.div`
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const EmptyImage = styled.img.attrs({
    src: empty,
})`
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
`

export const EmptyMessage = styled.div`
    font-size: 14px;
    color: #666;
    font-weight: bold;
`

export const ActionHeader = styled.div`
    padding: 15px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const ActionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

export const ActionButton = styled.div<{ outline?: boolean }>`
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    background-color: ${({ theme, outline }) => (outline ? theme.colors.white : theme.colors.primary)};
    color: ${({ theme, outline }) => (outline ? theme.colors.primary : theme.colors.white)};
    font-size: 14px;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 6px;
    font-weight: 600;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
    }
    &:hover {
        opacity: 0.6;
    }
    &:not(:last-child) {
        margin-right: 20px;
    }
`

export const TableId = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 7%;
        padding-right: 2%;
    }
`

export const TableBiker = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 15%;
        padding-right: 2%;
    }
`

export const TableSocial = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 25%;
        padding-right: 2%;
    }
`

export const TableVehicle = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 17%;
        padding-right: 2%;
    }
`

export const TableTotals = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 17%;
        padding-right: 2%;
    }
`

export const TableValue = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        width: 10%;
        padding-right: 2%;
    }
`

export const TableButtons = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        margin-top: 0;
        width: 60px;
    }
`

export const DownloadButtonContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 10px;
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        border: 0;
    }
`

export const DownloadButton = styled(FontAwesomeIcon).attrs({
    icon: faDownload,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 20px;
    cursor: pointer;
    transition: 400ms;
    font-size: 20px;
    &:hover {
        opacity: 0.6;
    }
`

export const DownloadText = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const ReportButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    padding: 10px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: 400ms;
    flex: 1;
    text-align: center;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 12px;
    }
    &:hover {
        opacity: 0.6;
    }
`
