import { ChartData } from 'chart.js'

import { ChartObject } from 'types'

export function getPerformanceChart(performance: ChartData<any>): ChartObject {
    return {
        data: performance,
        callbacks: {
            label: context => {
                let label = context.dataset.names[context.dataIndex] || ''

                if (label) {
                    label += ': '
                }
                if (context.parsed.y !== null) {
                    label += context.parsed.y
                }
                return label
            },
        },
    }
}
