import { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import Axios from 'axios'
import cep from 'cep-promise'

import { useAuth } from 'hooks'

import ButtonForm from '../../components/button-form'
import CheckboxInput from '../../components/checkbox-input'
import DropzoneInput from '../../components/dropzone-input/dropzone-input'
import MapboxView, { CircleFill, DraggableMarker } from '../../components/mapbox-view'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import SelectInputForm from '../../components/select-input-form'
import TextInputForm from '../../components/text-input-form'
import TextInputMask from '../../components/text-input-mask'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { showErrors, objectIsEmpty } from '../../helpers'
import api, { googleMapsKey } from '../../services/api'

import { validateFields, removeMask, statusArray, preparationArray, priceArray } from './store-creation-helpers'

import './style.scss'
import { InputPlace } from 'components/input-place/input-place'

const StoreCreation = ({ history, match }) => {
    const { mall, basePath } = useAuth()
    const [loading, setLoading] = useState(false)
    const [store, setStore] = useState({})
    const [collectFee, setCollectFee] = useState({
        bike: { not_work: false, agent: 0, store: 0 },
        moto: { not_work: false, agent: 0, store: 0 },
        car: { not_work: false, agent: 0, store: 0 },
        van: { not_work: false, agent: 0, store: 0 },
        truck: { not_work: false, agent: 0, store: 0 },
    })
    const [types, setTypes] = useState([])
    const [categories, setCategories] = useState([])
    const [errors, setErrors] = useState({ collect_fee: {} })
    const [showAllAddress, setShowAllAddress] = useState(false)
    const modalMessage = useRef(null)
    const modalDelete = useRef(null)
    const [modalMessageData, setModalMessageData] = useState({
        title: '',
        message: '',
        textButton: null,
        onClose: () => {},
    })

    const { id } = match.params

    const getSelectData = useCallback(async () => {
        try {
            setLoading(true)

            const responseTypes = await api.get(`/painel/store-types?per_page=-1`)
            const responseCategories = await api.get(`/painel/store-categories?per_page=-1`)

            setTypes(responseTypes.data.items)
            setCategories(responseCategories.data.items)
        } catch (error) {
            console.log({ error })

            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/lojas`),
            })
            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }, [history, mall])

    useEffect(() => {
        getSelectData()
    }, [getSelectData])

    const getStore = useCallback(
        async id => {
            try {
                setLoading(true)

                const { data } = await api.get(`/painel/store/${id}`)

                setStore({
                    ...data,
                    neighborhood: data?.address?.neighborhood,
                    store_type_id: data.store_category.store_type.id,
                    store_category_id: data.store_category.id,

                    status: data.status.toString(),
                })

                if (data.is_satelite) {
                    const { agent, store } = data.collect_fee

                    setCollectFee({
                        bike: {
                            not_work: agent.bike == null && store.bike == null,
                            agent: agent.bike,
                            store: store.bike,
                        },
                        moto: {
                            not_work: agent.moto == null && store.moto == null,
                            agent: agent.moto,
                            store: store.moto,
                        },
                        car: {
                            not_work: agent.car == null && store.car == null,
                            agent: agent.car,
                            store: store.car,
                        },
                        van: {
                            not_work: agent.van == null && store.van == null,
                            agent: agent.van,
                            store: store.van,
                        },
                        truck: {
                            not_work: agent.truck == null && store.truck == null,
                            agent: agent.truck,
                            store: store.truck,
                        },
                    })
                }
            } catch (error) {
                console.log({ error })
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    useEffect(() => {
        if (id) {
            getStore(id)
        }
    }, [getStore, id])

    useEffect(() => {
        if (!id && objectIsEmpty(store) && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords

                    setStore({
                        ...store,
                        lat: latitude,
                        lng: longitude,
                    })
                },
                error => {
                    console.log(error)

                    setStore({
                        ...store,
                        lat: store?.address?.lat || mall.address.lat,
                        lng: store?.address?.lat || mall.address.lng,
                    })
                },
                { enableHighAccuracy: true, maximumAge: 30000, timeout: 30000 }
            )
        }
    }, [store, mall, id])

    const deleteStore = async () => {
        try {
            setLoading(true)
            await api.delete(`/painel/store/${id}`)
            setModalMessageData({
                title: 'Sucesso!',
                message: 'Loja removida com sucesso.',
                onClose: () => history.push(`${basePath}/lojas`),
            })
            modalMessage.current.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/lojas`),
            })
            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    const onChange = (key, value) => {
        setStore({ ...store, [key]: value })
    }

    const onChangeCollectFee = (key, value) => {
        setCollectFee({ ...collectFee, [key]: value })
    }

    async function updateImages(id, banner, logo) {
        if (!id) return

        if (banner instanceof File) {
            const bannerFormData = new FormData()
            bannerFormData.set('banner', banner)

            await api.post(`/painel/store/${id}/banner`, bannerFormData)
        }

        if (logo instanceof File) {
            const logoFormData = new FormData()
            logoFormData.set('logo', logo)

            await api.post(`/painel/store/${id}/logo`, logoFormData)
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)

            if (!validateFields(store, collectFee, setErrors)) return

            let body = {
                mall_id: mall.id,
                //loja
                name: store.name,
                description: store.description,
                trade_name: store.trade_name,
                company_name: store.company_name,
                cnpj: removeMask(store.cnpj),
                store_type_id: store.store_type_id,
                store_category_id: store.store_category_id,
                slug: store.slug,
                //proprietário
                owner_name: store.owner_name,
                owner_cpf: removeMask(store.owner_cpf),
                owner_email: store.owner_email,
                owner_cellphone: removeMask(store.owner_cellphone),
                //configuração
                operating_hour: store.operating_hour,
                preparation_time: store.preparation_time,
                time_rating: store.time_rating,
                price_rating: store.price_rating,

                phone: store.phone,
                //situação
                working: store.working ? 1 : 0,
                status: store.status,
                //satélite
                is_satelite: store.is_satelite ? 1 : 0,
                //marketplace
                marketplace_enable: store.marketplace_enable ? 1 : 0,
            }

            body.configs = {
                delivery_ops: store.configs?.delivery_ops ? 1 : 0,
                picker_ops: store.configs?.picker_ops ? 1 : 0,
                commission: parseFloat(store.configs?.commission),
            }

            if (store.is_satelite) {
                const { bike, moto, car, van, truck } = collectFee

                body.configs = {
                    ...body.configs,
                    radius: store.configs?.radius,
                }

                body.collect_fee = {
                    agent: {
                        bike: bike.agent,
                        moto: moto.agent,
                        car: car.agent,
                        van: van.agent,
                        truck: truck.agent,
                    },
                    store: {
                        bike: bike.store,
                        moto: moto.store,
                        car: car.store,
                        van: van.store,
                        truck: truck.store,
                    },
                }

                body.address = {
                    city: store.city,
                    state: store.state,
                    number: store.number,
                    street: store.street,
                    zipcode: removeMask(store.zipcode),
                    landmark: store.landmark,
                    location: {
                        lat: store.lat,
                        lng: store.lng,
                    },
                    complement: store.complement,
                    neighborhood: store.neighborhood,
                }
            }

            if (id) {
                await api.put(`/painel/store/${id}`, body)

                await updateImages(id, store.banner, store.logo)

                setModalMessageData({
                    title: 'Sucesso!',
                    message: 'Loja atualizada com sucesso.',
                    textButton: 'Continuar',
                    onClose: () => history.push(`${basePath}/lojas`),
                })

                modalMessage.current.openModal()
            } else {
                const { data } = await api.post(`/painel/store`, body)

                await updateImages(data.id, store.banner, store.logo)

                setModalMessageData({
                    title: 'Sucesso!',
                    message: 'Loja criada com sucesso.',
                    textButton: 'Continuar',
                    onClose: () => history.push(`${basePath}/lojas`),
                })

                modalMessage.current.openModal()
            }
        } catch (err) {
            console.log('handleSubmit', err)

            setModalMessageData({
                title: 'Erro',
                message: showErrors(err),
                textButton: 'Revisar alterações',
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    async function getAddressByCEP(zipcode) {
        try {
            if (zipcode.length === 8) {
                setLoading(true)

                const response = await cep(zipcode)

                setStore({
                    ...store,
                    neighborhood: response.neighborhood,
                    street: response.street,
                    zipcode: response.cep,
                })
            }
        } catch (error) {
            console.log({ error, message: error.message })

            setErrors({ ...errors, zipcode: error.message })
        } finally {
            setLoading(false)
        }
    }

    const getAddressByGeodecoding = async () => {
        try {
            setLoading(true)
            const { zipcode, neighborhood, street, number, city, state } = store

            const {
                data: {
                    results: [first_result],
                },
            } = await Axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: `${number} ${street}, ${zipcode}, ${neighborhood}, ${city}, ${state}`.replaceAll(' ', '+'),
                    key: googleMapsKey,
                },
            })

            const {
                geometry: { location },
            } = first_result

            setStore({
                ...store,
                lat: location.lat,
                lng: location.lng,
                location_changed: true,
            })
        } catch (error) {
            console.log('getAddressByGeodecoding ERROR:', { error })
        } finally {
            setLoading(false)
        }
    }

    function formatPercentage(value) {
        let stringValue = value.toString()
        if (stringValue.length !== 5) {
            if (stringValue.indexOf('.') === 1) {
                return '0' + stringValue
            }
            if (stringValue.indexOf('.') === 2) {
                return stringValue + '0'
            }
        }
        return stringValue
    }

    const getFitBounds = () => {
        return store.lat && store.lng
            ? [
                  [mall.address.lng, mall.address.lat],
                  [store.lng, store.lat],
              ]
            : null
    }

    const stringAddress = useMemo(() => {
        const address = store?.address || mall.address
        if (address.street && address.number && address.neighborhood && address.zipcode) {
            return `${address.street}, ${address.number} - ${address.neighborhood} (${address.zipcode})`
        }
    }, [store, mall])

    const _setAddress = useCallback(address => {
        if (address) {
            const lat = Number(address.location.lat || '')
            const lng = Number(address.location.lng || '')

            delete address.location
            setStore(state => ({
                ...state,
                street: address.street || '',
                state: address.state || '',
                city: address.city || '',
                number: address.number || '',
                neighborhood: address.neighborhood || '',
                zipcode: (address.zipcode || '').replace(/\D/g, ''),
                lat,
                lng,
            }))

            setShowAllAddress(true)
        }
    }, [])

    const { bike, moto, car, van, truck } = collectFee

    return (
        <div className="page-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                textButton={modalMessageData.textButton}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Loja"
                message="Deseja realmente excluir esta loja?"
                onYes={() => deleteStore()}
            />

            <TopRowDefault
                title="Loja"
                onBackButtonClick={() => history.push(`${basePath}/lojas`)}
                buttons={
                    id && [
                        {
                            title: 'Excluir Loja',
                            icon: 'trash-alt',
                            onClick: () => modalDelete.current.openModal(),
                        },
                    ]
                }
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="store-left-column">
                        <div className="title">{id ? 'Edição' : 'Criação'}</div>

                        <div className="store-form-container">
                            <TextInputForm
                                label="Nome*"
                                value={store.name}
                                onChange={e => onChange('name', e.target.value)}
                                msgErro={errors.name}
                            />
                            <TextInputForm
                                label="Slug*"
                                value={store.slug}
                                onChange={e => onChange('slug', e.target.value)}
                                msgErro={errors.slug}
                            />
                            <TextInputForm
                                label="Descrição*"
                                value={store.description}
                                onChange={e => onChange('description', e.target.value)}
                                msgErro={errors.description}
                            />
                            <TextInputForm
                                label="Nome Comercial*"
                                value={store.trade_name}
                                onChange={e => onChange('trade_name', e.target.value)}
                                msgErro={errors.trade_name}
                            />
                            <TextInputForm
                                label="Razão Social*"
                                value={store.company_name}
                                onChange={e => onChange('company_name', e.target.value)}
                                msgErro={errors.company_name}
                            />
                            <TextInputMask
                                label="CNPJ"
                                value={store.cnpj}
                                onChange={e => onChange('cnpj', e.target.value)}
                                msgErro={errors.cnpj}
                                mask="99.999.999/9999-99"
                            />
                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <SelectInputForm
                                        label="Tipo de Loja*"
                                        data={types}
                                        value={store.store_type_id}
                                        onChange={e => onChange('store_type_id', e.target.value)}
                                        msgErro={errors.store_type_id}
                                    />
                                </div>
                                <div className="sub-item">
                                    <SelectInputForm
                                        label="Categoria de Loja*"
                                        disabled={!store.store_type_id}
                                        data={categories.filter(
                                            ({ store_type }) => Number(store_type.id) === Number(store.store_type_id)
                                        )}
                                        value={store.store_category_id}
                                        onChange={e => onChange('store_category_id', e.target.value)}
                                        msgErro={errors.store_category_id}
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Proprietário</span>
                            </div>

                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <TextInputForm
                                        label="Nome"
                                        value={store.owner_name}
                                        onChange={e => onChange('owner_name', e.target.value)}
                                        msgErro={errors.owner_name}
                                    />
                                </div>
                                <div className="sub-item">
                                    <TextInputMask
                                        label="CPF"
                                        value={store.owner_cpf}
                                        onChange={e => onChange('owner_cpf', e.target.value)}
                                        msgErro={errors.owner_cpf}
                                        mask="999.999.999-99"
                                    />
                                </div>
                            </div>
                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <TextInputForm
                                        label="E-mail"
                                        value={store.owner_email}
                                        onChange={e => onChange('owner_email', e.target.value)}
                                        msgErro={errors.owner_email}
                                    />
                                </div>
                                <div className="sub-item">
                                    <TextInputMask
                                        label="Celular"
                                        value={store.owner_cellphone}
                                        onChange={e => onChange('owner_cellphone', e.target.value)}
                                        msgErro={errors.owner_cellphone}
                                        mask="(99) 99999-9999"
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Configuração da loja</span>
                            </div>

                            <TextInputForm
                                label="Horário de Operação*"
                                value={store.operating_hour}
                                onChange={e => onChange('operating_hour', e.target.value)}
                                msgErro={errors.operating_hour}
                            />
                            <TextInputMask
                                label="Tempo de Operação*"
                                value={store.preparation_time}
                                onChange={e => onChange('preparation_time', e.target.value)}
                                msgErro={errors.preparation_time}
                                mask="99:99:99"
                            />
                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <SelectInputForm
                                        label="Avaliação do Tempo*"
                                        data={preparationArray}
                                        value={store.time_rating}
                                        onChange={e => onChange('time_rating', e.target.value)}
                                        msgErro={errors.time_rating}
                                    />
                                </div>
                                <div className="sub-item">
                                    <SelectInputForm
                                        label="Classificação do Preço*"
                                        data={priceArray}
                                        value={store.price_rating}
                                        onChange={e => onChange('price_rating', e.target.value)}
                                        msgErro={errors.price_rating}
                                    />
                                </div>
                            </div>
                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <TextInputMask
                                        label="Telefone*"
                                        value={store.phone}
                                        onChange={e => onChange('phone', e.target.value)}
                                        msgErro={errors.phone}
                                        mask="(99) 99999-9999"
                                    />
                                </div>
                            </div>

                            <DropzoneInput
                                label="Logo"
                                onChange={file => onChange('logo', file)}
                                preview={store.logo}
                            />
                            <DropzoneInput
                                label="Banner"
                                onChange={file => onChange('banner', file)}
                                preview={store.banner}
                            />

                            {/* <div className="section-title-container">
                                <span className="section-title">Operações</span>
                            </div>

                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <CheckboxInput
                                        id="configs.picker_ops"
                                        label="Operação com picker"
                                        onChange={({ target: { checked } }) =>
                                            onChange('configs', { ...store.configs, picker_ops: checked })
                                        }
                                        checked={store.configs?.picker_ops}
                                    />
                                </div>
                                <div className="sub-item">
                                    <CheckboxInput
                                        id="configs.delivery_ops"
                                        label="Operação de logística"
                                        onChange={({ target: { checked } }) =>
                                            onChange('configs', { ...store.configs, delivery_ops: checked })
                                        }
                                        checked={store.configs?.delivery_ops}
                                    />
                                </div>
                            </div> */}

                            <div className="section-title-container">
                                <span className="section-title">Situação da loja</span>
                            </div>

                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <CheckboxInput
                                        id="working"
                                        label="Loja desativada"
                                        onChange={({ target: { checked } }) => onChange('working', !checked)}
                                        checked={!store.working}
                                    />
                                </div>
                            </div>

                            <div className="store-sub-container">
                                <div className="sub-item">
                                    <SelectInputForm
                                        label="Status*"
                                        data={statusArray}
                                        value={store.status}
                                        onChange={e => onChange('status', e.target.value)}
                                        msgErro={errors.status}
                                    />
                                </div>
                            </div>

                            <div className="section-title-container">
                                <span className="section-title">Loja Satélite</span>
                            </div>

                            <CheckboxInput
                                id="is_satelite"
                                label="Loja Satélite"
                                onChange={({ target: { checked } }) => onChange('is_satelite', checked)}
                                checked={store.is_satelite}
                            />

                            {Number(store.is_satelite) === 1 && (
                                <>
                                    <div className="title" style={{ marginTop: '30px' }}>
                                        Endereço
                                    </div>
                                    <InputPlace
                                        placeholder={stringAddress}
                                        onChange={_setAddress}
                                        onButtonTextClick={() => setShowAllAddress(true)}
                                        showButtonText={!showAllAddress}
                                    />
                                    {showAllAddress && (
                                        <>
                                            <TextInputMask
                                                label="CEP*"
                                                mask="99999-999"
                                                value={store.zipcode}
                                                onChange={({ target: { value } }) => {
                                                    getAddressByCEP(value.replace(/\D/g, ''))
                                                    onChange('zipcode', value.replace(/\D/g, ''))
                                                }}
                                                msgErro={errors.zipcode}
                                            />

                                            <TextInputForm
                                                label="Bairro*"
                                                value={store.neighborhood}
                                                onChange={({ target: { value } }) => onChange('neighborhood', value)}
                                                msgErro={errors.neighborhood}
                                            />

                                            <TextInputForm
                                                label="Logradouro*"
                                                value={store.street}
                                                onChange={({ target: { value } }) => onChange('street', value)}
                                                msgErro={errors.street}
                                            />

                                            <div className="grouped-input-container">
                                                <div style={{ width: '20%' }}>
                                                    <TextInputForm
                                                        label="Número*"
                                                        value={store.number}
                                                        onChange={({ target: { value } }) => onChange('number', value)}
                                                        msgErro={errors.number}
                                                    />
                                                </div>

                                                <div style={{ width: '70%' }}>
                                                    <TextInputForm
                                                        label="Complemento"
                                                        value={store.complement}
                                                        onChange={({ target: { value } }) =>
                                                            onChange('complement', value)
                                                        }
                                                        msgErro={errors.complement}
                                                    />
                                                </div>
                                            </div>

                                            <TextInputForm
                                                label="Referência"
                                                value={store.landmark}
                                                onChange={({ target: { value } }) => onChange('landmark', value)}
                                                msgErro={errors.landmark}
                                            />

                                            {store.street && store.number && (
                                                <ButtonForm
                                                    buttonText="Localizar no mapa"
                                                    onClick={getAddressByGeodecoding}
                                                />
                                            )}

                                            <TextInputForm
                                                label="Raio de coleta da loja satélite*"
                                                type="number"
                                                min="10"
                                                step="50"
                                                onChange={({ target: { value } }) =>
                                                    onChange('configs', { ...store.configs, radius: value })
                                                }
                                                value={store.configs?.radius || 0}
                                            />
                                        </>
                                    )}

                                    <div className="title" style={{ marginTop: '30px' }}>
                                        Tarifas
                                    </div>

                                    <div className="section-title-container">
                                        <span className="section-title">Tarifa para Bicicleta</span>
                                    </div>

                                    <CheckboxInput
                                        id="bike_not_work"
                                        label={'Não opera neste turno*'}
                                        onChange={({ target: { checked } }) => {
                                            onChangeCollectFee('bike', {
                                                ...bike,
                                                not_work: checked,
                                                agent: checked ? null : '0.0',
                                                store: checked ? null : '0.0',
                                            })
                                        }}
                                        checked={bike.not_work}
                                    />

                                    <div className="price-inputs-container">
                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Restaurante*'}
                                                value={bike.store}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('bike', { ...bike, store: floatValue })
                                                }
                                                disabled={bike.not_work}
                                                type="currency"
                                            />
                                        </div>

                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Entregador*'}
                                                value={bike.agent}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('bike', { ...bike, agent: floatValue })
                                                }
                                                disabled={bike.not_work}
                                                type="currency"
                                            />
                                        </div>
                                    </div>

                                    <div className="section-title-container">
                                        <span className="section-title">Tarifa para Motocicleta</span>
                                    </div>

                                    <CheckboxInput
                                        id="motorcycle_not_work"
                                        label={'Não opera neste turno*'}
                                        onChange={({ target: { checked } }) => {
                                            onChangeCollectFee('moto', {
                                                ...moto,
                                                not_work: checked,
                                                agent: checked ? null : '0.0',
                                                store: checked ? null : '0.0',
                                            })
                                        }}
                                        checked={moto.not_work}
                                    />

                                    <div className="price-inputs-container">
                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Restaurante*'}
                                                value={moto.store}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('moto', { ...moto, store: floatValue })
                                                }
                                                disabled={moto.not_work}
                                                type="currency"
                                            />
                                        </div>

                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Entregador*'}
                                                value={moto.agent}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('moto', { ...moto, agent: floatValue })
                                                }
                                                disabled={moto.not_work}
                                                type="currency"
                                            />
                                        </div>
                                    </div>
                                    <div className="section-title-container">
                                        <span className="section-title">Tarifa para Car</span>
                                    </div>

                                    <CheckboxInput
                                        id="car_not_work"
                                        label={'Não opera neste turno*'}
                                        onChange={({ target: { checked } }) => {
                                            onChangeCollectFee('car', {
                                                ...car,
                                                not_work: checked,
                                                agent: checked ? null : '0.0',
                                                store: checked ? null : '0.0',
                                            })
                                        }}
                                        checked={car.not_work}
                                    />

                                    <div className="price-inputs-container">
                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Restaurante*'}
                                                value={car.store}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('car', { ...car, store: floatValue })
                                                }
                                                disabled={car.not_work}
                                                type="currency"
                                            />
                                        </div>

                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Entregador*'}
                                                value={car.agent}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('car', { ...car, agent: floatValue })
                                                }
                                                disabled={car.not_work}
                                                type="currency"
                                            />
                                        </div>
                                    </div>
                                    <div className="section-title-container">
                                        <span className="section-title">Tarifa para Van</span>
                                    </div>

                                    <CheckboxInput
                                        id="van_not_work"
                                        label={'Não opera neste turno*'}
                                        onChange={({ target: { checked } }) => {
                                            onChangeCollectFee('van', {
                                                ...van,
                                                not_work: checked,
                                                agent: checked ? null : '0.0',
                                                store: checked ? null : '0.0',
                                            })
                                        }}
                                        checked={van.not_work}
                                    />

                                    <div className="price-inputs-container">
                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Restaurante*'}
                                                value={van.store}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('van', { ...van, store: floatValue })
                                                }
                                                disabled={van.not_work}
                                                type="currency"
                                            />
                                        </div>

                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Entregador*'}
                                                value={van.agent}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('van', { ...van, agent: floatValue })
                                                }
                                                disabled={van.not_work}
                                                type="currency"
                                            />
                                        </div>
                                    </div>
                                    <div className="section-title-container">
                                        <span className="section-title">Tarifa para Caminhão</span>
                                    </div>

                                    <CheckboxInput
                                        id="truck_not_work"
                                        label={'Não opera neste turno*'}
                                        onChange={({ target: { checked } }) => {
                                            onChangeCollectFee('truck', {
                                                ...truck,
                                                not_work: checked,
                                                agent: checked ? null : '0.0',
                                                store: checked ? null : '0.0',
                                            })
                                        }}
                                        checked={truck.not_work}
                                    />

                                    <div className="price-inputs-container">
                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Restaurante*'}
                                                value={truck.store}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('truck', { ...truck, store: floatValue })
                                                }
                                                disabled={truck.not_work}
                                                type="currency"
                                            />
                                        </div>

                                        <div className="price-input">
                                            <TextInputForm
                                                label={'Entregador*'}
                                                value={truck.agent}
                                                onChange={floatValue =>
                                                    onChangeCollectFee('truck', { ...truck, agent: floatValue })
                                                }
                                                disabled={truck.not_work}
                                                type="currency"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="section-title-container">
                            <span className="section-title">Marketplace</span>
                        </div>

                        <CheckboxInput
                            id="marketplace_enable"
                            label="Loja ativa no marketplace"
                            onChange={({ target: { checked } }) => onChange('marketplace_enable', checked)}
                            checked={store.marketplace_enable}
                        />

                        {!!store.marketplace_enable && (
                            <TextInputMask
                                label="Comissão da loja (porcentagem)"
                                onChange={({ target: { value } }) =>
                                    onChange('configs', { ...store.configs, commission: value })
                                }
                                value={store?.configs?.commission ? formatPercentage(store?.configs.commission) : ''}
                                mask={'99.99'}
                            />
                        )}

                        <ButtonForm
                            buttonText="Salvar"
                            onClick={() => handleSubmit()}
                            style={{ width: '100%', marginBottom: 50 }}
                        />
                    </div>

                    <div className="right-column">
                        <MapboxView fitBounds={getFitBounds()} fitBoundsOptions={{ maxZoom: 18 }}>
                            <DraggableMarker
                                coordinates={store.lat && store.lng && [store.lng, store.lat]}
                                onDragEnd={({ lngLat: { lat, lng } }) => setStore({ ...store, lat, lng })}
                            />

                            {store.is_satelite && (
                                <CircleFill
                                    radius={store.configs?.radius || 0}
                                    center={store.lat && store.lng && [store.lng, store.lat]}
                                />
                            )}
                        </MapboxView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoreCreation
