import { useState, useRef } from 'react'

import { useOrdersInProgress } from 'hooks/orders'

import EmptyMessage from '../../components/empty-message/empty-message'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import OrderModerationTableHeader from '../../components/order-moderation-table-header/order-moderation-table-header'
import OrderModerationTableRow from '../../components/order-moderation-table-row/order-moderation-table-row'
import OrderModerationTopRow from '../../components/order-moderation-top-row/order-moderation-top-row'
import { showErrors } from '../../helpers'
import api from '../../services/api'
import './style.scss'

const OrderModeration = ({ history }) => {
    const {
        orders: { inModeration },
    } = useOrdersInProgress()

    const modalMessageRef = useRef()

    const [loading, setLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState({
        title: '',
        message: '',
        onClose: null,
    })

    const getStatusColor = minutes => {
        if (minutes < 1) {
            return '#7ED321'
        }

        if (minutes >= 1 && minutes <= 4) {
            return '#F5A623'
        }

        return '#D0021B'
    }

    const acceptOrder = async id => {
        try {
            setLoading(true)

            await api.put(`/painel/dm-order/${id}/in_production`)
            setModalMessage({
                title: 'Sucesso',
                message: 'Pedido aceito com sucesso!',
            })

            modalMessageRef.current.openModal()
        } catch (error) {
            setModalMessage({
                title: 'Alerta',
                message: showErrors(error),
            })

            modalMessageRef.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    const refuseOrder = async id => {
        try {
            setLoading(true)

            await api.put(`/painel/dm-order/${id}`, { status: '10A' })

            setModalMessage({
                title: 'Sucesso',
                message: 'Pedido rejeitado com sucesso!',
            })

            modalMessageRef.current.openModal()
        } catch (error) {
            setModalMessage({
                title: 'Alerta',
                message: showErrors(error),
            })

            modalMessageRef.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="page-container">
            <ModalLoading visible={loading || inModeration.loading} />

            <ModalMessage
                ref={modalMessageRef}
                title={modalMessage.title}
                message={modalMessage.message}
                onClose={modalMessage.onClose}
            />

            <OrderModerationTopRow backClick={() => history.goBack()} />

            <div className="content-container order-moderation-page">
                <OrderModerationTableHeader />

                <div className="table-content">
                    {inModeration.items?.length ? (
                        <div>
                            {inModeration.items.map((item, index) => {
                                return (
                                    <OrderModerationTableRow
                                        key={index}
                                        orderID={item.id}
                                        store={item.merchant.name}
                                        channel={item.reference_name}
                                        clientName={item.customer.name}
                                        neighborhood={item.address.neighborhood}
                                        street={item.address.street}
                                        addressNumber={item.address.number}
                                        orderValue={item.total_price}
                                        totalTime={item.total_time.text}
                                        status={getStatusColor(item.total_time.minutes)}
                                        detailClick={() => history.push(`/detalhes-pedido/${item.id}`)}
                                        refuseClick={() => refuseOrder(item.id)}
                                        acceptClick={() => acceptOrder(item.id)}
                                    />
                                )
                            })}
                        </div>
                    ) : (
                        <EmptyMessage icon="thumbs-up">Você não possui pedidos aguardando moderação</EmptyMessage>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OrderModeration
