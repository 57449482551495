import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

const DelivererDocItem = props => {
    const downloadClick = () => {
        const link = document.createElement('a')
        link.href = props.docImage
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div className="deliverer-doc-item">
            <div className="left-side">
                <div className="label">{props.label}</div>
                <div className="doc-image">
                    <img src={props.docImage} alt="" />
                </div>
            </div>
            <div className="buttons">
                <div className="button-item">
                    <FontAwesomeIcon icon="expand-arrows-alt" onClick={props.expandClick} />
                </div>
                <div className="button-item">
                    <FontAwesomeIcon icon="download" onClick={downloadClick} />
                </div>
            </div>
        </div>
    )
}

export default DelivererDocItem
