import { useState, useEffect, useCallback, useMemo } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import CheckboxInput from 'components/checkbox-input'

import { useGeocodingSearch } from 'hooks'

import ButtonForm from '../../components/button-form'
import MapboxView, { DraggableMarker } from '../../components/mapbox-view'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import SelectInputForm from '../../components/select-input-form'
import TextInputForm from '../../components/text-input-form'
import TextInputMask from '../../components/text-input-mask'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { objectIsEmpty, showErrors } from '../../helpers'
import api from '../../services/api'

import './style.scss'
import DropzoneInput from 'components/dropzone-input/dropzone-input'

import { useUI } from 'contexts'

import { ColorSelect } from 'components/color-select/color-select'
import MensagemErro from 'components/mensagem-erro'
import { InputPlace } from 'components/input-place/input-place'

// const colors = [
//     '#FFC312',
//     '#F79F1F',
//     '#EE5A24',
//     '#EA2027',
//     '#C4E538',
//     '#A3CB38',
//     '#009432',
//     '#006266',
//     '#12CBC4',
//     '#1289A7',
//     '#0652DD',
//     '#1B1464',
//     '#FDA7DF',
//     '#D980FA',
//     '#9980FA',
//     '#5758BB',
//     '#ED4C67',
//     '#B53471',
//     '#833471',
//     '#6F1E51',
//     '#6E4480',
//     '#7F8C8D',
//     '#95A5A6',
//     '#2C3E50',
// ]

const MallCreation = ({ history, match: { params } }) => {
    const { setErrorModal, setSuccessModal } = useUI()
    const [loading, toggleLoading] = useState(false)
    const [showAllAddress, setShowAllAddress] = useState(false)
    const [modalMessageData, setModalMessageData] = useState({
        title: '',
        message: '',
        textButton: null,
        onClose: () => {},
    })
    const {
        initialValues,
        isSubmitting,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        setValues,
    } = useFormik({
        initialValues: {
            name: '',
            slug: '',
            zipcode: '',
            uf: '',
            city: '',
            neighborhood: '',
            street: '',
            number: '',
            complement: '',
            landmark: '',
            lat: '',
            lng: '',
            location_changed: false,
            status: '',
            type: 'public',
            configs: {
                marketplace_enable: false,
                picker_ops: false,
                color: '',
                operation_type: '',
            },
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required('Por favor, preencha com o Nome do Mall'),
            slug: Yup.string().trim().required('Por favor, preencha com o slug do Mall'),
            configs: Yup.object().shape({
                color: Yup.string().trim().required('Por favor, preencha com a cor do Mall'),
                operation_type: Yup.string().trim().required('Por favor, preencha com o tipo de operação do Mall'),
            }),
            zipcode: Yup.string().trim().min(8, 'CEP deve ter 8 números').required('Por favor, insira o CEP.'),
            uf: Yup.string().trim().required('Por favor, insira o Estado'),
            city: Yup.string().trim().required('Por favor, insira a Cidade'),
            neighborhood: Yup.string().trim().required('Por favor, insira o Bairro'),
            street: Yup.string().trim().required('Por favor, insira o Logradouro'),
            number: Yup.string().trim().required('Por favor, insira o Número'),
            location_changed: Yup.bool().oneOf([true], ''),
            status: Yup.string().trim().required('Por favor, selecione o Status'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)
                if (values?.id) {
                    await api.put(`/painel/mall/${values.id}`, values)
                } else {
                    await api.post('/painel/mall', values)
                }

                setModalMessageData({
                    title: 'Sucesso',
                    message: `Mall ${values?.id ? 'atualizado' : 'criado'} com sucesso!`,
                    textButton: 'Ok',
                    onClose: () => history.push('/painel-malls'),
                })
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                })
            } finally {
                setSubmitting(false)
            }
        },
    })

    const stringAddress = useMemo(() => {
        if (values.street && values.number && values.neighborhood && values.zipcode) {
            return `${values.street}, ${values.number} - ${values.neighborhood} (${values.zipcode})`
        }
    }, [values])

    const getMall = useCallback(
        async mall_id => {
            try {
                toggleLoading(true)

                const {
                    data: {
                        id,
                        name,
                        slug,
                        type,
                        status,
                        logo,
                        banner,
                        address: { zipcode, city, neighborhood, street, number, complement, landmark, lat, lng, uf },
                        configs,
                    },
                } = await api.get(`/painel/mall/${mall_id}`)

                setValues({
                    ...initialValues,
                    id: id,
                    name: name,
                    slug: slug,
                    zipcode: zipcode,
                    city: city.name,
                    neighborhood: neighborhood.name,
                    uf,
                    street,
                    number,
                    complement,
                    landmark,
                    lat,
                    lng,
                    location_changed: true,
                    status,
                    type,
                    logo,
                    banner,
                    configs: {
                        color: configs?.color,
                        picker_ops: configs?.picker_ops || false,
                        marketplace_enable: configs?.marketplace_enable || false,
                        operation_type: configs?.operation_type,
                    },
                })
                setShowAllAddress(true)
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                    onClose: () => history.push('/painel-malls'),
                })
            } finally {
                toggleLoading(false)
            }
        },
        [history, initialValues, setValues]
    )

    const getAddressByCEP = async zipcode => {
        try {
            if (zipcode.length === 8) {
                setFieldTouched('zipcode', true)
                toggleLoading(true)

                const { cep, state, city, street, neighborhood } = await cep(zipcode)

                setValues({
                    ...values,
                    uf: state,
                    city,
                    neighborhood,
                    street,
                    zipcode: cep,
                })
            }
        } catch (error) {
            console.log({ error, message: error.message })
            setFieldError('zipcode', error.message)
        } finally {
            toggleLoading(false)
        }
    }

    const _uploadFile = useCallback(
        async (field, file) => {
            toggleLoading(true)
            try {
                const url = `/painel/mall/${values.id}/${field}`
                const body = new FormData()
                body.append(field, file)

                await api.post(url, body)

                setFieldValue(field, file)

                setSuccessModal({
                    title: 'Sucesso',
                    subtitle: `Imagem atualizado com sucesso`,
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: `Não foi possível atualizar ${field}`,
                })
            } finally {
                toggleLoading(false)
            }
        },
        [values.id]
    )

    const _handleChangeFile = useCallback(
        field => async file => {
            await _uploadFile(field, file)
        },
        [_uploadFile]
    )

    const _handleColorSelect = useCallback(color => {
        setFieldValue('configs.color', color)
    }, [])

    const { getLatLngByAddress } = useGeocodingSearch({
        onSuccess: ({ lat, lng }) => {
            setValues({ ...values, lat, lng, location_changed: true })
        },
    })

    const _setAddress = useCallback(
        address => {
            if (address) {
                const lat = Number(address.location.lat || '')
                const lng = Number(address.location.lng || '')
                setFieldValue('street', address.street || '')
                setFieldValue('uf', address.state || '')
                setFieldValue('city', address.city || '')
                setFieldValue('number', address.number || '')
                setFieldValue('neighborhood', address.neighborhood || '')
                setFieldValue('zipcode', (address.zipcode || '').replace(/\D/g, ''))
                setFieldValue('lat', lat || null)
                setFieldValue('lng', lng || null)

                setShowAllAddress(true)
            }
        },
        [setFieldValue]
    )

    useEffect(() => {
        if (values.id) return

        if (params.id) {
            getMall(params.id)
        }
    }, [getMall, values.id, params.id])

    const mallLocation = values.lat && values.lng ? [values.lng, values.lat] : undefined
    const fortalezaCityLocation = [-38.5027509, -3.7438823]

    return (
        <div className="page-container">
            <ModalLoading visible={isSubmitting || loading} />

            <ModalMessage
                isActive={modalMessageData.message}
                title={modalMessageData.title}
                message={modalMessageData.message}
                textButton={modalMessageData.textButton}
                onClose={() => {
                    setModalMessageData({})
                    if (modalMessageData.onClose) {
                        modalMessageData.onClose()
                    }
                }}
            />

            <TopRowDefault
                title={params.id ? 'Editar mall' : 'Criar novo mall'}
                onBackButtonClick={() => history.push('/painel-malls')}
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="mall-creation-left-column">
                        <div className="mall-creation-form-container">
                            <TextInputForm
                                label="Nome do mall*"
                                value={values.name}
                                onChange={({ target: { value } }) => setFieldValue('name', value)}
                                msgErro={touched.name && errors.name}
                            />
                            <TextInputForm
                                label="Slug do Mall*"
                                value={values.slug}
                                onChange={({ target: { value } }) => setFieldValue('slug', value)}
                                msgErro={touched.slug && errors.slug}
                            />
                            <div className="mall-creation-input-row">
                                {/* <TextInputForm
                                    label={`Cor do Mall - ${values.configs.color}`}
                                    type="color"
                                    style={{ paddingLeft: 5, paddingRight: 5 }} 
                                    value={values.configs.color}
                                    onChange={({ target: { value } }) => setFieldValue('configs.color', value)}
                                    msgErro={touched.color && errors.color}
                                /> */}
                            </div>
                            <div className="colors-container">
                                <ColorSelect
                                    label="Cor do Mall"
                                    defaultColor={values.configs.color}
                                    handleColorSelected={_handleColorSelect}
                                />
                                {touched?.configs?.color && errors?.configs?.color && (
                                    <MensagemErro msgErro={touched.configs.color && errors.configs.color} />
                                )}
                            </div>

                            <div className="title" style={{ marginTop: '30px' }}>
                                Endereço
                            </div>
                            <InputPlace
                                placeholder={stringAddress}
                                onChange={_setAddress}
                                onButtonTextClick={() => setShowAllAddress(true)}
                                showButtonText={!showAllAddress}
                            />
                            {showAllAddress && (
                                <>
                                    <TextInputMask
                                        label="CEP*"
                                        mask="99999-999"
                                        value={values.zipcode}
                                        onChange={({ target: { value } }) => {
                                            getAddressByCEP(value.replace(/\D/g, ''))
                                            setFieldValue('zipcode', value.replace(/\D/g, ''))
                                        }}
                                        msgErro={touched.zipcode && errors.zipcode}
                                    />
                                    <TextInputForm
                                        label="Estado*"
                                        max={2}
                                        value={values.uf}
                                        onChange={({ target: { value } }) => setFieldValue('uf', value)}
                                        msgErro={touched.uf && errors.uf}
                                    />
                                    <TextInputForm
                                        label="Cidade*"
                                        value={values.city}
                                        onChange={({ target: { value } }) => setFieldValue('city', value)}
                                        msgErro={touched.city && errors.city}
                                    />
                                    <TextInputForm
                                        label="Bairro*"
                                        value={values.neighborhood}
                                        onChange={({ target: { value } }) => setFieldValue('neighborhood', value)}
                                        msgErro={touched.neighborhood && errors.neighborhood}
                                    />
                                    <TextInputForm
                                        label="Logradouro*"
                                        value={values.street}
                                        onChange={({ target: { value } }) => setFieldValue('street', value)}
                                        msgErro={touched.street && errors.street}
                                    />
                                    <div className="grouped-input-container">
                                        <div style={{ width: '20%' }}>
                                            <TextInputForm
                                                label="Número*"
                                                value={values.number}
                                                onChange={({ target: { value } }) => setFieldValue('number', value)}
                                                msgErro={touched.number && errors.number}
                                            />
                                        </div>

                                        <div style={{ width: '70%' }}>
                                            <TextInputForm
                                                label="Complemento"
                                                value={values.complement}
                                                onChange={({ target: { value } }) => setFieldValue('complement', value)}
                                                msgErro={touched.complement && errors.complement}
                                            />
                                        </div>
                                    </div>
                                    <TextInputForm
                                        label="Referência"
                                        value={values.landmark}
                                        onChange={({ target: { value } }) => setFieldValue('landmark', value)}
                                        msgErro={touched.landmark && errors.landmark}
                                    />
                                </>
                            )}
                            {values.street && values.number && (
                                <ButtonForm
                                    buttonText="Localizar no mapa"
                                    onClick={() => {
                                        const { uf, city, neighborhood, street, number, zipcode } = values
                                        console.log({ values })

                                        getLatLngByAddress({
                                            city: city,
                                            state: uf,
                                            zipcode: zipcode,
                                            neighborhood: neighborhood,
                                            street: street,
                                            number: number,
                                        })
                                    }}
                                />
                            )}
                            {values?.id && (
                                <div className="upload-image-container">
                                    <DropzoneInput
                                        label="Logo"
                                        onChange={_handleChangeFile('logo')}
                                        preview={values.logo}
                                    />
                                    <DropzoneInput
                                        label="Banner"
                                        onChange={_handleChangeFile('banner')}
                                        preview={values.banner}
                                    />
                                </div>
                            )}
                            <SelectInputForm
                                label="Status*"
                                data={[
                                    { name: 'Ativo', value: '1' },
                                    { name: 'Inativo', value: '0' },
                                ]}
                                value={values.status}
                                onChange={({ target: { value } }) => setFieldValue('status', value)}
                                msgErro={touched.status && errors.status}
                            />
                            <SelectInputForm
                                label="Tipo de Operação"
                                data={[
                                    { name: 'Full Service', value: 'full_service' },
                                    { name: 'SaaS', value: 'saas' },
                                ]}
                                value={values.configs?.operation_type}
                                onChange={({ target: { value } }) => setFieldValue('configs.operation_type', value)}
                                msgErro={touched.operation_type && errors.operation_type}
                            />
                            <div className="title" style={{ marginTop: '30px', marginBottom: '8px' }}>
                                Configurações
                            </div>
                            <CheckboxInput
                                id="type"
                                label="Bolsão Privado"
                                onChange={({ target: { checked } }) =>
                                    setFieldValue('type', checked ? 'private' : 'public')
                                }
                                checked={values.type === 'private'}
                            />
                            <CheckboxInput
                                id="configs.picker_ops"
                                label="Operação com picker"
                                onChange={({ target: { checked } }) => setFieldValue('configs.picker_ops', checked)}
                                checked={values.configs.picker_ops}
                            />
                            <CheckboxInput
                                id="configs.marketplace_enable"
                                label="Habilitar Marketplace"
                                onChange={({ target: { checked } }) =>
                                    setFieldValue('configs.marketplace_enable', checked)
                                }
                                checked={values.configs.marketplace_enable}
                            />
                        </div>

                        <ButtonForm
                            buttonText="Salvar"
                            type="submit"
                            onClick={() => {
                                if (!objectIsEmpty(errors) || !values.location_changed) {
                                    setModalMessageData({
                                        title: 'Erro',
                                        message: !values.location_changed
                                            ? 'Selecione a localização no mapa'
                                            : 'Preencha a informações corretamente',
                                        textButton: 'Revisar alterações',
                                    })
                                }

                                handleSubmit()
                            }}
                            style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
                        />
                    </div>

                    <div className="right-column">
                        <MapboxView center={mallLocation || fortalezaCityLocation} zoom={mallLocation && 16}>
                            <DraggableMarker
                                coordinates={mallLocation}
                                onDragEnd={({ lngLat: { lat, lng } }) => setValues({ ...values, lat, lng })}
                            />
                        </MapboxView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MallCreation
