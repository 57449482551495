import { useState, useMemo, useEffect } from 'react'
import { withRouter } from 'react-router'

import { useAuth } from 'hooks'

import { getSortedItems, getStatusColorProduction, getStatusColorReadyDeliver } from '../../helpers'
import EmptyMessage from '../empty-message/empty-message'
import OperationRow from '../operation-row/operation-row'
import OrderRow from '../order-row/order-row'

import './style.scss'

const OperationListingProduction = ({ loading, ordersProduction, ordersReadyDeliver, history, clicked }) => {
    const { basePath, mall } = useAuth()

    const [inProductionSorting, setInProductionSorting] = useState()
    const [toCollectSorting, setToCollectSorting] = useState()

    const [searchOrderProduction, setSearchOrderProduction] = useState(null)
    const [searchOrdersReady, setSearchOrdersReady] = useState(null)

    const ordersInProductions = useMemo(() => {
        if (!ordersProduction?.items) {
            return []
        }
        if (searchOrderProduction) {
            let search = searchOrderProduction.toLocaleLowerCase()
            return ordersProduction.items.filter(
                item =>
                    (item.customer?.name || '').toLocaleLowerCase().includes(search) ||
                    (item.id || '').toString().includes(search) ||
                    (item.reference_id || '').toString().toLocaleLowerCase().includes(search)
            )
        }
        return ordersProduction.items
    }, [ordersProduction.items, searchOrderProduction])

    const ordersReady = useMemo(() => {
        if (!ordersReadyDeliver?.items) {
            return []
        }
        if (searchOrdersReady) {
            let search = searchOrdersReady.toLocaleLowerCase()
            return ordersReadyDeliver.items.filter(
                item =>
                    (item.customer?.name || '').toLocaleLowerCase().includes(search) ||
                    (item.id || '').toString().includes(search) ||
                    (item.reference_id || '').toString().toLocaleLowerCase().includes(search)
            )
        }
        return ordersReadyDeliver.items
    }, [ordersReadyDeliver.items, searchOrdersReady])

    useEffect(() => {
        if (inProductionSorting) {
            localStorage.setItem('inProductionSorting', JSON.stringify(inProductionSorting))
        }
        if (toCollectSorting) {
            localStorage.setItem('toCollectSorting', JSON.stringify(toCollectSorting))
        }
    }, [inProductionSorting, toCollectSorting])

    useEffect(() => {
        try {
            const inProductionSorting = localStorage.getItem('inProductionSorting')
            const toCollectSorting = localStorage.getItem('toCollectSorting')

            if (inProductionSorting) {
                setInProductionSorting(JSON.parse(inProductionSorting))
            }
            if (toCollectSorting) {
                setToCollectSorting(JSON.parse(toCollectSorting))
            }
        } catch (err) {
            console.log('err')
        }
    }, [])

    return (
        <div className="operation-listing-production-container">
            <OperationRow
                iconName="concierge-bell"
                loading={loading}
                status={3}
                columnTitle={`${(ordersProduction?.items || []).length} em produção`}
                setSorting={setInProductionSorting}
                sorting={inProductionSorting}
                onSearch={e => setSearchOrderProduction(e)}
            >
                {getSortedItems(ordersInProductions, inProductionSorting).map(item => {
                    return (
                        <OrderRow
                            key={item.id}
                            orderId={item.id}
                            orderNumber={item.reference_id}
                            orderValue={item.total_price}
                            salesChannel={item.reference_name}
                            paymentName={item.payment?.name}
                            restaurantName={item.merchant.name}
                            clientName={item.customer.name}
                            neighborhood={item.address.neighborhood}
                            street={item.address.street}
                            number={item.address.number}
                            distance={item.distance}
                            totalTime={item.total_time.text}
                            status={item.status}
                            collectName={item.delivery_origin?.name}
                            observation={!!item.observation}
                            statusColor={getStatusColorProduction(item.total_time.minutes, mall.configs)}
                            routeClick={() => history.push(`${basePath}/rotas/criar-rota`, { initial: item })}
                            detailClick={() => history.push(`${basePath}/detalhes-pedido/${item.id}`)}
                            clicked={item.id === clicked}
                        />
                    )
                })}

                {(ordersProduction?.items || []).length === 0 && !loading && (
                    <div className="empty-message-container">
                        <EmptyMessage icon="concierge-bell">Não há produtos em produção no momento</EmptyMessage>
                    </div>
                )}
            </OperationRow>

            <OperationRow
                iconName="cube"
                loading={loading}
                status={4}
                columnTitle={`${(ordersReadyDeliver?.items || []).length} pronto p/ coleta`}
                setSorting={setToCollectSorting}
                sorting={toCollectSorting}
                style={{ zIndex: 8 }}
                onSearch={e => setSearchOrdersReady(e)}
            >
                {getSortedItems(ordersReady, toCollectSorting).map(item => {
                    return (
                        <OrderRow
                            key={item.id}
                            orderId={item.id}
                            orderNumber={item.reference_id}
                            orderValue={item.total_price}
                            salesChannel={item.reference_name}
                            paymentName={item.payment?.name}
                            restaurantName={item.merchant.name}
                            clientName={item.customer.name}
                            neighborhood={item.address.neighborhood}
                            street={item.address.street}
                            number={item.address.number}
                            distance={item.distance}
                            collectName={item.delivery_origin?.name}
                            observation={!!item.observation}
                            totalTime={item.total_time.text}
                            statusColor={getStatusColorReadyDeliver(item.total_time.minutes, mall.configs)}
                            status={item.status}
                            routeClick={() => history.push(`${basePath}/rotas/criar-rota`, { initial: item })}
                            detailClick={() => history.push(`${basePath}/detalhes-pedido/${item.id}`)}
                            isReadyToDeliver
                            timeReady={item.time_ready.text}
                            clicked={item.id === clicked}
                        />
                    )
                })}
                {(ordersReadyDeliver?.items || []).length === 0 && !loading && (
                    <div className="empty-message-container">
                        <EmptyMessage icon="cube">Não há produtos prontos para entrega no momento</EmptyMessage>
                    </div>
                )}
            </OperationRow>
        </div>
    )
}

export default withRouter(OperationListingProduction)
