import { useCallback, useEffect, useMemo, useState, Fragment } from 'react'
import { useLocation } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'
import { useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import TextInputForm from 'components/text-input-form'
import { Text } from 'components/text/text'

import { useUI } from 'contexts'
import { formatCurrency, getFormInputError } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import { PagedList } from 'types'
import { ProductFormFields, ProductPart, SideDish, SideDishCategory } from 'types/marketplace'
import { NestedKeyOf } from 'types/nested-key-of'

import { DeleteButton, DeleteIcon } from '../product-details-content/product-details-content.styles'

import {
    ContentTitle,
    EditGroupModalBackground,
    EditGroupModalBox,
    EditGroupModalContainer,
    EditGroupModalContent,
    CloseButton,
    CloseContainer,
    EditGroupHeader,
    ButtonRow,
    TextButton,
    ConfirmButton,
    InputRow,
    ItemContainer,
    GroupTitle,
    CheckboxRow,
    SingleInputItem,
    DishesListing,
    DishItem,
} from './edit-group-modal.styles'

interface Props {
    isActive: boolean
    onAddProductPart(productPart: ProductPart): void
    onEditProductPart(productPart: ProductPart, productPartIndex: number | undefined): void
    onDeleteProductPart(productPartId: number, productPartIndex: number | undefined): void
    handleRequestError(error: AxiosError): void
    closeClick(): void
}

const EditGroupModal: React.FC<Props> = ({
    isActive,
    onAddProductPart,
    onEditProductPart,
    onDeleteProductPart,
    handleRequestError,
    closeClick,
}) => {
    const { setLoading, setSnackbar } = useUI()
    const { store } = useAuth()

    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [sideDishCategoryId, setSideDishCategoryId] = useState<number>()

    const { pathname } = useLocation()

    const productFormContext = useFormikContext<ProductFormFields>()
    const productParts = productFormContext.values.parts

    function onChangeSideDish(sideDish: SideDish) {
        if (values.side_dishes.some(({ id }) => id === sideDish.id)) {
            setFieldValue(
                'side_dishes',
                values.side_dishes.filter(({ id }) => id !== sideDish.id)
            )
        } else {
            setFieldValue('side_dishes', [...values.side_dishes, sideDish])
        }
    }

    const { errors, handleSubmit, initialValues, getFieldProps, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                id: undefined as unknown as number,
                name: '',
                qty_min: '0',
                qty_max: '',
                is_required: 0 as 0 | 1,
                side_dishes: [] as SideDish[],
                status: 1 as 0 | 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().required('Nome da parte é obrigatório'),
                qty_min: Yup.number()
                    .nullable()
                    .max(Yup.ref('qty_max'), 'Quantidade mínima deve ser menor ou igual a quantidade máxima'),
                qty_max: Yup.number().required('Quantidade máxima é obrigatória'),
                side_dishes: Yup.array().min(1, 'Selecione pelo menos um acompanhamento'),
            }),
            onSubmit: async values => {
                try {
                    setLoading(true)

                    const productPart = {
                        ...values,
                        order: productParts.length + 1,
                        qty_min: Number(values.qty_min),
                        qty_max: Number(values.qty_max),
                        side_dishes: values.side_dishes.map(s => {
                            if (s.fraction && s.price_fraction) {
                                if (Number(s.fraction) * s.price === s.price_fraction) {
                                    s.price_fraction = null
                                }
                            }

                            return s
                        }),
                    }

                    if (productPartIndex !== undefined) {
                        onEditProductPart(productPart, productPartIndex)
                    } else {
                        onAddProductPart(productPart)
                    }

                    //setSnackbar({
                    //    message: `Grupo de acompanhamentos ${
                    //        productPartIndex !== undefined ? 'editado' : 'adicionado'
                    //    }`,
                    //})
                    //closeClick()
                } catch (error) {
                    console.log('handleSubmit', { error })

                    handleRequestError(error)
                } finally {
                    setLoading(false)
                }
            },
        })

    const _onChangeSideDish = useCallback(
        ({ id, price }: SideDish, key: NestedKeyOf<SideDish>) => {
            return (data: any) => {
                let value: any

                if (key === 'price_fraction') {
                    value = data
                } else {
                    value = data?.target?.value
                }

                const index = values.side_dishes.findIndex(x => x.id === id)
                if (index !== -1) {
                    setFieldValue(`side_dishes.${index}.${key}`, value)

                    if (key === 'fraction') {
                        setFieldValue(`side_dishes.${index}.price_fraction`, (Number(value) || 1) * price)
                    }
                }
            }
        },
        [values, setFieldValue]
    )

    const _getPropsInput = useCallback(
        (sideDish: SideDish, type: 'price_fraction' | 'fraction') => {
            const index = values.side_dishes.findIndex(s => s.id === sideDish.id)
            const side = values.side_dishes[index]
            let value = 0

            if (type === 'fraction') {
                value = side?.fraction ?? sideDish.fraction
            }

            if (type === 'price_fraction') {
                value = side?.price_fraction ?? (side?.fraction || 1) * (side?.price || 0)
            }

            if (!side) {
                return {
                    value,
                    disabled: !side,
                }
            }

            return {
                value: value,
                onChange: _onChangeSideDish(side, type),
                onBlur: ev => {
                    if (type === 'fraction') {
                        const value = ev.target.value
                        if (!value || value === '0') {
                            setFieldValue(`side_dishes.${index}.fraction`, 1)
                        } else {
                            setFieldValue(`side_dishes.${index}.fraction`, Number(value))
                        }
                    }
                },
            }
        },
        [values.side_dishes, _onChangeSideDish, setFieldValue]
    )

    const _resetPriceFraction = useCallback(
        (sideDish: SideDish) => {
            return () => {
                const index = values.side_dishes.findIndex(x => x.id === sideDish.id)
                if (index !== -1) {
                    setFieldValue(`side_dishes.${index}.price_fraction`, sideDish.price)
                    setFieldValue(`side_dishes.${index}.fraction`, sideDish.fraction || 1)
                }
            }
        },
        [values, setFieldValue]
    )

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const productPartIndex = useMemo(() => {
        resetForm()
        const editingMatch = pathname.match(new RegExp(/editar-grupo\/\d+/g))

        if (!editingMatch) return undefined

        const productPartId = Number(editingMatch[0].split('/').pop())
        const productPartIndex = productParts.findIndex(part => part.id === productPartId)

        const productPart = productParts.find((_part, index) => index === productPartIndex)

        if (productPart) {
            setValues({
                ...initialValues,
                ...productPart,
                name: productPart.name,
                qty_min: productPart.qty_min.toString(),
                qty_max: productPart.qty_max.toString(),
                is_required: productPart.is_required,
                side_dishes: productPart.side_dishes,
            })
        }

        return productPartIndex
    }, [initialValues, pathname, productParts, resetForm, setValues])

    const { data: sideDishes } = useDataFetching<PagedList<SideDish>>('/painel/side-dishes', {
        params: { store_id: store.id, per_page: -1 },
    })

    const { data: sideDishCategories } = useDataFetching<PagedList<SideDishCategory>>('/painel/side-dish-categories', {
        params: { store_id: store.id, order_by: 'name', per_page: -1 },
    })

    return (
        <EditGroupModalContainer isDisplayed={isDisplayed}>
            <EditGroupModalBox isEffectActive={isEffectActive}>
                <EditGroupHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>
                        {productPartIndex !== undefined
                            ? `Editar grupo de acompanhamento`
                            : 'Criar grupo de acompanhamento'}{' '}
                    </ContentTitle>
                </EditGroupHeader>
                <EditGroupModalContent>
                    <InputRow>
                        <ItemContainer>
                            <InputItem
                                labelText="Nome do grupo"
                                inputProps={getFieldProps('name')}
                                errorMessage={getFormInputError('name', errors, touched)}
                            />
                        </ItemContainer>
                    </InputRow>
                    <GroupTitle>Tipo de Acompanhamento</GroupTitle>
                    <CheckboxRow>
                        <Checkbox
                            checkboxTitle="Obrigatório"
                            inputProps={{
                                onChange: () => {
                                    setFieldValue('qty_min', '1')
                                    setFieldValue('is_required', 1)
                                },
                                value: values.is_required,
                            }}
                        />
                        <Checkbox
                            checkboxTitle="Opcional"
                            inputProps={{
                                onChange: () => {
                                    setFieldValue('qty_min', '0')
                                    setFieldValue('is_required', 0)
                                },
                                value: isNaN(values.is_required) ? undefined : Number(!values.is_required),
                            }}
                        />
                    </CheckboxRow>
                    <InputRow halfSize>
                        {values.is_required ? (
                            <ItemContainer>
                                <InputItem
                                    labelText="Quantidade Mínima"
                                    type="number"
                                    inputProps={{ ...getFieldProps('qty_min'), min: 1 }}
                                    errorMessage={getFormInputError('qty_min', errors, touched)}
                                />
                            </ItemContainer>
                        ) : null}
                        <ItemContainer>
                            <InputItem
                                labelText="Quantidade Máxima"
                                type="number"
                                inputProps={{ ...getFieldProps('qty_max'), min: 1 }}
                                errorMessage={getFormInputError('qty_max', errors, touched)}
                            />
                        </ItemContainer>
                    </InputRow>
                    <SingleInputItem>
                        <InputItem
                            labelText="Opções de acompanhamento"
                            type="select"
                            options={sideDishCategories?.items?.map(({ name, id }) => ({
                                label: name,
                                value: id,
                            }))}
                            inputProps={{
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) =>
                                    setSideDishCategoryId(Number(value)),
                            }}
                        />
                    </SingleInputItem>
                    <DishesListing>
                        {sideDishes?.items
                            .filter(sideDish =>
                                sideDishCategoryId ? sideDish?.category?.id === sideDishCategoryId : true
                            )
                            .map((sideDish, index) => (
                                <Fragment key={index}>
                                    {index === 0 && (
                                        <DishItem>
                                            <Text text="Produtos" className="column-product" />
                                            <div className="container-inputs">
                                                <Text className="column-input" text="Fração" />
                                                <Text className="column-input" text="Preço" />
                                            </div>
                                            <div className="fake-icon" />
                                        </DishItem>
                                    )}
                                    <DishItem>
                                        <Checkbox
                                            className="column-product"
                                            isSquared
                                            checkboxTitle={sideDish.name}
                                            checkboxSubtitle={`Preço original: ${formatCurrency(
                                                sideDish.price
                                            )} | SKU: ${sideDish.code_pdv}`}
                                            inputProps={{
                                                onChange: () => onChangeSideDish(sideDish),
                                                value: Number(values.side_dishes.some(({ id }) => id === sideDish.id)),
                                            }}
                                        />
                                        <div className="container-inputs">
                                            <TextInputForm
                                                className="column-input"
                                                type="number"
                                                {..._getPropsInput(sideDish, 'fraction')}
                                            />
                                            <TextInputForm
                                                className="column-input"
                                                type="currency"
                                                {..._getPropsInput(sideDish, 'price_fraction')}
                                            />
                                            <FontAwesomeIcon
                                                className="icon"
                                                icon="sync"
                                                onClick={_resetPriceFraction(sideDish)}
                                            />
                                        </div>
                                    </DishItem>
                                </Fragment>
                            ))}
                    </DishesListing>
                    {productPartIndex !== undefined && values.id && (
                        <DeleteButton
                            onClick={() => {
                                onDeleteProductPart(values.id, productPartIndex)
                                closeClick()
                            }}
                        >
                            <DeleteIcon /> Excluir grupo de acompanhamento
                        </DeleteButton>
                    )}
                </EditGroupModalContent>
                <ButtonRow>
                    <TextButton onClick={closeClick}>Cancelar</TextButton>
                    <ConfirmButton onClick={() => handleSubmit()}>Salvar Alterações</ConfirmButton>
                </ButtonRow>
            </EditGroupModalBox>
            <EditGroupModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </EditGroupModalContainer>
    )
}

export default EditGroupModal
