import { useCallback, useEffect, useState } from 'react'

import { baseURL } from 'services/api'
import { ApiPhoto } from 'types/marketplace'

export function useImagePreview(preview: ApiPhoto | File | string): {
    image: string
    previewError: string
} {
    const [previewError, setPreviewError] = useState('')
    const [image, setImage] = useState('')

    const getPreview = useCallback(() => {
        try {
            if (preview instanceof File) {
                setImage(window.URL.createObjectURL(preview))
            } else if (typeof preview === 'string') {
                setImage(preview)
            } else if (typeof (preview as ApiPhoto)?.path === 'string') {
                setImage(`${baseURL}/${(preview as ApiPhoto)?.path}`)
            }
        } catch (error) {
            setPreviewError('Não foi possível carregar a imagem')
        }
    }, [preview])

    useEffect(() => {
        getPreview()

        return () => {
            if (preview instanceof File) window.URL.revokeObjectURL(preview.name)
            setImage('')
        }
    }, [preview, getPreview])

    return { image, previewError }
}
