import ReactSlider from 'react-slider'

import styled from 'styled-components'

const Container = styled.div`
    width: fit-content;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 200px;
    z-index: 1;
    user-select: none;
`

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`

const Thumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 2px;
    font-size: 12px;
    color: #fff;
    border-radius: 50%;
    border: solid #e5e5e5 1px;
    cursor: grab;
`

const Track = styled.div<{ index?: number }>`
    top: 0;
    bottom: 0;
    height: 10px;
    background: ${({ index, theme }) => (index === 1 ? '#fff' : theme.colors.primary)};
    border-radius: 50px;
    margin-top: 10px;
`

export { Container, StyledSlider, Thumb, Track }
