import { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'

import ButtonForm from 'components/button-form'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'
import colors from 'themes/colors'

import './style.scss'

const PosScreenCard: React.FC<{ label: string; value: number; onClick?(): void }> = ({ label, value, onClick }) => {
    return (
        <div className="pos-screen" onClick={onClick} style={{ cursor: 'pointer' }}>
            <div className="check-container">
                <span style={{ color: value > 0 ? colors.dark_gray_color : colors.light_gray_color }}>
                    {value !== 0 ? `${value}ª tela` : 'Tela desativada'}
                </span>

                {value > 0 ? (
                    <FontAwesomeIcon icon="check-circle" color={colors.green} />
                ) : (
                    <FontAwesomeIcon icon="circle" color="white" />
                )}
            </div>
            <div
                className="pos-screen-text"
                style={{ color: value > 0 ? colors.dark_gray_color : colors.light_gray_color }}
            >
                <span>{label}</span>
            </div>
        </div>
    )
}

const PosViewControl: React.FC<RouteComponentProps> = ({ history }) => {
    const modalMessage = useRef<ModalMessageRef>(null)

    const [loading, setLoading] = useState(false)
    const [establishments, setEstablishments] = useState({ establishment_list: [] })

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const { handleSubmit, initialValues, isSubmitting, setFieldValue, setValues, values } = useFormik({
        initialValues: {
            establishment_id: '',
            adjustment_view: 0,
            validations: 0,
        },
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                setSubmitting(true)

                const { data } = await siclosPagApi.put('/pos-view/update-pos-view-control', {
                    ...values,
                })

                if (data?.error) {
                    throw data.msg
                }

                resetForm()

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Validações configuradas com sucesso!',
                })

                modalMessage.current?.openModal()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    isActive: false,
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessage.current?.openModal()
            } finally {
                setSubmitting(false)
            }
        },
    })

    const getEstablishments = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get('/establishment/get-establishment-list')

            setEstablishments(data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls/gestao-stone'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getEstablishments()
    }, [getEstablishments])

    const getPosViewControl = useCallback(
        async (establishment_id: string) => {
            try {
                setLoading(true)

                const { data } = await siclosPagApi.get(`/pos-view/get-pos-view-control/${establishment_id}`)

                setValues({ ...initialValues, establishment_id, ...data.pos_view_control })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/painel-malls'),
                })

                modalMessage.current?.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history, initialValues, setValues]
    )

    useEffect(() => {
        values.establishment_id && getPosViewControl(values.establishment_id)
    }, [getPosViewControl, values.establishment_id])

    function onExchangeScreen() {
        if (values.adjustment_view > values.validations) {
            setFieldValue('adjustment_view', 3)

            if (values.validations !== 0) {
                setFieldValue('validations', 4)
            }
        }

        if (values.adjustment_view < values.validations) {
            setFieldValue('validations', 3)

            if (values.adjustment_view !== 0) {
                setFieldValue('adjustment_view', 4)
            }
        }

        if (values.adjustment_view === 0 && values.validations === 0) {
            setFieldValue('validations', 3)
            setFieldValue('adjustment_view', 4)
        }
    }

    function onToggleScreen(key: 'adjustment_view' | 'validations') {
        if (values[key] === 0) {
            if (key === 'adjustment_view') {
                setFieldValue('adjustment_view', values.validations === 0 || values.validations === 4 ? 3 : 4)
            }

            if (key === 'validations') {
                setFieldValue('validations', values.adjustment_view === 0 || values.adjustment_view === 4 ? 3 : 4)
            }
        } else {
            if (key === 'validations') {
                setFieldValue(
                    'adjustment_view',
                    values.validations === 3 && values.adjustment_view === 4 ? 3 : values.adjustment_view
                )
            }

            if (key === 'adjustment_view') {
                setFieldValue(
                    'validations',
                    values.adjustment_view === 3 && values.validations === 4 ? 3 : values.validations
                )
            }

            setFieldValue(key, 0)
        }
    }

    return (
        <div>
            <RowTitle title="Configurações da maquininha">
                <SelectInputForm
                    style={{ margin: 0, marginRight: '10px' }}
                    placeholder="Selecione um estabelecimento"
                    data={establishments.establishment_list?.map(({ id, business_name }) => ({
                        name: business_name,
                        value: id,
                    }))}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('establishment_id', value)
                    }}
                    value={values.establishment_id}
                />
            </RowTitle>

            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            {!values.establishment_id && <EmptyMessage>Selecione um estabelecimento para edição</EmptyMessage>}

            {values.establishment_id && (
                <div className="stone-form-container">
                    <div className="title" style={{ margin: '20px 10px 30px' }}>
                        Posição das telas no fluxo da transação
                    </div>

                    <div className="pos-screens-container">
                        <div className="pos-screen" title="Tela obrigatória" style={{ cursor: 'not-allowed' }}>
                            <div className="check-container">1ª tela</div>
                            <span className="pos-screen-text">Inserção de valor da transação</span>
                        </div>
                        <div className="pos-screen" title="Tela obrigatória" style={{ cursor: 'not-allowed' }}>
                            <div className="check-container">2ª tela</div>
                            <span className="pos-screen-text">Seleção de modalidade de pagamento</span>
                        </div>

                        {values.adjustment_view < values.validations ? (
                            <PosScreenCard
                                label="Ajuste de valores de transação"
                                value={values.adjustment_view}
                                onClick={() => onToggleScreen('adjustment_view')}
                            />
                        ) : (
                            <PosScreenCard
                                label="Validações"
                                value={values.validations}
                                onClick={() => onToggleScreen('validations')}
                            />
                        )}

                        <div className="exchange-screen-action" onClick={onExchangeScreen}>
                            <FontAwesomeIcon icon="exchange-alt" />
                        </div>

                        {values.adjustment_view < values.validations ? (
                            <PosScreenCard
                                label="Validações"
                                value={values.validations}
                                onClick={() => onToggleScreen('validations')}
                            />
                        ) : (
                            <PosScreenCard
                                label="Ajuste de valores de transação"
                                value={values.adjustment_view}
                                onClick={() => onToggleScreen('adjustment_view')}
                            />
                        )}

                        <div className="pos-screen" title="Tela obrigatória" style={{ cursor: 'not-allowed' }}>
                            <div className="check-container">
                                {values.adjustment_view && values.validations
                                    ? 5
                                    : !values.adjustment_view && !values.validations
                                    ? 3
                                    : 4}
                                ª tela
                            </div>
                            <div className="pos-screen-text">
                                <span>Pagamento</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonForm type="submit" buttonText="Salvar" onClick={handleSubmit} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PosViewControl
