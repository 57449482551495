import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { Creators as AuthActions } from 'store/reducers/auth'
import RootState, { AuthReducer, User } from 'store/reducers/types'
import { Mall, PagedList, Store } from 'types'

import { useDataFetching } from './useDataFetching'

interface Auth {
    basePath: string
    loading: boolean
    mall: Mall
    store: Store
    user?: User
    setUser(user: User): void
    setMall(mall: Mall): void
    setStore(store: Store): void
    mallsKey: string
    mallSlug: string
    malls: string[]
}

const initialState = { basePath: '', mall: {} as Mall, store: {} as Store }
const mallsKey = 'use-auth-malls'
const storesKey = 'use-auth-stores'

export function useAuth(): Auth {
    const history = useHistory()
    const { pathname } = history.location
    const dispatch = useDispatch()

    const { user } = useSelector<RootState, AuthReducer>(({ auth }) => auth)

    // const [mall, setMall] = useState({} as Mall)
    // const [store, setStore] = useState({} as Store)

    function setUser(user: User) {
        dispatch(AuthActions.setUserData(user || null))
    }

    function setMall(mall: Mall) {
        const mallsUpdated = mallsData.items.map(item => (mall.id === item.id ? mall : item))
        setInitialData(mallsKey, { ...mallsData, items: mallsUpdated })
    }

    function setStore(store: Store) {
        if (!user?.isShopkeeper) {
            const updatedStores = storesData.items.map(item => (store.id === item.id ? store : item))
            setInitialData(storesKey, { ...storesData, items: updatedStores })
            mutateStores({ ...storesData, items: updatedStores })
            return
        }
        setInitialData('use-auth-store', store)
        setInitialData('use-auth-mall', store.mall)
        mutateStore(store)
    }

    function getInitialData<Data>(key: string): Data {
        const initialData = localStorage.getItem(key)

        return initialData ? JSON.parse(initialData) : undefined
    }

    function setInitialData<Data>(key: string, value: Data) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    const { mallSlug, storeSlug } = useMemo(() => {
        const splitPath = pathname
            .split('/')
            .filter(item => item.length > 0)
            .reverse()

        const mallSlug = splitPath.pop()
        const storeSlug = splitPath.pop()

        return { mallSlug, storeSlug }
    }, [pathname])

    const { data: mallsData, loading: loadingMallsData } = useDataFetching<PagedList<Mall>>(
        user?.isMallManager ? '/painel/malls' : null,
        {
            params: { order_by: 'name', status: [1], per_page: 100 },
            initialData: getInitialData(mallsKey),
            onSuccess: data => {
                setInitialData(mallsKey, data)
            },
        }
    )
    const {
        data: storesData,
        loading: loadingStoresData,
        mutate: mutateStores,
    } = useDataFetching<PagedList<Store>>(user?.isMallManager ? '/painel/stores' : null, {
        initialData: getInitialData('use-auth-stores'),
        onSuccess: data => {
            setInitialData('use-auth-stores', data)
        },
    })

    const {
        data: storeData,
        loading: loadingStoreData,
        mutate: mutateStore,
    } = useDataFetching<Store>(user?.isShopkeeper && isNaN(Number(storeSlug)) ? `/painel/store/${storeSlug}` : null, {
        initialData: getInitialDataShopkeeperStore(),
        onSuccess: data => {
            setInitialData('use-auth-store', data)
        },
    })

    function getInitialDataShopkeeperStore() {
        const store = getInitialData<Store>('use-auth-store')
        if (store?.slug === storeSlug) {
            return store
        }
        return undefined
    }

    const getUnitiesWithAdmin = useCallback(() => {
        if (!mallsData?.items.length || !storesData?.items.length)
            return { basePath: '', mall: {} as Mall, store: {} as Store }

        const mall = mallsData.items.find(mall => mall.slug === mallSlug)
        const store = storesData.items.find(store => store.slug === storeSlug)

        const basePath =
            mall?.slug && store?.slug ? `/${mall?.slug}/${store?.slug}` : mall?.slug ? `/${mall?.slug}` : ''

        return { basePath, mall: mall || ({} as Mall), store: store || ({} as Store) }
    }, [mallsData, storesData, mallSlug, storeSlug])

    const getUnitiesWithShopkeeper = useCallback(() => {
        const store = getInitialData<Store>('use-auth-store')

        if (store?.slug && store?.slug === storeSlug) {
            return { basePath: `/${store.mall?.slug}/${store?.slug}`, mall: store.mall, store: store }
        } else if (storeData) {
            const store = storeData as Store
            setInitialData('use-auth-store', store)
            setInitialData('use-auth-mall', store.mall)

            return { basePath: `/${store.mall?.slug}/${store?.slug}`, mall: store.mall, store: store }
        } else {
            return initialState
        }
    }, [storeData, storeSlug])

    const { basePath, mall, store } = useMemo(() => {
        if (user?.isMallManager) {
            const { basePath, mall, store } = getUnitiesWithAdmin()
            return { basePath, mall, store }
        } else if (user?.isShopkeeper) {
            const { basePath, mall, store } = getUnitiesWithShopkeeper()
            return { basePath, mall, store }
        } else {
            return initialState
        }
    }, [getUnitiesWithAdmin, getUnitiesWithShopkeeper])

    const malls = useMemo<string[]>(() => {
        if (user?.malls) {
            return user.malls.map(item => item.slug)
        }
        return []
    }, [user])

    const loading = user?.isMallManager
        ? (!mall?.id || !store?.id) && (loadingMallsData || loadingStoresData)
        : user?.isShopkeeper
        ? loadingStoreData
        : false

    return {
        basePath,
        loading,
        mall,
        store,
        user,
        setUser,
        mallsKey,
        setMall,
        mallSlug,
        malls,
        setStore,
    }
}
