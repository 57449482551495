const getBatteryIcon = battery => {
    if (battery >= 90) {
        return 'battery-full'
    } else if (battery > 60) {
        return 'battery-three-quarters'
    } else if (battery > 40) {
        return 'battery-half'
    } else if (battery > 20) {
        return 'battery-quarter'
    } else {
        return 'battery-empty'
    }
}

export { getBatteryIcon }
