/* eslint-disable no-case-declarations */
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { format } from 'date-fns'
import { stringify } from 'query-string'
import StringMask from 'string-mask'

import { AmountCard, LoadingMessage, PerformanceCard } from 'components/_common'
import InfoItem from 'components/_new-general-vision/info-item/info-item'
import { PersonalDeliveriesTable } from 'components/_store-general-vision'

import { formatCnpj, formatCpf, formatCurrency, formatPhone, setSearchParams } from 'helpers'
import { useDataFetching } from 'hooks'
import { GeneralReportFilter, Mall, PagedList, PaymentReport, ReportOrderItem } from 'types'

import {
    CardRow,
    AmountRow,
    PerformanceContent,
    PerformancePageContainer,
    RowItem,
    AmountItem,
    InfoSectionTitle,
    InfoRow,
    InfoItemContainer,
    BikerCard,
    BikerPhoto,
    BikerText,
    BikerName,
    InfoFlex,
    ReportHeader,
    HeaderTitle,
    HeaderContent,
    BackContainer,
    BackIcon,
    BackText,
} from './individual-report-page.styles'

interface Params {
    id: string
}

export default function IndividualReportPage(): JSX.Element {
    const { id } = useParams() as Params
    const history = useHistory()
    const [deliveriesList, setDeliveriesList] = useState([] as ReportOrderItem[])
    const filters: GeneralReportFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)
        const malls = query.get('malls')
        const mallsArray = malls?.split(',').map(id => Number(id))
        return {
            malls: mallsArray || undefined,
            start_date: query.get('start_date') || undefined,
            end_date: query.get('end_date') || undefined,
            start_time: query.get('start_time') || undefined,
            end_time: query.get('end_time') || undefined,
            current_page: query.get('page') || 1,
        }
    }, [location.search])

    const revalidateOnMount = Boolean(stringify(filters).length)

    const { data: reportData, loading } = useDataFetching<PaymentReport>(`/painel/report/payments/agent/${id}`, {
        params: filters,
        revalidateOnMount: revalidateOnMount,
        onSuccess: data => {
            if (data.current_page > 1) {
                setDeliveriesList([...deliveriesList, ...(data?.items as ReportOrderItem[])])
            } else {
                setDeliveriesList(data.items as ReportOrderItem[])
            }
        },
    })

    const { data: malls } = useDataFetching<PagedList<Mall>>(`/painel/malls`, {
        params: { order_by: 'name', status: [1], per_page: 100 },
    })

    function loadMore() {
        setSearchParams({ ...filters, page: Number(reportData?.current_page) + 1 }, location, history)
    }

    function getSelectedMalls(selectedMalls: number[]) {
        const names = []
        selectedMalls.forEach(mallItem => {
            malls.items.forEach(mall => {
                if (mall.id === mallItem) {
                    names.push(mall.name)
                }
            })
        })

        return names
    }

    const names = useMemo(() => {
        if (malls) {
            return getSelectedMalls(filters.malls).join(', ')
        } else {
            return []
        }
    }, [filters, malls])

    return (
        <PerformancePageContainer>
            <ReportHeader>
                <BackContainer onClick={() => history.goBack()}>
                    <BackIcon />
                    <BackText>Voltar para relatório geral</BackText>
                </BackContainer>
                <HeaderTitle>Malls Selecionados</HeaderTitle>
                <HeaderContent>{names}</HeaderContent>

                <HeaderTitle>Período Selecionado</HeaderTitle>
                <HeaderContent>
                    {format(filters.start_date, 'DD/MM/YYYY')} a {format(filters.end_date, 'DD/MM/YYYY')}
                    {filters.start_time && filters.end_time && <> {` (${filters.start_time} - ${filters.end_time})`}</>}
                </HeaderContent>
            </ReportHeader>
            <PerformanceContent>
                <CardRow>
                    <RowItem>
                        <PerformanceCard title="Parceiro de entregas" subtitle="Informações básicas do entregador ">
                            {loading ? (
                                <LoadingMessage />
                            ) : (
                                <>
                                    <InfoSectionTitle>Dados Pessoais</InfoSectionTitle>
                                    <BikerCard>
                                        <BikerPhoto src={reportData?.header.photo} />
                                        <BikerText>
                                            <BikerName>{reportData?.header.biker_name}</BikerName>
                                            <InfoFlex>
                                                <InfoItem label="ID" content={reportData?.header.biker_id.toString()} />
                                                <InfoItem
                                                    label="Veículo"
                                                    content={
                                                        reportData?.header.biker_type === 1
                                                            ? 'Motocicleta'
                                                            : 'Bicicleta'
                                                    }
                                                />
                                            </InfoFlex>
                                        </BikerText>
                                    </BikerCard>

                                    <InfoRow>
                                        <InfoItemContainer triple>
                                            <InfoItem label="CPF" content={formatCpf(reportData?.header.biker_cpf)} />
                                        </InfoItemContainer>
                                        {reportData?.header.biker_cnpj && (
                                            <InfoItemContainer triple>
                                                <InfoItem
                                                    label="Razão Social"
                                                    content={reportData?.header.biker_social_reason}
                                                />
                                            </InfoItemContainer>
                                        )}
                                        <InfoItemContainer triple>
                                            <InfoItem
                                                label="CNPJ"
                                                content={
                                                    reportData?.header.biker_cnpj
                                                        ? formatCnpj(reportData?.header.biker_cnpj)
                                                        : 'Não consta'
                                                }
                                            />
                                        </InfoItemContainer>
                                    </InfoRow>
                                    <InfoSectionTitle>Contato</InfoSectionTitle>
                                    <InfoRow>
                                        <InfoItemContainer>
                                            <InfoItem
                                                label="Telefone"
                                                content={formatPhone(reportData?.header.biker_phone)}
                                            />
                                        </InfoItemContainer>
                                        <InfoItemContainer>
                                            <InfoItem label="E-mail" content={reportData?.header.email} />
                                        </InfoItemContainer>
                                    </InfoRow>
                                </>
                            )}
                        </PerformanceCard>
                    </RowItem>
                    <RowItem>
                        <PerformanceCard
                            title="Resumo para pagamento"
                            subtitle="Baseado nos pedidos entregues no período"
                        >
                            {loading ? (
                                <LoadingMessage />
                            ) : (
                                <>
                                    <AmountRow>
                                        <AmountItem>
                                            <AmountCard
                                                number={reportData?.header.total_orders}
                                                title="Total de entregas realizadas"
                                                subtitle="Número de pedidos do período"
                                            />
                                        </AmountItem>
                                        <AmountItem>
                                            <AmountCard
                                                number={formatCurrency(reportData?.header.payment_total)}
                                                title="Total a ser pago"
                                                subtitle="Valor a ser repassado ao entregador"
                                            />
                                        </AmountItem>
                                    </AmountRow>

                                    <InfoSectionTitle>Dados bancários</InfoSectionTitle>
                                    {reportData?.header.bank.account_type === 'pix' ? (
                                        <InfoRow>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="Tipo de chave PIX"
                                                    content={transformPixKey(reportData?.header.bank.pix_type)}
                                                />
                                            </InfoItemContainer>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="Chave PIX"
                                                    content={transformPixCode(
                                                        reportData?.header.bank.pix_type,
                                                        reportData?.header.bank.pix_code
                                                    )}
                                                />
                                            </InfoItemContainer>
                                        </InfoRow>
                                    ) : (
                                        <InfoRow>
                                            <InfoItemContainer>
                                                <InfoItem label="Banco" content={reportData?.header.bank.bank} />
                                            </InfoItemContainer>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="Tipo de conta"
                                                    content={reportData?.header.bank.operation_number}
                                                />
                                            </InfoItemContainer>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="Agência"
                                                    content={reportData?.header.bank.bank_branch}
                                                />
                                            </InfoItemContainer>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="Conta"
                                                    content={reportData?.header.bank.account_number}
                                                />
                                            </InfoItemContainer>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="Nome do Titular"
                                                    content={reportData?.header.bank.owner_account}
                                                />
                                            </InfoItemContainer>
                                            <InfoItemContainer>
                                                <InfoItem
                                                    label="CPF/CNPJ DO TITULAR"
                                                    content={reportData?.header.bank.cpf_cnpj}
                                                />
                                            </InfoItemContainer>
                                        </InfoRow>
                                    )}
                                </>
                            )}
                        </PerformanceCard>
                    </RowItem>
                </CardRow>
                <PersonalDeliveriesTable
                    deliveries={deliveriesList}
                    totals={reportData?.totals}
                    loading={loading}
                    loadClick={() => loadMore()}
                    filters={filters}
                    id={Number(id)}
                />
            </PerformanceContent>
        </PerformancePageContainer>
    )
}

function transformPixKey(key?: string | null) {
    if (!key) {
        return null
    }
    switch (key) {
        case 'email':
            return 'E-mail'
        case 'phone':
            return 'Número do Telefone'
        case 'cpf':
            return 'Número do CPF'
        case 'cnpj':
            return 'Número do CNPJ'
        case 'random_key':
            return 'Chave aleatória'
    }

    return key
}

function transformPixCode(key?: string, value?: string) {
    if (!key) {
        return null
    }
    switch (key) {
        case 'phone':
            return phoneMask(value)
        case 'cpf':
            const formatteerCPF = new StringMask('000.000.000-00')
            return formatteerCPF.apply((value || '').replace(/\D/g, ''))
        case 'cnpj':
            const formatterCNPJ = new StringMask('00.000.000/0000-00')
            return formatterCNPJ.apply((value || '').replace(/\D/g, ''))
    }

    return value
}

function phoneMask(phone: string) {
    if (!phone) {
        return ''
    }

    let number = phone.replace('+55', '')
    number = number.replace(/\D/g, '')
    number = number.replace(/^(\d{2})(\d)/g, '($1) $2')
    number = number.replace(/(\d)(\d{4})$/, '$1-$2')

    return number
}
