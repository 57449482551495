import styled from 'styled-components'

export const ProductTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    //height: 100%;

    gap: 0.75rem;
    padding: 1rem 0.75rem;

    background-color: ${({ theme }) => theme.colors.gray};
`

export const ProductTabHeader = styled.div`
    background-color: #eaeaea;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 30px 40px;
    }
`

export const MainContent = styled.div`
    height: 600px;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.gray};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        height: unset;
        min-height: 0;
        flex: 1;
    }
`

export const TabTitle = styled.div`
    font-weight: bold;
    font-size: 22px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 30px;
    }
`

export const HeaderButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    border-radius: 8px;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0.75rem 1.25rem;

    background-color: ${({ theme }) => theme.colors.white};

    border-radius: 0.5rem;
`

export const HeaderTitle = styled.h3`
    padding: 0;
    margin: 0;

    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primary};
`
