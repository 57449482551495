import React, { memo } from 'react'

import { Text } from 'components/text/text'

type CellProps = {
    label: string
    value: string | number
    variant?: 'success' | 'danger' | 'primary' | 'alert'
}

const Info: React.FC<CellProps> = memo(({ label, value, variant }) => {
    return (
        <div>
            <Text text={label} light dimensions="xSmall" />
            <Text text={value} bold marginTop={4} variant={variant} />
        </div>
    )
})

export { Info }
