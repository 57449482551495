import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { IZone } from 'types/floor-map'

import { FloorMapContext } from '../../context/floor-map-context'

type IPolygon = { color?: string; x: number; y: number; width: number; height: number }

function useFloorMapController(selectedImage: string, zones: IZone[]) {
    const { isDrawZone, isNewFormZone, values, selectedZone, canvasPolygon, setCanvasPolygon, setZoneCoordinates } =
        useContext(FloorMapContext)

    const dimensionValueRef = useRef<{ total: number; actually: { x: number; y: number } }>({
        total: 1920,
        actually: null,
    })

    const canvasRef = useRef<HTMLCanvasElement>()
    const canvasContextRef = useRef<CanvasRenderingContext2D>(null)
    const canvasOffSetXRef = useRef<number>(null)
    const canvasOffSetYRef = useRef<number>(null)
    const drawStartXRef = useRef<number>(null)
    const drawStartYRef = useRef<number>(null)

    const pointDrawRef = useRef<IPolygon>(null)

    const [mapZones, setMapZones] = useState<IZone[]>()
    const [isDrawing, setIsDrawing] = useState<boolean>()

    const getShowPolygons = useMemo(() => {
        return mapZones
    }, [mapZones])

    const _convertPolygonToCoordinates = useCallback((point: IPolygon): number[][][] => {
        const firstPoint = [point.x, point.y]
        const lastPoint = [point.x, point.y]

        const points = [
            [point.x + point.width, point.y],
            [point.x + point.width, point.y + point.height],
            [point.x, point.y + point.height],
        ]

        const coordinates = [[firstPoint, ...points, lastPoint]]

        return coordinates
    }, [])

    const _startDrawing = useCallback(
        event => {
            event.preventDefault()
            event.stopPropagation()

            drawStartXRef.current = event.clientX - canvasOffSetXRef.current
            drawStartYRef.current = event.clientY - canvasOffSetYRef.current

            if (!pointDrawRef.current && isDrawZone) {
                setIsDrawing(true)
            }
        },
        [isDrawZone]
    )

    const _addPolygon = useCallback(
        (data: IPolygon) => {
            const coordinates = _convertPolygonToCoordinates(data)
            setZoneCoordinates(coordinates)
            setMapZones(state => {
                let zonesFiltered = []

                if (state && !isNewFormZone && !values?.id) {
                    zonesFiltered = state?.filter(item => item.id)
                } else {
                    zonesFiltered = state?.filter(item => item.id !== values.id)
                }

                return [
                    ...zonesFiltered,
                    {
                        ...values,
                        area: {
                            type: 'Polygon',
                            coordinates,
                        },
                    },
                ]
            })
        },
        [values, isNewFormZone, setZoneCoordinates, _convertPolygonToCoordinates]
    )

    const _stopDraw = useCallback(() => {
        setIsDrawing(false)

        if (pointDrawRef.current) {
            //_addPolygon(pointDrawRef.current)
            setCanvasPolygon({ ...pointDrawRef.current, color: '' })
            pointDrawRef.current = null
            return
        }
    }, [setCanvasPolygon])

    const _handleDraw = useCallback(
        event => {
            if (!isDrawing) return

            event.preventDefault()
            event.stopPropagation()

            const mouseX = event.clientX - canvasOffSetXRef.current
            const mouseY = event.clientY - canvasOffSetYRef.current

            const rectW = mouseX - drawStartXRef.current
            const rectH = mouseY - drawStartYRef.current

            canvasContextRef.current.strokeStyle = '#000000'
            canvasContextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)

            canvasContextRef.current.strokeRect(drawStartXRef.current, drawStartYRef.current, rectW, rectH)

            const dimensionConvert = (value: number, dimension: 'width' | 'height') =>
                Math.round((Math.round(value) * dimensionValueRef.current.total) / canvasRef.current[dimension])

            pointDrawRef.current = {
                x: dimensionConvert(
                    rectW < 0 ? drawStartXRef.current - Math.abs(rectW) : drawStartXRef.current,
                    'width'
                ),
                y: dimensionConvert(
                    rectH < 0 ? drawStartYRef.current - Math.abs(rectH) : drawStartYRef.current,
                    'height'
                ),
                width: dimensionConvert(rectW < 0 ? Math.abs(rectW) : rectW, 'width'),
                height: dimensionConvert(rectH < 0 ? Math.abs(rectH) : rectH, 'height'),
            }
        },
        [isDrawing]
    )

    const _drawStrokePolygon = useCallback((x: number, y: number, width: number, height: number, color: string) => {
        canvasContextRef.current.fillStyle = color
        canvasContextRef.current.strokeStyle = color

        // border polygon
        canvasContextRef.current.strokeRect(x, y, width, height)
    }, [])

    const _drawFillPolygon = useCallback(
        (x: number, y: number, width: number, height: number, color: string, opacity?: number) => {
            canvasContextRef.current.fillStyle = color
            canvasContextRef.current.strokeStyle = color

            // background polygon
            canvasContextRef.current.globalAlpha = opacity ?? 0.4
            canvasContextRef.current.fillRect(x, y, width, height)
            canvasContextRef.current.globalAlpha = 1
        },
        []
    )

    const _drawPoints = useCallback(() => {
        canvasContextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)

        const polygons = getShowPolygons

        // drawPolygon
        polygons?.forEach(item => {
            const coords = item.area.coordinates[0]
            const opacity = item.id === selectedZone?.id ? 1 : 0.4
            const color = isNewFormZone ? (item.id === selectedZone?.id ? item.color : `${item.color}80`) : item.color

            if (!coords) return

            const initialPoint = coords[0]
            const lastPoint = coords[2]

            const [fX, fY] = initialPoint
            const [lX, lY] = lastPoint

            // border polygon
            _drawStrokePolygon(
                fX * dimensionValueRef.current.actually.x,
                fY * dimensionValueRef.current.actually.y,
                (lX - fX) * dimensionValueRef.current.actually.x,
                (lY - fY) * dimensionValueRef.current.actually.y,
                color
            )

            _drawFillPolygon(
                fX * dimensionValueRef.current.actually.x,
                fY * dimensionValueRef.current.actually.y,
                (lX - fX) * dimensionValueRef.current.actually.x,
                (lY - fY) * dimensionValueRef.current.actually.y,
                color,
                opacity
            )

            // Label text
            canvasContextRef.current.fillStyle = '#000000'
            canvasContextRef.current.font = 'arial bold 0.75rem Roboto'
            canvasContextRef.current.textAlign = 'center'
            canvasContextRef.current.textBaseline = 'middle'
            canvasContextRef.current.fillText(
                item.label,
                (fX + (lX - fX) / 2) * dimensionValueRef.current.actually.x,
                (fY + (lY - fY) / 2) * dimensionValueRef.current.actually.y
            )
        })
    }, [getShowPolygons, selectedZone, isNewFormZone, _drawStrokePolygon, _drawFillPolygon])

    const _initCanvas = useCallback(() => {
        const canvasEle = canvasRef.current
        canvasEle.width = canvasEle.clientWidth
        canvasEle.height = canvasEle.clientHeight

        dimensionValueRef.current.actually = {
            x: (canvasEle.width * 100) / dimensionValueRef.current.total / 100,
            y: (canvasEle.height * 100) / dimensionValueRef.current.total / 100,
        }

        const context = canvasEle.getContext('2d')
        context.lineCap = 'round'
        context.strokeStyle = '#000000'
        context.lineWidth = 1
        canvasContextRef.current = context

        const canvasOffset = canvasEle.getBoundingClientRect()
        canvasOffSetXRef.current = canvasOffset.left
        canvasOffSetYRef.current = canvasOffset.top
    }, [])

    useEffect(() => {
        if (canvasPolygon) {
            _addPolygon(canvasPolygon)
        }
    }, [canvasPolygon, values.color, values.label])

    useEffect(() => {
        if (zones) {
            setMapZones([...zones])
        }
    }, [zones, isNewFormZone])

    useEffect(() => {
        if (selectedImage !== undefined || isNewFormZone) {
            _initCanvas()
        }
    }, [selectedImage, isNewFormZone])

    useEffect(() => {
        if (canvasContextRef.current) {
            _drawPoints()
        }
    }, [isNewFormZone, _drawPoints])

    return { canvasRef, _startDrawing, _handleDraw, _stopDraw }
}

export { useFloorMapController }
