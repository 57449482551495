import { featureCollection, point } from '@turf/helpers'
import { getBounds } from 'geolib'
import { EventData, GeoJSONSource, Map, PaddingOptions, Popup, SymbolLayer } from 'mapbox-gl'

import { Image } from '../types'

export function addMultipleSymbolPoints(
    map: Map,
    images: Image[],
    fitBounds = true,
    fitBoundsOptions?: mapboxgl.FitBoundsOptions
): {
    source: GeoJSONSource
    layer: SymbolLayer
    popup: Popup
} {
    if (!map) {
        return
    }
    images.forEach(image => {
        try {
            if (map.hasImage(image.id)) return

            map.loadImage(image?.src, function (error, loadedImage: HTMLImageElement | ImageBitmap | undefined) {
                if (error) throw error

                map.addImage(image?.id, loadedImage as HTMLImageElement)
            })
        } catch (err) {
            console.log('err', err)
        }
    })

    function getImageLatLng(image: Image) {
        const { lat, lng } = image.coordinates

        return { lat, lng }
    }

    const geojson = featureCollection(
        images
            .filter(image => image?.coordinates)
            .map(image => {
                const { lat, lng } = getImageLatLng(image)

                return point([lng, lat], {
                    icon: image.id,
                    size: image?.size || 0.75,
                    popup: image?.popup || '',
                })
            })
    )

    map.addSource('multiple-points', {
        type: 'geojson',
        data: geojson,
        cluster: false,
    })

    map.addLayer({
        id: 'multiple-points',
        type: 'symbol',
        source: 'multiple-points',
        layout: {
            'icon-image': ['get', 'icon'],
            'icon-size': ['get', 'size'],
        },
    })

    const source = map.getSource('multiple-points') as GeoJSONSource
    const layer = map.getLayer('multiple-points') as SymbolLayer

    if (fitBounds && images.length > 1) {
        const { minLat, minLng, maxLat, maxLng } = getBounds(
            images.map(image => {
                const { lat, lng } = getImageLatLng(image)

                return { latitude: lat, longitude: lng }
            })
        )

        map.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat],
            ],
            { padding: { top: 75, bottom: 75 } as PaddingOptions, ...fitBoundsOptions }
        )
    }

    const popup = new Popup({ offset: 25, closeButton: false })

    map.on('click', 'multiple-points', function (e: EventData) {
        const popupElement = document.createElement('div')

        const coordinates = e.features[0].geometry.coordinates.slice()
        const popupContent = e.features[0].properties.popup

        popupElement.innerHTML = popupContent

        popupElement.addEventListener('click', () => {
            popup.remove()
        })

        popup.setLngLat(coordinates).setDOMContent(popupElement).addTo(map)
    })

    return { source, layer, popup }
}
