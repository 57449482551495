import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: 0.75rem;
    padding: 1rem 0.75rem;

    background-color: ${({ theme }) => theme.colors.gray};
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0.75rem 1.25rem;

    background-color: ${({ theme }) => theme.colors.white};

    border-radius: 0.5rem;
`

export const HeaderTitle = styled.h3`
    padding: 0;
    margin: 0;

    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primary};
`

export const SideDishesListingContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    padding-bottom: 1.25rem;
`

export const SideDishCategoryContainer = styled.div`
    border-radius: 0.5rem;
    overflow: hidden;
`

export const SideDishTitleContainer = styled.div`
    padding: 0.5rem 1.25rem;
    height: 2.375rem;
    background-color: #eaeaea;
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    //border-bottom: 4px solid #fff;
`

export const GroupTitleText = styled.div`
    font-weight: bold;
    position: relative;
    //border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
    //padding-bottom: 7px;
    display: inline-flex;
    //margin-bottom: 20px;

    color: ${({ theme }) => theme.colors.textLight};
`

export const SideDishItem = styled.div`
    padding: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        font-size: 14px;
    }
`

export const MobileTag = styled.span`
    font-weight: bold;
    margin-right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`

export const SideDishItemTitleBar = styled.div`
    justify-content: space-between;
    font-weight: bold;
    font-size: 12px;
    display: none;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        height: 3.375rem;
    }
`

export const SideDishID = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 5%;
    }
`

export const SideDishName = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 12%;
        margin-right: 3%;
    }
`

export const SideDishDescription = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 25%;
    }
`

export const SideDishSKU = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 10%;
    }
`

export const SideDishPrice = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 10%;
    }
`

export const SideDishStatus = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 10%;
    }
`

export const SideDishButtons = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 25%;
    }
`

export const EditButtonContainer = styled.div`
    margin-left: 20px;
    cursor: pointer;
    transition: 400ms;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
        opacity: 0.6;
    }
`

export const DeleteButtonContainer = styled.div`
    margin-left: 20px;
    cursor: pointer;
    transition: 400ms;
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    &:hover {
        opacity: 0.6;
    }
`

export const EditButton = styled(FontAwesomeIcon).attrs({
    icon: faEdit,
})`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary};
`

export const TrashButton = styled(FontAwesomeIcon).attrs({
    icon: faTrashAlt,
})`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.errorMessage};
`

export const CollapsableListContainer = styled.div<{ isActive: boolean }>`
    //height: ${({ isActive }) => (isActive ? 'auto' : 0)};
    overflow: ${({ isActive }) => (isActive ? 'auto' : 'hidden')};
    min-height: ${({ isActive }) => (isActive ? '300px' : 0)};
    max-height: ${({ isActive }) => (isActive ? 'fit-content' : 0)};

    transition: all 400ms ease-out;
    transform: ${({ isActive }) => (isActive ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
`

export const CollapseButton = styled.div`
    //margin-bottom: 20px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    font-size: 10px;
    //text-decoration: underline;
    cursor: pointer;
    user-select: none;
`

export const FlexRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const CollapsedRow = styled.div<{ isActive?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 0.25rem;

    font-size: 0.875rem;

    i {
        transition: 700ms;
        transform: ${({ isActive }) => (isActive ? 'rotateX(-180deg)' : 'rotateX(0deg)')};
    }
`

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
`

export const CollapsedIcon = styled(LogarooIcon).attrs({
    icon: 'chevron-down',
})``

export const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    color: ${({ theme }) => theme.colors.white};
`

export const StatusItem = styled.div<{ isActive?: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.success : theme.colors.danger)};
`
