import { useState } from 'react'

import NewTopRow from 'components/_new-general-vision/new-top-row/new-top-row'
import { CollectsInProgress, NotifyProblemModal } from 'components/_picker-vision'
import { CollectDetailsModal } from 'components/_store-general-vision'

import { ContentContainer, RouteCreationContainer } from './picker-collect-in-progress.styles'

export default function PickerCollectInProgress(): JSX.Element {
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [isProblemModalActive, setIsProblemModalActive] = useState(false)
    return (
        <>
            <RouteCreationContainer>
                <NewTopRow title="Rota em andamento" />
                <ContentContainer>
                    <CollectsInProgress
                        orderCardClick={() => setIsDetailsModalOpen(true)}
                        problemClick={() => setIsProblemModalActive(true)}
                    />
                </ContentContainer>
            </RouteCreationContainer>
            <CollectDetailsModal isActive={isDetailsModalOpen} closeClick={() => setIsDetailsModalOpen(false)} />
            <NotifyProblemModal isActive={isProblemModalActive} closeClick={() => setIsProblemModalActive(false)} />
        </>
    )
}
