import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    padding: 10px;
    user-select: none;
    background-color: #f1f1f1;
    &:nth-child(odd) {
        background-color: #fff;
    }
`

const Column = styled.div<{ fitContent?: boolean; hideOnSmall?: boolean; center?: boolean; widthFix?: number }>`
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
    ${({ fitContent }) =>
        fitContent
            ? css`
                  width: fit-content;
              `
            : css`
                  flex: 1;
              `}

    ${({ widthFix }) =>
        widthFix &&
        css`
            width: ${widthFix}px;
            @media (max-width: 888px) {
                width: ${widthFix - widthFix * 0.2}px;
            }
        `}
    ${({ hideOnSmall }) =>
        hideOnSmall &&
        css`
            @media (max-width: 750px) {
                display: none;
            }
        `}
`

const TextName = styled.div`
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 14px;

    @media (max-width: 888px) {
        font-size: 12px;
    }
`
const TextRole = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    @media (max-width: 888px) {
        font-size: 10px;
    }
`
const TextEmail = styled.div<{ hideOnLarge?: boolean }>`
    font-size: 12px;
    @media (max-width: 888px) {
        font-size: 10px;
    }
    ${({ hideOnLarge }) =>
        hideOnLarge &&
        css`
            @media (min-width: 751px) {
                display: none;
            }
        `}
`
const TextStores = styled.div`
    font-size: 12px;
    @media (max-width: 888px) {
        font-size: 10px;
    }
`
const TextID = styled.div`
    font-size: 12px;
    @media (max-width: 888px) {
        font-size: 10px;
    }
`

const ContainerButtons = styled.div`
    display: flex;
`

const IconButton = styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 15px;
    &:last-child {
        margin-right: 0;
    }
`

export { ContainerButtons, Container, Column, TextStores, TextName, TextEmail, TextRole, IconButton, TextID }
