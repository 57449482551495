import styled, { css } from 'styled-components'

const IconContainer = styled.i`
    color: ${({ theme }) => theme.colors.primary};

    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`

export { IconContainer }
