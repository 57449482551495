import { RouteType } from 'types'

const getRouteType = (type: RouteType): string => {
    return {
        direct: 'Rota direcionada',
        offer: 'Oferta aos disponíveis',
        fix: 'Rota de correção',
    }[type || '']
}

export { getRouteType }
