import { ChartData } from 'chart.js'

import { ChartObject } from 'types'

export function getAreaOrdersChart(areaOrders: ChartData<any>): ChartObject {
    return {
        data: areaOrders,
        // callbacks: {
        //     label: tooltipItem => {
        //         const { percentage, total } = areaOrders[tooltipItem.dataIndex as number]

        //         return `${total} pedidos - ${percentage?.toString().replace('.', ',')}%`
        //     },
        // },
    }
}
