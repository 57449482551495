/**
 *
 * @param {array} items
 * @param {string} selectSortingValue
 */

const getSortedItems = (items, selectSortingValue) => {
    let sortedItems = []

    switch (Number(selectSortingValue)) {
        // Maior tempo total
        case 1:
            sortedItems = items.sort((a, b) => (new Date(a.birth) > new Date(b.birth) ? 1 : -1))
            break
        // Menor tempo total
        case 2:
            sortedItems = items.sort((a, b) => (new Date(a.birth) < new Date(b.birth) ? 1 : -1))
            break
        // Maior tempo de pronto
        case 3:
            sortedItems = items.sort((a, b) => (new Date(a.updated_at) > new Date(b.updated_at) ? 1 : -1))
            break
        // Menor tempo de pronto
        case 4:
            sortedItems = items.sort((a, b) => (new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1))
            break
        // Maior previsão de entrega
        case 5:
            sortedItems = items.sort((a, b) => (new Date(a.delivery_forecast) < new Date(b.delivery_forecast) ? 1 : -1))
            break
        // Menor previsão de entrega
        case 6:
            sortedItems = items.sort((a, b) => (new Date(a.delivery_forecast) > new Date(b.delivery_forecast) ? 1 : -1))
            break

        default:
            sortedItems = items
            break
    }

    return sortedItems
}

export { getSortedItems }
