/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'

import { useAuth } from 'hooks'
import { plugApi } from 'services/api'
import { IIntegration } from 'types/integration'

interface ListIntegration {
    name: string
    logo: string
    integration: IIntegration[]
}

type IntegrationsContextType = {
    isAzul: boolean
    loadingIntegrations: boolean
    integrators: ListIntegration[]
    refreshIntegrations(): void
}

const InitialValue: IntegrationsContextType = {
    isAzul: false,
    loadingIntegrations: false,
    integrators: [],
    refreshIntegrations: () => {},
}

const IntegrationsContext = createContext<IntegrationsContextType>(InitialValue)

type IntegrationsContextProps = {
    children: any
}

const IntegrationsContextProvider: React.FC<IntegrationsContextProps> = memo(({ children }) => {
    const isAutoRefreshRef = useRef(true)

    const { store } = useAuth()
    const [loading, setLoading] = useState(false)
    const [isAzul, setIsAzul] = useState(false)
    const [integrators, setIntegrators] = useState<ListIntegration[]>([])

    const _getIntegrators = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await plugApi.get<ListIntegration[]>(`store/${store.id}/integrations`)
            let hasIfood = false

            for (let index = 0; index < data.length; index++) {
                if (hasIfood) {
                    break
                }
                const item = data[index]

                if (item.name === 'azul-cargo') {
                    setIsAzul(true)
                    break
                } else {
                    setIsAzul(false)
                }
                for (let i = 0; i < item.integration.length; i++) {
                    const integration = item.integration[i]
                    integration.extra = { logo: item.logo }

                    if (integration.type === 'ifood') {
                        hasIfood = true
                        break
                    }
                }
            }

            isAutoRefreshRef.current = hasIfood

            for (let index = 0; index < data.length; index++) {
                const item = data[index]
                for (let i = 0; i < item.integration.length; i++) {
                    const integration = item.integration[i]
                    const { type, origin } = integration
                    try {
                        const { data } = await plugApi.post<{ status: boolean; pause: any; message: any }>(
                            '/store/status',
                            {
                                origin_id: origin.id,
                                type: type,
                            }
                        )

                        integration.extra = { store_status: data.status, logo: item.logo, pause: data.pause }
                        integration.message = data.message
                    } catch (error) {
                        console.log('error', error)
                    }
                }
            }
            setIntegrators(data)
        } catch (error) {
            console.log('error', error)
        }

        setLoading(false)
    }, [setLoading, store])

    useEffect(() => {
        _getIntegrators()
        const interval = setInterval(async () => {
            if (isAutoRefreshRef.current) {
                await _getIntegrators()
            } else {
                clearInterval(interval)
            }
        }, 30000)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <IntegrationsContext.Provider
            value={{
                isAzul,
                loadingIntegrations: loading,
                integrators,
                refreshIntegrations: _getIntegrators,
            }}
        >
            {children}
        </IntegrationsContext.Provider>
    )
})

export { IntegrationsContext, IntegrationsContextProvider }
