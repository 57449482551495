import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import {
    ColorItem,
    ColorsContainer,
    Container,
    Label,
    Row,
    SelectedColorContainer,
    SelectedIcon,
    SubTitle,
} from './color-select.styled'

interface Props {
    label?: string
    defaultColor?: string
    handleColorSelected?(color: string): void
}

const colors = [
    '#FCB500',
    '#EF8C06',
    '#E24118',
    '#DD001C',
    '#BBE119',
    '#96C21F',
    '#1F851F',
    '#125053',
    '#2EC2B7',
    '#1E7798',
    '#033DD9',
    '#130D53',
    '#F792D9',
    '#CA66FC',
    '#8469FC',
    '#4343B0',
    '#E03255',
    '#A01E5F',
    '#6B235F',
    '#581240',
    '#58326F',
    '#849596',
    '#6C7A7B',
    '#222F3F',
]

const ColorSelect: FC<Props> = memo(({ label, defaultColor, handleColorSelected }) => {
    const [selectedColor, setSelectedColor] = useState<string>(colors[0])

    const _handleSelectedColor = useCallback(
        (color: string) => () => {
            if (handleColorSelected) {
                handleColorSelected(color)
            }
            setSelectedColor(color)
        },
        [handleColorSelected]
    )

    const _handleChangeInputColor = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (handleColorSelected) {
                handleColorSelected(value)
            }
            if (value === '') {
                setSelectedColor('#')
            } else {
                setSelectedColor(value)
            }
        },
        [handleColorSelected]
    )

    useEffect(() => {
        if (defaultColor) {
            setSelectedColor(defaultColor)
        }
    }, [defaultColor])

    return (
        <Container>
            {label && <Label>{label}</Label>}
            <ColorsContainer>
                <React.Fragment>
                    {colors.map((color, i) => (
                        <ColorItem
                            key={i}
                            color={color}
                            isSelectedColor={selectedColor === color}
                            onClick={_handleSelectedColor(color)}
                        >
                            <SelectedIcon />
                        </ColorItem>
                    ))}
                </React.Fragment>
            </ColorsContainer>
            <SubTitle>Escolha uma das cores acima ou informe o código HEX</SubTitle>

            <SelectedColorContainer>
                <label htmlFor="input-color">Cor selecionada</label>
                <label htmlFor="input-color">
                    <Row>
                        <ColorItem color={selectedColor} />
                        <input
                            type="text"
                            id="input-color"
                            name="color"
                            maxLength={7}
                            value={selectedColor}
                            onChange={_handleChangeInputColor}
                        />
                    </Row>
                </label>
            </SelectedColorContainer>
        </Container>
    )
})

export { ColorSelect }
