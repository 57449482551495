import { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { booleanPointInPolygon, point } from '@turf/turf'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import CheckboxInput from 'components/checkbox-input'
import { DefaultButton } from 'components/default-button/default-button'
import { GlobalMapContext, MirrorMap } from 'components/global-map'
import { CircleLines, InfoMapMessage, PolygonFill } from 'components/mapbox-view'
import ModalLoading from 'components/modal-loading'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { AvailableStores, PaymentArea, Store } from 'types'

import {
    Container,
    ContainerForm,
    ContainerMap,
    Content,
    FormTitle,
    FormGroup,
    InputGroup,
    Label,
} from './config-mall-rate-chain.styled'

interface IRedirectProp {
    store?: string
}

const ConfigMallRateChain: React.FC<RouteComponentProps<{ id: string; store: string }>> = ({
    history,
    match: { params },
}) => {
    const { store: selectedStoreRedirect } = (history.location.state || {}) as IRedirectProp

    const { basePath, mall } = useAuth()

    const { setErrorModal, setSuccessModal, setLoading } = useUI()
    const { setMapBoxContentComponent, setMarkerCenter } = useContext(GlobalMapContext)

    const [loading, toggleLoading] = useState<boolean>(false)
    const [paymentAreas, setPaymentAreas] = useState<PaymentArea[]>([])
    const [selectedStore, setSelectedStore] = useState<string>()
    const [selectedStoreData, setSelectedStoreData] = useState<Store>()
    const [storesOptionsData, setStoresOptionsData] = useState([])
    const [availableStores, setAvailableStores] = useState<AvailableStores>()

    const statusOptions = useMemo(() => {
        return [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 0 },
        ]
    }, [])

    const validateMinRateValue = Yup.number()
        .when('not_work', {
            is: not_work => not_work === false,
            then: (schema: Yup.NumberSchema) => schema.min(0.0, 'Tarifa é obrigatória'),
        })
        .nullable()

    const { initialValues, errors, handleSubmit, isSubmitting, setFieldValue, setValues, touched, values } = useFormik({
        initialValues: {
            id: null,
            payment_areas: [],
            status: null,
            values: {
                bike: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                moto: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                car: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                van: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                truck: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
            },
        },
        validationSchema: Yup.object().shape({
            values: Yup.object().shape({
                bike: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                moto: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                car: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                van: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                truck: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
            }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                const { bike, moto, car, van, truck } = values.values
                const paymentAreaIds = values.payment_areas.map(area => area.id)

                let param = null
                if (selectedStore == null || Number(selectedStore) === mall.id) {
                    param = { mall_id: Number(mall.id) }
                } else {
                    param = { store_id: Number(selectedStore) }
                }

                const body = {
                    ...param,
                    payment_areas: paymentAreaIds,
                    value: {
                        agent: {
                            bike: bike.agent,
                            moto: moto.agent,
                            car: car.agent,
                            van: van.agent,
                            truck: truck.agent,
                        },
                        store: {
                            bike: bike.store,
                            moto: moto.store,
                            car: car.store,
                            van: van.store,
                            truck: truck.store,
                        },
                    },
                }

                if (values.id) {
                    await api.put(`/painel/rate-chain/${values.id}`, body)
                } else {
                    await api.post('/painel/rate-chain/', body)
                }

                setSuccessModal({
                    title: 'Sucesso!',
                    subtitle: `Tarifa ${values.id ? 'atualizada' : 'criada'} com sucesso`,
                    singleButtonText: 'Continuar',
                    singleButtonClick: _redirect('tarifa-corrente', selectedStore),
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: showErrors(error),
                })
            }
            setSubmitting(false)
        },
    })

    const _redirect = useCallback(
        (endPoint: string, store?: string) => {
            return (): void =>
                history.replace(`${basePath}/${endPoint}`, {
                    store: store,
                })
        },
        [history, basePath]
    )

    const _handleChecked = useCallback(
        key => {
            return ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`values.${key}.not_work`, checked)

                if (checked) {
                    setFieldValue(`values.${key}.agent`, null)
                    setFieldValue(`values.${key}.store`, null)
                } else {
                    setFieldValue(`values.${key}.agent`, '0.0')
                    setFieldValue(`values.${key}.store`, '0.0')
                }
            }
        },
        [setFieldValue]
    )

    const _handleChange = useCallback(
        (key: string, type: string) => {
            return (event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target
                setFieldValue(`values.${key}.${type}`, value)
            }
        },
        [setFieldValue]
    )

    const _handleSelect = useCallback(
        (key?: string) => {
            return ({ target }) => {
                const { value } = target

                if (key !== 'store') {
                    setFieldValue(key, value)
                    return
                }

                if (value === mall?.id) {
                    setSelectedStoreData(null)
                }

                setSelectedStore(value)
            }
        },
        [mall.id]
    )

    const _checkPaymentArea = useCallback(
        ({ lngLat }: { lngLat: { lat: number; lng: number } }) => {
            const searchPoint = point([lngLat.lng, lngLat.lat])
            const areaClicked = paymentAreas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

            if (!areaClicked) return

            if (values.payment_areas.some((item: PaymentArea) => item.id === areaClicked?.id)) {
                setFieldValue(
                    'payment_areas',
                    values.payment_areas.filter((item: PaymentArea) => item.id !== areaClicked?.id)
                )
            } else {
                setFieldValue('payment_areas', [...values.payment_areas, areaClicked])
            }
        },
        [paymentAreas, setFieldValue, values.payment_areas]
    )

    const _renderContentMap = useCallback(() => {
        setMarkerCenter([
            selectedStoreData?.lng ? selectedStoreData?.lng : mall?.address?.lng,
            selectedStoreData?.lat ? selectedStoreData?.lat : mall?.address?.lat,
        ])

        return (
            <>
                <PolygonFill
                    id="rate-areas"
                    areaType="rate-area"
                    showPopUpArea
                    areas={paymentAreas}
                    onClick={_checkPaymentArea}
                    defaultColor="#333"
                    defaultOpacity={0.5}
                    rateCoverageAreas={values.payment_areas}
                />

                <InfoMapMessage message="Clique nos polígonos destacados no mapa para marcar ou desmarcar uma área de cobrança." />
                <CircleLines
                    id="circle-lines"
                    center={[
                        selectedStoreData?.lng ? selectedStoreData?.lng : mall?.address?.lng,
                        selectedStoreData?.lat ? selectedStoreData?.lat : mall?.address?.lat,
                    ]}
                />
            </>
        )
    }, [values.payment_areas, mall, selectedStoreData, paymentAreas, _checkPaymentArea])

    const _getRateChain = useCallback(
        async id => {
            toggleLoading(true)
            try {
                const { data } = await api.get(`/painel/rate-chain/${id}`)

                const { mall: selectedMall, store: selectedStore } = data
                setSelectedStore(selectedMall?.id || selectedStore?.id)

                const { agent, store } = data.value || {}

                setValues({
                    ...initialValues,
                    ...data,
                    status: Number(data.status),
                    values: {
                        bike: {
                            not_work: agent?.bike == null && store?.bike == null,
                            agent: agent?.bike,
                            store: store?.bike,
                        },
                        moto: {
                            not_work: agent?.moto == null && store?.moto == null,
                            agent: agent?.moto,
                            store: store?.moto,
                        },
                        car: {
                            not_work: agent?.car == null && store?.car == null,
                            agent: agent?.car,
                            store: store?.car,
                        },
                        van: {
                            not_work: agent?.van == null && store?.van == null,
                            agent: agent?.van,
                            store: store?.van,
                        },
                        truck: {
                            not_work: agent?.truck == null && store?.truck == null,
                            agent: agent?.truck,
                            store: store?.truck,
                        },
                    },
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível carregar os dados.',
                })
            }
            toggleLoading(false)
        },
        [history, initialValues, setValues]
    )

    const _getSelectData = useCallback(async () => {
        toggleLoading(true)

        const getMallStoreId = () => {
            if (!selectedStore || Number(selectedStore) === mall.id) {
                return { mall_id: mall.id }
            }

            return { store_id: selectedStore }
        }

        try {
            const { data } = await api.get(`/painel/payment-areas`, {
                params: {
                    ...getMallStoreId(),
                    per_page: -1,
                    status: [1],
                },
            })
            setPaymentAreas(data.items)
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar os dados. (payment areas)',
            })
        }
        toggleLoading(false)
    }, [mall, selectedStore])

    const _getSelectedStoreData = useCallback(async () => {
        if (selectedStore && Number(selectedStore) !== mall.id) {
            setLoading(true)
            try {
                const { data } = await api.get(`/painel/store/${selectedStore}`)

                setSelectedStoreData(data)
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        }
    }, [mall, selectedStore])

    const _getAvailableStores = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get(`/painel/stores`, {
                params: {
                    mall_id: mall.id,
                    per_page: -1,
                    status: 1,
                },
            })

            setAvailableStores(data)
        } catch (error) {
            console.log('error', error)
        }

        setLoading(false)
    }, [mall.id])

    useEffect(() => {
        _getSelectData()
    }, [_getSelectData])

    useEffect(() => {
        _getSelectedStoreData()
    }, [_getSelectedStoreData])

    useEffect(() => {
        setMapBoxContentComponent(_renderContentMap)
    }, [_renderContentMap])

    useEffect(() => {
        if (params.id) {
            _getRateChain(params.id)
        }
        if (params.store === 'undefined') {
            setSelectedStore(null)
        }

        if (params.store !== 'undefined') {
            setSelectedStore(params.store)
        }

        if (!params.id && selectedStoreRedirect) {
            setSelectedStore(selectedStoreRedirect)
        }
    }, [_getRateChain, params.id, params.store])

    useEffect(() => {
        _getAvailableStores()
    }, [_getAvailableStores])

    useEffect(() => {
        if (availableStores) {
            const available = availableStores?.items?.map(item => {
                return { label: item.name, value: item.id }
            })

            setStoresOptionsData([{ label: mall?.name, value: mall?.id }, ...available])

            const select = document.getElementsByTagName('select')
            select[0].selectedIndex = 1
        }
    }, [availableStores])

    const { bike, moto, car, van, truck } = values.values

    return (
        <Container>
            <ModalLoading visible={loading || isSubmitting} />

            <TopRowDefault
                title="Tarifa de Rota Corrente"
                onBackButtonClick={_redirect('tarifa-corrente', selectedStore || null)}
            />

            <Content>
                <ContainerForm>
                    <FormTitle>Editar Tarifa de Rota Corrente</FormTitle>

                    <FormGroup>
                        {!params.store && (
                            <InputGroup>
                                <InputItem
                                    labelText="Estabelecimento"
                                    type="select"
                                    options={storesOptionsData}
                                    inputProps={{
                                        value: selectedStore,
                                        onChange: _handleSelect('store'),
                                    }}
                                />
                            </InputGroup>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label>Tarifa para Bicicleta</Label>
                        <CheckboxInput
                            id="bike_not_work"
                            label="Não realiza entrega corrente*"
                            onChange={_handleChecked('bike')}
                            checked={bike.not_work}
                        />

                        <InputGroup direction="row">
                            <InputItem
                                labelText="Restaurante*"
                                type="currency"
                                errorMessage={touched.values?.bike?.store && errors.values?.bike?.store}
                                inputProps={{
                                    disabled: bike.not_work,
                                    value: bike.store,
                                    onChange: _handleChange('bike', 'store'),
                                }}
                            />

                            <InputItem
                                labelText="Entregador*"
                                errorMessage={touched.values?.bike?.agent && errors.values?.bike?.agent}
                                type="currency"
                                inputProps={{
                                    disabled: bike.not_work,
                                    value: bike.agent,
                                    onChange: _handleChange('bike', 'agent'),
                                }}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Tarifa para Motocicleta</Label>

                        <CheckboxInput
                            id="motorcycle_not_work"
                            label="Não realiza entrega corrente*"
                            onChange={_handleChecked('moto')}
                            checked={moto.not_work}
                        />

                        <InputGroup>
                            <InputItem
                                labelText="Restaurante*"
                                errorMessage={touched.values?.moto?.store && errors.values?.moto?.store}
                                type="currency"
                                inputProps={{
                                    disabled: moto.not_work,
                                    value: moto.store,
                                    onChange: _handleChange('moto', 'store'),
                                }}
                            />

                            <InputItem
                                labelText="Entregador*"
                                errorMessage={touched.values?.moto?.agent && errors.values?.moto?.agent}
                                type="currency"
                                inputProps={{
                                    disabled: moto.not_work,
                                    value: moto.agent,
                                    onChange: _handleChange('moto', 'agent'),
                                }}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label className="section-title">Tarifa para Carro</Label>

                        <CheckboxInput
                            id="car_not_work"
                            label="Não realiza entrega corrente*"
                            onChange={_handleChecked('car')}
                            checked={car.not_work}
                        />

                        <InputGroup>
                            <InputItem
                                labelText="Restaurante*"
                                errorMessage={touched.values?.car?.store && errors.values?.car?.store}
                                type="currency"
                                inputProps={{
                                    disabled: car.not_work,
                                    value: car.store,
                                    onChange: _handleChange('car', 'store'),
                                }}
                            />
                            <InputItem
                                labelText="Entregador*"
                                errorMessage={touched.values?.car?.agent && errors.values?.car?.agent}
                                type="currency"
                                inputProps={{
                                    disabled: car.not_work,
                                    value: car.agent,
                                    onChange: _handleChange('car', 'agent'),
                                }}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Tarifa para Van</Label>

                        <CheckboxInput
                            id="van_not_work"
                            label="Não realiza entrega corrente*"
                            onChange={_handleChecked('van')}
                            checked={van.not_work}
                        />

                        <InputGroup>
                            <InputItem
                                labelText="Restaurante*"
                                errorMessage={touched.values?.van?.store && errors.values?.van?.store}
                                type="currency"
                                inputProps={{
                                    disabled: van.not_work,
                                    value: van.store,
                                    onChange: _handleChange('van', 'store'),
                                }}
                            />
                            <InputItem
                                labelText="Entregador*"
                                errorMessage={touched.values?.van?.agent && errors.values?.van?.agent}
                                type="currency"
                                inputProps={{
                                    disabled: van.not_work,
                                    value: van.agent,
                                    onChange: _handleChange('van', 'agent'),
                                }}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Tarifa para Caminhão</Label>

                        <CheckboxInput
                            id="truck_not_work"
                            label="Não realiza entrega corrente*"
                            onChange={_handleChecked('truck')}
                            checked={truck.not_work}
                        />

                        <InputGroup>
                            <InputItem
                                labelText="Restaurante*"
                                errorMessage={touched.values?.truck?.store && errors.values?.truck?.store}
                                type="currency"
                                inputProps={{
                                    disabled: truck.not_work,
                                    value: truck.store,
                                    onChange: _handleChange('truck', 'store'),
                                }}
                            />
                            <InputItem
                                labelText="Entregador*"
                                errorMessage={touched.values?.truck?.agent && errors.values?.truck?.agent}
                                type="currency"
                                inputProps={{
                                    disabled: truck.not_work,
                                    value: truck.agent,
                                    onChange: _handleChange('truck', 'agent'),
                                }}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Status</Label>

                        <InputGroup>
                            <InputItem
                                type="select"
                                options={statusOptions}
                                inputProps={{
                                    value: values.status,
                                    onChange: _handleSelect('status'),
                                }}
                            />
                        </InputGroup>
                    </FormGroup>

                    <DefaultButton title="Salvar" onClick={() => handleSubmit()} />
                </ContainerForm>

                <ContainerMap>
                    <MirrorMap />
                </ContainerMap>
            </Content>
        </Container>
    )
}

export default ConfigMallRateChain
