import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonForm from 'components/button-form'

import colors from 'themes/colors'

import './style.scss'

interface ButtonRow {
    label: string
    secondary?: boolean
    onClick(): void
    style?: React.CSSProperties
}

interface Props {
    title: string
    backClick?(): void
    buttonRow?: ButtonRow[]
}

const RowTitle: React.FC<Props> = ({ title, backClick, buttonRow, children }) => {
    return (
        <div className="row-title-container">
            <div className="text-container">
                {backClick && <FontAwesomeIcon icon="arrow-left" onClick={backClick} />}
                <div className="row-title-text">{title}</div>
            </div>

            <div className="right-container">
                {children}

                {buttonRow?.map(({ label, onClick, style, secondary }, index) => (
                    <ButtonForm
                        key={index}
                        secondary={secondary}
                        buttonText={label}
                        style={{ ...style }}
                        bgColor={colors.primary_color}
                        textColor="#fff"
                        onClick={onClick}
                    />
                ))}
            </div>
        </div>
    )
}

export default RowTitle
