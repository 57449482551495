import { eventChannel } from '@redux-saga/core'
import { all, AllEffect, ForkEffect, put, spawn, take } from '@redux-saga/core/effects'
import { SocketIoChannel } from 'laravel-echo/dist/channel'
import { AnyAction } from 'redux'

interface EchoEvent {
    name: string
    action: (data: unknown) => AnyAction
}

function* listenToChannelEvent({ socketIoChannel, event }: { socketIoChannel: SocketIoChannel; event: EchoEvent }) {
    const channel = eventChannel(emitter => {
        socketIoChannel.listen(event.name, emitter)

        return () => {
            socketIoChannel.stopListening(event.name)
        }
    })

    try {
        while (true) {
            const { data } = yield take(channel)
            // console.log(`${socketIoChannel.name} - ${event.name}`, { data })

            yield put(event.action(data))
        }
    } finally {
        console.log('closed', { socketIoChannel: socketIoChannel, event })
        channel.close()
    }
}

export function* listenToEchoChannel({
    socketIoChannel,
    events,
}: {
    socketIoChannel: SocketIoChannel
    events: EchoEvent[]
}): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all(events.map(event => spawn(listenToChannelEvent, { socketIoChannel, event })))
}
