import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const OrderItemContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    min-height: min-content;
    align-items: center;
    position: relative;
`

export const StoreImageContainer = styled.div<{
    onTime?: boolean
    attention?: boolean
    late?: boolean
    scheduled?: boolean
}>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    border: 3px solid;
    border-color: ${({ theme, onTime, attention, late, scheduled }) =>
        onTime
            ? theme.orders.green
            : attention
            ? theme.orders.yellow
            : late
            ? theme.orders.red
            : scheduled
            ? theme.colors.lightGray
            : theme.colors.white};
    padding: 2px;
    margin-right: 20px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 60px;
        height: 60px;
    }
`

export const StoreImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
`

export const ChannelImage = styled.img<{ channel?: number }>`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -7px;
    right: -10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 30px;
        height: 30px;
    }
`

export const CustomerInfo = styled.div`
    width: 40%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 35%;
        margin-right: 20px;
        font-size: 11px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 14px;
        width: 30%;
    }
`

export const CustomerName = styled.div`
    position: relative;
    color: ${({ theme }) => theme.colors.mediumGray};
`

export const CustomerNeighborhood = styled.div`
    font-weight: bold;
`

export const CustomerAddress = styled.div``

export const OrderDetails = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
        margin-top: 0;
        flex: 1;
    }
`

export const MobileOrderValue = styled.div`
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: none;
    }
`

export const OrderValue = styled.div`
    margin-right: 20px;
    font-size: 14px;
    display: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 70%;
        font-size: 11px;
        display: block;
    }

    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 12px;
        width: 60%;
    }
`

export const ValueNumber = styled.div`
    font-weight: bold;
`

export const PaymentMethod = styled.div``

export const TagsContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 80px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        position: relative;
        top: unset;
        right: unset;
        flex: 1;
    }
`

export const TagItem = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
`

export const IconContainer = styled.div`
    width: 20px;
    text-align: center;
`

export const PlaceIcon = styled(FontAwesomeIcon).attrs({
    icon: faMapMarkerAlt,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    margin-right: 15px;
    width: 20px;
    font-size: 12px;
`

export const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 15px;
    width: 20px;
    font-size: 12px;
`

export const GreenClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-right: 15px;
    width: 20px;
    font-size: 12px;
`

export const TagText = styled.div`
    font-size: 10px;
    flex: 1;
`

export const Position = styled.div<{ hasPosition?: boolean }>`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, hasPosition }) => (hasPosition ? theme.colors.primary : theme.colors.white)};
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    margin-left: 20px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.gray};
    position: absolute;
    top: 38.5%;
    right: 20px;
    font-size: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        position: relative;
        top: unset;
        right: unset;
    }
`
