import { memo, useEffect, useRef } from 'react'
import * as Feather from 'react-feather'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'

import { getFormInputError, getFormattedDateFilters, setSearchParams } from 'helpers'
import { useAuth } from 'hooks'

import {
    ButtonsContainer,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    InputsRow,
    MiddleContainer,
    Row,
    PeriodButton,
} from './import-header.styled'

export interface ImportFilters {
    start_date?: string
    end_date?: string
    current_page?: number
    status?: 'draft' | 'run' | 'completed'
}

interface Props {
    title?: string
    filters?: ImportFilters
    onFilterData?(filters: ImportFilters): void
    onImportClick(): void
}

const OPTIONS = [
    { label: 'Rascunho', value: 'draft' },
    {
        label: 'Processando',
        value: 'run',
    },
    {
        label: 'Sucesso',
        value: 'completed',
    },
]

const ImportHeader: React.FC<Props> = memo(({ title, filters, onFilterData, onImportClick }) => {
    const history = useHistory()
    const { store } = useAuth()
    const gambiRef = useRef(true)

    const { errors, getFieldProps, handleSubmit, touched, setFieldValue, setValues, values } = useFormik({
        initialValues: {
            start_date: '',
            end_date: '',
            status: undefined,
            ...filters,
        },
        validationSchema: Yup.object().shape({
            end_date: Yup.string().when(['start_date'], {
                is: startDate => !!startDate,
                then: schema => schema.required('Selecione a data final'),
                otherwise: schema => schema.optional(),
            }),
        }),
        onSubmit: async values => {
            const data = {
                ...values,
                store_id: store?.id,
                start_date: values.start_date ? format(values.start_date, 'YYYY-MM-DD') : undefined,
                end_date: values.end_date ? format(values.end_date, 'YYYY-MM-DD') : undefined,
                current_page: 1,
            }
            gambiRef.current = true
            setSearchParams(data, location, history)
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    useEffect(() => {
        const query = new URLSearchParams(location.search)

        if (onFilterData) {
            const data = {
                ...values,
                store_id: store?.id,
                start_date: query.get('start_date') || undefined,
                end_date: query.get('end_date') || undefined,
                status: (query.get('status') || undefined) as any,
                current_page: query.get('current_page') ? Number(query.get('current_page')) : 1,
            }
            if (gambiRef.current) {
                gambiRef.current = false
                setValues(data)
                onFilterData(data)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{title}</HeaderTitle>
                <MiddleContainer>
                    <InputsRow>
                        <InputContainer>
                            <InputItem
                                labelText="Status"
                                type="select"
                                options={OPTIONS}
                                inputProps={{
                                    ...getFieldProps('status'),
                                    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('status', value === 'undefined' ? undefined : value)
                                    },
                                }}
                                errorMessage={getFormInputError('status', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Data inicial"
                                type="date"
                                inputProps={getFieldProps('start_date')}
                                errorMessage={getFormInputError('start_date', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Data final"
                                type="date"
                                inputProps={getFieldProps('end_date')}
                                errorMessage={getFormInputError('end_date', errors, touched)}
                            />
                        </InputContainer>
                        <DefaultButton title="Consultar" onClick={() => handleSubmit()} widthFitContainer />
                    </InputsRow>
                    <ButtonsContainer>
                        <Row>
                            {periodFilters.map((filter, filterIndex) => (
                                <PeriodButton key={filterIndex} onClick={filter.onClick}>
                                    {filter.label}
                                </PeriodButton>
                            ))}
                        </Row>
                        <DefaultButton onClick={onImportClick}>
                            <Feather.Share /> Importar Pedidos
                        </DefaultButton>
                    </ButtonsContainer>
                </MiddleContainer>
            </HeaderContainer>
        </>
    )
})

export { ImportHeader }
