import { memo } from 'react'

import colors from '../../themes/colors'
import MensagemErro from '../mensagem-erro'
export interface SelectItems {
    id?: number | string
    label?: string
    name?: string
    value?: string | number
}

interface Props {
    data?: SelectItems[]
    colorLabel?: string
    labelInput?: string
    bgColor?: string
    color?: string
    borderColor?: string
    onChange(e: React.ChangeEvent<HTMLSelectElement>): void
    value: string | number
    placeholder?: string
    msgErro?: string | boolean
    noPlaceholder?: boolean
}

const SelectInput: React.FC<Props> = ({
    data,
    colorLabel,
    labelInput,
    bgColor,
    color,
    borderColor,
    noPlaceholder,
    onChange,
    value,
    placeholder,
    msgErro,
}) => {
    return (
        <div className="item-input">
            <label style={{ color: colorLabel }}>{labelInput}</label>
            <select
                style={{
                    backgroundColor: bgColor ? bgColor : '#fff',
                    color: color ? color : '#000',
                    borderColor: borderColor ? borderColor : colors.light_gray_color,
                }}
                onChange={onChange}
                value={value || ''}
            >
                {!noPlaceholder && <option value={''}>{placeholder || 'Selecionar'}</option>}
                {data?.map((item, index) => (
                    <option key={index} value={item.value || item.id}>
                        {item.name || item.label}
                    </option>
                ))}
            </select>
            <MensagemErro msgErro={msgErro} />
        </div>
    )
}

export default memo(SelectInput)
