import styled from 'styled-components'

export const ProgressCardContainer = styled.div`
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    display: inline-flex;
    flex-direction: column;
    height: 500px;
    width: 350px;
    margin-bottom: 10px;
    border-radius: 12px;
    &:not(:last-child) {
        margin-right: 20px;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 550px;
        height: 63vh;
    }

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: 68vh;
    }

    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        height: 75vh;
    }
`

export const CardHeader = styled.div`
    display: flex;
    background-color: #f7f7f7;
    padding: 10px 20px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
`

export const CardTitle = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 18px;
    }
`

export const CardNumber = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c6c6c6;
    font-weight: 600;
    font-size: 14px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
`

export const OrdersList = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
`

export const EmptyMessageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const EmptyIcon = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 20px;
`

export const EmptyText = styled.div`
    font-size: 16px;
    color: #666;
    width: 200px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    white-space: normal;
    text-align: center;
`

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 20px 10px;
    span {
        &:last-child {
            width: 95%;
        }
    }
`
