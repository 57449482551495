import { useMemo } from 'react'

import { format } from 'date-fns'

import { LoadButton } from 'containers/mercadoo-bags-listing/mercadoo-bags-listing.styles'

import { formatCurrency, formatDistance } from 'helpers'
import { Bag, IBagItem } from 'types'

import {
    CustomerAddress,
    CustomerName,
    DetailsItem,
    OrderClient,
    OrderId,
    OrderSmallValue,
    OrderStore,
    OrderTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    ItemButton,
    ButtonText,
    EmptyMessageContainer,
    EmptyImage,
    EmptyMessage,
    LoadingIcon,
    Transaction,
    ItemLarge,
} from './bags-listing-table.styles'

interface Props {
    bags?: IBagItem[]
    detailClick(id: number): void
    loadMoreClick(): void
    loading: boolean
    totals: number
    hasMore?: boolean
}

const BagsListingTable: React.FC<Props> = ({ bags, detailClick, loadMoreClick, loading, totals, hasMore }) => {
    return (
        <TableContainer>
            <OrderTable>
                <TableHeader>
                    <OrderId>ID</OrderId>
                    <OrderSmallValue>Recebido</OrderSmallValue>
                    <OrderStore>Loja</OrderStore>
                    <OrderClient>Cliente</OrderClient>
                    <OrderSmallValue>Status</OrderSmallValue>
                    <OrderSmallValue>Tipo</OrderSmallValue>
                    <ItemLarge>Valor</ItemLarge>
                    <OrderSmallValue></OrderSmallValue>
                </TableHeader>
                <TableContent className="table-content">
                    {bags ? (
                        <>
                            {bags?.length > 0 ? (
                                <>
                                    {bags?.map((bag, index) => (
                                        <TableItem key={index}>
                                            <OrderId>
                                                <span>ID:</span> {bag.id}
                                            </OrderId>
                                            <OrderSmallValue>
                                                <span>Recebido:</span>
                                                <div>{format(new Date(bag.created_at), 'DD/MM/YYYY')}</div>
                                                <div>{format(new Date(bag.created_at), 'HH:mm')}</div>
                                            </OrderSmallValue>
                                            <OrderStore>
                                                <span>Loja:</span>
                                                {bag.stores.map((store, i) => (
                                                    <div key={i}>{store}</div>
                                                ))}
                                            </OrderStore>

                                            <OrderClient>
                                                <CustomerName>
                                                    <span>Cliente:</span>
                                                    {bag.customer.full_name}
                                                </CustomerName>
                                                <CustomerAddress>
                                                    <span>Contato:</span>
                                                    {bag.customer.phone}
                                                </CustomerAddress>
                                            </OrderClient>

                                            <OrderSmallValue>
                                                <span>Status:</span>
                                                {bag.payment_method}
                                            </OrderSmallValue>
                                            <OrderSmallValue>
                                                <span>Tipo:</span>
                                                {getTypeName(bag.type)}
                                            </OrderSmallValue>
                                            <ItemLarge>
                                                <span>Valor:</span>
                                                {formatCurrency(bag.total)}
                                                <Transaction>{bag.transaction_id}</Transaction>
                                            </ItemLarge>

                                            <OrderSmallValue onClick={() => bag.id && detailClick(bag?.id)}>
                                                <ItemButton>
                                                    <DetailsItem />
                                                    <ButtonText>Ver detalhes</ButtonText>
                                                </ItemButton>
                                            </OrderSmallValue>
                                        </TableItem>
                                    ))}
                                    <>
                                        {totals > 0 ? (
                                            <>
                                                {!!bags && bags.length !== totals && (
                                                    <LoadButton onClick={loadMoreClick}>
                                                        {loading ? 'Carregando...' : 'Carregar Mais'}
                                                    </LoadButton>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {hasMore && (
                                                    <LoadButton onClick={loadMoreClick}>
                                                        {loading ? 'Carregando...' : 'Carregar Mais'}
                                                    </LoadButton>
                                                )}
                                            </>
                                        )}
                                    </>
                                </>
                            ) : (
                                <EmptyMessageContainer>
                                    <EmptyImage />
                                    <EmptyMessage>Nenhum relatório localizado</EmptyMessage>
                                </EmptyMessageContainer>
                            )}
                        </>
                    ) : (
                        <EmptyMessageContainer>
                            <LoadingIcon />
                            <EmptyMessage>Carregando informações...</EmptyMessage>
                        </EmptyMessageContainer>
                    )}
                </TableContent>
            </OrderTable>
        </TableContainer>
    )
}

function getTypeName(type: 'takeout' | 'delivery' | 'indoor') {
    return {
        takeout: 'Balcão',
        delivery: 'Delivery',
        indoor: 'Mesa',
    }[type]
}

export default BagsListingTable
