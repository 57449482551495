import './style.scss'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import { formatAddress, getTimeDiff, getStatusOrder } from '../../helpers'
import EmptyMessage from '../empty-message/empty-message'

const DelivererQueueTable = ({ orders }) => {
    return (
        <div className="deliverer-queue-table">
            <div className="table-row first">
                <div className="table-item id">ID do pedido</div>
                <div className="table-item client">Cliente e endereço</div>
                <div className="table-item order">Pedido feito em</div>
                <div className="table-item status">Status</div>
                <div className="table-item total">Tempo total</div>
                <div className="table-item last"></div>
            </div>

            {orders.map(item => (
                <div className="table-row" key={item.id}>
                    <div className="table-item id">
                        <span className="mobile-tag">Número do Pedido: </span>
                        {item.id}
                    </div>
                    <div className="table-item client">
                        <div className="name">
                            <span className="mobile-tag">Cliente: </span>
                            {item.customer.name}
                        </div>
                        <div className="address">
                            <span className="mobile-tag">Endereço: </span>
                            {formatAddress(item.address)}
                        </div>
                    </div>
                    <div className="table-item order">
                        <span className="mobile-tag">Pedido feito em: </span>
                        {format(item.birth, 'DD/MM/YYYY [às] HH:mm')}
                    </div>
                    <div className="table-item status">
                        <span className="mobile-tag">Status: </span>
                        {getStatusOrder(item.status)}
                    </div>
                    <div className="table-item total">
                        <span className="mobile-tag">Tempo total: </span>
                        {getTimeDiff(item.birth).text}
                    </div>
                    <div className="table-item last">
                        <Link to={`detalhes-pedido/${item.id}`}>
                            <FontAwesomeIcon icon="info-circle" />
                            <span className="mobile-tag">Ver detalhes</span>
                        </Link>
                    </div>
                </div>
            ))}

            {orders.length === 0 && <EmptyMessage icon="biking">Não foram encontrados pedidos em rota</EmptyMessage>}
        </div>
    )
}

export default DelivererQueueTable
