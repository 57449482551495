import { useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import { getSortedItems, groupItemsByKey } from 'helpers'
import { useAuth } from 'hooks'
import colors from 'themes/colors'
import { Order, PagedList } from 'types'

import EmptyMessage from '../empty-message/empty-message'
import OperationRow from '../operation-row/operation-row'
import OrderRow from '../order-row/order-row'

import './style.scss'

interface Props {
    loading: boolean
    storeFilterEnabled: boolean
    ordersScheduled: PagedList<Order>
    clicked: number
}

const OperationListingScheduled: React.FC<Props> = ({ loading, ordersScheduled, clicked }) => {
    const { basePath } = useAuth()
    const history = useHistory()

    const [scheduledSorting, setScheduledSorting] = useState('2')

    const groupedOrders = useMemo(() => {
        const items = getSortedItems(ordersScheduled.items, scheduledSorting || '2')

        return groupItemsByKey(items, 'delivery_forecast_hour', (item: Order) => ({
            ...item,
            delivery_forecast_hour: format(item.delivery_forecast, 'DD/MM/YYYY - HH:00'),
        }))
    }, [ordersScheduled.items, scheduledSorting])

    return (
        <div className="operation-listing-scheduled-container">
            <OperationRow
                iconName={faCalendarAlt}
                loading={loading}
                status={1}
                columnTitle={`${(ordersScheduled?.items || []).length} agendados`}
                setSorting={setScheduledSorting}
                sorting={scheduledSorting}
                style={{ height: '79vh' }}
            >
                {groupedOrders.map(group => (
                    <>
                        <div className="group-title-container">
                            <FontAwesomeIcon icon="caret-square-down" />
                            <span>{group.title}</span>
                        </div>

                        {group.items.map((item: Order) => (
                            <OrderRow
                                key={item.id}
                                orderId={item.id}
                                orderNumber={item.reference_id}
                                orderValue={item.total_price}
                                salesChannel={item.reference_name}
                                paymentName={item.payment?.name}
                                restaurantName={item.merchant.name}
                                deliveryForecast={item.delivery_forecast}
                                clientName={item.customer.name}
                                neighborhood={item.address?.neighborhood}
                                collectName={item.delivery_origin?.name}
                                street={item.address?.street}
                                number={item.address?.number}
                                distance={item.distance}
                                status={item.status}
                                statusColor={colors.light_gray_color}
                                routeClick={() => history.push(`${basePath}/rotas/criar-rota`, { initial: item })}
                                detailClick={() => history.push(`${basePath}/detalhes-pedido/${item.id}`)}
                                clicked={item.id === clicked}
                            />
                        ))}
                    </>
                ))}

                {(ordersScheduled?.items || []).length === 0 && !loading && (
                    <div className="empty-message-container">
                        <EmptyMessage icon="concierge-bell">Não há agendados no momento</EmptyMessage>
                    </div>
                )}
            </OperationRow>
        </div>
    )
}

export default OperationListingScheduled
