import { memo } from 'react'

import { InputItem } from 'components/_common'
import { DefaultButton } from 'components/default-button/default-button'
import { Grid } from 'components/grid/grid'
import { Text } from 'components/text/text'

import { getFormInputError } from 'helpers'

import { useDeliveryContent } from './delivery-content.controller'
import { Container, Content, ContentMap, ContentForm, InputsRow, InputContainer } from './delivery-content.styled'

const DeliveryContent = memo(() => {
    const {
        mapContainerRef,
        values,
        errors,
        touched,
        isValid,
        _findAddress,
        getAddressByCEP,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        _close,
    } = useDeliveryContent()

    return (
        <>
            <Container>
                <Text
                    text="Preencha os dados faltantes para a alteração de tipo de entrega para:"
                    marginBottom={10}
                    light
                    dimensions="xxSmall"
                />
                <Text text="Delivery" variant="primary" bold dimensions="large" marginBottom={20} />
                <Content>
                    <ContentMap ref={mapContainerRef}></ContentMap>
                    <ContentForm>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="*CEP"
                                    inputProps={{
                                        ...getFieldProps('zipcode'),
                                        mask: '99999-999',
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            getAddressByCEP(value.replace(/\D/g, ''))
                                            setFieldValue('zipcode', value.replace(/\D/g, ''))
                                        },
                                    }}
                                    errorMessage={getFormInputError('zipcode', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="*Bairro"
                                    inputProps={{ ...getFieldProps('neighborhood') }}
                                    errorMessage={getFormInputError('neighborhood', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="*Rua"
                                    inputProps={{ ...getFieldProps('street') }}
                                    errorMessage={getFormInputError('street', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="*Número"
                                    inputProps={{ ...getFieldProps('number') }}
                                    errorMessage={getFormInputError('number', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="Complemento"
                                    inputProps={{ ...getFieldProps('complement') }}
                                    errorMessage={getFormInputError('complement', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Referência"
                                    inputProps={{ ...getFieldProps('landmark') }}
                                    errorMessage={getFormInputError('landmark', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                        <InputsRow>
                            <InputContainer>
                                {!!values.city &&
                                    !!values.state &&
                                    !!values.zipcode &&
                                    !!values.street &&
                                    !!values.number && (
                                        <Text
                                            text="Localizar no Mapa"
                                            variant="primary"
                                            dimensions="medium"
                                            link
                                            onClick={_findAddress}
                                        />
                                    )}
                            </InputContainer>
                            <InputContainer />
                        </InputsRow>
                    </ContentForm>
                </Content>
                <Grid.Row className="container-buttons" justify="space-between" align="center">
                    <DefaultButton title="Cancelar" outline variant="danger" onClick={_close} />
                    <DefaultButton title="Alterar" disabled={!isValid} onClick={handleSubmit} />
                </Grid.Row>
            </Container>
        </>
    )
})

export { DeliveryContent }
