import { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { ManagerStaffModal } from 'modals/manager-staff-modal/manager-staff-modal'
import * as Yup from 'yup'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import EmptyMessage from 'components/empty-message/empty-message'
import ModalChangePassword from 'components/modal-change-password'
import ModalConfirmation from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage from 'components/modal-message'
import SelectInput from 'components/select-input'
import Spinner from 'components/spinner/spinner'
import { StaffListingItem, StaffListingHeader } from 'components/staff-listing-item/staff-listing-item'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { getRoles, setSearchParams } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'

import './staffs-page.scss'

const StaffsPage = ({ history, location }) => {
    const { basePath, mall, user } = useAuth()

    const modalMessage = useRef()
    const modalDelete = useRef()
    const modalChangePassword = useRef()
    const managerStaffModalRef = useRef()

    const initialFilter = useMemo(() => {
        const query = new URLSearchParams(location.search)

        return {
            role: query.get('role') || '',
            status: query.get('status') || '1',
            store: query.get('store') || '',
        }
    }, [location.search])

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [stores, setStores] = useState([])
    const [staffs, setStaffs] = useState({ items: [], current_page: 1 })
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [deleteData, setDeleteData] = useState({ id: null, first_name: '', last_name: '' })
    const [form, setForm] = useState({ first_name: '', last_name: '', email: '', status: 1 })
    const [filter, setFilter] = useState(initialFilter)

    const { errors, handleSubmit, resetForm, setFieldValue, touched, values } = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().min(8, 'Deve ter no mínimo 8 caracteres').trim().required('Insira a Senha'),
            password_confirmation: Yup.string()
                .trim()
                .oneOf([Yup.ref('password'), ''], 'Senhas devem ser iguais')
                .required('Confirme a Senha'),
        }),
        onSubmit: async values => {
            try {
                setLoadingSubmit(true)

                await api.put(`painel/staff/${form.id}`, {
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                })

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Senha atualizada com sucesso!',
                    onClose: () => modalChangePassword.current.closeModal(),
                })

                modalMessage.current.openModal()

                resetForm()
            } catch (error) {
                console.log({ error })

                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível atualizar a senha do lojista. Tente novamente mais tarde.',
                })

                modalMessage.current.openModal()
            } finally {
                setLoadingSubmit(false)
            }
        },
    })

    const _getFilteredStaffs = useCallback(data => {
        const availableRoles = getRoles().map(item => item.name)
        return data.filter(item => availableRoles.includes(item.role[0]))
    }, [])

    const getStaffs = useCallback(
        async ({ current_page = 1, staffs = { items: [] }, filter = initialFilter } = {}) => {
            try {
                setLoading(true)

                const filters = {
                    role: !isNaN(filter.role) ? filter.role : null,
                    store: !isNaN(filter.store) ? filter.store : null,
                    status: filter.status && !isNaN(filter.status) ? [filter.status] : null,
                }

                const { data } = await api.get('/painel/staffs', {
                    params: {
                        current_page,
                        mall: mall.id,
                        ...filters,
                    },
                })

                const filteredData = _getFilteredStaffs(data.items)

                setStaffs({
                    ...data,
                    items: current_page > 1 ? [...staffs.items, ...filteredData] : filteredData,
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar a lista de lojistas.',
                    onClose: () => history.push(`${basePath}/gestao-mall`),
                })

                modalMessage.current.openModal()
            } finally {
                setLoading(false)
            }
        },
        [initialFilter, history, mall]
    )

    useEffect(() => {
        getStaffs()
    }, [getStaffs])

    const getStores = useCallback(async () => {
        try {
            setLoading(true)
            const {
                data: { items },
            } = await api.get('/painel/stores-to-select', {
                params: {
                    mall_id: mall.id,
                    order_by: 'name',
                },
            })

            setStores(items)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar a lista de lojas.',
                onClose: () => history.push(`${basePath}/gestao-mall`),
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }, [history, mall])

    useEffect(() => {
        getStores()
    }, [getStores])

    const deleteStaff = async () => {
        try {
            setLoadingSubmit(true)

            await api.delete(`/painel/administrator/${deleteData.id}/`)

            setStaffs({ ...staffs, items: staffs.items.filter(item => item.id !== deleteData.id) })

            setModalMessageData({
                title: 'Sucesso',
                message: 'Lojista excluído com sucesso!',
            })

            modalMessage.current.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível excluir os dados do lojista. Tente novamente mais tarde.',
                onClose: () => history.push(`${basePath}/gestao-mall`),
            })

            modalMessage.current.openModal()
        } finally {
            setLoadingSubmit(false)
        }
    }

    const _openManagerStaff = useCallback(
        staff => {
            const callback = (type, staff) => {
                if (type === 'add') {
                    setStaffs({
                        ...staffs,
                        items: [...staffs.items, staff],
                    })
                }
                if (type === 'update') {
                    setStaffs({
                        ...staffs,
                        items: staffs.items.map(item => {
                            if (item.id === staff?.id) {
                                return staff
                            }
                            return item
                        }),
                    })
                }
            }
            managerStaffModalRef.current?.show({ staff, onChange: callback })
        },
        [staffs]
    )

    const setFilterParam = (key, value) => {
        setFilter({ ...filter, [key]: value })
    }

    return (
        <div className="page-container staffs-page">
            <ModalLoading visible={loadingSubmit || (loading && staffs.items.length)} />
            <ManagerStaffModal ref={managerStaffModalRef} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Lojista"
                message={`Deseja mesmo excluir o usuário ${deleteData.first_name} ${deleteData.last_name} da lista de lojistas?`}
                onYes={deleteStaff}
            />

            {/* <ModalCreateStaff
                ref={modalCreate}
                user={form}
                stores={stores}
                title={
                    form.id && form.id === user.id
                        ? 'Editar meus dados'
                        : form.id && form.id !== user.id
                        ? 'Editar lojista'
                        : 'Criar novo lojista'
                }
                subtitle={
                    form.id && form.id !== user.id && `Você está editando: ${form.first_name} ${form.last_name || ''}`
                }
                setLoadingSubmit={setLoadingSubmit}
                staffs={staffs}
                setStaffs={setStaffs}
                modalMessageRef={modalMessage}
                setModalMessageData={setModalMessageData}
                isCreation={!form.id}
                onClose={() => setForm({})}
            /> */}

            <ModalChangePassword
                ref={modalChangePassword}
                user={values}
                title={form.id === user.id ? 'Alterar minha senha' : 'Alterar senha'}
                subtitle={
                    form.id && form.id !== user.id && `Você está editando: ${form.first_name} ${form.last_name || ''}`
                }
                setUserData={setFieldValue}
                onYes={handleSubmit}
                onClose={resetForm}
                errors={errors}
                touched={touched}
            />

            <TopRowDefault
                onBackButtonClick={() => history.push(`${basePath}/gestao-mall`)}
                title="Gerenciar Lojistas"
            />

            <div className="content-container">
                <div className="left-column">
                    <div className="title-row">
                        <div className="section-title">Lojistas</div>
                        <div className="add-button" onClick={() => _openManagerStaff()}>
                            Adicionar lojista <FontAwesomeIcon icon="plus-circle" />
                        </div>
                    </div>
                    <div className="filter-row">
                        <div className="filter-item">
                            <SelectInput
                                placeholder="Todas as lojas"
                                data={stores}
                                onChange={({ target: { value } }) => setFilterParam('store', value)}
                                value={filter.store || ''}
                            />
                        </div>
                        <div className="filter-item">
                            <SelectInput
                                placeholder="Todas as funções"
                                data={getRoles()}
                                onChange={({ target: { value } }) => setFilterParam('role', value)}
                                value={filter.role || ''}
                            />
                        </div>
                        <div className="filter-item">
                            <SelectInput
                                noPlaceholder
                                data={[
                                    { name: 'Ativo', value: '1' },
                                    { name: 'Inativo', value: '0' },
                                ]}
                                onChange={({ target: { value } }) => setFilterParam('status', value)}
                                value={filter.status || ''}
                            />
                        </div>

                        <div className="filter-button" onClick={() => setSearchParams(filter, location, history)}>
                            Filtrar
                        </div>
                    </div>
                    <div className="totals">
                        Sua busca retornou <span>{staffs?.totals}</span>{' '}
                        {staffs?.totals > 1 ? 'resultados' : 'resultado'}
                    </div>

                    <StaffListingHeader />
                    <div className="listing">
                        {loading && !staffs.items.length ? (
                            <div className="loader-container">
                                <Spinner />
                            </div>
                        ) : staffs.items.length ? (
                            staffs.items.map((item, index) => (
                                <StaffListingItem
                                    key={index}
                                    id={item.id}
                                    stores={item.stores}
                                    name={`${item.first_name} ${item.last_name || ''}`}
                                    email={item.email}
                                    roles={item.role}
                                    status={item.status}
                                    editClick={() => {
                                        _openManagerStaff(item)
                                    }}
                                    passwordClick={() => {
                                        setForm(item)

                                        modalChangePassword.current.openModal()
                                    }}
                                    deleteClick={() => {
                                        setDeleteData(item)

                                        modalDelete.current.openModal()
                                    }}
                                />
                            ))
                        ) : (
                            <EmptyMessage icon="user-friends">Não há lojistas cadastrados.</EmptyMessage>
                        )}

                        <ButtonLoadMore
                            loading={loading}
                            visible={staffs.totals > staffs.items.length}
                            onClick={() => getStaffs({ current_page: ++staffs.current_page, staffs, filter })}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaffsPage
