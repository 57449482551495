import { forwardRef, useRef, useImperativeHandle } from 'react'

import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'

import EmptyMessage from 'components/empty-message/empty-message'
import { ItemProblemCard } from 'components/items'
import ModalLoading from 'components/modal-loading'

import { useAuth, useDataFetching } from 'hooks'
import { PagedList, Order } from 'types'

import { ContainerCardListing } from './delivery-problems-modal.styled'

type DeliveryProblemsModal = {
    show?(): void
    close?(): void
}
interface DeliveryProblemsModalProps {
    onEditClick?(order: Order): void
}
// eslint-disable-next-line react/display-name
const DeliveryProblemsModal = forwardRef<LateralModalBase, DeliveryProblemsModalProps>(({ onEditClick }, ref) => {
    const { mall } = useAuth()

    const lateralModalBaseRef = useRef<LateralModalBase | null>(null)

    const {
        revalidate,
        data: orders,
        loading,
    } = useDataFetching<PagedList<Order>>('/painel/dm-orders', {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        params: {
            mall_id: mall.id,
            per_page: -1,
            status: ['10', '10A', '10B', '10C', '10D', '10E', '10F', '10G', '10H'],
        },
    })

    useImperativeHandle(
        ref,
        () => ({
            show: () => {
                revalidate()
                lateralModalBaseRef.current?.show()
            },
            close: () => {
                lateralModalBaseRef.current?.close()
            },
        }),
        [lateralModalBaseRef, revalidate]
    )

    return (
        <LateralModalBase ref={lateralModalBaseRef} title="Problema na entrega" refresh={revalidate}>
            {!!orders && (
                <ContainerCardListing>
                    {!orders.items.length ? (
                        <div className="empty-container">
                            <EmptyMessage icon="shopping-bag">Não há pedidos com problema no momento</EmptyMessage>
                        </div>
                    ) : (
                        orders.items.map((item, index) => (
                            <ItemProblemCard
                                key={index}
                                order={item}
                                onEditClick={e => {
                                    if (onEditClick) {
                                        onEditClick(e)
                                    }
                                    lateralModalBaseRef.current?.close()
                                }}
                            />
                        ))
                    )}
                </ContainerCardListing>
            )}
            <ModalLoading visible={loading} />
        </LateralModalBase>
    )
})

export default DeliveryProblemsModal
