import React, { memo, useMemo } from 'react'

import { useAuth } from 'hooks'
type Role = 'superadmin' | 'admin' | 'manager' | 'financial' | 'owner' | 'staff' | 'biker'
type Props = {
    roles: Role[]
    children: any
    type?: 'show' | 'hide'
}

const AccessComponent: React.FC<Props> = memo(({ children, roles, type = 'show' }) => {
    const { user } = useAuth()

    const hasRole = useMemo(() => {
        const myRoles = user?.roles || []
        for (let i = 0; i < myRoles.length; i++) {
            const role = myRoles[i]
            if (roles.includes(role.role)) {
                return true
            }
        }
        return false
    }, [roles, user])

    if (type === 'show' && !hasRole) {
        return null
    }

    if (type === 'hide' && hasRole) {
        return null
    }

    return <>{children || null}</>
})

export { AccessComponent }
