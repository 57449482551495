import { useState } from 'react'

import {
    ContentContainer,
    ContentTitle,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
    TitleContainer,
    OrderStatus,
    StatusCircle,
    StatusTitle,
    TabsContainer,
    TabItem,
} from './collect-details-content.styles'
import DetailsTab from './details-tab/details-tab'
import HistoryTab from './history-tab/history-tab'

interface Props {
    closeClick(): void
}

const CollectDetailsContent: React.FC<Props> = ({ closeClick }) => {
    const [isHistoryActive, setIsHistoryActive] = useState(true)
    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={closeClick}>
                    <CloseButton />
                </CloseContainer>
                <TitleContainer>
                    <OrderStatus>
                        <StatusCircle status={1} />
                        <StatusTitle>Aguardando coleta</StatusTitle>
                    </OrderStatus>
                    <ContentTitle>Pedido #12345</ContentTitle>
                </TitleContainer>
                <TabsContainer>
                    <TabItem isActive={!isHistoryActive} onClick={() => setIsHistoryActive(false)}>
                        Informações gerais
                    </TabItem>
                    <TabItem isActive={isHistoryActive} onClick={() => setIsHistoryActive(true)}>
                        Histórico
                    </TabItem>
                </TabsContainer>
                {isHistoryActive ? <HistoryTab /> : <DetailsTab />}
            </ContentContainer>
            <ButtonRow>
                <TextButton onClick={closeClick}>Cancelar pedido</TextButton>
                <ConfirmButton>Editar pedido</ConfirmButton>
            </ButtonRow>
        </OutsideContainer>
    )
}

export default CollectDetailsContent
