import { faSearch, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const TopRowContainer = styled.div`
    padding: 20px 20px;
    box-shadow: 1px 0px 20px 0px rgba(0, 0, 0, 0.1);
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        justify-content: space-between;
    }
`

export const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
    }
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`

export const SearchContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 200px;
`

export const SearchInput = styled.input`
    border: 0;
    outline: none;
    flex: 1;
`

export const SearchIcon = styled(FontAwesomeIcon).attrs({
    icon: faSearch,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    margin-left: 10px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }
`

export const UserIcon = styled(FontAwesomeIcon).attrs({
    icon: faExchangeAlt,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 24px;
    margin-left: 20px;
    cursor: pointer;
`
