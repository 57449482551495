import InfoItem from 'components/_new-general-vision/info-item/info-item'

import {
    DetailsTabContainer,
    PhoneIcon,
    PickerContainer,
    PickerName,
    PickerPhone,
    PickerPhoto,
    PickerTextContainer,
    PickerTitle,
    InfoSection,
    SectionTitle,
    InfoRow,
} from './details-tab.styles'

const DetailsTab: React.FC = () => {
    return (
        <DetailsTabContainer>
            <PickerContainer>
                <PickerPhoto src={'https://i.pravatar.cc/150?img=56'} />
                <PickerTextContainer>
                    <PickerTitle>Responsável pela coleta</PickerTitle>
                    <PickerName>Samuel Batista Paixão</PickerName>
                    <PickerPhone>
                        <PhoneIcon /> (85) 91234 5678
                    </PickerPhone>
                </PickerTextContainer>
            </PickerContainer>
            <InfoSection>
                <SectionTitle>Informações Sobre o Pedido</SectionTitle>
                <InfoRow>
                    <InfoItem label="Canal de venda" content="Ifood" />
                    <InfoItem label="Número do Pedido" content="123456" />
                </InfoRow>
                <InfoRow>
                    <InfoItem label="Nome do Cliente" content="Yan Gondim" />
                    <InfoItem label="Bairro de Entrega" content="Aldeota" />
                </InfoRow>
                <InfoRow>
                    <InfoItem label="Forma de Pagamento" content="Pagamento Online" />
                    <InfoItem label="Valor do Pedido" content="R$ 35,00" />
                </InfoRow>
            </InfoSection>
            <InfoSection>
                <SectionTitle>Itens e Observações</SectionTitle>
                <InfoItem label="Itens" contentList={['1x Pizza Calabresa', '1x Coca-cola 2L']} />
                <InfoItem
                    label="Observações de coleta"
                    content="Entregar para o entregador de blusa preta e máscara azul"
                />
            </InfoSection>
        </DetailsTabContainer>
    )
}

export default DetailsTab
