import { useCallback, useEffect, useState } from 'react'
import * as Feather from 'react-feather'
import { useHistory, useParams } from 'react-router-dom'

import { format } from 'date-fns'

import { Info } from 'components/info/info'
import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'
import { TableOrderRomaneio } from 'components/tables/table-order-romaneio/table-order-romaneio'

import { plugApi } from 'services/api'
import { IRomaneio, ITableRomaneio } from 'types/romaneio'

import { Container, Header, Content, HeaderTitle, Row, ContainerTitle } from './view-romaneio-page.styled'

type Params = {
    id: string
}

const ViewRomaneioPage = () => {
    const { id } = useParams() as Params
    const history = useHistory()

    const [rows, setRows] = useState<ITableRomaneio[]>([])
    const [romaneio, setRomaneio] = useState<IRomaneio>()
    const [loading, setLoading] = useState(true)

    const _getRomaneio = useCallback(async (id: string) => {
        console.log('aaaaaaa', id)

        setLoading(true)
        try {
            const { data } = await plugApi.get<IRomaneio>(`/webhooks/${id}`)
            setRomaneio(data)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (!romaneio) {
            return
        }
        const rows: ITableRomaneio[] = []
        romaneio.data.content.rotas.forEach(rota => {
            rota.entregas.forEach(entrega => {
                entrega.pedidos.forEach(pedido => {
                    rows.push({
                        cod: pedido.codigo,
                        name: entrega.cliente.nome,
                        address:
                            (entrega.cliente.endereco || '') +
                            '\n' +
                            (entrega.cliente.numeroEndereco || '') +
                            '\n' +
                            (entrega.cliente.observacao || ''),
                        cep: entrega.cliente.cep,
                        city: entrega.cliente.cidade,
                        state: entrega.cliente.estado,
                        neighborhood: entrega.cliente.bairro,
                        dimensions: pedido.notasFiscais.map(e => e.pesoCubado).join('\n'),
                        volume: pedido.notasFiscais.map(e => e.volume).join('\n'),
                        value: pedido.notasFiscais.map(e => e.valor).join('\n'),
                        weight: pedido.notasFiscais.map(e => e.peso).join('\n'),
                        payment: pedido.notasFiscais.map(e => e.condicaoPagamento).join('\n'),
                        operation: pedido.notasFiscais.map(e => e.tipoOperacao).join('\n'),
                    })
                })
            })
        })
        console.log('rows', rows)

        setRows(rows)
    }, [romaneio])

    useEffect(() => {
        console.log('id', id)

        _getRomaneio(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <>
            {!!romaneio && romaneio?.data?.content?.rotas?.length > 0 && (
                <Container>
                    <Header>
                        <ContainerTitle>
                            <Feather.ArrowLeft className="arrow" onClick={() => history.goBack()} />
                            <HeaderTitle>Pré - Visualização</HeaderTitle>
                        </ContainerTitle>
                        <Row>
                            <Info label="Empresa" value={romaneio.data.content.empresa} />
                            <Info label="Nome da rota" value={romaneio.data.content.rotas[0].nomeRota} />
                            <Info
                                label="Data da rota"
                                value={format(new Date(romaneio.data.content.rotas[0].dataRota), 'DD/MM/YYYY HH:mm')}
                            />
                            <Info
                                label="Nome da transportadora"
                                value={romaneio.data.content.rotas[0].transportadora.nome}
                            />
                            <Info label="Nome Motorista" value={romaneio.data.content.rotas[0].motorista.nome} />
                            <Info label="Quantidade de AWBs" value={romaneio.total_awb} />
                            <Info label="Status" {...getProps(romaneio.status)} />
                        </Row>
                    </Header>
                    <Content>
                        <Scroll>
                            <TableOrderRomaneio data={rows} />
                        </Scroll>
                    </Content>
                </Container>
            )}
            <ModalLoading visible={loading} />
        </>
    )
}

function getProps(type: 'received' | 'accept' | 'reject' | 'finished'): {
    value: string
    variant?: 'success' | 'danger' | 'primary' | 'alert'
} {
    switch (type) {
        case 'received':
            return {
                value: 'Recebido',
            }
        case 'accept':
            return {
                value: 'Aceito',
                variant: 'success',
            }
        case 'reject':
            return {
                value: 'Rejeitado',
                variant: 'danger',
            }
        case 'finished':
            return {
                value: 'Encerrado',
            }
    }

    return {
        value: '',
    }
}

export { ViewRomaneioPage }
