import { createReducer, createActions } from 'reduxsauce'

import {
    Actions,
    SetAgentsAvailableAction,
    SetWebSocketConnectionAction,
    UpdateAgentsAvailableAction,
    State,
    AddAgentsAvailableAction,
    RemoveAgentsAvailableAction,
} from './types'

export const { Types: AgentsTypes, Creators: AgentsActions } = createActions(
    {
        setWebSocketConnection: ['connection', 'mall', 'store'],
        setAgentsAvailable: ['data'],
        updateAgentsAvailable: ['data'],
        addAgentsAvailable: ['data'],
        removeAgentsAvailable: ['data'],
    },
    { prefix: 'agents/' }
)

const initialState: State = {
    webSocketConnection: 'disconnected',
    agents: {
        items: [],
        current_page: 1,
        totals: 0,
    },
}

function setWebSocketConnection(state = initialState, action: SetWebSocketConnectionAction): State {
    return {
        ...state,
        webSocketConnection: action.connection,
    }
}

function setAgentsAvailable(state = initialState, action: SetAgentsAvailableAction): State {
    return {
        ...state,
        agents: action.data,
    }
}

function addAgentsAvailable(state = initialState, action: AddAgentsAvailableAction): State {
    if (state?.agents?.items && action?.data?.id) {
        const newState = { ...state }
        newState.agents.items.push(action.data)
        newState.agents.totals = newState.agents.items.length
        return newState
    }
    return state
}

function removeAgentsAvailable(state = initialState, action: RemoveAgentsAvailableAction): State {
    if (state?.agents?.items && action?.data?.id) {
        const newState = { ...state }
        newState.agents.items = newState.agents.items.filter(x => x.id !== action.data.id)
        newState.agents.totals = newState.agents.items.length
        return newState
    }
    return state
}

function updateAgentsAvailable(state = initialState, action: UpdateAgentsAvailableAction): State {
    if (state?.agents?.items?.length > 0) {
        const indexRoute = state.agents.items.findIndex(item => item.id === action.data.id)

        if (indexRoute >= 0) {
            const agents = state.agents
            const oldData = { ...state.agents.items[indexRoute] }
            agents.items[indexRoute] = { ...oldData, ...action.data }

            return {
                ...state,
                agents,
            }
        }
    }

    return state
}

export const agentsReducer = createReducer<State, Actions>(initialState, {
    [AgentsTypes.SET_WEB_SOCKET_CONNECTION]: setWebSocketConnection,
    [AgentsTypes.SET_AGENTS_AVAILABLE]: setAgentsAvailable,
    [AgentsTypes.ADD_AGENTS_AVAILABLE]: addAgentsAvailable,
    [AgentsTypes.REMOVE_AGENTS_AVAILABLE]: removeAgentsAvailable,
    [AgentsTypes.UPDATE_AGENTS_AVAILABLE]: updateAgentsAvailable,
})
