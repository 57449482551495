import React, { memo, useCallback, useMemo } from 'react'

import { DefaultButton } from 'components/default-button/default-button'

import { ISalesChannel } from 'types/sales-channel'

import {
    Actions,
    Color,
    ColorContent,
    ColorText,
    ID,
    Label,
    Logo,
    PointColor,
    RowContent,
    Status,
} from './sales-channels-listing-table-row.styled'

type Props = {
    salesChannel: ISalesChannel
    handleEdit: () => void
    handleDelete: () => void
}

const SalesChannelsListingTableRow: React.FC<Props> = memo(({ salesChannel, handleEdit, handleDelete }) => {
    const getPointColor = useMemo(() => {
        return salesChannel.status ? 'green' : 'red'
    }, [salesChannel.status])

    const status = useMemo(() => {
        return salesChannel.status ? 'Ativo' : 'Inativo'
    }, [salesChannel.status])

    return (
        <RowContent>
            <ID>{salesChannel.id}</ID>
            <Label>
                <Logo src={salesChannel.logo} width={8} height={8} />
                {salesChannel.name}
            </Label>
            <ColorContent>
                <Color color={salesChannel.color} />
                <ColorText>{salesChannel.color}</ColorText>
            </ColorContent>
            <Status>
                {status}
                <PointColor color={getPointColor} />
            </Status>
            <Actions>
                <DefaultButton outline onClick={handleEdit}>
                    Editar
                </DefaultButton>
                <DefaultButton onClick={handleDelete}>Remover</DefaultButton>
            </Actions>
        </RowContent>
    )
})

export { SalesChannelsListingTableRow }
