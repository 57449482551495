import styled from 'styled-components'

export const HeaderContainer = styled.div`
    padding: 30px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

export const HeaderTitle = styled.div`
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: bold;
`

export const InputsRow = styled.div`
    .button {
        width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        .button {
            width: fit-content;
        }
    }
`

export const InputContainer = styled.div`
    margin-right: 8px;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 200px;
        margin-right: 20px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 300px;
    }
`

export const MiddleContainer = styled.div``

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    .button {
        margin-top: 20px;
        width: 100%;
    }

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-top: 0;
        .button {
            margin-top: 0px;
            width: fit-content;
        }
    }
`

export const Row = styled.div`
    display: flex;
`

export const TextButton = styled.div`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 30px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.6;
    }
`

export const PrimaryButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 400ms;
    text-align: center;
    &:hover {
        opacity: 0.6;
    }
`

export const FiltersContainer = styled.div`
    margin-top: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

export const SearchResultsTitle = styled.div`
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 0;
    }
    span {
        font-weight: bold;
    }
`

export const PeriodButton = styled.div<{ isActive?: boolean }>`
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.white)};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.primary)};
    padding: 10px;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    height: fit-content;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`
