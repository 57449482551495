import './style.scss'

const MallsListingTableHeader = () => {
    return (
        <div className="malls-table-head-container">
            <div className="table-head-item id">ID</div>
            <div className="table-head-item label">Nome</div>
            <div className="table-head-item address">Endereço</div>
            <div className="table-head-item status">Status</div>
            <div className="table-head-item button-row" />
        </div>
    )
}

export default MallsListingTableHeader
