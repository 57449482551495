import styled, { css } from 'styled-components'

const Container = styled.div<{ expand?: boolean }>`
    width: 100%;
    min-height: 75vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    ${({ expand }) =>
        expand &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999999999999999;
        `}
`

const ContainerMap = styled.div`
    position: relative;
    flex: 1;
    display: flex;
`

const ContinerButtons = styled.div`
    display: flex;
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
`

const IconButton = styled.div`
    width: 40px;
    display: flex;
    height: 40px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:first-child {
        margin-right: 20px;
    }
`

const ContainerImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    img {
        width: 20px;
        height: 20px;
    }
`

export { Container, IconButton, ContainerMap, ContainerImage, ContinerButtons }
