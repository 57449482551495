import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { EmptyMessageContainer, EmptyImage, EmptyMessage, EmptyMessageIcon } from './empty-list-message.styles'

interface Props {
    image?: string
    icon?: IconProp
    message: string
}

const EmptyListMessage: React.FC<Props> = ({ icon, image, message }) => {
    return (
        <EmptyMessageContainer className="empty-list-message">
            {icon && <EmptyMessageIcon icon={icon} />}
            {image && <EmptyImage src={image} />}
            <EmptyMessage>{message}</EmptyMessage>
        </EmptyMessageContainer>
    )
}

export default EmptyListMessage
