import styled, { css } from 'styled-components'

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    /* overflow: auto; */
`

const Content = styled.div`
    height: 100%;
    position: relative;
`

const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;
`

const Title = styled.h4`
    padding: 0;
    margin: 0;

    font-size: 0.875rem;
    font-weight: bold;
`

const Description = styled.p`
    padding: 0;
    margin: 0;

    font-size: 0.75rem;
`

const ContentTop = styled.div``

const ContentBody = styled.div<{ hasPagination?: boolean }>`
    overflow-y: auto;
    overflow-x: hidden;
    ${({ hasPagination }) =>
        hasPagination
            ? css`
                  height: calc(100% - 10.438rem);
              `
            : css`
                  height: 90%;
              `};
`

const ContentFooter = styled.div<{ isVisible?: boolean }>`
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 0px 8px 2px rgb(138, 138, 138);

    ${({ isVisible }) =>
        isVisible &&
        css`
            display: block;
        `}
`

const ButtonRow = styled.div`
    display: flex;
    gap: 0.438rem;
    flex-direction: column;
    width: 100%;

    button {
        width: 100%;
        /* margin: 0.5rem 0; */
    }

    @media all and (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        /* width: 20%; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
            /* width: fit-content; */
        }
    }
`

const PreviewContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 1rem;
`

const TagIconCircle = styled.div<{ bgColor?: string }>`
    display: flex;
    min-width: 2.5rem;
    min-height: 2.5rem;

    i {
        width: 1.5rem !important;
        height: 1.5rem !important;
        padding: 0.5rem;

        color: #fff;
        font-size: 1.5rem;
        text-align: center;

        border-radius: 50%;
        border: 0.125rem solid #fff;

        background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.black)};
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
`

const TagIconTooltipText = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
`
const TagIconTooltip = styled.div<{ color?: string }>`
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.5rem;

    padding: 0.125rem 0.5rem;
    color: ${({ theme, color }) => (color ? color : theme.colors.black)};
    border-radius: 1.5rem;
    border: 0.063rem solid ${({ theme, color }) => (color ? color : theme.colors.black)};

    background-color: transparent;

    font-size: 1rem;
    font-weight: bold;
    line-height: 1.125rem;
    text-align: center;

    i {
        font-size: 1.5rem;
        font-weight: normal;
        color: ${({ theme, color }) => (color ? color : theme.colors.black)};
    }
`

export {
    ButtonRow,
    Container,
    Content,
    ContentTop,
    ContentBody,
    ContentFooter,
    TagIconCircle,
    TextContent,
    Title,
    Description,
    TagIconTooltip,
    TagIconTooltipText,
    PreviewContent,
}
