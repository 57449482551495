import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { booleanPointInPolygon, point } from '@turf/turf'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import MapboxView, { CircleLines, InfoMapMessage, PolygonFill } from 'components/mapbox-view'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PaymentArea } from 'types'

import './style.scss'

const ReturnRateCreation: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match: { params } }) => {
    const { basePath, mall } = useAuth()

    const [loading, toggleLoading] = useState(false)
    const [paymentAreas, setPaymentAreas] = useState<PaymentArea[]>([])

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const statusArray = [
        {
            name: 'Ativo',
            value: 1,
        },
        {
            name: 'Inativo',
            value: 0,
        },
    ]

    const validateMinRateValue = Yup.number().min(0.0, 'Tarifa é obrigatória').nullable()

    const { initialValues, isSubmitting, values, touched, errors, handleSubmit, setFieldValue, setValues } = useFormik({
        initialValues: {
            id: null,
            label: '',
            payment_areas: [],
            values: {
                bike: {
                    agent: '0.0',
                    store: '0.0',
                },
                moto: {
                    agent: '0.0',
                    store: '0.0',
                },
                car: {
                    agent: '0.0',
                    store: '0.0',
                },
                van: {
                    agent: '0.0',
                    store: '0.0',
                },
                truck: {
                    agent: '0.0',
                    store: '0.0',
                },
            },
            status: '',
        },
        validationSchema: Yup.object().shape({
            label: Yup.string().trim().required('Por favor, preencha com o Nome da tarifa'),
            values: Yup.object().shape({
                bike: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                moto: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                car: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                van: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                truck: Yup.object().shape({
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
            }),
            status: Yup.string().trim().required('Por favor, selecione o Status'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)
                const { bike, moto, car, van, truck } = values.values

                const body = {
                    ...values,
                    mall_id: mall.id,
                    payment_areas: values.payment_areas.map((item: PaymentArea) => item.id),
                    values: {
                        agent: {
                            bike: bike.agent,
                            moto: moto.agent,
                            car: car.agent,
                            van: van.agent,
                            truck: truck.agent,
                        },
                        store: {
                            bike: bike.store,
                            moto: moto.store,
                            car: car.store,
                            van: van.store,
                            truck: truck.store,
                        },
                    },
                }

                if (values?.id) {
                    await api.put(`/painel/rate-return/${values.id}`, body)
                } else {
                    await api.post('/painel/rate-return', body)
                }

                setModalMessageData({
                    title: 'Sucesso!',
                    message: `Tarifa ${values?.id ? 'atualizada' : 'criada'} com sucesso.`,
                    onClose: () => history.push(`${basePath}/tarifas-de-retorno`),
                })
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                })
            } finally {
                setSubmitting(false)
                modalMessage.current?.openModal()
            }
        },
    })

    const getSelectData = useCallback(async () => {
        try {
            toggleLoading(true)

            const { data } = await api.get(`/painel/payment-areas`, {
                params: {
                    mall_id: mall.id,
                    per_page: -1,
                    status: [1],
                },
            })

            setPaymentAreas(data.items)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push(`${basePath}/tarifas-de-retorno`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [history, mall])

    useEffect(() => {
        getSelectData()
    }, [getSelectData])

    const getRate = useCallback(
        async id => {
            try {
                toggleLoading(true)

                const { data } = await api.get(`/painel/rate-return/${id}`)
                const { agent, store } = data.values

                setValues({
                    ...initialValues,
                    ...data,
                    values: {
                        bike: {
                            agent: agent.bike,
                            store: store.bike,
                        },
                        moto: {
                            agent: agent.moto,
                            store: store.moto,
                        },
                        car: {
                            agent: agent.car,
                            store: store.car,
                        },
                        van: {
                            agent: agent.van,
                            store: store.van,
                        },
                        truck: {
                            agent: agent.truck,
                            store: store.truck,
                        },
                    },
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push(`${basePath}/tarifas-de-retorno`),
                })

                modalMessage.current?.openModal()
            } finally {
                toggleLoading(false)
            }
        },
        [history, initialValues, setValues]
    )

    useEffect(() => {
        params.id && getRate(params.id)
    }, [getRate, params.id])

    const deleteRate = async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate-return/${params.id}`)

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Tarifa removida com sucesso.',
                onClose: () => history.push(`${basePath}/tarifas-de-retorno`),
            })

            modalMessage.current?.openModal()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/tarifas-de-retorno`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }

    const checkPaymentArea = ({ lngLat }: { lngLat: { lat: number; lng: number } }) => {
        const searchPoint = point([lngLat.lng, lngLat.lat])
        const areaClicked = paymentAreas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

        if (!areaClicked) return

        if (values.payment_areas.some((item: PaymentArea) => item.id === areaClicked?.id)) {
            setFieldValue(
                'payment_areas',
                values.payment_areas.filter((item: PaymentArea) => item.id !== areaClicked?.id)
            )
        } else {
            setFieldValue('payment_areas', [...values.payment_areas, areaClicked])
        }
    }

    const { bike, moto, car, van, truck } = values.values

    return (
        <div className="page-container">
            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Deseja realmente excluir esta tarifa?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                title="Tarifa de Retorno"
                onBackButtonClick={() => history.push(`${basePath}/tarifas-de-retorno`)}
                buttons={
                    params.id && [
                        {
                            title: 'Excluir Tarifa',
                            icon: 'trash-alt',
                            onClick: () => modalDelete.current?.openModal(),
                        },
                    ]
                }
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title">{params.id ? 'Edição' : 'Criação'}</div>

                        <div className="rate-label">
                            <TextInputForm
                                label={'Nome da Tarifa'}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('label', value)
                                }
                                value={values.label}
                                msgErro={touched.label && errors.label}
                            />
                        </div>

                        <div className="section-title-container return">
                            <span className="section-title">Tarifa para Bicicleta</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={bike.store}
                                    onChange={(floatValue: number) => setFieldValue('values.bike.store', floatValue)}
                                    msgErro={touched.values?.bike?.store && errors.values?.bike?.store}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={bike.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.bike.agent', floatValue)}
                                    msgErro={touched.values?.bike?.agent && errors.values?.bike?.agent}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container return">
                            <span className="section-title">Tarifa para Motocicleta</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={moto.store}
                                    onChange={(floatValue: number) => setFieldValue('values.moto.store', floatValue)}
                                    msgErro={touched.values?.moto?.store && errors.values?.moto?.store}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={moto.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.moto.agent', floatValue)}
                                    msgErro={touched.values?.moto?.agent && errors.values?.moto?.agent}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container return">
                            <span className="section-title">Tarifa para Carro</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={car.store}
                                    onChange={(floatValue: number) => setFieldValue('values.car.store', floatValue)}
                                    msgErro={touched.values?.car?.store && errors.values?.car?.store}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={car.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.car.agent', floatValue)}
                                    msgErro={touched.values?.car?.agent && errors.values?.car?.agent}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container return">
                            <span className="section-title">Tarifa para Van</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={van.store}
                                    onChange={(floatValue: number) => setFieldValue('values.van.store', floatValue)}
                                    msgErro={touched.values?.van?.store && errors.values?.van?.store}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={van.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.van.agent', floatValue)}
                                    msgErro={touched.values?.van?.agent && errors.values?.van?.agent}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container return">
                            <span className="section-title">Tarifa para Caminhão</span>
                        </div>

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={truck.store}
                                    onChange={(floatValue: number) => setFieldValue('values.truck.store', floatValue)}
                                    msgErro={touched.values?.truck?.store && errors.values?.truck?.store}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={truck.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.truck.agent', floatValue)}
                                    msgErro={touched.values?.truck?.agent && errors.values?.truck?.agent}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div style={{ width: 200 }}>
                            <SelectInputForm
                                label="Status"
                                data={statusArray}
                                value={values.status}
                                onChange={({ target: { value } }) => setFieldValue('status', value)}
                                msgErro={touched.status && errors.status}
                            />
                        </div>

                        <ButtonForm
                            type="submit"
                            buttonText="Salvar"
                            onClick={() => handleSubmit()}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="right-column">
                        <MapboxView>
                            <PolygonFill
                                id="rate-areas"
                                showPopUpArea
                                areaType="rate-area"
                                areas={paymentAreas}
                                onClick={checkPaymentArea}
                                defaultColor="#333"
                                defaultOpacity={0.5}
                                rateCoverageAreas={values.payment_areas}
                            />

                            <InfoMapMessage message="Clique nos polígonos destacados no mapa para marcar ou desmarcar uma área de cobrança." />
                            <CircleLines id="circle-lines" center={[mall.address?.lng, mall.address?.lat]} />
                        </MapboxView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnRateCreation
