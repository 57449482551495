const formatCurrency = currency => {
    const formatter = new Intl.NumberFormat([], {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
    })
    if (currency) {
        return formatter.format(currency)
    }

    return formatter.format(0)
}

const onChangeMoney = value => {
    if (value.length > 20) return
    value = Number(value.replace(/\D+/g, '')).toString()
    if (!value.length) value = `0,00`
    else if (value.length === 1) value = `0,0${value}`
    else if (value.length === 2) value = `0,${value}`
    else value = `${value.substr(0, value.length - 2)},${value.substr(value.length - 2)}`
    return `R$ ${value}`
}

const formatMoney = value => {
    value = Number(value.replace(/\D+/g, '')).toString()
    value = `${value.substr(0, value.length - 2)}.${value.substr(value.length - 2)}`
    return Number(value)
}

export { formatCurrency, onChangeMoney, formatMoney }
