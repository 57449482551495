import { useCallback, useMemo, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import { format } from 'date-fns'
import {
    ModalOrderIntegrationCancel,
    ModalOrderIntegrationCancelRef,
} from 'modals/modal-order-integration-cancel/modal-order-integration-cancel'
import {
    ModalOrderIntegrationDismiss,
    ModalOrderIntegrationDismissRef,
} from 'modals/modal-order-integration-dismiss/modal-order-integration-dismiss'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { plugApi } from 'services/api'
import RootState, { OrdersInProgressState } from 'store/reducers/types'
import { Order } from 'types'

import { OrderItem } from '..'

import { IOrderIntegration } from 'types/order-integration'
import { IOrderIntegrationList } from 'types/order-integration-list'

import { OrderItemIntegration } from '../order-item-integration/order-item-integration'

import {
    CardHeader,
    CardNumber,
    CardTitle,
    EmptyIcon,
    EmptyText,
    EmptyMessageContainer,
    OrdersList,
    ProgressCardContainer,
    LoadingContainer,
    DateGroup,
    Groupheader,
    GroupheaderTitle,
    GroupheaderSize,
    Header,
} from './grouped-progress-card.styles'

interface Props {
    cardTitle: string
    amount: number | undefined
    emptyText: string
    emptyIcon: string
    onClickOrder(order: Order): void
    dropdownOrderIdActive?: number | undefined
    setDropdownOrderIdActive?(orderId: number | undefined): void
    onRescheduleOrder?(order: Order): void
    onConfirmOrder?(order: Order): void
    onMoveOrder?(order: Order): void
    onCancelOrder?(order: Order): void
    loading?: boolean
    orders?: Order[]
    ordersIntegrations?: IOrderIntegrationList[]
    hasBikerInfo?: boolean
    header?: any
}

const GroupedProgressCard: React.FC<Props> = ({
    amount,
    cardTitle,
    loading,
    orders,
    emptyText,
    emptyIcon,
    onClickOrder,
    dropdownOrderIdActive,
    setDropdownOrderIdActive,
    onRescheduleOrder,
    onConfirmOrder,
    onMoveOrder,
    onCancelOrder,
    ordersIntegrations,
    hasBikerInfo,
    header,
}) => {
    const { setErrorModal } = useUI()
    const ModalOrderIntegrationCancelRef = useRef<ModalOrderIntegrationCancelRef>()
    const modalOrderIntegrationDismissRef = useRef<ModalOrderIntegrationDismissRef>()

    const { highlightedOrderId } = useSelector<RootState, OrdersInProgressState>(
        ({ ordersInProgress }) => ordersInProgress
    )

    const groupedOrdersIntegrationList = useMemo(() => {
        if (!ordersIntegrations) {
            return null
        }

        const groupedList = []

        ordersIntegrations?.forEach(order => {
            const date = format(order.details.delivery_forecast || order.details.birth, 'DD/MM/YYYY')

            if (groupedList.findIndex(e => e.date === date) === -1) {
                groupedList.push({ date: date, dateOrders: [] })
            }
        })

        ordersIntegrations?.forEach(order => {
            groupedList.forEach((listItem, index) => {
                const date = format(order.details.delivery_forecast || order.details.birth, 'DD/MM/YYYY')

                if (listItem.date === date) {
                    groupedList[index].dateOrders.push(order)
                    groupedList[index].dateOrders.sort(compareTimes)
                }
            })
        })

        return groupedList.sort(compareDates)
    }, [ordersIntegrations])

    const groupedOrdersList = useMemo(() => {
        if (!orders) {
            return null
        }
        const groupedList = []

        orders?.forEach(order => {
            if (!groupedList.filter(e => e.date === format(order.delivery_forecast, 'DD/MM/YYYY')).length) {
                groupedList.push({ date: format(order.delivery_forecast, 'DD/MM/YYYY'), dateOrders: [] })
            }
        })

        orders?.forEach(order => {
            groupedList.forEach((listItem, index) => {
                if (listItem.date === format(order.delivery_forecast, 'DD/MM/YYYY')) {
                    groupedList[index].dateOrders.push(order)
                    groupedList[index].dateOrders.sort(compareTimes)
                }
            })
        })

        return groupedList.sort(compareDates)
    }, [orders])

    const _showDismissModal = useCallback(
        (order: IOrderIntegrationList | IOrderIntegration) => () => {
            modalOrderIntegrationDismissRef.current.show({ order: order })
        },
        []
    )

    const _forceIntegration = useCallback(
        (id: number) => async () => {
            try {
                await plugApi.put(`orders/${id}/force-integration`)
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao forçar integração de pedido',
                    subtitle: showErrors(error),
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <>
            <ProgressCardContainer>
                <CardHeader>
                    <CardTitle>{cardTitle}</CardTitle>
                    <CardNumber>{amount}</CardNumber>
                </CardHeader>
                {!!header && <Header>{header}</Header>}
                {loading ? (
                    <OrdersList>
                        {[...Array(6)].map((_item, index) => (
                            <LoadingContainer key={index}>
                                <Skeleton circle height={60} width={60} style={{ marginRight: 20 }} />

                                <Skeleton height={50} />
                            </LoadingContainer>
                        ))}
                    </OrdersList>
                ) : (
                    <OrdersList>
                        {groupedOrdersList && (
                            <>
                                {Number(groupedOrdersList.length) > 0 ? (
                                    <>
                                        <>
                                            {groupedOrdersList.map((group, index) => (
                                                <DateGroup key={index}>
                                                    <Groupheader>
                                                        <GroupheaderTitle>{group.date}</GroupheaderTitle>
                                                        <GroupheaderSize>{group.dateOrders.length}</GroupheaderSize>
                                                    </Groupheader>
                                                    {group.dateOrders.map((order, index) => (
                                                        <OrderItem
                                                            key={index}
                                                            order={order}
                                                            type={'scheduled'}
                                                            isHighlighted={order.id === highlightedOrderId}
                                                            dropdownOrderIdActive={dropdownOrderIdActive}
                                                            setDropdownOrderIdActive={setDropdownOrderIdActive}
                                                            onClickOrder={onClickOrder}
                                                            onRescheduleOrder={onRescheduleOrder}
                                                            onConfirmOrder={onConfirmOrder}
                                                            onMoveOrder={onMoveOrder}
                                                            onCancelOrder={onCancelOrder}
                                                            hasBikerInfo={hasBikerInfo}
                                                        />
                                                    ))}
                                                </DateGroup>
                                            ))}
                                        </>
                                    </>
                                ) : (
                                    <EmptyMessageContainer>
                                        <EmptyIcon src={emptyIcon} />
                                        <EmptyText>{emptyText}</EmptyText>
                                    </EmptyMessageContainer>
                                )}
                            </>
                        )}
                        <>
                            {Number(groupedOrdersIntegrationList?.length) > 0 ? (
                                <>
                                    <>
                                        {groupedOrdersIntegrationList.map((group, index) => (
                                            <DateGroup key={index}>
                                                <Groupheader>
                                                    <GroupheaderTitle>{group.date}</GroupheaderTitle>
                                                    <GroupheaderSize>{group.dateOrders.length}</GroupheaderSize>
                                                </Groupheader>
                                                {group.dateOrders.map((order, index) => (
                                                    <OrderItemIntegration
                                                        key={index}
                                                        order={order}
                                                        modalRef={ModalOrderIntegrationCancelRef}
                                                        dropdownOrderIdActive={dropdownOrderIdActive}
                                                        setDropdownOrderIdActive={setDropdownOrderIdActive}
                                                        showDismissModal={_showDismissModal(order)}
                                                        forceIntegration={_forceIntegration(order.id)}
                                                    />
                                                ))}
                                            </DateGroup>
                                        ))}
                                    </>
                                </>
                            ) : (
                                <EmptyMessageContainer>
                                    <EmptyIcon src={emptyIcon} />
                                    <EmptyText>{emptyText}</EmptyText>
                                </EmptyMessageContainer>
                            )}
                        </>
                    </OrdersList>
                )}
            </ProgressCardContainer>
            <ModalOrderIntegrationCancel ref={ModalOrderIntegrationCancelRef} />
            <ModalOrderIntegrationDismiss ref={modalOrderIntegrationDismissRef} />
        </>
    )
}

function compareDates(a, b) {
    const keyA = new Date(a.date),
        keyB = new Date(b.date)
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
}

function compareTimes(a, b) {
    const keyA = new Date(a.delivery_forecast),
        keyB = new Date(b.delivery_forecast)
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
}

export default GroupedProgressCard
