import { memo, useCallback, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ButtonForm from 'components/button-form'
import { DefaultButton } from 'components/default-button/default-button'
import RowTitle from 'components/row-title'
import TextArea from 'components/text-area'
import TextInputMask from 'components/text-input-mask'

import { useUI } from 'contexts'
import { getFormInputError } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import api from 'services/api'

import {
    Container,
    Content,
    Header,
    HeaderTitle,
    InputSection,
    StoreInfoContainer,
} from './marketplace-store-info.styles'

interface UpdateStore {
    description: string
    phone: string
    operating_hour: string
    preparation_time: string
    configs?: {
        marketplace_name: string
    }
}

const MarketplaceStoreInfo: React.FC = memo(() => {
    const { store } = useAuth()
    const { setLoading, setSnackbar, setErrorModal } = useUI()

    const _getStoreInfo = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get(`/painel/marketplace/store/${store.id}/details`)
            setValues({
                description: data?.description || '',
                phone: data?.phone || '',
                operating_hour: data?.operating_hour || '',
                preparation_time: data?.preparation_time || '',
                configs: { marketplace_name: data?.configs?.marketplace_name || '' },
            })
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível carregar informações da loja.',
            })
        } finally {
            setLoading(false)
        }
    }, [store])

    useEffect(() => {
        _getStoreInfo()
    }, [_getStoreInfo])

    const { errors, getFieldProps, handleSubmit, setValues, touched, setFieldValue, values } = useFormik<UpdateStore>({
        initialValues: {
            description: '',
            phone: '',
            operating_hour: '',
            preparation_time: '',
            configs: {
                marketplace_name: '',
            },
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().trim().required('Descrição da loja é obrigatória'),
            phone: Yup.string().required('Telefone da loja é obrigatório'),
            operating_hour: Yup.string().trim().required('Tempo de produção da loja é obrigatório'),
            preparation_time: Yup.string().trim().required('Telefone da loja é obrigatório'),
        }),
        onSubmit: async values => {
            try {
                setLoading(true)
                await api.put(`/painel/store/${store.id}`, values)
                setSnackbar({ message: 'Informações atualizadas' })
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
    })
    return (
        <Container>
            <Header>
                <HeaderTitle>Informações da Loja</HeaderTitle>
            </Header>
            <Content>
                <StoreInfoContainer>
                    <InputSection>
                        <InputItem
                            labelText="Nome da Loja no Marketplace"
                            inputProps={{
                                ...getFieldProps('configs.marketplace_name'),
                                placeholder: 'Informe o nome de loja a ser mostrado no Marketplace',
                            }}
                            errorMessage={getFormInputError('description', errors, touched)}
                        />
                    </InputSection>
                    <InputSection>
                        <InputItem
                            type="textarea"
                            big
                            labelText="Descrição"
                            inputProps={{
                                ...getFieldProps('description'),
                                placeholder: 'Descreva sua loja e o que ela vende, ex: Comida Chinesa e japonesa ',
                            }}
                            errorMessage={getFormInputError('description', errors, touched)}
                        />
                    </InputSection>
                    <InputSection>
                        <InputItem
                            type="textarea"
                            big
                            labelText="Horário de funcionamento"
                            inputProps={{
                                ...getFieldProps('operating_hour'),
                                placeholder:
                                    'Descreva os horário de abertuira e fechamento da sua loja, ex: 10:30 as 15:00 e 17:00 as 22:00',
                            }}
                            errorMessage={getFormInputError('operating_hour', errors, touched)}
                        />
                    </InputSection>

                    <InputSection>
                        <InputItem
                            labelText="Telefone"
                            inputProps={{
                                ...getFieldProps('phone'),
                                mask: '(99) 9 9999-9999',
                                placeholder: 'Adcione um número de contatto para sua loja, ex: (85) 9 0000-0000',
                                onChange: ({ target: { value } }) => {
                                    setFieldValue('phone', value)
                                },
                            }}
                            errorMessage={getFormInputError('phone', errors, touched)}
                        />
                    </InputSection>

                    <InputSection>
                        <InputItem
                            labelText="Tempo de preparo"
                            inputProps={{
                                ...getFieldProps('preparation_time'),
                                mask: '99:99:99',
                                placeholder:
                                    'Aponte o tempo médo de preparo dos seus produtos no formato hh:mm:ss, ex: 00:20:00',
                                onChange: ({ target: { value } }) => {
                                    setFieldValue('preparation_time', value)
                                },
                            }}
                            errorMessage={getFormInputError('preparation_time', errors, touched)}
                        />
                    </InputSection>

                    <DefaultButton widthFitContainer title="Atualizar Informaçõces" onClick={handleSubmit} />
                </StoreInfoContainer>
            </Content>
        </Container>
    )
})

export default MarketplaceStoreInfo
