import { AxiosError } from 'axios'
import { stringify } from 'querystring'
import { Key, SWRConfiguration, SWRInfiniteResponse, useSWRInfinite } from 'swr'

import api from 'services/api'
import { PagedList } from 'types'

type Config<Data, Error = AxiosError> = SWRConfiguration<Data, Error> & { params?: unknown }
type Response<Data, Error = AxiosError> = SWRInfiniteResponse<Data, Error> & { loading: boolean }

export function useFetchPagination<Data, Error = AxiosError>(
    url: Key,
    config?: Config<PagedList<Data>[], Error>
): Response<PagedList<Data>, Error> & {
    items: Data[] | undefined
    count: number
    totals: number
    has_more: boolean
} {
    async function fetcher(pageIndex: number, params: unknown) {
        const { data } = await api.get(url as string, {
            params: typeof params === 'object' ? { ...params, current_page: pageIndex + 1 } : undefined,
        })

        return data
    }

    const { data, error, isValidating, size, setSize, mutate, revalidate } = useSWRInfinite<PagedList<Data>>(
        (pageIndex: number, previousPageData: PagedList<Data> | null) => {
            if (url === null) return null

            if (previousPageData && !previousPageData?.items?.length) return null

            const key = config?.params ? `${url}?${stringify(config.params as Record<string, string>)}` : url

            return [key, pageIndex]
        },
        (url, pageIndex: number) => (url ? fetcher(pageIndex, config?.params) : undefined),
        {
            ...config,
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )

    const items = data?.flatMap(data => data.items) as Data[]

    return {
        loading: isValidating,
        data,
        items,
        count: items?.length || 0,
        totals: data?.[0]?.totals || 0,
        has_more: !!data?.[0]?.has_more,
        error,
        size,
        setSize,
        mutate,
        isValidating,
        revalidate,
    }
}
