import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'
import { OrderIntegrationSummary } from 'components/_store-general-vision/order-integration-summary/order-summary'

import { IOrderIntegration } from 'types/order-integration'
import { IOrderIntegrationList } from 'types/order-integration-list'

import { useModalOrderIntegrationDismiss } from './modal-order-integration-dismiss.controller'
import {
    Box,
    Container,
    ContainerButtons,
    Footer,
    Icon,
    Message,
    Separator,
    Title,
    Button,
    ContainerSelect,
} from './modal-order-integration-dismiss.styled'

const OPTIONS = [
    {
        code: 501,
        title: 'PROBLEMAS DE SISTEMA',
    },
    {
        code: 502,
        title: 'PEDIDO EM DUPLICIDADE',
    },
    {
        code: 503,
        title: 'ITEM INDISPONÍVEL',
    },
    {
        code: 504,
        title: 'RESTAURANTE SEM MOTOBOY',
    },
    {
        code: 505,
        title: 'CARDÁPIO DESATUALIZADO',
    },
    {
        code: 506,
        title: 'PEDIDO FORA DA ÁREA DE ENTREGA',
    },
    {
        code: 507,
        title: 'CLIENTE GOLPISTA / TROTE',
    },
    {
        code: 508,
        title: 'FORA DO HORÁRIO DO DELIVERY',
    },
    {
        code: 509,
        title: 'DIFICULDADES INTERNAS DO RESTAURANTE',
    },
    {
        code: 511,
        title: 'ÁREA DE RISCO',
    },
    {
        code: 512,
        title: 'RESTAURANTE ABRIRÁ MAIS TARDE',
    },
    {
        code: 513,
        title: 'RESTAURANTE FECHOU MAIS CEDO',
    },
    {
        code: 514,
        title: 'PEDIDO ENTREGUE PELO IFOOD',
    },
]

type Params = {
    order: IOrderIntegrationList | IOrderIntegration
}

export type ModalOrderIntegrationDismissRef = {
    show?(params: Params): void
}

interface ModalOrderIntegrationDismissProps {
    onClose?(success?: boolean): void
}
const ModalOrderIntegrationDismiss = memo(
    forwardRef<ModalOrderIntegrationDismissRef, ModalOrderIntegrationDismissProps>((props, ref) => {
        const { visible, show, order, reason, _setOptionCancel, _onButtonClick, _setShow } =
            useModalOrderIntegrationDismiss(props)

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
            }),
            [_setShow]
        )

        if (!visible) {
            return null
        }

        return (
            <Container id="global-modal" visible={visible}>
                <Box show={show}>
                    <>
                        <Title id="title-modal" className="title">
                            Desconsiderar Pedido
                        </Title>
                        <Icon icon="exclamation-circle" />
                        <Message id="message-modal">Deseja realmente desconsiderar este pedido?</Message>
                        <OrderIntegrationSummary order={order as any} />
                        {['ifood'].includes(order?.type) && (
                            <ContainerSelect>
                                <InputItem
                                    labelText="Motivo"
                                    type="select"
                                    options={OPTIONS.map(({ title, code }) => ({ label: title, value: code }))}
                                    inputProps={{
                                        placeholder: 'Selecione o motivo',
                                        value: reason?.code?.toString(),
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            _setOptionCancel(
                                                OPTIONS.find(e => {
                                                    return e.code === Number(value)
                                                })
                                            )
                                        },
                                    }}
                                />
                            </ContainerSelect>
                        )}
                        <Footer>
                            <>
                                <ContainerButtons>
                                    <Button
                                        id="yes-button"
                                        onClick={reason && _onButtonClick('yes')}
                                        disabled={!reason}
                                    >
                                        Sim
                                    </Button>
                                </ContainerButtons>
                                <Separator />
                                <ContainerButtons>
                                    <Button id="no-button" isOutline onClick={_onButtonClick('no')}>
                                        Não
                                    </Button>
                                </ContainerButtons>
                            </>
                        </Footer>
                    </>
                </Box>
            </Container>
        )
    })
)

export { ModalOrderIntegrationDismiss }
