import { useCallback, useState } from 'react'

import { HeatmapHeader } from 'components/_reports'
import { HeatMap } from 'components/heatmap/heatmap'
import ModalLoading from 'components/modal-loading'

import api from 'services/api'

import { CardRow, HeatMapContent, HeatMapPageContainer } from './heatmap-page.styles'

export default function HeatmapPage(): JSX.Element {
    const [loading, setLoading] = useState(false)
    const [points, setPoints] = useState(null)
    const [center, setCenter] = useState(null)

    const _getReports = useCallback(async (value: any) => {
        setLoading(true)
        try {
            const { location, ...params } = value
            const { data } = await api.get('/painel/report/heatmap', {
                params: {
                    mall_id: params.mall_id || null,
                    stores: params.stores,
                    start_date: params.start_date || null,
                    end_date: params.end_date || null,
                    start_time: params.start_time || null,
                    end_time: params.end_time || null,
                    start_ticket: params.start_ticket || null,
                    end_ticket: params.end_ticket || null,
                    status: params.status,
                },
            })
            setCenter(location)
            setPoints(data.items)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])
    return (
        <HeatMapPageContainer>
            <HeatmapHeader title="Mapa de calor" onFilterData={_getReports} disabled={loading} />
            <HeatMapContent>
                {!!points && (
                    <CardRow>
                        <HeatMap center={center} data={points} />
                    </CardRow>
                )}
            </HeatMapContent>
            <ModalLoading visible={loading} />
        </HeatMapPageContainer>
    )
}
