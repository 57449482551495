import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'

import login1 from '../../../assets/images/login1.png'
import login2 from '../../../assets/images/login2.png'
import login3 from '../../../assets/images/login3.png'

import {
    LeftLogo,
    LeftSide,
    LoginBox,
    LoginImage,
    LoginImageContainer,
    LoginPageContainer,
    LoginTitle,
    MainButton,
    MobileLogo,
    RightSide,
    SignupBox,
    SignupCall,
    SignupButton,
    LoginSubtitle,
    TopSignupTag,
    DesktopText,
    TagOutline,
    MapImage,
    ButtonsRow,
    ResendLink,
    CounterSection,
    SendContainer,
} from './password-recovery-link.styles'

interface Email {
    email: string
}

export default function PasswordRecoveryLink(): JSX.Element {
    const { email } = useParams() as Email
    const { setLoading } = useUI()
    const [timer, setTimer] = useState(59)
    const [linkAvailable, setLinkAvailable] = useState(false)
    const [loginImage, setLoginImage] = useState(login1)
    const [isChanging, setIsChanging] = useState(false)

    const changeImage = useCallback(() => {
        if (loginImage === login1) {
            setLoginImage(login2)
        }
        if (loginImage === login2) {
            setLoginImage(login3)
        }
        if (loginImage === login3) {
            setLoginImage(login1)
        }
    }, [loginImage])

    useEffect(() => {
        setTimeout(() => {
            if (isChanging) {
                setIsChanging(false)
                changeImage()
            }
        }, 1000)

        setTimeout(() => {
            if (!isChanging) {
                setIsChanging(true)
            }
        }, 5000)
    }, [isChanging, changeImage])

    useEffect(() => {
        if (timer === 0) {
            setLinkAvailable(true)
            return
        }

        setTimeout(() => {
            setTimer(timer - 1)
            if (timer === 0) {
                setLinkAvailable(true)
            }
        }, 1000)
    }, [timer])

    async function resendLink(email: string) {
        if (!linkAvailable) {
            return
        }

        try {
            setLoading(true)
            await api.post(`/recover`, {
                email: email,
                origin: 'router',
            })
            setTimer(59)
            setLinkAvailable(false)
        } catch (error) {
            console.log(showErrors(error))
        } finally {
            setLoading(false)
        }
    }

    return (
        <LoginPageContainer>
            <LeftSide>
                <MapImage />
                <LeftLogo />
                <LoginImageContainer>
                    <LoginImage src={loginImage} isChanging={isChanging} />
                </LoginImageContainer>
            </LeftSide>
            <RightSide>
                <TopSignupTag>
                    <DesktopText>Já possui cadastro?</DesktopText>
                    <TagOutline>Acessar conta</TagOutline>
                </TopSignupTag>

                <LoginBox>
                    <MobileLogo />

                    <LoginTitle>Recuperar Senha</LoginTitle>
                    <LoginSubtitle>
                        Enviamos um link de recuperação de conta para o e-mail <b>{email}</b>
                        <br />
                        <br />
                        Por favor, acesse-o para redefinir sua senha.
                    </LoginSubtitle>
                    <ButtonsRow>
                        <ResendLink>Não recebeu o e-mail?</ResendLink>
                        <SendContainer>
                            <MainButton sentLink={!linkAvailable} onClick={() => resendLink(email)}>
                                {linkAvailable ? 'Enviar Link' : 'Link Enviado'}
                            </MainButton>
                            {timer > 0 && (
                                <CounterSection>
                                    Enviar novamente em <b>{timer}</b> segundos
                                </CounterSection>
                            )}
                        </SendContainer>
                    </ButtonsRow>

                    <SignupBox>
                        <SignupCall>Problemas para recuperar a senha?</SignupCall>
                        <SignupButton>FALE COM O NOSSO SUPORTE</SignupButton>
                    </SignupBox>
                </LoginBox>
            </RightSide>
        </LoginPageContainer>
    )
}
