import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { api2 } from 'services/api'
import { Order } from 'types'

type Params = {
    order: Order
}

type Form = {
    reason: string
}

function useModalRemoveOrder(revalidate: () => void) {
    const { setSuccessModal, setErrorModal } = useUI()
    const modalShowRef = useRef<boolean>()

    const [order, setOrder] = useState<Order>()
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)

    const {
        isValid: isValidReason,
        values,
        touched,
        errors,
        getFieldProps,
        setFieldValue,
        handleSubmit,
        resetForm,
    } = useFormik<Form>({
        initialValues: {
            reason: undefined,
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required('O motivo da remoção do pedido é obrigatório'),
        }),
        onSubmit: async (values: Form) => {
            try {
                // validate status before collected
                if (['1', '2', '3', '4'].includes(order.status)) {
                    await api2.put('routes/remove-order', {
                        route_id: order?.routes?.id,
                        order_id: order.id,
                        reason: values.reason || '',
                    })
                }

                // validate status to collected and on delivery
                if (['5', '6'].includes(order.status)) {
                    await api2.put('routes/remove/routed-order', {
                        route_id: order?.routes?.id,
                        order_id: order.id,
                        reason: values.reason || '',
                    })
                }
                setSuccessModal({
                    title: 'Remover Pedido',
                    subtitle: 'O pedido foi removido com sucesso da rota',
                    singleButtonClick() {
                        revalidate()
                        _onClose()
                    },
                })
            } catch (error) {
                setErrorModal({
                    title: 'Houve um error ao remover o pedido da rota',
                    subtitle: showErrors(error),
                })
            }
        },
    })

    const isValid = useMemo(
        () => !['undefined', undefined, null].includes(values.reason) && isValidReason,
        [values, isValidReason]
    )

    const _setShow = useCallback(({ order }: Params) => {
        setOrder(order)
        setShow(true)
    }, [])

    const _onClose = useCallback(() => {
        setShow(false)
        setOrder(null)
        resetForm()
    }, [resetForm])

    const _changeFormValue = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setFieldValue('reason', value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setVisible(false)
            setOrder(null)
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    return {
        visible,
        show,
        order,
        isValid,
        touched,
        errors,
        getFieldProps,
        handleSubmit,
        _setShow,
        _onClose,
        _changeFormValue,
    }
}

export { useModalRemoveOrder }
