import Spinner from 'react-spinner-material'

import colors from '../../themes/colors'
import './style.scss'

interface Props {
    label?: string
    loading: boolean
    onClick(): void
    visible: boolean
}

const ButtonLoadMore: React.FC<Props> = ({ label, loading, onClick, visible }) => {
    return (
        <div className="load-more-container">
            {visible && (
                <div className="load-button" onClick={!loading && onClick}>
                    {loading ? (
                        <Spinner size={16.5} color={colors.primary_color} stroke={2} radius={20} visible={true} />
                    ) : (
                        label || 'Carregar mais'
                    )}
                </div>
            )}
        </div>
    )
}

export default ButtonLoadMore
