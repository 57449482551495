import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import {
    SuccessIcon,
    SuccessMessageContainer,
    SuccessTitle,
} from 'components/_common/password-strength/password-strength.styles'

import login1 from '../../../assets/images/login1.png'
import login2 from '../../../assets/images/login2.png'
import login3 from '../../../assets/images/login3.png'

import {
    LeftLogo,
    LeftSide,
    LoginBox,
    LoginImage,
    LoginImageContainer,
    LoginPageContainer,
    MainButton,
    MobileLogo,
    RightSide,
    SignupBox,
    SignupCall,
    SignupButton,
    TopSignupTag,
    DesktopText,
    TagOutline,
    MapImage,
} from './password-recovery-success.styles'

export default function PasswordRecoverySuccess(): JSX.Element {
    const [loginImage, setLoginImage] = useState(login1)
    const [isChanging, setIsChanging] = useState(false)
    const history = useHistory()

    const changeImage = useCallback(() => {
        if (loginImage === login1) {
            setLoginImage(login2)
        }
        if (loginImage === login2) {
            setLoginImage(login3)
        }
        if (loginImage === login3) {
            setLoginImage(login1)
        }
    }, [loginImage])

    useEffect(() => {
        setTimeout(() => {
            if (isChanging) {
                setIsChanging(false)
                changeImage()
            }
        }, 1000)

        setTimeout(() => {
            if (!isChanging) {
                setIsChanging(true)
            }
        }, 5000)
    }, [isChanging, changeImage])

    return (
        <LoginPageContainer>
            <LeftSide>
                <MapImage />
                <LeftLogo />
                <LoginImageContainer>
                    <LoginImage src={loginImage} isChanging={isChanging} />
                </LoginImageContainer>
            </LeftSide>
            <RightSide>
                <TopSignupTag>
                    <DesktopText>Já possui cadastro?</DesktopText>
                    <TagOutline onClick={() => history.push('/login')}>Acessar conta</TagOutline>
                </TopSignupTag>

                <LoginBox>
                    <MobileLogo />

                    <SuccessMessageContainer>
                        <SuccessIcon />
                        <SuccessTitle>Senha redefinida com sucesso!</SuccessTitle>
                        <MainButton onClick={() => history.push('/login')}>acessar conta</MainButton>
                    </SuccessMessageContainer>
                    <SignupBox>
                        <SignupCall>Problemas para recuperar a senha?</SignupCall>
                        <SignupButton>FALE COM O NOSSO SUPORTE</SignupButton>
                    </SignupBox>
                </LoginBox>
            </RightSide>
        </LoginPageContainer>
    )
}
