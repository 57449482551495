const getFiltersByTimeOptions = (ordersStatus = null) => {
    const filtersOptions = [
        { name: 'Maior tempo total', value: 1 },
        { name: 'Menor tempo total', value: 2 },
        { name: 'Maior tempo de pronto', value: 3 },
        { name: 'Menor tempo de pronto', value: 4 },
    ]

    if (ordersStatus === 1) return filtersOptions.slice(4, 6)
    if (ordersStatus === 3) return filtersOptions.slice(0, 2)
    if (ordersStatus === 4) return filtersOptions.slice(0, 4)

    return filtersOptions
}

export { getFiltersByTimeOptions }
