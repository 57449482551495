import BikerInfo from 'components/_new-general-vision/biker-info/biker-info'
import InfoItem from 'components/_new-general-vision/info-item/info-item'
import OrderDetailTopRow from 'components/_new-general-vision/order-detail-top-row/order-detail-top-row'
import { ClockIcon } from 'components/_new-general-vision/order-item/order-item.styles'
import ProductItem from 'components/_new-general-vision/product-item/product-item'
import StatusTimeline from 'components/_new-general-vision/status-timeline/status-timeline'
import TopRowDefault from 'components/top-row-default/top-row-default'

import {
    OrderDetailsContainer,
    ContentContainer,
    LeftColumn,
    RightColumn,
    InfoCard,
    InfoRow,
    BigSide,
    EditButton,
    CardTitle,
    BagIcon,
    TitleText,
    OrderTable,
    TableHeader,
    ItensHeader,
    AmountHeader,
    ValueHeader,
    ColumnsContainer,
    TimesColumn,
    BoxTitle,
    TimeBox,
    BoxValue,
    InsideColumnsContainer,
    InsideColumn,
    MapContainer,
    BikerIcon,
    TaxesContainer,
    TaxItem,
    TaxTitle,
    TaxValue,
} from './new-order-details.styles'

export default function NewOrderDetails(): JSX.Element {
    return (
        <OrderDetailsContainer>
            <TopRowDefault title="Detalhes do Pedido" onBackButtonClick={() => null} />
            <OrderDetailTopRow
                status={4}
                captureDate={'20/02/2021'}
                captureTime={'11:20'}
                orderNumber={2327}
                scheduleDate={'21/02/2021'}
                scheduleTime={'18:30'}
                problemDetail={'O Cliente não se encontra no local'}
                seeRouteClick={() => null}
            />
            <ContentContainer>
                <LeftColumn>
                    <InfoCard>
                        <EditButton>Editar</EditButton>
                        <InfoItem label="Loja" content="Chinatown Aldeota" big />
                        <InfoRow>
                            <BigSide>
                                <InfoItem label="Canal de Venda" content="Ifood" />
                            </BigSide>
                            <InfoItem label="N° do Pedido" content="5" />
                        </InfoRow>
                        <InfoItem label="Nome e ID do cliente" content="Samuel Batista Paixão - 204542" />
                        <InfoRow>
                            <BigSide>
                                <InfoItem label="E-mail" content="cidafurtado94@gmail.com" />
                            </BigSide>
                            <InfoItem label="Telefone" content="(85) 99899-1234" />
                        </InfoRow>
                        <InfoRow>
                            <BigSide>
                                <InfoItem label="Forma de Pagamento" content="Dinheiro - Pagamento na entrega" />
                            </BigSide>
                            <InfoItem label="Valor" content="R$ 39,90" />
                        </InfoRow>
                    </InfoCard>
                    <InfoCard>
                        <EditButton>Editar</EditButton>
                        <CardTitle>
                            <BagIcon />
                            <TitleText>Informações do Pedido</TitleText>
                        </CardTitle>
                        <InfoItem label="Observações da Entrega" content="Deixar o pedido na portaria do condomínio" />
                        <OrderTable>
                            <TableHeader>
                                <ItensHeader>Itens solicitados</ItensHeader>
                                <AmountHeader>Qtd:</AmountHeader>
                                <ValueHeader>Valor:</ValueHeader>
                            </TableHeader>
                            <ProductItem
                                id={213}
                                title={'Combo Fat Mike'}
                                observation={'Carne mal passada'}
                                amount={2}
                                value={74.9}
                            />
                            <ProductItem
                                id={213}
                                title={'Combo Fat Mike'}
                                observation={'Carne mal passada'}
                                amount={2}
                                value={74.9}
                            />
                        </OrderTable>
                    </InfoCard>
                    <br />
                </LeftColumn>
                <RightColumn>
                    <InfoCard>
                        <CardTitle>
                            <ClockIcon />
                            <TitleText>Linha do Tempo</TitleText>
                        </CardTitle>
                        <ColumnsContainer>
                            <StatusTimeline currentStatus={4} />
                            <TimesColumn>
                                <TimeBox>
                                    <BoxTitle>Produção</BoxTitle>
                                    <BoxValue>08:00</BoxValue>
                                </TimeBox>
                                <TimeBox>
                                    <BoxTitle>Coleta</BoxTitle>
                                    <BoxValue>08:00</BoxValue>
                                </TimeBox>
                                <TimeBox>
                                    <BoxTitle>Entrega</BoxTitle>
                                    <BoxValue>08:00</BoxValue>
                                </TimeBox>
                                <TimeBox>
                                    <BoxTitle>Total</BoxTitle>
                                    <BoxValue>08:00</BoxValue>
                                </TimeBox>
                            </TimesColumn>
                        </ColumnsContainer>
                    </InfoCard>
                    <InsideColumnsContainer>
                        <InsideColumn>
                            <InfoCard>
                                <EditButton>Editar</EditButton>
                                <CardTitle>
                                    <ClockIcon />
                                    <TitleText>Endereço</TitleText>
                                </CardTitle>
                                <MapContainer>mapa</MapContainer>
                                <InfoItem label="" content="Rua Mosenhor Otaviano de Castro, 1284 Fátima" />
                                <InfoRow>
                                    <InfoItem label="Complemento" content="Apto 1401" />
                                    <InfoItem label="CEP" content="60000-567" />
                                </InfoRow>
                                <InfoItem label="Referência" content="Próximo ao 23 BC" />
                            </InfoCard>
                        </InsideColumn>
                        <InsideColumn>
                            <InfoCard>
                                <EditButton>Editar</EditButton>
                                <CardTitle>
                                    <BikerIcon />
                                    <TitleText>Entregador</TitleText>
                                </CardTitle>
                                <BikerInfo
                                    name="Samuel Batista Paixão"
                                    vehicle="Bicicleta"
                                    photo="https://i.pravatar.cc/150?img=15"
                                />
                            </InfoCard>
                            <InfoCard>
                                <EditButton>Editar</EditButton>
                                <CardTitle>
                                    <BikerIcon />
                                    <TitleText>Tarifas</TitleText>
                                </CardTitle>
                                <TaxesContainer>
                                    <TaxItem>
                                        <TaxTitle>Tarifa Padrão</TaxTitle>
                                        <TaxValue>R$ 4,00</TaxValue>
                                    </TaxItem>
                                    <TaxItem>
                                        <TaxTitle>Tarifa Adicional</TaxTitle>
                                        <TaxValue>R$ 4,00</TaxValue>
                                    </TaxItem>
                                    <TaxItem>
                                        <TaxTitle>Total</TaxTitle>
                                        <TaxValue>R$ 8,00</TaxValue>
                                    </TaxItem>
                                </TaxesContainer>
                            </InfoCard>
                        </InsideColumn>
                    </InsideColumnsContainer>
                </RightColumn>
            </ContentContainer>
        </OrderDetailsContainer>
    )
}
