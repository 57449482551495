import { useEffect, useState } from 'react'

import Checkbox from 'components/_common/checkbox/checkbox'

import {
    ContentTitle,
    NotifyProblemModalBackground,
    NotifyProblemModalBox,
    NotifyProblemModalContainer,
    NotifyProblemModalContent,
    CloseButton,
    CloseContainer,
    NotifyProblemHeader,
    FormContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    TextContent,
    OrderID,
    CustomerName,
    OrderInfo,
    OrderDetails,
    FormTitle,
} from './notify-problem-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
}

const NotifyProblemModal: React.FC<Props> = ({ isActive, closeClick }) => {
    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)
    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <NotifyProblemModalContainer isDisplayed={isDisplayed}>
            <NotifyProblemModalBox isEffectActive={isEffectActive}>
                <NotifyProblemHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Notificar Problema na coleta</ContentTitle>
                </NotifyProblemHeader>
                <NotifyProblemModalContent>
                    <TextContent>
                        <div>O pedido será removido da sua rota</div>
                        <br />
                        <OrderInfo>
                            <OrderID>Pedido #21049</OrderID>
                            <CustomerName>Michelle Souza</CustomerName>
                        </OrderInfo>
                        <OrderDetails>
                            <div>Mc Donalds</div>
                            <div>Ifood - R$ 35,40</div>
                            <div>Pagamento Online</div>
                        </OrderDetails>
                    </TextContent>
                    <FormTitle>Problema(s) ocorrido(s):</FormTitle>
                    <FormContainer>
                        <Checkbox checkboxTitle="Opção 1" />
                        <Checkbox checkboxTitle="Opção 2" />
                        <Checkbox checkboxTitle="Opção 3" />
                        <Checkbox checkboxTitle="Opção 4" />
                    </FormContainer>
                </NotifyProblemModalContent>
                <ButtonRow>
                    <TextButton>Cancelar</TextButton>
                    <ConfirmButton>Notificar</ConfirmButton>
                </ButtonRow>
            </NotifyProblemModalBox>
            <NotifyProblemModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </NotifyProblemModalContainer>
    )
}

export default NotifyProblemModal
