import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import TextArea from 'components/text-area'
import TextInputForm from 'components/text-input-form'

import { getFormInputError, showErrors } from 'helpers'
import { useAuth, useAxiosRequest } from 'hooks'
import api from 'services/api'
import { SideDishCategory } from 'types'

const SideDishCategoryForm: React.FC<RouteComponentProps<{ side_dish_category_id: string }>> = ({
    history,
    match: { params },
}) => {
    const { basePath, store } = useAuth()
    const modalMessage = useRef<ModalMessageRef>(null)

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const { loading } = useAxiosRequest<SideDishCategory>({
        api,
        url: `/painel/side-dish-category/${params.side_dish_category_id}`,
        executeOnStart: params.side_dish_category_id ? true : false,
        onComplete: ({ data }) => {
            const { id } = data

            setValues({ ...values, ...data, id: id.toString() })
        },
        onError: onRequestError,
    })

    const submitSideDishCategory = useAxiosRequest({
        api,
        method: params.side_dish_category_id ? 'put' : 'post',
        executeOnStart: false,
        onComplete: () => {
            setModalMessageData({
                title: 'Sucesso!',
                message: `Categoria de acompanhamento ${
                    params.side_dish_category_id ? 'editada' : 'criada'
                } com sucesso!`,
                onClose: () => history.push(`${basePath}/gestao-marketplace/categorias-acompanhamento`),
            })

            modalMessage.current?.openModal()
        },
        onError: onRequestError,
    })

    const { isSubmitting, errors, getFieldProps, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                id: '',
                name: '',
                description: '',
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome da categoria é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    await submitSideDishCategory.request({
                        url: values.id ? `/painel/side-dish-category/${values.id}` : '/painel/side-dish-category',
                        data: {
                            ...values,
                            store_id: store.id,
                        },
                    })

                    resetForm()
                } finally {
                    setSubmitting(false)
                }
            },
        })

    return (
        <div>
            <RowTitle
                title={
                    params.side_dish_category_id
                        ? 'Editar Categoria de acompanhamento'
                        : 'Criar categoria de acompanhamento'
                }
                backClick={() => history.push(`${basePath}/gestao-marketplace/categorias-acompanhamento`)}
            />

            <ModalLoading visible={isSubmitting || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="marketplace-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome*"
                        {...getFieldProps('name')}
                        msgErro={getFormInputError('name', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <TextArea
                        label="Descrição (opcional)"
                        {...getFieldProps('description')}
                        msgErro={getFormInputError('description', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="radio-group">
                            <label>Status</label>
                            <div className="radio-group-row">
                                <InputRadio
                                    id="true-status"
                                    label="Ativa"
                                    checked={Boolean(values.status)}
                                    onClick={() => setFieldValue('status', 1)}
                                />

                                <InputRadio
                                    id="false-status"
                                    label="Inativa"
                                    checked={!values.status}
                                    onClick={() => setFieldValue('status', 0)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ButtonForm type="submit" buttonText="Salvar" style={{ marginTop: 30 }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default SideDishCategoryForm
