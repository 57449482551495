import { forwardRef, memo, useImperativeHandle, useCallback, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import DropzoneInput from 'components/dropzone-input/dropzone-input'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'
import colors from 'themes/colors'
import { SalesChannel } from 'types'
import { ISalesChannel } from 'types/sales-channel'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './modal-sales-channel.styled'

export interface ModalCreateSalesChannelRef {
    show(): void
    close(): void
    setSalesChannel(salesChannel: ISalesChannel): void
}

type Props = {
    onClose?: (isRefresh?: boolean) => void
    onRemove?(id: number): () => void
}

type IForm = {
    id?: number
    name: string
    color: string
    status: number
    logo?: File | string
}

const statusOptions = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 },
]

const ModalSalesChannel = memo(
    forwardRef<ModalCreateSalesChannelRef, Props>(({ onClose, onRemove }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [salesChannel, setSalesChannel] = useState<ISalesChannel | null>()

        const isEdit = useMemo(() => {
            return !!salesChannel
        }, [salesChannel])

        const modalTitle = useMemo(() => {
            return `${!isEdit ? 'Criar' : 'Gerenciar'} Canal de Vendas.`
        }, [isEdit])

        const { isSubmitting, errors, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
            useFormik<IForm>({
                initialValues: {
                    name: '',
                    color: colors.light_primary_color,
                    status: 1,
                    logo: '',
                },
                validationSchema: Yup.object().shape({
                    name: Yup.string().trim().required('Insira o Nome do Canal'),
                    color: Yup.string().trim().required('Insira a Cor'),
                    status: Yup.string().trim().required('Selecione o Status'),
                }),
                onSubmit: async values => {
                    try {
                        if (!isEdit) {
                            const { data: saleChannel } = await api.post<SalesChannel>(`painel/sales-channel`, {
                                ...values,
                            })

                            if (saleChannel.id && values.logo instanceof File) {
                                await _uploadImage(saleChannel.id, values.logo)
                            }
                        } else {
                            await api.put(`painel/sales-channel/${values.id}`, {
                                ...values,
                            })

                            if (values.logo instanceof File) {
                                await _uploadImage(values.id, values.logo)
                            }
                        }

                        setSuccessModal({
                            title: 'Sucesso',
                            subtitle: !isEdit
                                ? 'Canal de Venda criado com sucesso!'
                                : 'Canal de Venda atualizado com sucesso!',
                            singleButtonClick: _onClose(true),
                        })
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro!',
                            subtitle: showErrors(error),
                            singleButtonText: 'Revisar alterações',
                        })
                    }
                },
            })

        useImperativeHandle(
            ref,
            () => ({
                show: (): void => {
                    resetForm()
                    setSalesChannel(null)
                    lateralModalBaseRef.current?.show()
                },
                close: (): void => {
                    resetForm()
                    lateralModalBaseRef.current?.close()
                },
                setSalesChannel: (salesChannel: ISalesChannel): void => {
                    setSalesChannel(salesChannel)
                    const formData = { ...salesChannel, status: Number(salesChannel.status) }
                    setValues(formData)
                },
            }),
            []
        )

        const _onClose = useCallback(
            (isRefresh?: boolean) => {
                return () => {
                    if (onClose) {
                        onClose(isRefresh)
                    }

                    setSalesChannel(null)
                    lateralModalBaseRef.current?.close()
                }
            },
            [onClose, resetForm]
        )

        const _handleChange = useCallback((key: string) => {
            return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(key, value)
            }
        }, [])

        const _handleChangeFile = useCallback((file: File) => setFieldValue('logo', file), [setFieldValue])

        const _uploadImage = useCallback(async (channelId: number, logo: File) => {
            const formData = new FormData()
            formData.append('logo', logo)
            await api.post(`/painel/sales-channel/${channelId}/logo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        }, [])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle} onClose={onClose}>
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Nome do Canal"
                                    inputProps={{
                                        value: values.name,
                                        onChange: _handleChange('name'),
                                    }}
                                    errorMessage={touched.name && errors.name}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Cor"
                                    type="color"
                                    inputProps={{
                                        value: values.color,
                                        onChange: _handleChange('color'),
                                    }}
                                    errorMessage={touched.color && errors.color}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Status"
                                    type="select"
                                    options={statusOptions}
                                    inputProps={{
                                        value: values.status,
                                        onChange: _handleChange('status'),
                                    }}
                                    errorMessage={touched.status && errors.status}
                                />
                            </InputContainer>
                            <InputContainer>
                                <DropzoneInput label="Logo" onChange={_handleChangeFile} preview={values.logo} />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <div>
                            {isEdit && (
                                <TextButton isRemove onClick={onRemove(salesChannel.id)}>
                                    <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={_onClose()}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting} />
            </LateralModalBase>
        )
    })
)

export { ModalSalesChannel }
