import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    width: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Avatar = styled.img<{ color?: string }>`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4px;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    object-fit: cover;
    z-index: 2;
`
const Triangle = styled.div<{ color?: string }>`
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top: 12px solid ${({ theme, color }) => (color ? color : theme.colors.primary)};
    border-bottom: 0;
    margin-top: -4px;
`

export { Container, Avatar, Triangle }
