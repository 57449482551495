import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

const ModalImage = props => {
    if (!props.visible) return <div />

    return (
        <div className="modal-image">
            <div className="modal-image-bg" />
            <div className="modal-image-content">
                <div className="modal-close" onClick={props.onClose}>
                    <FontAwesomeIcon size="lg" icon="times" />
                </div>
                <div className="modal-image-box">
                    <img src={props.image} alt="" />
                </div>
            </div>
        </div>
    )
}

export default ModalImage
