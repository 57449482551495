import { useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Snackbar } from 'components/_common'
import InputRadio from 'components/input-radio'
import SelectInputForm from 'components/select-input-form'

import { useUI } from 'contexts'
import { setFormFieldErrors, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PerformanceFilters } from 'types'

import {
    ContentTitle,
    ExportReportsModalBackground,
    ExportReportsModalBox,
    ExportReportsModalContainer,
    ExportReportsModalContent,
    CloseButton,
    CloseContainer,
    ExportReportsHeader,
    ButtonRow,
    TextButton,
    ConfirmButton,
    FormSection,
    RadiosError,
    FormSectionLabel,
    SelectContainer,
} from './export-reports-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
    filters: PerformanceFilters
}

const ExportReportsModal: React.FC<Props> = ({ isActive, closeClick, filters }) => {
    const { setLoading, setSnackbar, snackbarProps, closeSnackbar } = useUI()
    const [isEffectActive, setIsEffectActive] = useState(false)
    const { mall, store } = useAuth()
    const [isDisplayed, setIsDisplayed] = useState(false)
    const [salesChannel, setSalesChannel] = useState([])
    const [storeFileName, setStoreFileName] = useState('')

    const getSalesChannel = async () => {
        const selectOptions = []
        const { data } = await api.get(`/painel/sales-channels`, {
            params: {
                per_page: -1,
            },
        })
        selectOptions.push({ name: 'Todos', value: 'null' })
        data.items.forEach(item => {
            selectOptions.push({ name: item.name, value: item.name })
        })

        setSalesChannel(Array.from(selectOptions))
    }

    useEffect(() => {
        getSalesChannel()
    }, [])

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    const getStoreByID = async (id: number) => {
        const { data } = await api.get(`/painel/store/${id}`)
        setStoreFileName(data.name)
    }

    useEffect(() => {
        if (filters.store_id && !store.id) {
            getStoreByID(filters.store_id)
        }

        if (!filters.store_id && store.id) {
            getStoreByID(store.id)
        }
    }, [filters, store])

    const { errors, handleSubmit, isValid, setFieldValue, values } = useFormik({
        initialValues: {
            type: 'shopkeeper',
            download: 'pdf',
            channel: 'null',
        },
        validationSchema: Yup.object().shape({
            type: Yup.string().trim().required('Escolha um tipo de exportação'),
            download: Yup.string().trim().required('Escolha um downloado de exportação'),
            channel: Yup.string().trim().required('Escolha um canal de venda'),
        }),

        onSubmit: async (values, { setFieldError }) => {
            try {
                setLoading(true)

                const response = await api.get('/painel/report/orders', {
                    responseType: 'blob',
                    params: {
                        ...filters,
                        type: values.type === 'internal' ? null : values.type,
                        download: values.download,
                        sale_channel: values.channel === 'null' ? null : values.channel,
                        store_id: filters.store_id ? filters.store_id : store ? store?.id : null,
                        mall: filters.mall_id ? filters.mall_id : mall?.id ? mall?.id : null,
                    },
                })

                const downloadURL = window.URL.createObjectURL(response)

                const timestamp = `${format(filters.start_date, 'DD-MM-YYYY')} a ${format(
                    filters.end_date,
                    'DD-MM-YYYY'
                )}`
                const filename = `${mall?.name?.length > 0 ? mall?.name : 'Relatório'} - ${
                    storeFileName.length > 0 ? `${storeFileName} - ` : ''
                } ${timestamp}.${values.download}`

                const link = document.createElement('a')
                link.href = downloadURL
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                link.remove()
                setTimeout(() => {
                    window.URL.revokeObjectURL(downloadURL)
                }, 100)
                setSnackbar({ message: 'Relatório Exportado', type: 'success' })
            } catch (error) {
                if (error?.response?.status !== 422) {
                    setSnackbar({ message: showErrors(error), type: 'error' })
                    return
                }
                setFormFieldErrors(error, setFieldError)
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <>
            <Snackbar {...snackbarProps} closeClick={closeSnackbar} />
            <ExportReportsModalContainer isDisplayed={isDisplayed}>
                <ExportReportsModalBox isEffectActive={isEffectActive}>
                    <ExportReportsHeader>
                        <CloseContainer onClick={closeClick}>
                            <CloseButton />
                        </CloseContainer>
                        <ContentTitle>Exportar relatório</ContentTitle>
                    </ExportReportsHeader>
                    <ExportReportsModalContent>
                        <FormSection>
                            <FormSectionLabel>Selecione o canal de venda</FormSectionLabel>
                            <SelectContainer>
                                <SelectInputForm
                                    data={salesChannel}
                                    value={values.channel}
                                    onChange={e => setFieldValue('channel', e.target.value)}
                                    msgErro={errors.channel}
                                />
                            </SelectContainer>
                        </FormSection>
                        <FormSection>
                            <FormSectionLabel>Formato do relatório</FormSectionLabel>
                            {errors.download && <RadiosError>{errors.download}</RadiosError>}
                            <InputRadio
                                checked={values.download === 'pdf'}
                                id="pdf"
                                label="PDF"
                                onClick={() => setFieldValue('download', 'pdf')}
                            />
                            <InputRadio
                                checked={values.download === 'xlsx'}
                                id="xlsx"
                                label="XLSX"
                                onClick={() => setFieldValue('download', 'xlsx')}
                            />
                        </FormSection>
                        {values.download === 'xlsx' && !store?.id && (
                            <FormSection>
                                <FormSectionLabel>Selecione o tipo de exportação</FormSectionLabel>
                                {errors.type && <RadiosError>{errors.type}</RadiosError>}
                                <InputRadio
                                    checked={values.type === 'shopkeeper'}
                                    id="shopkeeper"
                                    label="Relatório do Lojista"
                                    onClick={() => setFieldValue('type', 'shopkeeper')}
                                />

                                <InputRadio
                                    checked={values.type === 'internal'}
                                    id="internal"
                                    label="Relatório Interno"
                                    onClick={() => setFieldValue('type', 'internal')}
                                />
                            </FormSection>
                        )}
                    </ExportReportsModalContent>
                    <ButtonRow>
                        <TextButton onClick={closeClick}>Cancelar</TextButton>
                        <ConfirmButton isActive={isValid} onClick={() => handleSubmit()}>
                            Exportar Relatório
                        </ConfirmButton>
                    </ButtonRow>
                </ExportReportsModalBox>
                <ExportReportsModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
            </ExportReportsModalContainer>
        </>
    )
}

export default ExportReportsModal
