import { forwardRef, memo, useImperativeHandle } from 'react'

import ModalLoading from 'components/modal-loading'

import { ChangeOrderTypeContextProvider } from 'contexts/change-order-type-context'
import { Order } from 'types'

import { ChangeTypeContent, DeliveryContent, IndoorContent } from './contents'
import { useModalChangeTypeOrderController } from './modal-change-type-order.controller'
import { Box, Container, Content } from './modal-change-type-order.styled'

export type ModalChangeTypeOrderRef = {
    show(order: Order): void
}

type ModalChangeTypeOrderContentProps = {
    ref: React.ForwardedRef<ModalChangeTypeOrderRef>
}
const ModalChangeTypeOrderContent: React.FC<ModalChangeTypeOrderContentProps> = memo(
    forwardRef<ModalChangeTypeOrderRef, any>((_, ref) => {
        const { type, visible, show, loading, _setShow, _setOrder } = useModalChangeTypeOrderController()

        useImperativeHandle(ref, () => ({
            show: order => {
                _setOrder(order)
                _setShow(true)
            },
        }))

        if (!visible) {
            return null
        }

        return (
            <Container>
                <Box show={show}>
                    <Content>{type === undefined && <ChangeTypeContent />}</Content>
                    <Content>{type === 'indoor' && <IndoorContent />}</Content>
                    <Content>{type === 'delivery' && <DeliveryContent />}</Content>
                    <ModalLoading visible={loading} />
                </Box>
            </Container>
        )
    })
)

const ModalChangeTypeOrder = memo(
    forwardRef<ModalChangeTypeOrderRef, any>((_, ref) => {
        return (
            <ChangeOrderTypeContextProvider>
                <ModalChangeTypeOrderContent ref={ref} />
            </ChangeOrderTypeContextProvider>
        )
    })
)

export { ModalChangeTypeOrder }
