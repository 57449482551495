import styled from 'styled-components'

const OrderSummaryContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const OrderSummaryId = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`

const OrderSummaryCustomer = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
`

const OrderSummaryInfo = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGray};
`

export { OrderSummaryContainer, OrderSummaryCustomer, OrderSummaryId, OrderSummaryInfo }
