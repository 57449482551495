import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

export const ContainerFile = styled.div`
    display: flex;
    align-items: center;
    background-color: #cecece;
    color: #333;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
`

export const IconFile = styled(FontAwesomeIcon)<{ denial?: boolean }>`
    padding: 2px 4px;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

    ${({ denial }) =>
        denial &&
        css`
            &:hover {
                color: ${({ theme }) => theme.colors.errorMessage};
            }
        `}
`

export const ClickableArea = styled.div`
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
    display: flex;
    flex: 1%;
`

export const FileName = styled.div`
    font-weight: bold;
    font-size: 14px;
    flex: 1;
    margin: 0px 5px;
`

export const InputFile = styled.input`
    flex: 1;
    outline: 0;
    border: none;
    border-bottom: 1px solid #333;
    background-color: transparent;
    &:focus-visible {
        border: none;
        border-bottom: 1px solid #333;
    }
    &:focus {
        border: none;
        border-bottom: 1px solid #333;
    }
`
