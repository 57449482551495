import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: 0.75rem;
    padding: 1rem 0.75rem;
`

export const StoreInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 20px;
    padding-top: 0;
    flex: 1;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        button {
            align-self: end;
        }
    }
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0.75rem 1.25rem;

    background-color: ${({ theme }) => theme.colors.white};

    border-radius: 0.5rem;
`

export const HeaderTitle = styled.h3`
    padding: 0;
    margin: 0;

    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primary};
`

export const Content = styled.div`
    display: flex;

    padding: 1.25rem;
    flex: 1;

    background-color: ${({ theme }) => theme.colors.white};
`

export const InputSection = styled.div``

export const StoreInfoTitle = styled.div``
