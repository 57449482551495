import { useState, useRef, useEffect } from 'react'
import DayPicker from 'react-day-picker'

import moment from 'moment'

import colors from '../../themes/colors'
import MensagemErro from '../mensagem-erro'

import 'react-day-picker/lib/style.css'
import './style.scss'

const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
]
const WEEKDAYS_LONG = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
const WEEKDAYS_SHORT = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

const DatepickerInput = props => {
    const [showDatepicker, toggleDatepicker] = useState(false)
    const inputWrapperRef = useRef()

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
    }, [])

    const handleOutsideClick = event => {
        if (inputWrapperRef.current && !inputWrapperRef.current.contains(event.target)) {
            toggleDatepicker(false)
        }
    }

    const modifiers = {
        highlighted: props.value,
    }

    const modifiersStyles = {
        highlighted: {
            color: 'white',
            backgroundColor: colors.primary_color,
        },
    }

    const getFormattedDate = date => {
        const dateFormatted = moment(date).format('DD/MM/YYYY')

        if (dateFormatted === 'Invalid date' || dateFormatted === 'Data inválida') {
            return ''
        } else if (dateFormatted === moment().format('DD/MM/YYYY')) {
            return `Hoje (${moment(date).format('DD/MM/YYYY')})`
        }

        return dateFormatted
    }

    return (
        <div className="datepicker-spacing" ref={inputWrapperRef}>
            <div className={`datepicker-field ${props.fieldHeight ? `field-height` : ''}`}>
                {props.label && <label>{props.label}</label>}
                <input
                    onClick={() => toggleDatepicker(!showDatepicker)}
                    onChange={() => {}}
                    value={getFormattedDate(props.value)}
                    placeholder={props.placeholder}
                />
                <MensagemErro msgColor={props.msgColor} msgErro={props.msgErro} />
            </div>

            {showDatepicker && (
                <div className="datepicker-container">
                    <DayPicker
                        locale={'br'}
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        firstDayOfWeek={0}
                        onDayClick={props.onChange}
                        selectedDay={props.value}
                        modifiers={modifiers}
                        modifiersStyles={modifiersStyles}
                        showOutsideDays
                        fixedWeeks
                    />
                </div>
            )}
        </div>
    )
}

export default DatepickerInput
