import { Order } from 'types'

import { getTimeDiff } from '.'

function getOrderWithTimes(order: Order): Order {
    return {
        ...order,
        production_time: getTimeDiff(order.start_production || order.birth, order.end_production),
        delivery_time: getTimeDiff(order.end_production, order.end_delivery),
        total_time: getTimeDiff(order.start_production || order.birth || order.created_at),
        time_ready: getTimeDiff(order.updated_at),
    }
}

export { getOrderWithTimes }
