import { Component } from 'react'
import './style.scss'

export default class OrderModerationTableHeader extends Component {
    render() {
        return (
            <div className="table-head-container">
                <div className="table-head-item id">ID</div>
                <div className="table-head-item store">Loja</div>
                <div className="table-head-item channel">Canal</div>
                <div className="table-head-item client">Cliente e Localidade</div>
                <div className="table-head-item value">Valor do Pedido</div>
                {/* <div className="table-head-item value">Tempo de pronto</div> */}
                <div className="table-head-item button-single" />
                <div className="table-head-item button-row"> </div>
            </div>
        )
    }
}
