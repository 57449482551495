import './style.scss'

const SmallOrderCard = ({ statusColor, orderNumber, clientName, clientAddress, totalTime }) => {
    return (
        <div className="small-order-card" style={{ borderLeftColor: statusColor ? statusColor : '#6c1741' }}>
            <div className="order-number">#{orderNumber}</div>
            <div className="client-name">{clientName}</div>
            <div className="client-address">{clientAddress}</div>
            <div className="total-time">Tempo total: {totalTime}</div>
        </div>
    )
}

export default SmallOrderCard
