import { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { External } from 'containers/individual-report-page/individual-report-page.styles'

import { useAuth, useDataFetching } from 'hooks'
import { Creators as AuthActions } from 'store/reducers/auth'
import { PagedList, Store, Mall } from 'types'

import { Accordion } from '..'

import {
    ContentTitle,
    ContextTitle,
    ContextModalBackground,
    ContextModalBox,
    ContextModalContainer,
    ContextModalContent,
    CloseButton,
    CloseContainer,
    PersonalDataHeader,
    MallsListing,
    StoreItem,
    ContextListing,
    ContextItem,
    StoreIcon,
    ChevronIcon,
    StoreName,
    NewTab,
} from './context-modal.styles'

interface Props {
    isActive: boolean
    closeClick(): void
    setIsActive(active: boolean): void
}

interface GroupedListItem {
    mall: Mall
    stores: Array<Store>
}
const ContextModal: React.FC<Props> = ({ isActive, closeClick, setIsActive }) => {
    const dispatch = useDispatch()

    const { user } = useAuth()

    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    const history = useHistory()

    const { data: malls } = useDataFetching<PagedList<Mall>>(user?.isMallManager ? '/painel/malls' : null, {
        params: {
            order_by: 'name',
            status: [1],
            per_page: 100,
        },
    })

    const { data: stores } = useDataFetching<PagedList<Store>>(user?.isMallManager ? '/painel/stores' : null)

    function getGroupedList() {
        const groupedList = [] as GroupedListItem[]

        malls?.items.forEach(mall => {
            groupedList.push({ mall: mall, stores: [] })
        })

        stores?.items.forEach(store => {
            groupedList.forEach((listItem, index) => {
                if (listItem?.mall?.id === store?.mall?.id) {
                    groupedList[index].stores.push(store)
                }
            })
        })

        return groupedList
    }

    const goToNextScreen = async (selectedMall: number, selectedStore: number | null, blank?: boolean) => {
        try {
            const mall = malls?.items.find(mall => mall.id === Number(selectedMall))
            const store = stores?.items.find(mall => mall.id === Number(selectedStore))

            if (blank) {
                window.open(
                    store ? `/${mall?.slug}/${store.slug}/visao-geral` : `/${mall?.slug}/rotas/visao-geral`,
                    '_blank'
                )
            } else {
                dispatch(AuthActions.setMall(mall || null))
                dispatch(AuthActions.setStore(store || null))

                history.push(store ? `/${mall?.slug}/${store.slug}/visao-geral` : `/${mall?.slug}/rotas/visao-geral`)
            }

            setIsActive(false)
        } catch (error) {
            console.log(error)
        }
    }

    function manageClick(route: string) {
        setIsActive(false)
        history.push(route)
    }

    const goToStore = async (store: Store, blank?: boolean) => {
        try {
            dispatch(AuthActions.setStore(store || null))
            dispatch(AuthActions.setMall(store.mall || null))
            const url = `/${store.mall?.slug}/${store.slug}/visao-geral/em-andamento`
            if (blank) {
                window.open(url, '_blank')
            } else {
                history.push(url)
                setIsActive(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const groupedList = useMemo(getGroupedList, [malls, stores])

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden'
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            document.body.style.overflow = 'auto'
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <ContextModalContainer isDisplayed={isDisplayed}>
            <ContextModalBox isEffectActive={isEffectActive}>
                <PersonalDataHeader>
                    <CloseContainer onClick={closeClick}>
                        <CloseButton />
                    </CloseContainer>
                    <ContentTitle>Trocar Visualização</ContentTitle>
                </PersonalDataHeader>
                <ContextModalContent>
                    <MallsListing>
                        {groupedList.map((item, index) => (
                            <Accordion
                                accordionTitle={item.mall.name}
                                isPrivate={item.mall.type === 'private'}
                                key={index}
                            >
                                <StoreItem>
                                    <StoreName onClick={() => goToNextScreen(item.mall.id, null)}>
                                        Roteirização
                                    </StoreName>
                                    <NewTab onClick={() => goToNextScreen(item.mall.id, null, true)}>
                                        <External />
                                    </NewTab>
                                </StoreItem>
                                {item.stores.map((store, storeIndex) => (
                                    <StoreItem key={storeIndex}>
                                        <StoreName onClick={() => goToNextScreen(item.mall.id, store.id)}>
                                            {store.name}
                                        </StoreName>
                                        <NewTab onClick={() => goToNextScreen(item.mall.id, store.id, true)}>
                                            <External />
                                        </NewTab>
                                    </StoreItem>
                                ))}
                            </Accordion>
                        ))}
                        {user?.isShopkeeper && user?.stores && (
                            <Accordion accordionTitle="Suas lojas" open>
                                {user.stores.map((store, storeIndex) => (
                                    <StoreItem key={storeIndex}>
                                        <StoreName onClick={() => goToStore(store)}>{store.name}</StoreName>
                                        <NewTab onClick={() => goToStore(store, true)}>
                                            <External />
                                        </NewTab>
                                    </StoreItem>
                                ))}
                            </Accordion>
                        )}
                    </MallsListing>
                    {user?.isAdmin && !user?.isManager && (
                        <ContextListing>
                            <ContextItem onClick={() => manageClick('/painel-malls')}>
                                <StoreIcon />
                                <ContextTitle>Gerenciar Malls</ContextTitle>
                                <ChevronIcon />
                            </ContextItem>
                            <ContextItem onClick={() => manageClick('/painel-entregadores')}>
                                <StoreIcon />
                                <ContextTitle>Gerenciar Entregadores</ContextTitle>
                                <ChevronIcon />
                            </ContextItem>
                            <ContextItem onClick={() => manageClick('/marketplace/gestao-marketplace')}>
                                <StoreIcon />
                                <ContextTitle>Gerenciar Marketplace</ContextTitle>
                                <ChevronIcon />
                            </ContextItem>
                            <ContextItem onClick={() => manageClick('/financeiro')}>
                                <StoreIcon />
                                <ContextTitle>Gerenciar Financeiro</ContextTitle>
                                <ChevronIcon />
                            </ContextItem>
                        </ContextListing>
                    )}
                </ContextModalContent>
            </ContextModalBox>
            <ContextModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </ContextModalContainer>
    )
}

export default ContextModal
