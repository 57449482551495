import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { formatCurrency } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InfoContent,
    InfoSubtitle,
    InfoTitle,
    InputContainer,
    MessageError,
    OutsideContainer,
    Row,
    TextButton,
} from './picker-tax-modal.styled'

export type PickerTaxModalRef = {
    show(): void
    close(): void
}

type IForm = {
    type?: 'fixed' | 'percentage'
    value?: number
    status?: boolean
}

const PickerTaxModal = memo(
    forwardRef<PickerTaxModalRef>((_, ref) => {
        const { mall, setMall } = useAuth()
        const { setErrorModal, setSuccessModal } = useUI()
        const lateralModalBaseRef = useRef<LateralModalBase>()

        const {
            isSubmitting,
            values,
            errors,
            touched,
            getFieldProps,
            setValues,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
        } = useFormik<IForm>({
            initialValues: {},
            validationSchema: Yup.object().shape({
                type: Yup.string().nullable().required('Tipo é obrigatório'),
                value: Yup.number().nullable().required('Valor é obrigatório'),
                status: Yup.boolean().nullable().required('Status é obrigatório'),
            }),
            onSubmit: async values => {
                try {
                    const { data } = await api.put(`/painel/mall/${mall.id}`, {
                        configs: {
                            marketplace: {
                                service_tax: {
                                    type: values.type,
                                    value: values.value,
                                    status: !!values.status,
                                },
                            },
                        },
                    })
                    setMall(data)

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Tarifa de Atendimento presencial Atualizada',
                        singleButtonClick: lateralModalBaseRef.current?.close,
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: 'Não foi possível atualizar tarifa de atendimento presencial',
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            []
        )

        useEffect(() => {
            setValues(mall?.configs?.marketplace?.service_tax || {})
        }, [mall])

        useEffect(() => {
            console.log({ errors })
        }, [errors])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={'Taxa de Atendimento Presencial'}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InfoContent>
                                    <InfoTitle>Taxa de serviço por atendimento na praça</InfoTitle>
                                    <InfoSubtitle>
                                        A tarifa de atendimento presencial será cobrada sempre que o usuário realizar
                                        uma compra e optar por receber o pedido em uma das zonas da praça de
                                        alimentação.
                                    </InfoSubtitle>
                                </InfoContent>
                            </InputContainer>
                            <InputContainer>
                                <Checkbox
                                    checkboxTitle="Fixo"
                                    inputProps={{
                                        onChange: () => {
                                            setFieldValue('value', undefined)
                                            setFieldValue('type', 'fixed')
                                            setFieldTouched('type', true)
                                        },
                                        value: values.type === 'fixed' ? 1 : 0,
                                    }}
                                />
                                <Checkbox
                                    checkboxTitle="Percentual"
                                    inputProps={{
                                        onChange: () => {
                                            setFieldValue('value', undefined)
                                            setFieldValue('type', 'percentage')
                                            setFieldTouched('type', true)
                                        },
                                        value: values.type === 'percentage' ? 1 : 0,
                                    }}
                                />
                                {!!errors.type && <MessageError>{errors.type}</MessageError>}

                                {values?.type === 'fixed' && (
                                    <InputItem
                                        type="currency"
                                        labelText="Valor da Taxa"
                                        inputProps={{
                                            ...getFieldProps('value'),
                                            value: formatCurrency(values.value),
                                        }}
                                        footer={
                                            <InfoSubtitle>
                                                Este valor será cobrado por compra, independente do pedido ser de uma
                                                única loja ou de várias.
                                            </InfoSubtitle>
                                        }
                                        errorMessage={errors.value}
                                    />
                                )}
                                {values?.type === 'percentage' && (
                                    <InputItem
                                        type="number"
                                        labelText="Valor da Taxa (%)"
                                        inputProps={{
                                            ...getFieldProps('value'),
                                            value: values.value,
                                        }}
                                        footer={
                                            <InfoSubtitle>
                                                Este valor será cobrado por compra, independente do pedido ser de uma
                                                única loja ou de várias.
                                            </InfoSubtitle>
                                        }
                                        errorMessage={errors.value}
                                    />
                                )}
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={[
                                        { label: 'Ativo', value: '1' },
                                        { label: 'Inativo', value: '0' },
                                    ]}
                                    inputProps={{
                                        onChange: ev => {
                                            const value = ev.target.value
                                            setFieldValue('status', !!Number(value))
                                            setFieldTouched('status', true)
                                        },
                                        value: values.status ? '1' : '0',
                                    }}
                                    errorMessage={errors.status}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{'Salvar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
        )
    })
)

export { PickerTaxModal }
