import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const LoadingMessageContainer = styled.div`
    margin: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px;
    min-height: 300px;
`

export const Message = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: bold;
`

export const LoadingIcon = styled(FontAwesomeIcon).attrs({
    icon: faSpinner,
})`
    margin-bottom: 20px;
    font-size: 24px;
    animation-name: spin;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`
