import { useMemo } from 'react'

import { formatCurrency } from 'helpers'
import { OrderItem } from 'types'

import {
    ItemAmount,
    ItemID,
    ItemObservations,
    ItemTextContent,
    ItemTitle,
    ItemValue,
    ObservationContent,
    ObservationsTitle,
    OrderItemContainer,
} from './table-order-item.styles'

interface Props {
    orderItem: OrderItem & { parts?: any[] }
}

const TableOrderItem: React.FC<Props> = ({ orderItem }) => {
    const subitems = useMemo(() => {
        if (orderItem.parts) {
            return (orderItem?.parts || []).reduce((prev, current) => {
                return [...prev, ...(current?.side_dishes || [])]
            }, [])
        }

        return orderItem.subitems || []
    }, [orderItem])

    return (
        <OrderItemContainer>
            <ItemID>{orderItem.cod || '-'}</ItemID>
            <ItemTextContent>
                <ItemTitle>{orderItem.name}</ItemTitle>
                {(orderItem.obs || orderItem.observation) && (
                    <ItemObservations>
                        <ObservationsTitle>Observações:</ObservationsTitle>
                        <ObservationContent>{orderItem.obs || orderItem.observation}</ObservationContent>
                    </ItemObservations>
                )}
                {subitems && (
                    <ItemObservations>
                        <ObservationsTitle>ACOMPANHAMENTOS:</ObservationsTitle>
                        {subitems.map((subitem, index) => (
                            <ObservationContent key={index}>
                                {subitem.quantity}x {subitem.name}
                            </ObservationContent>
                        ))}
                    </ItemObservations>
                )}
            </ItemTextContent>
            <ItemAmount>x{orderItem.quantity}</ItemAmount>
            <ItemValue>{formatCurrency(orderItem.price)}</ItemValue>
        </OrderItemContainer>
    )
}

export default TableOrderItem
