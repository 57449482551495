import React, { memo, useMemo } from 'react'

import { DefaultButton } from 'components/default-button/default-button'

import { IPayment } from 'types/payment'

import { getPaymentType } from '../../../../../helpers'

import {
    Actions,
    Color,
    ColorContent,
    ColorText,
    ID,
    Label,
    PointColor,
    RowContent,
    Status,
    Type,
} from './payments-listing-table-row.styled'

type Props = {
    paymentMethod: IPayment
    handleDelete(): void
    handleEdit(): void
}

const PaymentsListingTableRow: React.FC<Props> = memo(({ paymentMethod, handleDelete, handleEdit }) => {
    const { id, name, type, status, color } = paymentMethod

    const formattedStatus = useMemo(() => {
        return status ? 'Ativo' : 'Inativo'
    }, [status])

    const statusColor = useMemo(() => {
        return status ? 'green' : 'red'
    }, [status])

    return (
        <RowContent>
            <ID>{id}</ID>
            <Label>{name}</Label>
            <Type>{getPaymentType(type)}</Type>
            <ColorContent>
                <Color color={color} />
                <ColorText>{color}</ColorText>
            </ColorContent>
            <Status>
                {formattedStatus} <PointColor color={statusColor} />
            </Status>
            <Actions>
                <DefaultButton outline onClick={handleEdit}>
                    Editar
                </DefaultButton>
                <DefaultButton onClick={handleDelete}>Remover</DefaultButton>
            </Actions>
        </RowContent>
    )
})

export { PaymentsListingTableRow }
