import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    min-height: 410px;
`

const FilterContent = styled.div`
    width: fit-content;
    padding: 1.25rem;
`

export { Container, FilterContent }
