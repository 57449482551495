import { useFormik } from 'formik'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Order } from 'types'

import { TariffInput } from '..'

import { TariffBoxContainer, TariffItem, TariffLabel, UpdateTariffs } from './tariff-box.styles'

interface Props {
    order: Order
    isAdmin?: boolean
    showBikerTariffs?: boolean
}

const TariffBox: React.FC<Props> = ({ order, isAdmin, showBikerTariffs }) => {
    const { user } = useAuth()
    const { setErrorModal, setLoading, setSnackbar } = useUI()

    const { getFieldProps, dirty, handleSubmit, values } = useFormik({
        initialValues: {
            id: order.id,
            store_delivery_rate: order?.store_delivery_rate || 0,
            agent_rate: order?.agent_rate || 0,

            store_delivery_pick: order?.store_delivery_pick || 0,
            agent_pick: order?.agent_pick || 0,

            store_delivery_express: order?.store_delivery_express || 0,
            agent_express: order?.agent_express || 0,

            store_rate_return: order?.store_rate_return || 0,
            agent_rate_return: order?.agent_rate_return || 0,

            store_delivery_dynamic_rate: order?.store_delivery_dynamic_rate || 0,
            agent_dynamic_rate: order?.agent_dynamic_rate || 0,
        },
        onSubmit: async values => {
            try {
                setLoading(true)

                await api.put(`/painel/dm-order/${order.id}`, values)

                setSnackbar({ message: 'Tarifas atualizas' })
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao alterar tarifas',
                    subtitle: showErrors(error),
                })
            } finally {
                setLoading(false)
            }
        },
    })

    function tariffDisplay(tariff: number) {
        if (user.isShopkeeper) {
            if (tariff > 0) {
                return true
            } else {
                return false
            }
        }

        return true
    }

    return (
        <TariffBoxContainer readOnly={isAdmin}>
            <TariffItem>
                <TariffLabel>Tarifa Padrão:</TariffLabel>
                <TariffInput type="store" disabled={!isAdmin} inputProps={getFieldProps('store_delivery_rate')} />
                {showBikerTariffs && (
                    <TariffInput type="biker" disabled={!isAdmin} inputProps={getFieldProps('agent_rate')} />
                )}
            </TariffItem>
            {tariffDisplay(values.store_delivery_pick) && (
                <TariffItem>
                    <TariffLabel>Tarifa de Coleta:</TariffLabel>
                    <TariffInput type="store" disabled={!isAdmin} inputProps={getFieldProps('store_delivery_pick')} />
                    {showBikerTariffs && (
                        <TariffInput type="biker" disabled={!isAdmin} inputProps={getFieldProps('agent_pick')} />
                    )}
                </TariffItem>
            )}
            {tariffDisplay(values.store_rate_return) && (
                <TariffItem>
                    <TariffLabel>Tarifa de Retorno:</TariffLabel>
                    <TariffInput type="store" disabled={!isAdmin} inputProps={getFieldProps('store_rate_return')} />
                    {showBikerTariffs && (
                        <TariffInput type="biker" disabled={!isAdmin} inputProps={getFieldProps('agent_rate_return')} />
                    )}
                </TariffItem>
            )}
            {tariffDisplay(values.store_delivery_express) && (
                <TariffItem>
                    <TariffLabel>Tarifa Expressa:</TariffLabel>
                    <TariffInput
                        type="store"
                        disabled={!isAdmin}
                        inputProps={getFieldProps('store_delivery_express')}
                    />
                    {showBikerTariffs && (
                        <TariffInput type="biker" disabled={!isAdmin} inputProps={getFieldProps('agent_express')} />
                    )}
                </TariffItem>
            )}
            {tariffDisplay(values.store_delivery_dynamic_rate) && (
                <TariffItem>
                    <TariffLabel>Tarifa Dinâmica:</TariffLabel>
                    <TariffInput
                        type="store"
                        disabled={!isAdmin}
                        inputProps={getFieldProps('store_delivery_dynamic_rate')}
                    />
                    {showBikerTariffs && (
                        <TariffInput
                            type="biker"
                            disabled={!isAdmin}
                            inputProps={getFieldProps('agent_dynamic_rate')}
                        />
                    )}
                </TariffItem>
            )}
            {isAdmin && (
                <UpdateTariffs isDisabled={!dirty} onClick={() => handleSubmit()}>
                    Atualizar Tarifas
                </UpdateTariffs>
            )}
        </TariffBoxContainer>
    )
}

export default TariffBox
