import IntlCurrencyInput from 'react-intl-currency-input'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import MensagemErro from '../mensagem-erro'

import './style.scss'

const TextInputForm = props => {
    return (
        <div className={classNames('item-input-form', props.className)}>
            {(props.label || props.removeClick) && (
                <div className="top-row">
                    {props.label && (
                        <label className={`label${props.disabled ? ' faded' : ''}`} style={props.labelStyle}>
                            {props.label}
                        </label>
                    )}
                    {props.removeClick && (
                        <div className="remove-button-container" onClick={props.removeClick}>
                            Remover <FontAwesomeIcon icon="times-circle" color="#CF1D1C" />
                        </div>
                    )}
                </div>
            )}

            {props.rightInsideIcon && (
                <div className="icon" style={{ top: props.label ? null : '5px' }}>
                    {props.rightInsideIcon}
                </div>
            )}

            {props.type === 'currency' ? (
                <IntlCurrencyInput
                    config={{
                        locale: 'pt-BR',
                        formats: {
                            number: {
                                BRL: {
                                    style: 'currency',
                                    currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                },
                            },
                        },
                    }}
                    currency="BRL"
                    onChange={(event, value) => {
                        event.preventDefault()

                        props.onChange(value)
                    }}
                    onBlur={props.onBlur}
                    className={`${props.disabled ? 'faded' : ''}`}
                    placeholder={props.placeholder}
                    max={props.maxLength ? Math.pow(10, props.maxLength / 3) : Math.pow(10, 2)}
                    disabled={props.disabled}
                    value={props.value ? Number(props.value) : 0}
                />
            ) : props.type === 'textarea' ? (
                <textarea
                    {...props}
                    id={props.id}
                    className={`${props.disabled ? 'faded' : ''}`}
                    type={props.type || 'text'}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    value={props.value || ''}
                />
            ) : (
                <input
                    {...props}
                    id={props.id}
                    className={`${props.disabled ? 'faded' : ''}`}
                    type={props.type || 'text'}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                    value={props.value || ''}
                />
            )}

            {props.msgErro?.length ? <MensagemErro msgColor={props.msgColor} msgErro={props.msgErro} /> : null}
        </div>
    )
}

export default TextInputForm
