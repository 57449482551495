import { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import api from 'services/api'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './modal-change-password.styled'

type ModalChangePassword = {
    show?(): void
    close?(): void
    setUser(user: UserData): void
}

type IForm = {
    password?: string
    password_confirmation?: string
}

type UserData = {
    id: number
    first_name: string
    last_name: string
}

const atLeastOneDigit = /.*\d.*/
const atLeastOneLowercaseCharacter = /.*[a-z].*/
const atLeastOneUppercaseCharacter = /.*[A-Z].*/

const ModalChangePassword = memo(
    forwardRef<ModalChangePassword>((_, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()
        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [user, setUser] = useState<UserData>(null)
        const [isLoading, setIsLoading] = useState<boolean>()

        const { isSubmitting, touched, errors, getFieldProps, resetForm, handleSubmit } = useFormik<IForm>({
            initialValues: {
                password: '',
                password_confirmation: '',
            },
            validationSchema: Yup.object().shape({
                password: Yup.string()
                    .trim()
                    .required('Insira uma senha')
                    .min(8, 'Senha deve ter no mínimo 8 caracteres')
                    .matches(atLeastOneDigit, 'Deve conter um número')
                    .matches(atLeastOneLowercaseCharacter, 'Deve conter uma letra mínuscula')
                    .matches(atLeastOneUppercaseCharacter, 'Deve conter uma letra maiúscula'),
                password_confirmation: Yup.string()
                    .trim()
                    .oneOf([Yup.ref('password'), ''], 'Senhas não correspondem'),
            }),
            onSubmit: async values => {
                setIsLoading(true)
                try {
                    await api.put(`/painel/administrator/${user.id}`, values)

                    lateralModalBaseRef.current?.close()
                    resetForm()

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: `Senha do administrador ${user.first_name} atualizado com sucesso!`,
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: 'Não foi possível atualizar a senha do administrador. Tente novamente mais tarde.',
                    })
                }

                setIsLoading(false)
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    setUser(null)
                    resetForm()

                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setUser: (user: UserData) => {
                    setUser(user)
                },
            }),
            []
        )

        const headerComponent = useMemo(
            () => (
                <FormLabel>
                    Você está editando a senha de: {user?.first_name} {user?.last_name}
                </FormLabel>
            ),
            [user]
        )

        return (
            <LateralModalBase
                ref={lateralModalBaseRef}
                title={'Atualização de Senha'}
                headerComponent={headerComponent}
            >
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    type="password"
                                    labelText="Senha"
                                    inputProps={{
                                        ...getFieldProps('password'),
                                    }}
                                    errorMessage={touched.password && errors.password}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="password"
                                    labelText="Confirmar Senha"
                                    inputProps={{
                                        ...getFieldProps('password_confirmation'),
                                    }}
                                    errorMessage={touched.password_confirmation && errors.password_confirmation}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{'Salvar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting || isLoading} />
            </LateralModalBase>
        )
    })
)

export { ModalChangePassword }
