import styled from 'styled-components'

const LoadButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    padding: 10px;
    width: 170px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 400ms;
    user-select: none;
    text-transform: uppercase;
    align-self: center;
    font-weight: bold;
    text-align: center;
    &:hover {
        opacity: 0.6;
    }
`

export { LoadButton }
