import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { booleanPointInPolygon, point } from '@turf/turf'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import CheckboxInput from 'components/checkbox-input'
import MapboxView, { PolygonFill, InfoMapMessage } from 'components/mapbox-view'
import ModalConfirmation, { ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import SelectInputForm from 'components/select-input-form'
import TextInputForm from 'components/text-input-form'
import TopRowDefault from 'components/top-row-default/top-row-default'

import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PaymentArea } from 'types'

const ExpressRateCreation: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match: { params } }) => {
    const { basePath, mall } = useAuth()

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalDelete = useRef<ModalConfirmationRef>(null)
    const [loading, toggleLoading] = useState(false)
    const [paymentAreas, setPaymentAreas] = useState<PaymentArea[]>([])
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const statusArray = [
        {
            name: 'Ativo',
            value: 'true',
        },
        {
            name: 'Inativo',
            value: 'false',
        },
    ]

    const validateMinRateValue = Yup.number()
        .when('not_work', {
            is: not_work => not_work === false,
            then: (schema: Yup.NumberSchema) => schema.min(0.0, 'Tarifa é obrigatória'),
        })
        .nullable()

    const { errors, handleSubmit, isSubmitting, setFieldValue, setValues, touched, values } = useFormik({
        initialValues: {
            id: 0,
            label: '',
            payment_areas: [],
            values: {
                bike: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                moto: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                car: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                van: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
                truck: {
                    not_work: false,
                    agent: '0.0',
                    store: '0.0',
                },
            },
            status: '',
        },
        validationSchema: Yup.object().shape({
            label: Yup.string().trim().required('Nome da Tarifa é requerido'),
            values: Yup.object().shape({
                bike: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                moto: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                car: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                van: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
                truck: Yup.object().shape({
                    not_work: Yup.boolean(),
                    agent: validateMinRateValue,
                    store: validateMinRateValue,
                }),
            }),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                if (!values.payment_areas?.length) {
                    setModalMessageData({
                        title: 'Alerta',
                        message: 'É necessário escolher uma ou mais áreas de cobrança para a criação da tarifa.',
                    })

                    modalMessage.current?.openModal()
                    return
                }

                const { bike, moto, car, van, truck } = values.values

                const body = {
                    ...values,
                    payment_areas: values.payment_areas.map((item: PaymentArea) => item.id),
                    status: values.status === 'true' ? true : false,
                    value: {
                        agent: {
                            bike: bike.agent,
                            moto: moto.agent,
                            car: car.agent,
                            van: van.agent,
                            truck: truck.agent,
                        },
                        store: {
                            bike: bike.store,
                            moto: moto.store,
                            car: car.store,
                            van: van.store,
                            truck: truck.store,
                        },
                    },
                    values: undefined,
                }

                if (values?.id) {
                    await api.put(`/painel/rate_express/${values.id}`, body)
                } else {
                    await api.post(`/painel/rate_express`, body)
                }

                setModalMessageData({
                    title: 'Sucesso!',
                    message: `Tarifa ${values?.id ? 'atualizada' : 'criada'} com sucesso.`,
                    textButton: 'Continuar',
                    onClose: () => history.push(`${basePath}/tarifas-expressas`),
                })
            } catch (error) {
                console.log({ error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                })
            } finally {
                setSubmitting(false)
                modalMessage.current?.openModal()
            }
        },
    })

    const getRate = useCallback(
        async id => {
            try {
                toggleLoading(true)

                const { data } = await api.get(`/painel/rate_express/${id}`)

                const { agent, store, car, van, truck } = data.value

                setValues({
                    ...data,
                    values: {
                        bike: {
                            not_work: agent.bike == null && store.bike == null,
                            agent: agent.bike,
                            store: store.bike,
                        },
                        moto: {
                            not_work: agent.moto == null && store.moto == null,
                            agent: agent.moto,
                            store: store.moto,
                        },
                        car: {
                            not_work: agent.car == null && store.car == null,
                            agent: agent.car,
                            store: store.car,
                        },
                        van: {
                            not_work: agent.van == null && store.van == null,
                            agent: agent.van,
                            store: store.van,
                        },
                        truck: {
                            not_work: agent.truck == null && store.truck == null,
                            agent: agent.truck,
                            store: store.truck,
                        },
                    },
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push(`${basePath}/tarifas-expressas`),
                })

                modalMessage.current?.openModal()
            } finally {
                toggleLoading(false)
            }
        },
        [history, setValues]
    )

    useEffect(() => {
        params.id && getRate(params.id)
    }, [getRate, params.id])

    const getPaymentAreas = useCallback(async () => {
        try {
            toggleLoading(true)

            const { data } = await api.get(`/painel/payment-areas`, {
                params: {
                    mall_id: mall.id,
                    per_page: -1,
                    status: [1],
                },
            })

            setPaymentAreas(data.items)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push(`${basePath}/tarifas-expressas`),
            })

            modalMessage.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [history, mall])

    useEffect(() => {
        getPaymentAreas()
    }, [getPaymentAreas])

    const deleteRate = async () => {
        try {
            toggleLoading(true)

            await api.delete(`/painel/rate_express/${params.id}`)

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Tarifa Expressa removida com sucesso.',
                onClose: () => history.push(`${basePath}/tarifas-expressas`),
            })
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(`${basePath}/tarifas-expressas`),
            })
        } finally {
            modalMessage.current?.openModal()
            toggleLoading(false)
        }
    }

    function checkPaymentArea({ lngLat }: { lngLat: { lat: number; lng: number } }) {
        const searchPoint = point([lngLat.lng, lngLat.lat])
        const areaClicked = paymentAreas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

        if (values.payment_areas.some((item: PaymentArea) => item.id === areaClicked?.id)) {
            setFieldValue(
                'payment_areas',
                values.payment_areas.filter((item: PaymentArea) => item.id !== areaClicked?.id)
            )
        } else {
            setFieldValue('payment_areas', [...values.payment_areas, areaClicked])
        }
    }

    const { bike, moto, car, van, truck } = values.values

    return (
        <div className="page-container">
            <ModalLoading visible={loading || isSubmitting} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Tarifa"
                message="Atenção! Esta tarifa será removida porém as entregas já realizadas que foram afetadas por ela não serão atualizadas. Deseja continuar?"
                onYes={() => deleteRate()}
            />

            <TopRowDefault
                title="Tarifa Expressa"
                onBackButtonClick={() => history.push(`${basePath}/tarifas-expressas`)}
                buttons={
                    params.id && [
                        {
                            title: 'Excluir Tarifa',
                            icon: 'trash-alt',
                            onClick: () => modalDelete.current?.openModal(),
                        },
                    ]
                }
            />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="title">{params.id ? 'Edição' : 'Criação'}</div>

                        <div className="rate-label">
                            <TextInputForm
                                label={'Nome da Tarifa'}
                                value={values.label}
                                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('label', value)
                                }
                                msgErro={touched.label && errors.label}
                            />
                        </div>

                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Bicicleta</span>
                        </div>

                        <CheckboxInput
                            id="bike_not_work"
                            label={'Não opera neste turno*'}
                            onChange={({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('values.bike.not_work', checked)

                                if (checked) {
                                    setFieldValue('values.bike.agent', null)
                                    setFieldValue('values.bike.store', null)
                                } else {
                                    setFieldValue('values.bike.agent', '0.0')
                                    setFieldValue('values.bike.store', '0.0')
                                }
                            }}
                            checked={bike.not_work}
                        />

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={bike.store}
                                    onChange={(floatValue: number) => setFieldValue('values.bike.store', floatValue)}
                                    msgErro={touched.values?.bike?.store && errors.values?.bike?.store}
                                    disabled={bike.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={bike.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.bike.agent', floatValue)}
                                    msgErro={touched.values?.bike?.agent && errors.values?.bike?.agent}
                                    disabled={bike.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Motocicleta</span>
                        </div>

                        <CheckboxInput
                            id="motorcycle_not_work"
                            label={'Não opera neste turno*'}
                            onChange={({ target: { checked } }) => {
                                setFieldValue('values.moto.not_work', checked)

                                if (checked) {
                                    setFieldValue('values.moto.agent', null)
                                    setFieldValue('values.moto.store', null)
                                } else {
                                    setFieldValue('values.moto.agent', '0.0')
                                    setFieldValue('values.moto.store', '0.0')
                                }
                            }}
                            checked={moto.not_work}
                        />

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={moto.store}
                                    onChange={(floatValue: number) => setFieldValue('values.moto.store', floatValue)}
                                    msgErro={touched.values?.moto?.store && errors.values?.moto?.store}
                                    disabled={moto.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={moto.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.moto.agent', floatValue)}
                                    msgErro={touched.values?.moto?.agent && errors.values?.moto?.agent}
                                    disabled={moto.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>
                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Carro</span>
                        </div>

                        <CheckboxInput
                            id="car_not_work"
                            label={'Não opera neste turno*'}
                            onChange={({ target: { checked } }) => {
                                setFieldValue('values.car.not_work', checked)

                                if (checked) {
                                    setFieldValue('values.car.agent', null)
                                    setFieldValue('values.car.store', null)
                                } else {
                                    setFieldValue('values.car.agent', '0.0')
                                    setFieldValue('values.car.store', '0.0')
                                }
                            }}
                            checked={car.not_work}
                        />

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={car.store}
                                    onChange={(floatValue: number) => setFieldValue('values.car.store', floatValue)}
                                    msgErro={touched.values?.car?.store && errors.values?.car?.store}
                                    disabled={car.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={car.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.car.agent', floatValue)}
                                    msgErro={touched.values?.car?.agent && errors.values?.car?.agent}
                                    disabled={car.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>
                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Van</span>
                        </div>

                        <CheckboxInput
                            id="van_not_work"
                            label={'Não opera neste turno*'}
                            onChange={({ target: { checked } }) => {
                                setFieldValue('values.van.not_work', checked)

                                if (checked) {
                                    setFieldValue('values.van.agent', null)
                                    setFieldValue('values.van.store', null)
                                } else {
                                    setFieldValue('values.van.agent', '0.0')
                                    setFieldValue('values.van.store', '0.0')
                                }
                            }}
                            checked={van.not_work}
                        />

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={van.store}
                                    onChange={(floatValue: number) => setFieldValue('values.van.store', floatValue)}
                                    msgErro={touched.values?.van?.store && errors.values?.van?.store}
                                    disabled={van.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={van.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.van.agent', floatValue)}
                                    msgErro={touched.values?.van?.agent && errors.values?.van?.agent}
                                    disabled={van.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>
                        <div className="section-title-container">
                            <span className="section-title">Tarifa para Caminhão</span>
                        </div>

                        <CheckboxInput
                            id="truck_not_work"
                            label={'Não opera neste turno*'}
                            onChange={({ target: { checked } }) => {
                                setFieldValue('values.truck.not_work', checked)

                                if (checked) {
                                    setFieldValue('values.truck.agent', null)
                                    setFieldValue('values.truck.store', null)
                                } else {
                                    setFieldValue('values.truck.agent', '0.0')
                                    setFieldValue('values.truck.store', '0.0')
                                }
                            }}
                            checked={truck.not_work}
                        />

                        <div className="price-inputs-container">
                            <div className="price-input">
                                <TextInputForm
                                    label={'Restaurante*'}
                                    value={truck.store}
                                    onChange={(floatValue: number) => setFieldValue('values.truck.store', floatValue)}
                                    msgErro={touched.values?.truck?.store && errors.values?.truck?.store}
                                    disabled={truck.not_work}
                                    type="currency"
                                />
                            </div>

                            <div className="price-input">
                                <TextInputForm
                                    label={'Entregador*'}
                                    value={truck.agent}
                                    onChange={(floatValue: number) => setFieldValue('values.truck.agent', floatValue)}
                                    msgErro={touched.values?.truck?.agent && errors.values?.truck?.agent}
                                    disabled={truck.not_work}
                                    type="currency"
                                />
                            </div>
                        </div>

                        <div style={{ width: 200 }}>
                            <SelectInputForm
                                label="Status"
                                data={statusArray}
                                value={values.status}
                                onChange={({ target: { value } }) => setFieldValue('status', value)}
                                msgErro={touched.status && errors.status}
                            />
                        </div>

                        <ButtonForm buttonText="Salvar" onClick={() => handleSubmit()} style={{ width: '100%' }} />
                    </div>

                    <div className="right-column">
                        <MapboxView>
                            <PolygonFill
                                id="rate-areas"
                                showPopUpArea
                                areaType="rate-area"
                                areas={paymentAreas}
                                onClick={checkPaymentArea}
                                defaultColor="#333"
                                defaultOpacity={0.5}
                                rateCoverageAreas={values.payment_areas}
                            />

                            <InfoMapMessage message="Clique nos polígonos destacados no mapa para marcar ou desmarcar uma área de cobrança." />
                        </MapboxView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpressRateCreation
