import { VisionCard } from '..'

import { orders } from './mock-orders'
import { CardsContainer, InProgressOrders } from './picker-vision-cards.styles'

interface Props {
    orderItemClick(): void
}

const OrdersInProgress: React.FC<Props> = ({ orderItemClick }) => {
    return (
        <InProgressOrders>
            <CardsContainer>
                <VisionCard
                    cardTitle="Em separação"
                    amount={orders.length}
                    orders={orders}
                    emptyText={'Mensagem de card vazio'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    orderItemClick={orderItemClick}
                />
                <VisionCard
                    cardTitle="Aguardando Coleta"
                    amount={orders.length}
                    orders={orders}
                    emptyText={'Mensagem de cad vazio lorem ipsum dolot sit amet'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    orderItemClick={orderItemClick}
                    showsCreationButton
                />
                <VisionCard
                    cardTitle="Em rota"
                    amount={orders.length}
                    orders={orders}
                    emptyText={'Mensagem de card vazio'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    orderItemClick={orderItemClick}
                />
                <VisionCard
                    cardTitle="Entregues"
                    amount={orders.length}
                    orders={orders}
                    emptyText={'Mensagem de card vazio'}
                    emptyIcon={'http://via.placeholder.com/500x500'}
                    orderItemClick={orderItemClick}
                />
            </CardsContainer>
        </InProgressOrders>
    )
}

export default OrdersInProgress
