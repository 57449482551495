import styled from 'styled-components'

const ButtonItem = styled.div`
    input[type='file'] {
        display: none;
    }
    label {
        cursor: pointer;
    }
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 5px;
    border-radius: 6px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    user-select: none;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    width: fit-content;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`
export { ButtonItem }
