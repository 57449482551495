import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

const AdministrationListingItem = ({ adminName, adminEmail, roles, editClick, status, passwordClick, deleteClick }) => {
    return (
        <div className="adm-item">
            <div className="left-column">
                {roles?.length > 0 && <div className="roles">{roles.map(item => item.display_name).join(',')}</div>}
                <div className="name">{adminName}</div>
                <div className="email">{adminEmail}</div>
                <div className="status">{status === 1 ? 'Ativo' : 'Inativo'}</div>
            </div>
            <div className="adm-right-column">
                <div className="button-item" onClick={editClick}>
                    <div className="icon">
                        <FontAwesomeIcon icon="edit" />
                    </div>
                    <div className="text">Editar</div>
                </div>

                <div className="button-item" onClick={passwordClick}>
                    <div className="icon">
                        <FontAwesomeIcon icon="lock" />
                    </div>
                    <div className="text">Alterar Senha</div>
                </div>

                <div className="button-item" onClick={deleteClick}>
                    <div className="icon">
                        <FontAwesomeIcon icon="trash-alt" />
                    </div>
                    <div className="text">Excluir</div>
                </div>
            </div>
        </div>
    )
}

export default AdministrationListingItem
