import { LoadingButton, LoadingIcon } from './loading-more-button.styles'

interface Props {
    label?: string
    loading: boolean
    onClick(): void
}

const LoadingMoreButton: React.FC<Props> = ({ label, loading, onClick }) => {
    return (
        <LoadingButton onClick={loading ? undefined : onClick}>
            {loading ? <LoadingIcon /> : label || 'Carregar mais'}
        </LoadingButton>
    )
}

export { LoadingIcon }

export default LoadingMoreButton
