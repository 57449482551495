import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './order-detail-top-row.styles.scss'

const RouteDetailTopRow = ({ backClick }) => {
    return (
        <div className="order-detail-top-row-container">
            <div className="row-item">
                <div className="left-column">
                    <div onClick={backClick} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon="arrow-left" />
                    </div>
                    <div className="top-row-title">Detalhes da Rota</div>
                </div>
            </div>
        </div>
    )
}

export default RouteDetailTopRow
