import { useMemo, memo, useCallback, useState } from 'react'

import { format, parse } from 'date-fns'

import { DefaultButton } from 'components/default-button/default-button'
import { Grid } from 'components/grid/grid'
import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'

import { useUI } from 'contexts'
import { api2 } from 'services/api'
import { IOrderKDS } from 'types'

import {
    ContentInfo,
    Footer,
    TextInfo,
    ContainerInfo,
    UserName,
    BadgeType,
    ContainerColumn,
    ContainerTitle,
    DateTime,
    Observation,
} from './column-order-production.styled'

type Props = {
    order: IOrderKDS
}

const ColumnOrderProduction: React.FC<Props> = memo(({ order }) => {
    const { setConfirmationModal } = useUI()

    const [loading, setLoading] = useState(false)

    const deliveryType = useMemo(() => {
        if (order.type === 'takeout') {
            return 'Retirada em Balcão'
        }
        if (order.type === 'indoor') {
            return 'Atendimento em Mesa'
        }
        if (order.type === 'delivery') {
            return 'Delivery'
        }
    }, [order.type])

    const date = useMemo(() => {
        return format(order.created_at, 'DD/MM/YYYY [às] HH:mm')
    }, [order.created_at])

    const _toReady = useCallback(() => {
        setConfirmationModal({
            title: 'Pedido Pronto!',
            subtitle: 'Deseja marcar este pedido como pronto?',
            leftButtonText: 'Não',
            rightButtonText: 'Sim',
            rightButtonClick: async () => {
                setLoading(true)
                try {
                    await api2.put(`/orders/${order.id}/ready-to-delivery`)
                } catch (error) {
                    console.log(error)
                }
                setLoading(false)
            },
        })
    }, [order, setConfirmationModal])

    return (
        <ContainerColumn>
            <>
                <ContainerTitle>
                    <BadgeType>{deliveryType}</BadgeType>
                    <UserName>
                        #{order.reference_id} - {order.customer?.name}
                    </UserName>
                    <DateTime>{date}</DateTime>
                    {!!order.observation && <Observation>Obs.: {order?.observation}</Observation>}
                </ContainerTitle>
                <Scroll hideScrollbar>
                    <ContainerInfo>
                        {order.items.map((item, i) => (
                            <ContentInfo className="content-info" key={i}>
                                <Grid.Column>
                                    <TextInfo className="info" header>
                                        <span>x{item.quantity}</span> {item.name}
                                    </TextInfo>
                                    {!!item?.obs && <Observation noMargin>{item.obs}</Observation>}
                                </Grid.Column>
                                {item?.subitems?.map((sub, index) => (
                                    <TextInfo className="info" key={index}>
                                        <span>x{sub.quantity}</span> {sub.name}
                                    </TextInfo>
                                ))}
                            </ContentInfo>
                        ))}
                    </ContainerInfo>
                </Scroll>

                <Footer>
                    <DefaultButton title="Marcar como pronto" variant="success" onClick={_toReady} />
                </Footer>
            </>
            <ModalLoading visible={loading} />
        </ContainerColumn>
    )
})

export { ColumnOrderProduction }
