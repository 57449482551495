import { memo, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { ISalesChannel } from 'types/sales-channel'

import ButtonLoadMore from '../../components/button-load-more/button-load-more'
import EmptyMessage from '../../components/empty-message/empty-message'
import ModalLoading from '../../components/modal-loading'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import api from '../../services/api'

import { ModalCreateSalesChannelRef, ModalSalesChannel } from './components/modal-sales-channel/modal-sales-channel'
import { SalesChannelsListingTableHeader } from './components/sales-channels-listing-table-header/sales-channels-listing-table-header'
import { SalesChannelsListingTableRow } from './components/sales-channels-listing-table-row/sales-channels-listing-table-row'
import { Container, FilterContent, TableSalesChannel, TableSalesChannelContent } from './sales-channels-listing.styled'

interface Props extends RouteComponentProps {}

type SalesChannels = {
    current_page: number
    items: ISalesChannel[]
    totals: number
}

const statusOptions = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 },
]

const SalesChannelsListing: React.FC<Props> = memo(({ history }) => {
    const { setErrorModal, setConfirmationModal } = useUI()

    const modalCreateSalesChannelRef = useRef<ModalCreateSalesChannelRef>()

    const [loading, setLoading] = useState(false)
    const [salesChannels, setSalesChannels] = useState<SalesChannels>()
    const [status, setStatus] = useState<number>(1)

    const renderEmptyMessage = useMemo(() => {
        return <EmptyMessage icon="dollar-sign">Não foram encontrados canais de vendas cadastrados.</EmptyMessage>
    }, [])

    const _getSalesChannels = useCallback(
        async ({ current_page = 1, salesChannels = { items: [] } } = {}) => {
            setLoading(true)

            try {
                const { data } = await api.get('/painel/sales-channels', {
                    params: { current_page, per_page: 50, status: [status], order_by: 'name' },
                })

                setSalesChannels({
                    ...data,
                    items: current_page > 1 ? [...salesChannels.items, ...data.items] : data.items,
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Não foi possível carregar os dados.',
                    singleButtonClick: () => history.push('/painel-malls'),
                })
            }

            setLoading(false)
        },
        [history, status]
    )

    const _handleDelete = useCallback((id: number) => {
        return (): void => {
            const salesChannelDelete = async () => {
                modalCreateSalesChannelRef.current?.close()

                setLoading(true)
                try {
                    await api.delete(`/painel/sales-channel/${id}`)
                    _getSalesChannels()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro!',
                        subtitle: 'Não foi possível excluir canal de vendas!',
                    })
                }
                setLoading(false)
            }

            setConfirmationModal({
                title: 'Excluir Canal de vendas.',
                subtitle: 'Deseja mesmo excluir este canal de vendas?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: salesChannelDelete,
            })
        }
    }, [])

    const _openModal = useCallback(() => {
        modalCreateSalesChannelRef.current?.show()
    }, [])

    const _onClose = useCallback((isRefresh?: boolean) => {
        if (isRefresh) {
            _getSalesChannels()
        }
    }, [])

    const _handleEdit = useCallback((salesChannel: ISalesChannel) => {
        return (): void => {
            _openModal()
            modalCreateSalesChannelRef.current?.setSalesChannel(salesChannel)
        }
    }, [])

    const _handleChangeStatus = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setStatus(Number(value))
    }, [])

    useEffect(() => {
        _getSalesChannels()
    }, [_getSalesChannels])

    return (
        <Container>
            <TopRowDefault
                title="Canais de Vendas"
                buttons={[
                    {
                        title: 'Criar Canal de Venda',
                        onClick: _openModal,
                    },
                ]}
            />

            <ModalLoading visible={loading} />
            <ModalSalesChannel ref={modalCreateSalesChannelRef} onClose={_onClose} onRemove={_handleDelete} />

            <FilterContent>
                <InputItem
                    labelText="Status"
                    type="select"
                    options={statusOptions}
                    inputProps={{
                        value: status,
                        onChange: _handleChangeStatus,
                    }}
                />
            </FilterContent>

            <TableSalesChannel>
                <SalesChannelsListingTableHeader bordered={!salesChannels?.items.length} />

                <TableSalesChannelContent>
                    {salesChannels?.items.map((item, index) => (
                        <SalesChannelsListingTableRow
                            key={index}
                            salesChannel={item}
                            handleEdit={_handleEdit(item)}
                            handleDelete={_handleDelete(item.id)}
                        />
                    ))}
                </TableSalesChannelContent>
            </TableSalesChannel>

            {!salesChannels?.items.length && !loading && renderEmptyMessage}

            <ButtonLoadMore
                loading={loading}
                visible={salesChannels?.totals > 0 && salesChannels?.items.length < salesChannels?.totals}
                onClick={() => _getSalesChannels({ current_page: ++salesChannels.current_page, salesChannels })}
            />
        </Container>
    )
})

export { SalesChannelsListing }
