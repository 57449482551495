import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
const Container = styled.div<{ isEffectActive: boolean }>`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: translateX(${({ isEffectActive }) => (isEffectActive ? '0%' : '200%')});
        border-radius: 4px;
        width: 550px;
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: ${({ theme }) => theme.bikersSelectIndex};
    }
`

const ContentContainer = styled.div`
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
        padding-top: 10px;
    }
`

const HeaderContainer = styled.div`
    padding: 15px;
    position: relative;
    padding-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
        padding-bottom: 10px;
    }
`
const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const LocateButton = styled.div`
    cursor: pointer;
    user-select: none;
    width: fit-content;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    padding: 10px;
    display: inline-flex;
    margin-bottom: 20px;
    transition: 400ms;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

const MapContainer = styled.div`
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
`

const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

const FormSection = styled.div<{ hide?: boolean }>`
    margin-bottom: 30px;
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    flex-direction: column;
`

const FormSectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
        width: 18px;
        height: 18px;
    }
`

const InputsRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const InputContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 48%;
    }
`

const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 16px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

const ConfirmButton = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: 'times',
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`
const ButtonItem = styled.div`
    input[type='file'] {
        display: none;
    }
    label {
        cursor: pointer;
    }
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 5px;
    border-radius: 6px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    user-select: none;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    width: fit-content;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

const SmallTextButton = styled.div`
    cursor: pointer;
    font-size: 12px;
    margin-top: 4px;
    span {
        color: ${({ theme }) => theme.colors.primary};
        margin-right: 4px;
    }
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
    }
`

export {
    Container,
    ContentContainer,
    ContentTitle,
    FormSection,
    FormSectionTitle,
    InputContainer,
    SmallTextButton,
    ButtonItem,
    InputsRow,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
    HeaderContainer,
    MapContainer,
    LocateButton,
}
