import './style.scss'

const ExpressRatesTableHeader: React.FC = () => {
    return (
        <div className="express-rates-table-head-container">
            <div className="table-head-item id">ID</div>
            <div className="table-head-item label">Tarifa</div>
            <div className="table-head-item charge-areas">Áreas de cobrança</div>
            <div className="table-head-item delivery">Bicicleta</div>
            <div className="table-head-item delivery">Motocicleta</div>
            <div className="table-head-item delivery">Carro</div>
            <div className="table-head-item delivery">Van</div>
            <div className="table-head-item delivery">Caminhão</div>
            <div className="table-head-item status">Status</div>
            <div className="table-head-item button-row" />
        </div>
    )
}

export default ExpressRatesTableHeader
