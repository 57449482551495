import { PropsWithChildren } from 'react'
import { DragDropContext, DragStart, DragUpdate, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd'

interface Props {
    onDragStart?(initial: DragStart, provided: ResponderProvided): void
    onDragUpdate?(initial: DragUpdate, provided: ResponderProvided): void
    onDragEnd(result: DropResult, provided: ResponderProvided): void
}

const DragWrapper: React.FC<PropsWithChildren<Props>> = ({ children, onDragEnd }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DragWrapper
