import React, { memo, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { ModalOrderIntegrationCancelRef } from 'modals/modal-order-integration-cancel/modal-order-integration-cancel'

import { useUI } from 'contexts'
import { formatCurrency, getSalesChannelByName, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import { mercadooApi, plugApi } from 'services/api'
import { IOrderIntegrationList } from 'types/order-integration-list'

import { OrderIntegrationSummary } from '../order-integration-summary/order-summary'

import {
    ChannelLogo,
    OrderItemContainer,
    CustomerInfo,
    CustomerID,
    Payments,
    CustomerName,
    TotalPrice,
    TimesContainer,
    GreenClockIcon,
    TimeItem,
    TimeText,
    ContainerLogo,
    ActionRow,
    AcceptButton,
    RejectButton,
    Processing,
    ContainerInfo,
    OrderStore,
    TextButton,
    TextButtonIcon,
    OptionsDropdown,
    CloseDropdown,
    DropdownItem,
    IconContainer,
    DropdownText,
    Line,
    SyncIcon,
    CheckIcon,
    TrashIcon,
} from './order-item-integration.styles'

interface Props {
    order: IOrderIntegrationList
    modalRef?: React.MutableRefObject<ModalOrderIntegrationCancelRef>
    dropdownOrderIdActive?: number | undefined
    setDropdownOrderIdActive?(orderId: number | undefined): void
    showDismissModal(): void
    forceIntegration(): void
}

const OrderItemIntegration: React.FC<Props> = memo(
    ({ order, modalRef, dropdownOrderIdActive, setDropdownOrderIdActive, showDismissModal, forceIntegration }) => {
        const history = useHistory()
        const { setConfirmationModal, setErrorModal, setLoading } = useUI()
        const { store } = useAuth()

        const isProcessing = useMemo(() => order.header.status_plooga === 'processing', [order.header.status_plooga])
        const isIfood = useMemo(() => order.type === 'ifood', [order.type])
        const isMercadoo = useMemo(() => order.type === 'mercadoo', [order.type])

        const customerName = useMemo(() => {
            return order.type === 'mercadoo' ? order.customer?.full_name : order.owner?.name
        }, [order])

        const _toggleDropdown = useCallback(
            (type: 'open' | 'close', orderId?: number) => (e: React.MouseEvent) => {
                e.stopPropagation()
                if (!setDropdownOrderIdActive) return

                if (type === 'open' && orderId) {
                    setDropdownOrderIdActive(orderId)
                    return
                }
                setDropdownOrderIdActive(undefined)
            },
            [setDropdownOrderIdActive]
        )

        const _showOrderDetails = useCallback(() => {
            if (order.type === 'mercadoo') {
                history.push(`?order-mercadoo-integration=${order.id}`)
                return
            }
            history.push(`?order-integration=${order.id}`)
        }, [order, history])

        const _onConfirmOrder = useCallback(() => {
            const callback = async () => {
                setLoading(true)
                try {
                    if (order.type === 'mercadoo') {
                        await mercadooApi.put(`/orders/${order.id}/moderate`, { status: true })
                    } else {
                        await plugApi.put<{ message: string; status: boolean }>(`/orders/${order.id}/approve`, {
                            type: order.type,
                            time: 20,
                        })
                    }
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao aprovar pedido',
                        subtitle: showErrors(error),
                    })
                }
                setLoading(false)
            }
            setConfirmationModal({
                title: 'Aceitar pedido',
                subtitle: 'Deseja realmente aceitar este pedido?',
                type: 'alert',
                modalIcon: 'check-circle',
                leftButtonText: 'Fechar',
                rightButtonText: 'Sim, aceitar',
                rightButtonClick: callback,
                children: <OrderIntegrationSummary order={order as any} />,
            })
        }, [order, setConfirmationModal, setErrorModal, setLoading])

        const _onRejectOrder = useCallback(() => {
            modalRef?.current?.show({ order })
        }, [order, modalRef])

        return (
            <OrderItemContainer>
                <ContainerInfo onClick={_showOrderDetails}>
                    <ContainerLogo>
                        <ChannelLogo
                            src={getSalesChannelByName(order.header?.sales_channel?.name ?? order.header?.sale_channel)}
                        />
                    </ContainerLogo>
                    <CustomerInfo>
                        <CustomerID>#{order.header?.reference_id || order.id}</CustomerID>
                        <OrderStore>{order?.header?.store?.name}</OrderStore>
                        <CustomerName>{customerName}</CustomerName>
                        <Payments>
                            {order.details?.payments?.map((item, i) => (
                                <div key={i}>
                                    <div>{`${item.name} (${item.type === 'OFFLINE' ? 'Na Entrega' : item.type})`}</div>
                                    {!!item.card_brand && <div>{item.card_brand}</div>}
                                    {!!item.change_for && <div>Troco: {formatCurrency(item.change_for)}</div>}
                                </div>
                            ))}
                        </Payments>
                        <TotalPrice>{formatCurrency(order.header.total_price)}</TotalPrice>
                    </CustomerInfo>
                    <TimesContainer>
                        {order.details.birth && (
                            <TimeItem>
                                <GreenClockIcon />
                                <div>
                                    <TimeText>Solicitado:</TimeText>
                                    <TimeText>{format(order.details.birth, 'HH:mm')}</TimeText>
                                </div>
                            </TimeItem>
                        )}
                        {order.details.delivery_forecast && (
                            <TimeItem>
                                <GreenClockIcon />
                                <div>
                                    <TimeText>Agendamento:</TimeText>
                                    <TimeText>{format(order.details.delivery_forecast, 'DD/MM/YY HH:mm')}</TimeText>
                                </div>
                            </TimeItem>
                        )}
                    </TimesContainer>
                </ContainerInfo>
                <ActionRow>
                    {isProcessing ? (
                        <Processing>
                            <FontAwesomeIcon icon="spinner" pulse /> Processando
                        </Processing>
                    ) : (
                        <>
                            {isMercadoo && !store.configs.auto_integration && !store.configs.auto_accept ? (
                                <Processing>
                                    <FontAwesomeIcon icon="spinner" pulse /> Aguardando ERP
                                </Processing>
                            ) : (
                                <>
                                    <AcceptButton onClick={_onConfirmOrder}>Aceitar</AcceptButton>
                                    <RejectButton onClick={_onRejectOrder}>Recusar</RejectButton>
                                </>
                            )}
                        </>
                    )}

                    {isIfood && (
                        <TextButton onClick={_toggleDropdown('open', order.id)}>
                            <TextButtonIcon /> Opções
                        </TextButton>
                    )}
                    <OptionsDropdown isActive={dropdownOrderIdActive === order.id}>
                        <CloseDropdown onClick={_toggleDropdown('close')} />
                        {isProcessing && (
                            <DropdownItem onClick={forceIntegration}>
                                <IconContainer>
                                    <CheckIcon />
                                </IconContainer>
                                <DropdownText>Forçar Integração</DropdownText>
                                <Line />
                            </DropdownItem>
                        )}
                        {isIfood && !isProcessing && (
                            <DropdownItem onClick={showDismissModal}>
                                <IconContainer>
                                    <TrashIcon />
                                </IconContainer>
                                <DropdownText>Desconsiderar Pedido</DropdownText>
                                <Line />
                            </DropdownItem>
                        )}
                    </OptionsDropdown>
                </ActionRow>
            </OrderItemContainer>
        )
    }
)

export { OrderItemIntegration }
