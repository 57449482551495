import { useState, useRef, useMemo, useCallback, memo } from 'react'

import { AxiosError } from 'axios'

import ButtonLoadMore from 'components/button-load-more/button-load-more'
import { DefaultButton } from 'components/default-button/default-button'
import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalLoading from 'components/modal-loading'
import { ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { useUI } from 'contexts'
import { groupItemsByKey, showErrors } from 'helpers'
import { useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, StoreCategory } from 'types'

import { ModalStoreCategories } from './components'
import { ModalStoreCategoriesRef } from './components/modal-store-categories/modal-store-categories'
import {
    BannerImage,
    ButtonRow,
    Container,
    Content,
    ContentBody,
    ContentFooter,
    ContentTop,
    Description,
    TableColumnContent,
    TableContent,
    Title,
    TitleTableContent,
} from './store-categories-listing.styles'

const StoreCategoriesListing: React.FC = memo(() => {
    const { setConfirmationModal, setErrorModal } = useUI()

    const modalMessage = useRef<ModalMessageRef>(null)
    const modalStoreCategoriesRef = useRef<ModalStoreCategoriesRef>()

    const [isLoading, setIsLoading] = useState<boolean>()

    const onRequestError = (error: AxiosError) => {
        setErrorModal({
            title: 'Erro',
            subtitle: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const storeCategories = useAxiosRequest<PagedList<StoreCategory>>({
        api,
        url: '/painel/store-categories',
        initialConfig: { params: { status: [0, 1], orderby: 'name', per_page: 50 } },
        transformData: (state, { data }) => ({
            ...data,
            items: Number(data?.current_page) > 1 && state?.items ? [...state.items, ...data.items] : data.items,
        }),
        onError: onRequestError,
    })

    const groupedStoreCategories = useMemo(
        () =>
            groupItemsByKey(storeCategories?.data?.items, 'store_type_name', (item: StoreCategory) => ({
                ...item,
                store_type_name: item.store_type.name,
            })).sort((a, b) => a.title.localeCompare(b.title)),
        [storeCategories]
    )

    const hasPagination = useMemo(() => {
        return (
            Number(storeCategories.data?.totals) > 0 &&
            Number(storeCategories.data?.items?.length) < Number(storeCategories.data?.totals)
        )
    }, [storeCategories])

    const _getPagination = useCallback(() => {
        storeCategories.request({
            params: {
                current_page: Number(storeCategories.data?.current_page) + 1,
            },
        })
    }, [storeCategories])

    const _openModal = useCallback(() => {
        modalStoreCategoriesRef.current?.show()
    }, [])

    const _handleClose = useCallback((refresh: boolean) => {
        if (refresh) {
            storeCategories.request()
        }
    }, [])

    const _handleEdit = useCallback(
        (storeCategory: StoreCategory) => () => {
            _openModal()
            modalStoreCategoriesRef.current?.setStoreCategory(storeCategory)
        },
        []
    )

    const _handleDelete = useCallback((id: number) => {
        return (): void => {
            const storeCategoryDelete = async () => {
                modalStoreCategoriesRef.current?.close()

                setIsLoading(true)

                try {
                    await api.delete(`/painel/store-category/${id}`)

                    storeCategories.request()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro!',
                        subtitle: 'Não foi possível excluir categoria de loja!',
                    })
                }
                setIsLoading(false)
            }

            setConfirmationModal({
                title: 'Excluir Categoria de loja',
                subtitle: 'Tem certeza de que deseja excluir esta Categoria de loja?',
                type: 'alert',
                modalIcon: 'trash-alt',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Sim, excluir!',
                rightButtonClick: storeCategoryDelete,
            })
        }
    }, [])

    return (
        <Container>
            <ModalLoading visible={storeCategories.loading || isLoading} />
            <ModalStoreCategories ref={modalStoreCategoriesRef} onRemove={_handleDelete} onClose={_handleClose} />

            <Content>
                <ContentTop>
                    <RowTitle
                        title="Categorias de loja"
                        buttonRow={[
                            {
                                label: 'Criar categoria de loja',
                                onClick: _openModal,
                            },
                        ]}
                    />
                </ContentTop>
                <ContentBody hasPagination={hasPagination}>
                    {groupedStoreCategories.map(({ title, items }, index) => (
                        <TableContent key={index}>
                            <TitleTableContent>{title}</TitleTableContent>
                            <FlexTable
                                columns={[
                                    { name: 'ID', width: '3%' },
                                    { name: 'Título', width: '27%' },
                                    { name: 'Ícone', width: '15%' },
                                    { name: 'Banner', width: '20%' },
                                    { name: 'Status', width: '10%' },
                                    { name: '', width: '25%' },
                                ]}
                                list={items?.map((storeCategory: StoreCategory) => {
                                    const { id, icon, banner, name, description, status } = storeCategory

                                    return {
                                        id: id.toString(),
                                        name: (
                                            <TableColumnContent>
                                                <Title>{name}</Title>
                                                <Description>{description}</Description>
                                            </TableColumnContent>
                                        ),
                                        icon: icon ? <BannerImage alt={`icon-${id}`} src={icon.path} /> : '',
                                        banner: banner ? <BannerImage alt={`banner-${id}`} src={banner.path} /> : '',
                                        status: status ? 'Ativo' : 'Inativo',
                                        buttonRow: (
                                            <ButtonRow>
                                                <DefaultButton outline onClick={_handleEdit(storeCategory)}>
                                                    Editar
                                                </DefaultButton>
                                                <DefaultButton onClick={_handleDelete(id)}>Excluir</DefaultButton>
                                            </ButtonRow>
                                        ),
                                    }
                                })}
                            />
                        </TableContent>
                    ))}

                    {!storeCategories.data?.items.length && !storeCategories.loading && (
                        <EmptyMessage>Nenhuma Categoria de loja criada</EmptyMessage>
                    )}
                </ContentBody>
                <ContentFooter isVisible={hasPagination}>
                    <ButtonLoadMore
                        loading={storeCategories.loading}
                        visible={hasPagination}
                        onClick={_getPagination}
                    />
                </ContentFooter>
            </Content>
        </Container>
    )
})

export { StoreCategoriesListing }
