import './style.scss'

const StoresListingTableRow = props => {
    return (
        <div className="stores-table-row-item">
            <div className="stores-table-row-content id">{props.id}</div>
            <div className="stores-table-row-content label">{props.name}</div>
            <div className="stores-table-row-content type">{props.type}</div>
            <div className="stores-table-row-content category">{props.category}</div>
            <div className="stores-table-row-content status">{props.status}</div>
            <div className="stores-table-row-content button-row">
                <div className="button-item outline" onClick={props.deleteClick}>
                    Remover
                </div>
                <div className="button-item" onClick={props.editClick}>
                    Editar
                </div>
            </div>
        </div>
    )
}

export default StoresListingTableRow
