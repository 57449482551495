import { faCheck, faClock, faCog, faSync, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const OrderItemContainer = styled.div<{ isHighlighted?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    white-space: normal;
    user-select: none;

    background-color: ${({ isHighlighted }) => (isHighlighted ? 'rgba(255, 134, 10, 0.1)' : '')};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        justify-content: space-between;
    }
    &:hover {
        background-color: rgba(255, 134, 10, 0.1);
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
`

const ContainerInfo = styled.div`
    flex: 1;
    display: flex;
    cursor: pointer;
`
const ContainerLogo = styled.div`
    width: 60px;
`
const ChannelLogo = styled.img`
    margin: 0px auto;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
`

const CustomerInfo = styled.div`
    flex: 1;
`

const CustomerID = styled.div`
    color: #666;
    font-size: 12px;
`

const CustomerName = styled.div`
    font-size: 12px;
    font-weight: 600;
    width: 80%;
    margin-bottom: 4px;
`
const Payments = styled.div`
    font-size: 10px;
    margin-bottom: 4px;
`

const OrderStore = styled.div`
    font-size: 10px;
    width: 80%;
    margin-bottom: 4px;
    text-transform: uppercase;
`

const TotalPrice = styled.div`
    color: #27ae70;
    font-size: 10px;
`

const TimesContainer = styled.div`
    max-width: 90px;
    font-size: 12px;
    margin-right: 20px;
`

const TimeItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
`

const TimeText = styled.div`
    font-size: 10px;
`

const GreenClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

const ActionRow = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    gap: 0.25rem;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: column;
        width: unset;
        flex-basis: 20%;
        margin-top: 0;
    }
`

const AcceptButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 200ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`
const RejectButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.errorMessage};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 200ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`

const Processing = styled.div`
    text-align: center;
    height: fit-content;
    background-color: ${({ theme }) => theme.colors.yellow};
    color: #000;
    padding: 4px 6px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    font-size: 10px;
`

const TextButton = styled.div`
    cursor: pointer;
    flex-basis: 48%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border: 0;
        text-transform: unset;
        font-weight: normal;
        padding: 0;
        font-size: 12px;
    }
    &:hover {
        text-decoration: underline;
    }
`

const TextButtonIcon = styled(FontAwesomeIcon).attrs({
    icon: faCog,
})``

const OptionsDropdown = styled.div<{ isActive: boolean }>`
    width: 180px;
    transition: 200ms;
    padding: 15px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -5px;
    z-index: 1;
    right: -5px;
    overflow: hidden;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        top: 75%;
    }
`

const CloseDropdown = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
`

const Line = styled.div`
    width: 0px;
    height: 2px;
    position: absolute;
    bottom: -6px;
    left: 7px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: 200ms;
`
const IconContainer = styled.div`
    width: 20px;
    text-align: center;
`

const DropdownText = styled.div`
    font-size: 12px;
    margin-left: 5px;
`

const DropdownItem = styled.div`
    display: flex;
    width: 85%;
    white-space: normal;
    align-items: center;
    color: #666;
    position: relative;
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
        svg {
            transform: translateX(3px);
        }
        ${Line} {
            width: 90%;
        }
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const SyncIcon = styled(FontAwesomeIcon).attrs({
    icon: faSync,
})`
    font-size: 14px;
    transition: 200ms;
`

const CheckIcon = styled(FontAwesomeIcon).attrs({
    icon: faCheck,
})`
    font-size: 14px;
    transition: 200ms;
`

const TrashIcon = styled(FontAwesomeIcon).attrs({
    icon: faTrash,
})`
    font-size: 14px;
    transition: 200ms;
`

export {
    ContainerLogo,
    ChannelLogo,
    OrderItemContainer,
    CustomerInfo,
    CustomerID,
    CustomerName,
    TotalPrice,
    TimesContainer,
    GreenClockIcon,
    TimeItem,
    TimeText,
    Payments,
    ActionRow,
    AcceptButton,
    RejectButton,
    Processing,
    ContainerInfo,
    OrderStore,
    TextButton,
    TextButtonIcon,
    OptionsDropdown,
    CloseDropdown,
    Line,
    IconContainer,
    DropdownText,
    DropdownItem,
    SyncIcon,
    CheckIcon,
    TrashIcon,
}
