import { useCallback, useEffect, useState } from 'react'

import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'

interface HookParams<Data> {
    api: AxiosInstance
    method?: Method
    url?: string
    initialConfig?: AxiosRequestConfig
    executeOnStart?: boolean
    transformData?(state: Data | null, response: AxiosResponse<Data>): Data
    onComplete?(response: AxiosResponse<Data>): void
    onError?(error: AxiosError): void
}

interface HookState<Data> {
    loading: boolean
    status: 'loading' | 'complete' | 'error'
    error: AxiosError | null
    data: Data | null
}

export function useAxiosRequest<Data>({
    api,
    method = 'get',
    url,
    initialConfig,
    executeOnStart = true,
    transformData,
    onComplete,
    onError,
}: HookParams<Data>): HookState<Data> & { request(config?: AxiosRequestConfig): Promise<void> } {
    const [state, setState] = useState<HookState<Data>>({
        loading: false,
        status: 'loading',
        error: null,
        data: null,
    })

    const request = useCallback(async (config?: AxiosRequestConfig) => {
        if (!url && !config?.url) {
            return
        }

        setState(state => ({ ...state, loading: true, status: 'loading' }))

        try {
            const response = await api.request<Data>({
                method: method,
                url: url,
                ...initialConfig,
                ...config,
                data: {
                    ...initialConfig?.data,
                    ...config?.data,
                },
                params: {
                    ...initialConfig?.params,
                    ...config?.params,
                },
            })

            onComplete && onComplete(response)

            setState(state => ({
                ...state,
                loading: false,
                status: 'complete',
                error: null,
                data: transformData ? transformData(state?.data, response) : response.data,
            }))
        } catch (error) {
            onError && onError(error)

            setState(state => ({ ...state, loading: false, status: 'error', error, data: null }))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (executeOnStart) {
            request(initialConfig)
        }
        // eslint-disable-next-line
    }, [executeOnStart])

    return { ...state, request }
}
