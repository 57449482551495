import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'
import { UIProvider } from 'contexts'
import { PersistGate } from 'redux-persist/integration/react'
/** Importa datas para pt-br */
import 'moment/locale/pt-br'
/** Importa plugin para precisão de datas */
import 'moment-precise-range-plugin'
import { Chart as ChartJS, registerables } from 'chart.js'
import { OrdersContextProvider } from 'contexts/orders-context'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'

import { GlobalMap } from 'components/global-map'

import App from '../src/containers/App/App'

import { store, persistor, history } from './store'

ChartJS.register(...registerables)

import './index.css'
import { PrinterContextProvider } from 'contexts/printer-context'

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <UIProvider>
                    <PrinterContextProvider>
                        <OrdersContextProvider>
                            <ThemeProvider theme={theme}>
                                <GlobalMap>
                                    <App />
                                </GlobalMap>
                            </ThemeProvider>
                        </OrdersContextProvider>
                    </PrinterContextProvider>
                </UIProvider>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
