import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 20px;

    .css-yk16xz-control {
        border: 1px solid #ababab;
        border-radius: 8px;
        flex: 1;
        outline: none;
        background-color: #fff;
        padding-left: 5px;
        user-select: none;
        font-family: 'Arial';
    }
`
const TextButton = styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    text-align: end;
    margin-top: 5px;
    font-size: 14px;
    &:hover {
        text-decoration: underline;
    }
`

export { Container, TextButton }
