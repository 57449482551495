import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'

import { useEventListener } from 'hooks'
import './style.scss'

export interface ModalConfirmationProps {
    isActive?: boolean
    message?: string
    onClose?(): void
    onNo?(): void
    onYes?(): void
    title: string
    children?: JSX.Element
}

export interface ModalConfirmationRef {
    openModal(): void
    closeModal(): void
}

const ModalConfirmation: React.ForwardRefRenderFunction<ModalConfirmationRef, ModalConfirmationProps> = (
    { children, isActive, message, onClose, onNo, onYes, title },
    ref
) => {
    const [active, setActive] = useState(isActive)

    const closeModal = useCallback(() => {
        setActive(false)

        if (onClose) {
            onClose()
        }
    }, [onClose])

    const handleEscPress = useCallback(
        ({ code }) => {
            if (code === 'Escape') {
                closeModal()
            }
        },
        [closeModal]
    )

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setActive(true)
        },
        closeModal,
    }))

    return (
        <div className={`Modal-confirmation ${active ? 'is-active' : ''}`}>
            <div className="Modal-bg" onClick={closeModal}>
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">{title}</div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">{message || children}</div>
                        <div className="Modal-buttons">
                            <button className="Modal-button primary" onClick={onYes}>
                                Sim
                            </button>
                            <button className="Modal-button outline" onClick={onNo}>
                                Não
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ModalConfirmation)
