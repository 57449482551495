import { useCallback, useContext, useEffect, useState } from 'react'

import { useUI } from 'contexts'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList, Store } from 'types'
import { IZone } from 'types/floor-map'

import { FloorMapContext } from '../../context/floor-map-context'

function useZoneFormController(
    zone?: IZone,
    floorId?: number,
    toggleShow?: () => void,
    handleCloseZonesForms?: () => void
) {
    const { mall } = useAuth()
    const { setErrorModal, setLoading } = useUI()
    const {
        selectedZone,
        values,
        canvasPolygon,
        getFieldProps,
        handleSelectZone,
        setIsDrawZone,
        setFloor,
        setIsNewFormZone,
        handleSubmit,
        setCanvasPolygon,
        setHandleClose,
        setFieldValue,
    } = useContext(FloorMapContext)

    const [stores, setStores] = useState<Store[]>()

    const _handleClose = useCallback(() => {
        setIsDrawZone(false)
        setIsNewFormZone(false)
        handleSelectZone(null)
        setCanvasPolygon({
            x: null,
            y: null,
            width: null,
            height: null,
        })
        toggleShow()
    }, [toggleShow, handleSelectZone, setIsDrawZone, setIsNewFormZone, setCanvasPolygon])

    const _setCanvasPolygon = useCallback(
        (field: string) =>
            ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setCanvasPolygon({ [field]: Number(value) })
            },
        [setCanvasPolygon]
    )

    const _handleSelectedStore = useCallback(
        (id: number) => () => {
            if (values?.stores?.includes(id)) {
                setFieldValue(
                    'stores',
                    values?.stores?.filter(item => item !== id)
                )
                return
            }

            setFieldValue('stores', !values?.stores ? [id] : [...values?.stores, id])
        },
        [values.stores, setFieldValue]
    )

    const _getStores = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<Store>>('/painel/stores', {
                params: {
                    mall_id: mall.id,
                    status: [1],
                },
            })
            setStores(data.items.filter(item => item.marketplace_enable))
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: 'Não foi possível listar as lojas deste mall',
            })
        } finally {
            setLoading(false)
        }
    }, [mall])

    useEffect(() => {
        _getStores()
    }, [_getStores])

    useEffect(() => {
        setIsDrawZone(true)
        setIsNewFormZone(true)
    }, [])

    useEffect(() => {
        if (floorId) {
            setFloor(floorId)
        }
    }, [floorId, setFloor])

    useEffect(() => {
        if (zone) {
            handleSelectZone(zone)
        }
    }, [zone])

    useEffect(() => {
        const callback = () => {
            _handleClose()
            handleCloseZonesForms()
        }
        setHandleClose(callback)
    }, [_handleClose])

    return {
        stores,
        values,
        selectedZone,
        canvasPolygon,
        _setCanvasPolygon,
        getFieldProps,
        handleSubmit,
        _handleClose,
        _handleSelectedStore,
    }
}

export { useZoneFormController }
