import { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

export default class OrderModerationTableRow extends Component {
    render() {
        return (
            <div className="table-row-item" style={{ borderColor: this.props.status }}>
                <div className="table-row-content id">{this.props.orderID}</div>
                <div className="table-row-content store">{this.props.store}</div>
                <div className="table-row-content channel">{this.props.channel}</div>
                <div className="table-row-content client">
                    <div className="client-name">{this.props.clientName}</div>
                    <div className="client-address">
                        <div className="street">
                            {this.props.street}, {this.props.addressNumber}
                        </div>
                        {this.props.neighborhood}
                    </div>
                </div>
                <div className="table-row-content value">R$ {this.props.orderValue}</div>
                {/* <div className="table-row-content value">{this.props.totalTime}</div> */}
                <div className="table-row-content button-single">
                    <FontAwesomeIcon icon="info-circle" onClick={this.props.detailClick} />
                </div>
                <div className="table-row-content button-row">
                    <div className="button-item outline" onClick={this.props.refuseClick}>
                        Recusar
                    </div>
                    <div className="button-item" onClick={this.props.acceptClick}>
                        Aceitar
                    </div>
                </div>
            </div>
        )
    }
}
