import axios from 'axios'

export const siclosPagURL = process.env.REACT_APP_SICLOSPAG_URL

const siclosPagApi = axios.create({ baseURL: siclosPagURL })

siclosPagApi.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('@extranet.stone:token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        if (config.url.includes('/token')) {
            const privateKey = process.env.REACT_APP_SICLOSPAG_KEY
            config.headers.Authorization = `Bearer ${privateKey}`
        }

        return config
    },
    error => Promise.reject(error)
)

siclosPagApi.interceptors.response.use(
    async response => response,
    error => Promise.reject(error)
)

export default siclosPagApi
