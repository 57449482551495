import styled, { Interpolation, css } from 'styled-components'

type TableItem = {
    styles: Interpolation<React.CSSProperties>
}

const Table = styled.table<{ hasShadow?: boolean; isBordered?: boolean }>`
    width: 100%;
    th,
    td {
        text-align: start !important;
    }

    border-radius: 0.5rem;

    border-collapse: collapse;
    overflow: hidden;

    ${({ isBordered }) =>
        isBordered
            ? css`
                  border-radius: 0.5rem;
              `
            : ''}

    ${({ hasShadow }) =>
        hasShadow
            ? css`
                  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
              `
            : ''}
`

const TableHead = styled.thead`
    display: flex;
    background: #eaeaea;
`

const TableHeadRow = styled.tr`
    padding: 1.25rem;
    //border-bottom: 0.125rem solid #e8e8e8;
    height: 1.875rem;
    // padding-left: 50px;
    display: none;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const TableHeadItem = styled.th<TableItem>`
    ${({ styles }) => styles}
    font-size: 0.875rem;

    display: flex;

    color: ${({ theme }) => theme.colors.textLight};
`

const TableBody = styled.tbody`
    .button-row {
        width: 30%;
        display: flex;
        .button-item {
            user-select: none;
            margin-right: 20px;
            background-color: var(--primary-color);
            padding: 10px 20px;
            border-radius: 4px;
            color: white;
            font-weight: bold;
            border: 1px solid var(--primary-color);
            cursor: pointer;
            &.outline {
                background-color: transparent;
                color: var(--primary-color);
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
`

const TableBodyRow = styled.tr`
    padding: 1.25rem;
    // padding-left: 50px;
    background-color: #eaeaea;
    &:nth-child(odd) {
        background-color: #fff;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    @media all and (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-wrap: nowrap;
    }
`

const TableBodyItem = styled.td<TableItem>`
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
    width: fit-content;

    @media all and (min-width: ${({ theme }) => theme.devices.tablet}) {
        ${({ styles }) => styles}
    }
`

export { Table, TableHead, TableHeadRow, TableHeadItem, TableBody, TableBodyRow, TableBodyItem }
