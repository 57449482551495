import {
    faClock,
    faCalendarCheck,
    faTimes,
    faTimesCircle,
    faFileUpload,
    faCog,
    faArrowCircleLeft,
    faPaperPlane,
    faCalendarAlt,
    faExclamationCircle,
    faHandshake,
    faHandHoldingMedical,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

export const OrderItemContainer = styled.div<{ isHighlighted?: boolean }>`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    white-space: normal;
    user-select: none;
    cursor: pointer;
    background-color: ${({ isHighlighted }) => (isHighlighted ? 'rgba(255, 134, 10, 0.1)' : '')};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        justify-content: space-between;
    }
    &:hover {
        background-color: rgba(255, 134, 10, 0.1);
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
`

export const BikerInfoContainer = styled.div`
    position: relative;
    margin-bottom: -3px;
    width: 45px;
    margin-right: -15px;
`

export const BikerPhoto = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    object-fit: cover;
`
export const BikerName = styled.div`
    font-size: 10px;
    position: absolute;
    top: 80%;
    left: -1px;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 2px 5px;
    border-radius: 20px;
    min-width: 100%;
    text-align: center;
`

export const InfoBadgeContainer = styled.div<{ color: string }>`
    display: inline-flex;
    align-items: center;
    margin-top: 5px;
    background-color: ${({ color }) => color};
    padding: 3px 15px;
    border-radius: 20px;
`

export const ProblemIcon = styled(FontAwesomeIcon).attrs({
    icon: faExclamationCircle,
})`
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    margin-right: 8px;
`

export const InfoBadgeStatus = styled.div`
    font-size: 10px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
    text-align: left;
`

export const ChannelLogo = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-right: 5px;
`

export const TopRow = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

export const CustomerInfo = styled.div`
    width: 30%;
    .tracker {
        margin-top: 10px;
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 37%;
    }
`

export const CustomerID = styled.div`
    color: #666;
    font-size: 12px;
`

export const CustomerName = styled.div`
    font-size: 14px;
    font-weight: 600;
    width: 80%;
`
export const TotalPrice = styled.div`
    color: #27ae70;
    font-size: 10px;
`

export const TimesContainer = styled.div`
    width: 35%;
    font-size: 12px;
    align-self: flex-start;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 20%;
        margin-right: 2%;
    }
`

export const TimeItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
`

export const TimeText = styled.div`
    font-size: 10px;
`

export const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

export const GreenClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-right: 5px;
    width: 20px;
    font-size: 12px;
`

export const CalendarIcon = styled(FontAwesomeIcon).attrs({
    icon: faCalendarCheck,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 7px;
    width: 20px;
    font-size: 12px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
`

export const ActionRow = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: column;
        width: unset;
        flex-basis: 20%;
        margin-top: 0;
    }
`

export const TextButton = styled.div`
    cursor: pointer;
    flex-basis: 48%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border: 0;
        text-transform: unset;
        font-weight: normal;
        padding: 0;
        font-size: 12px;
    }
    &:hover {
        text-decoration: underline;
    }
`

export const TextButtonIcon = styled(FontAwesomeIcon).attrs({
    icon: faCog,
})``

export const AcceptButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 200ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`

export const PrintButton = styled.button`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 200ms;
    font-size: 12px;
    border: none;

    ${({ theme, disabled }) =>
        disabled &&
        css`
            background-color: ${`#cecece4D`};
            border-color: ${'#ffffff'};
            color: ${theme.colors.textLight};
        `}

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
    svg {
        width: 0.75rem;
        height: 0.75rem;
    }
`

export const RejectButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.errorMessage};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 200ms;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 5px;
        flex-basis: unset;
        padding: 5px 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`

export const OptionsDropdown = styled.div<{ isActive: boolean }>`
    width: 150px;
    transition: 200ms;
    padding: 15px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -5px;
    z-index: 999999999;
    right: -5px;
    overflow: hidden;
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        top: 75%;
    }
`

export const CloseDropdown = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
`

export const CancelIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimesCircle,
})`
    font-size: 14px;
    transition: 200ms;
`
export const IconContainer = styled.div`
    width: 20px;
    margin-right: 20px;
    margin-top: 5px;
`

export const DetailIcon = styled(FontAwesomeIcon).attrs({
    icon: faFileUpload,
})`
    font-size: 14px;
    transition: 200ms;
`
export const TakeoutIcon = styled(FontAwesomeIcon).attrs({
    icon: faHandHoldingMedical,
})`
    font-size: 14px;
    transition: 200ms;
`
export const DeliveredIcon = styled(FontAwesomeIcon).attrs({
    icon: faHandshake,
})`
    font-size: 14px;
    transition: 200ms;
`

export const RescheduleIcon = styled(FontAwesomeIcon).attrs({
    icon: faCalendarAlt,
})`
    font-size: 14px;
`

export const MoveIcon = styled(FontAwesomeIcon).attrs({
    icon: faArrowCircleLeft,
})`
    font-size: 14px;
    transition: 200ms;
`
export const CopyIcon = styled(FontAwesomeIcon).attrs({
    icon: faPaperPlane,
})`
    font-size: 14px;
    transition: 200ms;
`

export const DropdownText = styled.div`
    font-size: 12px;
    margin-left: 5px;
`

export const Line = styled.div`
    width: 0px;
    height: 2px;
    position: absolute;
    bottom: -6px;
    left: 7px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: 200ms;
`

export const DropdownItem = styled.div`
    display: flex;
    width: 85%;
    white-space: normal;
    align-items: center;
    color: #666;
    position: relative;
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
        ${DetailIcon}, ${MoveIcon}, ${CancelIcon} {
            transform: translateX(3px);
        }
        ${Line} {
            width: 90%;
        }
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`
export const Tag = styled.div`
    background-color: ${({ theme }) => theme.colors.alert}66;
    padding: 2px 6px;
    width: fit-content;
    border-radius: 4px;
    font-size: 11px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
`
export const PointTag = styled.div`
    background-color: ${({ theme }) => theme.colors.alert};
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 4px;
`
