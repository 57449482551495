import { SocketIoChannel } from 'laravel-echo/dist/channel'
import { take, spawn, ForkEffect, TakeEffect } from 'redux-saga/effects'

import { echoConnection } from 'services/api'
import {
    RoutesActions,
    RoutesScoreboard,
    RoutesTypes,
    SetWebSocketConnectionAction,
    WebSocketConnection,
} from 'store/reducers/routes'
import { Mall } from 'types'

import { listenToEchoChannel } from './helpers'

export function* watchRoutesChanges(): Generator<
    TakeEffect | ForkEffect<void>,
    void,
    {
        connection: WebSocketConnection
        mall: Mall
    }
> {
    const echo = echoConnection()

    while (true) {
        const action = yield take<SetWebSocketConnectionAction>(RoutesTypes.SET_WEB_SOCKET_CONNECTION)
        const { connection, mall } = action

        const channelName = mall?.id ? `route.mall.${mall.id}` : undefined

        if (connection === 'connected' && channelName) {
            const socketIoChannel = echo.private(channelName) as SocketIoChannel

            yield spawn(listenToEchoChannel, {
                socketIoChannel,
                events: [
                    {
                        name: '.scoreboard',
                        action: scoreboard => RoutesActions.setScoreboard(scoreboard as RoutesScoreboard),
                    },
                ],
            })
        } else if (connection === 'disconnected' && channelName) {
            echo.leave(channelName)
        }
    }
}
