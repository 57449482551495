import { useState } from 'react'

import Checkbox from '../../_common/checkbox/checkbox'
import BikerInfo from '../biker-info/biker-info'
import OrderItem from '../order-item/order-item'

import { ready_orders } from './mock-orders'
import {
    BackIcon,
    ContentTitleRow,
    ListBox,
    ListTitleRow,
    ListTitleItem,
    TabItem,
    TabsContainer,
    TitleText,
    ConfigSection,
    ConfigTitle,
    ButtonsContainer,
    PrimaryButton,
    TextButton,
    BikerCard,
    BikerCardContainer,
    ExchangeBiker,
} from './route-creation-content.styles'

interface Props {
    toggleBikersClick(): void
    backClick(): void
}

const RouteCreationContent: React.FC<Props> = ({ toggleBikersClick, backClick }) => {
    const [selectionTab, setSelectionTab] = useState(false)
    return (
        <>
            <ContentTitleRow>
                <BackIcon onClick={backClick} />
                <TitleText>Criar Rota</TitleText>
            </ContentTitleRow>
            <TabsContainer>
                <TabItem isActive={selectionTab} onClick={() => setSelectionTab(true)}>
                    1. Selecionar pedidos
                </TabItem>
                <TabItem isActive={!selectionTab} onClick={() => setSelectionTab(false)}>
                    2. Configurar rotas
                </TabItem>
            </TabsContainer>
            {selectionTab ? (
                <>
                    <ListTitleRow>
                        <ListTitleItem>Pedidos selecionados</ListTitleItem>
                        <ListTitleItem>Ordem de rota</ListTitleItem>
                    </ListTitleRow>
                    <ListBox>
                        {ready_orders.map((item, index) => (
                            <OrderItem
                                key={index}
                                storeImage={item.storeImage}
                                channel={item.channel}
                                customerName={item.customerName}
                                customerNeighborhood={item.customerNeighborhood}
                                customerStreet={item.customerStreet}
                                customerNumber={item.customerNumber}
                                orderValue={item.orderValue}
                                payment={item.payment}
                                distance={item.distance}
                                elapsedTime={item.elapsedTime}
                                productionTime={item.productionTime}
                                readyTime={item.readyTime}
                                routePosition={item.routePosition}
                            />
                        ))}
                    </ListBox>
                    <ButtonsContainer>
                        <TextButton>Salvar rascunho</TextButton>
                        <PrimaryButton>Enviar Rota</PrimaryButton>
                    </ButtonsContainer>
                </>
            ) : (
                <>
                    <ListBox>
                        <ConfigSection>
                            <ConfigTitle>Selecione o tipo de rota</ConfigTitle>
                            <Checkbox
                                checkboxTitle="Expressa"
                                checkboxSubtitle="Até 3 pedidos para rota de alta performance"
                            />
                            <Checkbox
                                checkboxTitle="Corrente"
                                checkboxSubtitle="A partir de 3 pedidos para rota de longa duração"
                            />
                        </ConfigSection>

                        <ConfigSection>
                            <ConfigTitle>Escolha o modo de oferta</ConfigTitle>
                            <Checkbox checkboxTitle="Disponibilizar para base" />
                            <Checkbox checkboxTitle="Selecionar entregador" />
                        </ConfigSection>

                        <BikerCardContainer>
                            <BikerCard>
                                <BikerInfo
                                    name="Samuel Batista"
                                    vehicle="Motocicleta"
                                    bikerRating={4.2}
                                    photo="https://i.pravatar.cc/150?img=8"
                                    batteryLevel={75}
                                    hasWifi
                                    buildVersion={'1.10.20'}
                                    androidVersion={9}
                                />
                                <ExchangeBiker onClick={toggleBikersClick} />
                            </BikerCard>
                        </BikerCardContainer>

                        <ConfigSection>
                            <ConfigTitle>Configurações Adicionais</ConfigTitle>
                            <Checkbox isSquared checkboxTitle="Definir como rota de correção" />
                            <Checkbox isSquared checkboxTitle="Adicionar taxa de urgência" />
                        </ConfigSection>
                    </ListBox>
                    <ButtonsContainer>
                        <TextButton>Salvar rascunho</TextButton>
                        <PrimaryButton>Enviar Rota</PrimaryButton>
                    </ButtonsContainer>
                </>
            )}
        </>
    )
}

export default RouteCreationContent
