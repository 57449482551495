import { tag } from '@turf/turf'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: calc(100% - 82px);
    display: flex;
    flex-direction: column;
    * {
        box-sizing: border-box;
    }
`

const Content = styled.div`
    flex: 1;
    display: flex;
`

const ContainerList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`
const ContainerCredentials = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 400px;
    padding: 20px;
`

const ButtonText = styled.div`
    color: var(--primary-color);
    cursor: pointer;
`
const Title = styled.div`
    font-size: 22px;
    font-weight: bold;
`
const ContainerTitle = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;

    ${Title} {
        margin-bottom: 20px;
    }
    @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        ${Title} {
            margin-bottom: 0px;
        }
    }
`
const ContainerTitleForm = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`

const ContainerFilter = styled.div`
    display: flex;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
`

const FilterItem = styled.div`
    margin-right: 15px;
`

const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const FormSectionTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`

const Button = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px 14px;
    height: fit-content;
    background-color: transparent;
    border: 2px solid;
    border-color: var(--primary-color);
    border-radius: 5px;
    color: white;
    background-color: var(--primary-color);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
`
const OutlineButton = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px 14px;
    height: fit-content;
    background-color: transparent;
    border: 2px solid;
    border-color: var(--primary-color);
    border-radius: 5px;
    color: var(--primary-color);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
    .icon {
        margin-right: 5px;
    }
`

const ContainerButton = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
`

const ContainerInput = styled.div`
    display: flex;
`

const RowInfo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`

const Label = styled.div`
    font-weight: bold;
    font-size: 14px;
    width: 120px;
`

const Value = styled.div<{ type?: 'success' | 'error' }>`
    font-size: 14px;
    color: ${({ theme, type }) => {
        if (type === 'success') {
            return theme.colors.success
        }
        if (type === 'error') {
            return theme.colors.errorMessage
        }
        return theme.colors.black
    }};
`

const Line = styled.div`
    height: 1.5px;
    width: 100%;
    background-color: #cecece;
    margin-bottom: 20px;
`

const ItemPayment = styled.div`
    display: flex;
    margin-bottom: 20px;
`

const ContainerInfo = styled.div`
    flex: 1;
`

const LogarooPayment = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 12px;
`

const OrtherPayment = styled.div`
    font-size: 16px;
`

export {
    ItemPayment,
    ContainerInfo,
    LogarooPayment,
    OrtherPayment,
    FormSectionTitle,
    Content,
    ContainerList,
    ContainerCredentials,
    Container,
    ContainerTitle,
    Title,
    ButtonText,
    ContainerFilter,
    FilterItem,
    LoaderContainer,
    Button,
    ContainerInput,
    RowInfo,
    Label,
    Value,
    ContainerTitleForm,
    ContainerButton,
    Line,
    OutlineButton,
}
