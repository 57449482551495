import { memo } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { getFormInputError, getFormattedDateFilters } from 'helpers'
import { useDataFetching } from 'hooks'
import { Mall, PagedList, Store } from 'types'

import {
    AutocompleteContainer,
    ButtonsContainer,
    ContainerPeriodButton,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    InputsRow,
    MiddleContainer,
    PeriodButton,
    PrimaryButton,
    RowWidth,
} from './heatmap-header.styles'

interface Props {
    title?: string
    filters?: any
    onFilterData?(filters: any): void
    disabled: boolean
}

const HeatmapHeader: React.FC<Props> = ({ title, filters, onFilterData, disabled }) => {
    const { errors, getFieldProps, handleSubmit, touched, setFieldValue, setValues, values } = useFormik({
        initialValues: {
            mall_id: '',
            stores: [],
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            start_ticket: '0',
            end_ticket: '0',
            status: 'finished',
            ...filters,
        },
        validationSchema: Yup.object().shape({
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
            mall_id: Yup.string().required('Selecione o Mall'),
            status: Yup.string().required('Selecione uma situação'),
        }),
        onSubmit: async values => {
            if (onFilterData) {
                const mall = malls.items.find(item => item.id === Number(values.mall_id)) as any

                onFilterData({
                    ...values,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                    start_ticket: Number(values.start_ticket) || null,
                    end_ticket: Number(values.end_ticket) || null,
                    stores: values?.stores.map(item => item.value) || null,
                    location: mall ? [mall.address.lng, mall.address.lat] : null,
                })
            }
        },
    })

    const { data: malls, loading: loadingMalls } = useDataFetching<PagedList<Mall>>('/painel/malls', {
        params: { order_by: 'name', status: [1] },
    })

    const { data: stores, loading: loadingStores } = useDataFetching<PagedList<Store>>(
        values.mall_id ? '/painel/stores-to-select' : null,
        {
            params: { mall_id: values.mall_id },
        }
    )

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    return (
        <>
            <ModalLoading visible={loadingMalls || loadingStores} />
            <HeaderContainer>
                <HeaderTitle>Selecione a loja e período</HeaderTitle>
                <MiddleContainer>
                    <InputsRow>
                        <RowWidth>
                            <InputContainer>
                                <InputItem
                                    labelText="Mall*"
                                    type="select"
                                    options={malls?.items.map(mall => ({
                                        label: mall.name,
                                        value: mall.id,
                                    }))}
                                    inputProps={{
                                        ...getFieldProps('mall_id'),
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('mall_id', value)
                                            setFieldValue('stores', [])
                                        },
                                    }}
                                    errorMessage={getFormInputError('mall_id', errors, touched)}
                                />
                            </InputContainer>
                        </RowWidth>
                        <AutocompleteContainer>
                            <InputItem
                                labelText="Loja"
                                type="autocomplete"
                                options={stores?.items.map(store => ({
                                    label: store.name,
                                    value: store.id,
                                }))}
                                inputProps={{
                                    placeholder: 'Todas',
                                    ...getFieldProps('stores'),
                                    defaultValue: values.stores,
                                    onChange: (value: any) => {
                                        setFieldValue('stores', value)
                                    },
                                }}
                                errorMessage={getFormInputError('store_id', errors, touched)}
                            />
                        </AutocompleteContainer>
                    </InputsRow>
                    <InputsRow>
                        <RowWidth>
                            <InputContainer>
                                <InputItem
                                    labelText="Data Inicial*"
                                    type="date"
                                    inputProps={getFieldProps('start_date')}
                                    errorMessage={getFormInputError('start_date', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Data Final*"
                                    type="date"
                                    inputProps={getFieldProps('end_date')}
                                    errorMessage={getFormInputError('end_date', errors, touched)}
                                />
                            </InputContainer>
                        </RowWidth>

                        <RowWidth>
                            <InputContainer>
                                <InputItem
                                    labelText="Ticket Mínimo"
                                    type="currency"
                                    inputProps={getFieldProps('start_ticket')}
                                    errorMessage={getFormInputError('start_ticket', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Ticket Máximo"
                                    type="currency"
                                    inputProps={getFieldProps('end_ticket')}
                                    errorMessage={getFormInputError('end_ticket', errors, touched)}
                                />
                            </InputContainer>
                        </RowWidth>

                        <RowWidth>
                            <InputContainer>
                                <InputItem
                                    labelText="Hora Inicial"
                                    type="time"
                                    inputProps={getFieldProps('start_time')}
                                    errorMessage={getFormInputError('start_time', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Hora Final"
                                    type="time"
                                    inputProps={getFieldProps('end_time')}
                                    errorMessage={getFormInputError('end_time', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Status*"
                                    type="select"
                                    options={[
                                        { label: 'Finalizado', value: 'finished' },
                                        { label: 'Cancelado', value: 'canceled' },
                                    ]}
                                    inputProps={{
                                        placeholder: '-',
                                        ...getFieldProps('status'),
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('status', value)
                                        },
                                    }}
                                    errorMessage={getFormInputError('status', errors, touched)}
                                />
                            </InputContainer>
                        </RowWidth>
                    </InputsRow>

                    <ButtonsContainer>
                        <ContainerPeriodButton>
                            {periodFilters.map((filter, filterIndex) => (
                                <PeriodButton key={filterIndex} onClick={filter.onClick}>
                                    {filter.label}
                                </PeriodButton>
                            ))}
                        </ContainerPeriodButton>
                        <PrimaryButton disabled={disabled} onClick={() => handleSubmit()}>
                            Consultar
                        </PrimaryButton>
                    </ButtonsContainer>
                </MiddleContainer>
            </HeaderContainer>
        </>
    )
}

export default memo(HeatmapHeader)
