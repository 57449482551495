import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const CheckBoxContainer = styled.div`
    height: 12.5rem;
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom: 1rem;
`

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    ol {
        padding: 0rem 1rem;
        font-size: 0.875rem;

        li {
            margin-bottom: 0.5rem;
        }
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const FormLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-bottom: 0.5rem;
`
const CoordinatesContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0.5rem;

    //padding-top: 1rem;
`

const CoordinatesItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    span {
        width: 4rem;
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;

    padding: 1rem 0rem;
`

const Title = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
`

const InputContainer = styled.div`
    width: 100%;
`

const OutlineButton = styled.button<{ isRemove?: boolean }>`
    color: ${({ theme, isRemove }) => (isRemove ? theme.colors.errorMessage : '#ABABAB')};

    transition: 400ms;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0.563rem 1.25rem;
    border-radius: 0.25rem;
    border: 0.063rem solid #ababab;

    background-color: transparent;
    width: 100%;
    &:hover {
        opacity: 0.8;
    }
`

const ConfirmButton = styled.button<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.25rem;
    border: 0;
    width: 100%;

    &:hover {
        opacity: 0.8;
    }
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;

    width: 100%;
`
export {
    Container,
    Content,
    InputContainer,
    Title,
    ButtonRow,
    OutlineButton,
    ConfirmButton,
    HeaderContent,
    CoordinatesContent,
    CoordinatesItem,
    FormLabel,
    Row,
    CheckBoxContainer,
}
