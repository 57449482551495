/**
 *
 * @param {string} status
 */

const getStatusOrder = status => {
    switch ((status || '').toLocaleUpperCase()) {
        case '0':
            return 'Pedido cancelado'
        case '1':
            return 'Pedido capturado'
        case '2':
            return 'Pedido aguardando moderação'
        case '3':
            return 'Pedido em produção'
        case '4':
            return 'Pedido pronto p/ coleta'
        case '5':
            return 'Pedido coletado'
        case '6':
            return 'Pedido saiu p/ entrega'
        case '7':
            return 'Pedido chegou ao cliente'
        case '8':
            return 'Pedido entregue ao cliente'
        case '9':
            return 'Comprovante de pagamento entregue ao lojista'
        case '10':
            return 'Problema identificado'
        case '10A':
            return 'Pedido fora da área de roteirização'
        case '10B':
            return 'Endereço não encontrado'
        case '10C':
            return 'Destinatário ausente'
        case '10D':
            return 'Destinatário recusou receber'
        case '10E':
            return 'Problema no pagamento'
        case '10F':
            return 'Pedido errado'
        case '10G':
            return 'Impossibilitado de devolver comprovantes'
        case '10H':
            return 'Geolocalização errada'
        case '11':
            return 'Entregue no Balcão'
        default:
            return ''
    }
}

export { getStatusOrder }
