import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ContentContainer = styled.div`
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 25px;
        padding-top: 30px;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const TitleContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
    }
`

const ContentTitle = styled.div`
    font-weight: bold;
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 28px;
        margin-right: 30px;
    }
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f7f7f7;
`

const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    margin-right: 30px;
    font-size: 14px;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 9px 20px;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

const ConfirmButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        opacity: 0.8;
    }
`

const CloseContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 3;
`

const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: 'times',
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 28px;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

const DetailsTabContainer = styled.div``

const InfoSection = styled.div`
    margin-bottom: 40px;
`

const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
`

const SectionSubtitle = styled.div`
    font-size: 12px;
    margin-bottom: 20px;
    color: #737373;
`

const InputsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const InputContainer = styled.div`
    flex: 1;
    &:first-child {
        margin-right: 5px;
    }
    &:last-child {
        margin-left: 5px;
    }
    .message-error {
        bottom: -30px;
    }
`

export {
    ContentContainer,
    ContentTitle,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
    TitleContainer,
    DetailsTabContainer,
    InfoSection,
    SectionTitle,
    InputsRow,
    SectionSubtitle,
    InputContainer,
}
