import styled from 'styled-components'

export const DetailsTabContainer = styled.div``

export const InfoSection = styled.div`
    margin-bottom: 40px;
`

export const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
`

export const SectionSubtitle = styled.div`
    font-size: 12px;
    margin-bottom: 20px;
    color: #737373;
`

export const InputsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const InputContainer = styled.div`
    flex-basis: 48%;
`
