import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import emptyImage from '../../../assets/images/photo.png'

export const DetailsContainer = styled.div``

export const TopContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        flex-direction: row-reverse;
    }
`

export const ImageColumn = styled.div`
    display: flex;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 40%;
    }
`

export const ImageSide = styled.div``

export const ImageTitle = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
`

export const ImageContainer = styled.img`
    width: 180px;
    height: 180px;
    border-radius: 12px;
    overflow: hidden;
    object-fit: cover;
`

export const SizesContainer = styled.div`
    margin-top: 10px;
    font-size: 12px;
`

export const SizeItem = styled.div`
    margin-bottom: 5px;
`

export const ButtonsSide = styled.div`
    margin-left: 20px;
    flex: 1;
    padding-top: 20px;
`

export const ButtonItem = styled.div`
    input[type='file'] {
        display: none;
    }
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 5px;
    border-radius: 6px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    user-select: none;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const InputsColumn = styled.div`
    flex: 1;
    margin-top: 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 0;
        margin-right: 50px;
    }
`

export const MidContainer = styled.div`
    margin-top: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
`

export const MidLeftSide = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex: 1;
        margin-right: 50px;
    }
`
export const CheckboxesContainer = styled.div`
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 50%;
        margin-right: 7%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-right: 10%;
    }
`

export const MidInput = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex: 1;
    }
`

export const CheckboxesRow = styled.div`
    display: flex;
    div {
        margin-right: 20px;
    }
`

export const CheckboxesTitle = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 16px;
    }
`

export const InputRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }
`

export const InputColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 50%;
        div {
            width: 100%;
        }
    }
`

export const TurnsContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 60%;
    }
`

export const BottomContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
    }
`

export const TagsContainer = styled.div`
    margin-top: 10px;
`

export const DeleteButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.errorMessage};
    color: ${({ theme }) => theme.colors.errorMessage};
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-flex;
    font-weight: bold;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    &:hover {
        background-color: ${({ theme }) => theme.colors.errorMessage};
        color: ${({ theme }) => theme.colors.white};
    }
`

export const DeleteIcon = styled(FontAwesomeIcon).attrs({
    icon: faTrashAlt,
})`
    margin-right: 10px;
`

export const EmptyPhoto = styled.img.attrs({
    src: emptyImage,
})`
    width: 180px;
    height: 180px;
    border-radius: 12px;
    overflow: hidden;
`

export const InputLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 400ms;
    margin-bottom: 8px;
    .check-box {
        margin: 0;
    }
    .box {
        width: 8px;
        height: 8px;
        margin: 0;
    }
`
