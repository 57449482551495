import colors from 'themes/colors'
import './style.scss'

interface Props {
    id: string
    checked: boolean
    label?: string
    onClick(): void
}

const InputRadio: React.FC<Props> = ({ id, checked, label, onClick }) => {
    return (
        <div className="radio-group-container" onClick={onClick}>
            <label className="radio-label" htmlFor={id}>
                {label}
                <div className="outside-circle">
                    <div
                        className="inner-circle"
                        style={{ backgroundColor: checked ? colors.primary_color : '#fff' }}
                    ></div>
                </div>
                <input className="radio-input" type="radio" id={id} checked={checked} onChange={onClick} name="teste" />
            </label>
        </div>
    )
}

export default InputRadio
