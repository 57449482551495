import { GeoJSONSource, Map } from 'mapbox-gl'

export function addGeojsonPolygons(
    map: Map,
    geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry>
): { source: GeoJSONSource } {
    map.addSource('polygons', {
        type: 'geojson',
        data: geojson,
    })

    map.addLayer({
        id: 'polygons',
        type: 'fill',
        source: 'polygons', // reference the data source
        layout: {},
        paint: {
            'fill-color': ['get', 'fill-color'],
            'fill-opacity': ['get', 'fill-opacity'],
        },
    })
    // Add a black outline around the polygon.
    map.addLayer({
        id: 'polygon-lines',
        type: 'line',
        source: 'polygons',
        layout: {},
        paint: {
            'line-color': ['get', 'line-color'],
            'line-width': ['get', 'line-width'],
        },
    })

    const source = map.getSource('polygons') as GeoJSONSource

    return { source }
}
