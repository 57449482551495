import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { ErrorDialog } from 'containers/login-page/login-page.styles'

import { useUI } from 'contexts'
import { getFormInputError, setFormFieldErrors, showErrors } from 'helpers'
import api from 'services/api'

import login1 from '../../../assets/images/login1.png'
import login2 from '../../../assets/images/login2.png'
import login3 from '../../../assets/images/login3.png'

import {
    LeftLogo,
    LeftSide,
    LoginBox,
    LoginImage,
    LoginImageContainer,
    LoginPageContainer,
    LoginTitle,
    MainButton,
    MobileLogo,
    RightSide,
    SignupBox,
    SignupCall,
    SignupButton,
    LoginSubtitle,
    TopSignupTag,
    DesktopText,
    TagOutline,
    MapImage,
} from './password-recovery-email.styles'

export default function PasswordRecoveryEmail(): JSX.Element {
    const [errorData, setErrorData] = useState('')

    const [loginImage, setLoginImage] = useState(login1)
    const [isChanging, setIsChanging] = useState(false)
    const { setLoading } = useUI()
    const history = useHistory()

    const changeImage = useCallback(() => {
        if (loginImage === login1) {
            setLoginImage(login2)
        }
        if (loginImage === login2) {
            setLoginImage(login3)
        }
        if (loginImage === login3) {
            setLoginImage(login1)
        }
    }, [loginImage])

    useEffect(() => {
        setTimeout(() => {
            if (isChanging) {
                setIsChanging(false)
                changeImage()
            }
        }, 1000)

        setTimeout(() => {
            if (!isChanging) {
                setIsChanging(true)
            }
        }, 5000)
    }, [isChanging, changeImage])

    const { errors, getFieldProps, handleSubmit, touched } = useFormik({
        initialValues: {
            email: '',
        },

        validationSchema: Yup.object().shape({
            email: Yup.string().trim().required('E-mail é obrigatório'),
        }),

        onSubmit: async (values, { resetForm, setFieldError }) => {
            try {
                setLoading(true)
                await api.post(`/recover`, {
                    email: values.email,
                    origin: 'router',
                })
                history.push(`/recuperar-senha/${values.email}`)
                resetForm()
            } catch (error) {
                setFormFieldErrors(error, setFieldError)
                setErrorData(showErrors(error))
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <LoginPageContainer>
            <LeftSide>
                <MapImage />
                <LeftLogo />
                <LoginImageContainer>
                    <LoginImage src={loginImage} isChanging={isChanging} />
                </LoginImageContainer>
            </LeftSide>
            <RightSide>
                <TopSignupTag>
                    <DesktopText>Já possui cadastro?</DesktopText>
                    <TagOutline onClick={() => history.push('login')}>Acessar conta</TagOutline>
                </TopSignupTag>

                <LoginBox>
                    <MobileLogo />
                    <LoginTitle>Recuperar Senha</LoginTitle>
                    {errorData.length > 0 && <ErrorDialog>{errorData}</ErrorDialog>}

                    <LoginSubtitle>Enviaremos o link de recuperação de senha para o e-mail cadastrado</LoginSubtitle>
                    <InputItem
                        labelText="E-mail"
                        errorMessage={getFormInputError('email', errors, touched)}
                        inputProps={getFieldProps('email')}
                    />
                    <MainButton onClick={() => handleSubmit()}>Enviar</MainButton>

                    <SignupBox>
                        <SignupCall>Problemas para recuperar a senha?</SignupCall>
                        <SignupButton>FALE COM O NOSSO SUPORTE</SignupButton>
                    </SignupBox>
                </LoginBox>
            </RightSide>
        </LoginPageContainer>
    )
}
