import { useCallback, useEffect, useRef, useState } from 'react'

import cep from 'cep-promise'
import { useFormik } from 'formik'
import { ModalFile, ModalFileRef } from 'modals/modal-file/modal-file'
import * as Yup from 'yup'

import Checkbox from 'components/_common/checkbox/checkbox'
import ButtonForm from 'components/button-form'
import { FileItem } from 'components/file-item/file-item'
import ModalLoading from 'components/modal-loading'
import { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import OrderItemsTable from 'components/order-items-table/order-items-table'
import SelectInputForm from 'components/select-input-form'
import TextArea from 'components/text-area'
import TextInputForm from 'components/text-input-form'
import TextInputMask from 'components/text-input-mask'

import { useUI } from 'contexts'
import { formatToOrderItems, formatOrderItemsToString, objectIsEmpty, showErrors } from 'helpers'
import { useAuth, useGeocodingSearch } from 'hooks'
import api from 'services/api'
import { IAttachment, IDeliveryOrigin, Order, PagedList } from 'types'
import { SalesChannel } from 'types'
import { ordersStatusList } from 'utils'
import { transformFormData } from 'utils/transform-formdata'

import { ButtonItem } from './order-editing-mode.styled'

interface Props {
    order: Order | undefined
    getOrder(): void
    setOrderEditingMode(bool: boolean): void
    orderLocation: { lat: number | undefined; lng: number | undefined }
    setOrderLocation(obj: { lat: number; lng: number }): void
    modalMessageRef: React.RefObject<ModalMessageRef>
    setModalMessage(obj: ModalMessageProps): void
}

const OrderEditingMode: React.FC<Props> = ({
    order,
    getOrder,
    setOrderEditingMode,
    orderLocation,
    setOrderLocation,
    modalMessageRef,
    setModalMessage,
}) => {
    const { mall, user, store } = useAuth()

    const modalFileRef = useRef<ModalFileRef>()
    const { setErrorModal, setConfirmationModal } = useUI()

    const [loading, toggleLoading] = useState(false)
    const [payments, setPayments] = useState([])

    const [salesChannels, setSalesChannels] = useState([])

    const [storeOrigins, setStoreOrigins] = useState<IDeliveryOrigin[]>([])
    const [optionsOrigins, setOptionsOrigin] = useState<{ name: string; value: any }[]>([])
    const [allocatedAttachments, setAllocatedAttachments] = useState<IAttachment[]>([])

    const [isCollected, setIsCollected] = useState<boolean>(false)

    const {
        initialValues,
        isSubmitting,
        values,
        touched,
        errors,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        setValues,
    } = useFormik({
        initialValues: {
            reference_id: '',
            sale_channel: '',
            payment_code: '',
            total_price: '',
            customer_id_number: '',
            customer_name: '',
            customer_email: '',
            customer_phone: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            landmark: '',
            lat: '',
            lng: '',
            location_changed: false,
            items: '',
            status: '',
            request_collect_attachments: [],
            collect: {
                observations: '',
                confirmation_required: false,
                confirmation_delivery: false,
                confirmation_fail: false,
            },
            delivery_origin: null as any,
        },
        validationSchema: Yup.object().shape({
            sale_channel: Yup.string().trim().required('Por favor, selecione um Canal de venda.'),
            payment_code: Yup.string().trim().required('Por favor, selecione uma Forma de pagamento.'),
            customer_name: Yup.string().trim().required('Por favor, insira o Nome do cliente'),
            customer_email: Yup.string().nullable().trim().email('Deve ser um email válido'),
            customer_phone: Yup.string()
                .trim()
                .min(10, 'Telefone deve ter 11 números')
                .required('Por favor, insira o Telefone'),
            zipcode: Yup.string().trim().min(8, 'CEP deve ter 8 números').required('Por favor, insira o CEP.'),
            street: Yup.string().trim().required('Por favor, insira o Logradouro'),
            neighborhood: Yup.string().trim().required('Por favor, insira o Bairro'),
            number: Yup.string().trim().required('Por favor, insira o Número'),
            items: Yup.string().trim().required('Por favor, preencha com items do pedido'),
            location_changed: Yup.bool().oneOf([true], ''),
            status: Yup.string().trim().required('Por favor, selecione o status'),
        }),

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                await api.put(`painel/dm-order/${order?.id}`, {
                    ...values,
                    ...orderLocation,
                    neighborhoods: undefined,
                    neighborhood_id: undefined,
                    location_changed: undefined,
                    sale_channel: values.sale_channel,
                    state: mall.address.city.state.uf,
                    city: mall.address.city.name,
                    neighborhood: values.neighborhood,
                    customer_id_number: values.customer_id_number
                        ? values.customer_id_number
                        : Math.random() * (999999 - 100000) + 100000,
                    items: formatToOrderItems(values.items),
                    total_price: values.total_price,
                })

                setModalMessage({
                    isActive: true,
                    title: 'Pedido atualizado',
                    message: 'Pedido atualizado com sucesso',
                    textButton: 'Continuar',
                    onClose: () => {
                        getOrder()
                        setOrderEditingMode(false)
                    },
                })
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessage({
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })
            } finally {
                modalMessageRef.current?.openModal()

                setSubmitting(false)
            }
        },
    })

    useEffect(() => {
        if (!order) return

        const salesChannel = salesChannels.find((item: SalesChannel) => item.name === order.reference_name)

        setAllocatedAttachments(order?.attachments?.request_collect || [])
        setIsCollected(!['1', '2', '3', '4'].includes(order.status))

        const storeOrigin = (storeOrigins || []).find(item => item.id === store.id)

        setValues({
            ...initialValues,
            reference_id: order.reference_id,
            sale_channel: salesChannel ? (salesChannel as SalesChannel)?.id.toString() : '',
            payment_code: order.payment.id.toString(),
            total_price: order.total_price.toString(),
            customer_id_number: order.customer.id.toString(),
            customer_name: order.customer.name,
            customer_email: order.customer.email,
            customer_phone: order.customer.phone,
            zipcode: order.address?.zipcode || '',
            street: order.address?.street || '',
            number: order.address?.number || '',
            complement: order.address?.complement || '',
            neighborhood: order.address?.neighborhood || '',
            landmark: order.address?.landmark || '',
            lat: order.address?.lat.toString() || '',
            lng: order.address?.lng.toString() || '',
            location_changed: true,
            items: formatOrderItemsToString(order.items),
            status: order.status,
            collect: {
                observations: order.collect?.observations || '',
                confirmation_required: !!Number(order.collect?.confirmation_required),
                confirmation_fail: !!Number(order.collect?.confirmation_fail),
                confirmation_delivery: !!Number(order.collect?.confirmation_delivery),
            },
            delivery_origin: order.delivery_origin || storeOrigin,
        })
    }, [order, salesChannels, initialValues, store, storeOrigins, setValues])

    const getSelectData = useCallback(async () => {
        try {
            toggleLoading(true)

            const resPayments = await api.get('painel/payments')
            setPayments(resPayments.data.items)

            const resSalesChannels = await api.get('painel/sales-channels')
            setSalesChannels(resSalesChannels.data.items)
        } catch (error) {
            console.log({ error })
            setModalMessage({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessageRef.current?.openModal()
        } finally {
            toggleLoading(false)
        }
    }, [modalMessageRef, setModalMessage])

    useEffect(() => {
        getSelectData()
    }, [getSelectData])

    async function getAddressByCEP(zipcode: string) {
        try {
            if (zipcode.length === 8) {
                setFieldTouched('zipcode', true)
                toggleLoading(true)

                const response = await cep(zipcode)

                setValues({
                    ...values,
                    neighborhood: response.neighborhood,
                    street: response.street,
                    zipcode: response.cep,
                })
            }
        } catch (error) {
            console.log({ error, message: error.message })
            setFieldError('zipcode', error.message)
        } finally {
            toggleLoading(false)
        }
    }

    const _getStoresOrigins = useCallback(async () => {
        if (isCollected) {
            return
        }
        try {
            const { data } = await api.get<PagedList<IDeliveryOrigin>>('/painel/delivery-origins')
            const storeOrigin = data.items.find(item => item.id === store.id)
            if (storeOrigin) {
                setStoreOrigins(data.items)
            } else {
                const origin: IDeliveryOrigin = {
                    name: store.name,
                    id: store.id,
                    address: {
                        state: store.neighborhood.city.state.uf,
                        city: store.neighborhood.city.name,
                        neighborhood: store.neighborhood.name,
                        number: store.number,
                        street: store.street,
                        zipcode: store.zipcode,
                        location: {
                            lat: store.lat,
                            lng: store.lng,
                        },
                        lat: store.lat,
                        lng: store.lng,
                    },
                }
                setStoreOrigins([origin, ...data.items])
            }
        } catch (error) {
            console.log('error', error)
        }
    }, [isCollected, store])

    const _setDeliveryOrigin = useCallback(
        (storeId: string) => {
            const origin = storeOrigins.find(item => Number(item.id) === Number(storeId))
            setFieldValue('delivery_origin', origin)
        },
        [storeOrigins, setFieldValue]
    )

    const _openFile = useCallback((attachment: IAttachment) => {
        modalFileRef.current?.show({ attachment })
    }, [])

    const _addDoc = useCallback(
        async (files: any) => {
            for (let i = 0; i < files.length; i++) {
                const file = files[i]

                const fileSize = file.size / 1024 / 1024
                if (fileSize > 50) {
                    setErrorModal({
                        title: 'Anexo grande de mais',
                        subtitle: 'O Anexo não pode passar de 50MB',
                    })

                    return
                }
            }

            toggleLoading(true)

            try {
                const { data } = await api.post<IAttachment[]>(
                    `/painel/order/${order.id}/collect-attachment`,
                    transformFormData({ files })
                )
                setAllocatedAttachments([...allocatedAttachments, ...data])
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao adicionar anexo',
                    subtitle: showErrors(error),
                })
            }
            toggleLoading(false)
        },
        [order, allocatedAttachments, setErrorModal]
    )
    const _addRemove = useCallback(
        (index: number, attachment?: IAttachment) => {
            const callback = async () => {
                toggleLoading(true)
                try {
                    await api.delete(`/painel/order/attachment/${attachment.id}`)
                    setAllocatedAttachments(allocatedAttachments.filter(item => item.id !== attachment.id))
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao remover anexo',
                        subtitle: showErrors(error),
                    })
                }
                toggleLoading(false)
            }

            setConfirmationModal({
                title: 'Remover Anexo',
                subtitle: 'Deseja mesmo remover este anexo?',
                type: 'alert',
                modalIcon: 'times-circle',
                leftButtonText: 'Não',
                rightButtonText: 'Sim, remover',
                rightButtonClick: callback,
            })
        },
        [allocatedAttachments, setErrorModal, setConfirmationModal]
    )

    const { getLatLngByAddress } = useGeocodingSearch({
        onSuccess: ({ lat, lng }) => {
            setValues({ ...values, lat: lat.toString(), lng: lng.toString(), location_changed: true })
            setOrderLocation({ lat, lng })
        },
    })

    function handleInputChange(field: string) {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(field, value)
        }
    }

    useEffect(() => {
        setOptionsOrigin(storeOrigins.map(item => ({ name: item.name, value: item.id })))
    }, [storeOrigins])

    useEffect(() => {
        _getStoresOrigins()
    }, [])

    return (
        <>
            <div>
                <ModalLoading visible={loading || isSubmitting} />

                <TextInputForm
                    label="Número do pedido"
                    onChange={handleInputChange('reference_id')}
                    value={values.reference_id}
                    msgErro={touched.reference_id && errors.reference_id}
                />

                <SelectInputForm
                    label="Canal de venda*"
                    data={salesChannels}
                    onChange={({ target: { value } }) => setFieldValue('sale_channel', value)}
                    value={values.sale_channel}
                    msgErro={touched.sale_channel && errors.sale_channel}
                />

                <SelectInputForm
                    label="Forma de pagamento*"
                    data={payments}
                    value={values.payment_code}
                    onChange={({ target: { value } }) => setFieldValue('payment_code', value)}
                    msgErro={touched.payment_code && errors.payment_code}
                />

                <TextInputForm
                    label="Valor do pedido*"
                    type="currency"
                    maxLength={13}
                    value={values.total_price}
                    onChange={(value: string) => setFieldValue('total_price', value)}
                    msgErro={touched.total_price && errors.total_price}
                />
                <>
                    <div className="title" style={{ marginTop: '30px' }}>
                        Informações de coleta
                    </div>

                    {optionsOrigins?.length > 0 && values.delivery_origin && (
                        <SelectInputForm
                            label="Loja de coleta"
                            data={optionsOrigins}
                            value={values.delivery_origin?.id}
                            onChange={({ target: { value } }) => {
                                _setDeliveryOrigin(value)
                            }}
                        />
                    )}

                    <TextInputForm
                        label="Observações sobre a coleta"
                        type="textarea"
                        value={values.collect?.observations}
                        onChange={({ target: { value } }) => setFieldValue('collect.observations', value)}
                        msgErro={touched.collect?.observations && errors.collect?.observations}
                    />

                    {store?.store_category?.store_type?.slug !== 'restaurantes' && (
                        <>
                            <Checkbox
                                checkboxTitle="Requer comprovação de coleta"
                                isSquared
                                inputProps={getFieldProps('collect.confirmation_required')}
                            />
                            <Checkbox
                                checkboxTitle="Requer comprovação de entrega"
                                isSquared
                                inputProps={getFieldProps('collect.confirmation_delivery')}
                            />
                            <Checkbox
                                checkboxTitle="Requer comprovação de problema"
                                isSquared
                                inputProps={getFieldProps('collect.confirmation_fail')}
                            />
                        </>
                    )}

                    {allocatedAttachments.map((attachment, i) => (
                        <FileItem
                            key={i}
                            isView={isCollected}
                            attachment={attachment}
                            onClick={_openFile}
                            onRemove={() => _addRemove(i, attachment)}
                        />
                    ))}
                    {values?.request_collect_attachments.map((file, i) => (
                        <FileItem
                            key={i}
                            isView={isCollected}
                            index={i}
                            file={file}
                            onRemove={_addRemove}
                            setFieldValue={setFieldValue}
                        />
                    ))}
                    {store?.store_category?.store_type?.slug !== 'restaurantes' && !isCollected && (
                        <ButtonItem>
                            <label htmlFor="photo">Anexar</label>
                            <input
                                type="file"
                                name="photo"
                                id="photo"
                                multiple
                                accept="image/png,image/jpeg,application/pdf"
                                onChange={({ target: { files } }) => _addDoc(files)}
                            />
                        </ButtonItem>
                    )}
                </>
                <div className="title" style={{ marginTop: '30px' }}>
                    Informações do cliente
                </div>

                <TextInputForm
                    label="ID do cliente"
                    value={values.customer_id_number}
                    onChange={handleInputChange('customer_id_number')}
                />

                <TextInputForm
                    label="Nome do cliente*"
                    value={values.customer_name}
                    onChange={handleInputChange('customer_name')}
                    msgErro={touched.customer_name && errors.customer_name}
                />

                <TextInputForm
                    label="Email do cliente"
                    value={values.customer_email}
                    onChange={handleInputChange('customer_email')}
                    msgErro={touched.customer_email && errors.customer_email}
                />

                <TextInputMask
                    label="Telefone*"
                    mask="(99) 99999-9999"
                    value={values.customer_phone}
                    onChange={handleInputChange('customer_phone')}
                    msgErro={touched.customer_phone && errors.customer_phone}
                />

                <div className="title" style={{ marginTop: '30px' }}>
                    Endereço
                </div>

                <TextInputMask
                    label="CEP*"
                    mask="99999-999"
                    value={values.zipcode}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                        getAddressByCEP(value.replace(/\D/g, ''))
                        setFieldValue('zipcode', value.replace(/\D/g, ''))
                    }}
                    msgErro={touched.zipcode && errors.zipcode}
                />

                <TextInputForm
                    label="Bairro*"
                    value={values.neighborhood}
                    onChange={handleInputChange('neighborhood')}
                    msgErro={touched.neighborhood && errors.neighborhood}
                />

                <TextInputForm
                    label="Logradouro*"
                    value={values.street}
                    onChange={handleInputChange('street')}
                    msgErro={touched.street && errors.street}
                />

                <div className="grouped-input-container">
                    <div style={{ width: '20%' }}>
                        <TextInputForm
                            label="Número*"
                            value={values.number}
                            onChange={handleInputChange('number')}
                            msgErro={touched.number && errors.number}
                        />
                    </div>

                    <div style={{ width: '70%' }}>
                        <TextInputForm
                            label="Complemento"
                            value={values.complement}
                            onChange={handleInputChange('complement')}
                            msgErro={touched.complement && errors.complement}
                        />
                    </div>
                </div>

                <TextInputForm
                    label="Referência"
                    value={values.landmark}
                    onChange={handleInputChange('landmark')}
                    msgErro={touched.landmark && errors.landmark}
                />

                {values.street && values.number && (
                    <ButtonForm
                        buttonText="Localizar no mapa"
                        onClick={() => {
                            const { city } = mall.address

                            getLatLngByAddress({
                                city: city.name,
                                state: city.state.uf,
                                zipcode: values.zipcode,
                                neighborhood: values.neighborhood,
                                street: values.street,
                                number: values.number,
                            })
                        }}
                    />
                )}

                <div className="title" style={{ marginTop: '30px' }}>
                    Informações do pedido
                </div>

                <OrderItemsTable orderItems={formatToOrderItems(values.items)} />

                <TextArea
                    label="Informações do pedido"
                    rows={5}
                    value={values.items}
                    onChange={handleInputChange('items')}
                />

                {user.isMallManager && (
                    <SelectInputForm
                        label="Status*"
                        data={ordersStatusList.map(item => ({ name: item.name, value: item.id }))}
                        onChange={({ target: { value } }) => setFieldValue('status', value)}
                        value={values.status}
                        msgErro={touched.status && errors.status}
                    />
                )}
            </div>

            <ButtonForm
                buttonText="Atualizar pedido"
                type="submit"
                onClick={() => {
                    if (!objectIsEmpty(errors) || !values.location_changed) {
                        setModalMessage({
                            title: 'Erro',
                            message: !values.location_changed
                                ? 'Selecione a localização no mapa'
                                : 'Preencha a informações corretamente',
                            textButton: 'Revisar alterações',
                        })

                        modalMessageRef.current?.openModal()
                    }

                    handleSubmit()
                }}
                style={{ width: '100%', marginBottom: 50 }}
            />
            <ModalFile ref={modalFileRef} />
        </>
    )
}

export default OrderEditingMode
