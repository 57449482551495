import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import mainLogo from '../../../../assets/images/logaroo-logo-full-white.png'

export const DesktopMenuContainer = styled.div<{ isOpened?: boolean }>`
    background-color: #ff6600;
    width: ${({ isOpened }) => (isOpened ? '300px' : '80px')};
    height: 100vh;
    padding-top: 25px;
    z-index: 99999999999999;
    transition: 400ms;
    display: none;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: block;
    }
`

export const TopRow = styled.div<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ isActive }) => (isActive ? 'space-between' : 'center')};
    padding: 0px 20px;
    margin-bottom: 80px;
`

export const MenuLogo = styled.img.attrs({
    src: mainLogo,
})<{ isActive?: boolean }>`
    width: 200px;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: 400ms;
    overflow: hidden;
    position: relative;
`

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 30px;
    padding-left: 6px;
`

export const DesktopMenuIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
`

export const ItemText = styled.div<{ isActive?: boolean }>`
    font-size: 18px;
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    transition: 300ms;
    flex: 1;
    margin-left: 20px;
    font-weight: 600;
    width: 150px;
    height: ${({ isActive }) => (isActive ? '30px' : '0')};
    overflow: hidden;
    padding-top: 10px;
`

export const HoverBalloon = styled.div<{ isHome?: boolean }>`
    background-color: #717171;
    color: #fff;
    padding: 10px;
    border-radius: 12px;
    position: absolute;
    left: 110%;
    top: 5px;
    transition: 400ms;
    text-align: center;
    display: none;
    min-width: ${({ isHome }) => (isHome ? '150px' : 'unset')};
    &::after {
        content: ' ';
        width: 10px;
        height: 10px;
        background-color: #717171;
        position: absolute;
        left: -4px;
        top: 40%;
        transform: rotate(-45deg);
    }
`

export const DesktopMenuItem = styled.div<{ isSelected?: boolean; isShown?: boolean }>`
    display: ${({ isShown }) => (isShown !== undefined ? (isShown ? 'flex' : 'none') : 'flex')};
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-left: 5px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.white : 'transparent')};
    position: relative;
    padding-left: 18px;
    transition: 400ms;
    cursor: pointer;
    background-color: ${({ isSelected }) => (isSelected ? 'rgba(0,0,0,0.4)' : 'transparent')};
    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        ${HoverBalloon} {
            &:not(:first-child) {
                display: block;
            }
        }
    }
`
