import { useState, useEffect, useCallback, useContext, useLayoutEffect } from 'react'

import { MirrorMap, GlobalMapContext } from 'components/global-map'
import SelectInputForm from 'components/select-input-form'

import { useAuth } from 'hooks'

import ChargeAreasListingView from '../../components/charge-areas-listing-view/charge-areas-listing-view'
import { CircleLines, PolygonFill } from '../../components/mapbox-view'
import ModalMessage from '../../components/modal-message'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import api from '../../services/api'
import './style.scss'

const ChargeAreasListing = ({ history }) => {
    const { setMapBoxContentComponent, setMarkerCenter, setZoom } = useContext(GlobalMapContext)
    const { mall } = useAuth()
    const [loading, setLoading] = useState(false)
    const [chargeAreas, setChargeAreas] = useState({ items: [] })
    const [availableStores, setAvailableStores] = useState([])
    const [selectedStore, setSelectedStore] = useState()
    const [selectedStoreData, setSelectedStoreData] = useState({})
    const [selectOptions, setSelectOptions] = useState([])

    const getSelectedStoreData = useCallback(async () => {
        if (selectedStore?.length > 0) {
            try {
                setLoading(true)
                const { data } = await api.get(`/painel/store/${selectedStore}`)

                setSelectedStoreData(data)
            } catch (error) {
                console.log('error', error)
            } finally {
                setLoading(false)
            }
        }
    }, [selectedStore])

    useEffect(() => {
        getSelectedStoreData()
    }, [getSelectedStoreData])

    const getAvailableStores = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/stores`, {
                params: {
                    mall_id: mall.id,
                    store_id: selectedStore?.id || null,
                    per_page: -1,
                },
            })

            setAvailableStores(data)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [mall.id])

    useEffect(() => {
        getAvailableStores()
    }, [getAvailableStores])

    const getChargeAreas = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/payment-areas`, {
                params: {
                    mall_id: selectedStore?.length > 0 ? null : mall.id,
                    store_id: selectedStore?.length > 0 ? selectedStore : null,
                    per_page: -1,
                    status: [0, 1],
                },
            })

            setChargeAreas(data)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [mall.id, selectedStore])

    useEffect(() => {
        getChargeAreas()
    }, [getChargeAreas])

    useEffect(() => {
        if (availableStores) {
            let available = []
            availableStores?.items?.map(item => available.push({ name: item.name, value: item.id }))

            setSelectOptions([{ name: mall?.name, value: 'mall' }, ...available])

            const select = document.getElementsByTagName('select')
            select[0].selectedIndex = 1
        }
    }, [availableStores])

    const handleSelect = value => {
        if (value === 'mall') {
            setSelectedStore(null)
            setSelectedStoreData(null)
        } else {
            setSelectedStore(value)
        }
    }

    useEffect(() => {
        setZoom(11)
        if (selectedStoreData?.lng && selectedStoreData?.lat) {
            setMarkerCenter([selectedStoreData.lng, selectedStoreData.lat])
            return
        }
        if (mall?.address) {
            setMarkerCenter([mall.address?.lng, mall.address?.lat])
            return
        }
    }, [selectedStoreData, mall])

    const _renderMapContent = useCallback(() => {
        return (
            <>
                <CircleLines
                    id="circle-lines"
                    center={[
                        selectedStoreData?.lng ? selectedStoreData?.lng : mall?.address?.lng,
                        selectedStoreData?.lat ? selectedStoreData?.lat : mall?.address?.lat,
                    ]}
                />
                <PolygonFill showPopUpArea id="payment-areas" areaType="payment-area" areas={chargeAreas.items} />
            </>
        )
    }, [selectedStoreData, chargeAreas])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
    }, [_renderMapContent])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="coverage-area-page-container">
            <ModalMessage />

            <TopRowDefault
                onBackButtonClick={() => history.push('gestao-mall')}
                title="Área de Cobrança"
                buttons={[
                    {
                        title: 'Criar nova área',
                        onClick: () => history.push('area-de-cobranca-criacao'),
                    },
                ]}
            />
            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        <div className="payment-area-filter">
                            <SelectInputForm
                                label="Estabelecimento"
                                data={selectOptions}
                                onChange={({ target: { value } }) => {
                                    handleSelect(value)
                                }}
                                value={selectedStore}
                            />
                        </div>
                        <ChargeAreasListingView
                            title={selectedStoreData?.name || mall?.name}
                            data={chargeAreas.items}
                            loading={loading}
                            goToEdit={id => history.push(`area-de-cobranca/${selectedStore}/${id}`)}
                        />
                    </div>

                    <div className="right-column">
                        <MirrorMap />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChargeAreasListing
