import styled, { css } from 'styled-components'

interface DefaultTextProps {
    variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'alert' | 'foregroundPrimary' | null
    light?: boolean
    bold?: boolean
    uppercase?: boolean
    link?: boolean
    align?: 'left' | 'center' | 'right'
    dimensions?: 'large' | 'normal' | 'medium' | 'small' | 'xLarge' | 'xSmall' | 'xxLarge' | 'xxSmall' | 'caption'
    marginBottom?: number
    marginTop?: number
    font?: 'Exo'
}
const DefaultText = styled.p<DefaultTextProps>`
    color: ${({ theme, variant, light }) => {
        if (variant) {
            return theme.colors[variant]
        }
        if (light) {
            return theme.colors.textLight
        }

        return theme.colors.text
    }};

    font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
    text-align: ${({ align }) => align || 'left'};
    font-size: ${({ dimensions, theme }) => (dimensions ? theme.fontSizes[dimensions] : theme.fontSizes.normal)}px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
    margin-top: ${({ marginTop }) => marginTop || 0}px;
    font-family: ${({ font }) => (font ? font : undefined)};
    ${({ link }) =>
        link &&
        css`
            &:hover {
                text-decoration: underline;
            }
            cursor: pointer;
        `}
`

export { DefaultText }
