import { useState, useEffect, useRef, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import EmptyMessage from 'components/empty-message/empty-message'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import RowTitle from 'components/row-title'
import ViewControl from 'components/view-control/view-control'

import { Service, Services } from 'containers/stone-management/types'

import { showErrors } from 'helpers'
import siclosPagApi from 'services/siclospag'

import ModalCreateService, { ModalCreateServiceRef } from './components/modal-create-service'

const ServicesListing: React.FC<RouteComponentProps> = ({ history }) => {
    const modalCreate = useRef<ModalCreateServiceRef>(null)
    const modalMessage = useRef<ModalMessageRef>(null)
    const modalConfirmation = useRef<ModalConfirmationRef>(null)

    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [services, setServices] = useState<Service[]>()
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const getServices = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await siclosPagApi.get<Services>('/services/integrator/get-services-list')

            setServices(data.services)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: 'Não foi possível carregar os dados.',
                onClose: () => history.push('/painel-malls'),
            })

            modalMessage.current?.openModal()
        } finally {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getServices()
    }, [getServices])

    const deactivateService = async (integrator_service_id: string) => {
        try {
            setLoadingSubmit(true)

            const { data } = await siclosPagApi.delete(`/services/integrator/deactivate/${integrator_service_id}`)

            if (data?.error) {
                throw data.msg
            }

            setModalMessageData({
                title: 'Sucesso!',
                message: 'Serviço desativado com sucesso!',
            })

            getServices()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })
        } finally {
            setLoadingSubmit(false)
            modalMessage.current?.openModal()
        }
    }

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="dollar-sign">Não foram encontrados serviços cadastrados.</EmptyMessage>
    }

    return (
        <div className="services-listing-container">
            <RowTitle
                title="Áreas/Itens/Serviços"
                buttonRow={[
                    {
                        label: 'Criar Área/Item/Serviço',
                        onClick: () => modalCreate.current?.openModal(),
                    },
                ]}
            >
                <div style={{ marginRight: '10px' }}>
                    <ViewControl
                        leftItem="Todos os serviços"
                        rightItem="Vínculos"
                        leftItemActive={true}
                        rightItemActive={false}
                        setLeftItemActive={() => null}
                        setRightItemActive={() => history.replace('/painel-malls/gestao-stone/servicos/vinculos')}
                    />
                </div>
            </RowTitle>

            <ModalLoading visible={loadingSubmit || (loading && !services?.length)} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />

            <ModalCreateService
                ref={modalCreate}
                title={'Criar novo serviço'}
                setLoadingSubmit={setLoadingSubmit}
                getServices={getServices}
                modalMessageRef={modalMessage}
                setModalMessageData={setModalMessageData}
            />

            <FlexTable
                columns={[
                    { name: 'Nome', width: '50%' },
                    { name: 'Status', width: '20%' },
                    { name: '', width: '30%' },
                ]}
                list={services?.map(service => {
                    const { integrator_service_id, integrator_service_name, is_active } = service

                    return {
                        integrator_service_name: (
                            <>
                                {integrator_service_name} <br />
                                <small>{integrator_service_id}</small>
                            </>
                        ),
                        is_active: is_active ? 'Ativo' : 'Inativo',
                        buttonRow: (
                            <div className="flex-table-row-content button-row">
                                <div
                                    className="button-item outline"
                                    onClick={() => {
                                        setModalConfirmationData({
                                            title: 'Desativar serviço',
                                            message:
                                                'Tem certeza de que deseja desativar este serviço? A ação de desativação desta área/item/serviço de integrador acarretará na desativação dos seus vínculos criados com estabelecimentos.',
                                            onYes: () => deactivateService(integrator_service_id),
                                        })

                                        modalConfirmation.current?.openModal()
                                    }}
                                >
                                    Desativar
                                </div>
                            </div>
                        ),
                    }
                })}
            />

            {!services?.length && !loading && renderEmptyMessage()}
        </div>
    )
}

export default ServicesListing
