import { formatCurrency } from 'helpers'
import { Order, PendingOrder } from 'types'

import { OrderSummaryContainer, OrderSummaryCustomer, OrderSummaryId, OrderSummaryInfo } from './order-summary.styles'

interface Props {
    order: Order | PendingOrder
}

const OrderSummary: React.FC<Props> = ({ order }) => {
    const address = order.address

    if (!address) return null

    const { street, number, neighborhood } = address

    return (
        <OrderSummaryContainer className="content-order">
            <OrderSummaryId>Pedido #{order.id}</OrderSummaryId>
            <OrderSummaryCustomer>{order.customer.name}</OrderSummaryCustomer>
            <OrderSummaryInfo>
                {(order as PendingOrder)?.price?.total
                    ? formatCurrency((order as PendingOrder).price.total)
                    : formatCurrency((order as Order)?.total_price)}
            </OrderSummaryInfo>
            <OrderSummaryInfo>{order.payment?.name}</OrderSummaryInfo>
            <OrderSummaryInfo>{`${street}, ${number} - ${neighborhood}`}</OrderSummaryInfo>
        </OrderSummaryContainer>
    )
}

export default OrderSummary
