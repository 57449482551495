export const orders = [
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 12,
        customerName: 'Yan Gondim',
        channel: 2,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 23,
        customerName: 'Carmen Li',
        channel: 1,
        productionTime: 12,
        totalTime: 21,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
    {
        customerID: 41,
        customerName: 'Samuel Batista Paixão',
        channel: 3,
        productionTime: 20,
        totalTime: 41,
        storeLogo:
            'https://1.bp.blogspot.com/-fcolkHbrqYU/XyJAR1FcEdI/AAAAAAABzo4/OQaHcAY6j70T3KNeXuHSmDN40hOzXgpRQCLcBGAsYHQ/s1600/mc%2Blogos%2B4.jpg',
        pickerPhoto: 'https://i.pravatar.cc/150?img=59',
    },
]
