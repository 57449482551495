import { forwardRef, memo, useImperativeHandle } from 'react'

import ModalLoading from 'components/modal-loading'

import { IAttachment } from 'types'

import { useModalFileCTL } from './modal-file.controller'
import { Box, Container, CloseIcon, Header, Title, Content, Image, Iframe, TextButton } from './modal-file.styled'

type Params = {
    attachment: IAttachment
}

export type ModalFileRef = {
    show?(params: Params): void
}

interface ModalFileProps {
    onClose?(success?: boolean): void
}
const ModalFile = memo(
    forwardRef<ModalFileRef, ModalFileProps>((props, ref) => {
        const { loading, visible, show, attachment, base64, _download, _setShow, _onClose } = useModalFileCTL()

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
            }),
            [_setShow]
        )

        if (!visible) {
            return null
        }

        return (
            <Container visible={visible}>
                <Box show={show} isFull={attachment?.extension === 'pdf'}>
                    <Header>
                        <Title>{attachment?.original_name}</Title>
                        <CloseIcon icon="times" onClick={_onClose} />
                    </Header>
                    <Content>
                        {attachment && base64 && (
                            <>{attachment.extension !== 'pdf' ? <Image src={base64} /> : <Iframe src={base64} />}</>
                        )}
                        <ModalLoading visible={loading} />
                    </Content>
                    <TextButton onClick={_download}>Baixar</TextButton>
                </Box>
            </Container>
        )
    })
)

export { ModalFile }
