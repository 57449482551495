import { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    Column,
    Container,
    TextName,
    TextRole,
    TextEmail,
    TextStores,
    IconButton,
    ContainerButtons,
    TextID,
} from './staff-listing-item.styled'

const StaffListingItem = ({ id, stores, name, email, roles, status, editClick, passwordClick, deleteClick }) => {
    const storesString = useMemo(() => {
        return stores.map(store => store.name).join()
    }, [stores])

    const rolesString = useMemo(() => {
        return roles.join()
    }, [roles])

    return (
        <Container>
            <Column fitContent widthFix={25}>
                <TextID> {id}</TextID>
            </Column>
            <Column fitContent widthFix={300}>
                <TextName>{name}</TextName>
                <TextRole>{rolesString}</TextRole>
                <TextEmail hideOnLarge>{email}</TextEmail>
            </Column>
            <Column fitContent widthFix={300} hideOnSmall>
                <TextEmail>{email}</TextEmail>
            </Column>
            <Column>
                <TextStores>{storesString}</TextStores>
            </Column>
            <Column>
                <TextRole>{Number(status) === 1 ? 'Ativo' : 'Inativo'}</TextRole>
            </Column>
            <Column fitContent>
                <ContainerButtons>
                    <IconButton>
                        <FontAwesomeIcon icon="edit" onClick={editClick} />
                    </IconButton>
                    <IconButton>
                        <FontAwesomeIcon icon="lock" onClick={passwordClick} />
                    </IconButton>
                    <IconButton onClick={deleteClick}>
                        <FontAwesomeIcon icon="trash-alt" />
                    </IconButton>
                </ContainerButtons>
            </Column>
        </Container>
    )
}
const StaffListingHeader = () => {
    return (
        <Container>
            <Column fitContent widthFix={25}></Column>
            <Column fitContent widthFix={300}>
                Lojista
            </Column>
            <Column fitContent widthFix={300} hideOnSmall>
                E-mail
            </Column>
            <Column>Lojas</Column>
            <Column></Column>
            <Column></Column>
        </Container>
    )
}

export { StaffListingItem, StaffListingHeader }
