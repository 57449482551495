import { faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const OrderItemContainer = styled.div<{ isHighlighted?: boolean }>`
    padding: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: 400ms;
    background-color: ${({ isHighlighted }) => (isHighlighted ? 'rgba(255, 134, 10, 0.1)' : '')};
    &:hover {
        background-color: rgba(255, 134, 10, 0.1);
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        justify-content: space-between;
        padding: 15px 20px;
    }
`

export const ChannelIcon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    overflow: hidden;
    margin-right: 10px;
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        width: 70px;
        height: 70px;
    }
`

export const CustomerInfo = styled.div`
    flex-basis: 60%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 20%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 18px;
    }
`

export const OrderPrice = styled.div`
    flex-basis: 20%;
    display: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: block;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 18px;
    }
`

export const TimesContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        position: relative;
        top: unset;
        right: unset;
        flex-basis: 15%;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktopLarge}) {
        font-size: 14px;
    }
`

export const OrderID = styled.div`
    color: #888;
`

export const CustomerName = styled.div`
    font-weight: bold;
`

export const PriceValue = styled.div`
    font-weight: bold;
`

export const PaymentMethod = styled.div``

export const TagItem = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
`

export const TagText = styled.div`
    font-size: 14px;
    color: #888;
`

export const IconContainer = styled.div`
    width: 20px;
    text-align: center;
`

export const ClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 15px;
    width: 20px;
    font-size: 12px;
    margin-top: -1px;
`

export const GreenClockIcon = styled(FontAwesomeIcon).attrs({
    icon: faClock,
})`
    color: ${({ theme }) => theme.colors.success};
    margin-right: 15px;
    width: 20px;
    font-size: 12px;
`

export const MobileValue = styled.div`
    font-size: 12px;
    margin-top: 5px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: none;
    }
`

export const ActionRow = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: column;
        width: unset;
        flex-basis: 20%;
        margin-top: 0;
    }
`

export const RejectButton = styled.div`
    cursor: pointer;
    flex-basis: 48%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border: 0;
        text-transform: unset;
        font-weight: normal;
        padding: 0;
    }
    &:hover {
        text-decoration: underline;
    }
`

export const RejectIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimesCircle,
})``

export const AcceptButton = styled.div`
    flex-basis: 48%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 0px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: 400ms;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 10px;
    }
    &:hover {
        opacity: 0.8;
    }
`
