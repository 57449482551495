import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ContainerNavigation = styled.div`
    display: flex;
    margin: 0px 45px;
`

const NavigationItemContainer = styled.div<{ isActive?: boolean }>`
    font-size: 14px;
    padding: 30px 0px;
    align-items: center;
    display: flex;
    width: 40%;
    border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#c0c0c0')};
    position: relative;
    margin-right: 15px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: unset;
        border-bottom: 0;
        display: inline-flex;
        align-items: center;
        margin-right: 25px;
        font-size: 12px;
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        margin-bottom: 0;
        &:last-child {
            margin-right: 0;
        }
    }
`

const NavigationItem = styled.div<{ isActive?: boolean }>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#c0c0c0')};
    display: inline-flex;
    align-items: center;
    padding-bottom: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: 400ms;
    font-size: 14px;
    &:hover {
        border-color: ${({ theme }) => theme.colors.primary};
    }
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        border-bottom: 3px solid;
        border-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : 'transparent')};
    }
`

const NavigationIcon = styled(FontAwesomeIcon)<{ isActive: boolean }>`
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : '#c0c0c0')};
`

export { NavigationItem, NavigationItemContainer, ContainerNavigation, NavigationIcon }
