import { OrderItem } from 'components/_picker-vision'

import {
    CardHeader,
    CardNumber,
    CardTitle,
    EmptyIcon,
    EmptyText,
    EmptyMessageContainer,
    OrdersList,
    ProgressCardContainer,
    RouteButtonContainer,
    RouteButton,
} from './vision-card.styles'

interface Props {
    cardTitle: string
    amount: number
    emptyText: string
    emptyIcon: string
    showsCreationButton?: boolean
    orderItemClick(): void
    orders: Array<{
        customerID: number
        customerName: string
        channel: number
        productionTime: number
        totalTime: number
        storeLogo: string
        pickerPhoto?: string
    }>
}

const ProgressCard: React.FC<Props> = ({
    amount,
    cardTitle,
    orders,
    emptyText,
    emptyIcon,
    orderItemClick,
    showsCreationButton,
}) => {
    return (
        <ProgressCardContainer>
            <CardHeader>
                <CardTitle>{cardTitle}</CardTitle>
                <CardNumber>{amount}</CardNumber>
            </CardHeader>
            <OrdersList>
                {orders.length > 0 ? (
                    <>
                        {orders.map((item, index) => (
                            <OrderItem
                                key={index}
                                customerID={item.customerID}
                                customerName={item.customerName}
                                channel={item.channel}
                                storeLogo={item.storeLogo}
                                pickerPhoto={item.pickerPhoto}
                                productionTime={item.productionTime}
                                totalTime={item.totalTime}
                                confirmClick={() => console.log('confirmar')}
                                cardClick={orderItemClick}
                            />
                        ))}
                    </>
                ) : (
                    <EmptyMessageContainer>
                        <EmptyIcon src={emptyIcon} />
                        <EmptyText>{emptyText}</EmptyText>
                    </EmptyMessageContainer>
                )}
            </OrdersList>
            {showsCreationButton && (
                <RouteButtonContainer>
                    <RouteButton to="/criar-rota-picker">Criar Rota</RouteButton>
                </RouteButtonContainer>
            )}
        </ProgressCardContainer>
    )
}

export default ProgressCard
