import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'
import OrderSummary from 'components/_store-general-vision/order-summary'

import { Order } from 'types'

import { useModalOrderCancelCTL } from './modal-order-cancel.controller'
import {
    Box,
    Container,
    ContainerButtons,
    Footer,
    Icon,
    Message,
    Separator,
    Title,
    Button,
    ContainerSelect,
} from './modal-order-cancel.styled'

const OPTIONS = [
    {
        code: 1,
        title: 'PROBLEMAS DE SISTEMA',
    },
    {
        code: 2,
        title: 'PEDIDO EM DUPLICIDADE',
    },
    {
        code: 3,
        title: 'ITEM INDISPONÍVEL',
    },
    {
        code: 4,
        title: 'INDISPONIBILIDADE DE ENTREGADORES',
    },
    {
        code: 5,
        title: 'CARDÁPIO DESATUALIZADO',
    },
    {
        code: 6,
        title: 'PEDIDO FORA DA ÁREA DE ENTREGA',
    },
    {
        code: 7,
        title: 'ENTREGUE PELO IFOOD',
    },
    {
        code: 8,
        title: 'CLIENTE GOLPISTA / TROTE',
    },
    {
        code: 9,
        title: 'FORA DO HORÁRIO DO DELIVERY',
    },
    {
        code: 10,
        title: 'DIFICULDADES INTERNAS DA LOJA',
    },
    {
        code: 11,
        title: 'ÁREA DE RISCO',
    },
    {
        code: 12,
        title: 'LOJA ABRIRÁ MAIS TARDE',
    },
    {
        code: 13,
        title: 'LOJA FECHOU MAIS CEDO',
    },
]

type Params = {
    order: Order
    onSelectReason(reason: string): void
}

export type ModalOrderCancelRef = {
    show?(params: Params): void
}

interface ModalOrderCancelProps {
    onClose?(success?: boolean): void
}
const ModalOrderCancel = memo(
    forwardRef<ModalOrderCancelRef, ModalOrderCancelProps>((props, ref) => {
        const { visible, show, order, reason, _setOptionCancel, _onButtonClick, _setShow } =
            useModalOrderCancelCTL(props)

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
            }),
            [_setShow]
        )

        if (!visible) {
            return null
        }

        return (
            <Container id="global-modal" visible={visible}>
                <Box show={show}>
                    <>
                        <Title id="title-modal" className="title">
                            Cancelar pedido
                        </Title>
                        <Icon icon="exclamation-circle" />
                        <Message id="message-modal">
                            {order?.status && ['4', '5', '6', '7'].includes(order?.status)
                                ? 'Este pedido já foi coletado e está a caminho do cliente, tem certeza de que deseja cancelar? A taxa de entrega continuará sendo cobrada ao lojista'
                                : 'Ao cancelar, ele não poderá ser recuperado.'}
                        </Message>

                        {order && <OrderSummary order={order as any} />}
                        <ContainerSelect>
                            <InputItem
                                labelText="Motivo"
                                type="select"
                                options={OPTIONS.map(({ title, code }) => ({ label: title, value: code }))}
                                inputProps={{
                                    placeholder: 'Selecione o motivo do cancelamento',
                                    value: reason?.code?.toString(),
                                    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                        _setOptionCancel(
                                            OPTIONS.find(e => {
                                                return e.code === Number(value)
                                            })
                                        )
                                    },
                                }}
                            />
                        </ContainerSelect>
                        <Footer>
                            <>
                                <ContainerButtons>
                                    <Button
                                        id="yes-button"
                                        onClick={reason && _onButtonClick('yes')}
                                        disabled={!reason}
                                    >
                                        Sim
                                    </Button>
                                </ContainerButtons>
                                <Separator />
                                <ContainerButtons>
                                    <Button id="no-button" isOutline onClick={_onButtonClick('no')}>
                                        Não
                                    </Button>
                                </ContainerButtons>
                            </>
                        </Footer>
                    </>
                </Box>
            </Container>
        )
    })
)

export { ModalOrderCancel }
