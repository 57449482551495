import azul from 'assets/images/azul-cargo.jpg'
import dmLogo from 'assets/images/delivery-mall-avatar.png'

import { Order, PendingOrder } from 'types'

import americanas from './assets/americanas-delivery.png'
import app from './assets/app.png'
import callCenter from './assets/callcenter.png'
import eCommerce from './assets/ecommerce.png'
import ifood from './assets/ifood.png'
import james from './assets/james.png'
import mercadoo from './assets/mercadoo.svg'
import neemo from './assets/neemo.png'
import pedZap from './assets/pedzap.png'
import rappi from './assets/rappi.png'
import supermenu from './assets/supermenu.png'
import televendas from './assets/televendas.png'
import uberEats from './assets/ubereats.png'
import whatsapp from './assets/whatsapp.png'
import zedelivery from './assets/zedelivery.png'

const undefinedStore =
    'https://images.vexels.com/media/users/3/152579/isolated/lists/a52ce2d4014c39b7b7c5974a1a1cbb85-icone-de-ponto-de-interrogacao-do-circulo-laranja.png'

const availableChannels = [
    { name: 'Call Center', logo: callCenter },
    { name: 'James Delivery', logo: james },
    { name: 'Rappi', logo: rappi },
    { name: 'Supermenu', logo: supermenu },
    { name: 'Ifood', logo: ifood },
    { name: 'Televendas', logo: televendas },
    { name: 'UberEats', logo: uberEats },
    { name: 'App', logo: app },
    { name: 'Zé Delivery', logo: zedelivery },
    { name: 'PedZap', logo: pedZap },
    { name: 'Whatsapp', logo: whatsapp },
    { name: 'Ecommerce Próprio', logo: eCommerce },
    { name: 'Delivery Mall', logo: dmLogo },
    { name: 'Neemo', logo: neemo },
    { name: 'Americanas Delivery', logo: americanas },
    { name: 'Azul Cargo', logo: azul },
    { name: 'Mercadoo', logo: mercadoo },
]

function getSalesChannelIcon(order: PendingOrder | Order): string | undefined {
    let selectedLogo = undefinedStore

    if ((order as PendingOrder)?.bag_id) {
        return dmLogo
    }

    if (!order?.channel && !order?.sales_channel) {
        return dmLogo
    }

    if (order?.channel || order?.sales_channel) {
        availableChannels.forEach(channel => {
            if (order?.channel === channel?.name || order?.sales_channel?.name === channel?.name) {
                selectedLogo = channel.logo
            }
        })
    }

    return selectedLogo
}
function getSalesChannelByName(channelName: string) {
    let selectedLogo = undefinedStore

    if (channelName) {
        availableChannels.forEach(channel => {
            if (channelName === channel?.name) {
                selectedLogo = channel.logo
            }
        })
    }

    return selectedLogo
}

export { getSalesChannelIcon, getSalesChannelByName }
