import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, subDays } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message/'
import TextInputForm from 'components/text-input-form'

import { formatCurrency, showErrors } from 'helpers'
import { useEventListener } from 'hooks'
import api from 'services/api'
import { Order } from 'types'

import './style.scss'

export interface ModalDeliveryForecastProps {
    isActive?: boolean
    onClose?(): void
    order: Order | undefined
    width?: string
    getOrder?(): void
}

export interface ModalDeliveryForecastRef {
    openModal(): void
    closeModal(): void
}

const ModalDeliveryForecast: React.ForwardRefRenderFunction<ModalDeliveryForecastRef, ModalDeliveryForecastProps> = (
    { isActive, order, getOrder, onClose },
    ref
) => {
    const modalMessageRef = useRef<ModalMessageRef>(null)

    const [active, setActive] = useState(false)
    const [modalMessage, setModalMessage] = useState<ModalMessageProps>({
        title: '',
        message: '',
    })

    const { isSubmitting, values, touched, errors, isValid, handleSubmit, getFieldProps, setValues } = useFormik({
        initialValues: {
            date: '',
            hours: '',
        },
        validationSchema: Yup.object().shape({
            date: Yup.date()
                .min(subDays(new Date(), 1), 'Não é possível agendar um pedido em uma data passada.')
                .required('Por favor, insira a Data'),
            hours: Yup.string()
                .trim()
                .when('date', (date: Date, schema: Yup.StringSchema) => {
                    return schema.test({
                        test: hours => {
                            if (hours) {
                                const [hour, minutes] = hours?.split(':')
                                date.setHours(hour)
                                date.setMinutes(minutes)

                                const currentDate = new Date()

                                return date <= currentDate ? false : true
                            } else {
                                return false
                            }
                        },
                        message: hours =>
                            hours?.value
                                ? 'Não é possível um pedido em horários passados.'
                                : 'Por favor, insira o Horário',
                    })
                }),
        }),

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                await api.put(`/painel/dm-order/${order?.id}/scheduled `, {
                    delivery_forecast: `${values.date} ${values.hours + (values.hours?.length < 8 ? ':00' : '')}`,
                })

                if (getOrder) {
                    getOrder()
                }

                setModalMessage({
                    title: 'Sucesso',
                    message: 'Pedido reagendado com sucesso',
                })
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessage({
                    title: 'Erro',
                    message: showErrors(error),
                })
            } finally {
                setSubmitting(false)

                modalMessageRef.current?.openModal()
            }
        },
    })

    useEffect(() => {
        setActive(Boolean(isActive))
    }, [isActive])

    useEffect(() => {
        if (order?.delivery_forecast) {
            const [d, t] = order.delivery_forecast.split(' ')
            const date = d
            const hours = t

            setValues({
                date,
                hours,
            })
        } else {
            const [d, t] = format(new Date(), 'YYYY-MM-DD HH:mm').split(' ')

            const date = d
            const hours = t

            setValues({
                date,
                hours,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    const closeModal = useCallback(() => {
        setActive(false)

        if (onClose) {
            onClose()
        }
    }, [onClose])

    const handleEscPress = useCallback(
        ({ code }) => {
            if (code === 'Escape') {
                closeModal()
            }
        },
        [closeModal]
    )

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setActive(true)
        },
        closeModal,
    }))

    return (
        <div className={`modal-delivery-forecast ${active ? 'is-active' : ''}`}>
            <div className="Modal-bg">
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">Deseja reagendar o pedido?</div>
                        <div className="Modal-close" onClick={closeModal}>
                            <FontAwesomeIcon size="lg" icon="times" />
                        </div>
                    </div>
                    <div className="Modal-body">
                        <ModalLoading visible={isSubmitting} />

                        <ModalMessage
                            ref={modalMessageRef}
                            title={modalMessage.title}
                            message={modalMessage.message}
                            textButton={modalMessage.textButton}
                            onClose={closeModal}
                        />

                        <p>
                            <strong>
                                #{order?.reference_id} - {formatCurrency(order?.total_price)}
                            </strong>{' '}
                            <br />
                            {order?.customer.name} <br />
                            {order?.address?.neighborhood}, {order?.address?.street}, {order?.address?.number}
                        </p>

                        <div style={{ fontWeight: 'bold', marginTop: '30px' }}>
                            Defina a data e hora prevista para o agendamento:
                        </div>

                        <div className="Modal-content">
                            <div className="grouped-input-container">
                                <TextInputForm
                                    label="Data*"
                                    type="date"
                                    {...getFieldProps('date')}
                                    msgErro={touched.date && errors.date}
                                />

                                <TextInputForm
                                    label="Hora*"
                                    type="time"
                                    {...getFieldProps('hours')}
                                    msgErro={touched.hours && errors.hours}
                                />
                            </div>
                        </div>

                        <div className="Modal-buttons">
                            <button
                                disabled={!isValid}
                                className="Modal-button primary"
                                type="submit"
                                onClick={() => handleSubmit()}
                            >
                                Salvar
                            </button>
                            <button className="Modal-button outline" onClick={closeModal}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ModalDeliveryForecast)
