export default {
    primary_color: '#FF860A',
    dark_primary_color: '#C44300',
    light_primary_color: '#FF9933',
    lighter_primary_color: '#F7E8D7',
    secondary_color: '#FFC612',
    dark_gray_color: '#333333',
    gray_color: '#666666',
    light_gray_color: '#999999',

    green: '#7ED321',
    yellow: '#F5A623',
    red: '#D0021B',
}
