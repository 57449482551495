import { memo, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'hooks'
import { Biker, RouteInfo } from 'types'

const typeVehicle = ['bike', 'moto', 'car', 'van', 'truck']

const Vehicles = {
    bike: 'Bicicleta',
    moto: 'Moto',
    car: 'Carro',
    van: 'Van',
    truck: 'Caminhão',
}
const VehiclesIcon = {
    bike: 'bicycle',
    moto: 'motorcycle',
    car: 'car',
    van: 'shuttle-van',
    truck: 'truck',
}

import {
    Container,
    Content,
    Avatar,
    ContainerInfo,
    AgentName,
    Line,
    RowInfo,
    Icon,
    TextInfo,
    ContainerIcon,
    ArrowIcon,
} from './agent-card.styled'
type Props = {
    agent: Biker
    route?: RouteInfo
}

const AgentCard: React.FC<Props> = memo(({ route, agent }) => {
    const { basePath } = useAuth()
    const history = useHistory()

    const position = useMemo(() => {
        if (route) {
            return `${route.position}º de ${route.total_orders} pedidos`
        }

        return null
    }, [route])

    const typeRoute = useMemo(() => {
        switch (route?.type) {
            case 'offer':
                return 'Rota Ofertada'
            case 'fix':
                return 'Rota de Correção'
            case 'chain':
                return 'Rota em Corrente'
            case 'direct':
                return 'Rota direcionada'

            default:
                return null
        }
    }, [route])

    const _goToDetails = useCallback(() => {
        if (route) {
            history.push(`${basePath}/detalhes-rota/${route.id}`)
        }
    }, [route, basePath, history])

    return (
        <Container>
            <Content>
                <Avatar className="image" src={agent.avatar} />
                <ContainerInfo>
                    <AgentName className="name">{`${agent.first_name} ${agent.last_name || ''}`}</AgentName>
                    <Line />
                    {!!route && (
                        <>
                            <RowInfo>
                                <Icon icon="paper-plane" />
                                <TextInfo>
                                    {typeRoute}
                                    {route.status === 3 && <span>(encerrada)</span>}
                                </TextInfo>
                            </RowInfo>
                            <RowInfo>
                                <Icon icon={'shopping-bag'} />
                                <TextInfo>{position}</TextInfo>
                            </RowInfo>
                        </>
                    )}
                    <RowInfo>
                        <Icon icon={VehiclesIcon[agent.type_name] as any} />
                        <TextInfo>{Vehicles[agent.type_name]}</TextInfo>
                    </RowInfo>
                </ContainerInfo>
            </Content>
            {!!route && (
                <ContainerIcon className="contianer-icon" onClick={_goToDetails}>
                    <ArrowIcon icon="arrow-right" />
                </ContainerIcon>
            )}
        </Container>
    )
})

export { AgentCard }
