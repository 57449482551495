import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { EmptyIcon } from 'components/_store-general-vision/new-orders/new-orders.styles'
import FlexTable from 'components/flex-table'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useDataFetching } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'

import { EmptyMessage, EmptyMessageSubtitle, EmptyMessageTitle } from './featured-stores-form.styles'
import { Container } from './featured-stores.styled'

export interface FeaturedStore {
    id: number
    mall_id: string
    start_time: string
    end_time: string
    stores: {
        id: number
        name: string
    }[]
    status: number
}

const FeaturedStoresListing: React.FC<RouteComponentProps> = ({ history }) => {
    const { setSnackbar, setLoading } = useUI()
    const { data: storesFeatured, revalidate: revalidateStores } = useDataFetching<PagedList<FeaturedStore>>(
        '/painel/stores-featured',
        {
            params: { mall_id: 1, order_by: 'id' },
        }
    )

    const modalConfirmation = useRef<ModalConfirmationRef>(null)
    const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })

    const modalMessage = useRef<ModalMessageRef>(null)
    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const deleteFeaturedStore = async deleteID => {
        try {
            setLoading(true)

            await api.delete(`/painel/store-featured/${deleteID}`)
            revalidateStores()
            setSnackbar({
                message: `Destaque deletado`,
            })
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmation}
                title={modalConfirmationData.title}
                message={modalConfirmationData.message}
                onYes={modalConfirmationData.onYes}
            />
            <RowTitle
                title="Lojas em destaque"
                buttonRow={[
                    {
                        label: 'Criar loja em destaque',
                        onClick: () => history.push('lojas-em-destaque/criar-loja-destaque'),
                    },
                ]}
            />
            {storesFeatured?.items?.length > 0 ? (
                <FlexTable
                    columns={[
                        { name: 'ID', width: '3%' },
                        { name: 'Hora de Início', width: '15%' },
                        { name: 'Hora de Término', width: '15%' },
                        { name: 'Lojas', width: '30%' },
                        { name: 'Status', width: '7%' },
                        { name: '', width: '30%' },
                    ]}
                    list={storesFeatured?.items?.map(storeFeatured => {
                        const { id, start_time, end_time, stores, status } = storeFeatured
                        return {
                            ID: id.toString(),
                            start_time: start_time.slice(0, -3),
                            end_time: end_time.slice(0, -3),
                            stores: (
                                <>
                                    {stores.map(item => (
                                        <div key={item.id}>{item.name}</div>
                                    ))}
                                </>
                            ),
                            status: status === 1 ? 'Ativo' : 'Inativo',
                            buttonRow: (
                                <div className="flex-table-row-content button-row">
                                    <div
                                        className="button-item outline"
                                        onClick={() =>
                                            history.push(
                                                `/marketplace/gestao-marketplace/lojas-em-destaque/editar-loja-destaque/${id}`
                                            )
                                        }
                                    >
                                        Editar
                                    </div>
                                    <div
                                        className="button-item"
                                        onClick={() => {
                                            setModalConfirmationData({
                                                title: 'Excluir destaque',
                                                message: 'Tem certeza de que deseja excluir este destaque?',
                                                onYes: () => deleteFeaturedStore(id),
                                            })
                                            modalConfirmation.current?.openModal()
                                        }}
                                    >
                                        Excluir
                                    </div>
                                </div>
                            ),
                        }
                    })}
                />
            ) : (
                <EmptyMessage>
                    <EmptyIcon />
                    <EmptyMessageTitle>Ainda não há destaques cadastrados</EmptyMessageTitle>
                    <EmptyMessageSubtitle>Cadastre um destaque para poder visualizá-lo</EmptyMessageSubtitle>
                </EmptyMessage>
            )}
        </Container>
    )
}

export default FeaturedStoresListing
