import { useCallback, useEffect, useMemo, useState, memo, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { Scroll } from 'components/scroll/scroll'
import { Stopwatch } from 'components/stopwatch/stopwatch'

import { useEchoConnection } from 'hooks/use-echo-connection'
import api from 'services/api'
import { IMallInfo } from 'types/mall'

import {
    Message,
    ContentInfo,
    TextButton,
    Footer,
    ChevronIcon,
    SegmentTitle,
    TextInfo,
    ContainerInfo,
    SkeletonContent,
    MallName,
    ContainerColumn,
    ContainerTitle,
    Row,
    RefreshIcon,
} from './column-mall-general-vision.styled'

type TotalOrders = {
    mallId: number
    finished: number
    canceled: number
}

type ColumnOrderProduction = {
    onTotalOrdersChange(total: TotalOrders): void
    onRemoveClick(index: number): void
    onNextClick(index: number): void
    onPreviousClick(index: number): void
    onProblemsClick(mallId: number): void
    isFirst: boolean
    isLast: boolean
    index: number
    mallId: number
}

const ColumnMallGeneralVision: React.FC<ColumnOrderProduction> = memo(
    ({
        mallId,
        isFirst,
        isLast,
        index,
        onRemoveClick,
        onNextClick,
        onPreviousClick,
        onTotalOrdersChange,
        onProblemsClick,
    }) => {
        const mallRef = useRef<IMallInfo>()
        const [mallInfo, setMallInfo] = useState<IMallInfo>()

        const [shake, setShake] = useState(false)
        const [loading, setLoading] = useState(true)

        const alert = useMemo(() => {
            if (mallInfo) {
                return (
                    (mallInfo.orders.in_production > 0 || mallInfo.orders.to_collect > 0) &&
                    mallInfo.agents_available === 0
                )
            }
            return false
        }, [mallInfo])

        const attention = useMemo(() => {
            if (mallInfo) {
                return mallInfo.orders.to_collect > 0 && mallInfo.agents_available === 0
            }
            return false
        }, [mallInfo])

        const eventsOrders = useMemo(() => {
            return [
                {
                    name: '.scoreboard',
                    callback: (scoreboard: any) => {
                        const newMalInfo: IMallInfo = { ...mallRef.current, orders: scoreboard }
                        setMallInfo(newMalInfo)
                        mallRef.current = newMalInfo

                        onTotalOrdersChange({
                            mallId: newMalInfo.id,
                            canceled: newMalInfo.orders.canceled,
                            finished: (newMalInfo.orders.delivered || 0) + (newMalInfo.orders.finished || 0),
                        })
                        setShake(true)
                        setTimeout(() => {
                            setShake(false)
                        }, 1000)
                    },
                },
            ]
        }, [])

        const eventsRoute = useMemo(() => {
            return [
                {
                    name: '.scoreboard',
                    callback: (scoreboard: any) => {
                        const newMalInfo: IMallInfo = { ...mallRef.current, routes: scoreboard }
                        setMallInfo(newMalInfo)
                        mallRef.current = newMalInfo
                        setShake(true)
                        setTimeout(() => {
                            setShake(false)
                        }, 1000)
                    },
                },
            ]
        }, [])

        const eventsAgents = useMemo(() => {
            return [
                {
                    name: '.presence',
                    callback: ({ total }: any) => {
                        const newMalInfo: IMallInfo = { ...mallRef.current, agents_available: total }
                        setMallInfo(newMalInfo)
                        mallRef.current = newMalInfo
                        setShake(true)
                        setTimeout(() => {
                            setShake(false)
                        }, 1000)
                    },
                },
            ]
        }, [])

        useEchoConnection<any, any>({
            enable: !!mallInfo,
            channelName: `orders.mall.${mallId}`,
            events: eventsOrders,
        })

        useEchoConnection<any, any>({
            enable: !!mallInfo,
            channelName: `route.mall.${mallId}`,
            events: eventsRoute,
        })
        useEchoConnection<any, any>({
            enable: !!mallInfo,
            channelName: `queue.mall.${mallId}`,
            events: eventsAgents,
        })

        const _onRemoveClick = useCallback(() => {
            onRemoveClick(mallId)
        }, [mallId, onRemoveClick])

        const _onNextClick = useCallback(() => {
            onNextClick(index)
        }, [index, onNextClick])

        const _onPreviousClick = useCallback(() => {
            onPreviousClick(index)
        }, [index, onPreviousClick])

        const _openMall = useCallback(() => {
            window.open(`/${mallInfo?.slug}/rotas/visao-geral`, '_blank')
        }, [mallInfo])

        const _getMallInfo = useCallback(async () => {
            setLoading(true)
            try {
                const { data } = await api.get<IMallInfo>(`/painel/mall/${mallId}/dashboard`)

                mallRef.current = data
                setMallInfo(data)

                onTotalOrdersChange({
                    mallId: data.id,
                    canceled: data.orders.canceled,
                    finished: (data.orders.delivered || 0) + (data.orders.finished || 0),
                })
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        }, [mallId, onTotalOrdersChange])

        const _onProblemsClick = useCallback(() => {
            if (onProblemsClick) {
                onProblemsClick(mallId)
            }
        }, [mallId, onProblemsClick])

        const _refresh = useCallback(() => {
            setLoading(true)
            setMallInfo(null)
            _getMallInfo()
        }, [_getMallInfo])

        useEffect(() => {
            setLoading(true)
            setMallInfo(null)
            _getMallInfo()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [mallId])

        if (loading) {
            return <ColumnMallSkeleton />
        }

        return (
            <ContainerColumn alert={alert} shake={shake} attention={attention}>
                {!mallInfo ? (
                    <Message>
                        Não foi possivel carregar as informações desse mal, por favor tente novamente em breve
                    </Message>
                ) : (
                    <>
                        <ContainerTitle alert={alert}>
                            <ChevronIcon icon={faChevronLeft} hide={isFirst} onClick={_onPreviousClick} />
                            <MallName onClick={_openMall}>{mallInfo.name}</MallName>
                            <ChevronIcon icon={faChevronRight} hide={isLast} onClick={_onNextClick} />
                        </ContainerTitle>
                        <Scroll hideScrollbar>
                            <ContainerInfo>
                                <SegmentTitle>Entregadores</SegmentTitle>
                                <ContentInfo className="content-info" alert={alert}>
                                    <Row attention={attention}>
                                        <TextInfo className="info">
                                            <span>{mallInfo.agents_available}</span> na fila
                                        </TextInfo>
                                        {alert && <Stopwatch />}
                                    </Row>
                                    <TextInfo className="info">
                                        <span>{mallInfo.routes.in_progress}</span> em rota
                                    </TextInfo>
                                </ContentInfo>
                                <SegmentTitle>Pedidos</SegmentTitle>
                                <ContentInfo className="content-info" alert={alert}>
                                    <TextInfo className="info">
                                        <span>{mallInfo.orders.scheduled}</span> Agendados
                                    </TextInfo>
                                    <TextInfo className="info">
                                        <span>{mallInfo.orders.in_production}</span> em produção
                                    </TextInfo>
                                    <TextInfo className="info">
                                        <span>{mallInfo.orders.to_collect}</span> pronto p/ coleta
                                    </TextInfo>
                                    <TextInfo className="info">
                                        <span>{mallInfo.orders.routed}</span> aguarda coleta
                                    </TextInfo>
                                    <TextInfo className="info">
                                        <span>{mallInfo.orders.in_progress}</span> em rota
                                    </TextInfo>
                                </ContentInfo>
                                <SegmentTitle>Resultado</SegmentTitle>
                                <ContentInfo className="content-info" alert={alert}>
                                    <TextInfo className="info">
                                        <span>
                                            {(mallInfo.orders.delivered || 0) + (mallInfo.orders.finished || 0)}
                                        </span>{' '}
                                        pedidos entregues
                                    </TextInfo>
                                    <TextInfo className="info">
                                        <span>{mallInfo.routes.finished}</span> rotas realizadas
                                    </TextInfo>
                                    <TextInfo className="info" onClick={!!mallInfo.orders.canceled && _onProblemsClick}>
                                        <span>{mallInfo.orders.canceled}</span> pedidos cancelados
                                    </TextInfo>
                                </ContentInfo>
                            </ContainerInfo>
                        </Scroll>

                        <Footer>
                            <TextButton onClick={_onRemoveClick}>REMOVER</TextButton>
                            <RefreshIcon icon="redo" onClick={_refresh} />
                        </Footer>
                    </>
                )}
            </ContainerColumn>
        )
    }
)
const ColumnMallSkeleton: React.FC = memo(() => {
    return (
        <ContainerColumn>
            <ContainerTitle>
                <ChevronIcon icon={faChevronLeft} />
                <MallName>
                    <Skeleton height={18} width={200} />
                </MallName>
                <ChevronIcon icon={faChevronRight} />
            </ContainerTitle>
            <Scroll hideScrollbar>
                <ContainerInfo>
                    <SegmentTitle>Entregadores</SegmentTitle>
                    <ContentInfo className="content-info">
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                    </ContentInfo>
                    <SegmentTitle>Pedidos</SegmentTitle>
                    <ContentInfo className="content-info">
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                    </ContentInfo>
                    <SegmentTitle>Resultado</SegmentTitle>
                    <ContentInfo className="content-info">
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                        <Row>
                            <SkeletonContent className="info">
                                <Skeleton height={33} width={33} />
                                <Skeleton height={10} width={200} style={{ marginLeft: 5 }} />
                            </SkeletonContent>
                        </Row>
                    </ContentInfo>
                </ContainerInfo>
            </Scroll>
        </ContainerColumn>
    )
})

export { ColumnMallGeneralVision }
