import { AnyAction } from 'redux'

export interface IntegrationType {
    token?: string
}

export const enum IntegrationReducerTypes {
    SET_INTEGRATION = 'SET_INTEGRATION',
    UPDATE_INTEGRATION = 'UPDATE_INTEGRATION',
    REMOVE_INTEGRATION = 'REMOVE_INTEGRATION',
}

export interface SetAction extends AnyAction {
    type: IntegrationReducerTypes.SET_INTEGRATION
    payload: IntegrationType
}

export interface UpdateAction extends AnyAction {
    type: IntegrationReducerTypes.UPDATE_INTEGRATION
    payload: IntegrationType
}
export interface RemoveAction extends AnyAction {
    type: IntegrationReducerTypes.REMOVE_INTEGRATION
    payload: undefined
}

export interface IntegrationReducerActions {
    setIntegration(state: IntegrationType): SetAction
    updateIntegration(state: IntegrationType): UpdateAction
    removeIntegration(): RemoveAction
}
