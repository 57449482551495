import { memo } from 'react'

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Text } from 'components/text/text'

import { ITableRomaneio } from 'types/romaneio'

import { Container, Table, RowTable, ColumnTable, ColumnHeader, CellHeader } from './table-order-romaneio.styled'

const columnHelper = createColumnHelper<ITableRomaneio>()

const columns = [
    columnHelper.accessor('cod', {
        id: 'cod',
        header: () => <CellHeader>AWB do Pedido</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('name', {
        id: 'name',
        header: () => <CellHeader>Nome do Cliente</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('cep', {
        id: 'cep',
        header: () => <CellHeader>CEP</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('address', {
        id: 'address',
        header: () => <CellHeader>Endereço</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('neighborhood', {
        id: 'neighborhood',
        header: () => <CellHeader>Bairro</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('city', {
        id: 'city',
        header: () => <CellHeader>Cidade</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('state', {
        id: 'state',
        header: () => <CellHeader>UF</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('operation', {
        id: 'operation',
        header: () => <CellHeader>Operação</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('weight', {
        id: 'weight',
        header: () => <CellHeader>Peso</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('dimensions', {
        id: 'dimensions',
        header: () => <CellHeader>Dimensões</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('volume', {
        id: 'volume',
        header: () => <CellHeader>Volume</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('value', {
        id: 'value',
        header: () => <CellHeader>Valor</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
    columnHelper.accessor('payment', {
        id: 'payment',
        header: () => <CellHeader>Pagamento</CellHeader>,
        cell: cell => <Text text={cell.getValue()} />,
    }),
]

type Props = {
    data: ITableRomaneio[]
}

const TableOrderRomaneio: React.FC<Props> = memo(({ data }) => {
    const table = useReactTable({
        data,
        columns,
        enableColumnResizing: true,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <>
            <Container>
                <Table cellPadding={0} cellSpacing={0}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <RowTable key={headerGroup.id} gray>
                                {headerGroup.headers.map(header => (
                                    <ColumnHeader key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                    </ColumnHeader>
                                ))}
                            </RowTable>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <RowTable key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <ColumnTable key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </ColumnTable>
                                ))}
                            </RowTable>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </>
    )
})

export { TableOrderRomaneio }
