import { memo } from 'react'

import { formatCurrency } from 'helpers'
import { Invoice } from 'types'

import {
    IncomeFill,
    IncomeItem,
    IncomeItemTitle,
    IncomeListingContainer,
    IncomeProgress,
    IncomeValue,
} from './income-listing.styles'

interface Props {
    incomingList: Invoice[]
}

const IncomeListing: React.FC<Props> = memo(({ incomingList }) => {
    return (
        <IncomeListingContainer>
            {incomingList
                .sort((a, b) => Number(b?.total) - Number(a?.total))
                .map((income, index) => (
                    <IncomeItem key={index}>
                        <IncomeItemTitle>
                            {income.name} - <br />
                            {income.percentage?.toFixed(2)}%
                        </IncomeItemTitle>
                        <IncomeProgress>
                            <IncomeFill style={{ width: `${income.percentage}%` }} />
                        </IncomeProgress>
                        <IncomeValue>{formatCurrency(income.total)}</IncomeValue>
                    </IncomeItem>
                ))}
        </IncomeListingContainer>
    )
})

export default IncomeListing
