import styled, { css } from 'styled-components'

const HeaderContainer = styled.thead<{ bordered?: boolean }>`
    padding: 1.25rem;
    height: 1.875rem;
    padding-left: 1.25rem;
    display: none;
    font-size: 1rem;
    font-weight: bold;

    ${({ bordered }) =>
        bordered &&
        css`
            border-bottom: 1px solid #e8e8e8;
        `}

    @media all and (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const ID = styled.th`
    width: 2%;
    text-align: left;
`

const Label = styled.th`
    width: 25%;
    text-align: left;
`
const Type = styled.th`
    width: 30%;
    text-align: left;
`
const Status = styled.th`
    width: 10%;
    text-align: left;
`

const Actions = styled.th`
    width: 20%;
`

const Color = styled.th`
    width: 12.5%;
    text-align: left;
`

export { HeaderContainer, ID, Label, Type, Status, Actions, Color }
