import { useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SearchModal } from 'modals/search-modal/search-modal'

import ChangePasswordModal from 'components/_new-general-vision/change-password-modal'
import PersonalDataModal from 'components/_new-general-vision/personal-data-modal'

import { useAuth } from 'hooks/useAuth'

import { ContextModal } from '..'

import { AuthThunk } from 'store/thunks'

import {
    NavigationItemContainer,
    NavigationBarContainer,
    NavigationBarTitle,
    NavigationIconsRow,
    SearchIcon,
    SettingIcon,
    StoreIcon,
    UserInfoContainer,
    UserIcon,
    UserName,
    UserDropdown,
    UserItem,
    DropdownText,
    DropdownLogoutIcon,
    UserIconContainer,
    UserTitleContainer,
    DropdownDataIcon,
    IconLock,
    SpanId,
    DropdownLockIcon,
} from './desktop-navigation-bar.styles'

const DesktopNavigationBar: React.FC = () => {
    const searchModalRef = useRef<SearchModal>()
    const dispatch = useDispatch()
    const history = useHistory()
    const { pathname } = history.location
    const { basePath, mall, store, user } = useAuth()

    const [isUserDropdownOpened, setIsUserDropdownOpened] = useState(false)
    const [isContextModalActive, setIsContextModalActive] = useState(false)
    const [isChangePasswordActive, setIsChangePasswordActive] = useState(false)
    const [isChangeInfoActive, setIsChangeInfoActive] = useState(false)

    function navigate(route: string) {
        history.push(route)
    }

    function getTitle() {
        if (store?.name) {
            return store.name
        }

        if (mall?.name) {
            return mall.name
        }

        if (pathname.includes('/heatmap')) {
            return 'Mapa de calor'
        }
        if (pathname.includes('/painel-malls')) {
            return 'Gerenciar Malls'
        }

        if (pathname.includes('entregadores') && basePath?.length === 0) {
            return 'Gerenciar Entregadores'
        }

        if (pathname.includes('marketplace')) {
            return 'Gerenciar Marketplace'
        }

        if (pathname.includes('notas')) {
            return 'Notas de débitos'
        }

        if (pathname.includes('financeiro')) {
            return 'Relatório de Pagamento'
        }

        if (pathname.includes('relatorio-individual')) {
            return 'Relatório Individual de Pagamento'
        }
    }

    function showHeaderItem() {
        if (user) {
            if (user.isMallManager || user?.stores?.length > 1) {
                return true
            } else {
                return false
            }
        }
    }

    function changePasswordClick() {
        setIsUserDropdownOpened(false)
        setIsChangePasswordActive(true)
    }

    function changeInfoClick() {
        setIsUserDropdownOpened(false)
        setIsChangeInfoActive(true)
    }

    return (
        <>
            <NavigationBarContainer>
                <NavigationBarTitle>
                    {getTitle()} {mall?.type === 'private' && <IconLock />}
                    {store?.id && <SpanId>(ID: {store.id})</SpanId>}
                </NavigationBarTitle>
                <NavigationIconsRow>
                    {mall?.id && (
                        <NavigationItemContainer>
                            <SearchIcon onClick={() => searchModalRef.current?.show()} />
                        </NavigationItemContainer>
                    )}
                    {showHeaderItem() && (
                        <NavigationItemContainer onClick={() => setIsContextModalActive(true)}>
                            <StoreIcon />
                        </NavigationItemContainer>
                    )}
                    {showHeaderItem() && mall?.id && !store?.id && (
                        <NavigationItemContainer onClick={() => navigate(`/${mall.slug}/gestao-mall`)}>
                            <SettingIcon />
                        </NavigationItemContainer>
                    )}
                    {/* <NavigationItemContainer>
                        <PlatformIcon onClick={() => setIsPlatformDropdownOpened(!isPlatformDropdownOpened)} />
                        <PlatformDropdown isActive={isPlatformDropdownOpened}>
                            <CloseButton onClick={() => setIsPlatformDropdownOpened(false)} />
                            <PlatformListing>
                                <PlatformItem>
                                    <RouterImage />
                                    <PlatformText>Roteirizar</PlatformText>
                                </PlatformItem>
                                <PlatformItem>
                                    <DelivererImage />
                                    <PlatformText>Entregador</PlatformText>
                                </PlatformItem>
                                <PlatformItem>
                                    <MercadooImage />
                                    <PlatformText>Mercadoo</PlatformText>
                                </PlatformItem>
                                <PlatformItem>
                                    <PlugImage />
                                    <PlatformText>Plug</PlatformText>
                                </PlatformItem>
                            </PlatformListing>
                            <MallManagementCall>Gerenciar Malls</MallManagementCall>
                        </PlatformDropdown>
                    </NavigationItemContainer> */}
                    <UserInfoContainer>
                        <UserTitleContainer onClick={() => setIsUserDropdownOpened(!isUserDropdownOpened)}>
                            <UserIcon />
                            <UserName>{`${user?.first_name} ${user?.last_name}`}</UserName>
                        </UserTitleContainer>
                        <UserDropdown isActive={isUserDropdownOpened}>
                            <UserItem onClick={() => changeInfoClick()}>
                                <UserIconContainer>
                                    <DropdownDataIcon />
                                </UserIconContainer>
                                <DropdownText>Alterar Informações</DropdownText>
                            </UserItem>
                            <UserItem onClick={() => changePasswordClick()}>
                                <UserIconContainer>
                                    <DropdownLockIcon />
                                </UserIconContainer>
                                <DropdownText>Alterar Senha</DropdownText>
                            </UserItem>
                            <UserItem onClick={() => dispatch(AuthThunk.logout())}>
                                <UserIconContainer>
                                    <DropdownLogoutIcon />
                                </UserIconContainer>
                                <DropdownText>Sair</DropdownText>
                            </UserItem>
                        </UserDropdown>
                    </UserInfoContainer>
                </NavigationIconsRow>
            </NavigationBarContainer>
            <ContextModal
                isActive={isContextModalActive}
                setIsActive={setIsContextModalActive}
                closeClick={() => setIsContextModalActive(false)}
            />
            <SearchModal ref={searchModalRef} />
            <ChangePasswordModal
                isActive={isChangePasswordActive}
                closeClick={() => setIsChangePasswordActive(false)}
            />
            <PersonalDataModal isActive={isChangeInfoActive} closeClick={() => setIsChangeInfoActive(false)} />
        </>
    )
}

export default DesktopNavigationBar
