import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

const IntegratorListingItem = ({ id, stores, name, email, roles, status, editClick, passwordClick, deleteClick }) => {
    const printItemsWithCommas = array => {
        return array?.reduce((previous, current) => (previous.length ? `${previous}, ${current}` : current), '')
    }

    return (
        <div className="integrator-item">
            <div className="left-column">
                <div className="id-name">{`${id} - ${name}`}</div>
                <div className="email">{email}</div>
                <div className="stores">{printItemsWithCommas(stores?.map(store => store.name))}</div>
                <div className="roles">{printItemsWithCommas(roles)}</div>
                <div className="status">{Number(status) === 1 ? 'Ativo' : 'Inativo'}</div>
            </div>
            <div className="integrator-right-column">
                <div className="button-item" onClick={editClick}>
                    <div className="icon">
                        <FontAwesomeIcon icon="edit" />
                    </div>
                    <div className="text">Editar</div>
                </div>

                <div className="button-item" onClick={passwordClick}>
                    <div className="icon">
                        <FontAwesomeIcon icon="lock" />
                    </div>
                    <div className="text">Alterar Senha</div>
                </div>

                <div className="button-item" onClick={deleteClick}>
                    <div className="icon">
                        <FontAwesomeIcon icon="trash-alt" />
                    </div>
                    <div className="text">Excluir</div>
                </div>
            </div>
        </div>
    )
}

export default IntegratorListingItem
