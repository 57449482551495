import React, { memo } from 'react'

import { IZone } from 'types/floor-map'

import { useFloorMapController } from './floor-map.controller'
import { MapPreview, MapPreviewContainer, MapPreviewContent } from './floor-map.styled'

type Props = {
    selectedImage?: string
    zones: IZone[]
}

const FloorMap: React.FC<Props> = memo(({ selectedImage, zones }) => {
    const { canvasRef, _startDrawing, _handleDraw, _stopDraw } = useFloorMapController(selectedImage, zones)

    return (
        <MapPreviewContainer>
            <MapPreviewContent isShow={!!selectedImage}>
                <MapPreview
                    ref={canvasRef}
                    image={selectedImage}
                    onMouseDown={_startDrawing}
                    onMouseMove={_handleDraw}
                    onMouseUp={_stopDraw}
                    onMouseLeave={_stopDraw}
                />
            </MapPreviewContent>
        </MapPreviewContainer>
    )
})

export { FloorMap }
