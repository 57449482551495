import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'

import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'

import { EmptyListMessage, LoadingMessage, LoadingMoreButton } from 'components/_common'

import { formatCurrency, getStatusOrder, getTimeDiff } from 'helpers'
import { useAuth } from 'hooks'
import { Order } from 'types'

import {
    CustomerAddress,
    CustomerName,
    DetailsItem,
    OrderClient,
    OrderDate,
    OrderId,
    OrderNeighborhood,
    OrderSmallValue,
    OrderStore,
    OrderTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    ItemButton,
    ButtonText,
    Problem,
    StoreId,
} from './orders-listing-table.styles'

interface Props {
    loading: boolean
    onLoadMore(): void
    orders: Order[]
    hasMore: boolean
}

const OrdersListingTable: React.FC<Props> = ({ loading, onLoadMore, orders, hasMore }) => {
    const { store, basePath } = useAuth()
    const history = useHistory()

    const [isStoreVision] = useState(false)

    function getTotalTime(order: Order) {
        if (Number(order.status) === 9) {
            const totalTime = getTimeDiff(order.created_at, order.end_delivery)

            return totalTime.text
        } else {
            const totalTime = getTimeDiff(order.created_at)

            return totalTime.text
        }
    }

    const _goToDetails = useCallback(
        (order: Order) => {
            return () => {
                if (store?.id) {
                    history.push(`?modal-order-id=${order.id}`)
                } else {
                    history.push(`${basePath}/detalhes-pedido/${order.id}`)
                }
            }
        },
        [history, store?.id, basePath]
    )

    return (
        <TableContainer>
            <OrderTable>
                <TableHeader>
                    <OrderId>ID</OrderId>
                    <OrderStore>{isStoreVision ? 'Canal de Venda' : 'Loja'}</OrderStore>
                    <OrderClient>Cliente e localidade</OrderClient>
                    <OrderNeighborhood>Bairro</OrderNeighborhood>
                    {!isStoreVision && <OrderDate>Recebido</OrderDate>}
                    <OrderDate>Entrega</OrderDate>
                    <OrderSmallValue>Total</OrderSmallValue>
                    <OrderSmallValue>Valor</OrderSmallValue>
                    <OrderClient>Status</OrderClient>
                    <OrderSmallValue></OrderSmallValue>
                </TableHeader>
                <TableContent>
                    {loading && orders.length === 0 ? (
                        <LoadingMessage />
                    ) : orders.length > 0 ? (
                        <>
                            {orders.map((order, index) => (
                                <TableItem key={index}>
                                    <OrderId>
                                        <span>ID:</span> {order.id}
                                    </OrderId>
                                    <OrderStore>
                                        {order.reference_id && <StoreId>{`#${order.reference_id}`}</StoreId>}
                                        <span>Loja:</span> {order.merchant?.name}
                                    </OrderStore>
                                    <OrderClient>
                                        <CustomerName>
                                            <span>Cliente:</span>
                                            {order.customer.name}
                                        </CustomerName>
                                        <CustomerAddress>
                                            <span>Endereço:</span>
                                            {order.address?.street}
                                        </CustomerAddress>
                                    </OrderClient>
                                    <OrderNeighborhood>
                                        <span>Bairro:</span>
                                        {order.address?.neighborhood}
                                    </OrderNeighborhood>
                                    {!isStoreVision && (
                                        <OrderDate>
                                            <span>Recebido:</span>
                                            {format(order.birth, 'DD/MM/YYYY [às] HH:mm')}
                                        </OrderDate>
                                    )}
                                    <OrderDate>
                                        <span>Entrega:</span>
                                        {order.end_delivery ? format(order.end_delivery, 'DD/MM/YYYY [às] HH:mm') : '-'}
                                    </OrderDate>
                                    <OrderSmallValue>
                                        <span>Tempo total:</span>
                                        {getTotalTime(order)}
                                    </OrderSmallValue>
                                    <OrderSmallValue>
                                        <span>Valor:</span>
                                        {formatCurrency(order.total_price)}
                                    </OrderSmallValue>
                                    <OrderClient>
                                        <span>Status :</span>
                                        {getStatusOrder(order.status)}
                                        {!!order?.problem?.reason && <Problem>{order.problem?.reason}</Problem>}
                                    </OrderClient>
                                    <OrderSmallValue>
                                        <ItemButton onClick={_goToDetails(order)}>
                                            <DetailsItem />
                                            <ButtonText>Ver detalhes</ButtonText>
                                        </ItemButton>
                                    </OrderSmallValue>
                                </TableItem>
                            ))}
                            {hasMore && <LoadingMoreButton loading={loading} onClick={onLoadMore} />}
                        </>
                    ) : (
                        <EmptyListMessage icon={faShoppingBag} message="Nenhum resultado encontrado" />
                    )}
                </TableContent>
            </OrderTable>
        </TableContainer>
    )
}

export default OrdersListingTable
