import { useCallback, useEffect, useRef, useState } from 'react'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { plugApi } from 'services/api'

type Params = {
    id: number
    onSuccess(): void
}

type TypeButton = 'yes' | 'no'

interface Props {
    onClose?(success?: boolean): void
    onNoClick?(): void
    onYesClick?(): void
}

function useModalRomaneioRejectCTL({ onClose }: Props) {
    const { setErrorModal, setLoading } = useUI()
    const modalShowRef = useRef<boolean>()
    const onSuccessRef = useRef<() => void>()

    const [reason, setReason] = useState<string>()
    const [romaneioId, setRomaneioId] = useState<number>()
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)

    const _setShow = useCallback(({ id, onSuccess }: Params) => {
        onSuccessRef.current = onSuccess
        setRomaneioId(id)
        setShow(true)
    }, [])

    const _onClose = useCallback(
        (success?: boolean) => {
            onSuccessRef.current = undefined
            setShow(false)
            if (onClose) {
                onClose(success)
            }
        },
        [onClose]
    )

    const _setReason = useCallback((reason: string) => {
        setReason(reason)
    }, [])

    const _onButtonClick = useCallback(
        (type: TypeButton) => {
            return async () => {
                if (type === 'no') {
                    _onClose(false)
                }
                if (type === 'yes') {
                    setLoading(true)
                    try {
                        await plugApi.put<{ message: string; status: boolean }>(`/webhooks/${romaneioId}`, {
                            status: 'reject',
                            type: 'azul-cargo',
                            reason: reason,
                        })
                        if (onSuccessRef.current) {
                            onSuccessRef.current()
                        }
                        _onClose(true)
                    } catch (error) {
                        setErrorModal({
                            title: 'Erro ao romaneio pedido',
                            subtitle: showErrors(error),
                        })
                        _onClose(false)
                    }

                    setLoading(false)
                }
            }
        },
        [romaneioId, reason, setErrorModal, setLoading, _onClose]
    )

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setVisible(false)
            setRomaneioId(null)
            setReason(null)
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    return {
        visible,
        show,
        reason,
        _onClose,
        _setReason,
        _onButtonClick,
        _setShow,
    }
}

export { useModalRomaneioRejectCTL }
