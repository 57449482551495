import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { getFormattedDateFilters, getFormInputError, getRouteType, getStatusRoute } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import { PagedList, RouteFilters, RouteStatus, RouteType, Store } from 'types'

import { selected_filters } from '../../_bags/bags-listing-header/mock-selected-filters'
import { AppliedFilters } from '../../_store-general-vision'

import {
    ButtonsContainer,
    FiltersContainer,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    InputsRow,
    MiddleContainer,
    PeriodButton,
    PrimaryButton,
    SearchResultsTitle,
    TextButton,
} from './routes-listing-header.styles'

interface Props {
    title?: string
    showFilters?: boolean
    total?: number
    filters: RouteFilters
    onFilterData?(filters: RouteFilters): void
}

const RoutesListingHeader: React.FC<Props> = ({ title, showFilters, total, filters, onFilterData }) => {
    const { user, mall } = useAuth()
    const { data: stores } = useDataFetching<PagedList<Store>>(user?.isShopkeeper ? null : '/painel/stores-to-select', {
        params: {
            mall_id: mall.id,
            order_by: 'name',
        },
    })

    const { errors, getFieldProps, handleSubmit, setValues, touched, values } = useFormik({
        initialValues: {
            store: '',
            start_date: '',
            end_date: '',
            status: '',
            ...filters,
        },
        validationSchema: Yup.object().shape({
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
        }),
        onSubmit: async values => {
            if (onFilterData) {
                onFilterData({
                    ...values,
                    status: values.status === 'undefined' ? undefined : values.status,
                    type: (values.type === 'undefined' ? undefined : values.type) as RouteType,
                    store: values.store ? Number(values.store) : undefined,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                })
            }
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    return (
        <HeaderContainer>
            <HeaderTitle>{title}</HeaderTitle>
            <MiddleContainer>
                <InputsRow>
                    {!user?.isShopkeeper && (
                        <InputContainer>
                            <InputItem
                                labelText="Loja"
                                type="select"
                                options={stores?.items.map(store => ({
                                    label: store.name,
                                    value: store.id,
                                }))}
                                inputProps={{ ...getFieldProps('store'), placeholder: 'Todas as lojas' }}
                            />
                        </InputContainer>
                    )}
                    <InputContainer>
                        <InputItem
                            labelText="Data inicial"
                            type="date"
                            inputProps={getFieldProps('start_date')}
                            errorMessage={getFormInputError('start_date', errors, touched)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Data final"
                            type="date"
                            inputProps={getFieldProps('end_date')}
                            errorMessage={getFormInputError('end_date', errors, touched)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Tipo de rota"
                            type="select"
                            options={['direct', 'offer', 'fix', 'chain'].map(type => ({
                                label: getRouteType(type as RouteType),
                                value: type,
                            }))}
                            inputProps={{ ...getFieldProps('type'), placeholder: 'Todas os tipos' }}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Status"
                            type="select"
                            options={[...Array(10)].map((_item, index) => ({
                                label: getStatusRoute(index as RouteStatus),
                                value: index,
                            }))}
                            inputProps={{ ...getFieldProps('status'), placeholder: 'Todos os status' }}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Buscar"
                            inputProps={{
                                ...getFieldProps('search'),
                                placeholder: 'Buscar por Nome e/ou Sobrenome do entregador',
                            }}
                        />
                    </InputContainer>
                    <PrimaryButton onClick={() => handleSubmit()}>Consultar</PrimaryButton>
                </InputsRow>
                <ButtonsContainer>
                    {periodFilters.map((filter, filterIndex) => (
                        <PeriodButton key={filterIndex} onClick={filter.onClick}>
                            {filter.label}
                        </PeriodButton>
                    ))}
                    {showFilters && <TextButton>Mais Filtros</TextButton>}
                </ButtonsContainer>
            </MiddleContainer>
            <FiltersContainer>
                {showFilters && <AppliedFilters filters={selected_filters} />}
                {Number(total) > 0 && (
                    <SearchResultsTitle>
                        Sua busca retornou <span>{total}</span> {total > 1 ? 'resultados' : 'resultado'}
                    </SearchResultsTitle>
                )}
            </FiltersContainer>
        </HeaderContainer>
    )
}

export default RoutesListingHeader
