import React, { memo, useCallback, useRef } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import { AnyShapeCoordinates } from 'react-mapbox-gl/lib/util/types'

import { TrailData } from 'types/map-line'

import { MapLinePopup, MapLinePopupRef } from './map-line-popup/map-line-popup'

type Props = {
    trails: TrailData[]
}

const MapLineTrail: React.FC<Props> = memo(({ trails }) => {
    const mapLinePopup = useRef<MapLinePopupRef>()

    const _pointLineOnClick = useCallback(trail => {
        return event => {
            const { lng, lat } = event.lngLat
            const { current_order, created_at } = event.feature.properties
            const popupData = {
                color: trail.color,
                lngLat: [lng, lat],
                data: {
                    label: trail.properties.label,
                    order: trail.properties.order,
                    current_order: trail.properties.order.reference_id || current_order,
                    created_at,
                },
            }

            mapLinePopup.current.openPopup(popupData)
        }
    }, [])

    return (
        <>
            {trails?.map((trail, key) => (
                <div key={key}>
                    <Layer
                        key={`l-${key}`}
                        type="line"
                        layout={{
                            'line-join': 'round',
                            'line-cap': 'round',
                        }}
                        paint={{
                            'line-color': trail.color,
                            'line-width': 5,
                        }}
                    >
                        <Feature coordinates={trail.coordinates.map(item => item.value) as AnyShapeCoordinates} />
                    </Layer>

                    <Layer
                        key={`c-${key}`}
                        type="circle"
                        paint={{
                            'circle-color': trail.color,
                            'circle-stroke-width': 5,
                            'circle-stroke-color': '#fff',
                            'circle-stroke-opacity': 1,
                        }}
                    >
                        {trail.coordinates.map((coordinate, key) => (
                            <Feature
                                key={key}
                                coordinates={coordinate.value}
                                properties={coordinate.props}
                                onClick={_pointLineOnClick(trail)}
                            />
                        ))}
                    </Layer>
                </div>
            ))}
            <MapLinePopup ref={mapLinePopup} />
        </>
    )
})

export { MapLineTrail }
