import { useCallback, useEffect, useMemo, useState } from 'react'

import { getIn, useFormik } from 'formik'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { api2 } from 'services/api'
import { IPaymentMethodsV2 } from 'types/payment'

type Form = {
    orderId?: number
    sale_channel_id?: number
    payment?: {
        id: number
    }
}

type TCallback = () => void

function useModalChangePaymentType(refresh: TCallback) {
    const { setErrorModal, setConfirmationModal, setSuccessModal } = useUI()
    const [visible, setVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [paymentOptions, setPaymentOptions] = useState<React.OptionHTMLAttributes<HTMLOptionElement>[]>([])

    const { values, errors, touched, getFieldProps, handleSubmit, resetForm } = useFormik<Form>({
        initialValues: {
            orderId: undefined,
            payment: undefined,
        },
        validationSchema: Yup.object().shape({
            payment: Yup.object().shape({
                id: Yup.number().typeError('Selecione uma opção válida').required('Forma de pagamento é obrigatória'),
            }),
        }),
        onSubmit: async values => {
            const _save = async () => {
                setLoading(true)
                try {
                    const { orderId, ...body } = values
                    await api2.put(`orders/${orderId}/details`, body)
                    setSuccessModal({
                        type: 'success',
                        title: 'Sucesso!',
                        subtitle: 'Forma de Pagamento atualizada',
                        singleButtonClick() {
                            refresh()
                            _close()
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao alterar forma de pagamento',
                        subtitle: showErrors(error),
                    })
                }
                setLoading(false)
            }
            setConfirmationModal({
                title: 'Forma de Pagamento',
                subtitle: 'Deseja atualizar a forma de pagamento do pedido?',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Atualizar',
                type: 'alert',
                rightButtonClick: _save,
            })
        },
    })

    const payment = getIn(values, 'payment.id')

    const isValid = useMemo(() => !!payment && payment !== 'undefined', [payment])

    const _show = useCallback(
        ({
            orderId,
            orderPaymentId,
            orderChannelId,
        }: {
            orderId: number
            orderPaymentId: number
            orderChannelId: number
        }) => {
            setVisible(true)
            resetForm({
                values: {
                    orderId,
                    sale_channel_id: orderChannelId,
                    payment: {
                        id: orderPaymentId,
                    },
                },
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        []
    )

    const _close = useCallback(() => {
        setVisible(false)
        resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleSubmit = useCallback(() => {
        if (isValid) {
            handleSubmit()
        }
    }, [isValid, handleSubmit])

    const _getPaymentTypes = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api2.get<{ items: IPaymentMethodsV2[] }>('payment-methods', {
                params: {
                    per_page: 100,
                    status: 1,
                },
            })

            setPaymentOptions(
                data?.items?.map(payment => ({
                    label: payment.name,
                    value: payment.id,
                }))
            )
        } catch (error) {
            setErrorModal({
                title: 'Erro ao listar formas de pagamento',
                subtitle: showErrors(error),
            })
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        _getPaymentTypes()
    }, [_getPaymentTypes])

    return {
        isValid,
        visible,
        loading,
        paymentOptions,
        errors,
        touched,
        getFieldProps,
        _show,
        _close,
        _handleSubmit,
    }
}

export { useModalChangePaymentType }
