import { Draggable, DraggableLocation, DropResult } from 'react-beautiful-dnd'
import { useHistory } from 'react-router'

import { useFormikContext } from 'formik'

import { DragWrapper } from 'components/_common'

import { useAuth, useDataFetching } from 'hooks'
import { PagedList } from 'types'
import { ProductFormFields, ProductPart, SideDish } from 'types/marketplace'

import { DishesGroup } from '..'

import {
    EmptyImage,
    EmptyMessage,
    EmptyMessageContainer,
    GroupDishesListing,
    ListingTitle,
    GroupTitle,
    GroupTitleInfo,
} from './product-dishes-content.styles'

interface Props {
    productId: number | undefined
    onEditProductPart(productPart: ProductPart, productPartIndex: number | undefined): void
}

const ProductDishesContent: React.FC<Props> = ({ productId, onEditProductPart }) => {
    const { store } = useAuth()

    const history = useHistory()
    const { pathname } = history.location

    const { setFieldValue, values } = useFormikContext<ProductFormFields>()

    async function updateProductPartsOrder(sourceIndex: number, destinationIndex: number) {
        const partSource = values.parts[sourceIndex]
        const partDestination = values.parts[destinationIndex]

        if (partSource?.id && partDestination?.id) {
            onEditProductPart({ ...partSource, order: destinationIndex + 1 }, sourceIndex)
            onEditProductPart({ ...partDestination, order: sourceIndex + 1 }, destinationIndex)
        }
    }

    function reorderProductParts(
        productParts: ProductPart[],
        source: DraggableLocation,
        destination: DraggableLocation
    ) {
        const reordered = Array.from(productParts)
        const sourceIndex = source.index

        const sourceObject = reordered[sourceIndex]

        reordered.splice(source.index, 1)
        reordered.splice(destination.index, 0, sourceObject)

        return reordered
    }

    async function handleDragEnd(result: DropResult) {
        const { source, destination } = result

        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return

        if (productId) {
            await updateProductPartsOrder(source.index, destination.index)
        }

        const reordered = reorderProductParts(values.parts, source, destination)

        setFieldValue(
            'parts',
            reordered.map((part, index) => ({ ...part, order: index + 1 }))
        )
    }

    async function onUpdateProductPartStatus(productPartIndex: number | undefined, status: 0 | 1) {
        const productPart = values.parts.find((_part, index) => index === productPartIndex)

        if (!productPart) return

        onEditProductPart({ ...productPart, status }, productPartIndex)
    }

    const { data: sideDishes } = useDataFetching<PagedList<SideDish>>('/painel/side-dishes', {
        params: { store_id: store.id, per_page: -1 },
    })

    return (
        <>
            <ListingTitle>
                <GroupTitle>Grupo de acompanhamento</GroupTitle>
                <GroupTitleInfo>Qtd mínima</GroupTitleInfo>
                <GroupTitleInfo>Qtd máxima</GroupTitleInfo>
                <GroupTitleInfo>Tipo</GroupTitleInfo>
            </ListingTitle>
            <GroupDishesListing>
                <DragWrapper onDragEnd={handleDragEnd}>
                    {values.parts.map((productPart, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ ...provided.draggableProps.style, left: 0 }}
                                >
                                    <DishesGroup
                                        key={index}
                                        productPart={productPart}
                                        productPartIndex={index}
                                        sideDishes={sideDishes?.items}
                                        onUpdateProductPartStatus={onUpdateProductPartStatus}
                                        editGroupClick={() =>
                                            history.push(`${pathname}/editar-grupo/${productPart.id}`)
                                        }
                                    />
                                </div>
                            )}
                        </Draggable>
                    ))}
                </DragWrapper>

                {values.parts.length === 0 && (
                    <EmptyMessageContainer>
                        <EmptyImage />
                        <EmptyMessage>Não há acompanhamentos cadastrados</EmptyMessage>
                    </EmptyMessageContainer>
                )}
            </GroupDishesListing>
        </>
    )
}

export default ProductDishesContent
