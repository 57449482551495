import { memo, useCallback, useEffect } from 'react'

import { format, subDays } from 'date-fns'
import { useFormik } from 'formik'
import { mutate } from 'swr'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import InfoItem from 'components/_new-general-vision/info-item/info-item'

import { useUI } from 'contexts'
import { formatCurrency, getFormInputError, showErrors } from 'helpers'
import api from 'services/api'
import { Order } from 'types'

import {
    ContentContainer,
    ContentTitle,
    OutsideContainer,
    ButtonRow,
    TextButton,
    ConfirmButton,
    CloseButton,
    CloseContainer,
    TitleContainer,
    DetailsTabContainer,
    InfoSection,
    SectionTitle,
    InputsRow,
    SectionSubtitle,
    InputContainer,
} from './reschedule-content.styled'

type Props = {
    onClose(type?: 'add' | 'update'): void
    order: Order
}
const RescheduleContent: React.FC<Props> = memo(({ order, onClose }) => {
    const { setErrorModal, setLoading, setSnackbar } = useUI()

    const _onClose = useCallback(
        (type?: 'add' | 'update') => {
            if (onClose) {
                onClose(type)
            }
        },
        [onClose]
    )

    const { errors, getFieldProps, setValues, handleSubmit, touched, isValid } = useFormik({
        initialValues: {
            date: '',
            time: '',
        },
        validationSchema: Yup.object().shape({
            date: Yup.date()
                .min(subDays(new Date(), 1), 'Não é possível agendar um pedido em uma data passada.')
                .required('Por favor, insira a Data'),
            time: Yup.string()
                .trim()
                .when('date', (date: Date, schema: Yup.StringSchema) => {
                    return schema.test({
                        test: time => {
                            if (time) {
                                const [hour, minutes] = time?.split(':')
                                date.setHours(hour)
                                date.setMinutes(minutes)

                                const currentDate = new Date()

                                return date <= currentDate ? false : true
                            } else {
                                return false
                            }
                        },
                        message: time =>
                            time?.value
                                ? 'Não é possível agendar um pedido em horários passados.'
                                : 'Por favor, insira o Horário',
                    })
                }),
        }),
        onSubmit: async values => {
            try {
                setLoading(true)

                const { data } = await api.put(`/painel/dm-order/${order.id}/scheduled `, {
                    delivery_forecast: `${values.date} ${values.time + (values.time?.length < 8 ? ':00' : '')}`,
                })

                mutate(data)

                _onClose('update')
                setSnackbar({ message: 'Pedido reagendado' })
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao reagendar pedido',
                    subtitle: showErrors(error),
                })
            } finally {
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        if (order.delivery_forecast) {
            const [d, t] = order.delivery_forecast.split(' ')
            const date = d
            const time = t

            setValues({
                date,
                time,
            })
        } else {
            const [d, t] = format(new Date(), 'YYYY-MM-DD HH:mm').split(' ')

            const date = d
            const time = t

            setValues({
                date,
                time,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={() => _onClose()}>
                    <CloseButton />
                </CloseContainer>
                <TitleContainer>
                    <ContentTitle>Reagendar Pedido</ContentTitle>
                </TitleContainer>

                <DetailsTabContainer>
                    <InfoSection>
                        <SectionTitle>Informações sobre o pedido</SectionTitle>
                        <InfoItem label="Lojista" content={order?.merchant.name} />
                        <InputsRow>
                            <InfoItem
                                label="Canal de venda"
                                content={order?.sales_channel?.name || order?.reference_name}
                            />
                            <InfoItem label="Número do pedido" content={order?.reference_id} />
                        </InputsRow>
                        <InputsRow>
                            <InfoItem label="Nome do cliente" content={order?.customer?.name} />
                            <InfoItem label="Bairro do cliente" content={order?.address?.neighborhood as string} />
                        </InputsRow>
                        <InputsRow>
                            <InfoItem label="Forma de Pagamento" content={order?.payment?.name} />
                            <InfoItem label="Valor do Pedido" content={formatCurrency(order?.total_price)} />
                        </InputsRow>
                    </InfoSection>

                    <InfoSection>
                        <SectionTitle>Reagendamento</SectionTitle>
                        <SectionSubtitle>Você poderá definir uma nova data e horário para entrega</SectionSubtitle>
                        <InputsRow>
                            <InputContainer>
                                <InputItem
                                    labelText="Data de entrega"
                                    type="date"
                                    inputProps={getFieldProps('date')}
                                    errorMessage={getFormInputError('date', errors, touched)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Horário"
                                    type="time"
                                    inputProps={getFieldProps('time')}
                                    errorMessage={getFormInputError('time', errors, touched)}
                                />
                            </InputContainer>
                        </InputsRow>
                    </InfoSection>
                </DetailsTabContainer>
            </ContentContainer>
            <ButtonRow>
                <TextButton onClick={() => _onClose()}>Cancelar</TextButton>
                <ConfirmButton disabled={!isValid} onClick={() => isValid && handleSubmit()}>
                    Reagendar
                </ConfirmButton>
            </ButtonRow>
        </OutsideContainer>
    )
})

export { RescheduleContent }
