import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'

import { useModalRomaneioRejectCTL } from './modal-romaneio-reject.controller'
import {
    Box,
    Container,
    ContainerButtons,
    Footer,
    Icon,
    Message,
    Separator,
    Title,
    Button,
    ContainerSelect,
} from './modal-romaneio-reject.styled'

type Params = {
    id: number
    onSuccess(): void
}

export type ModalRomaneioRejectRef = {
    show?(params: Params): void
    close(): void
}

interface ModalRomaneioRejectProps {
    onClose?(success?: boolean): void
}
const ModalRomaneioReject = memo(
    forwardRef<ModalRomaneioRejectRef, ModalRomaneioRejectProps>((props, ref) => {
        const { visible, show, reason, _onClose, _setReason, _onButtonClick, _setShow } =
            useModalRomaneioRejectCTL(props)

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
                close: () => _onClose(false),
            }),
            [_setShow, _onClose]
        )

        if (!visible) {
            return null
        }

        return (
            <Container id="global-modal" visible={visible}>
                <Box show={show}>
                    <>
                        <Title id="title-modal" className="title">
                            Rejeitar romaneio
                        </Title>
                        <Icon icon="exclamation-circle" />
                        <Message id="message-modal">Deseja realmente rejeitar este romaneio?</Message>

                        <ContainerSelect>
                            <InputItem
                                labelText="Motivo"
                                type="textarea"
                                inputProps={{
                                    value: reason,
                                    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                        _setReason(value)
                                    },
                                }}
                            />
                        </ContainerSelect>

                        <Footer>
                            <>
                                <ContainerButtons>
                                    <Button
                                        id="yes-button"
                                        onClick={reason && _onButtonClick('yes')}
                                        disabled={!reason}
                                    >
                                        Sim
                                    </Button>
                                </ContainerButtons>
                                <Separator />
                                <ContainerButtons>
                                    <Button id="no-button" isOutline onClick={_onButtonClick('no')}>
                                        Não
                                    </Button>
                                </ContainerButtons>
                            </>
                        </Footer>
                    </>
                </Box>
            </Container>
        )
    })
)

export { ModalRomaneioReject }
