import { useCallback, useContext, useRef } from 'react'

import { useFormik } from 'formik'
import { Marker } from 'mapbox-gl'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { ChangeOrderTypeContext } from 'contexts/change-order-type-context'
import { showErrors } from 'helpers'
import { useAuth, useCEPSearch, useGeocodingSearch, useMapboxView } from 'hooks'
import api from 'services/api'

function useDeliveryContent() {
    const draggableMarkerRef = useRef<Marker>()

    const { order, setType, setShow, setLoading } = useContext(ChangeOrderTypeContext)
    const { mall } = useAuth()
    const { setErrorModal } = useUI()
    const {
        errors,
        handleSubmit,
        isValid,
        getFieldProps,
        setFieldError,
        setFieldValue,
        setValues,
        validateForm,
        touched,
        values,
    } = useFormik({
        initialValues: {
            state: mall.address.city.state.uf,
            city: mall.address.city.name,
            zipcode: '',
            neighborhood: '',
            street: '',
            number: '',
            complement: '',
            landmark: '',
            lat: null as unknown,
            lng: null as unknown,
        },
        validationSchema: Yup.object().shape({
            zipcode: Yup.string().trim().min(8, 'CEP deve ter 8 números').required('Por favor, insira o CEP.'),
            street: Yup.string().trim().required('Por favor, insira o Logradouro'),
            number: Yup.string().trim().required('Por favor, insira o Número'),
        }),
        onSubmit: async body => {
            setLoading(true)
            try {
                await api.put(`painel/dm-order/${order.id}`, {
                    ...order,
                    destiny: null,
                    address: body,
                    type: 'delivery',
                })
                setShow(false)
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao alterar o tipo de entrega',
                    subtitle: showErrors(error),
                })
            }
            setLoading(false)
        },
    })

    const { map, mapContainerRef, addDraggableMarker } = useMapboxView({
        zoom: 13,
        onLoad: map => {
            if (!order) {
                return
            }
            if (order.address) {
                const address = order.address
                const lat = Number(address.lat || '')
                const lng = Number(address.lng || '')
                setFieldValue('street', address.street || '')
                setFieldValue('number', address.number || '')
                setFieldValue('neighborhood', address.neighborhood || '')
                setFieldValue('zipcode', (address.zipcode || '').replace(/\D/g, ''))
                setFieldValue('lat', lat || null)
                setFieldValue('lng', lng || null)

                const draggableMarker = draggableMarkerRef.current
                if (draggableMarker && map) {
                    draggableMarker.setLngLat({ lng, lat })
                    map.flyTo({ center: { lat, lng } })
                } else {
                    const marker = addDraggableMarker({ lat, lng }, ({ lat, lng }) => {
                        setFieldValue('lat', lat || null)
                        setFieldValue('lng', lng || null)
                    })
                    draggableMarkerRef.current = marker
                }
                validateForm()
            }
        },
    })

    const { getAddressByCEP } = useCEPSearch({
        onSuccess: response => {
            setValues({
                ...values,
                neighborhood: response.neighborhood,
                street: response.street,
                zipcode: response.cep,
                number: '',
                complement: '',
                landmark: '',
                lat: null,
                lng: null,
            })
            response
        },
        onError: error => setFieldError('zipcode', error.message),
    })

    const { getLatLngByAddress } = useGeocodingSearch({
        onSuccess: ({ lat, lng }) => {
            const draggableMarker = draggableMarkerRef.current
            if (draggableMarker && map) {
                draggableMarker.setLngLat({ lng, lat })
                map.flyTo({ center: { lat, lng } })
            } else {
                const marker = addDraggableMarker({ lat, lng }, ({ lat, lng }) => setValues({ ...values, lat, lng }))
                draggableMarkerRef.current = marker
            }

            setValues({ ...values, lat, lng })
        },
    })

    const _findAddress = useCallback(() => {
        getLatLngByAddress({
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            neighborhood: values.neighborhood,
            street: values.street,
            number: values.number,
        })
    }, [values, getLatLngByAddress])

    const _close = useCallback(() => {
        setType(undefined)
    }, [setType])

    return {
        mapContainerRef,
        values,
        errors,
        touched,
        isValid,
        _findAddress,
        getAddressByCEP,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        _close,
    }
}

export { useDeliveryContent }
