import { forwardRef, useImperativeHandle, useState, useCallback, RefObject } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import SelectInputForm from 'components/select-input-form'

import { Establishment, Service } from 'containers/stone-management/types'

import { showErrors } from 'helpers'
import { useEventListener } from 'hooks'
import siclosPagApi from 'services/siclospag'

import './style.scss'

export interface ModalCreateServiceBindProps {
    services: Service[]
    profiles: Establishment[]
    getProfileServices(profile_id: string): void
    modalMessageRef: RefObject<ModalMessageRef>
    onClose?(): void
    setLoadingSubmit(loading: boolean): void
    setModalMessageData(object: ModalMessageProps): void
    title: string
}

export interface ModalCreateServiceBindRef {
    openModal(): void
    closeModal(): void
}

const ModalCreateServiceBind: React.ForwardRefRenderFunction<ModalCreateServiceBindRef, ModalCreateServiceBindProps> = (
    { services, profiles, getProfileServices, modalMessageRef, onClose, setLoadingSubmit, setModalMessageData, title },
    ref
) => {
    const [active, setActive] = useState(false)

    const { errors, handleSubmit, resetForm, setFieldValue, touched, values } = useFormik({
        initialValues: {
            integrator_service_id: '',
            profile_id: '',
        },
        validationSchema: Yup.object().shape({
            integrator_service_id: Yup.string().trim().required('Selecione o serviço'),
            profile_id: Yup.string().trim().required('Selecione o perfil'),
        }),
        onSubmit: async values => {
            try {
                setLoadingSubmit(true)

                const { data } = await siclosPagApi.post('services/profile/bind-service', values)

                if (data?.error) {
                    throw data.msg
                }

                getProfileServices(values.profile_id)

                setActive(false)

                setModalMessageData({
                    title: 'Sucesso',
                    message: 'Serviço vinculado ao perfil com sucesso!',
                })

                modalMessageRef.current?.openModal()
                resetForm()
            } catch (error) {
                console.log('handleSubmit', { error })

                setModalMessageData({
                    title: 'Erro',
                    message: showErrors(error),
                    textButton: 'Revisar alterações',
                })

                modalMessageRef.current?.openModal()
            } finally {
                setLoadingSubmit(false)
            }
        },
    })

    const handleEscPress = useCallback(({ code }) => {
        if (code === 'Escape') {
            setActive(false)
        }
    }, [])

    useEventListener('keydown', handleEscPress)

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setActive(true)
        },
        closeModal: () => {
            setActive(false)
            onClose && onClose()
            resetForm()
        },
    }))

    return (
        <div className={`modal-create-service ${active ? 'is-active' : ''}`}>
            <div className="Modal-bg">
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">{title}</div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">
                            <div className="inputs-container">
                                <SelectInputForm
                                    label="Serviço*"
                                    data={services
                                        .filter(service => service.is_active)
                                        .map(service => ({
                                            name: service.integrator_service_name,
                                            value: service.integrator_service_id,
                                        }))}
                                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) =>
                                        setFieldValue('integrator_service_id', value)
                                    }
                                    value={values.integrator_service_id}
                                    msgErro={touched.integrator_service_id && errors.integrator_service_id}
                                />

                                <SelectInputForm
                                    label="Perfil*"
                                    data={profiles.map(profile => ({
                                        name: profile.business_name,
                                        value: profile.id,
                                    }))}
                                    onChange={({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) =>
                                        setFieldValue('profile_id', value)
                                    }
                                    value={values.profile_id}
                                    msgErro={touched.profile_id && errors.profile_id}
                                />
                            </div>
                        </div>
                        <div className="Modal-buttons">
                            <button className="Modal-button primary" type="submit" onClick={() => handleSubmit()}>
                                Salvar
                            </button>
                            <button className="Modal-button outline" onClick={() => setActive(false)}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ModalCreateServiceBind)
