import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`

const ContentContainer = styled.div`
    flex: 1;
    padding: 0.938rem;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 1.563rem;
    }
`

const FormLabel = styled.div`
    font-size: 0.875rem;
    color: #999999;
    margin: 0;
    padding: 0;
`

const FormSection = styled.div`
    margin-bottom: 1.875rem;
`

const TextInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 3.5rem;
`
const Title = styled.h3`
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
`
const TextContent = styled.p`
    font-size: 0.875rem;
    text-align: left;
    padding: 0;
    margin: 0;
    color: #999999;
`

const InputContainer = styled.div`
    margin-bottom: 1.25rem;
    div:first-child {
        margin-bottom: 1rem;
    }

    div > label {
        font-weight: bold;
    }
`

const CollapseContainer = styled.div`
    margin-bottom: 1.75rem;
    border-radius: 0.5rem;
`

const CollapseLabel = styled.label`
    font-size: 1rem;
    font-weight: bold;
    /* text-transform: uppercase; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`

const CollapseContentHead = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 0.5rem 0; */
`
const CollapseContentBody = styled.div<{ isCollapse?: boolean }>`
    display: ${({ isCollapse }) => (isCollapse ? 'block' : 'none')};
    height: fit-content;
    padding: 0.5rem 0;
`

const Row = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
`

const InputRow = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.7rem;

    div {
        width: 100%;
        margin-bottom: 0;
    }
`

const InputInfo = styled.span`
    font-size: 0.875rem;
    text-align: left;
    color: #999999;
`

const Icon = styled(FontAwesomeIcon)`
    /* width: 1.25rem;
    height: 1.25rem; */
    font-size: 1.25rem;
`

const ButtonRow = styled.div<{ justify?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => justify || 'space-between'};
    padding: 1.25rem;
    background-color: #f7f7f7;

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        padding: 0.625rem;
    }
`

const TextButton = styled.div<{ isRemove?: boolean }>`
    color: ${({ theme, isRemove }) => (isRemove ? theme.colors.errorMessage : theme.colors.primary)};
    margin-right: 1.875rem;
    font-size: 1rem;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 0.563rem 1.25rem;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
    span {
        margin-left: 0.25;
    }

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        margin-right: 0px;
    }
`

const ConfirmButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
`

const MapContainer = styled.div`
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
    border: 0.063rem solid #f1f1f1;
    margin-bottom: 1.25rem;
`

export {
    OutsideContainer,
    ContentContainer,
    FormLabel,
    FormSection,
    Row,
    ConfirmButton,
    TextButton,
    ButtonRow,
    CollapseContainer,
    CollapseLabel,
    InputContainer,
    CollapseContentHead,
    CollapseContentBody,
    InputRow,
    InputInfo,
    Icon,
    TextInfo,
    Title,
    TextContent,
    MapContainer,
}
