import { memo } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { getFormattedDateFilters, getFormInputError } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import { OrderFilters, PagedList, Store } from 'types'

import { selected_filters } from '../../_bags/bags-listing-header/mock-selected-filters'
import { AppliedFilters } from '../../_store-general-vision'

import {
    ButtonsContainer,
    FiltersContainer,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    InputsRow,
    MiddleContainer,
    PrimaryButton,
    SearchResultsTitle,
    TextButton,
    PeriodButton,
} from './orders-listing-header.styles'

const orderStatus = [
    { id: '0', name: 'Cancelado' },
    { id: '1', name: 'Capturado' },
    { id: '2', name: 'Aguardando moderação' },
    { id: '3', name: 'Em produção' },
    { id: '4', name: 'Pronto p/ coleta' },
    { id: '5', name: 'Coletado' },
    { id: '6', name: 'Saiu  p/ entrega' },
    { id: '7', name: 'Chegou ao cliente' },
    { id: '8', name: 'Entregue ao cliente' },
    { id: '9', name: 'Comprovante de pagamento entregue ao lojista' },
    { id: '10', name: 'Problema identificado' },
    { id: '10A', name: 'Fora da área de cobertura' },
    { id: '10B', name: 'Endereço não encontrado' },
    { id: '10C', name: 'Destinatário ausente' },
    { id: '10D', name: 'Destinatário recusou receber' },
    { id: '10E', name: 'Problema no pagamento' },
    { id: '10F', name: 'Pedido errado' },
    { id: '10G', name: 'Impossibilitado de devolver comprovantes' },
    { id: '10H', name: 'Geolocalização errada' },
    { id: '11', name: 'Entregue no Balcão' },
]

interface Props {
    title?: string
    showFilters?: boolean
    total?: number
    filters?: OrderFilters
    onFilterData?(filters: OrderFilters): void
}

const OrdersListingHeader: React.FC<Props> = ({ title, showFilters, total, filters, onFilterData }) => {
    const { user, mall } = useAuth()
    const { data: stores } = useDataFetching<PagedList<Store>>(user?.isShopkeeper ? null : '/painel/stores-to-select', {
        params: {
            mall_id: mall.id,
            order_by: 'name',
        },
    })

    const { errors, getFieldProps, handleSubmit, setValues, touched, values } = useFormik({
        initialValues: {
            merchant_id: '',
            start_date: '',
            end_date: '',
            status: '',
            ...filters,
        },
        validationSchema: Yup.object().shape({
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
        }),
        onSubmit: async values => {
            if (onFilterData) {
                onFilterData({
                    ...values,
                    merchant_id: values.merchant_id ? Number(values.merchant_id) : undefined,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                })
            }
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    return (
        <HeaderContainer>
            <HeaderTitle>{title}</HeaderTitle>
            <MiddleContainer>
                <InputsRow>
                    {!user?.isShopkeeper && (
                        <InputContainer>
                            <InputItem
                                labelText="Loja"
                                type="select"
                                options={stores?.items.map(store => ({
                                    label: store.name,
                                    value: store.id,
                                }))}
                                inputProps={getFieldProps('merchant_id')}
                            />
                        </InputContainer>
                    )}
                    <InputContainer>
                        <InputItem
                            labelText="Data inicial"
                            type="date"
                            inputProps={getFieldProps('start_date')}
                            errorMessage={getFormInputError('start_date', errors, touched)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Data final"
                            type="date"
                            inputProps={getFieldProps('end_date')}
                            errorMessage={getFormInputError('end_date', errors, touched)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Status"
                            type="select"
                            options={orderStatus.map(status => ({
                                label: status.name,
                                value: status.id,
                            }))}
                            inputProps={getFieldProps('status')}
                        />
                    </InputContainer>
                    <PrimaryButton onClick={() => handleSubmit()}>Consultar</PrimaryButton>
                </InputsRow>
                <ButtonsContainer>
                    {periodFilters.map((filter, filterIndex) => (
                        <PeriodButton key={filterIndex} onClick={filter.onClick}>
                            {filter.label}
                        </PeriodButton>
                    ))}
                    {showFilters && <TextButton>Mais Filtros</TextButton>}
                </ButtonsContainer>
            </MiddleContainer>
            <FiltersContainer>
                {showFilters && <AppliedFilters filters={selected_filters} />}
                {Number(total) > 0 && (
                    <SearchResultsTitle>
                        Sua busca retornou <span>{total}</span> {total > 1 ? 'resultados' : 'resultado'}
                    </SearchResultsTitle>
                )}
            </FiltersContainer>
        </HeaderContainer>
    )
}

export default memo(OrdersListingHeader)
