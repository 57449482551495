import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Creators as AuthActions } from 'store/reducers/auth'
import { AuthThunk } from 'store/thunks'

import login1 from '../../assets/images/login1.png'
import login2 from '../../assets/images/login2.png'
import login3 from '../../assets/images/login3.png'

import {
    LeftLogo,
    LeftSide,
    LoginBox,
    LoginImage,
    LoginImageContainer,
    LoginPageContainer,
    LoginTitle,
    MobileLogo,
    RightSide,
    AreaItem,
    AreaIcon,
    RouteIcon,
    AreaText,
    BikersIcon,
    MercadooIcon,
    MallsIcon,
    MapImage,
    CashIcon,
    BackButtonContainer,
    BackIcon,
    BackText,
} from './area-page.styles'

export default function AreaPage(): JSX.Element {
    const history = useHistory()

    const dispatch = useDispatch()

    const [loginImage, setLoginImage] = useState(login1)
    const [isChanging, setIsChanging] = useState(false)

    const changeImage = useCallback(() => {
        if (loginImage === login1) {
            setLoginImage(login2)
        }
        if (loginImage === login2) {
            setLoginImage(login3)
        }
        if (loginImage === login3) {
            setLoginImage(login1)
        }
    }, [loginImage])

    useEffect(() => {
        setTimeout(() => {
            if (isChanging) {
                setIsChanging(false)
                changeImage()
            }
        }, 1000)

        setTimeout(() => {
            if (!isChanging) {
                setIsChanging(true)
            }
        }, 5000)
    }, [isChanging, changeImage])

    return (
        <LoginPageContainer>
            <LeftSide>
                <MapImage />
                <LeftLogo />
                <LoginImageContainer>
                    <LoginImage src={loginImage} isChanging={isChanging} />
                </LoginImageContainer>
            </LeftSide>
            <RightSide>
                <LoginBox>
                    <MobileLogo />
                    <BackButtonContainer>
                        <BackIcon onClick={() => dispatch(AuthThunk.logout())} />
                        <BackText onClick={() => dispatch(AuthThunk.logout())}>Voltar para login</BackText>
                    </BackButtonContainer>
                    <LoginTitle>Escolher Área</LoginTitle>

                    <AreaItem onClick={() => history.push('/escolher-unidade')}>
                        <AreaIcon>
                            <RouteIcon />
                        </AreaIcon>
                        <AreaText>Roteirização e lojas</AreaText>
                    </AreaItem>

                    <AreaItem
                        onClick={() => {
                            dispatch(AuthActions.setStore(null))
                            dispatch(AuthActions.setMall(null))

                            history.push('/painel-malls')
                        }}
                    >
                        <AreaIcon>
                            <MallsIcon />
                        </AreaIcon>
                        <AreaText>Gerenciar Malls</AreaText>
                    </AreaItem>

                    <AreaItem onClick={() => history.push('/painel-entregadores')}>
                        <AreaIcon>
                            <BikersIcon />
                        </AreaIcon>
                        <AreaText>Gerenciar Entregadores</AreaText>
                    </AreaItem>

                    <AreaItem onClick={() => history.push('/marketplace')}>
                        <AreaIcon>
                            <MercadooIcon />
                        </AreaIcon>
                        <AreaText>Mercadoo</AreaText>
                    </AreaItem>

                    <AreaItem
                        onClick={() => {
                            dispatch(AuthActions.setStore(null))
                            dispatch(AuthActions.setMall(null))

                            history.push('/financeiro')
                        }}
                    >
                        <AreaIcon>
                            <CashIcon />
                        </AreaIcon>
                        <AreaText>Financeiro</AreaText>
                    </AreaItem>
                </LoginBox>
            </RightSide>
        </LoginPageContainer>
    )
}
