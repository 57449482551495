import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import useSound from 'use-sound'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'

import { OrderDetailsContent, StoreOrderItem } from '..'

import RootState, { PendingOrdersState } from 'store/reducers/types'
import { PendingOrder } from 'types'

import OrderDetailsModal from '../order-details-modal'
import OrderSummary from '../order-summary'

import {
    ListBox,
    ListsContainer,
    ListTitle,
    ListTitleRow,
    OrderDetails,
    NumberContainer,
    EmptyMessage,
    EmptyIcon,
    EmptyText,
    OrderCallClick,
    OrderCallIcon,
    EmptyTitle,
    NoOrdersContainer,
    NoOrdersIcon,
    NoOrdersText,
    NoOrdersTitle,
    LoadingContainer,
} from './new-orders.styles'

const pendingSound = '/pending-sound.mp3'
const cashSound = '/cash.mp3'

interface Props {
    pendingOrders: PendingOrdersState
}

const NewOrders: React.FC<Props> = ({ pendingOrders }) => {
    const [playPendingSound] = useSound(pendingSound)
    const [playCashSound] = useSound(cashSound)

    const history = useHistory()
    const { actionModalProps, setConfirmationModal, setErrorModal, setLoading, setSnackbar } = useUI()
    const [orderSelectedId, setOrderSelectedId] = useState<number>()
    const { highlightedOrderId } = useSelector<RootState, PendingOrdersState>(({ pendingOrders }) => pendingOrders)

    const { location } = history
    const { orders } = pendingOrders

    function checkPendingOrders(orders: PendingOrder[]) {
        if (orders.length > 0) {
            playPendingSound()
        } else {
            playCashSound()
        }
    }

    useEffect(() => {
        setTimeout(() => {
            checkPendingOrders(orders.items)
        }, 100)
    }, [orders])

    useEffect(() => {
        if (!location?.search) {
            setOrderSelectedId(undefined)
            return
        }

        const query = new URLSearchParams(location.search)

        const order = query.get('order')
        setOrderSelectedId(Number(order))
    }, [location])

    async function moderateOrder(orderId: number, status: boolean) {
        try {
            setLoading(true)
            await api.put(`/painel/order/${orderId}/moderate`, { status })

            if (orderId === orderSelectedId) onCloseOrder()

            setSnackbar({ message: status ? 'Novo pedido em produção' : 'Pedido cancelado' })
        } catch (error) {
            setErrorModal({
                title: 'Erro ao moderar pedido',
                subtitle: showErrors(error),
            })
        } finally {
            setLoading(false)
        }
    }

    function onConfirm(order: PendingOrder) {
        setConfirmationModal({
            title: 'Confirmar pedido?',
            subtitle: 'O pedido irá para pedidos em produção.',
            type: 'alert',
            modalIcon: 'check-circle',
            leftButtonText: 'Fechar',
            rightButtonText: 'Confirmar',
            rightButtonClick: () => moderateOrder(order.id, true),
            children: <OrderSummary order={order} />,
        })
    }

    function onReject(order: PendingOrder) {
        setConfirmationModal({
            title: 'Cancelar pedido?',
            subtitle: 'Ao cancelar, ele não poderá ser recuperado.',
            type: 'alert',
            modalIcon: 'times-circle',
            leftButtonText: 'Fechar',
            rightButtonText: 'Sim, cancelar',
            rightButtonClick: () => moderateOrder(order.id, false),
            children: <OrderSummary order={order} />,
        })
    }

    function onCloseOrder() {
        history.push('novos')
    }

    return (
        <>
            <ListsContainer>
                <ListTitleRow>
                    <ListTitle>Pendentes de aprovação</ListTitle>
                    <NumberContainer>{orders.totals}</NumberContainer>
                </ListTitleRow>
                {orders.loading ? (
                    <ListBox>
                        {[...Array(8)].map((_item, index) => (
                            <LoadingContainer key={index}>
                                <Skeleton circle height={75} width={75} style={{ marginRight: 20 }} />

                                <Skeleton height={70} />
                            </LoadingContainer>
                        ))}
                    </ListBox>
                ) : (
                    <ListBox>
                        {Number(orders.items?.length) > 0 ? (
                            <>
                                {orders.items?.map(order => (
                                    <StoreOrderItem
                                        key={order.id}
                                        order={order}
                                        isHighlighted={order.id === highlightedOrderId}
                                        onConfirm={() => onConfirm(order)}
                                        onReject={() => onReject(order)}
                                        cardClick={() => history.push(`?order=${order.id}`)}
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyMessage>
                                <EmptyIcon />
                                <EmptyText>Os pedidos novos de todos os canais serão listados aqui</EmptyText>
                            </EmptyMessage>
                        )}
                    </ListBox>
                )}
            </ListsContainer>
            <OrderDetails>
                {Number(orders.items.length) > 0 ? (
                    <>
                        {orderSelectedId ? (
                            <OrderDetailsContent
                                orderId={orderSelectedId}
                                onConfirm={onConfirm}
                                onReject={onReject}
                                onClose={onCloseOrder}
                            />
                        ) : (
                            <OrderCallClick>
                                <OrderCallIcon />
                                <EmptyTitle>Você possui pedido(s) novos!</EmptyTitle>
                                <EmptyText>Clique no pedido para visualizar seus detalhes</EmptyText>
                            </OrderCallClick>
                        )}
                    </>
                ) : (
                    <NoOrdersContainer>
                        <NoOrdersIcon />
                        <NoOrdersTitle>
                            Gerencie e acompanhe os pedidos em andamento pelo nosso painel de visualização.
                        </NoOrdersTitle>
                        <NoOrdersText>
                            Em <span>Novos</span>, você poderá:
                            <br />
                            - Visualizar os pedidos recebidos de todos os canais de venda;
                            <br />
                            - Cancelar ou confirmar o início da produção de cada pedido;
                            <br />
                            <br />
                            <br />
                            Em <span>Andamento</span>, você poderá ver os pedidos que estão:
                            <br />
                            - Agendados, e confirmar o início de sua produção;
                            <br />
                            - Em produção, e disponibilizá-los para coleta;
                            <br />
                            - Prontos para coleta;
                            <br />
                            - Em rota;
                            <br />- Entregues do dia.
                        </NoOrdersText>
                    </NoOrdersContainer>
                )}
            </OrderDetails>
            <OrderDetailsModal
                isActive={Boolean(orderSelectedId) && !actionModalProps.isActive}
                orderId={orderSelectedId}
                onConfirm={onConfirm}
                onReject={onReject}
                closeClick={onCloseOrder}
            />
        </>
    )
}

export default NewOrders
