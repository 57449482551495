import { useCallback, useEffect, useMemo, useState, memo, useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OrderProblemModal } from 'modals/order-problem-modal/order-problem-modal'

import { InputItem } from 'components/_common'
import { ColumnMallGeneralVision } from 'components/column-mall-general-vision/column-mall-general-vision'
import ModalLoading from 'components/modal-loading'

import api from 'services/api'
import { Mall, PagedList } from 'types'

import {
    ContainerButtons,
    ButtonOutline,
    Container,
    ContainerBoard,
    ContainerColumns,
    Header,
    ContainerHeaderFill,
    TitleFill,
} from './malls.general-vision.styled'

type TotalOrders = {
    mallId: number
    finished: number
    canceled: number
}
type Option = {
    label: string
    value: number
}

export default function MallsGeneralVision() {
    const totalOrdersRef = useRef<TotalOrders[]>([])
    const orderProblemModalRef = useRef<OrderProblemModal>()

    const [malls, setMalls] = useState<Mall[]>([])
    const [selectedMalls, setSelectedMalls] = useState<Option[]>([])
    const [totalOrders, setTotalOrders] = useState<TotalOrders[]>([])

    const [expand, setExpand] = useState(false)
    const [loading, setLoading] = useState(false)

    const totalFinished = useMemo(() => {
        return totalOrders.reduce((value, a) => {
            return value + a.finished
        }, 0)
    }, [totalOrders])

    const totalCanceled = useMemo(() => {
        return totalOrders.reduce((value, a) => {
            return value + a.canceled
        }, 0)
    }, [totalOrders])

    const _onRemoveClick = useCallback(
        (mallId: number) => {
            setTotalOrders(totalOrders.filter(item => item.mallId !== mallId))
            setSelectedMalls(selectedMalls.filter(item => Number(item.value) !== Number(mallId)))
        },
        [selectedMalls, totalOrders]
    )

    const _onTotalOrdersChange = useCallback((total: TotalOrders) => {
        if (totalOrdersRef.current.findIndex(item => item.mallId === total.mallId) === -1) {
            totalOrdersRef.current = [...totalOrdersRef.current, total]
            setTotalOrders([...totalOrdersRef.current])
        } else {
            const totals = totalOrdersRef.current.map(item => {
                if (item.mallId === total.mallId) {
                    return { ...total }
                }
                return item
            })
            totalOrdersRef.current = totals
            setTotalOrders([...totalOrdersRef.current])
        }
    }, [])

    const _onNextClick = useCallback(
        (index: number) => {
            const list = [...selectedMalls]
            const value = { ...list[index] }
            const broValue = { ...list[index + 1] }
            if (value && broValue) {
                list[index] = broValue
                list[index + 1] = value
                setSelectedMalls(list)
            }
        },
        [selectedMalls]
    )

    const _onPreviousClick = useCallback(
        (index: number) => {
            const list = [...selectedMalls]
            const value = { ...list[index] }
            const broValue = { ...list[index - 1] }
            if (value && broValue) {
                list[index] = broValue
                list[index - 1] = value
                setSelectedMalls(list)
            }
        },
        [selectedMalls]
    )

    const _refreshAll = useCallback(() => {
        const clone = [...selectedMalls]

        setSelectedMalls([])
        setTimeout(() => {
            setSelectedMalls(clone)
        }, 200)
    }, [selectedMalls])

    const _getMalls = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<Mall>>('/painel/malls', {
                params: { order_by: 'name', status: [1], per_page: 100 },
            })
            setMalls(data.items)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])

    const _showOrdersProblem = useCallback((mallId: number) => {
        if (mallId) {
            orderProblemModalRef.current?.show(mallId)
        }
    }, [])

    useEffect(() => {
        _getMalls()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Container>
                <Header>
                    <InputItem
                        type="autocomplete"
                        options={malls?.map(mall => ({
                            label: mall.name,
                            value: mall.id,
                        }))}
                        inputProps={{
                            placeholder: 'Selecione os malls',
                            defaultValue: [],
                            value: selectedMalls as any,
                            onChange: (value: any) => {
                                setSelectedMalls(value)
                            },
                        }}
                    />
                    {/* <Button onClick={_generateColumns}>Consultar</Button> */}
                    <ContainerButtons>
                        <ButtonOutline onClick={_refreshAll}>
                            <FontAwesomeIcon icon="redo" />
                        </ButtonOutline>
                        <ButtonOutline onClick={() => setExpand(!expand)}>
                            <FontAwesomeIcon icon="expand-arrows-alt" />
                        </ButtonOutline>
                    </ContainerButtons>
                </Header>

                <ContainerBoard expand={expand}>
                    {expand && (
                        <ContainerHeaderFill>
                            <ButtonOutline onClick={_refreshAll}>
                                <FontAwesomeIcon icon="redo" />
                            </ButtonOutline>
                            <TitleFill>
                                Logaroo - Torre de Controle [+{totalFinished} / -{totalCanceled}]
                            </TitleFill>
                            <ButtonOutline onClick={() => setExpand(!expand)}>
                                <FontAwesomeIcon icon="compress-arrows-alt" />
                            </ButtonOutline>
                        </ContainerHeaderFill>
                    )}
                    <ScrollContainer vertical={false} horizontal hideScrollbars style={{ flex: 1 }}>
                        <Board
                            selectedMalls={selectedMalls}
                            onTotalOrdersChange={_onTotalOrdersChange}
                            onRemoveClick={_onRemoveClick}
                            onNextClick={_onNextClick}
                            onPreviousClick={_onPreviousClick}
                            onProblemsClick={_showOrdersProblem}
                        />
                    </ScrollContainer>
                    <ModalLoading visible={loading} />
                </ContainerBoard>
            </Container>
            <OrderProblemModal ref={orderProblemModalRef} />
        </>
    )
}

type BoardProps = {
    onTotalOrdersChange(total: TotalOrders): void
    onRemoveClick(index: number): void
    onNextClick(index: number): void
    onPreviousClick(index: number): void
    onProblemsClick(mallId: number): void
    selectedMalls: Option[]
}

const Board: React.FC<BoardProps> = memo(
    ({ selectedMalls, onRemoveClick, onNextClick, onPreviousClick, onTotalOrdersChange, onProblemsClick }) => {
        return (
            <ContainerColumns>
                {selectedMalls.map((item, i) => (
                    <ColumnMallGeneralVision
                        mallId={item.value}
                        key={i}
                        index={i}
                        isFirst={i === 0}
                        isLast={i === selectedMalls.length - 1}
                        onTotalOrdersChange={onTotalOrdersChange}
                        onRemoveClick={onRemoveClick}
                        onNextClick={onNextClick}
                        onPreviousClick={onPreviousClick}
                        onProblemsClick={onProblemsClick}
                    />
                ))}
            </ContainerColumns>
        )
    }
)
