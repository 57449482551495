import { DefaultButton } from 'components/default-button/default-button'

import { formatCurrency } from 'helpers'
import { Product } from 'types/marketplace'

import { SwitchButton } from '..'

import {
    ProductItemContainer,
    EditButton,
    ProductAmount,
    ProductInfo,
    ProductPrice,
    ProductPriceInOffer,
    ProductSku,
    ProductStatus,
    ProductPhoto,
    ProductText,
    ProductName,
    ProductDescription,
    Actions,
} from './product-item.styles'

interface Props {
    product: Product
    updateProductStatus(productId: number, status: number): void
    editClick(productId: number): void
}

const ProductItem: React.FC<Props> = ({ product, updateProductStatus, editClick }) => {
    return (
        <ProductItemContainer>
            <ProductSku>{product.id}</ProductSku>
            <ProductStatus>
                <SwitchButton
                    isActive={Boolean(product.status)}
                    onClick={() => updateProductStatus(product.id, product.status ? 0 : 1)}
                />
            </ProductStatus>
            <ProductInfo>
                <ProductPhoto src={`${product.photo}?width=200`} />
                <ProductText>
                    <ProductName>{product.name}</ProductName>
                    <ProductDescription>{product.description}</ProductDescription>
                </ProductText>
            </ProductInfo>
            <ProductSku>
                <span>SKU:</span> {product.code_pdv || 'Não informado'}
            </ProductSku>
            {/*
                <ProductAmount>
                    <span>Estoque:</span> {product.manage_stock ? product.stock : 'Não gerenciável'}
                </ProductAmount>
            */}
            <ProductPrice>
                <span>Preço:</span>
                {product.is_offer ? <ProductPriceInOffer>{formatCurrency(product.price)}</ProductPriceInOffer> : null}
                {product.is_offer ? formatCurrency(product.price_offer) : formatCurrency(product.price)}
            </ProductPrice>
            <Actions>
                <DefaultButton outline widthFitContainer onClick={() => editClick(product.id)}>
                    editar
                </DefaultButton>
            </Actions>
        </ProductItemContainer>
    )
}

export default ProductItem
