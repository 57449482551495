import { useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ButtonForm from 'components/button-form'
import InputRadio from 'components/input-radio'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageRef, ModalMessageProps } from 'components/modal-message'
import RowTitle from 'components/row-title'
import SelectInputForm from 'components/select-input-form'
import TextArea from 'components/text-area'
import TextInputForm from 'components/text-input-form'

import { getFormInputError, showErrors } from 'helpers'
import { useAuth, useAxiosRequest } from 'hooks'
import api from 'services/api'
import { PagedList, SideDish, SideDishCategory } from 'types'

const SideDishForm: React.FC<RouteComponentProps<{ side_dish_id: string }>> = ({ history, match: { params } }) => {
    const { basePath, store } = useAuth()
    const modalMessage = useRef<ModalMessageRef>(null)

    const [modalMessageData, setModalMessageData] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })

    const onRequestError = (error: AxiosError) => {
        setModalMessageData({
            title: 'Erro',
            message: showErrors(error),
        })

        modalMessage.current?.openModal()
    }

    const { loading } = useAxiosRequest<SideDish>({
        api,
        url: `/painel/side-dish/${params.side_dish_id}`,
        executeOnStart: params.side_dish_id ? true : false,
        onComplete: ({ data }) => {
            const { id, category, price } = data

            setValues({
                ...values,
                ...data,
                id: id.toString(),
                category_id: category.id.toString(),
                price: price,
            })
        },
        onError: onRequestError,
    })

    const sideDishCategories = useAxiosRequest<PagedList<SideDishCategory>>({
        api,
        url: '/painel/side-dish-categories',
        initialConfig: { params: { store_id: store.id, order_by: 'name' } },
        onError: onRequestError,
    })

    const submitSideDish = useAxiosRequest({
        api,
        method: params.side_dish_id ? 'put' : 'post',
        executeOnStart: false,
        onComplete: () => {
            setModalMessageData({
                title: 'Sucesso!',
                message: `Acompanhamento ${params.side_dish_id ? 'editado' : 'criado'} com sucesso!`,
                onClose: () => history.push(`${basePath}/gestao-marketplace/acompanhamentos`),
            })

            modalMessage.current?.openModal()
        },
        onError: onRequestError,
    })

    const { isSubmitting, errors, getFieldProps, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                id: '',
                name: '',
                description: '',
                category_id: '',
                code_pdv: '',
                price: 0,
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Nome do acompanhamento é obrigatório'),
                category_id: Yup.string().required('Categoria do acompanhamento é obrigatória'),
                price: Yup.number().min(0).required('Preço é obrigatório'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    await submitSideDish.request({
                        url: values.id ? `/painel/side-dish/${values.id}` : '/painel/side-dish',
                        data: {
                            ...values,
                            store_id: values.id ? undefined : store.id,
                        },
                    })

                    resetForm()
                } finally {
                    setSubmitting(false)
                }
            },
        })

    return (
        <div>
            <RowTitle
                title={params.side_dish_id ? 'Editar Acompanhamento' : 'Criar Acompanhamento'}
                backClick={() => history.push(`${basePath}/gestao-marketplace/acompanhamentos`)}
            />

            <ModalLoading visible={isSubmitting || loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <div className="marketplace-form-container">
                <div className="inputs-row col-3">
                    <TextInputForm
                        label="Nome*"
                        {...getFieldProps('name')}
                        msgErro={getFormInputError('name', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-2">
                    <TextArea
                        label="Descrição (opcional)"
                        {...getFieldProps('description')}
                        msgErro={getFormInputError('description', errors, touched)}
                    />
                </div>

                <div className="inputs-row col-3">
                    <SelectInputForm
                        label="Categoria do acompanhamento*"
                        data={sideDishCategories.data?.items.map(item => ({ name: item.name, value: item.id }))}
                        {...getFieldProps('category_id')}
                        msgErro={getFormInputError('category_id', errors, touched)}
                    />
                    <TextInputForm
                        label="SKU"
                        {...getFieldProps('code_pdv')}
                        msgErro={getFormInputError('code_pdv', errors, touched)}
                    />
                    <TextInputForm
                        label="Preço*"
                        type="currency"
                        maxLength={18}
                        onChange={(floatValue: number) => setFieldValue('price', floatValue)}
                        value={values.price}
                        msgErro={touched.price && errors.price}
                    />
                </div>

                <div className="inputs-row col-2">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="radio-group">
                            <label>Status</label>
                            <div className="radio-group-row">
                                <InputRadio
                                    id="true-status"
                                    label="Ativo"
                                    checked={Boolean(values.status)}
                                    onClick={() => setFieldValue('status', 1)}
                                />

                                <InputRadio
                                    id="false-status"
                                    label="Inativo"
                                    checked={!values.status}
                                    onClick={() => setFieldValue('status', 0)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ButtonForm type="submit" buttonText="Salvar" style={{ marginTop: 30 }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default SideDishForm
