import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAuth } from 'hooks/useAuth'
import { useDataFetching } from 'hooks/useDataFetching'
import { RoutesActions, RoutesScoreboard } from 'store/reducers/routes'
import RootState, { RoutesState } from 'store/reducers/types'

import { useEchoPrivateChannel } from './useEchoPrivateChannel'

type Hook = RoutesState & {
    isSocketConnected: boolean
    loading: boolean
    revalidateRoutesScoreboard(): Promise<void>
}

export function useRoutesChanges(): Hook {
    const dispatch = useDispatch()
    const { mall } = useAuth()
    const routesState = useSelector<RootState, RoutesState>(({ routes }) => routes)

    const { setScoreboard } = RoutesActions

    const channelName = mall?.id ? `route.mall.${mall.id}` : undefined
    const { isSocketConnected } = useEchoPrivateChannel({
        channelName,
        events: [
            {
                name: '.scoreboard',
                callback: scoreboard => dispatch(RoutesActions.setScoreboard(scoreboard as RoutesScoreboard)),
            },
        ],
    })

    const { loading, revalidate } = useDataFetching('/painel/routes/scoreboard', {
        params: {
            mall: mall?.id,
            today: true,
        },
        onSuccess: data => {
            dispatch(setScoreboard(data as RoutesScoreboard))
        },
    })

    const resetRoutesScoreboard = useCallback(() => {
        const initialScoreboard = {
            accepted: 0,
            canceled: 0,
            failed: 0,
            finished: 0,
            offered: 0,
            refused: 0,
            in_progress: 0,
            waiting: 0,
        }

        dispatch(setScoreboard(initialScoreboard))
    }, [dispatch, setScoreboard])

    const revalidateRoutesScoreboard = useCallback(async () => {
        resetRoutesScoreboard()

        await revalidate()
    }, [resetRoutesScoreboard, revalidate])

    return { ...routesState, isSocketConnected, loading, revalidateRoutesScoreboard }
}
