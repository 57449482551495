import styled from 'styled-components'

const MapPreviewContainer = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0rem 1.25rem 0rem 1.25rem;
    gap: 1rem;
    flex: 1;
`

const MapPreviewContent = styled.div<{ isShow?: boolean }>`
    display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
`

const MapPreview = styled.canvas<{ image?: string }>`
    height: 45rem;
    width: 45rem;
    background-image: url(${({ image }) => image});
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
`

export { MapPreview, MapPreviewContainer, MapPreviewContent }
