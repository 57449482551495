import { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useHistory } from 'react-router-dom'

import { stringify } from 'query-string'

import { AmountCard, FinancialHeader, LoadingMessage, PerformanceCard } from 'components/_common'
import { GeneralReportsTable } from 'components/_reports'

import { formatCurrency, setSearchParams } from 'helpers'
import { getStoresChart } from 'helpers/performance-page'
import { useDataFetching } from 'hooks'
import { BikerReport, ChartObject, GeneralPaymentReport, ChartResponse } from 'types'

import {
    CardRow,
    AmountRow,
    PerformanceContent,
    PerformancePageContainer,
    RowItem,
    AmountItem,
} from './general-report-page.styles'

interface FinancialFilters {
    malls: number[]
    start_date: string
    end_date: string
}

interface StoreChart {
    store_performance: ChartResponse
}

export default function GeneralReportPage(): JSX.Element {
    const history = useHistory()
    const [bikersList, setBikersList] = useState([] as BikerReport[])
    const [storeChart, setStoreChart] = useState({} as ChartObject)

    const filters: FinancialFilters = useMemo(() => {
        const query = new URLSearchParams(location.search)
        const malls = query.get('malls')
        const mallsArray = malls?.split(',').map(id => Number(id))
        return {
            malls: mallsArray || undefined,
            start_date: query.get('start_date') || undefined,
            end_date: query.get('end_date') || undefined,
            start_time: query.get('start_time') || undefined,
            end_time: query.get('end_time') || undefined,
            current_page: query.get('current_page') || undefined,
        }
    }, [location.search])

    const revalidateOnMount = Boolean(stringify(filters).length)

    const { data: reportData, loading: paymentLoading } = useDataFetching<GeneralPaymentReport>(
        '/painel/report/payments',
        {
            params: filters,
            revalidateOnMount: revalidateOnMount,
            onSuccess: data => {
                if (data.current_page > 1) {
                    setBikersList([...bikersList, ...data?.items])
                } else {
                    setBikersList(data.items)
                }
            },
        }
    )

    const { loading: chartLoading } = useDataFetching<StoreChart>('/painel/report/store', {
        params: filters,
        revalidateOnMount: revalidateOnMount,
        onSuccess: data => {
            setStoreChart(getStoresChart(data.store_performance))
        },
    })

    function loadMore() {
        setSearchParams({ ...filters, current_page: Number(reportData?.current_page) + 1 }, location, history)
    }

    return (
        <PerformancePageContainer>
            <FinancialHeader
                filters={filters}
                onFilterData={(filters: FinancialFilters) => {
                    setSearchParams(filters, location, history)
                }}
            />
            <PerformanceContent>
                <CardRow>
                    <RowItem>
                        <PerformanceCard title="Visão geral" subtitle="Placar geral do pedido">
                            {paymentLoading ? (
                                <LoadingMessage />
                            ) : (
                                <AmountRow>
                                    <AmountItem>
                                        <AmountCard
                                            number={`${reportData?.header.total_deliveries || 0}`}
                                            title="Total de entregas realizadas"
                                            subtitle="Número de pedidos do período"
                                        />
                                    </AmountItem>
                                    <AmountItem>
                                        <AmountCard
                                            number={formatCurrency(reportData?.header.total_paid || 0)}
                                            title="Total a ser pago"
                                            subtitle="A ser repassado aos entregadores"
                                        />
                                    </AmountItem>
                                    <AmountItem>
                                        <AmountCard
                                            number={`${reportData?.header.total_routes || 0}`}
                                            title="Rotas Finalizadas"
                                            subtitle="Total de rotas feitas no período"
                                        />
                                    </AmountItem>
                                    <AmountItem>
                                        <AmountCard
                                            number={`${reportData?.header.total_agents || 0}`}
                                            title="Total de entregadores"
                                            subtitle="Número de entregadores atuantes"
                                        />
                                    </AmountItem>
                                </AmountRow>
                            )}
                        </PerformanceCard>
                    </RowItem>
                    <RowItem>
                        <PerformanceCard
                            title="Quantitativo de pedidos por lojas"
                            subtitle="Baseado nos pedidos recebidos de cada estabelecimento"
                        >
                            {chartLoading ? (
                                <LoadingMessage />
                            ) : (
                                <>
                                    {storeChart.data && (
                                        <Bar
                                            data={storeChart.data}
                                            height={230}
                                            options={{
                                                plugins: {
                                                    tooltip: {
                                                        callbacks: storeChart.callbacks,
                                                    },
                                                },
                                                responsive: true,
                                                scales: {
                                                    x: {
                                                        stacked: true,
                                                    },
                                                    y: {
                                                        stacked: true,
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </PerformanceCard>
                    </RowItem>
                </CardRow>
                <GeneralReportsTable
                    filters={filters}
                    bikers={bikersList}
                    loading={paymentLoading}
                    loadClick={() => loadMore()}
                    totals={reportData?.totals}
                />
            </PerformanceContent>
        </PerformancePageContainer>
    )
}
