import styled, { css } from 'styled-components'

const ContainerDistrics = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
`

const Message = styled.div`
    padding: 0px 20px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    color: #1e1e1e;
`

const Header = styled.div`
    padding: 10px;
    .badge {
        font-size: 14px;
        font-weight: 500;
        border-width: 1.5px;
    }
`

const Badge = styled.div<{ selected?: boolean }>`
    user-select: none;
    margin-top: 10px;
    margin-right: 10px;
    width: fit-content;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 16px;
    padding: 4px 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
    }
    ${({ selected }) =>
        selected &&
        css`
            background-color: ${({ theme }) => theme.colors.primary};
            color: #fff;
        `}
`

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`

const ButtonText = styled.div`
    color: var(--primary-color);
    cursor: pointer;
    margin-top: 20px;
    @media (min-width: 1200px) {
        margin-top: 0;
    }
`

export { ContainerDistrics, Badge, Footer, Message, ButtonText, Header }
