import styled, { css } from 'styled-components'

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    user-select: none;

    .confirm-button {
        width: 200px;
        margin-right: 20px;
    }
`

const ContentOrder = styled.div`
    padding: 16px;
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
`

const MapContainer = styled.div`
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
    border: 0.063rem solid #f1f1f1;
    margin: 1.25rem 0rem;
`

const SectionTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
`

const OrderDetailItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    .container-canvas {
        min-height: 400px;
        margin-bottom: 20px;
    }
`
const RowStore = styled.div<{ first?: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    width: 100%;

    ${({ first }) =>
        !first &&
        css`
            border-top: 0.5px solid #ccc;
            padding-top: 1.25rem;
        `}
`
const Avatar = styled.img`
    width: 2.8rem;
    height: 2.8rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.5rem;
`

const StoreName = styled.div``

const Total = styled.div`
    text-align: right;
    font-size: 0.8rem;
`

export { Container, ContentOrder, Row, MapContainer, OrderDetailItem, SectionTitle, Avatar, StoreName, RowStore, Total }
