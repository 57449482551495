import React, { memo } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import TopRowDefault from 'components/top-row-default/top-row-default'

import { AttendanceArea } from 'containers/attendance-area/attendance-area'

import { useAuth } from 'hooks'

import { Container, Content } from './store-rates.styled'

interface Props extends RouteComponentProps {}

const StoreRates: React.FC<Props> = memo(({ history }) => {
    const { basePath } = useAuth()

    return (
        <Container>
            <TopRowDefault
                title="Áreas de Cobrança Marketplace"
                onBackButtonClick={() => history.replace(`${basePath}/gestao-mall`)}
            />
            <Content>
                <AttendanceArea type="mall" />
            </Content>
        </Container>
    )
})

export { StoreRates }
