import styled from 'styled-components'

export const ProductItemContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`

export const ProductId = styled.div`
    margin-right: 5%;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 20%;
        font-size: 14px;
    }
`

export const ProductTitle = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 75%;
    }
`

export const ProductTitleText = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        font-size: 14px;
    }
`

export const Observation = styled.div`
    font-size: 12px;
    text-transform: normal !important;
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.lightGray};
`

export const ProductInfoText = styled.div`
    width: 20%;
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 20%;
        font-size: 14px;
    }
`

export const ProductTitleRow = styled.div`
    display: flex;
    width: 55%;
    margin-right: 5%;
    align-items: flex-start;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
    }
`
