import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, showErrors } from 'helpers'
import api, { plugApi } from 'services/api'
import { Mall, PagedList, Store } from 'types'
import { IIntegration, IntegrationType } from 'types/integration'
import { getIntegrationName } from 'utils/get-integration-name'

import {
    CloseButton,
    CloseContainer,
    ConfirmButton,
    TextButton,
    ButtonRow,
    Row,
    InputContainer,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
} from './manager-integrator-content.styled'

type OriginStore = {
    id: string
    name: string
}

interface Props {
    type: IntegrationType
    closeClick(isRefresh?: boolean): void
    isActive: boolean
    integration?: IIntegration | null
}

export interface Form {
    origin?: {
        id: string
        name: string
    }
    logaroo?: {
        id: number
        name: string
        logo: string
        slug: string
        address: {
            state: string
        }
        mall: {
            id: number
            name: string
        }
    }
    type: string
    status: boolean
}

const ManagerIntegratorContent: React.FC<Props> = memo(({ closeClick, isActive, type, integration }) => {
    const [malls, setMalls] = useState<Mall[]>([])
    const [stores, setStores] = useState<Store[]>([])
    const [originStores, setOriginStores] = useState<OriginStore[]>([])

    const { setSuccessModal, setErrorModal, setConfirmationModal } = useUI()

    const isEdit = useMemo(() => {
        return !!integration
    }, [integration])

    const { setValues, values, errors, getFieldProps, handleSubmit, setFieldValue, touched, resetForm } =
        useFormik<Form>({
            initialValues: {
                status: true,
                type: type,
            },
            validationSchema: Yup.object().shape({
                logaroo: Yup.string().trim().required('Loja é obrigatório'),
                origin: Yup.string().trim().required('loja é obrigatório'),
                type: Yup.string().trim().required('Tipo é obrigatório'),
                status: Yup.bool().required('Status é obrigatório'),
            }),
            onSubmit: async (values, { resetForm }) => {
                try {
                    if (!isEdit) {
                        await plugApi.post(`/integrations`, values)
                        setSuccessModal({
                            title: 'Integração Criada',
                            subtitle: 'Sua Integração foi integrada com sucesso',
                        })
                    } else {
                        await plugApi.put(`/integrations/${integration.id}`, values)
                        setSuccessModal({
                            title: 'Integração Atualizada',
                            subtitle: 'Sua Integração foi atualizada com sucesso',
                        })
                    }

                    resetForm()
                    if (closeClick) {
                        closeClick(true)
                    }
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao alterar status',
                        subtitle: showErrors(error),
                    })
                }
            },
        })

    const _onClose = useCallback(() => {
        setStores([])
        resetForm()

        if (closeClick) {
            closeClick()
        }
    }, [closeClick, resetForm])

    const _getStores = useCallback(async (mall_id: number | string) => {
        if (mall_id) {
            try {
                const { data } = await api.get<PagedList<Store>>('/painel/stores/available', {
                    params: { integrator_id: process.env.REACT_APP_INTEGRATOR_ID, mall_id },
                })
                setStores(data.items)
            } catch (error) {
                console.log('error', error)
            }
        } else {
            setStores([])
        }
    }, [])

    const _originStore = useCallback(async () => {
        try {
            const { data } = await plugApi.get<OriginStore[]>('/store/available', { params: { type } })
            setOriginStores(data.map(item => ({ ...item, id: item.id.toString() })))
        } catch (error) {
            console.log('error', error)
        }
    }, [type])

    const _getMalls = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<Mall>>('/painel/malls', {
                params: { order_by: 'name', status: [1], per_page: 100 },
            })
            setMalls(data.items)
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    const _setLogaroo = useCallback(
        (storeId: string) => {
            if (storeId) {
                const store = stores.find(item => item.id === Number(storeId))
                setFieldValue('logaroo', store)
            }
        },
        [stores, setFieldValue]
    )

    const _setOrigin = useCallback(
        (storeId: string) => {
            if (storeId) {
                const origin = originStores.find(item => item.id === storeId)
                setFieldValue('origin', origin)
            }
        },
        [originStores, setFieldValue]
    )

    const _remove = useCallback(() => {
        if (integration) {
            const callback = async () => {
                try {
                    await plugApi.delete(`/integrations/${integration.id}`)
                    setSuccessModal({
                        title: 'Integração removida',
                        subtitle: 'Sua Integração foi removida com sucesso',
                    })
                    resetForm()
                    if (closeClick) {
                        closeClick(true)
                    }
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao alterar status',
                        subtitle: showErrors(error),
                    })
                }
            }
            setConfirmationModal({
                title: 'Remover Integração',
                subtitle: 'Deseja Realmente remover está integração?',
                leftButtonText: 'Não',
                rightButtonText: 'Sim',
                rightButtonClick: callback,
            })
        }
    }, [integration, setConfirmationModal, setSuccessModal, closeClick, resetForm, setErrorModal])

    useEffect(() => {
        if (isActive) {
            _originStore()
            _getMalls()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive])

    useEffect(() => {
        if (integration) {
            if (integration?.logaroo?.mall?.id) {
                _getStores(integration.logaroo.mall.id)
            }
            setValues({
                status: integration.status,
                type: integration.type,
                logaroo: integration.logaroo,
                origin: integration.origin,
            })
        } else {
            setValues({
                status: true,
                type: type,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration])

    useEffect(() => {
        if (!isActive) {
            resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isActive) {
        return null
    }

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={_onClose}>
                    <CloseButton />
                </CloseContainer>
                <ContentTitle>{getIntegrationName(type)}</ContentTitle>
                <FormSection>
                    <div>{isEdit ? 'Gerenciar' : 'Adicionar'} Loja Integrada</div>
                </FormSection>
                <FormSection>
                    <InputContainer>
                        <InputItem
                            labelText="Mall"
                            type="select"
                            options={malls.map(mall => ({
                                label: mall.name,
                                value: mall.id,
                            }))}
                            inputProps={{
                                disabled: isEdit,
                                value: values.logaroo?.mall?.id,
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setStores([])
                                    _getStores(value)
                                },
                            }}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText="Loja"
                            type="select"
                            name="id-store"
                            options={stores.map(store => ({
                                label: store.name,
                                value: store.id,
                            }))}
                            inputProps={{
                                placeholder: 'Selecione uma loja',
                                disabled: isEdit || stores.length === 0,
                                ...getFieldProps('stores'),
                                value: values.logaroo?.id,
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    _setLogaroo(value)
                                },
                            }}
                            errorMessage={getFormInputError('logaroo', errors, touched)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputItem
                            labelText={`Loja da ${getIntegrationName(type)}`}
                            type="select"
                            name="id-store"
                            options={originStores.map(store => ({
                                label: store.name,
                                value: store.id,
                            }))}
                            inputProps={{
                                disabled: isEdit || originStores.length === 0,
                                placeholder: 'Selecione uma loja',
                                ...getFieldProps('stores'),
                                value: values.origin?.id,
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    _setOrigin(value)
                                },
                            }}
                            errorMessage={getFormInputError('origin', errors, touched)}
                        />
                    </InputContainer>

                    <InputContainer>
                        <InputItem
                            labelText="Status"
                            type="select"
                            options={[
                                { label: 'Loja aberta', value: 'true' },
                                { label: 'Loja fechada', value: 'false' },
                            ]}
                            inputProps={{
                                placeholder: 'Selecione um status',
                                ...getFieldProps('status'),
                                value: values.status.toString(),
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('status', value === 'true')
                                },
                            }}
                            errorMessage={getFormInputError('status', errors, touched)}
                        />
                    </InputContainer>
                </FormSection>
            </ContentContainer>
            <ButtonRow>
                <div>
                    {isEdit && (
                        <TextButton isRemove onClick={_remove}>
                            <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                        </TextButton>
                    )}
                </div>
                <Row>
                    <TextButton onClick={_onClose}>Cancelar</TextButton>
                    <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                </Row>
            </ButtonRow>
        </OutsideContainer>
    )
})

export { ManagerIntegratorContent }
