import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'

import { useUI } from 'contexts'
import { getFormInputError, showErrors } from 'helpers'
import api, { plugApi } from 'services/api'
import { PagedList } from 'types'
import { IOriginators } from 'types/originators'
import { ISalesChannel } from 'types/sales-channel'

import {
    Button,
    CloseButton,
    CloseContainer,
    ConfirmButton,
    TextButton,
    ButtonRow,
    InputContainer,
    FormSection,
    ContentTitle,
    OutsideContainer,
    ContentContainer,
} from './auth-integrator-content.styled'

interface Props {
    origin?: IOriginators
    closeClick(isRefresh?: boolean): void
    isActive: boolean
}

const AuthIntegratorContent: React.FC<Props> = memo(({ closeClick, origin, isActive }) => {
    const { setSuccessModal, setErrorModal } = useUI()

    const [channels, setChannels] = useState<ISalesChannel[]>([])

    const { values, errors, setValues, setFieldValue, getFieldProps, handleSubmit, resetForm, touched } = useFormik({
        initialValues: {
            name: '',
            credentials: '',
            logaroo_id: 0,
            logo: '',
            status: true,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required('Nome é obrigatório'),
            credentials: Yup.string()
                .trim()
                .required('Credênciais é obrigatório')
                .test('json-invalid', 'JSON inválido', value => isJsonString(value)),
            logaroo_id: Yup.number().min(1, 'Canal de venda é obrigatório').required('Canal de venda éobrigatório'),
            logo: Yup.string().trim().required('Logo é obrigatório'),
            status: Yup.boolean().required('Status é obrigatório'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (origin?.id) {
                    await plugApi.put<{
                        access_token: string
                        token_type: string
                    }>(`/originators/${origin.id}`, {
                        ...values,
                        credentials: JSON.parse(values.credentials),
                    })
                    setSuccessModal({
                        title: 'Origem atualizada',
                        subtitle: 'A origem foi atualizada com sucesso',
                    })
                } else {
                    await plugApi.post<{
                        access_token: string
                        token_type: string
                    }>(`/originators`, {
                        ...values,
                        credentials: JSON.parse(values.credentials),
                    })

                    setSuccessModal({
                        title: 'Origem criada',
                        subtitle: 'A origem foi criada com sucesso',
                    })
                }
                resetForm()
                if (closeClick) {
                    closeClick(true)
                }
            } catch (error) {
                setErrorModal({
                    title: 'Erro ao alterar status',
                    subtitle: showErrors(error),
                })
            }
        },
    })

    const _onClose = useCallback(() => {
        resetForm()

        if (closeClick) {
            closeClick()
        }
    }, [closeClick, resetForm])

    const _getSalesChannel = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<ISalesChannel>>(`/painel/sales-channels`)
            setChannels(data.items)
        } catch (err) {
            console.log('err', err)
        }
    }, [])

    useEffect(() => {
        _getSalesChannel()
    }, [])

    useEffect(() => {
        if (origin) {
            setValues({
                name: origin.name,
                credentials: '',
                logaroo_id: origin.logaroo_id,
                logo: origin.logo,
                status: origin.status,
            })
        } else {
            setValues({
                name: '',
                credentials: '',
                logaroo_id: 0,
                logo: '',
                status: true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [origin])

    useEffect(() => {
        if (!isActive) {
            resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isActive) {
        return null
    }

    return (
        <OutsideContainer>
            <ContentContainer>
                <CloseContainer onClick={_onClose}>
                    <CloseButton />
                </CloseContainer>
                <ContentTitle>{origin?.id ? 'Atualizar Credencial' : 'Criar Credencial'}</ContentTitle>
                <FormSection>
                    <InputContainer>
                        <InputItem
                            labelText="Nome"
                            inputProps={getFieldProps('name')}
                            errorMessage={getFormInputError('name', errors, touched)}
                        />
                    </InputContainer>

                    <InputContainer>
                        <InputItem
                            labelText="Credencial de Acesso"
                            type="textarea"
                            errorMessage={getFormInputError('credentials', errors, touched)}
                            inputProps={{ placeholder: '{ }', ...getFieldProps('credentials') }}
                        />
                    </InputContainer>

                    <InputContainer>
                        <InputItem
                            labelText="Logo"
                            inputProps={getFieldProps('logo')}
                            errorMessage={getFormInputError('logo', errors, touched)}
                        />
                    </InputContainer>

                    <InputContainer>
                        <InputItem
                            labelText="Canal de venda"
                            type="select"
                            name="id-channel"
                            options={channels.map(c => ({
                                label: c.name,
                                value: c.id,
                            }))}
                            inputProps={{
                                placeholder: 'Selecione um canal',
                                disabled: channels.length === 0,
                                ...getFieldProps('logaroo_id'),
                                value: values.logaroo_id,
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('logaroo_id', value)
                                },
                            }}
                            errorMessage={getFormInputError('logaroo_id', errors, touched)}
                        />
                    </InputContainer>

                    <InputContainer>
                        <InputItem
                            labelText="Status"
                            type="select"
                            options={[
                                { label: 'Ativo', value: 'true' },
                                { label: 'Inativo', value: 'false' },
                            ]}
                            inputProps={{
                                placeholder: 'Selecione um status',
                                ...getFieldProps('status'),
                                value: values.status.toString(),
                                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('status', value === 'true')
                                },
                            }}
                            errorMessage={getFormInputError('status', errors, touched)}
                        />
                    </InputContainer>
                </FormSection>
            </ContentContainer>
            <ButtonRow>
                <TextButton onClick={_onClose}>Cancelar</TextButton>
                <ConfirmButton onClick={() => handleSubmit()}>{!origin?.id ? 'Criar' : 'Salvar'}</ConfirmButton>
            </ButtonRow>
        </OutsideContainer>
    )
})

function isJsonString(str: string) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export { AuthIntegratorContent }
