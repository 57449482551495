import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Info } from 'components/info/info'
import ModalLoading from 'components/modal-loading'
import { Scroll } from 'components/scroll/scroll'
import { TableOrderFile } from 'components/tables'

import api from 'services/api'
import { PagedList } from 'types'
import { IImportOrder } from 'types/import-order'

import { Container, Header, Content, HeaderTitle, Row } from './view-import-page.styled'

type Params = {
    id: string
}

const ViewImportPage = () => {
    const { id } = useParams() as Params
    const [oders, setOrders] = useState<IImportOrder[]>([])
    const [loading, setLoading] = useState(false)

    const _getOrders = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await api.get<PagedList<IImportOrder>>(`/painel/imports/${id}/preview`)
            setOrders(data.items)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [id])

    useEffect(() => {
        _getOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Container>
                <Header>
                    <HeaderTitle>Pré - Visualização</HeaderTitle>
                    <Row>
                        <Info label="Nome do Arquivo" value="Romaneio 6435235.XLS" />
                        <Info label="Quem enviou" value="Maria Sousa" />
                        <Info label="Hora" value="13:35" />
                        <Info label="Qnt. de Pedidos" value="50 Pedidos" />
                        <Info label="Status" value="Pré-Visualização" variant="primary" />
                    </Row>
                </Header>
                <Content>
                    <Scroll>
                        <TableOrderFile orders={oders} />
                    </Scroll>
                </Content>
            </Container>
            <ModalLoading visible={loading} />
        </>
    )
}

export { ViewImportPage }
