import { useEffect, useState, useCallback, useRef } from 'react'
import { Layer, Source, Popup } from 'react-mapbox-gl'

import { booleanPointInPolygon, featureCollection, feature, point } from '@turf/turf'
import { isPointInPolygon } from 'geolib'

import { MapPopup } from 'components/_common'
import { AreaPopup } from 'components/_common/area-popup/area-popup'
import CheckboxInput from 'components/checkbox-input'

import colors from '../../../themes/colors'

const PolygonFill = ({
    id,
    areas,
    mapControls = false,
    defaultColor = undefined,
    defaultOpacity = undefined,
    editionArea = undefined,
    editionColor = undefined,
    onClick = undefined,
    showPopUpArea = undefined,
    areaType = undefined,
    rateCoverageAreas = undefined,
}) => {
    const timeoutRef = useRef()

    const [popupItem, setPopupItem] = useState(null)

    const [areaPopup, setAreaPopup] = useState(null)
    const [coordinatesAreaPopup, setCoordinatesAreaPopup] = useState(null)

    const [coordinatesClicked, setCoordinatesClicked] = useState(null)
    const [polygonsVisible, setPolygonsVisible] = useState(mapControls ? false : true)

    /**
     *
     * Retorna cor da área de cobrança,
     * de acordo com need_moderation e status
     *
     * */
    const getPaymentAreaColor = ({ color, need_moderation, status }) => {
        if (need_moderation && status === 1) return '#FF0000'
        if (status === 0) return '#000'
        return color
    }

    const getRateAreaColor = item => {
        if (rateCoverageAreas.some(area => area.id === item.id)) return colors.primary_color

        return defaultColor
    }

    const getPolygonColor = (type, item) => {
        return {
            'coverage-area': editionColor || item.color,
            'coverage-area-creation': item?.id === editionArea?.id ? editionColor : defaultColor,
            'charge-area-creation': item?.id === editionArea?.id ? editionColor : defaultColor,
            'payment-area': type === 'payment-area' && getPaymentAreaColor(item),
            'rate-area': type === 'rate-area' && getRateAreaColor(item),
        }[type || 'coverage-area']
    }

    const showPopup = ({ lngLat }) => {
        const searchPoint = point([lngLat.lng, lngLat.lat])
        const areaClicked = areas.some(({ area }) => Array.isArray(area))
            ? areas.find(({ area }) =>
                  isPointInPolygon(
                      { lat: lngLat.lat, lng: lngLat.lng },
                      area.map(({ lat, lng }) => ({ lat, lng }))
                  )
              )
            : areas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

        setCoordinatesClicked([lngLat.lng, lngLat.lat])
        setPopupItem(areaClicked?.id === popupItem?.id ? null : areaClicked)
    }

    const _showAreaPopup = useCallback(
        ({ lngLat }) => {
            const searchPoint = point([lngLat.lng, lngLat.lat])
            const areaFocus = areas.some(({ area }) => Array.isArray(area))
                ? areas.find(({ area }) =>
                      isPointInPolygon(
                          { lat: lngLat.lat, lng: lngLat.lng },
                          area.map(({ lat, lng }) => ({ lat, lng }))
                      )
                  )
                : areas.find(({ area }) => booleanPointInPolygon(searchPoint, area))

            setCoordinatesAreaPopup([lngLat.lng, lngLat.lat])
            setAreaPopup(areaFocus)
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                setCoordinatesAreaPopup(null)
                setAreaPopup(null)
            }, 2000)
        },
        [areas]
    )

    const geoJsonSource = {
        type: 'geojson',
        data: featureCollection(
            areas?.map(({ area, ...item }) =>
                feature(
                    Array.isArray(area)
                        ? {
                              type: 'Polygon',
                              coordinates: [area.map(({ lat, lng }) => [lng, lat])],
                          }
                        : area,
                    {
                        color: getPolygonColor(areaType, item),
                        opacity:
                            areaType === 'payment-area' && (item.need_moderation || item.status === 0)
                                ? 0.75
                                : defaultOpacity || 0.25,
                    }
                )
            )
        ),
    }

    useEffect(() => {
        if (!mapControls) return

        const polygonsVisibleStorage = localStorage.getItem('@extranet.logaroo:polygons-visible')
        if (polygonsVisibleStorage) {
            setPolygonsVisible(polygonsVisibleStorage === 'true' ? true : false)
        }
    }, [mapControls])

    function handlePolygonsVisibleChange(checked) {
        setPolygonsVisible(checked)
        localStorage.setItem('@extranet.logaroo:polygons-visible', checked)
    }

    return (
        <>
            {mapControls && (
                <div className="map-controls-container">
                    <CheckboxInput
                        label="Habilitar polígonos"
                        checked={polygonsVisible}
                        onChange={({ target: { checked } }) => handlePolygonsVisibleChange(checked)}
                    />
                </div>
            )}

            <Source id={id} geoJsonSource={geoJsonSource} />
            <Layer
                type="fill"
                onMouseEnter={showPopUpArea ? e => _showAreaPopup(e) : null}
                onClick={e => (onClick ? onClick(e) : showPopup(e))}
                id="polygon-areas"
                layout={{
                    visibility: polygonsVisible ? 'visible' : 'none',
                }}
                paint={{
                    'fill-color': ['get', 'color'],
                    'fill-opacity': ['get', 'opacity'],
                }}
                sourceId={id}
            />
            <Layer
                type="line"
                id="line-areas"
                layout={{
                    visibility: polygonsVisible ? 'visible' : 'none',
                }}
                paint={{
                    'line-color': ['get', 'color'],
                    'line-width': 1,
                }}
                sourceId={id}
            />

            {popupItem && (
                <Popup style={{ visibility: 'visible' }} coordinates={coordinatesClicked} offset={5}>
                    <MapPopup paymentArea={popupItem} />
                </Popup>
            )}

            {areaPopup && coordinatesAreaPopup && (
                <Popup style={{ visibility: 'visible' }} coordinates={coordinatesAreaPopup} offset={5}>
                    <AreaPopup paymentArea={areaPopup} />
                </Popup>
            )}
        </>
    )
}

export default PolygonFill
