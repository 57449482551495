import {
    forwardRef,
    memo,
    useImperativeHandle,
    useRef,
    useCallback,
    useEffect,
    useState,
    useLayoutEffect,
    useMemo,
} from 'react'

import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import mallMarker from 'assets/images/mall-marker.png'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth, useMapboxView } from 'hooks'
import { addSymbolPoint } from 'hooks/useMapboxView/helpers'
import api from 'services/api'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormLabel,
    FormSection,
    InputContainer,
    InputInfo,
    MapContainer,
    OutsideContainer,
    Row,
    TextButton,
    TextContent,
    TextInfo,
    Title,
} from './modal-config-queue.styled'

export interface ModalConfigQueueRef {
    show(): void
    close(): void
}

type IForm = {
    radius: number
    timeout: number
    biker_strikes_limit?: number
}

const DEFAULT_MAX_STRIKES = 3

const ModalConfigQueue = memo(
    forwardRef<ModalConfigQueueRef>((_, ref) => {
        const { mall } = useAuth()
        const { setErrorModal, setSuccessModal } = useUI()
        const { mapRef, mapContainerRef, addCirclePolygon } = useMapboxView({ zoom: 14 })

        const lateralModalBaseRef = useRef<LateralModalBase>()

        const [loading, setLoading] = useState<boolean>(false)

        const headerComponent = useMemo(
            () => <FormLabel>Altere os valores para personalizar sua fila de entregadores</FormLabel>,
            []
        )

        const { isSubmitting, handleSubmit, setValues, getFieldProps, touched, errors, values } = useFormik<IForm>({
            initialValues: { radius: 0, timeout: 0, biker_strikes_limit: DEFAULT_MAX_STRIKES },
            validationSchema: Yup.object().shape({
                radius: Yup.number().required('Raio da fila é obrigatório.'),
                timeout: Yup.number().required('Timeout da fila é obrigatório.'),
                biker_strikes_limit: Yup.number()
                    .min(1, 'A  quantidade máxima de strikes deve ser igual ou maior que 1.')
                    .required('Quantidade máxima de strikes é obrigatório.'),
            }),
            onSubmit: async values => {
                try {
                    await api.put(`/painel/mall/${mall.id}`, {
                        radius: values.radius < 10 ? 10 : values.radius,
                        timeout: values.timeout < 20 ? 20 : values.timeout,
                        configs: {
                            biker_strikes_limit: values?.biker_strikes_limit,
                        },
                    })

                    setSuccessModal({
                        title: 'Sucesso!',
                        subtitle: 'Unidade editada com sucesso!',
                        singleButtonText: 'Ok',
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    lateralModalBaseRef.current?.show()
                    setTimeout(() => {
                        mapRef.current?.resize()
                    }, 400)
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            []
        )

        const _getMall = useCallback(async () => {
            setLoading(true)
            try {
                const { data } = await api.get(`/painel/mall/${mall.id}`)

                setValues({
                    radius: Number(data.radius),
                    timeout: Number(data.timeout),
                    biker_strikes_limit: Number(data?.configs?.biker_strikes_limit ?? null),
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: showErrors(error),
                })
            }
            setLoading(false)
        }, [mall, setErrorModal])

        const _removePolygonLayer = useCallback(() => {
            if (mapRef.current?.getSource('polygons')) {
                mapRef.current?.removeLayer('polygon-lines')
                mapRef.current?.removeLayer('polygons')
                mapRef.current?.removeSource('polygons')
            }
        }, [mapRef])

        const _handlePolygonRadius = useCallback(() => {
            if (!values.radius) return

            _removePolygonLayer()

            addCirclePolygon(
                {
                    lat: mall.address.lat,
                    lng: mall.address.lng,
                },
                values.radius
            )
        }, [mall, values.radius, _removePolygonLayer])

        useEffect(() => {
            setTimeout(() => {
                if (mall?.address?.lat && mall?.address?.lng) {
                    addSymbolPoint(
                        mapRef.current,
                        { lat: mall.address.lat, lng: mall.address.lng },
                        {
                            id: 'mall-marker-preview',
                            src: mallMarker,
                            size: 0.4,
                            coordinates: { lat: mall.address.lat, lng: mall.address.lng },
                        }
                    )
                }
            }, 1000)
        }, [mall, mapRef])

        useEffect(() => {
            _getMall()
        }, [_getMall])

        useEffect(() => {
            _handlePolygonRadius()
        }, [_handlePolygonRadius])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={'Configurar Fila'} headerComponent={headerComponent}>
                <OutsideContainer>
                    <ContentContainer>
                        <TextInfo>
                            <Title>Como funciona a fila</Title>
                            <TextContent>
                                Para otimizar nossos tempos de coleta e entrega, esse sistema trabalha de forma
                                semelhante ao de um “ranking”, de quais os entregadores ideais para aquela entrega. Onde
                                entregadores que dispensam requisições de entrega perdem prioridade na entregas.
                            </TextContent>
                        </TextInfo>

                        <MapContainer ref={mapContainerRef} />

                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Raio da fila (metros)"
                                    type="number"
                                    inputProps={{
                                        min: '10',
                                        step: '50',
                                        ...getFieldProps('radius'),
                                        placeholder: 'EX: 50',
                                    }}
                                    errorMessage={touched.radius && errors.radius}
                                />
                                <InputInfo>
                                    Esse valor corresponde a área circular em metros, em que os entregadores podem
                                    receber as requisições de entrega, isso possibilita que o tempo de coleta mínimo
                                    seja atingido.
                                </InputInfo>
                            </InputContainer>
                            {/* <InputContainer>
                                <InputItem
                                    labelText="Timeout da fila (segundos)"
                                    type="number"
                                    inputProps={{
                                        min: '20',
                                        step: '5',
                                        ...getFieldProps('timeout'),
                                        placeholder: 'EX: 30',
                                    }}
                                    errorMessage={touched.timeout && errors.timeout}
                                />
                                <InputInfo>
                                    Esse valor corresponde ao tempo em segundos, que o entregador tem para aceitar a
                                    requisição de entrega, passado esse tempo, a requisição é passada para o próximo da
                                    fila.
                                </InputInfo>
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    labelText="Strikes"
                                    type="number"
                                    inputProps={{
                                        ...getFieldProps('biker_strikes_limit'),
                                        placeholder: 'EX: 3',
                                        min: 0,
                                    }}
                                    errorMessage={touched.biker_strikes_limit && errors.biker_strikes_limit}
                                />
                                <InputInfo>
                                    Esse valor corresponde a quantidade de vezes que um entregador pode perder uma
                                    requisição de pedido antes de ser deslogado da fila e precisar fazer um novo
                                    cheking.
                                </InputInfo>
                            </InputContainer> */}
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="flex-end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>Salvar</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
                <ModalLoading visible={isSubmitting || loading} />
            </LateralModalBase>
        )
    })
)

export { ModalConfigQueue }
