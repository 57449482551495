import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import Checkbox from 'components/_common/checkbox/checkbox'
import ButtonForm from 'components/button-form'
import RowTitle from 'components/row-title'
import TextInputMask from 'components/text-input-mask'

import { useUI } from 'contexts'
import { getFormInputError } from 'helpers'
import { useDataFetching } from 'hooks'
import api from 'services/api'
import { PagedList, Store } from 'types'

import { StoresError, StoresListingContainer, StoresListingTitle } from './featured-stores-form.styles'

const FeaturedStoresForm: React.FC<RouteComponentProps<{ featured_store_id: string }>> = ({
    history,
    match: { params },
}) => {
    const { setLoading, setSnackbar } = useUI()
    const { data: storesAvailable, loading: loadingStores } = useDataFetching<PagedList<Store>>('/stores', {
        params: { mall_id: 1 },
    })

    useEffect(() => {
        if (loadingStores) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [loadingStores])

    useEffect(() => {
        if (params.featured_store_id) {
            getFeaturedStoreData(params.featured_store_id)
        }
    }, [params.featured_store_id])

    function getStoreId(storeList) {
        const idList = []
        storeList.map(store => {
            idList.push(store.id)
        })
        return idList
    }

    const getFeaturedStoreData = async storeID => {
        try {
            setLoading(true)
            const { data } = await api.get(`/painel/store-featured/${storeID}`)

            setValues({
                ...initialValues,
                start_time: data?.start_time.slice(0, -3),
                end_time: data?.end_time.slice(0, -3),
                stores: getStoreId(data.stores),
                status: data?.status,
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    function onCheckboxClick(storeID: number) {
        if (values.stores?.some(id => id === storeID)) {
            setFieldValue(
                'stores',
                values.stores.filter(id => id !== storeID)
            )
        } else {
            setFieldValue('stores', [...values.stores, storeID])
        }
    }

    const { errors, getFieldProps, initialValues, handleSubmit, resetForm, setFieldValue, setValues, touched, values } =
        useFormik({
            initialValues: {
                mall_id: 1,
                start_time: '',
                end_time: '',
                stores: [],
                status: 0,
            },
            validationSchema: Yup.object().shape({
                start_time: Yup.string().trim().required('Horário de início é obrigatório'),
                end_time: Yup.string().trim().required('Horário de fim é obrigatório'),
                stores: Yup.array().min(1, 'Selecione pelo menos uma loja'),
            }),
            onSubmit: async values => {
                try {
                    setLoading(true)
                    if (params.featured_store_id) {
                        await api.put(`/painel/store-featured/${params.featured_store_id}`, values)
                        setSnackbar({
                            message: `Destaque alterado com sucesso`,
                        })
                    }

                    if (!params.featured_store_id) {
                        await api.post('/painel/store-featured', values)
                        setSnackbar({
                            message: `Destaque criado com sucesso`,
                        })
                    }
                    resetForm()
                    history.push('/marketplace/gestao-marketplace/lojas-em-destaque')
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoading(false)
                }
            },
        })

    return (
        <div>
            <RowTitle
                title={params.featured_store_id ? 'Editar lojas em destaque' : 'Criar lojas em destaque'}
                backClick={() => history.push('/marketplace/gestao-marketplace/lojas-em-destaque')}
            />

            <div className="marketplace-form-container">
                <div className="inputs-row col-3">
                    <TextInputMask
                        mask="99:99"
                        label="Hora de início*"
                        value={values.start_time}
                        {...getFieldProps('start_time')}
                        msgErro={getFormInputError('start_time', errors, touched)}
                        onChange={({ target: { value } }) => {
                            setFieldValue('start_time', value)
                        }}
                    />
                </div>

                <div className="inputs-row col-3">
                    <TextInputMask
                        mask="99:99"
                        label="Hora de término*"
                        value={values.end_time}
                        {...getFieldProps('end_time')}
                        msgErro={getFormInputError('end_time', errors, touched)}
                        onChange={({ target: { value } }) => {
                            setFieldValue('end_time', value)
                        }}
                    />
                </div>

                <StoresListingContainer>
                    <StoresListingTitle>Escolha as lojas em destaque do período</StoresListingTitle>
                    {getFormInputError('stores', errors, touched) !== null && (
                        <StoresError>{`${getFormInputError('stores', errors, touched)}`}</StoresError>
                    )}
                    {storesAvailable?.items.map(store => (
                        <Checkbox
                            key={store.id}
                            isSquared
                            checkboxTitle={store.name}
                            inputProps={{
                                checked: true,
                                value: Number(values.stores?.some(id => id === store.id)),
                                onChange: () => onCheckboxClick(store.id),
                            }}
                        />
                    ))}
                </StoresListingContainer>

                <StoresListingContainer>
                    <StoresListingTitle>Status do período de destaque</StoresListingTitle>
                    <Checkbox
                        checkboxTitle={values.status === 1 ? 'Ativo' : 'Inativo'}
                        isSquared
                        inputProps={{
                            checked: true,
                            value: Number(values.status === 1),
                            onChange: () => {
                                if (values.status === 1) {
                                    setFieldValue('status', 0)
                                } else {
                                    setFieldValue('status', 1)
                                }
                            },
                        }}
                    />
                </StoresListingContainer>

                <ButtonForm type="submit" buttonText="Salvar" style={{ marginTop: 30 }} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default FeaturedStoresForm
