import styled from 'styled-components'

export const TariffBoxContainer = styled.div<{ readOnly?: boolean }>`
    border-radius: 12px;
    background-color: #f6f6f6;
    padding: 40px 20px;
`

export const TariffItem = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:not(:last-child) {
        margin-bottom: 20px;
        @media (min-width: ${({ theme }) => theme.devices.tablet}) {
            margin-bottom: 20px;
        }
    }
`

export const TariffLabel = styled.div`
    margin-bottom: 15px;
    font-size: 14px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 30%;
        margin-bottom: 0;
    }
`

export const UpdateTariffs = styled.div<{ isDisabled?: boolean }>`
    user-select: none;
    border-radius: 4px;
    background-color: ${({ theme, isDisabled }) => (isDisabled ? '#ccc' : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    display: inline-flex;
    padding: 5px 20px;
    text-transform: uppercase;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    margin-top: 20px;
`
