import { faGripLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const DishesGroupContainer = styled.div`
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        align-items: center;
        padding: 20px 40px;
    }
`

export const DragContainer = styled.div`
    margin-right: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-bottom: 0;
    }
`

export const DragIcon = styled(FontAwesomeIcon).attrs({
    icon: faGripLines,
})``

export const ContentContainer = styled.div`
    flex: 1;
`

export const GroupTitleRow = styled.div`
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
    }
`

export const GroupTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
    flex-basis: 100%;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 40%;
        margin-bottom: 0;
    }
`

export const GroupTitleInfo = styled.div`
    flex-basis: 31%;
    display: flex;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        flex-basis: 20%;
    }
`

export const GroupOptions = styled.div``

export const MobileTag = styled.span`
    font-weight: bold;
    margin-right: 5px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: none;
    }
`

export const OptionItem = styled.div`
    color: ${({ theme }) => theme.colors.darkGray};
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 15px;
    position: relative;
    padding-left: 15px;
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.darkGray};
        position: absolute;
        top: 3px;
        left: 0;
    }
`

export const OptionText = styled.div`
    width: 20%;
    margin-right: 5%;
`

export const OptionValue = styled.div`
    flex: 1;
`

export const ButtonsRow = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        position: absolute;
        bottom: 20px;
        right: 20px;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
`

export const EditButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    width: 150px;
    margin-right: 80px;
    text-transform: uppercase;
    font-weight: 600;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 25px;
        margin-right: 0;
        width: 80px;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
`
