import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { LateralModalBase } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import api from 'services/api'
import { ApiPhoto, ImageDetails, PagedList, StoreCategory, StoreType } from 'types'

import {
    AttachmentContent,
    AttachmentContentLabel,
    ButtonItem,
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormRow,
    FormSection,
    InputContainer,
    OutsideContainer,
    Row,
    TextButton,
} from './modal-store-categories.styled'

export interface ModalStoreCategoriesRef {
    show(): void
    close(): void
    setStoreCategory(storeCategory: StoreCategory): void
}

type Props = {
    onClose?(refresh: boolean): void
    onRemove?(id: number): () => void
}

type IForm = {
    id?: number
    name: string
    description: string
    store_type_id?: number
    icon: ApiPhoto | File
    banner: ApiPhoto | File
    status: 0 | 1
}

const statusOptions = [
    {
        label: 'Ativo',
        value: 1,
    },
    {
        label: 'Inativo',
        value: 0,
    },
]

const ModalStoreCategories = memo(
    forwardRef<ModalStoreCategoriesRef, Props>(({ onClose, onRemove }, ref) => {
        const { setErrorModal, setSuccessModal } = useUI()

        const lateralModalBaseRef = useRef<LateralModalBase>()
        const [storeTypes, setStoreTypes] = useState<StoreType[]>()
        const [storeCategory, setStoreCategory] = useState<StoreCategory>()

        const storeTypeOptions = useMemo(() => {
            return storeTypes?.map(item => ({ label: item.name, value: item.id }))
        }, [storeTypes])

        const isEdit = useMemo(() => {
            return !!storeCategory
        }, [storeCategory])

        const modalTitle = useMemo(() => {
            return `${isEdit ? 'Gerenciar' : 'Criar'} Categoria de Loja`
        }, [isEdit])

        const {
            isSubmitting,
            errors,
            getFieldProps,
            handleSubmit,
            resetForm,
            setFieldValue,
            setValues,
            touched,
            values,
        } = useFormik<IForm>({
            initialValues: {
                name: '',
                description: '',
                icon: { id: '' } as ApiPhoto | File,
                banner: { id: '' } as ApiPhoto | File,
                status: 1,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string().trim().required('Título da categoria é obrigatório'),
                store_type_id: Yup.string().required('Vertical de loja é obrigatória'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    const formData = new FormData()

                    formData.set('name', values.name)
                    formData.set('description', values.description)
                    formData.set('store_type_id', String(values.store_type_id))

                    formData.set('status', values.status.toString())

                    if (values.id) {
                        formData.set('_method', 'PUT')
                        await api.post(`/painel/store-category/${values.id}`, formData)
                        if (values.icon instanceof File) {
                            const iconData = new FormData()
                            iconData.set('icon', values.icon as File)
                            await api.post(`/painel/store-category/${values.id}/icon`, iconData)
                        }
                        if (values.banner instanceof File) {
                            const bannerData = new FormData()
                            bannerData.set('banner', values.banner as File)
                            await api.post(`/painel/store-category/${values.id}/banner`, bannerData)
                        }
                    } else {
                        const { data } = await api.post('/painel/store-category', formData)
                        if (values.icon instanceof File) {
                            const iconData = new FormData()
                            iconData.set('icon', values.icon as File)
                            await api.post(`/painel/store-category/${data.id}/icon`, iconData)
                        }
                        if (values.banner instanceof File) {
                            const bannerData = new FormData()
                            bannerData.set('banner', values.banner as File)
                            await api.post(`/painel/store-category/${data.id}/banner`, bannerData)
                        }
                    }

                    setSuccessModal({
                        title: 'Sucesso!',
                        subtitle: `Categoria de loja ${values.id ? 'editada' : 'criada'} com sucesso!`,
                        singleButtonClick: _onClose(true),
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Error',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setSubmitting(false)
                }
            },
        })

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    resetForm()
                    setStoreCategory(null)
                    lateralModalBaseRef.current?.show()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
                setStoreCategory: (storeCategory: StoreCategory) => {
                    setStoreCategory(storeCategory)
                    setValues({ ...storeCategory, store_type_id: storeCategory.store_type.id })
                },
            }),
            []
        )

        const _getStoryTypes = useCallback(async () => {
            const {
                data: { items },
            } = await api.get<PagedList<StoreType>>('/painel/store-types')
            setStoreTypes(items)

            // setStoreTypes(data)
        }, [])

        const _onClose = useCallback(
            (refresh?: boolean) => () => {
                if (onClose) {
                    onClose(refresh)
                }

                lateralModalBaseRef.current?.close()
            },
            []
        )

        const _handleChange = useCallback(
            (fieldName: string) =>
                ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(fieldName, value)
                },
            []
        )

        const _handleAttachment = useCallback(
            (fieldName: string) =>
                ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
                    const file = files[0]
                    const fileSize = file.size / 1024 / 1024
                    if (fileSize > 50) {
                        setErrorModal({
                            title: 'Anexo grande de mais',
                            subtitle: 'O Anexo não pode passar de 50MB',
                        })

                        return
                    }

                    setFieldValue(fieldName, file)
                },
            []
        )

        const _getFileName = useCallback(
            (field: string) => {
                const iconDetails = storeCategory?.icon as ImageDetails
                const bannerDetails = storeCategory?.banner as ImageDetails

                const iconName = iconDetails
                    ? (values.icon?.name || iconDetails?.original_name) ?? iconDetails?.name
                    : values.icon?.name

                const bannerName = bannerDetails
                    ? (values.banner?.name || bannerDetails?.original_name) ?? bannerDetails?.name
                    : values.banner?.name

                return {
                    icon: iconName || 'Anexar Ícone da categoria',
                    banner: bannerName || 'Anexar Banner da categoria',
                }[field]
            },
            [storeCategory, values.icon, values.banner]
        )

        useEffect(() => {
            _getStoryTypes()
        }, [_getStoryTypes])

        return (
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <InputItem
                                    labelText="Título"
                                    inputProps={{
                                        value: values.name,
                                        onChange: _handleChange('name'),
                                    }}
                                    errorMessage={touched.name && errors.name}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="textarea"
                                    labelText="Descrição"
                                    big
                                    inputProps={{
                                        value: values.description,
                                        onChange: _handleChange('description'),
                                    }}
                                    errorMessage={touched.description && errors.description}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Vertical de loja"
                                    options={storeTypeOptions}
                                    inputProps={{
                                        value: values.store_type_id,
                                        onChange: _handleChange('store_type_id'),
                                    }}
                                    errorMessage={touched.store_type_id && errors.store_type_id}
                                />
                            </InputContainer>
                            <InputContainer>
                                <FormRow>
                                    <AttachmentContent>
                                        <AttachmentContentLabel>Ícone</AttachmentContentLabel>
                                        <ButtonItem>
                                            <label htmlFor="icon">{_getFileName('icon')}</label>
                                            <input
                                                type="file"
                                                name="icon"
                                                id="icon"
                                                accept="image/png,image/jpeg,application/pdf"
                                                onChange={_handleAttachment('icon')}
                                            />
                                        </ButtonItem>
                                    </AttachmentContent>
                                    <AttachmentContent>
                                        <AttachmentContentLabel>Banner</AttachmentContentLabel>
                                        <ButtonItem>
                                            <label htmlFor="banner">{_getFileName('banner')}</label>
                                            <input
                                                type="file"
                                                name="banner"
                                                id="banner"
                                                accept="image/png,image/jpeg,application/pdf"
                                                onChange={_handleAttachment('banner')}
                                            />
                                        </ButtonItem>
                                    </AttachmentContent>
                                </FormRow>
                            </InputContainer>

                            <InputContainer>
                                <InputItem
                                    type="select"
                                    labelText="Status"
                                    options={statusOptions}
                                    inputProps={{
                                        value: values.status,
                                        onChange: _handleChange('status'),
                                    }}
                                    errorMessage={touched.status && errors.status}
                                />
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow>
                        <div>
                            {isEdit && onRemove && (
                                <TextButton isRemove onClick={onRemove(storeCategory.id)}>
                                    <FontAwesomeIcon icon="times-circle" /> <span>Remover</span>
                                </TextButton>
                            )}
                        </div>
                        <Row>
                            <TextButton onClick={_onClose()}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{isEdit ? 'Salvar' : 'Criar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>

                <ModalLoading visible={isSubmitting} />
            </LateralModalBase>
        )
    })
)

export { ModalStoreCategories }
