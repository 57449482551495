import classNames from 'classnames'
import { memo } from 'react'

import { ContainerColumn, ContainerRow } from './grid.styled'

type Props = {
    align?: 'center' | 'flex-end' | 'flex-start'
    justify?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly'
    maxWidth?: boolean
    maxHeight?: boolean
    flex?: number
    children?: any
    marginBottom?: number
    marginTop?: number
    marginLeft?: number
    marginRight?: number
    className?: string
    onClick?(e?: any): void
}

const Row: React.FC<Props> = memo(({ children, className, ...props }) => {
    return (
        <ContainerRow className={classNames('row', className)} {...props}>
            {children}
        </ContainerRow>
    )
})

const Column: React.FC<Props> = memo(({ children, className, ...props }) => {
    return (
        <ContainerColumn className={classNames('column', className)} {...props}>
            {children}
        </ContainerColumn>
    )
})

export const Grid = { Row, Column }
