import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
        box-sizing: border-box;
    }
`

const BoxContainer = styled.div`
    background-color: #fff;
    min-width: 600px;
    min-height: 500px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        width: 100%;
        height: 100%;
        min-width: auto;
        min-height: auto;
    }
`

const Header = styled.div`
    height: 50px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    user-select: none;
`

const Title = styled.div`
    font-weight: bold;
    position: relative;
    &::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`

const CloseIcon = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    cursor: pointer;
`

const MapBox = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ContainerPopupInfo = styled.div`
    background-color: rgba(255, 255, 255, 0.9);
    border-top: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 4;
    padding: 8px 15px;
    position: absolute;
    top: 10px;
    left: 10px;
    overflow: hidden;
    max-width: 50%;

    .info {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }
`

const OrderId = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    font-size: 12px;
`

const StoreName = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
`

const ClientName = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
`

const Address = styled.div`
    font-size: 11px;
    text-transform: uppercase;
`

const Footer = styled.div`
    border-top: 1px solid #ccc;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    justify-content: flex-end;
    padding: 8px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    z-index: 4;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        justify-content: space-between;
    }
`

const FooterItem = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    z-index: 5;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        margin: 10px 10px;
        flex: 1;
        button {
            width: 100%;
        }
    }

    &:last-child {
        margin-right: 0px;
    }

    select {
        width: 200px;
    }

    .Modal-button {
        padding: 5px 20px;
        border-radius: 5px;
        border-style: solid;
        color: black;
        font-size: 14px;
        // margin-bottom: 10px;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        cursor: pointer;

        &.primary {
            color: white;
            border-color: var(--primary-color);
            background-color: var(--primary-color);
        }

        &.outline {
            color: var(--primary-color);
            border-color: var(--primary-color);
            border-width: 2px;
        }

        &:disabled {
            background-color: ${({ theme }) => theme.colors.lightGray};
            border-color: ${({ theme }) => theme.colors.lightGray};
        }
    }
`

export {
    OrderId,
    StoreName,
    ClientName,
    Address,
    Header,
    ContainerPopupInfo,
    Container,
    BoxContainer,
    MapBox,
    Footer,
    FooterItem,
    Title,
    CloseIcon,
}
