import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import RootState, { OrdersInProgressState } from 'store/reducers/types'
import { Order } from 'types'

import { OrderItem } from '..'

import {
    CardHeader,
    CardNumber,
    CardTitle,
    EmptyIcon,
    EmptyText,
    EmptyMessageContainer,
    OrdersList,
    ProgressCardContainer,
    LoadingContainer,
} from './progress-card.styles'

interface Props {
    cardTitle: string
    type?: 'in-production' | 'ready-for-collect' | 'awaiting-collect' | 'on-route' | 'delivered-today'
    amount: number | undefined
    emptyText: string
    emptyIcon: string
    onClickOrder(order: Order): void
    dropdownOrderIdActive?: number | undefined
    setDropdownOrderIdActive?(orderId: number | undefined): void
    onRescheduleOrder?(order: Order): void
    onConfirmOrder?(order: Order): void
    onMoveOrder?(order: Order): void
    onDelivered?(order: Order): void
    onToTakeout?(order: Order): void
    onCancelOrder?(order: Order): void
    loading?: boolean
    orders?: Order[]
    hasBikerInfo?: boolean
}

const ProgressCard: React.FC<Props> = ({
    amount,
    cardTitle,
    loading,
    orders,
    emptyText,
    emptyIcon,
    onClickOrder,
    dropdownOrderIdActive,
    setDropdownOrderIdActive,
    onRescheduleOrder,
    onConfirmOrder,
    onMoveOrder,
    onDelivered,
    onToTakeout,
    onCancelOrder,
    hasBikerInfo,
    type,
}) => {
    const { highlightedOrderId } = useSelector<RootState, OrdersInProgressState>(
        ({ ordersInProgress }) => ordersInProgress
    )

    return (
        <ProgressCardContainer>
            <CardHeader>
                <CardTitle>{cardTitle}</CardTitle>
                <CardNumber>{amount}</CardNumber>
            </CardHeader>
            {loading ? (
                <OrdersList>
                    {[...Array(6)].map((_item, index) => (
                        <LoadingContainer key={index}>
                            <Skeleton circle height={60} width={60} style={{ marginRight: 20 }} />

                            <Skeleton height={50} />
                        </LoadingContainer>
                    ))}
                </OrdersList>
            ) : (
                <OrdersList>
                    {Number(orders?.length) > 0 ? (
                        <>
                            {orders?.map((order, index) => (
                                <OrderItem
                                    key={index}
                                    type={type}
                                    order={order}
                                    isHighlighted={order.id === highlightedOrderId}
                                    dropdownOrderIdActive={dropdownOrderIdActive}
                                    setDropdownOrderIdActive={setDropdownOrderIdActive}
                                    onClickOrder={onClickOrder}
                                    onRescheduleOrder={onRescheduleOrder}
                                    onConfirmOrder={onConfirmOrder}
                                    onMoveOrder={onMoveOrder}
                                    onDelivered={onDelivered}
                                    onToTakeout={onToTakeout}
                                    onCancelOrder={onCancelOrder}
                                    hasBikerInfo={hasBikerInfo}
                                />
                            ))}
                        </>
                    ) : (
                        <EmptyMessageContainer>
                            <EmptyIcon src={emptyIcon} />
                            <EmptyText>{emptyText}</EmptyText>
                        </EmptyMessageContainer>
                    )}
                </OrdersList>
            )}
        </ProgressCardContainer>
    )
}

export default ProgressCard
