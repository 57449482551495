import { Layer, Source } from 'react-mapbox-gl'

import circle from '@turf/circle'

import colors from '../../../themes/colors'

const CircleLines = ({ id, center }) => {
    const geoJsonSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [...Array(30)].map((item, index) => {
                const radius = 1000 * (index + 1)

                return circle(center, radius, { steps: 50, units: 'meters' })
            }),
        },
    }

    return center?.length ? (
        <>
            <Source id={id} geoJsonSource={geoJsonSource} />
            <Layer
                type="line"
                id="line-circle"
                paint={{
                    'line-color': colors.gray_color,
                    'line-width': 1,
                    'line-dasharray': [5, 3],
                }}
                sourceId={id}
            />
        </>
    ) : null
}

export default CircleLines
