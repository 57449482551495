import { formatPhone, getBikerType } from 'helpers'
import { Biker, IAgent } from 'types'

import {
    AvailableBikerCardContainer,
    BikerPhotoContainer,
    BikerImage,
    BikerTextContainer,
    BikerName,
    BikerPhoneContainer,
    PhoneIcon,
    PhoneValue,
    Vehicle,
    BikerFlag,
} from './selectable-biker-card.styles'

interface Props {
    biker: Biker
    onRemove?(): void
    isActive?: boolean
    cardClick?(): void
    isRouteBiker?: boolean
}

const SelectableBikerCard: React.FC<Props> = ({ biker, isActive, cardClick, isRouteBiker }) => {
    return (
        <AvailableBikerCardContainer isActive={isActive} onClick={cardClick}>
            {isRouteBiker && <BikerFlag>Entregador atual</BikerFlag>}
            <BikerPhotoContainer>
                <BikerImage src={biker?.avatar} />
            </BikerPhotoContainer>
            <BikerTextContainer>
                <Vehicle>{getBikerType(biker?.type)}</Vehicle>
                <BikerName>
                    {biker?.first_name} {biker?.last_name}
                </BikerName>
                <BikerPhoneContainer>
                    <PhoneIcon />
                    <PhoneValue>{formatPhone(biker?.cellphone)}</PhoneValue>
                </BikerPhoneContainer>
            </BikerTextContainer>
        </AvailableBikerCardContainer>
    )
}
interface SelectableAgentCardProps {
    agent: IAgent
    onRemove?(): void
    isActive?: boolean
    cardClick?(): void
    isRouteBiker?: boolean
}

export const SelectableAgentCard: React.FC<SelectableAgentCardProps> = ({
    agent,
    isActive,
    cardClick,
    isRouteBiker,
}) => {
    return (
        <AvailableBikerCardContainer isActive={isActive} onClick={cardClick}>
            {isRouteBiker && <BikerFlag>Entregador atual</BikerFlag>}
            <BikerPhotoContainer>
                <BikerImage src={agent?.avatar} />
            </BikerPhotoContainer>
            <BikerTextContainer>
                <Vehicle>{getBikerType(agent?.type)}</Vehicle>
                <BikerName>{agent?.name}</BikerName>
                <BikerPhoneContainer>
                    <PhoneIcon />
                    <PhoneValue>{formatPhone(agent?.phone)}</PhoneValue>
                </BikerPhoneContainer>
            </BikerTextContainer>
        </AvailableBikerCardContainer>
    )
}

export default SelectableBikerCard
