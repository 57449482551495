import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'

const ContentContainer = styled.div`
    flex: 1;
    padding: 0.938rem;
    overflow-y: auto;
    position: relative;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        padding: 1.563rem;
    }
`

const OutsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const FormLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-bottom: 1.5rem;

    svg {
        color: ${({ theme }) => theme.colors.primary};
    }
`

const FormSection = styled.div`
    gap: 0.5rem;
    margin-bottom: 1.875rem;
`

const InputContainer = styled.div``

const ButtonRow = styled.div<{ justify?: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => justify || 'space-between'};
    padding: 1.25rem;
    background-color: #f7f7f7;

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        padding: 0.625rem;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
`

const TextButton = styled.div<{ isRemove?: boolean }>`
    color: ${({ theme, isRemove }) => (isRemove ? theme.colors.errorMessage : theme.colors.primary)};
    margin-right: 1.875rem;
    font-size: 1rem;
    transition: 400ms;
    font-weight: 600;
    cursor: pointer;
    padding: 0.563rem 1.25rem;
    border-radius: 0.25rem;
    &:hover {
        opacity: 0.8;
    }
    span {
        margin-left: 0.25;
    }

    @media (min-width: ${({ theme }) => theme.devices.mobile}) {
        margin-right: 0px;
    }
`

const ConfirmButton = styled.button<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.5rem;
    width: fit-content;
    border: 0;
    &:hover {
        opacity: 0.8;
    }
`
const DeleteButton = styled.div<{ disabled?: boolean }>`
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray : theme.colors.danger)};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.625rem 1.25rem;
    text-transform: uppercase;
    transition: 400ms;
    cursor: pointer;
    border-radius: 0.25rem;
    width: fit-content;
    &:hover {
        opacity: 0.8;
    }
`

const DeleteIcon = styled(LogarooIcon).attrs({
    icon: 'trash',
})`
    color: ${({ theme }) => theme.colors.white};
`

const CloseContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    cursor: pointer;
    z-index: 3;
`

const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: 'times',
})`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.28rem;
    cursor: pointer;
    transform: rotate(90deg);
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        transform: rotate(0);
    }
`

const Message = styled.div`
    color: #5f5f5f;
    font-size: 12px;
    margin-bottom: 2px;
    span {
        color: ${({ theme }) => theme.colors.primary};

        &.link {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
`

const CardFile = styled.div<{ showBorder?: boolean; alert?: boolean; pointer?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: ${({ showBorder }) => (showBorder ? '1px dashed #939393' : 'none')};
    background-color: ${({ alert, theme }) => (alert ? theme.colors.danger : '#f3f6ff')};
    padding: 10px;
    min-height: 150px;
    display: flex;
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};

    .upload {
        color: ${({ theme }) => theme.colors.primary};
        margin-left: 10px;
        width: 30px;
        height: 30px;
        opacity: 0.7;
    }
    .alert {
        color: #fff;
        margin-left: 10px;
        width: 30px;
        height: 30px;
        opacity: 0.7;
        align-self: flex-end;
    }

    .sending {
        font-size: 30px;
        color: #939393;
    }
    .error {
        font-size: 50px;
        color: #fff;
    }
`

const ContainerInfoCard = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const TitleFile = styled.div<{ hasFile }>`
    font-size: ${({ hasFile }) => (hasFile ? 20 : 36)}px;
    font-weight: bold;
    color: #939393;
    margin-bottom: 10px;
`

const ImagePreviewContent = styled.div`
    margin-bottom: 1rem;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        height: 26.25rem;
    }
`

const ImagePreview = styled.img`
    object-fit: contain;

    width: 100%;
    height: 100%;
`

export {
    ContentContainer,
    OutsideContainer,
    FormSection,
    InputContainer,
    ButtonRow,
    Row,
    TextButton,
    ConfirmButton,
    DeleteButton,
    CloseContainer,
    CloseButton,
    CardFile,
    ContainerInfoCard,
    TitleFile,
    Message,
    FormLabel,
    ImagePreviewContent,
    ImagePreview,
    DeleteIcon,
}
