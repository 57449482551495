import circle from '@turf/circle'
import { featureCollection } from '@turf/helpers'
import { GeoJSONSource, Map } from 'mapbox-gl'

import colors from 'themes/colors'

import { Coordinates } from '../types'

import { addGeojsonPolygons } from './addGeojsonPolygons'

export function addCirclePolygon(map: Map, center: Coordinates, radius: number): { source: GeoJSONSource } {
    const circlePolygon = circle([center.lng, center.lat], radius, {
        steps: 50,
        units: 'meters',
        properties: {
            'fill-color': colors.secondary_color,
            'fill-opacity': 0.2,
            'line-color': colors.light_primary_color,
            'line-width': 1,
        },
    })
    const geojsonData = featureCollection([circlePolygon])

    const { source } = addGeojsonPolygons(map, geojsonData as GeoJSON.FeatureCollection<GeoJSON.Geometry>)

    return { source }
}
